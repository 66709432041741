import { ApiService } from '~plugins/axios/api';
import { axiosInstanceV2 } from '~plugins/axios';
import { type IBodyResponse } from '~common/interfaces';
import type {
    AlmexKioskSettingResponse,
    AlmexKioskSettingUpdateBody,
    AlmexKioskTerminalUpdateReqBody,
    AlmexKioskTerminalUpdateResponse,
} from '../types';

class AlmexKioskIntegrationService extends ApiService {
    getAlmexKioskSetting() {
        return this._get(this.baseUrl) as Promise<
            IBodyResponse<AlmexKioskSettingResponse | null>
        >;
    }

    updateAlmexSetting(body: AlmexKioskSettingUpdateBody) {
        return this._post(`${this.baseUrl}/update-setting`, body) as Promise<
            IBodyResponse<AlmexKioskSettingUpdateBody>
        >;
    }

    updateAlmexTerminals(body: AlmexKioskTerminalUpdateReqBody) {
        return this._post(`${this.baseUrl}/update-terminals`, body) as Promise<
            IBodyResponse<AlmexKioskTerminalUpdateResponse>
        >;
    }
}

export const almexIntegrationService = new AlmexKioskIntegrationService(
    { baseUrl: '/setting/kiosk-almex' },
    axiosInstanceV2,
);
