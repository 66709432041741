import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { IIntegrationsState, IHotelSmartIntegration } from '../interfaces';
import { integrationsService } from '../services/integrations.service';

const initialState: IIntegrationsState = {
    hotelSmart: {
        username: '',
        password: '',
        enableIntegration: false,
        checkedInDate: undefined,
        houseId: undefined,
    },
};

export const getHotelSmartConfig = createAsyncThunk('hotelSmartConfig/get', async () => {
    return await integrationsService.getHotelSmartConfig();
});

export const integrateHotelSmart = createAsyncThunk(
    'integrateHotelSmart/post',
    async (formData: IHotelSmartIntegration) => {
        return await integrationsService.integrateHotelSmart(formData);
    },
);

export const updateHotelSmart = createAsyncThunk(
    'hotelSmart/patch',
    async (body: IHotelSmartIntegration) => {
        return await integrationsService.updateHotelSmart(body);
    },
);

const integrationsSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        setHotelSmart: (state, action) => {
            const { enableIntegration, username, houseId, password, checkedInDate } =
                action.payload;
            state.hotelSmart.enableIntegration = enableIntegration;
            state.hotelSmart.username = username;
            state.hotelSmart.houseId = houseId;
            state.hotelSmart.password = password;
            state.hotelSmart.checkedInDate = checkedInDate;
        },

        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {},
});

export const { resetState, setHotelSmart } = integrationsSlice.actions;

export const integrationstStateSelector = (state: AppState) => {
    return state.integrations;
};

export default integrationsSlice.reducer;
