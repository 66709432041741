import { Button, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
    createReservation: () => void;
}

export const CreateBookingButton = ({ createReservation }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const bookingOptions: MenuProps['items'] = [
        {
            key: '0',
            label: t('roomBooking.schedule.createBookingOptions.generalBooking'),
        },
        {
            key: '1',
            label: t('roomBooking.schedule.createBookingOptions.groupBooking'),
        },
    ];

    const handleClickOptions: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case '0': {
                createReservation();
                break;
            }
            case '1': {
                navigate('/room-booking/group');
                break;
            }
            default:
                break;
        }
    };

    return (
        <Dropdown
            menu={{
                items: bookingOptions,
                onClick: handleClickOptions,
            }}
            trigger={['click']}
        >
            <Button type="primary" id={'btn-create-booking'}>
                <PlusOutlined size={11} />
                {t('roomBooking.schedule.createReservation')}
            </Button>
        </Dropdown>
    );
};
