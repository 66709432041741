const guestForm = {
    basicInfo: {
        contentTitle: 'お客様情報',
        attachmentTitle: '添付ファイル',
        fullName: {
            label: '氏名',
            placeholder: '例：山田　太郎',
        },
        yomigana: {
            label: '氏名（ヨミガナ）',
            placeholder: '例：ヤマダ　タロウ',
        },
        mobilePhoneNumber: {
            label: '電話番号',
            placeholder: '例: 070123000',
        },
        emailAddress: {
            label: 'メールアドレス',
            placeholder: '例: example@tabist.com',
        },
        birthday: {
            label: '生年月日',
            placeholder: '年/月/日',
        },
        gender: {
            label: '性別',
            placeholder: '性別を選択',
            male: '男性',
            female: '女性',
            other: 'その他',
        },
        postalCode: {
            label: '郵便番号',
            placeholder: '000000',
        },
        address: {
            label: '住所',
            placeholder: '東京都港区海岸一丁目7番1号',
        },
        country: {
            label: '国籍',
            placeholder: '国籍を選択',
        },
        classification: {
            label: '識別設定',
            vip: '上客',
            regular: '常連',
            beCareful: '要注意',
            clearBtn: '設定解除',
        },
        memo: {
            label: 'メモ',
            placeholder: 'その他必要な情報をご記入ください',
        },
        image: {
            title: 'ドラッグ＆ドロップで画像をアップロード',
            description: '最大アップロードファイルサイズ',
        },
    },
    companyInfo: {
        title: '会社情報',
        companyName: {
            label: '会社名',
            placeholder: '例: Tabist株式会社',
        },
        companyYomigana: {
            label: '会社名（ヨミガナ）',
            placeholder: '例：たびすとかぶしきかいしゃ',
        },
        companyPhoneNumber: {
            label: '電話番号',
            placeholder: '例: 090-0000-0000',
        },
        companyEmailAddress: {
            label: 'メールアドレス',
            placeholder: '例: taro.yamada@tabist.com',
        },
        companyPostalCode: {
            label: '郵便番号',
            placeholder: '000000',
        },
        companyAddress: {
            label: '住所',
            placeholder: '東京都港区海岸一丁目7番1号',
        },
        companyWebsite: {
            label: 'ウェブサイト',
            placeholder: '例 http://tabist.co.jp',
        },
    },
    addBtn: '新しいゲスト',
    cancelBtn: 'キャンセル',
    createBtn: '登録する',
    updateBtn: '保存',
    createSuccessMessage: 'ゲストが作成されました',
    updateSuccessMessage: 'ゲストが更新されました',
    confirm: { cancelTitle: 'キャンセルしてもよろしいですか？' },
    uploadFile: {
        message: {
            acceptTypeError: 'Only allow the following formats: {{format}}',
            maxCount: 'Maximum number of attachments per guest: {{maxCount}}',
            guestDataNotJSON: 'Guest data is not JSON type',
            guestDataRequire: 'Guest data is required field',
        },
    },
};

export default guestForm;
