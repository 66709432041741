import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import {
    breadcrumbs,
    DEFAULT_PAYMENT_METHOD_LIST_QUERY,
} from '~features/payment-method/constants';
import FilterForm from '~features/payment-method/components/FilterForm/FilterForm';
import './PaymentMethodPage.scss';
import PaymentMethodList from '~features/payment-method/components/PaymentMethodList/PaymentMethodList';
import PaymentMethodForm from '~features/payment-method/components/PaymentMethodForm/PaymentMethodForm';
import { useEffect } from 'react';
import { useAppDispatch } from '~hooks';
import { setPaymentMethodListQuery } from '~features/payment-method/reducers/paymentMethod.reducer';

function PaymentMethodPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(setPaymentMethodListQuery(DEFAULT_PAYMENT_METHOD_LIST_QUERY));
        };
    }, []);

    return (
        <div className="payment-method-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('paymentMethod.list.title')}
            />

            <Content>
                <div className="payment-method-content">
                    <FilterForm />
                    <PaymentMethodList />
                </div>
                <PaymentMethodForm />
            </Content>
        </div>
    );
}
export default PaymentMethodPage;
