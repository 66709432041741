import React from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';
import './ContactCard.scss';

export const ContactCard = () => {
    const { t } = useTranslation();
    const linkOpenGmail = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${t(
        'dashboard.contactCard.email',
    )}`;
    return (
        <div className="dashboard-contact-card-wrapper">
            <div className="card-title">
                <span className="mr-8">{t('dashboard.contactCard.title')}</span>
            </div>
            <div className="contact-card-content">
                <div className="contact-item">
                    <PhoneOutlined className="contact-item-icon" />
                    <div className="contact-phone text-truncate">
                        {t('dashboard.contactCard.phone')}
                    </div>
                </div>
                <div className="contact-item">
                    <MailOutlined className="contact-item-icon" />
                    <div className="contact-item-email">
                        <a href={linkOpenGmail} target="_blank" rel="noreferrer">
                            {t('dashboard.contactCard.email')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
