import axiosService from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import {
    IBookingHistoryExportCsvQuery,
    IBookingHistoryQuery,
    IClassificationListResponse,
    ICountryListForDropdownResponse,
    ICountryListResponse,
    IFacilityBookingHistoryResponse,
    IGuest,
    IGuestBulkDeleteItem,
    IGuestDetail,
    IGuestExportCsvQuery,
    IGuestForm,
    IGuestGetDropDownListQuery,
    IGuestListResponse,
    IRoomBookingHistoryResponse,
} from '../interfaces';

import {
    IBodyResponse,
    ICommonListQuery,
    ICsvFile,
    IGetListResponse,
} from '~common/interfaces';

class GuestService extends ApiService {
    getList(queryString: ICommonListQuery) {
        return this._getList(queryString) as Promise<IGuestListResponse>;
    }

    getListForDropdown(query: IGuestGetDropDownListQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params: { ...query },
        }) as Promise<IBodyResponse<IGetListResponse<any>>>;
    }

    getCountryListForDropdown() {
        return this._get('/country/dropdown') as Promise<ICountryListForDropdownResponse>;
    }

    create(createBody: IGuestForm | FormData) {
        return this._create(createBody) as Promise<IBodyResponse<IGuest>>;
    }

    update(id: number, updateBody: IGuestForm | FormData) {
        return this._update(id, updateBody) as Promise<IBodyResponse<IGuest>>;
    }

    exportCsv(query: IGuestExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }
    getDetail(id: number) {
        return this._getDetail(id) as Promise<IBodyResponse<IGuestDetail>>;
    }

    getCountryList() {
        return this._get('/country/dropdown') as Promise<ICountryListResponse>;
    }

    getRoomBookingHistoryData(id: number, query: IBookingHistoryQuery) {
        return this._get(`${this.baseUrl}/${id}/room-booking-history`, {
            params: { ...query },
        }) as Promise<IRoomBookingHistoryResponse>;
    }

    getFacilityBookingHistoryData(id: number, query: IBookingHistoryQuery) {
        return this._get(`${this.baseUrl}/${id}/facility-booking-history`, {
            params: {
                ...query,
            },
        }) as Promise<IFacilityBookingHistoryResponse>;
    }

    exportCsvRoomBookingHistory(id: number, query: IBookingHistoryExportCsvQuery) {
        return this._post(
            `${this.baseUrl}/${id}/export-room-booking-history`,
            query,
        ) as Promise<IBodyResponse<ICsvFile>>;
    }

    exportCsvFacilityBookingHistory(id: number, query: IBookingHistoryExportCsvQuery) {
        return this._post(
            `${this.baseUrl}/${id}/export-facility-booking-history`,
            query,
        ) as Promise<IBodyResponse<ICsvFile>>;
    }

    getClassificationList() {
        return this._get('/guest-classification') as Promise<IClassificationListResponse>;
    }

    bulkDelete(bulkDeleteBody: IGuestBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    getCountStayedGuest(guestId: number) {
        return this._get(`${this.baseUrl}/guest-stay-count`, {
            params: { guestId },
        }) as Promise<IBodyResponse<number>>;
    }

    getPresignedUrl(key: string) {
        return this._get(`${this.baseUrl}/files/presigned-url`, {
            params: key,
        }) as Promise<IBodyResponse<string>>;
    }
}
export const guestService = new GuestService({ baseUrl: '/guest' }, axiosService);
