const hotelSettingForm = {
    title: 'ホテル情報',
    breadcrumbs: {
        home: 'ホーム',
        hotel: 'フロント設定',
        hotelSetting: 'ホテル情報',
    },
    cancelBtn: 'キャンセル',
    submitBtn: '保存',
    editButton: '編集',
    hotelName: 'ホテル名',
    createdAt: '作成日',
    picName: '担当者名',
    picNameInput: '例：山田太郎',
    postalCode: '郵便番号',
    inputPostalCode: '1057510',
    address: '住所',
    inputAddress: '東京都港区海岸1-7-1 東京ポートシティ竹芝 WeWork11F',
    picPhoneNumber: '連絡先電話番号',
    picPhoneNumberInput: '例：0701234567',
    picEmailAddress: '連絡先メールアドレス',
    picEmailAddressInput: '例：taroyamada@example.com',
    numberOfRooms: '部屋数',
    inputRoomsNumber: '例：10000',
    standardCheckIn: '標準チェックイン',
    checkIn: '時間を選択',
    standardCheckOut: '標準チェックアウト',
    checkOut: '時間を選択',
    registeredNumber: '登録番号',
    isRegisteredNumberNotObtained: '登録番号を有していません',
    companyName: '会社名',
    updateSuccess: 'ホテルが更新されました',
    updateError: 'ホテルの更新がされませんでした',
    confirm: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
        cancelTitle: 'キャンセルしてもよろしいですか？',
    },
    bankAccount: {
        title: '銀行口座',
        titleAdd: '銀行口座を追加する',
        titleEdit: '銀行口座を編集する',
        titleDelete: 'この銀行口座を削除しますか？',
        btnAdd: '銀行口座',
        btnDelete: '銀行口座を削除する',
        bankName: '金融機関名',
        branchName: '支店名',
        accountType: '口座種別',
        saving: '普通',
        checking: '当座',
        accountNumber: '口座番号',
        accountOwner: '口座名義人名',
        save: '保存',
        cancel: 'キャンセル',
        noData: 'データなし',
        notification: {
            successCreate: '銀行口座が作成されました',
            successUpdate: '銀行口座が更新されました',
            successDelete: '銀行口座が削除されました',
        },
    },
};
export default hotelSettingForm;
