import i18next from '~plugins/i18next/i18n';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';

export enum OrderBy {
    ID = 'id',
    NAME = 'name',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    CHANNEL_GROUP_NAME = 'marketingChannelGroupName',
}

export const enum MarketingChannelColumn {
    ID = 'autoGeneratedCode',
    NAME = 'name',
    GROUP = 'marketingChannelGroupName',
    LINKAGE_CODE = 'linkageCode',
}

export const initMarketingChannelListQuery = {
    keyword: '',
    name: '',
    autoGeneratedCode: '',
    marketingChannelGroupName: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const enum MarketingChannelTableColumn {
    ID = 'id',
    NAME = 'name',
    CHANNEL_GROUP = 'marketingChannelGroupName',
    LINKAGE_CODE = 'linkageCode',
    LINKAGE_CODE_DAY_USE = 'linkageCodeDayUse',
    ACTION = 'action',
}

export const breadcrumbs = () => [
    {
        text: i18next.t('marketingChannel.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('marketingChannel.list.breadcrumbs.marketingChannel'),
    },
    {
        text: i18next.t('marketingChannel.list.breadcrumbs.marketingChannelSetting'),
    },
];

export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    GROUP = 'marketingChannelGroupName',
    NAME = 'name',
    LINKAGE_CODE = 'linkageCode',
}

export const FILE_NAME = 'marketing_channel.csv';

// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'marketing-channel';
export const exportColumns = [
    'autoGeneratedCode',
    'marketingChannelGroupName',
    'name',
    'linkageCode',
];
export const i18ExportKey = 'marketingChannel.list';

export const MAX_MARKETING_CHANNELS = 99;
export const MAX_MARKETING_CHANNEL_NAME = 30;
export const MAX_LINKAGE_CODE = 10;
export const MAX_LINKAGE_CODE_DAY_USE = 10;
