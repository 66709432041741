const paymentSummaryJa = {
    title: '入金明細表',
    breadcrumbs: {
        report: '帳票',
        paymentSummary: '入金明細表',
    },
    filterForm: {
        aggregationMethod: {
            label: '集計方法',
            daily: '日別',
            month: '月間',
        },
        dateLabel: '集計日',
        monthLabel: '集計月',
        filterButton: '表示',
    },
    table: {
        paymentMethod: '科目',
        monthlyAccumulative: '月間累計',
    },
    total: '合計',
    unit: '¥',
};

export default paymentSummaryJa;
