import { UploadOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { compact, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { INPUT_TEXT_MAX_LENGTH, Regex } from '~common/constants';
import { ImportCsvError, InputText } from '~components';
import { ImportSaleGroupColumn } from '~features/sale-group/constants';
import { IImportError, IImportSaleGroup } from '~features/sale-group/interfaces';
import {
    saleGroupSelector,
    setHasImportError,
} from '~features/sale-group/reducers/sale-group.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './ImportCsvSaleGroup.scss';

interface IProps {
    responseError: Record<number, any>;
}

function ImportSaleGroup({ responseError }: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { importCsvFileName, importSaleGroupList, hasImportError } =
        useAppSelector(saleGroupSelector);
    const { control } = useForm({});
    const [errors, setErrors] = useState<Record<number, any>>({});
    const importSaleGroupColumn: ColumnsType<IImportSaleGroup> = [
        {
            title: t('saleGroup.list.saleGroupListColumn.num'),
            width: '60px',
            key: '#',
            onCell: () => {
                return {
                    style: { backgroundColor: '#E6F6F9' },
                };
            },
            render: (saleGroup: IImportSaleGroup) => {
                return <div>{saleGroup.order}</div>;
            },
        },
        {
            title: t('saleGroup.list.saleGroupListColumn.saleGroupName'),
            key: ImportSaleGroupColumn.NAME,
            render: (saleGroup: IImportSaleGroup) => {
                return <div className="text-truncate">{saleGroup.name}</div>;
            },
            onCell: (saleGroup: IImportSaleGroup) => {
                if (errors[saleGroup.order]?.name) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
        },
        {
            title: t('saleGroup.list.saleGroupListColumn.saleItemName'),
            width: '50%',
            dataIndex: '',
            key: ImportSaleGroupColumn.SALE_ITEM,
            render: (saleGroup: IImportSaleGroup) => {
                if (saleGroup?.saleItems && saleGroup?.saleItems?.length > 0) {
                    return (
                        <>
                            <ul className="sale-item-list">
                                {saleGroup?.saleItems.map((item, index) => (
                                    <li key={index} className="text-truncate">
                                        {item || 'N/A'}
                                    </li>
                                ))}
                            </ul>
                        </>
                    );
                }
                return (
                    <>
                        <div></div>
                    </>
                );
            },
            onCell: (saleGroup: IImportSaleGroup) => {
                if (errors[saleGroup.order]?.saleItems) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
            ellipsis: true,
        },
    ];

    useEffect(() => {
        setErrors(responseError);
    }, [responseError]);

    useEffect(() => {
        validateData();
    }, []);

    const validateData = () => {
        let hasError = false;
        let errors = {};
        importSaleGroupList.forEach((importSaleGroup) => {
            if (importSaleGroup.order !== undefined) {
                const nameError = importSaleGroup.isDuplicated
                    ? t('saleGroup.import.error.name.uniqueError')
                    : validateName(importSaleGroup.name, importSaleGroup.order);

                const saleItemsError = validateSaleItems(
                    importSaleGroup.saleItems || ([] as string[]),
                );
                errors = {
                    ...errors,
                    [importSaleGroup.order]: {
                        name: nameError,
                        saleItems: saleItemsError,
                    },
                };

                if (nameError || saleItemsError) {
                    hasError = true;
                }
            }
            delete importSaleGroup.saleItem;
        });
        dispatch(setHasImportError(hasError));
        setErrors(errors);
    };

    const validateName = (name: string, order: number) => {
        if (!name) {
            return t('saleGroup.import.error.name.required');
        }
        if (name?.length > INPUT_TEXT_MAX_LENGTH) {
            return t('saleGroup.import.error.name.maxLength', {
                max: INPUT_TEXT_MAX_LENGTH,
            });
        }
        if (
            importSaleGroupList.some(
                (saleGroup) => saleGroup.name === name && saleGroup.order !== order,
            )
        ) {
            return t('saleGroup.import.error.name.uniqueError');
        }
        if (name.match(Regex.NAME_SPECIAL_CHARACTERS)) {
            return t('saleGroup.import.error.name.hasNoSpecialCharacters');
        }
        return '';
    };

    const validateSaleItems = (saleItems?: string[]) => {
        if (
            !saleItems?.length ||
            !compact(saleItems)?.length ||
            saleItems?.length !== compact(saleItems)?.length
        ) {
            return t('saleGroup.import.error.saleItems.required');
        }

        if (
            compact(saleItems)?.some((saleItem) => {
                return saleItem.length > INPUT_TEXT_MAX_LENGTH;
            })
        ) {
            return t('saleGroup.import.error.saleItems.maxLength', {
                max: INPUT_TEXT_MAX_LENGTH,
            });
        }

        if (saleItems?.length !== uniq(saleItems)?.length) {
            return t('saleGroup.import.error.saleItems.duplicate');
        }

        if (
            compact(saleItems)?.some((saleItem) => {
                return saleItem.match(Regex.NAME_SPECIAL_CHARACTERS);
            })
        ) {
            return t('saleGroup.import.error.saleItems.hasNoSpecialCharacters');
        }
        return '';
    };

    const errorList = useMemo(() => {
        const _errorItems: IImportError[] = [];
        Object.keys(errors)?.forEach((order) => {
            Object.keys(errors[+order])?.forEach((key) => {
                if (errors[+order]?.[key]) {
                    _errorItems.push({
                        order: +order,
                        key,
                        message: errors[+order]?.[key],
                    });
                }
            });
        });
        return _errorItems;
    }, [errors]);

    return (
        <div className="import-csv-sale-group-content">
            <div className="import-csv-sale-group-file-name-wrapper">
                <InputText
                    label={t('common.importCsv.title')}
                    name="title"
                    control={control}
                    required
                    onKeyUp={(e) => {
                        e.preventDefault();
                    }}
                    disabled
                />
                <div className="file-name">
                    <UploadOutlined />
                    <span className="file-name-content">{importCsvFileName}</span>
                </div>
            </div>
            {hasImportError && (
                <ImportCsvError
                    errors={errorList}
                    i18nErrorKey={'saleGroup.import.error.key'}
                />
            )}

            {importSaleGroupList?.length !== 0 && (
                <div className="import-csv-sale-group-total-data">
                    {t('common.importCsv.numData', {
                        totalData: importSaleGroupList?.length,
                    })}
                </div>
            )}

            <Table
                columns={importSaleGroupColumn}
                dataSource={importSaleGroupList}
                pagination={false}
                rowKey="id"
            />
        </div>
    );
}

export default ImportSaleGroup;
