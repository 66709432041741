import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customDayjs from '~plugins/dayjs';
import { AppState } from '~plugins/redux-toolkit/store';
import { IPrintRoomCleaningState } from '../interfaces';
import { roomCleaningService } from '../services/room-cleaning.services';
import { IPrintRoomCleaningListQuery } from './../interfaces';

const initialState: IPrintRoomCleaningState = {
    printRoomCleaningList: [],
    printRoomCleaningListQuery: {
        printDateTime: customDayjs().fmYYYYMMDDHHmmss(),
    },
    isPrintRoomCleaningListLoading: false,
    isShowRoomCleaningPrintingModal: false,
};

export const fetchPrintRoomCleaningList = createAsyncThunk(
    'print-room-cleaning',
    async (query: IPrintRoomCleaningListQuery) => {
        return await roomCleaningService.getPrintRoomCleaningData(query);
    },
);

export const printCleaningSlice = createSlice({
    name: 'print-room-cleaning',
    initialState,
    reducers: {
        setPrintingRoomCleaningList: (state, action) => {
            state.printRoomCleaningList = action.payload;
        },
        setPrintRoomCleaningListQuery: (state, action) => {
            state.printRoomCleaningListQuery = action.payload;
        },
        setIsShowRoomCleaningPrintingModal: (state, action) => {
            state.isShowRoomCleaningPrintingModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPrintRoomCleaningList.pending, (state, action) => {
            state.isPrintRoomCleaningListLoading = true;
        });
        builder.addCase(fetchPrintRoomCleaningList.fulfilled, (state, action) => {
            state.isPrintRoomCleaningListLoading = false;
            state.printRoomCleaningList = action.payload?.data || [];
        });
    },
});

export const {
    setPrintingRoomCleaningList,
    setPrintRoomCleaningListQuery,
    setIsShowRoomCleaningPrintingModal,
} = printCleaningSlice.actions;

export const printRoomCleaningListSelector = (state: AppState) => {
    return state.printRoomCleaning.printRoomCleaningList;
};

export const printRoomCleaningListQuerySelector = (state: AppState) => {
    return state.printRoomCleaning.printRoomCleaningListQuery;
};

export const isPrintRoomCleaningListLoadingSelector = (state: AppState) => {
    return state.printRoomCleaning.isPrintRoomCleaningListLoading;
};

export const isShowRoomCleaningPrintingModalSelector = (state: AppState) => {
    return state.printRoomCleaning.isShowRoomCleaningPrintingModal;
};

export default printCleaningSlice.reducer;
