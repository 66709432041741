import React from 'react';
import { IMarketingChannelBulkCreateFormItem } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import { marketingChannelGroupDropdownOptionsSelector } from '~features/marketing-channel-group/marketing-channel-group.reducer';
import {
    createMarketingChannelListSelector,
    setCreateMarketingChannelList,
} from '../../marketing-channel.reducer';
import {
    MarketingChannelTableColumn,
    MAX_MARKETING_CHANNELS,
} from '~features/marketing-channel/constants';
import { InputText, SingleSelect } from '~components';
import './CreateFormMarketingChannelList.scss';
import { getFormMarketingChannelId } from '~features/marketing-channel/helpers';

interface IProp {
    control: any;
    currentMarketingChannelList: IMarketingChannelBulkCreateFormItem[];
}
function CreateFormMarketingChannelList(props: IProp) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const createMarketingChannelList = useAppSelector(createMarketingChannelListSelector);
    const marketingChannelOptions = useAppSelector(
        marketingChannelGroupDropdownOptionsSelector,
    );

    const onClickButtonAdd = () => {
        dispatch(
            setCreateMarketingChannelList([
                ...props.currentMarketingChannelList,
                {
                    name: '',
                    marketingChannelGroupId: null,
                    linkageCode: '',
                    linkageCodeDayUse: '',
                    feId: `${Date.now()}_${createMarketingChannelList.length}`,
                },
            ]),
        );
    };

    const onClickButtonRemove = (channel: IMarketingChannelBulkCreateFormItem) => {
        dispatch(
            setCreateMarketingChannelList(
                props.currentMarketingChannelList.filter(
                    (marketingChannel) => marketingChannel.feId !== channel.feId,
                ),
            ),
        );
    };

    return (
        <div className="marketing-channels-form-marketing-channel-list-wrapper">
            <Form layout="vertical">
                <Table
                    dataSource={createMarketingChannelList}
                    pagination={false}
                    scroll={{ y: 500 }}
                    locale={{
                        emptyText: <div></div>,
                    }}
                    rowKey="feId"
                    footer={() => (
                        <Button
                            type="dashed"
                            disabled={
                                createMarketingChannelList.length >=
                                MAX_MARKETING_CHANNELS
                            }
                            onClick={onClickButtonAdd}
                            className="button-add"
                            id={getFormMarketingChannelId('add-marketing-channel')}
                        >
                            <PlusCircleOutlined className="right-register-form-icon" />
                            <span>
                                {t(
                                    'marketingChannel.form.createForm.addMarketingChannel',
                                )}
                            </span>
                        </Button>
                    )}
                >
                    <Column
                        align="left"
                        width={50}
                        title={`${t('marketingChannel.form.createForm.id')}`}
                        dataIndex=""
                        key={MarketingChannelTableColumn.ID}
                        render={(
                            channel: IMarketingChannelBulkCreateFormItem,
                            item,
                            index,
                        ) => {
                            return <div>{index + 1}</div>;
                        }}
                    />
                    <Column
                        title={`${t(
                            'marketingChannel.form.createForm.marketingChannelName',
                        )}`}
                        width={'20%'}
                        dataIndex=""
                        key={MarketingChannelTableColumn.NAME}
                        render={(
                            channel: IMarketingChannelBulkCreateFormItem,
                            item,
                            index,
                        ) => {
                            return (
                                <InputText
                                    label=""
                                    placeholder={t(
                                        'marketingChannel.form.createForm.placeholder.marketingChannelName',
                                    )}
                                    name={`items.${index}.name`}
                                    id={getFormMarketingChannelId(`items.${index}.name`)}
                                    control={props.control}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t(
                            'marketingChannel.form.createForm.marketingChannelGroupName',
                        )}`}
                        width={'25%'}
                        dataIndex=""
                        key={MarketingChannelTableColumn.CHANNEL_GROUP}
                        render={(
                            channel: IMarketingChannelBulkCreateFormItem,
                            item,
                            index,
                        ) => {
                            return (
                                <SingleSelect
                                    label=""
                                    placeholder={t(
                                        'marketingChannel.form.createForm.placeholder.marketingChannelGroupName',
                                    )}
                                    name={`items.${index}.marketingChannelGroupId`}
                                    id={getFormMarketingChannelId(
                                        `items.${index}.marketingChannelGroupId`,
                                    )}
                                    showSearch={false}
                                    control={props.control}
                                    options={marketingChannelOptions}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('marketingChannel.form.createForm.linkageCode')}`}
                        dataIndex=""
                        key={MarketingChannelTableColumn.LINKAGE_CODE}
                        render={(
                            channel: IMarketingChannelBulkCreateFormItem,
                            item,
                            index,
                        ) => {
                            return (
                                <InputText
                                    label=""
                                    placeholder={t(
                                        'marketingChannel.form.createForm.placeholder.linkageCode',
                                    )}
                                    id={getFormMarketingChannelId(
                                        `items.${index}.linkageCode`,
                                    )}
                                    name={`items.${index}.linkageCode`}
                                    control={props.control}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t(
                            'marketingChannel.form.createForm.linkageCodeDayUse',
                        )}`}
                        width={'25%'}
                        dataIndex=""
                        key={MarketingChannelTableColumn.LINKAGE_CODE_DAY_USE}
                        render={(
                            channel: IMarketingChannelBulkCreateFormItem,
                            item,
                            index,
                        ) => {
                            return (
                                <InputText
                                    label=""
                                    placeholder={t(
                                        'marketingChannel.form.createForm.placeholder.linkageCodeDayUse',
                                    )}
                                    id={getFormMarketingChannelId(
                                        `items.${index}.linkageCodeDayUse`,
                                    )}
                                    name={`items.${index}.linkageCodeDayUse`}
                                    control={props.control}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('marketingChannel.form.createForm.delete')}`}
                        align="center"
                        width={60}
                        dataIndex=""
                        key={MarketingChannelTableColumn.ACTION}
                        render={(channel: IMarketingChannelBulkCreateFormItem) => {
                            return (
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    onClick={() => onClickButtonRemove(channel)}
                                />
                            );
                        }}
                    />
                </Table>
            </Form>
        </div>
    );
}

export default CreateFormMarketingChannelList;
