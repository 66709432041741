import { Button, Popover } from 'antd';
import { useRef } from 'react';
import UpdateFacilityBookingForm from '~features/facility-booking/components/UpdateFacilityBookingForm/UpdateFacilityBookingForm';
import { IFacilityBooking } from '~features/facility-booking/interfaces';
import './UpdateBookingFormPopup.scss';
import Card from 'antd/lib/card/Card';
import { useTranslation } from 'react-i18next';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    isCalculatingAmountSelector,
    showUpdateFacilityBookingLoadingSelector,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { updateBooking } from '~features/facility-booking/reducers/facility-schedule.reducer';
import { useEscape } from '~common/useHooks';
import { parseDate } from '~plugins/dayjs';

type Props = {
    booking: IFacilityBooking;
    isOpen: boolean;
    onClose?: () => void;
    placement?: TooltipPlacement;
};

function UpdateBookingFormPopup(props: Props) {
    const { booking, isOpen, placement = 'left', onClose } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formBusy = useAppSelector(showUpdateFacilityBookingLoadingSelector);
    const isCalculatingAmount = useAppSelector(isCalculatingAmountSelector);
    const bookingFormRef = useRef<{
        onSubmit: Function;
        onCloseForm: Function;
    }>(null);
    const onCloseForm = () => {
        onClose?.();
    };

    const submit = () => {
        bookingFormRef.current?.onSubmit();
    };
    const closeForm = () => {
        bookingFormRef.current?.onCloseForm(false, false);
        onClose?.();
    };
    useEscape(closeForm);
    const updateBookingSuccess = (item?: IFacilityBooking) => {
        onClose?.();
        if (!item) return;
        const start = parseDate(item.startDatetime);
        const end = parseDate(item.endDatetime);
        dispatch(
            updateBooking({
                booking: {
                    id: item.id,
                    guest: item.guest,
                    facilityId: item.facility?.id,
                    facility: {
                        id: item.facility?.id,
                        name: item.facility?.name,
                    },
                    facilityKey: `facility-${item.facility?.id}`,
                    checkInTimeWeekView: start.clone()?.fmHHmm(),
                    checkInTime: start.clone()?.fmHHmm(),
                    checkOutTime: end.clone()?.fmHHmm(),
                    checkOutTimeWeekView: end.clone()?.fmHHmm(),
                    checkInDateTime: item.startDatetime,
                    checkOutDateTime: item.endDatetime,
                    status: item.status,
                    numberOfGuests: item.numberOfGuests,
                    roomBookingId: item.roomBookingId,
                    date: '',
                    rooms: item.roomOfBookings || [],
                    totalAmount: item.totalAmount,
                    facilityTypeId: item.facilityType?.id,
                },
            }),
        );
    };
    const renderContent = () => {
        return (
            <Card
                className="update-booking-form-card"
                actions={[
                    <Button onClick={closeForm}>{t('common.buttonCancelText')}</Button>,
                    <Button
                        onClick={submit}
                        className="btn-submit"
                        type="primary"
                        loading={formBusy || isCalculatingAmount}
                    >
                        {t('common.buttonSaveText')}
                    </Button>,
                ]}
            >
                <UpdateFacilityBookingForm
                    booking={booking}
                    isShowForm={isOpen}
                    ref={bookingFormRef}
                    onUpdateSuccess={updateBookingSuccess}
                />
            </Card>
        );
    };
    return (
        <Popover
            content={renderContent()}
            trigger="click"
            open={isOpen}
            overlayClassName="update-facility-booking-form-pop-up"
            onOpenChange={onCloseForm}
            placement={placement}
        />
    );
}

export default UpdateBookingFormPopup;
