import { errorMessagesGroup, errorMessagesSeparated } from './constants';
import { IStatusItems } from './interfaces';

export const getErrorMessagesList = (errorItems: IStatusItems[]) => {
    const errorMessagesList: string[] = [];
    let errorMessage: string;
    Object.entries(errorMessagesGroup).forEach(([key, value]) => {
        errorItems.forEach((item) => {
            if (item.message.includes(key)) {
                const errorValues = item.message.split(': ');
                if (key === 'BOOKING_ALREADY_EXISTS') {
                    errorMessage = `${item.originalGroupId}: ${value}: ${item.autoGeneratedCode}`;
                } else {
                    errorMessage = `${item.originalGroupId}: ${value}: ${errorValues[1]}`;
                }
                errorMessagesList.push(errorMessage);
            }
        });
    });
    return errorMessagesList;
};

/** Delete if not needed */
export const getParsingErrorMessage = (errorMessage: string, isJP = true): string[] => {
    const errorMessageList: string[] = errorMessage.split(',');
    if (!isJP) {
        return errorMessageList;
    }
    const parseKeys = Object.keys(errorMessagesGroup);
    const errorMessageTrimmed = errorMessageList.map((error) => error.trim());
    return errorMessageList;
};

export const getErrorMessage = (errorMessage: string) => {
    let errorTextList: string[] = [];
    if (errorMessage.includes(': ')) {
        const splitMessage = errorMessage.split(': ');
        const errorMessageList = splitMessage[1].split(', ');
        Object.entries(errorMessagesSeparated).forEach(([key, value]) => {
            errorMessageList.forEach((errorMessage) => {
                if (errorMessage.includes(key)) {
                    errorTextList.push(value);
                }
            });
        });
    } else {
        Object.entries(errorMessagesGroup).forEach(([key, value]) => {
            if (errorMessage.includes(key)) {
                errorTextList.push(value);
            }
        });
    }
    return errorTextList;
};
