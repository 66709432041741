const marketingChannelList = {
    title: 'Marketing channel setting',
    breadcrumbs: {
        home: 'Home',
        marketingChannel: 'Marketing channel',
        marketingChannelSetting: 'Marketing channel setting',
    },
    channelColumn: {
        id: 'ID',
        marketingChannelGroupName: 'Marketing channel group name',
        marketingChannelName: 'Marketing channel name',
        linkageCode: 'Linkage code',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Marketing channel name',
        marketingChannelGroupName: 'Marketing channel group name',
        linkageCode: 'Linkage code',
    },
    searchBox: 'Search',
    message: {
        deleteMarketingChannelsSuccess:
            'Marketing Channels have been deleted successfully',
    },
    exportColumns: {
        name: 'Marketing channel name',
        autoGeneratedCode: 'ID',
        marketingChannelGroupName: 'Marketing channel group name',
        linkageCode: 'Linkage code',
        linkageCodeDayUse: 'Linkage code (day-use)',
    },
    initData: {
        cantDelete: `Can't delete marketing channel item`,
        cantEdit: `Can't edit marketing channel item`,
    },
};

export default marketingChannelList;
