import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { ISmtpSettingState, ISmtpSettingItem } from '../interfaces';
import { smtpService } from '../services/smtp-setting.service';

const initialState: ISmtpSettingState = {
    smtpSetting: [],
    showLoading: false,
};

export const getSmtpSetting = createAsyncThunk(
    'smtp-setting/get-smtp-setting',
    async () => {
        return await smtpService.getSmtpSetting();
    },
);

export const updateSmtpSetting = createAsyncThunk(
    'smtp-setting/update-smtp-setting',
    async (formData: ISmtpSettingItem[]) => {
        return await smtpService.updateSmtpSetting(formData);
    },
);

export const smtpSettingSlice = createSlice({
    name: 'smtpSetting',
    initialState,
    reducers: {
        setSmtpSetting: (state, action) => {
            state.smtpSetting = action.payload;
        },
        setShowLoading: (state, action) => {
            state.showLoading = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getSmtpSetting.fulfilled, (state, action) => {
            state.showLoading = false;
            state.smtpSetting = action.payload?.data || [];
        });
        builder.addCase(getSmtpSetting.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(updateSmtpSetting.fulfilled, (state, action) => {
            state.showLoading = false;
        });
        builder.addCase(updateSmtpSetting.pending, (state, action) => {
            state.showLoading = true;
        });
    },
});

export const { setSmtpSetting, setShowLoading } = smtpSettingSlice.actions;

export const smtpSettingSelector = (state: AppState) => {
    return state.smtpSetting.smtpSetting;
};

export const showLoadingSelector = (state: AppState) => {
    return state.smtpSetting.showLoading;
};

export default smtpSettingSlice.reducer;
