import ReactGA from 'react-ga';
import { GA_EVENT, GA_KEYWORD_CONTENT } from '~common/constants';
import { analytics } from './service';

export const trackSearchKeyword = (keyword: string, contentType: GA_KEYWORD_CONTENT) => {
    // GA v4
    analytics.track(GA_EVENT.SEARCH_BY_KEYWORD, {
        category: `search_${contentType}_by_keyword`,
        action: GA_EVENT.SEARCH_BY_KEYWORD,
        label: keyword,
        value: 1,
    });

    // GA v3
    ReactGA.event({
        category: `search_${contentType}_by_keyword`,
        action: GA_EVENT.SEARCH_BY_KEYWORD,
        label: keyword,
        value: 1,
    });
};
