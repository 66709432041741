import { Layout, Progress, Spin, Typography } from 'antd';
import { cloneDeep } from 'lodash';
import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrintingFonts, PrintingOrientation, PrintingPaperSize } from '~common/constants';
import { showConfirm } from '~common/notification';
import { PdfPrintingModal } from '~components';
import { roomBookingStateSelector } from '~features/room-booking/reducers/room-booking.reducer';
import {
    setIsShowSplitRoomBookingReceiptPrintingModal,
    splitReceiptStateSelector,
} from '~features/room-booking/reducers/split-receipt.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { generateSplitRoomBookingReceiptPdf } from './generateSplitRoomBookingReceiptContent';
import './SplitRoomBookingReceiptPrintingModal.scss';
import { currentLanguageSelector } from '~common/reducers/app.reducer';
import {
    ICreateReceiptFileBody,
    IReceiptByGroup,
} from '~features/room-booking/interfaces';
import { FILE_TYPE, INVOICE_PATH, ReceiptItemType } from '~features/room-booking/constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { receiptFileService } from '~features/room-booking/services/receipt-file.service';
import { CacheKeys } from '~queries/queries';
import { getAutoGeneratedCode } from '~features/room-booking/helper';
import { hotelService } from '~features/hotel/services/hotel.api';
import { useBankAccounts } from '~features/hotel/hooks/useBankAccounts';
import localStorageAuthService from '~common/authStorage';

const { Text } = Typography;

export default function SplitRoomBookingReceiptPrintingModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currentLang = useAppSelector(currentLanguageSelector);
    const { selectedRoomBookingDetail } = useAppSelector(roomBookingStateSelector);
    const { pathname } = useLocation();
    const isInvoice = pathname.endsWith(INVOICE_PATH);

    const { isShowSplitRoomBookingReceiptPrintingModal, printingReceiptByGroupList } =
        useAppSelector(splitReceiptStateSelector);
    const [totalSales, setTotalSales] = useState<number[]>();

    const [url, setUrl] = useState<string>();
    const [pdf, setPdf] = useState<TCreatedPdf>();
    const [pdfs, setPdfs] = useState<TCreatedPdf[]>();
    const [progress, setProgress] = useState<number | undefined>(undefined);

    const [orientation, setOrientation] = useState<PrintingOrientation>(
        PrintingOrientation.PORTRAIT,
    );
    const [paperSize, setPaperSize] = useState<PrintingPaperSize>(PrintingPaperSize.A4);
    const [totalPage, setTotalPage] = useState(0);
    const { data: bankAccounts } = useBankAccounts();

    const totalPaidAmount = useMemo(() => {
        const total: Record<string, number> = {};
        if (printingReceiptByGroupList.length) {
            printingReceiptByGroupList.forEach((printingReceipt) => {
                printingReceipt.receipt.roomBooking.roomBookingItems.forEach(
                    (roomBookingItem) => {
                        roomBookingItem?.receiptItems?.forEach((receiptItem) => {
                            if (
                                receiptItem.type === ReceiptItemType.PAYMENT &&
                                receiptItem.paymentMethod
                            ) {
                                const receiptItemUnpaidAmount =
                                    receiptItem.receiptItemDetails.reduce(
                                        (result, receiptItemDetail) =>
                                            result + receiptItemDetail.amount,
                                        0,
                                    );
                                if (total[printingReceipt!.group!.id]) {
                                    total[printingReceipt!.group!.id] +=
                                        receiptItemUnpaidAmount;
                                } else {
                                    total[printingReceipt!.group!.id] =
                                        receiptItemUnpaidAmount;
                                }
                            }
                        });
                    },
                );
            });
        }
        return total;
    }, [printingReceiptByGroupList]);

    const onPrint = useCallback(() => {
        cloneDeep(pdf)?.print();
    }, [pdf]);

    const onChangeOrientation = (orientation: PrintingOrientation) => {
        setUrl('');
        setOrientation(orientation);
    };

    const onChangePaperSize = (paperSize: PrintingPaperSize) => {
        setUrl('');
        setPaperSize(paperSize);
    };

    const updateTotalSales = (totalPayments: number[]) => {
        setTotalSales(totalPayments);
    };

    const hotelId = localStorageAuthService.getHotelId();
    const hotelStored = localStorageAuthService.getSelectedHotel();

    const { data: hotel, isRefetching: isRefetchingHotel } = useQuery({
        queryKey: [CacheKeys.hotel, hotelId],
        queryFn: async () => {
            try {
                const hotel = await hotelService.getDetail(Number(hotelId));
                return hotel.data;
            } catch (error) {
                return hotelStored;
            }
        },
        placeholderData: hotelStored ? hotelStored : undefined,
        staleTime: 1 * 1000,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    });

    const preparePdf = useCallback(
        (item: IReceiptByGroup[], payment?: boolean) => {
            return generateSplitRoomBookingReceiptPdf(
                item,
                selectedRoomBookingDetail!,
                { orientation, setTotalPage, paperSize },
                currentLang,
                totalPaidAmount,
                hotel,
                payment ? updateTotalSales : undefined,
                isInvoice,
                bankAccounts || [],
            );
        },
        [
            currentLang,
            orientation,
            paperSize,
            selectedRoomBookingDetail,
            totalPaidAmount,
            hotel,
            bankAccounts,
        ],
    );

    useEffect(() => {
        setUrl('');
        if (selectedRoomBookingDetail && !isRefetchingHotel) {
            pdfMake.fonts = PrintingFonts;
            setPdf(pdfMake.createPdf(preparePdf(printingReceiptByGroupList, true)));
        }
    }, [
        printingReceiptByGroupList,
        orientation,
        paperSize,
        selectedRoomBookingDetail,
        isRefetchingHotel,
        bankAccounts,
    ]);

    useEffect(() => {
        if (
            totalSales?.length &&
            printingReceiptByGroupList.length &&
            !pdfs &&
            selectedRoomBookingDetail &&
            !isRefetchingHotel
        ) {
            const receiptsDocument = printingReceiptByGroupList.map((receiptPrint) => {
                return pdfMake.createPdf(preparePdf([receiptPrint]));
            });
            setPdfs(receiptsDocument);
        }
    }, [
        totalSales,
        printingReceiptByGroupList,
        selectedRoomBookingDetail,
        isRefetchingHotel,
    ]);

    useEffect(() => {
        cloneDeep(pdf)?.getDataUrl((dataUrl: string) => {
            setUrl(dataUrl);
        });
    }, [pdf]);

    const removeProgress = () => {
        setTimeout(() => {
            setProgress(undefined);
        }, 5000);
    };

    const queryClient = useQueryClient();
    const receiptFiles = useMutation({
        mutationFn: async ({ id, body }: { id: number; body: FormData }) => {
            const res = await receiptFileService.createReceiptFiles(
                id,
                body,
                setProgress,
            );
            if (res.success) return res.data;
            throw Error(res.message);
        },
        onMutate: () => {
            setProgress(0);
        },
        onError: () => {
            removeProgress();
        },
        onSuccess: () => {
            removeProgress();

            const receiptId = printingReceiptByGroupList[0].receipt.id;
            queryClient.invalidateQueries({
                queryKey: [CacheKeys.receiptFilesCount, receiptId],
            });
            queryClient.invalidateQueries({
                queryKey: [CacheKeys.receiptFiles, receiptId],
            });
        },
    });

    useEffect(() => {
        if (pdfs) {
            const receiptId = printingReceiptByGroupList[0].receipt.id;
            const body: ICreateReceiptFileBody[] = printingReceiptByGroupList.map(
                (data, i) => ({
                    number: `${isInvoice ? 'IV' : 'RC'}-${getAutoGeneratedCode(
                        data.receipt.roomBooking.autoGeneratedCode,
                    )}-${data.logNumber}`,
                    name:
                        data.guestName ||
                        data.receipt.roomBooking.representativeGuest?.yomigana,
                    total: totalSales?.[i] ?? 0,
                    type: isInvoice ? FILE_TYPE.INVOICE : FILE_TYPE.RECEIPT,
                }),
            );

            const bodyFormData = new FormData();
            bodyFormData.append('receiptData', JSON.stringify({ items: body }));

            let pdfsFinished = 0;
            pdfs.forEach((p, i) => {
                p.getBlob((file) => {
                    bodyFormData.append(`files`, file, `${body[i].number}.pdf`);
                    pdfsFinished++;
                    if (pdfsFinished === printingReceiptByGroupList.length) {
                        receiptFiles.mutate({ id: receiptId, body: bodyFormData });
                    }
                });
            });
        }
    }, [pdfs]);

    const onClose = () => {
        showConfirm({
            title: t('roomBooking.printing.cancelTitle'),
            onOk() {
                dispatch(setIsShowSplitRoomBookingReceiptPrintingModal(false));
            },
        });
        setTotalSales(undefined);
        setPdfs(undefined);
    };

    return (
        <PdfPrintingModal
            totalPage={totalPage}
            onPrint={onPrint}
            onClose={onClose}
            onChangeOrientation={onChangeOrientation}
            onChangePaperSize={onChangePaperSize}
            isShowPdfPrinting={isShowSplitRoomBookingReceiptPrintingModal}
        >
            <Layout className="split-room-booking-receipt-printing-layout">
                <Layout.Content className="split-room-booking-receipt-printing-content">
                    {url ? (
                        <iframe
                            title="split-receipt-pdf"
                            className="pdf-view"
                            src={`${url}#toolbar=0`}
                        />
                    ) : (
                        <Spin />
                    )}
                </Layout.Content>
                <div className="split-room-booking-receipt-printing-upload-status">
                    {!!progress && (
                        <div>
                            <Progress
                                percent={progress}
                                status={
                                    receiptFiles.isPending
                                        ? 'active'
                                        : receiptFiles.error
                                        ? 'exception'
                                        : undefined
                                }
                            />
                            {receiptFiles.isPending && (
                                <Text type="secondary">
                                    {t('roomBooking.splitReceipt.files.uploadLoading')}
                                </Text>
                            )}
                            {receiptFiles.isError && (
                                <Text type="danger">
                                    {t('roomBooking.splitReceipt.files.uploadError')}
                                </Text>
                            )}
                            {receiptFiles.isSuccess && (
                                <Text type="success">
                                    {t('roomBooking.splitReceipt.files.uploadSuccess')}
                                </Text>
                            )}
                        </div>
                    )}
                </div>
            </Layout>
        </PdfPrintingModal>
    );
}
