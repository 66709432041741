import { Form, Select, Button, notification, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import './ActionForm.scss';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    getRankCalendarListForDropDown,
    rankCalendarListForDropDownSelector,
    selectedRankCalendarDetailSelector,
} from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { IRankCalendarForDropDown } from '~features/rank-calendar/interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalConfirmDeletion } from '~components';
import { rankCalendarService } from '~features/rank-calendar/services/rank-calendar.service';
import { showConfirm } from '~common/notification';
import { Status } from '~features/rank-calendar/constants';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup } from '~common/constants';

function ActionForm() {
    const { Option } = Select;
    const { rankCalendarId } = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const rankCalendarList = useAppSelector(rankCalendarListForDropDownSelector);
    const rankCalendarDetail = useAppSelector(selectedRankCalendarDetailSelector);
    const [isActive, setIsActive] = useState(false);
    const isReadOnly = !checkUserPermission([...AdminGroup]);

    useEffect(() => {
        dispatch(getRankCalendarListForDropDown({}));
    }, []);

    useEffect(() => {
        const isActive = rankCalendarDetail?.status === Status.ACTIVE;
        setIsActive(isActive);
    }, [rankCalendarDetail]);

    const handleChange = (value: number) => {
        if (typeof value === 'number') {
            navigate(`/front-settings/rank-calendar/${value}/detail`);
        }
    };

    const onConfirmDeletion = async () => {
        if (isActive) {
            notification.error({
                message: t('rankCalendar.list.message.cannotDeleteRankCalendar'),
            });
            return;
        }
        if (rankCalendarDetail?.isSeedData) {
            notification.error({
                message: t('rankCalendar.list.message.cannotDeleteInitRankCalendar'),
            });
            return;
        }
        const response = await rankCalendarService.bulkDelete({
            ids: [Number(rankCalendarId)],
        });
        if (response.success) {
            notification.success({
                message: t('rankCalendar.list.message.deleteRankCalendarsSuccess'),
            });
            navigate('/front-settings/rank-calendar');
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const showConfirmDeleteDialog = () => {
        ModalConfirmDeletion({
            title: t('rankCalendar.detail.modalConfirm.title'),
            description: t('rankCalendar.detail.modalConfirm.description'),
            buttonCancelText: t('rankCalendar.detail.modalConfirm.cancelBtn'),
            buttonDeleteText: t('rankCalendar.detail.modalConfirm.deleteBtn'),
            deletedItems: [],
            okButtonProps: { danger: true },
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const onClickButtonEdit = () => {
        navigate(`/front-settings/rank-calendar/${rankCalendarId}/update`);
    };

    const onConfirmSetActive = async () => {
        const response = await rankCalendarService.updateStatus(Number(rankCalendarId), {
            status: Status.ACTIVE,
        });

        setIsActive(response.success);
    };

    const showConfirmSetActiveDialog = () => {
        showConfirm({
            title: t('rankCalendar.detail.modalSetActive.title'),
            content: t('rankCalendar.detail.modalSetActive.description'),
            cancelText: t('rankCalendar.detail.modalSetActive.cancelBtn'),
            okText: t('rankCalendar.detail.modalSetActive.deleteBtn'),
            onOk: onConfirmSetActive,
        });
    };

    return (
        <div className="rank-calendar-action-form-wrapper">
            <div className="rank-calendar-action-form-left">
                <Form.Item label={t('rankCalendar.detail.actionForm.label')}>
                    <Select
                        defaultValue={Number(rankCalendarId)}
                        onChange={handleChange}
                        popupMatchSelectWidth={false}
                        dropdownStyle={{
                            maxWidth: '400px',
                        }}
                        className={'rank-calendar-select'}
                    >
                        {rankCalendarList.map(
                            (rankCalendar: IRankCalendarForDropDown) => {
                                return (
                                    <Option value={rankCalendar.id} key={rankCalendar.id}>
                                        {rankCalendar.name}
                                    </Option>
                                );
                            },
                        )}
                    </Select>
                </Form.Item>
                {isActive && (
                    <Tag color={'green'}>{t(`rankCalendar.form.status.active`)}</Tag>
                )}
            </div>
            {!isReadOnly && (
                <div className="rank-calendar-action-form-right">
                    <Button onClick={showConfirmDeleteDialog}>
                        <DeleteOutlined />
                        <span>{t('rankCalendar.detail.actionForm.deleteBtn')}</span>
                    </Button>
                    <Button onClick={onClickButtonEdit}>
                        <EditOutlined />
                        <span>{t('rankCalendar.detail.actionForm.editBtn')}</span>
                    </Button>
                    <Button
                        type="primary"
                        onClick={showConfirmSetActiveDialog}
                        disabled={isActive}
                        className={isActive ? 'disabled-btn' : ''}
                    >
                        {t('rankCalendar.detail.actionForm.activeBtn')}
                    </Button>
                </div>
            )}
        </div>
    );
}

export default ActionForm;
