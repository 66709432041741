import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './CalendarHeader.scss';

interface Iprops {
    rankCalendarMonth: number;
    rankCalendarYear: number;
    onClickButtonPrevious: () => void;
    onClickButtonNext: () => void;
}
function CalendarHeader({
    rankCalendarMonth,
    rankCalendarYear,
    onClickButtonPrevious,
    onClickButtonNext,
}: Iprops) {
    const { t } = useTranslation();
    return (
        <div className="rank-calendar-header">
            <p className="rank-calendar-year">{rankCalendarYear}</p>
            <div className="rank-calendar-month-wrapper">
                <LeftOutlined
                    className="rank-calendar-icon"
                    onClick={onClickButtonPrevious}
                />
                <p className="rank-calendar-month">
                    {rankCalendarMonth}
                    <span>{t('rankCalendar.detail.calendarHeader.monthText')}</span>
                </p>
                <RightOutlined
                    className="rank-calendar-icon"
                    onClick={onClickButtonNext}
                />
            </div>
        </div>
    );
}

export default CalendarHeader;
