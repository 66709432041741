export const fieldsEn = {
    relatedRoomTypeId: 'Room type ID (abb.)',
    standardCapacity: 'Standard capacity',
    abbreviation: 'Abbreviation',
    connectingNumber: 'Connecting number',
    name: 'Name',
    quantity: 'Quantity',
    initialRoomName: 'Starting room number',
    roomType: 'Room type',
    basicUsageHours: 'Basic using hours',
    businessTimes: 'Business using hours',
    roomTypeId: 'Room type',
    email: 'Email',
    password: 'Password',
    marketingChannelGroupId: 'Marketing channel group name',
    linkageCode: 'Linkage code',
    linkageCodeDayUse: 'Linkage code (Day-use)',
    facilityTypeId: 'Facility type',
    initialFacilityName: 'Facility name',
    basePriceTaxIncluded: 'Base price',
    guestMobilePhoneNumber: 'Phone number',
    guestPhoneNumber: 'Phone number',
    timeUnit: 'Time unit',
    numberOfGuests: 'Number of guests',
    bookingTimes: 'Booking time',
    mobilePhoneNumber: 'Phone number',
    facilityId: 'Facility',
    guestId: 'Yomigana',
    planId: 'Plan',
    representativeGuestId: 'Representative guest',
    plan: 'Plan',
    numberOfAdults: 'Number of adults',
    childrenCount: 'Number of children',
    stayingDate: 'Staying date',
    time: 'Staying time',
    yomigana: 'Yomigana',
    fullName: 'Full name',
    phone: 'Phone',
    birthday: 'Birthday',
    gender: 'Gender',
    marketingChannelId: 'Marketing channel',
    price: 'Price',
    roomTypeIds: 'Room type',
    emailAddress: 'Email',
    tllRank: 'Tll rank',
    taxIdPlan: 'Tax percentage',
    saleItemId: 'Sale item',
    checkInOutTimes: 'Check In Time | Check Out Time',
    unitPrice: 'Price',
    paymentRoomBookingItem: 'Paying room',
    paymentRoomBookingItemId: 'Paying room ID',
    paymentMethodId: 'Payment method',
    roomId: 'Room',
    paymentAmount: 'Amount',
    roomIds: 'Rooms',
    rangeDay: 'Duration day',
    title: 'Title',
    notifyAt: 'Delivery date',
    tags: 'Tag',
    hotelIds: 'Hotel',
    content: 'Detail',
    amount: 'Amount',
    children: 'Children',
    planGroupCode: 'Plan group code',
    serverName: 'Server name',
    smtpServer: 'SMTP server name',
    smtpEncryption: 'SMTP encryption',
    smtpPort: 'SSMTP port',
    userName: 'User name',
    connectionSecurity: 'Security connection',
    remarks: 'Remark',
    roomTypeCode: 'Room type code',
    bookingDate: 'Booking Date',
    classificationId: 'Classification Setting',
    paymentMethodIds: 'Payment methods',
    memo: 'Remark',
    otaMemo: 'OTA note',
    reservationDuration: 'Reservation duration',
    numberOfRooms: 'Number of rooms',
    houseId: 'House ID',
    username: 'User ID',
    checkedInDate: 'Checked-in date',
    code: 'Code',
    keySystemId: 'System ID',
    bankName: 'Bank name',
    branchName: 'Branch name',
    accountType: 'Account type',
    accountNumber: 'Account number',
    accountOwner: 'Account owner',
    picEmailAddress: 'Email',
};
