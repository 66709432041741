import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, notification } from 'antd';
import { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { compareFormData } from '~common/commonFunctions';
import { showConfirm } from '~common/notification';
import { RightDrawerLayout } from '~components';
import { getStartDateAndEndDateByDay } from '~features/inventory/helper';
import { IBulkSaveStoppingRoomCountForm } from '~features/inventory/interfaces';
import {
    bulkSaveStoppingRoomCount,
    getInitStoppingRoomTypeList,
    getInventoryListData,
    getRoomTypeList,
    isShowStoppingRoomCountFormSelector,
    roomTypeListSelector,
    setIsEditMode,
    setIsShowStoppingRoomCountForm,
    stoppingInventoryListSelector,
} from '~features/inventory/reducers/inventory.reducer';
import { bulkSaveStoppingRoomCountSchema } from '~features/inventory/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import './StoppingRoomCountForm.scss';
import StoppingRoomCountTable from './StoppingRoomCountTable/StoppingRoomCountTable';

function StoppingRoomCountForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowStoppingRoomCountForm = useAppSelector(
        isShowStoppingRoomCountFormSelector,
    );
    const roomTypeList = useAppSelector(roomTypeListSelector);
    const stoppingInventoryList = useAppSelector(stoppingInventoryListSelector);
    const { control, handleSubmit, reset, getValues } = useForm({
        resolver: yupResolver(bulkSaveStoppingRoomCountSchema),
    });

    const oldFormData = useRef<
        Record<string, { stoppingRoomCount: number; totalRooms: number }>
    >({});

    useEffect(() => {
        if (isShowStoppingRoomCountForm) {
            dispatch(
                getRoomTypeList({
                    isConnectingRoomType: false,
                    isPullFromTll: false,
                }),
            );
            dispatch(
                getInitStoppingRoomTypeList({ date: customDayjs().fmYYYYMMDDHHmmss() }),
            );
        }
    }, [isShowStoppingRoomCountForm, dispatch]);

    useEffect(() => {
        const formData: Record<
            string,
            { stoppingRoomCount: number; totalRooms: number }
        > = {};
        roomTypeList.forEach((roomType) => {
            Object.assign(formData, {
                [`roomType-${roomType.id}`]: {
                    stoppingRoomCount:
                        stoppingInventoryList?.find(
                            (inventory) => inventory?.roomTypeId === roomType.id,
                        )?.stoppingRoomCount || 0,
                    totalRooms: roomType.totalRooms,
                },
            });
        });
        reset(formData);
        oldFormData.current = { ...formData };
    }, [roomTypeList, stoppingInventoryList, reset]);

    const onCancel = (reload?: boolean) => {
        if (reload) {
            dispatch(getInventoryListData());
        }
        dispatch(setIsShowStoppingRoomCountForm(false));
        reset();
        oldFormData.current = {};
    };

    const showConfirmCancel = () => {
        if (compareFormData(oldFormData.current, getValues())) {
            onCancel();
            return;
        }
        showConfirm({
            title: t('inventory.stoppingRoomCountForm.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    const _bulkSaveStoppingRoomCount = async (body: IBulkSaveStoppingRoomCountForm[]) => {
        if (!body.length) return;
        const response = await dispatch(bulkSaveStoppingRoomCount(body));
        if (bulkSaveStoppingRoomCount.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t(
                        'inventory.stoppingRoomCountForm.saveStoppingRoomCountSuccess',
                    ),
                });
                onCancel(true);
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };

    const onSubmit = () => {
        handleSubmit((data) => {
            const formData: IBulkSaveStoppingRoomCountForm[] = [];
            Object.keys(data).forEach((key) => {
                const roomTypeId = +key.split('-')[1];
                const { startDate } = getStartDateAndEndDateByDay(
                    customDayjs().fmYYYYMMDD(),
                    null,
                );
                formData.push({
                    startDate,
                    endDate: null,
                    roomTypeId: roomTypeId,
                    stoppingRoomCount: Number(data[`${key}`].stoppingRoomCount) || 0,
                });
            });
            _bulkSaveStoppingRoomCount(formData);
        })();
    };

    const goToEditModeScreen = () => {
        onCancel();
        dispatch(setIsEditMode(true));
    };

    return (
        <RightDrawerLayout
            open={isShowStoppingRoomCountForm}
            onClose={showConfirmCancel}
            onSubmit={onSubmit}
            title={t('inventory.stoppingRoomCountForm.title')}
            cancelText={t('inventory.stoppingRoomCountForm.cancelButton')}
            submitText={t('inventory.stoppingRoomCountForm.submitButton')}
            className="inventory-form"
            submitId="inventory-form-submit"
            disabledSaveButton={!roomTypeList.length}
        >
            <Alert
                message={t('inventory.stoppingRoomCountForm.alert.title')}
                description={t('inventory.stoppingRoomCountForm.alert.description')}
                showIcon
                type="info"
                action={
                    <Button
                        size="small"
                        onClick={goToEditModeScreen}
                        disabled={!roomTypeList.length}
                    >
                        {t('inventory.stoppingRoomCountForm.alert.button')}
                    </Button>
                }
                className="inventory-form-alert"
            />
            <StoppingRoomCountTable control={control} roomTypeList={roomTypeList} />
        </RightDrawerLayout>
    );
}

export default memo(StoppingRoomCountForm);
