const notificationList = {
    titlePage: 'お知らせ設定',
    breadcrumbs: {
        setting: '設定',
        notification: 'お知らせ設定',
    },
    searchBox: '検索',
    primaryButton: 'お知らせの作成',
    notificationListColumn: {
        id: 'ID',
        hotelDesignation: 'ホテル指定',
        title: 'タイトル',
        type: 'タイプ',
    },
    messageDeleteSuccess: 'お知らせ設定が削除されました',
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
    },
};

export default notificationList;
