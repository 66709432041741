import { Tabs } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReceiptByGroupMapIndex } from '~features/room-booking/constants';
import { IGroup } from '~features/room-booking/interfaces';
import { splitReceiptStateSelector } from '~features/room-booking/reducers/split-receipt.reducer';
import { useAppSelector } from '~hooks';
import ReceiptDetailItem from '../../ReceiptDetailItem/ReceiptDetailItem';
import './ReceiptDetailTab.scss';

interface ITab {
    label: string;
    key: string;
    children: JSX.Element;
}

function ReceiptDetailTab() {
    const { t } = useTranslation();

    const { receiptByGroupMap } = useAppSelector(splitReceiptStateSelector);

    const groupList = useMemo((): IGroup[] => {
        return Object.keys(receiptByGroupMap).reduce(
            (groupList: IGroup[], groupId: string) => {
                if (groupId === ReceiptByGroupMapIndex.ALL) return groupList;

                const { group } = receiptByGroupMap[groupId];
                if (group) groupList.push(group);
                return groupList;
            },
            [],
        );
    }, [receiptByGroupMap]);

    const receiptDetailTabs = useMemo((): ITab[] => {
        const receiptDetailAllTab = {
            label: t('roomBooking.splitReceipt.all'),
            key: '',
            children: (
                <ReceiptDetailItem
                    group={null}
                    groupList={groupList}
                    receipt={receiptByGroupMap[ReceiptByGroupMapIndex.ALL]?.receipt}
                />
            ),
        };

        const receiptDetailRoomTabs = Object.keys(receiptByGroupMap).reduce(
            (receiptDetailRoomTabs: ITab[], roomId: string) => {
                if (roomId === ReceiptByGroupMapIndex.ALL) return receiptDetailRoomTabs;

                const { group: room, receipt } = receiptByGroupMap[roomId];
                return receiptDetailRoomTabs.concat({
                    label: `${t('roomBooking.splitReceipt.room')}   ${room?.name}`,
                    key: room?.name || '',
                    children: (
                        <ReceiptDetailItem
                            group={{
                                id: room?.id?.toString() || '',
                                name: room?.name,
                            }}
                            groupList={groupList}
                            receipt={receipt}
                        />
                    ),
                });
            },
            [],
        );

        return [receiptDetailAllTab, ...receiptDetailRoomTabs];
    }, [receiptByGroupMap]);

    return (
        <div className="receipt-detail-tab-wrapper">
            <div className="receipt-detail-tab-header">
                {t('roomBooking.splitReceipt.receiptDetailTab.title')}
            </div>

            <Tabs type="card" items={receiptDetailTabs} className="tab-list" />
        </div>
    );
}

export default ReceiptDetailTab;
