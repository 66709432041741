import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchField } from '~features/bo-operation-history/constants';
import './SelectField.scss';

const { Option } = Select;

interface IProps {
    onChange: (columnName: SearchField) => void;
}

function SelectField(props: IProps) {
    const { onChange } = props;
    const { t } = useTranslation();

    return (
        <Select
            defaultValue={SearchField.CREATED_BY_EMAIL}
            onChange={onChange}
            className="bo-history-select-search"
        >
            {Object.values(SearchField).map((columnName, index) => {
                return (
                    <Option value={columnName} key={index}>
                        {t(`boHistory.list.searchColumns.${columnName}`)}
                    </Option>
                );
            })}
        </Select>
    );
}

export default SelectField;
