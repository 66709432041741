import { Button, Select } from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat, DatePickerType } from '~common/constants';
import { SingleDatePicker } from '~components';
import dayjs, { Dayjs, todayDayjs } from '~plugins/dayjs';
import './FilterForm.scss';
import { exportCSVReport } from '~features/report/helper';
import {
    ReportTableCsvFileName,
    ReportTableId,
    TaxTypes,
    taxTypeDropdownOptions,
} from '~features/report/constants';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { localTaxFilterSchema } from '~features/report/schema';
import customDayjs from '~plugins/dayjs';
import {
    TaxDetailPageContext,
    TaxDetailPageDispatchContext,
} from '../../context/TaxDetailPageContext';
import * as types from '../../context/actionTypes';

interface IFilterForm {
    showLocalTaxDetailTable: boolean;
    setShowLocalTaxDetailTable: (value: boolean) => void;
    setSelectedTaxTypeState: (value: string) => void;
    selectedTaxTypeState: string;
}

function FilterForm(props: IFilterForm) {
    const {
        showLocalTaxDetailTable,
        setShowLocalTaxDetailTable,
        setSelectedTaxTypeState,
        selectedTaxTypeState,
    } = props;
    const { t } = useTranslation();
    const { selectedMonth, isTableLoading } = useContext(TaxDetailPageContext);
    const contextDispatch = useContext(TaxDetailPageDispatchContext);
    const { control, setValue } = useForm({
        resolver: yupResolver(localTaxFilterSchema),
    });

    useEffect(() => {
        setSelectedTaxTypeState(TaxTypes.TOKYO_TAX);
        contextDispatch({
            type: types.SET_SELECTED_MONTH,
            payload: todayDayjs.fmYYYYMMDD(),
        });
        setValue(`day`, customDayjs());
        return () => {
            setShowLocalTaxDetailTable(false);
        };
    }, []);

    const changeMonth = (date: Dayjs) => {
        contextDispatch({ type: types.SET_SELECTED_MONTH, payload: date.fmYYYYMMDD() });
    };

    const onClickButtonFilter = () => {
        setSelectedTaxTypeState(selectedTaxTypeState);
        if (!showLocalTaxDetailTable) {
            setShowLocalTaxDetailTable(true);
        }
    };

    const exportCSV = () => {
        let tableId = '';
        let fileName = '';
        switch (selectedTaxTypeState) {
            case TaxTypes.TOKYO_TAX:
                tableId = ReportTableId.LOCAL_TAX;
                fileName = ReportTableCsvFileName.LOCAL_TAX;
                break;
            case TaxTypes.KUTCHAN_TAX:
                tableId = ReportTableId.KUTCHAN_LOCAL_TAX;
                fileName = ReportTableCsvFileName.KUTCHAN_LOCAL_TAX;
                break;
            default:
                break;
        }
        exportCSVReport(tableId, `${fileName}.csv`);
    };

    const onChangeTaxType = (value: string) => {
        setSelectedTaxTypeState(value);
    };

    return (
        <div className="report-local-tax-detail-filter-form-wrapper">
            <div className="filter-form-content">
                <span>{t('report.localTax.filterForm.dateLabel')}</span>
                <SingleDatePicker
                    value={dayjs(selectedMonth)}
                    format={DateFormat.YYYY_MM_HYPHEN}
                    allowClear={false}
                    onChange={changeMonth}
                    picker={DatePickerType.MONTH}
                    placeholder={t('report.placeholder.selectMonth')}
                    name={'day'}
                    control={control}
                />
                <span>{t('report.localTax.filterForm.taxTypeLabel')}:</span>
                <Select
                    options={taxTypeDropdownOptions()}
                    showSearch
                    value={selectedTaxTypeState}
                    className="tax-type-select"
                    popupMatchSelectWidth
                    onChange={onChangeTaxType}
                    getPopupContainer={(triggerNode) =>
                        triggerNode?.parentNode || document.body
                    }
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                />
                <Button
                    type="primary"
                    onClick={onClickButtonFilter}
                    loading={isTableLoading}
                >
                    {t('report.localTax.filterForm.filterButton')}
                </Button>
            </div>
            {showLocalTaxDetailTable && (
                <Button onClick={exportCSV} loading={isTableLoading}>
                    {t('report.exportCSV')}
                </Button>
            )}
        </div>
    );
}

export default FilterForm;
