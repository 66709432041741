const bookingForm = {
    title: 'Booking details',
    reservationForm: {
        title: 'Temporary booking',
        cancelBtn: 'Back',
        nextBtn: 'Next',
        addBtn: 'Add booking',
        fixedPrice: 'Fixed price',
        guestName: 'Guest name (representative)',
        salesChannel: 'Marketing channel',
        salesChannelPlaceholder: 'Select marketing channel',
        stayTime: 'Duration of stay',
        checkInTime: 'Check in',

        roomInfo: {
            title: 'Room',
            roomType: 'Room type',
            planType: 'Plan type',
            adultPerson: 'Adults',
            childPerson: 'Children',
        },
    },
    scheduleBooking: {
        createTitle: 'Create new booking',
        updateTitle: 'Edit booking',
        plan: 'Plan',
        adult: 'Adults',
        child: 'Children',
        lengthOfStay: 'Duration of stay',
        dayUse: 'Day-use',
        roomType: 'Room type',
        roomName: 'Room name',
        submitButton: 'Create',
        caveButton: 'Update',
        subTotalTitle: 'Subtotal (¥)',
    },
    placeholder: {
        phone: 'Ex: 0801239090',
        name: 'Ex: Yamada Tarou',
        nameYomigana: 'Ex: Yamada Tarou',
        email: 'Ex: yamada@example.com',
        saleChannel: 'Select marketing channel',
        lengthOfStay: {
            start: 'Start date',
            end: 'End date',
        },
        checkInTime: 'Check in time',
        checkOutTime: 'Check out time',
        roomName: 'Select room',
        planName: 'Select plan',
        roomType: 'Select room type',
        adults: '0',
        birthday: 'Date of birth',
        gender: 'Select gender',
        roomId: 'Select room name',
        startDateOfStay: 'Start date',
        endDateOfStay: 'End date',
    },
    message: {
        requiredAdult: 'Adults is required',
        exceedPeople: 'Exceed the allowed number of people',
        time24hPeriodError:
            'Please choose a time is period less than 24 hours and greater than 0',
        checkInTimeError: 'Check-in time is less than 2 days before',
        checkInDateTimeError:
            'Check-in date time is less or equal than check-out date time',
        datePeriodError: 'End staying date must be greater than start staying date',
        startDateError: 'Please choose start date after 2 days before',
        notCheckInOutTime:
            'Standard check in time and standard check out time are not set in Hotel settings',
        genderBreakdownError:
            'The total gender count must be equal to the number of guests in each gender',
        parentBookingItemShouldComeFirstError:
            'Parent booking item stay date always should come first',
        parentChildrenBookingItemsOverlappingError:
            'There is overlappin in stay date in parent and children booking items',
    },
    popupCancel: {
        cancelTitle: 'Do you want to cancel?',
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
    },
    planInvalidPrice:
        'The stay could not be created because there is no charge set for the selected plan and number of people.',
    uploadFile: {
        message: {
            acceptTypeError: 'Only allow the following formats: {{format}}',
            maxCount: 'Maximum number of attachments per guest: {{maxCount}}',
            roomBookingDataNotJSON: 'Room booking data is not JSON type',
            roomBookingDataRequire: 'Room booking data is required field',
        },
    },
    overbooking: {
        title: 'Seems like there is not enough inventory and creating this booking will result in overbooking. Are you sure to proceed?',
        warningResponse: 'There is no available room',
        warningTLLResponse: 'This room from TLL is temporarily not available',
    },
};

export default bookingForm;
