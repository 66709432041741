import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row, notification } from 'antd';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compareFormData, validateUniqueValue } from '~common/commonFunctions';
import { ErrorMessageType } from '~common/constants';
import { showConfirm } from '~common/notification';
import { useEscape } from '~common/useHooks';
import { InputText, RightDrawerLayout, SingleSelect } from '~components';
import {
    getMarketingChannelGroupListForDropdown,
    marketingChannelGroupDropdownOptionsSelector,
} from '~features/marketing-channel-group/marketing-channel-group.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import {
    IMarketingChannel,
    IMarketingChannelBulkUpdateForm,
    IMarketingChannelBulkUpdateFormItem,
} from '../../interfaces';
import {
    bulkUpdateMarketingChannels,
    fetchMarketingChannelList,
    isShowUpdateMarketingChannelFormSelector,
    setIsShowUpdateMarketingChannelForm,
    updateMarketingChannelFormBusySelector,
    updateMarketingChannelListSelector,
} from '../../marketing-channel.reducer';
import { bulkUpdateMarketingChannelsSchema } from '../../schema';
import './UpdateMarketingChannelsForm.scss';

function UpdateMarketingChannelsForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, handleSubmit, reset, setError, getValues } =
        useForm<IMarketingChannelBulkUpdateForm>({
            resolver: yupResolver(bulkUpdateMarketingChannelsSchema),
            defaultValues: {},
        });
    const isShowForm = useAppSelector(isShowUpdateMarketingChannelFormSelector);
    const formBusy = useAppSelector(updateMarketingChannelFormBusySelector);
    const marketingChannelList = useAppSelector(updateMarketingChannelListSelector);
    const marketingChannelGroupOptions = useAppSelector(
        marketingChannelGroupDropdownOptionsSelector,
    );

    const closeDialog = () => {
        reset({});
        dispatch(setIsShowUpdateMarketingChannelForm(false));
    };

    const _showConfirm = () => {
        if (compareFormData(getValues().items, marketingChannelList)) {
            closeDialog();
            return;
        }
        showConfirm({
            title: t('marketingChannel.form.confirm.cancelTitle'),
            cancelText: t('marketingChannel.form.confirm.buttonCancelText'),
            okText: t('marketingChannel.form.confirm.buttonDeleteText'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    const _bulkUpdateMarketingChannels = useCallback(
        async (channels: IMarketingChannelBulkUpdateFormItem[]) => {
            const response = await dispatch(
                bulkUpdateMarketingChannels(
                    channels.map((channel, index) => ({
                        id: channel.id,
                        marketingChannelGroupId: channel.marketingChannelGroupId,
                        name: channel.name,
                        order: index + 1,
                        linkageCode: channel.linkageCode,
                    })),
                ),
            );
            if (bulkUpdateMarketingChannels.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t(
                            'marketingChannel.form.updateForm.updateSuccessMessage',
                        ),
                    });
                    dispatch(setIsShowUpdateMarketingChannelForm(false));
                    dispatch(fetchMarketingChannelList());
                } else {
                    (response.payload?.errors || []).forEach((error) => {
                        if (
                            (error?.key === 'name' ||
                                error?.key === 'marketingChannelGroupId' ||
                                error?.key === 'linkageCode' ||
                                error?.key === 'linkageCodeDayUse') &&
                            error?.order
                        ) {
                            setError(
                                `items.${error.order - 1}.${error.key}`,
                                { type: ErrorMessageType.MANUAL, message: error.message },
                                { shouldFocus: true },
                            );
                        }
                    });
                    notification.error({
                        message: response.payload?.message || '',
                    });
                }
            }
        },
        [],
    );

    const onSubmit = () => {
        handleSubmit(async (data) => {
            const updatedMarketingChannels = getValues().items || [];
            if (!updatedMarketingChannels.length) {
                return;
            }
            const duplicatedNameIndexes = validateUniqueValue(
                updatedMarketingChannels,
                'name',
            );
            if (duplicatedNameIndexes.length) {
                duplicatedNameIndexes.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t(
                                'marketingChannel.form.updateForm.marketingChannelNameDuplicated',
                            ),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            const duplicatedCodeIndexes = validateUniqueValue(
                updatedMarketingChannels,
                'linkageCode',
            );
            if (duplicatedCodeIndexes.length) {
                duplicatedCodeIndexes.forEach((index) => {
                    setError(
                        `items.${index}.linkageCode`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t(
                                'marketingChannel.form.createForm.marketingChannelCodeDuplicated',
                            ),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkUpdateMarketingChannels(data.items);
        })();
    };
    useEffect(() => {
        reset({
            items: marketingChannelList,
        });
    }, [marketingChannelList]);

    useEffect(() => {
        dispatch(getMarketingChannelGroupListForDropdown({})); //TODO: replace hotel id here
    }, []);

    const getMarketingChannelGroupOptions = (channel: IMarketingChannel) => {
        const findChannel = marketingChannelGroupOptions.find(
            (item) => item.value === channel.marketingChannelGroup?.id,
        );
        if (findChannel) return marketingChannelGroupOptions;

        return [
            ...marketingChannelGroupOptions,
            {
                value: channel.marketingChannelGroup?.id,
                label: channel.marketingChannelGroup?.name,
            },
        ];
    };

    return (
        <div className="update-marketing-channels-form-wrapper">
            <RightDrawerLayout
                open={isShowForm}
                onClose={_showConfirm}
                onSubmit={onSubmit}
                title={t('marketingChannel.form.updateForm.title')}
                cancelText={t('marketingChannel.form.updateForm.cancelBtn')}
                submitText={t('marketingChannel.form.updateForm.submitBtn')}
                busy={formBusy}
                loading={formBusy}
            >
                <Form layout="vertical" autoComplete="off" scrollToFirstError>
                    {marketingChannelList.map((channel, index) => {
                        return (
                            <Row key={channel.id} gutter={8}>
                                <Col span={4}>
                                    <InputText
                                        label={index === 0 ? 'ID' : ''}
                                        placeholder=""
                                        name={`items.${index}.autoGeneratedCode`}
                                        control={control}
                                        disabled={true}
                                    />
                                </Col>
                                <Col span={8}>
                                    <SingleSelect
                                        label={
                                            index === 0
                                                ? t(
                                                      'marketingChannel.form.updateForm.marketingChannelGroupName',
                                                  )
                                                : ''
                                        }
                                        placeholder={t(
                                            'marketingChannel.form.updateForm.marketingChannelGroupName',
                                        )}
                                        dropdownStyle={{
                                            minWidth: '240px',
                                        }}
                                        disabled={channel.isSeedData}
                                        name={`items.${index}.marketingChannelGroupId`}
                                        control={control}
                                        options={getMarketingChannelGroupOptions(channel)}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputText
                                        label={
                                            index === 0
                                                ? t(
                                                      'marketingChannel.form.updateForm.marketingChannelName',
                                                  )
                                                : ''
                                        }
                                        placeholder={t(
                                            'marketingChannel.form.updateForm.marketingChannelName',
                                        )}
                                        name={`items.${index}.name`}
                                        control={control}
                                        disabled={channel.isSeedData}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputText
                                        label={
                                            index === 0
                                                ? t(
                                                      'marketingChannel.form.updateForm.linkageCode',
                                                  )
                                                : ''
                                        }
                                        placeholder=""
                                        name={`items.${index}.linkageCode`}
                                        control={control}
                                        disabled={channel.isSeedData}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </Form>
            </RightDrawerLayout>
        </div>
    );
}

export default UpdateMarketingChannelsForm;
