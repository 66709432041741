import { INPUT_TEXT_MAX_LENGTH, Regex } from '~common/constants';
import yup from '~plugins/yup';
import {
    MAX_LINKAGE_CODE,
    MAX_LINKAGE_CODE_DAY_USE,
    MAX_MARKETING_CHANNELS,
} from './constants';

export const settingSchema = yup.object().shape({
    quantity: yup
        .string()
        .minFullAndHalfWidth(1)
        .maxFullAndHalfWidth(MAX_MARKETING_CHANNELS)
        .required(),
    marketingChannelGroupId: yup.number().required(),
});

export const bulkCreateMarketingChannelsSchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                name: yup
                    .string()
                    .trim()
                    .hasNoSpecialCharacters(
                        Regex.MARKETING_CHANNEL_NAME_SPECIAL_CHARACTERS,
                    )
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .required(),
                marketingChannelGroupId: yup.number().required().min(1),
                linkageCode: yup
                    .string()
                    .trim()
                    .hasNoSpecialCharacters()
                    .max(MAX_LINKAGE_CODE)
                    .required(),
                linkageCodeDayUse: yup
                    .string()
                    .trim()
                    .hasNoSpecialCharacters()
                    .max(MAX_LINKAGE_CODE_DAY_USE)
                    .optional(),
            })
            .required(),
    ),
});

export const bulkUpdateMarketingChannelsSchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                name: yup
                    .string()
                    .trim()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .hasNoSpecialCharacters(
                        Regex.MARKETING_CHANNEL_NAME_SPECIAL_CHARACTERS,
                    )
                    .required(),
                marketingChannelGroupId: yup.number().required().min(1),
                linkageCode: yup
                    .string()
                    .trim()
                    .hasNoSpecialCharacters()
                    .max(MAX_LINKAGE_CODE)
                    .required(),
            })
            .required(),
    ),
});
