import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showConfirm } from '~common/notification';
import { createBookingStateSelector } from '~features/room-booking/reducers/create-booking.reducer';
import { useAppSelector } from '~hooks';

type Props = {
    errors: any;
    isValid: boolean;
    onSubmit: () => void;
};
function CreateBookingFooter(props: Props) {
    const { isValid, errors, onSubmit } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isCheckingPlan } = useAppSelector(createBookingStateSelector);

    const goBack = () => {
        showConfirm({
            title: t('roomBooking.createBooking.backConfirmDialog.title'),
            onOk() {
                navigate('/room-booking/schedule');
            },
        });
    };
    return (
        <Space className="d-flex j-end">
            <Button onClick={goBack}>{t('roomBooking.createBooking.cancel')}</Button>
            <Button
                type="primary"
                disabled={!isValid || Object.values(errors).length > 0 || isCheckingPlan}
                onClick={onSubmit}
                id="createBooking-btn-submit"
            >
                {t('roomBooking.createBooking.next')}
            </Button>
        </Space>
    );
}
export default CreateBookingFooter;
