const rankCalendarDetail = {
    title: 'ランクカレンダー詳細',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        rankCalendar: 'ランクカレンダー設定',
        rankCalendarDetail: 'ランクカレンダー詳細',
    },
    detaiViewTitle: '月別価格表示',
    calendarHeader: {
        monthText: '月',
    },
    actionForm: {
        label: 'カレンダー名',
        deleteBtn: '削除',
        editBtn: '編集',
        activeBtn: 'カレンダーを有効にする',
    },
    modalConfirm: {
        title: 'カレンダーの削除',
        description: '全ての情報が失われます。よろしいでしょうか？',
        cancelBtn: 'キャンセル',
        deleteBtn: '削除',
    },
    modalSetActive: {
        title: '有効カレンダーの変更',
        description: '「基本カレンダー」を有効にします。よろしいでしょうか？',
        cancelBtn: 'キャンセル',
        deleteBtn: '有効する',
    },
    calendarDay: {
        monday: '月',
        tuesday: '火',
        wednesday: '水',
        thursday: '木',
        friday: '金',
        saturday: '土',
        sunday: '日',
    },
};

export default rankCalendarDetail;
