import { CSSProperties, FC } from 'react';
import { useDrop } from 'react-dnd';
import { IFacilityBookingScheduleItem } from '~features/facility-booking/interfaces';

const style: CSSProperties = {
    height: '100%',
    position: 'absolute',
    zIndex: 2,
};

type Props = {
    booking: IFacilityBookingScheduleItem;
    facilityId: number;
    left: number;
    top: number;
    width: number;
};

function selectBackgroundColor(isActive: boolean) {
    if (isActive) {
        return '#E8ECF3';
    } else {
        return 'rgb(255, 235, 230)';
    }
}

export const DropBox: FC<Props> = ({ booking, facilityId, left, top, width }) => {
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: 'box',
            drop: () => ({
                facilityId,
            }),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [booking],
    );
    const backgroundColor = selectBackgroundColor(isOver);
    return (
        <div
            ref={drop}
            style={{
                ...style,
                backgroundColor,
                left: left,
                top: top,
                width: width,
                height: 55,
            }}
        ></div>
    );
};
