import historyListJa from './ja/history-list.locale';
import historyDetailJa from './ja/history-detail.locale';
import historyListEn from './en/history-list.locale';
import historyDetailEn from './en/history-detail.locale';

export const boHistory = {
    ja: {
        list: historyListJa,
        detail: historyDetailJa,
    },
    en: {
        list: historyListEn,
        detail: historyDetailEn,
    },
};
