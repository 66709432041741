import csvBookingUploadLocaleJa from './ja/csv-booking-upload.locale';
import csvBookingUploadLocaleEn from './en/csv-booking-upload.locale';

export const csvBookingUploadLocale = {
    ja: {
        text: csvBookingUploadLocaleJa,
    },
    en: {
        text: csvBookingUploadLocaleEn,
    },
};
