import React from 'react';
import { Select, SelectProps, Form } from 'antd';
import { Controller } from '~plugins/hook-form';
import './SingleSelect.scss';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import { parseErrorMessage } from '~common/commonFunctions';
import classNames from 'classnames';
import { TextTruncate } from '~components';
interface ISingleSelect extends SelectProps {
    name: string;
    label: string;
    defaultValue: string | number | (string | number)[];
    control: any;
    disableValues?: (string | number)[];
    tooltip?: LabelTooltipType;
    required?: boolean;
    hideHelpText?: boolean;
    isTriggerParentNode?: boolean;
    showTooltip?: boolean;
}

export function SingleSelect(props: ISingleSelect) {
    const {
        tooltip,
        control,
        name,
        defaultValue,
        label,
        onChange,
        options,
        fieldNames,
        disableValues,
        required,
        hideHelpText,
        showSearch = true,
        isTriggerParentNode = false,
        showTooltip,
        ...rest
    } = props;
    const _valKey = fieldNames?.value || 'value';
    const _labelKey = fieldNames?.label || 'label';
    return (
        <div
            className={classNames('single-select-wrapper', {
                'hide-help-text': hideHelpText,
            })}
        >
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            required={required}
                            tooltip={tooltip}
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                        >
                            <Select
                                {...rest}
                                showSearch={showSearch}
                                onBlur={field.onBlur}
                                value={field.value}
                                ref={field.ref}
                                onChange={(...agrs) => {
                                    if (onChange) {
                                        onChange(...agrs);
                                    }
                                    const val = agrs?.[0];
                                    field.onChange(val);
                                }}
                                filterOption={(input, option) => {
                                    if (
                                        (option!.children as any)?.props?.children &&
                                        (option!.children as any)?.props?.children
                                            .props &&
                                        (option!.children as any)?.props?.children.props
                                            .text
                                    ) {
                                        return (
                                            (
                                                (option!.children as any)?.props?.children
                                                    .props.text as string
                                            )
                                                ?.toLocaleLowerCase()
                                                ?.includes(input?.toLowerCase()) || false
                                        );
                                    }
                                    return (
                                        (
                                            (option!.children as any)?.props
                                                ?.children as string
                                        )
                                            ?.toLowerCase()
                                            ?.includes(input?.toLowerCase()) || false
                                    );
                                }}
                                getPopupContainer={(triggerNode?: HTMLElement) =>
                                    (isTriggerParentNode &&
                                        (triggerNode?.parentNode as HTMLElement)) ||
                                    document.body
                                }
                            >
                                {options?.map((item, index) => {
                                    const val = item[_valKey];
                                    const disabled =
                                        disableValues?.includes(val) || false;
                                    return (
                                        <Select.Option
                                            key={val}
                                            value={val}
                                            disabled={disabled}
                                        >
                                            <div 
                                                id={`${name}-${item[_labelKey]}`}
                                                className="text-truncate"
                                            >
                                                {showTooltip ? (
                                                    <TextTruncate
                                                        text={item[_labelKey]}
                                                    />
                                                ) : (
                                                    item[_labelKey]
                                                )}
                                            </div>
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

SingleSelect.defaultProps = {
    defaultValue: null,
    required: false,
    options: [],
    disableValues: [],
};
