import { Button, notification, Pagination, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import './IndicatorList.scss';
import { ColumnsType } from 'antd/lib/table';
import { IIndicator } from '~features/indicator/interfaces';
import { OrderBy, IndicatorColumn } from '~features/indicator/constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    fetchIndicatorList,
    indicatorListQuerySelector,
    indicatorListSelector,
    setIndicatorListQuery,
    showListLoadingSelector,
    totalPageSelector,
    totalIndicatorSelector,
} from '~features/indicator/reducers/indicator.reducer';
import { TableProps } from 'antd/es/table';
import { ISorter } from '~common/interfaces';
import {
    AdminGroup,
    AntdOrderDirection,
    OrderDirection,
    UserGroup,
} from '~common/constants';
import { ModalConfirmDeletion } from '~components';
import { indicatorService } from '~features/indicator/services/indicator.service';
import { useNavigate } from 'react-router-dom';
import { checkUserPermission } from '~common/commonFunctions';

function IndicatorList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const indicatorList = useAppSelector(indicatorListSelector);
    const indicatorListQuery = useAppSelector(indicatorListQuerySelector);
    const totalIndicator = useAppSelector(totalIndicatorSelector);
    const showListLoading = useAppSelector(showListLoadingSelector);
    const pageCount = useAppSelector(totalPageSelector);
    const navigate = useNavigate();
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);
    const fetchData = () => {
        dispatch(fetchIndicatorList());
    };

    useEffect(() => {
        fetchData();
    }, [indicatorListQuery]);

    useEffect(() => {
        if (indicatorList.length === 0 && (indicatorListQuery?.page as number) > 1) {
            dispatch(
                setIndicatorListQuery({
                    ...indicatorListQuery,
                    page: pageCount || 1,
                }),
            );
        }
    }, [indicatorList]);

    const onChangePage = (page: number) => {
        setSelectedRowKeys([]);
        dispatch(setIndicatorListQuery({ ...indicatorListQuery, page }));
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const onChangeTable: TableProps<IIndicator>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;

        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setIndicatorListQuery({
                    ...indicatorListQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setIndicatorListQuery({
                    ...indicatorListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const rows: IIndicator[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundIndicator = indicatorList.find(
                (indicator) => indicator.id === selectedRowKey,
            );
            if (foundIndicator) rows.push(foundIndicator);
        });
        return rows;
    }, [selectedRowKeys]);

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });

        const response = await indicatorService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('indicator.list.message.deleteSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            title: t('indicator.list.modalConfirmDeletion.title'),
            buttonCancelText: t('indicator.list.modalConfirmDeletion.cancelButton'),
            buttonDeleteText: t('indicator.list.modalConfirmDeletion.deleteButton'),
            deletedItems: selectedRows.map((indicator) => {
                return indicator.name;
            }),
            okButtonProps: { danger: true },
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const indicatorColumns: ColumnsType<IIndicator> = [
        {
            title: t('indicator.list.indicatorColumn.id'),
            key: IndicatorColumn.AUTO_GENERATED_CODE,
            sorter: true,
            render: (data) => {
                return <div className="id-text">{data.autoGeneratedCode}</div>;
            },
        },
        {
            title: t('indicator.list.indicatorColumn.name'),
            ellipsis: true,
            key: IndicatorColumn.NAME,
            sorter: true,
            dataIndex: IndicatorColumn.NAME,
        },
        {
            title: t('indicator.list.indicatorColumn.indicatorType'),
            key: IndicatorColumn.TYPE,
            sorter: true,
            render: (data) => {
                return <div>{t('indicator.create.type.' + data.indicatorType)}</div>;
            },
        },
        {
            title: t('indicator.list.indicatorColumn.layout'),
            key: IndicatorColumn.LAYOUT,
            sorter: true,
            dataIndex: IndicatorColumn.LAYOUT,
        },
    ];

    return (
        <>
            <div className="indicator-list-wrapper">
                <div className="indicator-list-header">
                    {!isReadOnly && selectedRows.length > 0 && (
                        <div className="indicator-header-left">
                            <Button
                                type="text"
                                className="delete-button"
                                onClick={showConfirmDialog}
                            >
                                <DeleteOutlined />
                            </Button>
                        </div>
                    )}
                </div>
                <Table
                    className="indicator-list-table"
                    pagination={false}
                    loading={showListLoading}
                    onChange={onChangeTable}
                    columns={indicatorColumns}
                    dataSource={indicatorList}
                    rowSelection={rowSelection}
                    rowKey="id"
                    rowClassName={'indicator-row'}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                if (isReadOnly) {
                                    return;
                                }
                                navigate('/front-settings/indicator/update/' + record.id);
                            },
                        };
                    }}
                />
            </div>
            {pageCount > 1 && (
                <Pagination
                    align="center"
                    defaultCurrent={indicatorListQuery.page}
                    current={indicatorListQuery.page}
                    total={totalIndicator}
                    pageSize={indicatorListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                    className="indicator-list-pagination"
                />
            )}
        </>
    );
}

export default IndicatorList;
