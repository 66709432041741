import React, { useCallback, useRef } from 'react';
import { UnorderedListOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { IBookingStaticType } from '~features/room-booking/interfaces';
import DraggableComponent from '../DraggableComponent/DraggableComponent';
import { IRoomDisplayOrderBody } from '~features/room/interfaces';
import './RoomTypesDraggableComponent.scss';

const { Panel } = Collapse;

interface IRoomTypesDraggableComponent {
    roomTypeList: IBookingStaticType[];
    activeKeys: string[] | string;
    setActiveKeys: (key: string[] | string) => void;
    handleMutateRoomTypeOrder: (
        body: IRoomDisplayOrderBody,
        roomTypeList: IBookingStaticType[],
    ) => void;
    handleMutateRoomOrder: (
        body: IRoomDisplayOrderBody,
        roomTypeList: IBookingStaticType[],
    ) => void;
}

interface IRefObject {
    parentId: string;
    index: number;
}

const RoomTypesDraggableComponent: React.FC<IRoomTypesDraggableComponent> = ({
    roomTypeList,
    activeKeys,
    setActiveKeys,
    handleMutateRoomTypeOrder,
    handleMutateRoomOrder,
}) => {
    const draggedRoomTypeRef = useRef<IRefObject | null>(null);
    const destinationRoomTypeRef = useRef<IRefObject | null>(null);
    const draggedRoomItemRef = useRef<IRefObject | null>(null);
    const destinationRoomItemRef = useRef<IRefObject | null>(null);

    const handleOnDragRoomTypeStart = (index: number, roomTypeId: number) => {
        const currentValue = {
            parentId: `room-type-dragged-parent_${roomTypeId}`,
            index,
        };
        draggedRoomTypeRef.current = currentValue;
    };
    const handleOnDragRoomTypeEnter = (index: number, roomTypeId: number) => {
        const currentValue = {
            parentId: `room-type-dragged-parent_${roomTypeId}`,
            index,
        };
        destinationRoomTypeRef.current = currentValue;
    };
    const handleOnDragOverRoomType = (event: React.DragEvent<HTMLDivElement>) => {
        const { current: draggedRoomTypeCurrent } = draggedRoomTypeRef;
        const { current: destinationRoomTypeCurrent } = destinationRoomTypeRef;
        if (
            draggedRoomTypeCurrent &&
            draggedRoomTypeCurrent.parentId &&
            destinationRoomTypeCurrent &&
            destinationRoomTypeCurrent.parentId &&
            draggedRoomTypeCurrent.parentId !== destinationRoomTypeCurrent.parentId
        ) {
            event.preventDefault();
        }
    };
    const handleOnDropRoomType = () => {
        const { current: draggedRoomTypeCurrent } = draggedRoomTypeRef;
        const { current: destinationRoomTypeCurrent } = destinationRoomTypeRef;
        if (draggedRoomTypeCurrent && destinationRoomTypeCurrent) {
            if (draggedRoomTypeCurrent.index === destinationRoomTypeCurrent.index) return;
            const roomTypeListCopy = [...roomTypeList];
            const draggedItemId = roomTypeListCopy[draggedRoomTypeCurrent.index].id;
            const newDisplayOrder =
                roomTypeListCopy[destinationRoomTypeCurrent.index].displayOrder;
            const draggedContent = roomTypeListCopy.splice(
                draggedRoomTypeCurrent.index,
                1,
            )[0];
            roomTypeListCopy.splice(destinationRoomTypeCurrent.index, 0, draggedContent);
            handleMutateRoomTypeOrder(
                { id: draggedItemId, displayOrder: newDisplayOrder },
                roomTypeListCopy,
            );
            draggedRoomTypeRef.current = null;
            destinationRoomTypeRef.current = null;
        }
    };

    const handleOnDragRoomItemStart = (index: number, roomTypeId: number) => {
        const currentValue = {
            parentId: `room-dragged-parent_${roomTypeId}`,
            index,
        };
        draggedRoomItemRef.current = currentValue;
    };
    const handleOnDragRoomItemEnter = (index: number, roomTypeId: number) => {
        const currentValue = {
            parentId: `room-dragged-parent_${roomTypeId}`,
            index,
        };
        destinationRoomItemRef.current = currentValue;
    };

    const handleOnDragOverRoomItem = (event: React.DragEvent<HTMLDivElement>) => {
        const { current: draggedRoomItemCurrent } = draggedRoomItemRef;
        const { current: destinationRoomItemCurrent } = destinationRoomItemRef;
        if (
            draggedRoomItemCurrent &&
            draggedRoomItemCurrent.parentId &&
            destinationRoomItemCurrent &&
            destinationRoomItemCurrent.parentId &&
            draggedRoomItemCurrent.parentId === destinationRoomItemCurrent.parentId &&
            draggedRoomItemCurrent.index !== destinationRoomItemCurrent.index
        ) {
            event.preventDefault();
        }
    };

    const handleOnDropRoomItem = (roomTypeId: number) => {
        const { current: draggedRoomItemCurrent } = draggedRoomItemRef;
        const { current: destinationRoomItemCurrent } = destinationRoomItemRef;
        if (draggedRoomItemCurrent && destinationRoomItemCurrent) {
            if (draggedRoomItemCurrent.index === destinationRoomItemCurrent.index) return;
            const roomTypeListCopy = [...roomTypeList];
            const roomTypeSelectedIndex = roomTypeListCopy.findIndex(
                (_roomType) => _roomType.id === roomTypeId,
            );
            const roomTypeSelectedRooms = roomTypeListCopy[roomTypeSelectedIndex].rooms;
            const draggedRoomItemId =
                roomTypeSelectedRooms[draggedRoomItemCurrent.index].id;
            const newDisplayOrder =
                roomTypeSelectedRooms[destinationRoomItemCurrent.index].displayOrder;
            const draggedContent = roomTypeSelectedRooms.splice(
                draggedRoomItemCurrent.index,
                1,
            )[0];
            roomTypeSelectedRooms.splice(
                destinationRoomItemCurrent.index,
                0,
                draggedContent,
            );
            handleMutateRoomOrder(
                { id: draggedRoomItemId, displayOrder: newDisplayOrder },
                roomTypeListCopy,
            );
            draggedRoomItemRef.current = null;
            destinationRoomItemRef.current = null;
        }
    };

    const renderPanel = useCallback(() => {
        return roomTypeList.map((roomTypeDetails, index) => (
            <Panel
                header={renderPanelHeader(roomTypeDetails, index)}
                key={roomTypeDetails.id.toString()}
                showArrow={false}
                className="room-types_collapse-panel"
            >
                {roomTypeDetails.rooms.map((room, index) => (
                    <DraggableComponent
                        key={`room-${room.name}_${room.id}`}
                        index={index}
                        handleOnDragStart={(index) =>
                            handleOnDragRoomItemStart(index, roomTypeDetails.id)
                        }
                        handleOnDragEnter={(index) =>
                            handleOnDragRoomItemEnter(index, roomTypeDetails.id)
                        }
                        handleOnDrop={() => handleOnDropRoomItem(roomTypeDetails.id)}
                        handleOnDragOver={handleOnDragOverRoomItem}
                    >
                        <div className="room-details_wrapper">
                            <div className="room-details_count">{index + 1}</div>
                            <div className="room-details_name-container">
                                <UnorderedListOutlined />
                                <div>{room.name}</div>
                            </div>
                        </div>
                    </DraggableComponent>
                ))}
            </Panel>
        ));
    }, [roomTypeList, activeKeys]);

    const renderPanelHeader = (roomTypeDetails: IBookingStaticType, index: number) => {
        return (
            <DraggableComponent
                key={`room-type_${roomTypeDetails.name}_${roomTypeDetails.id}`}
                index={index}
                handleOnDragStart={(index) =>
                    handleOnDragRoomTypeStart(index, roomTypeDetails.id)
                }
                handleOnDragEnter={(index) =>
                    handleOnDragRoomTypeEnter(index, roomTypeDetails.id)
                }
                handleOnDrop={handleOnDropRoomType}
                handleOnDragOver={handleOnDragOverRoomType}
            >
                <div className="room-types_collapse-panel-content">
                    <UnorderedListOutlined />
                    <span className="room-types_text-name">{roomTypeDetails.name}</span>
                    <span>{`(${roomTypeDetails.numberOfRooms} rooms)`}</span>
                    <div className="room-types_icon-container">
                        {activeKeys.includes(roomTypeDetails.id.toString()) ? (
                            <MinusOutlined style={{ fontSize: 16 }} />
                        ) : (
                            <PlusOutlined style={{ fontSize: 16 }} />
                        )}
                    </div>
                </div>
            </DraggableComponent>
        );
    };

    const handleOnChangeCollapse = (key: string[] | string) => {
        setActiveKeys(key);
    };

    return (
        <div>
            <Collapse
                ghost={true}
                activeKey={activeKeys}
                onChange={handleOnChangeCollapse}
                className="room-types_collapse"
            >
                {renderPanel()}
            </Collapse>
        </div>
    );
};

export default RoomTypesDraggableComponent;
