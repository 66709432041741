import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import i18n from '~plugins/i18next/i18n';
import { reportingServiceV2 } from '~features/report/reportV2.service';
import { CacheKeys } from '~queries/queries';
import { ReceiptItemBookingType } from '~features/fo-operation-history/constants';
import { AggregationMethod, paymentSummaryBreadcrumbs } from '~features/report/constants';

import { StaffLayoutHeader } from '~components';
import PaymentSummaryTable from './components/PaymentSummaryTable';
import FilterPaymentSummary from './components/PaymentSummaryFilter';

export default function PaymentSummaryPage() {
    const { t } = useTranslation();
    const [bookingType, setBookingType] = useState<ReceiptItemBookingType>(
        ReceiptItemBookingType.ROOM_BOOKING_ITEM,
    );
    const [aggregationMethod, setAggregationMethod] = useState(AggregationMethod.DAILY);
    const [date, setDate] = useState<Dayjs | null>(dayjs());

    const { data, refetch, isLoading, isFetching } = useQuery({
        queryKey: [
            CacheKeys.paymentSummaryReport,
            bookingType,
            aggregationMethod,
            date,
            i18n.language,
        ],
        queryFn: async () => {
            const res = await reportingServiceV2.getPaymentSummaryReport({
                bookingType,
                dateStart:
                    aggregationMethod === AggregationMethod.DAILY
                        ? dayjs(date).startOf('day').format('YYYY-MM-DD HH:mm:ss')
                        : dayjs(date)
                              .startOf('month')
                              .startOf('day')
                              .format('YYYY-MM-DD HH:mm:ss'),
                dateEnd:
                    aggregationMethod === AggregationMethod.DAILY
                        ? dayjs(date).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                        : dayjs(date)
                              .endOf('month')
                              .endOf('day')
                              .format('YYYY-MM-DD HH:mm:ss'),
            });
            return res.data;
        },
        enabled: false,
    });

    return (
        <div>
            <StaffLayoutHeader
                breadcrumbs={[...paymentSummaryBreadcrumbs()]}
                title={t('report.paymentSummary.title')}
            />
            <Layout.Content>
                <FilterPaymentSummary
                    data={data}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    date={date}
                    bookingType={bookingType}
                    aggregationMethod={aggregationMethod}
                    refetch={refetch}
                    setBookingType={setBookingType}
                    setAggregationMethod={setAggregationMethod}
                    setDate={setDate}
                />
                {data && (
                    <PaymentSummaryTable
                        data={data}
                        isLoading={isLoading}
                        aggregationMethod={aggregationMethod}
                        date={date}
                    />
                )}
            </Layout.Content>
        </div>
    );
}
