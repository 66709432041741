import saleGroupListJa from './ja/sale-group-list.locale';
import saleGroupFormJa from './ja/sale-group-form.locale';
import saleGroupFormImportJa from './ja/sale-group-form-import.locale';

import saleGroupListEn from './en/sale-group-list.locale';
import saleGroupFormEn from './en/sale-group-form.locale';
import saleGroupFormImportEn from './en/sale-group-form-import.locale';

const saleGroup = {
    ja: {
        list: saleGroupListJa,
        form: saleGroupFormJa,
        import: saleGroupFormImportJa,
    },
    en: {
        list: saleGroupListEn,
        form: saleGroupFormEn,
        import: saleGroupFormImportEn,
    },
};

export default saleGroup;
