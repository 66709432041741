const marketingChannelForm = {
    primaryButton: 'Create marketing channel',
    createForm: {
        title: 'Create marketing channel',
        submitBtn: 'Save',
        quantity: 'Quantity',
        cancelBtn: 'Cancel',
        addMarketingChannel: 'Add a row',
        id: '#',
        generateMarketingChannelsBtn: 'Generate',
        marketingChannelGroupId: 'Marketing channel group name',
        marketingChannelNameDuplicated: 'Marketing channel name is duplicated',
        marketingChannelCodeDuplicated: 'Marketing channel linkageCode is duplicated',
        marketingChannelCodeDayUseDuplicated:
            'Marketing channel code day-use is duplicated',
        marketingChannelName: 'Marketing channel name',
        marketingChannelGroupName: 'Marketing channel group name',
        linkageCode: 'Linkage code',
        linkageCodeDayUse: 'Linkage code (day-use)',
        delete: 'Delete',
        createSuccessMessage: 'Marketing Channels have been created succcessful!',
        placeholder: {
            linkageCode: 'Code',
            linkageCodeDayUse: 'Code',
            marketingChannelName: 'Enter marketing channel name',
            marketingChannelGroupName: 'Select marketing channel group',
        },
    },
    updateForm: {
        title: 'Edit marketing channel',
        submitBtn: 'Save',
        cancelBtn: 'Cancel',
        marketingChannelGroupName: 'Marketing channel group name',
        marketingChannelName: 'Marketing channel name',
        linkageCode: 'Linkage code',
        updateSuccessMessage: 'Marketing Channels have been updated succcessful!',
        marketingChannelNameDuplicated: 'Marketing Channel name is duplicated',
    },
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
    },
    confirm: {
        buttonCancelText: 'No',
        buttonDeleteText: 'YEs',
        cancelTitle: 'Do you want to cancel?',
    },
};
export default marketingChannelForm;
