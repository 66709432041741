import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse, ICsvFile, IGetListResponse } from '~common/interfaces';
import { trimData } from '~common/commonFunctions';
import {
    IHotel,
    IHotelDropDownQuery,
    IHotelDropdown,
    IHotelUpdateBody,
    IHotelGetListQuery,
    IHotelBulkDeleteItem,
    IHotelExportCsvQuery,
    IHotelCreateBody,
    IHotelListResponse,
    IHotelSettingUpdateBody,
} from '../interfaces';

class HotelsService extends ApiService {
    getHotelListForDropdown(params?: IHotelDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<IBodyResponse<IGetListResponse<IHotelDropdown>>>;
    }

    getList(queryString: IHotelGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IHotel>>
        >;
    }

    getBypeCsrHotelCodes(peCsrHotelCodes: string[]) {
        return this._get(`${this.baseUrl}/get-by-pe-codes`, {
            params: {
                peCsrHotelCodes,
            },
        }) as Promise<IBodyResponse<IHotel[]>>;
    }

    getDetail(id: number) {
        return this._getDetail(id) as Promise<IBodyResponse<IHotel>>;
    }

    create(createBody: IHotelCreateBody) {
        return this._create<IHotelCreateBody, IHotelListResponse>(createBody);
    }

    updateHotel(id: number, updateBody: IHotelUpdateBody) {
        trimData(updateBody);
        return this._patch(`${this.baseUrl}/management/${id}`, updateBody) as Promise<
            IBodyResponse<IHotel>
        >;
    }

    updateHoteSetting(id: number, updateBody: IHotelSettingUpdateBody) {
        trimData(updateBody);
        return this._patch(`${this.baseUrl}/setting/${id}`, updateBody) as Promise<
            IBodyResponse<IHotel>
        >;
    }

    bulkDelete(bulkDeleteBody: IHotelBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    exportCsv(query: IHotelExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }
}

export const hotelService = new HotelsService({ baseUrl: '/hotel' }, axiosService);
