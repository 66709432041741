import { DayOfWeek } from './../../../../common/constants';
export const dashboardEn = {
    controlOverlay: {
        bookingOverview: 'Today Booking Overview',
        bookingList: 'Booking List',
        notification: 'Notification',
        roomOccupancy: 'Number of remaining room today',
        contact: 'Contact/Support',
    },
    bookingList: {
        columns: {
            id: 'Booking ID',
            guestName: 'Full name (Yomigana)',
            fullName: 'Guest name',
            time: 'C/I - C/O',
            room: 'Room name',
            facility: 'Facility name',
            totalAmount: 'Unpaid amount',
            status: 'Status',
            receptionDate: 'Reception date',
            memo: 'Memo',
            roomCleanStatus: 'Room clean status',
        },
        tab: {
            hotelReservation: 'Room bookings',
            todayReservation: 'Today bookings',
            newArrivalReservation: 'New bookings',
            facilityReservation: 'Facility bookings',
        },
    },
    bookingOverview: {
        totalReservations: 'Number of bookings',
        todayCheckin: 'Check-in today',
        notArrivedYet: 'Reserved',
        arrived: 'Checked-in',
        todayCheckout: 'Check-out today',
        scheduledDeparture: 'Scheduled check-out',
        departed: 'Departed',
        todayOccupationRate: 'Today occupation rate',
        totalGuests: 'Number of guests in hotel',
        dayOfWeek: {
            [DayOfWeek.SUNDAY]: 'Sun',
            [DayOfWeek.MONDAY]: 'Mon',
            [DayOfWeek.TUESDAY]: 'Tue',
            [DayOfWeek.WEDNESDAY]: 'Wed',
            [DayOfWeek.THURSDAY]: 'Thu',
            [DayOfWeek.FRIDAY]: 'Fri',
            [DayOfWeek.SATURDAY]: 'Sat',
        },
    },
    roomOccupancyCard: {
        title: 'Number of remaining rooms today',
        link: 'Confirm bookings in schedule',
    },
    notificationCard: {
        title: 'Notification',
        new: 'NEW',
        contentCard: '「{{title}}」{{content}} ',
        closingBooking:
            '[Closing] There is one or more booking(s) that cannot be closed.',
    },
    contactCard: {
        title: 'Support Center',
        phone: '0570-066-906',
        email: 'partner.support@tabist.co.jp',
    },
    sort: {
        asc: 'Click to sort ascending',
        desc: 'Click to sort descending',
        cancel: 'Click to cancel sorting',
    },
};
