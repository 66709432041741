import dayjs from 'dayjs';
import { ShowMonth } from '~features/report/constants';
import {
    ISalesByPlansItem,
    ISalesByPlansResponse,
    ISalesByPlansSummary,
} from '../../interfaces';

export const generateDailyData = (
    salesByPlans: ISalesByPlansResponse,
    filterYear: number,
    filterMonth: number,
    date: number,
) => {
    const currentData = salesByPlans.items.filter((item: ISalesByPlansItem) => {
        const startOfDate = dayjs(item.dateStart);
        const endOfDate = dayjs(item.dateEnd);
        const selectedDate = dayjs(`${filterYear}/${filterMonth}/${date}`);
        return dayjs(selectedDate).isBetween(startOfDate, endOfDate, 'day', '[]');
    });

    return currentData.reduce(
        (acc: { [key: string]: { [key: string]: number } }, item: ISalesByPlansItem) => {
            const {
                plan: { id: planId },
                current,
                previousYear,
            } = item;
            acc[`totalBookedRoom${planId}`] = {
                current: current?.totalBookedRoom,
                previousYear: previousYear?.totalBookedRoom,
            };
            acc[`totalAdult${planId}`] = {
                current: current?.totalGuest,
                previousYear: previousYear?.totalGuest,
            };
            acc[`totalChild${planId}`] = {
                current: current?.totalChild,
                previousYear: previousYear?.totalChild,
            };
            acc[`totalRevenue${planId}`] = {
                current: current?.totalRevenue,
                previousYear: previousYear?.totalRevenue,
            };
            acc[`avgRevenue${planId}`] = {
                current: current?.avgRevenue,
                previousYear: previousYear?.avgRevenue,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );
};

export const generateDailySummary = (salesByPlans: ISalesByPlansResponse) => {
    return salesByPlans.totals.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByPlansSummary,
        ) => {
            if (!item.plan) return acc;
            const {
                plan: { id: planId },
                current,
                previousYear,
            } = item;
            acc[`totalBookedRoom${planId}`] = {
                current: current?.totalBookedRoom,
                previousYear: previousYear?.totalBookedRoom,
            };
            acc[`totalAdult${planId}`] = {
                current: current?.totalGuest,
                previousYear: previousYear?.totalGuest,
            };
            acc[`totalChild${planId}`] = {
                current: current?.totalChild,
                previousYear: previousYear?.totalChild,
            };
            acc[`totalRevenue${planId}`] = {
                current: current?.totalRevenue,
                previousYear: previousYear?.totalRevenue,
            };
            acc[`avgRevenue${planId}`] = {
                current: current?.avgRevenue,
                previousYear: previousYear?.avgRevenue,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );
};

export const generateMonthlySummary = (
    salesByPlans: ISalesByPlansResponse,
    filterDate: string,
) => {
    const lastMonthSummaryData = salesByPlans.totals.filter(
        (item: ISalesByPlansSummary) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.LAST_MONTH,
    );

    const lastMonthPlanData = lastMonthSummaryData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByPlansSummary,
        ) => {
            const { current, previousYear } = item;
            acc['lastMonthTotalBookedRoom'] = {
                current: current?.totalBookedRoom || 0,
                previousYear: previousYear?.totalBookedRoom || 0,
            };
            acc['lastMonthTotalAdult'] = {
                current: current?.totalGuest || 0,
                previousYear: previousYear?.totalGuest || 0,
            };
            acc['lastMonthTotalChild'] = {
                current: current?.totalChild || 0,
                previousYear: previousYear?.totalChild || 0,
            };
            acc['lastMonthTotalRevenue'] = {
                current: current?.totalRevenue || 0,
                previousYear: previousYear?.totalRevenue || 0,
            };
            acc['lastMonthAvgRevenue'] = {
                current: current?.avgRevenue || 0,
                previousYear: previousYear?.avgRevenue || 0,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    const currentMonthData = salesByPlans.totals.filter(
        (item: ISalesByPlansSummary) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.CURRENT_MONTH,
    );

    const currentMonthPlanData = currentMonthData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByPlansSummary,
        ) => {
            const { current, previousYear } = item;
            acc['currentMonthTotalBookedRoom'] = {
                current: current?.totalBookedRoom || 0,
                previousYear: previousYear?.totalBookedRoom || 0,
            };
            acc['currentMonthTotalAdult'] = {
                current: current?.totalGuest || 0,
                previousYear: previousYear?.totalGuest || 0,
            };
            acc['currentMonthTotalChild'] = {
                current: current?.totalChild || 0,
                previousYear: previousYear?.totalChild || 0,
            };
            acc['currentMonthTotalRevenue'] = {
                current: current?.totalRevenue || 0,
                previousYear: previousYear?.totalRevenue || 0,
            };
            acc['currentMonthAvgRevenue'] = {
                current: current?.avgRevenue || 0,
                previousYear: previousYear?.avgRevenue || 0,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    const nextMonthData = salesByPlans.totals.filter(
        (item) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.NEXT_MONTH,
    );
    const nextMonthPlanData = nextMonthData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByPlansSummary,
        ) => {
            const { current, previousYear } = item;
            acc['nextMonthTotalBookedRoom'] = {
                current: current?.totalBookedRoom || 0,
                previousYear: previousYear?.totalBookedRoom || 0,
            };
            acc['nextMonthTotalAdult'] = {
                current: current?.totalGuest || 0,
                previousYear: previousYear?.totalGuest || 0,
            };
            acc['nextMonthTotalChild'] = {
                current: current?.totalChild || 0,
                previousYear: previousYear?.totalChild || 0,
            };
            acc['nextMonthTotalRevenue'] = {
                current: current?.totalRevenue || 0,
                previousYear: previousYear?.totalRevenue || 0,
            };
            acc['nextMonthAvgRevenue'] = {
                current: current?.avgRevenue || 0,
                previousYear: previousYear?.avgRevenue || 0,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    return {
        ...lastMonthPlanData,
        ...currentMonthPlanData,
        ...nextMonthPlanData,
    };
};

const checkDatePeriod = (dateStart: string, dateEnd: string, filterDate: string) => {
    const startDate = dayjs(dateStart).month() + 1;
    const endDate = dayjs(dateEnd).month() + 1;
    const currentMonth = dayjs(filterDate).month() + 1;

    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;

    if (startDate === currentMonth && endDate === currentMonth) {
        return ShowMonth.CURRENT_MONTH;
    }
    if (startDate === previousMonth && endDate === previousMonth) {
        return ShowMonth.LAST_MONTH;
    }
    if (startDate === nextMonth && endDate === nextMonth) {
        return ShowMonth.NEXT_MONTH;
    }
};

export const generateMonthlyData = (
    salesByPlans: ISalesByPlansResponse,
    planId: number,
    filterDate: string,
) => {
    const planData = salesByPlans.items.filter(
        (item: ISalesByPlansItem) => item.plan.id === planId,
    );

    const lastMonthData = planData.filter(
        (item: ISalesByPlansItem) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.LAST_MONTH,
    );

    const lastMonthPlanData = lastMonthData.reduce(
        (acc: { [key: string]: { [key: string]: number } }, item: ISalesByPlansItem) => {
            const { current, previousYear } = item;
            acc['lastMonthTotalBookedRoom'] = {
                current: current?.totalBookedRoom || 0,
                previousYear: previousYear?.totalBookedRoom || 0,
            };
            acc['lastMonthTotalAdult'] = {
                current: current?.totalGuest || 0,
                previousYear: previousYear?.totalGuest || 0,
            };
            acc['lastMonthTotalChild'] = {
                current: current?.totalChild || 0,
                previousYear: previousYear?.totalChild || 0,
            };
            acc['lastMonthTotalRevenue'] = {
                current: current?.totalRevenue || 0,
                previousYear: previousYear?.totalRevenue || 0,
            };
            acc['lastMonthAvgRevenue'] = {
                current: current?.avgRevenue || 0,
                previousYear: previousYear?.avgRevenue || 0,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    const currentMonthData = planData.filter(
        (item: ISalesByPlansItem) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.CURRENT_MONTH,
    );
    const currentMonthPlanData = currentMonthData.reduce(
        (acc: { [key: string]: { [key: string]: number } }, item: ISalesByPlansItem) => {
            const { current, previousYear } = item;
            acc['currentMonthTotalBookedRoom'] = {
                current: current?.totalBookedRoom || 0,
                previousYear: previousYear?.totalBookedRoom || 0,
            };
            acc['currentMonthTotalAdult'] = {
                current: current?.totalGuest || 0,
                previousYear: previousYear?.totalGuest || 0,
            };
            acc['currentMonthTotalChild'] = {
                current: current?.totalChild || 0,
                previousYear: previousYear?.totalChild || 0,
            };
            acc['currentMonthTotalRevenue'] = {
                current: current?.totalRevenue || 0,
                previousYear: previousYear?.totalRevenue || 0,
            };
            acc['currentMonthAvgRevenue'] = {
                current: current?.avgRevenue || 0,
                previousYear: previousYear?.avgRevenue || 0,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    const nextMonthData = planData.filter(
        (item) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.NEXT_MONTH,
    );
    const nextMonthPlanData = nextMonthData.reduce(
        (acc: { [key: string]: { [key: string]: number } }, item: ISalesByPlansItem) => {
            const { current, previousYear } = item;
            acc['nextMonthTotalBookedRoom'] = {
                current: current?.totalBookedRoom || 0,
                previousYear: previousYear?.totalBookedRoom || 0,
            };
            acc['nextMonthTotalAdult'] = {
                current: current?.totalGuest || 0,
                previousYear: previousYear?.totalGuest || 0,
            };
            acc['nextMonthTotalChild'] = {
                current: current?.totalChild || 0,
                previousYear: previousYear?.totalChild || 0,
            };
            acc['nextMonthTotalRevenue'] = {
                current: current?.totalRevenue || 0,
                previousYear: previousYear?.totalRevenue || 0,
            };
            acc['nextMonthAvgRevenue'] = {
                current: current?.avgRevenue || 0,
                previousYear: previousYear?.avgRevenue || 0,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );
    return {
        ...lastMonthPlanData,
        ...currentMonthPlanData,
        ...nextMonthPlanData,
    };
};
