import i18next from 'i18next';
import { trim } from 'lodash';
import { ACCEPTED_FILE_FORMATS, GuestPageTabPane } from './constants';
import { IFile, IGuestForm } from './interfaces';

export function getGuestFormId(name: string) {
    return `guest-form-${name}`;
}

export function convertHexToRGB(hex: string, alpha: number) {
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    }
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
}

export function getErrorKeyTabPane(errorKeyList: string[], activeKey: GuestPageTabPane) {
    const isBasicTabPaneHasError = errorKeyList?.some((errorKey) => {
        return !errorKey.includes('company');
    });

    const isCompanyTabPaneHasError = errorKeyList?.some((errorKey) => {
        return errorKey.includes('company');
    });

    if (
        (isBasicTabPaneHasError && activeKey === GuestPageTabPane.BASIC_INFO_TAB_PANE) ||
        (isCompanyTabPaneHasError && activeKey === GuestPageTabPane.COMPANY_INFO_TAB_PANE)
    ) {
        return activeKey;
    }

    if (isBasicTabPaneHasError) {
        return GuestPageTabPane.BASIC_INFO_TAB_PANE;
    }

    return GuestPageTabPane.COMPANY_INFO_TAB_PANE;
}

export const getAutoGeneratedCode = (autoGeneratedCode: string) => {
    return autoGeneratedCode?.split('@')?.[0] || '';
};

export const validateFileExtension = (files: IFile[], extensions: string[]) => {
    const isError = files.some((file) => !extensions.includes(`.${file.extension}`));
    return {
        isError,
        message: i18next.t('guest.form.uploadFile.message.acceptTypeError', {
            format: ACCEPTED_FILE_FORMATS.join(', '),
        }),
    };
};

export const validateFileSize = (files: IFile[], maxSize: number) => {
    const isError = files.some((file) => file.size > maxSize);
    return {
        isError,
        message: i18next.t('common.uploadFile.message.maxSizeError', {
            maxSize: maxSize / 1024 / 1024,
        }),
    };
};

export const mapGuestData = (guest: IGuestForm) => {
    const oldFiles = guest.files?.filter((file) => !file.isAdditional && !!file.id);
    let fileIds;
    if (oldFiles?.length) {
        fileIds = oldFiles.map((file) => +(file.id || 0));
    }
    return {
        fullName: guest.fullName || null,
        yomigana: guest.yomigana,
        mobilePhoneNumber: guest.mobilePhoneNumber
            ? trim(guest.mobilePhoneNumber) || null
            : null,
        emailAddress: guest.emailAddress?.trim() || null,
        birthday: guest.birthday || null,
        gender: guest.gender || null,
        postalCode: guest.postalCode || null,
        address: guest.address || null,
        countryId: guest.countryId || null,
        classificationId: guest.classificationId || null,
        memo: guest.memo || null,
        companyName: guest.companyName || null,
        companyYomigana: guest.companyYomigana || null,
        companyPhoneNumber: guest.companyPhoneNumber || null,
        companyEmailAddress: guest.companyEmailAddress || null,
        companyAddress: guest.companyAddress || null,
        companyPostalCode: guest.companyPostalCode || null,
        companyWebsite: guest.companyWebsite || null,
        fileIds,
    };
};

export const mapCreateGuestBody = (guest: IGuestForm) => {
    const guestData = mapGuestData(guest);
    if (guest.files?.length) {
        let bodyFormData = new FormData();
        bodyFormData.append('guestData', JSON.stringify(guestData));
        guest.files?.forEach((file) => {
            if (file.isAdditional && file?.originFileObj) {
                bodyFormData.append(`files`, file?.originFileObj);
            }
        });
        return bodyFormData;
    }
    return guestData;
};

export const mapUpdateGuestBody = (guest: IGuestForm) => {
    const guestData = mapGuestData(guest);
    const newFiles = guest.files?.filter(
        (file) => file.isAdditional && !!file.originFileObj,
    );
    if (newFiles?.length) {
        var bodyFormData = new FormData();
        bodyFormData.append('guestData', JSON.stringify(guestData));
        newFiles?.forEach((file) => {
            if (file.isAdditional && file?.originFileObj) {
                bodyFormData.append(`files`, file?.originFileObj);
            }
        });
        return bodyFormData;
    }

    return guestData;
};
