import React, { useEffect, useState } from 'react';
import { Card, Divider } from 'antd';
import './ClockCard.scss';
import customDayjs, { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
export const ClockCard = () => {
    const [currentTime, setCurrentTime] = useState<Dayjs>(todayDayjs);

    useEffect(() => {
        let rotationInterval = setInterval(() => {
            let now = customDayjs();
            setCurrentTime(now);
        }, 1000);
        return () => {
            clearInterval(rotationInterval);
        };
    }, []);

    return (
        <Card className="dashboard-clock-card-wrapper">
            <div className="note">{currentTime.fmDayOfWeek()}</div>
            <div className="title">{currentTime.fmMMDDJp()}</div>
            <Divider />
            <div className="subtitle">{currentTime.fmHHmm()}</div>
        </Card>
    );
};
