const closingBookingPage = {
    title: '日締め',
    breadcrumbs: {
        home: 'ホーム',
        tightenWork: '締作業',
    },
    filterForm: {
        status: 'ステータス:',
    },
    closingStatus: {
        all: '全て',
        not_checked_in: '未チェックイン',
        not_checked_out: '未チェックアウト',
    },
    tabs: {
        room: '部屋終了',
        facility: '施設終了',
    },
    noBookingToDelete: '予約はございません',
};

export default closingBookingPage;
