import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../constants';
import { AlmexIntegrationCard } from '../components/AlmexIntegrationCard/AlmexIntegrationCard';

import './AlmexKioskSettingPage.scss';

export const AlmexKioskSettingPage = () => {
    const { t } = useTranslation();

    return (
        <div className="integrations-page-wrapper">
            <StaffLayoutHeader
                title={t('kioskAlmexIntegrations.almex.title')}
                breadcrumbs={breadcrumbs()}
            />
            <div className="page-contents">
                <AlmexIntegrationCard />
            </div>
        </div>
    );
};
