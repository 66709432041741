import { yupResolver } from '@hookform/resolvers/yup';
import { Form, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from '~plugins/hook-form';
import { useTranslation } from 'react-i18next';
import { timeToMinutes, validateUniqueValue } from '~common/commonFunctions';
import { ErrorMessageType } from '~common/constants';
import { RightDrawerLayout } from '~components';
import {
    bulkUpdateFacilityType,
    fetchFacilityTypeList,
    selectedFacilityTypeListSelector,
    setSelectedFacilityTypeList,
    setIsShowUpdateForm,
    showUpdateFormSelector,
    formBusySelector,
} from '~features/facility-type/facility-type.reducer';
import {
    IFacilityTypeCreate,
    IFacilityTypeBulkUpdate,
    IFacilityType,
} from '~features/facility-type/interfaces';
import { bulkUpdateFacilityTypesSchema } from '~features/facility-type/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import './UpdateFacilityTypesForm.scss';
import { showConfirm } from '~common/notification';
import { useEscape } from '~common/useHooks';
import UpdateFacilityTypesFormItem from './UpdateFacilityTypesFormItem/UpdateFacilityTypesFormItem';
import compact from 'lodash/compact';
import { BUSINESS_TIME_GAP } from '~features/facility-type/constants';
import dayjs from 'dayjs';

function UpdateFacilityTypesForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowUpdateForm = useAppSelector(showUpdateFormSelector);
    const selectedFacilityTypeList = useAppSelector(selectedFacilityTypeListSelector);
    const formBusy = useAppSelector(formBusySelector);
    const [toggleFlatRates, setToggleFlatRates] = useState<boolean[]>([]);

    const { control, reset, handleSubmit, setError, setValue } = useForm({
        resolver: yupResolver(bulkUpdateFacilityTypesSchema),
    });

    const closeDialog = (reload?: boolean) => {
        dispatch(setIsShowUpdateForm(false));
        dispatch(setSelectedFacilityTypeList([]));
        reset();
        if (reload) {
            dispatch(fetchFacilityTypeList());
        }
    };

    useEffect(() => {
        const toggleFlatRateList: boolean[] = [];
        const items = selectedFacilityTypeList.map((facilityType) => {
            let timeUnit = {
                hour: 0,
                minute: 0,
            };
            toggleFlatRateList.push(facilityType.duration === null ? true : false);
            if (facilityType.duration) {
                const hour = Math.floor((facilityType.duration || 0) / 3600);
                const minute = Math.floor(
                    ((facilityType.duration || 0) - hour * 3600) / 60,
                );
                timeUnit = {
                    hour,
                    minute,
                };
            }
            const [hourStart, minuteStart] = facilityType.businessStartTime.split(':');
            const [hourEnd, minuteEnd] = facilityType.businessEndTime.split(':');
            return {
                ...facilityType,
                businessTimes: [
                    dayjs()
                        .set('hour', Number(hourStart))
                        .set('minute', Number(minuteStart)),
                    dayjs().set('hour', Number(hourEnd)).set('minute', Number(minuteEnd)),
                ],
                timeUnit,
            };
        });
        setToggleFlatRates(toggleFlatRateList);
        reset({
            items,
        });
    }, [selectedFacilityTypeList]);

    const _bulkUpdate = useCallback(async (facilityTypes: IFacilityTypeCreate[]) => {
        const response = await dispatch(bulkUpdateFacilityType(facilityTypes));
        if (bulkUpdateFacilityType.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('facilityType.form.message.update.success'),
                });
                closeDialog(true);
            } else {
                (response.payload?.errors || []).forEach((error) => {
                    if (error.key === 'name' && error.order) {
                        setError(
                            `items.${error.order - 1}.name`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                    if (error.key === 'abbreviation' && error.order) {
                        setError(
                            `items.${error.order - 1}.abbreviation`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                });
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    const onSubmit = () => {
        handleSubmit((values) => {
            let isError = false;
            const facilityTypes = values.items.map(
                (item: IFacilityTypeBulkUpdate, index: number) => {
                    const saleItemIds = compact(item.saleItem?.map((item) => item.id));
                    const { id, name, abbreviation, businessTimes, price } = item;
                    const duration =
                        (Number(item?.timeUnit?.hour) || 0) * 3600 +
                        (Number(item?.timeUnit?.minute) || 0) * 60;
                    if (!toggleFlatRates[index] && duration === 0) {
                        isError = true;
                        setError(
                            `items.${index}.timeUnit`,
                            {
                                type: ErrorMessageType.MANUAL,
                                message: t('facilityType.form.message.durationError'),
                            },
                            { shouldFocus: true },
                        );
                    }
                    const [startTime, endTime] = businessTimes || [];
                    if (
                        startTime &&
                        endTime &&
                        timeToMinutes(endTime) - timeToMinutes(startTime) <
                            BUSINESS_TIME_GAP
                    ) {
                        setError(
                            `items.${index}.businessTimes`,
                            {
                                type: ErrorMessageType.MANUAL,
                                message: t(
                                    'facilityType.form.message.businessTimesError',
                                ),
                            },
                            { shouldFocus: true },
                        );
                        return;
                    }
                    return {
                        id,
                        name,
                        abbreviation,
                        price: Number(price),
                        saleItemIds: saleItemIds,
                        duration:
                            toggleFlatRates[index] || duration === 0 ? null : duration,
                        businessStartTime: dayjs(startTime).fmHHmm(),
                        businessEndTime: dayjs(endTime).fmHHmm(),
                        order: index + 1,
                    };
                },
            );
            if (isError) return;
            const duplicatedNameIndexs = validateUniqueValue(facilityTypes, 'name');
            const duplicateAbbreviationIndex = validateUniqueValue(
                facilityTypes,
                'abbreviation',
            );
            if (duplicatedNameIndexs.length) {
                duplicatedNameIndexs.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('facilityType.form.message.nameDuplicated'),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            if (duplicateAbbreviationIndex.length) {
                duplicateAbbreviationIndex.forEach((index) => {
                    setError(
                        `items.${index}.abbreviation`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t(
                                'facilityType.form.message.abbreviationDuplicated',
                            ),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkUpdate(facilityTypes);
        })();
    };

    const changeFlatRate = (
        status: boolean,
        facilityType: IFacilityType,
        index: number,
    ) => {
        toggleFlatRates[index] = status;
        setToggleFlatRates([...toggleFlatRates]);
    };

    const _showConfirm = () => {
        showConfirm({
            title: t('facility.updateForm.form.confirm.cancelTitle'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    return (
        <RightDrawerLayout
            open={isShowUpdateForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={t('facilityType.form.updateTitle')}
            cancelText={t('common.buttonCancelText')}
            submitText={t('common.buttonSaveText')}
            className="update-facility-type-drawer"
            busy={formBusy}
            loading={formBusy}
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                {selectedFacilityTypeList.map((facilityType, index) => {
                    return (
                        <div key={facilityType?.id}>
                            <UpdateFacilityTypesFormItem
                                setValue={setValue}
                                indexFacilityType={index}
                                control={control}
                                facilityType={facilityType}
                                selectedFacilityTypeList={selectedFacilityTypeList}
                                toggleFlatRates={toggleFlatRates}
                                changeFlatRate={changeFlatRate}
                            />
                        </div>
                    );
                })}
            </Form>
        </RightDrawerLayout>
    );
}

export default UpdateFacilityTypesForm;
