import { DeleteOutlined, ReloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Pagination, Table, Tag, Tooltip, notification } from 'antd';
import { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { orderBy } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FacilityBookingIcon } from '~assets/images/facility-booking.svg';
import { ReactComponent as RoomBookingIcon } from '~assets/images/room-booking.svg';
import {
    downloadFile,
    exportCsvFileWithXlsx,
    parseDateTime,
    replaceBreakLine,
} from '~common/commonFunctions';
import {
    AntdOrderDirection,
    CsvOption,
    DateFormat,
    OrderDirection,
    cellTextErrorStyle,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import { guestSchema } from '~common/validatorSchema';
import { CsvDropdown, ModalConfirmDeletion } from '~components';
import { getGuestClassificationListForDropdown } from '~features/guest-classification/guest-classification.reducer';
import { convertHexToRGB } from '~features/guest/helper';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    EXPORT_CSV_FILE_NAME,
    FILE_NAME,
    GuestColumn,
    OrderBy,
    UsedBookingType,
    exportColumns,
    i18ExportKey,
    initGuestListQuery,
} from '../../constants';
import { IGuest, IGuestExportCsvQuery } from '../../interfaces';
import {
    fetchGuestList,
    guestListQuerySelector,
    guestListSelector,
    resetState,
    setGuestListQuery,
    setShowMergeGuestsModal,
    showLoadingListSelector,
    totalGuestSelector,
    totalPageSelector,
} from '../../reducers/guest.reducer';
import { guestService } from '../../services/guest.service';
import MergeGuestsModal from '../MergeGuestsModal/MergeGuestsModal';
import './GuestList.scss';

function GuestList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const guestList = useAppSelector(guestListSelector);
    const showLoadingList = useAppSelector(showLoadingListSelector);
    const guestListQuery = useAppSelector(guestListQuerySelector);
    const totalGuests = useAppSelector(totalGuestSelector);
    const pageCount = useAppSelector(totalPageSelector);

    const fetchData = () => {
        dispatch(fetchGuestList());
    };

    useEffect(() => {
        fetchData();
    }, [guestListQuery]);

    useEffect(() => {
        dispatch(getGuestClassificationListForDropdown({}));
        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (guestList.length === 0 && guestListQuery?.page && guestListQuery?.page > 1) {
            dispatch(
                setGuestListQuery({
                    ...guestListQuery,
                    page: pageCount || 1,
                }),
            );
        }
    }, [guestList]);

    const onChange: TableProps<IGuest>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setGuestListQuery({
                    ...guestListQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setGuestListQuery({
                    ...guestListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const rows: IGuest[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundGuest = guestList.find((guest) => guest.id === selectedRowKey);
            if (foundGuest) rows.push(foundGuest);
        });
        return rows;
    }, [selectedRowKeys]);

    const onRefresh = async () => {
        await fetchData();
        setSelectedRowKeys([]);
    };

    const onChangePage = (page: number) => {
        setSelectedRowKeys([]);
        dispatch(setGuestListQuery({ ...guestListQuery, page }));
    };

    const convertDataToCsv = (guestList: IGuest[]) => {
        const dataOutput: any[] = [];
        guestList.forEach((guest) => {
            const data = {
                autoGeneratedCode: guest.autoGeneratedCode,
                yomigana: guest.yomigana,
                fullName: guest.fullName,
                gender: guest.gender ? t(`common.gender.${guest.gender}`) : '',
                birthday: guest.birthday,
                mobilePhoneNumber:
                    guest.mobilePhoneNumber || guest.phoneNumberLandline || '',
                usedBookingTypes: guest?.usedBookingTypes?.length
                    ? guest?.usedBookingTypes
                          .map((usedBookingType) =>
                              t(`guest.list.usedBookingType.${usedBookingType}`),
                          )
                          .join(t(`common.symbol.semicolon`))
                    : '',
                emailAddress: guest.emailAddress,
                memo: guest?.memo ? replaceBreakLine(guest.memo) : '',
                classification: guest.classification?.name,
            };

            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportGuestList = async (guestList: IGuest[]) => {
        try {
            const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;
            const guestDataCsv = convertDataToCsv(guestList);
            // Create header file csv
            const headers = exportColumns.map((key) =>
                t(`${i18ExportKey}.exportColumns.${key}`),
            );
            exportCsvFileWithXlsx(guestDataCsv, filename, headers);
            return {
                filename,
                filePath: `${process.env.FILE_STORAGE_BASE_URL}/${process.env.FILE_STORAGE_GUEST_CSV_FOLDER}/${filename}`,
            };
        } catch (error) {
            throw error;
        }
    };

    const exportCsv = async (query: IGuestExportCsvQuery) => {
        const response = await guestService.exportCsv(query);
        if (response.success) {
            downloadFile(FILE_NAME, response.data.filePath);
        }
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all guest
                await exportCsv({
                    keyword: guestListQuery.keyword,
                    fullName: guestListQuery.fullName,
                    phoneNumber: guestListQuery.phoneNumber,
                    email: guestListQuery.email,
                    orderBy: guestListQuery.orderBy,
                    orderDirection: guestListQuery.orderDirection,
                    usedBookingTypes: guestListQuery.usedBookingTypes,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportGuestList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                // ToDo: import
                break;
            }
            default:
                break;
        }
    };
    const showGuestDetail = (guest: IGuest) => {
        if (typeof guest.id === 'number') {
            navigate(`${guest.id}/detail`);
        }
    };

    const onClickOneRow = (record: IGuest) => {
        return {
            onClick: () => {
                showGuestDetail(record);
            },
        };
    };

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            title: t('guest.list.modalConfirmDeletion.title'),
            description: t('guest.list.modalConfirmDeletion.description'),
            buttonCancelText: t('guest.list.modalConfirmDeletion.cancelButton'),
            buttonDeleteText: t('guest.list.modalConfirmDeletion.deleteButton'),
            okButtonProps: { danger: true },
            deletedItems: [],
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });

        const response = await guestService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            Modal.success({
                content: t('guest.list.modalDeteleSuccess.title'),
                okText: t('guest.list.modalDeteleSuccess.okButton'),
                okType: 'default',
                centered: true,
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const getGuestCellTextStyle = (guest: IGuest) => {
        return guestSchema.isValidSync(guest) ? {} : cellTextErrorStyle;
    };

    const showMergeGuestsModal = () => {
        dispatch(setShowMergeGuestsModal(true));
    };

    const guestColumns: ColumnsType<IGuest> = [
        {
            title: t('guest.list.guestColumn.id'),
            key: GuestColumn.AUTO_GENERATED_CODE,
            sorter: true,
            width: '110px',
            render: (guest: IGuest) => {
                return (
                    <div>
                        <a>{guest.autoGeneratedCode}</a>
                    </div>
                );
            },
            ellipsis: true,
        },
        {
            title: t('guest.list.guestColumn.yomigana'),
            key: GuestColumn.YOMIGANA,
            sorter: true,
            width: '160px',
            dataIndex: GuestColumn.YOMIGANA,
            onCell: (guest: IGuest) => {
                return {
                    style: getGuestCellTextStyle(guest),
                };
            },
            ellipsis: true,
        },
        {
            title: t('guest.list.guestColumn.fullName'),
            key: GuestColumn.NAME,
            sorter: true,
            width: '190px',
            dataIndex: GuestColumn.NAME,
            onCell: (guest: IGuest) => {
                return {
                    style: getGuestCellTextStyle(guest),
                };
            },
            ellipsis: true,
        },
        {
            title: t('guest.list.guestColumn.gender'),
            key: GuestColumn.GENDER,
            sorter: true,
            width: '100px',
            dataIndex: GuestColumn.GENDER,
            onCell: (guest: IGuest) => {
                return {
                    style: getGuestCellTextStyle(guest),
                };
            },
            render: (gender: string) => {
                return (
                    <span className="text-truncate">
                        {gender ? t(`guest.list.gender.${gender}`) : ''}
                    </span>
                );
            },
        },
        {
            title: t('guest.list.guestColumn.birthday'),
            key: GuestColumn.BIRTHDAY,
            sorter: true,
            width: '140px',
            dataIndex: GuestColumn.BIRTHDAY,
            onCell: (guest: IGuest) => {
                return {
                    style: getGuestCellTextStyle(guest),
                };
            },
            render: (birthday: string) => {
                return (
                    <span className="text-truncate">
                        {birthday
                            ? parseDateTime(birthday, DateFormat.YYYY_MM_DD_SLASH)
                            : ''}
                    </span>
                );
            },
        },
        {
            title: t('guest.list.guestColumn.mobilePhoneNumber'),
            key: GuestColumn.PHONE,
            sorter: true,
            width: '150px',
            onCell: (guest: IGuest) => {
                return {
                    style: getGuestCellTextStyle(guest),
                };
            },
            render: (item: IGuest) => {
                return (
                    <span className="text-truncate">
                        {item.mobilePhoneNumber || item.phoneNumberLandline || ''}
                    </span>
                );
            },
            ellipsis: true,
        },

        {
            title: t('guest.list.guestColumn.usedBookingTypes'),
            key: GuestColumn.USED_BOOKING_TYPES,
            sorter: true,
            width: '180px',
            dataIndex: GuestColumn.USED_BOOKING_TYPES,
            onCell: (guest: IGuest) => {
                return {
                    style: getGuestCellTextStyle(guest),
                };
            },
            render: (usedBookingTypes: string[]) => {
                return (
                    <div className="used-booking-types text-truncate">
                        {usedBookingTypes &&
                            orderBy(usedBookingTypes, [], ['desc']).map(
                                (usedBookingType) => {
                                    return (
                                        <span
                                            className={usedBookingType}
                                            key={usedBookingType}
                                        >
                                            {usedBookingType ===
                                            UsedBookingType.ROOM_BOOKING ? (
                                                <RoomBookingIcon />
                                            ) : (
                                                <FacilityBookingIcon />
                                            )}
                                            {t(
                                                `guest.list.usedBookingType.${usedBookingType}`,
                                            )}
                                        </span>
                                    );
                                },
                            )}
                    </div>
                );
            },
        },
        {
            title: t('guest.list.guestColumn.emailAddress'),
            key: GuestColumn.EMAIL,
            sorter: true,
            width: '230px',
            ellipsis: true,
            dataIndex: GuestColumn.EMAIL,
            onCell: (guest: IGuest) => {
                return {
                    style: getGuestCellTextStyle(guest),
                };
            },
        },
        {
            title: t('guest.list.guestColumn.memo'),
            key: GuestColumn.MEMO,
            sorter: true,
            width: '200px',
            dataIndex: GuestColumn.MEMO,
            ellipsis: true,
            onCell: (guest: IGuest) => {
                return {
                    style: getGuestCellTextStyle(guest),
                };
            },
        },
        {
            title: t('guest.list.guestColumn.classification'),
            key: GuestColumn.CLASSIFICATION,
            sorter: true,
            width: '130px',
            onCell: (guest: IGuest) => {
                return {
                    style: getGuestCellTextStyle(guest),
                };
            },
            render: (guest: IGuest) => {
                return (
                    guest?.classification?.id && (
                        <Tag
                            className="guest-tag"
                            style={{
                                color: `#${guest?.classification?.color}`,
                                borderColor: `#${guest?.classification?.color}`,
                                backgroundColor: `${convertHexToRGB(
                                    guest?.classification?.color,
                                    0.1,
                                )}`,
                            }}
                        >
                            <div className="text-truncate">
                                {guest?.classification?.name}
                            </div>
                        </Tag>
                    )
                );
            },
        },
    ];

    return (
        <>
            <div className="guest-list-wrapper">
                <div className="guest-list-header">
                    <div className="guest-header-left">
                        <Button
                            type="text"
                            className="btn"
                            disabled={selectedRows.length < 1}
                        >
                            <DeleteOutlined onClick={showConfirmDialog} />
                        </Button>
                        <Button
                            className="merge-btn"
                            onClick={showMergeGuestsModal}
                            disabled={selectedRows.length < 2}
                        >
                            {t('guest.list.merge.mergeGuest')}
                        </Button>
                        <Tooltip title={t('guest.list.merge.buttonInfo')}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </div>
                    <div className="guest-header-right">
                        <CsvDropdown
                            onChange={onChangeCsvOption}
                            hasSelectedColumns={selectedRows.length > 0}
                            hasImportOption={false}
                        />
                        <Button type="text" className="btn">
                            <ReloadOutlined onClick={onRefresh} />
                        </Button>
                    </div>
                </div>
                <Table
                    className="guest-list-table"
                    columns={guestColumns}
                    onChange={onChange}
                    rowSelection={rowSelection}
                    pagination={false}
                    dataSource={guestList}
                    loading={showLoadingList}
                    scroll={{ x: 'scroll' }}
                    tableLayout={'fixed'}
                    rowKey="id"
                    onRow={onClickOneRow}
                />
            </div>
            {pageCount > 1 && (
                <Pagination
                    align="center"
                    defaultCurrent={guestListQuery.page}
                    current={guestListQuery.page}
                    total={totalGuests}
                    pageSize={guestListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                    className="guest-list-pagination"
                />
            )}
            <MergeGuestsModal
                selectedGuests={selectedRows}
                setSelectedRowKeys={setSelectedRowKeys}
            />
        </>
    );
}

export default GuestList;
