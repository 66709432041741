const login = {
    loginPageTitle: 'Login',
    email: 'Email Address *',
    inputEmail: 'Email',
    password: 'Password *',
    inputPassword: 'Password',
    forgotPassword: 'Forgot Password?',
    resetPassword: 'Reset Password',
    buttonLoginText: 'LOGIN',
    errorMessage: 'Incorrect username or password.',
    passwordAttempts: 'Password attempts exceeded',
};

export default login;
