import { InputSearch } from '~components';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import './FilterForm.scss';
import { useAppDispatch } from '~hooks';
import { onSearchByKeyword, setSearchKeyword } from '../../marketing-channel.reducer';
import SelectField from './SelectField/SelectField';
import { useNavigate } from 'react-router-dom';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, GA_KEYWORD_CONTENT, INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { trackSearchKeyword } from '~plugins/analytics';

function FilterForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([...AdminGroup]);
    const onSearch = (keyword: string) => {
        dispatch(onSearchByKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.MARKETING_CHANNEL);
    };
    const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchKeyword(event.target.value));
    };
    const addMarketingChannel = () => {
        navigate('/front-settings/marketing-channel/create-marketing-channel');
    };

    return (
        <div className="filter-marketing-channel-wrapper">
            <div className="marketing-channel-left-block">
                <InputSearch
                    onSearch={onSearch}
                    onChange={onChangeSearchField}
                    addonBefore={SelectField()}
                    max={INPUT_TEXT_MAX_LENGTH}
                    placeholder={t('marketingChannel.list.searchBox')}
                />
            </div>

            {!isReadOnly && (
                <div className="marketing-channel-btn">
                    <Button type="primary" onClick={addMarketingChannel}>
                        <PlusOutlined />
                        <span className="btn-text">
                            {t('marketingChannel.form.primaryButton')}
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
