import { useEffect } from 'react';
import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Button, Card, Col, Empty, Row, Spin } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import HotelSettingForm from '../HotelSettingForm/HotelSettingForm';
import BankAccountModal from '../BankAccountModal/BankAccountModal';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useBankAccounts } from '~features/hotel/hooks/useBankAccounts';
import { useDeleteBankAccount } from '~features/hotel/hooks/useDeleteBankAccount';
import {
    editingBankAccountSelector,
    setEditingBankAccount,
    setSelectedHotelSetting,
    setShowBankAccountModal,
} from '~features/hotel/hotel.reducer';
import { BankAccountResponseBody } from '~features/hotel/interfaces';
import localStorageAuthService from '~common/authStorage';
import { showConfirm } from '~common/notification';
import queryClient, { CacheKeys } from '~queries/queries';

import './HotelSettingDetail.scss';

interface IProps {
    control: Control;
    setValue: UseFormSetValue<FieldValues>;
    watch: UseFormWatch<FieldValues>;
    isEditing: boolean;
    onSubmit: () => void;
    onCancel: () => void;
}

function HotelSettingDetail({ control, setValue, watch, isEditing, onSubmit, onCancel }: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const editingBankAccount = useAppSelector(editingBankAccountSelector);
    const { isFetching, data: bankAccounts } = useBankAccounts();
    const { isPending: isDeletingBankAccount, mutateAsync } = useDeleteBankAccount();

    useEffect(() => {
        const selectedHotel = localStorageAuthService.getSelectedHotel();
        dispatch(setSelectedHotelSetting(selectedHotel));
    }, []);

    const onAdd = () => {
        dispatch(setShowBankAccountModal(true));
        dispatch(setEditingBankAccount(null));
    };

    const onEdit = (bankAccount: BankAccountResponseBody) => {
        dispatch(setShowBankAccountModal(true));
        dispatch(setEditingBankAccount(bankAccount));
    };

    const onDelete = (id: number) => {
        showConfirm({
            title: t('hotel.settingForm.bankAccount.titleDelete'),
            okText: t('hotel.settingForm.bankAccount.btnDelete'),
            cancelText: t('hotel.settingForm.bankAccount.cancel'),
            onOk() {
                mutateAsync(id)
                .then(() => {
                    queryClient.invalidateQueries({
                        queryKey: [CacheKeys.bankAccounts]
                    });;
                });
            },
        });
    };

    return (
        <div className="hotel-detail-form-wrapper">
            <Spin spinning={isFetching || isDeletingBankAccount}>
                <HotelSettingForm
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    isEditing={isEditing}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
                <Card
                    title={t('hotel.settingForm.bankAccount.title')}
                    extra={
                        <Button
                            className="btn-edit"
                            type="primary"
                            onClick={onAdd}
                            icon={<PlusOutlined />}
                        >
                            {t('hotel.settingForm.bankAccount.btnAdd')}
                        </Button>
                    }
                >
                    {bankAccounts?.length ? (
                        <Row gutter={[16, 16]}>
                            {bankAccounts?.map((bankAccount) => (
                                <Col span={12} key={bankAccount.id}>
                                    <Card
                                        title={bankAccount.bankName}
                                        extra={
                                            <div>
                                                <Button
                                                    type="text"
                                                    shape="circle"
                                                    icon={<EditOutlined />}
                                                    onClick={() => onEdit(bankAccount)}
                                                />
                                                <Button
                                                    type="text"
                                                    shape="circle"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => onDelete(bankAccount.id)}
                                                />
                                            </div>
                                        }
                                    >
                                        <Row>
                                            <Col span={12}>
                                                <span>{bankAccount.branchName}</span>
                                            </Col>
                                            <Col span={12}>
                                                <span>{bankAccount.accountNumber}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <span>{t(`hotel.settingForm.bankAccount.${bankAccount.accountType}`)}</span>
                                            </Col>
                                            <Col span={12}>
                                                <span>{bankAccount.accountOwner}</span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Empty description={t(`hotel.settingForm.bankAccount.noData`)} />
                    )}
                </Card>
                <BankAccountModal editingBankAccount={editingBankAccount} />
            </Spin>
        </div>
    );
}

export default HotelSettingDetail;
