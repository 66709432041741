import { useState } from 'react';
import { Button, Table } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import './PackagePlanCode.scss';
import PackagePlanCodeModal from './PackagePlanCodeModal/PackagePlanCodeModal';
import { useTranslation } from 'react-i18next';

export interface IPackagePlanCodeData {
    planCode: string;
}

const MOCK_DATA: IPackagePlanCodeData[] = [
    {
        planCode: '234234234324324-U2-01',
    },
    {
        planCode: '234234234324324-U2-02',
    },
];

function PackagePlanCode() {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPackagePlanCode, setSelectedPackagePlanCode] = useState('');
    const [packagePlanCodes, setPackagePlanCodes] =
        useState<IPackagePlanCodeData[]>(MOCK_DATA);

    const addPackagePlanCode = () => {
        setSelectedPackagePlanCode('');
        setIsModalOpen(true);
    };

    const editPackagePlanCode = (selectedPlanCode: string) => {
        setSelectedPackagePlanCode(selectedPlanCode);
        setIsModalOpen(true);
    };

    const removePackagePlanCode = (selectedPlanCode: string) => {
        const updatedPackagePlanCodes = packagePlanCodes.filter(
            (plan) => plan.planCode !== selectedPlanCode,
        );
        setPackagePlanCodes(updatedPackagePlanCodes);
    };

    const packagePlanCodeColumns = [
        {
            key: 'planCode',
            title: t('plan.create.packagePlanCode.tabLabel'),
            render: (item: IPackagePlanCodeData) => <div>{item.planCode}</div>,
        },
        {
            key: 'action',
            title: '',
            render: (item: IPackagePlanCodeData) => (
                <div className="table-action">
                    <Button
                        size="small"
                        type="text"
                        onClick={() => editPackagePlanCode(item.planCode)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        size="small"
                        type="text"
                        onClick={() => removePackagePlanCode(item.planCode)}
                    >
                        <DeleteOutlined />
                    </Button>
                </div>
            ),
            width: 300,
        },
    ];

    return (
        <>
            <div className="package-plan-code">
                <div className="table-header">
                    <div className="table-header__title">
                        {t('plan.create.packagePlanCode.title')}
                    </div>
                    <Button type="primary" onClick={() => addPackagePlanCode()}>
                        <PlusOutlined />
                        <span>{t('plan.create.packagePlanCode.addBtn')}</span>
                    </Button>
                </div>
                <Table
                    columns={packagePlanCodeColumns}
                    dataSource={packagePlanCodes}
                    rowKey="planCode"
                    pagination={false}
                />
            </div>
            <PackagePlanCodeModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                packagePlanCodes={packagePlanCodes}
                setPackagePlanCodes={setPackagePlanCodes}
                selectedPackagePlanCode={selectedPackagePlanCode}
            />
        </>
    );
}

export default PackagePlanCode;
