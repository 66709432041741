import React, { useCallback, useMemo } from 'react';
import './IndicatorSingleSelect.scss';
import { Select } from 'antd';
import { useAppSelector } from '~hooks';
import { roomDropDownListGroupSelector } from '~features/room/room.reducer';
import { IIndicatorDropDown } from '~features/room/interfaces';
import { indicatorSelector } from '~features/indicator/reducers/indicator.reducer';
import { facilityDropDownGroupSelector } from '~features/facility/facility.reducer';
import { IndicatorType } from '~features/indicator/constants';
import _ from 'lodash';
import { IIndicatorItemDropDown } from '~features/indicator/interfaces';
import { useTranslation } from 'react-i18next';

const { Option, OptGroup } = Select;

type Props = {
    onSelect: (value: IIndicatorDropDown) => void;
};

export const IndicatorSingleSelect = ({ onSelect }: Props) => {
    const roomList = useAppSelector(roomDropDownListGroupSelector);
    const facilityList = useAppSelector(facilityDropDownGroupSelector);
    const { assignedList, settingType } = useAppSelector(indicatorSelector);
    const { t } = useTranslation();
    const [keyword, setKeyword] = React.useState('');

    const inAssignList = useCallback(
        (child: IIndicatorDropDown) => {
            const _assignedList = assignedList[settingType];
            for (const key in _assignedList) {
                if (_assignedList[key].id === child.id) {
                    return true;
                }
            }
            return false;
        },
        [assignedList, settingType],
    );

    const list = useMemo(() => {
        const _list = settingType === IndicatorType.ROOM ? roomList : facilityList;
        const result: IIndicatorItemDropDown[] = [];
        _.forEach(_list, (item) => {
            const children = item.children?.filter(
                (child) =>
                    !inAssignList(child) &&
                    child?.name?.toLowerCase()?.includes(keyword?.toLowerCase()),
            );
            if (!children?.length) return;
            result.push({
                ...item,
                children,
            });
        });
        return result;
    }, [roomList, facilityList, settingType, keyword, inAssignList]);

    const handleChange = (value: string) => {
        for (let index = 0; index < Object.values(list).length; index++) {
            const item = Object.values(list)[index];
            for (let i = 0; i < item.children.length; i++) {
                const child = item.children[i];
                if (child.id === Number(value)) {
                    onSelect(child);
                    return;
                }
            }
        }
    };

    return (
        <div className="indicator-single-select-wrapper">
            <Select
                className="indicator-single-select"
                defaultOpen={true}
                showSearch
                autoFocus
                onChange={handleChange}
                popupClassName="indicator-single-select-popup"
                placement="bottomRight"
                placeholder={t('indicator.create.searchRoomName')}
                onSearch={(value) => setKeyword(value)}
                filterOption={() => true}
            >
                {list.map((item) => {
                    return (
                        <OptGroup key={item.id} label={item.name}>
                            {item.children.map((child) => {
                                return (
                                    <Option key={child.id} value={child.id}>
                                        {child.name}
                                    </Option>
                                );
                            })}
                        </OptGroup>
                    );
                })}
            </Select>
        </div>
    );
};
