import { Layout } from 'antd';
import React from 'react';
import { GuestLayoutHeader } from '~components';
import { Outlet } from 'react-router-dom';

function GuestLayout() {
    return (
        <Layout>
            <GuestLayoutHeader />
            <Outlet />
        </Layout>
    );
}

export default GuestLayout;
