import i18next from 'i18next';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';

export const breadcrumbs = () => [
    {
        text: i18next.t('paymentMethod.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('paymentMethod.list.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('paymentMethod.list.breadcrumbs.paymentMethod'),
    },
];

export enum PaymentMethodColumns {
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
}

export enum PaymentMethodOrderBy {
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
    ID = 'id',
}

export const DEFAULT_PAYMENT_METHOD_LIST_QUERY = {
    keyword: '',
    page: DEFAULT_FIRST_PAGE,
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: PaymentMethodOrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
};

export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    NAME = 'name',
}

export const CASH_PAYMENT_METHODS = '現金';
export const DEPOSIT = '事前入金分';
export const DEPOSIT_POINT = '事前入金分（ポイント)';

export const PREPAYPEMENT_METHODS = [DEPOSIT, DEPOSIT_POINT];
