const salesSummaryJa = {
    title: '売上明細',
    breadcrumbs: {
        report: '帳票',
        salesSummary: '売上明細',
    },
    filterForm: {
        toggleButton: 'グループ表示',
        aggregationMethod: {
            label: '集計方法',
            daily: '日別',
            month: '月間',
        },
        aggregationPeriod: '集計期間',
        filterButton: '表示',
    },
    salesSummaryColumn: {
        earnings: '売上',
        currentTerm: '当期',
        lastYear: '前年同期',
        comparison: '前年対比',
        saleItem: '科目',
        quantity: '個数',
        price: '売上',
    },
    total: '合計 (税込み)',
    unit: '¥',
    ungrouped: 'Ungrouped',
    stay_price: '室料',
    defaultName: 'N/A',
    tab: {
        roomBooking: '宿泊予約',
        facilityBooking: '設備予約',
    },
    receiptSummary: {
        uncollectedAmount: '未収金残高',
        accountReceivableAmount: '預かり金残高',
    },
};

export default salesSummaryJa;
