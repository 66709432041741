import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDropDownOption } from '~common/interfaces';
import { Checkbox, InputPrice, InputText, SingleSelect } from '~components';
import { MAX_SALE_ITEMS, SaleItemColumn } from '~features/sale-item/constants';
import { getSaleItemFormId } from '~features/sale-item/helper';
import { ISaleItemFormItem } from '~features/sale-item/interfaces';
import {
    newSaleItemListSelector,
    setNewSaleItemList,
    taxListDropdownSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './CreateSaleItemsFormSaleItemList.scss';

interface IProps {
    control: any;
    currentSaleItemList: ISaleItemFormItem[];
}

function CreateSaleItemsFormSaleItemList({ control, currentSaleItemList }: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const newSaleItemList = useAppSelector(newSaleItemListSelector);
    const taxList = useAppSelector(taxListDropdownSelector);
    const [defaultTax, setDefaultTax] = useState<IDropDownOption[]>([
        {
            value: 0,
            label: '',
        },
    ]);

    useEffect(() => {
        const searchDefaultTax = taxList.filter((tax) => {
            return tax.label === t('saleItem.create.formSetting.defaultTax');
        });
        if (searchDefaultTax.length !== 0) {
            setDefaultTax(searchDefaultTax);
        }
    }, [taxList]);

    const onClickButtonAdd = () => {
        dispatch(
            setNewSaleItemList([
                ...currentSaleItemList,
                {
                    feId: `${Date.now()}_${currentSaleItemList.length}`,
                    name: null,
                    price: null,
                    taxId:
                        defaultTax?.[0].value !== 0
                            ? defaultTax?.[0].value
                            : taxList?.[0]?.value,
                    isShowInBooking: false,
                },
            ]),
        );
    };

    const onClickButtonRemove = (saleItem: ISaleItemFormItem) => {
        dispatch(
            setNewSaleItemList(
                currentSaleItemList.filter(
                    (newSaleItem) => newSaleItem.feId !== saleItem.feId,
                ),
            ),
        );
    };

    return (
        <div className="create-sale-items-form-sale-item-list">
            <Form layout="vertical">
                <Table
                    dataSource={newSaleItemList}
                    pagination={false}
                    scroll={{ y: 500 }}
                    locale={{
                        emptyText: <div></div>,
                    }}
                    rowKey="feId"
                    footer={() => (
                        <Button
                            type="dashed"
                            className="add-sale-item-btn"
                            disabled={newSaleItemList.length >= MAX_SALE_ITEMS}
                            onClick={onClickButtonAdd}
                        >
                            <PlusCircleOutlined />
                            <span>
                                {t('saleItem.create.saleItemList.addSaleItemBtn')}
                            </span>
                        </Button>
                    )}
                >
                    <Column
                        align="center"
                        title={`${t('saleItem.create.saleItemList.id')}`}
                        width={50}
                        key={SaleItemColumn.ID}
                        render={(saleItem: ISaleItemFormItem, item, index) => {
                            return <div>{index + 1}</div>;
                        }}
                    />
                    <Column
                        align="left"
                        title={`${t('saleItem.create.saleItemList.name')}`}
                        key={SaleItemColumn.NAME}
                        render={(saleItem: ISaleItemFormItem, item, index) => {
                            return (
                                <InputText
                                    label=""
                                    name={`items.${index}.name`}
                                    placeholder={t(
                                        'saleItem.create.saleItemList.nameInput',
                                    )}
                                    control={control}
                                    required
                                    id={getSaleItemFormId(`items.${index}.name`)}
                                />
                            );
                        }}
                    />
                    <Column
                        align="left"
                        title={`${t('saleItem.create.saleItemList.price')}`}
                        key={SaleItemColumn.PRICE}
                        render={(saleItem: ISaleItemFormItem, item, index) => {
                            return (
                                <div>
                                    <InputPrice
                                        label=""
                                        placeholder={t(
                                            'saleItem.create.saleItemList.priceInput',
                                        )}
                                        name={`items.${index}.price`}
                                        control={control}
                                        required
                                        id={getSaleItemFormId(`items.${index}.price`)}
                                    />
                                </div>
                            );
                        }}
                    />
                    <Column
                        align="left"
                        width={'15%'}
                        title={`${t('saleItem.create.saleItemList.tax')}`}
                        key={SaleItemColumn.TAX}
                        render={(saleItem: ISaleItemFormItem, item, index) => {
                            return (
                                <SingleSelect
                                    label=""
                                    placeholder={t(
                                        'saleItem.create.saleItemList.taxSelect',
                                    )}
                                    name={`items.${index}.taxId`}
                                    control={control}
                                    showSearch={false}
                                    options={taxList}
                                    id={getSaleItemFormId(`items.${index}.taxId`)}
                                />
                            );
                        }}
                    />
                    <Column
                        align="center"
                        width={'13%'}
                        title={`${t('saleItem.create.saleItemList.isShowInBooking')}`}
                        key={SaleItemColumn.IS_SHOW_IN_BOOKING}
                        render={(saleItem: ISaleItemFormItem, item, index) => {
                            return (
                                <Checkbox
                                    label=""
                                    name={`items.${index}.isShowInBooking`}
                                    control={control}
                                    id={getSaleItemFormId(
                                        `items.${index}.isShowInBooking`,
                                    )}
                                />
                            );
                        }}
                    />
                    <Column
                        align="center"
                        width={60}
                        title={`${t('saleItem.create.saleItemList.deleteBtn')}`}
                        key={SaleItemColumn.ACTION}
                        render={(saleItem: ISaleItemFormItem, _, index) => {
                            return (
                                <div>
                                    <Button
                                        type="text"
                                        id={getSaleItemFormId(`items.${index}.delele`)}
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            onClickButtonRemove(saleItem);
                                        }}
                                    />
                                </div>
                            );
                        }}
                    />
                </Table>
            </Form>
        </div>
    );
}

export default CreateSaleItemsFormSaleItemList;
