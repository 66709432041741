export const fieldsJa = {
    relatedRoomTypeId: '部屋タイプ',
    standardCapacity: '標準 定員',
    abbreviation: '略称',
    connectingNumber: 'コネクト数',
    name: '表示名',
    quantity: '数量',
    initialRoomName: '開始番号',
    roomTypeId: '部屋タイプ',
    basicUsageHours: '基本利用時間',
    businessTimes: '営業時間',
    email: 'メール',
    password: 'パスワード',
    marketingChannelGroupId: '販売チャネルグループ名',
    linkageCode: '連携コード',
    linkageCodeDayUse: '連携コード（デイユース）',
    facilityTypeId: '設備タイプ',
    initialFacilityName: '開始番号',
    basePriceTaxIncluded: '基本料金',
    guestMobilePhoneNumber: '電話番号',
    representativeGuest: {
        yomigana: '宿泊者名前 (ヨミガナ)',
        fullName: '宿泊者名前',
        phone: '電話番号',
        email: 'メールアドレス',
    },
    reserverGuest: {
        yomigana: '宿泊者名前 (ヨミガナ)',
        fullName: '宿泊者名前',
        phone: '電話番号',
        email: 'メールアドレス',
    },
    checkInTime: 'チェックイン',
    checkOutTime: 'チェックアウト',
    marketingChannelId: '販売チャネル',
    picName: '担当者名',
    postalCode: '郵便番号',
    address: '住所',
    picEmailAddress: '連絡先メールアドレス',
    picPhoneNumber: '連絡先電話番号',
    numberOfRoom: '部屋数',
    standardCheckInTime: '標準チェックイン',
    standardCheckOutTime: '標準チェックアウト',
    standardTimes: '標準チェックイン | 標準チェックアウト',
    fullName: '氏名',
    emailAddress: 'メールアドレス',
    birthday: '生年月日',
    memo: '備考',
    otaMemo: 'OTA備考',
    companyName: '会社名',
    companyYomigana: '会社名（ヨミガナ）',
    companyPhoneNumber: '電話番号',
    companyEmailAddress: 'メールアドレス',
    companyPostalCode: '郵便番号',
    companyAddress: '住所',
    guestPhoneNumber: '電話番号',
    timeUnit: '時間単位',
    numberOfGuests: '人数',
    bookingTimes: '予約期間',
    mobilePhoneNumber: '電話番号',
    facilityId: '設備名',
    guestId: '予約者名 (ヨミガナ)',
    plan: 'プラン',
    peCsrHotelCode: '施設ID',
    roomType: '部屋タイプ',
    numberOfAdults: '大人',
    roomName: '部屋名',
    gender: '性別',
    yomigana: '氏名（ヨミガナ)',
    phone: '電話番号',
    channelId: 'チャネル/チャネルグループ',
    planId: 'プラン',
    planType: 'プランタイプ',
    taxId: '税設定',
    representativeGuestId: '宿泊者',
    childrenCount: '子供',
    stayingDate: '滞在期間',
    time: '滞在期間',
    tllPmsUserId: '連動用ID',
    tllPmsPassword: '連動用パスワード',
    tllSystemId: 'システム識別ID',
    tllBookingAcquisitionUrl: '予約情報取得URL',
    tllName: 'サイトコントローラー',
    price: '基本料金',
    tllRank: 'ランク',
    duration: '期間',
    planRankId: '料金カレンダー',
    taxIdPlan: '税設定',
    checkInOutTimes: 'チェックイン | チェックアウト',
    amount: '金額',
    saleItemId: '項目',
    roomTypeIds: '部屋タイプ',
    saleItemPrice: '標準価格',
    unitPrice: '単価',
    paymentRoomBookingItem: '精算部屋',
    paymentRoomBookingItemId: '精算部屋',
    paymentMethodId: '支払い方法',
    indicatorName: 'インジケーター名',
    priceValue: '料金',
    roomId: '部屋名',
    paymentAmount: '金額',
    stoppingRoomCount: '初期値',
    roomIds: '室名',
    rangeDay: 'Duration day',
    title: 'タイトル',
    notifyAt: '配信日時',
    tags: 'タイプ',
    hotelIds: 'ホテル指定',
    content: '詳細',
    children: '子供',
    planGroupCode: 'プラングループコード',
    date: '日付',
    serverName: '名前',
    smtpServer: 'SMTPサーバー',
    smtpEncryption: 'SMTP暗号化',
    smtpPort: 'SMTPポート',
    userName: 'ユーザー名',
    connectionSecurity: '接続セキュリティー',
    companyWebsite: 'ウェブサイト',
    remarks: '備考',
    roomTypeCode: '部屋タイプコード',
    bookingDate: '日付',
    classificationId: '識別設定',
    paymentMethodIds: 'Payment methods',
    reservationDuration: '予約期間',
    numberOfRooms: '部屋数',
    houseId: 'House ID',
    username: 'ユーザーID',
    checkedInDate: '送信開始チェックイン日',
    code: 'キオスク端末コード',
    keySystemId: 'システムID',
    bankName: '金融機関名',
    branchName: '支店名',
    accountType: '口座種別',
    accountNumber: '口座番号',
    accountOwner: '口座名義人名',
};
