import i18next from 'i18next';

export const breadcrumbs = () => [
    {
        text: i18next.t('siteController.breadcrumbs.home'),
    },
    {
        text: i18next.t('siteController.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('siteController.breadcrumbs.siteController'),
    },
];

export enum SiteControllerName {
    TL_LINCOLN = 'TLリンカーン',
}
