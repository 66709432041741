import marketingChannelListJa from './ja/marketing-channel-list.locale';
import marketingChannelFormJa from './ja/marketing-channel-form.locale';
import marketingChannelListEn from './en/marketing-channel-list.locale';
import marketingChannelFormEn from './en/marketing-channel-form.locale';

const marketingChannel = {
    ja: {
        list: marketingChannelListJa,
        form: marketingChannelFormJa,
    },
    en: {
        list: marketingChannelListEn,
        form: marketingChannelFormEn,
    },
};

export default marketingChannel;
