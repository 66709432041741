import { Col, Row } from 'antd';
import React from 'react';
import { ClockCard } from '../ClockCard/ClockCard';
import { BookingOverviewCard } from '../BookingOverviewCard/BookingOverviewCard';
import './DashboardHeader.scss';
import { OccupancyRateCard } from '../OccupancyRateCard/OccupancyRateCard';

const colClockCard = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 5,
    xl: 5,
    style: { marginBottom: 24 },
};

const colBookingOverviewCard = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 14,
    xl: 14,
    style: { marginBottom: 24 },
};

const colOccupancyRateCard = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 5,
    xl: 5,
    style: { marginBottom: 24 },
};

export const DashboardHeader = () => {
    return (
        <div className="dashboard-header-wrapper">
            <Row gutter={24}>
                <Col {...colClockCard}>
                    <ClockCard />
                </Col>
                <Col {...colBookingOverviewCard}>
                    <BookingOverviewCard />
                </Col>
                <Col {...colOccupancyRateCard}>
                    <OccupancyRateCard />
                </Col>
            </Row>
        </div>
    );
};
