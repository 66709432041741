const guestCreate = {
    title: 'Guest Information Creation',
    breadcrumbs: {
        home: 'Home',
        create: 'Guest Information Creation',
    },
    tabPane: {
        basicInfo: 'Basic Information',
        companyInfo: 'Company Information',
    },
};

export default guestCreate;
