import { IBodyResponse, ICommonListQuery } from '~common/interfaces';
import axiosService from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import {
    IRankCalendarBulkDeleteItem,
    IRankCalendarResponse,
    IRankCalendarDetail,
    IRankCalendarDropDownQuery,
    IUpdateStatusBody,
    IRankCalendarFormData,
} from '../interfaces';

class RankCalendarService extends ApiService {
    getList(queryString: ICommonListQuery) {
        return this._getList(queryString) as Promise<IRankCalendarResponse>;
    }

    getDetail(id: number) {
        return this._getDetail(id) as Promise<IBodyResponse<IRankCalendarDetail>>;
    }

    create(createBody: IRankCalendarFormData) {
        return this._create(createBody) as Promise<IBodyResponse<IRankCalendarFormData>>;
    }

    update(id: number, updateBody: IRankCalendarFormData) {
        return this._update(id, updateBody) as Promise<
            IBodyResponse<IRankCalendarFormData>
        >;
    }

    bulkDelete(bulkDeleteBody: IRankCalendarBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    getRankCalendarListForDropdown(params: IRankCalendarDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<any>;
    }

    updateStatus(id: number, updateBody: IUpdateStatusBody) {
        return this._patch(`${this.baseUrl}/${id}/status`, updateBody) as Promise<
            IBodyResponse<boolean>
        >;
    }
}

export const rankCalendarService = new RankCalendarService(
    { baseUrl: '/plan-rank' },
    axiosService,
);
