const facilityTypeForm = {
    title: 'Create Facility Type',
    updateTitle: 'Edit Facility Type',
    name: {
        label: 'Displayed name',
        placeholder: 'Ex: Single',
    },
    abbreviation: {
        label: 'Abbreviation',
        placeholder: 'Ex: S',
    },
    basicUsageTime: {
        label: 'Standard using time',
        placeholder: 'Standard using time',
    },
    businessTime: {
        label: 'Business hours',
        placeholder: {
            start: 'Start time',
            end: 'End time',
        },
    },
    salesItem: {
        label: 'Sales item',
        placeholder: 'Select sales item',
    },
    flatRate: {
        label: 'Fixed price',
        placeholder: '1000',
    },
    basicChargeHour: {
        label: 'Base price/ time unit',
        placeholder: '1000円',
    },
    switchFixedAmount: {
        label: 'Fixed price',
    },
    timeUnit: 'Time unit',
    hourUnit: 'hour',
    minutesUnit: 'minute',
    message: {
        create: {
            success: 'Successfully created facility type',
            error: 'Failed to create facility type',
        },
        update: {
            success: 'Successfully updated facility type',
        },
        nameDuplicated: 'Facility name already exists',
        abbreviationDuplicated: 'Abbreviation name already exists',
        durationError: 'Time unit is a required field',
        businessTimesError:
            'End time must be greater than Start time by at least 10 minutes',
    },
    buttons: {
        addSalesItem: 'Add sales item',
        submit: 'Create',
    },
    unitPrice: 'JPY',
};

export default facilityTypeForm;
