import React from 'react';
import { Radio as RadioAntd, Form, RadioGroupProps } from 'antd';
import { Controller } from '~plugins/hook-form';
import './RadioGroup.scss';
import { parseErrorMessage } from '~common/commonFunctions';

interface IRadioGroup extends RadioGroupProps {
    name: string;
    required: boolean;
    label: string;
    defaultValue: string | number;
    control: any;
    options: { label: string; value: string | number }[];
}

export function RadioGroup(props: IRadioGroup) {
    const { control, name, defaultValue, label, onChange, required, options, ...rest } =
        props;
    return (
        <div className="radio-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            required={required}
                        >
                            <RadioAntd.Group
                                {...rest}
                                value={field.value ? field.value : null}
                                ref={field.ref}
                                onChange={(...agrs) => {
                                    if (onChange) {
                                        onChange(...agrs);
                                    }
                                    field.onChange(...agrs);
                                }}
                            >
                                {options.map((option) => (
                                    <RadioAntd key={option.value} value={option.value}>
                                        {option.label}
                                    </RadioAntd>
                                ))}
                            </RadioAntd.Group>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

RadioGroup.defaultProps = {
    defaultValue: null,
    required: false,
    options: [],
    disableValues: [],
};
