export const cancellationPageEn = {
    titlePage: 'Cancellation and no-show details',
    breadcrumbs: {
        report: 'Report',
        cancellation: 'Cancellation and no-show details',
    },
    filterForm: {
        dateLabel: 'Date:',
        monthLabel: 'Month:',
        displayButton: 'Display',
        viewMode: {
            label: 'Payment method:',
            day: 'Per day',
            month: 'Per month',
        },
    },
    table: {
        date: 'Processing date',
        time: 'Processing time',
        registrationTime: 'Processing time',
        bookingId: 'Booking ID',
        no: 'No',
        createDate: 'Creation date',
        bookingMethod: 'Booking method',
        checkInDate: 'C/I',
        checkOutDate: 'C/O',
        numberOfLodgingNights: 'Number of nights',
        guestName: 'Guest name',
        cancellationReason: {
            label: 'Cancellation reason',
            noShow: 'No-show',
            cancelled: 'Cancel',
        },
        peopleInCharge: {
            label: 'PIC',
            noShow: 'Room closing',
        },
        memo: 'Memo',
        OTA: 'OTA',
        note: 'Remark',
        daysToCIn: 'Days to CI',
        updateSuccess: 'Update success',
    },
    exportColumns: {
        date: 'Processing date',
        time: 'Processing time',
        bookingId: 'Booking ID',
        createDate: 'Creation date',
        bookingMethod: 'Booking method',
        checkInDate: 'C/I date',
        numberOfLodgingNights: 'Number of nights',
        guestName: 'Guest name',
        cancellationReason: 'Cancellation reason',
        peopleInCharge: 'PIC',
        memo: 'Memo',
        daysToCheckInDate: 'Days to CI',
    },
};
