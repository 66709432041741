import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '~queries/queries';
import { almexIntegrationService } from '../services/almex-kiosk-integration.service';
import { HotelKey } from '../constants';
import type { AlmexKioskSettingResponse } from '../types';

const defaultSetting: AlmexKioskSettingResponse = {
    accessCode: '',
    enabled: false,
    keyType: HotelKey.None,
    terminals: [],
};

const getAlmexSetting = async (): Promise<AlmexKioskSettingResponse> => {
    const response = await almexIntegrationService.getAlmexKioskSetting();
    if (!response.data) return defaultSetting;
    return response.data;
};

export const useGetAlmexSetting = () => {
    return useQuery({
        queryKey: [CacheKeys.kioskAlmexSetting],
        queryFn: getAlmexSetting,
    });
};
