import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import { IPlanRoomTypePricing } from '~features/plan/interfaces';
import { TllRank, TllRankColors, TllRankDropdown } from '~common/constants';
import './PlanRoomTypePricingTable.scss';
import { isNumber } from 'lodash';

type Iprops = {
    dataTable: { feId: number; planRoomTypePricings: IPlanRoomTypePricing[] }[];
};
function PlanRoomTypePricingTable({ dataTable }: Iprops) {
    const { t } = useTranslation();
    return (
        <div className="plan-detail-pricing-table">
            <Table
                dataSource={dataTable}
                pagination={false}
                locale={{
                    emptyText: <div></div>,
                }}
                rowKey="feId"
                scroll={{ x: 1000 }}
                className="table-scrollbar"
            >
                <Column
                    align="left"
                    width={20}
                    title={`${t('plan.create.planRoomTypePricingsForm.rank')}`}
                    dataIndex=""
                    key="rank"
                    className="rank-column-people-color"
                    render={(pricingRoomTypeItem: IPlanRoomTypePricing, item, index) => {
                        return (
                            <div>
                                {dataTable[0]?.planRoomTypePricings[0].peopleCount === 0
                                    ? t('plan.create.planRoomTypePricingsForm.bulk')
                                    : `${index + 1}
                                ${t('plan.detail.tab2.planRoomTypePricing.person')}`}
                            </div>
                        );
                    }}
                />
                {TllRankDropdown.map((item, indexRank) => {
                    const colorIndex = Object.values(TllRank).indexOf(item.value);
                    return (
                        <Column
                            align="left"
                            width={90}
                            title={item.label}
                            onHeaderCell={() => {
                                return {
                                    style: {
                                        backgroundColor: `${TllRankColors[colorIndex]}`,
                                    },
                                };
                            }}
                            key={item.label}
                            render={(row: {
                                feId: number;
                                planRoomTypePricings: IPlanRoomTypePricing[];
                            }) => {
                                if (
                                    isNumber(
                                        row?.planRoomTypePricings?.[indexRank]?.price,
                                    )
                                ) {
                                    return (
                                        <div>
                                            {
                                                row?.planRoomTypePricings?.[indexRank]
                                                    ?.price
                                            }
                                            {t(
                                                'plan.detail.tab2.planRoomTypePricing.manJa',
                                            )}
                                        </div>
                                    );
                                } else {
                                    return <div></div>;
                                }
                            }}
                        />
                    );
                })}
            </Table>
        </div>
    );
}

export default PlanRoomTypePricingTable;
