import React from 'react';
import ForbiddenImage from '../../components/ForbiddenImage/ForbiddenImage';
import { Layout } from 'antd';
import './ForbiddenPage.scss';
import ForbiddenContent from '~features/error/components/ForbiddenContent/ForbiddenContent';

function ForbiddenPage() {
    return (
        <Layout className="forbidden-page">
            <Layout.Content>
                <div className="forbidden-page-wrapper">
                    <div className="forbidden-page-content">
                        <ForbiddenImage />
                        <ForbiddenContent />
                    </div>
                </div>
            </Layout.Content>
        </Layout>
    );
}

export default ForbiddenPage;
