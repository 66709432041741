const facilityTypeList = {
    title: 'Facility type setting',
    breadcrumbs: {
        home: 'Home',
        facilityType: 'Facility type setting',
    },
    filterForm: {
        buttons: {
            create: 'Create facility type',
        },
    },
    yen: 'JPY',
    hour: 'hour',
    minute: 'minute',
    columns: {
        id: 'ID',
        name: 'Facility type name',
        abbreviation: 'Abbreviation',
        businessTime: 'Business hours',
        price: 'Fixed price',
        duration: 'Base price',
        basicCharge: '{{price}} JPY/{{hour}} hour {{minute}} minute',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Facility type name',
        abbreviation: 'Abbreviation',
    },
    deleteFacilityTypesSuccess: 'Facility Types have been deleted successful',

    exportColumns: {
        autoGeneratedCode: 'ID',
        name: 'Facility type name',
        abbreviation: 'Abbreviation',
        businessTimes: 'Business hours',
        price: 'Fixed price',
        duration: 'Base price',
    },
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
    },
};

export default facilityTypeList;
