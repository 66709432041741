import { INPUT_TEXT_MAX_LENGTH, Regex } from '~common/constants';
import {
    MAX_FIXED_PRICE_VALUE,
    MAX_PRICE_PER_ADULT_VALUE,
    PriceType,
} from '~features/children-type/constants';
import yup from '~plugins/yup';

export const childrenTypeSchema = yup.object().shape({
    name: yup
        .string()
        .hasNoSpecialCharacters(Regex.CHILDREN_TYPE_SPECIAL_CHARACTERS)
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .required(),
    priceType: yup.mixed<PriceType>().oneOf(Object.values(PriceType)),
    priceValue: yup
        .string()
        .required()
        .when('priceType', {
            is: PriceType.FIXED,
            then: (schema) => schema.maxFullAndHalfWidth(MAX_FIXED_PRICE_VALUE),
            otherwise: (schema) => schema.maxFullAndHalfWidth(MAX_PRICE_PER_ADULT_VALUE),
        }),
});
