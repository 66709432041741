import React from 'react';
import { InputSearch } from '~components';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import './FilterForm.scss';
import { useAppDispatch } from '~hooks';
import { setShowForm, setKeyword } from '../../../../guest-classification.reducer';
import { getGuestClassFormId } from '~features/guest-classification/helper';
import {
    AdminGroup,
    GA_KEYWORD_CONTENT,
    INPUT_TEXT_MAX_LENGTH,
    UserGroup,
} from '~common/constants';
import { trackSearchKeyword } from '~plugins/analytics';
import { checkUserPermission } from '~common/commonFunctions';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.GUEST_CLASSIFICATION);
    };
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const addGuestTag = () => {
        dispatch(setShowForm(true));
    };
    return (
        <div className="filter-guest-classification-wrapper">
            <div className="guest-classification-left-block">
                <span className="search-label">
                    {t('guestClassification.list.textSearch')}
                </span>
                <InputSearch
                    onSearch={onSearch}
                    maxLength={INPUT_TEXT_MAX_LENGTH}
                    placeholder={t('guestClassification.list.searchBox')}
                />
            </div>

            <div className="guest-classification-btn">
                {!isReadOnly && (
                    <Button
                        type="primary"
                        id={getGuestClassFormId('btn-create')}
                        onClick={addGuestTag}
                    >
                        <PlusOutlined />
                        <span className="btn-text">
                            {t('guestClassification.list.primaryButton')}
                        </span>
                    </Button>
                )}
            </div>
        </div>
    );
}

export default FilterForm;
