import { keycardSettingEn } from './en/integrations';
import { keycardSettingJa } from './ja/integrations';

export const keycardSetting = {
    ja: {
        keycard: keycardSettingJa,
    },
    en: {
        keycard: keycardSettingEn,
    },
};
