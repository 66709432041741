import React, { ReactNode } from 'react';

interface IDraggableComponent {
    children: ReactNode;
    index: number;
    handleOnDragStart: (index: number) => void;
    handleOnDragEnter: (index: number) => void;
    handleOnDrop: () => void;
    handleOnDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
}

const DraggableComponent: React.FC<IDraggableComponent> = ({
    children,
    index,
    handleOnDragStart,
    handleOnDragEnter,
    handleOnDrop,
    handleOnDragOver,
}) => {
    return (
        <div
            draggable
            onDragStart={() => handleOnDragStart(index)}
            onDragEnter={() => handleOnDragEnter(index)}
            onDragOver={handleOnDragOver}
            onDrop={() => handleOnDrop()}
        >
            {children}
        </div>
    );
};

export default DraggableComponent;
