export const paymentMethodListEn = {
    title: 'Payment method setting',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        paymentMethod: 'Payment method setting',
    },
    filterForm: {
        buttons: {
            create: 'Add payment method',
        },
    },
    columns: {
        id: 'ID',
        paymentMethod: 'Method',
    },
    delete: {
        title: 'Delete confirmation',
        description: 'Do you want to delete following payment methods?',
        success: 'Payment method has been deleted',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Method',
    },
    confirm: {
        cantDelete: 'Can not delete default payment method',
    },
};
