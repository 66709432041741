import {
    ClockCircleOutlined,
    CloseSquareOutlined,
    ExportOutlined,
    EditOutlined,
    ImportOutlined,
    RightOutlined,
    StopOutlined,
} from '@ant-design/icons';
import { Button, Card, Modal, notification, Popover, Radio, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { checkUserPermission, formatMoney } from '~common/commonFunctions';
import { showConfirm } from '~common/notification';
import { TextTruncate } from '~components';
import {
    IGetBookingPrice,
    IRoomBookingItem,
    IRoomBookingSchedule,
    IUpdateBookingItemStatus,
    IRepresentativeGuest,
} from '~features/room-booking/interfaces';
import {
    createBookingStateSelector,
    getBookingPrice,
} from '~features/room-booking/reducers/create-booking.reducer';
import {
    fetchRoomBookingDetail,
    getRoomBookingReceipt,
    setActiveDetailTabPane,
    showLoadingSelector,
    updateBookingItemStatus,
} from '~features/room-booking/reducers/room-booking.reducer';
import {
    getStatisticByDate,
    scheduleStateSelector,
    setSelectedRoomBookingSchedule,
    setShowCheckInForm,
    setVisibleCreateBookingPopover,
    unassignBookingItemAndTmp,
    updateBookingItemAndTmp,
} from '~features/room-booking/reducers/schedule.reducer';
import {
    getRoomListStatus,
    updateCleaningStatus,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { CustomEmitter } from '~plugins/mitt';
import {
    RoomBookingDetailPageTabPane,
    RoomBookingEvent,
    RoomBookingItemBookingStatus,
    CleaningStatus,
} from '../../../../constants';
import './DetailBookingModal.scss';
import { getListForDropDown } from '~features/room/room.reducer';
import { showRoomBookingItemDetailModal } from '~features/room-booking/util';
import { convertBookingItemToScheduleItem } from '~features/room-booking/helper';
import { isFrozenBooking } from '~features/room-booking/helper.update-booking';
import { UserGroup } from '~common/constants';
import { useEscape } from '~common/useHooks';

type Props = {
    onClose?: () => void;
    booking: IRoomBookingSchedule;
    navigate?: NavigateFunction;
    emitter?: CustomEmitter;
    onUnassignBooking?: (booking: IRoomBookingSchedule) => void;
    onUnassignBookingSuccess?: (booking: IRoomBookingSchedule) => void;
    onUnassignBookingError?: (booking: IRoomBookingSchedule) => void;
    isOpen?: boolean;
    needGetPrice?: boolean;
    onClickChekinButton?: () => void;
    onChangeBookingStatus?: (booking: IRoomBookingSchedule) => void;
    isShowEditBtn?: boolean;
    element?: HTMLElement;
    onUpdateBookingSuccess?: (booking: IRoomBookingItem) => void;
    isReadOnly?: boolean;
    position?: { pageX: number; pageY: number };
};

export const DetailBookingModal = ({
    onClose,
    booking,
    navigate,
    emitter,
    onUnassignBooking,
    onUnassignBookingSuccess,
    onUnassignBookingError,
    isOpen,
    needGetPrice,
    onClickChekinButton,
    onChangeBookingStatus,
    isShowEditBtn,
    element,
    onUpdateBookingSuccess,
    isReadOnly,
    position,
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isAdmin = useMemo(() => {
        return checkUserPermission([UserGroup.ADMIN]);
    }, []);
    const [price, setPrice] = React.useState(booking?.individualUnpaidAmount || 0);
    const [isOpenPopover, setOpenPopover] = React.useState(true);
    const [cleanStatus, setCleanStatus] = React.useState(
        booking.room?.cleaningStatus || CleaningStatus.UNCLEANED,
    );

    const { isCreatingBookingItem } = useAppSelector(scheduleStateSelector);
    const { isCheckingPlan, focusBooking } = useAppSelector(createBookingStateSelector);
    const isShowLoadingFetchDetail = useAppSelector(showLoadingSelector);
    useEscape(() => {
        onClose && onClose();
    });

    const _updateBookingItemStatus = useCallback(
        async (body: IUpdateBookingItemStatus) => {
            const response = await dispatch(updateBookingItemStatus(body));
            if (updateBookingItemStatus.fulfilled.match(response)) {
                if (response.payload?.success) {
                    onClose && onClose();
                    notification.success({
                        message: t('roomBooking.list.checkoutDialog.checkoutSuccess'),
                    });
                    dispatch(
                        updateBookingItemAndTmp({
                            bookings: [{ ...booking, status: body.bookingStatus }],
                        }),
                    );
                    onChangeBookingStatus?.({ ...booking, status: body.bookingStatus });
                } else {
                    showConfirm({
                        title: t('roomBooking.list.checkoutDialog.unpaidTitle'),
                        content: t('roomBooking.list.checkoutDialog.content'),
                        okText: t('roomBooking.list.checkoutDialog.okText'),
                        cancelText: t('roomBooking.list.checkoutDialog.cancelText'),
                        onOk() {
                            if (navigate) {
                                dispatch(
                                    setActiveDetailTabPane(
                                        RoomBookingDetailPageTabPane.ROOM_BOOKING_RECEIPT_TAB_PANE,
                                    ),
                                );
                                if (booking.roomBookingId) {
                                    dispatch(
                                        getRoomBookingReceipt(booking.roomBookingId),
                                    );
                                }
                                navigate(`/room-booking/${booking.roomBookingId}/detail`);
                            }
                        },
                    });
                }
            }
        },
        [booking, dispatch, onClose, t, navigate],
    );

    const _getBookingPrice = async () => {
        if (!booking.isTmp || booking.price || !needGetPrice) return;
        const roomTypeId = booking.roomType?.id || 0;
        const planId = booking.plan.id || 0;
        const numberOfAdults = booking.numberOfAdults || 0;
        const query: IGetBookingPrice = {
            roomTypeId: roomTypeId,
            startDateOfStay: booking.stayingStartDate,
            endDateOfStay: booking.stayingEndDate,
            numberOfAdults: Number(numberOfAdults),
            planId: planId,
            childrenTypeQuantities: booking.children.map((item) => ({
                childrenTypeId: item.typeId as number,
                quantity: item.quantity as number,
            })),
        };
        const response = await dispatch(getBookingPrice(query));
        if (getBookingPrice.fulfilled.match(response)) {
            const { data } = response.payload;
            if (data) {
                const { amount = 0 } = data;
                setPrice(amount);
            } else {
                notification.error({
                    message:
                        response?.payload?.message ||
                        t('roomBooking.createBooking.message.calculateAmountError'),
                });
            }
        }
    };

    const _updateCleaningStatus = async (val: CleaningStatus) => {
        const response = await dispatch(
            updateCleaningStatus({
                id: booking.room.id || 0,
                cleaningStatus: val,
                memo: '',
            }),
        );
        if (updateCleaningStatus.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomBooking.updateBooking.update.success'),
                });
                setCleanStatus(val);
                dispatch(
                    updateBookingItemAndTmp({
                        bookings: [
                            {
                                ...booking,
                                room: { ...booking.room, cleaningStatus: val },
                            },
                        ],
                    }),
                );
                dispatch(getRoomListStatus());
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };

    const _showConfirmUpdateCleaningStatus = (val: CleaningStatus) => {
        showConfirm({
            title: t('roomBooking.list.update.cleaningStatusTitle'),
            cancelText: t('roomBooking.list.statusPopConfirm.cancelText'),
            okText: t('roomBooking.list.statusPopConfirm.okText'),
            onOk() {
                _updateCleaningStatus(val);
            },
        });
    };

    const onChangeStatus = async (val: CleaningStatus) => {
        if (val === CleaningStatus.CLEANED) {
            onClose && onClose();
            _showConfirmUpdateCleaningStatus(val);
        } else _updateCleaningStatus(val);
    };

    useEffect(() => {
        _getBookingPrice();
        dispatch(setVisibleCreateBookingPopover(false));
    }, []);

    useEffect(() => {
        emitter?.on(RoomBookingEvent.BOOKING_DELETED, (ids) => {
            if (ids.includes(booking.id)) {
                onClose && onClose();
            }
        });
        return () => {
            emitter?.off(RoomBookingEvent.BOOKING_DELETED);
        };
    }, [emitter, booking, onClose]);

    const changeStatusCheckout = () => {
        _updateBookingItemStatus({
            bookingStatus: RoomBookingItemBookingStatus.CHECKED_OUT,
            ids: [booking.id],
        });
    };

    const showChangeStatusCheckOutConfirmDialog = () => {
        showConfirm({
            zIndex: 1200,
            okText: t('roomBooking.list.checkoutModalConfirm.okText'),
            cancelText: t('roomBooking.list.checkoutModalConfirm.cancelText'),
            title: t('roomBooking.list.checkoutModalConfirm.title'),
            content: t('roomBooking.list.checkoutModalConfirm.content', {
                status: t(
                    `roomBooking.page.bookingStatus.${RoomBookingItemBookingStatus.CHECKED_OUT}`,
                ),
            }),
            onOk() {
                changeStatusCheckout();
            },
        });
    };

    const changeStatusCancel = () => {
        showConfirm({
            zIndex: 1200,
            okText: t('roomBooking.list.checkoutModalConfirm.okText'),
            cancelText: t('roomBooking.list.checkoutModalConfirm.cancelText'),
            title: t('roomBooking.list.cancelModalConfirm.title'),
            wrapClassName: 'cancel-room-booking-modal',
            onOk() {
                _updateBookingItemStatus({
                    bookingStatus: RoomBookingItemBookingStatus.CANCELLED,
                    ids: [booking.id],
                });
                dispatch(getRoomListStatus());
            },
        });
    };

    const changeStatusNotArrive = () => {
        showConfirm({
            zIndex: 1200,
            okText: t('roomBooking.list.notArriveModalConfirm.okText'),
            cancelText: t('roomBooking.list.notArriveModalConfirm.cancelText'),
            content: t('roomBooking.list.notArriveModalConfirm.content'),
            title: t('roomBooking.list.notArriveModalConfirm.title'),
            wrapClassName: 'cancel-room-booking-modal',
            onOk() {
                _updateBookingItemStatus({
                    bookingStatus: RoomBookingItemBookingStatus.NOT_ARRIVED,
                    ids: [booking.id],
                });
                dispatch(getRoomListStatus());
            },
        });
    };

    const date = useMemo(() => {
        const start = parseDate(booking.stayingStartDate)?.fmYYYYMMDD('/');
        const end = parseDate(booking.stayingEndDate)?.fmYYYYMMDD('/');
        if (
            booking.status === RoomBookingItemBookingStatus.NOT_ARRIVED &&
            booking.isDayUse
        ) {
            return (
                <span className="room-booking-date-is-day-use">
                    {`${start} (${t('common.standardTimeTitle')})`}
                    <span className="room-booking-date-day-use">
                        <ClockCircleOutlined className="room-booking-day-use-icon" />
                        {t('roomBooking.detail.detailBookingSchedule.dayUse')}
                    </span>
                </span>
            );
        } else {
            return `${start} - ${end} (${t('common.standardTimeTitle')})`;
        }
    }, [booking, t]);

    const showConfirmUnassignRoom = () => {
        onClose?.();
        showConfirm({
            title: t('roomManagement.list.unassignDialog.title'),
            content: t('roomManagement.list.unassignDialog.content'),
            cancelText: t('roomManagement.list.unassignDialog.cancelText'),
            okText: t('roomManagement.list.unassignDialog.okText'),
            onOk: unassignRoom,
            okButtonProps: { danger: true },
        });
    };

    const unassignRoom = async () => {
        onUnassignBooking?.(booking);
        onClose?.();
        const response = await dispatch(
            unassignBookingItemAndTmp({
                ...booking,
                room: {
                    name: '',
                    id: null,
                    cleaningStatus: CleaningStatus.UNCLEANED,
                },
            }),
        );
        if (unassignBookingItemAndTmp.fulfilled.match(response)) {
            if (response.payload?.success) {
                onUnassignBookingSuccess?.(booking);
                return;
            }
            Modal.error({
                title: t('common.somethingWentWrong'),
                content: response.payload?.message || '',
                centered: true,
            });
            onUnassignBookingError?.(booking);
        }
    };

    const cleanOptions = useMemo(() => {
        return Object.values(CleaningStatus).map((item) => {
            return {
                label: t(`roomBooking.detail.detailBookingSchedule.${item}`),
                value: item,
            };
        });
    }, [t]);

    const showCheckInForm = () => {
        const {
            id,
            stayingStartDate,
            stayingEndDate,
            checkInTime,
            checkOutTime,
            isDayUse,
        } = booking;
        if (parseDate(stayingStartDate).isAfter(customDayjs(), 'day')) {
            notification.error({
                message: t('roomBooking.schedule.message.cannotCheckIn.fromNotArrived', {
                    date: parseDate(stayingStartDate)?.fmYYYYMMDD('-'),
                }),
            });
            return;
        }

        dispatch(setShowCheckInForm(true));
        dispatch(setSelectedRoomBookingSchedule(booking));
        // get room drop down
        dispatch(
            getListForDropDown({
                roomBookingItemId: Number(id) || 0,
                isDayUse,
                roomTypeId: booking.roomType?.id || undefined,
                roomBookingStayPeriod: [
                    parseDate(`${stayingStartDate} ${checkInTime}`)
                        .startOf('minute')
                        .fmYYYYMMDDHHmmss(),
                    parseDate(`${stayingEndDate} ${checkOutTime}`)
                        .startOf('minute')
                        .fmYYYYMMDDHHmmss(),
                ],
            }),
        );
        if (onClose) onClose();
        if (onClickChekinButton) {
            onClickChekinButton();
        }
    };

    const openEditForm = async () => {
        if (!booking.roomBookingId || !element) return;
        const el = document.createElement('div');
        el.style.left = (position?.pageX || 0) + 'px';
        el.style.top = (position?.pageY || 0) + 'px';
        el.style.position = 'absolute';
        document.body.appendChild(el);

        const response = await dispatch(fetchRoomBookingDetail(booking.roomBookingId));
        if (fetchRoomBookingDetail.fulfilled.match(response)) {
            if (response.payload?.success) {
                const roomBookingDetail = response.payload?.data;
                const bookingItem = roomBookingDetail.roomBookingItems?.find(
                    (item) => item.id === booking.id,
                );
                if (!bookingItem) return;
                const isFrozen = isFrozenBooking(
                    roomBookingDetail.roomBookingItems || [],
                );
                showRoomBookingItemDetailModal({
                    bookingItem,
                    roomBookingId: roomBookingDetail?.id || 0,
                    isFromTll: !!roomBookingDetail?.tllDataId,
                    element: el,
                    placement: 'left',
                    isSingleBooking: roomBookingDetail.roomBookingItems?.length === 1,
                    isFrozen,
                    onUpdateSuccess: (body) => {
                        let representativeGuest = null;
                        if (body.representativeGuestId) {
                            representativeGuest = roomBookingDetail.guests?.find(
                                (guest) => guest.id === body.representativeGuestId,
                            );
                        }
                        const updateBooking = convertBookingItemToScheduleItem(booking, {
                            ...body,
                            representativeGuest:
                                (representativeGuest as IRepresentativeGuest) ||
                                roomBookingDetail.representativeGuest,
                        });
                        dispatch(
                            updateBookingItemAndTmp({
                                bookings: [updateBooking],
                            }),
                        );
                        if (
                            body.startDateOfStay !== bookingItem.startDateOfStay ||
                            body.endDateOfStay !== bookingItem.endDateOfStay
                        ) {
                            dispatch(getStatisticByDate());
                        }
                        onUpdateBookingSuccess?.(body);
                    },
                    onUpdateStatusSuccess: (body) => {
                        dispatch(
                            updateBookingItemAndTmp({
                                bookings: [
                                    {
                                        ...booking,
                                        status: body.bookingStatus as RoomBookingItemBookingStatus,
                                    },
                                ],
                            }),
                        );
                        onChangeBookingStatus?.({
                            ...booking,
                            status: body.bookingStatus as RoomBookingItemBookingStatus,
                        });
                    },
                    onClose: () => {
                        onClose?.();
                        el.remove();
                    },
                });
                setOpenPopover(false);
            }
        }
    };

    const renderContent = () => {
        const { status, roomBookingId } = booking;
        return (
            <Card className="detail-booking-card">
                <div className="detail-booking-card-header">
                    <div className="header-left w-100">
                        <TextTruncate text={booking.room.name} />
                    </div>
                    {!isReadOnly && (
                        <div className="header-right">
                            <Button
                                type="text"
                                onClick={showConfirmUnassignRoom}
                                loading={isCreatingBookingItem}
                            >
                                <CloseSquareOutlined />
                                {t(
                                    'roomBooking.detail.detailBookingSchedule.unassignRoomBtn',
                                )}
                            </Button>
                        </div>
                    )}
                </div>
                <div className="detail-booking-card-content pb-20">
                    <div className="detail-booking-card-row">
                        <TextTruncate text={booking.guest?.yomigana || ''} />
                    </div>
                    <div className="detail-booking-card-row">
                        <span>{date}</span>
                    </div>
                    <div className="detail-booking-card-row">
                        <TextTruncate text={booking.plan?.name || ''} />
                    </div>
                    <div className="detail-booking-card-row">
                        <Spin
                            spinning={isCheckingPlan && !!focusBooking[booking.id]}
                            size="small"
                        >
                            <span>
                                ￥
                                {formatMoney(
                                    (needGetPrice
                                        ? price
                                        : booking.individualUnpaidAmount) || 0,
                                )}
                            </span>
                        </Spin>
                    </div>
                    {!booking.isTmp && !isReadOnly && (
                        <div className="detail-booking-card-row">
                            <Button
                                type="text"
                                className="detail-btn"
                                onClick={() => {
                                    onClose && onClose();
                                    window.open(
                                        `/room-booking/${booking.roomBookingId}/detail`,
                                    );
                                }}
                            >
                                {t(
                                    'roomBooking.detail.detailBookingSchedule.showTheDetails',
                                )}
                                <RightOutlined />
                            </Button>
                        </div>
                    )}
                    <div className="detail-booking-card-row pt-17 d-flex j-between a-center">
                        <Radio.Group
                            options={cleanOptions}
                            value={cleanStatus}
                            onChange={(e) => {
                                onChangeStatus(e.target.value);
                            }}
                        />
                        {isShowEditBtn && !booking.isTmp && (
                            <Button
                                loading={isShowLoadingFetchDetail}
                                type="link"
                                icon={<EditOutlined />}
                                onClick={openEditForm}
                                disabled={
                                    !isAdmin && // Allow ADMIN user to edit regardless the status
                                    (status ===
                                        RoomBookingItemBookingStatus.CHECKED_OUT ||
                                        status === RoomBookingItemBookingStatus.CANCELLED)
                                }
                            />
                        )}
                    </div>
                </div>
                {!isReadOnly && (
                    <div className="detail-booking-card-footer">
                        {!!roomBookingId &&
                            status === RoomBookingItemBookingStatus.NOT_ARRIVED && (
                                <Space className="d-flex j-end">
                                    <Button onClick={changeStatusCancel}>
                                        <StopOutlined />
                                        {t(
                                            'roomBooking.detail.detailBookingSchedule.cancelReservationBtn',
                                        )}
                                    </Button>
                                    <Button type="primary" onClick={showCheckInForm}>
                                        <ImportOutlined rotate={180} />
                                        {t(
                                            'roomBooking.detail.detailBookingSchedule.checkInBtn',
                                        )}
                                    </Button>
                                </Space>
                            )}
                        {status === RoomBookingItemBookingStatus.CHECKED_IN && (
                            <Space className="d-flex j-end">
                                <Button type="primary" onClick={changeStatusNotArrive}>
                                    <ImportOutlined />
                                    {t(
                                        'roomBooking.detail.detailBookingSchedule.notArriveBtn',
                                    )}
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={showChangeStatusCheckOutConfirmDialog}
                                >
                                    <ExportOutlined />
                                    {t(
                                        'roomBooking.detail.detailBookingSchedule.checkOutBtn',
                                    )}
                                </Button>
                            </Space>
                        )}
                        {status === RoomBookingItemBookingStatus.CHECKED_OUT && (
                            <Space className="d-flex j-end">
                                <Button type="primary" onClick={showCheckInForm}>
                                    <ImportOutlined rotate={180} />
                                    {t(
                                        'roomBooking.detail.detailBookingSchedule.checkInBtn',
                                    )}
                                </Button>
                            </Space>
                        )}
                    </div>
                )}
            </Card>
        );
    };

    return (
        <Popover
            content={renderContent()}
            trigger="click"
            open={isOpen && isOpenPopover}
            overlayClassName="detail-booking-modal"
            onOpenChange={onClose}
            placement="left"
        ></Popover>
    );
};

DetailBookingModal.defaultProps = {
    isOpen: true,
    needGetPrice: true,
};
