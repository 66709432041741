import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    id: number;
    autoGeneratedCode: string;
};

export const BoxDragPreview = ({ id, autoGeneratedCode }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="booking-unassign-preview">
            {t('roomManagement.list.bookingId', { id: autoGeneratedCode })}
        </div>
    );
};
