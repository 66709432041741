import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { Button, Radio } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DatePicker } from '~components';
import {
    AggregationMethod,
    ReportTableCsvFileName,
    ReportTableId,
} from '~features/report/constants';
import { DatePickerType } from '~common/constants';
import { ReceiptItemBookingType } from '~features/fo-operation-history/constants';
import { exportTableToCSV } from '~features/report/helper';
import { IPaymentSummaryData } from '~features/report/interfaces';
import './PaymentSummaryFilter.scss';

type Props = {
    data: IPaymentSummaryData[] | undefined;
    isLoading: boolean;
    isFetching: boolean;
    bookingType: ReceiptItemBookingType;
    aggregationMethod: AggregationMethod;
    date: Dayjs | null;
    refetch: () => void;
    setBookingType: (bookingType: ReceiptItemBookingType) => void;
    setDate: (date: Dayjs | null) => void;
    setAggregationMethod: (aggregationMethod: AggregationMethod) => void;
};

export default function FilterPaymentSummary(props: Props) {
    const {
        data,
        isLoading,
        isFetching,
        bookingType,
        aggregationMethod,
        date,
        refetch,
        setBookingType,
        setAggregationMethod,
        setDate,
    } = props;
    const { t } = useTranslation();

    return (
        <div>
            <Radio.Group
                optionType="button"
                options={[
                    {
                        label: t('report.salesSummary.tab.roomBooking'),
                        value: ReceiptItemBookingType.ROOM_BOOKING_ITEM,
                        style: { borderBottomLeftRadius: 0 },
                    },
                    {
                        label: t('report.salesSummary.tab.facilityBooking'),
                        value: ReceiptItemBookingType.FACILITY_BOOKING,
                        style: { borderBottomRightRadius: 0 },
                    },
                ]}
                value={bookingType}
                onChange={(e) => setBookingType(e.target.value)}
            />
            <div className="payment-summary-filter">
                <div className="filter-date">
                    <div>
                        <span>
                            {t(
                                'report.paymentSummary.filterForm.aggregationMethod.label',
                            )}
                            :&nbsp;
                        </span>
                        <Radio.Group
                            value={aggregationMethod}
                            onChange={(e) => setAggregationMethod(e.target.value)}
                        >
                            {Object.values(AggregationMethod).map((value) => (
                                <Radio.Button value={value} key={value}>
                                    {t(
                                        `report.paymentSummary.filterForm.aggregationMethod.${value}`,
                                    )}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </div>

                    <div>
                        <span>
                            {aggregationMethod === AggregationMethod.DAILY
                                ? t('report.paymentSummary.filterForm.dateLabel')
                                : t('report.paymentSummary.filterForm.monthLabel')}
                            :&nbsp;
                        </span>
                        <DatePicker
                            allowClear={false}
                            placeholder={
                                aggregationMethod === AggregationMethod.DAILY
                                    ? t('report.placeholder.selectDate')
                                    : t('report.placeholder.selectMonth')
                            }
                            picker={
                                aggregationMethod === AggregationMethod.DAILY
                                    ? DatePickerType.DATE
                                    : DatePickerType.MONTH
                            }
                            value={date}
                            onChange={(date) => setDate(date)}
                        />
                    </div>

                    <Button
                        type="primary"
                        className="btn-display"
                        onClick={() => refetch()}
                        disabled={isLoading || isFetching}
                    >
                        {isFetching ? (
                            <LoadingOutlined spin />
                        ) : (
                            t('report.paymentSummary.filterForm.filterButton')
                        )}
                    </Button>
                </div>

                {data && data.length > 0 && (
                    <Button
                        onClick={() => {
                            exportTableToCSV(
                                ReportTableId.PAYMENT_SUMMARY,
                                `${bookingType.split('_')[0]}-${
                                    ReportTableCsvFileName.PAYMENT_SUMMARY
                                }.csv`,
                            );
                        }}
                        disabled={isFetching}
                    >
                        {t('report.exportCSV')}
                    </Button>
                )}
            </div>
        </div>
    );
}
