import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Layout, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import localStorageAuthService from '~common/authStorage';
import { AdminGroup, ErrorMessageType, UserGroup } from '~common/constants';
import { showConfirm } from '~common/notification';
import { StaffLayoutHeader } from '~components';
import HotelSettingDetail from '~features/hotel/components/HotelSettingDetail/HotelSettingDetail';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate, parseTime } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import { hotelSettingBreadcrumbs } from '../../constants';
import {
    selectedHotelSettingSelector,
    setSelectedHotelSetting,
    updateHotelSetting,
} from '../../hotel.reducer';
import { IHotelUpdateRequest } from '../../interfaces';
import { updateHotelSettingSchema } from '../../schema';
import './HotelSettingPage.scss';
import { checkUserPermission } from '~common/commonFunctions';

const { Content } = Layout;
function HotelSettingPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);
    const selectedHotelSetting = useAppSelector(selectedHotelSettingSelector);
    const [showSettingForm, setShowSettingForm] = useState<boolean>(false);
    const { control, handleSubmit, setValue, reset, setError, watch } = useForm({
        resolver: yupResolver(updateHotelSettingSchema),
    });

    const onCancel = () => {
        showConfirm({
            title: t('hotel.form.confirm.cancelTitle'),
            cancelText: t('hotel.form.confirm.buttonCancelText'),
            okText: t('hotel.form.confirm.buttonDeleteText'),
            onOk() {
                setShowSettingForm(false);
                reset();
            },
        });
    };

    const _updateHotelSetting = useCallback(
        async (formData: IHotelUpdateRequest) => {
            const response = await dispatch(updateHotelSetting(formData));
            if (updateHotelSetting.fulfilled.match(response)) {
                if (response?.payload?.success) {
                    notification.success({
                        message: t('hotel.settingForm.updateSuccess'),
                    });
                    localStorageAuthService.setHotel(response?.payload?.data);
                    dispatch(setSelectedHotelSetting(response?.payload?.data));
                    setShowSettingForm(false);
                    return;
                } else {
                    (response.payload?.errors || []).forEach((error) => {
                        setError(
                            error.key,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    });
                    notification.error({
                        message: t('hotel.settingForm.updateError'),
                        description: response?.payload?.message || '',
                    });
                }
            }
        },
        [dispatch, t],
    );

    const onSubmit = () => {
        if (isReadOnly) return;
        handleSubmit((value) => {
            const {
                name,
                address,
                numberOfRoom,
                standardCheckInTime,
                standardCheckOutTime,
                picEmailAddress,
                picName,
                picPhoneNumber,
                postalCode,
                registeredNumber,
                isRegisteredNumberNotObtained,
                companyName,
            } = value;
            let bodyUpdate = {
                name: name.length !== 0 ? name : null,
                address: address?.length !== 0 ? address : null,
                numberOfRoom: Number(numberOfRoom) ? Number(numberOfRoom) : null,
                picEmailAddress: picEmailAddress?.length !== 0 ? picEmailAddress : null,
                picName: picName?.length !== 0 ? picName : null,
                picPhoneNumber: picPhoneNumber?.length !== 0 ? picPhoneNumber : null,
                postalCode: postalCode?.length !== 0 ? postalCode : null,
                standardCheckInTime: parseDate(standardCheckInTime)?.fmHHmm(),
                standardCheckOutTime: parseDate(standardCheckOutTime)?.fmHHmm(),
                registeredNumber:
                    registeredNumber?.length !== 0 ? registeredNumber : null,
                isRegisteredNumberNotObtained: isRegisteredNumberNotObtained,
                companyName: companyName && companyName.length !== 0 ? companyName : null,
            };
            if (selectedHotelSetting) {
                _updateHotelSetting({
                    id: selectedHotelSetting.id,
                    body: bodyUpdate,
                });
            }
        })();
    };

    const onClickEditButton = () => {
        if (isReadOnly) return;
        setValue(`postalCode`, selectedHotelSetting?.postalCode);
        setValue(`picName`, selectedHotelSetting?.picName);
        setValue(`name`, selectedHotelSetting?.name);
        setValue(`address`, selectedHotelSetting?.address);
        setValue(`picPhoneNumber`, selectedHotelSetting?.picPhoneNumber);
        setValue(`picEmailAddress`, selectedHotelSetting?.picEmailAddress);
        setValue(`numberOfRoom`, selectedHotelSetting?.numberOfRoom);
        setValue(
            `standardTimes`,
            selectedHotelSetting?.standardCheckInTime
                ? [
                      parseTime(selectedHotelSetting?.standardCheckInTime || ''),
                      parseTime(selectedHotelSetting?.standardCheckOutTime || ''),
                  ]
                : [],
        );
        setValue(`registeredNumber`, selectedHotelSetting?.registeredNumber);
        setValue(
            `isRegisteredNumberNotObtained`,
            selectedHotelSetting?.isRegisteredNumberNotObtained,
        );
        setValue(`companyName`, selectedHotelSetting?.companyName);
        setShowSettingForm(true);
    };

    return (
        <div className="hotel-setting-page">
            <StaffLayoutHeader
                breadcrumbs={hotelSettingBreadcrumbs()}
                title={t('hotel.settingForm.title')}
                rightComponent={
                    !showSettingForm &&
                    !isReadOnly && (
                        <Button
                            className="btn-edit"
                            type="primary"
                            onClick={onClickEditButton}
                            icon={<EditOutlined />}
                        >
                            {t('hotel.settingForm.editButton')}
                        </Button>
                    )
                }
            />
            <Content>
                <HotelSettingDetail
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    isEditing={showSettingForm && !isReadOnly}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            </Content>
        </div>
    );
}

export default HotelSettingPage;
