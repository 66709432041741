import { ReceiptItemType } from './../../../room-booking/constants';
import {
    ReceiptItemBookingType,
    ReceiptItemDetailType,
    RoomBookingItemBookingStatus,
} from './../../constants';
import { FacilityBookingStatus } from '~features/facility-booking/constants';

const historyDetail = {
    memoInput: 'Enter memo',
    goToPage: 'Go to Page',
    update: {
        success: 'History updated successfully',
        failed: 'History updated failed',
    },
    memo: 'Memo',
    activityType: 'Activity Type',
    noData: 'No data',
    applicableRoomValue: 'Room {{value}}',
    key: {
        childrenType: {
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            afterData: 'After',
            beforeData: 'Before',
            name: 'Name',
            priceType: 'Price Type',
            priceValue: 'Price Value',
            linkageCode: 'Linkage Code',
        },
        facility: {
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            name: 'Name',
            afterData: 'After',
            beforeData: 'Before',
            facilityType: 'Facility Type',
        },
        facilityBooking: {
            status: 'Status',
            afterData: 'After',
            beforeData: 'Before',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            facility: 'Facility',
            name: 'Name',
            saleItemCheckIns: 'Sale Item Check-in',
            endDatetime: 'End Date',
            startDatetime: 'Start Date',
            guest: 'Guest',
            birthday: 'Birthday',
            fullName: 'Full Name',
            yomigana: 'Yomigana',
            emailAddress: 'Email',
            mobilePhoneNumber: 'Mobile Phone',
            numberOfGuests: 'Number of Guests',
            memo: 'Memo',
            price: 'Price',
            saleItem: 'Sale Item',
            tax: 'Tax',
            type: 'Type',
            isActive: 'Active',
            createdAt: 'Created At',
            createdBy: 'Created By',
            deletedAt: 'Deleted At',
            deletedBy: 'Deleted By',
            updatedAt: 'Updated At',
            updatedBy: 'Updated By',
            pricingType: 'Pricing Type',
            pricingValue: 'Pricing Value',
            isSeedData: 'Seed Data',
            isShowInBooking: 'Show in Booking',
            autoGeneratedCode: 'Code',
        },
        facilityType: {
            autoGeneratedCode: 'ID',
            name: 'Name',
            afterData: 'After',
            beforeData: 'Before',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            saleItems: 'Sale Items',
            abbreviation: 'Abbrevation',
            duration: 'Duration',
            price: 'Price',
            businessEndTime: 'Business End Time',
            businessStartTime: 'Business Start Time',
            facilityTypeNumber: 'Facility Type Number',
            currency: '円',
        },
        guestClassification: {
            autoGeneratedCode: 'ID',
            createdData: 'Created data',
            deletedData: 'Deleted data',
            name: 'Full name',
            color: 'Color',
            beforeData: 'Before',
            afterData: 'After',
        },
        marketingChannel: {
            autoGeneratedCode: 'ID',
            createdData: 'Created data',
            deletedData: 'Deleted data',
            name: 'Full name',
            afterData: 'After',
            beforeData: 'Before',
            linkageCode: 'Linkage code',
            linkageCodeDayUse: 'Day-use linkage code',
            marketingChannelGroup: 'Marketing channel group',
        },
        paymentMethod: {
            autoGeneratedCode: 'ID',
            createdData: 'Created data',
            deletedData: 'Deleted data',
            name: 'Full name',
            afterData: 'After',
            beforeData: 'Before',
        },
        room: {
            autoGeneratedCode: 'ID',
            createdData: 'Created data',
            deletedData: 'Deleted data',
            memo: 'Memo',
            name: 'Full name',
            afterData: 'After',
            beforeData: 'Before',
            roomType: 'Room Type',
            roomTypeId: 'Room Type ID',
            cleaningStatus: 'Cleaning Status',
        },
        saleGroup: {
            autoGeneratedCode: 'ID',
            createdData: 'Created data',
            deletedData: 'Deleted data',
            name: 'Full name',
            afterData: 'After',
            beforeData: 'Before',
            saleItems: 'Sales item',
        },
        saleItem: {
            autoGeneratedCode: 'ID',
            createdData: 'Created data',
            deletedData: 'Deleted data',
            tax: 'Tax',
            name: 'Full name',
            taxName: 'Tax name',
            beforeData: 'Before',
            afterData: 'After',
            price: 'Price',
            isShowInBooking: 'Displayed in booking',
            taxId: 'Tax ID',
            saleItemNumber: 'Sales item number',
        },
        marketingChannelGroup: {
            createdData: 'Created data',
            autoGeneratedCode: 'ID',
            deletedData: 'Deleted data',
            name: 'Full name',
            afterData: 'After',
            beforeData: 'Before',
        },
        plan: {
            autoGeneratedCode: 'ID',
            name: 'Name',
            afterData: 'After',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            planRoomTypes: 'Plan Room Types',
            roomType: 'Room Type',
            beforeData: 'Before',
            standardCapacity: 'Standard Capacity',
            planRoomTypeId: 'Plan Room Type ID',
            roomTypeId: 'Room Type ID',
            peopleCount: 'People Count',
            planRoomTypePricings: 'Plan Room Type Pricings',
            price: 'Price',
            tllRank: 'TLL Rank',
            planSaleItems: 'Plan Sale Items',
            tax: 'Tax',
            memo: 'Memo',
            taxId: 'Tax ID',
            planRank: 'Plan Rank',
            planType: 'Plan Type',
            planRankId: 'Plan Rank ID',
            checkInTime: 'Check-in time',
            checkOutTime: 'Check-out time',
            quantity: 'Quantity',
            saleItem: 'Sales Item',
            isFirstDayOnly: 'Is First Day Only',
            isForOnePeople: 'Is For One People',
        },
        tax: {
            autoGeneratedCode: 'ID',
            name: 'Name',
            afterData: 'After',
            beforeData: 'Before',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            isActive: 'Active',
        },
        guest: {
            autoGeneratedCode: 'ID',
            fullName: 'Full name',
            afterData: 'After',
            beforeData: 'Before',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            memo: 'Memo',
            gender: 'Gender',
            address: 'Address',
            country: 'Country',
            key: 'Key',
            birthday: 'Date of Birth',
            yomigana: 'Yomigana',
            countryId: 'Country ID',
            postalCode: 'Postal Code',
            companyName: 'Company',
            emailAddress: 'Email',
            classification: 'Guest type',
            classificationId: 'Guest type ID',
            name: 'Full Name',
            companyAddress: 'Company Address',
            companyYomigana: 'Company Yomigana',
            companyPostalCode: 'Company Postal Code',
            mobilePhoneNumber: 'Phone',
            companyPhoneNumber: 'Company Phone',
            companyEmailAddress: 'Company Email',
        },
        roomType: {
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            name: 'Name',
            marketingChannels: 'Marketing Channels',
            abbreviation: 'Abbreviation',
            standardCapacity: 'Standard Capacity',
            stoppingRoomCount: 'Stopping Room Count',
            afterData: 'After',
            beforeData: 'Before',
            roomTypeCode: 'Room Type Code',
        },
        indicator: {
            autoGeneratedCode: 'ID',
            name: 'Name',
            afterData: 'After',
            beforeData: 'Before',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            autoGeneratedCodeIndicator: 'Auto Generated Code Indicator',
            indicatorItems: 'Indicator Items',
            room: 'Room',
            roomId: 'Room ID',
            colIndex: 'Column',
            rowIndex: 'Row',
            layout: 'Layout',
            indicatorType: 'Indicator Type',
        },
        planRankCalendar: {
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            name: 'Name',
            afterData: 'After',
            beforeData: 'Before',
            planRankDurations: 'Plan Rank Durations',
            endDate: 'End date',
            startDate: 'Start date',
            tllRank: 'TLL Rank',
            dayOfWeek: 'Day of Week',
            status: 'Status',
        },
        roomBooking: {
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            representativeBookingItem: 'Representative Booking Item',
            memo: 'Memo',
            status: 'Status',
            reserverGuest: 'Reserver Guest',
            gender: 'Gender',
            birthday: 'Birthday',
            fullName: 'Full Name',
            yomigana: 'Yomigana',
            emailAddress: 'Email',
            mobilePhoneNumber: 'Mobile phone',
            marketingChannel: 'Marketing Channel',
            name: 'Name',
            room: 'Room',
            roomBookingItems: 'Room Booking Items',
            plan: 'Plan',
            isDayUse: 'Is Day Use',
            checkInTime: 'Check-in Time',
            checkOutTime: 'Check-out Time',
            bookingStatus: 'Booking Status',
            endDateOfStay: 'Last date of stay',
            startDateOfStay: 'First date of Stay',
            numberOfAdults: 'Number of Adults',
            isRepresentativeRoom: 'Representative Room',
            roomBookingItemChildrenTypes: 'Room Booking Item Children Types',
            quantity: 'Quantity',
            representativeGuest: 'Representative Guest',
            isReserverTheRepresentative: 'Is Reserver The Representative',
            beforeData: 'Before',
            afterData: 'After',
            roomType: 'Room Type',
            guests: 'Guest',
            companionBookingItems: 'Booking Details',
            usageDetails: 'Usage Details',
            date: 'Date',
            amount: 'Amount',
            planSaleItemNames: 'Plan Sale Item Names',
            numberOfChildren: 'Number of Children',
            pricingSettings: 'Pricing Settings',
            tllRank: 'TLL Rank',
            planSaleItems: 'Plan Sale Items',
            planRoomTypePricing: 'Plan Room Type Price',
            price: 'Price',
            peopleCount: 'People Count',
            numberOfMale: 'Number Of Male',
            numberOfFemale: 'Number Of Female',
            numberOfOtherGenderGuest: 'Number Of Other',
        },
        overbooking: {
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            representativeBookingItem: 'Representative Booking Item',
            memo: 'Memo',
            status: 'Status',
            reserverGuest: 'Reserver Guest',
            gender: 'Gender',
            birthday: 'Birthday',
            fullName: 'Full Name',
            yomigana: 'Yomigana',
            emailAddress: 'Email',
            mobilePhoneNumber: 'Mobile phone',
            marketingChannel: 'Marketing Channel',
            name: 'Name',
            room: 'Room',
            roomBookingItems: 'Room Booking Items',
            plan: 'Plan',
            isDayUse: 'Is Day Use',
            checkInTime: 'Check-in Time',
            checkOutTime: 'Check-out Time',
            bookingStatus: 'Booking Status',
            endDateOfStay: 'Last date of stay',
            startDateOfStay: 'First date of Stay',
            numberOfAdults: 'Number of Adults',
            isRepresentativeRoom: 'Representative Room',
            roomBookingItemChildrenTypes: 'Room Booking Item Children Types',
            quantity: 'Quantity',
            representativeGuest: 'Representative Guest',
            isReserverTheRepresentative: 'Is Reserver The Representative',
            beforeData: 'Before',
            afterData: 'After',
            roomType: 'Room Type',
            guests: 'Guest',
            companionBookingItems: 'Booking Details',
            usageDetails: 'Usage Details',
            date: 'Date',
            amount: 'Amount',
            planSaleItemNames: 'Plan Sale Item Names',
            numberOfChildren: 'Number of Children',
            pricingSettings: 'Pricing Settings',
            tllRank: 'TLL Rank',
            planSaleItems: 'Plan Sale Items',
            planRoomTypePricing: 'Plan Room Type Price',
            price: 'Price',
            peopleCount: 'People Count',
            numberOfMale: 'Number Of Male',
            numberOfFemale: 'Number Of Female',
            numberOfOtherGenderGuest: 'Number Of Other',
        },
        roomBookingItem: {
            gender: 'Gender',
            birthday: 'Birthday',
            fullName: 'Full Name',
            yomigana: 'Yomigana',
            emailAddress: 'Email Address',
            mobilePhoneNumber: 'Mobile Phone Number',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            beforeData: 'Before',
            afterData: 'After',
            bookingStatus: 'Booking Status',
            autoGeneratedCode: 'ID',
            room: 'Room',
            roomName: 'Room Name',
            name: 'Name',
            saleItemCheckIns: 'Sale Item Check-ins',
            price: 'Price',
            saleItem: 'Sale Item',
            tax: 'Tax',
            type: 'Type',
            isActive: 'Active',
            priceType: 'Price Type',
            priceValue: 'Price Value',
            createdAt: 'Created At',
            isSeedData: 'Seed Data',
            isShowInBooking: 'Show in Booking',
            endDateOfStay: 'End Date of Stay',
            isRepresentativeRoom: 'Representative Room',
            roomBookingItemChildrenTypes: 'Room Booking Item Children Types',
            quantity: 'Quantity',
            memo: 'Memo',
            plan: 'Plan',
            planName: 'Plan Name',
            isDayUse: 'Day Use',
            roomType: 'Room Type',
            roomTypeName: 'Room Type',
            checkInTime: 'Check-in Time',
            checkOutTime: 'Check-out Time',
            numberOfAdults: 'Number of Adults',
            startDateOfStay: 'Start Date of Stay',
            representativeGuest: 'Representative Guest',
            childrenType: 'Children Type',
            paymentMethodCheckIns: 'Check-in Payment Methods',
            paymentMethod: 'Payment Method',
            linkageCode: 'Linkage Code',
            numberOfMale: 'Number Of Male',
            numberOfFemale: 'Number Of Female',
            numberOfOtherGenderGuest: 'Number Of Orther',
            roomGuest: 'Room guest',
            applicableRoom: 'Applicable room',
            roomBookingItem: 'Room Booking Item',
        },
        roomBookingItemTemporary: {
            representativeGuest: 'Representative Guest',
            priceType: 'Price Type',
            isSeedData: 'Is Seed Data',
            priceValue: 'Price Value',
            linkageCode: 'LinkageCode',
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            memo: 'Memo',
            plan: 'Plan',
            name: 'Name',
            planId: 'Plan ID',
            roomId: 'Room ID',
            isDayUse: 'Is Day Use',
            roomTypeId: 'Room Type ID',
            checkInTime: 'Check-in Time',
            checkOutTime: 'Check-out Time',
            bookingStatus: 'Booking Status',
            numberOfAdults: 'Number of Adults',
            endDateOfStay: 'Last Date of Stay',
            startDateOfStay: 'First Date of Stay',
            isRepresentativeRoom: 'Is Representative Room',
            representativeGuestId: 'Representative Guest ID',
            room: 'Room',
            roomType: 'Room Type',
            roomBookingItemChildrenTypes: 'Room Booking Item Children Types',
            quantity: 'Quantity',
            childrenType: 'Children Type',
            beforeData: 'Before',
            afterData: 'After',
            numberOfMale: 'Number Of Male',
            numberOfFemale: 'Number Of Female',
            numberOfOtherGenderGuest: 'Number Of Orther',
        },
        receipt: {
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            type: 'Type',
            beforeData: 'Before',
            afterData: 'After',
            bookingType: 'Booking Type',
            boughtAt: 'Bought At',
            paymentMethod: 'Payment Method',
            receiptItemDetails: 'Receipt Item Details',
            amount: 'Amount',
            quantity: 'Quantity',
            saleItem: 'Sale Item',
            tax: 'Tax',
            name: 'Name',
            isActive: 'Active',
            createdAt: 'Created At',
            createdBy: 'Created By',
            deletedAt: 'Deleted At',
            deletedBy: 'Deleted By',
            updatedAt: 'Updated At',
            updatedBy: 'Updated By',
            pricingType: 'Pricing Type',
            pricingValue: 'Pricing Value',
            isSeedData: 'Seed Data',
            isShowInBooking: 'Show in Booking',
            autoGeneratedCode: 'Code',
            unitPrice: 'Unit Price',
            price: 'Price',
            payAt: 'Pay At',
            saleGroups: 'Sale Groups',
            representativeGuest: 'Representative Guest',
            fullName: 'Full Name',
            yomigana: 'Yomigana',
            createdByChannel: 'Created By',
            room: 'Room name',
            applicableRoom: 'Applicable room',
        },
        receiptItemDetail: {
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            amount: 'Amount',
            quantity: 'Quantity',
            unitPrice: 'Unit Price',
            receiptItem: 'Receipt Item',
            type: 'Type',
            bookingType: 'Booking Type',
            paymentMethod: 'Payment Method',
            name: 'Name',
            representativeGuest: 'Representative Guest',
            fullName: 'Full Name',
            yomigana: 'Yomigana',
            createdByChannel: 'Created By',
            room: 'Room name',
            applicableRoom: 'Applicable room',
        },
        stoppingInventory: {
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            endDate: 'End Date',
            roomType: 'Room Type',
            name: 'Name',
            beforeData: 'Before',
            afterData: 'After',
            startDate: 'Start Date',
            stoppingRoomCount: 'Stopping Room Count',
            stoppingInventoryItems: 'Stopping Inventory Items',
            room: 'Room',
            memo: 'Memo',
            reason: 'Reason',
            status: 'Status',
        },
        hotel: {
            autoGeneratedCode: 'ID',
            createdData: 'Created Data',
            deletedData: 'Deleted Data',
            address: 'Address',
            beforeData: 'Before',
            afterData: 'After',
            picName: 'Pic Name',
            postalCode: 'Postal Code',
            numberOfRoom: 'Number of Room',
            picPhoneNumber: 'PIC Phone Number',
            picEmailAddress: 'PIC Email Address',
            standardCheckInTime: 'Standard Check-in Time',
            tllName: 'TLL Name',
            isTllConnected: 'Connected to TLL',
            tllPmsPassword: 'TLL PMS Password',
            tllBookingAcquisitionUrl: 'TLL Booking Acquisition URL',
        },
    },
    value: {
        childrenType: {
            fixed: 'Fixed',
            pricePerAdult: 'Price per Adult',
            pricePerAdultValue: `大人１名あたり料金の{{value}}%`,
        },
        yen: '￥{{value}}',
        hour: 'hour',
        minute: 'minute',
        true: 'Yes',
        false: 'No',
        inactive: 'Inactive',
        active: 'Active',
        cleaned: 'Cleaned',
        uncleaned: 'Uncleaned',
        [FacilityBookingStatus.CANCELLED]: 'Cancelled',
        [FacilityBookingStatus.FINISHED]: 'Finished',
        [FacilityBookingStatus.RESERVED]: 'Reserved',
        [FacilityBookingStatus.STARTED]: 'Started',
        [ReceiptItemBookingType.ROOM_BOOKING]: 'Room Booking',
        [ReceiptItemBookingType.ROOM_BOOKING_ITEM]: 'Room Booking Item',
        [ReceiptItemBookingType.FACILITY_BOOKING]: 'Facility Booking',
        [ReceiptItemDetailType.OTHER_TAX]: 'Other Tax',
        [ReceiptItemDetailType.SALE_ITEM]: 'Sale Item',
        [ReceiptItemDetailType.STAY_PRICE]: 'Stay Price',
        [ReceiptItemType.RECEIPT]: 'Receipt',
        [ReceiptItemType.PAYMENT]: 'Payment',
        [RoomBookingItemBookingStatus.CHECKED_IN]: 'Checked-in',
        [RoomBookingItemBookingStatus.CHECKED_OUT]: 'Checked-out',
        [RoomBookingItemBookingStatus.NOT_ARRIVED]: 'Not Arrived',
    },
};

export default historyDetail;
