import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import localStorageAuthService from '~common/authStorage';
import { formatMoney } from '~common/commonFunctions';
import { CacheKeys } from '~queries/queries';
import { FacilitySalesSummaryColumn, ReportTableId } from '~features/report/constants';

import {
    IFacilitySaleTableData,
    IFacilitySalesResponse,
} from '~features/report/interfaces';

import './SalesSummaryTable.scss';

function FacilitySalesSummaryTable() {
    const { t } = useTranslation();

    const hotelId = +localStorageAuthService.getHotelId();
    const { data, isLoading, isRefetching } = useQuery({
        queryKey: [CacheKeys.facilitySalesReport, hotelId],
        enabled: false,
    });

    if (!data) return null;

    const { items: salesReport, total: totalSalesData } = data as IFacilitySalesResponse;

    const salesSummaryColumns: ColumnsType<IFacilitySaleTableData> = [
        {
            title: t('report.salesSummary.salesSummaryColumn.earnings'),
            className: 'header-cell',
            children: [
                {
                    title: t('report.salesSummary.salesSummaryColumn.saleItem'),
                    render: (item: IFacilitySaleTableData) => {
                        return <span>{item.name}</span>;
                    },
                },
            ],
        },
        {
            title: t('report.salesSummary.salesSummaryColumn.currentTerm'),
            className: 'header-cell',
            children: [
                {
                    title: t('report.salesSummary.salesSummaryColumn.quantity'),
                    width: '9%',
                    dataIndex: FacilitySalesSummaryColumn.QUANTITY,
                },
                {
                    title: t('report.salesSummary.salesSummaryColumn.price'),
                    width: '12%',
                    className: 'price-cell',
                    render: (item: IFacilitySaleTableData) => {
                        return (
                            <span>{`${t('report.salesSummary.unit')}${formatMoney(
                                item.amount || 0,
                            )}`}</span>
                        );
                    },
                },
            ],
        },
        {
            title: t('report.salesSummary.salesSummaryColumn.lastYear'),
            className: 'header-cell',
            children: [
                {
                    title: t('report.salesSummary.salesSummaryColumn.quantity'),
                    width: '9%',
                    dataIndex: FacilitySalesSummaryColumn.QUANTITY_PREV_YEAR,
                },
                {
                    title: t('report.salesSummary.salesSummaryColumn.price'),
                    width: '12%',
                    className: 'price-cell',
                    render: (item: IFacilitySaleTableData) => {
                        return (
                            <span>{`${t('report.salesSummary.unit')}${formatMoney(
                                item.amountPrevYear || 0,
                            )}`}</span>
                        );
                    },
                },
            ],
        },
        {
            title: t('report.salesSummary.salesSummaryColumn.comparison'),
            className: 'header-cell',
            children: [
                {
                    title: t('report.salesSummary.salesSummaryColumn.quantity'),
                    width: '13%',
                    render: (item: IFacilitySaleTableData) => {
                        return item.quantityCompared ? (
                            <>
                                <span>{item.quantityCompared}</span>
                                <span>%</span>
                            </>
                        ) : (
                            ''
                        );
                    },
                },
                {
                    title: t('report.salesSummary.salesSummaryColumn.price'),
                    width: '13%',
                    render: (item: IFacilitySaleTableData) => {
                        return (
                            <span>
                                {item.amountCompared ? `${item.amountCompared}%` : ''}
                            </span>
                        );
                    },
                },
            ],
        },
    ];

    return (
        <div className="report-sales-summary_wrapper">
            <Table
                columns={salesSummaryColumns}
                pagination={false}
                dataSource={salesReport || []}
                loading={isLoading || isRefetching}
                className="report-sales-summary-table"
                id={ReportTableId.FACILITY_SALES_SUMMARY}
                rowKey="name"
                summary={(data) => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                                {t('report.salesSummary.total')}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                {totalSalesData.quantity}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} className="price-cell">
                                {`${t('report.salesSummary.unit')}${formatMoney(
                                    totalSalesData.amount,
                                )}`}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                {totalSalesData.quantityPrevYear}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="price-cell">
                                {`${t('report.salesSummary.unit')}${formatMoney(
                                    totalSalesData.amountPrevYear,
                                )}`}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                                {totalSalesData.quantityCompared
                                    ? totalSalesData.quantityCompared + '%'
                                    : ''}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>
                                {totalSalesData.amountCompared
                                    ? totalSalesData.amountCompared + '%'
                                    : ''}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </div>
    );
}

export default FacilitySalesSummaryTable;
