const guestList = {
    title: 'ゲスト一覧',
    breadcrumbs: {
        home: 'ホーム',
        guest: 'ゲスト一覧',
    },
    searchColumn: {
        keyword: '全て',
        fullName: 'ゲスト名',
        phoneNumber: '電話番号',
        email: 'メールアドレス',
        usedBookingTypes: 'ラベル',
    },
    guestColumn: {
        id: 'ゲストID',
        fullName: '名前',
        yomigana: 'ヨミガナ',
        gender: '性別',
        birthday: '生年月日',
        mobilePhoneNumber: '電話番号',
        usedBookingTypes: 'ラベル',
        emailAddress: 'メールアドレス',
        memo: 'メモ',
        classification: 'ゲスト識別',
    },
    gender: {
        male: '男性',
        female: '女性',
        other: 'その他',
    },
    usedBookingType: {
        'room-booking': '宿泊',
        'facility-booking': '設備',
    },
    exportColumns: {
        autoGeneratedCode: 'ゲストID',
        fullName: '名前',
        yomigana: 'ヨミガナ',
        gender: '性別',
        birthday: '生年月日',
        mobilePhoneNumber: '電話番号',
        classification: 'カテゴリー',
        emailAddress: 'メールアドレス',
        usedBookingTypes: 'ラベル',
        memo: 'メモ',
    },
    modalConfirmDeletion: {
        title: 'ゲストの削除',
        description: '選択されたゲストを削除してもよろしいでしょうか？',
        cancelButton: 'キャンセル',
        deleteButton: '削除する',
    },
    modalDeteleSuccess: {
        title: 'ゲストが削除されました',
        okButton: '閉じる',
    },
    merge: {
        mergeGuest: 'ゲストの統合',
        buttonInfo: '統合するためには複数のゲストを選んでして下さい',
        instruction: '統合先のアカウントを選択してください。',
        note: '注意',
        warning: '統合が完了すると、統合されたユーザー情報は戻すことが出来ませんので、ご注意ください。',
        ok: 'はい',
        cancel: 'キャンセル',
        notification: {
            success: 'ゲストの統合が完了しました',
        },
    },
};

export default guestList;
