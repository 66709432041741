import { Card, Col, Form, FormInstance, Row, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
    InputPassword,
    InputText,
    SingleDatePicker,
    FormSwitch,
    InputNumber,
} from '~components';
import { IHotelSmartIntegration } from '~features/integrations/interfaces';
import './HotelSmartCard.scss';
import { DateFormat } from '~common/constants';

interface IProps {
    control: any;
    hotelSmart?: IHotelSmartIntegration;
}
const HotelSmartCard = ({ control, hotelSmart }: IProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation();
    const [hsChecked, setHsChecked] = useState(hotelSmart?.enableIntegration);

    useEffect(() => {
        setHsChecked(hotelSmart?.enableIntegration);
    }, [hotelSmart]);

    return (
        <Card bordered className="hotel-smart-setting-card">
            <Form layout="vertical">
                <Space align="center">
                    <p className="card-title">
                        {t('integrations.integrations.hotelSmart.title')}
                    </p>
                    <FormSwitch
                        control={control}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        name="enableIntegration"
                        label={''}
                        checked={hsChecked}
                        onChange={() => setHsChecked(!hsChecked)}
                    />
                    <div>
                        {isOpen ? (
                            <UpOutlined onClick={() => setIsOpen(false)} />
                        ) : (
                            <DownOutlined onClick={() => setIsOpen(true)} />
                        )}
                    </div>
                </Space>
                <div className={isOpen ? 'card-open-style' : 'card-close-style '}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <InputText
                                label={t('integrations.integrations.hotelSmart.userId')}
                                name="username"
                                control={control}
                                required
                            />
                        </Col>
                        <Col span={12}>
                            <InputPassword
                                label={t('integrations.integrations.hotelSmart.password')}
                                name="password"
                                control={control}
                                required
                                autoComplete="new-password"
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <InputNumber
                                label={t('integrations.integrations.hotelSmart.houseId')}
                                name="houseId"
                                control={control}
                                required
                            />
                        </Col>
                        <Col span={12}>
                            <SingleDatePicker
                                label={t(
                                    'integrations.integrations.hotelSmart.checkedInDate',
                                )}
                                placeholder={t(
                                    'facilityBooking.createForm.form.guestBirthday.placeholder',
                                )}
                                name="checkedInDate"
                                allowClear
                                control={control}
                                format={DateFormat.YYYY_MM_DD_HYPHEN}
                            />
                        </Col>
                    </Row>
                </div>
            </Form>
        </Card>
    );
};
export default HotelSmartCard;
