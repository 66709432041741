import { cloneDeep, difference, flatten, orderBy } from 'lodash';
import groupBy from 'lodash/groupBy';
import { TllRank } from '~common/constants';
import { IPlanRoomType, IPlanRoomTypePricing } from '~features/plan/interfaces';
import i18next from '~plugins/i18next/i18n';

export const transformPlanRoomTypePricings = (
    planRoomTypePricing: IPlanRoomTypePricing[],
) => {
    const groupByPeopleCount = groupBy(planRoomTypePricing, 'peopleCount');
    const tableData = Object.keys(groupByPeopleCount).map((key) => ({
        feId: +`${Date.now()}${Math.random() * 1000}`,
        planRoomTypePricings: groupByPeopleCount[key],
    }));
    return tableData;
};

export const reopenPlanRoomTypeTll = (roomTypes: IPlanRoomType[]) => {
    const newRoomTypes = roomTypes.map((item) => {
        return {
            id: item.roomType?.id,
            name: item.roomType?.name,
        };
    });
    return newRoomTypes;
};

export const mapPlanRoomTypePricings = (planRoomTypes: IPlanRoomType[]) => {
    planRoomTypes.forEach((item: IPlanRoomType) => {
        const wasPeopleCount: number[] = [];
        const peopleCountHaved: number[] = [];
        const planRoomTypeSelector = item.roomTypeId || item.roomType?.id;
        for (let i = -1; i < (item.peopleCount || 0); i++) {
            peopleCountHaved.push(i + 1);
        }

        if (peopleCountHaved.includes(0) && peopleCountHaved.length !== 1) {
            peopleCountHaved.splice(0, 1);
        }

        item.planRoomTypePricings?.forEach((item) => {
            if (
                (item.peopleCount || item.peopleCount === 0) &&
                !wasPeopleCount.includes(item.peopleCount)
            ) {
                wasPeopleCount.push(item.peopleCount);
            }
        });

        const peopleCountNeed = difference(peopleCountHaved, wasPeopleCount);

        (peopleCountNeed || []).forEach((number) => {
            item.planRoomTypePricings?.push({
                feId: +`${Date.now()}${Math.random() * 1000}`,
                peopleCount: number,
                tllRank: TllRank.A,
                planRoomTypeId: planRoomTypeSelector,
                price: null,
            });
        });
    });
};

export const reorderPlanRoomTypes = (planRoomTypes: IPlanRoomType[]) => {
    const sortedPlanRoomTypes = planRoomTypes.map((item: IPlanRoomType) => {
        const groupByPeopleCount = groupBy(item.planRoomTypePricings, 'peopleCount');
        const planRoomTypePricingsGroupByPeopleCount = Object.keys(
            groupByPeopleCount,
        ).map((key) => {
            if (
                groupByPeopleCount[key].length < Object.values(TllRank).length &&
                groupByPeopleCount[key][0].peopleCount
            ) {
                const peopleCountSelect = groupByPeopleCount[key][0].peopleCount;
                const planRoomTypeSelect = groupByPeopleCount[key][0].planRoomTypeId;
                const cloneGroupByPeopleCount = cloneDeep(groupByPeopleCount[key]);
                const tllRankList = cloneGroupByPeopleCount.map((item) => {
                    return item.tllRank;
                });
                const planRoomTypePricingItem = Object.values(TllRank).map((tllRank) => {
                    if (!tllRankList.includes(tllRank)) {
                        cloneGroupByPeopleCount.push({
                            planRoomTypeId: planRoomTypeSelect,
                            peopleCount: peopleCountSelect,
                            tllRank: tllRank,
                            price: null,
                        });
                    }
                    return cloneGroupByPeopleCount;
                });
                const formatPlanRoomTypePricingItem = orderBy(
                    planRoomTypePricingItem[0],
                    ['tllRank'],
                    ['asc'],
                );
                return {
                    feId: +`${Date.now()}${Math.random() * 1000}`,
                    planRoomTypePricings: formatPlanRoomTypePricingItem,
                };
            } else {
                return {
                    feId: +`${Date.now()}${Math.random() * 1000}`,
                    planRoomTypePricings: groupByPeopleCount[key],
                };
            }
        });
        const planRoomTypePricings = flatten(
            planRoomTypePricingsGroupByPeopleCount
                .filter((item) => {
                    return item?.feId;
                })
                .map((item) => {
                    return item.planRoomTypePricings;
                }),
        );
        return {
            ...item,
            planRoomTypePricings: planRoomTypePricings,
        };
    });
    return sortedPlanRoomTypes;
};

export function getFormPlanId(name: string) {
    return `form-plan-${name}`;
}

export const currencyFormat = (number: number) => {
    return (
        i18next.t('plan.create.price') +
        number.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    );
};

export const transformErrorKey = (errorKey: string) => {
    const errorsInfor = errorKey.split('.');
    return {
        key1: errorsInfor[0],
        order: Number(errorsInfor[1]),
        key2: errorsInfor[2],
    };
};
