import { DEFAULT_FIRST_PAGE, DEFAULT_ORDER_DIRECTION } from '~common/constants';
import i18next from '~plugins/i18next/i18n';
import { ICheckinPaymentMethod } from './interfaces';

export enum FacilityBookingOrderBy {
    ID = 'id',
    AUTO_GENERATED_CODE = 'bookingNumber',
    YOMIGANA = 'yomigana',
    GUEST_PHONE_NUMBER = 'guestPhoneNumber',
    FACILITY_NAME = 'facilityName',
    STATUS = 'status',
    BOOKING_DATE = 'bookingDate',
    BOOKING_START_TIME = 'bookingStartTime',
    BOOKING_END_TIME = 'bookingEndTime',
    ROOM_BOOKING_ID = 'roomBookingCode',
    PAYMENT_STATUS = 'paymentStatus',
    PAYMENT_AMOUNT = 'paymentAmount',
    NOTE = 'note',
}

export enum FacilityBookingStatus {
    ALL = 'all',
    RESERVED = 'reserved',
    STARTED = 'started',
    FINISHED = 'finished',
    CANCELLED = 'cancelled',
}

export const FacilityBookingStatusColor = {
    [FacilityBookingStatus.ALL]: '',
    [FacilityBookingStatus.STARTED]: '#52C41A',
    [FacilityBookingStatus.RESERVED]: '#ECB31D',
    [FacilityBookingStatus.FINISHED]: '#D9D9D9',
    [FacilityBookingStatus.CANCELLED]: '#D8D9D9',
};

export const breadcrumbs = () => {
    return [
        {
            text: i18next.t('facilityBooking.list.breadcrumbs.home'),
        },
        {
            text: i18next.t('facilityBooking.list.breadcrumbs.facilityBooking'),
        },
    ];
};

export enum FacilityBookingSearchField {
    ALL = 'keyword',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    GUEST_YOMIGANA = 'guestYomigana',
    GUEST_PHONE_NUMBER = 'guestPhoneNumber',
    FACILITY_NAMES = 'facilityNames',
}

export enum FacilityBookingColumn {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    GUEST_FULL_NAME = 'guestFullName',
    YOMIGANA = 'yomigana',
    GUEST_PHONE_NUMBER = 'guestPhoneNumber',
    FACILITY_NAME = 'facilityName',
    STATUS = 'status',
    BOOKING_DATE = 'bookingDate',
    START_DATE_TIME = 'bookingStartTime',
    END_DATE_TIME = 'bookingEndTime',
    ROOM_BOOKING_ID = 'roomBookingCode',
    PAYMENT_STATUS = 'paymentStatus',
    PAYMENT_AMOUNT = 'paymentAmount',
    MEMO = 'memo',
    CREATED_AT = 'createdAt',
}

export const FILE_NAME = 'facility_booking.csv';

export enum FacilityBookingAdvanceSearchFields {
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    STATUS = 'status',
    FACILITY_IDS = 'facilityIds',
    GUEST_PHONE_NUMBER = 'guestPhoneNumber',
    GUEST_YOSIGANA = 'guestYomigana',
    BOOKING_DATE_PERIOD = 'checkInDateRanges',
    BOOKING_TIME_PERIOD = 'checkInTimeRanges',
    USED_BOOKING_TYPE_OF_GUEST = 'usedBookingTypeOfGuest',
}

export enum UsedBookingTypeOfGuest {
    ALL = 'all',
    GUEST = 'guest',
    NON_GUEST = 'non-guest',
}

export enum FacilityBookingMarketingChannel {
    WALK_IN = 'walkIn',
    PHONE = 'phone',
}

export enum Gender {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other',
}

export enum FacilityBookingCreateFormField {
    FACILITY_ID = 'facilityId',
    NUMBER_OF_GUESTS = 'numberOfGuests',
    BOOKING_TIMES = 'bookingTimes',
    RESERVATION_DURATION = 'reservationDuration',
    MOBILE_PHONE_NUMBER = 'mobilePhoneNumber',
    GUEST_ID = 'guestId',
    YOMIGANA = 'yomigana',
    FULL_NAME = 'fullName',
    BIRTHDAY = 'birthday',
    GENDER = 'gender',
    MEMO = 'memo',
    START_DATE_TIME = 'startDatetime',
    END_DATE_TIME = 'endDatetime',
    EMAIL_ADDRESS = 'emailAddress',
}

export const FacilityBookingOccupanyRateRange = {
    empty: [0, 1],
    low: [1, 25],
    average: [25, 50],
    high: [50, 75],
    'extra-high': [75, 100],
};

export const CELL_HEIGHT = 76;
export const CELL_HEADER_HEIGHT = 44;
export const HEADER_HEIGHT = 40;
export const HEADER_HEIGHT_DAY_VIEW = 40;
export const CELL_HEADER_ROW_WIDTH = 130;
export const CELL_WIDTH = 160;
export const CELL_HOUR_WIDTH = 30;
export enum FacilityBookingDetailPageTabPane {
    FACILITY_BOOKING_DETAIL = 'facilityBookingDetail',
    FACILITY_BOOKING_RECEIPT = 'facilityBookingReceipt',
}

export enum FacilityBookingDetailTab {
    BOOKING_INFORMATION = 'booking_info',
    ATTACHMENT = 'attachment',
}

export const LIMIT_FACILITY_BOOKING_CELL_ITEMS = 2;
export const MIN_FACILITY_BOOKING_TIME = 10;
// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'facility_booking';
export const exportColumns = [
    'autoGeneratedCode',
    'yomigana',
    'fullName',
    'phone',
    'facilityName',
    'status',
    'bookingDate',
    'startDatetime',
    'endDatetime',
    'roomBookingId',
    'paymentStatus',
    'totalAmount',
    'memo',
];
export const i18ExportKey = 'facilityBooking.list';

export const MAX_GUEST_IN_FACILITY_BOOKING = 9999; // max number of guest in facility booking
export const MAX_AMOUNT_FACILITY_BOOKING = 999999999;
export const DEFAULT_LIMIT_FOR_FACILITY_BOOKING_PAGINATION = 50;
export const facilityBookingListQueryDefault = {
    limit: DEFAULT_LIMIT_FOR_FACILITY_BOOKING_PAGINATION,
    page: DEFAULT_FIRST_PAGE,
    orderBy: FacilityBookingOrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
};

export const ChangingBookingStatusesMap = {
    [`${FacilityBookingStatus.RESERVED}`]: [
        FacilityBookingStatus.STARTED,
        FacilityBookingStatus.CANCELLED,
    ],
    [`${FacilityBookingStatus.STARTED}`]: [
        FacilityBookingStatus.RESERVED,
        FacilityBookingStatus.FINISHED,
    ],
    [`${FacilityBookingStatus.FINISHED}`]: [FacilityBookingStatus.STARTED],
    [`${FacilityBookingStatus.CANCELLED}`]: [FacilityBookingStatus.RESERVED],
};

export enum PaymentSettings {
    REPRESENTATIVE_ROOM = 'representativeRoom',
    ALL_ROOMS = 'allRooms',
}

export enum ReceiptItemType {
    RECEIPT = 'receipt',
    PAYMENT = 'payment',
}

export enum ReceiptItemDetailType {
    STAY_PRICE = 'stay_price',
    SALE_ITEM = 'sale_item',
    PAYMENT = 'payment',
    OTHER_TAX = 'other_tax',
}

export const MAX_LENGTH_PRICE = 10;
export const MAX_LENGTH_QUANTITY = 3;
export const MAX_QUANTITY = 99;

export enum SplitRoomBookingReceiptColumn {
    SPLIT_GROUP = 'splitGroup',
    BOOKING_DETAILS = 'bookingDetails',
    GUEST_NAME = 'guestName',
    ROOM_NAME = 'roomName',
    UNIT_PRICE = 'unitPrice',
    QUANTITY = 'quantity',
    AMOUNT = 'amount',
    CHECK_OUT_ROOM = 'checkOutRoom',
    PAYMENT_METHOD = 'paymentMethod',
}

export enum ReceiptByGroupMapIndex {
    ALL = 'all',
}

export enum SelectSplitBillTypeOptions {
    BASED_ON_GROUP = 'basedOnGroup',
}

export const MAX_NUMBER_OF_SPLIT_RECEIPT_GROUP = 9;
export const DEFAULT_NUMBER_OF_GROUPS = 1;

export const defaultCheckinPaymentMethod: ICheckinPaymentMethod = {
    paymentMethodId: null,
    amount: null,
};

export const MAX_HEIGHT_SCHEDULE_VIEW = 700;
export const MIN_HEIGHT_SCHEDULE_VIEW = 200;

export const drawerHeaderSize = 55;
export const drawerBodyPadding = 24;
export const timeControlHeight = 48;
export const drawerFooterHeight = 21;

export const FACILITY_SCHEDULE_CARD_PADDING = 48; // top-padding + bottom-padding
export const FACILITY_SCHEDULE_PAGE_PADDING = 24; // bottom-padding of schedule page
export const FILTER_FORM_FACILITY_SCHEDULE_HEIGHT = 82;
export const FILTER_FORM_FACILITY_SCHEDULE_MARGIN = 48; // bottom-margin + top_margin
export const OTHER_FACILITY_PADDING = 16;

export const DEFAULT_BILL_PROVISO = '利用代金';
