import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { Layout } from 'antd';
import { breadcrumbs } from '~features/sale-group/constants';
import FilterForm from './components/FilterForm/FilterForm';
import SaleGroupList from './components/SaleGroupList/SaleGroupList';
import SaleGroupForm from './components/SaleGroupForm/SaleGroupForm';
import './SaleGroupListPage.scss';
function SaleGroupListPage() {
    const { t } = useTranslation();
    return (
        <div className="sale-group-list-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('saleGroup.list.title')}
            />
            <Layout.Content>
                <div className="sale-group-list-page-wrapper">
                    <div className="sale-group-list-page-content">
                        <FilterForm />
                        <SaleGroupList />
                    </div>
                    <SaleGroupForm />
                </div>
            </Layout.Content>
        </div>
    );
}

export default SaleGroupListPage;
