import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { unpaidDetailBreadcrumbs } from '~features/report/constants';
import { unpaidDetailSelector } from '~features/report/reducers/unpaid-detail.reducer';
import { useAppSelector } from '~hooks';
import FilterForm from './components/FilterForm/FilterForm';
import UnpaidDetailTable from './components/UnpaidDetailTable/UnpaidDetailTable';

function UnpaidDetailPage() {
    const { t } = useTranslation();
    const { showUnpaidDetailTable } = useAppSelector(unpaidDetailSelector);
    return (
        <div className="unpaid-detail-page">
            <StaffLayoutHeader
                breadcrumbs={unpaidDetailBreadcrumbs()}
                title={t('report.unpaidDetail.title')}
            />
            <Layout.Content>
                <div className="unpaid-detail-page-wrapper">
                    <div className="unpaid-detail-page-content">
                        <FilterForm />
                        {showUnpaidDetailTable && <UnpaidDetailTable />}
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default UnpaidDetailPage;
