const planList = {
    title: 'Plan setting',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        plan: 'Manage plan',
        createPlan: 'Create new plan',
        updatePlan: 'Edit plan',
    },
    primaryButton: 'Add plan',
    planTypeOptions: {
        stay: 'Accommodation',
        day_use: 'Day-use',
    },
    planListColumn: {
        id: 'ID',
        planName: 'Plan name',
        planType: 'Type',
        checkInTime: 'C/I',
        checkOutTime: 'C/O',
    },
    confirm: {
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
        cancelTitle: 'Do you want to cancel?',
    },
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
        deleteSuccess: 'Plans have been deleted successfully',
    },
};

export default planList;
