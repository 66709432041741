const saleItemCreate = {
    title: '売上科目の登録',
    breadcrumbs: {
        create: '売上科目の登録',
    },
    cancelBtn: 'キャンセル',
    createBtn: '保存',
    formSetting: {
        quantityLabel: '数量',
        quantityPlaceholder: '数量',
        saleGroupLabel: '集計グループ',
        priceLabel: '金額（円）',
        taxLabel: '消費税区分',
        isShowInBookingLabel: '手配リストに表示',
        isShowInBookingTitle: '手配リスト',
        generateBtn: '適応する',
        defaultTax: '非課税',
    },
    saleItemList: {
        id: 'ID',
        name: '科目',
        nameInput: '科目を入力',
        saleGroup: '集計グループ',
        saleGroupSelect: 'グループを選択',
        price: '金額 （円）',
        priceInput: '基本価格',
        tax: '消費税区分（％）',
        taxSelect: '消費税区別',
        isShowInBooking: '手配リスト表示',
        deleteBtn: '削除',
        addSaleItemBtn: '行を追加',
    },
    message: {
        nameDuplicated: 'セール項目名が重複しています',
        createSuccess: 'セール項目が登録されました',
    },
    confirm: {
        cancelTitle: 'キャンセルしてもよろしいですか？',
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
    },
};

export default saleItemCreate;
