import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Modal, Row } from 'antd';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '~common/commonFunctions';
import { ErrorMessageType } from '~common/constants';
import { showConfirm } from '~common/notification';
import { InputPrice, SelectPaymentMethod, SingleSelect } from '~components';
import {
    defaultCheckinPaymentMethod,
    FacilityBookingStatus,
} from '~features/facility-booking/constants';
import {
    ICheckinPaymentMethod,
    IFacilityBookingCheckIn,
} from '~features/facility-booking/interfaces';
import {
    checkInFacilityBooking,
    facilityBookingCheckInSelector,
    setShowCheckInForm,
    showCheckInFormLoadingSelector,
    showCheckInFormSelector,
    updateBooking,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { checkInFacilityBookingSchema } from '~features/facility-booking/schema';
import {
    facilityTypeDropDownOptionsSelector,
    getFacilityTypeListForDropDown,
} from '~features/facility-type/facility-type.reducer';
import {
    facilityListStateSelector,
    fetchFacilityDropDown,
} from '~features/facility/facility.reducer';
import {
    getPaymentMethodDropdown,
    paymentMethodStateSelector,
} from '~features/payment-method/reducers/paymentMethod.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './FacilityBookingCheckInForm.scss';

type Prop = {
    fetchFacilityBookingList?: () => void;
};

function FacilityBookingCheckInForm({ fetchFacilityBookingList }: Prop) {
    const {
        control,
        setValue,
        handleSubmit,
        reset,
        setError,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(checkInFacilityBookingSchema),
    });
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showCheckInForm = useAppSelector(showCheckInFormSelector);
    const facilityBooking = useAppSelector(facilityBookingCheckInSelector);
    const showLoading = useAppSelector(showCheckInFormLoadingSelector);
    const facilityTypeDropDownOptions = useAppSelector(
        facilityTypeDropDownOptionsSelector,
    );
    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);
    const { facilityDropDownList } = useAppSelector(facilityListStateSelector);

    const paymentMethodList: ICheckinPaymentMethod[] = useWatch({
        control,
        name: 'paymentMethodCheckIns',
    });

    const { disableAddSaleItemButton, totalAmount } = useMemo(() => {
        const totalPaymentAmount = (paymentMethodList || []).reduce(
            (total, item) => total + (Number(item.amount) || 0),
            0,
        );
        const totalAmount = (facilityBooking?.totalAmount || 0) - totalPaymentAmount;
        return {
            disableAddSaleItemButton: (paymentMethodList || []).length >= 99,
            totalAmount: totalAmount > 0 ? totalAmount : 0,
        };
    }, [paymentMethodList, facilityBooking]);

    useEffect(() => {
        reset();
        dispatch(getFacilityTypeListForDropDown({}));
        dispatch(fetchFacilityDropDown({}));
        dispatch(getPaymentMethodDropdown());
    }, []);

    useEffect(() => {
        if (showCheckInForm) {
            setValue('facilityId', facilityBooking?.facilityId);
            setValue('facilityType', facilityBooking?.facilityTypeId);
            setValue('paymentMethodCheckIns', []);
        }
    }, [showCheckInForm, setValue, facilityBooking]);

    const facilityDropDownOptions = useMemo(() => {
        if (showCheckInForm && facilityBooking?.facilityTypeId) {
            return facilityDropDownList
                .filter(
                    (facility) =>
                        facilityBooking.facilityTypeId === facility.facilityType?.id,
                )
                .map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
        }
        return [];
    }, [showCheckInForm, facilityBooking, facilityDropDownList]);

    const closeForm = () => {
        dispatch(setShowCheckInForm(false));
        reset();
    };

    const addNewSaleItem = () => {
        setValue('paymentMethodCheckIns', [
            ...(paymentMethodList || []),
            { ...defaultCheckinPaymentMethod },
        ]);
    };

    const removeSaleItem = (deletedItemIndex: number) => {
        setValue('paymentMethodCheckIns', [
            ...paymentMethodList.filter(
                (item: ICheckinPaymentMethod, index: number) =>
                    index !== deletedItemIndex,
            ),
        ]);
        if (!isEmpty(errors)) trigger('paymentMethodCheckIns');
    };

    const _checkInFacilityBooking = debounce(async (data: IFacilityBookingCheckIn) => {
        if (facilityBooking?.id) {
            const response = await dispatch(
                checkInFacilityBooking({ id: facilityBooking?.id, body: data }),
            );
            if (checkInFacilityBooking.fulfilled.match(response)) {
                if (response.payload?.success) {
                    Modal.success({
                        content: t('facilityBooking.list.statusModalConfirm.success', {
                            status: t(
                                `facilityBooking.list.bookingStatus.${FacilityBookingStatus.STARTED}`,
                            ),
                        }),
                        okText: t('facilityBooking.list.statusModalConfirm.okText'),
                        onOk() {
                            dispatch(
                                updateBooking({
                                    booking: {
                                        ...facilityBooking,
                                        status: FacilityBookingStatus.STARTED,
                                        facilityId: data.facilityId,
                                        totalAmount,
                                    },
                                }),
                            );
                            if (fetchFacilityBookingList) {
                                fetchFacilityBookingList();
                            }
                        },
                    });
                    closeForm();
                } else {
                    response.payload?.errors?.forEach((error) => {
                        if (error.key === 'facilityId') {
                            setError(
                                `${error.key}`,
                                { type: ErrorMessageType.MANUAL, message: error.message },
                                { shouldFocus: true },
                            );
                        }
                    });
                    Modal.error({
                        content: response.payload.message,
                        okText: t('facilityBooking.list.statusModalConfirm.okText'),
                        centered: true,
                    });
                }
            }
        }
    }, 500);

    const onSubmit = () => {
        handleSubmit((data) => {
            const formData = {
                facilityId: data.facilityId,
                paymentMethodCheckIns: data.paymentMethodCheckIns?.map(
                    (item: ICheckinPaymentMethod) => {
                        return {
                            paymentMethodId: item.paymentMethodId,
                            price: Number(item.amount),
                        };
                    },
                ),
            };

            _checkInFacilityBooking(formData as IFacilityBookingCheckIn);
        })();
    };

    const showConfirmCancel = () => {
        showConfirm({
            title: t('facilityBooking.schedule.checkInForm.cancelTitle'),
            onOk() {
                closeForm();
            },
        });
    };

    return (
        <Modal
            open={showCheckInForm}
            onCancel={showConfirmCancel}
            title={t('facilityBooking.schedule.checkInForm.title')}
            footer={[
                <div className="total-amount-wrapper">
                    <p className="total-amount-label">
                        {t('facilityBooking.schedule.checkInForm.totalAmount')}
                    </p>
                    <p className="total-amount-value">
                        {t('common.yen', {
                            number: formatMoney(totalAmount),
                        })}
                    </p>
                </div>,
                <Button onClick={showConfirmCancel}>
                    {t('facilityBooking.schedule.checkInForm.cancelBtn')}
                </Button>,
                <Button type="primary" onClick={onSubmit} loading={showLoading}>
                    {t('facilityBooking.schedule.checkInForm.submitBtn')}
                </Button>,
            ]}
            className="facility-booking-check-in-form-wrapper"
        >
            <Form layout="vertical" autoComplete="off">
                <Row className="check-in-form-row">
                    <Col span={24}>
                        <SingleSelect
                            label={t('facilityBooking.schedule.checkInForm.facilityType')}
                            name="facilityType"
                            required
                            control={control}
                            disabled
                            options={facilityTypeDropDownOptions}
                        />
                    </Col>
                </Row>
                <Row className="check-in-form-row">
                    <Col span={24}>
                        <SingleSelect
                            label={t('facilityBooking.schedule.checkInForm.facilityName')}
                            name="facilityId"
                            control={control}
                            options={facilityDropDownOptions}
                        />
                    </Col>
                </Row>
                <Row className="check-in-form-row note-row">
                    <Col span={22}>
                        <p>{t('facilityBooking.schedule.checkInForm.note')}</p>
                    </Col>
                </Row>
                {(paymentMethodList || []).map((paymentMethod, index) => {
                    return (
                        <Row
                            gutter={8}
                            className="check-in-form-row sale-item-row"
                            key={index}
                        >
                            <Col span={11}>
                                <SelectPaymentMethod
                                    label={
                                        index === 0
                                            ? t(
                                                  'facilityBooking.schedule.checkInForm.paymentMethod',
                                              )
                                            : ''
                                    }
                                    name={`paymentMethodCheckIns.${index}.paymentMethodId`}
                                    placeholder={t(
                                        'facilityBooking.schedule.checkInForm.paymentMethodlaceholder',
                                    )}
                                    allowClear
                                    control={control}
                                    paymentMethodOptions={paymentMethodDropDown}
                                    optionLabelProp="label"
                                    showSearch={true}
                                    required
                                />
                            </Col>
                            <Col span={11}>
                                <InputPrice
                                    label={
                                        index === 0
                                            ? t(
                                                  'facilityBooking.schedule.checkInForm.price',
                                              )
                                            : ''
                                    }
                                    name={`paymentMethodCheckIns.${index}.amount`}
                                    control={control}
                                />
                            </Col>
                            <Col span={2}>
                                <Form.Item label={index === 0 ? ' ' : ''}>
                                    <DeleteOutlined
                                        onClick={() => {
                                            removeSaleItem(index);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    );
                })}

                <Row className="check-in-form-row">
                    <Col span={24}>
                        <Button
                            type="dashed"
                            block
                            className="addition-button"
                            onClick={addNewSaleItem}
                            disabled={disableAddSaleItemButton}
                        >
                            <PlusCircleOutlined />
                            {t('facilityBooking.schedule.checkInForm.additionBtn')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default FacilityBookingCheckInForm;
