import { yupResolver } from '@hookform/resolvers/yup';
import { Button, notification, Space } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { validateUniqueValue } from '~common/commonFunctions';
import { ErrorMessageType } from '~common/constants';
import { StaffLayoutHeader } from '~components';
import CreateSaleItemsForm from '~features/sale-item/components/CreateSaleItemsForm/CreateSaleItemsForm';
import { breadcrumbsCreate, saleItemTaxTypes } from '~features/sale-item/constants';
import { ISaleItemFormItem } from '~features/sale-item/interfaces';
import {
    bulkCreateSaleItems,
    getSaleGroupListForDropdown,
    getTaxListForDropdown,
    newSaleItemListSelector,
    setNewSaleItemList,
    showLoadingFormSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { bulkCreateSaleItemsSchema } from '~features/sale-item/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import { showConfirm } from '~common/notification';
import './CreateSaleItemPage.scss';
import { getSaleItemFormId } from '~features/sale-item/helper';

function CreateSaleItemPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const newSaleItemList = useAppSelector(newSaleItemListSelector);
    const showLoading = useAppSelector(showLoadingFormSelector);
    const { control, getValues, reset, handleSubmit, setError } = useForm({
        resolver: yupResolver(bulkCreateSaleItemsSchema),
    });

    useEffect(() => {
        dispatch(getSaleGroupListForDropdown());
        dispatch(getTaxListForDropdown({ taxTypes: saleItemTaxTypes }));
        dispatch(setNewSaleItemList([]));
    }, []);

    useEffect(() => {
        reset({
            items: newSaleItemList,
        });
    }, [newSaleItemList]);

    const _bulkCreateSaleItem = useCallback(async (saleItemList: ISaleItemFormItem[]) => {
        const response = await dispatch(
            bulkCreateSaleItems(
                saleItemList.map((saleItem, index) => {
                    return {
                        name: saleItem.name,
                        price: +saleItem.price,
                        taxId: saleItem.taxId,
                        isShowInBooking: saleItem.isShowInBooking,
                        order: index + 1,
                    };
                }),
            ),
        );
        if (bulkCreateSaleItems.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('saleItem.create.message.createSuccess'),
                });
                navigate('/front-settings/sale-item');
            } else {
                (response.payload?.errors || []).forEach((error) => {
                    if (error.key === 'name' && error.order) {
                        setError(
                            `items.${error.order - 1}.${error.key}`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                });
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    const onSubmit = async () => {
        handleSubmit(async (data) => {
            const newSaleItemList = getValues().items || [];
            if (!newSaleItemList.length) {
                return;
            }
            const duplicatedNameIndexes = validateUniqueValue(newSaleItemList, 'name');
            if (duplicatedNameIndexes.length) {
                duplicatedNameIndexes.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('saleItem.create.message.nameDuplicated'),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkCreateSaleItem(data.items);
        })();
    };

    const _showConfirm = () => {
        showConfirm({
            title: t('saleItem.create.confirm.cancelTitle'),
            cancelText: t('saleItem.create.confirm.buttonCancelText'),
            okText: t('saleItem.create.confirm.buttonDeleteText'),
            onOk() {
                navigate('/front-settings/sale-item');
            },
        });
    };

    return (
        <div className="create-sale-item-page">
            <StaffLayoutHeader
                title={t('saleItem.create.title')}
                breadcrumbs={breadcrumbsCreate()}
            />
            <Content className="create-sale-item-content">
                <CreateSaleItemsForm
                    control={control}
                    currentSaleItemList={getValues().items}
                />
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space>
                        <Button onClick={_showConfirm}>
                            {t('saleItem.create.cancelBtn')}
                        </Button>
                        <Button
                            type="primary"
                            id={getSaleItemFormId('btn-submit')}
                            onClick={onSubmit}
                            loading={showLoading}
                            disabled={getValues()?.items?.length === 0}
                        >
                            {t('saleItem.create.createBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default CreateSaleItemPage;
