import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '../../common/constants';
import { SearchField, initMarketingChannelListQuery } from './constants';

import {
    IMarketingChannelGetListQuery,
    IMarketingChannelState,
    IMarketingChannelBulkCreateFormItem,
    IMarketingChannelBulkUpdateFormItem,
    IMarketingChannelDropDownQuery,
} from './interfaces';

import { cloneDeep, trim } from 'lodash';
import { marketingChannelService } from './services/marketing-channel.api';
const initialState: IMarketingChannelState = {
    marketingChannelListQuery: cloneDeep(initMarketingChannelListQuery),
    marketingChannelDropDownList: [],
    totalMarketingChannels: 0,
    marketingChannelList: [],
    updateMarketingChannelFormBusy: false,
    createMarketingChannelList: [],
    updateMarketingChannelList: [],
    isShowUpdateMarketingChannelForm: false,
    showLoadingList: false,
    searchField: SearchField.ALL,
    keyword: '',
};

export const fetchMarketingChannelList = createAsyncThunk(
    'marketingChannel/fetchMarketingChannelList',
    async (_, { getState }) => {
        const query = marketingChannelListQuerySelector(getState() as AppState);
        return await marketingChannelService.getList(query);
    },
);

export const getMarketingChannelListByIds = createAsyncThunk(
    'marketingChannel/getMarketingChannelListByIds',
    async (ids: number[]) => {
        return await marketingChannelService.getListByIds(ids);
    },
);

export const bulkCreateMarketingChannels = createAsyncThunk(
    'marketingChannel/bulkCreateMarketingChannels',
    async (formData: IMarketingChannelBulkCreateFormItem[]) => {
        return await marketingChannelService.bulkCreate(formData);
    },
);

export const bulkUpdateMarketingChannels = createAsyncThunk(
    'marketingChannel/bulkUpdateMarketingChannels',
    async (formData: IMarketingChannelBulkUpdateFormItem[]) => {
        return await marketingChannelService.bulkUpdate(formData);
    },
);

export const fetchMarketingChannelDropDown = createAsyncThunk(
    'marketingChannel/dropdown',
    async (query: IMarketingChannelDropDownQuery) => {
        return await marketingChannelService.getMarketingChannelListForDropdown({
            ...query,
            isTemporary: query.isTemporary || false,
        });
    },
);

export const marketingChannelSlice = createSlice({
    name: 'marketingChannel',
    initialState,
    reducers: {
        setMarketingChannelListQuery: (state, action) => {
            state.marketingChannelListQuery = action.payload;
        },
        onSearchByKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IMarketingChannelGetListQuery = {
                limit: state.marketingChannelListQuery.limit,
                orderBy: state.marketingChannelListQuery.orderBy,
                orderDirection: state.marketingChannelListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.marketingChannelListQuery = query;
        },
        setMarketingChannelList: (state, action) => {
            state.marketingChannelList = action.payload;
        },
        setCreateMarketingChannelList: (state, action) => {
            state.createMarketingChannelList = action.payload;
        },
        setUpdateMarketingChannelList: (state, action) => {
            state.updateMarketingChannelList = action.payload;
        },
        setTotalMarketingChannels: (state, action) => {
            state.totalMarketingChannels = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload.columnName;
            const keyword = trim(action.payload.keyword || '');
            const query: IMarketingChannelGetListQuery = {
                limit: state.marketingChannelListQuery.limit,
                orderBy: state.marketingChannelListQuery.orderBy,
                orderDirection: state.marketingChannelListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.marketingChannelListQuery = query;
        },
        setIsShowUpdateMarketingChannelForm: (state, action) => {
            state.isShowUpdateMarketingChannelForm = action.payload;
        },
        setSearchKeyword: (state, action) => {
            state.keyword = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMarketingChannelList.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.marketingChannelList = action.payload?.data?.items || [];
            state.totalMarketingChannels = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchMarketingChannelList.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(getMarketingChannelListByIds.fulfilled, (state, action) => {
            state.updateMarketingChannelFormBusy = false;
            state.updateMarketingChannelList = action.payload?.data || [];
        });
        builder.addCase(getMarketingChannelListByIds.pending, (state, action) => {
            state.updateMarketingChannelFormBusy = true;
            state.updateMarketingChannelList = [];
        });
        builder.addCase(bulkCreateMarketingChannels.fulfilled, (state, action) => {
            state.showLoadingList = false;
            if (action.payload?.success) {
                state.createMarketingChannelList = [];
            }
        });
        builder.addCase(bulkCreateMarketingChannels.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(fetchMarketingChannelDropDown.fulfilled, (state, action) => {
            state.marketingChannelDropDownList = action.payload?.data?.items || [];
        });
    },
});

export const {
    setMarketingChannelListQuery,
    setMarketingChannelList,
    onSearchByKeyword,
    setTotalMarketingChannels,
    setSearchField,
    setCreateMarketingChannelList,
    setUpdateMarketingChannelList,
    setIsShowUpdateMarketingChannelForm,
    setSearchKeyword,
} = marketingChannelSlice.actions;

export const marketingChannelListSelector = (state: AppState) => {
    return state.marketingChannel.marketingChannelList;
};

export const marketingChannelDropDownListSelector = (state: AppState) => {
    return state.marketingChannel.marketingChannelDropDownList;
};

export const totalMarketingChannelsSelector = (state: AppState) => {
    return state.marketingChannel.totalMarketingChannels;
};

export const showLoadingListSelector = (state: AppState) => {
    return state.marketingChannel.showLoadingList;
};

export const marketingChannelListQuerySelector = (state: AppState) => {
    return state.marketingChannel.marketingChannelListQuery;
};

export const searchFieldSelector = (state: AppState) => {
    return state.marketingChannel.searchField;
};

export const searchKeywordSelector = (state: AppState) => {
    return state.marketingChannel.keyword;
};

export const updateMarketingChannelListSelector = (state: AppState) => {
    return state.marketingChannel.updateMarketingChannelList;
};

export const isShowUpdateMarketingChannelFormSelector = (state: AppState) => {
    return state.marketingChannel.isShowUpdateMarketingChannelForm;
};

export const updateMarketingChannelFormBusySelector = (state: AppState) => {
    return state.marketingChannel.updateMarketingChannelFormBusy;
};

export const createMarketingChannelListSelector = (state: AppState) => {
    return state.marketingChannel.createMarketingChannelList;
};

export const totalPageSelector = (state: AppState) => {
    const { totalMarketingChannels, marketingChannelListQuery } = state.marketingChannel;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = marketingChannelListQuery;
    return Math.ceil(totalMarketingChannels / limit);
};

export default marketingChannelSlice.reducer;
