import { Badge } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import {
    FacilityBookingStatusColor,
    LIMIT_FACILITY_BOOKING_CELL_ITEMS,
} from '~features/facility-booking/constants';
import {
    IFacilityBookingSchedule,
    IFacilityBookingScheduleItem,
} from '~features/facility-booking/interfaces';
import { useScheduleEvent } from '~features/room-booking/util';
import { showBookingDetailModal } from '~features/facility-booking/util';
import { useTranslation } from 'react-i18next';
import {
    setFacilityBookingScheduleList,
    setIsShowFacilityBookingScheduleListModal,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { useAppDispatch } from '~hooks';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';

type Props = {
    bookings: IFacilityBookingScheduleItem[];
    facilityTypeName: string;
    isOnlyShowMore?: boolean;
    onChangeStatusSuccess?: (booking: IFacilityBookingScheduleItem) => void;
    isFromRoomManagement?: boolean;
};
export const BookingList = ({
    bookings,
    facilityTypeName,
    isOnlyShowMore,
    onChangeStatusSuccess,
    isFromRoomManagement,
}: Props) => {
    const { triggerScroll } = useScheduleEvent();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = useMemo(() => {
        return !checkUserPermission([
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ]);
    }, []);
    const count = useMemo(() => {
        return bookings.length > LIMIT_FACILITY_BOOKING_CELL_ITEMS
            ? LIMIT_FACILITY_BOOKING_CELL_ITEMS
            : bookings.length;
    }, [bookings]);

    const handleClickOtherBookings = (bookingSchedule: IFacilityBookingSchedule) => {
        dispatch(setFacilityBookingScheduleList(bookingSchedule));
        dispatch(setIsShowFacilityBookingScheduleListModal(true));
    };

    return (
        <div className="facility-booking-list">
            {!isOnlyShowMore &&
                bookings
                    .filter((_, index) => index < count)
                    .map((booking, index) => {
                        return (
                            <div
                                className="weekly-view-cell-item text-truncate"
                                onClick={(e) => {
                                    triggerScroll(false);
                                    const element = e.currentTarget.children?.[0];
                                    if (element) {
                                        showBookingDetailModal({
                                            booking,
                                            element: element as HTMLElement,
                                            navigate: navigate,
                                            onClose: () => {
                                                triggerScroll(true);
                                            },
                                            onChangeStatusSuccess,
                                            isReadOnly,
                                            isShowEditBtn: true,
                                            position: {
                                                pageX: e.pageX,
                                                pageY: e.pageY,
                                            },
                                        });
                                    }
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                key={`${booking.id}_${index}`}
                            >
                                <div id={'booking_' + booking.id}></div>
                                <span>{`${booking.checkInTimeWeekView} - ${booking.checkOutTimeWeekView}`}</span>
                                <Badge
                                    color={FacilityBookingStatusColor[booking.status]}
                                />
                                <span className="guest-name text-truncate">
                                    {booking.guest?.yomigana}
                                </span>
                            </div>
                        );
                    })}
            {(isOnlyShowMore || bookings.length > LIMIT_FACILITY_BOOKING_CELL_ITEMS) && (
                <div
                    className="other-facility-bookings"
                    onClick={(e) => {
                        handleClickOtherBookings({
                            parentName: facilityTypeName,
                            facilityKey: bookings[0].facilityKey,
                            date: bookings[0].date,
                        });
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    {t('facilityBooking.schedule.otherBookings', {
                        count: isOnlyShowMore
                            ? bookings.length
                            : bookings.length - LIMIT_FACILITY_BOOKING_CELL_ITEMS,
                    })}
                </div>
            )}
        </div>
    );
};
