import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputNumber, InputText, RadioGroup } from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useCreateBankAccount } from '~features/hotel/hooks/useCreateBankAccount';
import { useUpdateBankAccount } from '~features/hotel/hooks/useUpdateBankAccount';
import { setEditingBankAccount, setShowBankAccountModal, showBankAccountModal } from '~features/hotel/hotel.reducer';
import { BankAccountCreateBody, BankAccountResponseBody, BankAccountUpdateBody } from '~features/hotel/interfaces';
import { bankAccountBodySchema } from '~features/hotel/schema';
import { BANK_ACCOUNT_TYPE } from '~features/hotel/constants';
import queryClient, { CacheKeys } from '~queries/queries';

type Props = {
    editingBankAccount: BankAccountResponseBody | null;
};

const defaultBankAccountValue: BankAccountResponseBody = {
    id: 0,
    bankName: '',
    branchName: '',
    accountType: BANK_ACCOUNT_TYPE.SAVING,
    accountNumber: '',
    accountOwner: '',
}

function BankAccountModal({ editingBankAccount }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowBankAccountModal = useAppSelector(showBankAccountModal);
    const { isPending: isCreatingBankAccount, mutateAsync: createBankAccount } = useCreateBankAccount();
    const { isPending: isUpdatingBankAccount, mutateAsync: updateBankAccount } = useUpdateBankAccount();
    const { control, handleSubmit, reset } = useForm<BankAccountCreateBody | BankAccountUpdateBody>({
        resolver: yupResolver(bankAccountBodySchema),
    });

    useEffect(() => {
        reset({ ...editingBankAccount });
    }, [editingBankAccount]);

    const onSubmit = () => {
        handleSubmit((data: BankAccountCreateBody | BankAccountUpdateBody) => {
            const invalidateBankAccounts = () => {
                queryClient.invalidateQueries({
                    queryKey: [CacheKeys.bankAccounts]
                });
                onClose();
            }

            if (editingBankAccount) {
                updateBankAccount({
                    id: editingBankAccount.id,
                    bankName: data.bankName,
                    branchName: data.branchName,
                    accountType: data.accountType,
                    accountNumber: data.accountNumber,
                    accountOwner: data.accountOwner,
                })
                .then(() => invalidateBankAccounts());
            } else {
                createBankAccount({
                    bankName: data.bankName as string,
                    branchName: data.branchName as string,
                    accountType: data.accountType as string,
                    accountNumber: data.accountNumber as string,
                    accountOwner: data.accountOwner as string,
                })
                .then(() => invalidateBankAccounts());
            }
        })();
    };

    const onClose = () => {
        dispatch(setShowBankAccountModal(false));
        dispatch(setEditingBankAccount(null));
        reset(defaultBankAccountValue);
    };

    return (
        <Modal
            className="bank-account-modal-wrapper"
            title={t('hotel.settingForm.bankAccount.titleAdd')}
            open={isShowBankAccountModal}
            width={500}
            okText={t('hotel.settingForm.bankAccount.save')}
            cancelText={t('hotel.settingForm.bankAccount.cancel')}
            onOk={onSubmit}
            onCancel={onClose}
            confirmLoading={isCreatingBankAccount || isUpdatingBankAccount}
        >
            <Form layout="vertical">
                <InputText
                    label={t('hotel.settingForm.bankAccount.bankName')}
                    maxLength={255}
                    name="bankName"
                    control={control}
                    required
                />
                <InputText
                    label={t('hotel.settingForm.bankAccount.branchName')}
                    maxLength={255}
                    name="branchName"
                    control={control}
                    required
                />
                <RadioGroup
                    label={t('hotel.settingForm.bankAccount.accountType')}
                    name="accountType"
                    control={control}
                    defaultValue={editingBankAccount?.accountType || BANK_ACCOUNT_TYPE.SAVING}
                    options={[
                        {
                            label: t('hotel.settingForm.bankAccount.saving'),
                            value: BANK_ACCOUNT_TYPE.SAVING,
                        },
                        {
                            label: t('hotel.settingForm.bankAccount.checking'),
                            value: BANK_ACCOUNT_TYPE.CHECKING,
                        }
                    ]}
                />
                <InputNumber
                    label={t('hotel.settingForm.bankAccount.accountNumber')}
                    maxLength={255}
                    name="accountNumber"
                    control={control}
                    required
                />
                <InputText
                    label={t('hotel.settingForm.bankAccount.accountOwner')}
                    maxLength={255}
                    name="accountOwner"
                    control={control}
                    required
                />
            </Form>
        </Modal>
    )
}

export default BankAccountModal;