import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    IBookingConfirmDataItem,
    IBookingGuestInfo,
    IMemberAttachment,
} from '~features/room-booking/interfaces';
import { bookingInfoDefault, guestDefault } from '~features/room-booking/model';
import {
    setBookingConfirmData,
    setBookingInfo,
    setCurrentBookingSelected,
} from '~features/room-booking/reducers/create-booking.reducer';
import { scheduleStateSelector } from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import BookingTemporaryGridMode from './BookingTemporaryGridMode/BookingTemporaryGridMode';
import BookingTemporaryListMode from './BookingTemporaryListMode/BookingTemporaryListMode';
import './BookingTemporaryList.scss';

export const BookingTemporaryList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = React.useState('booking_tmp');
    const [isListMode, setIsListMode] = React.useState(false);
    const { bookingTemporaryList } = useAppSelector(scheduleStateSelector);

    const gotoCreateBooking = () => {
        const list: IBookingConfirmDataItem[] = bookingTemporaryList.map((booking) => {
            return {
                booking,
                memo: null,
                checkInTime: null,
                isAssignedRoom: !!booking.room?.id,
            };
        });
        let numberOfGuests = 0;
        bookingTemporaryList.forEach((booking) => {
            numberOfGuests += Number(booking.numberOfAdults || 0);
        });
        const arr = Array.from({ length: numberOfGuests - 1 }, (_, index) => ({
            ...guestDefault,
            index: index + 1,
            gender: null,
            birthday: null,
            emailAddress: null,
        })) as IMemberAttachment[];
        const info: IBookingGuestInfo = { ...bookingInfoDefault, attachments: [...arr] };
        dispatch(setCurrentBookingSelected(bookingTemporaryList?.[0]?.id || 0));
        dispatch(setBookingInfo(info));
        dispatch(setBookingConfirmData(list));
        navigate('/room-booking/create-booking');
    };

    const changeViewMode = (e: any) => {
        e.stopPropagation();
        setIsListMode(!isListMode);
    };

    return (
        <Collapse
            activeKey={activeKey}
            className="booking-tmp-collapse-wrapper"
            onChange={() => setActiveKey(activeKey ? '' : 'booking_tmp')}
        >
            <Collapse.Panel
                className="booking-tmp-collapse-panel-item"
                header={
                    <div className="booking-tmp-collapse-header">
                        <div className="booking-tmp-collapse-header-left">
                            <span className="title">
                                {t('roomBooking.schedule.currentBooking')} (
                                {bookingTemporaryList.length})
                            </span>
                            <Button
                                className="ml-24"
                                type="primary"
                                id="btn-goto-create-booking"
                                onClick={gotoCreateBooking}
                            >
                                {t('roomBooking.schedule.resevationOverview')}
                            </Button>
                        </div>
                        <div className="booking-tmp-collapse-header-right">
                            {!isListMode ? (
                                <Button
                                    type="text"
                                    shape="circle"
                                    onClick={changeViewMode}
                                    icon={<UnorderedListOutlined className="icon-size" />}
                                />
                            ) : (
                                <Button
                                    type="text"
                                    shape="circle"
                                    onClick={changeViewMode}
                                    icon={<AppstoreOutlined className="icon-size" />}
                                />
                            )}
                        </div>
                    </div>
                }
                key={'booking_tmp'}
            >
                {isListMode ? <BookingTemporaryListMode /> : <BookingTemporaryGridMode />}
            </Collapse.Panel>
        </Collapse>
    );
};
