const inventoryList = {
    title: 'Inventory management',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        inventory: 'Inventory management',
        stopSelling: 'Stop selling setting',
    },
    syncButton: 'Sync inventory with TLL',
    settingButton: 'Initial inventory setting',
    dateDesignation: 'Date designation',
    stopSellingButton: 'Stop selling setting',
    editButton: 'Edit',
    saveButton: 'Save',
    bulkStopSellingButton: 'Bulk stop',
    inventoryColumn: {
        stoppingRooms: 'Stop inventory',
        availableRooms: 'Remaining rooms',
    },
    day: {
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
        sunday: 'Sun',
    },
    year: 'Year',
    month: 'Month',
    message: {
        updateSuccess: 'Update success',
        updateStoppingFaild:
            "Can't update stopping room count bigger than available rooms",
        stoppingRoomEmpty: 'Please input stoping room number',
        roomError: 'There is no room exists in selected room types',
    },
    syncInventoryStart:
        'We are synchronizing inventorys with TLL, please wait a few minutes and reload again.',
};

export default inventoryList;
