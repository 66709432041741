const facilityBookingScheduleJp = {
    otherBookings: '他　{{count}}予約',
    title: '設備スケジュール',
    remaining: '残っている設備',
    remove: '予約を解除',
    showDetail: '詳細を見る',
    statusDropdown: {
        title: 'ステータスを変更',
    },
    modalConfirmDeletion: {
        title: '削除の確認',
        description: 'この設備予約を削除してもよろしいですか？',
        cancelBtn: 'キャンセル',
        deleteBtn: '削除',
    },
    modalConfirmCancel: {
        title: 'こちらの予約をキャンセルしますか？',
    },
    modalConfirmReserve: {
        title: 'こちらを予約しますか？',
    },
    modalConfirmCheckout: {
        title: 'ステータス変更の確認',
        description: 'ステータスは「使用終了」に変更しますがよろしいでしょうか？',
        cancelBtn: 'キャンセル',
        okBtn: '変更',
    },
    modalCheckoutSuccess: {
        title: 'ステータスが使用終了に変更されました。',
        okBtn: '閉じる',
    },
    modalCheckoutFailed: {
        title: '未集金のため精算されていません',
        description: '予約詳細に移動し、精算してください。',
        cancelBtn: 'キャンセル',
        okBtn: '予約詳細に移動',
    },
    modalCheckInFailed: {
        title: 'エラー',
        description: '以下の設備予約が開始できません',
        okBtn: '閉じる',
    },
    status: {
        cancelled: 'キャンセル',
        started: 'チェックイン',
        finished: 'チェックアウト',
        reserved: '予約済み',
    },
    checkInForm: {
        title: 'チェックイン',
        facilityType: '設備タイプ',
        facilityName: '設備名',
        note: '予約から使用開始にステータスを変更します。デポジットがある場合は、選択・入力して下さい。',
        saleItem: '販売科目',
        paymentMethod: '支払い方法',
        saleItemPlaceholder: '選択してください',
        paymentMethodlaceholder: '支払い方法選択',
        price: '金額',
        additionBtn: '追加',
        totalAmount: '未収金',
        cancelBtn: 'キャンセル',
        submitBtn: 'チェックイン',
        cancelTitle: 'キャンセルしてもよろしいですか？',
    },
    message: {
        cannotCheckIn: {
            fromReserved: 'こちらの予約は{{date}}の前には開始できません',
            fromFinish: 'こちらの予約は終了時刻が過ぎているため開始できません',
        },
    },
};

export default facilityBookingScheduleJp;
