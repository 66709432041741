import { axiosInstanceV2 } from "~plugins/axios";
import { ApiService } from "~plugins/axios/api";
import { IBodyResponse } from "~common/interfaces";
import { IMergeGuestsBody } from "../interfaces";

class GuestServiceV2 extends ApiService {
    mergeGuests(
        body: IMergeGuestsBody
    ) {
        return this._post(
            `${this.baseUrl}/merge`,
            body,
        ) as Promise<IBodyResponse<any>>;
    }
}

export const guestServiceV2 = new GuestServiceV2(
    {
        baseUrl: '/guest',
    },
    axiosInstanceV2,
);
