/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, notification, Space } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { uniq } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showConfirm } from '~common/notification';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '~features/sale-group/constants';
import { IImportSaleGroup } from '~features/sale-group/interfaces';
import {
    importCsvSaleGroup,
    saleGroupSelector,
    setHasImportError,
    setImportSaleGroupList,
    setShowLoading,
} from '~features/sale-group/reducers/sale-group.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import ImportCsvSaleGroup from './components/ImportCsvSaleGroup/ImportCsvSaleGroup';

function ImportCsvSaleGroupPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [responseError, setResponseError] = useState<Record<number, any>>({});
    const { importSaleGroupList, hasImportError } = useAppSelector(saleGroupSelector);

    useEffect(() => {
        dispatch(setShowLoading(false));
        if (!importSaleGroupList?.length) navigate('/front-settings/sale-group');
    }, []);

    const _showConfirm = () => {
        showConfirm({
            title: t('saleGroup.import.cancelTitle'),
            onOk() {
                dispatch(setImportSaleGroupList([]));
                navigate('/front-settings/sale-group');
            },
        });
    };

    const _importSaleGroups = useCallback(async (saleGroups: IImportSaleGroup[]) => {
        const response = await dispatch(importCsvSaleGroup(saleGroups));
        if (importCsvSaleGroup.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('saleGroup.import.importSuccessMessage'),
                });
                navigate('/front-settings/sale-group');
            } else {
                let errors = {};
                uniq(
                    (response.payload?.errors || []).map((error) => error.order),
                ).forEach((order) => {
                    if (order) {
                        let currentError = {};
                        (response.payload?.errors || [])
                            .filter((error) => error.order === order)
                            .forEach((error) => {
                                if (
                                    (error.key === 'name' || error.key === 'saleItems') &&
                                    error.order
                                ) {
                                    currentError = {
                                        ...currentError,
                                        [error.key]: error.message,
                                    };
                                }
                            });
                        errors = {
                            ...errors,
                            [order]: currentError,
                        };
                    }
                });
                dispatch(setHasImportError(true));
                setResponseError(errors);
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    const onSubmit = () => {
        _importSaleGroups(importSaleGroupList);
    };

    return (
        <div className="import-csv-sale-group-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('saleGroup.form.importTitle')}
            />
            <Content>
                <ImportCsvSaleGroup responseError={responseError} />
            </Content>
            <Footer>
                <div className="d-flex j-end">
                    <Space>
                        <Button onClick={_showConfirm}>
                            {t('saleGroup.import.cancelBtn')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={onSubmit}
                            disabled={hasImportError}
                        >
                            {t('saleGroup.import.importBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default ImportCsvSaleGroupPage;
