import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import forEach from 'lodash/forEach';
import sum from 'lodash/sum';
import values from 'lodash/values';
import { ScheduleViewMode } from '~common/constants';
import {
    IPaymentRoomBookingItem,
    IScheduleItem,
    NumberObject,
} from '~features/room-booking/interfaces';
import { PricingType } from '~features/tax/constants';
import { ITax } from '~features/tax/interfaces';
import { MIN_FACILITY_BOOKING_TIME } from './constants';
import {
    IBookingsResponse,
    IFacilityBookingList,
    IFacilityBookingReceiptTableData,
    IFacilityBookingScheduleItem,
    IFacilityBookingStatisticTypeResponse,
} from './interfaces';
import { sortBy } from 'lodash';

export const parseFacilityList = (response: IFacilityBookingStatisticTypeResponse) => {
    const { items } = response;
    const facilityList: IScheduleItem[] = [];
    forEach(items, (item) => {
        const {
            id,
            name,
            facilities,
            numberOfFacilities,
            businessEndTime,
            businessStartTime,
        } = item;
        facilityList.push({
            id,
            name,
            parentId: null,
            parentName: '',
            numberOfRooms: numberOfFacilities,
            businessEndTime,
            businessStartTime,
        });
        forEach(sortBy(facilities, 'name'), (facility) => {
            facilityList.push({
                id: facility.id,
                name: facility.name,
                parentId: id,
                parentName: name,
                numberOfRooms: 0,
                businessEndTime,
                businessStartTime,
            });
        });
    });
    return facilityList;
};

export const getBookingListFromBooking = (
    booking: IBookingsResponse,
    typeView = ScheduleViewMode.WEEK,
) => {
    const bookingList: IFacilityBookingList = {};
    const start = parseDate(booking.startDatetime);
    const end = parseDate(booking.endDatetime);
    const facilityKey = `facility-${booking.facilityId}`;
    const _booking: IFacilityBookingScheduleItem = {
        id: booking.id,
        facilityKey: facilityKey,
        guest: booking.guest,
        date: '',
        facilityId: booking.facilityId,
        checkInTimeWeekView: start.clone()?.fmHHmm(),
        checkInTime: start.clone()?.fmHHmm(),
        checkOutTime: end.clone()?.fmHHmm(),
        checkOutTimeWeekView: end.clone()?.fmHHmm(),
        checkInDateTime: start.clone()?.fmYYYYMMDDHHmm('-'),
        checkOutDateTime: end.clone()?.fmYYYYMMDDHHmm('-'),
        status: booking.status,
        numberOfGuests: booking.numberOfGuests,
        facility: booking.facility,
        rooms: booking.roomOfBookings || [],
        totalAmount: booking?.totalAmount,
    };

    if (typeView === ScheduleViewMode.WEEK) {
        const diff = end.clone().endOf('day').diff(start.clone().startOf('day'), 'day');
        for (let i = 0; i <= diff; i++) {
            const _date = start.clone().add(i, 'day')?.fmYYYYMMDD('-');
            const checkInTime =
                i >= 1
                    ? start.clone().add(i, 'day').startOf('day')?.fmHHmm(':')
                    : start.fmHHmm(':');
            const checkOutTime =
                i < diff
                    ? start.clone().add(i, 'day').endOf('day')?.fmHHmm(':')
                    : end.fmHHmm(':');
            if (checkInTime === checkOutTime) continue;
            const _bookingWithDate = {
                ..._booking,
                date: _date,
                checkInTimeWeekView: checkInTime,
                checkOutTimeWeekView: checkOutTime,
            };
            if (bookingList[_date]) {
                if (bookingList[_date][facilityKey]) {
                    bookingList[_date][facilityKey].push(_bookingWithDate);
                } else {
                    bookingList[_date][facilityKey] = [_bookingWithDate];
                }
            } else {
                bookingList[_date] = {
                    [facilityKey]: [_bookingWithDate],
                };
            }
        }
    } else {
        const _date = start.clone()?.fmYYYYMMDD('-');
        const _bookingWithStyle = {
            ..._booking,
            date: _date,
        };
        if (bookingList[_date]) {
            if (bookingList[_date][facilityKey]) {
                bookingList[_date][facilityKey].push(_bookingWithStyle);
            } else {
                bookingList[_date][facilityKey] = [_bookingWithStyle];
            }
        } else {
            bookingList[_date] = {
                [facilityKey]: [_bookingWithStyle],
            };
        }
    }
    return bookingList;
};

export const parseBookingList = (
    items: Record<string, IBookingsResponse>,
    options: { typeView: ScheduleViewMode },
): IFacilityBookingList => {
    const bookingList: IFacilityBookingList = {};
    const { typeView } = options;
    forEach(items, (booking) => {
        const _bookingList = getBookingListFromBooking(booking, typeView);
        forEach(_bookingList, (facilityBookingList, date) => {
            forEach(facilityBookingList, (bookings, facilityKey) => {
                if (bookingList[date]) {
                    if (bookingList[date][facilityKey]) {
                        bookingList[date][facilityKey] =
                            bookingList[date][facilityKey].concat(bookings);
                    } else {
                        bookingList[date][facilityKey] = bookings;
                    }
                } else {
                    bookingList[date] = {
                        [facilityKey]: bookings,
                    };
                }
            });
        });
    });
    return bookingList;
};

export const buildStatisticsByDate = (
    bookingList: IFacilityBookingList,
): Record<string, NumberObject> => {
    const result: Record<string, NumberObject> = {};
    forEach(bookingList, (roomBooking) => {
        forEach(roomBooking, (bookings) => {
            forEach(bookings, (booking) => {
                const roomKey = booking.facilityKey;
                const start = parseDate(booking.checkInDateTime);
                const diff = parseDate(booking.checkOutDateTime).diff(start, 'day');
                for (let i = 0; i <= diff; i++) {
                    const date = start.clone().add(i, 'day')?.fmYYYYMMDD('-');
                    if (!result[date]) {
                        result[date] = {
                            [roomKey]: 1,
                        };
                    } else if (!result[date][roomKey]) {
                        result[date][roomKey] = 1;
                    }
                }
            });
        });
    });

    forEach(result, (value, date) => {
        result[date] = {
            ...value,
            total: sum(values(value)),
        };
    });
    return result;
};

export const checkValidRangeDate = (options: {
    facilityKey: string;
    start: Dayjs;
    end: Dayjs;
    statisticByDate: Record<string, NumberObject>;
}) => {
    const { start, end, statisticByDate, facilityKey } = options;
    const diff = end.diff(start, 'day');
    for (let i = 0; i <= diff; i++) {
        const count =
            statisticByDate[start.clone().add(i, 'days')?.fmYYYYMMDD('-')]?.[
                facilityKey
            ] || 0;
        if (count) {
            return false;
        }
    }
    return true;
};

export const checkValidRangeDateTime = (options: {
    facilityKey: string;
    start: Dayjs;
    end: Dayjs;
    bookingList: IFacilityBookingList;
}) => {
    const { start, end, bookingList, facilityKey } = options;
    const diffDay = end.diff(start, 'day');
    const startUnix = start.unix();
    const endUnix = end.unix();
    for (let i = 0; i <= diffDay; i++) {
        const bookings =
            bookingList[start.clone().add(i, 'day')?.fmYYYYMMDD('-')]?.[facilityKey] ||
            [];
        for (let index = 0; index < bookings.length; index++) {
            const booking = bookings[index];
            const bookingStart = parseDate(booking.checkInDateTime).unix();
            const bookingEnd = parseDate(booking.checkOutDateTime).unix();
            if (startUnix < bookingEnd && endUnix > bookingStart) {
                return false;
            }
        }
    }
    return true;
};

export const validateDateTimeGreaterNow = (dateTime?: Dayjs) => {
    const startStayingDate = dateTime || null;
    if (startStayingDate) {
        const diffCurrent = startStayingDate.diff(customDayjs(), 'minute');
        if (diffCurrent < 0) return false;
    } else {
        return false;
    }
    return true;
};

export const validateDateAfterTwoDaysBefore = (date: Dayjs) => {
    if (!date) {
        return false;
    }
    return date.isSameOrAfter(customDayjs().subtract(2, 'day'), 'day');
};

export function getFacilityBookingFormId(name: string) {
    return `facility-booking-form-${name}`;
}

export const getById = (tree: IFacilityBookingReceiptTableData, id: string) => {
    let result = null;
    if (id === tree.id) {
        return tree;
    } else {
        if (tree.children) {
            tree.children.some((node) => (result = getById(node, id)));
        }
        return result;
    }
};

export const findReceiptRowById = (
    tree: IFacilityBookingReceiptTableData[],
    id: string,
) => {
    let result = null;
    for (const treeData of tree) {
        result = getById(treeData, id);
        if (result) return result;
    }
    return result;
};

export const validateBusinessTime = (
    bookingTimes: Dayjs[],
    businessStartTime: string,
    businessEndTime: string,
) => {
    if (bookingTimes?.length !== 2) return false;
    const startDatetime = parseDate(bookingTimes?.[0]);
    const endDatetime = parseDate(bookingTimes?.[1]);

    let businessStartDateTime = parseDate(
        `${startDatetime.fmYYYYMMDD()} ${businessStartTime}`,
    );
    let businessEndDateTime = parseDate(
        `${startDatetime.fmYYYYMMDD()} ${businessEndTime}`,
    );

    if (businessStartDateTime.isAfter(businessEndDateTime, 'minute')) {
        businessEndDateTime = businessEndDateTime.add(1, 'day');
    }

    if (
        startDatetime.isSameOrAfter(businessStartDateTime, 'minute') &&
        endDatetime.isSameOrBefore(businessEndDateTime, 'minute')
    ) {
        return true;
    }

    return false;
};

export const validateStayDateTimes = (bookingTimes: Dayjs[]) => {
    const startStayingDate = bookingTimes?.[0]
        ? parseDate(parseDate(bookingTimes[0])?.fmYYYYMMDDHHmm())
        : null;
    const endStayingDate = bookingTimes?.[1]
        ? parseDate(parseDate(bookingTimes[1])?.fmYYYYMMDDHHmm())
        : null;
    if (!startStayingDate || !endStayingDate) {
        return false;
    }
    if (endStayingDate.diff(startStayingDate, 'minute') <= MIN_FACILITY_BOOKING_TIME) {
        return false;
    }
    return true;
};

export const calculateTaxSaleItem = (tax: ITax, quantity: number, price: number) => {
    let taxTotal = 0;
    switch (tax?.pricingType) {
        case PricingType.FIX:
            taxTotal = quantity * tax.pricingValue;
            break;

        case PricingType.PERCENTAGE:
            taxTotal = (quantity * price * tax.pricingValue) / (100 + tax?.pricingValue);
            break;
    }
    return Math.floor(taxTotal);
};

export const findPaymentRoom = (roomBookingItemList: IPaymentRoomBookingItem[]) => {
    const roomBookingItemHasRoomList = roomBookingItemList?.filter((roomBookingItem) => {
        return roomBookingItem?.room;
    });

    const representativeRoom = roomBookingItemHasRoomList.find((roomBookingItem) => {
        return roomBookingItem?.isRepresentativeRoom;
    });

    if (representativeRoom) {
        return representativeRoom;
    }

    return roomBookingItemHasRoomList?.[0];
};

export const disabledBirthDayDate = (current: Dayjs) => {
    // Can not select days after today
    return current.isAfter(customDayjs().subtract(1, 'day'), 'day');
};

export const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};
