import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
    TllRank,
} from '~common/constants';
import i18next from '~plugins/i18next/i18n';

export const breadcrumbs = () => [
    {
        text: i18next.t('rankCalendar.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('rankCalendar.list.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('rankCalendar.list.breadcrumbs.rankCalendar'),
    },
];

export const breadcrumbsDetail = () => [
    {
        text: i18next.t('rankCalendar.detail.breadcrumbs.home'),
    },
    {
        text: i18next.t('rankCalendar.detail.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('rankCalendar.detail.breadcrumbs.rankCalendar'),
    },
    {
        text: i18next.t('rankCalendar.detail.breadcrumbs.rankCalendarDetail'),
    },
];

export const breadcrumbsUpdate = () => [
    {
        text: i18next.t('rankCalendar.update.breadcrumbs.home'),
    },
    {
        text: i18next.t('rankCalendar.update.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('rankCalendar.update.breadcrumbs.rankCalendar'),
    },
    {
        text: i18next.t('rankCalendar.update.breadcrumbs.rankCalendarDetail'),
    },
];

export enum RankCalendarColumn {
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    ID = 'id',
    NAME = 'name',
    STATUS = 'status',
}

export enum OrderBy {
    ID = 'id',
    NAME = 'name',
}

export const initRankCalendar = {
    name: '',
    monday: TllRank.A,
    tuesday: TllRank.A,
    wednesday: TllRank.A,
    thursday: TllRank.A,
    friday: TllRank.A,
    saturday: TllRank.A,
    sunday: TllRank.A,
    planRankDurations: [],
};

export enum Status {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export const initRankCalendarListQuery = {
    orderBy: OrderBy.ID,
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
    keyword: '',
};

export enum CalendarDay {
    SUNDAY = 'sunday',
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
}

export const TLL_RANK_UNCHANGED = 'no_change';
