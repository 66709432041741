import { Button, Form } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RangePicker } from '~components';
import {
    getBookingUnassignedList,
    setDateFilterUnassignBookingList,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import './FilterUnassignForm.scss';
import { scheduleStateSelector } from '~features/room-booking/reducers/schedule.reducer';

function FilterUnassignForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isShowUnassignList, dateHeaderFilterUnassignForm } =
        useAppSelector(scheduleStateSelector);
    const { control, getValues, setValue } = useForm();
    const onClickButtonFilter = () => {
        const unassignBookingDate = getValues().bookingDate;
        const startDate = parseDate(unassignBookingDate?.[0]).startOf('day').fmYYYYMMDD();
        const endDate = parseDate(unassignBookingDate?.[1]).endOf('day').fmYYYYMMDD();
        dispatch(setDateFilterUnassignBookingList([startDate, endDate]));
        dispatch(
            getBookingUnassignedList({
                start: startDate,
                end: endDate,
                isFromBookingSchedule: false,
            }),
        );
    };
    useEffect(() => {
        if (dateHeaderFilterUnassignForm) {
            const selectedStartDate = parseDate(dateHeaderFilterUnassignForm[0]);
            const selectedEndDate = parseDate(dateHeaderFilterUnassignForm[1]);
            setValue(`bookingDate`, [selectedStartDate, selectedEndDate]);
            const startDate = selectedStartDate.startOf('day').fmYYYYMMDD();
            const endDate = selectedEndDate.endOf('day').fmYYYYMMDD();
            dispatch(setDateFilterUnassignBookingList([startDate, endDate]));
        } else {
            const selectedStartDate = customDayjs();
            const selectedEndDate = customDayjs().add(1, 'day');
            setValue(`bookingDate`, [selectedStartDate, selectedEndDate]);
            const startDate = parseDate(selectedStartDate).fmYYYYMMDD();
            const endDate = parseDate(selectedEndDate).fmYYYYMMDD();
            dispatch(setDateFilterUnassignBookingList([startDate, endDate]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowUnassignList]);

    return (
        <div className="usassign-filter-wrapper">
            <Form
                layout="vertical"
                autoComplete="off"
                scrollToFirstError
                className="usassign-filter-form"
            >
                <RangePicker
                    label={``}
                    name={'bookingDate'}
                    defaultValue={[customDayjs(), customDayjs().add(1, 'day')]}
                    control={control}
                />
            </Form>
            <Button type="primary" onClick={onClickButtonFilter}>
                {t('common.searchPlaceholder')}
            </Button>
        </div>
    );
}

export default FilterUnassignForm;
