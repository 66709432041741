import { Badge, Button, Radio, Switch, Alert } from 'antd';
import { DatePicker } from '~components';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    facilityManagementFilterStatus,
    roomManagementFilterStatus,
    RoomManagementStatusColor,
} from '~features/room-management/constants';
import {
    roomManagementSelector,
    getRoomListStatus,
    setCurrentDate,
    setCurrentStatus,
    setIsShowBookingList,
    getBookingUnassignedList,
    roomManagementDetailSelector,
    getFacilityBookings,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { IndicatorSelect } from '../IndicatorSelect/IndicatorSelect';
import './HeaderControl.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { parseDate, todayDayjs } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import { RoomBookingEvent } from '~features/room-booking/constants';
import { IndicatorType } from '~features/indicator/constants';
import {
    fetchFacilityList,
    setBookingList,
    setCurrentStatus as setCurrentFacilityStatus,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { FacilityBookingStatus } from '~features/facility-booking/constants';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, SupportLanguage, UserGroup } from '~common/constants';
import { currentLanguageSelector } from '~common/reducers/app.reducer';
import localeJa from 'antd/es/date-picker/locale/ja_JP';
import localeEn from 'antd/es/date-picker/locale/en_US';

export const HeaderControl = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { currentStatus, currentDate, isShowBookingList, facilityBookings } =
        useAppSelector(roomManagementSelector);
    const currentIndicator = useAppSelector(roomManagementDetailSelector);
    const [showAlert, setShowAlert] = React.useState(false);
    const currentLang = useAppSelector(currentLanguageSelector);

    const DatePickerLanguage = useMemo(() => {
        if (currentLang === SupportLanguage.EN) return localeEn;
        return localeJa;
    }, [currentLang]);
    useEffect(() => {
        dispatch(setBookingList({ [currentDate]: facilityBookings }));
        dispatch(setCurrentStatus(FacilityBookingStatus.ALL));
    }, [facilityBookings, currentDate, dispatch]);
    const isReadOnly = useMemo(() => {
        return !checkUserPermission([
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ]);
    }, []);
    const isRoom = useMemo(() => {
        return currentIndicator?.indicatorType === IndicatorType.ROOM;
    }, [currentIndicator]);

    const options = useMemo(() => {
        if (!currentIndicator?.indicatorType) return [];
        if (isRoom) {
            return roomManagementFilterStatus.map((item) => ({
                label: (
                    <div>
                        {item !== 'all' && (
                            <Badge
                                className={item}
                                color={RoomManagementStatusColor[item]}
                            />
                        )}
                        {t(`roomManagement.list.roomStatus.${item}`)}
                    </div>
                ),
                value: item,
            }));
        }
        return facilityManagementFilterStatus.map((item) => ({
            label: (
                <div>
                    {item !== 'all' && (
                        <Badge className={item} color={RoomManagementStatusColor[item]} />
                    )}
                    {t(`roomManagement.list.facilityStatus.${item}`)}
                </div>
            ),
            value: item,
        }));
    }, [isRoom, t, currentIndicator]);

    useEffect(() => {
        return () => {
            dispatch(setCurrentFacilityStatus(FacilityBookingStatus.ALL));
        };
    }, [dispatch]);

    const onChange = (val: string) => {
        dispatch(setCurrentStatus(val));
    };

    const onSwitchChange = (val: boolean) => {
        dispatch(setIsShowBookingList(val));
    };

    const fetchData = () => {
        if (isRoom) {
            dispatch(getRoomListStatus());
            dispatch(getBookingUnassignedList());
        } else {
            dispatch(getFacilityBookings());
            dispatch(
                fetchFacilityList({
                    startDate: parseDate(currentDate).startOf('day').fmYYYYMMDDHHmmss(),
                    endDate: parseDate(currentDate).endOf('day').fmYYYYMMDDHHmmss(),
                }),
            );
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onDateSelect = (val: any) => {
        dispatch(setCurrentDate(val.fmYYYYMMDD()));
        fetchData();
    };

    const nextDate = () => {
        dispatch(setCurrentDate(parseDate(currentDate).add(1, 'day')?.fmYYYYMMDD()));
        fetchData();
    };

    const prevDate = () => {
        dispatch(setCurrentDate(parseDate(currentDate).subtract(1, 'day')?.fmYYYYMMDD()));
        fetchData();
    };

    const backToCurrentDate = () => {
        dispatch(setCurrentDate(todayDayjs.fmYYYYMMDD()));
        fetchData();
    };

    const { emitter } = useMitt();

    useEffect(() => {
        emitter.on(RoomBookingEvent.SHOW_ASSIGN_ROOM_SUCCESS, (val = true) => {
            setShowAlert(val);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        });

        return () => {
            emitter.off(RoomBookingEvent.SHOW_ASSIGN_ROOM_SUCCESS);
        };
    }, [emitter]);

    return (
        <div className="room-management-header">
            <div className="status-container">
                <div className="d-flex">
                    <IndicatorSelect />
                    <Radio.Group
                        options={options}
                        onChange={(e) => onChange(e.target.value)}
                        value={currentStatus}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </div>

                {isRoom && !isReadOnly && (
                    <div className="toggle-booking">
                        <span className="mr-8">
                            {t('roomManagement.list.showBookingList')}
                        </span>
                        <Switch checked={isShowBookingList} onChange={onSwitchChange} />
                    </div>
                )}
            </div>
            <div className="time-container">
                <Button className="mr-4" onClick={prevDate}>
                    <LeftOutlined />
                </Button>
                <Button className="mr-8" onClick={nextDate}>
                    <RightOutlined />
                </Button>
                <DatePicker
                    value={parseDate(currentDate)}
                    defaultValue={todayDayjs}
                    className="mr-8"
                    onChange={onDateSelect}
                    getPopupContainer={(triggerNode: HTMLElement) =>
                        (triggerNode?.parentNode as HTMLElement) || document.body
                    }
                    locale={DatePickerLanguage}
                />
                <Button type="default" onClick={backToCurrentDate}>
                    {t('roomManagement.list.currentDate')}
                </Button>
            </div>
            {showAlert && (
                <div className="alert-container">
                    <Alert
                        message={t('roomManagement.list.assignRoomSuccess')}
                        type="success"
                        showIcon
                        closable
                        onClose={() => {
                            setShowAlert(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
};
