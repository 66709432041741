import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import googleAnalytics from '@analytics/google-analytics';

const gtmContainerId = process.env.REACT_APP_GTM_CONTAINER_ID || '';
const ga4MeasurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID || '';

export const analytics = Analytics({
    app: 'tabist-pms',
    plugins: [
        googleTagManager({
            containerId: gtmContainerId,
        }),
        googleAnalytics({
            measurementIds: [ga4MeasurementId],
        }),
    ],
    debug: process.env.NODE_ENV === 'development',
});
