import { Button, Layout, notification, Space } from 'antd';
import { StaffLayoutHeader } from '~components';
import { individualRoomBreadCrumbs } from '~features/room/constants';
import './CreateIndividualRoomsPage.scss';
import CreateIndividualRoomsForm from '../../components/CreateIndividualRoomsForm/CreateIndividualRoomsForm';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import { getRoomTypeListForDropdown } from '~features/room-type/room-type.reducer';
import { useCallback, useEffect } from 'react';
import { bulkCreateRoomsSchema } from '../../schema';
import { IRoomBulkCreateForm, IRoomBulkCreateFormItem } from '../../interfaces';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    bulkCreateRooms,
    individualRoomListSelector,
    setIndividualRoomList,
    showLoadingListSelector,
} from '../../room.reducer';
import { useNavigate } from 'react-router-dom';
import { showConfirm } from '~common/notification';
import { ErrorMessageType } from '~common/constants';
import { validateUniqueValue } from '~common/commonFunctions';
const { Footer, Content } = Layout;

function CreateIndividualRoomsPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { control, handleSubmit, reset, setError, getValues } =
        useForm<IRoomBulkCreateForm>({
            resolver: yupResolver(bulkCreateRoomsSchema),
        });
    // get room type list for dropdown
    useEffect(() => {
        dispatch(
            getRoomTypeListForDropdown({
                isConnectingRoomType: false,
                isPullFromTll: false,
            }),
        );
        return () => {
            reset();
            dispatch(setIndividualRoomList([]));
        };
    }, []);

    const individualRoomList = useAppSelector(individualRoomListSelector);
    const showLoading = useAppSelector(showLoadingListSelector);
    // set individualRoomList form when has any change
    useEffect(() => {
        reset({
            items: individualRoomList,
        });
    }, [individualRoomList]);

    const _bulkCreateRooms = useCallback(async (rooms: IRoomBulkCreateFormItem[]) => {
        const response = await dispatch(
            bulkCreateRooms(
                rooms.map((room, index) => ({
                    name: room.name,
                    roomTypeId: room.roomTypeId,
                    order: index + 1,
                })),
            ),
        );
        if (bulkCreateRooms.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('room.roomFormIndividual.createSuccessMessage'),
                });
                navigate('/front-settings/room');
            } else {
                (response.payload?.errors || []).forEach((error) => {
                    if (
                        (error.key === 'name' || error.key === 'roomTypeId') &&
                        error.order
                    ) {
                        setError(
                            `items.${error.order - 1}.${error.key}`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                });
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    // handle submit form
    const onSubmit = async () => {
        handleSubmit(async (data) => {
            const newRooms = getValues().items || [];
            if (!newRooms.length) {
                return;
            }
            const duplicatedNameIndexes = validateUniqueValue(newRooms, 'name');
            if (duplicatedNameIndexes.length) {
                duplicatedNameIndexes.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('room.roomFormIndividual.roomNameDuplicated'),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkCreateRooms(data.items);
        })();
    };

    const _showConfirm = () => {
        showConfirm({
            title: t('room.roomFormIndividual.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };
    const onCancel = () => {
        dispatch(setIndividualRoomList([]));
        navigate('/front-settings/room');
    };

    return (
        <div className="create-individual-rooms-page">
            <StaffLayoutHeader
                isShowButtonBack={true}
                breadcrumbs={individualRoomBreadCrumbs()}
                title={t('room.list.breadcrumbs.createIndividual')}
            />
            <Content>
                <CreateIndividualRoomsForm
                    control={control}
                    currentIndividualRoomList={getValues()?.items}
                />
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space align="end">
                        <Button onClick={_showConfirm}>
                            {t('room.roomFormIndividual.cancelBtn')}
                        </Button>
                        <Button
                            id="form-individual-submit"
                            type="primary"
                            onClick={onSubmit}
                            loading={showLoading}
                            disabled={getValues()?.items?.length === 0}
                        >
                            {t('room.roomFormIndividual.submitBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default CreateIndividualRoomsPage;
