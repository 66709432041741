import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { AggregationMethod, ReportTableId } from '~features/report/constants';
import { IPaymentSummaryData } from '~features/report/interfaces';
import { formatMoneyWithSymbol } from '~common/commonFunctions';
import './PaymentSummaryTable.scss';

export default function PaymentSummaryTable({
    data,
    isLoading,
    aggregationMethod,
    date,
}: {
    data: IPaymentSummaryData[] | undefined;
    isLoading: boolean;
    aggregationMethod: AggregationMethod;
    date: Dayjs | null;
}) {
    const { t } = useTranslation();

    const dates = useMemo(() => {
        if (!date) return [];
        return aggregationMethod === AggregationMethod.DAILY
            ? [date.format('YYYY-MM-DD')]
            : Array.from({ length: date.daysInMonth() }, (_, i) =>
                  date.startOf('month').add(i, 'day').format('YYYY-MM-DD'),
              );
    }, [date, aggregationMethod]);

    const formatYen = (amount: number | undefined) =>
        amount ? formatMoneyWithSymbol(amount) : '-';

    return (
        <div className="report-payment-summary-table-wrapper payment-facility">
            <Table
                className="payment-summary-table"
                pagination={false}
                scroll={{
                    x: aggregationMethod === AggregationMethod.MONTH ? 3900 : undefined,
                }}
                loading={isLoading}
                dataSource={data}
                rowKey="id"
                id={ReportTableId.PAYMENT_SUMMARY}
            >
                <Column
                    key="paymentMethod"
                    dataIndex="paymentMethod"
                    title={`${t('report.paymentSummary.table.paymentMethod')}`}
                    fixed="left"
                    width={aggregationMethod === AggregationMethod.MONTH ? 300 : '33%'}
                />
                {dates.map((date) => (
                    <Column
                        key={date}
                        title={date}
                        dataIndex="amounts"
                        render={(amount) => formatYen(amount[date])}
                    />
                ))}
                <Column
                    key="total"
                    dataIndex="total"
                    title={`${t('report.paymentSummary.table.monthlyAccumulative')}`}
                    render={(total) => formatYen(total)}
                    fixed="right"
                    width={aggregationMethod === AggregationMethod.MONTH ? 200 : '33%'}
                />
            </Table>
        </div>
    );
}
