import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { HTMLProps } from 'react';
import { IScheduleItem } from '~features/room-booking/interfaces';
import {
    scheduleStateSelector,
    setCollapseRooms,
} from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';

type Props = HTMLProps<HTMLDivElement> & {
    item: IScheduleItem;
};

export const RoomHeader = ({ item, style }: Props) => {
    const { collapseRooms } = useAppSelector(scheduleStateSelector);
    const dispatch = useAppDispatch();
    const collapseIndex = collapseRooms.findIndex((id) => id === item.id);
    const collapseIconClass = classNames({
        active: collapseIndex < 0,
        deactive: collapseIndex > -1,
    });
    return (
        <div
            style={{
                ...style,
            }}
            className={'room-header-wrapper'}
        >
            {!item.parentId ? (
                <div className="schedule-collapse-header-action">
                    <Button
                        className={'btn-collapse-single'}
                        onClick={() => {
                            if (collapseIndex > -1) {
                                const newCollapseRooms = [...collapseRooms];
                                newCollapseRooms.splice(collapseIndex, 1);
                                dispatch(setCollapseRooms(newCollapseRooms));
                            } else {
                                dispatch(setCollapseRooms([...collapseRooms, item.id]));
                            }
                        }}
                    >
                        <span className="text-truncate room-type-name">{item.name}</span>
                        <DownOutlined className={collapseIconClass} />
                    </Button>
                </div>
            ) : (
                <div className="room-name">
                    <span className="text-truncate">{item.name}</span>
                </div>
            )}
        </div>
    );
};
