import i18next, { t } from 'i18next';
import { Content, PageSize, TDocumentDefinitions } from 'pdfmake/interfaces';
import localStorageAuthService from '~common/authStorage';
import { makeRowWithCellSpan } from '~common/commonFunctions';
import { PrintingOrientation, PrintingPaperSize } from '~common/constants';
import { ICountryResponse } from '~features/guest/interfaces';
import {
    IRepresentativeGuest,
    IRoomBooking,
    IRoomBookingItem,
} from '~features/room-booking/interfaces';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { IAccommodationCardTable } from './interfaces';

interface IOptions {
    orientation: PrintingOrientation;
    setTotalPage: (totalPage: number) => void;
    countryList: ICountryResponse[];
    paperSize: PrintingPaperSize;
}

export const generateRoomBookingPdf = (
    roomBookingDetailsList: IRoomBooking[],
    options: IOptions,
): TDocumentDefinitions => {
    const {
        orientation = PrintingOrientation.PORTRAIT,
        setTotalPage,
        countryList,
        paperSize = PrintingPaperSize.A4,
    } = options;

    const isPortrait = orientation === PrintingOrientation.PORTRAIT;

    let content: Content = roomBookingDetailsList.length
        ? []
        : (generateRoomBookingContent(isPortrait, false) as Content[]);

    for (let i = 0, n = roomBookingDetailsList.length - 1; i <= n; i++) {
        const roomBookingDetail = roomBookingDetailsList[i];
        const { roomBookingItems } = roomBookingDetail;

        for (let j = 0, m = roomBookingItems.length - 1; j <= m; j++) {
            const roomBookingItem = roomBookingItems[j];

            content = content.concat(
                generateRoomBookingContent(
                    isPortrait,
                    i !== n || j !== m,
                    roomBookingItem,
                    roomBookingDetail.isMultiReservation
                        ? roomBookingItem.representativeGuest
                        : roomBookingDetail.representativeGuest,
                    countryList,
                    roomBookingDetail.reserverGuest,
                ) as Content,
            );
        }
    }

    return {
        pageSize: paperSize.toUpperCase() as PageSize,
        pageOrientation: orientation,
        header: () => ({
            text: customDayjs().fmYYYYMMDD('/'),
            style: 'pageHeader',
            margin: [40, 20, 0, 0],
        }),
        footer: (currentPage, pageCount) => {
            setTotalPage(pageCount);

            return {
                text: `${currentPage}/${pageCount}`,
                style: 'pageFooter',
                alignment: 'right',
                margin: [0, 0, 30, 0],
            };
        },
        content,
        styles: {
            pageHeader: {
                fontSize: 10,
            },
            header: {
                fontSize: isPortrait ? 18 : 14,
                bold: true,
            },
            subheader: {
                fontSize: 10,
            },
            tableInvoice: {
                fontSize: 10,
            },
            roomName: {
                fontSize: 14,
                bold: true,
            },
        },
    };
};

const generateRoomBookingContent = (
    isPortrait: boolean,
    pageBreak?: boolean,
    roomBookingItem?: IRoomBookingItem,
    representativeGuest?: IRepresentativeGuest,
    countryList: ICountryResponse[] = [],
    reserverGuest?: IRepresentativeGuest,
) => {
    const NUMBER_OF_COLUMNS = 20;
    const hotel = localStorageAuthService.getSelectedHotel();
    const roomBookingItemContent: IAccommodationCardTable = {
        title: i18next.t('roomBooking.printing.registrationCard'),
        companyInfo: {
            name: hotel?.name || '',
            address: hotel?.address || '',
            phone: hotel?.picPhoneNumber || '',
        },
    };

    const getCountryById = (countryId: number) => {
        return countryList.find((country) => country.id === countryId);
    };

    return [
        {
            columns: [
                {
                    text: roomBookingItemContent.title,
                    style: 'header',
                    width: '50%',
                    alignment: 'left',
                },
                {
                    text: roomBookingItemContent.companyInfo.name,
                    style: 'header',
                    width: '50%',
                    alignment: 'right',
                },
            ],
            margin: [0, 5],
        },
        {
            columns: [
                {
                    columns: [
                        {
                            text: `${i18next.t('roomBooking.printing.checkIn')}:`,
                        },
                        roomBookingItem && {
                            text: parseDate(roomBookingItem.startDateOfStay)?.fmYYYYMMDD(
                                '/',
                            ),
                        },
                    ],
                    alignment: 'left',
                },
                {
                    text: roomBookingItemContent.companyInfo?.address,
                    width: '50%',
                    alignment: 'right',
                },
            ],
            style: 'subheader',
            margin: [0, 5],
        },
        {
            columns: [
                {
                    columns: [
                        {
                            text: `${i18next.t('roomBooking.printing.checkOut')}:`,
                        },
                        roomBookingItem && {
                            text: parseDate(roomBookingItem.endDateOfStay)?.fmYYYYMMDD(
                                '/',
                            ),
                        },
                    ],

                    alignment: 'left',
                },
                {
                    text: roomBookingItemContent.companyInfo.phone,
                    width: '50%',
                    alignment: 'right',
                },
            ],
            style: 'subheader',
            margin: [0, 0, 0, isPortrait ? 25 : 5],
        },
        {
            style: 'tableInvoice',
            margin: [0, 10],
            table: {
                // Split the table into NUMBER_OF_COLUMNS column
                // Incase text is too long, it is wrapped
                widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                    .map(() => `${100 / NUMBER_OF_COLUMNS}%`)
                    .concat('*'),
                body: [
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.2,
                            stack: [
                                {
                                    text: `${i18next.t('roomBooking.printing.yomigana')}`,
                                    margin: [0, 5],
                                },
                                {
                                    text: `${i18next.t('roomBooking.printing.name')}`,
                                    margin: [0, 5],
                                },
                            ],
                        },
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: representativeGuest?.yomigana ?? '\n',
                                    margin: [0, 5],
                                },
                                {
                                    canvas: [
                                        {
                                            type: 'line',
                                            x1: 0,
                                            y1: 0,
                                            x2: isPortrait ? 130 : 200,
                                            y2: 0,
                                            dash: { length: 3, space: 3 },
                                        },
                                    ],
                                },
                                {
                                    text: representativeGuest?.fullName ?? '\n',
                                    margin: [0, 5],
                                },
                            ],
                        },
                        {
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.dateOfBirth',
                                    )}`,
                                    margin: [0, 5],
                                },
                                {
                                    alignment: 'center',
                                    columns: [
                                        {
                                            text: representativeGuest?.birthday
                                                ? `${parseDate(
                                                      representativeGuest.birthday,
                                                  ).format('YYYY')}`
                                                : '',
                                        },
                                        {
                                            text: '/',
                                        },
                                        {
                                            text: representativeGuest?.birthday
                                                ? `${parseDate(
                                                      representativeGuest.birthday,
                                                  ).format('MM')}`
                                                : '',
                                        },
                                        {
                                            text: '/',
                                        },
                                        {
                                            text: representativeGuest?.birthday
                                                ? `${parseDate(
                                                      representativeGuest.birthday,
                                                  ).format('DD')}`
                                                : '',
                                        },
                                    ],
                                    margin: [0, 5],
                                },
                            ],
                            colSpan: NUMBER_OF_COLUMNS * 0.35,
                        },
                        {
                            stack: [
                                {
                                    text: `${i18next.t('roomBooking.printing.gender')}`,
                                    margin: [0, 5],
                                },
                                {
                                    text: representativeGuest?.gender
                                        ? `${t(
                                              `roomBooking.printing.guestGender.${representativeGuest.gender}`,
                                          )}`
                                        : '\n',
                                    margin: [0, 5],
                                },
                            ],
                            colSpan: NUMBER_OF_COLUMNS * 0.15,
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.homeAddress',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: representativeGuest?.address
                                        ? representativeGuest.address
                                        : reserverGuest?.address
                                        ? reserverGuest.address
                                        : '\n',
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.35
                                : NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.contactNumber',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: representativeGuest?.mobilePhoneNumber
                                        ? representativeGuest.mobilePhoneNumber
                                        : reserverGuest?.mobilePhoneNumber
                                        ? reserverGuest.mobilePhoneNumber
                                        : '\n',
                                },
                            ],
                        },
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.35
                                : NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.emailAddress',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: representativeGuest?.emailAddress ?? '\n',
                                    marginBottom: -100,
                                    lineHeight: 0.9,
                                },
                            ],
                        },
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.3
                                : NUMBER_OF_COLUMNS * 0.4,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.occupation',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                { text: representativeGuest?.occupation ?? '\n' },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.35
                                : NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.companyName',
                                    )}`,
                                },
                                {
                                    text:
                                        (representativeGuest?.companyName ||
                                            representativeGuest?.companyYomigana) ??
                                        '\n',
                                },
                            ],
                        },
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.35
                                : NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.companyPhoneNumber',
                                        { break: isPortrait ? '\n' : '' },
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: representativeGuest?.companyPhoneNumber ?? '\n',
                                },
                            ],
                        },
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.3
                                : NUMBER_OF_COLUMNS * 0.4,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.companyEmailAddress',
                                        { break: isPortrait ? '\n' : '' },
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text:
                                        representativeGuest?.companyEmailAddress ?? '\n',
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.5,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.passportNo',
                                    )}`,
                                    margin: [0, 0, 0, 18],
                                },
                            ],
                        },
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.5,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.nationality',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: representativeGuest?.countryId
                                        ? getCountryById(representativeGuest.countryId)
                                              ?.nameJp ?? '\n'
                                        : '\n',
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.5,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.previousPlaceOfStay',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text:
                                        roomBookingItemContent?.previousPlaceOfStay ??
                                        '\n',
                                },
                            ],
                        },
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.5,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'roomBooking.printing.nextPlaceOfStay',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: roomBookingItemContent?.nextPlaceOfStay ?? '\n',
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS,
                            stack: [
                                {
                                    text: `${i18next.t('roomBooking.printing.memo')}`,
                                    margin: [0, 0, 0, 18],
                                },
                            ],
                            rowSpan: isPortrait ? 5 : 2,
                        },
                    ]),
                    ...Array.from(Array(isPortrait ? 4 : 1).keys()).map(() => {
                        return makeRowWithCellSpan([
                            {
                                colSpan: NUMBER_OF_COLUMNS,
                                stack: [{}],
                            },
                        ]);
                    }),
                ],
            },
            layout: {
                paddingTop: (rowIndex: number) => {
                    return rowIndex === 0 ? 0 : isPortrait ? 10 : 5;
                },
                paddingBottom: (rowIndex: number) => {
                    return rowIndex === 0 ? 0 : 10;
                },
                paddingLeft: (rowIndex: number) => {
                    return 10;
                },
                paddingRight: (rowIndex: number) => {
                    return 10;
                },
            },
        },
        {
            columns: [
                {
                    margin: [0, isPortrait ? 20 : 10],
                    style: 'tableInvoice',
                    table: {
                        widths: [150],
                        body: [
                            [
                                {
                                    stack: [
                                        {
                                            text: `${i18next.t(
                                                'roomBooking.printing.roomNumber',
                                            )}`,
                                            margin: [0, 5, 0, 10],
                                            alignment: 'center',
                                        },
                                        {
                                            alignment: 'center',
                                            text: roomBookingItem?.room?.name ?? '\n',
                                            margin: [0, 0, 0, 5],
                                            style: 'roomName',
                                        },
                                    ],
                                },
                            ],
                        ],
                        layout: {
                            paddingLeft: (rowIndex: number) => {
                                return 10;
                            },
                        },
                    },
                },
                {
                    stack: [
                        {
                            text: `${i18next.t('roomBooking.printing.signature')}`,
                            margin: [0, isPortrait ? 20 : 15, 0, 30],
                        },
                        {
                            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 250, y2: 0 }],
                        },
                    ],
                    ...(pageBreak && { pageBreak: 'after' }),
                },
            ],
        },
    ];
};
