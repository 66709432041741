import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { HTMLProps } from 'react';
import {
    facilityBookingStateSelector,
    setCollapseRooms,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { IScheduleItem } from '~features/room-booking/interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';

type Props = HTMLProps<HTMLDivElement> & {
    item: IScheduleItem;
};

export const RoomHeader = ({ item, style }: Props) => {
    const { collapseRooms } = useAppSelector(facilityBookingStateSelector);
    const dispatch = useAppDispatch();
    const collapIndex = collapseRooms.findIndex((id) => id === item.id);
    const collapIconClass = classNames({
        active: collapIndex < 0,
        deactive: collapIndex > -1,
    });
    return (
        <div style={{ ...style, paddingBottom: 12 }}>
            {!item.parentId ? (
                <div className="schedule-collapse-header-action">
                    <Button
                        className={'btn-collap-single'}
                        onClick={() => {
                            if (collapIndex > -1) {
                                const newCollapseRooms = [...collapseRooms];
                                newCollapseRooms.splice(collapIndex, 1);
                                dispatch(setCollapseRooms(newCollapseRooms));
                            } else {
                                dispatch(setCollapseRooms([...collapseRooms, item.id]));
                            }
                        }}
                    >
                        <span className="text-truncate facility-type-name">
                            {item.name}
                        </span>
                        <DownOutlined className={collapIconClass} />
                    </Button>
                </div>
            ) : (
                <div className="room-name">
                    <span className="text-truncate">{item.name}</span>
                </div>
            )}
        </div>
    );
};
