import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent, Select } from 'antd';
import customDayjs, { Dayjs, todayDayjs } from '~plugins/dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleViewMode } from '~common/constants';
import './BookingScheduleHeader.scss';

const generateOptions = (num: number, prefix: string, step = 0) => {
    return Array.from(Array(num).keys()).map((val) => {
        const _val = step + val + 1;
        return {
            value: _val,
            label: _val + prefix,
        };
    });
};

type Props = {
    defaultValue: Dayjs;
    viewMode: ScheduleViewMode;
    onChangeDate: (value: Dayjs) => void;
    onChangeView: (value: ScheduleViewMode) => void;
};

export const BookingScheduleHeader = ({
    defaultValue,
    viewMode,
    onChangeDate,
    onChangeView,
}: Props) => {
    const currentDate = useMemo(() => {
        return defaultValue;
    }, [defaultValue]);
    const currentViewMode = useMemo(() => {
        return viewMode;
    }, [viewMode]);
    const day = useMemo(() => {
        return currentDate.date();
    }, [currentDate]);
    const month = useMemo(() => {
        return currentDate.month() + 1;
    }, [currentDate]);
    const year = useMemo(() => {
        return currentDate.year();
    }, [currentDate]);
    const { t } = useTranslation();
    const months = useMemo(() => {
        return generateOptions(12, t('common.schedule.viewMode.month'));
    }, []);
    const years = useMemo(() => {
        let start = todayDayjs.year() - 10;
        return generateOptions(30, t('common.schedule.viewMode.year'), start);
    }, []);
    const showDay = useMemo(() => {
        return currentViewMode === ScheduleViewMode.DAY;
    }, [currentViewMode]);
    const days = useMemo(() => {
        const daysInMonth = currentDate.daysInMonth();
        return generateOptions(daysInMonth, t('common.schedule.viewMode.day'));
    }, []);
    const types = useMemo(() => {
        return Object.values(ScheduleViewMode).map((type) => {
            return {
                value: type,
                label: t('common.schedule.viewMode.' + type.toLowerCase()),
            };
        });
    }, []);
    const _onChangeMonth = (value: number) => {
        const day = currentDate.date();
        let _day = day;
        const _currentDate = currentDate.clone().set('month', value - 1);
        const daysInMonth = _currentDate.daysInMonth();
        if (day > daysInMonth) {
            _day = 1;
        }
        if (value !== todayDayjs.month() + 1) {
            setCurrentDate(_currentDate.set('date', _day));
        } else {
            setCurrentDate(_currentDate.set('date', todayDayjs.date()));
        }
    };
    const _onChangeDay = (value: number) => {
        setCurrentDate(currentDate.set('date', value));
    };
    const _onChangeYear = (value: number) => {
        setCurrentDate(currentDate.set('year', value));
    };
    const _onChangeType = (e: RadioChangeEvent) => {
        setCurrentViewMode(e.target.value);
    };

    const setCurrentDate = (date: Dayjs) => {
        onChangeDate(date);
    };
    const setCurrentViewMode = (value: ScheduleViewMode) => {
        onChangeView(value);
    };

    const _onChangeDate = (next = true) => {
        switch (currentViewMode) {
            case ScheduleViewMode.DAY:
                setCurrentDate(currentDate.add(next ? 1 : -1, 'day'));
                break;
            case ScheduleViewMode.WEEK:
                setCurrentDate(currentDate.add(next ? 1 : -1, 'week'));
                break;
            case ScheduleViewMode.MONTH:
                setCurrentDate(currentDate.add(next ? 1 : -1, 'month'));
                break;
        }
    };

    const _onToday = () => {
        switch (currentViewMode) {
            case ScheduleViewMode.DAY:
                setCurrentDate(customDayjs());
                break;
            case ScheduleViewMode.WEEK:
                setCurrentDate(customDayjs().startOf('week'));
                break;
            case ScheduleViewMode.MONTH:
                setCurrentDate(customDayjs().startOf('month'));
                break;
        }
    };

    return (
        <div className="schedule-header-wrapper">
            <div className="time-control">
                <div>
                    <Button onClick={() => _onToday()}>
                        {t('common.schedule.today')}
                    </Button>
                </div>
                <div className="date-select">
                    <div className="arrows">
                        <Button onClick={() => _onChangeDate(false)}>
                            <LeftOutlined />
                        </Button>
                        <Button onClick={() => _onChangeDate(true)}>
                            <RightOutlined />
                        </Button>
                    </div>
                    <Select
                        onChange={_onChangeYear}
                        value={year}
                        className="year-select"
                        options={years}
                    />
                    <Select
                        onChange={_onChangeMonth}
                        value={month}
                        className="month-select"
                        options={months}
                    />
                    {showDay && (
                        <Select
                            onChange={_onChangeDay}
                            value={day}
                            className="day-select"
                            options={days}
                        />
                    )}
                </div>
                <div>
                    <Radio.Group
                        className="type-select"
                        options={types}
                        onChange={_onChangeType}
                        value={viewMode}
                        optionType="button"
                    />
                </div>
            </div>
        </div>
    );
};
