export default {
    modal: {
        title: 'Check in',
        accountReceivable: {
            title: 'Unpaid amount',
        },
        button: {
            cancel: 'Cancel',
            checkin: 'Check in',
            add: 'Add',
        },
        roomType: {
            label: 'Room type',
            placeholder: 'Select room type',
        },
        checkinSaleItem: {
            priceLabel: 'Price',
            label: 'Sales item',
            placeholder: 'Select sales item',
        },
        checkinPaymentMethod: {
            priceLabel: 'Price',
            label: 'Payment method',
            placeholder: 'Select payment method',
        },
        room: {
            label: 'Room name',
            placeholder: 'Select room',
        },
        depositNote:
            'The status will be changed from reserved to checked-in. If there is a deposit, please select or enter it.',
    },
};
