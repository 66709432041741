import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button } from 'antd';
import { InputNumber, SingleSelect } from '~components';
import { useForm } from '~plugins/hook-form';
import { useTranslation } from 'react-i18next';
import { IInititalMarketingChannelSetting } from '../../interfaces';
import './CreateMarketingChannelsFormSetting.scss';
import { setCreateMarketingChannelList } from '~features/marketing-channel/marketing-channel.reducer';
import { marketingChannelGroupDropdownOptionsSelector } from '~features/marketing-channel-group/marketing-channel-group.reducer';
import { getFormMarketingChannelId } from '~features/marketing-channel/helpers';
import { settingSchema } from '~features/marketing-channel/schema';

function CreateMarketingChannelsFormSetting() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, handleSubmit, reset, formState } =
        useForm<IInititalMarketingChannelSetting>({
            resolver: yupResolver(settingSchema),
        });
    const marketingChannelGroupOptions = useAppSelector(
        marketingChannelGroupDropdownOptionsSelector,
    );

    const initFormData = () => {
        reset({
            quantity: null,
            marketingChannelGroupId: null,
        });
    };

    useEffect(() => {
        initFormData();
    }, []);

    const onClickButtonGenerateMarketingChannel = () => {
        handleSubmit(async (data) => {
            const generatedMarketingChannelList = [];
            for (let i = 0; i < (Number(data.quantity) || 0); i += 1) {
                generatedMarketingChannelList.push({
                    feId: `${Date.now()}_${i}`,
                    marketingChannelGroupId: data.marketingChannelGroupId,
                });
            }
            dispatch(setCreateMarketingChannelList(generatedMarketingChannelList));
        })();
    };

    return (
        <div className="marketing-channels-form-setting-wrapper">
            <Form layout="vertical">
                <Row className="input-row" gutter={8}>
                    <Col span={7}>
                        <InputNumber
                            label={t('marketingChannel.form.createForm.quantity')}
                            placeholder={t('marketingChannel.form.createForm.quantity')}
                            name="quantity"
                            id={getFormMarketingChannelId('quantity')}
                            control={control}
                            required={true}
                            isShowIconArrow={true}
                        />
                    </Col>
                    <Col span={17}>
                        <SingleSelect
                            label={t(
                                'marketingChannel.form.createForm.marketingChannelGroupId',
                            )}
                            placeholder={t(
                                'marketingChannel.form.createForm.marketingChannelGroupId',
                            )}
                            name="marketingChannelGroupId"
                            id={getFormMarketingChannelId('marketingChannelGroupId')}
                            control={control}
                            showSearch={false}
                            options={marketingChannelGroupOptions}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Button
                                type="default"
                                id={getFormMarketingChannelId('btn-generate')}
                                className="marketing-channel-generate-button"
                                onClick={onClickButtonGenerateMarketingChannel}
                                disabled={!formState.isValid}
                            >
                                <PlusOutlined className="marketing-channel-generate-icon" />
                                <span>
                                    {t(
                                        'marketingChannel.form.createForm.generateMarketingChannelsBtn',
                                    )}
                                </span>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default CreateMarketingChannelsFormSetting;
