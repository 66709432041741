import classNames from 'classnames';
import { PhoneOutlined } from '@ant-design/icons';
import { IGuestDropDown } from '~features/guest/interfaces';
import './GuestItem.scss';
type Prop = {
    item: IGuestDropDown;
    isSelected?: boolean;
    onClick: (guest: IGuestDropDown) => void;
    disabled: boolean;
};
function GuestItem(prop: Prop) {
    const { item, isSelected, disabled, onClick } = prop;
    const { autoGeneratedCode, yomigana, mobilePhoneNumber, phoneNumberLandline } = item;
    const phoneGuest = mobilePhoneNumber || phoneNumberLandline || '';
    return (
        <div
            className={classNames('guest-option', {
                'selected-guest': isSelected,
            })}
            onClick={() => {
                if (!disabled) {
                    onClick(item);
                }
            }}
        >
            <div className="code">
                <div className="text-truncate">{autoGeneratedCode}</div>
            </div>
            <div className="yomigana">
                <div className="text-truncate">{yomigana}</div>
            </div>
            <div className="phone">
                {phoneGuest && (
                    <div className="mobile-phone-guest">
                        <PhoneOutlined className="phone-icon" />
                        <span className="text-truncate">{phoneGuest}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GuestItem;
