import i18next from 'i18next';
import { DEFAULT_ORDER_DIRECTION } from '~common/constants';

export const breadcrumbs = () => [
    {
        text: i18next.t('childrenType.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('childrenType.list.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('childrenType.list.breadcrumbs.childrenType'),
    },
];

export enum ChildrenTypeOrderBy {
    ID = 'id',
    NAME = 'name',
    PRICE_TYPE = 'priceType',
    PRICE_VALUE = 'priceValue',
    LINKAGE_CODE = 'linkageCode',
}

export enum ChildrenTypeColumn {
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
    PRICE_TYPE = 'priceType',
    PRICE_VALUE = 'priceValue',
    LINKAGE_CODE = 'linkageCode',
}

export enum PriceType {
    PRICE_PER_ADULT = 'price_per_adult',
    FIXED = 'fixed',
}

export enum LinkageCode {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
}

export const MAX_FIXED_PRICE_VALUE = 99999;
export const MAX_PRICE_PER_ADULT_VALUE = 100;
export const DEFAULT_CHILDREN_TYPE_LIST_QUERY = {
    page: 1,
    name: '',
    orderBy: ChildrenTypeOrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
};
