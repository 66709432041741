import { localeJa } from './ja';
import { localeEn } from './en';
import {
    MixedLocale,
    StringLocale,
    NumberLocale,
    DateLocale,
    ArrayLocale,
    ObjectLocale,
    BooleanLocale,
} from 'yup/lib/locale';

export const yupLocale = {
    localeJa,
    localeEn,
};

const mixed: Required<MixedLocale> = {
    default: (data) => {
        return `yup.mixed.default|${JSON.stringify(data)}`;
    },
    required: (data) => {
        return `yup.mixed.required|${JSON.stringify(data)}`;
    },
    defined: (data) => {
        return `yup.mixed.defined|${JSON.stringify(data)}`;
    },
    oneOf: (data) => {
        return `yup.mixed.oneOf|${JSON.stringify(data)}`;
    },
    notOneOf: (data) => {
        return `yup.notOneOf|${JSON.stringify(data)}`;
    },
    notType: (data) => {
        return data.type !== 'mixed'
            ? `yup.mixed.notType|${JSON.stringify(data)}`
            : `yup.mixed.notTypeMixed|${JSON.stringify(data)}`;
    },
};

const string: Required<StringLocale> = {
    length: (data) => {
        return `yup.string.length|${JSON.stringify(data)}`;
    },
    min: (data) => {
        return `yup.string.min|${JSON.stringify(data)}`;
    },
    max: (data) => {
        return `yup.string.max|${JSON.stringify(data)}`;
    },
    matches: (data) => {
        return `yup.string.matches|${JSON.stringify(data)}`;
    },
    email: (data) => {
        return `yup.string.email|${JSON.stringify(data)}`;
    },
    url: (data) => {
        return `yup.string.url|${JSON.stringify(data)}`;
    },
    uuid: (data) => {
        return `yup.string.uuid|${JSON.stringify(data)}`;
    },
    trim: (data) => {
        return `yup.string.trim|${JSON.stringify(data)}`;
    },
    lowercase: (data) => {
        return `yup.string.lowercase|${JSON.stringify(data)}`;
    },
    uppercase: (data) => {
        return `yup.string.uppercase|${JSON.stringify(data)}`;
    },
};

const number: Required<NumberLocale> = {
    min: (data) => {
        return `yup.number.min|${JSON.stringify(data)}`;
    },
    max: (data) => {
        return `yup.number.max|${JSON.stringify(data)}`;
    },
    lessThan: (data) => {
        return `yup.number.lessThan|${JSON.stringify(data)}`;
    },
    moreThan: (data) => {
        return `yup.number.moreThan|${JSON.stringify(data)}`;
    },
    positive: (data) => {
        return `yup.number.positive|${JSON.stringify(data)}`;
    },
    negative: (data) => {
        return `yup.number.negative|${JSON.stringify(data)}`;
    },
    integer: (data) => {
        return `yup.number.integer|${JSON.stringify(data)}`;
    },
};

const date: Required<DateLocale> = {
    min: (data) => {
        return `yup.date.min|${JSON.stringify(data)}`;
    },
    max: (data) => {
        return `yup.date.max|${JSON.stringify(data)}`;
    },
};

const boolean: BooleanLocale = {
    isValue: (data) => {
        return `yup.boolean.isValue|${JSON.stringify(data)}`;
    },
};

const object: Required<ObjectLocale> = {
    noUnknown: (data) => {
        return `yup.object.noUnknown|${JSON.stringify(data)}`;
    },
};

const array: Required<ArrayLocale> = {
    min: (data) => {
        return `yup.array.min|${JSON.stringify(data)}`;
    },
    max: (data) => {
        return `yup.array.max|${JSON.stringify(data)}`;
    },
    length: (data) => {
        return `yup.array.length|${JSON.stringify(data)}`;
    },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    mixed,
    string,
    number,
    date,
    object,
    array,
    boolean,
};
