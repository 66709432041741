import React, { useCallback, useEffect } from 'react';
import './UpdatePlanPage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchPlanDetail,
    fetchTaxListForDropdown,
    setFormStep,
    showLoadingSelector,
} from '../../reducers/plan.reducer';
import PlanForm from '~features/plan/components/PlanForm/PlanForm';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '~hooks';
import { planTaxTypes } from '~features/plan/constants';

function UpdatePlanPage() {
    const dispatch = useAppDispatch();
    const { planId } = useParams();
    const navigate = useNavigate();
    const isLoading = useAppSelector(showLoadingSelector);
    useEffect(() => {
        dispatch(fetchTaxListForDropdown({ taxTypes: planTaxTypes }) as any);
        dispatch(setFormStep(1));
    }, []);

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(fetchPlanDetail(id));
        if (fetchPlanDetail.fulfilled.match(response)) {
            if (!response.payload?.data) {
                navigate('/404');
            }
        }
    }, []);

    useEffect(() => {
        if (typeof Number(planId) === 'number') {
            _getDetail(Number(planId));
        } else {
            navigate('/404');
        }
    }, []);

    return (
        <div className="update-plan-page">
            <Spin spinning={isLoading}>
                <PlanForm />
            </Spin>
        </div>
    );
}

export default UpdatePlanPage;
