import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { Layout } from 'antd';
import { notificationListBreadcrumbs } from '~features/admin-notification/constants';
import FilterForm from './components/FilterForm/FilterForm';

import './NotificationListPage.scss';
import NotificationList from './components/NotificationList/NotificationList';
function NotificationListPage() {
    const { t } = useTranslation();
    return (
        <div className="notification-list-page">
            <StaffLayoutHeader
                breadcrumbs={notificationListBreadcrumbs()}
                title={t('notification.list.titlePage')}
            />
            <Layout.Content>
                <div className="notification-list-page-wrapper">
                    <div className="notification-list-page-content">
                        <FilterForm />
                        <NotificationList />
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default NotificationListPage;
