import { CheckboxOptionType, Layout, Radio, RadioChangeEvent, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from '@tanstack/react-query';
import { StaffLayoutHeader } from '~components';
import { salesSummaryBreadcrumbs } from '~features/report/constants';
import FilterForm from '~features/report/pages/SalesSummaryPage/components/FilterForm/FilterForm';
import RoomSalesSummaryTable from '~features/report/pages/SalesSummaryPage/components/Table/RoomSalesSummaryTable';
import { useAppSelector } from '~hooks';
import FacilitySalesSummaryTable from './components/Table/FacilitySalesSummaryTable';
import { reportSalesSummarySelector } from '~features/report/reducers/sales-summary.reducer';
import { CacheKeys } from '~queries/queries';
import {
    BOOKING_TYPES as BOOKING,
    BookingTypesValues as BookingTypes,
} from '~features/dashboard/constants';

function SalesSummaryPage() {
    const { t } = useTranslation();
    const [bookingType, setBookingType] = useState<BookingTypes>(BOOKING.ROOM);
    const options: CheckboxOptionType[] = [
        {
            label: t('report.salesSummary.tab.roomBooking'),
            value: BOOKING.ROOM,
            style: { borderBottomLeftRadius: 0 },
        },
        {
            label: t('report.salesSummary.tab.facilityBooking'),
            value: BOOKING.FACILITY,
            style: { borderBottomRightRadius: 0 },
        },
    ];
    const {
        showSalesSummaryTable,
        showFacilitySalesSummaryTable,
        showLoadingSalesSummaryTable,
    } = useAppSelector(reportSalesSummarySelector);

    const isFetchingReceiptSummaryData = useIsFetching({
        queryKey: [CacheKeys.getSalesSummaryReceiptDetails],
    });

    return (
        <div className="sales-summary-page">
            <StaffLayoutHeader
                breadcrumbs={salesSummaryBreadcrumbs()}
                title={t('report.salesSummary.title')}
            />
            <Layout.Content>
                <div className="sales-summary-page-wrapper">
                    <div className="sales-summary-page-content">
                        <Radio.Group
                            style={{ marginBottom: -1, paddingLeft: '24px' }}
                            options={options}
                            onChange={({ target: { value } }: RadioChangeEvent) => {
                                setBookingType(value);
                            }}
                            value={bookingType}
                            optionType="button"
                        />
                        <FilterForm bookingType={bookingType} />
                        {showSalesSummaryTable && bookingType === BOOKING.ROOM && (
                            <Spin
                                spinning={
                                    showLoadingSalesSummaryTable ||
                                    Boolean(isFetchingReceiptSummaryData)
                                }
                            >
                                <RoomSalesSummaryTable />
                                {/* Note: Temporarily hide receipt summary table */}
                                {/* <ReceiptSummary /> */}
                            </Spin>
                        )}
                        {showFacilitySalesSummaryTable &&
                            bookingType === BOOKING.FACILITY && (
                                <FacilitySalesSummaryTable />
                            )}
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default SalesSummaryPage;
