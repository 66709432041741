import i18next from '~plugins/i18next/i18n';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';

export enum OrderBy {
    ID = 'id',
    NAME = 'name',
}

export const enum MarketingChannelGroupColumn {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
}

export const breadcrumbs = () => [
    {
        text: i18next.t('marketingChannelGroup.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('marketingChannelGroup.list.breadcrumbs.marketingChannel'),
    },
    {
        text: i18next.t(
            'marketingChannelGroup.list.breadcrumbs.marketingChannelGroupSetting',
        ),
    },
];

export const FILE_NAME = 'marketing_channel_group.csv';

export const initMarketingChannelGroupListQuery = {
    keyword: '',
    name: '',
    autoGeneratedCode: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    NAME = 'name',
}

// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'marketing-channel-group';
export const exportColumns = ['autoGeneratedCode', 'name'];
export const i18ExportKey = 'marketingChannelGroup.list';
