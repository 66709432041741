import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse, ICsvFile, IGetListResponse } from '~common/interfaces';
import { trimData } from '~common/commonFunctions';
import {
    IGuestClassification,
    IGuestClassificationGetListQuery,
    IGuestClassificationBulkDeleteItem,
    IGuestClassificationExportCsvQuery,
    IGuestClassificationCreateBody,
    IGuestClassificationResponse,
    IGuestClassificationDropDownQuery,
    IGuestClassificationDropdown,
    IGuestClassificationUpdateBody,
} from '../interfaces';

class GuestClassificationsService extends ApiService {
    getGuestClassificationListForDropdown(params?: IGuestClassificationDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<IBodyResponse<IGetListResponse<IGuestClassificationDropdown>>>;
    }

    getDetail(id: number) {
        return this._getDetail(id) as Promise<IBodyResponse<IGuestClassification>>;
    }

    getList(queryString: IGuestClassificationGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IGuestClassification>>
        >;
    }

    create(createBody: IGuestClassificationCreateBody) {
        return this._create<IGuestClassificationCreateBody, IGuestClassificationResponse>(
            createBody,
        );
    }

    updateGuestClassification(id: number, updateBody: IGuestClassificationUpdateBody) {
        trimData(updateBody);
        return this._patch(`${this.baseUrl}/${id}`, updateBody) as Promise<
            IBodyResponse<IGuestClassification>
        >;
    }

    bulkDelete(bulkDeleteBody: IGuestClassificationBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    exportCsv(query: IGuestClassificationExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }
}

export const guestClassificationService = new GuestClassificationsService(
    { baseUrl: '/guest-classification' },
    axiosService,
);
