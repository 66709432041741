import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '~common/commonFunctions';
import './SummaryTotalReceipt.scss';

type IProp = {
    totalPrice: { totalSale: number; totalPayment: number; totalTax: number };
};

function SummaryTotalReceipt({ totalPrice }: IProp) {
    const { t } = useTranslation();
    const totalAmount = totalPrice.totalSale - totalPrice.totalPayment;
    return (
        <div className="summary-total-receipt">
            <Row>
                <Col span={7} offset={17}>
                    <div className="total-sales d-flex j-between a-center">
                        <span>
                            {t('facilityBooking.detail.receipt.summaryTotal.totalSales')}
                        </span>
                        <span>
                            {t('common.yen', {
                                number: formatMoney(totalPrice.totalSale),
                            })}
                        </span>
                    </div>
                    <div className="other-taxes d-flex j-between a-center">
                        <span>
                            {t('facilityBooking.detail.receipt.summaryTotal.otherTaxes')}
                        </span>
                        <span>
                            {t('common.yen', {
                                number: formatMoney(totalPrice.totalTax),
                            })}
                        </span>
                    </div>
                    <div className="payment d-flex j-between a-center">
                        <span>
                            {t('facilityBooking.detail.receipt.summaryTotal.payment')}
                        </span>
                        <span>
                            {t('common.yen', {
                                number: formatMoney(totalPrice.totalPayment),
                            })}
                        </span>
                    </div>
                    <div className="total-receivable d-flex j-between a-center">
                        <span>
                            {t(
                                'facilityBooking.detail.receipt.summaryTotal.totalReceivable',
                            )}
                        </span>
                        <span className="price">
                            {t('common.yen', {
                                number: formatMoney(totalAmount),
                            })}
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default SummaryTotalReceipt;
