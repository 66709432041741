import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { Row, Col, Form, notification } from 'antd';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SingleSelect } from '~components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    IErrorPlan,
    IPlanCreateBody,
    IPlanRoomType,
    IPlanRoomTypePricing,
} from '~features/plan/interfaces';
import { createPlanStep2Schema } from '~features/plan/schema';
import {
    rankCalendarDropDownsSelector,
    roomTypeDropDownsSelector,
    fetchRankCalendarListForDropdown,
    fetchRoomTypeListForDropdown,
    selectedPlanSelector,
    setSelectedPlan,
    createPlan,
    updatePlan,
    setFormStep,
} from '../../../../reducers/plan.reducer';
import './Step2.scss';
import PlanRoomTypePricingsTab from './PlanRoomTypePricingsTab/PlanRoomTypePricingsTab';
import { useNavigate, useParams } from 'react-router-dom';
import {
    reorderPlanRoomTypes,
    getFormPlanId,
    transformErrorKey,
    reopenPlanRoomTypeTll,
} from '~features/plan/helper';
import { cloneDeep, compact, difference, differenceBy, isNumber } from 'lodash';
import intersection from 'lodash/intersection';
import { scrollToClass } from '~features/plan/util';
import { HotelUsers, TllRankDropdown } from '~common/constants';
import { checkNotAllowedUsers, validateUniqueValue } from '~common/commonFunctions';
import { defaultPlanRoomTypePricings } from '~features/plan/constants';
type Props = {
    setError: (error: IErrorPlan) => void;
    error: IErrorPlan;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Step2(props: Props, ref: any) {
    const { t } = useTranslation();
    const { planId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedPlan = useAppSelector(selectedPlanSelector);
    const [roomTypeIds, setRoomTypeIds] = useState<number[]>([]);
    const [indexErrorTab, setIndexErrorTab] = useState<number | undefined>(undefined);
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm<IPlanCreateBody>({
        resolver: yupResolver(createPlanStep2Schema),
    });

    useEffect(() => {
        const dataPlanRoomTypes: IPlanRoomType[] = reorderPlanRoomTypes(
            selectedPlan?.planRoomTypes || [],
        );
        reset({
            planRankId: selectedPlan?.planRankId,
            roomTypeIds: selectedPlan?.roomTypeIds,
            planRoomTypes: dataPlanRoomTypes || [],
        });
        setRoomTypeIds(selectedPlan?.roomTypeIds || []);
    }, [selectedPlan]);

    useEffect(() => {
        dispatch(fetchRankCalendarListForDropdown({}));
        dispatch(fetchRoomTypeListForDropdown({ isPullFromTll: false }));
    }, []);

    const rankCalendarOptions = useAppSelector(rankCalendarDropDownsSelector);
    const roomTypeOptions = useAppSelector(roomTypeDropDownsSelector);

    useEffect(() => {
        if (selectedPlan?.planRankId) {
            return;
        }
        const defaultRank = rankCalendarOptions.filter((rank) => {
            return rank.name === t('plan.create.pricingSelectorForm.priceRankDefault');
        });
        if (defaultRank.length !== 0) {
            setValue(`planRankId`, defaultRank?.[0]?.id);
        } else {
            setValue(`planRankId`, rankCalendarOptions?.[0]?.id);
        }
    }, [rankCalendarOptions]);

    const getRankCalendarOptions = useMemo(() => {
        if (!selectedPlan?.planRankId) return rankCalendarOptions;
        const findRankCalendar = rankCalendarOptions.find(
            (item) => item.id === selectedPlan?.planRankId,
        );
        if (findRankCalendar) return rankCalendarOptions;
        return [
            ...rankCalendarOptions,
            {
                id: selectedPlan?.planRank?.id,
                name: selectedPlan?.planRank?.name,
            },
        ];
    }, [rankCalendarOptions, selectedPlan]);

    const getRoomTypeOptions = useMemo(() => {
        const planRoomTypes = reopenPlanRoomTypeTll(selectedPlan?.planRoomTypes || []);
        const findRoomTypes = differenceBy(planRoomTypes, roomTypeOptions, 'id');
        return [...findRoomTypes, ...roomTypeOptions];
    }, [roomTypeOptions, selectedPlan]);

    useEffect(() => {
        if (props.error.key === 'planGroupCode' && props.error.order) {
            setError(`planRoomTypes.${props.error.order}.planGroupCode`, {
                type: 'manual',
                message: props.error.message,
            });
            setIndexErrorTab(props.error.order);
        }
    }, [props.error]);

    const makeFormData = (plan: IPlanCreateBody): IPlanCreateBody => {
        const {
            name,
            memo,
            planRankId,
            planType,
            taxId,
            checkInTime,
            checkOutTime,
            planSaleItems = [],
            planRoomTypes = [],
            cleaningFrequency,
        } = plan;
        const transformPlanRoomTypes = planRoomTypes.map((planRoomType) => {
            const planRoomTypePricings = planRoomType?.planRoomTypePricings?.map(
                (item: IPlanRoomTypePricing, index: number) => {
                    return {
                        peopleCount: Number(item.peopleCount)
                            ? Number(item.peopleCount)
                            : Number(planRoomType?.peopleCount),
                        tllRank: item.tllRank
                            ? item.tllRank
                            : TllRankDropdown[index].value,
                        price: isNumber(item.price) ? Number(item.price) : null,
                    };
                },
            );
            return {
                roomTypeId: planRoomType.roomTypeId,
                peopleCount: planRoomType.peopleCount ? planRoomType.peopleCount : 0,
                planGroupCode: planRoomType.planGroupCode,
                planRoomTypePricings: planRoomTypePricings,
            };
        });
        const transformPlanSaleItems = planSaleItems.map((planSaleItem) => {
            const isForOnePeople = planSaleItem.isForOnePeople || false;
            return {
                saleItemId: planSaleItem.saleItemId,
                quantity: isForOnePeople ? null : planSaleItem.quantity,
                isFirstDayOnly: planSaleItem.isFirstDayOnly || false,
                isForOnePeople: isForOnePeople,
                price: planSaleItem.price,
            };
        });
        return {
            name,
            memo,
            planRankId,
            planType,
            taxId,
            checkInTime: checkInTime ? checkInTime : null,
            checkOutTime: checkOutTime ? checkOutTime : null,
            planSaleItems: transformPlanSaleItems,
            planRoomTypes: transformPlanRoomTypes,
            cleaningFrequency,
        };
    };

    const _createPlan = useCallback(async (formData: IPlanCreateBody) => {
        const response = await dispatch(createPlan(formData));
        if (createPlan.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('plan.create.createSuccess'),
                });
                navigate('/front-settings/plan');
                return;
            }
            (response.payload?.errors || []).forEach((error) => {
                if (
                    error.key === 'name' ||
                    error.key === 'saleItemId' ||
                    error.key === 'taxId'
                ) {
                    dispatch(setFormStep(1));
                    props.setError(error);
                } else if (error.key === 'planGroupCode' && error.order) {
                    setIndexErrorTab(error.order - 1);
                    setError(`planRoomTypes.${error.order - 1}.${error.key}`, {
                        type: 'manual',
                        message: error.message,
                    });
                } else {
                    const reopenError = transformErrorKey(error.key);
                    if (reopenError.key1 === 'planSaleItems') {
                        dispatch(setFormStep(1));
                        props.setError({
                            key: reopenError.key2,
                            message: error.message,
                            order: reopenError.order + 1,
                        });
                    }
                }
            });
            notification.error({
                message: t('plan.create.createError'),
                description: response.payload?.errors?.[0]?.message || '',
            });
        }
    }, []);

    const _updatePlan = useCallback(async (formData: IPlanCreateBody) => {
        const response = await dispatch(updatePlan({ id: Number(planId), formData }));
        if (updatePlan.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('plan.create.updateSuccess'),
                });
                navigate('/front-settings/plan');
                return;
            }
            (response.payload?.errors || []).forEach((error) => {
                if (
                    error.key === 'name' ||
                    error.key === 'saleItemId' ||
                    error.key === 'taxId'
                ) {
                    dispatch(setFormStep(1));
                    props.setError(error);
                } else if (error.key === 'planGroupCode' && error.order) {
                    setIndexErrorTab(error.order - 1);
                    setError(`planRoomTypes.${error.order - 1}.${error.key}`, {
                        type: 'manual',
                        message: error.message,
                    });
                } else {
                    const reopenError = transformErrorKey(error.key);
                    if (reopenError.key1 === 'planSaleItems') {
                        dispatch(setFormStep(1));
                        props.setError({
                            key: reopenError.key2,
                            message: error.message,
                            order: reopenError.order + 1,
                        });
                    }
                }
            });
            notification.error({
                message: t('plan.create.updateError'),
                description: response.payload?.errors?.[0]?.message || '',
            });
        }
    }, []);

    const onChangeRoomTypes = (ids: number[]) => {
        const oldPlanRoomTypeIds = (getValues().planRoomTypes || []).map(
            (planRoomType) => planRoomType.roomTypeId,
        );

        const unChangedRoomTypeIds = intersection(oldPlanRoomTypeIds, ids);
        const addedRoomTypeIds = difference(ids, oldPlanRoomTypeIds);
        const newRoomTypeIds = compact([...unChangedRoomTypeIds, ...addedRoomTypeIds]);

        const unChangedPlanRoomTypes = (getValues().planRoomTypes || []).filter(
            (planRoomType) =>
                planRoomType.roomTypeId &&
                unChangedRoomTypeIds.includes(planRoomType.roomTypeId),
        );

        const addPlanRoomTypes = addedRoomTypeIds.map((item) => {
            return {
                feId: +`${Date.now()}${Math.random() * 1000}`,
                roomTypeId: item,
                peopleCount: 0,
                planGroupCode: '',
                planRoomTypePricings: cloneDeep(defaultPlanRoomTypePricings),
            };
        });

        const newPlanRoomTypes = [...unChangedPlanRoomTypes, ...addPlanRoomTypes];
        setRoomTypeIds(newRoomTypeIds);
        setValue(`roomTypeIds`, newRoomTypeIds);
        setValue('planRoomTypes', cloneDeep(newPlanRoomTypes));
        clearErrors('planRoomTypes');
        setIndexErrorTab(undefined);
    };

    useImperativeHandle(ref, () => ({
        // this function is used in parent component
        onSubmitStep2() {
            handleSubmit((data) => {
                const planRoomTypes = cloneDeep(data.planRoomTypes || []);
                planRoomTypes.forEach((planRoomType) => {
                    planRoomType.planRoomTypePricings?.forEach((planRoomTypePricing) => {
                        const price = planRoomTypePricing.price;
                        planRoomTypePricing.price = price ? Number(price) : 0;
                    });
                });
                dispatch(
                    setSelectedPlan({
                        ...selectedPlan,
                        planRankId: data.planRankId,
                        roomTypeIds: data.roomTypeIds,
                        planRoomTypes,
                    }),
                );
                const submitBody = makeFormData({
                    ...selectedPlan,
                    planRankId: data.planRankId,
                    roomTypeIds: data.roomTypeIds,
                    planRoomTypes,
                });
                if (submitBody.planRoomTypes?.length) {
                    const duplicatedPlanGroupCodeIndexes = validateUniqueValue(
                        submitBody.planRoomTypes,
                        'planGroupCode',
                    );
                    if (duplicatedPlanGroupCodeIndexes.length) {
                        duplicatedPlanGroupCodeIndexes.forEach((index) => {
                            props.setError({
                                order: index,
                                key: 'planGroupCode',
                                message: t('plan.create.planGroupCodeDuplicated'),
                            });
                        });
                        return;
                    }
                    if (planId) {
                        _updatePlan(submitBody);
                    } else {
                        _createPlan(submitBody);
                    }
                }
            })();
        },
        onBackToStep1() {
            dispatch(
                setSelectedPlan({
                    ...selectedPlan,
                    planRankId: getValues().planRankId,
                    roomTypeIds: getValues().roomTypeIds,
                    planRoomTypes: getValues().planRoomTypes,
                }),
            );
        },
    }));

    useEffect(() => {
        scrollToClass('create-plan-step2');
    }, []);
    return (
        <div className="create-plan-step2">
            <Form layout="vertical">
                <div className="plan-room-type-selector-wrapper">
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="form-title">
                                <span className="mr-8">
                                    {t('plan.create.pricingSelectorForm.title')}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <div className="plan-room-type-selector">
                        <Row gutter={24}>
                            <Col span={10}>
                                <SingleSelect
                                    label={t('plan.create.pricingSelectorForm.priceRank')}
                                    placeholder={t(
                                        'plan.create.pricingSelectorForm.priceRankDefault',
                                    )}
                                    name="planRankId"
                                    id={getFormPlanId(`planRankId`)}
                                    control={control}
                                    options={getRankCalendarOptions.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    }))}
                                />
                            </Col>
                            <Col span={10}>
                                <SingleSelect
                                    disabled={checkNotAllowedUsers([...HotelUsers])}
                                    name="roomTypeIds"
                                    mode="multiple"
                                    id={getFormPlanId(`roomTypeIds`)}
                                    allowClear
                                    label={t('plan.create.pricingSelectorForm.roomType')}
                                    placeholder={t(
                                        'plan.create.pricingSelectorForm.roomTypePlaceholder',
                                    )}
                                    defaultValue={[]}
                                    options={getRoomTypeOptions.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    }))}
                                    onChange={(value) => {
                                        onChangeRoomTypes(value);
                                    }}
                                    control={control}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                {(roomTypeIds?.length > 0 ||
                    process.env.REACT_APP_PACKAGE_PLAN_CODE === 'true') && (
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="plan-room-type-pricing-wrapper">
                                <PlanRoomTypePricingsTab
                                    roomTypeIds={roomTypeIds || []}
                                    control={control}
                                    errors={errors}
                                    indexErrorTab={indexErrorTab}
                                    setValue={setValue}
                                    planRoomTypes={getValues().planRoomTypes || []}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    );
}

export default forwardRef(Step2);
