export const keycardSettingEn = {
    breadcrumbs: {
        home: 'Home',
        integrations: 'Integrations',
        keycardSetting: 'Keycard Setting',
    },
    card: {
        title: 'Select a card key setting',
        noKey: 'No Card key',
        active: 'Active',
        inactive: 'Inactive',
    },
};
