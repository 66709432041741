import { AppState } from '~plugins/redux-toolkit/store';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ISiteControllerState, ISiteController } from './../interfaces';
import siteControllerService from '../services/site-controller.service';

export const getSiteController = createAsyncThunk('siteController/get', async () => {
    return await siteControllerService.getSiteController();
});

export const updateSiteController = createAsyncThunk(
    'siteController/patch',
    async (body: ISiteController) => {
        return await siteControllerService.updateSiteController(body);
    },
);

const initialState: ISiteControllerState = {
    siteController: {} as unknown as ISiteController,
    isTllConnected: false,
    isLoading: false,
    formBusy: false,
};

const siteControllerSlice = createSlice({
    name: 'siteController',
    initialState,
    reducers: {
        setSiteController: (state, action) => {
            state.siteController = action.payload;
        },
        setIsTllConnected: (state, action) => {
            state.isTllConnected = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getSiteController.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(updateSiteController.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(updateSiteController.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(getSiteController.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload?.success) {
                state.siteController = action.payload?.data;
                state.isTllConnected = state.siteController.isTllConnected;
            } else state.siteController = {} as unknown as ISiteController;
        });
    },
});

export const { setSiteController, setIsTllConnected } = siteControllerSlice.actions;

export const siteControllerSelector = (state: AppState) =>
    state.siteController.siteController;

export const formBusySelector = (state: AppState) => state.siteController.formBusy;

export const isLoadingSelector = (state: AppState) => state.siteController.isLoading;

export const isTllConnectedSelector = (state: AppState) =>
    state.siteController.isTllConnected;

export const siteControllerReducer = siteControllerSlice.reducer;
