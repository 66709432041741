import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Modal, Popconfirm, notification } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SingleSelect } from '~components';
import { PREPAYPEMENT_METHODS } from '~features/payment-method/constants';
import { IPaymentMethodDropDown } from '~features/payment-method/interfaces';
import { paymentMethodStateSelector } from '~features/payment-method/reducers/paymentMethod.reducer';
import {
    IBulkPaymentGroupBookingFormData,
    IReceipt,
} from '~features/room-booking/interfaces';
import {
    getRoomBookingReceipt,
    roomBookingStateSelector,
    setShowBulkPaymentGroupBookingModal,
} from '~features/room-booking/reducers/room-booking.reducer';
import { bulkPaymentGroupBookingSchema } from '~features/room-booking/schema';
import { roomBookingServiceV2 } from '~features/room-booking/services/room-bookingV2.service';
import { useAppDispatch, useAppSelector } from '~hooks';
import dayjs, { parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import { PaymentMethodSelect } from '../../RoomBookingReceiptTabPane/PaymentMethodSelect/PaymentMethodSelect';

type Props = {
    roomBookingReceipt: IReceipt | null;
};

export function BulkPaymentGroupBookingModal({ roomBookingReceipt }: Props) {
    const { roomBookingId } = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isShowBulkPaymentGroupBookingModal } = useAppSelector(
        roomBookingStateSelector,
    );
    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);
    const { control, reset, handleSubmit, formState } = useForm({
        resolver: yupResolver(bulkPaymentGroupBookingSchema),
        defaultValues: {
            paymentDate: dayjs().fmYYYYMMDD('-'),
            paymentMethodId: null,
        },
    });

    const makeBulkPaymentGroupBooking = async (
        data: IBulkPaymentGroupBookingFormData,
    ) => {
        if (roomBookingId) {
            const res = await roomBookingServiceV2.createBulkPaymentGroupBooking(
                Number(roomBookingId),
                data,
            );
            if (res.success) return res.data;
            throw Error(res.errors![0] as unknown as string);
        }
    };

    const { mutate, isPending } = useMutation({
        mutationFn: async (data: IBulkPaymentGroupBookingFormData) =>
            await makeBulkPaymentGroupBooking(data),
    });

    const paymentMethodOptions = useMemo(() => {
        return paymentMethodDropDown.map((method) => {
            if (PREPAYPEMENT_METHODS.includes(method.name)) {
                return {
                    ...method,
                    disabled: true,
                };
            }
            return method;
        });
    }, [paymentMethodDropDown]);

    const getDisabledValues = (paymentMethodDropDown: IPaymentMethodDropDown[]) => {
        return paymentMethodDropDown
            .filter((method) => method.disabled)
            .map((method) => method.id);
    };

    const formattedPaymentDateOptions = useMemo(() => {
        const options = new Set<string>();
        const roomBookingCreationDate = roomBookingReceipt?.roomBooking.createdAt;
        const roomBookingItems = roomBookingReceipt?.roomBooking?.roomBookingItems;
        roomBookingItems?.forEach((item) => {
            const startDate = parseDate(item.startDateOfStay);
            const endDate = parseDate(item.endDateOfStay);

            for (
                let date = startDate;
                date.isSameOrBefore(endDate, 'day');
                date = date.add(1, 'day')
            ) {
                options.add(date.fmYYYYMMDD('-'));
            }
        });
        options.add(parseDate(roomBookingCreationDate).fmYYYYMMDD('-'));
        options.add(dayjs().fmYYYYMMDD('-'));
        const sortedOptions = Array.from(options).sort((a, b) => {
            return parseDate(a).valueOf() - parseDate(b).valueOf();
        });
        return sortedOptions.map((option) => ({
            value: option,
            label: option,
        })) as DefaultOptionType[];
    }, [roomBookingReceipt]);

    const onClose = () => {
        reset();
        dispatch(setShowBulkPaymentGroupBookingModal(false));
    };

    const onSumbit = (data: IBulkPaymentGroupBookingFormData) => {
        mutate(data, {
            onSuccess: () => {
                onClose();
                notification.success({
                    message: t(
                        'roomBooking.detail.receipt.bulkPaymentGroupBookingModal.updateSuccessMessage',
                    ),
                });
                dispatch(getRoomBookingReceipt(Number(roomBookingId)));
            },
            onError: (error) => {
                notification.error({
                    message: `${error}`,
                });
            },
        });
    };

    return (
        <Modal
            title={t('roomBooking.detail.receipt.button.bulkPayment')}
            open={isShowBulkPaymentGroupBookingModal}
            centered
            onCancel={onClose}
            okButtonProps={{ form: 'bulkPaymentGroupBookingForm', htmlType: 'submit' }}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    {t('roomBooking.detail.receipt.bulkPaymentGroupBookingModal.cancel')}
                </Button>,
                <Popconfirm
                    title={t(
                        'roomBooking.detail.receipt.bulkPaymentGroupBookingModal.overpaymentWarning',
                    )}
                    okText={t(
                        'roomBooking.detail.receipt.bulkPaymentGroupBookingModal.overpaymentProceed',
                    )}
                    cancelText={t(
                        'roomBooking.detail.receipt.bulkPaymentGroupBookingModal.cancel',
                    )}
                    onConfirm={handleSubmit((data) => {
                        onSumbit(data);
                    })}
                    disabled={!formState.isValid}
                    key="confirm-dialog"
                >
                    <Button
                        key="submit"
                        type="primary"
                        form="bulkPaymentGroupBookingForm"
                        htmlType="submit"
                        loading={isPending}
                        disabled={!formState.isValid}
                    >
                        {t(
                            'roomBooking.detail.receipt.bulkPaymentGroupBookingModal.createBulkPayment',
                        )}
                    </Button>
                </Popconfirm>,
            ]}
        >
            <Form
                name="bulkPaymentGroupBookingForm"
                id="bulkPaymentGroupBookingForm"
                layout="vertical"
            >
                <SingleSelect
                    label={t(
                        'roomBooking.detail.receipt.bulkPaymentGroupBookingModal.paymentDate',
                    )}
                    name="paymentDate"
                    control={control}
                    options={formattedPaymentDateOptions}
                />
                <PaymentMethodSelect
                    label={t(
                        'roomBooking.detail.receipt.bulkPaymentGroupBookingModal.paymentMethod',
                    )}
                    paymentMethodOptions={paymentMethodOptions}
                    disableValues={getDisabledValues(paymentMethodOptions)}
                    name="paymentMethodId"
                    control={control}
                />
            </Form>
        </Modal>
    );
}
