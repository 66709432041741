import { notification } from 'antd';
export const integrationsJa = {
    breadcrumbs: {
        home: 'ホーム',
        integrations: '外部連携',
    },
    notification: {
        success: '更新されました',
        error: '更新に失敗しました',
    },
    title: '外部連携設定',
    save: '保存',
    hotelSmart: {
        title: 'HOTEL SMART',
        userId: 'ユーザーID',
        password: 'パスワード',
        checkedInDate: '送信開始チェックイン日',
        houseId: 'House ID',
    },
};
