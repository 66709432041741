/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../../constants';
import './ImportCsvRoomPage.scss';
import { Button, Layout, notification, Space } from 'antd';
import ImportCsvRoom from '~features/room/components/ImportCsvRoom/ImportCsvRoom';
import { Footer } from 'antd/lib/layout/layout';
import { showConfirm } from '~common/notification';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useNavigate } from 'react-router-dom';
import {
    hasImportErrorSelector,
    importCsvRoom,
    importRoomListSelector,
    setHasImportError,
    setImportRoomList,
    setShowLoadingList,
    showLoadingListSelector,
} from '~features/room/room.reducer';
import { useCallback, useEffect, useState } from 'react';
import { IImportRoom } from '~features/room/interfaces';
import { uniq } from 'lodash';

const { Content } = Layout;

function ImportCsvRoomPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const showLoading = useAppSelector(showLoadingListSelector);
    const hasError = useAppSelector(hasImportErrorSelector);
    const importRoomList = useAppSelector(importRoomListSelector);
    const [responseError, setResponseError] = useState<Record<number, any>>({});

    useEffect(() => {
        dispatch(setShowLoadingList(false));
        if (!importRoomList?.length) {
            navigate('/front-settings/room');
        }
    }, []);

    const _showConfirm = () => {
        showConfirm({
            title: t('room.roomFormIndividual.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    const _importRooms = useCallback(async (rooms: IImportRoom[]) => {
        const response = await dispatch(importCsvRoom(rooms));
        if (importCsvRoom.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('room.roomFormIndividual.importSuccessMessage'),
                });
                navigate('/front-settings/room');
            } else {
                let errors = {};
                uniq(
                    (response.payload?.errors || []).map((error) => error.order),
                ).forEach((order) => {
                    if (order) {
                        let currentError = {};
                        (response.payload?.errors || [])
                            .filter((error) => error.order === order)
                            .forEach((error) => {
                                if (
                                    (error.key === 'name' ||
                                        error.key === 'roomTypeName' ||
                                        error.key === 'relatedRoomNames') &&
                                    error.order
                                ) {
                                    currentError = {
                                        ...currentError,
                                        [error.key]: error.message,
                                    };
                                }
                            });
                        errors = {
                            ...errors,
                            [order]: currentError,
                        };
                    }
                });
                dispatch(setHasImportError(true));
                setResponseError(errors);
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    const onCancel = () => {
        dispatch(setImportRoomList([]));
        navigate('/front-settings/room');
    };

    const onSubmit = () => {
        _importRooms(importRoomList);
    };

    return (
        <div className="import-csv-room-page">
            <StaffLayoutHeader
                breadcrumbs={[...breadcrumbs()]}
                title={t('room.form.importTitle')}
            />
            <Content>
                <div className="room-page-wrapper">
                    <div className="room-list-content">
                        <ImportCsvRoom responseError={responseError} />
                    </div>
                </div>
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space align="end">
                        <Button onClick={_showConfirm}>
                            {t('room.roomFormIndividual.cancelBtn')}
                        </Button>
                        <Button
                            id="form-individual-submit"
                            type="primary"
                            onClick={onSubmit}
                            loading={showLoading}
                            disabled={hasError}
                        >
                            {t('room.roomFormIndividual.importBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default ImportCsvRoomPage;
