export const salesByPlansReportEn = {
    title: 'Sales by plans report',
    breadcrumbs: {
        report: 'Report',
        salesByPlans: 'Sales by plans',
    },
    filterForm: {
        pickerLabel: 'Month',
        planLabel: 'Plan',
        allOption: 'All',
        displayMode: 'Daily',
        filterButton: 'Display',
    },
    tableColumns: {
        date: 'Date',
        lastMonth: 'Last month',
        currentMonth: 'This month',
        nextMonth: 'Next month',
        plan: 'Plan',
        totalBookedRoom: 'Number of rooms',
        totalAdult: 'Adults',
        totalChild: 'Children',
        totalRevenue: 'Total amount',
        avgRevenue: 'Average price',
        total: 'Total (tax inclusive)',
    },
    tableHeader:
        '※ The figures in parentheses indicate the same date of the previous year',
    total: 'Total (tax included)',
    unit: '¥',
    percentage: '%',
};
