const facilityBookingCreateFormJa = {
    title: '新規設備予約の作成',
    roomName: '部屋名',
    form: {
        facility: {
            label: '設備名',
            placeholder: '設備名を選択',
        },
        guestYomigana: {
            label: '予約者名 (ヨミガナ)',
            placeholder: '例：やまだ　たろう',
        },
        guestPhoneNumber: {
            label: '電話番号',
            placeholder: '電話番号を入力',
        },
        facilityBookingTimePeriod: {
            label: '予約期間',
            placeholder: {
                start: '開始日時',
                end: '終了日時',
            },
        },
        numberOfGuests: {
            label: '人数',
            placeholder: '0',
        },
        total: {
            label: '合計',
        },
        guestFullName: {
            label: '予約者名 ',
            placeholder: '例 : 焼き素　冬馬',
        },
        guestBirthday: {
            label: '生年月日',
            placeholder: '年/月/日',
        },
        guestGender: {
            label: '性別',
            placeholder: '性別を選択',
        },
        guestMemo: {
            label: 'メモ',
            placeholder: 'メモをご記入ください',
        },
        detailGuestInformation: '詳細情報',
        marketingChannel: {
            label: '販売チャネル',
            placeholder: '販売チャネルを選択',
        },
        emailAddress: {
            label: 'メールアドレス',
            placeholder: 'tsubota.ayane@gmail.com',
        },
    },
    priceNumber: '¥{{priceNumber}}',
    guestGender: {
        male: '男',
        female: '女性',
        other: '他',
    },
    message: {
        create: {
            success: '施設予約が作成されました',
            error: '施設予約が作成されませんでした',
        },
        update: {
            success: '施設予約が更新されました',
            error: '施設予約が更新されませんでした',
        },
    },
    button: {
        createFacilityBooking: '作成',
    },
};

export default facilityBookingCreateFormJa;
