import { useState } from 'react';
import { IChangedGuestItem } from '~features/room-booking/interfaces';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { t } from 'i18next';

export default function ChangedGuestItem({ guestId, change, length }: IChangedGuestItem) {
    const [isOpen, setIsOpen] = useState(false);
    const showValue = (field: string, value: any) => {
        const SELECT_FIELD = {
            COUNTRY: 'country',
            PREFECTURE: 'prefecture',
            SEX: 'sex',
        };
        const nullValueList = [0, null];
        if (nullValueList.includes(value)) {
            return t(`roomBooking.detail.hotelSmartCard.${value}`);
        }
        if (field === SELECT_FIELD.COUNTRY) {
            return t(`roomBooking.countries.${value}`);
        }
        if (field === SELECT_FIELD.PREFECTURE) {
            return t(`roomBooking.prefectures.${value}`);
        }
        if (field === SELECT_FIELD.SEX) {
            return t(`roomBooking.detail.hotelSmartCard.${value}`);
        }
        return value;
    };
    return (
        <div>
            <div className="clickable" onClick={() => setIsOpen(!isOpen)}>
                <p>
                    {`${t('roomBooking.detail.hotelSmartCard.guestId')} : ${guestId}`}
                    {isOpen ? (
                        <UpOutlined className="icon" />
                    ) : (
                        <DownOutlined className="icon" />
                    )}
                </p>
            </div>
            {change.map((item) => (
                <div className={isOpen ? 'open' : 'close'} key={item.field}>
                    <p className="font-weight-text-500">{`${t(
                        `roomBooking.detail.hotelSmartCard.${item.field}`,
                    )}`}</p>
                    <p>
                        {`${t(
                            'roomBooking.detail.hotelSmartCard.beforeChange',
                        )}: ${showValue(item.field, item.old_value)} `}
                    </p>
                    <p>
                        {`${t(
                            'roomBooking.detail.hotelSmartCard.afterChange',
                        )}: ${showValue(item.field, item.new_value)}`}
                    </p>
                </div>
            ))}
        </div>
    );
}
