export const paymentMethodListJa = {
    title: '入金科目設定',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        paymentMethod: '入金科目設定',
    },
    filterForm: {
        buttons: {
            create: '入金科目を追加',
        },
    },
    columns: {
        id: 'ID',
        paymentMethod: '科目',
    },
    delete: {
        title: '削除の確認',
        description: '下記の項目を削除してもよろしいですか？',
        success: '削除しました。',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '科目',
    },
    confirm: {
        cantDelete: 'デフォルトの入金科目が削除できません',
    },
};
