import { ReloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { notification, Table, Pagination, Button, Card } from 'antd';
import type { TableProps } from 'antd/es/table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    AdminGroup,
    AntdOrderDirection,
    cellAutoGeneratedCodeStyle,
    cellTextErrorStyle,
    CsvOption,
    OrderDirection,
} from '~common/constants';
import {
    roomTypeListQuerySelector,
    setRoomTypeListQuery,
    setSelectedRoomType,
    totalPageSelector,
    totalRoomTypesSelector,
    roomTypeListSelector,
    fetchRoomTypeList,
    showLoadingSelector,
    setShowForm,
    setImportCsvFileName,
    setImportLoading,
    setImportRoomTypeList,
} from '../../room-type.reducer';
import {
    exportColumns,
    EXPORT_CSV_FILE_NAME,
    FILE_NAME,
    i18ExportKey,
    ImportRoomTypeColumn,
    initRoomTypeListQuery,
    OrderBy,
    RoomTypeColumn,
} from '../../constants';
import {
    IImportRoomType,
    IImportRoomTypeBodyItem,
    IRoomType,
    IRoomTypeExportCsvQuery,
} from '../../interfaces';
import { CsvDropdown, ModalConfirmDeletion, TextTruncate } from '~components';
import { roomTypeService } from '../../services/room-type.service';
import {
    checkUserPermission,
    downloadFile,
    exportCSVFile,
} from '~common/commonFunctions';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '~hooks';
import { ISorter } from '~common/interfaces';
import './RoomTypeList.scss';
import { ColumnsType } from 'antd/lib/table';
import ImportCsv from '../../../../components/ImportCsv/ImportCsv';
import trim from 'lodash/trim';
import { roomTypeSchema } from '~common/validatorSchema';

interface IDataOutput {
    name: string;
    roomTypeCode: string;
    standardCapacity: number;
    abbreviation: string;
    autoGeneratedCode: string;
}

function RoomTypeList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const roomTypeList = useAppSelector(roomTypeListSelector);
    const roomTypeListQuery = useAppSelector(roomTypeListQuerySelector);
    const loading = useAppSelector(showLoadingSelector);
    const totalRoomTypes = useAppSelector(totalRoomTypesSelector);
    const pageCount = useAppSelector(totalPageSelector);
    const isReadOnly = !checkUserPermission([...AdminGroup]);
    const importCsvRef = useRef<{
        onClickImport: () => void;
    }>(null);

    useEffect(() => {
        return () => {
            dispatch(setRoomTypeListQuery({ ...initRoomTypeListQuery }));
        };
    }, []);

    const onChange: TableProps<IRoomType>['onChange'] = (pagination, filters, sorter) => {
        // this is for pagination, sort, filter logic later
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setRoomTypeListQuery({
                    ...roomTypeListQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setRoomTypeListQuery({
                    ...roomTypeListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const selectedRows = useMemo(() => {
        return roomTypeList.filter((room) => selectedRowKeys.includes(room.id)) || [];
    }, [selectedRowKeys, roomTypeList]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const exportCsv = async (query: IRoomTypeExportCsvQuery) => {
        const response = await roomTypeService.exportCsv(query);
        if (response.success) {
            downloadFile(FILE_NAME, response.data.filePath);
        }
    };

    const onChangePage = (page: number) => {
        dispatch(setRoomTypeListQuery({ ...roomTypeListQuery, page }));
    };

    const convertDataToCsv = (roomTypes: IRoomType[]) => {
        const dataOutput: IDataOutput[] = [];
        roomTypes.forEach((roomType) => {
            const data = {
                name: roomType.name,
                roomTypeCode: roomType.roomTypeCode,
                standardCapacity: roomType.standardCapacity,
                abbreviation: roomType.abbreviation,
                autoGeneratedCode: roomType.autoGeneratedCode,
            };
            if (roomType.roomTypeAndMarketingChannels?.length) {
                roomType.roomTypeAndMarketingChannels?.forEach((item) => {
                    const cloneData = JSON.parse(JSON.stringify(data));
                    cloneData.marketingChannel = item.marketingChannel?.name || '';
                    dataOutput.push(cloneData);
                });
            } else {
                dataOutput.push(data);
            }
        });
        return dataOutput;
    };

    const exportRoomTypeList = async (roomTypes: IRoomType[]) => {
        // Create header file csv
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        // Convert to file csv
        const roomDataCsvs = convertDataToCsv(roomTypes);
        await exportCSVFile(exportColumns, filename, roomDataCsvs, i18ExportKey);
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all room
                await exportCsv({
                    keyword: roomTypeListQuery.keyword,
                    name: roomTypeListQuery.name,
                    autoGeneratedCode: roomTypeListQuery.autoGeneratedCode,
                    abbreviation: roomTypeListQuery.abbreviation,
                    orderBy: roomTypeListQuery.orderBy,
                    orderDirection: roomTypeListQuery.orderDirection,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportRoomTypeList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                if (importCsvRef?.current) {
                    importCsvRef.current.onClickImport();
                }
                break;
            }
            default:
                break;
        }
    };

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await roomTypeService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('roomType.list.message.deleteRoomTypesSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    useEffect(() => {
        if (
            roomTypeList.length === 0 &&
            roomTypeListQuery?.page &&
            roomTypeListQuery?.page > 1
        ) {
            const page = roomTypeListQuery?.page - 1;
            dispatch(setRoomTypeListQuery({ ...roomTypeListQuery, page }));
        }
    }, [roomTypeList]);

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            deletedItems: selectedRows.map((row) => row.name),
            okButtonProps: { danger: true },
            buttonCancelText: t('roomType.list.delete.buttonCancelText'),
            buttonDeleteText: t('roomType.list.delete.buttonDeleteText'),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const editRoomType = () => {
        if (selectedRows.length !== 1) return;

        dispatch(setShowForm(true));
        dispatch(setSelectedRoomType(selectedRows[0]));
    };

    const fetchData = () => {
        dispatch(fetchRoomTypeList());
    };

    useEffect(() => {
        fetchData();
    }, [roomTypeListQuery]);

    const isCanEdit = useMemo(() => {
        return selectedRows.length === 1;
    }, [selectedRows]);

    const refreshList = () => {
        fetchData();
    };

    const editRecord = (room: IRoomType) => {
        if (isReadOnly) {
            return;
        }
        dispatch(setShowForm(true));
        dispatch(setSelectedRoomType(room));
    };

    useEffect(() => {
        if (roomTypeList.length === 0 && (roomTypeListQuery?.page as number) > 1) {
            dispatch(
                setRoomTypeListQuery({ ...roomTypeListQuery, page: pageCount || 1 }),
            );
        }
    }, [roomTypeList]);

    const convertToJson = (roomType: IImportRoomType) => {
        return JSON.stringify({
            name: roomType.name,
            abbreviation: roomType.abbreviation,
            standardCapacity: roomType.standardCapacity,
        });
    };

    const mapRoomTypeDataToImportCsv = (roomTypeList: IImportRoomType[]) => {
        const importRoomTypeList: IImportRoomTypeBodyItem[] = [];
        const marketingChannelsByRoomType: Record<string, string[]> = {};
        roomTypeList.forEach((roomType, index) => {
            const newRoomType = {
                order: index + 1,
                name: roomType.name,
                roomTypeCode: roomType.roomTypeCode,
                abbreviation: roomType.abbreviation,
                standardCapacity:
                    isNaN(+roomType.standardCapacity) ||
                    trim(roomType.standardCapacity as string) === ''
                        ? roomType.standardCapacity
                        : +roomType.standardCapacity,
            };
            const key = convertToJson(newRoomType);
            if (marketingChannelsByRoomType[key]) {
                if (roomType.marketingChannel?.trim()) {
                    marketingChannelsByRoomType[key].push(roomType.marketingChannel);
                }
            } else {
                if (roomType.marketingChannel?.trim()) {
                    marketingChannelsByRoomType[key] = [roomType.marketingChannel];
                }
                importRoomTypeList.push(newRoomType);
            }
        });
        const newRoomTypes = importRoomTypeList.map((roomType, index) => {
            const key = convertToJson(roomType);
            return {
                ...roomType,
                order: index + 1,
                marketingChannels: marketingChannelsByRoomType[key]?.length
                    ? marketingChannelsByRoomType[key]
                    : undefined,
            };
        });
        dispatch(setImportRoomTypeList(newRoomTypes));
    };

    const setImportRoomCsvFileName = (fileName: string) => {
        dispatch(setImportCsvFileName(fileName));
    };

    const handleLoadFile = () => {
        dispatch(setImportLoading(true));
    };

    const getRoomTypeCellTextStyle = (roomType: IRoomType) => {
        if (roomType?.isPullFromTll) return cellTextErrorStyle;
        return roomTypeSchema.isValidSync(roomType) ? {} : cellTextErrorStyle;
    };

    const roomTypeColumns: ColumnsType<IRoomType> = [
        {
            title: t('roomType.list.roomColumn.id'),
            width: '120px',
            onCell: () => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                };
            },
            render: (roomType: IRoomType) => {
                return <span>{roomType.autoGeneratedCode}</span>;
            },
            key: RoomTypeColumn.ID,
            sorter: true,
        },
        {
            title: t('roomType.list.roomColumn.type'),
            width: '30%',
            dataIndex: RoomTypeColumn.NAME,
            key: RoomTypeColumn.NAME,
            onCell: (roomType: IRoomType) => {
                return {
                    style: getRoomTypeCellTextStyle(roomType),
                };
            },
            render: (name: string) => {
                return <TextTruncate text={name} />;
            },
            sorter: true,
        },
        {
            title: t('roomType.list.roomColumn.roomTypeCode'),
            width: '30%',
            dataIndex: RoomTypeColumn.ROOM_TYPE_CODE,
            key: RoomTypeColumn.ROOM_TYPE_CODE,
            onCell: (roomType: IRoomType) => {
                return {
                    style: getRoomTypeCellTextStyle(roomType),
                };
            },
            render: (roomTypeCode: string) => {
                return <TextTruncate text={roomTypeCode} />;
            },
            sorter: true,
        },
        {
            title: t('roomType.list.roomColumn.abbreviation'),
            dataIndex: RoomTypeColumn.ABBREVATION,
            key: RoomTypeColumn.ABBREVATION,
            onCell: (roomType: IRoomType) => {
                return {
                    style: getRoomTypeCellTextStyle(roomType),
                };
            },
            render: (abbreviation: string) => {
                return <TextTruncate text={abbreviation} />;
            },
            sorter: true,
        },
        {
            title: t('roomType.list.roomColumn.standardCapacity'),
            dataIndex: RoomTypeColumn.STANDARD_CAPACITY,
            key: RoomTypeColumn.STANDARD_CAPACITY,
            onCell: (roomType: IRoomType) => {
                return {
                    style: getRoomTypeCellTextStyle(roomType),
                };
            },
            sorter: true,
        },
    ];

    return (
        <div className="room-type-list-wrapper">
            <ImportCsv
                importHeader={[
                    ImportRoomTypeColumn.NAME,
                    ImportRoomTypeColumn.ROOM_TYPE_CODE,
                    ImportRoomTypeColumn.ABBREVATION,
                    ImportRoomTypeColumn.STANDARD_CAPACITY,
                    ImportRoomTypeColumn.MARKETING_CHANNEL,
                ]}
                mapData={mapRoomTypeDataToImportCsv}
                setFilename={setImportRoomCsvFileName}
                handleLoadFile={handleLoadFile}
                ref={importCsvRef}
                previousPage="/front-settings/room-type/import-csv"
            />
            <Card
                className="room-type-card"
                title={
                    <div className="room-type-list-header">
                        {!isReadOnly && selectedRows.length > 0 && (
                            <div className="room-type-header-left">
                                <Button
                                    type="text"
                                    className="btn-icon"
                                    disabled={!isCanEdit}
                                    onClick={editRoomType}
                                >
                                    <EditOutlined />
                                </Button>
                                <Button
                                    type="text"
                                    className="btn-icon"
                                    onClick={showConfirmDialog}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )}

                        <div className="room-type-header-right">
                            <CsvDropdown
                                onChange={onChangeCsvOption}
                                hasSelectedColumns={selectedRows.length > 0}
                                hasImportOption={!isReadOnly}
                            />
                            <ReloadOutlined className="size-icon" onClick={refreshList} />
                        </div>
                    </div>
                }
            >
                <Table
                    rowSelection={rowSelection}
                    columns={roomTypeColumns}
                    dataSource={roomTypeList}
                    onChange={onChange}
                    pagination={false}
                    rowKey="id"
                    loading={loading}
                    scroll={{ y: 400 }}
                    rowClassName="room-type-row"
                    onRow={(record) => {
                        return {
                            // click row
                            onClick: () => {
                                editRecord(record);
                            },
                        };
                    }}
                />
            </Card>
            {pageCount > 1 && (
                <Pagination
                    align="center"
                    className="room-type-pagination"
                    defaultCurrent={roomTypeListQuery.page}
                    current={roomTypeListQuery.page}
                    total={totalRoomTypes}
                    pageSize={roomTypeListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default RoomTypeList;
