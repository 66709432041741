import React from 'react';
import './LoginForm.scss';
import { useTranslation } from 'react-i18next';
import { ILoginBody } from '../../interface';
import { Form, Button, Col, Row, notification } from 'antd';
import { useForm } from '~plugins/hook-form';
import { InputText } from '~components';
import { loginSchema } from '../../schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { appConfig } from '~config/app';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    getProfile,
    handleLogin,
    isFormBusySelector,
} from '~features/auth/reducers/login.reducer';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../AuthProvider';
import { ILoginUser } from '~common/interfaces';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import localStorageAuthService from '~common/authStorage';
import { CognitoError, getCognitoError } from '~plugins/amplify/helper';
import { intersection } from 'lodash';
import { UserGroup } from '~common/constants';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function LoginForm() {
    const { t } = useTranslation();
    const auth = useAuth();
    const { control, handleSubmit, setError } = useForm<ILoginBody>({
        resolver: yupResolver(loginSchema),
    });
    const dispatch = useAppDispatch();
    const isFormBusy = useAppSelector(isFormBusySelector);
    const navigate = useNavigate();
    const login = () => {
        handleSubmit(async (data) => {
            const response = await dispatch(handleLogin(data));
            if (handleLogin.fulfilled.match(response)) {
                // call api to get user's profile
                const profileResponse = await dispatch(getProfile());
                if (getProfile.fulfilled.match(profileResponse)) {
                    const loginUser: ILoginUser = {
                        ...profileResponse.payload?.data,
                        email: data.email,
                    };

                    if (
                        intersection(loginUser?.groups, Object.values(UserGroup))
                            .length <= 0
                    ) {
                        navigate('/forbidden');
                        return;
                    }

                    if (loginUser?.pmsHotels?.[0]?.id) {
                        localStorageAuthService.setHotelId(loginUser.pmsHotels[0]?.id);
                        localStorageAuthService.setUser(loginUser);
                        auth.login(loginUser);
                        if (loginUser.groups?.includes(UserGroup.HOTEL_CLEANING)) {
                            navigate('/room-cleaning');
                        } else {
                            navigate('/dashboard');
                        }
                    } else {
                        navigate('/forbidden');
                        return;
                    }
                } else {
                    notification.error({
                        message: t('common.somethingWentWrong'),
                    });
                }
            } else {
                const error = response.error;
                const cognitoError = getCognitoError(
                    error?.code,
                    error?.message ?? JSON.stringify(error),
                );
                let message = t('auth.login.errorMessage');
                switch (cognitoError) {
                    case CognitoError.INCORRECT_USERNAME_OR_PASSWORD:
                        message = t('auth.login.errorMessage');
                        break;
                    case CognitoError.PASSWORD_ATTEMPTS_EXCEEDED:
                    case CognitoError.PASSWORD_ATTEMPTS_EXCEEDED_WAIT:
                        message = t('auth.login.passwordAttempts');
                        break;
                    default:
                        message = t('auth.login.errorMessage');
                        break;
                }
                setError('email', {
                    type: 'manual',
                    message,
                });
            }
        })();
    };

    return (
        <div className="form-login">
            <h2 className="title">{t('auth.login.loginPageTitle')}</h2>
            <Form
                layout="vertical"
                autoComplete="off"
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        login();
                    }
                }}
            >
                <Row>
                    <Col span={24}>
                        <InputText
                            label={t('auth.login.email')}
                            placeholder={t('auth.login.inputEmail')}
                            name="email"
                            className="input-login-form"
                            control={control}
                            id="login-email"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <InputText
                            label={t('auth.login.password')}
                            placeholder={t('auth.login.inputPassword')}
                            name="password"
                            className="input-login-form"
                            control={control}
                            type="password"
                            id="login-password"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <p className="forgot-password">
                            {t('auth.login.forgotPassword')}
                            <a
                                href={appConfig.resetPasswordURL}
                                className="click-reset-passowrd"
                            >
                                {t('auth.login.resetPassword')}
                            </a>
                        </p>
                    </Col>
                </Row>
                <Row justify="end">
                    <Col>
                        <div className="wrapper-button">
                            <Button
                                type="primary"
                                disabled={isFormBusy}
                                onClick={login}
                                className="login-button"
                                id="login-submit"
                            >
                                {isFormBusy ? (
                                    <Spin indicator={antIcon} />
                                ) : (
                                    t('auth.login.buttonLoginText')
                                )}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default LoginForm;
