import { Modal } from 'antd';
import i18next from '~plugins/i18next/i18n';

interface IProp {
    title?: string;
    description?: string;
    okText?: string;
    onOk?: () => void;
    errorItems: string[];
    okButtonProps?: { danger: boolean };
}

export const BookingErrorModal = (props: IProp) => {
    Modal.error({
        title: props.title || i18next.t<string>('roomBooking.list.message.titleDelete'),
        okText:
            props.okText ||
            i18next.t<string>('roomBooking.list.statusModalConfirm.okText'),
        centered: true,
        okButtonProps: props.okButtonProps,
        content: (
            <>
                <span>
                    {props.description ||
                        i18next.t<string>('roomBooking.list.message.deleteByStatusError')}
                </span>
                <ul className="vertical-delete-list">
                    {props.errorItems.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </>
        ),
        onOk() {
            if (props.onOk) {
                props.onOk();
            }
        },
    });
};
