import { integrationsEn } from './en/integrations';
import { integrationsJa } from './ja/integrations';

const integrations = {
    ja: {
        integrations: integrationsJa,
    },
    en: {
        integrations: integrationsEn,
    },
};

export default integrations;
