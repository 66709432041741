const rankCalendarForm = {
    createTitle: 'Create new calendar',
    updateTitle: 'Detail setting',
    updateDescription: 'Select rank',
    createBtn: 'Create',
    name: {
        label: 'Calendar name',
        placeholder: 'Enter calendar name',
    },
    dailySettings: {
        title: 'Daily setting',
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
        sunday: 'Sun',
    },
    rankDuration: {
        title: 'Custom rank',
        duration: {
            label: 'Duration',
            placeholder: {
                startDate: 'Start date',
                endDate: 'End date',
            },
        },
        tllRank: {
            label: 'Rank',
            placeholder: 'Select rank',
        },
    },
    additionBtn: 'Add',
    create: {
        successMessage: 'Rank calendar have been created successful!',
        errorMessage: 'Create rank calendar error',
    },
    confirm: { cancelTitle: 'Do you want to cancel?' },
    updateBtn: 'Apply',
    update: {
        successMessage: 'Rank calendar have been updated successful!',
        errorMessage: 'Update rank calendar error',
    },
    overlapMessage: 'Plan rank duration is overlap',
    status: {
        active: 'Activate',
    },
    noChangeOption: 'Do not change',
};

export default rankCalendarForm;
