import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, notification, Row, Spin } from 'antd';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputTextArea, RadioGroup, RangePicker, SingleSelect } from '~components';
import { getStartDateAndEndDateByDay } from '~features/inventory/helper';
import { RoomBookingEvent } from '~features/room-booking/constants';
import {
    RoomManagementEvent,
    StopRoomCause,
    StopSellingStatus,
} from '~features/room-management/constants';
import {
    changeSellingStatusRoom,
    getRoomListStatus,
    roomManagementSelector,
    setSelectedRoomStopSellingCause,
} from '~features/room-management/reducers/room-management.reducer';
import { roomStatusFormSchemaResolver } from '~features/room-management/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import { useMitt } from '~plugins/mitt';
import './UpdateRoomStatusSidebar.scss';

export const UpdateRoomStatusSidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const { control, handleSubmit, reset, trigger } = useForm({
        resolver: roomStatusFormSchemaResolver,
    });
    const {
        room,
        isBlockRoomLoading,
        currentDate,
        isUpdateRoomStatusLoading,
        selectedRoomStopSellingCause,
    } = useAppSelector(roomManagementSelector);
    const [status, setStatus] = useState(StopSellingStatus.UNAVAILABLE);
    const dispatch = useAppDispatch();
    const { emitter } = useMitt();

    const onSubmit = () => {
        handleSubmit(async (data) => {
            const { startDate, endDate } = getStartDateAndEndDateByDay(
                data?.date[0],
                data?.date[1],
            );
            const mappedData = {
                reason: data.cause,
                memo: data.remark,
                startDate,
                endDate,
                status: data.status,
            };
            const response = await dispatch(
                changeSellingStatusRoom({ roomId: room!.id, body: mappedData }),
            );
            if (changeSellingStatusRoom.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('roomManagement.list.update.stopSelling'),
                    });
                    dispatch(getRoomListStatus());
                    emitter.emit(RoomManagementEvent.CHANGE_SELLING_STATUS_ROOM, true);
                } else {
                    notification.error({
                        message:
                            response.payload?.errors?.[0]?.message ||
                            response.payload?.message ||
                            '',
                    });
                }
            }
            onClose();
        })();
    };

    useEffect(() => {
        if (room) {
            let date =
                room.endDate && room.startDate
                    ? [parseDate(room.startDate), parseDate(room.endDate)]
                    : [parseDate(currentDate), parseDate(currentDate)];
            reset({
                status: StopSellingStatus.UNAVAILABLE,
                date,
                cause: room?.reason || StopRoomCause.INVENTORY_ADJUSTMENT,
                remark: room?.memo,
            });
        }
    }, [room]);

    const disabledDate = (current: Dayjs) => {
        // Can not select days before today
        return current.isBefore(customDayjs(), 'day');
    };

    useEffect(() => {
        emitter.on(RoomBookingEvent.TOGGLE_UPDATE_ROOM_STATUS, (val) => {
            const newStatus = typeof val === 'boolean' ? val : !isOpen;
            setIsOpen(newStatus);
        });
        return () => {
            emitter.off(RoomBookingEvent.TOGGLE_UPDATE_ROOM_STATUS);
        };
    }, []);

    const causeOptions = useMemo(() => {
        return Object.values(StopRoomCause).map((item) => {
            return {
                value: item,
                label: t(`roomManagement.list.causeOptions.${item}`),
            };
        });
    }, []);

    const stopSellingOptions = useMemo(() => {
        return [
            {
                value: StopSellingStatus.UNAVAILABLE,
                label: t(
                    `roomManagement.list.stopSellingStatus.${StopSellingStatus.UNAVAILABLE}`,
                ),
            },
        ];
    }, []);

    const watchStatus = useWatch({ control, name: 'status' });

    useEffect(() => {
        if (watchStatus) {
            setStatus(watchStatus);
        }
    }, [watchStatus]);

    const watchCause = useWatch({ control, name: 'cause' });

    useEffect(() => {
        if (watchCause !== StopRoomCause.OTHERS) {
            trigger('remark');
        }
    }, [watchCause]);

    const onClose = () => {
        setIsOpen(false);
        reset({
            status: StopSellingStatus.UNAVAILABLE,
            date: [parseDate(currentDate), parseDate(currentDate).add(1, 'day')],
            cause: selectedRoomStopSellingCause,
        });
    };

    return (
        <div className="update-room-status-form-wrapper">
            <Modal
                open={isOpen}
                onCancel={onClose}
                title={
                    <>
                        <span className="room-name">{room?.name}</span>
                        <span>{t('roomManagement.list.drawer.title')}</span>
                    </>
                }
                className="update-room-status-drawer"
                footer={
                    <div className="drawer-footer">
                        <Button
                            type="primary"
                            onClick={onSubmit}
                            loading={isBlockRoomLoading || isUpdateRoomStatusLoading}
                        >
                            {t('common.buttonSaveText')}
                        </Button>
                    </div>
                }
            >
                <Spin spinning={isUpdateRoomStatusLoading}>
                    <Form layout="vertical" autoComplete="off" scrollToFirstError>
                        <Row>
                            <Col span={15}>
                                <SingleSelect
                                    control={control}
                                    options={stopSellingOptions}
                                    name="status"
                                    label={t('roomManagement.list.drawer.statusLabel')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <RangePicker
                                    control={control}
                                    name="date"
                                    required
                                    disabledDate={disabledDate}
                                    label={t('roomManagement.list.drawer.dateLabel')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={20}>
                                <RadioGroup
                                    control={control}
                                    name="cause"
                                    options={causeOptions}
                                    disabled={status !== StopSellingStatus.UNAVAILABLE}
                                    label={t('roomManagement.list.drawer.causeLabel')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <InputTextArea
                                    control={control}
                                    name="remark"
                                    label={t('roomManagement.list.drawer.remarkLabel')}
                                    placeholder={t(
                                        'roomManagement.list.drawer.remarkPlaceholder',
                                    )}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};
