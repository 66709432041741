import saleItemListEn from './en/sale-item-list.locale';
import saleItemCreateEn from './en/sale-item-create.locale';
import saleItemUpdateEn from './en/sale-item-update.locale';
import saleItemImportEn from './en/sale-item-import.locale';
import saleItemListJa from './ja/sale-item-list.locale';
import saleItemCreateJa from './ja/sale-item-create.locale';
import saleItemUpdateJa from './ja/sale-item-update.locale';
import saleItemImportJa from './ja/sale-item-import.locale';

const saleItem = {
    en: {
        list: saleItemListEn,
        create: saleItemCreateEn,
        update: saleItemUpdateEn,
        import: saleItemImportEn,
    },
    ja: {
        list: saleItemListJa,
        create: saleItemCreateJa,
        update: saleItemUpdateJa,
        import: saleItemImportJa,
    },
};

export default saleItem;
