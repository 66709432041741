import { useMemo } from 'react';
import { InputNumber } from '~components';
import { ReceiptFormField, ReceiptItemType } from '~features/room-booking/constants';
import { IBookingReceiptTableData } from '~features/room-booking/interfaces';

type Props = {
    control: any;
    item: IBookingReceiptTableData;
    isEditMode: boolean;
    onChangeQuantity: (value: number, id: string, fieldUpdate: string) => void;
};
function QuantityColumn(props: Props) {
    const { control, item, isEditMode, onChangeQuantity } = props;
    const isCanEdit = useMemo(() => {
        return isEditMode && !item.isCheckout;
    }, [isEditMode, item]);

    const isCanEditQuantity = useMemo(() => {
        // additional sale item or sale item of plan/facility type
        if (!item.children && item.status === ReceiptItemType.RECEIPT) {
            return true;
        }
        return false;
    }, [item]);

    return isCanEdit && isCanEditQuantity ? (
        <>
            <InputNumber
                label=""
                name={`${item.id}.quantity`}
                onChange={(e) =>
                    onChangeQuantity(
                        Number(e.target?.value),
                        item.id,
                        ReceiptFormField.QUANTITY,
                    )
                }
                allowClear
                control={control}
            />
        </>
    ) : (
        <span>{item.quantity || item.quantity === 0 ? `${item.quantity}` : ''}</span>
    );
}

export default QuantityColumn;
