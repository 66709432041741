import { FieldTimeOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterExportCSVBody, parseDateTime } from '~common/commonFunctions';
import { DateFormat } from '~common/constants';
import { IDropDownOption } from '~common/interfaces';
import { useEscape } from '~common/useHooks';
import {
    InputPhoneNumber,
    InputText,
    RadioGroup,
    RangePicker,
    RightDrawerLayout,
    SingleSelect,
    TimeRangePicker,
} from '~components';
import {
    FacilityBookingAdvanceSearchFields,
    FacilityBookingStatus,
    UsedBookingTypeOfGuest,
} from '~features/facility-booking/constants';
import {
    facilityBookingAdvanceSearchFormSelector,
    isShowAdvanceSearchFormSelector,
    setAdvanceSearchListQuery,
    setFacilityBookingAdvanceSearchForm,
    setIsAdvanceSearch,
    setIsShowAdvanceSearch,
    setIsShowAdvanceSearchTags,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { advanceSearchSchema } from '~features/facility-booking/schema';
import {
    facilityDropDownOptionsSelector,
    fetchFacilityDropDown,
} from '~features/facility/facility.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import './AdvanceSearchForm.scss';

function AdvanceSearchForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [facilityBookingStatusOptions, setFacilityBookingStatusOptions] = useState<
        IDropDownOption[]
    >([]);
    const isShowAdvanceSearch = useAppSelector(isShowAdvanceSearchFormSelector);
    const facilityDropDownOptions = useAppSelector(facilityDropDownOptionsSelector);
    const facilityBookingAdvanceSearchForm = useAppSelector(
        facilityBookingAdvanceSearchFormSelector,
    );

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(advanceSearchSchema),
    });

    const onCloseDialog = () => {
        dispatch(setIsShowAdvanceSearch(false));
        reset();
    };
    const onSubmit = () => {
        handleSubmit((values) => {
            let {
                checkInDateRanges = [],
                checkInTimeRanges = [],
                usedBookingTypeOfGuest,
            } = values;

            dispatch(
                setFacilityBookingAdvanceSearchForm({
                    ...values,
                    checkInDateRanges: formatDateTime(
                        checkInDateRanges,
                        DateFormat.YYYY_MM_DD_SLASH,
                    ),
                    checkInTimeRanges: formatDateTime(
                        checkInTimeRanges,
                        DateFormat.HH_MM_COLON,
                    ),
                }),
            );
            const query = {
                ...values,
                checkInDateRanges:
                    checkInDateRanges?.length === 2
                        ? [
                              parseDate(parseDate(checkInDateRanges[0])?.fmYYYYMMDD())
                                  .startOf('day')
                                  .fmYYYYMMDDHHmmss(),
                              parseDate(parseDate(checkInDateRanges[1])?.fmYYYYMMDD())
                                  .endOf('day')
                                  .fmYYYYMMDDHHmmss(),
                          ]
                        : undefined,
                checkInTimeRanges:
                    checkInTimeRanges?.length === 2
                        ? [
                              parseDate(checkInTimeRanges[0])?.fmHHmmss(),
                              parseDate(checkInTimeRanges[1])?.fmHHmmss(),
                          ]
                        : undefined,
                usedBookingTypeOfGuest:
                    usedBookingTypeOfGuest === UsedBookingTypeOfGuest.ALL
                        ? undefined
                        : usedBookingTypeOfGuest,
            };
            filterExportCSVBody(query);
            const isEmptyForm = JSON.stringify(query) === JSON.stringify({});
            dispatch(setAdvanceSearchListQuery(query));
            dispatch(setIsShowAdvanceSearchTags(!isEmptyForm));
            dispatch(setIsAdvanceSearch(!isEmptyForm));
            onCloseDialog();
        })();
    };

    const formatDateTime = (
        momentArray: string[] | null,
        format: DateFormat,
    ): string[] => {
        if (momentArray?.length === 2) {
            return [
                parseDateTime(momentArray[0], format),
                parseDateTime(momentArray[1], format),
            ];
        }
        return [];
    };

    const onClose = () => {
        onCloseDialog();
    };
    useEscape(onClose);

    useEffect(() => {
        dispatch(fetchFacilityDropDown({}));
        const bookingStatus = Object.values(FacilityBookingStatus).filter((status) => {
            return status !== FacilityBookingStatus.ALL;
        });
        setFacilityBookingStatusOptions(
            bookingStatus.map((status) => {
                return {
                    value: status,
                    label: t(`facilityBooking.list.bookingStatus.${status}`),
                };
            }),
        );
    }, []);

    // TODO: check date time here
    const convertStringToDateTimeArray = (
        format: DateFormat,
        dateTiemArray?: string[] | null,
    ) => {
        if (dateTiemArray && dateTiemArray?.length === 2) {
            if (format === DateFormat.YYYY_MM_DD_SLASH) {
                return [parseDate(dateTiemArray[0]), parseDate(dateTiemArray[1])];
            }
            return [parseDateTime(dateTiemArray[0]), parseDateTime(dateTiemArray[1])];
        }
        return [];
    };

    useEffect(() => {
        if (isShowAdvanceSearch) {
            reset({
                ...facilityBookingAdvanceSearchForm,
                checkInDateRanges: convertStringToDateTimeArray(
                    DateFormat.YYYY_MM_DD_SLASH,
                    facilityBookingAdvanceSearchForm?.checkInDateRanges,
                ),
                checkInTimeRanges: convertStringToDateTimeArray(
                    DateFormat.HH_MM_COLON,
                    facilityBookingAdvanceSearchForm?.checkInTimeRanges,
                ),
            });
        }
    }, [isShowAdvanceSearch]);

    return (
        <div className="facility-booking-advance-search-form-wrapper">
            <RightDrawerLayout
                className="facility-booking-advance-search-form-drawer"
                open={isShowAdvanceSearch}
                onClose={onCloseDialog}
                onSubmit={onSubmit}
                title={''}
                placement="top"
                zIndex={99}
                getContainer={false}
                footer={
                    <div className="custom-drawer-footer">
                        <Row gutter={8}>
                            <Col span={8} offset={8}>
                                <Button className="cancel-button" onClick={onClose}>
                                    {t('common.buttonCancelText')}
                                </Button>
                            </Col>
                            <Col span={8}>
                                <Button
                                    type="primary"
                                    className="save-button"
                                    onClick={onSubmit}
                                >
                                    {t('facilityBooking.list.buttons.search')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
            >
                <Form layout="vertical">
                    <Row gutter={12}>
                        <Col span={12}>
                            <InputText
                                name={
                                    FacilityBookingAdvanceSearchFields.AUTO_GENERATED_CODE
                                }
                                label={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.AUTO_GENERATED_CODE}.label`,
                                )}
                                placeholder={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.AUTO_GENERATED_CODE}.placeholder`,
                                )}
                                allowClear
                                control={control}
                            />
                        </Col>
                        <Col span={12}>
                            <RadioGroup
                                name={
                                    FacilityBookingAdvanceSearchFields.USED_BOOKING_TYPE_OF_GUEST
                                }
                                label={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.USED_BOOKING_TYPE_OF_GUEST}.label`,
                                )}
                                optionType="button"
                                options={Object.values(UsedBookingTypeOfGuest).map(
                                    (classify) => {
                                        return {
                                            value: classify,
                                            label: t(
                                                `facilityBooking.list.guestClassification.${classify}`,
                                            ),
                                        };
                                    },
                                )}
                                defaultValue={UsedBookingTypeOfGuest.GUEST}
                                control={control}
                            />
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={12}>
                            <SingleSelect
                                name={FacilityBookingAdvanceSearchFields.FACILITY_IDS}
                                label={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.FACILITY_IDS}.label`,
                                )}
                                placeholder={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.FACILITY_IDS}.placeholder`,
                                )}
                                mode="multiple"
                                defaultValue={[]}
                                allowClear
                                control={control}
                                options={facilityDropDownOptions}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string)?.includes(
                                        input,
                                    )
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <SingleSelect
                                label={t(
                                    'facilityBooking.list.advanceSearchForm.status.label',
                                )}
                                placeholder={t(
                                    'facilityBooking.list.advanceSearchForm.status.placeholder',
                                )}
                                mode="multiple"
                                name={FacilityBookingAdvanceSearchFields.STATUS}
                                defaultValue={[]}
                                allowClear
                                control={control}
                                options={facilityBookingStatusOptions}
                            />
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={12}>
                            <InputText
                                name={FacilityBookingAdvanceSearchFields.GUEST_YOSIGANA}
                                label={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.GUEST_YOSIGANA}.label`,
                                )}
                                placeholder={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.GUEST_YOSIGANA}.placeholder`,
                                )}
                                allowClear
                                control={control}
                            />
                        </Col>
                        <Col span={12}>
                            <InputPhoneNumber
                                name={
                                    FacilityBookingAdvanceSearchFields.GUEST_PHONE_NUMBER
                                }
                                label={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.GUEST_PHONE_NUMBER}.label`,
                                )}
                                placeholder={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.GUEST_PHONE_NUMBER}.placeholder`,
                                )}
                                allowClear
                                control={control}
                            />
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={12}>
                            <RangePicker
                                name={
                                    FacilityBookingAdvanceSearchFields.BOOKING_DATE_PERIOD
                                }
                                label={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.BOOKING_DATE_PERIOD}.label`,
                                )}
                                placeholder={[
                                    t(
                                        `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.BOOKING_DATE_PERIOD}.placeholder`,
                                    ),
                                    t(
                                        `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.BOOKING_DATE_PERIOD}.placeholder`,
                                    ),
                                ]}
                                format={DateFormat.YYYY_MM_DD_SLASH}
                                control={control}
                            />
                        </Col>
                        <Col span={12}>
                            <TimeRangePicker
                                labelStart={t(
                                    `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.BOOKING_TIME_PERIOD}.label`,
                                )}
                                placeholder={[
                                    t(
                                        `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.BOOKING_TIME_PERIOD}.placeholder.start`,
                                    ),
                                    t(
                                        `facilityBooking.list.advanceSearchForm.${FacilityBookingAdvanceSearchFields.BOOKING_TIME_PERIOD}.placeholder.end`,
                                    ),
                                ]}
                                name={
                                    FacilityBookingAdvanceSearchFields.BOOKING_TIME_PERIOD
                                }
                                suffixIcon={<FieldTimeOutlined />}
                                control={control}
                                format={DateFormat.HH_MM_COLON}
                            />
                        </Col>
                    </Row>
                </Form>
            </RightDrawerLayout>
        </div>
    );
}

export default AdvanceSearchForm;
