import { Table } from 'antd';
import { ColumnGroupType, ColumnType, ColumnsType } from 'antd/lib/table';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormatMoney, FormatNumber, FormatPercent } from '~common/helper';
import {
    ReportTableCsvFileName,
    ReportTableId,
    ShowMonth,
} from '~features/report/constants';
import { ITableCellData } from '~features/report/interfaces';
import {
    salesByCategorySelector,
    setExportCsv,
} from '~features/report/reducers/sales-by-category.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { MonthlySummaryDataType } from './interfaces';
import { exportCSVReport } from '~features/report/helper';

interface Props {
    dataSource: any[];
    isLoading: boolean;
    summaryData: MonthlySummaryDataType;
}

const { Cell: SummaryCell, Row: SummaryRow } = Table.Summary;

const MonthlySalesByCategoryTable: React.FC<Props> = ({
    dataSource,
    isLoading,
    summaryData,
}) => {
    const { t } = useTranslation();
    const { isExportCsv } = useAppSelector(salesByCategorySelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isExportCsv) {
            exportCSVReport(
                ReportTableId.SALE_BY_CATEGORY_MONTHLY,
                `${ReportTableCsvFileName.SALE_BY_CATEGORY_MONTHLY}.csv`,
            );
            dispatch(setExportCsv(false));
        }
    }, [isExportCsv]);

    const renderChildrenColumns = (key: string) => {
        return [
            {
                title: t('report.salesByCategory.salesByCategoryColumn.numberOfRooms'),
                className: 'small-cell',
                key: `${key}-numberOfRooms`,
                dataIndex: `${key}NumberOfRooms`,
                render: (item: {
                    occupiedRooms: number;
                    totalRooms: number;
                    lastYearOccupiedRooms: number;
                    lastYearTotalRooms: number;
                }) => {
                    if (!item) return null;
                    const {
                        occupiedRooms,
                        totalRooms,
                        lastYearOccupiedRooms,
                        lastYearTotalRooms,
                    } = item;
                    return (
                        <>
                            {FormatNumber.format(occupiedRooms)}/
                            {FormatNumber.format(totalRooms)}{' '}
                            <span className="gray-color">
                                {`(${FormatNumber.format(
                                    lastYearOccupiedRooms,
                                )}/${FormatNumber.format(lastYearTotalRooms)})`}
                            </span>
                        </>
                    );
                },
            },
            {
                title: t('report.salesByCategory.salesByCategoryColumn.adults'),
                className: 'medium-cell',
                key: `${key}-adults`,
                dataIndex: `${key}Adults`,
                render: (item: ITableCellData) => {
                    if (!item) return null;
                    const { current, lastYear } = item;
                    return (
                        <>
                            {FormatNumber.format(current as number)}
                            <span className="gray-color">{` (${FormatNumber.format(
                                lastYear as number,
                            )})`}</span>
                        </>
                    );
                },
            },
            {
                title: t('report.salesByCategory.salesByCategoryColumn.children'),
                className: 'medium-cell',
                key: `${key}-children`,
                dataIndex: `${key}Children`,
                render: (item: ITableCellData) => {
                    if (!item) return null;
                    const { current, lastYear } = item;
                    return (
                        <>
                            {FormatNumber.format(current as number)}
                            <span className="gray-color">{` (${FormatNumber.format(
                                lastYear as number,
                            )})`}</span>
                        </>
                    );
                },
            },
            {
                title: t('report.salesByCategory.salesByCategoryColumn.amount'),
                className: 'large-cell',
                key: `${key}-amount`,
                dataIndex: `${key}Amount`,
                render: (item: ITableCellData) => {
                    if (!item) return null;
                    const { current, lastYear } = item;
                    return (
                        <>
                            {FormatMoney.format(current as number)}
                            <span className="gray-color">
                                {` (${FormatMoney.format(lastYear as number)})`}
                            </span>
                        </>
                    );
                },
            },
            {
                title: t('report.salesByCategory.salesByCategoryColumn.averagePrice'),
                className: 'medium-cell',
                key: `${key}-averagePrice`,
                dataIndex: `${key}AveragePrice`,
                render: (item: ITableCellData) => {
                    if (!item) return null;
                    const { current, lastYear } = item;
                    return (
                        <>
                            {FormatMoney.format(current as number)}
                            <span className="gray-color">
                                {` (${FormatMoney.format(lastYear as number)})`}
                            </span>
                        </>
                    );
                },
            },
            {
                title: t('report.salesByCategory.salesByCategoryColumn.occupancyRate'),
                className: 'medium-cell',
                key: `${key}-occupancyRate`,
                dataIndex: `${key}OccupancyRate`,
                render: (item: ITableCellData) => {
                    if (!item) return null;
                    const { current, lastYear } = item;
                    return (
                        <>
                            {FormatPercent.format(Number(current as number))}
                            <span className="gray-color">
                                {` (${FormatPercent.format(Number(lastYear as number))})`}
                            </span>
                        </>
                    );
                },
            },
        ];
    };

    const columns: ColumnsType<any> | ColumnGroupType<any> = [
        {
            title: t('report.salesByCategory.salesByCategoryColumn.roomType'),
            fixed: 'left',
            ellipsis: true,
            dataIndex: 'roomType',
            key: 'roomType',
            className: 'monthly-room-type-cell ',
            render: (roomTypeName: string) => {
                return <div className="room-type-cell">{roomTypeName}</div>;
            },
        },
        ...Object.values(ShowMonth).map((month) => {
            return {
                title: t(`report.salesByCategory.salesByCategoryColumn.${month}`),
                className: 'header-cell',
                children: renderChildrenColumns(month),
            };
        }),
    ];

    const summaryColumns = columns
        .filter((column) => column.key !== 'roomType')
        .map((column) => (column as ColumnGroupType<any>).children)
        .flat();

    const showSummaryNumberOfRooms = (columnDataIndex: string) => {
        if (!summaryData[columnDataIndex]) return null;
        const { occupiedRooms, totalRooms, lastYearOccupiedRooms, lastYearTotalRooms } =
            summaryData[columnDataIndex];

        return (
            <>
                {FormatNumber.format(occupiedRooms)}/{FormatNumber.format(totalRooms)}{' '}
                <span className="gray-color">
                    {`(${FormatNumber.format(
                        lastYearOccupiedRooms,
                    )}/${FormatNumber.format(lastYearTotalRooms)})`}
                </span>
            </>
        );
    };

    const showSummaryOccupancyRate = (columnDataIndex: string) => {
        if (!summaryData[columnDataIndex]) return null;
        const { current, lastYear } = summaryData[columnDataIndex];

        return (
            <>
                {FormatPercent.format(current)}{' '}
                <span className="gray-color">({FormatPercent.format(lastYear)})</span>
            </>
        );
    };

    const showSummaryAmount = (columnDataIndex: string) => {
        if (!summaryData[columnDataIndex]) return null;
        const { current, lastYear } = summaryData[columnDataIndex];

        return (
            <>
                {FormatMoney.format(current)}{' '}
                <span className="gray-color">({FormatMoney.format(lastYear)})</span>
            </>
        );
    };

    const showSummaryOthers = (columnDataIndex: string) => {
        if (!summaryData[columnDataIndex]) return null;
        const { current, lastYear } = summaryData[columnDataIndex];

        return (
            <>
                {FormatNumber.format(current)}{' '}
                <span className="gray-color">({FormatNumber.format(lastYear)})</span>
            </>
        );
    };

    const renderSummaryCell = (column: ColumnGroupType<any> | ColumnType<any>) => {
        if (!summaryData) return null;

        const columnDataIndex = (column as ColumnType<any>).dataIndex?.toString();

        const columnKey = column.key?.toString();
        if (columnKey?.includes('numberOfRooms')) {
            return showSummaryNumberOfRooms(columnDataIndex!);
        }
        if (columnKey?.includes('occupancyRate')) {
            return showSummaryOccupancyRate(columnDataIndex!);
        }
        if (columnKey?.includes('averagePrice') || columnKey?.includes('amount')) {
            return showSummaryAmount(columnDataIndex!);
        } else {
            return showSummaryOthers(columnDataIndex!);
        }
    };

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={isLoading}
            pagination={false}
            className="sales-by-category-table table-scrollbar"
            rowKey="id"
            scroll={{ x: true }}
            id={ReportTableId.SALE_BY_CATEGORY_MONTHLY}
            summary={() => {
                return (
                    <SummaryRow>
                        <SummaryCell index={0} className="total-cell">
                            {t('report.salesByCategory.total')}
                        </SummaryCell>
                        {summaryColumns.map((column, index) => {
                            return (
                                <SummaryCell key={index} index={index + 1}>
                                    {renderSummaryCell(column)}
                                </SummaryCell>
                            );
                        })}
                    </SummaryRow>
                );
            }}
        ></Table>
    );
};

export default MonthlySalesByCategoryTable;
