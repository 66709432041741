import React, { useEffect, useState } from 'react';
import { Col, Row, Tag, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '~hooks';
import { selectedPlanSelector } from '~features/plan/reducers/plan.reducer';
import PlanRoomTypePricingTab from './PlanRoomTypePricingTab/PlanRoomTypePricingTab';
import './Tab2.scss';
import { IPlanRoomType } from '~features/plan/interfaces';
import { reorderPlanRoomTypes } from '~features/plan/helper';

function Tab2() {
    const { t } = useTranslation();
    const selectedPlanDetail = useAppSelector(selectedPlanSelector);
    const [planRoomTypes, setPlanRoomTypes] = useState<IPlanRoomType[]>([]);
    useEffect(() => {
        const dataPlanRoomTypes: IPlanRoomType[] = reorderPlanRoomTypes(
            selectedPlanDetail?.planRoomTypes || [],
        );
        setPlanRoomTypes(dataPlanRoomTypes);
    }, [selectedPlanDetail]);

    const initPlanRoomType = {
        name: '',
        id: 0,
        feId: '',
        peopleCount: 0,
        planGroupCode: '',
        planRoomTypePricings: [],
    };

    return (
        <div className="plan-detail-room-type-pricing-tab">
            <div className="plan-detail-room-type-pricing-tab-content">
                <div className="form-title">
                    <span className="mr-8">{t('plan.detail.tab2.title')}</span>
                </div>
                <div className="plan-detail-rank-calendar-room-type">
                    <Row>
                        <Col span={6} className="plan-detail-label">
                            {t('plan.detail.tab2.rankCalendarAndRoomType.rankCalendar')}
                        </Col>
                        <Col span={18}>{selectedPlanDetail?.planRank?.name}</Col>
                    </Row>
                    <Row>
                        <Col span={6} className="plan-detail-label">
                            {t('plan.detail.tab2.rankCalendarAndRoomType.roomType')}
                        </Col>
                        <Col span={18}>
                            {planRoomTypes ? (
                                planRoomTypes.map((roomTypeItem) => {
                                    return (
                                        <Tag key={roomTypeItem.id}>
                                            {roomTypeItem?.roomType?.name}
                                        </Tag>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                </div>
                <div className="plan-detail-room-type-pricing-wrapper">
                    <div className="form-title">
                        <span className="mr-8">
                            {t('plan.detail.tab2.planRoomTypePricing.title')}
                        </span>
                        {planRoomTypes?.length !== 0 && (
                            <Tabs
                                defaultActiveKey={
                                    planRoomTypes?.[0]?.roomType?.name ||
                                    selectedPlanDetail?.autoGeneratedCode
                                }
                                className="plan-detail-guest-tabs"
                                items={planRoomTypes?.map((item, index) => {
                                    return {
                                        key: `${item.id}`,
                                        label: item.roomType?.name,
                                        children: (
                                            <PlanRoomTypePricingTab planRoomType={item} />
                                        ),
                                    };
                                })}
                            ></Tabs>
                        )}
                        {planRoomTypes?.length === 0 && (
                            <PlanRoomTypePricingTab planRoomType={initPlanRoomType} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tab2;
