import React, { useCallback, useEffect } from 'react';
import { Button, Layout, notification, Space } from 'antd';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../../constants';
import {
    ImarketingChannelBulkCreateForm,
    IMarketingChannelBulkCreateFormItem,
} from '../../interfaces';
import {
    bulkCreateMarketingChannels,
    createMarketingChannelListSelector,
    setCreateMarketingChannelList,
    showLoadingListSelector,
} from '../../marketing-channel.reducer';
import { bulkCreateMarketingChannelsSchema } from '../../schema';
import './CreateMarketingChannelsPage.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useNavigate } from 'react-router-dom';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getMarketingChannelGroupListForDropdown } from '~features/marketing-channel-group/marketing-channel-group.reducer';
import { ErrorMessageType } from '~common/constants';
import { showConfirm } from '~common/notification';
import CreateMarketingChannelsForm from '~features/marketing-channel/components/CreateMarketingChannelsForm/CreateMarketingChannelsForm';
import { validateUniqueValue } from '~common/commonFunctions';
import { getFormMarketingChannelId } from '~features/marketing-channel/helpers';
const { Footer, Content } = Layout;

function CreateMarketingChannelsPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { control, handleSubmit, reset, setError, getValues } =
        useForm<ImarketingChannelBulkCreateForm>({
            resolver: yupResolver(bulkCreateMarketingChannelsSchema),
        });
    // get marketing channel group list for dropdown
    useEffect(() => {
        dispatch(getMarketingChannelGroupListForDropdown({})); //TODO: replace hotel id here
        return () => {
            dispatch(setCreateMarketingChannelList([]));
        };
    }, []);

    const createMarketingChannelList = useAppSelector(createMarketingChannelListSelector);
    // set createMarketingChannelList form when has any change

    useEffect(() => {
        reset({
            items: createMarketingChannelList,
        });
    }, [createMarketingChannelList]);

    const showLoading = useAppSelector(showLoadingListSelector);
    const _bulkCreateChannels = useCallback(
        async (channels: IMarketingChannelBulkCreateFormItem[]) => {
            const response = await dispatch(
                bulkCreateMarketingChannels(
                    channels.map((channel, index) => ({
                        name: channel.name,
                        linkageCode: channel.linkageCode,
                        linkageCodeDayUse: channel.linkageCodeDayUse || undefined,
                        marketingChannelGroupId: channel.marketingChannelGroupId,
                        order: index + 1,
                    })),
                ),
            );
            if (bulkCreateMarketingChannels.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t(
                            'marketingChannel.form.createForm.createSuccessMessage',
                        ),
                    });
                    navigate('/front-settings/marketing-channel');
                } else {
                    (response.payload?.errors || []).forEach((error) => {
                        if (
                            (error?.key === 'name' ||
                                error?.key === 'marketingChannelGroupId' ||
                                error?.key === 'linkageCode' ||
                                error?.key === 'linkageCodeDayUse') &&
                            error?.order
                        ) {
                            setError(
                                `items.${error.order - 1}.${error.key}`,
                                { type: ErrorMessageType.MANUAL, message: error.message },
                                { shouldFocus: true },
                            );
                        }
                    });
                    notification.error({
                        message: response.payload?.message || '',
                    });
                }
            }
        },
        [],
    );
    // handle submit form
    const onSubmit = async () => {
        handleSubmit(async (data) => {
            const newMarketingChannels = getValues().items || [];
            if (!newMarketingChannels.length) {
                return;
            }
            const duplicatedNameIndexes = validateUniqueValue(
                newMarketingChannels,
                'name',
            );
            if (duplicatedNameIndexes.length) {
                duplicatedNameIndexes.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t(
                                'marketingChannel.form.createForm.marketingChannelNameDuplicated',
                            ),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            const duplicatedCodeIndexes = validateUniqueValue(
                newMarketingChannels,
                'linkageCode',
            );
            if (duplicatedCodeIndexes.length) {
                duplicatedCodeIndexes.forEach((index) => {
                    setError(
                        `items.${index}.linkageCode`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t(
                                'marketingChannel.form.createForm.marketingChannelCodeDuplicated',
                            ),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            const duplicatedLinkCodeDayUseIndexes = validateUniqueValue(
                newMarketingChannels,
                'linkageCodeDayUse',
            );
            if (duplicatedLinkCodeDayUseIndexes.length) {
                duplicatedLinkCodeDayUseIndexes.forEach((index) => {
                    setError(
                        `items.${index}.linkageCodeDayUse`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t(
                                'marketingChannel.form.createForm.marketingChannelCodeDayUseDuplicated',
                            ),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkCreateChannels(data.items);
        })();
    };

    const onCancel = () => {
        showConfirm({
            title: t('marketingChannel.form.confirm.cancelTitle'),
            cancelText: t('marketingChannel.form.confirm.buttonCancelText'),
            okText: t('marketingChannel.form.confirm.buttonDeleteText'),
            onOk() {
                dispatch(setCreateMarketingChannelList([]));
                navigate('/front-settings/marketing-channel');
            },
        });
    };
    return (
        <div className="create-marketing-channels-page">
            <StaffLayoutHeader
                isShowButtonBack={true}
                onClickButtonBack={onCancel}
                breadcrumbs={breadcrumbs()}
                title={t('marketingChannel.form.createForm.title')}
            />
            <Content>
                <CreateMarketingChannelsForm
                    control={control}
                    currentMarketingChannelList={getValues()?.items}
                />
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space align="end">
                        <Button onClick={onCancel}>
                            {t('marketingChannel.form.createForm.cancelBtn')}
                        </Button>
                        <Button
                            type="primary"
                            id={getFormMarketingChannelId('btn-submit')}
                            onClick={onSubmit}
                            loading={showLoading}
                        >
                            {t('marketingChannel.form.createForm.submitBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default CreateMarketingChannelsPage;
