import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import {
    IBodyResponse,
    ICsvFile,
    ICommonListQuery,
    IGetListResponse,
} from '~common/interfaces';
import { trimData } from '~common/commonFunctions';
import {
    IImportRoom,
    IRoom,
    IRoomBulkCreateFormItem,
    IRoomBulkDeleteItem,
    IRoomBulkUpdateFormItem,
    IRoomCreateBody,
    IRoomDisplayOrderBody,
    IRoomDisplayOrderResponse,
    IRoomDropDown,
    IRoomExportCsvQuery,
    IRoomGetListDropDownQuery,
    IRoomListResponse,
    IRoomResponseDetail,
    IRoomUpdateBody,
} from '../interfaces';
class RoomService extends ApiService {
    getList(queryString: ICommonListQuery) {
        return this._getList(queryString) as Promise<IRoomListResponse>;
    }

    getListByIds(ids: number[]) {
        return this._get(`${this.baseUrl}/get-by-ids`, {
            params: {
                ids,
            },
        }) as Promise<IBodyResponse<IRoom[]>>;
    }

    bulkCreate(bulkCreateBody: IRoomBulkCreateFormItem[]) {
        return this._post(`${this.baseUrl}/bulk-create`, {
            items: bulkCreateBody,
        }) as Promise<IBodyResponse<IRoom>>;
    }

    bulkUpdate(bulkUpdateBody: IRoomBulkUpdateFormItem[]) {
        return this._patch(`${this.baseUrl}/bulk-update`, {
            items: bulkUpdateBody,
        }) as Promise<IBodyResponse<IRoom>>;
    }

    create(body: IRoomCreateBody) {
        // TODO: replace hotel id here
        return this._create(body) as Promise<IBodyResponse<IRoom>>;
    }

    bulkDelete(bulkDeleteBody: IRoomBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    update(id: number, updateBody: IRoomUpdateBody) {
        trimData(updateBody);
        // TODO: replace here
        return this._patch(`${this.baseUrl}/${id}`, updateBody) as Promise<
            IBodyResponse<IRoom>
        >;
    }

    getDetail(id: number) {
        return this._getDetail(id) as Promise<IRoomResponseDetail>;
    }

    exportCsv(query: IRoomExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }

    getListForDropDown(query: IRoomGetListDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, { params: query }) as Promise<
            IBodyResponse<IGetListResponse<IRoomDropDown>>
        >;
    }
    getDropDownByRoomType(roomTypeIds: number[]) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params: {
                roomTypeIds,
            },
        }) as Promise<IBodyResponse<{ items: IRoom[] }>>;
    }

    importRoomCsv(importRoomBody: IImportRoom[]) {
        return this._post(`${this.baseUrl}/import-csv`, {
            items: importRoomBody,
        }) as Promise<IBodyResponse<IImportRoom>>;
    }

    updateRoomDisplayOrder(body: IRoomDisplayOrderBody) {
        return this._patch(`${this.baseUrl}/display-order`, body) as Promise<
            IBodyResponse<IRoomDisplayOrderResponse>
        >;
    }
}
export const roomService = new RoomService({ baseUrl: '/room' }, axiosService);
