export default {
    membersTitle: '同行者情報',
    cancel: '戻る',
    submit: '予約を確認',
    representativeRoom: '代表部屋',
    guestSelect: '宿泊者を選択',
    bookingList: '予約一覧',
    autoAssignment: '自動アサイン',
    label: {
        yomigana: '名前（ヨミガナ）',
        name: '名前',
        phone: '電話番号',
        gender: '性別',
        birthday: '生年月日',
        emailAddress: 'メールアドレス',
    },
    sameGuest: '*宿泊者と同じです',
    memo: 'メモ',
    memoPlaceholder: 'メモをご記入ください',
    file: 'ファイル',
    otaMemo: 'OTA備考',
    otaMemoPlaceholder: 'OTA備考',
    basicInfo: '基本情報',
    companionInfo: '同行者情報',
    next: '次へ',
    other: 'その他',
    memberGuestEmailDuplicated: 'Emailが重複しています',
    memberGuestEmailRequired: 'Emailが必要です',
    memberGuestYomiganaRequired: 'よみがなが必要です',
    subTotal: '料金: ¥{{subTotal}}',
    backConfirmDialog: {
        title: 'Unsaved info will be lost',
    },
    message: {
        calculateAmountError: 'Something wrong when calculating the item amount',
        exceedLimit: '残室数が足りません',
    },
    addRoomBooking: '予約を追加',
};
