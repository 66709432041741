import { Modal } from 'antd';
import classNames from 'classnames';
import {
    CLOSING_BOOKING_ITEMS_LIMIT_TO_ENABLE_SCROLL,
    CLOSING_BOOKING_MODAL_TIMEOUT,
} from '~features/closing-booking/constants';
import i18next from '~plugins/i18next/i18n';
import './ClosingBookingErrorModal.scss';

interface IProp {
    title?: string;
    description?: string;
    okText?: string;
    onOk?: () => void;
    errorRoomBookings: string[];
    errorFacilityBookings: string[];
    okButtonProps?: { danger: boolean };
}

export const ClosingBookingErrorModal = (props: IProp) => {
    const modal = Modal.warning({
        title: props.title || i18next.t<string>('roomBooking.list.message.titleDelete'),
        okText:
            props.okText ||
            i18next.t<string>('roomBooking.list.statusModalConfirm.okText'),
        centered: true,
        okButtonProps: props.okButtonProps,
        wrapClassName: 'closing-booking-error-modal',
        content: (
            <>
                {props.errorRoomBookings?.length === 0 &&
                props.errorFacilityBookings?.length === 0 ? (
                    <div>
                        {i18next.t<string>('closingBooking.page.noBookingToDelete')}
                    </div>
                ) : (
                    <div>
                        {props.description ||
                            i18next.t<string>(
                                'roomBooking.list.message.deleteByStatusError',
                            )}
                    </div>
                )}

                {props.errorRoomBookings?.length > 0 ? (
                    <div
                        className={classNames('closing-item-body', {
                            _scroll:
                                props.errorRoomBookings?.length >
                                CLOSING_BOOKING_ITEMS_LIMIT_TO_ENABLE_SCROLL,
                        })}
                    >
                        <span>{i18next.t<string>('closingBooking.page.tabs.room')}</span>
                        <ul className="vertical-list">
                            {props.errorRoomBookings?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    ''
                )}

                {props.errorFacilityBookings?.length > 0 ? (
                    <div
                        className={classNames('closing-item-body', {
                            _scroll:
                                props.errorRoomBookings?.length >
                                CLOSING_BOOKING_ITEMS_LIMIT_TO_ENABLE_SCROLL,
                        })}
                    >
                        <span>
                            {i18next.t<string>('closingBooking.page.tabs.facility')}
                        </span>
                        <ul className="vertical-list">
                            {props.errorFacilityBookings?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    ''
                )}
            </>
        ),
        onOk() {
            if (props.onOk) {
                props.onOk();
            }
        },
    });

    setTimeout(() => {
        modal.destroy();
    }, CLOSING_BOOKING_MODAL_TIMEOUT);
};
