import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Pagination, Table } from 'antd';
import { ColumnType, TableProps } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkUserPermission } from '~common/commonFunctions';
import {
    AdminGroup,
    AntdOrderDirection,
    cellAutoGeneratedCodeStyle,
    cellTextErrorStyle,
    DEFAULT_LIMIT_FOR_PAGINATION,
    OrderDirection,
    UserGroup,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import { paymentMethodSchema } from '~common/validatorSchema';
import { ModalConfirmDeletion } from '~components';
import {
    DEFAULT_PAYMENT_METHOD_LIST_QUERY,
    PaymentMethodColumns,
    PaymentMethodOrderBy,
} from '~features/payment-method/constants';
import { IPaymentMethod } from '~features/payment-method/interfaces';
import {
    getPaymentMethodList,
    paymentMethodListQuerySelector,
    paymentMethodListSelector,
    setIsShowForm,
    setPaymentMethodListQuery,
    setSelectedPaymentMethod,
    showLoadingListSelector,
    totalPageSelector,
    totalPaymentMethodsSelector,
} from '~features/payment-method/reducers/paymentMethod.reducer';
import { paymentMethodService } from '~features/payment-method/services/paymentMethod.service';
import { useAppDispatch, useAppSelector } from '~hooks';
import './PaymentMethodList.scss';

function PaymentMethodList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // store
    const paymentMethodList = useAppSelector(paymentMethodListSelector);
    const paymentMethodListQuery = useAppSelector(paymentMethodListQuerySelector);
    const totalPaymentMethods = useAppSelector(totalPaymentMethodsSelector);
    const totalPage = useAppSelector(totalPageSelector);
    const showLoadingList = useAppSelector(showLoadingListSelector);

    const getPaymentMethodCellTextStyle = (paymentMethod: IPaymentMethod) => {
        return paymentMethodSchema.isValidSync(paymentMethod) ? {} : cellTextErrorStyle;
    };

    const paymentMethodColumns: ColumnType<IPaymentMethod>[] = [
        {
            title: t('paymentMethod.list.columns.id'),
            width: '120px',
            key: 'id',
            sorter: true,
            onCell: () => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                };
            },
            render: (paymentMethod: IPaymentMethod) => {
                return <>{paymentMethod.autoGeneratedCode}</>;
            },
        },
        {
            title: t('paymentMethod.list.columns.paymentMethod'),
            key: PaymentMethodColumns.NAME,
            sorter: true,
            onCell: (paymentMethod: IPaymentMethod) => {
                return {
                    style: getPaymentMethodCellTextStyle(paymentMethod),
                };
            },
            render: (paymentMethod: IPaymentMethod) => {
                return <>{paymentMethod.name}</>;
            },
            ellipsis: true,
        },
    ];

    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const rows: any[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundRow = paymentMethodList.find(
                (paymentMethod: IPaymentMethod) =>
                    paymentMethod.autoGeneratedCode === selectedRowKey,
            );
            if (foundRow) rows.push(foundRow);
        });
        return rows;
    }, [selectedRowKeys, paymentMethodList]);

    const isSelectSeedData = useMemo(() => {
        const haveSeedData = selectedRows.filter((item) => item.isSeedData);
        return !!haveSeedData.length;
    }, [selectedRows, paymentMethodList]);

    const onChangePage = (page: number) => {
        dispatch(setPaymentMethodListQuery({ ...paymentMethodListQuery, page }));
    };

    const fetchData = () => {
        dispatch(getPaymentMethodList());
    };
    useEffect(() => {
        fetchData();
        setSelectedRowKeys([]);
    }, [paymentMethodListQuery]);

    const onChange: TableProps<IPaymentMethod>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        // this is for pagination, sort, filter logic later
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setPaymentMethodListQuery({
                    ...paymentMethodListQuery,
                    orderBy: PaymentMethodOrderBy.AUTO_GENERATED_CODE,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setPaymentMethodListQuery({
                    ...paymentMethodListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };
    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row: IPaymentMethod) => row.id);

        const response = await paymentMethodService.bulkDelete({ ids: selectedIds });

        if (response.success) {
            notification.success({
                message: t('paymentMethod.list.delete.success'),
            });
            setSelectedRowKeys([]);

            // if user delete all payments of the last page, show the first page
            if (
                selectedIds.length +
                    (totalPage - 1) *
                        (paymentMethodListQuery.limit ?? DEFAULT_LIMIT_FOR_PAGINATION) >=
                totalPaymentMethods
            ) {
                dispatch(setPaymentMethodListQuery(DEFAULT_PAYMENT_METHOD_LIST_QUERY));
            } else fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const handleEdit = () => {
        dispatch(setIsShowForm(true));
        dispatch(setSelectedPaymentMethod(selectedRows?.[0]));
    };

    const editPaymentMethod = (paymentMethod: IPaymentMethod) => {
        dispatch(setSelectedPaymentMethod(paymentMethod));
        dispatch(setIsShowForm(true));
    };

    const onClickRow = (paymentMethod: IPaymentMethod) => {
        if (isReadOnly || paymentMethod.isSeedData) {
            return {
                onClick: () => {
                    return;
                },
            };
        }
        return {
            onClick: () => {
                editPaymentMethod(paymentMethod);
            },
        };
    };

    const showDeleteConfirmDialog = () => {
        const canNotDeleteItems = selectedRows.filter((row) => row.isSeedData === true);
        if (canNotDeleteItems?.length) {
            Modal.error({
                title: t('paymentMethod.list.confirm.cantDelete'),
                content: (
                    <ul>
                        {canNotDeleteItems?.map((item) => (
                            <li key={item.autoGeneratedCode}>{item.name}</li>
                        ))}
                    </ul>
                ),
                okText: t('common.ok'),
            });
        } else {
            ModalConfirmDeletion({
                title: t('paymentMethod.list.delete.title'),
                description: t('paymentMethod.list.delete.description'),
                buttonCancelText: t('paymentMethod.form.deleteConfirm.cancelText'),
                buttonDeleteText: t('paymentMethod.form.deleteConfirm.deleteText'),
                okButtonProps: { danger: true },
                deletedItems: selectedRows.map((row: IPaymentMethod) => row.name),
                onClickButtonDelete: onConfirmDeletion,
            });
        }
    };

    return (
        <div className="payment-method-list-wrapper">
            <div className="children-type-list-header">
                {!isReadOnly && selectedRowKeys?.length > 0 && (
                    <div className="list-header-left">
                        {selectedRowKeys?.length === 1 && !isSelectSeedData && (
                            <Button
                                type="text"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={handleEdit}
                            />
                        )}
                        <Button
                            type="text"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={showDeleteConfirmDialog}
                        />
                    </div>
                )}
            </div>

            <Table
                columns={paymentMethodColumns}
                dataSource={paymentMethodList}
                pagination={false}
                rowKey="autoGeneratedCode"
                scroll={{ y: '400px' }}
                onChange={onChange}
                loading={showLoadingList}
                rowSelection={rowSelection}
                onRow={onClickRow}
                rowClassName={(record) => {
                    if (isReadOnly || record.isSeedData) {
                        return '';
                    }
                    return 'payment-method-row';
                }}
            />

            {totalPage > 1 && (
                <Pagination
                    align="center"
                    className="payment-method-pagination"
                    defaultCurrent={paymentMethodListQuery.page}
                    current={paymentMethodListQuery.page}
                    total={totalPaymentMethods}
                    pageSize={paymentMethodListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default PaymentMethodList;
