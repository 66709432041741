import React, { useEffect } from 'react';
import './DashboardPage.scss';
import { Layout } from 'antd';
import { DashboardHeader } from './components/DashboardHeader/DashboardHeader';
import { BookingListCard } from './components/BookingListCard/BookingListCard';
import { NotificationCard } from './components/NotificationCard/NotificationCard';
import { RoomOccupancyCard } from './components/RoomOccupancyCard/RoomOccupancyCard';
import { ContactCard } from './components/ContactCard/ContactCard';
import { ControlOverlay } from './components/ControlOverlay/ControlOverlay';
import { Element } from 'react-scroll';
import { getBookingList } from '../../reducers/dashboard.reducer';
import { DashboardControlOverlay } from '../../constants';
import { useAppDispatch } from '~hooks';
import { getDashboardBookingOverview } from '~features/dashboard/reducers/dashboard.reducer';

export const DashboardPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchData = () => {
            dispatch(getBookingList());
            dispatch(getDashboardBookingOverview());
        };
        fetchData();
    }, []);

    return (
        <Layout.Content>
            <div className="dashboard-page-content">
                <Element name={DashboardControlOverlay.bookingOverview}>
                    <DashboardHeader />
                </Element>
                <Element name={DashboardControlOverlay.bookingList}>
                    <BookingListCard />
                </Element>
                <Element name={DashboardControlOverlay.notification}>
                    <NotificationCard />
                </Element>
                <Element name={DashboardControlOverlay.roomOccupancy}>
                    <RoomOccupancyCard />
                </Element>
                <Element name={DashboardControlOverlay.contact}>
                    <ContactCard />
                </Element>
            </div>
            <ControlOverlay />
        </Layout.Content>
    );
};
