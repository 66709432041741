import { forEach } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { calculatePositionLeft, calculateWidth } from '~common/commonFunctions';
import { EmitterGlobalEvent, MINUTES_PER_DAY, MINUTES_PER_HOUR } from '~common/constants';
import {
    CELL_HEADER_HEIGHT,
    CELL_HEIGHT,
    CELL_WIDTH,
    HEADER_HEIGHT,
    HEADER_HEIGHT_DAY_VIEW,
} from '~features/room-booking/constants';
import {
    bookingListSelector,
    isDayViewSelector,
    roomFilterSelector,
    scheduleStateSelector,
} from '~features/room-booking/reducers/schedule.reducer';
import { useAppSelector } from '~hooks';
import { Dayjs, parseDate } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import { DropBox } from './DropBox';

type Props = {
    cellWidth: number;
    startTime: Dayjs;
    endTime: Dayjs;
};

export const DropBoxList = ({ cellWidth, startTime, endTime }: Props) => {
    const roomList = useAppSelector(roomFilterSelector);
    const bookingList = useAppSelector(bookingListSelector);
    const { currentDragData } = useAppSelector(scheduleStateSelector);
    const isDayView = useAppSelector(isDayViewSelector);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const time = useMemo(
        () => ({
            start: startTime,
            end: endTime,
        }),
        [startTime, endTime],
    );
    const { emitter } = useMitt();
    useEffect(() => {
        emitter.on(EmitterGlobalEvent.SCROLL, (scroll: { x: number; y: number }) => {
            setPosition(scroll);
        });
        return () => {
            emitter.off(EmitterGlobalEvent.SCROLL);
        };
    }, []);
    const unit = useMemo(() => {
        return isDayView ? MINUTES_PER_HOUR : MINUTES_PER_DAY;
    }, [isDayView]);
    const { booking } = currentDragData || {};

    const invalidRooms = useMemo(() => {
        const _invalidRooms: Record<string, boolean> = {};
        if (!booking) return _invalidRooms;
        forEach(bookingList, (items) => {
            forEach(items, (bookings) => {
                forEach(bookings, (item) => {
                    if (item.isDayUse) return;
                    const _start = parseDate(
                        item.stayingStartDate + ' ' + item.checkInTime,
                    );
                    const _end = parseDate(item.stayingEndDate + ' ' + item.checkOutTime);
                    const bookingStart = parseDate(
                        booking.stayingStartDate + ' ' + booking.checkInTime,
                    );
                    const bookingEnd = parseDate(
                        booking.stayingEndDate + ' ' + booking.checkOutTime,
                    );
                    if (
                        (_start.isBefore(bookingStart) && _end.isAfter(bookingEnd)) ||
                        (_start.isBefore(bookingEnd) && _end.isAfter(bookingEnd)) ||
                        (_start.isAfter(bookingStart) && _end.isBefore(bookingEnd))
                    ) {
                        const id = (item.roomId || item.room.id) as number;
                        _invalidRooms['room_' + id] = true;
                    }
                });
            });
        });
        return _invalidRooms;
    }, [bookingList, booking]);

    if (!booking) {
        return null;
    }

    const renderList = () => {
        const list: React.ReactNode[] = [];
        let start = booking.stayingStartDate;
        if (booking.checkInTime && booking.isDayUse) {
            start += ' ' + (isDayView ? booking.checkInTime : '00:00');
        } else {
            start += isDayView ? ' ' + booking.checkInTime : ' 12:00';
        }
        const left =
            calculatePositionLeft({
                from: time.start,
                to: parseDate(start),
                widthOfColumn: cellWidth / unit,
            }) + CELL_WIDTH;
        const cellHeaderHeight = isDayView ? HEADER_HEIGHT_DAY_VIEW : HEADER_HEIGHT;
        let top = cellHeaderHeight + 8;
        let end = booking.stayingEndDate;
        if (booking.checkOutTime && booking.isDayUse) {
            end += ' ' + booking.checkOutTime;
        } else {
            end += isDayView ? ' ' + booking.checkOutTime : ' 12:00';
        }
        const width =
            !isDayView && booking.isDayUse
                ? cellWidth - 8
                : calculateWidth({
                      from: parseDate(start)?.fmYYYYMMDDHHmm('-'),
                      to: parseDate(end)?.fmYYYYMMDDHHmm('-'),
                      widthOfColumn: cellWidth / unit,
                  });

        const _left = left - position.x;
        for (let index = 0; index < roomList.length; index++) {
            const room = roomList[index];
            if (!room.parentId) {
                top += CELL_HEADER_HEIGHT;
            } else {
                if (!invalidRooms['room_' + room.id]) {
                    list.push(
                        <DropBox
                            key={room.id}
                            booking={booking}
                            roomId={room.id}
                            roomTypeId={room.parentId}
                            roomName={room.name}
                            roomTypeName={room.parentName}
                            left={_left}
                            top={top - position.y}
                            width={width}
                        />,
                    );
                }
                top += CELL_HEIGHT;
            }
        }
        return list;
    };
    return <>{renderList()}</>;
};
