import { IBodyResponse } from '~common/interfaces';
import {
    IReceiptSummaryData,
    IReceiptSummaryRequestParameters,
} from '~features/report/interfaces';
import { axiosInstanceV2 } from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';

class ReportServiceV2 extends ApiService {
    getReceiptSummaryData(params: IReceiptSummaryRequestParameters) {
        return this._get(`${this.baseUrl}/receipt-summary`, {
            params,
        }) as Promise<IBodyResponse<IReceiptSummaryData>>;
    }
}

export const reportServiceV2 = new ReportServiceV2(
    {
        baseUrl: '/report',
    },
    axiosInstanceV2,
);
