import facilityBookingCreateFormEn from './en/facility-booking-create-form.locale';
import facilityBookingDetailEn from './en/facility-booking-detail.locale';
import facilityBookingListEn from './en/facility-booking-list.locale';
import facilityBookingScheduleEn from './en/facility-booking-schedule.locale';
import splitReceiptEn from './en/split-receipt.locale';
import facilityBookingPrintingEn from './en/facility-booking-printing.locale';

import facilityBookingCreateFormJa from './ja/facility-booking-create-form.locale';
import facilityBookingDetailJa from './ja/facility-booking-detail.locale';
import facilityBookingListJa from './ja/facility-booking-list.locale';
import facilityBookingScheduleJp from './ja/facility-booking-schedule.locale';
import splitReceiptJa from './ja/split-receipt.locale';
import facilityBookingPrintingJa from './ja/facility-booking-printing.locale';

const facilityBooking = {
    ja: {
        list: facilityBookingListJa,
        createForm: facilityBookingCreateFormJa,
        detail: facilityBookingDetailJa,
        schedule: facilityBookingScheduleJp,
        splitReceipt: splitReceiptJa,
        printing: facilityBookingPrintingJa,
    },
    en: {
        list: facilityBookingListEn,
        createForm: facilityBookingCreateFormEn,
        detail: facilityBookingDetailEn,
        schedule: facilityBookingScheduleEn,
        splitReceipt: splitReceiptEn,
        printing: facilityBookingPrintingEn,
    },
};

export default facilityBooking;
