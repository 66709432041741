const saleGroupForm = {
    title: '集計グループの登録',
    editTitle: '集計グループの編集',
    importTitle: 'CSVアップロード',
    name: 'グループ名',
    namePlaceholder: '集計グループ名',
    salesItemLabel: '科目',
    salesItemPlaceHolder: '科目を選択',
    addition: '科目を追加',
    submitBtn: '登録',
    message: {
        createSuccess: 'セールグループが登録されました',
        createError: 'セールグループの登録ができませんでした',
        updateSuccess: 'セールグループが更新されました',
        updateError: 'セールグループの更新ができませんでした',
    },
    nameDuplicated: '名前が重複しています',
    editButton: '保存',
    cancelButton: 'キャンセル',
    confirm: {
        cancelTitle: 'キャンセルしてもよろしいですか？',
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
    },
};

export default saleGroupForm;
