export const facilityListEn = {
    title: 'Facility',
    breadcrumbs: {
        home: 'home',
        facility: 'Facility',
    },
    filterForm: {
        buttons: {
            create: 'Add facility',
        },
    },
    columns: {
        id: 'ID',
        name: 'Name',
        typeName: 'Facility type',
        basePriceTaxIncluded: 'Base price with tax (￥)',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Facility name',
        facilityTypeName: 'Facility type name',
        basePriceTaxIncluded: 'Connecting room',
    },
    deleteFacilitiesSuccess: 'Facilities have been deleted successful',
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
    },
    exportColumns: {
        autoGeneratedCode: 'ID',
        name: 'Name',
        facilityType: 'Facility Type',
        basePriceTaxIncluded: 'Base price with tax (￥)',
    },
    unitPrice: '円',
};
