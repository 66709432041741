import { Radio, Select } from 'antd';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    DEFAULT_NUMBER_OF_GROUPS,
    MAX_NUMBER_OF_SPLIT_RECEIPT_GROUP,
    SelectSplitBillTypeOptions,
} from '~features/facility-booking/constants';
import {
    setNumberOfGroups,
    splitReceiptStateSelector,
} from '~features/facility-booking/reducers/split-receipt.reducer';
import { INVOICE_PATH } from '~features/room-booking/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import './SelectSplitBillTypePanel.scss';

function SelectSplitBillTypePanel() {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { numberOfGroups } = useAppSelector(splitReceiptStateSelector);

    const numberOfGroupsOptions = useMemo(() => {
        return Array.from(Array(MAX_NUMBER_OF_SPLIT_RECEIPT_GROUP).keys()).map(
            (number) => ({
                label: `${number + 1}${t(
                    'facilityBooking.splitReceipt.selectSplitBillTypePanel.split',
                )}`,
                value: number + 1,
            }),
        );
    }, []);

    const selectSplitBillTypeOptions = [
        {
            value: SelectSplitBillTypeOptions.BASED_ON_GROUP,
            label: t(
                `facilityBooking.splitReceipt.selectSplitBillTypePanel.basedOnGroup`,
            ),
            defaultChecked: true,
        },
    ];

    const onChangeNumberOfGroups = (number: number) => {
        dispatch(setNumberOfGroups(number));
    };

    useEffect(() => {
        return () => {
            dispatch(setNumberOfGroups(DEFAULT_NUMBER_OF_GROUPS)); // reset number of groups
        };
    }, []);

    return (
        <div className="split-facility-booking-select-split-bill-type-wrapper">
            <div className="title">
                {pathname.endsWith(INVOICE_PATH) ? t('facilityBooking.splitReceipt.titleInvoice') : t('facilityBooking.splitReceipt.selectSplitBillTypePanel.title')}
            </div>
            <div className="body">
                <div className="select-split-bill-type">
                    <div className="division-type-title">
                        {t(
                            'facilityBooking.splitReceipt.selectSplitBillTypePanel.divisionType',
                        )}
                    </div>
                    <Radio.Group
                        options={selectSplitBillTypeOptions}
                        optionType="button"
                        defaultValue={SelectSplitBillTypeOptions.BASED_ON_GROUP}
                    />
                </div>
                <div className="group-number-select">
                    <div className="split-title">
                        {t('facilityBooking.splitReceipt.selectSplitBillTypePanel.split')}
                    </div>
                    <Select
                        options={numberOfGroupsOptions}
                        value={numberOfGroups}
                        onChange={onChangeNumberOfGroups}
                    />
                </div>
            </div>
        </div>
    );
}

export default SelectSplitBillTypePanel;
