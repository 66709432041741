import { ShowMonth } from '~features/report/constants';
import dayjs from '~plugins/dayjs';
import customDayjs from '~plugins/dayjs';
import { IRoomBookingItem } from '~features/room-booking/interfaces';

/**
 * Extracts the first number or percentage from a string in CSV format.
 *
 * @param {string} str - The input string containing numbers or percentages.
 * @returns {number|null} The first number or percentage found in the string,
 *                        with percentages converted to float and others as integer.
 *                        Returns null if no number or percentage is found.
 */
export function getFirstNumberFromStringCsv(str: string): number | null {
    const match = str.match(/(?:\d{1,3}(,\d{3})*|\d+)(?:\.\d+)?%?/);
    if (match) {
        const numberOrPercentage = match[0].replace(/,/g, '');
        if (numberOrPercentage.includes('%')) {
            return parseFloat(numberOrPercentage.replace('%', ''));
        } else {
            return parseInt(numberOrPercentage);
        }
    }
    return null;
}

export function isValidDateCsv(dateString: string): boolean {
    const regex = /^\d{2}\/\d{2}\/\d{4}\(\w{3}\)$/;
    if (!regex.test(dateString)) {
        return false;
    }
    const dateComponents = dateString.split('/');
    const month = parseInt(dateComponents[0], 10);
    const day = parseInt(dateComponents[1], 10);
    const year = parseInt(dateComponents[2], 10);
    if (year < 1000 || year > 3000 || month <= 0 || month > 12) {
        return false;
    }
    const maxDayInMonth = new Date(year, month, 0).getDate();
    if (day <= 0 || day > maxDayInMonth) {
        return false;
    }
    return true;
}

export function getDateFromDateStringCsv(dateString: string): string {
    return customDayjs(dateString).format('YYYY/MM/DD');
}

export const FormatMoney = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
});

export const FormatPercent = new Intl.NumberFormat('ja-JP', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const FormatJapaneseDate = new Intl.DateTimeFormat('ja-JP', {
    month: 'long',
    day: 'numeric',
});

export const FormatNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export const calculatePercentage = (current: number, previous: number) => {
    if (previous === 0) return 0;
    return current / previous;
};

const getMonth = (date: string) => dayjs(date).month() + 1;

const getAdjacentMonths = (month: number) => {
    const previousMonth = month === 1 ? 12 : month - 1;
    const nextMonth = month === 12 ? 1 : month + 1;
    return { previousMonth, nextMonth };
};

export const checkMonthPeriod = (
    dateStart: string,
    dateEnd: string,
    filterDate: string,
) => {
    const startDate = getMonth(dateStart);
    const endDate = getMonth(dateEnd);
    const currentMonth = getMonth(filterDate);

    const { previousMonth, nextMonth } = getAdjacentMonths(currentMonth);

    if (startDate === currentMonth && endDate === currentMonth) {
        return ShowMonth.CURRENT_MONTH;
    }
    if (startDate === previousMonth && endDate === previousMonth) {
        return ShowMonth.LAST_MONTH;
    }
    if (startDate === nextMonth && endDate === nextMonth) {
        return ShowMonth.NEXT_MONTH;
    }
};

export const decodeUtf8byteSequences = (encodedFilename: string) => {
    try {
        // Convert the encoded string back to a byte array
        const byteArray = [];
        for (let i = 0; i < encodedFilename.length; i++) {
            byteArray.push(encodedFilename.charCodeAt(i));
        }

        // Decode the byte array back to a string
        return new TextDecoder('utf-8').decode(new Uint8Array(byteArray));
    } catch (err) {
        return encodedFilename;
    }
};

export const checkHasOverlappingDatesInRoomBookingItems = (
    bookingItems: IRoomBookingItem[],
) => {
    // Sort the booking items by startDateOfStay
    bookingItems.sort((a, b) =>
        dayjs(a.startDateOfStay).isAfter(b.startDateOfStay) ? 1 : -1,
    );

    for (let i = 1; i < bookingItems.length; i++) {
        // If the start date of the current item is before the end date of the previous item, there's an overlap
        if (
            dayjs(bookingItems[i].startDateOfStay).isBefore(
                dayjs(bookingItems[i - 1].endDateOfStay),
            )
        ) {
            return true;
        }
    }

    return false;
};
