const inventoryList = {
    title: '在庫管理',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        inventory: '在庫管理',
        stopSelling: '売止設定',
    },
    syncButton: 'Sync inventory with TLL',
    settingButton: '初期在庫設定',
    dateDesignation: '日付指定',
    stopSellingButton: '売止設定',
    editButton: '編集',
    saveButton: '保存する',
    bulkStopSellingButton: '一括売止',
    inventoryColumn: {
        stoppingRooms: '売止在庫',
        availableRooms: '残室',
    },
    day: {
        monday: '月',
        tuesday: '火',
        wednesday: '水',
        thursday: '木',
        friday: '金',
        saturday: '土',
        sunday: '日',
    },
    year: '年',
    month: '月',
    message: {
        updateSuccess: '更新成功',
        updateStoppingFaild: '予約可能部屋数より多くの売止在庫を更新できません',
        stoppingRoomEmpty: '売止在庫数を入力してください',
        roomError: '選択されている部屋タイプに部屋が存在しません',
    },
    syncInventoryStart:
        '現在TLLと在庫を同期中でございます, 数分後にもう一度更新してください。',
};

export default inventoryList;
