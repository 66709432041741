import { CalendarDay } from './../constants';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IRankCalendarDropDownQuery,
    IRankCalendarForm,
    IRankCalendarFormData,
    IRankCalendarState,
} from '../interfaces';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_LIMIT_FOR_PAGINATION, TllRank } from '~common/constants';
import { initRankCalendarListQuery } from '../constants';
import { cloneDeep, trim } from 'lodash';
import { rankCalendarService } from '../services/rank-calendar.service';
import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import { getDayRank } from '../helper';

const initialState: IRankCalendarState = {
    rankCalendarListQuery: cloneDeep(initRankCalendarListQuery),
    rankCalendarList: [],
    totalRankCalendar: 0,
    showLoadingList: false,
    selectedRankCalendarDetail: null,
    rankCalendarForm: null,
    rankCalendarListForDropDown: [],
    showForm: false,
    formBusy: false,
    isUpdating: false,
};

export const fetchRankCalendarList = createAsyncThunk(
    'rank-calendar/fetchRankCalendarList',
    async (_, { getState }) => {
        const query = rankCalendarListQuerySelector(getState() as AppState);
        return await rankCalendarService.getList(query);
    },
);

export const fetchRankCalendarDetail = createAsyncThunk(
    'rank-calendar/fetchRankCalendarDetail',
    async (id: number) => {
        return rankCalendarService.getDetail(id);
    },
);

export const getRankCalendarListForDropDown = createAsyncThunk(
    'rank-calendar/getRankCalendarListForDropDown',
    async (params: IRankCalendarDropDownQuery) => {
        return await rankCalendarService.getRankCalendarListForDropdown(params);
    },
);

export const createRankCalendar = createAsyncThunk(
    'rank-calendar/createRankCalendar',
    async (formData: IRankCalendarFormData) => {
        return await rankCalendarService.create(formData);
    },
);

export const updateRankCalendar = createAsyncThunk(
    'rank-calendar/updateRankCalendar',
    async ({ id, formData }: { id: number; formData: IRankCalendarFormData }) => {
        return await rankCalendarService.update(id, formData);
    },
);

const rankCalendarSlice = createSlice({
    name: 'rank-calendar',
    initialState,
    reducers: {
        setRankCalendarListQuery: (state, action) => {
            state.rankCalendarListQuery = action.payload;
        },
        setShowForm: (state, action) => {
            state.showForm = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            state.rankCalendarListQuery.keyword = keyword;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
        setRankCalendarForm: (state, action: PayloadAction<IRankCalendarForm>) => {
            state.rankCalendarForm = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRankCalendarList.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(fetchRankCalendarList.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.rankCalendarList = action.payload?.data?.items || [];
            state.totalRankCalendar = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchRankCalendarDetail.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(fetchRankCalendarDetail.fulfilled, (state, action) => {
            state.showLoadingList = false;
            const result = {
                ...action.payload?.data,
                planRankDurations: (action.payload?.data?.planRankDurations || [])?.map(
                    (planRankDuration) => {
                        return {
                            ...planRankDuration,
                            startDate: parseDate(
                                planRankDuration.startDate,
                            )?.fmYYYYMMDD(),
                            endDate: parseDate(planRankDuration.endDate)?.fmYYYYMMDD(),
                        };
                    },
                ),
                dayRanks: (action.payload?.data?.dayRanks || [])?.map((dayRank) => {
                    return {
                        ...dayRank,
                        startDate: dayRank.startDate
                            ? parseDate(dayRank.startDate)?.fmYYYYMMDD()
                            : undefined,
                        endDate: dayRank.endDate
                            ? parseDate(dayRank.endDate)?.fmYYYYMMDD()
                            : undefined,
                    };
                }),
            };
            state.selectedRankCalendarDetail = action.payload?.data ? result : null;
            if (state.selectedRankCalendarDetail) {
                const {
                    name,
                    dayRanks = [],
                    planRankDurations = [],
                } = state.selectedRankCalendarDetail;
                state.rankCalendarForm = {
                    name,
                    monday:
                        getDayRank(todayDayjs, dayRanks, CalendarDay.MONDAY) || TllRank.A,
                    tuesday:
                        getDayRank(todayDayjs, dayRanks, CalendarDay.TUESDAY) ||
                        TllRank.A,
                    wednesday:
                        getDayRank(todayDayjs, dayRanks, CalendarDay.WEDNESDAY) ||
                        TllRank.A,
                    thursday:
                        getDayRank(todayDayjs, dayRanks, CalendarDay.THURSDAY) ||
                        TllRank.A,
                    friday:
                        getDayRank(todayDayjs, dayRanks, CalendarDay.FRIDAY) || TllRank.A,
                    saturday:
                        getDayRank(todayDayjs, dayRanks, CalendarDay.SATURDAY) ||
                        TllRank.A,
                    sunday:
                        getDayRank(todayDayjs, dayRanks, CalendarDay.SUNDAY) || TllRank.A,
                    planRankDurations,
                };
            }
        });
        builder.addCase(getRankCalendarListForDropDown.fulfilled, (state, action) => {
            state.rankCalendarListForDropDown = action.payload?.data?.items || [];
        });
        builder.addCase(createRankCalendar.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(createRankCalendar.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(updateRankCalendar.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(updateRankCalendar.fulfilled, (state, action) => {
            state.formBusy = false;
        });
    },
});

export const {
    setRankCalendarListQuery,
    setKeyword,
    setShowForm,
    setIsUpdating,
    setRankCalendarForm,
} = rankCalendarSlice.actions;

export const rankCalendarListSelector = (state: AppState) => {
    return state.rankCalendar.rankCalendarList;
};

export const showLoadingListSelector = (state: AppState) => {
    return state.rankCalendar.showLoadingList;
};

export const totalPageSelector = (state: AppState) => {
    const { totalRankCalendar, rankCalendarListQuery } = state.rankCalendar;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = rankCalendarListQuery;
    return Math.ceil(totalRankCalendar / limit);
};

export const rankCalendarListQuerySelector = (state: AppState) => {
    return state.rankCalendar.rankCalendarListQuery;
};

export const totalRankCalendarSelector = (state: AppState) => {
    return state.rankCalendar.totalRankCalendar;
};

export const selectedRankCalendarDetailSelector = (state: AppState) => {
    return state.rankCalendar.selectedRankCalendarDetail;
};

export const rankCalendarFormSelector = (state: AppState) => {
    return state.rankCalendar.rankCalendarForm;
};

export const rankCalendarListForDropDownSelector = (state: AppState) => {
    return state.rankCalendar.rankCalendarListForDropDown;
};

export const showFormSelector = (state: AppState) => {
    return state.rankCalendar.showForm;
};

export const formBusySelector = (state: AppState) => {
    return state.rankCalendar.formBusy;
};

export const isUpdatingSelector = (state: AppState) => {
    return state.rankCalendar.isUpdating;
};

export default rankCalendarSlice.reducer;
