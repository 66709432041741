import { Row, Col, Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { showConfirm } from '~common/notification';
import { InputText, RightDrawerLayout } from '~components';
import {
    createPaymentMethod,
    formBusySelector,
    getPaymentMethodList,
    isShowFormSelector,
    selectedPaymentMethodSelector,
    setIsShowForm,
    setSelectedPaymentMethod,
    updatePaymentMethod,
} from '~features/payment-method/reducers/paymentMethod.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './PaymentMethodForm.scss';
import { getPaymentMethodFormId } from '../../helpers';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { paymentMethodSchema } from '~features/payment-method/schema';

import {
    IPaymentMethod,
    IPaymentMethodFormBody,
} from '~features/payment-method/interfaces';

import { ErrorMessageType } from '~common/constants';
import { useEscape } from '~common/useHooks';
import { useEffect } from 'react';

function PaymentMethodForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowForm = useAppSelector(isShowFormSelector);
    const selectedPaymentMethod = useAppSelector(selectedPaymentMethodSelector);
    const { control, reset, handleSubmit, setError, formState } = useForm({
        resolver: yupResolver(paymentMethodSchema),
    });
    const defaultPaymentMethod = {
        name: null,
    } as unknown as IPaymentMethod;
    const isUpdateForm = selectedPaymentMethod?.id ? true : false;

    // store
    const formBusy = useAppSelector(formBusySelector);

    const formTitle = isUpdateForm
        ? t('paymentMethod.form.updateForm.title')
        : t('paymentMethod.form.createForm.title');

    const closeDialog = (reload?: boolean) => {
        dispatch(setIsShowForm(false));
        dispatch(setSelectedPaymentMethod({}));
        reset(defaultPaymentMethod);
        if (reload) {
            dispatch(getPaymentMethodList());
        }
    };
    useEffect(() => {
        reset(selectedPaymentMethod);
    }, [selectedPaymentMethod]);

    const _showConfirm = () => {
        showConfirm({
            title: t('paymentMethod.form.confirm.cancelTitle'),
            cancelText: t('common.buttonCancelText'),
            okText: t('paymentMethod.form.confirm.buttonDeleteText'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    const _createPaymentMethod = async (body: IPaymentMethodFormBody) => {
        const response = await dispatch(createPaymentMethod(body));

        if (createPaymentMethod.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('paymentMethod.form.createForm.create.success'),
                });
                closeDialog(true);
                return;
            }
            notification.error({
                message: t('paymentMethod.form.createForm.create.failed'),
                description: response.payload?.message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                if (error.key === 'name') {
                    setError(
                        error.key,
                        { type: ErrorMessageType.MANUAL, message: error?.message },
                        { shouldFocus: true },
                    );
                }
            });
        }
    };
    const _updatePaymentMethod = async (id: number, body: IPaymentMethodFormBody) => {
        const response = await dispatch(updatePaymentMethod({ id, body }));
        if (updatePaymentMethod.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('paymentMethod.form.updateForm.update.success'),
                });
                closeDialog(true);
                return;
            }
            notification.error({
                message: t('paymentMethod.form.updateForm.update.failed'),
                description: response.payload?.message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                if (error.key === 'name') {
                    setError(
                        error.key,
                        { type: ErrorMessageType.MANUAL, message: error?.message },
                        { shouldFocus: true },
                    );
                }
            });
        }
    };

    const onSubmit = () => {
        handleSubmit((values) => {
            const { name } = values;
            const paymentMethodFormBody: IPaymentMethodFormBody = { name };

            if (isUpdateForm) {
                _updatePaymentMethod(selectedPaymentMethod.id, paymentMethodFormBody);
            } else {
                _createPaymentMethod(paymentMethodFormBody);
            }
        })();
    };

    return (
        <RightDrawerLayout
            open={isShowForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={formTitle}
            cancelText={t('common.buttonCancelText')}
            submitText={
                isUpdateForm
                    ? t('paymentMethod.form.updateForm.updateTextButton')
                    : t('paymentMethod.form.createForm.createTextButton')
            }
            busy={formBusy}
            loading={formBusy}
            destroyOnClose={true}
            disabledSaveButton={!formState.isValid}
            submitId={getPaymentMethodFormId('btn-submit')}
        >
            <Form
                layout="vertical"
                autoComplete="off"
                scrollToFirstError
                className="payment-method-form-wrapper"
            >
                {isUpdateForm ? (
                    <Row gutter={10} key="update-form">
                        <Col span={4}>
                            <InputText
                                label={t('paymentMethod.form.id')}
                                name="autoGeneratedCode"
                                id={getPaymentMethodFormId('id')}
                                control={control}
                                defaultValue={selectedPaymentMethod?.autoGeneratedCode}
                                disabled={true}
                            />
                        </Col>

                        <Col span={20}>
                            <InputText
                                label={t('paymentMethod.form.name')}
                                name="name"
                                id={getPaymentMethodFormId('name')}
                                control={control}
                                defaultValue={selectedPaymentMethod?.name}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Row key="create-form">
                        <Col span={24}>
                            <InputText
                                label={t('paymentMethod.form.name')}
                                name="name"
                                id={getPaymentMethodFormId('name')}
                                control={control}
                                required={true}
                            />
                        </Col>
                    </Row>
                )}
            </Form>
        </RightDrawerLayout>
    );
}

export default PaymentMethodForm;
