import React, { MutableRefObject, useCallback, useEffect, WheelEvent } from 'react';
import {
    bookingFilterSelector,
    facilityFilterSelector,
    setIsDragging,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import _ from 'lodash';
import { BookingCard } from './BookingCard/BookingCard';
import { useMitt } from '~plugins/mitt';
import { Dayjs, parseDate } from '~plugins/dayjs';
import { EmitterGlobalEvent, MINUTES_PER_HOUR } from '~common/constants';
import { calculatePositionLeft, calculateWidth } from '~common/commonFunctions';
import {
    CELL_HEADER_HEIGHT,
    CELL_HEIGHT,
    CELL_WIDTH,
    HEADER_HEIGHT_DAY_VIEW,
} from '~features/facility-booking/constants';
import { IFacilityBookingScheduleItem } from '~features/facility-booking/interfaces';
import { VariableSizeGrid } from 'react-window';

type Props = {
    onMouseUp: () => void;
    cellWidth: number;
    startTime: Dayjs;
    endTime: Dayjs;
    onWheel?: (e: WheelEvent<HTMLDivElement>) => void;
    gridRef: MutableRefObject<VariableSizeGrid>;
    outerRef: MutableRefObject<HTMLElement | undefined>;
    gridViewContentHeightRef?: MutableRefObject<number | undefined>;
};

export const BookingList = ({
    onMouseUp,
    cellWidth,
    startTime,
    endTime,
    onWheel,
    gridRef,
    outerRef,
    gridViewContentHeightRef,
}: Props) => {
    const bookingList = useAppSelector(bookingFilterSelector);
    const facilityList = useAppSelector(facilityFilterSelector);
    const dispatch = useAppDispatch();
    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    const { emitter } = useMitt();
    const [visibleIndexes, setVisibleIndexes] = React.useState<{
        visibleColumnStartIndex: number;
        visibleColumnStopIndex: number;
        visibleRowStartIndex: number;
        visibleRowStopIndex: number;
    }>({
        visibleColumnStartIndex: 0,
        visibleColumnStopIndex: 0,
        visibleRowStartIndex: 0,
        visibleRowStopIndex: 0,
    });

    useEffect(() => {
        emitter.on(EmitterGlobalEvent.SCROLL, (scroll: { x: number; y: number }) => {
            setPosition(scroll);
        });

        emitter.on(EmitterGlobalEvent.SCHEDULE_SCROLL, (options) => {
            setVisibleIndexes(options);
        });

        return () => {
            emitter.off(EmitterGlobalEvent.SCROLL);
            emitter.off(EmitterGlobalEvent.SCHEDULE_SCROLL);
        };
    }, []);

    const _calculateLeft = useCallback(
        (booking: IFacilityBookingScheduleItem) => {
            const _left =
                calculatePositionLeft({
                    from: startTime,
                    to: parseDate(booking.checkInDateTime),
                    widthOfColumn: cellWidth / MINUTES_PER_HOUR,
                }) + CELL_WIDTH;
            return _left;
        },
        [startTime, cellWidth],
    );

    const _calculateTop = useCallback(
        (booking: IFacilityBookingScheduleItem) => {
            const index = facilityList.findIndex(
                (item) => item.id === booking.facilityId && item.parentId,
            );
            if (index === -1) return -HEADER_HEIGHT_DAY_VIEW;
            let _top = HEADER_HEIGHT_DAY_VIEW + 8;
            if (!index) {
                _top += facilityList[0].parentId ? CELL_HEIGHT : CELL_HEADER_HEIGHT;
                return _top;
            }
            for (let i = 0; i < index; i++) {
                _top += facilityList[i].parentId ? CELL_HEIGHT : CELL_HEADER_HEIGHT;
            }
            return _top;
        },
        [facilityList],
    );

    const _calculateWidth = useCallback(
        (booking: IFacilityBookingScheduleItem) => {
            return calculateWidth({
                from: parseDate(booking.checkInDateTime)?.fmYYYYMMDDHHmm('-'),
                to: parseDate(booking.checkOutDateTime)?.fmYYYYMMDDHHmm('-'),
                widthOfColumn: cellWidth / MINUTES_PER_HOUR,
            });
        },
        [cellWidth],
    );

    const checkValidBooking = useCallback(
        (option: { left: number; endLeft: number; top: number }) => {
            const { left, endLeft, top } = option;
            const leftPosition = (visibleIndexes.visibleColumnStartIndex - 3) * cellWidth;
            const rightPosition = (visibleIndexes.visibleColumnStopIndex + 3) * cellWidth;
            const topPosition = visibleIndexes.visibleRowStartIndex - 3 * CELL_HEIGHT;
            const bottomPosition = visibleIndexes.visibleRowStopIndex + 3 * CELL_HEIGHT;
            const validLeft =
                (leftPosition <= endLeft && endLeft <= rightPosition) ||
                (leftPosition <= left && left <= rightPosition) ||
                (left <= rightPosition && endLeft >= rightPosition);
            const validTop =
                topPosition * CELL_HEIGHT <= top && top <= bottomPosition * CELL_HEIGHT;
            return validLeft && validTop;
        },
        [visibleIndexes, cellWidth],
    );

    const renderBookingList = () => {
        const list: React.ReactNode[] = [];
        _.forEach(bookingList, (facilityBookings) => {
            _.forEach(facilityBookings, (bookings) => {
                _.forEach(bookings, (booking, index) => {
                    const left = _calculateLeft(booking);
                    const top = _calculateTop(booking);
                    const width = _calculateWidth(booking);
                    const _endLeft = left + width;
                    const isValid = checkValidBooking({ left, endLeft: _endLeft, top });
                    if (!isValid) return;
                    list.push(
                        <BookingCard
                            booking={booking}
                            key={`${index}-${booking.id}`}
                            onMouseDown={() => {
                                dispatch(setIsDragging(true));
                            }}
                            endDrag={() => {
                                onMouseUp();
                            }}
                            time={{
                                start: startTime,
                                end: endTime,
                            }}
                            onMouseEnter={() => {
                                emitter.emit(EmitterGlobalEvent.TRIGGER_SCROLL, false);
                            }}
                            onWheel={onWheel}
                            facilityList={facilityList}
                            left={left - position.x}
                            width={width}
                            top={top - position.y}
                            gridRef={gridRef}
                            outerRef={outerRef}
                            gridViewContentHeightRef={gridViewContentHeightRef}
                        />,
                    );
                });
            });
        });
        return list;
    };

    return <>{renderBookingList()}</>;
};
