const saleItemList = {
    title: '売上科目設定',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        saleItem: '売上科目設定',
    },
    saleItemColumn: {
        num: '#',
        id: 'ID',
        name: '科目',
        price: '金額',
        showInBooking: '手配品リスト表示',
        tax: '消費税区分',
    },
    createBtn: '新規科目を登録',
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '科目',
        taxName: '消費税区分',
    },
    showInBooking: {
        hidden: '非表示',
        display: '表示',
    },
    message: {
        deleteSuccess: 'セール項目が削除されました',
        canNotEditDefaultData: `デフォルトデータは変更できません`,
        canNotDeleteDefaultData: `デフォルトデータは削除できません`,
    },
    modalConfirmDeletion: {
        cancelBtn: 'キャンセル',
        deleteBtn: '削除',
    },
    exportColumns: {
        autoGeneratedCode: 'ID',
        name: '科目',
        price: '金額',
        tax: '消費税区分',
        isShowInBooking: '手配品リスト表示',
    },
};

export default saleItemList;
