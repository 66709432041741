import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Button from 'antd/es/button';
import { useState } from 'react';

interface ITableItem {
    name: string;
    amount: string | number;
}

interface ITransactionDetail {
    title: string;
    transactions: ITableItem[];
    showAll: boolean;
}

function TransactionDetail({ title, transactions, showAll }: ITransactionDetail) {
    const [isOpen, setIsOpen] = useState(false);
    const priceStyle = (num: number | string) => {
        if (Number(num) > 0) return 'payment-style';
        return 'billing-style';
    };

    const sum = (arr: any) =>
        arr.reduce((accumulator: any, object: { name: string; amount: number }) => {
            return accumulator + object.amount;
        }, 0);

    return (
        <div>
            <div className="clickable" onClick={() => setIsOpen(!isOpen)}>
                <p className={priceStyle(sum(transactions))}>
                    {title}: ¥{Math.abs(Number(sum(transactions)))}
                    {isOpen ? (
                        <UpOutlined className="icon" />
                    ) : (
                        <DownOutlined className="icon" />
                    )}
                </p>
            </div>

            {transactions?.map((transaction, index) => (
                <div
                    key={index}
                    className={`hotel-smart-table ${
                        isOpen ? 'open' : 'close'
                    } ${priceStyle(transaction.amount)}`}
                >
                    <p>{transaction.name}</p>
                    <p>¥{Math.abs(Number(transaction.amount))}</p>
                </div>
            ))}
        </div>
    );
}

export default TransactionDetail;
