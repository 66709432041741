import { Button, Popconfirm, Tooltip } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { showConfirm } from '~common/notification';
import { FacilityBookingStatus } from '~features/facility-booking/constants';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import {
    roomBookingStateSelector,
    setIsShowPaymentModal,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useAppDispatch } from '~hooks';
import './ReceiptBottomButtonGroup.scss';

type Props = {
    isEditMode: boolean;
    resetReceipt: (isCancel?: boolean) => void;
    saveReceipt: () => void;
    cancelEdit: () => void;
};
function ReceiptBottomButtonGroup(props: Props) {
    const { isEditMode, resetReceipt, saveReceipt, cancelEdit } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { roomBookingReceipt, isShowUpdateReceiptLoading } = useSelector(
        roomBookingStateSelector,
    );

    const showCancelConfirm = () => {
        showConfirm({
            title: t('roomBooking.detail.cancelEditReceiptTitle'),
            onOk() {
                cancelEdit();
            },
        });
    };

    const isCanUpdateBooking = useMemo(() => {
        const isCanEditRoomBooking =
            roomBookingReceipt?.roomBooking?.roomBookingItems?.some(
                (roomBookingItem) =>
                    roomBookingItem.bookingStatus ===
                        RoomBookingItemBookingStatus.NOT_ARRIVED ||
                    roomBookingItem.bookingStatus ===
                        RoomBookingItemBookingStatus.CHECKED_IN,
            );

        const isCanEditFacilityBooking = roomBookingReceipt?.facilityBookings?.some(
            (booking) =>
                booking.status === FacilityBookingStatus.RESERVED ||
                booking.status === FacilityBookingStatus.STARTED,
        );
        return isCanEditRoomBooking || isCanEditFacilityBooking;
    }, [roomBookingReceipt]);

    const showPaymentModal = () => {
        dispatch(setIsShowPaymentModal(true));
    };

    return (
        <div className="bottom-button-group">
            {isEditMode ? (
                <>
                    <Button
                        onClick={showCancelConfirm}
                        disabled={isShowUpdateReceiptLoading}
                    >
                        {t(`common.buttonCancelText`)}
                    </Button>
                    <Popconfirm
                        placement="topRight"
                        title={t('roomBooking.detail.receipt.discardEdit')}
                        onConfirm={() => {
                            resetReceipt();
                        }}
                        okText={t(`roomBooking.detail.receipt.button.yes`)}
                        cancelText={t(`roomBooking.detail.receipt.button.no`)}
                    >
                        <Button disabled={isShowUpdateReceiptLoading}>
                            {t(`roomBooking.detail.receipt.button.split`)}
                        </Button>
                    </Popconfirm>
                    <Tooltip
                        title={
                            !isCanUpdateBooking
                                ? t(`roomBooking.detail.receipt.bookingCheckedOut`)
                                : ''
                        }
                        placement={'left'}
                    >
                        <Button
                            type="primary"
                            onClick={saveReceipt}
                            loading={isShowUpdateReceiptLoading}
                            disabled={!isCanUpdateBooking}
                        >
                            {t(`common.buttonSaveText`)}
                        </Button>
                    </Tooltip>
                </>
            ) : (
                <>
                    <Button onClick={showPaymentModal}>
                        {t(`roomBooking.detail.receipt.button.calculate`)}
                    </Button>
                </>
            )}
        </div>
    );
}

export default ReceiptBottomButtonGroup;
