import { IBodyResponse, IGetListResponse } from '~common/interfaces';
import axiosService from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import {
    IChildrenType,
    IChildrenTypeBulkDeleteItem,
    IChildrenTypeFormBody,
    IChildrenTypeGetListQuery,
} from '../interfaces';

class ChildrenTypeService extends ApiService {
    getDropdown() {
        return this._get(`${this.baseUrl}/dropdown`) as Promise<
            IBodyResponse<{ items: IChildrenType[] }>
        >;
    }

    getList(queryString: IChildrenTypeGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IChildrenType>>
        >;
    }

    create(childrenType: IChildrenTypeFormBody) {
        return this._post(this.baseUrl, childrenType) as Promise<IBodyResponse<boolean>>;
    }

    update(id: number, body: IChildrenTypeFormBody) {
        return this._patch(`${this.baseUrl}/${id}`, body) as Promise<
            IBodyResponse<IChildrenType>
        >;
    }

    bulkDelete(bulkDeleteBody: IChildrenTypeBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }
}

export const childrenTypeService = new ChildrenTypeService(
    { baseUrl: '/children-type' },
    axiosService,
);
