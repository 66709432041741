import { OrderBy } from '~features/indicator/constants';
import {
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_FIRST_PAGE,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
import i18next from '~plugins/i18next/i18n';

export const breadcrumbs = () => {
    return [
        {
            text: i18next.t('closingBooking.page.breadcrumbs.home'),
        },
        {
            text: i18next.t('closingBooking.page.breadcrumbs.tightenWork'),
        },
    ];
};

export enum ClosingBookingStatus {
    ALL = 'all',
    NOT_CHECKED_IN = 'not_checked_in',
    NOT_CHECKED_OUT = 'not_checked_out',
}

export enum ClosingBookingTabPane {
    ROOM_CLOSING = 'room_closing',
    FACILITY_CLOSING = 'facility_closing',
}

export const closingBookingListQueryDefault = {
    orderBy: OrderBy.ID,
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const CLOSING_BOOKING_MODAL_TIMEOUT = 60000;
export const CLOSING_BOOKING_ITEMS_LIMIT_TO_ENABLE_SCROLL = 10;
