import { Layout, Spin } from 'antd';
import { cloneDeep } from 'lodash';
import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { useCallback, useEffect, useState } from 'react';
import { PrintingFonts, PrintingOrientation } from '~common/constants';
import { PdfPrintingModal } from '~components';
import { showConfirm } from '~common/notification';
import {
    printRoomCleaningListSelector,
    setIsShowRoomCleaningPrintingModal,
} from '~features/room-cleaning/reducers/print-room-cleaning.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { generateRoomCleaningPdf } from './generateRoomCleaningPdfContent';
import './RoomCleaningPrintModal.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
    isShowRoomCleaningPrintingModal: boolean;
}

export default function RoomCleaningPrintModal(props: IProps) {
    const { isShowRoomCleaningPrintingModal } = props;
    const printRoomCleaningList = useAppSelector(printRoomCleaningListSelector);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [url, setUrl] = useState<string>();
    const [pdf, setPdf] = useState<TCreatedPdf>();
    const [orientation, setOrientation] = useState<PrintingOrientation>(
        PrintingOrientation.LANDSCAPE,
    );
    const [totalPage, setTotalPage] = useState(0);

    const onPrint = useCallback(() => {
        cloneDeep(pdf)?.print();
    }, [pdf]);

    const onChangeOrientation = (orientation: PrintingOrientation) => {
        setUrl('');
        setOrientation(orientation);
    };

    const createPdf = () => {
        setUrl('');
        pdfMake.fonts = PrintingFonts;
        setPdf(
            pdfMake.createPdf(
                generateRoomCleaningPdf(printRoomCleaningList, {
                    orientation,
                    setTotalPage,
                }),
            ),
        );
    };

    useEffect(() => {
        createPdf();
    }, [printRoomCleaningList, orientation]);

    useEffect(() => {
        cloneDeep(pdf)?.getDataUrl((dataUrl: string) => {
            setUrl(dataUrl);
        });
    }, [pdf]);

    const onClose = () => {
        dispatch(setIsShowRoomCleaningPrintingModal(false));
    };
    const showConfirmCancel = () => {
        showConfirm({
            title: t('roomCleaning.list.confirm.cancelTitle'),
            onOk() {
                onClose();
            },
        });
    };
    return (
        <PdfPrintingModal
            totalPage={totalPage}
            onPrint={onPrint}
            onClose={showConfirmCancel}
            onChangeOrientation={onChangeOrientation}
            defaultOrientation={orientation}
            isShowPdfPrinting={isShowRoomCleaningPrintingModal}
            isShowControlBar={false}
        >
            <Layout className="room-cleaning-printing-layout">
                <Layout.Content className="room-cleaning-printing-content">
                    {url ? (
                        <iframe className="pdf-view" src={`${url}#toolbar=0`} />
                    ) : (
                        <Spin />
                    )}
                </Layout.Content>
            </Layout>
        </PdfPrintingModal>
    );
}
