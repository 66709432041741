import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { InputNumber, InputText, InputPassword, SingleSelect } from '~components';

import './SmtpSettingForm.scss';
import { ConnectionSecurity, SMPT_Encryption } from '~features/smtp-setting/constants';

interface IProps {
    control: any;
}

function SmtpSettingForm({ control }: IProps) {
    const { t } = useTranslation();
    return (
        <div className="smtp-setting-form-wrapper">
            <Form layout="vertical" className="smtp-setting-form">
                <Row>
                    <Col span={11}>
                        <InputText
                            label={t('smtpSetting.smtpForm.serverName')}
                            placeholder={t('smtpSetting.smtpForm.serverNameInput')}
                            name={`serverName`}
                            control={control}
                            required
                        />
                    </Col>
                    <Col span={11}>
                        <InputPassword
                            label={t('smtpSetting.smtpForm.password')}
                            placeholder={''}
                            name={`password`}
                            control={control}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <InputNumber
                            label={t('smtpSetting.smtpForm.smtpServer')}
                            placeholder={t('smtpSetting.smtpForm.smtpServerInput')}
                            name={`smtpServer`}
                            control={control}
                            maxLength={20}
                        />
                    </Col>
                    <Col span={11}>
                        <InputNumber
                            label={t('smtpSetting.smtpForm.smtpPort')}
                            placeholder={''}
                            name={`smtpPort`}
                            maxLength={6}
                            control={control}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <SingleSelect
                            label={t('smtpSetting.smtpForm.smtpEncryption')}
                            placeholder={''}
                            name={'smtpEncryption'}
                            defaultValue={SMPT_Encryption.NO}
                            control={control}
                            options={Object.values(SMPT_Encryption).map((val) => ({
                                label: t(
                                    `smtpSetting.smtpForm.smtpEncryptionOption.` + val,
                                ),
                                value: val,
                            }))}
                            required
                        />
                    </Col>
                    <Col span={11}>
                        <SingleSelect
                            label={t('smtpSetting.smtpForm.connectionSecurity')}
                            placeholder={''}
                            name={'connectionSecurity'}
                            control={control}
                            options={Object.values(ConnectionSecurity).map((val) => ({
                                label: t(
                                    `smtpSetting.smtpForm.connectionSecurityOption.` +
                                        val,
                                ),
                                value: val,
                            }))}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <InputText
                            label={t('smtpSetting.smtpForm.userName')}
                            placeholder={t('smtpSetting.smtpForm.userNameInput')}
                            name={`userName`}
                            control={control}
                            required
                        />
                    </Col>
                    <Col span={11}>
                        <></>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default SmtpSettingForm;
