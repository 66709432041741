import { message, Spin } from 'antd';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { GridOnItemsRenderedProps, VariableSizeGrid } from 'react-window';
import { AutoSizer, GridView, ICell } from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import './InventoryGrid.scss';
import { IDirection, IScrollStatus } from '~common/interfaces';
import { useHandleScroll } from '~common/useHooks';
import { CellItem } from './CellItem';
import { CellHeader } from './Header';
import { RowHeader } from './RowHeader';
import { parseDate } from '~plugins/dayjs';
import {
    INVENTORY_CELL_HEIGHT,
    HEADER_HEIGHT,
    MIN_WIDTH_CELL,
    CELL_HEIGHT,
    INVENTORY_ROW_HEADER_WIDTH,
    HEADER_TOP_PADDING,
    MAX_HEIGHT_GRID_VIEW,
    MIN_HEIGHT_GRID_VIEW,
} from '~features/inventory/constants';
import {
    getInventoryListData,
    headerColumnsSelector,
    inventoryListStateSelector,
    updateInventoryGridData,
} from '~features/inventory/reducers/inventory.reducer';
import { SubHeader } from './SubHeader';
import { useTranslation } from 'react-i18next';
import { IScrollGridPosition } from '~features/inventory/interfaces';
import TimeControl from './TimeControl';

type Props = {
    height: number;
};
export const InventoryGrid = (props: Props) => {
    const { height } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const outerRef = useRef<HTMLElement>();
    const gridRef = useRef<VariableSizeGrid>() as MutableRefObject<VariableSizeGrid>;
    const containerRef = useRef<HTMLDivElement>(null);
    const currentScrollGridData = useRef<IScrollGridPosition>({
        scrollLeft: 0,
        scrollTop: 0,
    });

    const columns = useAppSelector(headerColumnsSelector);
    const {
        currentDate,
        inventoryList,
        inventoryGridData,
        showLoading,
        isEditMode,
        selectedDate,
        errorGridData,
    } = useAppSelector(inventoryListStateSelector);
    const [messageApi, contextHolder] = message.useMessage();

    const errorKeys = useRef<string[]>([]);

    // handle scroll
    const onStopScroll = useCallback((direction: IDirection, status: IScrollStatus) => {},
    []);

    const { onMouseDown, onMouseUp, onMouseMove, onMouseLeave, elementRef, onWheel } =
        useHandleScroll({ onStopScroll });

    useEffect(() => {
        dispatch(getInventoryListData());
    }, []);

    // end handle select day for create booking

    const renderHeader = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            return <CellHeader item={columns[columnIndex]} style={style} />;
        },
        [columns],
    );

    const renderSubHeader = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            return <SubHeader currentDate={columns[columnIndex].id} style={style} />;
        },
        [columns],
    );

    const renderCell = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            const item = inventoryGridData[rowIndex];
            const currentDate = columns[columnIndex].id;
            const isError =
                errorGridData[`room_type_${item.roomType?.id}`]?.includes(currentDate);
            return (
                <CellItem
                    item={item}
                    currentDate={currentDate}
                    rowIndex={rowIndex}
                    isError={isError}
                    style={style}
                    isEditMode={isEditMode}
                    changeStoppingRoom={changeStoppingRoom}
                />
            );
        },
        [columns, inventoryGridData, isEditMode, errorGridData],
    );

    const renderRowHeader = useCallback(
        ({ rowIndex, style }: ICell) => {
            return <RowHeader item={inventoryGridData[rowIndex]} style={style} />;
        },
        [inventoryList],
    );

    // handle load more item

    const onItemsRendered = useCallback(
        ({ visibleColumnStartIndex }: GridOnItemsRenderedProps) => {
            if (!elementRef.current) {
                elementRef.current = outerRef.current;
            }
        },
        [columns],
    );

    const scrollTable = (index: number) => {
        setTimeout(() => {
            gridRef.current?.scrollToItem({
                columnIndex: index,
                align: 'start',
            });
        }, 0);
    };

    useEffect(() => {
        const index = columns.findIndex((item) => item.id === selectedDate);
        scrollTable(index);
    }, [selectedDate, columns]);

    useEffect(() => {
        scrollTable(0);
        currentScrollGridData.current = {
            scrollLeft: 0,
            scrollTop: 0,
        };
    }, [showLoading]);

    const scrollGrid = ({ scrollLeft, scrollTop }: IScrollGridPosition) => {
        currentScrollGridData.current = { scrollLeft, scrollTop };
    };

    useEffect(() => {
        setTimeout(() => {
            gridRef.current?.scrollTo({
                scrollLeft: currentScrollGridData.current?.scrollLeft || 0,
                scrollTop: currentScrollGridData.current?.scrollTop || 0,
            });
        }, 0);
    }, [inventoryGridData]);

    useEffect(() => {
        if (isEditMode) {
            errorKeys.current = [];
        }
    }, [isEditMode]);

    const changeStoppingRoom = (
        value: number | string | null,
        date: string,
        rowIndex: number,
        id?: number,
    ) => {
        dispatch(updateInventoryGridData({ value, date, rowIndex, id }));
        const formKey = `${id}_${date}`;
        const totalAvailableRooms =
            inventoryGridData[rowIndex]?.day?.[date]?.totalAvailableRooms;
        const availableRooms = Number(totalAvailableRooms) - Number(value);

        if (!value && value !== 0) {
            messageApi.open({
                type: 'error',
                content: t('inventory.list.message.stoppingRoomEmpty'),
            });
            return false;
        }
        if (availableRooms < 0) {
            if (!errorKeys.current?.includes(formKey)) {
                errorKeys.current?.push(formKey);
                messageApi.open({
                    type: 'error',
                    content: t('inventory.list.message.updateStoppingFaild'),
                });
            }
            return;
        }
        const _errorKeys = errorKeys.current.filter((key) => {
            return key !== formKey;
        });
        errorKeys.current = _errorKeys;
    };

    const heightGrid = useMemo(() => {
        const _height = height - HEADER_TOP_PADDING;
        if (_height > MAX_HEIGHT_GRID_VIEW) return MAX_HEIGHT_GRID_VIEW;
        if (_height < MIN_HEIGHT_GRID_VIEW) return MIN_HEIGHT_GRID_VIEW;
        return _height;
    }, [height]);

    return (
        <div
            className="inventory-grid-wrapper"
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
            onWheel={onWheel}
            ref={containerRef}
        >
            {contextHolder}
            <Spin spinning={showLoading} style={{ minHeight: height }}>
                <AutoSizer disableHeight>
                    {({ width }) => {
                        let cellWidth = columns.length
                            ? (width - INVENTORY_ROW_HEADER_WIDTH) / columns.length
                            : MIN_WIDTH_CELL;
                        if (cellWidth < MIN_WIDTH_CELL) {
                            cellWidth = MIN_WIDTH_CELL;
                        }

                        return (
                            <GridView
                                style={{
                                    fontSize: 14,
                                }}
                                outerRef={outerRef}
                                gridRef={gridRef}
                                className={'schedule-table'}
                                height={heightGrid}
                                width={width}
                                columns={columns}
                                recordset={inventoryList}
                                rowHeaderWidth={INVENTORY_ROW_HEADER_WIDTH}
                                columnHeaderWidth={cellWidth}
                                columnHeaderHeight={HEADER_HEIGHT}
                                columnSubHeaderHeight={CELL_HEIGHT}
                                columnHeaderRenderer={renderHeader}
                                rowHeaderRenderer={renderRowHeader}
                                cellRenderer={renderCell}
                                getRowContentHeight={() => INVENTORY_CELL_HEIGHT}
                                bodyProps={{
                                    onItemsRendered,
                                }}
                                columnSubHeaderRenderer={renderSubHeader}
                                showSubHeader={true}
                                onScroll={scrollGrid}
                            />
                        );
                    }}
                </AutoSizer>
                <TimeControl />
                <div className="year-title">{parseDate(currentDate)?.fmYYYYMMJp()}</div>
            </Spin>
        </div>
    );
};
