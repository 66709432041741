import { Button } from 'antd';
import { InputSearch } from '~components';
import { PlusOutlined } from '@ant-design/icons';
import './FilterForm.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '~hooks';
import { setKeyword } from '~features/facility/facility.reducer';
import { useNavigate } from 'react-router-dom';
import SelectField from './SelectField/SelectField';
import { GA_KEYWORD_CONTENT, INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { trackSearchKeyword } from '~plugins/analytics';

function FilterForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.FACILITY);
    };

    const createFacility = () => {
        navigate('/front-settings/facility/create-facility');
    };

    return (
        <div className="facility-filter-form-wrapper">
            <InputSearch
                onSearch={onSearch}
                addonBefore={SelectField()}
                maxLength={INPUT_TEXT_MAX_LENGTH}
                max={255}
                placeholder={t('common.searchPlaceholder')}
            />

            <div className="btn-group">
                <Button type="primary" className="btn-room" onClick={createFacility}>
                    <PlusOutlined />
                    <span className="btn-text">
                        {t('facility.list.filterForm.buttons.create')}
                    </span>
                </Button>
            </div>
        </div>
    );
}

export default FilterForm;
