import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Checkbox, InputNumber, InputPrice, SingleSelect } from '~components';
import { settingSchema } from '~features/sale-item/schema';
import { useForm } from '~plugins/hook-form';
import {
    setNewSaleItemList,
    taxListDropdownSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useEffect } from 'react';
import { ISaleItemFormItem } from '~features/sale-item/interfaces';
import { getSaleItemFormId } from '~features/sale-item/helper';

interface IProps {
    currentSaleItemList: ISaleItemFormItem[];
}

function CreateSaleItemsFormSetting({ currentSaleItemList }: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const taxList = useAppSelector(taxListDropdownSelector);
    const { control, handleSubmit, setValue, formState } = useForm({
        resolver: yupResolver(settingSchema),
    });

    useEffect(() => {
        const defaultTax = taxList.filter((tax) => {
            return tax.label === t('saleItem.create.formSetting.defaultTax');
        });
        if (defaultTax.length !== 0) {
            setValue('taxId', defaultTax?.[0]?.value);
        } else {
            setValue('taxId', taxList?.[0]?.value);
        }
    }, [taxList]);

    const onClickButtonGenerate = () => {
        handleSubmit(async (data) => {
            const generatedSaleItemList = [];
            for (let i = 0; i < +data.quantity; i += 1) {
                generatedSaleItemList.push({
                    feId: `${Date.now()}_${i}`,
                    price: +data.saleItemPrice,
                    taxId: data.taxId,
                    isShowInBooking: data.isShowInBooking,
                });
            }
            dispatch(setNewSaleItemList(generatedSaleItemList));
        })();
    };
    return (
        <div className="create-sale-items-form-setting">
            <Form layout="vertical">
                <Row gutter={16}>
                    <Col span={10}>
                        <InputNumber
                            label={t('saleItem.create.formSetting.quantityLabel')}
                            placeholder={t(
                                'saleItem.create.formSetting.quantityPlaceholder',
                            )}
                            name="quantity"
                            control={control}
                            required={true}
                            isShowIconArrow
                            min={1}
                            id={getSaleItemFormId('quantity')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <InputPrice
                            label={t('saleItem.create.formSetting.priceLabel')}
                            name="saleItemPrice"
                            control={control}
                            required
                            id={getSaleItemFormId('saleItemPrice')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <SingleSelect
                            label={t('saleItem.create.formSetting.taxLabel')}
                            name="taxId"
                            control={control}
                            showSearch={false}
                            options={taxList}
                            id={getSaleItemFormId('taxId')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {t('saleItem.create.formSetting.isShowInBookingTitle')}
                    </Col>
                    <Col>
                        <Checkbox
                            label={t('saleItem.create.formSetting.isShowInBookingLabel')}
                            name="isShowInBooking"
                            control={control}
                            id={getSaleItemFormId('isShowInBooking')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button
                            id={getSaleItemFormId('btn-generate')}
                            block
                            disabled={!formState.isValid}
                            onClick={onClickButtonGenerate}
                        >
                            <PlusCircleOutlined />
                            <span>{t('saleItem.create.formSetting.generateBtn')}</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
export default CreateSaleItemsFormSetting;
