export const paymentMethodFormEn = {
    title: 'Edit payment method',
    confirm: {
        buttonDeleteText: 'Yes',
        buttonCancelText: 'No',
        cancelTitle: 'Do you want to cancel?',
    },
    deleteConfirm: {
        cancelText: 'Cancel',
        deleteText: 'Delete',
    },
    createForm: {
        title: 'Create payment method',
        createTextButton: 'Create',
        create: {
            success: 'Payment method has been created successfully.',
            failed: 'Create payment method failed',
        },
    },
    updateForm: {
        title: 'Edit payment method',
        updateTextButton: 'Save',
        update: {
            success: 'Payment method has been updated successfully.',
            failed: 'Update payment method failed',
        },
    },
    name: 'Payment method name',
    id: 'ID',
};
