import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { StaffLayoutHeader } from '~components';
import { TaxTypes, localTaxDetailBreadcrumbs } from '~features/report/constants';
import FilterForm from './components/FilterForm/FilterForm';
import LocalTaxDetailTable from './components/LocalTaxTable/LocalTaxTable';
import KutchanTaxTable from './components/KutchanTaxTable/KutchanTaxTable';
import BathTaxTable from './components/BathTaxTable/BathTaxTable';
import queryClient from '~queries/queries';
import TaxDetailPageContextProvider from './context/TaxDetailPageContext';

function LocalTaxDetailPage() {
    const { t } = useTranslation();
    const [showLocalTaxDetailTable, setShowLocalTaxDetailTable] =
        useState<boolean>(false);
    const [selectedTaxTypeState, setSelectedTaxTypeState] = useState<string>(
        TaxTypes.TOKYO_TAX,
    );

    const renderTaxTable = useMemo(() => {
        switch (selectedTaxTypeState) {
            case TaxTypes.TOKYO_TAX:
                return <LocalTaxDetailTable />;
            case TaxTypes.KUTCHAN_TAX:
                return <KutchanTaxTable />;
            case TaxTypes.BATH_TAX:
                return <BathTaxTable />;
            default:
                return;
        }
    }, [selectedTaxTypeState]);

    useEffect(() => {
        return () => {
            queryClient.removeQueries();
            queryClient.invalidateQueries();
        };
    }, []);

    return (
        <TaxDetailPageContextProvider>
            <div className="local-tax-detail-page">
                <StaffLayoutHeader
                    breadcrumbs={localTaxDetailBreadcrumbs()}
                    title={t('report.localTax.title')}
                />
                <Layout.Content>
                    <div className="local-tax-detail-page-wrapper">
                        <div className="local-tax-detail-page-content">
                            <FilterForm
                                showLocalTaxDetailTable={showLocalTaxDetailTable}
                                setShowLocalTaxDetailTable={setShowLocalTaxDetailTable}
                                selectedTaxTypeState={selectedTaxTypeState}
                                setSelectedTaxTypeState={setSelectedTaxTypeState}
                            />
                            {showLocalTaxDetailTable && renderTaxTable}
                        </div>
                    </div>
                </Layout.Content>
            </div>
        </TaxDetailPageContextProvider>
    );
}

export default LocalTaxDetailPage;
