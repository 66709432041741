const marketingChannelList = {
    title: '販売チャネル設定',
    breadcrumbs: {
        home: 'ホーム',
        marketingChannel: '販売チャネル',
        marketingChannelSetting: '販売チャネル設定',
    },
    channelColumn: {
        id: 'ID',
        marketingChannelGroupName: '販売チャネルグループ名',
        marketingChannelName: '販売チャネル名',
        linkageCode: '連携コード',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '販売チャネル名',
        marketingChannelGroupName: '販売チャネルグループ名',
        linkageCode: '連携コード',
    },
    searchBox: '検索',
    message: {
        deleteMarketingChannelsSuccess: '販売チャネルは削除されました',
    },
    exportColumns: {
        name: '販売チャネル名',
        autoGeneratedCode: 'ID',
        marketingChannelGroupName: '販売チャネルグループ名',
        linkageCode: '連携コード',
        linkageCodeDayUse: '連携コード（デイユース）',
    },
    initData: {
        cantDelete: `販売チャネルの項目が削除できません`,
        cantEdit: `販売チャネルの項目が変更できません`,
    },
};

export default marketingChannelList;
