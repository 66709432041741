import React from 'react';
import { Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { RoomOrderSettingsSortOptions } from '../../../constants';
import './HeaderComponent.scss';

interface IHeaderComponent {
    handleChangeSort?: (sortOption: RoomOrderSettingsSortOptions) => void;
    handleClickExpandAll?: () => void;
    handleClickCollapseAll?: () => void;
}

const HeaderComponent: React.FC<IHeaderComponent> = ({
    handleChangeSort = () => {},
    handleClickExpandAll = () => {},
    handleClickCollapseAll = () => {},
}) => {
    const { Option } = Select;
    const { t } = useTranslation();

    return (
        <div className="room-order-settings_header">
            <Select
                defaultValue={RoomOrderSettingsSortOptions.ASCENDING}
                onChange={handleChangeSort}
                className="room-order-settings_sort-options"
            >
                {Object.values(RoomOrderSettingsSortOptions).map((sortOption, index) => {
                    return (
                        <Option value={sortOption} key={index}>
                            {t(`room.roomOrderSettings.header.sortOption.${sortOption}`)}
                        </Option>
                    );
                })}
            </Select>
            <Button
                className="room-order-settings_header-button"
                onClick={handleClickExpandAll}
            >
                {t('room.roomOrderSettings.header.button.expandAll')}
            </Button>
            <Button
                className="room-order-settings_header-button"
                onClick={handleClickCollapseAll}
            >
                {t('room.roomOrderSettings.header.button.collapseAll')}
            </Button>
        </div>
    );
};

export default HeaderComponent;
