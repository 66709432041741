import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import {
    IFacilitiesBulkCreateItem,
    IFacility,
    IFacilityBulkDeleteItem,
    IFacilityBulkUpdateFormItem,
    IFacilityDropDown,
    IFacilityDropDownListQuery,
    IFacilityExportCsvQuery,
    IFacilityGetListQuery,
} from '../interfaces';
import { IBodyResponse, ICsvFile, IGetListResponse } from '~common/interfaces';

class FacilityService extends ApiService {
    getFacilityList(query: IFacilityGetListQuery) {
        return this._getList(query) as Promise<
            IBodyResponse<IGetListResponse<IFacility>>
        >;
    }

    getListByIds(ids: number[]) {
        return this._get(`${this.baseUrl}/get-by-ids`, {
            params: {
                ids,
            },
        }) as Promise<IBodyResponse<IFacility[]>>;
    }

    exportCsv(query: IFacilityExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }

    bulkDelete(bulkDeleteBody: IFacilityBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    bulkCreate(bulkCreateBody: IFacilitiesBulkCreateItem[]) {
        return this._post(`${this.baseUrl}/bulk-create`, {
            items: bulkCreateBody,
        }) as Promise<IBodyResponse<IGetListResponse<IFacility>>>;
    }

    bulkUpdate(bulkUpdateBody: IFacilityBulkUpdateFormItem[]) {
        return this._patch(`${this.baseUrl}/bulk-update`, {
            items: bulkUpdateBody,
        }) as Promise<IBodyResponse<IGetListResponse<IFacility>>>;
    }

    getDropDown(query: IFacilityDropDownListQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params: { ...query },
        }) as Promise<IBodyResponse<IGetListResponse<IFacilityDropDown>>>;
    }
}

export const facilityService = new FacilityService(
    { baseUrl: '/facility' },
    axiosService,
);
