import React from 'react';
import { Col, Row } from 'antd';
import CreateIndividualRoomsFormSetting from '../CreateIndividualRoomsFormSetting/CreateIndividualRoomsFormSetting';
import CreateIndividualRoomsFormRoomList from '../CreateIndividualRoomsFormRoomList/CreateIndividualRoomsFormRoomList';
import './CreateIndividualRoomsForm.scss';
import { IRoomBulkCreateFormItem } from '~features/room/interfaces';

interface IProp {
    control: any;
    currentIndividualRoomList: IRoomBulkCreateFormItem[];
}

function CreateIndividualRoomsForm(props: IProp) {
    return (
        <div className="individual-rooms-form-wrapper">
            <Row gutter={24}>
                <Col span={11}>
                    <CreateIndividualRoomsFormSetting />
                </Col>
                <Col span={13}>
                    <CreateIndividualRoomsFormRoomList
                        currentIndividualRoomList={props.currentIndividualRoomList}
                        control={props.control}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default CreateIndividualRoomsForm;
