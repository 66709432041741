const planCreatePage = {
    title: '新規プランの作成',
    updateTitle: 'プランの編集',
    buttonBack: '戻る',
    buttonNext: '次に進む',
    buttonCancel: 'キャンセル',
    buttonSubmit: '保存',
    planOverview: {
        title: 'プラン概要',
        titleNote: '*は必須項目です',
        name: 'プラン名',
        namePlaceholder: '例：プランA ',
        memo: 'プランの詳細',
        type: 'プランタイプ',
        typePlaceholder: 'プランタイプを選択',
        planTypeOptions: {
            stay: '宿泊',
            day_use: 'デイユース',
        },
        taxSetting: '税設定',
        taxPlaceholder: '税設定を選択',
        taxOptions: {
            '10': '課税10%',
            '0': '非課税',
        },
        checkInTime: 'チェックイン',
        checkOutTime: 'チェックアウト',
        cleaningFrequency: '連泊中の清掃頻度',
        cleaningFreqOption: {
            '0': 'チェックアウト時のみ',
            '1': '毎日 + チェックアウト',
            '2': '2日目 + チェックアウト',
            '3': '3日目 + チェックアウト',
            '4': '4日目 + チェックアウト',
            '5': '5日目 + チェックアウト',
            '6': '6日目 + チェックアウト',
            '7': '7日目 + チェックアウト',
        },
    },
    yen: '円',
    price: '¥',
    saleItemsForm: {
        title: '項目設定',
        describeItemPlan: 'プランに含まれるもの',
        addSaleItem: '行を追加',
        columns: {
            id: '#',
            name: '項目',
            nameInput: '項目を選択',
            price: '単価',
            priceInput: '￥0',
            quantity: '数量',
            isForOnePeople: '人数連動',
            isForOnePeopleHover: '宿泊人数（大人）に連動',
            isFirstDayOnly: '初日のみ',
            isFirstDayOnlyHover: 'この項目を初日のみ有効にします',
            totalPrices: '合計',
            totalPriceInput: '0',
            action: '削除',
        },
    },
    pricingSelectorForm: {
        title: '販売管理・料金設定',
        priceRank: '料金カレンダー',
        priceRankDefault: '基本カレンダー',
        roomType: '販売する部屋',
        roomTypePlaceholder: '対象の部屋タイプを選択',
    },
    planRoomTypePricingsForm: {
        title: '・プラン設定',
        double: 'ダブル',
        single: 'シングル',
        planGroup: 'プラングループコード',
        planGroupInput: 'グループコードを入力してください',
        pricing: '料金設定',
        placehoderPricing: '一括',
        person: '人',
        rank: 'ランク',
        planPricingInput: '金額を入力',
        bulk: '一括',
    },
    planGroupCodeDuplicated: 'プラングループコードが重複しています',
    createSuccess: 'プライシングダッシュボードへ適応するプランを選択してください',
    updateSuccess: 'プランが更新されました',
    createError: 'プランが登録されました',
    updateError: 'プランが更新されませんでした',
    packagePlanCode: {
        title: 'パッケージプランコード',
        tabLabel: 'プランコード',
        addBtn: 'プランコードを追加',
        addModalTitle: 'パッケージプランコードを追加',
        editModalTitle: 'パッケージプランコードを編集',
    },
};

export default planCreatePage;
