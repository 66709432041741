const facilityBookingScheduleEn = {
    otherBookings: '{{count}} other bookings',
    title: 'Facility schedule',
    remaining: 'Remaining facility',
    remove: 'Delete booking',
    showDetail: 'View booking detail',
    statusDropdown: {
        title: 'Change status',
    },
    modalConfirmDeletion: {
        title: 'Delete booking confirmation',
        description: 'Do you want to delete this facility booking?',
        cancelBtn: 'Cancel',
        deleteBtn: 'Delete',
    },
    modalConfirmCancel: {
        title: 'Are you sure to cancel this booking?',
    },
    modalConfirmReserve: {
        title: 'Are you sure to reserve this booking?',
    },
    modalConfirmCheckout: {
        title: 'Confirmation of status change',
        description: 'Do you want to change status to [Finished using]?',
        cancelBtn: 'Cancel',
        okBtn: 'Change',
    },
    modalCheckoutSuccess: {
        title: 'Status has been changed to [Finished using].',
        okBtn: 'Close',
    },
    modalCheckoutFailed: {
        title: 'Cannot check out due to unpaid amount',
        description: 'Proceed to payment details and settle the payment',
        cancelBtn: 'Cancel',
        okBtn: 'Go to booking detail',
    },
    modalCheckInFailed: {
        title: 'Error',
        description: 'The following facility bookings cannot be checked in',
        okBtn: 'Close',
    },
    status: {
        cancelled: 'Cancelled',
        started: 'Checked in',
        finished: 'Checked out',
        reserved: 'Reserved',
    },
    checkInForm: {
        title: 'Check in',
        facilityType: 'Facility type',
        facilityName: 'Facility name',
        note: 'The status will be changed from [Reserved] to [in-use] upon starting. If there is a deposit, please select or enter it.',
        saleItem: 'Sales item',
        paymentMethod: 'Payment method',
        saleItemPlaceholder: 'Select a sales item',
        paymentMethodlaceholder: 'Select a payment method',
        price: 'Total',
        additionBtn: 'Add',
        totalAmount: 'Unpaid amount',
        cancelBtn: 'Cancel',
        submitBtn: 'Check in',
        cancelTitle: 'Do you want to cancel?',
    },
    message: {
        cannotCheckIn: {
            fromReserved: 'Cannot check in before {{date}}',
            fromFinish: 'Cannot check out because the checkout date has already passed',
        },
    },
};

export default facilityBookingScheduleEn;
