import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import React from 'react';
import classNames from 'classnames';

type Props = {
    style: React.CSSProperties;
    item: { label: string; id: string };
};

const today = todayDayjs.format('YYYY-MM-DD');

export const CellHeader = ({ style, item }: Props) => {
    return (
        <div style={style} className="stop-selling-cell-header">
            <div
                className={classNames('stop-selling-cell-header-item', {
                    today: item.id === today,
                })}
            >
                {item.label}
            </div>
        </div>
    );
};
