import { BrowserRouter, Navigate, Route } from 'react-router-dom';
import { RoutesWithAnalytics } from '~plugins/analytics';
import { AdminGroup, TabistTeamGroup, UserGroup } from '~common/constants';
import CreateNotificationPage from '~features/admin-notification/pages/CreateNotificationPage/CreateNotificationPage';
import NotificationListPage from '~features/admin-notification/pages/NotificationListPage/NotificationListPage';
import UpdateNotificationPage from '~features/admin-notification/pages/UpdateNotificationPage/UpdateNotificationPage';
import ChildrenTypePage from '~features/children-type/pages/ChildrenTypePage/ChildrenTypePage';
import ClosingBookingPage from '~features/closing-booking/pages/ClosingBookingPage';
import { DashboardPage } from '~features/dashboard/pages/DashboardPage/DashboardPage';
import FacilityBookingDetailPage from '~features/facility-booking/pages/FacilityBookingDetailPage/FacilityBookingDetailPage';
import FacilityBookingListPage from '~features/facility-booking/pages/FacilityBookingListPage/FacilityBookingListPage';
import { FacilitySchedulePage } from '~features/facility-booking/pages/FacilitySchedulePage/FacilitySchedulePage';
import SplitFacilityBookingReceiptPage from '~features/facility-booking/pages/SplitReceiptPage/SplitFacilityBookingReceiptPage';
import FacilityTypePage from '~features/facility-type/pages/FacilityTypePage/FacilityTypePage';
import CreateFacilityPage from '~features/facility/pages/CreateFacilityPage/CreateFacilityPage';
import FacilityPage from '~features/facility/pages/FacilityPage/FacilityPage';
import GuestClassificationListPage from '~features/guest-classification/pages/GuestClassificationListPage/GuestClassificationListPage';
import CreateGuestPage from '~features/guest/pages/CreateGuestPage/CreateGuestPage';
import GuestDetailPage from '~features/guest/pages/GuestDetailPage/GuestDetailPage';
import GuestListPage from '~features/guest/pages/GuestListPage/GuestListPage';
import UpdateGuestPage from '~features/guest/pages/UpdateGuestPage/UpdateGuestPage';
import IndicatorListPage from '~features/indicator/pages/IndicatorListPage/IndicatorListPage';
import { IndicatorSettingPage } from '~features/indicator/pages/IndicatorSettingPage/IndicatorSettingPage';
import InventoryListPage from '~features/inventory/pages/InventoryListPage/InventoryListPage';
import StopSellingPage from '~features/inventory/pages/StopSellingPage/StopSellingPage';
import HistoryListPage from '~features/fo-operation-history/pages/HistoryPage/HistoryPage';
import PaymentMethodPage from '~features/payment-method/pages/PaymentMethodPage/PaymentMethodPage';
import CreatePlanPage from '~features/plan/pages/CreatePlanPage/CreatePlanPage';
import PlanDetailPage from '~features/plan/pages/PlanDetailPage/PlanDetailPage';
import PlanListPage from '~features/plan/pages/PlanListPage/PlanListPage';
import UpdatePlanPage from '~features/plan/pages/UpdatePlanPage/UpdatePlanPage';
import RankCalendarDetailPage from '~features/rank-calendar/pages/RankCalendarDetailPage/RankCalendarDetailPage';
import RankCalendarListPage from '~features/rank-calendar/pages/RankCalendarListPage/RankCalendarListPage';
import RankCalendarUpdatePage from '~features/rank-calendar/pages/RankCalendarUpdatePage/RankCalendarUpdatePage';
import { BookingConfirmPage } from '~features/room-booking/pages/BookingConfirmPage/BookingConfirmPage';
import CreateBookingPage from '~features/room-booking/pages/CreateBookingPage/CreateBookingPage';
import RoomBookingDetailPage from '~features/room-booking/pages/RoomBookingDetailPage/RoomBookingDetailPage';
import RoomBookingItemDetailPage from '~features/room-booking/pages/RoomBookingItemDetailPage/RoomBookingItemDetailPage';
import RoomBookingEditPage from '~features/room-booking/pages/RoomBookingEditPage/RoomBookingEditPage';
import { SchedulePage } from '~features/room-booking/pages/SchedulePage/SchedulePage';
import SplitReceiptPage from '~features/room-booking/pages/SplitReceiptPage/SplitReceiptPage';
import RoomCleaningListPage from '~features/room-cleaning/pages/RoomCleaningListPage/RoomCleaningListPage';
import { RoomManagementPage } from '~features/room-management/pages/RoomManagementPage';
import ImportCsvRoomTypePage from '~features/room-type/pages/ImportCsvRoomTypePage/ImportCsvRoomTypePage';
import ImportCsvRoomPage from '~features/room/pages/ImportCsvPage/ImportCsvRoomPage';
import ImportCsvSaleGroupPage from '~features/sale-group/pages/ImportCsvSaleGroupPage/ImportCsvSaleGroupPage';
import SaleGroupListPage from '~features/sale-group/pages/SaleGroupListPage/SaleGroupListPage';
import CreateSaleItemPage from '~features/sale-item/pages/CreateSaleItemPage/CreateSaleItemPage';
import ImportCsvSaleItemPage from '~features/sale-item/pages/ImportCsvSaleItemPage/ImportCsvSaleItemPage';
import SaleItemListPage from '~features/sale-item/pages/SaleItemListPage/SaleItemListPage';
import SiteControllerPage from '~features/site-controller/pages/SiteControllerPage/SiteControllerPage';
import TaxPage from '~features/tax/pages/TaxPage/TaxPage';
import {
    RedirectHomeIfLoggedIn,
    RequireAuth,
    RequireHotel,
    RequirePermission,
} from './AuthProvider';
import LoginPage from './features/auth/pages/LoginPage/LoginPage';
import ForbiddenPage from './features/error/pages/ForbiddenPage/ForbiddenPage';
import NotFoundPage from './features/error/pages/NotFoundPage/NotFoundPage';
import IntegrationsPage from './features/integrations/pages/IntegrationsPage';
import HotelListPage from './features/hotel/pages/HotelListPage/HotelListPage';
import HotelSettingPage from './features/hotel/pages/HotelSettingPage/HotelSettingPage';
import MarketingChannelGroupListPage from './features/marketing-channel-group/pages/MarketingGroupListPage/MarketingGroupListPage';
import CreateMarketingChannelsPage from './features/marketing-channel/pages/CreateMarketingChannelsPage/CreateMarketingChannelsPage';
import MarketingChannelListPage from './features/marketing-channel/pages/MarketingChannelListPage/MarketingChannelListPage';
import RoomBookingListPage from './features/room-booking/pages/RoomBookingListPage/RoomBookingListPage';
import RoomTypeListPage from './features/room-type/pages/RoomTypeListPage/RoomTypeListPage';
import CreateIndividualRoomsPage from './features/room/pages/CreateIndividualRoomsPage/CreateIndividualRoomsPage';
import RoomListPage from './features/room/pages/RoomListPage/RoomListPage';
import GuestLayout from './layouts/GuestLayout/GuestLayout';
import SalesSummaryPage from '~features/report/pages/SalesSummaryPage/SalesSummaryPage';
import SalesPaymentPage from '~features/report/pages/SalesPaymentPage/SalesPaymentPage';
import BoHistoryPage from '~features/bo-operation-history/pages/boHistoryPage/BoHistoryPage';
import SalesByCategoryPage from '~features/report/pages/SalesByCategoryPage/SalesByCategoryPage';
import UnpaidDetailPage from '~features/report/pages/UnpaidDetailPage/UnpaidDetailPage';
import PaymentSummaryPage from '~features/report/pages/PaymentSummaryPage/PaymentSummaryPage';
import CancellationPage from '~features/report/pages/CancellationPage/CancellationPage';
import OperationStatusPage from '~features/report/pages/OperationStatusPage/OperationStatusPage';
import PaymentDetailsPage from '~features/report/pages/PaymentDetailsPage/PaymentDetailsPage';
import SmtpSettingPage from '~features/smtp-setting/pages/SmtpSettingPage';
import LocalTaxDetailPage from '~features/report/pages/LocalTaxPage/LocalTaxDetailPage';
import CurrentGuestPage from '~features/report/pages/CurrentGuestPage/CurrentGuestPage';
import MealReportPage from '~features/report/pages/MealReportPage/MealReportPage';
import GroupBookingPage from '~features/room-booking/pages/GroupBookingPage/GroupBookingPage';
import CSVBookingUploadPage from '~features/csv-booking-upload/pages/CSVBookingUploadPage';
import { AlmexKioskSettingPage } from '~features/kiosk-setting/almex/pages/AlmexKioskSettingPage';
import SalesByPlansPage from '~features/report/pages/SalesByPlansPage/SalesByPlansPage';
import RoomOrderSettingsPage from '~features/room/pages/RoomOrderSettingsPage/RoomOrderSettingsPage';
import SalesByOtaPage from '~features/report/pages/SalesByOtaPage/SalesByOtaPage';
import StaffLayout from '~layouts/StaffLayout/StaffLayout';
import KeycardSettingPage from '~features/keycard-setting/pages/KeycardSettingPage';
import OTACancelationRobotPage from '~features/room-booking/pages/OTACancelationRobotPage/OTACancelationRobotPage';

function RootRouter() {
    return (
        <BrowserRouter>
            <RoutesWithAnalytics>
                <Route
                    element={
                        <RedirectHomeIfLoggedIn>
                            <GuestLayout />
                        </RedirectHomeIfLoggedIn>
                    }
                >
                    <Route path="/login" element={<LoginPage />} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
                <Route
                    element={
                        <RequireAuth>
                            <StaffLayout />
                        </RequireAuth>
                    }
                >
                    <Route path="/forbidden" element={<ForbiddenPage />} />
                </Route>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route
                    path="/dashboard"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_FRONT,
                                        UserGroup.HOTEL_OPERATOR,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="" element={<DashboardPage />} />
                </Route>
                <Route
                    path="/room-booking"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_OPERATOR,
                                        UserGroup.HOTEL_FRONT,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="" element={<RoomBookingListPage />} />
                    <Route path="create-booking" element={<CreateBookingPage />} />
                    <Route path="schedule" element={<SchedulePage />} />
                    <Route
                        path="create-booking/confirm"
                        element={<BookingConfirmPage />}
                    />
                    <Route path=":roomBookingId/edit" element={<RoomBookingEditPage />} />
                    <Route path=":roomBookingId/detail">
                        <Route path="" element={<RoomBookingDetailPage />} />
                        <Route path="split-receipt" element={<SplitReceiptPage />} />
                        <Route path="split-invoice" element={<SplitReceiptPage />} />
                        <Route
                            path=":roomBookingItemId"
                            element={<RoomBookingItemDetailPage />}
                        />
                        <Route
                            path="ota-cancelation-robot"
                            element={<OTACancelationRobotPage />}
                        />
                    </Route>
                    <Route path="group" element={<GroupBookingPage />} />
                </Route>

                <Route
                    path="/facility-booking"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_OPERATOR,
                                        UserGroup.HOTEL_FRONT,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="schedule" element={<FacilitySchedulePage />} />
                    <Route path="" element={<FacilityBookingListPage />} />
                    <Route path=":facilityBookingId/detail">
                        <Route path="" element={<FacilityBookingDetailPage />} />
                        <Route
                            path="split-receipt"
                            element={<SplitFacilityBookingReceiptPage />}
                        />
                        <Route
                            path="split-invoice"
                            element={<SplitFacilityBookingReceiptPage />}
                        />
                    </Route>
                </Route>
                <Route
                    path="/guest"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_OPERATOR,
                                        UserGroup.HOTEL_FRONT,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="" element={<GuestListPage />} />
                    <Route path="create-guest" element={<CreateGuestPage />} />
                    <Route path=":guestId/detail" element={<GuestDetailPage />} />
                    <Route path=":guestId/update" element={<UpdateGuestPage />} />
                </Route>
                <Route
                    path="/integrations/hotel-smart"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_OPERATOR,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="" element={<IntegrationsPage />} />
                </Route>
                <Route
                    path="/integrations/almex-kiosk"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_OPERATOR,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="" element={<AlmexKioskSettingPage />} />
                </Route>
                {process.env.REACT_APP_KEYCARDS === 'true' && (
                    <Route
                        path="/integrations/keycard-setting"
                        element={
                            <RequireAuth>
                                <RequireHotel>
                                    <RequirePermission
                                        permissions={[
                                            ...AdminGroup,
                                            UserGroup.HOTEL_OWNER,
                                            UserGroup.HOTEL_OPERATOR,
                                        ]}
                                    >
                                        <StaffLayout />
                                    </RequirePermission>
                                </RequireHotel>
                            </RequireAuth>
                        }
                    >
                        <Route path="" element={<KeycardSettingPage />} />
                    </Route>
                )}

                <Route
                    path="/404"
                    element={
                        <RequireAuth>
                            <NotFoundPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/closing-booking"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_FRONT,
                                        UserGroup.HOTEL_OPERATOR,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="" element={<ClosingBookingPage />} />
                </Route>
                <Route
                    path="/room-cleaning"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_FRONT,
                                        UserGroup.HOTEL_OPERATOR,
                                        UserGroup.HOTEL_CLEANING,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="" element={<RoomCleaningListPage />} />
                </Route>
                <Route
                    path="/room-management"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_FRONT,
                                        UserGroup.HOTEL_OPERATOR,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_CLEANING,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="" element={<RoomManagementPage />} />
                </Route>
                <Route
                    path="/report"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_FRONT,
                                        UserGroup.HOTEL_OPERATOR,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    {/* Temporarily removing access to Summary Page */}
                    {/* <Route path="summary" element={<SummaryReportPage />} /> */}
                    <Route path="sales-payment" element={<SalesPaymentPage />} />
                    <Route path="sales-by-plans-report" element={<SalesByPlansPage />} />
                    <Route path="sale" element={<SalesSummaryPage />} />
                    <Route path="sales-by-category" element={<SalesByCategoryPage />} />
                    <Route path="unpaid-detail" element={<UnpaidDetailPage />} />
                    <Route path="payment-summary" element={<PaymentSummaryPage />} />
                    <Route path="cancellation" element={<CancellationPage />} />
                    <Route path="operation-status" element={<OperationStatusPage />} />
                    <Route path="payment-details" element={<PaymentDetailsPage />} />
                    <Route path="local-tax" element={<LocalTaxDetailPage />} />
                    <Route path="current-guest" element={<CurrentGuestPage />} />
                    <Route path="meal-report" element={<MealReportPage />} />
                    <Route path="sales-by-ota-report" element={<SalesByOtaPage />} />
                </Route>
                <Route
                    path="/front-settings"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission
                                    permissions={[
                                        ...AdminGroup,
                                        UserGroup.HOTEL_OWNER,
                                        UserGroup.HOTEL_FRONT,
                                        UserGroup.HOTEL_OPERATOR,
                                    ]}
                                >
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="hotel-setting" element={<HotelSettingPage />} />
                    <Route path="plan">
                        <Route path="" element={<PlanListPage />} />
                        <Route path="create-plan" element={<CreatePlanPage />} />
                        <Route path=":planId/detail" element={<PlanDetailPage />} />
                        <Route path=":planId/update" element={<UpdatePlanPage />} />
                    </Route>
                    <Route path="rank-calendar">
                        <Route path="" element={<RankCalendarListPage />} />
                        <Route
                            path=":rankCalendarId/detail"
                            element={<RankCalendarDetailPage />}
                        />
                        <Route
                            path=":rankCalendarId/update"
                            element={<RankCalendarUpdatePage />}
                        />
                    </Route>
                    <Route path="room-type">
                        <Route path="" element={<RoomTypeListPage />} />
                        <Route path="import-csv" element={<ImportCsvRoomTypePage />} />
                    </Route>
                    <Route path="room">
                        <Route path="" element={<RoomListPage />} />
                        <Route
                            path="create-individual"
                            element={<CreateIndividualRoomsPage />}
                        />
                        <Route path="import-csv" element={<ImportCsvRoomPage />} />
                        <Route
                            path="room-order-settings"
                            element={<RoomOrderSettingsPage />}
                        />
                    </Route>
                    <Route path="indicator">
                        <Route path="" element={<IndicatorListPage />} />
                        <Route path="create" element={<IndicatorSettingPage />} />
                        <Route path="update/:id" element={<IndicatorSettingPage />} />
                    </Route>
                    <Route path="facility-type" element={<FacilityTypePage />} />
                    <Route path="facility">
                        <Route path="" element={<FacilityPage />} />
                        <Route path="create-facility" element={<CreateFacilityPage />} />
                    </Route>
                    <Route path="sale-item">
                        <Route path="" element={<SaleItemListPage />} />
                        <Route path="create-sale-item" element={<CreateSaleItemPage />} />
                        <Route path="import-csv" element={<ImportCsvSaleItemPage />} />
                    </Route>
                    <Route path="sale-group">
                        <Route path="" element={<SaleGroupListPage />} />
                        <Route path="import-csv" element={<ImportCsvSaleGroupPage />} />
                    </Route>
                    <Route path="payment-method" element={<PaymentMethodPage />} />
                    <Route path="marketing-channel">
                        <Route path="" element={<MarketingChannelListPage />} />
                        <Route
                            path="create-marketing-channel"
                            element={<CreateMarketingChannelsPage />}
                        />
                    </Route>
                    <Route
                        path="marketing-channel-group"
                        element={<MarketingChannelGroupListPage />}
                    />
                    <Route
                        path="guest-classification"
                        element={<GuestClassificationListPage />}
                    />
                    <Route path="inventory">
                        <Route path="" element={<InventoryListPage />} />
                        <Route path="stop-selling" element={<StopSellingPage />} />
                    </Route>
                    <Route path="tax" element={<TaxPage />} />
                    <Route path="children-type" element={<ChildrenTypePage />} />
                    <Route path="site-controller" element={<SiteControllerPage />} />
                    <Route path="operation-history" element={<HistoryListPage />} />
                </Route>
                <Route
                    path="/bo-settings"
                    element={
                        <RequireAuth>
                            <RequireHotel>
                                <RequirePermission permissions={[...TabistTeamGroup]}>
                                    <StaffLayout />
                                </RequirePermission>
                            </RequireHotel>
                        </RequireAuth>
                    }
                >
                    <Route path="hotel" element={<HotelListPage />} />
                    <Route path="general-setting/smtp" element={<SmtpSettingPage />} />
                    <Route path="operation-history" element={<BoHistoryPage />} />
                    <Route path="notification">
                        <Route path="" element={<NotificationListPage />} />
                        <Route
                            path="create-notification"
                            element={<CreateNotificationPage />}
                        />
                        <Route
                            path=":notificationId/update"
                            element={<UpdateNotificationPage />}
                        />
                    </Route>
                    <Route path="csv-booking-upload" element={<CSVBookingUploadPage />} />
                </Route>
            </RoutesWithAnalytics>
        </BrowserRouter>
    );
}
export default RootRouter;
