import { axiosInstanceV2 } from '~plugins/axios';
import { IBodyResponse } from '~common/interfaces';
import { ApiService } from '~plugins/axios/api';
import {
    ICreateReceiptFileResponse,
    IGetReceiptFilesCountResponse,
    IGetReceiptFilesResponse,
} from '../interfaces';

class ReceiptFileService extends ApiService {
    getReceiptFiles(id: number, type: string) {
        return this._get(`${this.baseUrl}/${id}/files?type=${type}`) as Promise<
            IBodyResponse<IGetReceiptFilesResponse[]>
        >;
    }

    getReceiptFilesCount(id: number, type: string) {
        return this._get(`${this.baseUrl}/${id}/files-count?type=${type}`) as Promise<
            IBodyResponse<IGetReceiptFilesCountResponse>
        >;
    }

    createReceiptFiles(
        id: number,
        body: FormData,
        setProgress: React.Dispatch<React.SetStateAction<number | undefined>>,
    ) {
        return this._post(`${this.baseUrl}/${id}/files`, body, {
            onUploadProgress: (progressEvent: any) => {
                const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                );
                setProgress(progress);
            },
        }) as Promise<IBodyResponse<ICreateReceiptFileResponse[]>>;
    }
}
export const receiptFileService = new ReceiptFileService(
    { baseUrl: '/receipt' },
    axiosInstanceV2,
);
