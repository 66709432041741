import FacilityBookingHistoryList from './FacilityBookingHistoryList/FacilityBookingHistoryList';
import FilterForm from './FilterForm/FilterForm';
import './GuestBookingHistory.scss';
import RoomBookingHistoryList from './RoomBookingHistoryList/RoomBookingHistoryList';

function GuestBookingHistory() {
    return (
        <div className="guest-detail-history">
            <FilterForm />
            <RoomBookingHistoryList />
            <FacilityBookingHistoryList />
        </div>
    );
}

export default GuestBookingHistory;
