import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import {
    IFacilityBookingReceiptItem,
    IFacilityBookingReceiptItemDetail,
    ISplitReceiptState,
} from '~features/facility-booking/interfaces';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_NUMBER_OF_GROUPS, ReceiptByGroupMapIndex } from '../constants';

const initialState: ISplitReceiptState = {
    receiptByGroupMap: {},
    numberOfGroups: DEFAULT_NUMBER_OF_GROUPS,
    printingReceiptByGroupList: [],
    isShowSplitFacilityBookingReceiptPrintingModal: false,
};

export const splitFacilityBookingReceiptSlice = createSlice({
    name: 'split-facility-booking-receipt',
    initialState,
    reducers: {
        setNumberOfGroups: (state, action) => {
            state.numberOfGroups = action.payload;
        },
        setPrintingReceiptByGroupList: (state, action) => {
            state.printingReceiptByGroupList = action.payload;
        },
        setIsShowSplitFacilityBookingReceiptPrintingModal: (state, action) => {
            state.isShowSplitFacilityBookingReceiptPrintingModal = action.payload;
        },
        setReceiptByGroupMap: (state, action) => {
            state.receiptByGroupMap = action.payload;
        },
        setGuestNameToGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string;
                guestName: string;
            }>,
        ) => {
            const { groupId, guestName } = action.payload;
            state.receiptByGroupMap[groupId].guestName = guestName;
        },
        setProvisoToGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string;
                proviso?: string;
            }>,
        ) => {
            const { groupId, proviso } = action.payload;
            state.receiptByGroupMap[groupId].proviso = proviso;
        },
        /**
         * Helper methods that:
         * - Add receiptItemDetails
         * - Remove receiptItemDetails
         * - Update receipt in the key 'ALL' of the group map
         *
         * These methods modify the receiptItemDetails list (sale item, etc.) in
         * the specific receiptItem of the roomBookingItem in the group's receipt
         */
        addFacilityBookingReceiptItemsInGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string | null;
                receiptItem: IFacilityBookingReceiptItem;
                receiptItemDetails: IFacilityBookingReceiptItemDetail[];
            }>,
        ) => {
            const { groupId, receiptItem, receiptItemDetails } = action.payload;

            const receiptItems =
                state.receiptByGroupMap[groupId || ReceiptByGroupMapIndex.ALL].receipt
                    .facilityBooking.receiptItems;

            const toUpdateReceiptItem = receiptItems.find(
                (item) => item.id === receiptItem.id,
            );
            if (!toUpdateReceiptItem) return;

            const newReceiptItemDetails = toUpdateReceiptItem.receiptItemDetails.concat(
                receiptItemDetails.map((detail) => ({
                    ...detail,
                    groupId,
                })),
            );
            toUpdateReceiptItem.receiptItemDetails = uniqBy(newReceiptItemDetails, 'id');
            state.receiptByGroupMap[
                groupId || ReceiptByGroupMapIndex.ALL
            ].receipt!.facilityBooking.receiptItems = receiptItems;
            return;
        },
        removeFacilityBookingReceiptItemsInGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string | null;
                receiptItem: IFacilityBookingReceiptItem;
                receiptItemDetails: IFacilityBookingReceiptItemDetail[];
            }>,
        ) => {
            const { groupId, receiptItem, receiptItemDetails } = action.payload;

            const receiptItems =
                state.receiptByGroupMap[groupId || ReceiptByGroupMapIndex.ALL].receipt
                    .facilityBooking.receiptItems;

            const toUpdateReceiptItem = receiptItems.find(
                (item) => item.id === receiptItem.id,
            );
            if (!toUpdateReceiptItem) return;

            toUpdateReceiptItem.receiptItemDetails =
                toUpdateReceiptItem.receiptItemDetails.filter(
                    (item) => !receiptItemDetails.find((i) => i.id === item.id),
                );
            state.receiptByGroupMap[
                groupId || ReceiptByGroupMapIndex.ALL
            ].receipt!.facilityBooking.receiptItems = receiptItems;
        },
        updateFacilityBookingReceiptItemsInGroupAllMap: (
            state,
            action: PayloadAction<{
                groupId: string | null;
                receiptItem: IFacilityBookingReceiptItem;
                receiptItemDetails: IFacilityBookingReceiptItemDetail[];
            }>,
        ) => {
            const { groupId, receiptItem, receiptItemDetails } = action.payload;

            const receiptItems =
                state.receiptByGroupMap[ReceiptByGroupMapIndex.ALL].receipt
                    .facilityBooking.receiptItems;

            const toUpdateReceiptItem = receiptItems.find(
                (item) => item.id === receiptItem.id,
            );
            if (!toUpdateReceiptItem) return;

            toUpdateReceiptItem.receiptItemDetails =
                toUpdateReceiptItem.receiptItemDetails.map((detail) => {
                    if (receiptItemDetails.find((i) => i.id === detail.id)) {
                        return {
                            ...detail,
                            groupId,
                        };
                    }
                    return detail;
                });

            state.receiptByGroupMap[
                ReceiptByGroupMapIndex.ALL
            ].receipt!.facilityBooking.receiptItems = receiptItems;
            return;
        },
        resetReceiptByGroupMap: (state) => {
            state.receiptByGroupMap = {};
        },
    },
});

export const {
    setNumberOfGroups,
    setPrintingReceiptByGroupList,
    setIsShowSplitFacilityBookingReceiptPrintingModal,
    setReceiptByGroupMap,
    setGuestNameToGroupMap,
    setProvisoToGroupMap,
    addFacilityBookingReceiptItemsInGroupMap,
    removeFacilityBookingReceiptItemsInGroupMap,
    updateFacilityBookingReceiptItemsInGroupAllMap,
    resetReceiptByGroupMap,
} = splitFacilityBookingReceiptSlice.actions;

export const splitReceiptStateSelector = (state: AppState) => {
    return state.splitFacilityBookingReceipt;
};

export const receiptByGroupMapSelector = (state: AppState) => {
    return state.splitFacilityBookingReceipt.receiptByGroupMap;
};

export default splitFacilityBookingReceiptSlice.reducer;
