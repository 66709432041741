import { InputSearch } from '~components';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import SelectField from './SelectField/SelectField';
import './FilterForm.scss';
import { useAppDispatch } from '~hooks';
import { setKeyword, setShowForm } from '../../room-type.reducer';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup } from '~common/constants';

interface IProp {}

function FilterForm(props: IProp) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([...AdminGroup]);

    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
    };

    const addRoomType = () => {
        dispatch(setShowForm(true));
    };

    return (
        <div className="filter-room-type-wrapper">
            <InputSearch
                placeholder={t('common.searchPlaceholder')}
                maxLength={30}
                onSearch={onSearch}
                addonBefore={SelectField()}
            />

            {!isReadOnly && (
                <div className="room-type-btn-group">
                    <Button type="primary" onClick={addRoomType}>
                        <PlusOutlined />
                        <span className="btn-text">
                            {t('roomType.form.primaryButton')}
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
