import { Col, Layout, Row, Spin, Tabs } from 'antd';
import { useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import { setIsShowFacilityBookingScheduleListModal } from '~features/facility-booking/reducers/facility-schedule.reducer';
import { getPlanDropdown } from '~features/plan/reducers/plan.reducer';
import { RoomCleaningPageTabPane } from '~features/room-cleaning/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import { todayDayjs } from '~plugins/dayjs';
import { RoomManagementStatus } from '../constants';
import {
    getBookingUnassignedList,
    roomManagementSelector,
    setCurrentDate,
    setCurrentStatus,
    setIsShowBookingList,
} from '../reducers/room-management.reducer';
import { AutoAssignModal } from './components/AutoAssignModal/AutoAssignModal';
import { BookingRightList } from './components/BookingRightList/BookingRightList';
import { HeaderControl } from './components/HeaderControl/HeaderControl';
import { SettingTable } from './components/SettingTable/SettingTable';
import { UpdateRoomStatusSidebar } from './components/UpdateRoomStatusSidebar/UpdateRoomStatusSidebar';
import './RoomManagementPage.scss';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';

export const RoomManagementPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        isGettingDetail,
        isShowBookingList,
        isGettingIndicatorDropdown,
        isGettingFacilityBookings,
    } = useAppSelector(roomManagementSelector);

    const isReadOnly = useMemo(() => {
        return !checkUserPermission([
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_CLEANING,
        ]);
    }, []);

    useEffect(() => {
        if (isReadOnly) {
            navigate('/room-cleaning');
        }
    }, [isReadOnly, navigate]);

    const title = useMemo(() => {
        return t('roomManagement.list.title');
    }, []);

    const breadcrumbs = useMemo(() => {
        return [
            {
                text: t('room.list.breadcrumbs.home'),
            },
            {
                text: title,
            },
        ];
    }, [title]);

    const onChangeTab = (tab: string) => {
        if (tab === RoomCleaningPageTabPane.ROOM_CLEANING_LIST)
            navigate('/room-cleaning');
    };
    useEffect(() => {
        if (!isReadOnly) {
            dispatch(setCurrentStatus(RoomManagementStatus.ALL));
            dispatch(setCurrentDate(todayDayjs.fmYYYYMMDD()));
            dispatch(getPlanDropdown({}));
            dispatch(getBookingUnassignedList());
        }
        return () => {
            // close facility booking schedule list modal when exit from room management page
            dispatch(setIsShowFacilityBookingScheduleListModal(false));
            // close right booking list
            dispatch(setIsShowBookingList(false));
        };
    }, []);

    const getRoomManagementTabItems = () => {
        const items = [
            {
                key: RoomCleaningPageTabPane.ROOM_CLEANING_LIST,
                label: <div>{t('roomManagement.list.cleaningList')}</div>,
            },
        ];
        if (!isReadOnly) {
            items.unshift({
                key: RoomCleaningPageTabPane.ROOM_MANAGEMENT,
                label: <div>{t('roomManagement.list.roomIndicator')}</div>,
            });
        }
        return items;
    };
    if (isReadOnly) return null;
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="room-management-page">
                <StaffLayoutHeader breadcrumbs={breadcrumbs} title={title} />
                <div className="tab-header">
                    <Tabs
                        onChange={onChangeTab}
                        items={getRoomManagementTabItems()}
                    />
                </div>

                <Layout.Content>
                    <Spin
                        spinning={
                            isGettingDetail ||
                            isGettingIndicatorDropdown ||
                            isGettingFacilityBookings
                        }
                    >
                        <div className="room-management-page-wrapper">
                            <HeaderControl />
                            <Row>
                                <Col span={isShowBookingList ? 18 : 24}>
                                    <SettingTable />
                                </Col>
                                {isShowBookingList && (
                                    <Col span={6}>
                                        <BookingRightList />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </Spin>
                    <AutoAssignModal isManagementScreen={true} />
                    <UpdateRoomStatusSidebar />
                </Layout.Content>
            </div>
        </DndProvider>
    );
};
