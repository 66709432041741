import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ImportCsvError, InputText } from '~components';
import { ImportSaleItemColumn } from '~features/sale-item/constants';
import { IImportSaleItem } from '~features/sale-item/interfaces';

import {
    hasImportErrorSelector,
    importCsvFileNameSelector,
    importSaleItemListSelector,
    setHasImportError,
} from '~features/sale-item/reducers/sale-item.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { UploadOutlined } from '@ant-design/icons';

import './ImportCsvSaleItem.scss';
import { INPUT_TEXT_MAX_LENGTH, MAX_PRICE, Regex } from '~common/constants';
import { IImportError } from '~features/sale-group/interfaces';
interface IProp {
    responseError: Record<number, any>;
}

function ImportCsvSaleItem(prop: IProp) {
    const { responseError } = prop;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control } = useForm({});
    const hasImportError = useAppSelector(hasImportErrorSelector);
    const [errors, setError] = useState<Record<number, any>>({});
    const importSaleItemList = useAppSelector(importSaleItemListSelector);
    const importCsvFileName = useAppSelector(importCsvFileNameSelector);
    const importSaleItemColumn: ColumnsType<IImportSaleItem> = [
        {
            title: t('saleItem.list.saleItemColumn.num'),
            width: '60px',
            key: '#',
            onCell: () => {
                return {
                    style: { backgroundColor: '#E6F6F9' },
                };
            },
            render: (saleItem: IImportSaleItem) => {
                return <div>{saleItem.order}</div>;
            },
        },
        {
            title: t('saleItem.list.saleItemColumn.name'),
            key: ImportSaleItemColumn.NAME,
            width: '40%',
            ellipsis: true,
            render: (saleItem: IImportSaleItem) => {
                return <div className="text-truncate">{saleItem.name}</div>;
            },
            onCell: (saleItem: IImportSaleItem) => {
                if (errors[saleItem.order]?.name) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
        },
        {
            title: t('saleItem.list.saleItemColumn.price'),
            dataIndex: '',
            key: ImportSaleItemColumn.PRICE,
            render: (saleItem: IImportSaleItem) => {
                return <div>{saleItem.price}</div>;
            },
            onCell: (saleItem: IImportSaleItem) => {
                if (errors[saleItem.order]?.price) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
        },
        {
            title: t('saleItem.list.saleItemColumn.showInBooking'),
            dataIndex: '',
            key: ImportSaleItemColumn.IS_SHOW_IN_BOOKING,
            render: (saleItem: IImportSaleItem) => {
                if (saleItem?.isShowInBooking) {
                    return <>{t('saleItem.list.showInBooking.display')}</>;
                } else {
                    return <>{t('saleItem.list.showInBooking.hidden')}</>;
                }
            },
            onCell: (saleItem: IImportSaleItem) => {
                if (errors[saleItem.order]?.testIsShowInBooking) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
        },
        {
            title: t('saleItem.list.saleItemColumn.tax'),
            key: ImportSaleItemColumn.TAX,
            ellipsis: true,
            render: (saleItem: IImportSaleItem) => {
                return <div>{saleItem.taxName}</div>;
            },
            onCell: (saleItem: IImportSaleItem) => {
                if (errors[saleItem.order]?.taxName) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
        },
    ];

    useEffect(() => {
        setError(responseError);
    }, [responseError]);

    const errorList = useMemo(() => {
        const _errorItems: IImportError[] = [];
        Object.keys(errors)?.forEach((order) => {
            Object.keys(errors[+order])?.forEach((key) => {
                if (errors[+order]?.[key]) {
                    _errorItems.push({
                        order: +order,
                        key,
                        message: errors[+order]?.[key],
                    });
                }
            });
        });
        return _errorItems;
    }, [errors]);

    useEffect(() => {
        validateData();
    }, []);

    const validateData = () => {
        let hasError = false;
        let errors = {};
        importSaleItemList.forEach((importSaleItem) => {
            if (importSaleItem.order !== undefined) {
                const nameError = importSaleItem.isDuplicated
                    ? t('saleItem.import.error.name.uniqueError')
                    : validateName(importSaleItem.name, importSaleItem.order);
                const priceError = validatePrice(importSaleItem.price);

                const taxNameError = validatetaxName(importSaleItem.taxName);
                errors = {
                    ...errors,
                    [importSaleItem.order]: {
                        name: nameError,
                        price: priceError,
                        taxName: taxNameError,
                    },
                };

                if (nameError || priceError || taxNameError) {
                    hasError = true;
                }
            }
        });

        dispatch(setHasImportError(hasError));
        setError(errors);
    };
    const validateName = (name: string, order: number) => {
        if (!name) {
            return t('saleItem.import.error.name.required');
        }
        if (name?.length > INPUT_TEXT_MAX_LENGTH) {
            return t('saleItem.import.error.name.maxLength', {
                maxLength: INPUT_TEXT_MAX_LENGTH,
            });
        }
        if (
            importSaleItemList.some(
                (saleItem) => saleItem.name === name && saleItem.order !== order,
            )
        ) {
            return t('saleItem.import.error.name.uniqueError');
        }
        if (name.match(Regex.NAME_SPECIAL_CHARACTERS)) {
            return t('saleItem.import.error.name.hasNoSpecialCharacters');
        }
        return '';
    };

    const validatePrice = (price: string | number) => {
        if (!price && Number(price) !== 0) {
            return t('saleItem.import.error.price.required');
        }
        if (!Number.isInteger(Number(price))) {
            return t('saleItem.import.error.price.integer');
        }
        if (Number(price) > MAX_PRICE) {
            return t('saleItem.import.error.price.max', {
                max: MAX_PRICE,
            });
        }
        if (Number(price) < 0) {
            return t('saleItem.import.error.price.min');
        }
        return 0;
    };

    const validatetaxName = (taxName: string) => {
        if (!taxName) {
            return t('saleItem.import.error.taxName.required');
        }
        if (taxName?.length > INPUT_TEXT_MAX_LENGTH) {
            return t('saleItem.import.error.taxName.maxLength', {
                maxLength: INPUT_TEXT_MAX_LENGTH,
            });
        }
        if (taxName.match(Regex.TAX_SPECIAL_CHARACTERS)) {
            return t('saleItem.import.error.taxName.hasNoSpecialCharacters');
        }
        return '';
    };

    return (
        <div className="import-csv-sale-item-content">
            <div className="import-csv-sale-item-file-name-wrapper">
                <InputText
                    label={t('common.importCsv.title')}
                    name="title"
                    control={control}
                    required
                    onKeyUp={(e) => {
                        e.preventDefault();
                    }}
                    disabled
                />
                <div className="file-name">
                    <UploadOutlined />
                    <span className="file-name-content">{importCsvFileName}</span>
                </div>
            </div>
            {hasImportError && (
                <ImportCsvError
                    errors={errorList}
                    i18nErrorKey={'saleItem.import.importErrorKey'}
                />
            )}

            {importSaleItemList?.length !== 0 && (
                <div className="import-csv-sale-item-total-data">
                    {t('common.importCsv.numData', {
                        totalData: importSaleItemList?.length,
                    })}
                </div>
            )}

            <Table
                className="import-sale-item-table"
                columns={importSaleItemColumn}
                dataSource={importSaleItemList}
                pagination={false}
                rowKey="id"
            />
        </div>
    );
}

export default ImportCsvSaleItem;
