import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Col, Form, Row } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputDropdown, InputText } from '~components';
import { useUpdateAlmexSetting } from '../../hooks/use-update-almex-kiosk-setting-mutation';
import { HotelKey } from '../../constants';
import type { AlmexKioskSettingUpdateBody } from '../../types';
import { almexSettingSchema } from '../../schema';

export const AlmexSettingForm = (data: AlmexKioskSettingUpdateBody) => {
    const { t } = useTranslation();
    const { isPending, mutate } = useUpdateAlmexSetting();
    const {
        control,
        watch,
        reset,
        getValues,
        handleSubmit,
        formState: { isDirty },
    } = useForm({
        defaultValues: data,
        resolver: yupResolver(almexSettingSchema),
        mode: 'onChange',
    });
    const selectedKeyType = watch('keyType');

    const options = useMemo(
        () => [
            {
                label: t('kioskAlmexIntegrations.almex.keyOptions.none'),
                value: HotelKey.None,
            },
            {
                label: t('kioskAlmexIntegrations.almex.keyOptions.miwa'),
                value: HotelKey.MiwaIC,
            },
            {
                label: t('kioskAlmexIntegrations.almex.keyOptions.goal'),
                value: HotelKey.GoalIC,
            },
            {
                label: t('kioskAlmexIntegrations.almex.keyOptions.showa'),
                value: HotelKey.ShowaIC,
            },
        ],
        [t],
    );

    const onSubmit = handleSubmit((formData) => {
        reset(getValues());
        mutate(formData);
    });

    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={12}>
                    <InputDropdown
                        label={t(
                            'kioskAlmexIntegrations.almex.kioskAlmex.roomKeyOptions',
                        )}
                        name="keyType"
                        control={control}
                        options={options}
                    />
                </Col>
                {selectedKeyType === HotelKey.GoalIC && (
                    <Col span={12}>
                        <InputText
                            label={t(
                                'kioskAlmexIntegrations.almex.kioskAlmex.roomKeySystemId',
                            )}
                            name="keySystemId"
                            control={control}
                        />
                    </Col>
                )}
            </Row>
            <Button
                onClick={onSubmit}
                loading={isPending}
                disabled={!isDirty}
                type="primary"
            >
                {t('kioskAlmexIntegrations.almex.save')}
            </Button>
        </Form>
    );
};
