import { useMutation } from '@tanstack/react-query';
import { roomService } from '../services/room.service';
import { IRoomDisplayOrderBody } from '../interfaces';

const updateRoomOrderSettings = async (body: IRoomDisplayOrderBody): Promise<any> => {
    const response = await roomService.updateRoomDisplayOrder(body);
    if (response.success) {
        return response.data;
    }
    throw response.errors![0];
};

export const useUpdateRoomOrderSettings = () => {
    return useMutation({ mutationFn: updateRoomOrderSettings });
};
