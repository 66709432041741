import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { IDropDownQuery } from '~features/room-type/interfaces';
import { roomTypeService } from '~features/room-type/services/room-type.service';
import { parseDate, todayDayjs } from '~plugins/dayjs';
import { AppState } from '~plugins/redux-toolkit/store';
import { getStartDateAndEndDateByDay, mapInventoryGridItem } from '../helper';
import {
    IBulkSaveStoppingRoomCountForm,
    IInventoryState,
    IStoppingRoomItem,
} from '../interfaces';
import { inventoryService } from '../services/inventory.service';

const initialState: IInventoryState = {
    inventoryList: [],
    showLoading: false,
    isShowStoppingRoomCountForm: false,
    roomTypeList: [],
    stoppingInventoryList: [],
    showLoadingStoppingRoomCountTable: false,
    isEditMode: false,
    inventoryGridData: [],
    currentDate: todayDayjs.format('YYYY-MM-DD'),
    selectedDate: todayDayjs.format('YYYY-MM-DD'),
    errorGridData: {},
};

export const getInventoryListData = createAsyncThunk(
    'inventory/getInventoryListData',
    async (_, { getState }) => {
        const state = getState() as AppState;
        const { currentDate } = state.inventory;
        const start =
            parseDate(currentDate)?.fmYYYYMM() === todayDayjs.fmYYYYMM()
                ? parseDate(currentDate)
                : parseDate(currentDate).startOf('month');
        const { startDate, endDate } = getStartDateAndEndDateByDay(
            start.fmYYYYMMDD(),
            parseDate(currentDate).endOf('month')?.fmYYYYMMDD(),
        );
        return await inventoryService.getInventoryGroupByRoomType({
            startDate,
            endDate,
        });
    },
);

export const getRoomTypeList = createAsyncThunk(
    'inventory/getRoomTypeList',
    async (params?: IDropDownQuery) => {
        return await roomTypeService.getRoomTypeListForDropdown(params);
    },
);

export const getInitStoppingRoomTypeList = createAsyncThunk(
    'inventory/getInitStoppingRoomTypeList',
    async (payload: { date: string }) => {
        return await inventoryService.getInitStoppingRoomTypeList(payload);
    },
);

export const bulkSaveStoppingRoomCount = createAsyncThunk(
    'inventory/bulkSaveStoppingRoomCount',
    async (body: IBulkSaveStoppingRoomCountForm[]) => {
        return await roomTypeService.bulkSaveStoppingRoomCount(body);
    },
);

export const bulkStopInventoryInsertRoomType = createAsyncThunk(
    'inventory/bulkStopInventoryInsertRoomType',
    async (body: IStoppingRoomItem[]) => {
        return await inventoryService.bulkStopInventoryInsertRoomType(body);
    },
);

export const bulkSaleSettingInsertRoomType = createAsyncThunk(
    'inventory/bulkSaleSettingInsertRoomType',
    async (body: IStoppingRoomItem[]) => {
        return await inventoryService.bulkSaleSettingInsertRoomType(body);
    },
);

const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setIsShowStoppingRoomCountForm: (state, action) => {
            state.isShowStoppingRoomCountForm = action.payload;
        },

        setIsEditMode: (state, action) => {
            state.isEditMode = action.payload;
        },
        setCurrentDate: (state, action) => {
            state.currentDate = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setInventoryForm: (state, action) => {
            state.inventoryGridData = action.payload;
        },
        setErrorGridData: (state, action) => {
            state.errorGridData = action.payload;
        },
        updateInventoryGridData: (state, action) => {
            const { value, date, rowIndex, id } = action.payload;
            const _inventoryGridData = cloneDeep(state.inventoryGridData);
            const gridItem = _inventoryGridData[rowIndex]?.day?.[date];
            gridItem.stoppingRooms = value;
            gridItem.availableRooms = gridItem.totalAvailableRooms - (value || 0);
            state.inventoryGridData = _inventoryGridData;

            // set error stopping rooms
            const isError =
                (!value && value !== 0) || value > gridItem.totalAvailableRooms
                    ? true
                    : false;
            const _errorGridData = cloneDeep(state.errorGridData);
            const roomTypeKey = `room_type_${id}`;
            if (isError) {
                if (!_errorGridData[roomTypeKey]) {
                    _errorGridData[roomTypeKey] = [];
                }
                if (!_errorGridData[roomTypeKey]?.includes(date)) {
                    _errorGridData[roomTypeKey].push(date);
                }
            } else {
                if (_errorGridData[roomTypeKey]?.includes(date)) {
                    const errorDates = _errorGridData[roomTypeKey]?.filter((item) => {
                        return item !== date;
                    });
                    _errorGridData[roomTypeKey] = [...errorDates];
                }
            }
            state.errorGridData = _errorGridData;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInventoryListData.pending, (state) => {
            state.showLoading = true;
        });
        builder.addCase(getInventoryListData.fulfilled, (state, action) => {
            state.showLoading = false;
            const _inventoryList = action.payload?.data || [];
            state.inventoryList = _inventoryList;
            state.inventoryGridData = mapInventoryGridItem(_inventoryList);
        });
        builder.addCase(getInitStoppingRoomTypeList.pending, (state) => {
            state.showLoadingStoppingRoomCountTable = true;
        });
        builder.addCase(getInitStoppingRoomTypeList.fulfilled, (state, action) => {
            state.showLoadingStoppingRoomCountTable = false;
            state.stoppingInventoryList = action.payload?.data || [];
        });
        builder.addCase(getRoomTypeList.pending, (state) => {
            state.showLoadingStoppingRoomCountTable = true;
        });
        builder.addCase(getRoomTypeList.fulfilled, (state, action) => {
            state.showLoadingStoppingRoomCountTable = false;
            state.roomTypeList =
                (action.payload?.data?.items || []).map((roomType) => {
                    return {
                        id: roomType.id,
                        name: roomType.name,
                        stoppingRoomCount: roomType.stoppingRoomCount || 0,
                        totalRooms: roomType.totalRooms || 0,
                    };
                }) || [];
        });
        builder.addCase(bulkStopInventoryInsertRoomType.pending, (state) => {
            state.showLoading = true;
        });
        builder.addCase(bulkStopInventoryInsertRoomType.fulfilled, (state, action) => {
            state.showLoading = false;
        });
    },
});

export const {
    setIsShowStoppingRoomCountForm,
    setIsEditMode,
    resetState,
    setCurrentDate,
    setSelectedDate,
    setInventoryForm,
    updateInventoryGridData,
    setErrorGridData,
} = inventorySlice.actions;

export const inventoryListStateSelector = (state: AppState) => {
    return state.inventory;
};

export const inventoryStateSelector = (state: AppState) => {
    return state.inventory;
};

export const inventoryListSelector = (state: AppState) => {
    return state.inventory.inventoryList;
};

export const showLoadingSelector = (state: AppState) => {
    return state.inventory.showLoading;
};

export const isShowStoppingRoomCountFormSelector = (state: AppState) => {
    return state.inventory.isShowStoppingRoomCountForm;
};

export const stoppingInventoryListSelector = (state: AppState) => {
    return state.inventory.stoppingInventoryList;
};

export const roomTypeListSelector = (state: AppState) => {
    return state.inventory.roomTypeList;
};

export const showLoadingStoppingRoomCountTableSelector = (state: AppState) => {
    return state.inventory.showLoadingStoppingRoomCountTable;
};

export const headerColumnsSelector = (state: AppState) => {
    const { currentDate } = state.inventory;
    const currentMonth = todayDayjs.format('YYYY-MM');
    const start =
        currentMonth === parseDate(currentDate).format('YYYY-MM')
            ? parseDate(currentDate).date()
            : 1;
    const end = parseDate(currentDate).endOf('month').date();
    const _currentDate = parseDate(currentDate);
    const _headerColumns: { label: string; id: string }[] = [];
    for (let i = start; i <= end; i++) {
        const _date = _currentDate.clone().set('date', i);
        _headerColumns.push({
            label: _date.format('DD (ddd)'),
            id: _date.fmYYYYMMDD(),
        });
    }
    return _headerColumns;
};

export const isErrorGridDataSelector = (state: AppState) => {
    const { errorGridData } = state.inventory;
    let isError = false;
    Object.keys(errorGridData)?.forEach((errorDates) => {
        if (errorGridData[errorDates]?.length > 0) {
            isError = true;
        }
    });
    return isError;
};

export default inventorySlice.reducer;
