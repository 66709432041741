import { Modal, Radio, RadioChangeEvent } from 'antd';
import customDayjs from '~plugins/dayjs';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IBodyResponse, IGetListResponse } from '~common/interfaces';
import {
    facilityBookingListQueryDefault,
    FacilityBookingStatus,
} from '~features/facility-booking/constants';
import {
    IAdvanceSearchGetListQuery,
    IFacilityBooking,
} from '~features/facility-booking/interfaces';
import {
    isShowAccommodationCardPrintingModalSelector,
    setIsShowAccommodationCardPrintingModal,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import {
    fetchPrintFacilityBookingList,
    printFacilityBookingListSelector,
    setIsShowFacilityBookingPrintingModal,
    setPrintFacilityBookingListQuery,
    setPrintingFacilityBookingList,
} from '~features/facility-booking/reducers/print-facility-booking.reducer';
import {
    AccommodationCardPrintingCheckInOption,
    MAX_PRINT_GUEST_CARD,
} from '~features/room-booking/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import './FacilityBookingAccommodationCardPrintPopup.scss';
import { showConfirm } from '~common/notification';

export default function FacilityBookingAccommodationCardPrintPopup() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { isPrintFacilityBookingListLoading, printFacilityBookingListQuery } =
        useAppSelector(printFacilityBookingListSelector);

    const isShowAccommodationCardPrintingModal = useAppSelector(
        isShowAccommodationCardPrintingModalSelector,
    );
    const [
        accommodationCardPrintingCheckInOption,
        setAccommodationCardPrintingCheckInOption,
    ] = useState<AccommodationCardPrintingCheckInOption>(
        AccommodationCardPrintingCheckInOption.TODAY,
    );

    useEffect(() => {
        const period = accommodationCheckIn[accommodationCardPrintingCheckInOption];
        dispatch(
            setPrintFacilityBookingListQuery({
                ...facilityBookingListQueryDefault,
                ...(!isEmpty(period.checkInStartTime) && {
                    checkInDateRanges: [period.checkInStartTime, period.checkInEndTime],
                }),
                status: [
                    FacilityBookingStatus.RESERVED,
                    FacilityBookingStatus.STARTED,
                    FacilityBookingStatus.FINISHED,
                ],
                limit: MAX_PRINT_GUEST_CARD,
            }),
        );
    }, [accommodationCardPrintingCheckInOption]);

    const onChangeAccommodationCardPrintingCheckInOption = (e: RadioChangeEvent) => {
        setAccommodationCardPrintingCheckInOption(e.target.value);
    };

    const onCloseAccommodationCardPrintingModal = () => {
        dispatch(setIsShowAccommodationCardPrintingModal(false));
        setAccommodationCardPrintingCheckInOption(
            AccommodationCardPrintingCheckInOption.TODAY,
        );
    };

    const getAccommodationCheckInOption = (offsetDay: number) => ({
        checkInStartTime: customDayjs()
            .add(offsetDay, 'day')
            .startOf('day')
            .fmYYYYMMDDHHmmss(),
        checkInEndTime: customDayjs()
            .add(offsetDay, 'day')
            .endOf('day')
            ?.fmYYYYMMDDHHmmss(),
        date: +customDayjs().add(offsetDay, 'day').format('DD'),
        month: +customDayjs().add(offsetDay, 'day').format('MM'),
    });

    const accommodationCheckIn = {
        [AccommodationCardPrintingCheckInOption.TODAY]: getAccommodationCheckInOption(0),
        [AccommodationCardPrintingCheckInOption.TOMORROW]:
            getAccommodationCheckInOption(1),
        [AccommodationCardPrintingCheckInOption.THE_DAY_AFTER_TOMORROW]:
            getAccommodationCheckInOption(2),
        [AccommodationCardPrintingCheckInOption.EMPTY_CARD]: {
            checkInStartTime: '',
            checkInEndTime: '',
            date: '',
            month: '',
        },
    };

    const setDataForPrintScreen = () => {
        dispatch(setIsShowAccommodationCardPrintingModal(false));
        dispatch(setIsShowFacilityBookingPrintingModal(true));
        setAccommodationCardPrintingCheckInOption(
            AccommodationCardPrintingCheckInOption.TODAY,
        );
    };

    const openPrintScreen = async () => {
        const response = await fetchData();
        const facilityBookingList = (
            response?.payload as IBodyResponse<IGetListResponse<IFacilityBooking>>
        )?.data?.items;
        if (!!response && !facilityBookingList.length) {
            Modal.error({
                content: t('facilityBooking.printing.notification.noSuchFacilityBooking'),
                okText: t('common.ok'),
            });
            return;
        }
        if (facilityBookingList.length > MAX_PRINT_GUEST_CARD) {
            showConfirm({
                title: t('roomBooking.printing.notification.biggerGuestCards', {
                    totalBookings: facilityBookingList.length,
                }),
                okText: t('common.ok'),
                cancelText: t('common.cancel'),
                onOk() {
                    setDataForPrintScreen();
                },
            });
            return;
        }
        setDataForPrintScreen();
    };

    const fetchData = async () => {
        if (
            (printFacilityBookingListQuery as IAdvanceSearchGetListQuery)
                ?.checkInDateRanges?.[0]
        ) {
            return await dispatch(
                fetchPrintFacilityBookingList(printFacilityBookingListQuery),
            );
        } else {
            await dispatch(setPrintingFacilityBookingList([]));
            return undefined;
        }
    };

    return (
        <Modal
            className="facility-booking-accommodation-card-printing-modal"
            title={`${t('roomBooking.detail.accommodationCardPrinting.modal.title')}`}
            cancelText={`${t(
                'roomBooking.detail.accommodationCardPrinting.modal.cancel',
            )}`}
            okText={`${t('roomBooking.detail.accommodationCardPrinting.modal.ok')}`}
            open={isShowAccommodationCardPrintingModal}
            onOk={openPrintScreen}
            onCancel={onCloseAccommodationCardPrintingModal}
            confirmLoading={isPrintFacilityBookingListLoading}
            width={572}
        >
            <Radio.Group
                className="check-in-option-radio-group"
                onChange={onChangeAccommodationCardPrintingCheckInOption}
                value={accommodationCardPrintingCheckInOption}
            >
                {Object.values(AccommodationCardPrintingCheckInOption).map(
                    (option: AccommodationCardPrintingCheckInOption) => {
                        return (
                            <Radio value={option} key={option}>
                                <span className="check-in-label bold">
                                    {t(
                                        `roomBooking.detail.accommodationCardPrinting.modal.${option}FirstPart`,
                                    )}
                                </span>
                                &nbsp;
                                <span className="check-in-label">
                                    {t(
                                        `roomBooking.detail.accommodationCardPrinting.modal.${option}SecondPart`,
                                        {
                                            month: accommodationCheckIn[option].month,
                                            date: accommodationCheckIn[option].date,
                                        },
                                    )}
                                </span>
                            </Radio>
                        );
                    },
                )}
            </Radio.Group>
        </Modal>
    );
}
