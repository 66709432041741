import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormatMoney, FormatNumber } from '~common/helper';
import {
    ISalesByOtaDetail,
    ISalesByOtaResponse,
    MarketingChannelData,
    MonthData,
} from '../interfaces';
import { COLUMN_KEYS, getMonthlyData, getMonthlySummaryData } from './helper';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { ReportTableCsvFileName, ReportTableId } from '~features/report/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    salesByOtaSelector,
    setExportCsv,
} from '~features/report/reducers/sales-by-ota.reducer';
import { useEffect, useMemo, useState } from 'react';
import { exportCSVReport } from '~features/report/helper';

type Props = {
    data: ISalesByOtaResponse;
    loading: boolean;
    selectedDate: string;
};

const renderCellContent = (
    current: number,
    previous: number,
    isMoneyFormat?: boolean,
) => {
    const formattedCurrent = isMoneyFormat
        ? FormatMoney.format(current)
        : FormatNumber.format(current);
    const formattedPrevious = isMoneyFormat
        ? FormatMoney.format(previous)
        : FormatNumber.format(previous);
    return (
        <>
            {formattedCurrent} <span className="gray-color">({formattedPrevious})</span>
        </>
    );
};
const { Cell: SummaryCell, Row: SummaryRow } = Table.Summary;

function MonthlySalesByOtaTable({ data, loading, selectedDate }: Props) {
    const [tableData, setTableData] = useState<MarketingChannelData[] | null>(null);
    const { isExportCsv } = useAppSelector(salesByOtaSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isExportCsv) {
            exportCSVReport(
                ReportTableId.SALES_BY_OTA_MONTHLY,
                `${ReportTableCsvFileName.SALES_BY_OTA_MONTHLY}.csv`,
            );
            dispatch(setExportCsv(false));
        }
    }, [isExportCsv, dispatch]);

    const { t } = useTranslation();

    useEffect(() => {
        const dataSource = Object.values(getMonthlyData(data, selectedDate));
        setTableData(dataSource);
    }, [data, selectedDate]);

    const summaryData = getMonthlySummaryData(data, selectedDate);

    const createMonthColumns = useMemo(
        () => (month: 'lastMonth' | 'currentMonth' | 'nextMonth') =>
            [
                {
                    title: t('report.salesByOta.tableColumns.totalBookedRoom'),
                    key: `${month}-totalBookedRoom`,
                    render: (_: any, record: MarketingChannelData) => {
                        const monthData = record[month];
                        if (!monthData) return null;
                        const { current, previousYear } = monthData;
                        return renderCellContent(
                            current.totalBookedRoom,
                            previousYear.totalBookedRoom,
                        );
                    },
                },
                {
                    title: t('report.salesByOta.tableColumns.totalAdult'),
                    key: `${month}-totalGuest`,
                    render: (_: any, record: MarketingChannelData) => {
                        const monthData = record[month];
                        if (!monthData) return null;
                        const { current, previousYear } = monthData;
                        return renderCellContent(
                            current.totalGuest,
                            previousYear.totalGuest,
                        );
                    },
                },
                {
                    title: t('report.salesByOta.tableColumns.totalChild'),
                    key: `${month}-totalChild`,
                    render: (_: any, record: MarketingChannelData) => {
                        const monthData = record[month];
                        if (!monthData) return null;
                        const { current, previousYear } = monthData;
                        return renderCellContent(
                            current.totalChild,
                            previousYear.totalChild,
                        );
                    },
                },
                {
                    title: t('report.salesByOta.tableColumns.totalRevenue'),
                    key: `${month}-totalRevenue`,
                    width: 150,
                    render: (_: any, record: MarketingChannelData) => {
                        const monthData = record[month];
                        if (!monthData) return null;
                        const { current, previousYear } = monthData;
                        return renderCellContent(
                            current.totalRevenue,
                            previousYear.totalRevenue,
                            true,
                        );
                    },
                },
                {
                    title: t('report.salesByOta.tableColumns.avgRevenue'),
                    key: `${month}-avgRevenue`,
                    render: (_: any, record: MarketingChannelData) => {
                        const monthData = record[month];
                        if (!monthData) return null;
                        const { current, previousYear } = monthData;
                        return renderCellContent(
                            current.avgRevenue,
                            previousYear.avgRevenue,
                            true,
                        );
                    },
                },
            ],
        [data, selectedDate],
    );

    const columns: TableColumnsType<any> = [
        {
            title: t('report.salesByOta.tableColumns.ota'),
            dataIndex: 'marketingChannelName',
            key: 'marketingChannelName',
            fixed: 'left',
        },
        {
            title: t('report.salesByOta.tableColumns.lastMonth'),
            dataIndex: 'lastMonth',
            key: 'lastMonth',
            className: 'header-cell',
            children: createMonthColumns('lastMonth'),
        },
        {
            title: t('report.salesByOta.tableColumns.currentMonth'),
            dataIndex: 'currentMonth',
            key: 'currentMonth',
            className: 'header-cell',
            children: createMonthColumns('currentMonth'),
        },
        {
            title: t('report.salesByOta.tableColumns.nextMonth'),
            dataIndex: 'nextMonth',
            key: 'nextMonth',
            className: 'header-cell',
            children: createMonthColumns('nextMonth'),
        },
    ];

    const summaryColumns = columns
        .slice(1)
        .flatMap((column) => (column as ColumnGroupType<any>).children);

    const renderSummaryCell = (column: ColumnGroupType<any> | ColumnType<any>) => {
        const key = column.key?.toString();
        if (!key) return null;
        const [monthPeriod, columnType] = key.split('-');
        if (!monthPeriod || !columnType) return null;
        const summary = summaryData[monthPeriod as keyof typeof summaryData];
        if (!summary) return null;
        const { current, previousYear } = summary;
        if (!current || !previousYear) return null;
        switch (columnType) {
            case COLUMN_KEYS.TOTAL_REVENUE:
            case COLUMN_KEYS.AVG_REVENUE:
                return renderCellContent(
                    current[columnType as keyof ISalesByOtaDetail] || 0,
                    previousYear[columnType as keyof ISalesByOtaDetail] || 0,
                    true,
                );
            default:
                return renderCellContent(
                    current[columnType as keyof ISalesByOtaDetail] || 0,
                    previousYear[columnType as keyof ISalesByOtaDetail] || 0,
                );
        }
    };

    return (
        <Table
            columns={columns}
            loading={loading}
            pagination={false}
            bordered={true}
            dataSource={tableData ? tableData : []}
            rowKey="marketingChannelId"
            sticky={{
                offsetHeader: 100,
                offsetScroll: 0,
            }}
            id={ReportTableId.SALES_BY_OTA_MONTHLY}
            summary={() => {
                return (
                    <>
                        <SummaryRow>
                            <SummaryCell className="header-cell" index={0}>
                                {t('report.salesByOta.tableColumns.total')}
                            </SummaryCell>
                            {summaryColumns.map((column, index) => {
                                return (
                                    <SummaryCell
                                        className="header-cell"
                                        key={index}
                                        index={index + 1}
                                    >
                                        {renderSummaryCell(column)}
                                    </SummaryCell>
                                );
                            })}
                        </SummaryRow>
                    </>
                );
            }}
        />
    );
}

export default MonthlySalesByOtaTable;
