import i18next from '~plugins/i18next/i18n';
import { DEFAULT_FIRST_PAGE, OrderDirection } from '~common/constants';
import { IRoomCleaningListQuery } from './interfaces';

export enum OrderBy {
    ID = 'id',
    NAME = 'name',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
}

export const ROOM_CLEANING_LIMIT_FOR_PAGINATION = 100;

export const enum RoomCleaningListColumn {
    ID = 'id',
    NAME = 'name',
    ROOM_TYPE = 'roomTypeName',
    STATUS = 'status',
    MEMO = ' memo',
}

export const initRoomCleaningListQuery: IRoomCleaningListQuery = {
    limit: ROOM_CLEANING_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.NAME,
    orderDirection: OrderDirection.ASC,
    page: DEFAULT_FIRST_PAGE,
    cleaningStatus: undefined,
};

export const breadcrumbs = () => [
    {
        text: i18next.t('roomCleaning.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('roomCleaning.list.breadcrumbs.roomCleaning'),
    },
];

export enum CleaningStatus {
    UNCLEANED = 'uncleaned',
    CLEANED = 'cleaned',
}

export enum RoomBookingItemBookingStatus {
    NOT_ARRIVED = 'not_arrived',
    CHECKED_IN = 'checked_in',
    CHECKED_OUT = 'checked_out',
    CANCELLED = 'cancelled',
}

export enum RoomCleaningPageTabPane {
    ROOM_MANAGEMENT = 'roomManagement',
    ROOM_CLEANING_LIST = 'cleaningList',
}

export const enum CleaningOptions {
    DIRTY_ALL = 'dirty_all',
    DIRTY_SELECTION = 'dirty_selection',
    CLEAR_ALL_MEMO = 'clean_all_memo',
    CLEAR_MEMO_SELECTION = 'clear_memo_selection',
}

// CSV Export params
export const FILE_NAME = 'room-cleaning.csv';
export const EXPORT_CSV_FILE_NAME = 'room-cleaning';
export const exportColumns = ['autoGeneratedCode', 'name', 'saleItemName'];
export const i18ExportKey = 'roomCleaning.list';

export const PRINT_ROOM_CLEANING_ROW_LIMIT = 1000;
