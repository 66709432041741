import { checkMonthPeriod } from '~common/helper';
import { ShowMonth } from '~features/report/constants';
import {
    ISalesByOtaDetail,
    ISalesByOtaResponse,
    MonthData,
    MonthDataSource,
} from '../interfaces';

export const getDailyData = (
    data: ISalesByOtaResponse,
): Record<
    string,
    | Record<
          string,
          {
              current: ISalesByOtaDetail;
              previousYear: ISalesByOtaDetail;
          }
      >
    | { [key: string]: string }
> => {
    // Initialize the accumulator object
    const initialAccumulator: Record<
        string,
        | Record<
              string,
              {
                  current: ISalesByOtaDetail;
                  previousYear: ISalesByOtaDetail;
              }
          >
        | { [key: string]: string }
    > = {};

    if (!data) return initialAccumulator;
    // Reduce the data to group by dateStart and marketing channel name
    return data.items.reduce((acc, item) => {
        const { dateStart, marketingChannel, current, previousYear } = item;

        // Initialize the date if it doesn't exist in the accumulator
        if (!acc[dateStart]) {
            acc[dateStart] = {};
        }

        // Assign data to the corresponding marketing channel name
        acc[dateStart][marketingChannel.id] = {
            current,
            previousYear,
        };
        acc[dateStart]['date'] = dateStart;

        return acc;
    }, initialAccumulator);
};

export const getDailySummaryData = (
    data: ISalesByOtaResponse,
): Record<
    string,
    {
        current: ISalesByOtaDetail;
        previousYear: ISalesByOtaDetail;
        marketingChannelId: number;
    }
> => {
    const initialAccumulator: Record<
        string,
        {
            current: ISalesByOtaDetail;
            previousYear: ISalesByOtaDetail;
            marketingChannelId: number;
        }
    > = {};

    return data.totals.reduce((acc, item) => {
        const { marketingChannel, current, previousYear } = item;
        if (!marketingChannel) return acc;
        acc[marketingChannel.id] = {
            current,
            previousYear,
            marketingChannelId: marketingChannel.id,
        };
        return acc;
    }, initialAccumulator);
};

const emptyRevenueData = (): ISalesByOtaDetail => ({
    totalBookedRoom: 0,
    totalGuest: 0,
    totalChild: 0,
    totalRevenue: 0,
    avgRevenue: 0,
});

export const getMonthlyData = (
    data: ISalesByOtaResponse,
    selectedDate: string,
): MonthDataSource => {
    const dataSource: MonthDataSource = {};

    data.items.forEach((entry) => {
        const { marketingChannel, current, previousYear, dateStart, dateEnd } = entry;
        const marketingChannelId = marketingChannel.id;

        if (!dataSource[marketingChannelId]) {
            dataSource[marketingChannelId] = {
                marketingChannelName: marketingChannel.name,
                marketingChannelId: marketingChannel.id,
                lastMonth: {
                    current: emptyRevenueData(),
                    previousYear: emptyRevenueData(),
                },
                currentMonth: {
                    current: emptyRevenueData(),
                    previousYear: emptyRevenueData(),
                },
                nextMonth: {
                    current: emptyRevenueData(),
                    previousYear: emptyRevenueData(),
                },
            };
        }

        const monthPeriod = checkMonthPeriod(dateStart, dateEnd, selectedDate);

        switch (monthPeriod) {
            case ShowMonth.CURRENT_MONTH:
                dataSource[marketingChannelId].currentMonth = {
                    current,
                    previousYear: previousYear,
                };
                break;
            case ShowMonth.LAST_MONTH:
                dataSource[marketingChannelId].lastMonth = {
                    current,
                    previousYear: previousYear,
                };
                break;
            case ShowMonth.NEXT_MONTH:
                dataSource[marketingChannelId].nextMonth = {
                    current,
                    previousYear: previousYear,
                };
                break;
            default:
                break;
        }
    });

    return dataSource;
};

const emptyMonthData = (): MonthData => ({
    current: emptyRevenueData(),
    previousYear: emptyRevenueData(),
});

export const getMonthlySummaryData = (
    data: ISalesByOtaResponse,
    selectedDate: string,
): {
    lastMonth: MonthData;
    currentMonth: MonthData;
    nextMonth: MonthData;
} => {
    const summaryData = {
        lastMonth: emptyMonthData(),
        currentMonth: emptyMonthData(),
        nextMonth: emptyMonthData(),
    };

    data.totals.forEach((entry) => {
        const { current, previousYear, dateStart, dateEnd } = entry;
        const monthPeriod = checkMonthPeriod(dateStart, dateEnd, selectedDate);

        switch (monthPeriod) {
            case ShowMonth.CURRENT_MONTH:
                summaryData.currentMonth = {
                    current,
                    previousYear,
                };
                break;
            case ShowMonth.LAST_MONTH:
                summaryData.lastMonth = {
                    current,
                    previousYear,
                };
                break;
            case ShowMonth.NEXT_MONTH:
                summaryData.nextMonth = {
                    current,
                    previousYear,
                };
                break;
            default:
                break;
        }
    });

    return summaryData;
};

export const COLUMN_KEYS = {
    TOTAL_BOOKED_ROOM: 'totalBookedRoom',
    TOTAL_GUEST: 'totalGuest',
    TOTAL_CHILD: 'totalChild',
    TOTAL_REVENUE: 'totalRevenue',
    AVG_REVENUE: 'avgRevenue',
};
