import React from 'react';
import './MenuLanguage.scss';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { SupportLanguage } from '~common/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    currentLanguageSelector,
    setCurrentLanguage,
} from '~common/reducers/app.reducer';
import classNames from 'classnames';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { TooltipPlacement } from 'antd/lib/tooltip';

type Props = {
    placement: TooltipPlacement | undefined;
    wrappClassName?: string;
    dropdownClassName?: string;
    children?: React.ReactNode;
    trigger?: ('contextMenu' | 'click' | 'hover')[] | undefined;
};

export function MenuLanguage({
    placement,
    trigger,
    children,
    wrappClassName,
    dropdownClassName,
}: Props) {
    const currentLang = useAppSelector(currentLanguageSelector);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const wrapperClasses = classNames('wrapper-language', wrappClassName);
    const dropdownClasses = classNames('language-dropdown', dropdownClassName);
    return (
        <div className={wrapperClasses}>
            <DropdownMenu
                placement={placement}
                trigger={trigger}
                dropdownClassName={dropdownClasses}
                options={Object.values(SupportLanguage).map((lang) => ({
                    label: t('common.language.' + lang),
                    value: lang,
                }))}
                onClick={(value) => dispatch(setCurrentLanguage(value))}
            >
                {children || (
                    <Button className="btn-language" type="text">
                        {t('common.language.' + currentLang)}
                    </Button>
                )}
            </DropdownMenu>
        </div>
    );
}

MenuLanguage.defaultProps = {
    placement: 'bottom',
    trigger: ['click'],
};
