interface IProps {
    title: string;
    checkInStatus: string;
}

function HotelSmartSelfCheckIn({ title, checkInStatus }: IProps) {
    return (
        <div className="hotel-smart-item">
            <p className="font-weight-text-600">{title}</p>
            <p>{checkInStatus}</p>
        </div>
    );
}

export default HotelSmartSelfCheckIn;
