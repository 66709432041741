import React from 'react';
import { Input, InputProps, Form } from 'antd';
import { Controller } from '~plugins/hook-form';
import './FacilityTypePrice.scss';
import { fullWidthNumConvert, parseErrorMessage } from '~common/commonFunctions';
import { Regex } from '~common/constants';

interface CustomInput extends InputProps {
    name: string;
    label: string;
    decimal: number;
    max: number;
    maxHour: number;
    maxMinute: number;
    isInt: boolean;
    defaultValue: string | number;
    control: any;
    addonAfter: string[];
}

export function InputNumberGroup(props: CustomInput) {
    const {
        control,
        name,
        defaultValue,
        label,
        decimal,
        isInt,
        onChange,
        addonAfter,
        maxHour,
        maxMinute,
        required,
        ...rest
    } = props;
    const intPattern = new RegExp(Regex.INT_PATTERN);
    const floatPattern = new RegExp(Regex.FLOAT_PATTERN);

    return (
        <div className="custom-input-number-group-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    const triggerChange = (value: string, type: string) => {
                        field.onChange({ ...field.value, [type]: value });
                    };
                    return (
                        <Form.Item
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            required={required}
                        >
                            <Input.Group compact>
                                <Input
                                    {...field}
                                    {...rest}
                                    value={field.value.hour}
                                    addonAfter={addonAfter[0]}
                                    onChange={(event) => {
                                        let val = event.target.value;
                                        if (Regex.FULL_WIDTH_CHARACTERS.test(val)) {
                                            val = fullWidthNumConvert(val) || '';
                                        }
                                        if (val && isInt && !intPattern.test(val)) {
                                            event.target.value = '';
                                            return;
                                        }
                                        if (
                                            val &&
                                            !intPattern.test(val) &&
                                            !floatPattern.test(val)
                                        ) {
                                            event.target.value = '';
                                            return;
                                        }
                                        if (Number(val) > maxHour) {
                                            event.target.value = maxHour.toString();
                                        }
                                        triggerChange(event.target.value, 'hour');
                                    }}
                                    onBlur={(event) => {
                                        const value = event.target.value;
                                        if (Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                                            event.target.value =
                                                fullWidthNumConvert(value) || '';
                                            triggerChange(event.target.value, 'hour');
                                        }
                                    }}
                                />
                                <Input
                                    {...field}
                                    {...rest}
                                    value={field.value.minute}
                                    addonAfter={addonAfter[1]}
                                    onChange={(event) => {
                                        let val = event.target.value;
                                        if (Regex.FULL_WIDTH_CHARACTERS.test(val)) {
                                            val = fullWidthNumConvert(val) || '';
                                        }
                                        if (val && isInt && !intPattern.test(val)) {
                                            event.target.value = '';
                                            return;
                                        }
                                        if (
                                            val &&
                                            !intPattern.test(val) &&
                                            !floatPattern.test(val)
                                        ) {
                                            event.target.value = '';
                                            return;
                                        }
                                        if (Number(val) > maxMinute) {
                                            event.target.value = maxMinute.toString();
                                        }
                                        triggerChange(event.target.value, 'minute');
                                    }}
                                    onBlur={(event) => {
                                        const value = event.target.value;
                                        if (Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                                            event.target.value =
                                                fullWidthNumConvert(value) || '';
                                            triggerChange(event.target.value, 'minute');
                                        }
                                    }}
                                />
                            </Input.Group>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

InputNumberGroup.defaultProps = {
    defaultValue: '',
    isInt: true,
    decimal: 2,
    max: 99,
    maxHour: 23,
    maxMinute: 59,
    min: 0,
};
