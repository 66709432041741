export const cancellationPageJa = {
    titlePage: 'キャンセルレポート',
    breadcrumbs: {
        report: '帳票',
        cancellation: 'キャンセルレポート',
    },
    filterForm: {
        dateLabel: '集計日:',
        monthLabel: '集計月:',
        displayButton: '表示',
        viewMode: {
            label: '集計方法:',
            day: '日別',
            month: '月間',
        },
    },
    table: {
        date: '処理日',
        time: '処理時間',
        registrationTime: '登録日時',
        bookingId: '予約',
        no: '番号',
        createDate: '作成日',
        bookingMethod: '予約方法',
        checkInDate: 'C/I',
        checkOutDate: 'C/O',
        numberOfLodgingNights: '泊数',
        guestName: '宿泊者名',
        cancellationReason: {
            label: 'キャンセル理由',
            noShow: 'ノーショー',
            cancelled: 'キャンセル',
        },
        peopleInCharge: {
            label: '担当者',
            noShow: '締め業務',
        },
        memo: 'メモ',
        OTA: 'OTA',
        note: '備考',
        daysToCIn: 'までの日数',
        updateSuccess: '更新成功',
    },
    exportColumns: {
        date: '処理日',
        time: '処理時間',
        bookingId: '予約No',
        createDate: '作成日',
        bookingMethod: '予約方法',
        checkInDate: 'チェックイン日',
        numberOfLodgingNights: '泊数',
        guestName: 'ゲスト名',
        cancellationReason: 'キャンセル理由',
        peopleInCharge: '担当者',
        memo: 'メモ',
        daysToCheckInDate: 'チェックイン日までの日数',
    },
};
