import { useTranslation } from 'react-i18next';
import { Props } from '../pages/RoomBookingItemDetailPage/components/SplitBookingScheduleGrid';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { inventoryService } from '~features/inventory/services/inventory.service';
import { CacheKeys } from '~queries/queries';
import { parseDate } from '~plugins/dayjs';
import { useMemo } from 'react';
import { IStopSellingInventoryTableData } from '~features/inventory/interfaces';

interface DataType {
    key: string;
    roomType: string;
    roomTypeId: string | number;
    children?: DataType[];
}

export const useSplitBookingScheduleGrid = ({
    startDate,
    endDate,
    setSelectedDates,
    selectedDates,
    originalBookingDates,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { roomBookingId, roomBookingItemId } = useParams();
    const startDateQuery = dayjs(startDate).startOf('day');
    const endDateQuery = dayjs(endDate).startOf('day');
    const dateDiff = endDateQuery.diff(startDateQuery, 'day');
    const datesArray = Array.from({ length: dateDiff < 30 ? 30 : dateDiff }).map(
        (_, i) => {
            return startDateQuery.add(i, 'day').format('YYYY-MM-DD');
        },
    );
    const isEndDateBeforeToday = endDateQuery
        .startOf('day')
        .isBefore(dayjs().startOf('day'));

    if (isEndDateBeforeToday) {
        notification.error({
            message: t('roomBooking.splitBooking.error.checkOutDate'),
        });
        navigate(`/room-booking/${roomBookingId}/detail`);
    }

    const fetchInventories = async () => {
        const response = await inventoryService.getStoppingInventory({
            // if startDate is today or future dates, fecth inventories for the next 30 days from the startDate
            // otherwise, fetche inventories for the next 30 days from today
            startDate: startDateQuery.isSameOrAfter(dayjs())
                ? startDateQuery.fmYYYYMMDDHHmmss()
                : dayjs().startOf('day').fmYYYYMMDDHHmmss(),
            endDate: startDateQuery.isSameOrAfter(dayjs())
                ? startDateQuery.add(30, 'day').fmYYYYMMDDHHmmss()
                : dayjs().startOf('day').add(30, 'day').fmYYYYMMDDHHmmss(),
        });

        if (response.success) {
            return response.data;
        }

        throw Error(response.message);
    };

    const {
        data: inventoryData,
        isFetching,
        isError,
    } = useQuery({
        queryKey: [CacheKeys.getInventories, roomBookingItemId, startDate, endDate],
        queryFn: fetchInventories,
        gcTime: 0,
        staleTime: 0,
    });

    if (isError) {
        notification.error({
            message: t('common.somethingWentWrong'),
        });
        navigate(`/room-booking/${roomBookingId}/detail`);
    }

    const formattedInventoryData = inventoryData?.map((data) => ({
        roomTypeId: data.id,
        rooms: data.children?.map((childData) => ({
            roomId: childData.id.toString().split('_')[1],
            days: Object.entries(childData.days)?.map((day) => ({
                date: dayjs(day[0]).startOf('day').format('YYYY-MM-DD'),
                ...day[1],
            })),
        })),
    }));

    const onCheckBoxChange = (e: CheckboxChangeEvent) => {
        setSelectedDates((prevData) => {
            const dateToRemove = prevData?.find(
                (date) => date.split('_')[0] === e.target.id?.split('_')[0],
            );
            let newDates = [...(prevData || [])];

            if (dateToRemove) {
                newDates = newDates.filter(
                    (date) => date.split('_')[0] !== dateToRemove.split('_')[0],
                );
            }

            if (e.target.checked && e.target.id) {
                newDates.push(e.target.id);
            }

            if (!e.target.checked && e.target.id) {
                newDates = newDates.filter((date) => date !== e.target.id);
            }

            return newDates;
        });
    };

    type FormattedInventoryData = typeof formattedInventoryData;
    const getColumn = (
        date: string,
        formattedInventoryData: FormattedInventoryData,
        originalBookingDates: string[],
        onCheckBoxChange: (e: CheckboxChangeEvent) => void,
    ) => ({
        title: parseDate(date).format('M/D (ddd)'),
        dataIndex: date,
        key: date,
        width: 60,
        align: 'center' as const,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        shouldCellUpdate: (record: any, prevRecord: any) =>
            record[date] && record[date].checked !== prevRecord[date].checked,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (_text: { roomTypeId: number; roomId: string }, record: any) => {
            if (!record[date]) return undefined;

            const { roomTypeId, roomId } = record[date];
            const targetRoomAvailability = formattedInventoryData
                ?.find((data) => data.roomTypeId === roomTypeId)
                ?.rooms?.find((room) => room.roomId === roomId)
                ?.days.find((day) => day.date === date);

            const isPastDate = dayjs(date)
                .startOf('day')
                .isBefore(dayjs().startOf('day'));
            const isBookedOrChecked =
                targetRoomAvailability?.isBooked || targetRoomAvailability?.isChecked;
            const isOriginalBooking = originalBookingDates?.includes(
                `${date}_${roomTypeId}_${roomId}`,
            );

            return (
                <Checkbox
                    id={`${date}_${roomTypeId}_${roomId}`}
                    className={isPastDate ? 'past' : 'future'}
                    disabled={isPastDate || (!isOriginalBooking && isBookedOrChecked)}
                    checked={record[date].checked}
                    onChange={onCheckBoxChange}
                />
            );
        },
    });

    const columns: ColumnsType<DataType> = formattedInventoryData
        ? [
              {
                  title: '',
                  dataIndex: 'roomType',
                  key: 'roomType',
                  fixed: 'left',
                  width: 200,
              },
              ...datesArray.map((date) =>
                  getColumn(
                      date,
                      formattedInventoryData,
                      originalBookingDates,
                      onCheckBoxChange,
                  ),
              ),
          ]
        : [];

    const getRoomChildren = (
        data: IStopSellingInventoryTableData,
        datesArray: string[],
        selectedDates: string[],
    ): DataType[] => {
        return Object.values(data.children || {}).map((room) => {
            const roomId = room.id.toString().split('_')[1];
            const dateData = datesArray.reduce((acc, date) => {
                acc[date] = {
                    roomTypeId: Number(data.id),
                    roomId,
                    checked: selectedDates?.includes(`${date}_${data.id}_${roomId}`),
                };
                return acc;
            }, {} as Record<string, { roomTypeId: number; roomId: string; checked: boolean }>);

            return {
                key: room.name,
                roomType: room.name,
                roomTypeId: data.id,
                ...dateData,
            };
        });
    };

    const getInventoryData = (
        inventoryData: IStopSellingInventoryTableData[],
        datesArray: string[],
        selectedDates: string[],
    ): DataType[] => {
        return inventoryData.map((data) => ({
            key: data.name,
            roomType: data.name,
            roomTypeId: data.id,
            children: getRoomChildren(data, datesArray, selectedDates),
        }));
    };

    const dataSource: DataType[] = useMemo(() => {
        return inventoryData && selectedDates
            ? getInventoryData(inventoryData, datesArray, selectedDates)
            : [];
    }, [inventoryData, datesArray, selectedDates]);

    return { isFetching, columns, dataSource };
};
