import { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IPlanRoomType } from '~features/plan/interfaces';
import './PlanRoomTypePricingTab.scss';
import PlanRoomTypePricingTable from './PlanRoomTypePricingTable/PlanRoomTypePricingTable';

import { IPlanRoomTypePricing } from '~features/plan/interfaces';
import { transformPlanRoomTypePricings } from '../../../../../helper';
type Iprops = {
    planRoomType: IPlanRoomType;
};

function PlanRoomTypePricingTab({ planRoomType }: Iprops) {
    const { t } = useTranslation();
    const [pricingRoomTypeList] = useState<
        { feId: number; planRoomTypePricings: IPlanRoomTypePricing[] }[]
    >(transformPlanRoomTypePricings(planRoomType?.planRoomTypePricings || []));
    return (
        <div className="plan-room-type-pricing-detail-wrapper">
            <div className="plan-group-and-number-of-people">
                <Row>
                    <Col span={6} className="plan-detail-label">
                        {t('plan.detail.tab2.planRoomTypePricing.planGroup')}
                    </Col>
                    <Col span={6}>{planRoomType?.planGroupCode}</Col>
                    <Col span={6} className="plan-detail-label">
                        {t('plan.detail.tab2.planRoomTypePricing.people')}
                    </Col>
                    {planRoomType.peopleCount === 0 && (
                        <Col span={6}>
                            {t('plan.create.planRoomTypePricingsForm.bulk')}
                        </Col>
                    )}
                    {planRoomType.peopleCount !== 0 && (
                        <Col span={6}>
                            {planRoomType.peopleCount}
                            {t('plan.create.planRoomTypePricingsForm.person')}
                        </Col>
                    )}
                </Row>
            </div>
            <div className="plan-room-type-pricing-detail-table">
                <PlanRoomTypePricingTable dataTable={pricingRoomTypeList} />
            </div>
        </div>
    );
}

export default PlanRoomTypePricingTab;
