import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './FilterForm.scss';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';
import { useAppDispatch } from '~hooks';
import { setIsShowForm } from '~features/payment-method/reducers/paymentMethod.reducer';

function FilterForm() {
    const { t } = useTranslation();
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);
    const dispatch = useAppDispatch();

    const createPaymentMethod = () => {
        dispatch(setIsShowForm(true));
    };

    return (
        <div className="payment-method-filter-form-wrapper">
            {!isReadOnly && (
                <Button
                    type="primary"
                    id={`btn-create-payment-method`}
                    onClick={createPaymentMethod}
                >
                    <PlusOutlined />
                    <span>{t('paymentMethod.list.filterForm.buttons.create')}</span>
                </Button>
            )}
        </div>
    );
}

export default FilterForm;
