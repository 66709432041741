import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { notification, Table, Pagination, Modal } from 'antd';
import type { TableProps } from 'antd/es/table';
import React, { useEffect, useMemo, useState } from 'react';
import {
    AdminGroup,
    AntdOrderDirection,
    cellAutoGeneratedCodeStyle,
    cellTextErrorStyle,
    OrderDirection,
} from '~common/constants';
import {
    marketingChannelGroupsSelector,
    marketingChannelGroupListQuerySelector,
    setMarketingChannelGroupListQuery,
    showLoadingSelector,
    totalPageSelector,
    totalMarketingsChannelGroupSelector,
    fetchMarketingChannelGroupList,
    setIsShowUpdateMarketingChannelGroupForm,
    getMarketingChannelGroupListByIds,
} from '../../marketing-channel-group.reducer';
import {
    MarketingChannelGroupColumn,
    OrderBy,
    initMarketingChannelGroupListQuery,
} from '../../constants';
import { checkUserPermission } from '~common/commonFunctions';
import { IMarketingChannelGroup } from '../../interfaces';
import { marketingChannelGroupsService } from '../../services/marketing-channel-group.api';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '~hooks';
import { ModalConfirmDeletion } from '~components';
import { ISorter } from '~common/interfaces';
import './MarketingChannelGroupList.scss';
import { ColumnsType } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import { marketingChannelGroupSchema } from '~common/validatorSchema';

function MarketingChannelGroupList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const marketingChannelGroupList = useAppSelector(marketingChannelGroupsSelector);
    const marketingChannelGroupListQuery = useAppSelector(
        marketingChannelGroupListQuerySelector,
    );
    const loading = useAppSelector(showLoadingSelector);
    const totalMarketingsChannelGroup = useAppSelector(
        totalMarketingsChannelGroupSelector,
    );
    const pageCount = useAppSelector(totalPageSelector);
    const isReadOnly = !checkUserPermission([...AdminGroup]);

    const onChange: TableProps<IMarketingChannelGroup>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        // this is for pagination, sort, filter logic later
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setMarketingChannelGroupListQuery({
                    ...marketingChannelGroupListQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setMarketingChannelGroupListQuery({
                    ...marketingChannelGroupListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const selectedRows = useMemo(() => {
        const rows: IMarketingChannelGroup[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundMarketingChannelGroup = marketingChannelGroupList.find(
                (channelGroup) => channelGroup.id === selectedRowKey,
            );
            if (foundMarketingChannelGroup) rows.push(foundMarketingChannelGroup);
        });
        return rows;
    }, [selectedRowKeys, marketingChannelGroupList]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onChangePage = (page: number) => {
        dispatch(
            setMarketingChannelGroupListQuery({
                ...marketingChannelGroupListQuery,
                page,
            }),
        );
    };

    const shouldDisableButtonEdit = useMemo(() => {
        return selectedRows.length === 1;
    }, [selectedRows]);

    useEffect(() => {
        return () => {
            dispatch(
                setMarketingChannelGroupListQuery(
                    cloneDeep(initMarketingChannelGroupListQuery),
                ),
            );
        };
    }, []);

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await marketingChannelGroupsService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t(
                    'marketingChannelGroup.list.message.deleteChannelGroupSuccess',
                ),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const showConfirmDialog = () => {
        const canNotDeleteItems = selectedRows.filter((row) => row.isSeedData === true);
        if (canNotDeleteItems?.length) {
            Modal.error({
                title: t('marketingChannelGroup.list.initData.cantDelete'),
                content: (
                    <ul>
                        {canNotDeleteItems?.map((item) => (
                            <li key={item.autoGeneratedCode}>{item.name}</li>
                        ))}
                    </ul>
                ),
                okText: t('common.ok'),
            });
        } else {
            ModalConfirmDeletion({
                buttonCancelText: t('marketingChannelGroup.form.delete.buttonCancelText'),
                buttonDeleteText: t('marketingChannelGroup.form.delete.buttonDeleteText'),
                okButtonProps: { danger: true },
                deletedItems: selectedRows.map((row) => row.name),
                onClickButtonDelete: onConfirmDeletion,
            });
        }
    };

    const fetchData = () => {
        dispatch(fetchMarketingChannelGroupList());
    };

    useEffect(() => {
        if (
            marketingChannelGroupList.length === 0 &&
            marketingChannelGroupListQuery?.page &&
            marketingChannelGroupListQuery?.page > 1
        ) {
            const page = marketingChannelGroupListQuery?.page - 1;
            dispatch(
                setMarketingChannelGroupListQuery({
                    ...marketingChannelGroupListQuery,
                    page,
                }),
            );
        }
    }, [marketingChannelGroupList]);

    useEffect(() => {
        fetchData();
    }, [marketingChannelGroupListQuery]);

    const onClickButtonEdit = () => {
        if (selectedRows?.length !== 1) return;
        else {
            const canNotDeleteItems = selectedRows.filter(
                (row) => row.isSeedData === true,
            );
            if (canNotDeleteItems?.length) {
                Modal.error({
                    title: t('marketingChannelGroup.list.initData.cantEdit'),
                    content: (
                        <ul>
                            {canNotDeleteItems?.map((item) => (
                                <li key={item.autoGeneratedCode}>{item.name}</li>
                            ))}
                        </ul>
                    ),
                    okText: t('common.ok'),
                });
            } else {
                // Call api to get marketing Channel Group list by marketing Channel Groups ids
                dispatch(
                    getMarketingChannelGroupListByIds(
                        selectedRows.map((channelGroup) => channelGroup.id),
                    ),
                );
                // Show form to bulk edit individual marketing Channel Groups
                dispatch(setIsShowUpdateMarketingChannelGroupForm(true));
            }
        }
        return;
    };

    const editRecord = (channelGroup: IMarketingChannelGroup) => {
        if (isReadOnly) {
            return;
        }
        dispatch(getMarketingChannelGroupListByIds([channelGroup.id]));
        dispatch(setIsShowUpdateMarketingChannelGroupForm(true));
    };

    const getMarketingChannelGroupCellTextStyle = (
        marketingChannelGroup: IMarketingChannelGroup,
    ) => {
        return marketingChannelGroupSchema.isValidSync(marketingChannelGroup)
            ? {}
            : cellTextErrorStyle;
    };

    const marketingsChannelGroupColumns: ColumnsType<IMarketingChannelGroup> = [
        {
            title: t('marketingChannelGroup.list.channelGroupColumn.id'),
            width: '120px',
            onCell: () => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                };
            },
            render: (channelGroup: IMarketingChannelGroup) => {
                return <span>{channelGroup.autoGeneratedCode}</span>;
            },
            key: MarketingChannelGroupColumn.ID,
            sorter: true,
        },
        {
            title: t(
                'marketingChannelGroup.list.channelGroupColumn.marketingChannelGroupName',
            ),
            width: '80%',
            key: MarketingChannelGroupColumn.NAME,
            dataIndex: MarketingChannelGroupColumn.NAME,
            ellipsis: true,
            onCell: (marketingChannelGroup: IMarketingChannelGroup) => {
                return {
                    style: getMarketingChannelGroupCellTextStyle(marketingChannelGroup),
                };
            },
        },
    ];
    return (
        <div className="marketing-channel-group-list-wrapper">
            <div className="marketing-channel-group-list-header">
                <div className="marketing-channel-groups-header-left">
                    {!isReadOnly && selectedRows.length === 1 && (
                        <EditOutlined
                            disabled={shouldDisableButtonEdit}
                            onClick={onClickButtonEdit}
                        />
                    )}
                    {!isReadOnly && selectedRows.length > 0 && (
                        <DeleteOutlined
                            className="size-icon"
                            onClick={showConfirmDialog}
                        />
                    )}
                </div>
            </div>

            <Table
                rowSelection={rowSelection}
                columns={marketingsChannelGroupColumns}
                dataSource={marketingChannelGroupList}
                onChange={onChange}
                pagination={false}
                rowKey="id"
                loading={loading}
                scroll={{ y: 400 }}
                rowClassName={(record) => {
                    if (isReadOnly || record.isSeedData) {
                        return '';
                    }
                    return 'marketing-channel-group-row';
                }}
                onRow={(record, rowIndex) => {
                    return {
                        // click row
                        onClick: (event) => {
                            editRecord(record);
                        },
                    };
                }}
            />
            {pageCount > 1 && (
                <Pagination
                    align="center"
                    className="marketing-channel-groups-pagination"
                    defaultCurrent={marketingChannelGroupListQuery.page}
                    current={marketingChannelGroupListQuery.page}
                    total={totalMarketingsChannelGroup}
                    pageSize={marketingChannelGroupListQuery.limit}
                    onChange={onChangePage}
                />
            )}
        </div>
    );
}

export default MarketingChannelGroupList;
