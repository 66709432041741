import { useMemo } from 'react';
import { InputPrice } from '~components';
import { ReceiptFormField, ReceiptItemType } from '~features/room-booking/constants';
import { IBookingReceiptTableData } from '~features/room-booking/interfaces';

type Props = {
    control: any;
    item: IBookingReceiptTableData;
    isEditMode: boolean;
    onChangeUnitPrice: (value: number, id: string, fieldUpdate: string) => void;
};
function UnitPriceColumn(props: Props) {
    const { control, item, isEditMode, onChangeUnitPrice } = props;
    const isCanEdit = useMemo(() => {
        return (
            isEditMode &&
            !item.isCheckout &&
            !item.children &&
            item.status !== ReceiptItemType.PAYMENT
        );
    }, [isEditMode, item]);

    return isCanEdit ? (
        <>
            <InputPrice
                label=""
                name={`${item.id}.unitPrice`}
                control={control}
                onChange={(e) =>
                    onChangeUnitPrice(
                        Number(e.target?.value),
                        item.id,
                        ReceiptFormField.UNIT_PRICE,
                    )
                }
                allowClear={true}
            />
        </>
    ) : (
        <span>{item.unitPrice || item.unitPrice === 0 ? `¥${item.unitPrice}` : ''}</span>
    );
}

export default UnitPriceColumn;
