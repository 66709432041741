import React from 'react';
import { Button } from 'antd';
import './ForbiddenContent.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ForbiddenContent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onCancel = () => {
        navigate('/front-settings/room');
    };
    return (
        <div className="forbidden-content-wrapper">
            <p className="forbidden-content-title">{t('error.forbidden.title')}</p>
            <p>{t('error.forbidden.content')}</p>
            <Button onClick={onCancel} className="forbidden-content-btn">
                {t('error.forbidden.backToHome')}
            </Button>
        </div>
    );
}

export default ForbiddenContent;
