const splitReceipt = {
    home: 'ホーム',
    roomBookingList: '予約リスト',
    splitReceipt: '領収書発行',
    title: '領収書発行',
    titleInvoice: '請求書発行',
    room: '部屋',
    group: '分割',
    roomItem: '宿泊',
    facilityItem: '設備',
    splitGroup: '分割番号',
    all: '全て',
    notificationAlert: {
        warning:
            '領収書の分割数や記載内容の編集が可能です。領収書編集を行っても、明細タブの内容は変わりません。',
        notSplitDone: '領収書発行する前に、部屋アサインして下さい',
    },
    files: {
        listError: '領収書発行履歴の取得に失敗しました。',
        countError: '領収書発行番号の取得に失敗しました。',
        uploadLoading: '領収書ファイルを保存中...',
        uploadSuccess: '領収書発行が保存されました。',
        uploadError: '領収書発行の保存に失敗しました。',
    },
    selectSplitBillTypePanel: {
        placeholder: '番号を選択',
        title: '領収書発行',
        divisionType: '分割式',
        split: '分割',
        basedOnRoom: '部屋毎',
        basedOnGroup: 'グループ毎',
        confirmationText:
            '選択済み項目をグループ「{{name}}」に変更します。よろしいでしょうか?',
        buttonConfirmText: 'はい',
    },
    billToTab: {
        basedOnRoomTitle: ' 部屋毎分割を指定',
        basedOnGroupTitle: 'グループ分割を指定',
        printAll: '一括で印刷',
        issueHistory: '領収書発行履歴',
        invoiceIssueHistory: '請求書発行履歴',
        cannotPrintCancelledBooking: 'キャンセルされた予約の領収書が印刷できない',
        issueHistoryTable: {
            createDate: '作成日',
            number: '領収書 #',
            name: '領収書名',
            total: '合計',
            downloadPdf: 'PDFをダウンロード',
        },
        item: {
            title: 'グループ1',
            print: '印刷',
            billName: '宛名',
            billNameSubText: '様',
            billProviso: '但し書き',
            billProvisoSubText: 'として',
            billTotal: '金額',
        },
    },
    receiptDetailTab: {
        title: 'レシートの項目',
        invoiceTitle: '請求書の項目',
        selectGroup: '分割番号を変更',
        item: {
            receiptTableColumn: {
                date: '日付',
                bookingDetails: '詳細',
                guestName: '宿泊者',
                roomName: '部屋名',
                unitPrice: '単価',
                quantity: '数量',
                amount: '金額',
                checkOutRoom: '精算部屋',
                paymentMethod: '支払い方法',
            },
        },
        bookingDetailNoRoom: '宿泊料 (大人:{{numberOfAdults}}名)',
        bookingDetailNoChildren: '宿泊料 ({{roomName}}/大人:{{numberOfAdults}}名)',
        bookingDetailChildren:
            '宿泊料 ({{roomName}}/{{childrenTypeName}}:{{numberOfAdults}}名)',
        bookingDetailChildrenNoRoom: '宿泊料 ({{childrenTypeName}}:{{numberOfAdults}}名)',
    },
    bottomButtonGroup: {
        buttonReturnText: '戻る',
    },
    print: {
        issueDate: '発行日',
        receiptNumber: '番号',
        receipt: '領収書',
        invoiceNumber: '番号',
        invoice: '請求書',
        registeredNumber: '登録番号',
        nameSubText: '様',
        amount: '金額',
        invoiceAmount: 'ご請求額',
        tax: '内消費税等',
        provisoSubText: '但',
        stampPlaceholder: '収入印紙',
        reservationNumber: '予約番号',
        stayPeriod: '宿泊期間',
        dateText: '日付',
        reservation: '予約 #',
        bookingDetails: 'ご利用明細',
        paymentDetails: 'ご入金明細',
        roomPrice: '宿泊料',
        reducedSalesTaxRate: '軽減税率対象',
        target: '対象',
        taxAmount: '消費税',
        otherTaxAmount: 'その他',
        totalTaxAmount: '内消費税',
        table: {
            bookingDate: '日付',
            bookingDetail: '明細',
            unitPrice: '単価',
            quantity: '数量',
            amount: '金額',
            subtotal: '小計',
            total: '合計',
        },
        prepaidAmount: '事前入金',
        paidAmount: '今回の支払額',
        taxBreakDown: '内訳',
        bankAccounts: 'お振込先',
        bank: '銀行',
        branch: '支店',
        accountNumber: '口座番号',
        accountOwner: '口座名義',
        bankAccountNote: '振込手数料はお客様負担にてお願いいたします。',
    },
};

export default splitReceipt;
