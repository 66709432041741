import { Button, Form, Modal, Select } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { InputText } from '~components';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import './AddKeycardModal.scss';
import { useForm } from 'react-hook-form';
import { parseDateTime } from '~common/commonFunctions';
import { DateFormat } from '~common/constants';
import { KeycardStatus, ModalMode } from '../RoomBookingKeycardTabPane';

type Prop = {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    mode: ModalMode;
};

function AddKeycardModal({ isModalOpen, setIsModalOpen, mode }: Prop) {
    const { t } = useTranslation();
    const { control } = useForm();
    const [selectedStatus, setSelectedStatus] = useState<KeycardStatus>(
        KeycardStatus.ACTIVE,
    );

    const MOCK_DATA = {
        id: '00012',
        checkInDateTime: '2024-08-22T10:30:00Z',
        checkOutDateTime: '2024-08-23T10:30:00Z',
    };

    const statusOptions = [
        {
            label: t('roomBooking.detail.keycard.status.active'),
            value: KeycardStatus.ACTIVE,
        },
        {
            label: t('roomBooking.detail.keycard.status.returned'),
            value: KeycardStatus.RETURNED,
        },
        {
            label: t('roomBooking.detail.keycard.status.lost'),
            value: KeycardStatus.LOST,
        },
    ];

    const submitKeycard = () => {
        if (mode === ModalMode.ADD) {
            // eslint-disable-next-line no-console
            console.log('Adding key card');
        } else {
            // eslint-disable-next-line no-console
            console.log('Editing key card', selectedStatus);
        }
    };

    return (
        <Modal
            title={t(
                `roomBooking.detail.keycard.${
                    mode === ModalMode.ADD ? 'addKey' : 'editKey'
                }`,
            )}
            open={isModalOpen}
            centered
            onCancel={() => setIsModalOpen(false)}
            width={440}
            className="keycard-modal"
            footer={[
                <Button
                    key="cancel"
                    onClick={() => setIsModalOpen(false)}
                    size="large"
                    className="keycard-modal__action-btn"
                >
                    {t('roomBooking.detail.keycard.cancel')}
                </Button>,
                <Button
                    key="proceed"
                    type="primary"
                    onClick={() => submitKeycard()}
                    size="large"
                    className="keycard-modal__action-btn"
                >
                    {t('roomBooking.detail.keycard.proceed')}
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <div className="keycard-modal__wrapper">
                    <div className="keycard-modal__item">
                        <span className="keycard-modal__item-label">
                            {t('roomBooking.detail.roomItem')}:
                        </span>
                        <strong>203</strong>
                    </div>
                    <InputText
                        label="ID"
                        name={MOCK_DATA.id}
                        defaultValue="00012"
                        control={control}
                        id="keycard-form-id"
                        required
                        disabled
                    />
                    {mode === ModalMode.ADD && (
                        <div className="keycard-modal__cico-wrapper">
                            <div>{t('roomBooking.detail.keycard.cico')}</div>
                            <div className="keycard-modal__cico">
                                <div>
                                    {parseDateTime(
                                        MOCK_DATA.checkInDateTime,
                                        DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                                    )}
                                </div>
                                <ArrowRightOutlined />
                                <div>
                                    {parseDateTime(
                                        MOCK_DATA.checkOutDateTime,
                                        DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {mode === ModalMode.EDIT && (
                        <Form.Item
                            label={t('roomBooking.detail.keycard.statusColumn')}
                            name="status"
                            rules={[{ required: true }]}
                        >
                            <Select
                                onChange={(val) => setSelectedStatus(val)}
                                defaultValue={selectedStatus}
                            >
                                {statusOptions.map((status) => (
                                    <Select.Option
                                        key={status.value}
                                        value={status.value}
                                    >
                                        {status.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </div>
            </Form>
        </Modal>
    );
}

export default AddKeycardModal;
