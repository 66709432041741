import guestListJa from './ja/guest-list.locale';
import guestFormJa from './ja/guest-form.locale';
import guestCreateJa from './ja/guest-create.locale';
import guestDetailJa from './ja/guest-detail.locale';
import guestUpdateJa from './ja/guest-update.locale';

import guestListEn from './en/guest-list.locale';
import guestFormEn from './en/guest-form.locale';
import guestCreateEn from './en/guest-create.locale';
import guestDetailEn from './en/guest-detail.locale';
import guestUpdateEn from './en/guest-update.locale';

const guest = {
    en: {
        list: guestListEn,
        form: guestFormEn,
        create: guestCreateEn,
        detail: guestDetailEn,
        update: guestUpdateEn,
    },
    ja: {
        list: guestListJa,
        form: guestFormJa,
        create: guestCreateJa,
        detail: guestDetailJa,
        update: guestUpdateJa,
    },
};

export default guest;
