const saleItemImport = {
    title: 'CSV upload',
    cancelTitle: 'Do you want to cancel?',
    cancelBtn: 'Cancel',
    submitBtn: 'Upload',
    importErrorKey: {
        name: 'Item',
        price: 'Standard price',
        isShowInBooking: 'Display in preparation list',
        taxName: 'Consumption tax type',
    },
    importSaleItemSuccess: 'CSV file has been uploaded',
    error: {
        name: {
            required: 'Enter sales item',
            maxLength: 'Sales item must be at most {{maxLength}} characters',
            uniqueError: 'Sales item must be unique',
            hasNoSpecialCharacters: 'Sales item must contain no special characters',
        },
        price: {
            required: 'Enter standard price',
            max: 'Standard price must be at most {{max}}',
            min: 'Standard price must be greater than or equal to 0',
            integer: 'Standard price must be an integer',
        },
        taxName: {
            required: 'Enter consumption tax type',
            maxLength: 'Consumption tax type must be at most {{maxLength}} characters',
            hasNoSpecialCharacters:
                'Consumption tax type must contain no special characters',
        },
    },
};

export default saleItemImport;
