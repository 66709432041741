import './FilterForm.scss';
import { Radio, RadioChangeEvent } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClosingBookingStatus } from '~features/closing-booking/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    roomClosingStateSelector,
    setRoomClosingListQuery,
} from '~features/closing-booking/reducers/closing-booking.reducer';
import { DEFAULT_FIRST_PAGE } from '~common/constants';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { closingBookingListQuery } = useAppSelector(roomClosingStateSelector);

    const onChangeStatus = (e: RadioChangeEvent) => {
        dispatch(
            setRoomClosingListQuery({
                ...closingBookingListQuery,
                page: DEFAULT_FIRST_PAGE,
                status:
                    e.target.value === ClosingBookingStatus.ALL
                        ? undefined
                        : e.target.value,
            }),
        );
    };

    const status = useMemo(() => {
        return closingBookingListQuery.status || ClosingBookingStatus.ALL;
    }, [closingBookingListQuery]);

    return (
        <div className="room-closing-filter-form">
            <label className="status-label">
                {t('closingBooking.page.filterForm.status')}
            </label>
            <Radio.Group
                onChange={onChangeStatus}
                defaultValue={ClosingBookingStatus.ALL}
                value={status}
            >
                {Object.values(ClosingBookingStatus).map((value, index) => {
                    return (
                        <Radio.Button value={value} key={index}>
                            {t(`closingBooking.page.closingStatus.${value}`)}
                        </Radio.Button>
                    );
                })}
            </Radio.Group>
        </div>
    );
}
export default FilterForm;
