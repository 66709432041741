import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import './FilterForm.scss';
import { useAppDispatch } from '~hooks';
import { setShowForm } from '../../marketing-channel-group.reducer';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup } from '~common/constants';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([...AdminGroup]);

    const addChannelGroup = () => {
        dispatch(setShowForm(true));
    };

    return (
        <div className="filter-marketing-channel-group-wrapper">
            <div className="marketing-channel-group-left-block"></div>

            {!isReadOnly && (
                <div className="marketing-channel-group-btn">
                    <Button
                        type="primary"
                        id="btn-create-marketing-channel-group"
                        onClick={addChannelGroup}
                    >
                        <PlusOutlined />
                        <span className="btn-text">
                            {t('marketingChannelGroup.form.primaryButton')}
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
