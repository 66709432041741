import { UploadOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Regex } from '~common/constants';
import { ImportCsvError, InputText } from '~components';
import { ImportRoomColumn, ROOM_NAME_MAX_LENGTH } from '~features/room/constants';
import { IImportRoom } from '~features/room/interfaces';
import {
    hasImportErrorSelector,
    importCsvFileNameSelector,
    importRoomListSelector,
    setHasImportError,
} from '~features/room/room.reducer';
import { IImportError } from '~features/sale-group/interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import './ImportCsvRoom.scss';

interface IProp {
    responseError: Record<number, any>;
}
function ImportCsvRoom(prop: IProp) {
    const { responseError } = prop;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control } = useForm({});
    const hasError = useAppSelector(hasImportErrorSelector);
    const [errors, setError] = useState<Record<number, any>>({});
    const importRoomList = useAppSelector(importRoomListSelector);
    const importCsvFileName = useAppSelector(importCsvFileNameSelector);
    const importRoomColumn: ColumnsType<IImportRoom> = [
        {
            title: t('room.list.roomColumn.num'),
            width: '60px',
            key: '#',
            onCell: () => {
                return {
                    style: { backgroundColor: '#E6F6F9' },
                };
            },
            render: (room: IImportRoom) => {
                return <div>{room.order}</div>;
            },
        },
        {
            title: t('room.list.roomColumn.name'),
            key: ImportRoomColumn.NAME,
            render: (room: IImportRoom) => {
                return <div>{room.name}</div>;
            },
            onCell: (room: IImportRoom) => {
                if (errors[room.order]?.name) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
        },
        {
            title: t('room.list.roomColumn.type'),
            dataIndex: '',
            key: ImportRoomColumn.TYPE,
            render: (room: IImportRoom) => {
                return <div>{room.roomTypeName}</div>;
            },
            onCell: (room: IImportRoom) => {
                if (errors[room.order]?.roomTypeName) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
        },
    ];

    useEffect(() => {
        setError(responseError);
    }, [responseError]);

    const errorList = useMemo(() => {
        const _errorItems: IImportError[] = [];
        Object.keys(errors)?.forEach((order) => {
            Object.keys(errors[+order])?.forEach((key) => {
                if (errors[+order]?.[key]) {
                    _errorItems.push({
                        order: +order,
                        key,
                        message: errors[+order]?.[key],
                    });
                }
            });
        });
        return _errorItems;
    }, [errors]);

    useEffect(() => {
        validateData();
    }, []);

    const validateData = () => {
        let hasError = false;
        let errors = {};
        importRoomList.forEach((importRoom) => {
            if (importRoom.order !== undefined) {
                const nameError = importRoom.isDuplicated
                    ? t('room.form.import.error.name.uniqueError')
                    : validateName(importRoom.name, importRoom.order);
                const roomTypeNameError = validateRoomTypeName(importRoom.roomTypeName);

                errors = {
                    ...errors,
                    [importRoom.order]: {
                        name: nameError,
                        roomTypeName: roomTypeNameError,
                    },
                };

                if (nameError || roomTypeNameError) {
                    hasError = true;
                }
            }
        });
        dispatch(setHasImportError(hasError));
        setError(errors);
    };

    const validateName = (name: string, order: number) => {
        if (!name) {
            return t('room.form.import.error.name.required');
        }
        if (name?.length > ROOM_NAME_MAX_LENGTH) {
            return t('room.form.import.error.name.maxLength');
        }
        if (importRoomList.some((room) => room.name === name && room.order !== order)) {
            return t('room.form.import.error.name.uniqueError');
        }
        if (name.match(Regex.GENERAL_SPECIAL_CHARACTERS)) {
            return t('room.form.import.error.name.hasNoSpecialCharacters');
        }
        return '';
    };

    const validateRoomTypeName = (roomTypeName: string) => {
        if (!roomTypeName) {
            return t('room.form.import.error.roomTypeName.required');
        }
        if (roomTypeName?.length > ROOM_NAME_MAX_LENGTH) {
            return t('room.form.import.error.roomTypeName.maxLength');
        }
        if (roomTypeName.match(Regex.GENERAL_SPECIAL_CHARACTERS)) {
            return t('room.form.import.error.roomTypeName.hasNoSpecialCharacters');
        }
        return '';
    };

    return (
        <div className="import-csv-room-content">
            <div className="import-csv-room-file-name-wrapper">
                <InputText
                    label={t('common.importCsv.title')}
                    name="title"
                    control={control}
                    required
                    onKeyUp={(e) => {
                        e.preventDefault();
                    }}
                    disabled
                />
                <div className="file-name">
                    <UploadOutlined />
                    <span className="file-name-content">{importCsvFileName}</span>
                </div>
            </div>
            {hasError && (
                <ImportCsvError
                    errors={errorList}
                    i18nErrorKey={'room.list.importErrorKey'}
                />
            )}

            {importRoomList?.length !== 0 && (
                <div className="import-csv-room-total-data">
                    {t('common.importCsv.numData', { totalData: importRoomList?.length })}
                </div>
            )}

            <Table
                columns={importRoomColumn}
                dataSource={importRoomList}
                pagination={false}
                rowKey="id"
            />
        </div>
    );
}

export default ImportCsvRoom;
