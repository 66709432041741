import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import {
    ILocalTaxSummaryQuery,
    IReportLocalTaxDetailState,
    IReportTaxParams,
} from '../interfaces';
import { reportService } from '../report.service';
import { TaxTypes } from '../constants';

const initialState: IReportLocalTaxDetailState = {
    showLocalTaxDetailTable: false,
    showLoadingLocalTaxDetailTable: false,
    localTaxList: [],
    selectedMonth: null,
    selectedTaxTypeState: TaxTypes.TOKYO_TAX,
};

export const getLocalTaxDetailList = createAsyncThunk(
    'localTaxDetail/getLocalTaxDetailList',
    async (datePeriod: ILocalTaxSummaryQuery) => {
        return await reportService.getTaxSummary(datePeriod);
    },
);

export const getReportTax = createAsyncThunk(
    'localTaxDetail/getReportTax',
    async (params: IReportTaxParams) => {
        return await reportService.getTaxReport(params);
    },
);

const localTaxDetailSlice = createSlice({
    name: 'local-tax-detail',
    initialState,
    reducers: {
        setShowLocalTaxDetailTable: (state, action) => {
            state.showLocalTaxDetailTable = action.payload;
        },
        setSelectedMonth: (state, action) => {
            state.selectedMonth = action.payload;
        },
        setSelectedTaxTypeState: (state, action) => {
            state.selectedTaxTypeState = action.payload;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLocalTaxDetailList.pending, (state, action) => {
            state.showLoadingLocalTaxDetailTable = true;
        });
        builder.addCase(getLocalTaxDetailList.fulfilled, (state, action) => {
            state.localTaxList = action.payload?.data || [];
            state.showLoadingLocalTaxDetailTable = false;
        });
    },
});

export const {
    setShowLocalTaxDetailTable,
    setSelectedMonth,
    resetState,
    setSelectedTaxTypeState,
} = localTaxDetailSlice.actions;

export const localTaxDetailSelector = (state: AppState) => {
    return state.reportLocalTaxDetail;
};

export default localTaxDetailSlice.reducer;
