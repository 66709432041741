import i18next from '~plugins/i18next/i18n';

export const breadcrumbs = () => [
    {
        text: i18next.t('smtpSetting.smtpForm.breadcrumbs.home'),
    },
    {
        text: i18next.t('smtpSetting.smtpForm.breadcrumbs.BOsetting'),
    },
    {
        text: i18next.t('smtpSetting.smtpForm.breadcrumbs.infrastructureSettings'),
    },
    {
        text: i18next.t('smtpSetting.smtpForm.breadcrumbs.smtpSetting'),
    },
];

export enum SMPT_Encryption {
    YES = 'yes',
    NO = 'no',
}

export enum ConnectionSecurity {
    SSL_TLS = 'SSL/TLS',
}
