import { CaretDownOutlined } from '@ant-design/icons';
import { ISaleItemDropdown } from '~features/sale-item/interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showConfirm } from '~common/notification';
import {
    InputNumber,
    InputPrice,
    InputText,
    SelectSaleItem,
    SingleDatePicker,
} from '~components';
import {
    IFacilityBookingReceiptDetail,
    IFacilityBookingReceiptItem,
    IFacilityBookingReceiptItemDetail,
} from '~features/facility-booking/interfaces';
import {
    MAX_LENGTH_PRICE,
    ReceiptByGroupMapIndex,
    ReceiptItemDetailType,
    ReceiptItemType,
    SplitRoomBookingReceiptColumn,
} from '~features/room-booking/constants';
import {
    convertSplitReceipt,
    updateNewReceiptItemDetail,
} from '~features/room-booking/helper.split-receipt';
import {
    IGroup,
    IPrintingReceiptItemDetail,
    IReceipt,
    IReceiptItemDetails,
    IRoomBookingItemReceipt,
    IRoomBookingItemsReceiptItem,
    ISplitRoomBookingReceiptTableData,
} from '~features/room-booking/interfaces';
import { roomBookingStateSelector } from '~features/room-booking/reducers/room-booking.reducer';
import {
    addFacilityBookingReceiptItemsInGroupMap,
    addRoomBookingReceiptItemsInGroupMap,
    receiptByGroupMapSelector,
    removeFacilityBookingReceiptItemsInGroupMap,
    removeRoomBookingReceiptItemsInGroupMap,
    setReceiptByGroupMap,
    updateFacilityBookingReceiptItemsInGroupAllMap,
    updateRoomBookingReceiptItemsInGroupAllMap,
} from '~features/room-booking/reducers/split-receipt.reducer';
import { updateSplitReceiptSchema } from '~features/room-booking/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import SelectGroup from '../SelectGroup/SelectGroup';
import './ReceiptDetailItem.scss';
import { cloneDeep } from 'lodash';
import { INPUT_TEXT_MAX_LENGTH, cellAutoGeneratedCodeStyle } from '~common/constants';
import customDayjs, { parseDate } from '~plugins/dayjs';

interface IProps {
    group: IGroup | null;
    groupList: IGroup[];
    receipt: IReceipt;
}

function ReceiptDetailItem(props: IProps) {
    const { groupList, receipt } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { selectedRoomBookingDetail } = useAppSelector(roomBookingStateSelector);

    const receiptByGroupMap = useAppSelector(receiptByGroupMapSelector);

    const [receiptTableData, setReceiptTableData] = useState<
        ISplitRoomBookingReceiptTableData[]
    >([]);
    const [expandRowIds, setExpandRowIds] = useState<string[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const { control, setValue, getValues } = useForm({
        resolver: yupResolver(updateSplitReceiptSchema),
    });
    const [isLoaded, setIsLoaded] = useState(false);

    const isSingleRoomBooking = useMemo(() => {
        return !(
            receipt?.roomBooking?.roomBookingItems &&
            receipt?.roomBooking?.roomBookingItems?.length > 1
        );
    }, [receipt]);

    useEffect(() => {
        const tableData = convertSplitReceipt(receipt, selectedRoomBookingDetail);
        setReceiptTableData(tableData);
        setExpandRowIds(
            tableData.map((receipt) => {
                return receipt.id;
            }),
        );
    }, [receipt, selectedRoomBookingDetail]);

    useEffect(() => {
        receiptTableData.forEach((item) => {
            item.children?.forEach((item) => {
                if (isLoaded) return;
                setValue(`${item.id}.unitPrice`, item.unitPrice);
                setValue(`${item.id}.quantity`, item.quantity);
                setValue(`${item.id}.amount`, item.amount);
                if (getValues(`${item.id}.amount`)) {
                    setIsLoaded(true);
                }
            });
        });
    }, [receiptTableData]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const receiptRowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    /**
     * @param fromGroupId old group of the receiptItemDetails (sale items, etc.)
     * @param toGroupId the group receiptItemDetails will be moved in
     * @param roomBookingItem
     * @param receiptItem
     * @param receiptItemDetails
     *
     * This methods remove receiptItemDetails in the room booking receipt of the old group (if existed)
     * Then add receiptItemDetails to the room booking receipt of the new group
     * Finally, update groupId of the receiptItemDetails in the key: 'ALL' of the group map to the new groupId
     */
    const moveRoomBookingReceiptItems = (
        fromGroupId: string | undefined,
        toGroupId: string,
        roomBookingItem: IRoomBookingItemReceipt,
        receiptItem: IRoomBookingItemsReceiptItem,
        receiptItemDetails: IReceiptItemDetails[],
    ) => {
        if (fromGroupId) {
            dispatch(
                removeRoomBookingReceiptItemsInGroupMap({
                    groupId: fromGroupId?.toString() || null,
                    roomBookingItem,
                    receiptItem,
                    receiptItemDetails,
                }),
            );
        }

        dispatch(
            addRoomBookingReceiptItemsInGroupMap({
                groupId: toGroupId?.toString() || null,
                roomBookingItem,
                receiptItem,
                receiptItemDetails,
            }),
        );

        dispatch(
            updateRoomBookingReceiptItemsInGroupAllMap({
                groupId: toGroupId?.toString() || null,
                roomBookingItem,
                receiptItem,
                receiptItemDetails,
            }),
        );
    };

    /**
     * @param fromGroupId old group of the receiptItemDetails (sale items, etc.)
     * @param toGroupId the group receiptItemDetails will be moved in
     * @param facilityBooking
     * @param receiptItem
     * @param receiptItemDetails
     *
     * This methods remove receiptItemDetails in the facility booking receipt of the old group (if existed)
     * Then add receiptItemDetails to the facility booking receipt of the new group
     * Finally, update groupId of the receiptItemDetails in the key: 'ALL' of the group map to the new groupId
     */
    const moveFacilityBookingReceiptItems = (
        fromGroupId: string | undefined,
        toGroupId: string,
        facilityBooking: IFacilityBookingReceiptDetail,
        receiptItem: IFacilityBookingReceiptItem,
        receiptItemDetails: IFacilityBookingReceiptItemDetail[],
    ) => {
        if (fromGroupId) {
            dispatch(
                removeFacilityBookingReceiptItemsInGroupMap({
                    groupId: fromGroupId?.toString() || null,
                    facilityBooking,
                    receiptItem,
                    receiptItemDetails,
                }),
            );
        }

        dispatch(
            addFacilityBookingReceiptItemsInGroupMap({
                groupId: toGroupId?.toString() || null,
                facilityBooking,
                receiptItem,
                receiptItemDetails,
            }),
        );

        dispatch(
            updateFacilityBookingReceiptItemsInGroupAllMap({
                groupId: toGroupId?.toString() || null,
                facilityBooking,
                receiptItem,
                receiptItemDetails,
            }),
        );
    };

    const _showChangeGroupReceiptItemsConfirm = (groupId: string) => {
        const toGroup = groupList.find((r) => r.id === groupId);
        showConfirm({
            title: t(
                'roomBooking.splitReceipt.selectSplitBillTypePanel.confirmationText',
                {
                    name: toGroup?.name,
                },
            ),
            cancelText: t('common.buttonCancelText'),
            okText: t(
                'roomBooking.splitReceipt.selectSplitBillTypePanel.buttonConfirmText',
            ),
            onOk() {
                onChangeGroupReceiptItems(groupId);
            },
            onCancel() {
                setSelectedRowKeys([]);
            },
        });
    };

    const onChangeGroupReceiptItems = (groupId: string) => {
        changeSelectedRoomBookingItemsGroup(groupId);
        changeSelectedFacilityBookingItemsGroup(groupId);
        setSelectedRowKeys([]);
    };

    const changeSelectedRoomBookingItemsGroup = (groupId: string) => {
        receipt.roomBooking.roomBookingItems.forEach((roomBookingItem) => {
            roomBookingItem?.receiptItems?.forEach((receiptItem) => {
                const toUpdateReceiptItemDetails = receiptItem.receiptItemDetails.filter(
                    (receiptItemDetail) => {
                        return selectedRowKeys.includes(
                            `hol_${roomBookingItem.id}_${receiptItem.id}_${receiptItemDetail.id}`,
                        );
                    },
                );

                // Group receiptItemDetails to group of receiptItemDetail with the same groupId
                const groupIdMap: { [id: string]: IReceiptItemDetails[] } = {};

                for (const receiptItemDetail of toUpdateReceiptItemDetails) {
                    const { groupId } = receiptItemDetail;
                    if (!groupId) continue;
                    if (groupIdMap[groupId]) {
                        groupIdMap[groupId].push(receiptItemDetail);
                    } else {
                        groupIdMap[groupId] = [receiptItemDetail];
                    }
                }

                // Remove receiptItemDetails in the group of the group list
                // We don't use `moveRoomBookingReceiptItems` method because
                // There have a chance of receiptItemDetails when change group in bulk have different group
                Object.keys(groupIdMap).forEach((groupId) => {
                    dispatch(
                        removeRoomBookingReceiptItemsInGroupMap({
                            groupId,
                            roomBookingItem,
                            receiptItem,
                            receiptItemDetails: groupIdMap[groupId],
                        }),
                    );
                });

                dispatch(
                    addRoomBookingReceiptItemsInGroupMap({
                        groupId,
                        roomBookingItem,
                        receiptItem,
                        receiptItemDetails: toUpdateReceiptItemDetails,
                    }),
                );

                dispatch(
                    updateRoomBookingReceiptItemsInGroupAllMap({
                        groupId,
                        roomBookingItem,
                        receiptItem,
                        receiptItemDetails: toUpdateReceiptItemDetails,
                    }),
                );
            });
        });
    };

    const changeSelectedFacilityBookingItemsGroup = (groupId: string) => {
        receipt.facilityBookings.forEach((facilityBooking) => {
            facilityBooking.receiptItems.forEach((receiptItem) => {
                const toUpdateReceiptItemDetails = receiptItem.receiptItemDetails.filter(
                    (receiptItemDetail) => {
                        return selectedRowKeys.includes(
                            `fac_${facilityBooking.id}_${receiptItem.id}_${receiptItemDetail.id}`,
                        );
                    },
                );

                // Group receiptItemDetails to group of receiptItemDetail with the same groupId
                const groupIdMap: { [id: string]: IFacilityBookingReceiptItemDetail[] } =
                    {};

                for (const receiptItemDetail of toUpdateReceiptItemDetails) {
                    const { groupId } = receiptItemDetail;
                    if (!groupId) continue;
                    if (groupIdMap[groupId]) {
                        groupIdMap[groupId].push(receiptItemDetail);
                    } else {
                        groupIdMap[groupId] = [receiptItemDetail];
                    }
                }

                // Remove receiptItemDetails in the group of the group list
                // We don't use `moveFacilityBookingReceiptItems` method because
                // There have a chance of receiptItemDetails when change group in bulk have different group
                Object.keys(groupIdMap).forEach((groupId) => {
                    dispatch(
                        removeFacilityBookingReceiptItemsInGroupMap({
                            groupId,
                            facilityBooking,
                            receiptItem,
                            receiptItemDetails: groupIdMap[groupId],
                        }),
                    );
                });

                dispatch(
                    addFacilityBookingReceiptItemsInGroupMap({
                        groupId,
                        facilityBooking,
                        receiptItem,
                        receiptItemDetails: toUpdateReceiptItemDetails,
                    }),
                );

                dispatch(
                    updateFacilityBookingReceiptItemsInGroupAllMap({
                        groupId,
                        facilityBooking,
                        receiptItem,
                        receiptItemDetails: toUpdateReceiptItemDetails,
                    }),
                );
            });
        });
    };

    const onChangeSingleReceiptItem = (
        previousGroupId: string | undefined,
        groupId: string,
        item: ISplitRoomBookingReceiptTableData,
    ) => {
        setSelectedRowKeys(selectedRowKeys.filter((key) => key !== item.id));
        if (isRoomBookingReceiptItem(item.id)) {
            let receiptItemDetails = [item.receiptItemDetail as IReceiptItemDetails];
            if (item.receiptItemDetail?.type === ReceiptItemDetailType.STAY_PRICE) {
                receiptItemDetails = (
                    item.receiptItem?.receiptItemDetails as IReceiptItemDetails[]
                )?.filter(
                    (item) =>
                        item.type === ReceiptItemDetailType.STAY_PRICE ||
                        item.type === ReceiptItemDetailType.SALE_ITEM,
                );
            }

            moveRoomBookingReceiptItems(
                previousGroupId?.toString(),
                groupId,
                item.roomBookingItem!,
                item.receiptItem as IRoomBookingItemsReceiptItem,
                receiptItemDetails,
            );
            return;
        }

        if (isFacilityBookingReceiptItem(item.id)) {
            moveFacilityBookingReceiptItems(
                previousGroupId?.toString(),
                groupId,
                item.facilityBooking!,
                item.receiptItem as IFacilityBookingReceiptItem,
                [item.receiptItemDetail as IFacilityBookingReceiptItemDetail],
            );
            return;
        }
    };

    const expandRow = (expanded: boolean, record: ISplitRoomBookingReceiptTableData) => {
        if (expanded) {
            setExpandRowIds([...expandRowIds, record.id]);
        } else {
            const _expandRowIds = expandRowIds.filter((id) => {
                return id !== record.id;
            });
            setExpandRowIds([..._expandRowIds]);
        }
    };

    const isRoomBookingReceiptItem = (id: string) => {
        return id.split('_')?.[0] === 'hol';
    };

    const isFacilityBookingReceiptItem = (id: string) => {
        return id.split('_')?.[0] === 'fac';
    };

    const isId = (item: ISplitRoomBookingReceiptTableData) => {
        return item.name;
    };

    const isPaymentType = (item: ISplitRoomBookingReceiptTableData) => {
        return item?.status === ReceiptItemType.PAYMENT;
    };

    const displayBookingId = (item: ISplitRoomBookingReceiptTableData) => {
        if (item.name) {
            if (isSingleRoomBooking) {
                return `${t(item.name)}`;
            }
            return `${t(item.name)}${(item.index || 0) + 1} ${item.autoGeneratedCode}`;
        }
        return '';
    };

    const onChangeSaleItem =
        (item: ISplitRoomBookingReceiptTableData) => (newSaleItem: ISaleItemDropdown) => {
            const { groupId, receiptItem } = item;

            const updatedReceipt = cloneDeep(receiptByGroupMap);
            updatedReceipt[groupId!].receipt.roomBooking.roomBookingItems.forEach(
                (roomBookingItem) => {
                    const updatedReceiptItem = roomBookingItem.receiptItems?.find(
                        (item) => item.id === receiptItem?.id,
                    );
                    if (updatedReceiptItem?.receiptItemDetails) {
                        const updatedReceiptItemDetail =
                            updatedReceiptItem.receiptItemDetails.find(
                                (receiptItemDetail) =>
                                    receiptItemDetail.id === item.receiptItemDetail?.id,
                            );
                        const { saleItem } = updatedReceiptItemDetail || {};
                        if (!saleItem) return;
                        saleItem.id = newSaleItem.id;
                        saleItem.tax = newSaleItem.tax;
                        saleItem.name = newSaleItem.name;
                        saleItem.price = newSaleItem.price;
                        saleItem.taxId = newSaleItem.tax?.id;
                    }
                },
            );

            dispatch(setReceiptByGroupMap(updatedReceipt));
        };

    const updateReceiptItemDetail = (
        item: ISplitRoomBookingReceiptTableData,
        newReceiptItemDetail: IPrintingReceiptItemDetail,
    ) => {
        const updatedReceipt = cloneDeep(receiptByGroupMap);
        updateNewReceiptItemDetail(
            updatedReceipt[item.groupId!],
            item,
            newReceiptItemDetail,
        );
        updateNewReceiptItemDetail(
            updatedReceipt[ReceiptByGroupMapIndex.ALL],
            item,
            newReceiptItemDetail,
        );
        dispatch(setReceiptByGroupMap(updatedReceipt));
    };

    const roomBookingReceiptColumns: ColumnsType<ISplitRoomBookingReceiptTableData> = [
        {
            title: t('roomBooking.splitReceipt.splitGroup'),
            onCell: (item: ISplitRoomBookingReceiptTableData) => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                    colSpan: isId(item) ? 4 : 1,
                };
            },
            render: (item: ISplitRoomBookingReceiptTableData) => {
                return isId(item) ? (
                    <span>{displayBookingId(item)}</span>
                ) : (
                    <SelectGroup
                        selectedGroupId={item.groupId || undefined}
                        groupList={groupList}
                        onChangeValue={(
                            previousValue: string | undefined,
                            nextValue: string,
                        ) => onChangeSingleReceiptItem(previousValue, nextValue, item)}
                        style={{ width: '100%' }}
                    />
                );
            },
            key: SplitRoomBookingReceiptColumn.SPLIT_GROUP,
            width: '90px',
        },
        {
            title: t(
                'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.date',
            ),
            ellipsis: true,
            onCell: (item: ISplitRoomBookingReceiptTableData) => {
                return {
                    colSpan: isId(item) ? 0 : 1,
                };
            },
            render: (item: ISplitRoomBookingReceiptTableData) => {
                return (
                    <SingleDatePicker
                        required
                        name={`${item.id}.date`}
                        defaultValue={customDayjs(item.bookingDate)}
                        control={control}
                        disabledDate={(current) =>
                            current.isBefore(
                                customDayjs(item.roomBookingItem?.startDateOfStay),
                                'day',
                            ) ||
                            current.isAfter(
                                parseDate(item.roomBookingItem?.endDateOfStay),
                                'day',
                            )
                        }
                        onChange={(_, printDate) => {
                            updateReceiptItemDetail(item, { printDate });
                        }}
                    />
                );
            },
            key: SplitRoomBookingReceiptColumn.DATE,
            width: '160px',
        },
        {
            title: t(
                'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.bookingDetails',
            ),
            ellipsis: true,
            onCell: (item: ISplitRoomBookingReceiptTableData) => {
                return {
                    colSpan: isId(item) ? 0 : 1,
                };
            },
            render: (item: ISplitRoomBookingReceiptTableData) => {
                return item.saleItem?.id ? (
                    <SelectSaleItem
                        label=""
                        placeholder={t('roomBooking.detail.receipt.saleItem.placeholder')}
                        name={`${item.id}.saleItemId`}
                        control={control}
                        required
                        dropdownStyle={{
                            minWidth: '400px',
                            maxWidth: '600px',
                        }}
                        showSearch={true}
                        optionLabelProp="label"
                        filterOption={(input, option) => {
                            return ((option!.label || '') as unknown as string)
                                .toLowerCase()
                                .includes(input.toLowerCase());
                        }}
                        onChange={onChangeSaleItem(item)}
                        selectedValue={item.saleItem?.id || undefined}
                        selectedSaleItemIds={[]}
                        popupMatchSelectWidth={false}
                    />
                ) : (
                    <InputText
                        label=""
                        placeholder={t(
                            'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.bookingDetails',
                        )}
                        name={`${item.id}.bookingDetail`}
                        control={control}
                        defaultValue={item.bookingDetail}
                        maxLength={INPUT_TEXT_MAX_LENGTH}
                        required
                        onBlur={(e) => {
                            updateReceiptItemDetail(item, {
                                bookingDetail: e.target.value,
                            });
                        }}
                    />
                );
            },
            key: SplitRoomBookingReceiptColumn.BOOKING_DETAILS,
            width: '220px',
        },
        {
            title: t(
                'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.guestName',
            ),
            ellipsis: true,
            onCell: (item: ISplitRoomBookingReceiptTableData) => {
                return {
                    colSpan: isId(item) ? 0 : 1,
                };
            },
            render: (item: ISplitRoomBookingReceiptTableData) => {
                return <>{item.guest?.yomigana}</>;
            },
            key: SplitRoomBookingReceiptColumn.GUEST_NAME,
        },
        {
            title: t(
                'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.roomName',
            ),
            ellipsis: true,
            onCell: (item: ISplitRoomBookingReceiptTableData) => {
                return {
                    colSpan: isId(item) ? 0 : 1,
                };
            },
            render: (item: ISplitRoomBookingReceiptTableData) => {
                return <>{item.room?.name}</>;
            },
            key: SplitRoomBookingReceiptColumn.ROOM_NAME,
        },
        {
            title: t(
                'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.unitPrice',
            ),
            render: (item: ISplitRoomBookingReceiptTableData) =>
                !isId(item) &&
                item.status !== ReceiptItemType.PAYMENT && (
                    <InputPrice
                        label=""
                        prefix="¥"
                        name={`${item.id}.unitPrice`}
                        maxLength={MAX_LENGTH_PRICE}
                        control={control}
                        allowClear
                        onChange={(e) => {
                            updateReceiptItemDetail(item, {
                                unitPrice: Number(e.target.value),
                            });
                        }}
                    />
                ),
            key: SplitRoomBookingReceiptColumn.UNIT_PRICE,
            width: '140px',
        },
        {
            title: t(
                'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.quantity',
            ),
            render: (item: ISplitRoomBookingReceiptTableData) =>
                !isId(item) &&
                item.status !== ReceiptItemType.PAYMENT && (
                    <InputNumber
                        label=""
                        name={`${item.id}.quantity`}
                        control={control}
                        allowClear
                        onChange={(e) =>
                            updateReceiptItemDetail(item, {
                                quantity: Number(e.target.value),
                            })
                        }
                    />
                ),
            key: SplitRoomBookingReceiptColumn.QUANTITY,
            width: '110px',
        },
        {
            title: t(
                'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.amount',
            ),
            render: (item: ISplitRoomBookingReceiptTableData) =>
                !isId(item) &&
                (isPaymentType(item) ? (
                    <InputNumber
                        label=""
                        name={`${item.id}.amount`}
                        control={control}
                        allowClear
                        onChange={(e) =>
                            updateReceiptItemDetail(item, {
                                amount: Number(e.target.value),
                            })
                        }
                    />
                ) : (
                    <span className="total-earnings">{`¥${item.amount}`}</span>
                )),
            key: SplitRoomBookingReceiptColumn.AMOUNT,
            width: '110px',
        },
        {
            title: t(
                'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.checkOutRoom',
            ),
            ellipsis: true,
            render: (item: ISplitRoomBookingReceiptTableData) => {
                return <>{item.checkOutRoom?.name}</>;
            },
            key: SplitRoomBookingReceiptColumn.CHECK_OUT_ROOM,
        },
        {
            title: t(
                'roomBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.paymentMethod',
            ),
            ellipsis: true,
            render: (item: ISplitRoomBookingReceiptTableData) => {
                return <>{item.paymentMethod?.name}</>;
            },
            key: SplitRoomBookingReceiptColumn.PAYMENT_METHOD,
        },
    ];

    return (
        <div className="split-room-booking-receipt-detail-item-wrapper">
            <div className="change-group-booking-receipt-select">
                {!!selectedRowKeys.length && (
                    <>
                        <span>
                            {t('roomBooking.splitReceipt.receiptDetailTab.selectGroup')}
                        </span>
                        <SelectGroup
                            groupList={groupList}
                            onChange={_showChangeGroupReceiptItemsConfirm}
                            style={{ minWidth: '120px' }}
                        />
                    </>
                )}
            </div>
            <Table
                rowSelection={{
                    ...receiptRowSelection,
                    checkStrictly: false,
                }}
                columns={roomBookingReceiptColumns}
                dataSource={receiptTableData}
                pagination={false}
                rowClassName={(record) => {
                    return record.children
                        ? 'row-expandable row-sale-item'
                        : 'row-sale-item';
                }}
                expandable={{
                    expandRowByClick: true,
                    expandedRowKeys: expandRowIds,
                    onExpand: expandRow,
                    expandIcon: ({ expanded, record }) =>
                        record?.level === 0 ? (
                            <span className="expand-icon">
                                <CaretDownOutlined
                                    className={classNames({
                                        active: !expanded,
                                        inactive: expanded,
                                    })}
                                />
                            </span>
                        ) : (
                            <></>
                        ),
                }}
                rowKey="id"
            />
        </div>
    );
}

export default ReceiptDetailItem;
