import { TreeSelect } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { InputSearch } from '~components';
import {
    AuditLogActions,
    AuditLogModules,
    SearchField,
} from '~features/bo-operation-history/constants';
import {
    historyListQuerySelector,
    setKeyword,
    setSelectedHistoryList,
    setSelectedRowKeys,
    setSelectKeyword,
} from '~features/bo-operation-history/reducers/history-list.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './FilterForm.scss';
import SelectField from './SelectField/SelectField';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [searchField, setSearchField] = useState<SearchField>(
        SearchField.CREATED_BY_EMAIL,
    );
    const [expandedModules, setExpandedModules] = useState<string[]>([]);

    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        dispatch(setSelectedHistoryList([]));
        dispatch(setSelectedRowKeys([]));
    };

    const onChangeSearchField = (field: SearchField) => {
        setSearchField(field);
        dispatch(setKeyword(''));
        dispatch(setSelectedRowKeys([]));
        dispatch(setSelectedHistoryList([]));
    };

    const onChangeSelectSearchField = (value: string) => {
        dispatch(setSelectKeyword(value));
        dispatch(setSelectedRowKeys([]));
        dispatch(setSelectedHistoryList([]));
    };

    const historyListQuery = useAppSelector(historyListQuerySelector);

    const getInputSearch = () => {
        return (
            <InputSearch
                onSearch={onSearch}
                placeholder={t('common.searchPlaceholder')}
                defaultValue={historyListQuery.keyword}
                maxLength={INPUT_TEXT_MAX_LENGTH}
            />
        );
    };

    const onTreeExpand = (expandedKeys: any) => {
        if (expandedKeys.length > expandedModules.length) {
            dispatch(setSelectKeyword(expandedKeys[expandedKeys.length - 1]));
            dispatch(setSelectedRowKeys([]));
            dispatch(setSelectedHistoryList([]));
        }
        setExpandedModules(expandedKeys);
    };

    const getSelectSearch = () => {
        const historySelectTreeData: BaseOptionType[] = Object.values(
            AuditLogModules,
        ).map((module) => {
            return {
                value: module,
                title: t(`boHistory.list.filter.${module}`),
                selectable: false,
                children: Object.values(AuditLogActions)
                    .filter(
                        (action) =>
                            !t(
                                `boHistory.list.activityTitle.${action}${module}`,
                            ).includes('.'),
                    )
                    .map((action) => ({
                        value: `${action}&${module}`,
                        title: t(`boHistory.list.activityTitle.${action}${module}`),
                    })),
            };
        });
        return (
            <TreeSelect
                className="history-select-search-field"
                placeholder={t('common.searchPlaceholder')}
                onChange={onChangeSelectSearchField}
                allowClear
                showSearch={true}
                treeData={historySelectTreeData}
                treeExpandAction="click"
                onTreeExpand={onTreeExpand}
            />
        );
    };

    const getSearchFieldForm: {
        [field: string]: () => JSX.Element;
    } = {
        [SearchField.CREATED_BY_EMAIL]: getInputSearch,
        [SearchField.ACTIVITY_TITLE]: getSelectSearch,
    };

    return (
        <div className="bo-history-filter-form-wrapper">
            <SelectField onChange={onChangeSearchField} />
            {getSearchFieldForm[searchField]()}
        </div>
    );
}

export default FilterForm;
