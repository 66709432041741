import { Button, Layout, notification, Space } from 'antd';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { STAFF_LAYOUT_HEADER_HEIGHT, TOP_BAR_HEIGHT } from '~common/constants';
import { showConfirm } from '~common/notification';
import { StaffLayoutHeader } from '~components';
import { InventoryGrid } from '~features/inventory/components/InventoryGrid/InventoryGrid';
import StoppingRoomCountForm from '~features/inventory/components/StoppingRoomCountForm/StoppingRoomCountForm';
import {
    breadcrumbs,
    INVENTORY_PAGE_FOOTER_HEIGHT,
    INVENTORY_PAGE_PADDING,
    StoppingInventoryType,
} from '~features/inventory/constants';
import {
    getStartDateAndEndDateByDay,
    mapInventoryGridItem,
} from '~features/inventory/helper';
import { IInventoryDayItem, IStoppingRoomItem } from '~features/inventory/interfaces';
import { setIsShowBulkStopSellingForm } from '~features/inventory/reducers/inventory-stop-selling.reducer';
import {
    bulkStopInventoryInsertRoomType,
    getInventoryListData,
    inventoryListStateSelector,
    isErrorGridDataSelector,
    resetState,
    setErrorGridData,
    setInventoryForm,
    setIsEditMode,
} from '~features/inventory/reducers/inventory.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import BulkStopSellingModal from '../StopSellingPage/components/BulkStopSellingModal/BulkStopSellingModal';
import './InventoryListPage.scss';

function InventoryListPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isEditMode, inventoryList, inventoryGridData, showLoading } = useAppSelector(
        inventoryListStateSelector,
    );
    const isErrorGridData = useAppSelector(isErrorGridDataSelector);

    const oldFormData = useRef<IInventoryDayItem>({});
    useEffect(() => {
        return () => {
            dispatch(resetState());
            oldFormData.current = {};
            dispatch(setIsShowBulkStopSellingForm(false));
        };
    }, []);

    const _bulkStopInventory = useCallback(
        async (body: IStoppingRoomItem[]) => {
            const response = await dispatch(bulkStopInventoryInsertRoomType(body));
            if (bulkStopInventoryInsertRoomType.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('inventory.list.message.updateSuccess'),
                    });
                    dispatch(setIsEditMode(false));
                    dispatch(getInventoryListData());
                } else {
                    notification.error({
                        message: response.payload?.message || '',
                    });
                }
            }
        },
        [dispatch, t],
    );

    const mapCurrentForm = () => {
        const currentForm = {};
        inventoryGridData.forEach((item) => {
            Object.keys(item.day)?.forEach((date) => {
                Object.assign(currentForm, {
                    [`${item.roomType.id}_${date}`]: {
                        stoppingRooms: item?.day?.[date]?.stoppingRooms,
                    },
                });
            });
        });
        return currentForm;
    };

    const mapOldForm = () => {
        const oldForm = {};
        inventoryList.forEach((roomTypeData) => {
            const roomTypeName = Object.keys(roomTypeData)[0];
            roomTypeData[roomTypeName].forEach((data) => {
                const mappedDate = parseDate(data.day)?.fmYYYYMMDD();
                Object.assign(oldForm, {
                    [`${data.roomTypeId}_${mappedDate}`]: {
                        stoppingRooms: data?.stoppingRooms,
                    },
                });
            });
        });
        return oldForm;
    };

    const makeFormData = () => {
        const diffForm: IStoppingRoomItem[] = [];
        const currentForm: IInventoryDayItem = mapCurrentForm();
        const oldForm: IInventoryDayItem = mapOldForm();

        Object.keys(currentForm).forEach((key) => {
            if (
                Number(oldForm[key]?.stoppingRooms) !==
                Number(currentForm[key]?.stoppingRooms)
            ) {
                diffForm.push({
                    roomTypeId: Number(key.split('_')?.[0]) || 0,
                    stoppingRoomCount: Number(currentForm[key]?.stoppingRooms),
                    type: StoppingInventoryType.ROOM_TYPE,
                    startDate: key.split('_')?.[1],
                    endDate: key.split('_')?.[1],
                });
            }
        });
        return diffForm;
    };

    const mappedBody = (body: IStoppingRoomItem[]) => {
        return sortBy(body, 'startDate').reduce(
            (accumulator: IStoppingRoomItem[], currentValue: IStoppingRoomItem) => {
                if (accumulator?.length === 0) {
                    accumulator.push({
                        roomTypeId: currentValue.roomTypeId,
                        startDate: currentValue.startDate,
                        endDate: currentValue.endDate,
                        stoppingRoomCount: currentValue.stoppingRoomCount,
                        type: currentValue.type,
                    });
                } else {
                    const index = accumulator.findIndex((i) => {
                        return (
                            i.stoppingRoomCount === currentValue.stoppingRoomCount &&
                            i.roomTypeId === currentValue.roomTypeId &&
                            parseDate(i.endDate).add(1, 'day')?.fmYYYYMMDD() ===
                                parseDate(currentValue.startDate)?.fmYYYYMMDD()
                        );
                    });
                    if (index > -1) {
                        accumulator[index].endDate = currentValue.endDate;
                    } else {
                        accumulator.push({
                            roomTypeId: currentValue.roomTypeId,
                            startDate: currentValue.startDate,
                            endDate: currentValue.endDate,

                            stoppingRoomCount: currentValue.stoppingRoomCount,
                            type: currentValue.type,
                        });
                    }
                }
                return accumulator;
            },
            [],
        );
    };

    const onSubmit = () => {
        if (isErrorGridData) return;
        const body = makeFormData();
        if (!body.length) {
            notification.success({
                message: t('inventory.list.message.updateSuccess'),
            });
            dispatch(setIsEditMode(false));
            dispatch(getInventoryListData());
            return;
        }

        // map startDate, endDate by check in/out hotel
        const mappedBody = body.map((item) => {
            const { startDate, endDate } = getStartDateAndEndDateByDay(
                item.startDate,
                item.endDate,
            );
            return {
                ...item,
                startDate,
                endDate,
            };
        });
        _bulkStopInventory(mappedBody);
    };

    const onCancel = () => {
        dispatch(setIsEditMode(false));
        dispatch(setInventoryForm(mapInventoryGridItem(inventoryList)));
        dispatch(setErrorGridData({}));
    };

    const _showConfirm = () => {
        if (!makeFormData().length) {
            onCancel();
            return;
        }
        showConfirm({
            title: t('room.roomFormIndividual.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    const submitStopSellingFormSuccess = useCallback(() => {
        dispatch(setIsEditMode(false));
        dispatch(getInventoryListData());
    }, [dispatch]);

    const calculateHeight = () => {
        const height =
            window.innerHeight -
            TOP_BAR_HEIGHT -
            STAFF_LAYOUT_HEADER_HEIGHT -
            INVENTORY_PAGE_PADDING;
        if (!isEditMode) {
            return height;
        }
        return height - INVENTORY_PAGE_FOOTER_HEIGHT;
    };

    return (
        <div className="inventory-list-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('inventory.list.title')}
            />
            <Layout.Content>
                <div
                    className="inventory-list-page-wrapper"
                    style={{ height: `${calculateHeight()}px` }}
                >
                    <InventoryGrid height={calculateHeight()} />
                    <StoppingRoomCountForm />
                    <BulkStopSellingModal
                        onSubmitSuccess={submitStopSellingFormSuccess}
                    />
                </div>
            </Layout.Content>
            {isEditMode && (
                <Layout.Footer>
                    <div className="footer-btn-group">
                        <Space align="end">
                            <Button onClick={_showConfirm}>
                                {t('common.buttonCancelText')}
                            </Button>
                            <Button
                                id="form-individual-submit"
                                type="primary"
                                onClick={onSubmit}
                                loading={showLoading}
                                disabled={!inventoryList.length}
                            >
                                {t('inventory.list.saveButton')}
                            </Button>
                        </Space>
                    </div>
                </Layout.Footer>
            )}
        </div>
    );
}

export default InventoryListPage;
