import classNames from 'classnames';
import { cloneDeep, sum } from 'lodash';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputNumber } from '~components';
import { childrenStateSelector } from '~features/children-type/reducers/children.reducer';
import { useAppSelector } from '~hooks';
import './ChildrenInput.scss';

type Props = {
    isOpen: boolean;
    name: (index: number) => string;
    id?: (index: number) => string;
    control: any;
    defaultValue?: Record<string, number>;
    onChange?: (value: Record<string, number>, total: number) => void;
    className?: string;
};

export const ChildrenInput = (props: Props) => {
    const { isOpen, name, id, control, defaultValue, onChange, className } = props;
    const { t } = useTranslation();
    const { children } = useAppSelector(childrenStateSelector);
    const childrenList = useRef<Record<string, number>>(
        defaultValue ? cloneDeep(defaultValue) : {},
    );

    return (
        <div
            className={classNames('child-content-wrapper', className, {
                open: isOpen,
                close: !isOpen,
            })}
        >
            <div className="child-content-title">
                {t('roomBooking.schedule.selectChildTitle')}
            </div>
            {children.map((child, index) => (
                <div className="child-content-row d-flex" key={child.id}>
                    <div className="child-content-label text-truncate" title={child.name}>
                        {child.name}
                    </div>
                    <InputNumber
                        label={''}
                        name={name(index)}
                        id={id ? id(index) : undefined}
                        control={control}
                        isShowIconArrow={true}
                        defaultValue={childrenList.current?.['id_' + child.id]}
                        onChange={(event) => {
                            childrenList.current['id_' + child.id] =
                                +event?.target?.value || 0;
                            onChange &&
                                onChange(
                                    childrenList.current,
                                    sum(Object.values(childrenList.current)),
                                );
                        }}
                    />
                    <div className="child-content-prefix">
                        {t('roomBooking.schedule.person')}
                    </div>
                </div>
            ))}
        </div>
    );
};
