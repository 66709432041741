import { AuditLogModules } from '~features/bo-operation-history/constants';
const historyDetail = {
    memoInput: '備考を入力',
    goToPage: 'ページへ移動',
    update: {
        success: '履歴更新成功',
        failed: '履歴更新失敗',
    },
    memo: 'メモ',
    activityType: 'アクティビティタイプ',
    noData: 'データなし',
    key: {
        [AuditLogModules.GENERAL_SETTING]: {
            beforeData: '前',
            afterData: '後',
            connectionSecurity: '接続セキュリティ',
            userName: 'ユーザー名',
            smtpPort: 'SMTPポート',
            smtpEncryption: 'SMTP暗号化',
            smtpServer: 'SMTPサーバー',
            password: 'パスワード',
            serverName: 'サーバー名',
        },
        [AuditLogModules.ADMIN_NOTIFICATION]: {
            title: 'タイトル',
            content: '内容',
            beforeData: '前',
            afterData: '後',
            id: 'ID',
            tags: 'タグ',
            tag: 'タグ',
            hotels: 'ホテル',
            name: '名前',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
        },
        [AuditLogModules.HOTEL]: {
            beforeData: '前',
            afterData: '後',
            name: 'ホテル名',
            status: 'ステータス',
            address: '住所',
            picName: '写真名',
            permission: '許可',
            numberOfRoom: '部屋数',
            picPhoneNumber: '携帯電話番号',
            tllPmsPassword: 'TLL PMS パスワード',
            tllSystemId: 'TLLシステムId',
            picEmailAddress: 'Email',
            standardCheckInTime: '標準チェックイン時刻',
            standardCheckOutTime: '標準チェックアウト時刻',
            id: 'ホテルID',
            tllPmsUserId: 'サイトコントローラーのPMSユーザーID',
            peCsrHotelCode: 'ホテルコード',
            postalCode: '郵便番号',
        },
    },
    value: {
        yes: 'はい',
        no: 'いいえ',
    },
};

export default historyDetail;
