import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { setReceiptByGroupMap } from '~features/room-booking/reducers/split-receipt.reducer';
import { useAppDispatch } from '~hooks';
import './BottomButtonGroup.scss';

function BottomButtonGroup() {
    const { t } = useTranslation();
    const { roomBookingId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onReturn = () => {
        dispatch(setReceiptByGroupMap({}));
        navigate(`/room-booking/${roomBookingId}/detail`);
    };

    return (
        <div className="split-room-booking-bottom-button-group-wrapper">
            <Button onClick={onReturn}>
                {t('roomBooking.splitReceipt.bottomButtonGroup.buttonReturnText')}
            </Button>
        </div>
    );
}

export default BottomButtonGroup;
