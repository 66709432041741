import { Layout } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '~features/facility-booking/constants';
import {
    isShowAdvanceSearchFormSelector,
    isShowAdvanceSearchTagsSelector,
    resetFacilityBookingState,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { useAppDispatch } from '~hooks';
import AdvanceSearchForm from './components/FacilityBookingList/AdvanceSearchForm/AdvanceSearchForm';
import AdvanceSearchTags from './components/FacilityBookingList/AdvanceSearchTags/AdvanceSearchTags';
import FacilityBookingList from './components/FacilityBookingList/FacilityBookingList';
import FilterForm from './components/FilterForm/FilterForm';
import './FacilityBookingListPage.scss';

const { Content } = Layout;

function FacilityBookingListPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowAdvanceSearch = useSelector(isShowAdvanceSearchFormSelector);
    const isShowAdvanceSearchTags = useSelector(isShowAdvanceSearchTagsSelector);

    useEffect(() => {
        return () => {
            dispatch(resetFacilityBookingState());
        };
    }, []);

    return (
        <div className="facility-booking-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('facilityBooking.list.title')}
            />
            <Content>
                <div className="facility-booking-page-wrapper">
                    <div className="facility-booking-page-content">
                        <FilterForm />
                        {isShowAdvanceSearchTags && <AdvanceSearchTags />}
                        <FacilityBookingList />
                        {isShowAdvanceSearch && (
                            <div className="facility-booking-advance-search">
                                <AdvanceSearchForm />
                            </div>
                        )}
                    </div>
                </div>
            </Content>
        </div>
    );
}

export default FacilityBookingListPage;
