import { Layout, Spin } from 'antd';
import { cloneDeep } from 'lodash';
import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PrintingFonts, PrintingOrientation, PrintingPaperSize } from '~common/constants';
import { showConfirm } from '~common/notification';
import { PdfPrintingModal } from '~components';
import { facilityBookingListStateSelector } from '~features/facility-booking/reducers/facility-booking.reducer';
import {
    setIsShowSplitFacilityBookingReceiptPrintingModal,
    splitReceiptStateSelector,
} from '~features/facility-booking/reducers/split-receipt.reducer';
import { ICreateReceiptFileBody } from '~features/facility-booking/interfaces';
import { ReceiptItemType } from '~features/facility-booking/constants';
import { receiptFileService } from '~features/room-booking/services/receipt-file.service';
import { getAutoGeneratedCode } from '~features/room-booking/helper';
import { FILE_TYPE, INVOICE_PATH } from '~features/room-booking/constants';
import { useBankAccounts } from '~features/hotel/hooks/useBankAccounts';
import { useAppDispatch, useAppSelector } from '~hooks';
import { generateSplitFacilityBookingReceiptPdf } from './generateSplitFacilityBookingReceiptContent';
import './SplitFacilityBookingReceiptPrintingModal.scss';
import queryClient, { CacheKeys } from '~queries/queries';

export default function SplitRoomBookingReceiptPrintingModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const isInvoice = pathname.endsWith(INVOICE_PATH);

    const { selectedFacilityBookingDetail } = useAppSelector(
        facilityBookingListStateSelector,
    );

    const { isShowSplitFacilityBookingReceiptPrintingModal, printingReceiptByGroupList } =
        useAppSelector(splitReceiptStateSelector);
    const [url, setUrl] = useState<string>();
    const [pdf, setPdf] = useState<TCreatedPdf>();
    const [orientation, setOrientation] = useState<PrintingOrientation>(
        PrintingOrientation.PORTRAIT,
    );
    const [paperSize, setPaperSize] = useState<PrintingPaperSize>(PrintingPaperSize.A4);
    const [totalPage, setTotalPage] = useState(0);
    const [progress, setProgress] = useState<number | undefined>(undefined);
    const [logNumber, setLogNumber] = useState<number>(0);
    const { data: bankAccounts } = useBankAccounts();

    const totalPaidAmount = useMemo(() => {
        const total: Record<string, number> = {};
        if (printingReceiptByGroupList.length) {
            printingReceiptByGroupList.forEach((printingReceipt) => {
                printingReceipt?.receipt?.facilityBooking?.receiptItems?.forEach((receiptItem) => {
                    if (
                        receiptItem.type === ReceiptItemType.PAYMENT &&
                        receiptItem.paymentMethod
                    ) {
                        const receiptItemUnpaidAmount =
                            receiptItem.receiptItemDetails.reduce(
                                (result, receiptItemDetail) =>
                                    result + receiptItemDetail.amount,
                                0,
                            );
                        if (total[printingReceipt!.group!.id]) {
                            total[printingReceipt!.group!.id] +=
                                receiptItemUnpaidAmount;
                        } else {
                            total[printingReceipt!.group!.id] =
                                receiptItemUnpaidAmount;
                        }
                    }
                });
            });
        }
        return total;
    }, [printingReceiptByGroupList]);

    const onPrint = useCallback(() => {
        cloneDeep(pdf)?.print();
    }, [pdf]);

    const onChangeOrientation = (orientation: PrintingOrientation) => {
        setUrl('');
        setOrientation(orientation);
    };

    const onChangePaperSize = (paperSize: PrintingPaperSize) => {
        setUrl('');
        setPaperSize(paperSize);
    };

    const createPdf = () => {
        setUrl('');
        pdfMake.fonts = PrintingFonts;
        setPdf(
            pdfMake.createPdf(
                generateSplitFacilityBookingReceiptPdf(
                    printingReceiptByGroupList,
                    selectedFacilityBookingDetail,
                    {
                        orientation,
                        setTotalPage,
                        paperSize,
                    },
                    logNumber,
                    isInvoice,
                    bankAccounts || [],
                    totalPaidAmount,
                ),
            ),
        );
    };

    useEffect(() => {
        createPdf();
    }, [printingReceiptByGroupList, orientation, paperSize, logNumber]);

    useEffect(() => {
        cloneDeep(pdf)?.getDataUrl((dataUrl: string) => {
            setUrl(dataUrl);
        });
    }, [pdf]);

    const onClose = () => {
        showConfirm({
            title: t('facilityBooking.detail.cancelTitle'),
            onOk() {
                dispatch(setIsShowSplitFacilityBookingReceiptPrintingModal(false));
            },
        });
    };

    const receiptFiles = useMutation({
        mutationFn: async ({ id, body }: { id: number; body: FormData }) => {
            const res = await receiptFileService.createReceiptFiles(
                id,
                body,
                setProgress,
            );
            if (res.success) return res.data;
            throw Error(res.message);
        },
        onMutate: () => {
            setProgress(0);
        },
        onSuccess: () => {
            const receiptId = printingReceiptByGroupList[0].receipt.id;
            queryClient.invalidateQueries({
                queryKey: [CacheKeys.receiptFiles, receiptId],
            });
        },
    });

    const filesCount = useQuery({
        queryKey: [CacheKeys.receiptFilesCount, printingReceiptByGroupList[0]?.receipt?.id, isInvoice],
        queryFn: async () => {
            if (printingReceiptByGroupList[0]?.receipt?.id) {
                const res = await receiptFileService.getReceiptFilesCount(
                    printingReceiptByGroupList[0].receipt.id,
                    isInvoice ? FILE_TYPE.INVOICE : FILE_TYPE.RECEIPT
                );
                if (res.success) {
                    setLogNumber(Number(res.data.count) + 1);
                    return res.data.count;
                };
                throw Error(res.message);
            }
            return 0;
        },
        enabled: false,
    });

    useEffect(() => {
        if (isShowSplitFacilityBookingReceiptPrintingModal && pdf && printingReceiptByGroupList.length) {
            filesCount.refetch()
            .then(response => {
                if (logNumber !== Number(response.data) + 1) {
                    setLogNumber(Number(response.data) + 1);
                    let total = 0;
                    printingReceiptByGroupList[0]?.receipt?.facilityBooking?.receiptItems?.forEach(receiptItem => {
                        if (receiptItem?.type !== ReceiptItemType.PAYMENT) {
                            total += receiptItem?.receiptItemDetails.reduce((result, receiptItemDetail) => result + receiptItemDetail.amount, 0);
                        }
                    });
                    const body: ICreateReceiptFileBody[] = [{
                        number: `${isInvoice ? 'IV' : 'RC'}-${getAutoGeneratedCode(
                            printingReceiptByGroupList[0].receipt.facilityBooking.autoGeneratedCode,
                        )}-${Number(response.data) + 1}`,
                        name: printingReceiptByGroupList[0]?.guestName as string,
                        total,
                        type: isInvoice ? FILE_TYPE.INVOICE : FILE_TYPE.RECEIPT,
                    }];
                    const bodyFormData = new FormData();
                    bodyFormData.append('receiptData', JSON.stringify({ items: body }));

                    cloneDeep(pdf).getBlob((file) => {
                        bodyFormData.append(`files`, file, `${body[0].number}.pdf`);
                        receiptFiles.mutate({ id: printingReceiptByGroupList[0].receipt.id, body: bodyFormData });
                    });
                }
            });
        }
    }, [pdf]);

    return (
        <PdfPrintingModal
            totalPage={totalPage}
            onPrint={onPrint}
            onClose={onClose}
            onChangeOrientation={onChangeOrientation}
            onChangePaperSize={onChangePaperSize}
            isShowPdfPrinting={isShowSplitFacilityBookingReceiptPrintingModal}
        >
            <Layout className="split-facility-booking-receipt-printing-layout">
                <Layout.Content className="split-facility-booking-receipt-printing-content">
                    {url ? (
                        <iframe className="pdf-view" src={`${url}#toolbar=0`} />
                    ) : (
                        <Spin />
                    )}
                </Layout.Content>
            </Layout>
        </PdfPrintingModal>
    );
}
