import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, notification } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import { useAppDispatch, useAppSelector } from '~hooks';
import { SingleSelect } from '~components';
import { getFacilityBookingReceipt, setShowBulkPaymentModal, showBulkPaymentModal, showPaymentModalLoadingSelector, updateFacilityBookingReceipt } from '~features/facility-booking/reducers/facility-booking.reducer';
import { IFacilityBookingReceipt, IUpdateFacilityBookingReceipt } from '~features/facility-booking/interfaces';
import { bulkPaymentSchema } from '~features/facility-booking/schema';
import { ReceiptItemType } from '~features/facility-booking/constants';
import { paymentMethodStateSelector } from '~features/payment-method/reducers/paymentMethod.reducer';
import { IPaymentMethodDropDown } from '~features/payment-method/interfaces';
import { PREPAYPEMENT_METHODS } from '~features/payment-method/constants';
import { PaymentMethodSelect } from '~features/room-booking/pages/RoomBookingDetailPage/components/RoomBookingReceiptTabPane/PaymentMethodSelect/PaymentMethodSelect';

type Props = {
    facilityBookingReceipt: IFacilityBookingReceipt | null;
    totalPrice: {
        totalSale: number;
        totalPayment: number;
        totalTax: number;
    };
};

export function BulkPaymentModal({ facilityBookingReceipt, totalPrice }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowBulkPaymentModal = useAppSelector(
        showBulkPaymentModal,
    );
    const showPaymentModalLoading = useAppSelector(showPaymentModalLoadingSelector);
    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);
    const { control, reset, handleSubmit, formState } = useForm({
        resolver: yupResolver(bulkPaymentSchema),
        defaultValues: {
            paymentDate: null,
            paymentMethodId: null,
        },
    });

    const _updateFacilityBookingReceipt = async (
        id: number,
        body: IUpdateFacilityBookingReceipt[],
    ) => {
        const response = await dispatch(updateFacilityBookingReceipt({ id, body }));
        if (updateFacilityBookingReceipt.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('facilityBooking.detail.receipt.updateSuccessMessage'),
                });
                if (facilityBookingReceipt?.bookingId) {
                    dispatch(
                        getFacilityBookingReceipt(facilityBookingReceipt?.bookingId),
                    );
                }
                onClose();
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };

    const paymentMethodOptions = useMemo(() => {
        return paymentMethodDropDown.map((method) => {
            if (PREPAYPEMENT_METHODS.includes(method.name)) {
                return {
                    ...method,
                    disabled: true,
                };
            }
            return method;
        });
    }, [paymentMethodDropDown]);

    const getDisabledValues = (paymentMethodDropDown: IPaymentMethodDropDown[]) => {
        return paymentMethodDropDown
            .filter((method) => method.disabled)
            .map((method) => method.id);
    };

    const formattedPaymentDateOptions = useMemo(() => {
        const options = new Set<string>();
        options.add(parseDate(facilityBookingReceipt?.facilityBooking.startDatetime).utc().fmYYYYMMDD('-'));
        return Array.from(options)
            .sort((a, b) => {
                return parseDate(a).valueOf() - parseDate(b).valueOf();
            })
            .map((option) => ({
                value: option,
                label: option,
            }));
    }, [facilityBookingReceipt]);

    const onClose = () => {
        reset();
        dispatch(setShowBulkPaymentModal(false));
    };

    const onSubmit = () => {
        handleSubmit(data => {
            const { paymentDate, paymentMethodId } = data;
            const formData = {
                type: ReceiptItemType.PAYMENT,
                paymentMethodId: paymentMethodId,
                receiptItemDetails: [
                    {
                        amount: Number(totalPrice.totalSale - totalPrice.totalPayment) || 0,
                        payAt: parseDate(paymentDate)
                            ?.startOf('day')
                            ?.fmYYYYMMDDHHmmss(),
                    },
                ],
            } as any;
            if (facilityBookingReceipt?.id) {
                _updateFacilityBookingReceipt(facilityBookingReceipt?.id, [formData]);
            }
        })();
    };

    return (
        <Modal
            title={t('facilityBooking.detail.receipt.button.bulkPayment')}
            open={isShowBulkPaymentModal}
            centered
            onCancel={onClose}
            okButtonProps={{ form: 'bulkPaymentGroupBookingForm', htmlType: 'submit' }}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    {t('facilityBooking.detail.receipt.bulkPaymentModal.cancel')}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    form="bulkPaymentGroupBookingForm"
                    htmlType="submit"
                    loading={showPaymentModalLoading}
                    onClick={onSubmit}
                    disabled={!formState.isValid || totalPrice.totalSale <= totalPrice.totalPayment}
                >
                    {t(
                        'facilityBooking.detail.receipt.bulkPaymentModal.createBulkPayment',
                    )}
                </Button>,
            ]}
        >
            <Form
                name="bulkPaymentGroupBookingForm"
                id="bulkPaymentGroupBookingForm"
                layout="vertical"
            >
                <SingleSelect
                    label={t(
                        'facilityBooking.detail.receipt.bulkPaymentModal.paymentDate',
                    )}
                    name="paymentDate"
                    control={control}
                    options={formattedPaymentDateOptions}
                />
                <PaymentMethodSelect
                    label={t(
                        'facilityBooking.detail.receipt.bulkPaymentModal.paymentMethod',
                    )}
                    paymentMethodOptions={paymentMethodOptions}
                    disableValues={getDisabledValues(paymentMethodOptions)}
                    name="paymentMethodId"
                    control={control}
                />
            </Form>
        </Modal>
    );
}
