const saleItemCreate = {
    title: 'Create sales item',
    breadcrumbs: {
        create: 'Create sales item',
    },
    cancelBtn: 'Cancel',
    createBtn: 'Save',
    formSetting: {
        quantityLabel: 'Quantity',
        quantityPlaceholder: 'Quantity',
        saleGroupLabel: 'Sales group',
        priceLabel: 'Standard price (JPY)',
        taxLabel: 'Consumption tax type',
        isShowInBookingLabel: 'Display in preparation list',
        isShowInBookingTitle: 'Preparation list',
        generateBtn: 'Generate',
        defaultTax: 'Tax free',
    },
    saleItemList: {
        id: 'ID',
        name: 'Sales item',
        nameInput: 'Enter sales item',
        saleGroup: 'Sales group',
        saleGroupSelect: 'Select sales group',
        price: 'Standard price (JPY)',
        priceInput: 'Standard price',
        tax: 'Consumption tax type (%)',
        taxSelect: 'Consumption tax type',
        isShowInBooking: 'Display in preparation list',
        deleteBtn: 'Delete',
        addSaleItemBtn: 'Add a row',
    },
    message: {
        nameDuplicated: 'Sale item name is duplicated',
        createSuccess: 'Sale item has been created successfully',
    },
    confirm: {
        cancelTitle: 'Do you want to cancel?',
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
    },
};

export default saleItemCreate;
