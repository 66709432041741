import { Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import { todayDayjs } from '~plugins/dayjs';
import { SocketEvents } from '~plugins/socket/socket';
import { useSocket } from '~plugins/socket/socketContext';
import { ClosingBookingErrorModal } from '../components/ClosingBookingErrorModal/ClosingBookingErrorModal';
import FacilityClosingList from '../components/FacilityClosingList/FacilityClosingList';
import FilterForm from '../components/FilterForm/FilterForm';
import RoomClosingList from '../components/RoomClosingList/RoomClosingList';
import {
    breadcrumbs,
    closingBookingListQueryDefault,
    ClosingBookingTabPane,
} from '../constants';
import { IClosingBookingSocketResponse } from '../interfaces';
import {
    getFacilityClosingList,
    getRoomClosingList,
    resetState,
    roomClosingStateSelector,
    setActiveClosingBookingTab,
    setRoomClosingListQuery,
} from '../reducers/closing-booking.reducer';
import './ClosingBookingPage.scss';

function ClosingBookingPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { activeClosingBookingTab, closingBookingListQuery } = useAppSelector(
        roomClosingStateSelector,
    );

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, []);

    const socket = useSocket();

    useEffect(() => {
        if (!socket) return;
        socket.on(
            SocketEvents.WEB_APP_CLOSING_BOOKING_NOTIFICATION,
            (response: IClosingBookingSocketResponse) => {
                ClosingBookingErrorModal({
                    title: t('roomBooking.list.checkoutDialog.unpaidTitle'),
                    okText: t('roomBooking.list.statusModalConfirm.okText'),
                    description: t('roomBooking.list.checkoutDialog.content'),
                    errorRoomBookings: response.roomBookings?.map((roomBooking) => {
                        return `${roomBooking.autoGeneratedCode} - ${
                            roomBooking.reserverGuest?.yomigana ||
                            roomBooking.representativeGuest?.yomigana ||
                            ''
                        } - ${
                            roomBooking.reserverGuest?.fullName ||
                            roomBooking.representativeGuest?.fullName ||
                            ''
                        }`;
                    }),
                    errorFacilityBookings: response.facilityBookings?.map(
                        (facilityBooking) => {
                            return `${facilityBooking.autoGeneratedCode} - ${
                                facilityBooking.guest?.yomigana
                            } - ${facilityBooking.guest?.fullName || ''}`;
                        },
                    ),
                    onOk: () => {
                        dispatch(
                            setActiveClosingBookingTab(
                                ClosingBookingTabPane.ROOM_CLOSING,
                            ),
                        );
                        dispatch(
                            setRoomClosingListQuery({
                                ...closingBookingListQueryDefault,
                            }),
                        );
                    },
                });
            },
        );
        return () => {
            socket?.off(SocketEvents.WEB_APP_CLOSING_BOOKING_NOTIFICATION);
        };
    }, [socket]);

    useEffect(() => {
        if (activeClosingBookingTab === ClosingBookingTabPane.ROOM_CLOSING) {
            dispatch(getRoomClosingList());
        } else {
            dispatch(getFacilityClosingList());
        }
    }, [closingBookingListQuery]);

    const changeClosingBookingTab = (activeKey: string) => {
        dispatch(setActiveClosingBookingTab(activeKey));
        dispatch(
            setRoomClosingListQuery({
                ...closingBookingListQueryDefault,
                status: closingBookingListQuery.status,
            }),
        );
    };

    return (
        <div className="closing-booking-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={`${t('closingBooking.page.title')} (${todayDayjs.fmYYYYMMDD()})`}
            />
            <Content>
                <div className="closing-booking-page-wrapper">
                    <FilterForm />
                    <div className="closing-booking-tabs">
                        <Tabs
                            onChange={changeClosingBookingTab}
                            activeKey={activeClosingBookingTab}
                            items={[
                                {
                                    key: ClosingBookingTabPane.ROOM_CLOSING,
                                    label: t('closingBooking.page.tabs.room'),
                                    children: <RoomClosingList />,
                                },
                                {
                                    key: ClosingBookingTabPane.FACILITY_CLOSING,
                                    label: t('closingBooking.page.tabs.facility'),
                                    children: <FacilityClosingList />,
                                },
                            ]}
                        ></Tabs>
                    </div>
                </div>
            </Content>
        </div>
    );
}

export default ClosingBookingPage;
