import type { CSSProperties, FC } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import { BoxDragPreview } from './BoxDragPreview';

const layerStyles: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
};

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }

    let { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

type CustomDragLayerProps = {};

export const CustomDragLayer: FC<CustomDragLayerProps> = (props) => {
    const { isDragging, initialOffset, currentOffset, item } = useDragLayer(
        (monitor) => ({
            item: monitor.getItem(),
            isDragging: monitor.isDragging(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
        }),
    );

    if (!isDragging) {
        return null;
    }
    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                <BoxDragPreview
                    id={item.bookingId}
                    autoGeneratedCode={item.autoGeneratedCode}
                />
            </div>
        </div>
    );
};
