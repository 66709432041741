const roomBookingDetail = {
    title: 'Room booking details',
    breadcrumbs: {
        roomBookingDetail: 'Room booking details',
        OtaCancelationRobot: 'OTA Cancelation Robot',
    },
    detailPagetabs: {
        itemList: 'Booking list',
        receipt: 'Receipt',
        keycards: 'Keycards',
        auditLog: 'Audit Log',
        representativeGuestInfo: 'Representative guest information',
        hotelSmart: 'Hotel Smart',
        attachment: 'File',
        memo: 'Memo',
        accompanier: 'Accompanion',
        OtaCancelationRobot: 'OTA Cancelation Robot',
        cancelationInfo: 'Cancellation info',
    },
    detailPageCards: {
        resId: 'RES ID: {{resId}}',
        bookingList: 'Booking list',
    },
    otaCancelation: {
        cancelResId: 'CANCEL RESERVATION ID: {{resId}}',
        AdminSetting: 'OTA Admin Setting',
        AdminSettingBtn: 'Change',
        completed: 'Completed',
        memoLabel: 'OTA cancelation memo',
        memoPlaceholder: 'Free cancellation due to weather',
        fee: 'Cancelation fee',
        free: 'Free',
        paid: 'Paid',
        cancelBtn: 'Cancel',
        submitBtn: 'Cancel booking',
        history: 'History',
        success: 'Cancellation was successful',
        account: 'Account',
        usernameEmail: 'Username or Email',
        password: 'Password',
        close: 'Close',
        save: 'Save',
    },
    roomBookingItemColumns: {
        stayTime: 'Duration of stay',
        yomigana: 'Guest name (yomigana)',
        bookingStatus: 'Status',
        roomTypeName: 'Room type',
        planName: 'Plan name',
        roomName: 'Room name',
        action: 'Edit',
        numberOfAdults: 'Adult',
        numberOfChildren: 'Children',
    },
    guestInfo: {
        representativeGuest: 'Representative guest',
        reserverGuest: 'Reserver',
        reserverIsTheRepresentative: '(same as guest information)',
        roomDetail: 'Detail',
        marketingChannel: 'Marketing channel',
        bookingTime: 'Booking time',
        multipleBooking: 'Multiple booking',
    },
    bookingItemCard: {
        personNumber: 'Adults: {{adult}}  Children: {{child}}',
        buttonEdit: 'Edit content',
        representativeGuest: '(Representative room)',
        guestYomigana: {
            label: 'Yomigana',
            placeholder: 'Select yomigana',
        },
        status: {
            label: 'Status',
            placeholder: 'Select status',
        },
        dateOfStayPeriod: {
            label: 'C/I:',
            placeholder: {
                start: 'Check in',
                end: 'Check out',
            },
        },
        checkInTime: {
            label: 'C/I:',
            placeholder: 'C/I',
        },
        checkOutTime: {
            label: 'C/O:',
            placeholder: 'C/O',
        },
        roomTypeId: {
            label: 'Room type',
            placeholder: 'Select room type',
        },
        roomId: {
            label: 'Room name',
            placeholder: 'Select room name',
        },
        planId: {
            label: 'Plan name',
            placeholder: 'Select plan name',
        },
        numberOfAdult: {
            label: 'Adults:',
            placeholder: '0',
        },
        numberOfChild: {
            label: 'Children:',
            placeholder: '0',
        },
        representativeRoom: 'Representative room',
        roomType: {
            notExist: 'Unknow room type',
        },
        numberOfStayedGuest: 'Number of guests: {{numberOfStayedGuest}}',
    },
    message: {
        createSuccess: 'Create room booking item success',
        updateSuccess: 'Update room booking item success',
        deleteSuccess: 'Delete room booking item success',
        cannotChangeStatus: 'Cannot change to {{status}}',
        availableRoomsNegative: 'Available room count is negative',
        marketingChannelNotExist: 'Marketing channel does not exist',
        roomTypeNotExist: 'Room type does not exist',
        planNotExist: 'Plan does not exist',
        paymentMethodNotExist: 'Payment method does not exist',
        autoAssignRoom:
            'Cannot finish auto assign due to insufficient room. There are {{unassignCount}} room(s) that cannot automatically assign rooms',
        assignedAllRoom: 'All bookings have been assigned rooms',
        cannotCheckInAll: 'Some booking item cannot be checked in before {{date}}',
        confirmSave:
            'Performing this change process will overwrite the contents of the receipt. Please make sure to review the current receipt contents before proceeding with the change process. If you are unable to accurately restore the receipt contents, please contact Tabist.',
        notAssignedAllBookings:
            'All bookings are not assigned rooms due to not select any room for each booking in the list',
        createError: 'Failed to create room booking item',
    },
    confirmUpdateReceipt: {
        updateButton: 'Update',
        notUpdateButton: 'Do not update',
        confirmTitle:
            'Based on the updated reservation information, would you like to update the sales information?',
        changeRoomTypeConfirmTitle:
            'Would you like to update the booking price based on the new room type for this booking?',
    },
    detailBookingSchedule: {
        dayUse: 'Day-use',
        checkInBtn: 'Check in',
        checkOutBtn: 'Check out',
        cancelReservationBtn: 'Cancel booking',
        unassignRoomBtn: 'Unassign room',
        showTheDetails: 'View more',
        cleaned: 'Clean',
        uncleaned: 'Unclean',
        guestName: 'Yamada Tarou',
        plan: 'Plan with breakfast and dinner',
        price: '￥25,000',
        notArriveBtn: 'Change to reserved',
    },
    deleteTitle: 'Do you want to delete?',
    deleteConfirmDialog: {
        content: 'Delete all booking including the child. Do you want to delete?',
        groupBookingContent:
            'Delete all booking including the child. Do you want to delete?',
        singleBookingContent: 'Do you want to delete?',
        title: 'Confirmation of deletion',
        deleteButton: 'Delete',
        cancelButton: 'Cancel',
        deleteByStatusError: 'The following room booking could not be deleted',
        deleteAssociatedBookingsFirstError:
            'Please delete associated booking items first',
    },
    receiptTableColumn: {
        id: 'ID',
        booking: 'Booking',
        date: 'Date',
        status: 'Type',
        plan: 'Detail',
        guest: 'Guest',
        roomName: 'Room name',
        unitPrice: 'Unit',
        quantity: 'Quantity',
        total: 'Total',
        checkoutRoom: 'Settlement room',
        payment: 'Payment method',
    },
    receipt: {
        title: 'Detail',
        button: {
            edit: 'Edit',
            splitReceipt: 'Receipt splitting',
            splitInvoice: 'Invoice splitting',
            bulkPayment: 'Group booking bulk payment',
            calculate: 'Settle',
            split: 'Reset',
            payment: 'Payment',
            earnings: 'Revenue',
            yes: 'Yes',
            no: 'No',
        },
        status: {
            payment: 'Payment',
            earnings: 'Revenue',
        },
        sumaryTotal: {
            totalReceivable: 'Total unpaid amount',
            totalSales: 'Total revenue (tax included)',
            otherTaxes: 'Other taxes',
            payment: 'Payment',
            change: 'Change',
        },
        paymentSetting: {
            title: 'Update payment setting',
            representative_room:
                'Accommodation fee will be charged for representative room',
            all_rooms: 'Accommodation fee will be charged for each room',
            allDetails: 'All details',
            allRoomCharges: 'Total price',
            questionChangeConfirm:
                'Is it okay to charge total accommodation fee for representative room?',
        },
        discardEdit: 'Is it okay to discard the unsaved content?',
        paymentMethod: {
            placeholder: 'Payment method',
        },
        saleItem: {
            placeholder: 'Select sales item',
        },
        saleItemDefault: 'Accommodation',
        adult: 'Adults',
        paymentModal: {
            title: 'Settlement confirmation',
            calculateButton: 'Settle',
            bookingID: {
                label: 'Booking ID',
                placeholder: 'Booking ID',
            },
            paymentMethod: {
                label: 'Payment method',
                placeholder: 'Select payment method',
            },
            paymentAmount: {
                label: 'Amount',
                placeholder: '0',
            },
        },
        bookingCheckedOut: 'This booking is checked out or canceled',
        item: {
            notExist: 'Unknown sales item',
        },
        plan: {
            notExist: 'Unknown plan',
            status: 'Accommodation fee',
        },
        facility: {
            status: 'Facility fee',
        },
        message: {
            bookingDateError:
                'There is a Receipt Item Detail those date is out of range C/I, C/O',
            dateOutOfBookingTime: 'Date is out of range C/I, C/O',
            amountTooLarge: 'The value  is too large',
            amountTooSmall: 'The value  is too small',
            priceOfAmountToLarge: 'Price of sale item or amount is too large',
        },
        bulkPaymentGroupBookingModal: {
            paymentDate: 'Payment Date',
            paymentMethod: 'Payment Method',
            createBulkPayment: 'Create One Time Payment',
            cancel: 'Cancel',
            updateSuccessMessage: 'Create One Time Payment Successfully',
            overpaymentWarning:
                'If there is a prepayment or prepayment by points, there is a chance that amount overpayment will occur. Please check the result carefully. Would you like to proceed?',
            overpaymentProceed: 'Proceed',
        },
    },
    receiptStatus: {
        payment: 'Payment',
        receipt: 'Revenue',
    },
    roomItem: 'Room',
    facilityItem: 'Facility',
    updateSuccessMessage: 'Update receipt item detail success',
    deleteSuccessMessage: 'Delete receipt item detail success',
    cancelEditReceiptTitle: 'Do you want to cancel?',
    deleteReceiptErrorModal: {
        title: 'Error',
        description: 'Cannot delete following room',
    },
    accommodationCardPrinting: {
        modal: {
            title: 'Accommodation card printing',
            ok: 'Print',
            cancel: 'Cancel',
            todayFirstPart: 'Current day',
            todaySecondPart: '({{month}} /{{date}} check in)',
            tomorrowFirstPart: 'Next day',
            tomorrowSecondPart: '({{month}}/{{date}} check in)',
            theDayAfterTomorrowFirstPart: 'Day after next day',
            theDayAfterTomorrowSecondPart: '({{month}}/{{date}} check in)',
            emptyCardFirstPart: 'Empty card',
            emptyCardSecondPart: '',
        },
    },
    hotelSmartCard: {
        message: 'Contact Info',
        myPage: 'My page edit history',
        selfCheckIn: 'Self check-in',
        keyCodes: 'Key codes',
        addSalesPayment: 'Billings / Payments',
        mail: 'Mail',
        phone: 'Phone',
        sent: 'Sent',
        beforeChange: 'Before',
        afterChange: 'After',
        billings: 'Billings',
        payments: 'Payments',
        showMore: 'Show more',
        showLess: 'Show less',
        notArrived: 'Not arrived',
        checkedIn: 'Checked-in',
        pmsManualCheckedIn: 'PMS manual check-in',
        guestId: 'Guest ID',
        prefecture: 'Prefecture',
        nameKana: 'Name Kana',
        country: 'Country',
        zipCode: 'Zip Code',
        name: 'Name',
        adrr: 'Address',
        dob: 'Date of Birth',
        email: 'Email',
        sex: 'Gender',
        other: 'Other',
        female: 'Female',
        male: 'Male',
        null: 'No Data',
        0: 'No Data',
        error: {
            syncWithHS: 'Reservation information synchronization with HOTEL SMART failed',
        },
    },
    splitBooking: {
        error: {
            checkOutDate: 'Checkout date should be same or after today',
        },
    },
    keycard: {
        issueDate: 'Issue date',
        statusColumn: 'Status',
        reIssue: 'Re-issue',
        reissueNumber: 'Number of reissues',
        status: {
            active: 'Active',
            returned: 'Returned',
            lost: 'Lost',
        },
        addKey: 'Add keycard',
        editKey: 'Edit Keycard',
        cancel: 'Cancel',
        proceed: 'Proceed',
        cico: 'Checkin / Checkout',
        reIssueCard: 'Re-issue Keycard',
        reissueState: {
            scanning: 'Place keycard on scanner',
            success: 'Keycard Active',
            failed: 'Keycard could not be read',
        },
        tryAgain: 'Try again',
        close: 'Close',
    },
};

export default roomBookingDetail;
