import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { CalendarDay } from '~features/rank-calendar/constants';
import { TllRank } from './../../common/constants';
import yup from '~plugins/yup';
import { TLL_RANK_UNCHANGED } from './constants';

export const createRankCalendarSchema = yup.object().shape({
    name: yup.string().max(INPUT_TEXT_MAX_LENGTH).required(),
    planDurations: yup.array().of(
        yup.object().shape({
            duration: yup.array().of(yup.mixed().required()),
            tllRank: yup.mixed<TllRank>().oneOf(Object.values(TllRank)).required(),
        }),
    ),
    ...Object.values(CalendarDay).reduce(
        (acc, calendarDay) => ({
            ...acc,
            [calendarDay]: yup
                .mixed<TllRank>()
                .oneOf([...Object.values(TllRank), TLL_RANK_UNCHANGED as TllRank])
                .required(),
        }),
        {},
    ),
});

export const DayRankSchema = {};

export const updateRankCalendarSchema = yup.object().shape({
    name: yup.string().max(INPUT_TEXT_MAX_LENGTH).required(),
});

export const updateTllRankSchema = yup.object().shape({
    tllRank: yup.string().required(),
});
