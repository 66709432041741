import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
import i18next from '~plugins/i18next/i18n';

export const breadcrumbs = () => [
    {
        text: i18next.t('guest.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('guest.list.breadcrumbs.guest'),
    },
];

export const breadcrumbsCreate = () => [
    {
        text: i18next.t('guest.create.breadcrumbs.home'),
    },
    {
        text: i18next.t('guest.create.breadcrumbs.create'),
    },
];

export const breadcrumbsDetail = () => [
    ...breadcrumbs(),
    {
        text: i18next.t('guest.detail.breadcrumbs.guestDetail'),
    },
];

export const breadcrumbsUpdate = () => [
    {
        text: i18next.t('guest.update.breadcrumbs.home'),
    },
    {
        text: i18next.t('guest.update.breadcrumbs.update'),
    },
];

export enum GuestColumn {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'fullName',
    YOMIGANA = 'yomigana',
    GENDER = 'gender',
    BIRTHDAY = 'birthday',
    PHONE = 'mobilePhoneNumber',
    EMAIL = 'emailAddress',
    USED_BOOKING_TYPES = 'usedBookingTypes',
    MEMO = 'memo',
    CLASSIFICATION = 'classificationName',
}

export enum SearchField {
    ALL = 'keyword',
    NAME = 'fullName',
    PHONE = 'phoneNumber',
    EMAIL = 'email',
}

export enum HistorySearchField {
    ALL = 'keyword',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    ROOM_NAME = 'roomName',
    FACILITY_NAME = 'facilityName',
}

export enum OrderBy {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'fullName',
    YOMIGANA = 'yomigana',
    GENDER = 'gender',
    BIRTHDAY = 'birthday',
    PHONE = 'mobilePhoneNumber',
    CLASSIFICATION = 'classification',
    EMAIL = 'emailAddress',
    MEMO = 'memo',
    USED_BOOKING_TYPES = 'usedBookingTypes',
}

export enum UsedBookingType {
    FACILITY_BOOKING = 'facility-booking',
    ROOM_BOOKING = 'room-booking',
}

export enum Gender {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other',
}

export const UPLOAD_FILE_MAX_SIZE = 25;

export const JAPAN_COUNTRY_ID = 120;

export const FILE_NAME = 'guest.csv';

// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'guest';
export const exportColumns = [
    'autoGeneratedCode',
    'yomigana',
    'fullName',
    'gender',
    'birthday',
    'mobilePhoneNumber',
    'usedBookingTypes',
    'emailAddress',
    'memo',
    'classification',
];

export const i18ExportKey = 'guest.list';

export const DEFAULT_LIMIT_FOR_EXPORT = 100;

export enum GuestPageTabPane {
    BASIC_INFO_TAB_PANE = 'basicInfoTabPane',
    COMPANY_INFO_TAB_PANE = 'companyInfoTabPane',
    BOOKING_HISTORY_TAB_PANE = 'bookingHistoryTabPane',
}

export enum RoomBookingHistoryColumn {
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    YOMIGANA = 'yomigana',
    FULL_NAME = 'fullName',
    ROOM_NAME = 'roomName',
    CHECK_IN_TIME = 'checkInTime',
    CHECK_OUT_TIME = 'checkOutTime',
}

export enum FacilityBookingHistoryColumn {
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    YOMIGANA = 'yomigana',
    FULL_NAME = 'fullName',
    FACILITY_NAME = 'facilityName',
    START_DATE_TIME = 'startDatetime',
    END_DATE_TIME = 'endDatetime',
}

export const ROOM_BOOKING_HISTORY_FILE_NAME = 'roomBookingHistory.csv';

export const EXPORT_CSV_ROOM_BOOKING_HISTORY_FILE_NAME = 'roomBookingHistory';

export const exportRoomBookingHistoryColumns = [
    'autoGeneratedCode',
    'yomigana',
    'fullName',
    'roomName',
    'checkInTime',
    'checkOutTime',
];

export const i18FacilityBookingHistoryExportKey = 'guest.detail.facilityBookingHistory';

export const FACILITY_BOOKING_HISTORY_FILE_NAME = 'facilityBookingHistory.csv';

export const EXPORT_CSV_FACILITY_BOOKING_HISTORY_FILE_NAME = 'facilityBookingHistory';

export const exportFacilityBookingHistoryColumns = [
    'autoGeneratedCode',
    'yomigana',
    'fullName',
    'facilityName',
    'checkInTime',
    'checkOutTime',
];

export const i18RoomBookingHistoryExportKey = 'guest.detail.roomBookingHistory';

export const PHONE_NUMBER_MAX_LENGTH = 15;
export const POSTAL_CODE_MAX_LENGTH = 10;

export const DEFAULT_LIMIT_FOR_BOOKING_HISTORY = 4;

export const initGuestListQuery = {
    keyword: '',
    fullName: '',
    phoneNumber: '',
    email: '',
    usedBookingTypes: [],
    orderBy: OrderBy.ID,
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const guestDefault = {
    fullName: '',
    yomigana: '',
    mobilePhoneNumber: '',
    emailAddress: null,
    address: null,
    birthday: null,
    gender: null,
    postalCode: null,
    countryId: null,
    classificationId: null,
    memo: null,
    companyEmailAddress: null,
    companyName: null,
    companyYomigana: null,
    companyPhoneNumber: null,
    companyAddress: null,
    companyPostalCode: null,
};

export const initFacilityBookingHistoryQuery = {
    keyword: '',
    autoGeneratedCode: '',
    facilityName: '',
    checkInDateTimePeriod: [],
    checkOutDateTimePeriod: [],
    orderBy: OrderBy.ID,
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const initRoomBookingHistoryQuery = {
    keyword: '',
    autoGeneratedCode: '',
    roomName: '',
    checkInDateTimePeriod: [],
    checkOutDateTimePeriod: [],
    orderBy: OrderBy.ID,
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

// UPLOAD ATTACHMENTS
// max size upload file 25 * 1024 * 1024 = 10485760
export const MAX_SIZE_FILE = 26214400; // 25Mb;
export const MAX_NUMBER_OF_FILES_PER_GUEST = 4;
export const ACCEPTED_FILE_EXTENSIONS = [
    '.jpg',
    '.jpeg',
    '.tiff',
    '.bmp',
    '.pdf',
    '.gif',
    '.png',
    '.svg',
];
export const ACCEPTED_FILE_FORMATS = [
    'JPG',
    'JPEG',
    'TIFF',
    'BMP',
    'PDF',
    'GIF',
    'PNG',
    'SVG',
];
export const errorMessagesFile = {
    NUMBER_OF_FILE_TOO_MUCH: i18next.t('common.uploadFile.message.maxNumberOfFiles', {
        count: MAX_NUMBER_OF_FILES_PER_GUEST,
    }),
    FILE_EXTENSION_NOT_ALLOW: i18next.t('guest.form.uploadFile.message.acceptTypeError', {
        format: ACCEPTED_FILE_FORMATS.join(', '),
    }),
    FILE_SIZE_TOO_LARGE: i18next.t('common.uploadFile.message.maxSizeError', {
        maxSize: MAX_SIZE_FILE / 1024 / 1024,
    }),
    FILE_DUPLICATE: i18next.t('common.uploadFile.message.fileDuplicate'),
    JSON_PARSING_FAILED: i18next.t('guest.form.uploadFile.message.guestDataNotJSON'),
    GUEST_DATA_NOT_EXIST: i18next.t('guest.form.uploadFile.message.guestDataRequire'),
};

export const UPLOAD_FILE_ERROR_I18N_KEY = 'uploadFile.upload.error';
