import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import yup from '~plugins/yup';

export const siteControllerSchema = yup.object().shape({
    tllName: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    tllBookingAcquisitionUrl: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .url()
        .required(),
    tllPmsUserId: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    tllPmsPassword: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
});
