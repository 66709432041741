const guestUpdate = {
    title: 'Edit guest information',
    breadcrumbs: {
        home: 'Home',
        update: 'Edit guest information',
    },
    tabPane: {
        basicInfo: 'Basic information',
        companyInfo: 'Company information',
        bookingHistory: 'History',
    },
    file: {
        notExist: 'There is a attachment which is not found',
        notBelongToGuest: 'There is a attachment which is not belong to this guest',
        guestFile: {
            notExist: 'There is a guest attachment which is not found',
        },
    },
    uploadFile: {
        upload: {
            error: 'An error occurred while uploading the file',
        },
    },
};

export default guestUpdate;
