import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import { BookingTemporaryCard } from '../BookingTemporaryCard/BookingTemporaryCard';
import './ConfirmCloseBookingModal.scss';

type Props = {
    bookings: IRoomBookingSchedule[];
    isOpen: boolean;
    handleCancel: () => void;
};

export function DeletedBookingModal(props: Props) {
    const { isOpen, bookings, handleCancel } = props;
    const { t } = useTranslation();
    const handleOk = () => {
        handleCancel();
    };

    return (
        <Modal
            title={t('roomBooking.schedule.bookingsDeleted')}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={t('common.ok')}
            className={'confirm-close-booking-modal'}
            footer={null}
        >
            {bookings.map((booking) => (
                <div key={booking.id} className="mb-10">
                    <BookingTemporaryCard showAction={false} booking={booking} />
                </div>
            ))}
        </Modal>
    );
}
