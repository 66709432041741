import { INPUT_TEXT_MAX_LENGTH, MIN_PRICE } from './../../common/constants';
import { MAX_PRICE } from '~common/constants';
import yup from '~plugins/yup';
import { MAX_SALE_ITEMS } from './constants';

export const settingSchema = yup.object().shape({
    quantity: yup
        .string()
        .required()
        .minFullAndHalfWidth(1)
        .maxFullAndHalfWidth(MAX_SALE_ITEMS),
    saleItemPrice: yup
        .string()
        .required()
        .minPriceFullAndHalfWidth(MIN_PRICE)
        .maxPriceFullAndHalfWidth(MAX_PRICE),
});

export const bulkCreateSaleItemsSchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                name: yup
                    .string()
                    .hasNoSpecialCharacters()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .required(),
                price: yup
                    .string()
                    .required()
                    .minPriceFullAndHalfWidth(MIN_PRICE)
                    .maxPriceFullAndHalfWidth(MAX_PRICE),
                taxId: yup.number().min(1).required(),
            })
            .required(),
    ),
});

export const bulkUpdateSaleItemsSchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                name: yup
                    .string()
                    .hasNoSpecialCharacters()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .required(),
                price: yup
                    .string()
                    .required()
                    .minPriceFullAndHalfWidth(MIN_PRICE)
                    .maxPriceFullAndHalfWidth(MAX_PRICE),
                taxId: yup.number().min(1).required(),
            })
            .required(),
    ),
});
