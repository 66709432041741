import i18next from '~plugins/i18next/i18n';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
export enum OrderBy {
    ID = 'id',
    NAME = 'name',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
}

export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    NAME = 'name',
    SALE_ITEM = 'saleItemName',
}

export const initSaleGroupListQuery = {
    keyword: '',
    name: '',
    autoGeneratedCode: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const enum SaleGroupListColumn {
    ID = 'autoGeneratedCode',
    NAME = 'name',
    SALE_ITEM = 'saleItemName',
}

export const breadcrumbs = () => [
    {
        text: i18next.t('saleGroup.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('saleGroup.list.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('saleGroup.list.breadcrumbs.saleGroup'),
    },
];

export const MAX_SALE_ITEM = 99;
export const MAX_ITEM_ERRORS = 5;

// CSV Export params
export const FILE_NAME = 'sale-group.csv';
export const EXPORT_CSV_FILE_NAME = 'sale-group';
export const exportColumns = ['autoGeneratedCode', 'name', 'saleItemName'];
export const i18ExportKey = 'saleGroup.list';

export enum ImportSaleGroupColumn {
    NAME = 'name',
    SALE_ITEM = 'saleItem',
}

export const MEAL_GROUP = '食事グループ';
