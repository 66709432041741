import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useNavigate } from 'react-router-dom';
import { Layout, Space, Button } from 'antd';
import { StaffLayoutHeader } from '~components';
import {
    getRoomList,
    scheduleStateSelector,
} from '~features/room-booking/reducers/schedule.reducer';
import { getChildrenDropdown } from '~features/children-type/reducers/children.reducer';
import { getPlanDropdown } from '~features/plan/reducers/plan.reducer';
import { createBookingStateSelector } from '~features/room-booking/reducers/create-booking.reducer';
import GroupBookingTable from './components/GroupBookingTable';
import { showConfirm } from '~common/notification';
import './GroupBookingPage.scss';
const { Footer, Content } = Layout;

const GroupBookingPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const groupTableRef = useRef<any>();
    const { isFetchingGroupBookingPriceList } = useAppSelector(
        createBookingStateSelector,
    );

    const { isCreatingGroupBookingItem, isFetchingRoomList } =
        useAppSelector(scheduleStateSelector);

    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);
    const breadcrumbs = () => [
        {
            text: t('roomBooking.schedule.breadcrumbs.home'),
        },
        {
            text: t('roomBooking.schedule.breadcrumbs.schedule'),
        },
        {
            text: t('roomBooking.schedule.breadcrumbs.groupBooking'),
        },
    ];

    useEffect(() => {
        dispatch(getRoomList());
        dispatch(getChildrenDropdown());
        dispatch(getPlanDropdown({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _showConfirm = () => {
        showConfirm({
            title: t('room.roomFormIndividual.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };
    const onCancel = () => {
        navigate('/room-booking/schedule');
    };

    const onSubmit = () => {
        if (groupTableRef && groupTableRef.current) {
            groupTableRef.current.onClickSubmit();
        }
    };

    return (
        <div className="group-booking-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('roomBooking.schedule.createBookingOptions.groupBooking')}
            />
            <Content>
                <GroupBookingTable
                    ref={groupTableRef}
                    setIsSubmitButtonDisabled={setIsSubmitButtonDisabled}
                />
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space align="end">
                        <Button onClick={_showConfirm}>
                            {t('room.roomFormIndividual.cancelBtn')}
                        </Button>
                        <Button
                            id="form-individual-submit"
                            type="primary"
                            onClick={onSubmit}
                            loading={
                                isFetchingGroupBookingPriceList ||
                                isCreatingGroupBookingItem ||
                                isFetchingRoomList
                            }
                            disabled={isSubmitButtonDisabled}
                        >
                            {t('room.roomFormIndividual.submitBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
};

export default GroupBookingPage;
