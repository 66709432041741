export const operationStatusPageEn = {
    titlePage: 'Occupancy Status',
    breadcrumbs: {
        report: 'Report',
        operationStatus: 'Occupancy Status',
    },
    filterForm: {
        dateLabel: 'Month:',
        channelLabel: 'Channel:',
        channel: {
            all: 'All channels',
        },
        displayButton: 'Display',
        noteInformation: 'Values in parentheses are from previous year',
    },
    table: {
        operationStatus: 'Occupancy status',
        utilRate: 'Occupancy rate',
        date: 'Date',
        totalRooms: 'Rooms',
        totalBookings: 'Stays',
        totalAdult: 'Adults',
        totalChildren: 'Children',
        sales: 'Sales',
        adr: 'ADR',
        revPAR: 'RevPAR',
        numberOfRoomsRate: 'Occupancy',
        adultCapacityRate: 'Capacity (adults)',
        day: {
            monday: 'Mon',
            tuesday: 'Tue',
            wednesday: 'Wed',
            thursday: 'Thu',
            friday: 'Fri',
            saturday: 'Sat',
            sunday: 'Sun',
        },
    },
};
