const marketingChannelGroupList = {
    title: '販売チャネルグループ設定',
    breadcrumbs: {
        home: 'ホーム',
        marketingChannel: '販売チャネル',
        marketingChannelGroupSetting: '販売チャネルグループ設定',
    },
    channelGroupColumn: {
        id: 'ID',
        marketingChannelGroupName: 'チャネルグループ名',
    },
    message: {
        deleteChannelGroupSuccess:
            'Marketing channel groups have been deleted successfully',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Marketing channel group name',
    },
    searchBox: 'Search',
    exportColumns: {
        name: 'Marketing channel group name',
        autoGeneratedCode: 'ID',
    },
    initData: {
        cantEdit: `Can't edit marketing channel group item`,
        cantDelete: `Can't delete marketing channel group item`,
    },
};

export default marketingChannelGroupList;
