const localTaxJa = {
    title: '宿泊税・入湯税明細表',
    breadcrumbs: {
        report: '帳票',
        localTax: '宿泊税・入湯税明細表',
    },
    filterForm: {
        dateLabel: '集計月:',
        filterButton: '表示',
        taxTypeLabel: '税金項目',
        taxTypeDropdownOptions: {
            tokyoLocalTax: '東京都宿泊税',
            kutchanLocalTax: '俱知安町宿泊税',
            bathTax: '入湯税',
        },
    },
    localTaxDetailColumn: {
        taxName: '科目',
        localTax1: '宿泊税(東京10000~)',
        localTax2: '宿泊税(東京15000~)',
        totalQuantity: '人数',
        totalAmount: '金額',
        day: '日',
        currency: '¥',
    },
    kutchanTaxDetailColumn: {
        kutchanTaxHeader: '宿泊税(北海道 虻田郡俱知安町)',
        numberOfGuests: '宿泊人数',
        totalBookingSales: '宿泊料金総額',
        totalTaxableSales: '課税標準額',
        totalTaxAmount: '宿泊税額',
        totalKutchanTax: '計',
    },
    bathTaxDetailColumn: {
        bathTaxHeader: '入湯税',
        bathTax2Header: '入湯税（大人）',
        bathTax4Header: '入湯税（日帰り）',
        quantity: '人数',
        amount: '金額',
        day: '日',
        currency: '¥',
    },
};

export default localTaxJa;
