export const roomManagementJa = {
    title: '部屋管理',
    roomIndicator: 'ルームインジケーター',
    cleaningList: '清掃一覧',
    selectIndicator: 'ページを選択',
    showBookingList: '未アサインリスト',
    expandBookingList: '全画面表示',
    roomStatus: {
        all: '全て',
        stay: '滞在',
        vacancy: '空室',
        stop_selling: '売止',
        finished: '使用終了',
        checked_in: '滞在',
        not_arrived: '未到着',
        checked_out: '出発',
    },
    facilityStatus: {
        all: '全て',
        stay: '滞在',
        vacancy: '空室',
        reserved: '未到着',
        started: '使用開始',
        finished: '使用終了',
    },
    plan: 'プラン',
    planPlaceholder: 'プランを選択してください',
    dayUse: 'デイユース',
    checked: 'ON',
    unChecked: 'OFF',
    cleaned: '清掃済み',
    notCleaned: '未清掃',
    stopSelling: '売止',
    reserve: '予約',
    statusCollapse: {
        title: 'ステータス',
        checkIn: 'チェックイン',
        checkOut: 'チェックアウト',
        notAssigned: '未アサイン',
        roomOccupancyRate: '客室稼働率',
    },
    unassignList: '未アサインリスト',
    closeAll: '全て閉じる',
    expandAll: '全て開く',
    openAll: '全て開く',
    unassignCount: '未アサイン予約',
    unassignListSearchNotFound: '検索結果は見つかりませんでした。',
    bookingId: 'Booking ID {{id}}',
    adultCount: '大人{{count}}人',
    childrenCount: '子供{{count}}人',
    assignment: 'アサイン',
    autoAssignment: '自動アサイン',
    drag: '希望の部屋にドラッグ＆ドロップ',
    cancel: 'キャンセル',
    autoAssign: {
        title: '自動アサイン',
        desc: 'アサインリストを確認して、問題なければアサインを実行してください。',
    },
    columns: {
        period: '期間',
        guests: '宿泊者',
        roomType: '部屋タイプ',
        room: '部屋',
        remarks: '備考',
    },
    drawer: {
        title: '部屋の売止設定',
        desc: '部屋のステータスを変更します。ステータス、期間、理由を入力してください。',
        statusLabel: 'ステータス',
        dateLabel: '期間（変更時のみ）',
        causeLabel: '理由',
        remarkLabel: '備考',
        remarkPlaceholder: '備考を入力してください',
    },
    causeOptions: {
        INVENTORY_ADJUSTMENT: '在庫調整',
        ROOM_ISSUES: '部屋の不具合',
        OTHERS: 'その他',
    },
    detail: {
        vacancyTitle: '空室 - {{name}}',
    },
    empty: 'なし',
    cancelReserve: '予約をキャンセル',
    checkIn: 'チェックイン',
    unassignButton: '割当解除',
    unassignDialog: {
        title: '割当の解除について',
        content: '選択された割当を解除してもよろしいでしょうか？',
        cancelText: 'キャンセル',
        okText: '解除',
    },
    clearStatusButton: '削除',
    editButton: '編集',
    clearStatusDialog: {
        title: 'ステータスは空室に変更します',
        content: '続行するとステータスは「空室」に変更します。よろしいでしょうか？',
        cancelText: 'キャンセル',
        okText: '続行',
    },
    seeMoreButton: 'もっと見る',
    stopSellingStatus: {
        vacant: '空室',
        unavailable: '売止',
    },
    update: {
        stopSelling: '部屋が売止に更新されました',
        cleaningStatus: '部屋の清掃ステータスが更新されました',
        continueSelling: '部屋が販売続行に更新されました',
    },
    currentDate: '本日',
    assignRoomSuccess: '部屋がアサインされました',
    bookingUnassigned: {
        more: 'もっと見る',
        less: 'もっと少なく見る',
    }
};
