import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { amplifyLogin } from '~plugins/amplify';
import { AppState } from '~plugins/redux-toolkit/store';
import { ILoginBody, ILoginState } from '../interface';
import { authService } from '../services/auth.api';

const initialState: ILoginState = {
    isFormBusy: false,
    username: '',
    password: '',
};

export const handleLogin = createAsyncThunk(
    'auth/handleLogin',
    async (body: ILoginBody) => {
        return await amplifyLogin(body.email, body.password);
    },
);

export const getProfile = createAsyncThunk('auth/getProfile', async () => {
    return await authService.getProfile();
});

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setFormBusy: (state, action) => {
            state.isFormBusy = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(handleLogin.pending, (state, action) => {
            state.isFormBusy = true;
        });

        builder.addCase(handleLogin.fulfilled, (state, action) => {
            state.isFormBusy = false;
        });

        builder.addCase(getProfile.pending, (state, action) => {
            state.isFormBusy = true;
        });

        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.isFormBusy = false;
        });

        builder.addCase(handleLogin.rejected, (state, action) => {
            state.isFormBusy = false;
        });
    },
});

export const isFormBusySelector = (state: AppState) => state.login.isFormBusy;

export const { setUsername, setPassword, setFormBusy } = loginSlice.actions;

export default loginSlice.reducer;
