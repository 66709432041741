import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Layout, notification, Space } from 'antd';
import { forEach } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showConfirm } from '~common/notification';
import { StaffLayoutHeader } from '~components';
import {
    INVENTORY_PAGE_FOOTER_HEIGHT,
    INVENTORY_PAGE_PADDING,
} from '~features/inventory/constants';
import {
    getStartDateAndEndDateByDay,
    groupByStoppingRoomItems,
} from '~features/inventory/helper';
import { IGridItem, IRoomItem, IStoppingRoomItem } from '~features/inventory/interfaces';
import {
    inventoryStopSellingStateSelector,
    resetState,
} from '~features/inventory/reducers/inventory-stop-selling.reducer';
import {
    bulkStopInventoryInsertRoomType,
    inventoryListStateSelector,
} from '~features/inventory/reducers/inventory.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import BulkStopSellingModal from './components/BulkStopSellingModal/BulkStopSellingModal';
import { StopSellingGrid } from './components/StopSellingGrid/StopSellingGrid';
import './StopSellingPage.scss';
import { STAFF_LAYOUT_HEADER_HEIGHT, TOP_BAR_HEIGHT } from '~common/constants';

const { Content, Footer } = Layout;

function StopSellingPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { stopSellingGridData } = useAppSelector(inventoryStopSellingStateSelector);
    const { showLoading } = useAppSelector(inventoryListStateSelector);
    const { showLoading: isFetchData } = useAppSelector(
        inventoryStopSellingStateSelector,
    );

    const oldFormData = useRef<IGridItem[]>([]);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (!stopSellingGridData) {
            oldFormData.current = [];
            return;
        }
    }, [stopSellingGridData]);

    useEffect(() => {
        oldFormData.current = stopSellingGridData;
    }, [isFetchData]);

    const checkRoomExist = useMemo(() => {
        return stopSellingGridData.filter((item) => item.parentId).length > 0;
    }, [stopSellingGridData]);

    const submitFormSuccess = useCallback(() => {
        onCancel();
    }, []);

    const breadcrumbs = () => {
        return [
            {
                text: t('inventory.list.breadcrumbs.home'),
            },
            {
                text: t('inventory.list.breadcrumbs.frontSetting'),
            },
            {
                text: t('inventory.list.breadcrumbs.inventory'),
            },
            {
                text: t('inventory.list.breadcrumbs.stopSelling'),
            },
        ];
    };

    const _showConfirm = () => {
        if (!checkRoomExist) {
            onCancel();
            return;
        }
        showConfirm({
            title: t('inventory.stopSelling.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    const onCancel = () => {
        navigate('/front-settings/inventory');
    };

    const _bulkStopInventory = async (body: IStoppingRoomItem[]) => {
        const response = await dispatch(bulkStopInventoryInsertRoomType(body));
        if (bulkStopInventoryInsertRoomType.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('inventory.list.message.updateSuccess'),
                });
                onCancel();
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };

    const flattenData = (items: IGridItem[]) => {
        const list: IRoomItem[] = [];
        items.forEach((item) => {
            forEach(item.date, (val, date) => {
                list.push({
                    key: `${item.id}_${date}`,
                    isChecked: val.isChecked,
                    isBlocked: val.isBooked,
                    parentId: item.parentId,
                    date: date,
                    memo: val?.memo,
                    reason: val?.reason,
                });
            });
        });
        return list;
    };

    const differenceData = (newItems: IRoomItem[], oldData: IRoomItem[]) => {
        return newItems.filter((item, index) => {
            if (item.isBlocked) return false;
            if (!item.parentId) return false;
            return item.isChecked || item.isChecked !== oldData[index].isChecked;
        });
    };

    const onSubmit = () => {
        const groupRoomByDate: Record<
            string,
            Record<
                string,
                {
                    roomId: number;
                    memo?: string;
                    reason?: string;
                }[]
            >
        > = {};

        const diffData = differenceData(
            flattenData(stopSellingGridData),
            flattenData(oldFormData.current),
        );

        forEach(diffData, (item) => {
            // group room by date and room type ex: roomType_1: {2021-01-01: [{roomId: 1}, {roomId: 2}]}
            const roomTypeKey = `roomType_${item.parentId}`;
            if (!groupRoomByDate[roomTypeKey]) {
                groupRoomByDate[roomTypeKey] = {};
            }
            if (!groupRoomByDate[roomTypeKey][item.date]) {
                groupRoomByDate[roomTypeKey][item.date] = [];
            }
            if (item.isChecked) {
                groupRoomByDate[roomTypeKey][item.date].push({
                    roomId: +`${item.key}`.split('_')?.[1],
                    memo: item.memo,
                    reason: item.reason,
                });
            }
        });

        const formData: IStoppingRoomItem[] = groupByStoppingRoomItems(groupRoomByDate);

        const mappedBody = formData.map((data) => {
            const { startDate, endDate } = getStartDateAndEndDateByDay(
                data.startDate,
                data.endDate,
            );
            return {
                ...data,
                startDate,
                endDate,
            };
        });

        _bulkStopInventory(mappedBody);
    };

    const contentHeight = useMemo(() => {
        return (
            window.innerHeight -
            TOP_BAR_HEIGHT -
            STAFF_LAYOUT_HEADER_HEIGHT -
            INVENTORY_PAGE_PADDING -
            INVENTORY_PAGE_FOOTER_HEIGHT
        );
    }, []);

    return (
        <div className="stop-selling-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('inventory.stopSelling.title')}
                rightComponent={
                    <div className="right-button-group">
                        <div className="stop-selling-note">
                            <InfoCircleFilled className="stop-selling-note-icon" />
                            {t('inventory.stopSelling.guideMessage')}
                        </div>
                    </div>
                }
            />
            <Content>
                <div
                    className="stop-selling-page-wrapper"
                    style={{ height: `${contentHeight}px` }}
                >
                    <StopSellingGrid height={contentHeight} />
                    <BulkStopSellingModal onSubmitSuccess={submitFormSuccess} />
                </div>
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space align="end">
                        <Button onClick={_showConfirm} disabled={showLoading}>
                            {t('common.buttonCancelText')}
                        </Button>
                        <Button
                            type="primary"
                            loading={showLoading}
                            onClick={onSubmit}
                            disabled={!checkRoomExist}
                        >
                            {t('inventory.stopSelling.buttons.save')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default StopSellingPage;
