const hotelList = {
    title: 'Hotel management',
    breadcrumbs: {
        home: 'Back Office Setting',
        hotel: 'Hotel Management',
    },
    primaryButton: 'Add new hotel',
    searchColumn: {
        keyword: 'All',
        name: 'Hotel Name',
        peCsrHotelCode: 'Hotel ID',
        picName: 'Name of person in-charge',
        postalCode: 'Postal Code',
        address: 'Address',
        picPhoneNumber: 'Phone number',
        picEmailAddress: 'Email address',
    },
    hotelListColumn: {
        hotelName: 'Hotel Name',
        peCsrHotelCode: 'Hotel ID',
        tllPmsUserId: 'Site Controller Interlink ID',
        picName: 'Person In Charge',
        postalCode: 'Postal Code',
        address: 'Address',
        picPhoneNumber: 'Phone number',
        picEmailAddress: 'Email address',
        numberOfRoom: 'Number of room',
        standardCheckInTime: 'Default check-in time',
        standardCheckOutTime: 'Default check-out time',
        status: 'Status',
        permission: 'Label',
        tagStatus: {
            active: 'Active',
            inactive: 'Inactive',
        },
        tagPermission: {
            pms: 'Tabist Hotels',
        },
    },
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Submit',
        deleteHotelsSuccess: 'Hotels have been deleted successfully',
    },
    exportColumns: {
        peCsrHotelCode: 'Hotel ID',
        name: 'Hotel Name',
        tllPmsUserId: 'Site Controller Interlink ID',
        picName: 'Person In Charge',
        postalCode: 'Postal Code',
        address: 'Address',
        picPhoneNumber: 'Phone number',
        picEmailAddress: 'Email address',
        numberOfRoom: 'Number of room',
        standardCheckInTime: 'Default check-in time',
        standardCheckOutTime: 'Default check-out time',
        status: 'Status',
        permission: 'Label',
    },
};
export default hotelList;
