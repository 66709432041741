import { Button, Card, Col, Form, Row, notification } from 'antd';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputTextArea, RadioGroup, RangePicker, SingleSelect } from '~components';
import {
    getStartDateAndEndDateByDay,
    groupByStoppingRoomItems,
} from '~features/inventory/helper';
import {
    IStoppingRoomInDurationItem,
    IStoppingRoomItem,
} from '~features/inventory/interfaces';
import { bulkStopInventoryInsertRoomType } from '~features/inventory/reducers/inventory.reducer';
import { getCreateBookingFormId } from '~features/room-booking/helper';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import {
    getStopRoomInDuration,
    getStopRoomInDurationWithStartAndEnd,
    scheduleStateSelector,
    setVisibleCreateBookingPopover,
} from '~features/room-booking/reducers/schedule.reducer';
import {
    blockRoomFormSchema,
    blockRoomFormSchemaResolver,
} from '~features/room-booking/schema';
import { StopRoomCause } from '~features/room-management/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import dayjs from '~plugins/dayjs';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import { StoppingRoomsActions } from '~features/room-booking/constants';
import yup from '~plugins/yup';
import { IBodyResponse } from '~common/interfaces';

type StoppingRoomsActionsType = keyof typeof StoppingRoomsActions;
type Props = {
    close: () => void;
    booking: IRoomBookingSchedule;
    usePopover: boolean;
    actionType: StoppingRoomsActionsType;
};
type BlockRoomFormType = yup.InferType<typeof blockRoomFormSchema>;

const StoppingRoomsModal = ({ close, booking, usePopover, actionType }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { stoppingRoomList, endDateWeekView } = useAppSelector(scheduleStateSelector);
    const { roomTypesDropdown } = useAppSelector(scheduleStateSelector);
    const disableDate = (current: Dayjs) => {
        return current.isBefore(customDayjs().subtract(1, 'day'), 'day');
    };
    const { handleSubmit, control, reset } = useForm<BlockRoomFormType>({
        resolver: blockRoomFormSchemaResolver,
    });

    const roomTypesOptions = useMemo(() => {
        return roomTypesDropdown.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }, [roomTypesDropdown]);
    const causeOptions = useMemo(() => {
        return Object.values(StopRoomCause).map((item) => {
            return {
                value: item,
                label: t(`roomManagement.list.causeOptions.${item}`),
            };
        });
    }, []);

    const stoppingRoomsByDate = Object.entries(stoppingRoomList).reduce(
        (acc, roomList) => {
            Object.entries(roomList[1]).forEach((roomTypeId) => {
                Object.entries(roomTypeId[1]).forEach((roomId) => {
                    acc.push({
                        date: roomList[0],
                        roomTypeId: roomId[1].roomTypeId,
                        roomId: roomId[1].roomId,
                        reason: roomId[1].reason,
                        memo: roomId[1].memo,
                    });
                });
            });
            return acc;
        },
        [] as {
            date: string;
            reason?: string;
            roomTypeId?: number;
            roomId?: number;
            memo?: string;
        }[],
    );
    const targetStoppingInventoryItem = stoppingRoomsByDate.find(
        (stoppingRoom) =>
            stoppingRoom.date === booking.stayingStartDate &&
            stoppingRoom.roomTypeId === booking.roomType.id &&
            stoppingRoom.roomId === booking.room.id,
    );

    const onClose = () => {
        reset();
        close();
        dispatch(setVisibleCreateBookingPopover(false));
    };

    const _bulkStopInventory = async (body: IStoppingRoomItem[]) => {
        const response = await dispatch(bulkStopInventoryInsertRoomType(body));
        if (bulkStopInventoryInsertRoomType.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('inventory.list.message.updateSuccess'),
                });
                dispatch(getStopRoomInDuration());
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };
    const _getMoreStoppingRoomsByDate = async (startDate: Dayjs, endDate: Dayjs) => {
        const { startDate: start, endDate: end } = getStartDateAndEndDateByDay(
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD'),
        );
        const result = await dispatch(
            getStopRoomInDurationWithStartAndEnd({
                start,
                end,
            }),
        );

        const newData = (result.payload as IBodyResponse<IStoppingRoomInDurationItem[]>)
            .data as IStoppingRoomInDurationItem[];

        const newStopingRoomList = newData.reduce(
            (acc, room) => {
                const date = parseDate(room.date)?.fmYYYYMMDD();
                const obj = {
                    [`roomType_${room.roomTypeId}`]: room.stoppingInventoryItems.reduce(
                        (acc, item) => {
                            acc[`room_${item.roomId}`] = {
                                stopping: true,
                                memo: item.memo,
                                reason: item.reason as StopRoomCause,
                                roomTypeId: room.roomTypeId,
                                roomId: item.roomId,
                            };
                            return acc;
                        },
                        {} as Record<
                            string,
                            {
                                stopping: boolean;
                                roomTypeId: number;
                                roomId: number;
                                memo?: string;
                                reason?: StopRoomCause;
                            }
                        >,
                    ),
                };
                acc[date] = { ...acc[date], ...obj };

                return acc;
            },
            {} as Record<
                string,
                Record<
                    string,
                    Record<
                        string,
                        {
                            stopping: boolean;
                            roomTypeId: number;
                            roomId: number;
                            memo?: string;
                            reason?: StopRoomCause;
                        }
                    >
                >
            >,
        );

        return newStopingRoomList;
    };

    const blockOrReleaseRooms = async (data: BlockRoomFormType) => {
        const selectedRoomTypeId = data.roomTypeId;
        const selectedRoomId = booking.room.id;
        const startDate = data.date[0] as Dayjs;
        const endDate = (data.date[1] as Dayjs).add(1, 'day');
        const dateDiff = endDate.diff(startDate, 'day');
        const reason = data.cause;
        const remark = data.remark;
        const selectedtDates = Array.from({ length: dateDiff }).map((_, i) =>
            startDate.clone().add(i, 'day').format('YYYY-MM-DD'),
        );

        if (!selectedRoomId) return;

        const groupRoomByDate: Record<
            string,
            Record<string, { roomId: number; memo?: string; reason?: string }[]>
        > = {};

        let existingStoppingRoomListDuringSelectedDates = stoppingRoomList;

        if (parseDate(endDateWeekView) <= endDate) {
            existingStoppingRoomListDuringSelectedDates =
                await _getMoreStoppingRoomsByDate(startDate, endDate);
        }

        groupRoomByDate[`roomType_${selectedRoomTypeId}`] = {};

        const existingStoppingBookingsByRoomType = Object.entries(
            existingStoppingRoomListDuringSelectedDates,
        ).flatMap((stoppingRoomList) =>
            Object.entries(stoppingRoomList[1]).flatMap((stoppingRoom) => ({
                roomTypeId: stoppingRoom[0],
                date: stoppingRoomList[0],
                room: Object.entries(stoppingRoom[1]).flatMap((room) => room[1]),
            })),
        );

        if (actionType === StoppingRoomsActions.BLOCK_ROOM) {
            // set curretly blocked rooms to groupRoomByDate
            Array.from({ length: dateDiff }).forEach((_, i) => {
                groupRoomByDate[`roomType_${selectedRoomTypeId}`][
                    startDate.clone().add(i, 'day').format('YYYY-MM-DD')
                ] = [{ roomId: selectedRoomId, memo: remark, reason: reason }];
            });

            // add existing blocked rooms to groupRoomByDate
            existingStoppingBookingsByRoomType.forEach((item) => {
                if (item.roomTypeId !== `roomType_${selectedRoomTypeId}`) return;
                if (dayjs(item.date).isBefore(startDate)) return;

                const targetExistingItems = item.room
                    .map((room) => ({
                        roomId: room.roomId || 0,
                        memo: room.memo,
                        reason: room.reason,
                    }))
                    .filter(
                        (i) =>
                            !(
                                selectedtDates.includes(item.date) &&
                                i.roomId === selectedRoomId
                            ),
                    );

                if (groupRoomByDate[item.roomTypeId][item.date]) {
                    return (groupRoomByDate[item.roomTypeId][item.date] = [
                        ...groupRoomByDate[item.roomTypeId][item.date],
                        ...targetExistingItems,
                    ]);
                }

                return (groupRoomByDate[item.roomTypeId][item.date] =
                    targetExistingItems);
            });
        } else {
            existingStoppingBookingsByRoomType.forEach((item) => {
                if (item.roomTypeId !== `roomType_${selectedRoomTypeId}`) return;
                if (dayjs(item.date).isBefore(startDate)) return;

                if (selectedtDates.includes(item.date)) {
                    return (groupRoomByDate[item.roomTypeId][item.date] = item.room
                        .filter((room) => room.roomId !== selectedRoomId)
                        .map((room) => ({
                            roomId: room.roomId || 0,
                            memo: room.memo,
                            reason: room.reason,
                        })));
                }

                return (groupRoomByDate[item.roomTypeId][item.date] = item.room.map(
                    (room) => ({
                        roomId: room.roomId || 0,
                        memo: room.memo,
                        reason: room.reason,
                    }),
                ));
            });
        }

        const formData = groupByStoppingRoomItems(groupRoomByDate);

        const mappedBody = formData.map((data) => {
            const { startDate, endDate } = getStartDateAndEndDateByDay(
                data.startDate,
                data.endDate,
            );
            return {
                ...data,
                startDate,
                endDate,
            };
        });

        _bulkStopInventory(mappedBody);

        onClose();
    };

    return (
        <Card
            title={
                actionType === StoppingRoomsActions.BLOCK_ROOM
                    ? t('roomBooking.schedule.stoppingRoomsModal.stoppingRooms')
                    : t('roomBooking.schedule.stoppingRoomsModal.releasingRooms')
            }
            className="create-booking-card"
        >
            <div className="booking-card-content">
                <Form layout="vertical">
                    <Row>
                        <Col span={24}>
                            <SingleSelect
                                label={t(
                                    'roomBooking.schedule.stoppingRoomsModal.form.roomType',
                                )}
                                placeholder={t(
                                    'roomBooking.schedule.stoppingRoomsModal.form.roomType',
                                )}
                                name="roomTypeId"
                                id={getCreateBookingFormId('roomTypeId')}
                                control={control}
                                required
                                allowClear
                                defaultValue={booking?.roomType?.id || ''}
                                options={roomTypesOptions}
                                disabled={usePopover}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <RangePicker
                            required
                            label={`${t(
                                'roomBooking.schedule.stoppingRoomsModal.form.stoppingPeriod.label',
                            )} (${t('common.standardTimeTitle')})`}
                            placeholder={[
                                t(
                                    'roomBooking.schedule.stoppingRoomsModal.form.stoppingPeriod.start',
                                ),
                                t(
                                    'roomBooking.schedule.stoppingRoomsModal.form.stoppingPeriod.end',
                                ),
                            ]}
                            name="date"
                            defaultValue={[
                                parseDate(booking.stayingStartDate),
                                parseDate(booking.stayingEndDate),
                            ]}
                            control={control}
                            disabledDate={disableDate}
                        />
                    </Row>
                    <Row>
                        <Col span={20}>
                            <RadioGroup
                                control={control}
                                name="cause"
                                options={causeOptions}
                                defaultValue={
                                    targetStoppingInventoryItem?.reason ||
                                    causeOptions[0].value
                                }
                                disabled={actionType !== StoppingRoomsActions.BLOCK_ROOM}
                                label={t(
                                    'roomBooking.schedule.stoppingRoomsModal.form.cause',
                                )}
                            />
                        </Col>
                    </Row>
                    {actionType === StoppingRoomsActions.BLOCK_ROOM && (
                        <Row>
                            <InputTextArea
                                control={control}
                                name="remark"
                                label={t(
                                    'roomBooking.schedule.stoppingRoomsModal.form.reason',
                                )}
                                disabled={actionType !== StoppingRoomsActions.BLOCK_ROOM}
                                placeholder={t(
                                    'roomBooking.schedule.stoppingRoomsModal.form.reason',
                                )}
                            />
                        </Row>
                    )}
                </Form>
            </div>
            <div className="booking-card-footer">
                <Button onClick={onClose}>{t('common.buttonCancelText')}</Button>
                <Button
                    onClick={handleSubmit((formData) => blockOrReleaseRooms(formData))}
                    className="btn-submit"
                    type="primary"
                    id={getCreateBookingFormId('submit')}
                >
                    {actionType === StoppingRoomsActions.BLOCK_ROOM
                        ? t('roomBooking.schedule.stoppingRoomsModal.stoppingRooms')
                        : t('roomBooking.schedule.stoppingRoomsModal.releasingRooms')}
                </Button>
            </div>
        </Card>
    );
};

export default StoppingRoomsModal;
