import { PrinterOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Modal, Tabs } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReceiptByGroupMapIndex } from '~features/facility-booking/constants';
import { isSplitAllReceiptItemDetails } from '~features/facility-booking/helper.split-receipt';
import { IReceiptByGroup } from '~features/facility-booking/interfaces';
import {
    setIsShowSplitFacilityBookingReceiptPrintingModal,
    setPrintingReceiptByGroupList,
    splitReceiptStateSelector,
} from '~features/facility-booking/reducers/split-receipt.reducer';
import { facilityBookingReceiptSelector } from '~features/facility-booking/reducers/facility-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { ReceiptFilesModal } from './ReceiptFilesModal';
import BillToTabItem from '../BillToTabItem/BillToTabItem';
import './BillToTab.scss';
import { INVOICE_PATH } from '~features/room-booking/constants';

interface ITab {
    label: string;
    key: string;
    children: JSX.Element;
}

function BillToTab() {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const receipt = useAppSelector(facilityBookingReceiptSelector);
    const { receiptByGroupMap } = useAppSelector(splitReceiptStateSelector);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

    const facilityBookingItemTabs = useMemo((): ITab[] => {
        return Object.keys(receiptByGroupMap).reduce(
            (facilityBookingItemTabs: ITab[], groupId: string) => {
                if (groupId === ReceiptByGroupMapIndex.ALL)
                    return facilityBookingItemTabs;

                const { group, receipt } = receiptByGroupMap[groupId];
                return facilityBookingItemTabs.concat({
                    label: `${t(`facilityBooking.splitReceipt.group`)}  ${group?.name}`,
                    key: group?.name || '',
                    children: (
                        <BillToTabItem
                            groupName={`${t(`facilityBooking.splitReceipt.group`)}   ${
                                group?.name
                            }`}
                            groupId={group?.id || ''}
                            receipt={receipt}
                        />
                    ),
                });
            },
            [],
        );
    }, [receiptByGroupMap]);

    const onPrintAllReceiptByRoom = () => {
        const receiptByGroupAllMap = receiptByGroupMap[ReceiptByGroupMapIndex.ALL];
        if (!isSplitAllReceiptItemDetails(receiptByGroupAllMap.receipt)) {
            Modal.warning({
                title: t('facilityBooking.splitReceipt.notificationAlert.notSplitDone'),
            });
            return;
        }
        const receiptByGroupList = Object.keys(receiptByGroupMap).reduce(
            (receiptByGroupList: IReceiptByGroup[], key) => {
                if (key === ReceiptByGroupMapIndex.ALL) return receiptByGroupList;
                receiptByGroupList.push(receiptByGroupMap[key]);
                return receiptByGroupList;
            },
            [],
        );
        dispatch(setPrintingReceiptByGroupList(receiptByGroupList));
        dispatch(setIsShowSplitFacilityBookingReceiptPrintingModal(true));
    };

    return (
        <div className="split-facility-booking-bill-to-tab-wrapper">
            <div className="bill-to-tab-header">
                {t('facilityBooking.splitReceipt.billToTab.title')}
                <Button type="primary" onClick={onPrintAllReceiptByRoom}>
                    <PrinterOutlined />
                    {t('facilityBooking.splitReceipt.billToTab.printAll')}
                </Button>
                <Button onClick={() => setIsHistoryModalOpen(true)}>
                    <UnorderedListOutlined />
                    {pathname.endsWith(INVOICE_PATH) ? t('facilityBooking.splitReceipt.billToTab.invoiceIssueHistory') : t('facilityBooking.splitReceipt.billToTab.issueHistory')}
                </Button>
            </div>
            <Tabs type="card" items={facilityBookingItemTabs} />
            {receipt && (
                <ReceiptFilesModal
                    isOpen={isHistoryModalOpen}
                    setIsOpen={setIsHistoryModalOpen}
                    receiptId={receipt.id}
                />
            )}
        </div>
    );
}

export default BillToTab;
