import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { paymentDetailsBreadcrumbs } from '~features/report/constants';
import { paymentDetailsSelector } from '~features/report/reducers/report-payment-details.reducer';
import { useAppSelector } from '~hooks';
import FilterForm from './components/FilterForm/FilterForm';
import PaymentDetailList from './components/PaymentDetailList/PaymentDetailList';

export default function PaymentDetailsPage() {
    const { t } = useTranslation();
    const { isShowTable } = useAppSelector(paymentDetailsSelector);

    return (
        <div>
            <StaffLayoutHeader
                title={t('report.paymentDetails.title')}
                breadcrumbs={paymentDetailsBreadcrumbs()}
            />

            <FilterForm />
            {isShowTable && <PaymentDetailList />}
        </div>
    );
}
