import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '../../common/constants';
import { SearchField, initHotelListQuery } from './constants';
import { cloneDeep, trim } from 'lodash';
import {
    IHotelState,
    IHotelDropDownQuery,
    IHotelUpdateRequest,
    IHotelGetListQuery,
    IHotelCreateBody,
    IHotelSettingUpdate,
} from './interfaces';
import { hotelService } from './services/hotel.api';

const initialState: IHotelState = {
    hotelListQuery: cloneDeep(initHotelListQuery),
    hotelDropdownOptions: [],
    hotelList: [],
    totalHotels: 0,
    operatableHotels: [],
    selectedHotelSetting: null,
    selectedHotel: null,
    formBusy: false,
    showLoadingList: false,
    searchField: SearchField.ALL,
    showForm: false,
    isShowBankAccountModal: false,
    editingBankAccount: null,
};

export const fetchHotelList = createAsyncThunk(
    'hotel/fetchHotelList',
    async (_, { getState }) => {
        const query = hotelListQuerySelector(getState() as AppState);
        return await hotelService.getList(query);
    },
);

export const getHotelListForDropdown = createAsyncThunk(
    'hotel/getHotelListForDropdown',
    async (params?: IHotelDropDownQuery) => {
        return await hotelService.getHotelListForDropdown(params);
    },
);

export const getListHotelByPeCsrHotelCodes = createAsyncThunk(
    'hotel/getHotelListByPeCsrHotelCodes',
    async (peCsrHotelCodes: string[]) => {
        return await hotelService.getBypeCsrHotelCodes(peCsrHotelCodes);
    },
);

export const getHotelDetail = createAsyncThunk(
    'hotel/getHotelDetail',
    async (id: number) => {
        return await hotelService.getDetail(id);
    },
);

export const createHotel = createAsyncThunk(
    'hotel/createHotel',
    async (formData: IHotelCreateBody) => {
        return await hotelService.create(formData);
    },
);

export const updateHotel = createAsyncThunk(
    'hotel/updateHotel',
    async (formData: IHotelUpdateRequest) => {
        return await hotelService.updateHotel(formData.id, formData.body);
    },
);

export const updateHotelSetting = createAsyncThunk(
    'hotel-setting/updateHotel',
    async (formData: IHotelSettingUpdate) => {
        return await hotelService.updateHoteSetting(formData.id, formData.body);
    },
);

export const hotelSlice = createSlice({
    name: 'hotel',
    initialState,
    reducers: {
        setHotelListQuery: (state, action) => {
            state.hotelListQuery = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IHotelGetListQuery = {
                limit: state.hotelListQuery.limit,
                orderBy: state.hotelListQuery.orderBy,
                orderDirection: state.hotelListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.hotelListQuery = query;
        },
        setHotelList: (state, action) => {
            state.hotelList = action.payload;
        },
        setHotelDropdownOptions: (state, action) => {
            state.hotelDropdownOptions = action.payload;
        },
        setSelectedHotel: (state, action) => {
            state.selectedHotel = action.payload;
        },
        setSelectedHotelSetting: (state, action) => {
            state.selectedHotelSetting = action.payload;
        },
        setShowForm: (state, action) => {
            state.showForm = action.payload;
        },
        setOperatableHotels: (state, action) => {
            state.operatableHotels = action.payload;
        },
        setTotalHotels: (state, action) => {
            state.totalHotels = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IHotelGetListQuery = {
                limit: state.hotelListQuery.limit,
                orderBy: state.hotelListQuery.orderBy,
                orderDirection: state.hotelListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.hotelListQuery.keyword ||
                    state.hotelListQuery.autoGeneratedCode ||
                    state.hotelListQuery.name ||
                    state.hotelListQuery.picName ||
                    state.hotelListQuery.postalCode ||
                    state.hotelListQuery.address ||
                    state.hotelListQuery.picPhoneNumber ||
                    state.hotelListQuery.picEmailAddress ||
                    state.hotelListQuery.peCsrHotelCode ||
                    state.hotelListQuery.tllSystemId ||
                    state.hotelListQuery.tllPmsUserId ||
                    state.hotelListQuery.tllPmsPassword ||
                    '',
            };
            state.hotelListQuery = query;
        },
        setShowBankAccountModal: (state, action) => {
            state.isShowBankAccountModal = action.payload;
        },
        setEditingBankAccount: (state, action) => {
            state.editingBankAccount = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHotelList.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.hotelList = action.payload?.data?.items || [];
            state.totalHotels = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchHotelList.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(getHotelListForDropdown.fulfilled, (state, action) => {
            state.hotelDropdownOptions = action.payload?.data?.items;
        });
        builder.addCase(createHotel.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(createHotel.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(updateHotel.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(updateHotel.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(updateHotelSetting.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(updateHotelSetting.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(getHotelDetail.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(getHotelDetail.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(getListHotelByPeCsrHotelCodes.fulfilled, (state, action) => {
            if (state.selectedHotelSetting) {
                const newListHotelSetting = (action.payload?.data || []).filter(
                    (item) => {
                        return (
                            item.autoGeneratedCode !==
                            state.selectedHotelSetting?.autoGeneratedCode
                        );
                    },
                );
                newListHotelSetting.splice(0, 0, state.selectedHotelSetting);
                state.operatableHotels = newListHotelSetting || [];
            } else {
                state.operatableHotels = action.payload?.data || [];
            }
        });
        builder.addCase(getListHotelByPeCsrHotelCodes.pending, (state, action) => {
            state.operatableHotels = [];
        });
    },
});

export const {
    setHotelDropdownOptions,
    setSelectedHotel,
    setOperatableHotels,
    setHotelListQuery,
    setHotelList,
    setSearchField,
    setKeyword,
    setTotalHotels,
    setShowForm,
    setSelectedHotelSetting,
    setShowBankAccountModal,
    setEditingBankAccount
} = hotelSlice.actions;

export const hotelDropdownOptionsSelector = (state: AppState) => {
    return state.hotel.hotelDropdownOptions;
};

export const selectedHotelSelector = (state: AppState) => {
    return state.hotel.selectedHotel;
};

export const selectedHotelSettingSelector = (state: AppState) => {
    return state.hotel.selectedHotelSetting;
};

export const hotelListSelector = (state: AppState) => {
    return state.hotel.hotelList;
};

export const hotelListQuerySelector = (state: AppState) => {
    return state.hotel.hotelListQuery;
};

export const totalHotelsSelector = (state: AppState) => {
    return state.hotel.totalHotels;
};

export const showLoadingListSelector = (state: AppState) => {
    return state.hotel.showLoadingList;
};

export const formBusySelector = (state: AppState) => {
    return state.hotel.formBusy;
};

export const showFormSelector = (state: AppState) => {
    return state.hotel.showForm;
};

export const operatableHotelsSelector = (state: AppState) => {
    return state.hotel.operatableHotels;
};

export const totalPageSelector = (state: AppState) => {
    const { totalHotels, hotelListQuery } = state.hotel;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = hotelListQuery;
    return Math.ceil(totalHotels / limit);
};

export const showBankAccountModal = (state: AppState) => {
    return state.hotel.isShowBankAccountModal;
};

export const editingBankAccountSelector = (state: AppState) => {
    return state.hotel.editingBankAccount;
};

export default hotelSlice.reducer;
