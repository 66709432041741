import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import yup from '~plugins/yup';

export const bulkCreateSaleGroupsSchema = yup.object().shape({
    name: yup.string().hasNoSpecialCharacters().max(INPUT_TEXT_MAX_LENGTH).required(),
    saleItems: yup.array().of(
        yup.object().shape({
            saleItemId: yup.number().required(),
        }),
    ),
});
