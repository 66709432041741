const guestDetail = {
    title: 'ゲスト情報の詳細',
    breadcrumbs: {
        guestDetail: 'ゲスト情報の詳細',
    },
    tabPane: {
        basicInfo: '基本情報',
        companyInfo: '会社情報',
        bookingHistory: '履歴',
    },
    editButton: '編集する',
    label: {
        fullName: 'お客様名',
        yomigana: 'ヨミガナ',
        createdAt: '作成日',
        createdBy: '作成者',
        mobilePhoneNumber: '電話番号',
        emailAddress: 'メールアドレス',
        gender: '性別',
        country: '国籍',
        postalCode: '郵便番号',
        address: '住所',
        classification: '識別',
        birthday: '生年月日',
        memo: 'メモ',
        identificationCard: '添付ファイル',
        companyName: '会社名',
        companyYomigana: '会社名（ヨミガナ）',
        companyEmailAddress: 'メールアドレス',
        companyPhoneNumber: '電話番号',
        companyPostalCode: '郵便番号',
        companyAddress: '住所',
        companyWebsite: 'ウェブサイト',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: '予約番号',
        roomName: '部屋名',
        facilityName: '設備名',
        checkInTime: 'C/I',
        checkOutTime: 'C/O',
    },
    classification: {
        vip: '上客',
        regular: '常連',
        beCareful: '要注意',
    },
    gender: {
        male: '男性',
        female: '女性',
        other: 'その他',
    },
    roomBookingHistory: {
        headerTitle: '施設予約一覧',
        column: {
            autoGeneratedCode: '予約番号',
            yomigana: 'ヨミガナ',
            fullName: '宿泊者',
            roomName: '部屋名',
            checkInTime: 'C/I',
            checkOutTime: 'C/O',
        },
        exportColumns: {
            autoGeneratedCode: '予約番号',
            fullName: '宿泊者',
            yomigana: 'ヨミガナ',
            roomName: '部屋名',
            checkInTime: 'C/I',
            checkOutTime: 'C/O',
        },
    },
    facilityBookingHistory: {
        headerTitle: '設備予約一覧',
        column: {
            autoGeneratedCode: '予約番号',
            yomigana: 'ヨミガナ',
            fullName: '宿泊者',
            facilityName: '設備名',
            checkInTime: 'C/I',
            checkOutTime: 'C/O',
        },
        exportColumns: {
            autoGeneratedCode: '予約番号',
            fullName: '宿泊者',
            yomigana: 'ヨミガナ',
            facilityName: '設備名',
            checkInTime: 'C/I',
            checkOutTime: 'C/O',
        },
    },
};

export default guestDetail;
