export default {
    cleaningList: '清掃リスト',
    date: '{{year}}年{{month}}月{{day}}日 ({{dayOfWeek}}) {{time}}',
    day: {
        monday: '月',
        tuesday: '火',
        wednesday: '水',
        thursday: '木',
        friday: '金',
        saturday: '土',
        sunday: '日',
    },
    no: 'No',
    roomName: '部屋名',
    roomType: '部屋タイプ',
    numberOfNights: '泊数',
    isCleaned: '清掃',
    cleaned: '済み',
    uncleaned: '未',
    currentStatus: 'ステータス',
    status: {
        all: '全て',
        stay: '滞在',
        vacancy: '空室',
        stop_selling: '売止',
        check_out: '出発',
    },
    schedule: 'チェックイン予定',
    time: 'C/I時間',
    numberOfGuests: '人数',
    memo: 'メモ',
    personInCharge: '担当',
    notification: {
        fetchFailed: 'エラーが発生しました',
    },
};
