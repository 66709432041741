import { Table } from 'antd';
import { differenceBy, orderBy, sumBy, uniqBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { downloadCSVFile, formatMoney } from '~common/commonFunctions';
import { TextTruncate } from '~components';
import { IPaymentMethodDropDown } from '~features/payment-method/interfaces';
import { paymentMethodStateSelector } from '~features/payment-method/reducers/paymentMethod.reducer';
import {
    IPaymentDetail,
    IPaymentDetailsReceiptItem,
    IPaymentMethod,
} from '~features/report/interfaces';
import { parseDate } from '~plugins/dayjs';

import {
    ALL_PAYMENT_METHOD_VALUE,
    ReportTableCsvFileName,
    ReportTableId,
} from '~features/report/constants';
import {
    getPaymentDetails,
    isShowPaymentDetailPrintingModalSelector,
    paymentDetailsSelector,
    resetState,
    setExportCsv,
} from '~features/report/reducers/report-payment-details.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './PaymentDetailList.scss';
import PaymentDetailPrintingModal from '../PaymentDetailPrintingModal/PaymentDetailPrintingModal';

const {
    Column,
    Summary: { Cell, Row },
} = Table;

export default function PaymentDetailList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        isLoading,
        paymentDetailList,
        paymentDetailsQuery,
        selectedPaymentMethodIds,
        isShowTable,
        isExportCsv,
    } = useAppSelector(paymentDetailsSelector);
    const isShowPaymentDetailPrintingModal = useAppSelector(
        isShowPaymentDetailPrintingModalSelector,
    );
    const navigate = useNavigate();

    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);

    useEffect(() => {
        if (isShowTable) {
            dispatch(getPaymentDetails());
        }
    }, [paymentDetailsQuery, isShowTable]);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, []);

    const sanitizedPaymentDetailList = useMemo(() => {
        const result: Record<string, any>[] = [];
        paymentDetailList?.forEach((paymentDetail: IPaymentDetail) => {
            paymentDetail?.receiptItems?.forEach((item: IPaymentDetailsReceiptItem) => {
                const row: Record<string, any> = {};
                row.id = item?.id;
                row.checkInDate = parseDate(item?.payAt)?.fmYYYYMMDD('/');

                row.registrationTime = parseDate(item?.createdAt).fmYYYYMMDDHHmmss();

                row.roomBookingId = paymentDetail?.roomBookingId;
                row.roomName = paymentDetail?.room?.[0]?.name;
                row.representativeName = paymentDetail?.representativeGuest?.yomigana;
                row.unpaidAmount = Number(paymentDetail?.unpaidAmount);
                row.paymentMethods = [] as IPaymentMethod[];
                row.paymentMethods.push({
                    id: item?.paymentMethodId,
                    amount: item?.amount,
                });
                paymentMethodDropDown.forEach(({ id }: IPaymentMethodDropDown) => {
                    if (id !== row.paymentMethods?.[0]?.id) {
                        row.paymentMethods.push({ id, amount: 0 });
                    }
                });
                row.paidAmount = row.paymentMethods.reduce(
                    (paidAmount: number, paymentMethod: IPaymentMethod) =>
                        (paidAmount += paymentMethod.amount || 0),
                    0,
                );
                row.paymentMethods.sort((pre: IPaymentMethod, next: IPaymentMethod) => {
                    return pre.id - next.id;
                });

                result.push(row);
            });
        });
        return result;
    }, [paymentDetailList]);

    const selectedPaymentMethods = useMemo(() => {
        if (
            !selectedPaymentMethodIds.includes(ALL_PAYMENT_METHOD_VALUE) &&
            selectedPaymentMethodIds.length > 0
        ) {
            return paymentMethodDropDown.filter((paymentMethod) =>
                selectedPaymentMethodIds.includes(paymentMethod.id),
            );
        }
        const paymentMethods: IPaymentMethod[] = [];
        paymentDetailList?.forEach((paymentDetail: IPaymentDetail) => {
            paymentDetail?.receiptItems.forEach((item: IPaymentDetailsReceiptItem) => {
                paymentMethods.push(item.paymentMethod);
            });
        });
        const diffMethods = differenceBy(
            uniqBy(paymentMethods, 'id'),
            paymentMethodDropDown,
            'id',
        );
        return orderBy(
            [...paymentMethodDropDown, ...(diffMethods || [])],
            ['id'],
            ['asc'],
        );
    }, [selectedPaymentMethodIds, paymentDetailList, paymentMethodDropDown]);

    const exportCSVFile = () => {
        // append data to this file
        const tableHeader = [
            t('report.paymentDetails.column.checkInDate'),
            t('report.paymentDetails.column.registrationTime'),
            t('report.paymentDetails.column.roomName'),
            t('report.paymentDetails.column.representativeName'),
            t('report.paymentDetails.column.unpaidAmount'),
        ];
        const methodNames = selectedPaymentMethods.map((item) => item.name);
        let csvContent = `\uFEFF${[...tableHeader, ...methodNames].join()}`;
        // append content
        (sanitizedPaymentDetailList || []).forEach((item) => {
            const p: string[] = [
                item.checkInDate,
                item.registrationTime,
                `"${item.roomName || ''}"`,
                `"${item.representativeName || ''}"`,
                t('common.yen', {
                    number: item?.paidAmount || 0,
                }),
            ];
            selectedPaymentMethods?.forEach((method) => {
                const { amount } =
                    item?.paymentMethods?.find(
                        (paymentMethod: IPaymentMethod) =>
                            paymentMethod?.id === method?.id,
                    ) || {};
                const fieldData = t('common.yen', {
                    number: amount || 0,
                });
                p.push(fieldData);
            });
            csvContent = `${csvContent}\n${p.join()}`;
        });

        // append summary
        const summary = Array.from({ length: 3 }, () => '');
        summary.push(t('report.paymentDetails.total'));
        summary.push(
            t('common.yen', {
                number: sumBy(sanitizedPaymentDetailList, 'paidAmount') || 0,
            }),
        );
        const summaryByMethod = selectedPaymentMethods?.map((method) => {
            let total = 0;
            sanitizedPaymentDetailList.forEach((row) => {
                total +=
                    row.paymentMethods?.filter(
                        ({ id }: IPaymentMethod) => id === method?.id,
                    )?.[0]?.amount || 0;
            });
            return t('common.yen', {
                number: total || 0,
            });
        });

        csvContent = `${csvContent}\n${[...summary, ...summaryByMethod].join()}`;
        downloadCSVFile(csvContent, `${ReportTableCsvFileName.PAYMENT_DETAIL}.csv`);
    };
    useEffect(() => {
        if (isExportCsv) {
            exportCSVFile();
            dispatch(setExportCsv(false));
        }
    }, [isExportCsv]);

    return (
        <div className="report-payment-detail-list">
            <Table
                id={ReportTableId.PAYMENT_DETAIL}
                loading={isLoading}
                dataSource={sanitizedPaymentDetailList}
                pagination={false}
                rowKey="id"
                className="table-scrollbar"
                summary={(sanitizedPaymentDetailList) => {
                    return (
                        sanitizedPaymentDetailList.length > 0 && (
                            <Row>
                                <Cell
                                    index={0}
                                    key="cell-footer-0"
                                    className="z-100 border-none"
                                ></Cell>
                                <Cell
                                    index={1}
                                    key="cell-footer-1"
                                    className="z-100 border-none "
                                ></Cell>
                                <Cell
                                    index={2}
                                    key="cell-footer-2"
                                    className="z-100 border-none"
                                ></Cell>
                                <Cell
                                    index={3}
                                    key="cell-footer-3"
                                    align="right"
                                    className="z-100 total"
                                >
                                    {t('report.paymentDetails.total')}
                                </Cell>
                                <Cell
                                    index={4}
                                    key="cell-footer-4"
                                    align="center"
                                    className="z-100"
                                >
                                    {t('report.paymentDetails.yen', {
                                        value: formatMoney(
                                            sumBy(
                                                sanitizedPaymentDetailList,
                                                'paidAmount',
                                            ),
                                        ),
                                    })}
                                </Cell>
                                {selectedPaymentMethods?.map((item, index) => {
                                    let total = 0;
                                    sanitizedPaymentDetailList.forEach((row) => {
                                        total +=
                                            row.paymentMethods?.filter(
                                                ({ id }: IPaymentMethod) =>
                                                    id === item?.id,
                                            )?.[0]?.amount || 0;
                                    });
                                    return (
                                        <Cell
                                            index={4 + index}
                                            key={item.id}
                                            align="center"
                                            className="payment-method-total"
                                        >
                                            {t('report.paymentDetails.yen', {
                                                value: formatMoney(total),
                                            })}
                                        </Cell>
                                    );
                                })}
                            </Row>
                        )
                    );
                }}
                scroll={{ x: 800, y: 800 }}
            >
                <Column
                    title={`${t('report.paymentDetails.column.checkInDate')}`}
                    dataIndex="checkInDate"
                    fixed="left"
                    width={126}
                />
                <Column
                    fixed="left"
                    title={`${t('report.paymentDetails.column.registrationTime')}`}
                    dataIndex="registrationTime"
                    align="center"
                    width={210}
                />
                <Column
                    title={`${t('report.paymentDetails.column.roomName')}`}
                    key="roomName"
                    width={170}
                    fixed="left"
                    render={(_, record: any) => {
                        return (
                            <span
                                className="room-name"
                                onClick={() =>
                                    navigate(
                                        `/room-booking/${record?.roomBookingId}/detail`,
                                    )
                                }
                            >
                                <TextTruncate text={record?.roomName} />
                            </span>
                        );
                    }}
                    align="center"
                />
                <Column
                    title={`${t('report.paymentDetails.column.representativeName')}`}
                    key="representativeName"
                    align="center"
                    fixed="left"
                    width={170}
                    render={(_, record: any) => (
                        <TextTruncate text={record?.representativeName} />
                    )}
                />
                <Column
                    title={`${t('report.paymentDetails.column.unpaidAmount')}`}
                    fixed="left"
                    key="unpaidAmount"
                    align="center"
                    render={(_, record: any) => {
                        return (
                            <>
                                {t('report.paymentDetails.yen', {
                                    value: formatMoney(record?.paidAmount),
                                })}
                            </>
                        );
                    }}
                    width={170}
                />
                {selectedPaymentMethods?.map((item) => (
                    <Column
                        title={item?.name}
                        align="center"
                        key={item?.id}
                        ellipsis={true}
                        width={170}
                        render={(_, record: any) => {
                            const { amount } =
                                record?.paymentMethods?.find(
                                    (paymentMethod: IPaymentMethod) =>
                                        paymentMethod?.id === item?.id,
                                ) || {};
                            return (
                                <>
                                    {' '}
                                    {t('report.paymentDetails.yen', {
                                        value: formatMoney(amount),
                                    })}
                                </>
                            );
                        }}
                    />
                ))}
            </Table>
            <PaymentDetailPrintingModal
                isShowPaymentDetailPrintingModal={isShowPaymentDetailPrintingModal}
            />
        </div>
    );
}
