import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import queryClient, { CacheKeys } from '~queries/queries';
import { almexIntegrationService } from '../services/almex-kiosk-integration.service';
import { AlmexKioskSettingUpdateBody } from '../types';

const updateAlmexSetting = async (
    input: AlmexKioskSettingUpdateBody,
): Promise<AlmexKioskSettingUpdateBody> => {
    const response = await almexIntegrationService.updateAlmexSetting(input);
    if (response.success) {
        return response.data;
    }
    throw response.errors![0];
};

export const useUpdateAlmexSetting = () => {
    const { t } = useTranslation();
    return useMutation({
        mutationFn: updateAlmexSetting,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [CacheKeys.kioskAlmexSetting],
            });
            notification.success({
                message: t(
                    'kioskAlmexIntegrations.almex.notification.update.setting.success',
                ),
            });
        },
        onError: (error) => {
            notification.error({
                message: t('common.somethingWentWrong'),
                description: `${error}`,
            });
        },
    });
};
