const unpaidDetailJa = {
    title: '未収金明細表',
    breadcrumbs: {
        report: '帳票',
        unpaidDetail: '未収金明細表',
    },
    filterForm: {
        dateLabel: '集計日:',
        filterButton: '表示',
    },
    total: '合計',
    unit: '¥',
    unpaidDetailColumn: {
        roomName: '部屋名',
        guestName: '宿泊者名',
        checkIn: 'C/I',
        checkOut: 'C/O',
        numberOfNights: '泊目/泊数',
        unpaid: '未収額',
    },
    day: {
        monday: '月',
        tuesday: '火',
        wednesday: '水',
        thursday: '木',
        friday: '金',
        saturday: '土',
        sunday: '日',
    },
};

export default unpaidDetailJa;
