import { Card, Col, Row, Spin } from 'antd';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '~common/commonFunctions';
import { Checkbox, SingleSelect } from '~components';
import { IGuestDropDown } from '~features/guest/interfaces';
import {
    IMemberAttachment,
    IRoomBookingSchedule,
} from '~features/room-booking/interfaces';
import {
    createBookingStateSelector,
    setRepresentativeRoom,
} from '~features/room-booking/reducers/create-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import BookingForm from './BookingForm';
import './BookingTemporaryCard.scss';
import { cloneDeep } from 'lodash';

type Props = {
    booking: IRoomBookingSchedule;
    watch: any;
    control: any;
    getValues: any;
    setValue: any;
    index: number;
    setError: any;
    clearErrors: any;
    isShowTitle?: boolean;
    isSingleBooking?: boolean;
};

function BookingTemporaryCard({
    booking,
    watch,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    index,
    isShowTitle,
    isSingleBooking,
}: Props) {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const { representativeRoom, isCheckingPlan, focusBooking } = useAppSelector(
        createBookingStateSelector,
    );

    const guestMembers: IMemberAttachment[] = useWatch({
        control,
        name: 'members',
    });

    const representativeGuest: IMemberAttachment = useWatch({
        control,
        name: 'representativeGuest',
    });

    const cardClass = classNames('booking-tmp-card', {
        'mb-24': true,
    });
    const toggleRepresentativeRoom = (id: number) => {
        if (id === representativeRoom) {
            dispatch(setRepresentativeRoom(null));
        } else {
            dispatch(setRepresentativeRoom(id));
        }
    };

    const attaches = useMemo(() => {
        let allGuestBooking: IMemberAttachment[] = [];
        const cloneGuestMembers = cloneDeep(guestMembers);
        const guestMemberLength = (cloneGuestMembers || []).filter(
            (item) => item.yomigana || item.id,
        );
        const hasRepresentativeGuest = (cloneGuestMembers || []).some(
            (guest) => representativeGuest.yomigana === guest.yomigana,
        );
        let representativeGuests = {
            ...representativeGuest,
            index: guestMemberLength?.length + 1,
        };
        if (hasRepresentativeGuest) {
            allGuestBooking = [...guestMemberLength];
        } else {
            allGuestBooking = [...guestMemberLength, representativeGuests];
        }
        return (allGuestBooking || [])
            .filter((item) => item.yomigana)
            .map((item) => {
                let yomigana = item.yomigana;
                if (yomigana instanceof Object) {
                    yomigana = (item?.yomigana as IGuestDropDown)?.yomigana;
                }
                return {
                    value: item.index,
                    label: yomigana,
                };
            });
    }, [guestMembers, representativeGuest]);

    useEffect(() => {
        const guest = attaches.find((attach) => attach.value === booking.guestIndex);
        setValue(`bookings.${index}.guestIndex`, guest?.value || undefined);
    }, [attaches]);

    const isRepresentativeRoom = useMemo(() => {
        setValue(
            `bookings.${index}.isRepresentativeRoom`,
            representativeRoom === booking.id,
        );

        return representativeRoom === booking.id;
    }, [representativeRoom, booking, setValue, index]);

    return (
        <Card
            className={cardClass}
            title={
                isShowTitle ? (
                    <div>
                        <SingleSelect
                            hideHelpText
                            control={control}
                            options={attaches}
                            name={`bookings.${index}.guestIndex`}
                            label=""
                            allowClear
                            placeholder={t('roomBooking.createBooking.guestSelect')}
                        />
                    </div>
                ) : null
            }
        >
            <Spin spinning={isCheckingPlan && focusBooking[booking.id]}>
                {!isSingleBooking && (
                    <Row>
                        <Col span={24}>
                            <Checkbox
                                checked={isRepresentativeRoom}
                                onChange={() =>
                                    toggleRepresentativeRoom(booking.id as number)
                                }
                                name={`bookings.${index}.isRepresentativeRoom`}
                                value={booking.id}
                                control={control}
                                hideHelpText={true}
                                label={t('roomBooking.createBooking.representativeRoom')}
                            />
                        </Col>
                    </Row>
                )}

                <BookingForm
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    index={index}
                    booking={booking}
                />
                <Row>
                    <Col span={24}>
                        <div className="total-price">
                            {t('roomBooking.createBooking.subTotal', {
                                subTotal: booking.price
                                    ? formatMoney(booking.price || 0)
                                    : '',
                            })}
                        </div>
                    </Col>
                </Row>
            </Spin>
        </Card>
    );
}

BookingTemporaryCard.defaultProps = {
    isShowTitle: true,
};

export default BookingTemporaryCard;
