const roomFormIndividual = {
    quantity: '部屋数',
    id: '#',
    roomName: '部屋名',
    roomType: '部屋タイプ',
    startRoomName: '部屋番号',
    delete: '削除',
    generateRoomsBtn: '適用',
    deleteBtn: 'ルーム名',
    cancelBtn: 'キャンセル',
    submitBtn: '予約概要へ',
    addRoomBtn: '行を追加',
    importBtn: 'アップロード',
    createSuccessMessage: '部屋が登録されました',
    updateSuccessMessage: '部屋が更新されました',
    importSuccessMessage: 'CSVファイルのアップロードに成功しました!',
    updateForm: {
        title: '部屋の編集',
    },
    roomNameDuplicated: '部屋名が重複しています',
    cancelTitle: 'キャンセルしてもよろしいですか？',
    placeholder: {
        roomType: '部屋タイプを選択',
    },
};

export default roomFormIndividual;
