import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { yupFields } from '~plugins/yup/locale/field';
import { DEFAULT_LANGUAGE, SupportLanguage } from '../../common/constants';
import resources from './resources';

i18next.use(initReactI18next).init({
    fallbackLng: DEFAULT_LANGUAGE,
    lng: localStorage.getItem('currentLanguage') || DEFAULT_LANGUAGE,
    resources: resources,
    interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
});

i18next.services.formatter?.add('field', (value, lng, options) => {
    const fields = (yupFields?.[lng as unknown as SupportLanguage] || {}) as Record<
        string,
        string
    >;
    value = value.replace(/\[\d+\]/g, '');

    return fields[value] || value;
});

export default i18next;
