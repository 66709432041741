import * as React from 'react';
import localStorageAuthService from '~common/authStorage';
import {
    FC_FRAME_ID,
    FC_FRAME_WRAPPER_CLASS_NAME,
    FC_ICON_HEIGHT,
} from '~common/constants';
import './FreshChat.scss';

// need to declare window for typescript stop complaining about fcWidget
declare const window: any;

type FreshchatInitProps = {
    token: string;
    externalId?: string;
    firstName?: string;
    lastName?: string;
    host?: string;
    restoreId?: string;
    email?: string;
    phone?: string;
    phoneCountryCode?: string;
    config?: any;
    open?: boolean;
    tags?: [string];
    faqTags?: any;
    locale?: string;
};

export interface FreshchatStyles {
    backgroundColor: string;
    color: string;
}

export interface FreshChatProps extends FreshchatInitProps {
    label?: string;
    ic_styles?: FreshchatStyles;
}

const MAX_POSITION_BOTTOM = 100;
const MIN_POSITION_BOTTOM = 5;
const MAX_POSITION_RIGHT = 430;
const MIN_POSITION_RIGHT = 0;

export function FreshChat({
    label,
    ic_styles,
    ...rest
}: FreshChatProps): JSX.Element | null {
    const id = 'freshchat-lib';
    const isDragging = React.useRef(false);
    const isInitialized = React.useRef(false);
    const loadScript = () => {
        if (document.getElementById(id) || window.fcWidget) return;
        const script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        script.src = 'https://tabist-support.freshchat.com/js/widget.js';
        script.id = id;
        script.async = !0;
        document.head.appendChild(script);
    };

    const dragElement = (fcFrameWrapper: HTMLElement, fcFrame: HTMLElement) => {
        let pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;

        const elementDrag = (e: MouseEvent) => {
            isDragging.current = true;
            e = e || window.event;
            e.preventDefault();

            // calculate the new cursor position:
            pos1 = pos3 - e.clientX; // length from right
            pos2 = pos4 - e.clientY; // length from bottom
            pos3 = e.clientX; // length from left
            pos4 = e.clientY; // height from top

            // set the element's new position:
            const height = window.innerHeight;
            const width = window.innerWidth;
            let posTop = fcFrameWrapper.offsetTop - pos2;
            let posLeft = fcFrameWrapper.offsetLeft - pos1;
            let posBottom = height - posTop - FC_ICON_HEIGHT;

            if (posBottom <= MIN_POSITION_BOTTOM) posBottom = MIN_POSITION_BOTTOM;
            if (posBottom >= MAX_POSITION_BOTTOM) posBottom = MAX_POSITION_BOTTOM;

            if (posLeft <= MIN_POSITION_RIGHT) posLeft = MIN_POSITION_RIGHT;
            if (posLeft + MAX_POSITION_RIGHT >= width)
                posLeft = width - MAX_POSITION_RIGHT;

            fcFrameWrapper.style.bottom = posBottom + 'px';
            fcFrameWrapper.style.left = posLeft + 'px';
            fcFrame.style.bottom = posBottom + 'px';
            fcFrame.style.left = posLeft + 'px';
        };

        const elementMouseUp = () => {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
            if (window.fcWidget.isOpen() !== true && !isDragging.current) {
                window.fcWidget.open();
            }
            isDragging.current = false;
        };

        const dragMouseDown = (e: MouseEvent) => {
            e = e || window.event;
            e.stopPropagation();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = elementMouseUp;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        };

        // move the DIV from anywhere inside the DIV:
        fcFrameWrapper.onmousedown = dragMouseDown;
    };

    const addDragDropEvent = () => {
        const freshChatWrapper = document.getElementsByClassName(
            FC_FRAME_WRAPPER_CLASS_NAME,
        )?.[0];
        const fcFrame = document.getElementById(FC_FRAME_ID);
        if (!freshChatWrapper || !fcFrame) return;

        // set initial position of fresh chat icon
        (freshChatWrapper as HTMLElement).style.bottom = `${MIN_POSITION_BOTTOM}px`;
        (freshChatWrapper as HTMLElement).style.left = '0px';
        fcFrame.style.bottom = `${MIN_POSITION_BOTTOM}px`;
        fcFrame.style.left = '0px';

        dragElement(freshChatWrapper as HTMLElement, fcFrame);
        window.fcWidget.on('widget:opened', function () {
            (freshChatWrapper as HTMLElement).style.display = 'none';
        });
        window.fcWidget.on('widget:closed', function () {
            (freshChatWrapper as HTMLElement).style.display = 'block';
        });
    };

    // Init FreshChat with the data passed in
    const init = () => {
        if (label) {
            if (!rest.config) {
                rest.config = {
                    headerProperty: {
                        hideChatButton: true,
                    },
                };
            } else {
                rest.config = {
                    ...rest.config,
                    headerProperty: {
                        hideChatButton: true,
                    },
                };
            }
        }
        if (!rest.host) rest.host = 'https://tabist-support.freshchat.com';
        window.fcWidget.init({
            ...rest,
            config: {
                cssNames: {
                    widget: 'freshchat-widget',
                },
            },
            faqTags: {
                tags: ['pms'],
                filterType: 'category',
            },
        });
        addDragDropEvent();
        window.fcWidget.on('widget:loaded', function () {
            const loginUser = localStorageAuthService.getUser();
            window.fcWidget.user.setEmail(loginUser.email);
            window.fcWidget.setExternalId(loginUser.cognitoId);
            console.log('Widget Loaded', loginUser.email, loginUser.cognitoId);
            addDragDropEvent();
        });
    };

    React.useEffect(() => {
        loadScript();
        const interval = setInterval(() => {
            if (window.fcWidget) {
                clearInterval(interval);
                try {
                    if (!isInitialized.current) {
                        init();
                        isInitialized.current = true;
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }, 500);
    });

    React.useEffect(() => {
        return () => {
            const fcScript = document.getElementById(id);
            const fcFrame = document.getElementById('fc_frame');
            if (fcScript && fcFrame) {
                document.head.removeChild(fcScript);
                document.body.removeChild(fcFrame);
                window.fcWidget = null;
                isInitialized.current = false;
            }
        };
    }, []);

    if (!label) {
        return null;
    }

    return null;
}
