import React from 'react';
import { BookingStatusSingle } from './BookingStatusSingle/BookingStatusSingle';
import './BookingOverviewCard.scss';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '~hooks';
import { dashboardBookingOverviewSelector } from '~features/dashboard/reducers/dashboard.reducer';
import { BookingStatusColor } from '~features/dashboard/constants';

export const BookingOverviewCard = () => {
    const { t } = useTranslation();
    const { overviewData } = useAppSelector(dashboardBookingOverviewSelector);

    return (
        <div className="dashboard-booking-overview-card-wrapper">
            <BookingStatusSingle
                title={t('dashboard.bookingOverview.totalReservations')}
                subTitle={t('dashboard.bookingOverview.todayCheckin')}
                left={{
                    label: t('dashboard.bookingOverview.notArrivedYet'),
                    value: overviewData?.willCheckInCount || 0,
                    color: BookingStatusColor.WILL_CHECK_IN,
                }}
                right={{
                    label: t('dashboard.bookingOverview.arrived'),
                    value: overviewData?.checkedInCount || 0,
                    color: BookingStatusColor.CHECKED_IN,
                }}
            />
            <Divider type="vertical" />
            <BookingStatusSingle
                title=""
                subTitle={t('dashboard.bookingOverview.todayCheckout')}
                left={{
                    label: t('dashboard.bookingOverview.scheduledDeparture'),
                    value: overviewData?.willCheckOutCount || 0,
                    color: BookingStatusColor.WILL_CHECK_OUT,
                }}
                right={{
                    label: t('dashboard.bookingOverview.departed'),
                    value: overviewData?.checkedOutCount || 0,
                    color: BookingStatusColor.CHECKED_OUT,
                }}
            />
        </div>
    );
};
