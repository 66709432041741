import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useRef, useState } from 'react';
import { IRoomBookingItem } from '~features/room-booking/interfaces';
import { fetchRoomBookingDetail } from '~features/room-booking/reducers/room-booking.reducer';
import { useEscape } from '~common/useHooks';
import { useAppDispatch } from '~hooks';
import './RoomBookingItemDetailPopover.scss';
import RoomBookingItemForm from './RoomBookingItemForm/RoomBookingItemForm';

type Props = {
    bookingItem: IRoomBookingItem;
    roomBookingId: number;
    placement?: TooltipPlacement;
    isFromTll?: boolean;
    isFrozen?: boolean;
    isSingleBooking?: boolean;
    onClose?: () => void;
    onUpdateSuccess?: (booking: IRoomBookingItem) => void;
    onUpdateStatusSuccess?: (booking: IRoomBookingItem) => void;
};

function RoomBookingItemDetailPopover({
    bookingItem,
    roomBookingId,
    isFromTll,
    isSingleBooking,
    isFrozen,
    placement = 'top',
    onClose,
    onUpdateSuccess,
    onUpdateStatusSuccess,
}: Props) {
    const dispatch = useAppDispatch();
    const [showPopover, setShowPopover] = useState(true);
    const isShowConfirmSave = useRef(false);
    const isSubmitting = useRef(false);

    const closePopover = (visible: boolean, isReload: boolean) => {
        isSubmitting.current = false;
        if (isReload) {
            dispatch(fetchRoomBookingDetail(roomBookingId));
        }
        setShowPopover(isShowConfirmSave.current || visible);
        if (!isShowConfirmSave.current && !visible) {
            onClose?.();
        }
    };

    useEscape(closePopover);

    const setShowConfirm = (isShow: boolean) => {
        isShowConfirmSave.current = isShow;
    };

    const renderContent = () => {
        return (
            <RoomBookingItemForm
                bookingItem={bookingItem}
                isFromTll={isFromTll}
                isSingleBooking={isSingleBooking}
                isFrozen={isFrozen}
                closePopover={closePopover}
                setShowConfirm={setShowConfirm}
                onUpdateSuccess={onUpdateSuccess}
                onUpdateStatusSuccess={onUpdateStatusSuccess}
            />
        );
    };

    return (
        <Popover
            content={renderContent()}
            trigger="click"
            open={showPopover}
            placement={placement}
            overlayClassName="detail-room-booking-modal"
            onOpenChange={(visible) => {
                closePopover(visible, false);
            }}
            zIndex={150}
        ></Popover>
    );
}

export default RoomBookingItemDetailPopover;
