import { MEMO_MAX_LENGTH } from '~common/constants';
import yup from '~plugins/yup';

export const bulkUpdateCleaningStatus = yup.object().shape({
    items: yup.array().of(
        yup.object().shape({
            memo: yup.string().max(MEMO_MAX_LENGTH).optional(),
        }),
    ),
});
