import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { SaleType } from '~features/report/constants';
import {
    IReportSalesSummaryState,
    IReceiptSummaryRequestParameters,
    ISalesSummaryQuery,
} from '~features/report/interfaces';
import { reportServiceV2 } from '~features/report/services/v2/report.service';
import { reportService } from '../report.service';

const initialState: IReportSalesSummaryState = {
    salesSummaryQuery: {
        datePeriod: [],
        previousDatePeriod: [],
        saleType: SaleType.SALE_ITEM,
    },
    showSalesSummaryTable: false,
    showFacilitySalesSummaryTable: false,
    showLoadingSalesSummaryTable: false,
    salesSummaryData: [],
    receiptSummaryData: null,
    isFetchingReceiptSummaryData: false,
};

export const fetchSalesSummaryData = createAsyncThunk(
    'salesSummary/fetchSalesSummaryData',
    async (query: ISalesSummaryQuery) => {
        return await reportService.getSalesSummaryData(query);
    },
);

export const fetchReceiptSummaryData = createAsyncThunk(
    'salesSummary/fetchReceiptSummaryData',
    async (requestParameters: IReceiptSummaryRequestParameters) => {
        return await reportServiceV2.getReceiptSummaryData(requestParameters);
    },
);

const salesSummarySlice = createSlice({
    name: 'sales-summary',
    initialState,
    reducers: {
        setShowSalesSummaryTable: (state, action) => {
            state.showSalesSummaryTable = action.payload;
        },
        setFacilityShowSalesSummaryTable: (state, action) => {
            state.showFacilitySalesSummaryTable = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSalesSummaryData.pending, (state, action) => {
            state.showLoadingSalesSummaryTable = true;
        });
        builder.addCase(fetchSalesSummaryData.fulfilled, (state, action) => {
            state.showLoadingSalesSummaryTable = false;
            state.salesSummaryData = action.payload?.data || [];
        });
        builder.addCase(fetchReceiptSummaryData.pending, (state, action) => {
            state.isFetchingReceiptSummaryData = true;
        });
        builder.addCase(fetchReceiptSummaryData.fulfilled, (state, action) => {
            state.isFetchingReceiptSummaryData = false;
            state.receiptSummaryData = action.payload?.data || null;
        });
    },
});

export const {
    setShowSalesSummaryTable,
    setFacilityShowSalesSummaryTable,
} = salesSummarySlice.actions;

export const salesSummarySelector = (state: AppState) => {
    return state.reportSalesSummary;
};

export const showSalesSummaryTableSelector = (state: AppState) => {
    return state.reportSalesSummary.showSalesSummaryTable;
};

export const showLoadingSalesSummaryTableSelector = (state: AppState) => {
    return state.reportSalesSummary.showLoadingSalesSummaryTable;
};

export const salesSummaryDataSelector = (state: AppState) => {
    return state.reportSalesSummary.salesSummaryData;
};

export const reportSalesSummarySelector = (state: AppState) => {
    return state.reportSalesSummary;
};

export default salesSummarySlice.reducer;
