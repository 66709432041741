import { roomManagementJa } from './ja/list';
import { roomManagementEn } from './en/list';

export const roomManagement = {
    ja: {
        list: roomManagementJa,
    },
    en: {
        list: roomManagementEn,
    },
};
