import {
    AuditLogActions,
    AuditLogModules,
} from '~features/fo-operation-history/constants';

const historyList = {
    breadcrumbs: {
        home: 'ホーム',
        frontOffice: 'フロント設定',
        history: '履歴',
    },
    title: '履歴',
    columns: {
        createdAt: '操作日時',
        picName: '操作者',
        activityTitle: '操作項目',
    },
    header: {
        activityContent: {
            create: '作成 {{targetObjectKey}}',
            bulkCreate: '一括作成 {{targetObjectKey}}',
            update: '更新 {{targetObjectKey}}',
            bulkUpdate: '一括更新 {{targetObjectKey}}',
            updateStatus: '更新 {{targetObjectKey}} status',
            delete: '削除 {{targetObjectKey}}',
            bulkDelete: '一括削除 {{targetObjectKey}}',
            checkIn: 'チェックイン {{targetObjectKey}}',
            importXMLCreateRoomBooking: 'インポートXML作成 {{targetObjectKey}}',
            importXMLModifyRoomBooking: 'インポートXML変更 {{targetObjectKey}}',
            specialCases: {
                roomBookingItem: {
                    create: '部屋予約項目が作成されました',
                    update: '部屋予約項目が更新されました',
                    bulkUpdate: '部屋予約項目が更新されました',
                    bulkDelete: '部屋予約項目が削除されました',
                    updateStatus: '部屋予約項目のステータスが更新されました',
                },
                receipt: {
                    bulkUpdate: 'レシートが更新されました',
                },
                receiptItemDetail: {
                    bulkDelete: 'レシート科目が削除されました',
                },
                overbooking: {
                    create: '過剰予約の作成',
                },
            },
        },
    },
    searchColumns: {
        // picName: '担当者',
        activityTitle: '操作項目',
        createdByEmail: '操作者',
    },
    detail: '詳細',
    noData: '表示するデータはありません',
    operationContents: '※操作内容',
    exportColumns: {
        createdAt: '日付',
        picName: '担当者',
        actionTitle: '操作項目',
        createdByEmail: '担当者',
    },
    activityTitle: {
        [`${AuditLogActions.CREATE}${AuditLogModules.ROOM_BOOKING}`]: '部屋予約作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ROOM_BOOKING}`]: '部屋予約更新',
        [`${AuditLogActions.DELETE}${AuditLogModules.ROOM_BOOKING}`]: '部屋予約削除',
        [`${AuditLogActions.IMPORT_XML_CREATE}${AuditLogModules.ROOM_BOOKING}`]:
            'Import XML 部屋予約作成',
        [`${AuditLogActions.IMPORT_XML_MODIFY}${AuditLogModules.ROOM_BOOKING}`]:
            'Import XML 部屋予約変更',
        [`${AuditLogActions.IMPORT_XML_CANCEL}${AuditLogModules.ROOM_BOOKING}`]:
            'Import XML 部屋予約キャンセル',
        [`${AuditLogActions.CREATE}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            '部屋予約項目作成',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            '部屋予約項目一括更新 ',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            '部屋予約項目更新',
        [`${AuditLogActions.UPDATE_STATUS}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            '部屋予約項目ステータス更新　',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            '部屋予約項目一括削除 ',
        [`${AuditLogActions.CREATE}${AuditLogModules.ROOM_BOOKING_ITEM_TEMPORARY}`]:
            '仮部屋予約項目作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ROOM_BOOKING_ITEM_TEMPORARY}`]:
            '仮部屋予約項目更新',
        [`${AuditLogActions.DELETE}${AuditLogModules.ROOM_BOOKING_ITEM_TEMPORARY}`]:
            '仮部屋予約項目削除',
        [`${AuditLogActions.CREATE}${AuditLogModules.CHILDREN_TYPE}`]: '子供区分作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.CHILDREN_TYPE}`]: '子供区分更新',
        [`${AuditLogActions.DELETE}${AuditLogModules.CHILDREN_TYPE}`]: '子供区分削除',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.CHILDREN_TYPE}`]:
            '子供区分一括削除',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.FACILITY}`]: '施設一括作成',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.FACILITY}`]: '施設一括更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.FACILITY}`]: '施設一括削除',
        [`${AuditLogActions.CREATE}${AuditLogModules.FACILITY_TYPE}`]: '施設タイプ作成',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.FACILITY_TYPE}`]:
            '施設タイプ一括更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.FACILITY_TYPE}`]:
            '施設タイプ一括削除',
        [`${AuditLogActions.CREATE}${AuditLogModules.GUEST_CLASSIFICATION}`]:
            'ゲストクラシフィケーション作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.GUEST_CLASSIFICATION}`]:
            'ゲストクラシフィケーション更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.GUEST_CLASSIFICATION}`]:
            'ゲストクラシフィケーション一括削除',
        [`${AuditLogActions.CREATE}${AuditLogModules.GUEST}`]: 'ゲスト作成',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.GUEST}`]: 'ゲスト一括更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.GUEST}`]: 'ゲスト一括削除',
        [`${AuditLogActions.CREATE}${AuditLogModules.INDICATOR}`]: 'インジケータ作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.INDICATOR}`]: 'インジケータ更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.INDICATOR}`]:
            'インジケータ一括削除',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.MARKETING_CHANEL}`]:
            '販売チャネル一括作成',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.MARKETING_CHANEL}`]:
            '販売チャネル一括更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.MARKETING_CHANEL}`]:
            '販売チャネル一括削除',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.MARKETING_CHANEL_GROUP}`]:
            '販売チャネルグループ一括作成',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.MARKETING_CHANEL_GROUP}`]:
            '販売チャネルグループ一括更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.MARKETING_CHANEL_GROUP}`]:
            '販売チャネルグループ一括削除',
        [`${AuditLogActions.CREATE}${AuditLogModules.ROOM_TYPE}`]: '部屋タイプ作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ROOM_TYPE}`]: '部屋タイプ更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.ROOM_TYPE}`]:
            '部屋タイプ一括削除',
        [`${AuditLogActions.CREATE}${AuditLogModules.FACILITY_BOOKING}`]:
            '施設予約一括削除作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.FACILITY_BOOKING}`]: '施設予約更新',
        [`${AuditLogActions.UPDATE_STATUS}${AuditLogModules.FACILITY_BOOKING}`]:
            '施設予約ステータス更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.FACILITY_BOOKING}`]:
            '施設予約一括削除',
        [`${AuditLogActions.CHECK_IN}${AuditLogModules.FACILITY_BOOKING}`]:
            'チェックイン施設予約',
        [`${AuditLogActions.CREATE}${AuditLogModules.PAYMENT_METHOD}`]: '支払い方法作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.PAYMENT_METHOD}`]: '支払い方法更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.PAYMENT_METHOD}`]:
            '支払い方法一括削除',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.ROOM}`]: '部屋一括作成',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.ROOM}`]: '部屋一括更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.ROOM}`]: '部屋一括削除',
        [`${AuditLogActions.CREATE}${AuditLogModules.SALE_GROUP}`]: '売上集計作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.SALE_GROUP}`]: '売上集計更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.SALE_GROUP}`]:
            '売上集計一括削除',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.SALE_ITEM}`]:
            '販売項目一括作成',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.SALE_ITEM}`]:
            '販売項目一括更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.SALE_ITEM}`]:
            '販売項目一括削除',
        [`${AuditLogActions.CREATE}${AuditLogModules.PLAN}`]: 'プラン作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.PLAN}`]: 'プラン更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.PLAN}`]: 'プラン一括削除',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.TAX}`]: '税一括更新',
        [`${AuditLogActions.CREATE}${AuditLogModules.PLAN_RANK_CALENDAR}`]:
            'プランランクカレンダー作成',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.PLAN_RANK_CALENDAR}`]:
            'プランランクカレンダー一括更新',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.PLAN_RANK_CALENDAR}`]:
            'プランランクカレンダー一括削除',
        [`${AuditLogActions.UPDATE}${AuditLogModules.RECEIPT}`]: 'レシート更新',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.STOPPING_INVENTORY}`]:
            '売止一括作成',
        [`${AuditLogActions.CREATE}${AuditLogModules.STOPPING_INVENTORY}`]: '売止作成',
        [`${AuditLogActions.UPDATE}${AuditLogModules.HOTEL}`]: 'ホテルを変更',
        [`${AuditLogActions.CREATE}${AuditLogModules.OVERBOOKING}`]: '過剰予約の作成',
    },
    filter: {
        CREATE: '作成',
        UPDATE: '更新',
        DELETE: '削除',
        CHECK_IN: 'チェックイン',
        CHECK_OUT: 'チェックアウト',
        CANCEL: 'キャンセル',
        [`${AuditLogModules.CHILDREN_TYPE}`]: '子供区分',
        [`${AuditLogModules.FACILITY}`]: '設備',
        [`${AuditLogModules.FACILITY_BOOKING}`]: '設備予約',
        [`${AuditLogModules.FACILITY_TYPE}`]: '設備タイプ',
        [`${AuditLogModules.GUEST}`]: 'ゲスト',
        [`${AuditLogModules.GUEST_CLASSIFICATION}`]: 'ゲスト識別',
        [`${AuditLogModules.HOTEL}`]: 'ホテル',
        [`${AuditLogModules.INDICATOR}`]: 'インジケータ',
        [`${AuditLogModules.MARKETING_CHANEL}`]: '販売チャネル',
        [`${AuditLogModules.MARKETING_CHANEL_GROUP}`]: '販売チャネルグループ',
        [`${AuditLogModules.NOTIFICATION}`]: '通知',
        [`${AuditLogModules.PAYMENT_METHOD}`]: '支払い方法',
        [`${AuditLogModules.PLAN}`]: 'プラン',
        [`${AuditLogModules.PLAN_RANK_CALENDAR}`]: 'プランランクカレンダー',
        [`${AuditLogModules.RECEIPT}`]: '領収書',
        [`${AuditLogModules.RECEIPT_ITEM_DETAIL}`]: '領収書項目詳細',
        [`${AuditLogModules.ROOM}`]: '部屋',
        [`${AuditLogModules.ROOM_BOOKING}`]: '部屋予約',
        [`${AuditLogModules.ROOM_TYPE}`]: '部屋タイプ',
        [`${AuditLogModules.SALE_GROUP}`]: '販売グループ',
        [`${AuditLogModules.SALE_ITEM}`]: '販売項目',
        [`${AuditLogModules.STOPPING_INVENTORY}`]: '売止',
        [`${AuditLogModules.TAG}`]: 'タグ',
        [`${AuditLogModules.TAX}`]: '税金',
        [`${AuditLogModules.ROOM_BOOKING_ITEM}`]: '部屋予約項目',
        [`${AuditLogModules.ROOM_BOOKING_ITEM_TEMPORARY}`]: '仮予約',
        [`${AuditLogModules.OVERBOOKING}`]: '過剰予約の作成',
    },
};

export default historyList;
