import { Layout } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import RankCalendarForm from '~features/rank-calendar/components/RankCalendarForm/RankCalendarForm';
import RankCalendarList from '~features/rank-calendar/components/RankCalendarList/RankCalendarList';
import { setIsUpdating } from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { useAppDispatch } from '~hooks';
import FilterForm from '../../components/FilterForm/FilterForm';
import { breadcrumbs } from '../../constants';
import './RankCalendarListPage.scss';

function RankCalendarListPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setIsUpdating(false));
    }, []);

    return (
        <div className="rank-calendar-list-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('rankCalendar.list.title')}
            />
            <Layout.Content>
                <div className="rank-calendar-list-page-wrapper">
                    <div className="rank-calendar-list-page-content">
                        <FilterForm />
                        <RankCalendarList />
                    </div>
                    <RankCalendarForm />
                </div>
            </Layout.Content>
        </div>
    );
}

export default RankCalendarListPage;
