import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    IPaymentSummaryQuery,
    IPaymentSummaryReportState,
} from '~features/report/interfaces';
import { reportService } from '~features/report/report.service';
import { AppState } from '~plugins/redux-toolkit/store';
import { AggregationMethod } from '../constants';

const initialState: IPaymentSummaryReportState = {
    selectedDate: [],
    selectedAggregationMethod: AggregationMethod.DAILY,
    paymentSummaryQuery: {
        datePeriod: [],
    },
    showPaymentSummaryTable: false,
    showLoadingPaymentSummaryTable: false,
    paymentSummaryList: [],
    isShowTable: false,
    isExportCsv: false,
};

export const getPaymentSummary = createAsyncThunk(
    'paymentSummary/getPaymentSummary',
    async (query: IPaymentSummaryQuery) => {
        return await reportService.getPaymentSummary(query);
    },
);

const paymentSummarySlice = createSlice({
    name: 'payment-summary',
    initialState,
    reducers: {
        setIsShowTable: (state, action) => {
            state.isShowTable = action.payload;
        },
        setShowPaymentSummaryTable: (state, action) => {
            state.showPaymentSummaryTable = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setSelectedAggregationMethod: (state, action) => {
            state.selectedAggregationMethod = action.payload;
        },
        setExportCsv: (state, action) => {
            state.isExportCsv = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPaymentSummary.pending, (state, action) => {
            state.showLoadingPaymentSummaryTable = true;
            state.showPaymentSummaryTable = true;
        });
        builder.addCase(getPaymentSummary.fulfilled, (state, action) => {
            state.showLoadingPaymentSummaryTable = false;
            state.paymentSummaryList = action.payload?.data || [];
        });
    },
});

export const {
    setIsShowTable,
    setShowPaymentSummaryTable,
    setSelectedDate,
    setSelectedAggregationMethod,
    setExportCsv,
} = paymentSummarySlice.actions;

export const showPaymentSummaryStateSelector = (state: AppState) => {
    return state.reportPaymentSummary;
};

export const showPaymentSummaryTableSelector = (state: AppState) => {
    return state.reportPaymentSummary.showPaymentSummaryTable;
};

export const showLoadingPaymentSummaryTableSelector = (state: AppState) => {
    return state.reportPaymentSummary.showLoadingPaymentSummaryTable;
};

export const paymentSummarySelector = (state: AppState) => {
    return state.reportPaymentSummary.paymentSummaryList;
};

export const selectedDateSelector = (state: AppState) => {
    return state.reportPaymentSummary.selectedDate;
};

export const selectedAggregationMethodSelector = (state: AppState) => {
    return state.reportPaymentSummary.selectedAggregationMethod;
};

export const exportCsvPaymentSummarySelector = (state: AppState) => {
    return state.reportPaymentSummary.isExportCsv;
};

export default paymentSummarySlice.reducer;
