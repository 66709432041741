import { memo, useMemo } from 'react';
import './ScheduleBookingMonthView.scss';
import jaJP from 'antd/es/calendar/locale/ja_JP';
import { Calendar } from '~components';
import { Dayjs, parseDate, todayDayjs } from '~plugins/dayjs';
import { UserOutlined } from '@ant-design/icons';
import { DateFormat } from '~common/constants';
import { Spin } from 'antd';
import { ICalendarBookingItem } from '~common/interfaces';
import { inRange } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RoomBookingOccupanyRateRange } from '~features/room-booking/constants';
import { FacilityBookingOccupanyRateRange } from '~features/facility-booking/constants';
import classNames from 'classnames';

type Props = {
    calendarData: ICalendarBookingItem[];
    currentDate: string;
    isFetching: boolean;
    onChangeDate: (date: Dayjs) => void;
    occupanyRateRange:
        | typeof RoomBookingOccupanyRateRange
        | typeof FacilityBookingOccupanyRateRange;
};

const ScheduleBookingMonthViewComponent = ({
    calendarData,
    currentDate,
    isFetching,
    onChangeDate,
    occupanyRateRange,
}: Props) => {
    const { t } = useTranslation();
    const calendarDate = useMemo(() => {
        return parseDate(currentDate);
    }, [currentDate]);

    const month = useMemo(() => {
        return calendarDate.month() + 1;
    }, [calendarDate]);

    const headerRender = () => null;

    const getDateCellItem = (value: Dayjs) => {
        return calendarData.find((item) => value.diff(parseDate(item.date), 'day') === 0);
    };

    const occupanyRateInWord = (rate: number): string => {
        let key: keyof typeof occupanyRateRange;
        for (key in occupanyRateRange) {
            if (inRange(rate, occupanyRateRange[key][0], occupanyRateRange[key][1])) {
                return key;
            }
        }

        return '';
    };

    const onSelectDate = (newDate: Dayjs) => {
        onChangeDate(newDate);
    };

    const dateFullCellRender = (value: Dayjs) => {
        const data = getDateCellItem(value);
        const occupanyRate = data?.occupanyRate || 0;
        return value.month() + 1 === month ? (
            <div className={`date-cell-item ${occupanyRateInWord(occupanyRate || 0)}`}>
                <div className="date-in-month">{value.format(DateFormat.DD)}</div>
                <div
                    className={classNames('room-occupancy-rate', {
                        'color-green': occupanyRate < 25,
                        'color-blue': occupanyRate < 75 && occupanyRate >= 25,
                        'color-light-red': occupanyRate <= 90 && occupanyRate >= 75,
                        'color-dark-red': occupanyRate > 90,
                    })}
                >
                    {occupanyRate || 0}%
                </div>
                <div className="checkin-rate">
                    <span className="checkin-body">
                        <span className="checkin-label">
                            {t('common.schedule.checkin.label')}
                        </span>
                        {data?.numberOfCheckedInBookings || 0}/
                        {data?.numberOfBookings || 0}
                    </span>
                    <UserOutlined />
                </div>
                <div className="checkout-rate">
                    <span className="checkout-body">
                        <span className="checkout-label">
                            {t('common.schedule.checkout.label')}
                        </span>
                        {data?.numberOfCheckedOutBookings || 0}/
                        {data?.numberOfBookings || 0}
                    </span>
                    <UserOutlined />
                </div>
            </div>
        ) : (
            <div className="date-cell-item">
                <div className="date-in-month">{value.format(DateFormat.DD)}</div>
            </div>
        );
    };
    return (
        <Spin spinning={isFetching}>
            <Calendar
                className="schedule-booking-month-view"
                mode="month"
                headerRender={headerRender}
                fullCellRender={dateFullCellRender}
                onSelect={onSelectDate}
                value={calendarDate}
                locale={jaJP}
            />
        </Spin>
    );
};

ScheduleBookingMonthViewComponent.defaultProps = {
    calendarData: [],
    currentDate: todayDayjs.format('YYYY-MM-DD'),
    isFetching: false,
    onChangeDate: () => ({}),
};

export const ScheduleBookingMonthView = memo(ScheduleBookingMonthViewComponent);
