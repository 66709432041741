import React from 'react';
import { Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import { IPlanRoomTypePricing } from '~features/plan/interfaces';
import { TllRank, TllRankDropdown } from '~common/constants';
import { TllRankColors } from '~common/constants';
import './PlanRoomTypePricingTable.scss';
import { getFormPlanId } from '~features/plan/helper';
import { InputPrice } from '~components';

type Props = {
    control: any;
    planRoomTypePricings: {
        feId: number;
        planRoomTypePricings: IPlanRoomTypePricing[];
    }[];
    indexPlanRoomType: number;
    peopleCount: number;
};

function PlanRoomTypePricingTable({
    control,
    planRoomTypePricings,
    indexPlanRoomType,
    peopleCount,
}: Props) {
    const { t } = useTranslation();
    return (
        <div className="plan-pricing-rank-table-setting">
            <Table
                dataSource={planRoomTypePricings}
                pagination={false}
                scroll={{ x: 1500 }}
                locale={{
                    emptyText: <div></div>,
                }}
                rowKey="feId"
                rowClassName="editable-row"
                className="table-scrollbar"
            >
                <Column
                    align="left"
                    width="10px"
                    title={`${t('plan.create.planRoomTypePricingsForm.rank')}`}
                    dataIndex=""
                    className="rank-column-people-color"
                    render={(roomTypePricing: IPlanRoomTypePricing, item, index) => {
                        return (
                            <div className="rank-people-cell">
                                {peopleCount === 0
                                    ? t('plan.create.planRoomTypePricingsForm.bulk')
                                    : `${index + 1}${t(
                                          'plan.create.planRoomTypePricingsForm.person',
                                      )}`}
                            </div>
                        );
                    }}
                />
                {TllRankDropdown.map((item, indexRank) => {
                    const colorIndex = Object.values(TllRank).indexOf(item.value);
                    return (
                        <Column
                            align="left"
                            width={50}
                            title={item.label}
                            dataIndex=""
                            onHeaderCell={() => {
                                return {
                                    style: {
                                        backgroundColor: `${TllRankColors[colorIndex]}`,
                                    },
                                };
                            }}
                            key={item.label}
                            render={(
                                roomTypePricing: IPlanRoomTypePricing,
                                item,
                                index,
                            ) => {
                                return (
                                    <div className="plan-room-type-pricing-input">
                                        <InputPrice
                                            label=""
                                            placeholder={t(
                                                'plan.create.planRoomTypePricingsForm.planPricingInput',
                                            )}
                                            name={`planRoomTypes.${indexPlanRoomType}.planRoomTypePricings.${
                                                indexRank + index * TllRankDropdown.length
                                            }.price`}
                                            id={getFormPlanId(
                                                `planRoomTypes.${indexPlanRoomType}.planRoomTypePricings.${
                                                    indexRank +
                                                    index * TllRankDropdown.length
                                                }.price`,
                                            )}
                                            control={control}
                                            addonAfter={
                                                <Tag
                                                    className="room-type-pricing"
                                                    color="default"
                                                >
                                                    {t('plan.create.yen')}
                                                </Tag>
                                            }
                                        />
                                    </div>
                                );
                            }}
                        />
                    );
                })}
            </Table>
        </div>
    );
}

export default PlanRoomTypePricingTable;
