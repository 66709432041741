const facilityBookingDetailEn = {
    title: 'Facility booking detail',
    breadcrumbs: {
        facilityBookingDetail: 'facility-booking-detail',
    },
    detailPageTabs: {
        detail: 'Detail',
        receipt: 'Receipt',
        bookingInfo: 'Information',
        attachment: 'File',
    },
    detailPageCards: {
        resId: 'RES ID: {{resId}}',
    },
    updateForm: {
        title: 'Update facility reservation',
    },
    message: {
        updateSuccess: 'Update facility booking success',
        updateError: 'Update facility booking failed',
        checkInTimeError: 'Start time must be greater than or equal to {{currentTime}}',
        checkInOutError: 'End time must be greater than or equal to {{currentTime}}',
        startTimeGreaterEndError:
            'End staying time must be greater than start staying time 10 minutes',
        businessTimeError:
            'Reservation duration must be within business time: {{startTime}} ~ {{endTime}}',
    },
    deleteTitle: 'Do you want to delete?',
    cancelTitle: 'Do you want to cancel?',

    receiptTableColumn: {
        id: 'Booking ID',
        date: 'Date',
        status: 'Type',
        facilityDetail: 'Detail',
        guestName: 'Guest',
        roomName: 'Room name',
        unitPrice: 'Unit price',
        quantity: 'Quantity',
        total: 'Total',
        checkoutRoom: 'Check-out room',
        payment: 'Payment method',
        paymentPlaceholder: 'Payment method',
        saleItemPlaceholder: 'Select item',
        paymentRoom: 'Settlement room',
    },

    receipt: {
        title: 'Detail',
        button: {
            edit: 'Edit',
            splitReceipt: 'Receipt splitting',
            splitInvoice: 'Invoice splitting',
            calculate: 'Settlement',
            reset: 'Reset',
            payment: 'Payment received',
            earnings: 'Revenue',
            bulkPayment: 'Bulk payment',
        },
        status: {
            payment: 'Payment received',
            earnings: 'Revenue',
        },
        summaryTotal: {
            totalReceivable: 'Total receivable',
            totalSales: 'Total sales (tax included)',
            otherTaxes: 'Other comsumption taxes',
            payment: 'Payment received',
            change: 'Change',
            deposit: 'Deposit balance',
            summary: 'Summary',
        },
        paymentSetting: {
            title: 'Payment setting',
            allRooms: 'Payments are charged for each room',
            representativeRoom: 'Payments are charged for representative room',
            allDetails: 'All details',
            allRoomCharges: 'Total room fees',
            questionChangeConfirm:
                'Would you like all payments to be charged to the representative room?',
        },
        updateSuccessMessage: 'Update receipt success',
        deleteSuccessMessage: 'Delete receipt item success',
        booking: 'Accommodation',
        resetPopup: {
            title: 'Is it acceptable to discard the edited content?',
            yesButton: 'Yes',
            noButton: 'No',
        },
        bulkPaymentModal: {
            paymentDate: 'Payment date',
            paymentMethod: 'Payment method',
            createBulkPayment: 'Create payment',
            cancel: 'Cancel',
            updateSuccessMessage: 'Create payment successfully',
        },
    },
    receiptStatus: {
        payment: 'Payment received',
        receipt: 'Revenue',
    },
    facilityBookingInfo: {
        user: 'User',
        hotelReservation: {
            title: 'Room booking',
            yes: 'Yes',
            no: 'No',
        },
        detail: 'Detail',
        memo: 'Memo',
        stayTime: 'Duration of stay',
    },
    personText: 'Guests',
    facility: 'Facility',
    paymentModal: {
        title: 'Payment confirmation',
        calculateButton: 'Settle',
        bookingId: {
            label: 'Booking ID',
            placeholder: 'Booking ID',
        },
        paymentMethod: {
            label: 'Payment method',
            placeholder: 'Select payment method',
        },
        paymentAmount: {
            label: 'Payment amount',
            placeholder: '0',
        },
    },
    modalConfirmDeletion: {
        cancelButton: 'Cancel',
        deleteButton: 'Delete',
    },
    modalDeleteError: {
        title: 'Error',
        description: 'Following room cannot be deleted',
        closeButton: 'Close',
    },
};

export default facilityBookingDetailEn;
