import { Switch as SwitchAntd, Switch, SwitchProps, Form } from 'antd';
import { Controller } from '~plugins/hook-form';
import './FormSwitch.scss';

interface IFormSwitch extends SwitchProps {
    name: string;
    required: boolean;
    label: string;
    defaultValue: boolean;
    control: any;
}

export function FormSwitch(props: IFormSwitch) {
    const {
        control,
        name,
        defaultValue,
        defaultChecked,
        label,
        onChange,
        required,
        ...rest
    } = props;
    return (
        <div className="FormSwitch-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            label={''}
                            validateStatus={status}
                            required={required}
                            className="custom-swtich"
                        >
                            <Switch
                                {...rest}
                                ref={field.ref}
                                defaultChecked={defaultChecked}
                                onChange={(...agrs) => {
                                    if (onChange) {
                                        onChange(...agrs);
                                    }
                                    field.onChange(...agrs);
                                }}
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

FormSwitch.defaultProps = {
    required: false,
    defaultValue: false,
};
