const facilityBookingListJa = {
    title: '設備予約一覧',
    breadcrumbs: {
        home: 'ホーム',
        facilityBooking: '設備予約',
        facilityManagement: '設備管理',
        facilitySchedule: '設備スケジュール',
    },
    buttons: {
        detailSearch: '詳細検索',
        createBooking: '新規予約',
        search: '検索',
    },
    filterForm: {
        searchColumn: {
            keyword: '全て',
            autoGeneratedCode: '予約番号',
            status: 'ステータス',
            guestFullName: '使用者名',
            guestYomigana: 'ヨミガナ',
            guestPhoneNumber: '電話番号',
            facilityNames: '設備名',
        },

        status: {
            label: 'ステータス:',
        },
    },
    bookingStatus: {
        all: '全て',
        reserved: '未到着',
        started: '使用開始',
        finished: '使用終了',
        cancelled: 'キャンセル',
    },
    dropdown: {
        title: 'ステータスを変更',
    },
    message: {
        deleteRoomTypesSuccess: '施設予約が削除されました',
        deleteTitle: '予約が削除できません',
        contentNoCanDelete: '開始済み、もしくは予約済みの予約がございます',
    },
    column: {
        autoGeneratedCode: '予約番号',
        fullName: '予約者',
        yomigana: 'ヨミガナ',
        mobilePhoneNumber: '電話番号',
        facilityName: '設備名',
        status: 'ステータス',
        bookingDate: '予約日',
        startDatetime: '開始時刻',
        endDatetime: '終了時刻',
        roomBookingId: {
            title: '宿泊予約',
            yes: '有',
            no: '無',
        },
        paymentStatus: {
            title: '決済',
            already: '済',
            not: '未',
        },
        paymentAmount: '未収金',
        memo: 'メモ',
    },
    advanceSearchForm: {
        autoGeneratedCode: {
            label: '予約ID',
            placeholder: '予約IDを入力',
        },
        memo: {
            label: 'OTA備考',
            placeholder: 'メモを入力',
        },
        status: {
            label: 'ステータス',
            placeholder: 'ステータスを変更',
        },
        guestYomigana: {
            label: '宿泊者名 (ヨミガナ)',
            placeholder: '宿泊者名を入力',
        },
        guestPhoneNumber: {
            label: '電話番号',
            placeholder: '電話番号を入力',
        },
        checkInDateRanges: {
            label: '予約日',
            placeholder: '予約日を選択',
        },
        checkInTimeRanges: {
            label: '予約時間',
            placeholder: {
                start: '開始時刻',
                end: '終了時刻',
            },
        },
        usedBookingTypeOfGuest: {
            label: '分類',
        },
        facilityIds: {
            label: '設備名',
            placeholder: '設備名を選択',
        },
        marketingChannels: {
            label: '販売チャネル',
            placeholder: '販売チャネルを選択',
        },
    },
    guestClassification: {
        all: '全て',
        guest: '宿泊者',
        'non-guest': '宿泊者以外の方',
    },
    facilityBookingMarketingChannel: {
        walkIn: 'ウォークイン',
        phone: '電話',
    },
    statusPopConfirm: {
        title: 'ステータス変更の確認',
        content: 'ステータスを「{{status}}」に変更します。よろしいでしょうか？',
        okText: 'はい',
        cancelText: 'いいえ',
    },
    statusModalConfirm: {
        success: 'ステータスが更新されました',
        error: '予約ステータスの変更がされていません',
        errorChangeStatus:
            'ステータスを{{beforeStatus}}から{{afterStatus}}に変更することはできません',
        okText: '閉じる',
        title: 'ステータスを変更',
    },
    exportColumns: {
        autoGeneratedCode: '予約ID',
        yomigana: 'ヨミガナ',
        fullName: '予約者',
        phone: '電話番号',
        facilityName: '設備名',
        status: 'ステータス',
        bookingDate: '予約日',
        startDatetime: '開始時刻',
        endDatetime: '終了時刻',
        roomBookingId: '宿泊予約',
        paymentStatus: '決済',
        totalAmount: '未収金',
        memo: 'メモ',
    },
    deleteConfirmDialog: {
        description: 'この設備予約を削除してもよろしいですか？',
        deleteButton: '削除',
        cancelButton: 'キャンセル',
        deleteByStatusError: '以下の施設予約が削除されていません',
        titleDelete: 'エラー',
        closeButton: '閉じる',
    },
};

export default facilityBookingListJa;
