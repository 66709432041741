import { AuditLogModules } from '~features/bo-operation-history/constants';
const historyDetail = {
    memoInput: 'Input Memo',
    goToPage: 'Go to page',
    update: {
        success: 'History updated successfully',
        failed: 'History updated failed',
    },
    memo: 'Memo',
    activityType: 'Activity Type',
    noData: 'No data',
    key: {
        [AuditLogModules.GENERAL_SETTING]: {
            beforeData: 'Before',
            afterData: 'After',
            connectionSecurity: 'Connection Security',
            userName: 'Username',
            smtpPort: 'SMTP Port',
            smtpEncryption: 'SMTP Encryption',
            smtpServer: 'SMTP Server',
            password: 'Password',
            serverName: 'Server Name',
        },
        [AuditLogModules.ADMIN_NOTIFICATION]: {
            title: 'Title',
            content: 'Content',
            beforeData: 'Before',
            afterData: 'After',
            id: 'ID',
            tags: 'Tags',
            tag: 'Tag',
            hotels: 'Hotels',
            name: 'Name',
            createdData: 'Created data',
            deletedData: 'Deleted data',
        },
        [AuditLogModules.HOTEL]: {
            beforeData: 'Before',
            afterData: 'After',
            name: 'Hotel Name',
            status: 'Status',
            address: 'Address',
            picName: 'Pic Name',
            permission: 'Permission',
            numberOfRoom: 'Number of Rooms',
            picPhoneNumber: 'Phone Number',
            tllPmsPassword: 'TLL PMS Password',
            tllSystemId: 'TLL System ID',
            picEmailAddress: 'Email',
            standardCheckInTime: 'Standard Check In Time',
            standardCheckOutTime: 'Standard Check Out Time',
            id: 'Hotel ID',
            tllPmsUserId: 'TLL PMS User ID',
            peCsrHotelCode: 'Price Engine Hotel Code',
            postalCode: 'Postal Code',
        },
    },
    value: {
        yes: 'Yes',
        no: 'No',
    },
};

export default historyDetail;
