import { useTranslation } from 'react-i18next';
import UpdateIndividualRoomsForm from '../../components/UpdateIndividualRoomsForm/UpdateIndividualRoomsForm';
import { StaffLayoutHeader } from '~components';
import FilterForm from '../../components/FilterForm/FilterForm';
import RoomList from '../../components/RoomList/RoomList';
import { breadcrumbs } from '../../constants';

import './RoomListPage.scss';
import { Layout } from 'antd';

const { Content } = Layout;

function RoomListPage() {
    const { t } = useTranslation();

    return (
        <div className="room-page">
            <StaffLayoutHeader
                breadcrumbs={[...breadcrumbs()]}
                title={t('room.list.title')}
            />
            <Content>
                <div className="room-page-wrapper">
                    <div className="room-list-content">
                        <FilterForm />
                        <RoomList />
                    </div>
                    <UpdateIndividualRoomsForm />
                </div>
            </Content>
        </div>
    );
}

export default RoomListPage;
