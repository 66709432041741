import { WarningFilled } from '@ant-design/icons';
import { Badge, Popover, Spin } from 'antd';
import classNames from 'classnames';
import { max, min, sumBy } from 'lodash';
import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    VariableSizeGrid as Grid,
    GridOnScrollProps,
    VariableSizeGrid,
    VariableSizeList,
} from 'react-window';
import { ReactComponent as CleanRoomIcon } from '~assets/images/clean-room.svg';
import { ReactComponent as MealIcon } from '~assets/images/meal_icon.svg';
import { formatMoney } from '~common/commonFunctions';
import { OrderDirection, RoomCleanStatusEnum } from '~common/constants';
import { AutoSizer, ICell, NoData, TextTruncate } from '~components';
import {
    BookingStatusType,
    DashboardColumns,
    HEADER_HEIGHT,
    MAX_GRID_HEIGHT,
    MIN_GRID_HEIGHT,
    MIN_GRID_WIDTH,
    SCROLL_BAR_HEIGHT,
    StatusColor,
} from '~features/dashboard/constants';
import { IBookingTableItem } from '~features/dashboard/interfaces';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import { getAutoGeneratedCode } from '~features/room-booking/helper';
import { parseDate } from '~plugins/dayjs';
import './BookingGrid.scss';
import Header from './Header';
import { ISorter } from '~common/interfaces';
import localStorageAuthService from '~common/authStorage';

type Props = {
    data: IBookingTableItem[];
    pageCount: number;
    query: {
        page?: number;
        limit?: number;
        orderDirection?: OrderDirection | null | undefined;
        orderBy?: string | null;
    };
    total: number;
    loading: boolean;
    isRoomBooking: boolean;
    onChangePage?: (page: number, pageSize: number) => void;
    onChange?: (sorter: ISorter) => void;
    type: BookingStatusType;
};

export const BookingGrid = (props: Props) => {
    const { data, query, onChange, loading, type, isRoomBooking } = props;
    const { t } = useTranslation();
    const [widthTable, setWidthTable] = useState(0);
    const gridRef = useRef<VariableSizeGrid>(null);
    const headerRef = useRef<VariableSizeList>() as MutableRefObject<VariableSizeList>;
    const hotelStored = localStorageAuthService.getSelectedHotel();

    const { rowHeaderTitle, rowHeaderTitleWidthSum } = useMemo(() => {
        const headerTitles =
            type === BookingStatusType.newBooking
                ? [
                      {
                          width: 10,
                          label: t('dashboard.bookingList.columns.id'),
                          columnKey: DashboardColumns.ID,
                      },
                      {
                          width: 15,
                          label: t('dashboard.bookingList.columns.fullName'),
                          columnKey: DashboardColumns.FULL_NAME,
                      },
                      {
                          width: 25,
                          label: t('dashboard.bookingList.columns.time'),
                          columnKey: isRoomBooking
                              ? DashboardColumns.START_DATE_OF_STAY
                              : DashboardColumns.START_TIME_DATE,
                      },
                      {
                          width: 10,
                          label: t(
                              'dashboard.bookingList.columns.' +
                                  (isRoomBooking ? 'room' : 'facility'),
                          ),
                          columnKey: isRoomBooking
                              ? DashboardColumns.ROOM
                              : DashboardColumns.FACILITY,
                      },
                      {
                          width: 15,
                          label: t('dashboard.bookingList.columns.totalAmount'),
                          columnKey: DashboardColumns.TOTAL_AMOUNT,
                      },
                      {
                          width: 12,
                          label: t('dashboard.bookingList.columns.status'),
                          columnKey: isRoomBooking
                              ? DashboardColumns.BOOKING_STATUS
                              : DashboardColumns.STATUS,
                      },
                      ...(hotelStored?.showCleaningStatus
                          ? [
                                {
                                    width: 12,
                                    label: t(
                                        'dashboard.bookingList.columns.roomCleanStatus',
                                    ),
                                    columnKey: DashboardColumns.ROOM_CLEAN_STATUS,
                                },
                            ]
                          : []),
                      {
                          width: 12,
                          label: t('dashboard.bookingList.columns.receptionDate'),
                          columnKey: DashboardColumns.CREATED_AT,
                      },
                      {
                          width: 15,
                          label: t('dashboard.bookingList.columns.memo'),
                          columnKey: DashboardColumns.MEMO,
                          sorter: false,
                      },
                  ]
                : [
                      {
                          width: 10,
                          label: t('dashboard.bookingList.columns.id'),
                          columnKey: DashboardColumns.ID,
                      },
                      {
                          width: 15,
                          label: t('dashboard.bookingList.columns.fullName'),
                          columnKey: DashboardColumns.FULL_NAME,
                      },
                      {
                          width: 30,
                          label: t('dashboard.bookingList.columns.time'),
                          columnKey: isRoomBooking
                              ? DashboardColumns.START_DATE_OF_STAY
                              : DashboardColumns.START_TIME_DATE,
                      },
                      {
                          width: 20,
                          label: t(
                              'dashboard.bookingList.columns.' +
                                  (isRoomBooking ? 'room' : 'facility'),
                          ),
                          columnKey: isRoomBooking
                              ? DashboardColumns.ROOM
                              : DashboardColumns.FACILITY,
                      },
                      {
                          width: 13,
                          label: t('dashboard.bookingList.columns.totalAmount'),
                          columnKey: DashboardColumns.TOTAL_AMOUNT,
                      },
                      {
                          width: 12,
                          label: t('dashboard.bookingList.columns.status'),
                          columnKey: isRoomBooking
                              ? DashboardColumns.BOOKING_STATUS
                              : DashboardColumns.STATUS,
                      },
                      ...(hotelStored?.showCleaningStatus
                          ? [
                                {
                                    width: 12,
                                    label: t(
                                        'dashboard.bookingList.columns.roomCleanStatus',
                                    ),
                                    columnKey: DashboardColumns.ROOM_CLEAN_STATUS,
                                },
                            ]
                          : []),
                      {
                          width: 15,
                          label: t('dashboard.bookingList.columns.memo'),
                          columnKey: DashboardColumns.MEMO,
                          sorter: false,
                      },
                  ];
        const rowHeaderTitleWidthSum = sumBy(headerTitles, 'width');
        return { rowHeaderTitle: headerTitles, rowHeaderTitleWidthSum };
    }, [type, isRoomBooking, t]);

    function renderRoomCleanStatus(roomCleanStatus: RoomCleanStatusEnum | undefined) {
        switch (roomCleanStatus) {
            case RoomCleanStatusEnum.CLEANED:
                return (
                    <span className="room-clean text-success">
                        <CleanRoomIcon />
                        {t('dashboard.bookingList.roomCleanStatus.cleaned')}
                    </span>
                );
            case RoomCleanStatusEnum.UNCLEANED:
                return (
                    <span className="room-clean text-danger">
                        <WarningFilled />
                        {t('dashboard.bookingList.roomCleanStatus.uncleaned')}
                    </span>
                );
            default:
                return '';
        }
    }

    const bookingCells = useMemo(() => {
        const _columns = [
            {
                key: 'id',
                columnName: DashboardColumns.ID,
                render: (item: IBookingTableItem, index: number) => {
                    return (
                        <div key={'id' + index} className="text-id text-truncate">
                            {isRoomBooking
                                ? getAutoGeneratedCode(
                                      item.roomBooking?.autoGeneratedCode || '',
                                  )
                                : item.autoGeneratedCode}
                        </div>
                    );
                },
            },
            {
                key: 'fullName',
                columnName: DashboardColumns.FULL_NAME,
                render: (item: IBookingTableItem, index: number) => {
                    const guest = isRoomBooking
                        ? item.representativeGuest ||
                          item.roomBooking?.representativeGuest
                        : item.guest;

                    let guestName;

                    if (!guest?.fullName) {
                        guestName = guest?.yomigana;
                    } else if (!guest?.yomigana) {
                        guestName = guest.fullName;
                    } else {
                        guestName =
                            guest?.fullName === guest?.yomigana
                                ? guest?.fullName
                                : `${guest?.yomigana} (${guest?.fullName})`;
                    }

                    return (
                        <div
                            key={'fullName' + index}
                            className="yomigana-guest text-truncate"
                        >
                            <TextTruncate text={guestName || ''} />
                            {item.isInMealGroup && (
                                <div className="meal-icon">
                                    <MealIcon className="ml-8" />
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                key: 'time',
                columnName: isRoomBooking
                    ? DashboardColumns.START_DATE_OF_STAY
                    : DashboardColumns.START_TIME_DATE,
                render: (item: IBookingTableItem, index: number) => {
                    if (!isRoomBooking) {
                        return (
                            <div key={'time' + index}>
                                {`${parseDate(
                                    item.startDatetime,
                                )?.fmYYYYMMDDHHmm()} ~ ${parseDate(
                                    item.endDatetime,
                                )?.fmYYYYMMDDHHmm()}`}
                            </div>
                        );
                    }
                    return (
                        <div className="text-truncate" key={'time' + index}>
                            {`${parseDate(item.startDateOfStay)?.fmYYYYMMDD()} ${
                                item.checkInTime
                            } ~ ${parseDate(item.endDateOfStay)?.fmYYYYMMDD()} ${
                                item.checkOutTime
                            }`}
                        </div>
                    );
                },
            },
            {
                key: 'room',
                columnName: isRoomBooking
                    ? DashboardColumns.ROOM
                    : DashboardColumns.FACILITY,
                render: (item: IBookingTableItem, index: number) => {
                    const bookingStatus = item.bookingStatus;
                    return (
                        <div key={'room' + index}>
                            <TextTruncate
                                text={
                                    (isRoomBooking
                                        ? bookingStatus !==
                                          RoomBookingItemBookingStatus.CANCELLED
                                            ? item.room?.name
                                            : ''
                                        : item.facility?.name) || ''
                                }
                            />
                        </div>
                    );
                },
            },
            {
                key: 'totalAmount',
                columnName: DashboardColumns.TOTAL_AMOUNT,
                render: (item: IBookingTableItem, index: number) => {
                    const totalAmount = item.totalAmount;
                    return (
                        <div key={'totalAmount' + index}>
                            <div>
                                ￥
                                {(totalAmount || 0) > 0
                                    ? `${formatMoney(totalAmount || 0)}`
                                    : 0}
                            </div>
                        </div>
                    );
                },
            },
            {
                key: 'receptionDate',
                columnName: DashboardColumns.CREATED_AT,
                render: (item: IBookingTableItem, index: number) => {
                    const status = isRoomBooking ? item.bookingStatus : item.status;
                    const tx = isRoomBooking
                        ? 'roomBooking.page.bookingStatus.'
                        : 'facilityBooking.list.bookingStatus.';
                    return (
                        <div key={'status' + index} className="text-truncate">
                            <Badge
                                color={
                                    (StatusColor as Record<string, string>)[status || '']
                                }
                                className="mr-8"
                            />
                            {t(`${tx}${status}`)}
                        </div>
                    );
                },
            },
            ...(hotelStored?.showCleaningStatus
                ? [
                      {
                          key: 'roomCleanStatus',
                          columnName: DashboardColumns.ROOM_CLEAN_STATUS,
                          render: (item: IBookingTableItem, index: number) => {
                              return (
                                  <div
                                      key={'roomCleanStatus' + index}
                                      className="text-truncate"
                                  >
                                      {renderRoomCleanStatus(item.room?.cleaningStatus)}
                                  </div>
                              );
                          },
                      },
                  ]
                : []),
        ];
        if (type === BookingStatusType.newBooking) {
            _columns.push({
                key: 'receptionDate',
                columnName: DashboardColumns.CREATED_AT,
                render: (item: IBookingTableItem, index: number) => {
                    return (
                        <div key={'receptionDate' + index} className="text-truncate">
                            {parseDate(item.createdAt)?.fmYYYYMMDDHHmm()}
                        </div>
                    );
                },
            });
        }
        _columns.push({
            key: 'memo',
            columnName: DashboardColumns.MEMO,
            render: (item: IBookingTableItem, index: number) => {
                const { memo, otaMemo } = item;
                let textMemo = '';
                let popoverContent = null;
                if (memo && otaMemo) {
                    const memoStrings = memo.split('\n');
                    const otaMemoStrings = otaMemo.split('\n');
                    popoverContent = (
                        <div className="memo-tooltip_wrapper">
                            <div className="memo-container">
                                {memoStrings.map((value, index) => (
                                    <div key={index}>{value}</div>
                                ))}
                            </div>
                            <div className="ota-container">
                                {otaMemoStrings.map((value, index) => (
                                    <div key={index}>{value}</div>
                                ))}
                            </div>
                        </div>
                    );
                    textMemo = memo.concat('. ', otaMemo);
                } else if (memo && !otaMemo) {
                    const popoverContentList = memo.split('\n');
                    textMemo = memo;
                    popoverContent = (
                        <>
                            {popoverContentList.map((content, index) => (
                                <div key={index}>{content}</div>
                            ))}
                        </>
                    );
                } else if (!memo && otaMemo) {
                    const popoverContentList = otaMemo.split('\n');
                    textMemo = otaMemo;
                    popoverContent = (
                        <>
                            {popoverContentList.map((content, index) => (
                                <div key={index}>{content}</div>
                            ))}
                        </>
                    );
                }

                return (
                    <Popover
                        key={'memo' + index}
                        overlayClassName="popover-booking-grid"
                        content={popoverContent}
                        trigger="hover"
                        color="rgba(0, 0, 0, 0.75)"
                        placement="left"
                    >
                        <div className="memo-cell_wrapper" key={'memo' + index}>
                            {textMemo}
                        </div>
                    </Popover>
                );
            },
        });
        return _columns;
    }, [type, isRoomBooking, t]);

    const onClickCell = (record: IBookingTableItem) => {
        if (isRoomBooking) {
            window.open(`/room-booking/${record.roomBookingId}/detail`);
            return;
        }
        window.open(`/facility-booking/${record.id}/detail`);
    };

    const renderCell = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            const column = bookingCells[columnIndex];
            const item = data[rowIndex];
            return (
                <div
                    style={style}
                    className={classNames('booking-table-cell', {
                        'sorted-booking-table-cell': column.columnName === query.orderBy,
                    })}
                    onClick={() => onClickCell(item)}
                >
                    {column.render(item, rowIndex)}
                </div>
            );
        },
        [data, bookingCells],
    );

    const renderColumnWidth = (columnIndex: number, width: number) => {
        if (width < MIN_GRID_WIDTH) {
            const defaultWidth = MIN_GRID_WIDTH;
            return (
                (defaultWidth / rowHeaderTitleWidthSum) *
                rowHeaderTitle[columnIndex].width
            );
        }
        return (width / rowHeaderTitleWidthSum) * rowHeaderTitle[columnIndex].width;
    };

    useEffect(() => {
        gridRef?.current?.resetAfterColumnIndex(0);
        gridRef?.current?.resetAfterRowIndex(0);
        headerRef?.current?.resetAfterIndex(0);
    }, [widthTable]);

    const _onScroll = (params: GridOnScrollProps) => {
        const { scrollLeft } = params;
        headerRef.current.scrollTo(scrollLeft);
    };
    return (
        <div className="dashboard-booking-table">
            <Spin spinning={loading} style={{ minHeight: MIN_GRID_HEIGHT }}>
                <AutoSizer
                    disableHeight
                    onResize={({ width }) => {
                        setWidthTable(width);
                    }}
                >
                    {({ width }) => {
                        const heightGrid = data.length
                            ? min([
                                  HEADER_HEIGHT * data.length +
                                      (width > MIN_GRID_WIDTH ? 0 : SCROLL_BAR_HEIGHT),
                                  MAX_GRID_HEIGHT,
                              ]) || MIN_GRID_HEIGHT
                            : MIN_GRID_HEIGHT;
                        const layout = document.getElementById('staff-layout-content');
                        const scrollbarWidth =
                            heightGrid >= MAX_GRID_HEIGHT
                                ? (layout?.offsetWidth || 0) - (layout?.clientWidth || 0)
                                : 0;
                        const _widthContent = width - scrollbarWidth;

                        const columnHeader =
                            width > MIN_GRID_WIDTH ? width : MIN_GRID_WIDTH;
                        return (
                            <div>
                                <div style={{ width: `${columnHeader}px` }}>
                                    <Header
                                        items={rowHeaderTitle}
                                        scrollbarWidth={scrollbarWidth}
                                        orderDirection={query?.orderDirection}
                                        orderBy={query?.orderBy}
                                        onClick={onChange}
                                        renderColumnWidth={(index: number) => {
                                            return renderColumnWidth(
                                                index,
                                                _widthContent,
                                            );
                                        }}
                                        width={_widthContent}
                                        headerRef={headerRef}
                                    />
                                </div>

                                <Grid
                                    ref={gridRef}
                                    className={classNames('grid-view-content', {
                                        'show-scroll-bar': !!data.length,
                                        'not-show-scroll-bar': !data.length,
                                    })}
                                    height={heightGrid}
                                    width={width}
                                    rowCount={data.length}
                                    rowHeight={() => HEADER_HEIGHT}
                                    columnWidth={(index) =>
                                        renderColumnWidth(index, _widthContent)
                                    }
                                    columnCount={rowHeaderTitle.length}
                                    onScroll={_onScroll}
                                >
                                    {renderCell}
                                </Grid>
                                {data.length === 0 && (
                                    <VariableSizeList
                                        className="no-data-list"
                                        height={MIN_GRID_HEIGHT}
                                        itemCount={1}
                                        itemSize={() =>
                                            max([widthTable, MIN_GRID_WIDTH]) ||
                                            MIN_GRID_WIDTH
                                        }
                                        layout="horizontal"
                                        width={widthTable}
                                        style={{
                                            position: 'absolute',
                                            top: '56px',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                        }}
                                        onScroll={(params) => {
                                            headerRef.current.scrollTo(
                                                params.scrollOffset,
                                            );
                                        }}
                                    >
                                        {() => <NoData />}
                                    </VariableSizeList>
                                )}
                            </div>
                        );
                    }}
                </AutoSizer>
            </Spin>
        </div>
    );
};
