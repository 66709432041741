import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../../constants';
import FilterForm from '../../components/FilterForm/FilterForm';
import './GuestListPage.scss';
import GuestList from '../../components/GuestList/GuestList';

function GuestListPage() {
    const { t } = useTranslation();
    return (
        <div className="guest-list-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('guest.list.title')}
            />
            <Layout.Content>
                <div className="guest-list-page-wrapper">
                    <div className="guest-list-page-content">
                        <FilterForm />
                        <GuestList />
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default GuestListPage;
