import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Divider, Form, notification, Row } from 'antd';
import { parseDate } from '~plugins/dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showConfirm } from '~common/notification';
import { InputPrice, RightDrawerLayout, SingleSelect } from '~components';
import {
    IFacilityBookingReceipt,
    IUpdateFacilityBookingReceipt,
} from '~features/facility-booking/interfaces';
import {
    getFacilityBookingReceipt,
    setShowPaymentModal,
    showPaymentModalSelector,
    showPaymentModalLoadingSelector,
    updateFacilityBookingReceipt,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { paymentFacilityBookingSchema } from '~features/facility-booking/schema';
import { paymentMethodStateSelector } from '~features/payment-method/reducers/paymentMethod.reducer';
import { ReceiptItemType } from '~features/room-booking/constants';
import { PaymentMethodSelect } from '~features/room-booking/pages/RoomBookingDetailPage/components/RoomBookingReceiptTabPane/PaymentMethodSelect/PaymentMethodSelect';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './PaymentModal.scss';
import { formatMoney } from '~common/commonFunctions';
import { useEscape } from '~common/useHooks';

type IProp = {
    facilityBookingReceipt: IFacilityBookingReceipt | null;
    totalPrice: { totalSale: number; totalPayment: number; totalTax: number };
};

function PaymentModal({ facilityBookingReceipt, totalPrice }: IProp) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showPaymentModal = useAppSelector(showPaymentModalSelector);
    const showPaymentModalLoading = useAppSelector(showPaymentModalLoadingSelector);
    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const { control, reset, handleSubmit, formState } = useForm({
        resolver: yupResolver(paymentFacilityBookingSchema),
    });

    useEffect(() => {
        if (showPaymentModal) {
            reset({
                bookingId: facilityBookingReceipt?.facilityBooking?.id,
                paymentAmount:
                    totalPrice.totalSale - totalPrice.totalPayment > 0
                        ? totalPrice.totalSale - totalPrice.totalPayment
                        : null,
                paymentRoomBookingItemId:
                    facilityBookingReceipt?.facilityBooking?.roomBookingItems?.find(
                        (roomBookingItem) => {
                            return roomBookingItem?.isRepresentativeRoom;
                        },
                    )?.id ||
                    facilityBookingReceipt?.facilityBooking?.roomBookingItems?.[0]?.id,
            });
            setPaymentAmount(
                totalPrice.totalSale - totalPrice.totalPayment > 0
                    ? totalPrice.totalSale - totalPrice.totalPayment
                    : 0,
            );
        }
    }, [showPaymentModal]);

    const totalAmount = useMemo(() => {
        return totalPrice.totalSale - (totalPrice.totalPayment + paymentAmount);
    }, [paymentAmount]);

    const bookingOptions = useMemo(() => {
        return [
            {
                label: `${t('facilityBooking.detail.facility')} ${
                    facilityBookingReceipt?.facilityBooking?.autoGeneratedCode
                }`,
                value: facilityBookingReceipt?.facilityBooking?.id,
            },
        ];
    }, [showPaymentModal]);

    const changePaymentAmount = (value: number) => {
        setPaymentAmount(Number(value) || 0);
    };

    const _updateFacilityBookingReceipt = async (
        id: number,
        body: IUpdateFacilityBookingReceipt[],
    ) => {
        const response = await dispatch(updateFacilityBookingReceipt({ id, body }));
        if (updateFacilityBookingReceipt.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('facilityBooking.detail.receipt.updateSuccessMessage'),
                });
                if (facilityBookingReceipt?.bookingId) {
                    dispatch(
                        getFacilityBookingReceipt(facilityBookingReceipt?.bookingId),
                    );
                }
                closeModal();
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };

    const onSubmit = () => {
        handleSubmit((data) => {
            const { paymentAmount, paymentMethodId, paymentRoomBookingItemId } = data;
            const formData = {
                type: ReceiptItemType.PAYMENT,
                paymentMethodId: paymentMethodId,
                paymentRoomBookingItemId: paymentRoomBookingItemId,
                receiptItemDetails: [
                    {
                        amount: Number(paymentAmount) || 0,
                        payAt: parseDate(
                            facilityBookingReceipt?.facilityBooking?.startDatetime,
                        )
                            ?.startOf('day')
                            ?.fmYYYYMMDDHHmmss(),
                    },
                ],
            };
            if (facilityBookingReceipt?.id) {
                _updateFacilityBookingReceipt(facilityBookingReceipt?.id, [formData]);
            }
        })();
    };

    const closeModal = () => {
        reset();
        dispatch(setShowPaymentModal(false));
    };

    const showConfirmCancel = () => {
        showConfirm({
            title: t('facilityBooking.schedule.checkInForm.cancelTitle'),
            onOk() {
                closeModal();
            },
        });
    };
    useEscape(closeModal);

    return (
        <div>
            <RightDrawerLayout
                open={showPaymentModal}
                onClose={showConfirmCancel}
                onSubmit={onSubmit}
                loading={showPaymentModalLoading}
                busy={showPaymentModalLoading}
                title={t('facilityBooking.detail.paymentModal.title')}
                cancelText={t('common.buttonCancelText')}
                submitText={t('facilityBooking.detail.paymentModal.calculateButton')}
                className="facility-booking-payment-modal"
                disabledSaveButton={!formState.isValid}
            >
                <Form layout="vertical" autoComplete="off">
                    <Row className="input-row">
                        <Col span={24}>
                            <SingleSelect
                                name="bookingId"
                                label={t(
                                    'facilityBooking.detail.paymentModal.bookingId.label',
                                )}
                                placeholder={t(
                                    'facilityBooking.detail.paymentModal.bookingId.placeholder',
                                )}
                                control={control}
                                options={bookingOptions}
                            />
                        </Col>
                    </Row>
                    <Row className="input-row">
                        <Col span={24}>
                            <PaymentMethodSelect
                                label={t(
                                    'facilityBooking.detail.paymentModal.paymentMethod.label',
                                )}
                                placeholder={t(
                                    'facilityBooking.detail.paymentModal.paymentMethod.placeholder',
                                )}
                                name="paymentMethodId"
                                allowClear
                                control={control}
                                paymentMethodOptions={paymentMethodDropDown}
                                optionLabelProp="label"
                                showSearch={true}
                                placement={'bottomRight'}
                            />
                        </Col>
                    </Row>
                    <Row className="input-row">
                        <Col span={24}>
                            <InputPrice
                                name="paymentAmount"
                                label={t(
                                    'facilityBooking.detail.paymentModal.paymentAmount.label',
                                )}
                                prefix="¥"
                                placeholder={t(
                                    'facilityBooking.detail.paymentModal.paymentAmount.placeholder',
                                )}
                                allowClear
                                control={control}
                                onChange={(e) =>
                                    changePaymentAmount(Number(e.target?.value) || 0)
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="row-padding total-sale-item-row d-flex j-between a-center">
                                <span>
                                    {t(
                                        'facilityBooking.detail.receipt.summaryTotal.totalSales',
                                    )}
                                </span>
                                <span className="price">
                                    {t('common.yen', {
                                        number: formatMoney(totalPrice.totalSale),
                                    })}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="row-padding d-flex j-between a-center">
                                <span>
                                    {t(
                                        'facilityBooking.detail.receipt.summaryTotal.otherTaxes',
                                    )}
                                </span>
                                <span>
                                    {t('common.yen', {
                                        number: formatMoney(totalPrice.totalTax),
                                    })}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="payment-row">
                                <div className="d-flex j-between a-center">
                                    <span>
                                        {t(
                                            'facilityBooking.detail.receipt.summaryTotal.payment',
                                        )}
                                    </span>
                                    <span>
                                        {t('common.yen', {
                                            number: formatMoney(totalPrice.totalPayment),
                                        })}
                                    </span>
                                </div>
                                <div className="payment-amount">
                                    <span>{paymentAmount >= 0 ? '+' : '-'}</span>
                                    <span>￥</span>
                                    <span>{`${formatMoney(
                                        Math.abs(paymentAmount),
                                    )}`}</span>
                                </div>
                            </div>
                            <Divider plain className="divider" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="row-padding d-flex j-between a-center">
                                <span>
                                    {t(
                                        'facilityBooking.detail.receipt.summaryTotal.totalReceivable',
                                    )}
                                </span>
                                <span className="total-receivable">
                                    {t('common.yen', {
                                        number: formatMoney(
                                            totalAmount > 0 ? totalAmount : 0,
                                        ),
                                    })}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="row-padding d-flex j-between a-center">
                                <span>
                                    {t(
                                        'facilityBooking.detail.receipt.summaryTotal.change',
                                    )}
                                </span>
                                <span>
                                    {t('common.yen', {
                                        number: formatMoney(
                                            totalAmount < 0 ? -totalAmount : 0,
                                        ),
                                    })}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </RightDrawerLayout>
        </div>
    );
}

export default PaymentModal;
