import i18next from '~plugins/i18next/i18n';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
import { TaxType as ITaxType } from '~common/constants';
export const breadcrumbs = () => [
    {
        text: i18next.t('saleItem.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('saleItem.list.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('saleItem.list.breadcrumbs.saleItem'),
    },
];

export const breadcrumbsCreate = () => [
    ...breadcrumbs(),
    {
        text: i18next.t('saleItem.create.breadcrumbs.create'),
    },
];

export enum SaleItemColumn {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
    SALE_GROUP = 'saleGroupName',
    PRICE = 'price',
    IS_SHOW_IN_BOOKING = 'isShowInBooking',
    TAX = 'taxName',
    ACTION = 'action',
}

export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    NAME = 'name',
    TAX = 'taxName',
}

export enum OrderBy {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
    PRICE = 'price',
    IS_SHOW_IN_BOOKING = 'isShowInBooking',
    TAX = 'tax',
}

export const initSaleItemListQuery = {
    keyword: '',
    autoGeneratedCode: '',
    name: '',
    saleGroupName: '',
    orderBy: OrderBy.ID,
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export enum TaxType {
    FIX = 'fix',
    PERCENTAGE = 'percentage',
}

export const enum ImportSaleItemColumn {
    NAME = 'name',
    TAX = 'taxName',
    PRICE = 'price',
    IS_SHOW_IN_BOOKING = 'isShowInBooking',
}

export const FILE_NAME = 'sale-item.csv';
export const EXPORT_CSV_FILE_NAME = 'sale-item';

export const exportColumns = [
    'autoGeneratedCode',
    'name',
    'price',
    'isShowInBooking',
    'tax',
];

export const i18ExportKey = 'saleItem.list';

export const DEFAULT_LIMIT_FOR_EXPORT = 100;
export const MAX_SALE_ITEMS = 99;

export const saleItemTaxTypes = [ITaxType.TAX_1, ITaxType.TAX_2, ITaxType.TAX_3];

export const BREAKFAST = '朝ごはん';

export const LUNCH = 'ランチ';

export const DINNER = '夕食';
