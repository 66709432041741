import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { Layout, Tabs } from 'antd';
import { breadcrumbs, RoomCleaningPageTabPane } from '~features/room-cleaning/constants';
import TabCleaningList from './components/TabCleaningList/TabCleaningList';
import './RoomCleaningListPage.scss';
import { useNavigate } from 'react-router-dom';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';
function RoomCleaningListPage() {
    const { t } = useTranslation();
    const { Content } = Layout;
    const navigate = useNavigate();
    const onChangeTab = (tab: string) => {
        if (tab === RoomCleaningPageTabPane.ROOM_MANAGEMENT) navigate('/room-management');
    };
    const isReadOnly = useMemo(() => {
        return !checkUserPermission([
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
            UserGroup.HOTEL_CLEANING,
        ]);
    }, []);
    const getRoomCleaningTabItems = () => {
        const items = [
            {
                key: RoomCleaningPageTabPane.ROOM_CLEANING_LIST,
                label: t('roomCleaning.list.tabs.cleaningList'),
                children: <TabCleaningList />,
            },
        ];
        if (!isReadOnly) {
            items.unshift({
                key: RoomCleaningPageTabPane.ROOM_MANAGEMENT,
                label: t('roomCleaning.list.tabs.roomManagement'),
                children: <></>,
            });
        }
        return items;
    };

    return (
        <div className="room-cleanning-list-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('roomCleaning.list.title')}
            />
            <Content className="room-cleaning-content">
                <Tabs
                    className="room-cleanning-tabs"
                    defaultActiveKey={RoomCleaningPageTabPane.ROOM_CLEANING_LIST}
                    onChange={onChangeTab}
                    items={getRoomCleaningTabItems()}
                ></Tabs>
            </Content>
        </div>
    );
}

export default RoomCleaningListPage;
