export const salesByCategoryJa = {
    title: '部屋タイプ別売上表',
    breadcrumbs: {
        report: '帳票',
        salesByCategory: '部屋タイプ別売上表',
    },
    filterForm: {
        pickerLabel: '日付',
        roomType: '部屋タイプ',
        allOption: '全て',
        showMode: '日毎に表示',
        filterButton: '表示',
    },
    salesByCategoryColumn: {
        date: '日付',
        lastMonth: '先月',
        currentMonth: '今月',
        nextMonth: '来月',
        roomType: '分類名',
        numberOfRooms: '室数',
        adults: '人数 (大人)',
        children: '人数 (子供)',
        amount: '金額',
        averagePrice: '平均単価',
        occupancyRate: '稼働率',
    },
    tableHeader: '※ グレイ字は前年同日の情報',
    total: '合計 (税込み)',
    unit: '¥',
    percentage: '%',
};
