import { CSSProperties, useMemo } from 'react';
import { ScheduleViewMode } from '~common/constants';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import {
    setCurrentDate,
    setCurrentViewMode,
    setRoomViewId,
} from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch } from '~hooks';
import customDayjs from '~plugins/dayjs';
import './DayUseBookingBadge.scss';

type Prop = {
    bookings: IRoomBookingSchedule[];
    style?: CSSProperties;
};
function DayUseBookingBadge(prop: Prop) {
    const { bookings, style } = prop;

    const dispatch = useAppDispatch();
    const { top, left, stayingStartDate, room } = useMemo(() => {
        return bookings[0];
    }, [bookings]);

    const changeView = () => {
        dispatch(setCurrentViewMode(ScheduleViewMode.DAY));
        dispatch(setCurrentDate(stayingStartDate || customDayjs().fmYYYYMMDD()));
        dispatch(setRoomViewId(room?.id || 0));
    };
    return (
        <div
            className="day-use-booking-badge"
            style={{
                ...style,
                top,
                left: left,
            }}
            onClick={changeView}
        >
            <span>{bookings.length}</span>
        </div>
    );
}
export default DayUseBookingBadge;
