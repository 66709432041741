import { Empty, Pagination } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectedHistoryListSelector } from '~features/bo-operation-history/reducers/history-list.reducer';
import { useAppSelector } from '~hooks';
import HistoryDetail from '../HistoryDetail/HistoryDetail';
import './SelectedHistoryList.scss';

function SelectedHistoryList() {
    const { t } = useTranslation();
    const selectedHistoryList = useAppSelector(selectedHistoryListSelector);
    const [selectedHistoryIndex, setSelectedHistoryIndex] = useState(0);

    const onChangePage = (page: number, pageSize: number) => {
        setSelectedHistoryIndex(page - 1);
    };

    useEffect(() => {
        const lastHistoryIndex = selectedHistoryList.length - 1;
        if (selectedHistoryIndex > lastHistoryIndex) {
            if (lastHistoryIndex === -1) setSelectedHistoryIndex(0);
            else setSelectedHistoryIndex(selectedHistoryList.length - 1);
        }
    }, [selectedHistoryList]);

    const currentHistoryPagination = useMemo(() => {
        return selectedHistoryList.length > 0 ? selectedHistoryIndex + 1 : 0;
    }, [selectedHistoryList, selectedHistoryIndex]);
    return (
        <div className="bo-selected-history-list-wrapper">
            <div className="selected-history-list-header">
                <div className="title">{t('boHistory.list.detail')}</div>
                {selectedHistoryList.length > 1 ? (
                    <Pagination
                        align="center"
                        defaultPageSize={1}
                        current={currentHistoryPagination}
                        total={selectedHistoryList.length}
                        onChange={onChangePage}
                    />
                ) : null}
            </div>
            <div className="selected-history-list-content">
                {selectedHistoryList.length > 0 ? (
                    <HistoryDetail
                        history={
                            selectedHistoryList[selectedHistoryIndex] ??
                            selectedHistoryList[selectedHistoryList.length - 1]
                        }
                    />
                ) : (
                    <div className="empty-data">
                        <Empty description={t('boHistory.list.noData')} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default SelectedHistoryList;
