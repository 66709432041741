import { Table } from 'antd';
import './RoomBookingCancelationInfoTabPane.scss';

interface ICancelationInfoData {
    id: string;
    date: string;
    type: string;
    detail: string;
}

function RoomBookingCancelationInfoTabPane() {
    const cancelationInfoColumns = [
        {
            key: 'date',
            title: 'Date',
            render: (item: ICancelationInfoData) => <div>{item.date}</div>,
            width: '150px',
        },
        {
            key: 'type',
            title: 'Type',
            render: (item: ICancelationInfoData) => <div>{item.type}</div>,
            width: '150px',
        },
        {
            key: 'detail',
            title: 'Detail',
            render: (item: ICancelationInfoData) => <div>{item.detail}</div>,
        },
    ];

    const cancelationInfoData: ICancelationInfoData[] = [
        {
            id: '1',
            date: '2024-02-20',
            type: '18:20:00',
            detail: 'Rakuten Travel cancellation robot request received',
        },
        {
            id: '1',
            date: '2024-02-20',
            type: '18:30:00',
            detail: 'Cancellation complete',
        },
    ];

    return (
        <>
            <div className="cancelation-info__card">
                <div className="cancelation-info__card-grid">
                    <div>OTA</div>
                    <div>Rakuten Travel</div>
                    <div>キャンセル料金</div>
                    <div>無償</div>
                    <div>OTAメモをキャンセル</div>
                    <div>
                        訴ミス守加オ徳東ケ動東場りク下属車ルゅ図2不ッす降音シリヱ由良者らぎ図客すやゆく被負やらがぽ禁伸換要ぞ。軍び何受ヤスムケ復量れぴトス育要し断褒第済サ載人らぼイレ安能ウクチミ減胸域トソ村門ークで転億討のわ。型73測覧下4下のび事67月ぐ具報ノセワオ載環ワオチ井敗もばよ票事セミソ詳如イさ市要ク上情ヲマチオ属息ルマトヌ選見ほクラ案高ヤヘチホ的3俣妃巳にク。
                        29官ソリ部料ぱに代季ルミヲリ野席3造ヒウシ科金当澤げどご機記フぴゃい会豊ヌヲ性原ドね図掌シノエヲ入野なぱふ私意ヒウ健歩ル長男ごろひ節波りドゃ草吉圏看ぽ。戦く有出サヒ説先ぶぽお渡理ツ翻動ぼてンじ断追要戦フゅ違立ッべスし法文予シロク元実千ぞきごト更平来普河っ。必ワユヲ秀位取い番映イつの条51招エケム友1需クソ亜生な提49部ロタコス谷腹知ヲエ技帰よそ盟指ネチ決郎媛ルめど。
                    </div>
                    <div>Who cancelled</div>
                    <div>[username]</div>
                </div>
            </div>
            <div className="cancelation-info__table">
                <Table
                    columns={cancelationInfoColumns}
                    dataSource={cancelationInfoData}
                    rowKey="key"
                />
            </div>
        </>
    );
}

export default RoomBookingCancelationInfoTabPane;
