import { Dispatch, SetStateAction, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { InputText } from '~components';
import './PackagePlanCodeModal.scss';
import { IPackagePlanCodeData } from '../PackagePlanCode';
import { useTranslation } from 'react-i18next';

type Prop = {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    packagePlanCodes: IPackagePlanCodeData[];
    setPackagePlanCodes: Dispatch<SetStateAction<IPackagePlanCodeData[]>>;
    selectedPackagePlanCode?: string;
};

function PackagePlanCodeModal({
    isModalOpen,
    setIsModalOpen,
    packagePlanCodes,
    setPackagePlanCodes,
    selectedPackagePlanCode,
}: Prop) {
    const { t } = useTranslation();
    const { control, reset, handleSubmit } = useForm();

    const submitPackagePlanCode = () => {
        handleSubmit(async (values) => {
            const { planCode } = values;
            if (!planCode) return;

            const foundIdx = packagePlanCodes.findIndex(
                (p) => p.planCode === selectedPackagePlanCode,
            );
            if (foundIdx !== -1) {
                const updatedData = packagePlanCodes;
                updatedData[foundIdx].planCode = planCode;
                setPackagePlanCodes(updatedData);
            } else {
                setPackagePlanCodes([...packagePlanCodes, { planCode }]);
            }
            setIsModalOpen(false);
        })();
    };

    useEffect(() => {
        reset({ planCode: selectedPackagePlanCode || '' });
    }, [isModalOpen]);

    return (
        <Modal
            title={t(
                `plan.create.packagePlanCode.${
                    selectedPackagePlanCode ? 'editModalTitle' : 'addModalTitle'
                }`,
            )}
            open={isModalOpen}
            centered
            onCancel={() => setIsModalOpen(false)}
            width={400}
            className="package-plan-code-modal"
            footer={[
                <Button
                    key="cancel"
                    onClick={() => setIsModalOpen(false)}
                    size="large"
                    className="action-btn"
                >
                    {t('plan.create.buttonCancel')}
                </Button>,
                <Button
                    key="proceed"
                    onClick={submitPackagePlanCode}
                    type="primary"
                    size="large"
                    className="action-btn"
                >
                    {t('plan.create.buttonSubmit')}
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <InputText
                    label={t('plan.create.packagePlanCode.title')}
                    defaultValue={selectedPackagePlanCode}
                    id="planCode"
                    name="planCode"
                    control={control}
                    required
                />
            </Form>
        </Modal>
    );
}

export default PackagePlanCodeModal;
