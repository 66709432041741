import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, notification, Pagination, Table } from 'antd';
import type { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { orderBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile, exportCSVFile } from '~common/commonFunctions';
import { AntdOrderDirection, CsvOption, OrderDirection } from '~common/constants';
import { ISorter } from '~common/interfaces';
import { CsvDropdown, ModalConfirmDeletion, TextTruncate } from '~components';
import {
    CSV_EXPORT_FILE_NAME,
    exportColumns,
    EXPORT_CSV_FILE_NAME,
    FacilityTypeColumn,
    FacilityTypeOrderBy,
    i18ExportKey,
} from '~features/facility-type/constants';
import {
    facilityTypeListQuerySelector,
    facilityTypeListSelector,
    fetchFacilityTypeList,
    fetchFacilityTypeListByIds,
    resetState,
    setFacilityTypeListQuery,
    setIsShowUpdateForm,
    showLoadingListSelector,
    totalFacilityTypesSelector,
    totalPageSelector,
} from '~features/facility-type/facility-type.reducer';
import {
    IFacilityType,
    IFacilityTypeExportCsvQuery,
} from '~features/facility-type/interfaces';
import { facilityTypeService } from '~features/facility-type/services/facility-type.api';
import { useAppDispatch, useAppSelector } from '~hooks';
import './FacilityTypeList.scss';

interface ICSVData {
    name: string;
    abbreviation: string;
    businessTimes: string;
    duration?: string;
    price?: string;
}

function FacilityTypeList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const facilityTypeListQuery = useAppSelector(facilityTypeListQuerySelector);
    const facilityTypeList = useAppSelector(facilityTypeListSelector);
    const pageCount = useAppSelector(totalPageSelector);
    const totalFacilityTypes = useAppSelector(totalFacilityTypesSelector);
    const showLoadingList = useAppSelector(showLoadingListSelector);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        return facilityTypeList.filter((type) => selectedRowKeys.includes(type.id)) || [];
    }, [selectedRowKeys, facilityTypeList]);

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await facilityTypeService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('facilityType.list.deleteFacilityTypesSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const showConfirmDialog = () => {
        const selectedFacilityTypes = facilityTypeList.filter((facilityType) => {
            return selectedRowKeys.includes(facilityType.id);
        });
        ModalConfirmDeletion({
            okButtonProps: { danger: true },
            buttonCancelText: t('facilityType.list.delete.buttonCancelText'),
            buttonDeleteText: t('facilityType.list.delete.buttonDeleteText'),
            deletedItems: selectedFacilityTypes.map((facilityType) =>
                facilityType.name.toString(),
            ),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const exportCsv = async (query: IFacilityTypeExportCsvQuery) => {
        const response = await facilityTypeService.exportCsv(query);
        if (response.success) {
            downloadFile(CSV_EXPORT_FILE_NAME, response.data.filePath);
        }
    };

    const sortDataToCsv = (datas: IFacilityType[]) => {
        const newDatas = datas.sort(function (data1, data2) {
            return data2.id - data1.id;
        });
        return newDatas;
    };

    const convertDataToCsv = (facilityTypes: IFacilityType[]) => {
        const dataOutput: ICSVData[] = [];
        const newFacilityTypes = sortDataToCsv(facilityTypes);
        newFacilityTypes.forEach((facilityType) => {
            if (facilityType.duration) {
                const time = facilityType.duration;
                const hour = Math.floor(time / 3600);
                const minute = Math.floor((time % 3600) / 60);
                const data = {
                    name: facilityType.name,
                    abbreviation: facilityType.abbreviation,
                    businessTimes: `${facilityType.businessStartTime} - ${facilityType.businessEndTime}`,
                    autoGeneratedCode: facilityType.autoGeneratedCode,
                    duration: `${t('facilityType.list.columns.basicCharge', {
                        price: facilityType.price,
                        hour,
                        minute,
                    })}`,
                };
                dataOutput.push(data);
            } else {
                const data = {
                    name: facilityType.name,
                    abbreviation: facilityType.abbreviation,
                    businessTimes: `${facilityType.businessStartTime} - ${facilityType.businessEndTime}`,
                    autoGeneratedCode: facilityType.autoGeneratedCode,
                    price: `${facilityType.price} ${t('facilityType.list.yen')}`,
                };
                dataOutput.push(data);
            }
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportFacilityTypeList = async (facilityTypes: IFacilityType[]) => {
        // Create header file csv
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        // Convert to file csv
        const roomDataCsvs = convertDataToCsv(facilityTypes);
        await exportCSVFile(exportColumns, filename, roomDataCsvs, i18ExportKey);
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all room
                await exportCsv({
                    keyword: facilityTypeListQuery.keyword,
                    abbreviation: facilityTypeListQuery.abbreviation,
                    autoGeneratedCode: facilityTypeListQuery.autoGeneratedCode,
                    name: facilityTypeListQuery.name,
                    orderBy: facilityTypeListQuery.orderBy,
                    orderDirection: facilityTypeListQuery.orderDirection,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportFacilityTypeList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                // ToDo: import
                break;
            }
            default:
                break;
        }
    };

    const onChange: TableProps<IFacilityType>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        // this is for pagination, sort, filter logic later
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setFacilityTypeListQuery({
                    ...facilityTypeListQuery,
                    orderBy: FacilityTypeOrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setFacilityTypeListQuery({
                    ...facilityTypeListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const onChangePage = (page: number) => {
        dispatch(setFacilityTypeListQuery({ ...facilityTypeListQuery, page }));
    };

    const fetchData = () => {
        dispatch(fetchFacilityTypeList());
    };

    useEffect(() => {
        if (
            facilityTypeList.length === 0 &&
            facilityTypeListQuery?.page &&
            facilityTypeListQuery?.page > 1
        ) {
            const page = facilityTypeListQuery?.page - 1;
            dispatch(setFacilityTypeListQuery({ ...facilityTypeListQuery, page }));
        }
    }, [facilityTypeList]);

    useEffect(() => {
        fetchData();
    }, [facilityTypeListQuery]);

    const onRefresh = () => {
        fetchData();
    };

    const handleEdit = () => {
        dispatch(setIsShowUpdateForm(true));
        dispatch(fetchFacilityTypeListByIds(selectedRowKeys as number[]));
    };

    const editFacility = (facility: IFacilityType) => {
        dispatch(setIsShowUpdateForm(true));
        dispatch(fetchFacilityTypeListByIds([facility.id]));
    };

    const facilityTypeColumns: ColumnsType<IFacilityType> = [
        {
            title: t('facilityType.list.columns.id'),
            width: '120px',
            onCell: () => {
                return {
                    style: { color: '#1c3e86' },
                };
            },
            render: (facility: IFacilityType) => {
                return <span>{facility.autoGeneratedCode}</span>;
            },
            key: FacilityTypeColumn.AUTO_GENERATED_CODE,
            sorter: true,
        },
        {
            title: t('facilityType.list.columns.name'),
            dataIndex: FacilityTypeColumn.NAME,
            sorter: true,
            render: (name: string) => {
                return <TextTruncate text={name} />;
            },
        },
        {
            title: t('facilityType.list.columns.abbreviation'),
            dataIndex: FacilityTypeColumn.ABBREVIATION,
            sorter: true,
            render: (abbreviation: string) => {
                return <TextTruncate text={abbreviation} />;
            },
        },
        {
            title: t('facilityType.list.columns.businessTime'),
            width: '15%',
            render: (facilityType: IFacilityType) => {
                return (
                    <div>{`${facilityType.businessStartTime} - ${facilityType.businessEndTime}`}</div>
                );
            },
            key: FacilityTypeColumn.BUSINESS_START_TIME,
            sorter: true,
        },
        {
            title: t('facilityType.list.columns.price'),
            render: (facilityType: IFacilityType) => {
                return facilityType.duration ? (
                    ''
                ) : (
                    <div>
                        {facilityType.price} {t('facilityType.list.yen')}
                    </div>
                );
            },
            key: FacilityTypeColumn.PRICE,
            width: '16%',
            sorter: true,
        },
        {
            title: t('facilityType.list.columns.duration'),
            render: (facilityType: IFacilityType) => {
                if (facilityType.duration) {
                    const time = facilityType.duration;
                    const hour = Math.floor(time / 3600);
                    const minute = Math.floor((time % 3600) / 60);
                    return (
                        <div>
                            {t('facilityType.list.columns.basicCharge', {
                                price: facilityType.price,
                                hour,
                                minute,
                            })}
                        </div>
                    );
                }
            },
            key: FacilityTypeColumn.DURATION,
            sorter: true,
        },
    ];

    return (
        <div className="facility-type-list-wrapper">
            <div className="facility-type-list-header">
                {selectedRows.length > 0 && (
                    <div className="list-header-left">
                        <Button
                            type="text"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={handleEdit}
                        />
                        <Button
                            type="text"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={showConfirmDialog}
                        />
                    </div>
                )}

                <div className="list-header-right">
                    <CsvDropdown
                        onChange={onChangeCsvOption}
                        hasSelectedColumns={selectedRows.length > 0}
                        hasImportOption={false}
                    />
                    <ReloadOutlined className="size-icon" onClick={onRefresh} />
                </div>
            </div>

            <Table
                locale={{
                    triggerDesc: t('dashboard.sort.desc'),
                    triggerAsc: t('dashboard.sort.asc'),
                    cancelSort: t('dashboard.sort.cancel'),
                }}
                rowSelection={rowSelection}
                columns={facilityTypeColumns}
                dataSource={facilityTypeList}
                onChange={onChange}
                pagination={false}
                rowKey="id"
                loading={showLoadingList}
                scroll={{ y: '400px' }}
                rowClassName="facility-type-row"
                onRow={(record) => {
                    return {
                        // click row
                        onClick: () => {
                            editFacility(record);
                        },
                    };
                }}
            />

            {pageCount > 1 && (
                <Pagination
                    align="center"
                    className="facility-type-pagination"
                    defaultCurrent={facilityTypeListQuery.page}
                    current={facilityTypeListQuery.page}
                    total={totalFacilityTypes}
                    pageSize={facilityTypeListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default FacilityTypeList;
