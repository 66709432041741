const stoppingRoomCountForm = {
    title: '売止在庫初期設定',
    alert: {
        title: '日別設定に関して',
        button: '日別に設定',
        description:
            '部屋タイプ毎の初期値を設定します。日別に設定するには、管理画面で編集してください。',
    },
    tableColumn: {
        roomTypeName: '部屋タイプ名',
        totalRooms: '部屋数',
        stoppingRoomCount: '初期値',
    },
    cancelButton: 'キャンセル',
    submitButton: '保存',
    saveStoppingRoomCountSuccess: '更新成功',
    cancelTitle: 'キャンセルしてもよろしいですか？',
};

export default stoppingRoomCountForm;
