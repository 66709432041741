import planListJa from './ja/plan-list.locale';
import planCreateJa from './ja/plan-create.locale';
import planDetailJa from './ja/plan-detail-page.locale';

import planListEn from './en/plan-list.locale';
import planCreateEn from './en/plan-create.locale';
import planDetailEn from './en/plan-detail-page.locale';

const plan = {
    ja: {
        list: planListJa,
        create: planCreateJa,
        detail: planDetailJa,
    },
    en: {
        list: planListEn,
        create: planCreateEn,
        detail: planDetailEn,
    },
};

export default plan;
