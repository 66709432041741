import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import {
    IMarketingChannelGroup,
    IMarketingChannelGroupListResponse,
    IMarketingChannelGroupBulkCreateFormItem,
    IMarketingChannelGroupExportCsvQuery,
    IMarketingChannelListDropdownResponse,
    IMarketingChannelGroupGetListQuery,
    IMarketingChannelGroupBulkDeleteItem,
    IMarketingChannelGroupBulkUpdateFormItem,
    IMarketingChannelGroupDropDownQuery,
} from '../interfaces';
import { ICsvFile, IBodyResponse } from '~common/interfaces';

class MarketingChannelGroupsService extends ApiService {
    getDetail(id: number) {
        return this._getDetail(id) as Promise<IMarketingChannelGroupListResponse>;
    }

    getList(queryString: IMarketingChannelGroupGetListQuery) {
        return this._getList(queryString) as Promise<IMarketingChannelGroupListResponse>;
    }

    getListByIds(ids: number[]) {
        return this._get(`${this.baseUrl}/get-by-ids`, {
            params: {
                ids,
            },
        }) as Promise<IBodyResponse<IMarketingChannelGroup[]>>;
    }

    bulkCreate(bulkCreateBody: IMarketingChannelGroupBulkCreateFormItem[]) {
        return this._post(`${this.baseUrl}/bulk-create`, {
            items: bulkCreateBody,
        }) as Promise<IBodyResponse<IMarketingChannelGroup>>;
    }

    bulkUpdate(bulkUpdateBody: IMarketingChannelGroupBulkUpdateFormItem[]) {
        return this._patch(`${this.baseUrl}/bulk-update`, {
            items: bulkUpdateBody,
        }) as Promise<IBodyResponse<IMarketingChannelGroup>>;
    }

    bulkDelete(bulkDeleteBody: IMarketingChannelGroupBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    getMarketingChannelGroupListForDropdown(params: IMarketingChannelGroupDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<IMarketingChannelListDropdownResponse>;
    }

    exportCsv(query: IMarketingChannelGroupExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }
}

export const marketingChannelGroupsService = new MarketingChannelGroupsService(
    { baseUrl: '/marketing-channel-group' },
    axiosService,
);
