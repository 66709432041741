import React, { useRef } from 'react';
import { Button, Space } from 'antd';
import { StaffLayoutHeader } from '~components';
import { planCreateBreadrumbs, planUpdateBreadrumbs } from '../../constants';
import { useTranslation } from 'react-i18next';
import Step1 from './components/Step1/Step1';
import Step2 from './components/Step2/Step2';
import { Footer, Content } from 'antd/lib/layout/layout';
import './PlanForm.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '~hooks';
import {
    formStepSelector,
    setFormStep,
    showLoadingSelector,
} from '../../reducers/plan.reducer';
import { useDispatch } from 'react-redux';
import { getFormPlanId } from '~features/plan/helper';
import { showConfirm } from '~common/notification';
import { IErrorPlan } from '~features/plan/interfaces';

function PlanForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { planId } = useParams();
    const step = useAppSelector(formStepSelector);
    const showLoading = useAppSelector(showLoadingSelector);
    const [error, setError] = React.useState<IErrorPlan>({
        key: '',
        message: '',
    });
    const step1Ref = useRef<{
        onSubmitStep1: () => void;
    }>(null);

    const step2Ref = useRef<{
        onSubmitStep2: () => void;
        onBackToStep1: () => void;
    }>(null);

    const onClickButtonNextStep = () => {
        step1Ref.current?.onSubmitStep1();
    };

    const onClickButtonPreviousStep = () => {
        step2Ref.current?.onBackToStep1();
        dispatch(setFormStep(1));
    };

    const onClickButtonBack = () => {
        showConfirm({
            title: t('plan.list.confirm.cancelTitle'),
            cancelText: t('plan.list.confirm.buttonCancelText'),
            okText: t('plan.list.confirm.buttonDeleteText'),
            onOk() {
                navigate('/front-settings/plan');
            },
        });
    };

    const onSubmit = () => {
        step2Ref.current?.onSubmitStep2();
    };

    return (
        <>
            <StaffLayoutHeader
                breadcrumbs={planId ? planUpdateBreadrumbs() : planCreateBreadrumbs()}
                title={planId ? t('plan.create.updateTitle') : t('plan.create.title')}
            />
            <Content>
                {step === 1 && <Step1 error={error} ref={step1Ref} />}
                {step === 2 && <Step2 error={error} setError={setError} ref={step2Ref} />}
            </Content>

            <Footer>
                <div className="plan-form-footer">
                    {step === 1 && (
                        <Space align="end">
                            <Button onClick={onClickButtonBack}>
                                {t('plan.create.buttonCancel')}
                            </Button>
                            <Button
                                type="primary"
                                id={getFormPlanId(`btn-next`)}
                                onClick={onClickButtonNextStep}
                            >
                                {t('plan.create.buttonNext')}
                            </Button>
                        </Space>
                    )}
                    {step === 2 && (
                        <Space align="end">
                            <Button onClick={onClickButtonPreviousStep}>
                                {t('plan.create.buttonBack')}
                            </Button>
                            <Button
                                type="primary"
                                loading={showLoading}
                                onClick={onSubmit}
                                id={getFormPlanId(`btn-submit`)}
                            >
                                {t('plan.create.buttonSubmit')}
                            </Button>
                        </Space>
                    )}
                </div>
            </Footer>
        </>
    );
}

export default PlanForm;
