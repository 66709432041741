import { Table } from 'antd';
import { ColumnGroupType } from 'antd/es/table';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat } from '~common/constants';
import { FormatMoney, FormatNumber } from '~common/helper';
import { ReportTableCsvFileName, ReportTableId } from '~features/report/constants';
import { exportCSVReport } from '~features/report/helper';
import {
    salesByPlansSelector,
    setExportCsv,
} from '~features/report/reducers/sales-by-plans.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs from '~plugins/dayjs';

interface Props {
    dataSource:
        | {
              id: string;
              date: string;
          }[]
        | undefined;
    filteredPlanList: {
        value: number;
        label: string;
    }[];
    summaryData:
        | {
              [key: string]: {
                  [key: string]: number;
              };
          }
        | undefined;
    loading: boolean;
}
const { Cell: SummaryCell, Row: SummaryRow } = Table.Summary;

export function DailySalesByPlansTable({
    dataSource,
    filteredPlanList,
    loading,
    summaryData,
}: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isExportCsv } = useAppSelector(salesByPlansSelector);

    useEffect(() => {
        if (isExportCsv) {
            exportCSVReport(
                ReportTableId.SALES_BY_PLANS_DAILY,
                `${ReportTableCsvFileName.SALES_BY_PLANS_DAILY}.csv`,
            );
            dispatch(setExportCsv(false));
        }
    }, [isExportCsv, dispatch]);

    const columns: ColumnsType<any> | ColumnGroupType<any> = [
        {
            className: 'date-header-cell',
            title: t('report.salesByPlans.tableColumns.date'),
            dataIndex: 'date',
            key: 'date',
            fixed: 'left',
            width: 150,
            render: (date) => {
                return (
                    <span>{customDayjs(date).format(DateFormat.MM_DD_YYYY_SLASH)}</span>
                );
            },
        },
        {
            title: t('report.salesByPlans.tableColumns.plan'),
            className: 'header-cell daily-plan-header-cell',
            dataIndex: 'plan',
            key: 'plan',
            children: filteredPlanList.map((plan) => ({
                title: plan.label,
                className: 'small-cell header-cell',
                key: plan.value,
                children: [
                    {
                        title: t('report.salesByPlans.tableColumns.totalBookedRoom'),
                        className: 'small-cell',
                        dataIndex: `totalBookedRoom${plan.value}`,
                        key: `totalBookedRoom${plan.value}`,
                        width: 150,
                        render: (_, record) => {
                            const current = record[`totalBookedRoom${plan.value}`];

                            if (!current) return null;
                            const {
                                current: totalBookedRoom,
                                previousYear: previousYearTotalBookedRoom,
                            } = current;

                            return (
                                <>
                                    {FormatNumber.format(totalBookedRoom)} {''}
                                    <span className="gray-color" key={plan.value}>
                                        (
                                        {FormatNumber.format(previousYearTotalBookedRoom)}
                                        )
                                    </span>
                                </>
                            );
                        },
                    },
                    {
                        title: t('report.salesByPlans.tableColumns.totalAdult'),
                        className: 'small-cell',
                        dataIndex: `totalAdult${plan.value}`,
                        key: `totalAdult${plan.value}`,
                        width: 150,
                        render: (_, record) => {
                            const current = record[`totalAdult${plan.value}`];
                            if (!current) return null;
                            const {
                                current: totalAdult,
                                previousYear: previousYearTotalAdult,
                            } = current;

                            return (
                                <>
                                    {FormatNumber.format(totalAdult)} {''}
                                    <span className="gray-color" key={plan.value}>
                                        ({FormatNumber.format(previousYearTotalAdult)})
                                    </span>
                                </>
                            );
                        },
                    },
                    {
                        title: t('report.salesByPlans.tableColumns.totalChild'),
                        className: 'small-cell',
                        dataIndex: `totalChild${plan.value}`,
                        key: `totalChild${plan.value}`,
                        width: 150,
                        render: (_, record) => {
                            const current = record[`totalChild${plan.value}`];
                            if (!current) return null;
                            const {
                                current: totalChild,
                                previousYear: previousYearTotalChild,
                            } = current;

                            return (
                                <>
                                    {FormatNumber.format(totalChild)} {''}
                                    <span className="gray-color" key={plan.value}>
                                        ({FormatNumber.format(previousYearTotalChild)})
                                    </span>
                                </>
                            );
                        },
                    },
                    {
                        title: t('report.salesByPlans.tableColumns.totalRevenue'),
                        className: 'small-cell',
                        dataIndex: `totalRevenue${plan.value}`,
                        key: `totalRevenue${plan.value}`,
                        width: 150,
                        render: (_, record) => {
                            const current = record[`totalRevenue${plan.value}`];
                            if (!current) return null;
                            const {
                                current: totalRevenue,
                                previousYear: previousYearTotalRevenue,
                            } = current;

                            return (
                                <>
                                    {FormatMoney.format(totalRevenue)} {''}
                                    <span className="gray-color" key={plan.value}>
                                        ({FormatMoney.format(previousYearTotalRevenue)})
                                    </span>
                                </>
                            );
                        },
                    },
                    {
                        title: t('report.salesByPlans.tableColumns.avgRevenue'),
                        className: 'small-cell',
                        dataIndex: `avgRevenue${plan.value}`,
                        key: `avgRevenue${plan.value}`,
                        width: 150,
                        render: (_, record) => {
                            const current = record[`avgRevenue${plan.value}`];
                            if (!current) return null;
                            const {
                                current: avgRevenue,
                                previousYear: previousYearAvgRevenue,
                            } = current;

                            return (
                                <>
                                    {FormatMoney.format(avgRevenue)} {''}
                                    <span className="gray-color" key={plan.value}>
                                        ({FormatMoney.format(previousYearAvgRevenue)})
                                    </span>
                                </>
                            );
                        },
                    },
                ],
            })),
        },
    ];

    const summaryColumns = columns
        .filter((column) => column.key === 'plan')
        .map((column) =>
            (column as ColumnGroupType<any>).children.map(
                (child) => (child as ColumnGroupType<any>).children,
            ),
        )
        .flat(2);

    const showSummaryNumberOfRooms = (columnDataIndex: string) => {
        let totalBookedRoom = 0;
        let previousYearTotalBookedRoom = 0;
        if (summaryData && summaryData[columnDataIndex]) {
            const { current, previousYear } = summaryData[columnDataIndex];
            totalBookedRoom = current;
            previousYearTotalBookedRoom = previousYear;
        }

        return (
            <>
                {FormatNumber.format(totalBookedRoom)} {''}
                <span className="gray-color">
                    ({FormatNumber.format(previousYearTotalBookedRoom)})
                </span>
            </>
        );
    };

    const showSummaryTotalAdult = (columnDataIndex: string) => {
        let totalAdult = 0;
        let previousYearTotalAdult = 0;
        if (summaryData && summaryData[columnDataIndex]) {
            const { current, previousYear } = summaryData[columnDataIndex];
            totalAdult = current;
            previousYearTotalAdult = previousYear;
        }
        return (
            <>
                {FormatNumber.format(totalAdult)} {''}
                <span className="gray-color">
                    ({FormatNumber.format(previousYearTotalAdult)})
                </span>
            </>
        );
    };

    const showSummaryTotalChild = (columnDataIndex: string) => {
        let totalChild = 0;
        let previousYearTotalChild = 0;
        if (summaryData && summaryData[columnDataIndex]) {
            const { current, previousYear } = summaryData[columnDataIndex];
            totalChild = current;
            previousYearTotalChild = previousYear;
        }
        return (
            <>
                {FormatNumber.format(totalChild)} {''}
                <span className="gray-color">
                    ({FormatNumber.format(previousYearTotalChild)})
                </span>
            </>
        );
    };

    const showSummaryTotalRevenue = (columnDataIndex: string) => {
        let totalRevenue = 0;
        let previousYearTotalRevenue = 0;
        if (summaryData && summaryData[columnDataIndex]) {
            const { current, previousYear } = summaryData[columnDataIndex];
            totalRevenue = current;
            previousYearTotalRevenue = previousYear;
        }
        return (
            <>
                {FormatMoney.format(totalRevenue)} {''}
                <span className="gray-color">
                    ({FormatMoney.format(previousYearTotalRevenue)})
                </span>
            </>
        );
    };

    const showSummaryAvgRevenue = (columnDataIndex: string) => {
        let totalAvgRevenue = 0;
        let previousYearTotalAvgRevenue = 0;
        if (summaryData && summaryData[columnDataIndex]) {
            const { current, previousYear } = summaryData[columnDataIndex];
            totalAvgRevenue = current;
            previousYearTotalAvgRevenue = previousYear;
        }
        return (
            <>
                {FormatMoney.format(totalAvgRevenue)} {''}
                <span className="gray-color">
                    ({FormatMoney.format(previousYearTotalAvgRevenue)})
                </span>
            </>
        );
    };

    const renderSummaryCell = (column: ColumnGroupType<any> | ColumnType<any>) => {
        const columnDataIndex = (column as ColumnType<any>).dataIndex?.toString();
        const columnKey = column.key?.toString();
        if (columnKey?.includes('totalBookedRoom')) {
            return showSummaryNumberOfRooms(columnDataIndex!);
        }
        if (columnKey?.includes('totalAdult')) {
            return showSummaryTotalAdult(columnDataIndex!);
        }
        if (columnKey?.includes('totalChild')) {
            return showSummaryTotalChild(columnDataIndex!);
        }
        if (columnKey?.includes('totalRevenue')) {
            return showSummaryTotalRevenue(columnDataIndex!);
        }
        if (columnKey?.includes('avgRevenue')) {
            return showSummaryAvgRevenue(columnDataIndex!);
        }
        return <></>;
    };

    const renderSummary = () => {
        if (!isEmpty(summaryData)) {
            return (
                <>
                    <SummaryRow>
                        <SummaryCell index={0} colSpan={1}>
                            {t('report.salesByPlans.tableColumns.total')}
                        </SummaryCell>
                        {summaryColumns.map((column, index) => {
                            return (
                                <SummaryCell key={index} index={index + 1}>
                                    {renderSummaryCell(column)}
                                </SummaryCell>
                            );
                        })}
                    </SummaryRow>
                </>
            );
        }
        return <></>;
    };
    return (
        <Table
            bordered={true}
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            scroll={{ x: 400 }}
            sticky={{
                offsetHeader: 50,
                offsetScroll: 0,
            }}
            id={ReportTableId.SALES_BY_PLANS_DAILY}
            pagination={false}
            className="sales-by-plans-table table-scrollbar"
            rowKey="id"
            summary={() => renderSummary()}
        />
    );
}
