const saleItemUpdate = {
    title: 'Edit sales item',
    form: {
        name: 'Sales item name',
        saleGroup: 'Sales group',
        price: 'Standard price',
        tax: 'Consumption tax type',
        isShowInBooking: 'Preparation list',
        isShowInBookingTitle: 'Display in preparation list',
    },
    message: {
        nameDuplicated: 'Sales item name is duplicated',
        updateSuccess: 'Sales item has been updated successfully',
    },
    confirm: { cancelTitle: 'Do you want to cancel?' },
};

export default saleItemUpdate;
