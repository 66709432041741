import { useMutation } from '@tanstack/react-query';
import { roomTypeService } from '~features/room-type/services/room-type.service';
import { IRoomDisplayOrderBody } from '../interfaces';

const updateRoomTypeOrderSettings = async (body: IRoomDisplayOrderBody): Promise<any> => {
    const response = await roomTypeService.updateRoomTypeDisplayOrder(body);
    if (response.success) {
        return response.data;
    }
    throw response.errors![0];
};

export const useUpdateRoomTypeOrderSettings = () => {
    return useMutation({ mutationFn: updateRoomTypeOrderSettings });
};
