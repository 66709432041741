import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DateFormat } from '~common/constants';
import { SingleDatePicker } from '~components';
import {
    currentGuestQuerySelector,
    isLoadingCurrentGuestSelector,
    selectedDateSelector,
    setCurrentGuestQuery,
    setSelectedDate,
    setShowCurrentGuestTable,
} from '~features/report/reducers/current-guest.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import './FilterForm.scss';
import ExportCsvButton from './ExportCsvButton';
import { getChildrenTypeDropdown } from '~features/children-type/reducers/children-type.reducer';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { currentGuestFilterSchema } from '~features/report/schema';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowLoading = useAppSelector(isLoadingCurrentGuestSelector);
    const selectedDate = useAppSelector(selectedDateSelector);
    const query = useAppSelector(currentGuestQuerySelector);
    const { control, setValue } = useForm({
        resolver: yupResolver(currentGuestFilterSchema),
    });

    useEffect(() => {
        setValue(`day`, customDayjs());
    }, []);

    const changeDate = (date: Dayjs) => {
        dispatch(setSelectedDate(date.fmYYYYMMDD()));
        dispatch(
            setCurrentGuestQuery({
                ...query,
                datePeriod: [
                    parseDate(date).startOf('day').fmYYYYMMDDHHmmss(),
                    parseDate(date).endOf('day').fmYYYYMMDDHHmmss(),
                ],
            }),
        );
    };

    const clickButtonFilter = () => {
        dispatch(
            setCurrentGuestQuery({
                ...query,
                datePeriod: [
                    parseDate(selectedDate).startOf('day').fmYYYYMMDDHHmmss(),
                    parseDate(selectedDate).endOf('day').fmYYYYMMDDHHmmss(),
                ],
            }),
        );
        dispatch(setShowCurrentGuestTable(true));
        dispatch(getChildrenTypeDropdown());
    };

    return (
        <div className="report-current-guest-filter-form-wrapper">
            <div>
                <div className="left-block-filter">
                    <span className="current-guest-date-label">
                        {t('report.currentGuest.filterForm.dateLabel')}
                    </span>
                    <SingleDatePicker
                        className="date-picker"
                        placeholder={t('report.placeholder.selectDate')}
                        value={customDayjs(selectedDate)}
                        name="day"
                        format={DateFormat.YYYY_MM_DD_HYPHEN}
                        onChange={changeDate}
                        control={control}
                    />
                    <Button
                        type="primary"
                        onClick={clickButtonFilter}
                        loading={isShowLoading}
                        className="display-button"
                    >
                        {t('report.currentGuest.filterForm.displayButton')}
                    </Button>
                </div>
            </div>
            <ExportCsvButton />
        </div>
    );
}

export default FilterForm;
