const notificationMessage = {
    createFaclityBookingSuccess:
        'Create facility booking success click to view booking detail',
    createRoomBookingItemSucces:
        'A room booking item has been created click to view booking',
    updateRoomBookingStatus: 'A room booking status updated success',
    updateFacilityBooking:
        'A facility booking updated success click to view booking detail',
};

export default notificationMessage;
