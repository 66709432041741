import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse, ILoginUser } from '~common/interfaces';

class AuthService extends ApiService {
    getProfile() {
        return this._get(`/auth/profile`) as Promise<IBodyResponse<ILoginUser>>;
    }
}
export const authService = new AuthService({ baseUrl: '/auth' }, axiosService);
