import {
    IPaymentMethod,
    IPaymentMethodGetListQuery,
} from '~features/payment-method/interfaces';
import { DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import { IPaymentMethodFormBody } from './../interfaces';
import { cloneDeep } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaymentMethodState } from '../interfaces';
import { AppState } from '~plugins/redux-toolkit/store';
import { paymentMethodService } from '../services/paymentMethod.service';
import { DEFAULT_PAYMENT_METHOD_LIST_QUERY, SearchField } from '../constants';
import { paymentMethodServiceV2 } from '../services/paymentMethodV2.service';

const initialState: IPaymentMethodState = {
    paymentMethodDropDown: [],
    paymentMethods: [],
    selectedPaymentMethod: {} as IPaymentMethod,
    isShowForm: false,
    formBusy: false,
    showLoadingList: false,
    paymentMethodListQuery: cloneDeep(DEFAULT_PAYMENT_METHOD_LIST_QUERY),
    totalPaymentMethods: 0,
    searchField: SearchField.ALL,
};

export const getPaymentMethodDropdown = createAsyncThunk(
    'paymentMethod/getPaymentMethodDropdown',
    async (params?: IPaymentMethodGetListQuery) => {
        return await paymentMethodServiceV2.getDropdown(params);
    },
);

export const getPaymentMethodList = createAsyncThunk(
    'paymentMethod/getPaymentMethodList',
    async (_, { getState }) => {
        const query = paymentMethodListQuerySelector(getState() as AppState);
        return await paymentMethodService.getList(query);
    },
);

export const createPaymentMethod = createAsyncThunk(
    'paymentMethod/createPaymentMethod',
    async (body: IPaymentMethodFormBody) => {
        return await paymentMethodService.createPaymentMethod(body);
    },
);

export const updatePaymentMethod = createAsyncThunk(
    'paymentMethod/updatePaymentMethod',
    async ({ id, body }: { id: number; body: IPaymentMethodFormBody }) => {
        return await paymentMethodService.updatePaymentMethod(id, body);
    },
);

const paymentMethodSlice = createSlice({
    name: 'paymentMethod',
    initialState,
    reducers: {
        setPaymentMethods: (state, action) => {
            state.paymentMethods = action.payload;
        },
        setIsShowForm: (state, action) => {
            state.isShowForm = action.payload;
        },
        setPaymentMethodListQuery: (state, action) => {
            state.paymentMethodListQuery = action.payload;
        },
        setSelectedPaymentMethod: (state, action) => {
            state.selectedPaymentMethod = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPaymentMethodDropdown.fulfilled, (state, action) => {
            if (action.payload?.success) {
                state.paymentMethodDropDown = action.payload?.data?.items || [];
            } else {
                state.paymentMethodDropDown = [];
            }
        });

        builder.addCase(getPaymentMethodList.pending, (state, action) => {
            state.showLoadingList = true;
        });

        builder.addCase(getPaymentMethodList.fulfilled, (state, action) => {
            if (action.payload?.success) {
                state.paymentMethods = action.payload?.data?.items || [];
                state.totalPaymentMethods = action.payload?.data?.totalItems || 0;
                state.showLoadingList = false;
            } else state.paymentMethods = [];
        });

        builder.addCase(createPaymentMethod.pending, (state, action) => {
            state.formBusy = true;
        });

        builder.addCase(createPaymentMethod.fulfilled, (state, action) => {
            state.formBusy = false;
        });

        builder.addCase(updatePaymentMethod.pending, (state, action) => {
            state.formBusy = true;
        });

        builder.addCase(updatePaymentMethod.fulfilled, (state, action) => {
            state.formBusy = false;
        });
    },
});

export const {
    setPaymentMethods,
    setIsShowForm,
    setPaymentMethodListQuery,
    setSelectedPaymentMethod,
    setSearchField,
} = paymentMethodSlice.actions;

export const paymentMethodStateSelector = (state: AppState) => state.paymentMethod;

export const isShowFormSelector = (state: AppState) => state.paymentMethod.isShowForm;

export const paymentMethodListSelector = (state: AppState) =>
    state.paymentMethod.paymentMethods;

export const paymentMethodListQuerySelector = (state: AppState) =>
    state.paymentMethod.paymentMethodListQuery;

export const formBusySelector = (state: AppState) => state.paymentMethod.formBusy;

export const selectedPaymentMethodSelector = (state: AppState) =>
    state.paymentMethod.selectedPaymentMethod;

export const totalPaymentMethodsSelector = (state: AppState) =>
    state.paymentMethod.totalPaymentMethods;

export const totalPageSelector = (state: AppState) => {
    const { totalPaymentMethods, paymentMethodListQuery } = state.paymentMethod;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = paymentMethodListQuery;
    return Math.ceil(totalPaymentMethods / limit);
};

export const showLoadingListSelector = (state: AppState) =>
    state.paymentMethod.showLoadingList;

export const searchFieldSelector = (state: AppState) => state.paymentMethod.searchField;

export const paymentMethodReducer = paymentMethodSlice.reducer;
