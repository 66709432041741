import { Modal, Tooltip } from 'antd';
import classNames from 'classnames';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { HTMLProps, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { checkRangeDateTimeOverlapping } from '~features/room-booking/helper';
import { IScheduleItem, IStatisticBookingItem } from '~features/room-booking/interfaces';
import {
    scheduleStateSelector,
    setBlockedRoomsSelecting,
    setPanelId,
    setSelecting,
    setStartPosition,
    setVisibleCreateBookingPopover,
} from '~features/room-booking/reducers/schedule.reducer';
import {
    endSelect,
    getCellList,
    getDateFromElement,
    removeSelection,
    useScheduleEvent,
} from '~features/room-booking/util';
import { useAppDispatch, useAppSelector } from '~hooks';
import _ from 'lodash';
import { StopRoomCause } from '~features/room-management/constants';

type Props = HTMLProps<HTMLDivElement> & {
    isToday: boolean;
    disabled: boolean;
    item: IScheduleItem;
    rowIndex: number;
    columnIndex: number;
    day: string;
    memo?: string;
    reason?: StopRoomCause.INVENTORY_ADJUSTMENT | StopRoomCause.ROOM_ISSUES;
};

export const CellItem = ({
    isToday,
    item,
    rowIndex,
    columnIndex,
    day,
    children,
    style,
    disabled,
    memo,
    reason,
    ...rest
}: Props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    // handle select day for create booking
    const {
        startPosition,
        isSelecting,
        isBlockedRoomsSelecting,
        statisticsByDate,
        visibleCreateBookingPopover,
        stoppingRoomList,
        bookingGroupById,
    } = useAppSelector(scheduleStateSelector);

    const panelId = useMemo(() => {
        return `room-${item.parentId}-${item.id}`;
    }, [item]);

    const remain = useMemo(() => {
        if (item.parentId) return 0;
        const roomTypeKey = 'roomType_' + item.id;
        const { overnightBookingRoomCount, overnightUnassignRoomBookingRoomCount } =
            (statisticsByDate[day]?.[roomTypeKey] || {}) as IStatisticBookingItem;

        const stopSaleRoom = _.sum(
            Object.values(stoppingRoomList[day]?.[roomTypeKey] || {}).map((room) =>
                room.stopping ? 1 : 0,
            ),
        );
        return (
            item.numberOfRooms -
            stopSaleRoom -
            (overnightBookingRoomCount || 0) -
            (overnightUnassignRoomBookingRoomCount || 0)
        );
    }, [statisticsByDate, day, item, stoppingRoomList]);

    const startSelect = useCallback(
        (position: { x: number; y: number }, selectBlocked?: boolean) => {
            if (visibleCreateBookingPopover) {
                return;
            }
            if (!selectBlocked) {
                dispatch(setSelecting(true));
            } else {
                dispatch(setBlockedRoomsSelecting(true));
            }

            const list = getCellList(panelId);
            const targetSell = list[`${position.y}-${position.x}`];
            selectBlocked
                ? targetSell?.classList.add('selection-blocked')
                : targetSell?.classList.add('selection');

            dispatch(setStartPosition(position));
            dispatch(setPanelId(panelId));
        },
        [panelId, visibleCreateBookingPopover, dispatch],
    );

    const { triggerScroll, today } = useScheduleEvent();
    const _endSelect = useCallback(
        (position: { x: number; y: number }) => {
            if (visibleCreateBookingPopover) {
                return;
            }

            if (!startPosition) {
                removeSelection();
                triggerScroll(true);
                return;
            }
            const options = {
                panelId,
                startPosition,
                currentCursor: position,
                onClose: () => {
                    triggerScroll(true);
                    dispatch(setVisibleCreateBookingPopover(false));
                },
            };
            const { startDate, endDate } = getDateFromElement(options);
            const availableDates = checkRangeDateTimeOverlapping({
                start: parseDate(startDate).endOf('day'),
                end: parseDate(endDate).startOf('day'),
                roomKey: `${item.parentId}-${item.id}`,
                bookingGroupById,
            });

            if (availableDates.length === 0) {
                dispatch(setVisibleCreateBookingPopover(true));
                endSelect(options, isBlockedRoomsSelecting);
            } else {
                removeSelection();
                Modal.error({
                    title: t('roomBooking.schedule.dateRageInvalid'),
                    content: (
                        <>
                            <div>{t('roomBooking.schedule.bookingOccupied')}</div>
                            <ul>
                                {availableDates.map((date) => {
                                    return <li>{date}</li>;
                                })}
                            </ul>
                        </>
                    ),
                    centered: true,
                });
                triggerScroll(true);
            }

            dispatch(setSelecting(false));
            dispatch(setBlockedRoomsSelecting(false));
        },
        [
            startPosition,
            isSelecting,
            isBlockedRoomsSelecting,
            visibleCreateBookingPopover,
            bookingGroupById,
            item,
            panelId,
            triggerScroll,
            t,
            dispatch,
        ],
    );
    return (
        <div
            style={{
                ...style,
            }}
            {...rest}
            className={classNames('cell-item-wrapper has-padding', {
                today: isToday,
            })}
        >
            {item.parentId ? (
                <div
                    className={classNames('day-item border-top', {
                        today: isToday,
                        disabled: disabled,
                        broken: reason === StopRoomCause.ROOM_ISSUES,
                        [panelId]: true,
                    })}
                >
                    <Tooltip title={memo}>
                        <div
                            className={classNames('cell-content', {
                                [panelId]: true,
                            })}
                            onClick={() => {
                                if (
                                    customDayjs(today)
                                        .subtract(2, 'day')
                                        .isAfter(customDayjs(day))
                                )
                                    return;

                                if (disabled) {
                                    if (isSelecting) return;

                                    triggerScroll(false);
                                    if (!isBlockedRoomsSelecting) {
                                        startSelect(
                                            { x: columnIndex, y: rowIndex },
                                            true,
                                        );
                                    } else {
                                        _endSelect({ x: columnIndex, y: rowIndex });
                                    }
                                    return;
                                }

                                if (isBlockedRoomsSelecting) return;

                                triggerScroll(false);
                                if (!isSelecting) {
                                    startSelect({ x: columnIndex, y: rowIndex });
                                } else {
                                    _endSelect({ x: columnIndex, y: rowIndex });
                                }
                            }}
                            data-x={columnIndex}
                            data-y={rowIndex}
                            data-room_type_id={item.parentId}
                            data-room_id={item.id}
                            data-day={day}
                            data-memo={memo}
                        ></div>
                        {children}
                    </Tooltip>
                </div>
            ) : (
                <Tooltip title={t('roomBooking.schedule.remainingRoom')}>
                    <div
                        className={classNames('schedule-collapse-header-item', {
                            'color-red': remain <= 0,
                            'color-yellow': remain > 0 && remain <= 3,
                        })}
                    >
                        {remain}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};
