import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import trim from 'lodash/trim';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '../../common/constants';
import { initRoomTypeListQuery, SearchField } from './constants';
import {
    IDropDownQuery,
    IImportRoomTypeBodyItem,
    IRoomTypeCreateBody,
    IRoomTypeGetListQuery,
    IRoomTypeState,
    IRoomTypeUpdateRequest,
} from './interfaces';
import { roomTypeService } from './services/room-type.service';

const initialState: IRoomTypeState = {
    roomTypeListQuery: cloneDeep(initRoomTypeListQuery),
    totalRoomTypes: 0,
    roomTypeList: [],
    roomTypeDropdownOptions: [],
    roomTypeListDropdown: [],
    selectedRoomType: null,
    showLoading: false,
    formBusy: false,
    showForm: false,
    searchField: SearchField.ALL,
    importRoomTypeList: [],
    importCsvFileName: '',
    hasImportError: false,
    importLoading: false,
};

export const fetchRoomTypeList = createAsyncThunk(
    'roomType/fetchRoomTypeList',
    async (_, { getState }) => {
        const query = roomTypeListQuerySelector(getState() as AppState);
        return await roomTypeService.getList(query);
    },
);

export const getRoomTypeListForDropdown = createAsyncThunk(
    'roomType/getRoomTypeListForDropdown',
    async (params?: IDropDownQuery) => {
        return await roomTypeService.getRoomTypeListForDropdown(params);
    },
);

export const createRoomType = createAsyncThunk(
    'roomType/createRoomType',
    async (formData: IRoomTypeCreateBody) => {
        return await roomTypeService.create(formData);
    },
);

export const updateRoomType = createAsyncThunk(
    'roomType/updateRoomType',
    async (formData: IRoomTypeUpdateRequest) => {
        return await roomTypeService.update(formData.id, formData.body);
    },
);

export const getRoomTypeDetail = createAsyncThunk(
    'roomType/getRoomTypeDetail',
    async (id: number) => {
        return await roomTypeService.getDetail(id);
    },
);

export const importCsvRoomType = createAsyncThunk(
    'roomType/importCsvRoomType',
    async (importRoomTypeBody: IImportRoomTypeBodyItem[]) => {
        return await roomTypeService.importRoomTypeCsv(importRoomTypeBody);
    },
);

export const roomTypeSlice = createSlice({
    name: 'roomType',
    initialState,
    reducers: {
        setRoomTypeListQuery: (state, action) => {
            state.roomTypeListQuery = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IRoomTypeGetListQuery = {
                limit: state.roomTypeListQuery.limit,
                orderBy: state.roomTypeListQuery.orderBy,
                orderDirection: state.roomTypeListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.roomTypeListQuery = query;
        },
        setRoomTypeList: (state, action) => {
            state.roomTypeList = action.payload;
        },
        setTotalRoomTypes: (state, action) => {
            state.totalRoomTypes = action.payload;
        },
        setRoomTypeDropdownOptions: (state, action) => {
            state.roomTypeDropdownOptions = action.payload;
        },
        setRoomTypeListDropdown: (state, action) => {
            state.roomTypeListDropdown = action.payload;
        },
        setSelectedRoomType: (state, action) => {
            state.selectedRoomType = action.payload;
        },
        setShowForm: (state, action) => {
            state.showForm = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IRoomTypeGetListQuery = {
                limit: state.roomTypeListQuery.limit,
                orderBy: state.roomTypeListQuery.orderBy,
                orderDirection: state.roomTypeListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.roomTypeListQuery.keyword ||
                    state.roomTypeListQuery.name ||
                    state.roomTypeListQuery.roomTypeCode ||
                    state.roomTypeListQuery.autoGeneratedCode ||
                    state.roomTypeListQuery.abbreviation ||
                    state.roomTypeListQuery.connectingRoomTypeName ||
                    '',
            };
            state.roomTypeListQuery = query;
        },
        setImportLoading: (state, action) => {
            state.importLoading = action.payload;
        },
        setImportRoomTypeList: (state, action) => {
            state.importRoomTypeList = action.payload;
        },
        setImportCsvFileName: (state, action) => {
            state.importCsvFileName = action.payload;
        },
        setHasImportError: (state, action) => {
            state.hasImportError = action.payload;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoomTypeListForDropdown.fulfilled, (state, action) => {
            state.roomTypeDropdownOptions = (action.payload?.data?.items || []).map(
                (item) =>
                    ({
                        label: item.name,
                        value: item.id,
                    } || []),
            );
            state.roomTypeListDropdown = action.payload?.data?.items || [];
        });
        builder.addCase(fetchRoomTypeList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.roomTypeList = action.payload?.data?.items || [];
            state.totalRoomTypes = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchRoomTypeList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(createRoomType.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(createRoomType.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(updateRoomType.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(updateRoomType.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(getRoomTypeDetail.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(getRoomTypeDetail.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(importCsvRoomType.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(importCsvRoomType.fulfilled, (state, action) => {
            state.showLoading = false;
        });
    },
});

export const {
    setRoomTypeListQuery,
    setTotalRoomTypes,
    setRoomTypeList,
    setKeyword,
    setRoomTypeDropdownOptions,
    setRoomTypeListDropdown,
    setSelectedRoomType,
    setShowForm,
    setSearchField,
    setImportRoomTypeList,
    setImportCsvFileName,
    setHasImportError,
    setImportLoading,
    resetState,
} = roomTypeSlice.actions;

export const roomTypeDropdownOptionsSelector = (state: AppState) => {
    return state.roomType.roomTypeDropdownOptions;
};
export const roomTypeListDropdownSelector = (state: AppState) => {
    return state.roomType.roomTypeListDropdown;
};

export const roomTypeListSelector = (state: AppState) => {
    return state.roomType.roomTypeList;
};
export const showLoadingSelector = (state: AppState) => {
    return state.roomType.showLoading;
};
export const formBusySelector = (state: AppState) => {
    return state.roomType.formBusy;
};

export const roomTypeListQuerySelector = (state: AppState) => {
    return state.roomType.roomTypeListQuery;
};

export const searchFieldSelector = (state: AppState) => {
    return state.roomType.searchField;
};

export const selectedRoomTypeSelector = (state: AppState) => {
    return state.roomType.selectedRoomType;
};
export const showFormSelector = (state: AppState) => {
    return state.roomType.showForm;
};

export const totalRoomTypesSelector = (state: AppState) => {
    return state.roomType.totalRoomTypes;
};

export const totalPageSelector = (state: AppState) => {
    const { totalRoomTypes, roomTypeListQuery } = state.roomType;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = roomTypeListQuery;
    return Math.ceil(totalRoomTypes / limit);
};

export const importRoomTypeListSelector = (state: AppState) => {
    return state.roomType.importRoomTypeList;
};

export const importCsvFileNameSelector = (state: AppState) => {
    return state.roomType.importCsvFileName;
};

export const hasImportErrorSelector = (state: AppState) => {
    return state.roomType.hasImportError;
};

export const importLoadingSelector = (state: AppState) => {
    return state.roomType.importLoading;
};

export default roomTypeSlice.reducer;
