import { Button, Modal, Table, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from '~features/plan/helper';

import './ReceiptFilesModal.scss';
import { useQuery } from '@tanstack/react-query';
import { IGetReceiptFilesResponse } from '~features/room-booking/interfaces';

import { fileService } from '~features/file/file.service';
import { receiptFileService } from '~features/room-booking/services/receipt-file.service';
import { FILE_TYPE, INVOICE_PATH } from '~features/room-booking/constants';
import { CacheKeys } from '~queries/queries';

type Props = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    receiptId: number;
};

export const ReceiptFilesModal = ({ isOpen, setIsOpen, receiptId }: Props) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const { data, isLoading, refetch, isError, isRefetching } = useQuery({
        queryKey: [CacheKeys.receiptFiles, receiptId],
        queryFn: async () => {
            const res = await receiptFileService.getReceiptFiles(receiptId, pathname.endsWith(INVOICE_PATH) ? FILE_TYPE.INVOICE : FILE_TYPE.RECEIPT);
            if (res.success) return res.data;
            throw Error(res.message);
        },
    });

    useEffect(() => {
        if (isOpen) refetch();
    }, [isOpen, refetch]);

    useEffect(() => {
        if (isError && isOpen && !isRefetching && !isLoading)
            notification.error({
                message: t('roomBooking.splitReceipt.files.listError'),
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, isRefetching, t]);

    const onCancel = () => {
        setIsOpen(false);
    };

    const columns: ColumnsType<IGetReceiptFilesResponse> = [
        {
            title: t('roomBooking.splitReceipt.billToTab.issueHistoryTable.createDate'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => dayjs(createdAt).fmYYYYMMDDHHmm(),
            className: 'column-created-at',
        },
        {
            title: t('roomBooking.splitReceipt.billToTab.issueHistoryTable.number'),
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: t('roomBooking.splitReceipt.billToTab.issueHistoryTable.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('roomBooking.splitReceipt.billToTab.issueHistoryTable.total'),
            dataIndex: 'total',
            key: 'total',
            width: '15%',
            render: (total) => currencyFormat(total),
        },
        {
            dataIndex: 'fileId',
            key: 'fileId',
            align: 'center',
            render: (fileId) => (
                <Button
                    type="link"
                    onClick={async () => {
                        const url = await fileService.getPresignedUrl(fileId);
                        window.open(url.data);
                    }}
                >
                    {t(
                        'roomBooking.splitReceipt.billToTab.issueHistoryTable.downloadPdf',
                    )}
                </Button>
            ),
        },
    ];

    return (
        <Modal
            open={isOpen}
            onCancel={onCancel}
            title={pathname.endsWith(INVOICE_PATH) ? t('roomBooking.splitReceipt.billToTab.invoiceIssueHistory') : t('roomBooking.splitReceipt.billToTab.issueHistory')}
            centered
            width={1024}
            footer={false}
            className="receipt-history-modal"
        >
            <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                scroll={{ y: 560 }}
                rowKey="id"
                className="receipt-history-table"
                rowClassName="rows"
                locale={{
                    triggerDesc: t('dashboard.sort.desc'),
                    triggerAsc: t('dashboard.sort.asc'),
                    cancelSort: t('dashboard.sort.cancel'),
                }}
                loading={isLoading}
            />
        </Modal>
    );
};
