import { ReloadOutlined } from '@ant-design/icons';
import { Pagination, TableProps } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { parseDate } from '~plugins/dayjs';
import { cloneDeep, orderBy } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile, exportCSVFile } from '~common/commonFunctions';
import { AntdOrderDirection, CsvOption, OrderDirection } from '~common/constants';
import { ISorter } from '~common/interfaces';
import { CsvDropdown, TextTruncate } from '~components';
import {
    exportColumns,
    EXPORT_CSV_FILE_NAME,
    EXPORT_CSV_FILE_NAME_CSV,
    HistoryColumn,
    HistoryOrderBy,
    i18ExportKey,
    initHistoryListQuery,
} from '~features/bo-operation-history/constants';
import {
    IHistory,
    IHistoryExportCsvQuery,
} from '~features/bo-operation-history/interfaces';
import {
    fetchHistoryList,
    historyListQuerySelector,
    historyListSelector,
    selectedRowKeysSelector,
    setHistoryListQuery,
    setSelectedHistoryList,
    setHistoryList,
    setSelectedRowKeys,
    showLoadingListSelector,
    totalHistoriesSelector,
    totalPageSelector,
} from '~features/bo-operation-history/reducers/history-list.reducer';
import { boHistoryService } from '~features/bo-operation-history/services/boHistory.service';
import { useAppDispatch, useAppSelector } from '~hooks';
import './HistoryList.scss';

type sortType = boolean | 'asc' | 'desc';

interface ICSVData {
    createdAt: string;
    picName: string;
    actionTitle: string;
}

function HistoryList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedRowKeys = useAppSelector(selectedRowKeysSelector);
    const historyListQuery = useAppSelector(historyListQuerySelector);
    const historyList = useAppSelector(historyListSelector);
    const pageCount = useAppSelector(totalPageSelector);
    const totalHistories = useAppSelector(totalHistoriesSelector);
    const showLoadingList = useAppSelector(showLoadingListSelector);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        dispatch(setSelectedRowKeys(selectedRowKeys));
    };

    useEffect(() => {
        return () => {
            dispatch(setHistoryListQuery(cloneDeep(initHistoryListQuery)));
        };
    }, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const histories: IHistory[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const history = historyList.find((history) => history.id === selectedRowKey);
            if (history) histories.push(history);
        });
        return histories;
    }, [selectedRowKeys]);

    useEffect(() => {
        dispatch(setSelectedHistoryList(selectedRows));
    }, [selectedRows]);

    const exportCsv = async (query: IHistoryExportCsvQuery) => {
        const response = await boHistoryService.exportCsv(query);
        if (response.success) {
            downloadFile(EXPORT_CSV_FILE_NAME_CSV, response.data.filePath);
        }
    };

    const sortDataToCsv = (datas: IHistory[]) => {
        const newDatas = datas.sort(function (data1, data2) {
            return data2.id - data1.id;
        });
        return newDatas;
    };

    const convertDataToCsv = (histories: IHistory[]) => {
        const dataOutput: ICSVData[] = [];
        const newHistories = sortDataToCsv(cloneDeep(histories));
        newHistories.forEach((history) => {
            const data = {
                createdAt: parseDate(history.createdAt)?.fmYYYYMMDDHHmm(),
                picName: history.createdByEmail,
                actionTitle: t(
                    `boHistory.list.activityTitle.${history.action}${history.targetObjectKey}`,
                ),
            };
            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['createdAt'], ['desc']);
    };

    const exportHistoryList = async (histories: IHistory[]) => {
        // Create header file csv
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        // Convert to file csv
        const historyDataCsvs = convertDataToCsv(histories);
        await exportCSVFile(exportColumns, filename, historyDataCsvs, i18ExportKey);
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all room
                await exportCsv({
                    keyword: historyListQuery.keyword,
                    picName: historyListQuery.picName,
                    orderBy: historyListQuery.orderBy,
                    orderDirection: historyListQuery.orderDirection,
                    action: historyListQuery.action,
                    targetObjectKey: historyListQuery.targetObjectKey,
                    type: historyListQuery.type,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                exportHistoryList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                break;
            }
            default:
                break;
        }
    };

    const onChange: TableProps<IHistory>['onChange'] = (pagination, filters, sorter) => {
        // this is for pagination, sort, filter logic later
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setHistoryListQuery({
                    ...historyListQuery,
                    orderBy: HistoryOrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;

            // sort by activityTitle
            if (_field === HistoryColumn.ACTIVITY_TITLE) {
                const sortedHistoryList = orderBy(
                    historyList,
                    ['description.header.activityType'],
                    [_order.toLowerCase() as sortType],
                );
                dispatch(setHistoryList(sortedHistoryList));
            } else {
                dispatch(
                    setHistoryListQuery({
                        ...historyListQuery,
                        orderBy: _field,
                        orderDirection: _order,
                    }),
                );
            }
        }
    };

    const onChangePage = (page: number) => {
        dispatch(setHistoryListQuery({ ...historyListQuery, page }));
        dispatch(setSelectedHistoryList([]));
        dispatch(setSelectedRowKeys([]));
    };

    const fetchData = () => {
        dispatch(fetchHistoryList());
    };

    useEffect(() => {
        if (
            historyList.length === 0 &&
            historyListQuery?.page &&
            historyListQuery?.page > 1
        ) {
            const page = historyListQuery?.page - 1;
            dispatch(setHistoryListQuery({ ...historyListQuery, page }));
        }
    }, [historyList]);

    useEffect(() => {
        fetchData();
        dispatch(setSelectedRowKeys([]));
    }, [historyListQuery]);

    const onRefresh = () => {
        dispatch(setSelectedRowKeys([]));
        fetchData();
    };

    const historyColumns: ColumnsType<IHistory> = [
        {
            title: t('boHistory.list.columns.createdAt'),
            onCell: () => {
                return {
                    style: { color: '#1c3e86' },
                };
            },
            dataIndex: HistoryColumn.CREATED_AT,
            key: HistoryColumn.CREATED_AT,
            render: (date: string) => {
                return parseDate(date)?.fmYYYYMMDDHHmm();
            },
            sorter: true,
            width: '136px',
        },
        {
            title: t('boHistory.list.columns.picName'),
            key: HistoryColumn.CREATED_BY_EMAIL,
            sorter: true,
            render: (history: IHistory) => (
                <span>
                    <TextTruncate text={history.createdByEmail} />
                </span>
            ),
        },
        {
            title: t('boHistory.list.columns.activityTitle'),
            key: HistoryColumn.ACTIVITY_TITLE,
            render: (history: IHistory) => {
                return (
                    <span>
                        <TextTruncate
                            text={t(
                                `boHistory.list.activityTitle.${history.action}${history.targetObjectKey}`,
                            )}
                        />
                    </span>
                );
            },
            sorter: true,
        },
    ];

    return (
        <div className="bo-history-list-wrapper">
            <div className="history-list-header">
                {selectedRowKeys?.length > 0 && <div className="list-header-left"></div>}

                <div className="list-header-right">
                    <CsvDropdown
                        onChange={onChangeCsvOption}
                        hasSelectedColumns={selectedRows.length > 0}
                        hasImportOption={false}
                    />
                    <ReloadOutlined className="size-icon" onClick={onRefresh} />
                </div>
            </div>

            <Table
                rowSelection={rowSelection}
                columns={historyColumns}
                dataSource={historyList}
                onChange={onChange}
                pagination={false}
                rowKey="id"
                loading={showLoadingList}
                scroll={{ y: '500px' }}
            />

            {pageCount > 1 && (
                <Pagination
                    align="center"
                    className="history-pagination"
                    defaultCurrent={historyListQuery.page}
                    current={historyListQuery.page}
                    total={totalHistories}
                    pageSize={historyListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default HistoryList;
