import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { ITagDropDownQuery, ITagListDropdownResponse } from '../interfaces';

class TagService extends ApiService {
    getDropdown(params: ITagDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<ITagListDropdownResponse>;
    }
}

export const tagService = new TagService({ baseUrl: '/tag' }, axiosService);
