import { IUpdateMemoBookingBody } from './../../room-booking/interfaces';
import {
    IBookingsResponse,
    ICalculatedAmountResponse,
    IFacilityBookingCheckIn,
    IFacilityBookingGroupByFacilityTypeResponse,
    IFacilityBookingReceiptResponse,
    IFacilityBookingStatisticTypeResponse,
    IGetCalculatedAmountQuery,
    IPaymentMethodListDropdownResponse,
    IUpdateFacilityBookingReceipt,
} from './../interfaces';
import {
    IFacilityBookingCreateBody,
    IFacilityBookingStatisticResponse,
    IFacilityBookingUpdate,
    IUpdateFacilityBookingStatus,
} from '../interfaces';
import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import {
    IBodyResponse,
    ICommonListQuery,
    ICsvFile,
    IGetListResponse,
} from '~common/interfaces';
import { IFacilityBooking, IFacilityBookingExportCsvQuery } from '../interfaces';
class FacilityBookingService extends ApiService {
    getList(queryString: ICommonListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IFacilityBooking>>
        >;
    }

    getDetail(id: number) {
        return this._getDetail(id) as Promise<IBodyResponse<IFacilityBooking>>;
    }

    getReceipt(id: number) {
        return this._get(`${this.baseUrl}/${id}/receipt`) as Promise<
            IBodyResponse<IFacilityBookingReceiptResponse>
        >;
    }

    bulkDelete(ids: number[]) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: { ids },
        }) as Promise<IBodyResponse<boolean>>;
    }

    exportCsv(query: IFacilityBookingExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }

    updateFacilityBooking(updateFacilityBooking: IFacilityBookingUpdate) {
        return this._patch(
            `${this.baseUrl}/${updateFacilityBooking.id}`,
            updateFacilityBooking.body,
        ) as Promise<IBodyResponse<IFacilityBooking>>;
    }

    updateFacilityBookingStatus(body: IUpdateFacilityBookingStatus) {
        return this._patch(`${this.baseUrl}/status`, body) as Promise<
            IBodyResponse<boolean>
        >;
    }

    updateFacilityBookingFacility(id: number, facilityId: number) {
        return this._patch(`${this.baseUrl}/${id}/facility-id`, {
            facilityId,
        }) as Promise<IBodyResponse<boolean>>;
    }

    getFacilityBookingList(startDate: string, endDate: string) {
        return this._get(`${this.baseUrl}/group-by-facility-type`, {
            params: {
                startDate,
                endDate,
            },
        }) as Promise<IBodyResponse<IFacilityBookingGroupByFacilityTypeResponse>>;
    }

    getFacilityBookingStatisticByDate(startDate: string, endDate: string) {
        return this._get(`${this.baseUrl}/statistic/by-date`, {
            params: {
                startDate,
                endDate,
            },
        }) as Promise<IBodyResponse<IFacilityBookingStatisticResponse>>;
    }

    getFacilityBookingStatisticType(startDate: string, endDate: string) {
        return this._get(`${this.baseUrl}/statistic/by-facility-type`, {
            params: {
                startDate,
                endDate,
            },
        }) as Promise<IBodyResponse<IFacilityBookingStatisticTypeResponse>>;
    }

    getBookings(startDate: string, endDate: string) {
        return this._get(`${this.baseUrl}/schedule`, {
            params: {
                startDate,
                endDate,
            },
        }) as Promise<IBodyResponse<IBookingsResponse[]>>;
    }

    createFacilityBooking(body: IFacilityBookingCreateBody) {
        return this._post(this.baseUrl, body) as Promise<IBodyResponse<IFacilityBooking>>;
    }

    updateFacilityBookingReceipt(id: number, body: IUpdateFacilityBookingReceipt[]) {
        return this._patch(`${this.baseUrl}/receipt/${id}`, {
            items: body,
        }) as Promise<IBodyResponse<IUpdateFacilityBookingReceipt[]>>;
    }

    getPaymentMethodListForDropdown() {
        return this._get(
            `/payment-method/dropdown`,
        ) as Promise<IPaymentMethodListDropdownResponse>;
    }

    checkInFacilityBooking(id: number, body: IFacilityBookingCheckIn) {
        return this._patch(`${this.baseUrl}/${id}/check-in`, body) as Promise<
            IBodyResponse<boolean>
        >;
    }

    updateMemoBooking(updateBooking: IUpdateMemoBookingBody) {
        return this._patch(`${this.baseUrl}/${updateBooking.id}/memo`, {
            ...updateBooking.body,
        }) as Promise<IBodyResponse<boolean>>;
    }

    getCalculatedAmount(query: IGetCalculatedAmountQuery) {
        return this._get(`${this.baseUrl}/calculate-amount`, {
            params: {
                ...query,
            },
        }) as Promise<IBodyResponse<ICalculatedAmountResponse>>;
    }

    bulkDeleteReceiptItems(ids: number[]) {
        return this._delete('/bulk-delete/receipt-item-detail', {
            data: { ids },
        }) as Promise<IBodyResponse<boolean>>;
    }
}
export const facilityBookingService = new FacilityBookingService(
    { baseUrl: '/facility-booking' },
    axiosService,
);
