const guestDetail = {
    title: 'Guest Information Details',
    breadcrumbs: {
        guestDetail: 'Guest Information Details',
    },
    tabPane: {
        basicInfo: 'Basic Information',
        companyInfo: 'Company Information',
        bookingHistory: 'History',
    },
    editButton: 'Edit',
    label: {
        fullName: 'Full name',
        yomigana: 'Yomigana',
        createdAt: 'Created Date',
        createdBy: 'Created By',
        mobilePhoneNumber: 'Phone Number',
        emailAddress: 'Email Address',
        gender: 'Gender',
        country: 'Nationality',
        postalCode: 'Postal code',
        address: 'Address',
        classification: 'Guest type',
        birthday: 'Date of Birth',
        memo: 'Memo',
        identificationCard: 'Attachment',
        companyName: 'Company Name',
        companyYomigana: 'Company Name (Yomigana)',
        companyEmailAddress: 'Email Address',
        companyPhoneNumber: 'Phone Number',
        companyPostalCode: 'Postal Code',
        companyAddress: 'Address',
        companyWebsite: 'Website',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'Booking ID',
        roomName: 'Room Name',
        facilityName: 'Facility Name',
        checkInTime: 'C/I Time',
        checkOutTime: 'C/O Time',
    },
    classification: {
        vip: 'VIP',
        regular: 'Regular',
        beCareful: 'Be Careful',
    },
    gender: {
        male: 'Male',
        female: 'Female',
        other: 'Other',
    },
    roomBookingHistory: {
        headerTitle: 'Room booking history',
        column: {
            autoGeneratedCode: 'Room booking ID',
            yomigana: 'Yomigana',
            fullName: 'Full Name',
            roomName: 'Room Name',
            checkInTime: 'C/I Time',
            checkOutTime: 'C/O Time',
        },
        exportColumns: {
            autoGeneratedCode: 'Booking ID',
            fullName: 'Full Name',
            yomigana: 'Yomigana',
            roomName: 'Room Name',
            checkInTime: 'C/I Time',
            checkOutTime: 'C/O Time',
        },
    },
    facilityBookingHistory: {
        headerTitle: 'Facility Booking History',
        column: {
            autoGeneratedCode: 'Booking ID',
            yomigana: 'Yomigana',
            fullName: 'Full Name',
            facilityName: 'Facility Name',
            checkInTime: 'C/I Time',
            checkOutTime: 'C/O Time',
        },
        exportColumns: {
            autoGeneratedCode: 'Booking ID',
            fullName: 'Full Name',
            yomigana: 'Yomigana',
            facilityName: 'Facility Name',
            checkInTime: 'C/I Time',
            checkOutTime: 'C/O Time',
        },
    },
};

export default guestDetail;
