import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, notification, Row } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageType } from '~common/constants';
import { showConfirm } from '~common/notification';
import { useEscape } from '~common/useHooks';
import { InputText, RightDrawerLayout } from '~components';
import { PriceType } from '~features/children-type/constants';
import { getChildrenTypeFormId } from '~features/children-type/helper';
import { IChildrenType, IChildrenTypeFormBody } from '~features/children-type/interfaces';
import {
    createChildrenType,
    fetchChildrenTypeList,
    formBusySelector,
    selectedChildrenTypeListSelector,
    setIsShowForm,
    setSelectedChildrenTypeList,
    showFormSelector,
    updateChildrenType,
} from '~features/children-type/reducers/children-type.reducer';
import { childrenTypeSchema } from '~features/children-type/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './ChildrenTypeForm.scss';
import InputPriceType from './InputPriceType/InputPriceType';

function ChildrenTypeForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowForm = useAppSelector(showFormSelector);
    const formBusy = useAppSelector(formBusySelector);
    const selectedChildrenType =
        useAppSelector(selectedChildrenTypeListSelector)?.[0] || null;
    const { control, reset, handleSubmit, setError, formState, getValues, trigger } =
        useForm({
            resolver: yupResolver(childrenTypeSchema),
            defaultValues: useMemo(() => {
                return selectedChildrenType;
            }, [selectedChildrenType]),
        });

    const defaultChildrenType = {
        name: null,
        priceValue: null,
        priceType: PriceType.FIXED,
    } as unknown as IChildrenType;

    const closeDialog = (reload?: boolean) => {
        dispatch(setIsShowForm(false));
        dispatch(setSelectedChildrenTypeList([]));
        reset(defaultChildrenType);
        if (reload) {
            dispatch(fetchChildrenTypeList());
        }
    };

    useEffect(() => {
        reset(selectedChildrenType);
    }, [selectedChildrenType]);

    const _createChildrenType = useCallback(async (formData: IChildrenTypeFormBody) => {
        const response = await dispatch(createChildrenType(formData));

        if (createChildrenType.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('childrenType.form.createForm.create.success'),
                });
                closeDialog(true);
                return;
            }
            notification.error({
                message: t('childrenType.form.createForm.create.failed'),
                description: response.payload?.message,
            });

            (response.payload?.errors || []).forEach((error) => {
                if (
                    error.key === 'name' ||
                    error.key === 'priceType' ||
                    error.key === 'priceValue'
                ) {
                    setError(
                        error.key,
                        { type: ErrorMessageType.MANUAL, message: error?.message },
                        { shouldFocus: true },
                    );
                }
            });
        }
    }, []);

    const _updateChildrenType = useCallback(
        async (id: number, childrenType: IChildrenTypeFormBody) => {
            const response = await dispatch(
                updateChildrenType({ id, body: childrenType }),
            );
            if (updateChildrenType.fulfilled.match(response)) {
                if (response?.payload?.success) {
                    notification.success({
                        message: t('childrenType.form.updateForm.update.success'),
                    });
                    closeDialog(true);
                } else {
                    notification.error({
                        message: t('childrenType.form.updateForm.update.failed'),
                        description: response.payload?.message,
                    });
                    (response.payload?.errors || []).forEach((error) => {
                        if (
                            error.key === 'name' ||
                            error.key === 'priceType' ||
                            error.key === 'priceValue'
                        ) {
                            setError(
                                error.key,
                                {
                                    type: ErrorMessageType.MANUAL,
                                    message: error?.message,
                                },
                                { shouldFocus: true },
                            );
                        }
                    });
                }
            }
        },
        [],
    );

    const onSubmit = () => {
        handleSubmit((values) => {
            const { name, priceType, priceValue } = values;
            const childrenTypeFormBody: IChildrenTypeFormBody = {
                name,
                priceType,
                priceValue: Number(priceValue) || 0,
            };
            if (selectedChildrenType) {
                _updateChildrenType(selectedChildrenType.id, childrenTypeFormBody);
            } else {
                _createChildrenType(childrenTypeFormBody);
            }
        })();
    };

    const _showConfirm = () => {
        showConfirm({
            title: t('childrenType.form.confirm.cancelTitle'),
            cancelText: t('common.buttonCancelText'),
            okText: t('childrenType.form.confirm.buttonDeleteText'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    const formTitle = selectedChildrenType
        ? t('childrenType.form.updateForm.title')
        : t('childrenType.form.createForm.title');

    return (
        <RightDrawerLayout
            open={isShowForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={formTitle}
            cancelText={t('common.buttonCancelText')}
            submitText={t('common.buttonSaveText')}
            className="update-children-type-drawer"
            busy={formBusy}
            loading={formBusy}
            destroyOnClose={true}
            disabledSaveButton={!formState.isValid}
            submitId={getChildrenTypeFormId('btn-submit')}
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                <Row>
                    <Col span={24}>
                        <InputText
                            id={getChildrenTypeFormId('name')}
                            label={t('childrenType.form.childTypeName.label')}
                            required={true}
                            control={control}
                            defaultValue={selectedChildrenType?.name}
                            name="name"
                        />
                    </Col>
                    <Col span={24}>
                        <InputPriceType
                            label={t('childrenType.form.priceValue.label')}
                            required={true}
                            control={control}
                            defaultValue={selectedChildrenType?.priceValue}
                            defaultPriceType={selectedChildrenType?.priceType}
                            getValues={getValues}
                            trigger={trigger}
                        />
                    </Col>
                    {selectedChildrenType?.linkageCode && (
                        <Col span={24}>
                            <InputText
                                label={t('childrenType.form.linkageCode.label')}
                                disabled={true}
                                control={control}
                                name="linkageCode"
                                defaultValue={selectedChildrenType?.linkageCode}
                            />
                        </Col>
                    )}
                </Row>
            </Form>
        </RightDrawerLayout>
    );
}

export default ChildrenTypeForm;
