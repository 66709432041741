import { Col, Form, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { InputNumber, InputPhoneNumber, InputPostalCode, InputText } from '~components';
import { POSTAL_CODE_MAX_LENGTH } from '~features/guest/constants';
import { getGuestFormId } from '~features/guest/helper';
import './GuestCompanyInfoForm.scss';

interface IProp {
    control: any;
}
function GuestCompanyInfoForm(props: IProp) {
    const { Title } = Typography;
    const { control } = props;
    const { t } = useTranslation();
    return (
        <div className="company-info-form">
            <Title level={5} className="company-info-form-title">
                {t('guest.form.companyInfo.title')}
            </Title>
            <Form layout="vertical">
                <Row gutter={[24, 4]}>
                    <Col span={12}>
                        <InputText
                            label={t('guest.form.companyInfo.companyName.label')}
                            placeholder={t(
                                'guest.form.companyInfo.companyName.placeholder',
                            )}
                            name="companyName"
                            id={getGuestFormId('companyName')}
                            control={control}
                            maxLength={INPUT_TEXT_MAX_LENGTH}
                        />
                    </Col>
                    <Col span={12}>
                        <InputText
                            label={t('guest.form.companyInfo.companyYomigana.label')}
                            placeholder={t(
                                'guest.form.companyInfo.companyYomigana.placeholder',
                            )}
                            name="companyYomigana"
                            id={getGuestFormId('companyYomigana')}
                            control={control}
                            maxLength={INPUT_TEXT_MAX_LENGTH}
                        />
                    </Col>
                    <Col span={12}>
                        <InputPhoneNumber
                            label={t('guest.form.companyInfo.companyPhoneNumber.label')}
                            placeholder={t(
                                'guest.form.companyInfo.companyPhoneNumber.placeholder',
                            )}
                            name="companyPhoneNumber"
                            id={getGuestFormId('companyPhoneNumber')}
                            control={control}
                            allowClear
                        />
                    </Col>
                    <Col span={12}>
                        <InputText
                            label={t('guest.form.companyInfo.companyEmailAddress.label')}
                            placeholder={t(
                                'guest.form.companyInfo.companyEmailAddress.placeholder',
                            )}
                            name="companyEmailAddress"
                            id={getGuestFormId('companyEmailAddress')}
                            control={control}
                            maxLength={INPUT_TEXT_MAX_LENGTH}
                        />
                    </Col>
                    <Col span={12}>
                        <Row gutter={4}>
                            <Col span={6}>
                                <InputPostalCode
                                    label={t(
                                        'guest.form.companyInfo.companyPostalCode.label',
                                    )}
                                    placeholder={t(
                                        'guest.form.companyInfo.companyPostalCode.placeholder',
                                    )}
                                    name="companyPostalCode"
                                    id={getGuestFormId('companyPostalCode')}
                                    control={control}
                                />
                            </Col>
                            <Col span={18}>
                                <InputText
                                    label={t(
                                        'guest.form.companyInfo.companyAddress.label',
                                    )}
                                    placeholder={t(
                                        'guest.form.companyInfo.companyAddress.placeholder',
                                    )}
                                    id={getGuestFormId('companyAddress')}
                                    name="companyAddress"
                                    control={control}
                                    maxLength={INPUT_TEXT_MAX_LENGTH}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <InputText
                            label={t('guest.form.companyInfo.companyWebsite.label')}
                            placeholder={t(
                                'guest.form.companyInfo.companyWebsite.placeholder',
                            )}
                            name="companyWebsite"
                            id={getGuestFormId('companyWebsite')}
                            control={control}
                            maxLength={INPUT_TEXT_MAX_LENGTH}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default GuestCompanyInfoForm;
