import { Button, Form, Modal } from 'antd';
import { InputText, SingleSelect } from '~components';
import './OTACancelationRobotModal.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { otaAccountSettingSchema } from '~features/room-booking/schema';

type Prop = {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

function OTACancelationRobotModal({ isModalOpen, setIsModalOpen }: Prop) {
    const { t } = useTranslation();
    const { control, handleSubmit, formState } = useForm({
        resolver: yupResolver(otaAccountSettingSchema),
        mode: 'onChange',
    });
    const otaOptions = [
        {
            label: 'Rakuten Travel',
            value: 'rakuten',
        },
    ];

    const submitSetting = () => {
        handleSubmit(async (data) => {
            // eslint-disable-next-line no-console
            console.log(data);
            setIsModalOpen(false);
        })();
    };

    return (
        <Modal
            title={t('roomBooking.detail.otaCancelation.AdminSetting')}
            open={isModalOpen}
            centered
            onCancel={() => setIsModalOpen(false)}
            width={440}
            className="cancelation-modal"
            footer={[
                <Button
                    key="close"
                    onClick={() => setIsModalOpen(false)}
                    size="large"
                    className="cancelation-modal__action-btn"
                >
                    {t('roomBooking.detail.otaCancelation.close')}
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={submitSetting}
                    size="large"
                    disabled={!formState.isValid}
                    className="cancelation-modal__action-btn"
                >
                    {t('roomBooking.detail.otaCancelation.save')}
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <div className="cancelation-modal__wrapper">
                    <SingleSelect
                        label="OTA"
                        placeholder="OTA"
                        name="ota"
                        control={control}
                        defaultValue={otaOptions[0].value}
                        options={otaOptions}
                        required={true}
                    />
                    <InputText
                        label={t('roomBooking.detail.otaCancelation.account')}
                        placeholder={t('roomBooking.detail.otaCancelation.usernameEmail')}
                        autoComplete="one-time-code"
                        name="email"
                        control={control}
                        id="ota-cancelation-form-email"
                        required={true}
                    />
                    <InputText
                        label={t('roomBooking.detail.otaCancelation.password')}
                        placeholder={t('roomBooking.detail.otaCancelation.password')}
                        autoComplete="one-time-code"
                        name="password"
                        control={control}
                        type="password"
                        id="ota-cancelation-form-password"
                        required={true}
                    />
                </div>
            </Form>
        </Modal>
    );
}

export default OTACancelationRobotModal;
