import customDayjs, { parseTime } from '~plugins/dayjs';
import localStorageAuthService from '~common/authStorage';
import { FacilityBookingStatus } from './constants';
import { IFacilityBookingScheduleItem } from './interfaces';

export const getCheckInAndCheckOutHotel = () => {
    // get check in and check out time in hotel setting
    const pmsHotel = localStorageAuthService.getSelectedHotel();
    let checkInTime: string | null = null;
    let checkOutTime: string | null = null;
    let checkInDateTime: string | null = null;
    let checkOutDateTime: string | null = null;
    if (pmsHotel?.standardCheckInTime && pmsHotel?.standardCheckOutTime) {
        checkInTime = pmsHotel?.standardCheckInTime;
        checkOutTime = pmsHotel?.standardCheckOutTime;
        checkInDateTime = `${customDayjs()
            .add(1, 'day')
            ?.fmYYYYMMDD('-')} ${checkInTime}`;
        if (parseTime(checkInTime).isBefore(parseTime(checkOutTime))) {
            checkOutDateTime = `${customDayjs()
                .add(1, 'day')
                .fmYYYYMMDD('-')} ${checkOutTime}`;
        } else {
            checkOutDateTime = `${customDayjs()
                .add(2, 'day')
                .fmYYYYMMDD('-')} ${checkOutTime}`;
        }
    }
    return {
        checkInTime,
        checkOutTime,
        checkInDateTime,
        checkOutDateTime,
    };
};

export const bookingScheduleDefault = (): IFacilityBookingScheduleItem => {
    const now = customDayjs()
        .set(
            'hour',
            customDayjs().get('hours') + Math.ceil(customDayjs().get('minutes') / 60),
        )
        .set('minute', 0);
    return {
        id: 0,
        facilityId: 0,
        facilityKey: '',
        checkInTime: now.fmHHmm(),
        checkOutTime: now.clone().add(3, 'hours')?.fmHHmm(),
        checkInTimeWeekView: now.fmHHmm(),
        checkOutTimeWeekView: now.clone().add(3, 'hours')?.fmHHmm(),
        checkInDateTime: now.fmYYYYMMDDHHmm('-'),
        checkOutDateTime: now.clone().add(3, 'hours')?.fmYYYYMMDDHHmm('-'),
        status: FacilityBookingStatus.RESERVED,
        numberOfGuests: 0,
        date: '',
        facility: {
            id: 0,
            name: '',
        },
    };
};
