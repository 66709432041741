import yup from '~plugins/yup';
import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';

export const almexSettingSchema = yup.object().shape({
    keyType: yup.string().required(),
    keySystemId: yup.string().length(8).required(),
});

export const almexKioskTerminalBodySchema = yup.object().shape({
    name: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    code: yup.number().min(1).max(99).required(),
    enabled: yup.boolean().required(),
});
