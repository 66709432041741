import { axiosInstanceV2 } from "~plugins/axios";
import { ApiService } from "~plugins/axios/api";
import { IBodyResponse } from "~common/interfaces";
import { BankAccountCreateBody, BankAccountResponseBody, BankAccountUpdateBody } from "../interfaces";

class BankAccountServiceV2 extends ApiService {
    getBankAccounts() {
        return this._get(`${this.baseUrl}`) as Promise<IBodyResponse<BankAccountResponseBody[]>>;
    }

    createBankAccount(body: BankAccountCreateBody) {
        return this._post(`${this.baseUrl}`, body) as Promise<IBodyResponse<BankAccountResponseBody>>;
    }

    updateBankAccount(id: number, body: Partial<BankAccountCreateBody>) {
        return this._patch(`${this.baseUrl}/${id}`, body) as Promise<IBodyResponse<BankAccountResponseBody>>;
    }

    deleteBankAccount(id: number) {
        return this._delete(`${this.baseUrl}/${id}`) as Promise<IBodyResponse<BankAccountResponseBody>>;
    }
}

export const bankAccountServiceV2 = new BankAccountServiceV2(
    {
        baseUrl: '/bank-account',
    },
    axiosInstanceV2,
);
