import { Col, Row } from 'antd';
import { DebouncedFunc } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Checkbox, RangePicker } from '~components';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import { IRoomBookingItem } from '~features/room-booking/interfaces';
import customDayjs from '~plugins/dayjs';
import BookingTimeRow from './BookingTimeRow';
import ChangeStatusSelect from './ChangeStatusSelect/ChangeStatusSelect';
import NumberOfGuestRow from './NumberOfGuestRow';
import RoomRoomTypePlanRow from './RoomRoomTypePlanRow';
import { useMemo } from 'react';
import { checkUserPermission } from '~common/commonFunctions';
import { UserGroup } from '~common/constants';
import {
    Control,
    FieldValues,
    UseFormGetValues,
    UseFormSetError,
    UseFormSetValue,
} from 'react-hook-form';

type Props = {
    control: Control;
    setValue: UseFormSetValue<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setError: UseFormSetError<FieldValues>;
    bookingItem: IRoomBookingItem;
    isSingleBooking?: boolean;
    fetchRoomDropDown: DebouncedFunc<(roomBookingStayPeriod: string[]) => void>;
    isFromTll?: boolean;
    isFrozen?: boolean;
    closePopover: (visible: boolean, isReload: boolean) => void;
    onChangeChildrenList: (value: Record<string, number>) => void;
    onUpdateStatusSuccess?: (booking: IRoomBookingItem) => void;
};

function CardContent(props: Props) {
    const {
        isSingleBooking,
        control,
        setValue,
        getValues,
        setError,
        bookingItem,
        fetchRoomDropDown,
        isFromTll,
        isFrozen,
        closePopover,
        onChangeChildrenList,
        onUpdateStatusSuccess,
    } = props;
    const { t } = useTranslation();
    const isAdmin = useMemo(() => {
        return checkUserPermission([UserGroup.ADMIN]);
    }, []);
    const changeDateOfStayPeriod = (values: any, formatString: [string, string]) => {
        const { checkInTime, checkOutTime } = getValues();
        fetchRoomDropDown([
            `${formatString[0]} ${checkInTime.fmHHmmss()}`,
            `${formatString[1]} ${checkOutTime.fmHHmmss()}`,
        ]);
    };
    const canNotEditField = (status: RoomBookingItemBookingStatus[]) => {
        // if ADMIN user, there should be no disabled fields and buttons
        if (isAdmin) return false;

        return status.includes(bookingItem.bookingStatus as RoomBookingItemBookingStatus);
    };

    return (
        <div className="booking-card-content">
            <Row gutter={12}>
                {!isSingleBooking && (
                    <Col span={12}>
                        <Checkbox
                            label={t(
                                'roomBooking.detail.bookingItemCard.representativeRoom',
                            )}
                            name="isRepresentativeRoom"
                            control={control}
                            // isRepresentativeRoom checkbox is disabled when status is CHECKED_OUT or CANCELLED
                            // only for ADMIN user, the checkbox is always enabled regardless the status
                            disabled={canNotEditField([
                                RoomBookingItemBookingStatus.CHECKED_OUT,
                                RoomBookingItemBookingStatus.CANCELLED,
                            ])}
                        />
                    </Col>
                )}
                <Col span={12} offset={isSingleBooking ? 12 : 0}>
                    <ChangeStatusSelect
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        setError={setError}
                        bookingItem={bookingItem}
                        disabled={isFrozen}
                        closePopover={closePopover}
                        onUpdateStatusSuccess={onUpdateStatusSuccess}
                    />
                </Col>
            </Row>
            {/* date of stay period */}
            <Row>
                <Col span={24}>
                    <RangePicker
                        label={`${t(
                            'roomBooking.form.scheduleBooking.lengthOfStay',
                        )} (${t('common.standardTimeTitle')})`}
                        name="dateOfStayPeriod"
                        placeholder={[
                            t(
                                'roomBooking.detail.bookingItemCard.dateOfStayPeriod.placeholder.start',
                            ),
                            t(
                                'roomBooking.detail.bookingItemCard.dateOfStayPeriod.placeholder.end',
                            ),
                        ]}
                        allowClear={false}
                        // checkInDate field is disabled when status is CHECKED_IN, CHECKED_OUT, or CANCELLED
                        // checkOutDate field is disabled when status is CHECKED_OUT or CANCELLED
                        // only for ADMIN user, CI/CO fields are always enabled regardless the status
                        disabled={[
                            canNotEditField([
                                RoomBookingItemBookingStatus.CHECKED_IN,
                                RoomBookingItemBookingStatus.CHECKED_OUT,
                                RoomBookingItemBookingStatus.CANCELLED,
                            ]),
                            canNotEditField([
                                RoomBookingItemBookingStatus.CHECKED_OUT,
                                RoomBookingItemBookingStatus.CANCELLED,
                            ]),
                        ]}
                        control={control}
                        disabledDate={(current) => {
                            // Allow only ADMIN user to select any dates
                            if (isAdmin) return false;
                            // Can not select days before 2 days ago
                            return current.isBefore(
                                customDayjs().subtract(2, 'day'),
                                'day',
                            );
                        }}
                        onChange={changeDateOfStayPeriod}
                    />
                </Col>
            </Row>
            {/* check in and check out time */}
            <BookingTimeRow
                control={control}
                getValues={getValues}
                bookingItem={bookingItem}
                fetchRoomDropDown={fetchRoomDropDown}
            />
            <RoomRoomTypePlanRow
                control={control}
                setValue={setValue}
                bookingItem={bookingItem}
                isFromTll={isFromTll}
            />
            {/* number of adult and child */}
            <NumberOfGuestRow
                control={control}
                setValue={setValue}
                bookingItem={bookingItem}
                onChangeChildrenList={onChangeChildrenList}
            />
        </div>
    );
}

export default CardContent;
