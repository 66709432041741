import { IPrintRoomCleaning } from '~features/room-cleaning/interfaces';
import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';

import { IBodyResponse, IGetListResponse } from '~common/interfaces';
import {
    IRoomCleaningListQuery,
    IRoomCleaning,
    IRoomCleaningBulkUpdateFormItem,
    IPrintRoomCleaningListQuery,
} from '../interfaces';

class RoomCleaningService extends ApiService {
    getList(queryString: IRoomCleaningListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IRoomCleaning>>
        >;
    }

    bulkUpdate(bulkUpdateBody: IRoomCleaningBulkUpdateFormItem[]) {
        return this._patch(`${this.baseUrl}/cleaning-status`, {
            items: bulkUpdateBody,
        }) as Promise<IBodyResponse<IRoomCleaning>>;
    }

    dirtyAllRooms() {
        return this._patch(`${this.baseUrl}/dirty-all-room-of-hotel`, {}) as Promise<
            IBodyResponse<IRoomCleaning>
        >;
    }

    clearAllRoomMemos() {
        return this._patch(`${this.baseUrl}/clear-all-room-memo-of-hotel`, {}) as Promise<
            IBodyResponse<IRoomCleaning>
        >;
    }

    cleanAllRooms() {
        return this._patch(`${this.baseUrl}/clean-all-room-of-hotel`, {}) as Promise<
            IBodyResponse<IRoomCleaning>
        >;
    }

    getPrintRoomCleaningData(
        queryString: IPrintRoomCleaningListQuery,
    ): Promise<IBodyResponse<IPrintRoomCleaning[]>> {
        return this._get(`${this.baseUrl}/print-room-cleaning-status`, {
            params: queryString,
        });
    }
}

export const roomCleaningService = new RoomCleaningService(
    { baseUrl: '/room' },
    axiosService,
);
