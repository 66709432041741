import type { CSSProperties, FC } from 'react';
import { useDrop } from 'react-dnd';
import { IDropData, IRoomBookingSchedule } from '~features/room-booking/interfaces';

const style: CSSProperties = {
    height: '100%',
    position: 'absolute',
    zIndex: 2,
};

export interface DustbinProps {
    booking: IRoomBookingSchedule;
    roomId: number;
    roomTypeId: number;
    top: number;
    left: number;
    width: number;
    roomName: string;
    roomTypeName: string;
}

function selectBackgroundColor(isActive: boolean) {
    if (isActive) {
        return '#e8ecf3';
    } else {
        return 'rgb(255, 235, 230)';
    }
}

export const DropBox: FC<DustbinProps> = ({
    booking,
    roomId,
    roomTypeId,
    top,
    left,
    width,
    roomTypeName,
    roomName,
}) => {
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: 'box',
            drop: () =>
                ({
                    roomId,
                    roomTypeId,
                    roomTypeName,
                    roomName,
                } as IDropData),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [booking],
    );
    const backgroundColor = selectBackgroundColor(isOver);
    return (
        <div
            ref={drop}
            style={{
                ...style,
                backgroundColor,
                left,
                top,
                width,
                height: 55,
            }}
        ></div>
    );
};
