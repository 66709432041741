import { AxiosRequestConfig } from 'axios';
import { getToken } from '~plugins/amplify';
import { HttpMiddleware } from './httpMiddleware';

export default class AuthMiddleware extends HttpMiddleware {
    async onRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        const { accessToken, idToken } = await getToken();
        if (accessToken) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
                idToken: `${idToken}`,
            };
        }
        return config;
    }
}
