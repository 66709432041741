import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IChildrenState } from '../interfaces';
import { childrenService } from '../services/children.service';
import { AppState } from '~plugins/redux-toolkit/store';

const initialState: IChildrenState = {
    children: [],
    isFetchingChildrenDropdown: false,
};

export const getChildrenDropdown = createAsyncThunk(
    'children/getChildrenDropdown',
    async () => {
        return await childrenService.getDropdown();
    },
);

const childrenSlice = createSlice({
    name: 'children',
    initialState,
    reducers: {
        setChildren: (state, action) => {
            state.children = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getChildrenDropdown.pending, (state, action) => {
            state.isFetchingChildrenDropdown = true;
        });
        builder.addCase(getChildrenDropdown.fulfilled, (state, action) => {
            if (action.payload?.success) {
                state.children = action.payload?.data?.items || [];
            } else {
                state.children = [];
            }
            state.isFetchingChildrenDropdown = false;
        });
    },
});

export const { setChildren } = childrenSlice.actions;

export const childrenStateSelector = (state: AppState) => state.children;

export const childrenReducer = childrenSlice.reducer;
