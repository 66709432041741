import React, { HTMLProps } from 'react';
import { IColumnOption } from '~features/facility-booking/interfaces';

type Props = HTMLProps<HTMLDivElement> & {
    column: IColumnOption;
    today: string;
};

export const Header = ({ column, style }: Props) => {
    return (
        <div style={{ ...style }} className="header-item">
            <div className="header-item-hour">{column.label}</div>
        </div>
    );
};
