import { IUpdateMemoBookingBody } from './../../room-booking/interfaces';
import { IGetCalculatedAmountQuery } from './../interfaces';
import {
    IFacilityBookingCreateBody,
    IFacilityBookingUpdate,
    IUpdateFacilityBookingStatus,
    IUpdateFacilityBookingReceipt,
} from '../interfaces';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
} from '../../../common/constants';
import trim from 'lodash/trim';
import {
    IAdvanceSearchGetListQuery,
    IFacilityBookingListQuery,
    IFacilityBookingState,
} from '../interfaces';
import { facilityBookingService } from '../services/facility-booking.service';
import {
    FacilityBookingDetailPageTabPane,
    facilityBookingListQueryDefault,
    FacilityBookingSearchField,
    FacilityBookingStatus,
} from '../constants';
import { cloneDeep } from 'lodash';

const initialState: IFacilityBookingState = {
    facilityBookingListQuery: {
        ...facilityBookingListQueryDefault,
    },
    facilityBookingList: [],
    totalFacilityBookings: 0,
    showLoading: false,
    searchField: FacilityBookingSearchField.ALL,
    isShowAdvanceSearch: false,
    isShowAdvanceSearchTags: false,
    facilityBookingAdvanceSearchForm: null,
    advanceSearchListQuery: null,
    isShowCreateFacilityBookingForm: false,
    isShowUpdateFacilityBookingForm: false,
    isAdvanceSearch: false,
    selectedFacilityBookingDetail: null,
    facilityBookingReceipt: null,
    paymentMethodListForDropdown: [],
    isCalculatingAmount: false,
    activeDetailTabPane: FacilityBookingDetailPageTabPane.FACILITY_BOOKING_DETAIL,
    showPaymentModal: false,
    showPaymentModalLoading: false,
    showUpdateFacilityBookingLoading: false,
    isShowAccommodationCardPrintingModal: false,
    isShowBulkPaymentModal: false,
};

export const fetchFacilityBookingList = createAsyncThunk(
    'facilityBooking/fetchFacilityBookingList',
    async (query: IFacilityBookingListQuery | IAdvanceSearchGetListQuery) => {
        return await facilityBookingService.getList(query);
    },
);

export const getFacilityBookingDetail = createAsyncThunk(
    'facilityBooking/getFacilityBookingDetail',
    async (id: number) => {
        return await facilityBookingService.getDetail(id);
    },
);

export const getFacilityBookingReceipt = createAsyncThunk(
    'facilityBooking/getFacilityBookingReceipt',
    async (id: number) => {
        return await facilityBookingService.getReceipt(id);
    },
);

export const updateFacilityBooking = createAsyncThunk(
    'facilityBooking/updateFacilityBooking',
    async (updateFacilityBooking: IFacilityBookingUpdate) => {
        return await facilityBookingService.updateFacilityBooking(updateFacilityBooking);
    },
);

export const updateFacilityBookingStatus = createAsyncThunk(
    'room-booking/updateBookingItemStatus',
    async (body: IUpdateFacilityBookingStatus) => {
        return await facilityBookingService.updateFacilityBookingStatus(body);
    },
);

export const createFacilityBooking = createAsyncThunk(
    'room-booking/createFacilityBooking',
    async (body: IFacilityBookingCreateBody) => {
        return await facilityBookingService.createFacilityBooking(body);
    },
);

export const updateFacilityBookingReceipt = createAsyncThunk(
    'facilityBooking/updateFacilityBookingReceipt',
    async ({ id, body }: { id: number; body: IUpdateFacilityBookingReceipt[] }) => {
        return await facilityBookingService.updateFacilityBookingReceipt(id, body);
    },
);

export const getPaymentMethodListForDropdown = createAsyncThunk(
    'facilityBooking/getPaymentMethodListForDropdown',
    async () => {
        return await facilityBookingService.getPaymentMethodListForDropdown();
    },
);

export const getCalculatedAmount = createAsyncThunk(
    'facilityBooking/calculatedAmount',
    async (query: IGetCalculatedAmountQuery) => {
        return await facilityBookingService.getCalculatedAmount(query);
    },
);

export const bulkDeleteReceiptItems = createAsyncThunk(
    'facilityBooking/bulkDeleteReceiptItems',
    async (ids: number[]) => {
        return await facilityBookingService.bulkDeleteReceiptItems(ids);
    },
);

export const updateMemoBooking = createAsyncThunk(
    'facilityBooking/updateMemoBooking',
    async (body: IUpdateMemoBookingBody) => {
        return await facilityBookingService.updateMemoBooking(body);
    },
);

export const facilityBookingSlice = createSlice({
    name: 'facility-booking',
    initialState,
    reducers: {
        setFacilityBookingListQuery: (state, action) => {
            state.facilityBookingListQuery = action.payload;
        },
        changeBookingStatusQuery: (state, action) => {
            const selectedStatus = action.payload;
            let query;
            if (selectedStatus === FacilityBookingStatus.ALL) {
                query = { status: undefined, page: DEFAULT_FIRST_PAGE };
            } else {
                query = { status: [selectedStatus], page: DEFAULT_FIRST_PAGE };
            }

            // clear advance search
            state.isShowAdvanceSearch = false;
            if (state.isAdvanceSearch) {
                state.isShowAdvanceSearchTags = false;
                state.isAdvanceSearch = false;
                state.advanceSearchListQuery = null;
                state.facilityBookingAdvanceSearchForm = null;
                query = { ...facilityBookingListQueryDefault, ...query };
            } else {
                query = { ...state.facilityBookingListQuery, ...query };
            }
            state.facilityBookingListQuery = query;
        },
        setKeyword: (state, action) => {
            let keyword: string | string[] | undefined = trim(
                action.payload.keyword || '',
            );
            const status =
                action.payload.status === FacilityBookingStatus.ALL
                    ? undefined
                    : [action.payload.status];
            if (keyword) {
                keyword =
                    state.searchField === FacilityBookingSearchField.FACILITY_NAMES
                        ? [keyword]
                        : keyword;
            } else {
                keyword = undefined;
            }

            const query: IFacilityBookingListQuery = {
                limit: state.facilityBookingListQuery.limit,
                orderBy: state.facilityBookingListQuery.orderBy,
                orderDirection: state.facilityBookingListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || FacilityBookingSearchField.ALL]: keyword,
                status,
            };
            state.facilityBookingListQuery = query;
            // clear advance search
            state.isShowAdvanceSearch = false;
            if (state.isAdvanceSearch) {
                state.isShowAdvanceSearchTags = false;
                state.isAdvanceSearch = false;
                state.advanceSearchListQuery = null;
                state.facilityBookingAdvanceSearchForm = null;
            }
        },
        setFacilityBookingList: (state, action) => {
            state.facilityBookingList = action.payload;
        },
        setTotalFacilityBookings: (state, action) => {
            state.totalFacilityBookings = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
        },
        setIsShowAdvanceSearch: (state, action) => {
            state.isShowAdvanceSearch = action.payload;
        },
        setShowLoading: (state, action) => {
            state.showLoading = action.payload;
        },
        setIsShowAdvanceSearchTags: (state, action) => {
            state.isShowAdvanceSearchTags = action.payload;
        },
        setFacilityBookingAdvanceSearchForm: (state, action) => {
            state.facilityBookingAdvanceSearchForm = action.payload;
        },
        setAdvanceSearchListQuery: (state, action) => {
            state.advanceSearchListQuery = action.payload;
            state.facilityBookingListQuery = {
                ...facilityBookingListQueryDefault,
                keyword: undefined,
                ...action.payload,
            };
        },
        setIsShowUpdateFacilityBookingForm: (state, action) => {
            state.isShowUpdateFacilityBookingForm = action.payload;
        },
        setIsAdvanceSearch: (state, action) => {
            state.isAdvanceSearch = action.payload;
        },
        resetFacilityBookingState: () => {
            return initialState;
        },
        setActiveDetailTabPane: (state, action) => {
            state.activeDetailTabPane = action.payload;
        },
        clearListQuery: (state) => {
            state.isShowAdvanceSearchTags = false;
            state.isAdvanceSearch = false;
            state.advanceSearchListQuery = null;
            state.facilityBookingAdvanceSearchForm = null;
            state.facilityBookingListQuery = {
                ...facilityBookingListQueryDefault,
            };
        },
        setShowPaymentModal: (state, action) => {
            state.showPaymentModal = action.payload;
        },
        setIsShowAccommodationCardPrintingModal: (state, action) => {
            state.isShowAccommodationCardPrintingModal = action.payload;
        },
        setTotalAmount: (state, action) => {
            const _detail = cloneDeep(state.selectedFacilityBookingDetail);
            if (!_detail) return;
            _detail.totalAmount = action.payload;
            state.selectedFacilityBookingDetail = _detail;
        },
        setShowBulkPaymentModal: (state, action) => {
            state.isShowBulkPaymentModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFacilityBookingList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.facilityBookingList = action.payload?.data?.items || [];
            state.totalFacilityBookings = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchFacilityBookingList.pending, (state, action) => {
            state.showLoading = true;
            state.facilityBookingList = [];
        });
        builder.addCase(getFacilityBookingDetail.fulfilled, (state, action) => {
            state.showLoading = false;
            state.selectedFacilityBookingDetail = action.payload?.data || null;
        });
        builder.addCase(getFacilityBookingDetail.pending, (state, action) => {
            state.showLoading = true;
        });

        builder.addCase(getFacilityBookingReceipt.fulfilled, (state, action) => {
            state.showLoading = false;
            state.facilityBookingReceipt = action.payload?.data?.receipt || null;
        });
        builder.addCase(getFacilityBookingReceipt.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(getPaymentMethodListForDropdown.fulfilled, (state, action) => {
            state.paymentMethodListForDropdown = (action.payload?.data?.items || [])?.map(
                (item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                },
            );
        });
        builder.addCase(getCalculatedAmount.pending, (state, action) => {
            state.isCalculatingAmount = true;
        });
        builder.addCase(getCalculatedAmount.fulfilled, (state, action) => {
            state.isCalculatingAmount = false;
        });
        builder.addCase(updateFacilityBookingReceipt.pending, (state, action) => {
            state.showPaymentModalLoading = true;
            state.showLoading = true;
        });
        builder.addCase(updateFacilityBookingReceipt.fulfilled, (state, action) => {
            state.showPaymentModalLoading = false;
            state.showLoading = false;
        });
        builder.addCase(updateFacilityBooking.pending, (state, action) => {
            state.showUpdateFacilityBookingLoading = true;
        });
        builder.addCase(updateFacilityBooking.fulfilled, (state, action) => {
            state.showUpdateFacilityBookingLoading = false;
        });
        builder.addCase(bulkDeleteReceiptItems.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(bulkDeleteReceiptItems.fulfilled, (state, action) => {
            state.showLoading = false;
        });
    },
});

export const {
    setFacilityBookingListQuery,
    setTotalFacilityBookings,
    setFacilityBookingList,
    setKeyword,
    setSearchField,
    setIsShowAdvanceSearch,
    setShowLoading,
    setIsShowAdvanceSearchTags,
    setFacilityBookingAdvanceSearchForm,
    setAdvanceSearchListQuery,
    setIsShowUpdateFacilityBookingForm,
    setIsAdvanceSearch,
    resetFacilityBookingState,
    clearListQuery,
    changeBookingStatusQuery,
    setShowPaymentModal,
    setActiveDetailTabPane,
    setIsShowAccommodationCardPrintingModal,
    setTotalAmount,
    setShowBulkPaymentModal,
} = facilityBookingSlice.actions;

export const facilityBookingListStateSelector = (state: AppState) => {
    return state.facilityBooking;
};

export const facilityBookingListSelector = (state: AppState) => {
    return state.facilityBooking.facilityBookingList;
};
export const facilityBookingListQuerySelector = (state: AppState) => {
    return state.facilityBooking.facilityBookingListQuery;
};
export const totalFacilityBookingsSelector = (state: AppState) => {
    return state.facilityBooking.totalFacilityBookings;
};
export const showLoadingSelector = (state: AppState) => {
    return state.facilityBooking.showLoading;
};

export const facilityBookingSearchFieldSelector = (state: AppState) => {
    return state.facilityBooking.searchField;
};

export const isShowAdvanceSearchFormSelector = (state: AppState) => {
    return state.facilityBooking.isShowAdvanceSearch;
};

export const isShowAdvanceSearchTagsSelector = (state: AppState) => {
    return state.facilityBooking.isShowAdvanceSearchTags;
};

export const totalPageSelector = (state: AppState) => {
    const { totalFacilityBookings, facilityBookingListQuery } = state.facilityBooking;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = facilityBookingListQuery;
    return Math.ceil(totalFacilityBookings / limit);
};

export const facilityBookingAdvanceSearchFormSelector = (state: AppState) => {
    return state.facilityBooking.facilityBookingAdvanceSearchForm;
};

export const advanceSearchListQuerySelector = (state: AppState) => {
    return state.facilityBooking.advanceSearchListQuery;
};

export const isShowUpdateFacilityBookingForm = (state: AppState) => {
    return state.facilityBooking.isShowUpdateFacilityBookingForm;
};

export const selectedFacilityBookingDetailSelector = (state: AppState) => {
    return state.facilityBooking.selectedFacilityBookingDetail;
};

export const paymentMethodListForDropdownSelector = (state: AppState) => {
    return state.facilityBooking.paymentMethodListForDropdown;
};

export const facilityBookingReceiptSelector = (state: AppState) => {
    return state.facilityBooking.facilityBookingReceipt;
};

export const isCalculatingAmountSelector = (state: AppState) => {
    return state.facilityBooking.isCalculatingAmount;
};

export const showUpdateFacilityBookingLoadingSelector = (state: AppState) => {
    return state.facilityBooking.showUpdateFacilityBookingLoading;
};

export const showPaymentModalSelector = (state: AppState) => {
    return state.facilityBooking.showPaymentModal;
};

export const showPaymentModalLoadingSelector = (state: AppState) => {
    return state.facilityBooking.showPaymentModalLoading;
};

export const isShowAccommodationCardPrintingModalSelector = (state: AppState) => {
    return state.facilityBooking.isShowAccommodationCardPrintingModal;
};

export const showBulkPaymentModal = (state: AppState) => {
    return state.facilityBooking.isShowBulkPaymentModal;
};

export default facilityBookingSlice.reducer;
