import { createListenerMiddleware } from '@reduxjs/toolkit';
import { storage } from '~common/localStorage';
import { setCurrentLanguage } from '~common/reducers/app.reducer';
import i18next from '~plugins/i18next/i18n';

export const settingListenerMiddleware = createListenerMiddleware();

settingListenerMiddleware.startListening({
    actionCreator: setCurrentLanguage,
    effect: async (action) => {
        storage.set('currentLanguage', action.payload);
        i18next.changeLanguage(action.payload);
    },
});
