import { IHotel } from '~features/hotel/interfaces';
import { isStringify } from './commonFunctions';
import { ILoginUser } from './interfaces';
import { storage } from './localStorage';

export enum LOCAL_STORAGE_KEY {
    LOGIN_USER = 'loginUser',
    HOTEL_ID = 'hotelId',
}

class LocalStorageAuthService {
    // LOGIN USER
    setUser(user: null | ILoginUser): void {
        if (!user) {
            storage.set(LOCAL_STORAGE_KEY.LOGIN_USER, '');
        }
        if (!isStringify(user)) {
            return;
        }
        storage.set(LOCAL_STORAGE_KEY.LOGIN_USER, JSON.stringify(user));
    }
    getUser(): ILoginUser {
        return storage.getObjectFromKey(LOCAL_STORAGE_KEY.LOGIN_USER) as ILoginUser;
    }

    resetAll(): void {
        this.setUser(null);
        this.setHotelId(null);
    }

    // HotelId Access
    setHotelId(hotelId: null | number): void {
        if (!hotelId) {
            storage.set(LOCAL_STORAGE_KEY.HOTEL_ID, '');
        } else {
            storage.set(LOCAL_STORAGE_KEY.HOTEL_ID, hotelId.toString());
        }
    }

    getHotelId(): string {
        return storage.get(LOCAL_STORAGE_KEY.HOTEL_ID) || '';
    }

    getSelectedHotel(): IHotel | null {
        const hotelId = Number(this.getHotelId());
        if (hotelId) {
            return (
                (localStorageAuthService.getUser()?.pmsHotels || []).find(
                    (hotel) => hotel.id === hotelId,
                ) || null
            );
        }
        return null;
    }

    setHotel(hotel: IHotel) {
        if (!hotel) return;
        const user = this.getUser();
        const pmsHotel = user?.pmsHotels?.find((_pmsHotel) => {
            return _pmsHotel.id === hotel.id;
        });
        if (pmsHotel) {
            pmsHotel.name = hotel.name;
            pmsHotel.picName = hotel.picName;
            pmsHotel.postalCode = hotel.postalCode;
            pmsHotel.address = hotel.address;
            pmsHotel.picPhoneNumber = hotel.picPhoneNumber;
            pmsHotel.picEmailAddress = hotel.picEmailAddress;
            pmsHotel.numberOfRoom = hotel.numberOfRoom;
            pmsHotel.standardCheckInTime = hotel.standardCheckInTime;
            pmsHotel.standardCheckOutTime = hotel.standardCheckOutTime;
            pmsHotel.registeredNumber = hotel.registeredNumber;
            pmsHotel.isRegisteredNumberNotObtained = hotel.isRegisteredNumberNotObtained;
            pmsHotel.companyName = hotel.companyName;
            this.setUser(user);
        }
    }
}

const localStorageAuthService = new LocalStorageAuthService();
export default localStorageAuthService;
