import { Button, Radio } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputText, SingleSelect } from '~components';
import {
    IndicatorSize,
    IndicatorSizeOptions,
    IndicatorType,
} from '~features/indicator/constants';
import './IndicatorControl.scss';
import { SelectRoomOrFacility } from '../SelectRoomOrFacility/SelectRoomOrFacility';
import {
    indicatorSelector,
    setAssignedList,
    setCellSelected,
    setMultipleSelect,
    setSettingSize,
    setSettingType,
} from '~features/indicator/reducers/indicator.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { showConfirm } from '~common/notification';
import { cloneDeep } from 'lodash';

type Props = {
    control: any;
    setValue: any;
};

const getKeysFromPosition = (x: number, y: number, startX = 0, startY = 0) => {
    const validKeys: Record<string, boolean> = {};
    for (let i = startY; i < y; i++) {
        for (let j = startX; j < x; j++) {
            validKeys[`${i}-${j}`] = true;
        }
    }
    return validKeys;
};

export const IndicatorControl = ({ control, setValue }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { settingType, isMultipleSelect, settingSize, assignedList, cellSelected } =
        useAppSelector(indicatorSelector);
    const reassignment = (x: number, y: number) => {
        const validKeys = getKeysFromPosition(x, y);
        const newAssignedList = cloneDeep(assignedList);
        const newCellSelected = cloneDeep(cellSelected);
        for (const key in newAssignedList[settingType]) {
            if (!validKeys[key]) {
                delete newAssignedList[settingType][key];
            }
        }
        for (const key in newCellSelected[settingType]) {
            if (!validKeys[key]) {
                delete newCellSelected[settingType][key];
            }
        }
        dispatch(setAssignedList(newAssignedList));
        dispatch(setCellSelected(newCellSelected));
    };

    const needShowConfirm = (keys: Record<string, boolean>) => {
        for (const key in keys) {
            if (assignedList[settingType][key]) {
                return true;
            }
        }
        return false;
    };

    const onChangeSize = (value: IndicatorSize) => {
        const [oldX, oldY] = settingSize.split('x');
        const [newX, newY] = value.split('x');

        if (Number(oldX) > Number(newX)) {
            const rightKeys = getKeysFromPosition(+oldX, +oldY, +newX);
            const bottomKeys = getKeysFromPosition(+oldX, +oldY, 0, +newY);
            if (needShowConfirm({ ...rightKeys, ...bottomKeys })) {
                showConfirm({
                    title: t('indicator.create.changeSizeConfirm', { size: value }),
                    onOk: () => {
                        dispatch(setSettingSize(value));
                        reassignment(Number(newX), Number(newY));
                    },
                    onCancel: () => {
                        setValue('size', settingSize);
                    },
                });
                return;
            }
        }
        dispatch(setSettingSize(value));
    };

    const onChangeType = (value: IndicatorType) => {
        showConfirm({
            title: t('indicator.create.changeTypeConfirm', { type: value }),
            onOk: () => {
                const _newCellSelected = cloneDeep(cellSelected);
                _newCellSelected[value] = {};
                const _newAssignedList = cloneDeep(assignedList);
                _newAssignedList[value] = {};
                dispatch(setAssignedList(_newAssignedList));
                dispatch(setCellSelected(_newCellSelected));
                dispatch(setSettingType(value));
            },
        });
    };

    return (
        <div className="indicator-control-wrapper d-flex">
            <div className="control-left d-flex">
                <div className="d-flex mr-8">
                    <span className="indicator-name pt-5 pr-8">
                        {t('indicator.create.indicatorName')}:
                    </span>
                    <InputText
                        control={control}
                        name="indicatorName"
                        placeholder={t('indicator.create.indicatorName')}
                        label=""
                    />
                </div>
                <div>
                    <Radio.Group
                        options={Object.values(IndicatorType).map((item) => ({
                            label: t('indicator.create.type.' + item),
                            value: item,
                        }))}
                        value={settingType}
                        optionType="button"
                        onChange={(e) => {
                            onChangeType(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="control-right d-flex">
                <div className="d-flex pl-8">
                    <span className="indicator-name pt-5 pr-8">
                        {t('indicator.create.size')}:
                    </span>
                    <SingleSelect
                        control={control}
                        className="select-size"
                        name="size"
                        label=""
                        defaultValue={settingSize}
                        options={IndicatorSizeOptions}
                        showSearch={false}
                        onChange={onChangeSize}
                        isTriggerParentNode={true}
                    />
                </div>
                <Button
                    type="primary"
                    className="ml-8 mr-8"
                    icon={<SelectOutlined />}
                    onClick={() => {
                        if (isMultipleSelect) {
                            return;
                        }
                        dispatch(setMultipleSelect(true));
                    }}
                >
                    {t('indicator.create.selectMultipleBox')}
                </Button>
                <SelectRoomOrFacility />
            </div>
        </div>
    );
};
