import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import ja from 'dayjs/locale/ja';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { displayPlugin } from './format';

dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(displayPlugin);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.locale({ ...ja, weekStart: 1 });
dayjs.tz.setDefault('Asia/Tokyo');

const customDayjs = (...args: any[]) => {
    return dayjs(...args).tz();
};

const timezonedUnix = (value: number) => {
    return dayjs.unix(value).tz();
};

customDayjs.unix = timezonedUnix;

export const parseDate = (
    value: string | number | dayjs.Dayjs | Date | null | undefined,
) => {
    if (dayjs(value).isValid()) {
        if (typeof value === 'string' && /[ZT]/.test(value)) {
            return customDayjs(value);
        }
        return dayjs.tz(value);
    }
    return customDayjs();
};
export const parseTime = (value: string | null | undefined) => {
    const dateString = customDayjs().format('YYYY-MM-DD') + ' ' + value;
    if (dayjs(dateString).isValid()) {
        return dayjs.tz(dateString);
    }
    return customDayjs();
};
export const todayDayjs = customDayjs();
export default customDayjs;
export type Dayjs = dayjs.Dayjs;
