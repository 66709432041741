import React, { useMemo } from 'react';
import './SettingTable.scss';
import { indicatorSelector } from '~features/indicator/reducers/indicator.reducer';
import { useAppSelector } from '~hooks';
import classNames from 'classnames';
import { SettingCell } from '../SettingCell/SettingCell';

export const SettingTable = () => {
    const { settingSize } = useAppSelector(indicatorSelector);

    const columnLength = useMemo(() => {
        return Number(settingSize.split('x')[0]);
    }, [settingSize]);

    const rowLength = useMemo(() => {
        return Number(settingSize.split('x')[1]);
    }, [settingSize]);

    const renderContent = (params: { columnIndex: number; rowIndex: number }) => {
        const { columnIndex, rowIndex } = params;
        return (
            <div
                key={`${columnIndex}-${rowIndex}`}
                className={classNames({
                    'border-right': columnIndex !== columnLength - 1,
                    'border-bottom': rowIndex !== rowLength - 1,
                    'setting-table-cell': true,
                })}
                style={{
                    width: `calc(100% / ${columnLength})`,
                    paddingTop: `calc(100% / ${columnLength})`,
                }}
            >
                <SettingCell columnIndex={columnIndex} rowIndex={rowIndex} />
            </div>
        );
    };

    const generateTable = () => {
        const table = [];
        for (let i = 0; i < rowLength; i++) {
            const row = [];
            for (let j = 0; j < columnLength; j++) {
                row.push(
                    renderContent({
                        columnIndex: j,
                        rowIndex: i,
                    }),
                );
            }
            table.push(
                <div className="setting-table-row" key={i}>
                    {row}
                </div>,
            );
        }
        return table;
    };

    return (
        <div className="setting-table-wrapper">
            <div className="room-grid-view-content">{generateTable()}</div>
        </div>
    );
};
