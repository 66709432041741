import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import './IndicatorSettingPage.scss';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { Alert, Button, Layout, notification, Space, Spin } from 'antd';
import { IndicatorControl } from './components/IndicatorControl/IndicatorControl';
import { useAppDispatch, useAppSelector } from '~hooks';
import { getListForDropDown } from '~features/room/room.reducer';
import { SettingTable } from './components/SettingTable/SettingTable';
import {
    indicatorSelector,
    resetForm,
    createIndicator,
    getIndicatorDetail,
    updateIndicator,
    defaultCellSelected,
} from '~features/indicator/reducers/indicator.reducer';
import { useForm } from '~plugins/hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { createIndicatorSchemaResolver } from '~features/indicator/schema';
import {
    IIndicatorAlert,
    IIndicatorForm,
    IIndicatorItem,
    IRoomInfo,
} from '~features/indicator/interfaces';
import { IndicatorType } from '~features/indicator/constants';
import { fetchFacilityDropDown } from '~features/facility/facility.reducer';
import { cloneDeep, forEach } from 'lodash';
import { showConfirm } from '~common/notification';
import { checkUserPermission, compareFormData } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';

export const IndicatorSettingPage = () => {
    const { control, handleSubmit, setValue, getValues } = useForm({
        resolver: createIndicatorSchemaResolver,
    });
    const {
        settingSize,
        assignedList,
        isIndicatorSubmitting,
        isFetchingDetail,
        settingType,
    } = useAppSelector(indicatorSelector);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);
    const navigate = useNavigate();
    const { id } = useParams();
    const formDataDefault = useRef<IIndicatorForm>({} as IIndicatorForm);

    const title = useMemo(() => {
        return id ? t('indicator.create.updateTitle') : t('indicator.create.title');
    }, [id, t]);

    const breadcrumbs = useMemo(() => {
        return [
            {
                text: t('indicator.list.breadcrumbs.home'),
            },
            {
                text: t('indicator.list.breadcrumbs.indicator'),
            },
            {
                text: title,
            },
        ];
    }, [t, title]);

    useEffect(() => {
        dispatch(getListForDropDown({}));
        dispatch(fetchFacilityDropDown({}));
        formDataDefault.current = makeFormData({});
    }, []);

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(getIndicatorDetail(id));
        if (getIndicatorDetail.fulfilled.match(response)) {
            if (response.payload?.success) {
                const data = response.payload?.data;
                const _assignedList: Record<
                    string,
                    Record<string, IRoomInfo>
                > = cloneDeep(defaultCellSelected);
                data.indicatorItems?.forEach((item) => {
                    const isRoomType = !!item.roomId;
                    const type = isRoomType ? IndicatorType.ROOM : IndicatorType.FACILITY;
                    const _item = (isRoomType ? item.room : item.facility) as {
                        id: number;
                        name: string;
                    };
                    if (!_assignedList[type]) {
                        _assignedList[type] = {};
                    }
                    _assignedList[type][`${item.rowIndex}-${item.colIndex}`] = {
                        id: _item.id,
                        name: _item.name,
                        desc: '',
                        x: item.colIndex,
                        y: item.rowIndex,
                        type: type,
                        updateId: item.id,
                    };
                });
                dispatch(
                    resetForm({
                        settingSize: data.layout,
                        settingType: data.indicatorType,
                        assignedList: _assignedList,
                    }),
                );
                setValue('indicatorName', data.name);
                setValue('size', data.layout);
                setTimeout(() => {
                    formDataDefault.current = makeFormData(_assignedList);
                }, 0);
            }
        }
    }, []);

    useEffect(() => {
        if (id) {
            _getDetail(Number(id));
        }
    }, [id]);

    const _showConfirm = () => {
        if (compareFormData(makeFormData(assignedList), formDataDefault.current)) {
            onCancel();
            return;
        }

        showConfirm({
            title: t('roomType.form.confirm.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    const onCancel = () => {
        navigate('/front-settings/indicator');
        dispatch(resetForm({}));
    };

    const _createIndicator = async (formData: IIndicatorForm) => {
        const response = await dispatch(createIndicator(formData));
        if (createIndicator.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('indicator.create.createSuccess'),
                });
                onCancel();
            } else {
                const [error] = response.payload?.errors || [];
                if (error && error.message) {
                    notification.error({
                        message: error.message,
                    });
                } else {
                    notification.error({
                        message: t('common.somethingWentWrong'),
                    });
                }
            }
        } else {
            notification.error({
                message: t('common.somethingWentWrong'),
            });
        }
    };

    const _updateIndicator = async (formData: IIndicatorForm) => {
        const response = await dispatch(updateIndicator({ id: Number(id), formData }));
        if (updateIndicator.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('indicator.create.updateSuccess'),
                } as IIndicatorAlert);
                onCancel();
            } else {
                const [error] = response.payload?.errors || [];
                if (error && error.message) {
                    notification.error({
                        message: error.message,
                    });
                } else {
                    notification.error({
                        message: t('common.somethingWentWrong'),
                    });
                }
            }
        } else {
            notification.error({
                message: t('common.somethingWentWrong'),
            });
        }
    };

    const makeFormData = (assignedList: Record<string, Record<string, IRoomInfo>>) => {
        const indicatorItems: IIndicatorItem[] = [];
        forEach(assignedList, (list) => {
            forEach(list, (item) => {
                indicatorItems.push({
                    colIndex: item.x,
                    rowIndex: item.y,
                    roomId: item.type === IndicatorType.ROOM ? item.id : null,
                    facilityId: item.type === IndicatorType.FACILITY ? item.id : null,
                    id: item.updateId || undefined,
                });
            });
        });

        const formData: IIndicatorForm = {
            name: getValues('indicatorName'),
            indicatorType: IndicatorType.ROOM,
            layout: getValues('size'),
            indicatorItems: indicatorItems,
        };
        return formData;
    };

    const submit = () => {
        handleSubmit(async (data) => {
            const indicatorItems: IIndicatorItem[] = [];
            forEach(assignedList, (list) => {
                forEach(list, (item) => {
                    const mappedObject = {
                        colIndex: item.x,
                        rowIndex: item.y,
                        roomId: item.type === IndicatorType.ROOM ? item.id : null,
                        facilityId: item.type === IndicatorType.FACILITY ? item.id : null,
                        id: item.updateId || undefined,
                    };
                    if (settingType === IndicatorType.ROOM && mappedObject.roomId)
                        indicatorItems.push(mappedObject);
                    else if (
                        settingType === IndicatorType.FACILITY &&
                        mappedObject.facilityId
                    )
                        indicatorItems.push(mappedObject);
                });
            });
            const formData: IIndicatorForm = {
                name: data.indicatorName,
                indicatorType: settingType,
                layout: settingSize,
                indicatorItems: indicatorItems,
            };
            if (id) {
                _updateIndicator(formData);
            } else {
                _createIndicator(formData);
            }
        })();
    };

    useEffect(() => {
        return () => {
            dispatch(resetForm({}));
        };
    }, []);

    return (
        <div className="indicator-create-page">
            <StaffLayoutHeader breadcrumbs={breadcrumbs} title={title} />
            <Spin spinning={isFetchingDetail}>
                <Layout.Content>
                    <div className="indicator-create-page-wrapper">
                        <div className="indicator-create-page-content">
                            <IndicatorControl control={control} setValue={setValue} />
                            <div className="indicator-alert-wrapper">
                                <Alert
                                    message={t('indicator.create.helpText')}
                                    type="info"
                                    showIcon
                                />
                            </div>
                            <SettingTable />
                        </div>
                    </div>
                </Layout.Content>
                <Layout.Footer>
                    <Space className="d-flex j-end">
                        <Button onClick={_showConfirm}>
                            {t('indicator.create.cancel')}
                        </Button>
                        {!isReadOnly && (
                            <Button
                                type="primary"
                                onClick={submit}
                                loading={isIndicatorSubmitting}
                            >
                                {t('indicator.create.' + (id ? 'update' : 'submit'))}
                            </Button>
                        )}
                    </Space>
                </Layout.Footer>
            </Spin>
        </div>
    );
};
