import { FormSwitch, InputNumber, InputText } from '~components';
import { EditableCellProps } from '../../types';

export const EditableCell = ({
    children,
    control,
    initValue,
    inputType,
    isEditing,
    name,
    handleSwitchChange,
    placeholder,
}: EditableCellProps) => {
    const getInputNode = (type: string | undefined) => {
        switch (type) {
            case 'switch':
                return (
                    <FormSwitch
                        control={control}
                        name={name}
                        label=""
                        defaultValue={initValue as boolean}
                        defaultChecked={initValue as boolean}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        onChange={handleSwitchChange}
                    />
                );
            case 'number':
                return (
                    <InputNumber
                        label=""
                        placeholder={placeholder}
                        defaultValue={initValue as number}
                        name={name}
                        control={control}
                    />
                );
            default:
                return (
                    <InputText
                        label=""
                        placeholder={placeholder}
                        defaultValue={initValue as string}
                        name={name}
                        control={control}
                    />
                );
        }
    };
    return isEditing ? getInputNode(inputType) : <>{children}</>;
};
