const saleItemUpdate = {
    title: '科目の編集',
    form: {
        name: '科目名',
        saleGroup: '集計グループ',
        price: '料金',
        tax: '消費税区分',
        isShowInBooking: '手配リスト',
        isShowInBookingTitle: '手配リストに表示',
    },
    message: {
        nameDuplicated: 'セール項目名が重複しています',
        updateSuccess: 'セール項目が更新されました',
    },
    confirm: { cancelTitle: 'キャンセルしてもよろしいですか？' },
};

export default saleItemUpdate;
