import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';

import {
    INotification,
    INotificationGetListQuery,
    INotificationBulkDeleteItem,
    INotificationCreateBody,
    INotificationResponse,
    IUserNotificationTimes,
} from '../interfaces';

import { IBodyResponse, IGetListResponse } from '~common/interfaces';
import { IRoomBookingStaticItem } from '~features/room-booking/interfaces';

class NotificationsService extends ApiService {
    get notificationUrl() {
        return `/notification`;
    }

    get roomBookingUrl() {
        return '/room-booking';
    }

    getList(queryString: INotificationGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<INotification>>
        >;
    }

    getAdminNotificationList() {
        return this._get(`${this.baseUrl}/hotel`) as Promise<IBodyResponse<any>>;
    }

    getNotificationList() {
        return this._get(`${this.notificationUrl}/hotel`) as Promise<IBodyResponse<any>>;
    }

    getDetail(id: number) {
        return this._get(`${this.baseUrl}/${id}`) as Promise<
            IBodyResponse<INotification>
        >;
    }

    create(createBody: INotificationCreateBody) {
        return this._post<INotificationCreateBody, INotificationResponse>(
            this.baseUrl,
            createBody,
        );
    }

    update(id: number, updateBody: INotificationCreateBody) {
        return this._patch<INotificationCreateBody, INotificationResponse>(
            `${this.baseUrl}/${id}`,
            updateBody,
        );
    }

    bulkDelete(bulkDeleteBody: INotificationBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    updateReadNotificationTime(body: { type: string }) {
        return this._patch<{ type: string }, IUserNotificationTimes>(
            `${this.notificationUrl}/last-read-time`,
            body,
        );
    }

    getReadNotificationTime() {
        return this._get(`${this.notificationUrl}/last-read-time`) as Promise<
            IBodyResponse<IUserNotificationTimes[]>
        >;
    }

    getTemporaryBookingItemList() {
        return this._get(`${this.roomBookingUrl}/temporary-item`) as Promise<
            IBodyResponse<IRoomBookingStaticItem[]>
        >;
    }
}

export const notificationsService = new NotificationsService(
    { baseUrl: '/admin-notification' },
    axiosService,
);
