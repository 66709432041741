export const kioskAlmexIntegrationEn = {
    breadcrumbs: {
        home: 'Home',
        integrations: 'Integrations',
        almex: 'Almex',
    },
    notification: {
        success: 'Success',
        error: 'Error',
        update: {
            setting: {
                success: 'Update Almex setting successfully',
            },
        },
    },
    title: 'Almex',
    save: 'Save',
    ok: 'OK',
    cancel: 'Cancel',
    enabled: 'Enabled',
    disabled: 'Disabled',
    kioskAlmex: {
        roomKeyOptions: 'Room key options',
        roomKeySystemId: 'System ID',
        terminalName: 'Terminal name',
        terminalCode: 'Terminal code',
        terminalStatus: 'Status',
        addTerminal: 'Add terminal',
    },
    keyOptions: {
        none: 'None',
        miwa: 'MIWA IC',
        goal: 'GOAL IC',
        showa: 'SHOWA IC',
    },
    modal: {
        delete: {
            title: 'Are you sure to delete this item?',
        },
    },
};
