const facilityBookingListEn = {
    title: 'Facility Booking',
    breadcrumbs: {
        home: 'Home',
        facilityBooking: 'Facility booking',
        facilityManagement: 'Facility management',
        facilitySchedule: 'Facility schedule',
    },
    buttons: {
        detailSearch: 'Advanced search',
        createBooking: 'Create new booking',
        search: 'Search',
    },
    filterForm: {
        searchColumn: {
            keyword: 'All',
            autoGeneratedCode: 'Code',
            status: 'Status',
            guestFullName: 'Guest name',
            guestYomigana: 'Yomigana',
            guestPhoneNumber: 'Phone number',
            facilityNames: 'Facility name',
        },

        status: {
            label: 'Status:',
        },
    },
    bookingStatus: {
        all: 'All',
        reserved: 'Reserved',
        started: 'Started',
        finished: 'Finished',
        cancelled: 'Cancelled',
    },
    message: {
        deleteRoomTypesSuccess: 'Facility bookings have been deleted successfully',
        deleteTitle: 'Can not delete booking',
        contentNoCanDelete: 'Have a booking in started or reserved status',
    },
    dropdown: {
        title: 'Change status',
    },
    column: {
        autoGeneratedCode: 'Code',
        fullName: 'Full name',
        yomigana: 'Yomigana',
        mobilePhoneNumber: 'Phone number',
        facilityName: 'Facility name',
        status: 'Status',
        bookingDate: 'Booking date',
        startDatetime: 'Check in',
        endDatetime: 'Check out',
        roomBookingId: {
            title: 'Room booking',
            yes: 'Yes',
            no: 'No',
        },
        paymentStatus: {
            title: 'Payment',
            already: 'Paid',
            not: 'Unpaid',
        },
        paymentAmount: 'Amount',
        memo: 'Memo',
    },
    advanceSearchForm: {
        autoGeneratedCode: {
            label: 'Code',
            placeholder: 'Enter code',
        },
        memo: {
            label: 'OTA memo',
            placeholder: 'Enter memo',
        },
        status: {
            label: 'Status',
            placeholder: 'Select status',
        },
        guestYomigana: {
            label: 'Guest name (Yomigana)',
            placeholder: 'Enter guest name',
        },
        guestPhoneNumber: {
            label: 'Phone number',
            placeholder: 'Enter phone number',
        },
        checkInDateRanges: {
            label: 'Booking date',
            placeholder: 'Select booking date',
        },
        checkInTimeRanges: {
            label: 'Booking time',
            placeholder: {
                start: 'Start time',
                end: 'End time',
            },
        },
        usedBookingTypeOfGuest: {
            label: 'Guest type',
        },
        facilityIds: {
            label: 'Facility name',
            placeholder: 'Select facility name',
        },
        marketingChannels: {
            label: 'Marketing channel',
            placeholder: 'Select marketing channel',
        },
    },
    guestClassification: {
        all: 'All',
        guest: 'Guest',
        'non-guest': 'Non guest',
    },
    facilityBookingMarketingChannel: {
        walkIn: 'Walk in',
        phone: 'Phone',
    },
    statusPopConfirm: {
        title: 'Confirmation of status change',
        content: 'Is it okay to change status to「{{status}}」?',
        okText: 'Yes',
        cancelText: 'No',
    },
    statusModalConfirm: {
        success: 'Status has been changed to {{status}}.',
        error: 'Change booking status failed!',
        errorChangeStatus:
            'Cannot change status from {{beforeStatus}} to {{afterStatus}}',
        okText: 'Close',
        title: 'Status change',
    },
    exportColumns: {
        autoGeneratedCode: 'Booking number',
        yomigana: 'Yomigana',
        fullName: 'Full name',
        phone: 'Phone number',
        facilityName: 'Facility name',
        status: 'Stutus',
        bookingDate: 'Booking date',
        startDatetime: 'Start date time',
        endDatetime: 'End date time',
        roomBookingId: 'Room booking',
        paymentStatus: 'Payment status',
        totalAmount: 'Total',
        memo: 'Memo',
    },
    deleteConfirmDialog: {
        description: 'Do you want to delete this facility booking?',
        deleteButton: 'Delete',
        cancelButton: 'Cancel',
        deleteByStatusError: 'The following facility bookings could not be deleted',
        titleDelete: 'Error',
        closeButton: 'Close',
    },
};

export default facilityBookingListEn;
