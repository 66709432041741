import dayjs from 'dayjs';
import { ShowMonth } from '~features/report/constants';
import { ISalesByRoomTypesItem, ISalesByRoomTypesSummary } from './interfaces';

export const generateDailyRoomTypeData = (
    salesByCategoryList: ISalesByRoomTypesItem[],
    filterYear: number,
    filterMonth: number,
    date: number,
) => {
    const currentData = salesByCategoryList.filter((item: ISalesByRoomTypesItem) => {
        const startOfDate = dayjs(item.dateStart);
        const endOfDate = dayjs(item.dateEnd);
        const selectedDate = dayjs(`${filterYear}/${filterMonth}/${date}`);
        return dayjs(selectedDate).isBetween(startOfDate, endOfDate, 'day', '[]');
    });

    return currentData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByRoomTypesItem,
        ) => {
            const {
                roomType: { id: roomTypeId },
                current,
                previousYear,
            } = item;

            acc[`numberOfOccupiedRooms${roomTypeId}`] = {
                [`occupiedRooms${roomTypeId}`]: current?.totalOccupiedRoom,
                [`totalRooms${roomTypeId}`]: current?.totalAvailableRoom,
                [`lastYearOccupiedRooms${roomTypeId}`]: previousYear?.totalOccupiedRoom,
                [`lastYearTotalRooms${roomTypeId}`]: previousYear?.totalAvailableRoom,
            };
            acc[`adults${roomTypeId}`] = {
                current: current?.totalGuest,
                lastYear: previousYear?.totalGuest,
            };
            acc[`numberOfChildren${roomTypeId}`] = {
                current: current?.totalChild,
                lastYear: previousYear?.totalChild,
            };
            acc[`amount${roomTypeId}`] = {
                current: current?.totalRevenue,
                lastYear: previousYear?.totalRevenue,
            };
            acc[`averagePrice${roomTypeId}`] = {
                current: current?.avgRevenue,
                lastYear: previousYear?.avgRevenue,
            };
            acc[`occupancyRate${roomTypeId}`] = {
                current: current?.occupancyRate,
                lastYear: previousYear?.occupancyRate,
            };

            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );
};

const checkDatePeriod = (dateStart: string, dateEnd: string, filterDate: string) => {
    const startDate = dayjs(dateStart).month() + 1;
    const endDate = dayjs(dateEnd).month() + 1;
    const currentMonth = dayjs(filterDate).month() + 1;

    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;

    if (startDate === currentMonth && endDate === currentMonth) {
        return ShowMonth.CURRENT_MONTH;
    }
    if (startDate === previousMonth && endDate === previousMonth) {
        return ShowMonth.LAST_MONTH;
    }
    if (startDate === nextMonth && endDate === nextMonth) {
        return ShowMonth.NEXT_MONTH;
    }
};

export const generateMonthlyRoomTypeData = (
    salesByRoomTypes: ISalesByRoomTypesItem[],
    roomTypeId: number,
    filterDate: string,
) => {
    const roomTypeData = salesByRoomTypes.filter(
        (item: ISalesByRoomTypesItem) => item.roomType.id === roomTypeId,
    );

    const lastMonthData = roomTypeData.filter(
        (item: ISalesByRoomTypesItem) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.LAST_MONTH,
    );

    const lastMonthRoomTypeData = lastMonthData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByRoomTypesItem,
        ) => {
            const { current, previousYear } = item;
            acc['lastMonthNumberOfRooms'] = {
                occupiedRooms: current?.totalOccupiedRoom || 0,
                totalRooms: current?.totalAvailableRoom || 0,
                lastYearOccupiedRooms: previousYear?.totalOccupiedRoom || 0,
                lastYearTotalRooms: previousYear?.totalAvailableRoom || 0,
            };
            acc['lastMonthAdults'] = {
                current: current?.totalGuest || 0,
                lastYear: previousYear?.totalGuest || 0,
            };
            acc['lastMonthChildren'] = {
                current: current?.totalChild || 0,
                lastYear: previousYear?.totalChild || 0,
            };
            acc['lastMonthAmount'] = {
                current: current?.totalRevenue || 0,
                lastYear: previousYear?.totalRevenue || 0,
            };
            acc['lastMonthAveragePrice'] = {
                current: current?.avgRevenue || 0,
                lastYear: previousYear?.avgRevenue || 0,
            };
            acc['lastMonthOccupancyRate'] = {
                current: current?.occupancyRate || 0,
                lastYear: previousYear?.occupancyRate || 0,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    const currentMonthData = roomTypeData.filter(
        (item: ISalesByRoomTypesItem) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.CURRENT_MONTH,
    );
    const currentMonthRoomTypeData = currentMonthData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByRoomTypesItem,
        ) => {
            const { current, previousYear } = item;
            acc['currentMonthNumberOfRooms'] = {
                occupiedRooms: current?.totalOccupiedRoom || 0,
                totalRooms: current?.totalAvailableRoom || 0,
                lastYearOccupiedRooms: previousYear?.totalOccupiedRoom || 0,
                lastYearTotalRooms: previousYear?.totalAvailableRoom || 0,
            };
            acc['currentMonthAdults'] = {
                current: current?.totalGuest || 0,
                lastYear: previousYear?.totalGuest || 0,
            };
            acc['currentMonthChildren'] = {
                current: current?.totalChild || 0,
                lastYear: previousYear?.totalChild || 0,
            };
            acc['currentMonthAmount'] = {
                current: current?.totalRevenue || 0,
                lastYear: previousYear?.totalRevenue || 0,
            };
            acc['currentMonthAveragePrice'] = {
                current: current?.avgRevenue || 0,
                lastYear: previousYear?.avgRevenue || 0,
            };
            acc['currentMonthOccupancyRate'] = {
                current: current?.occupancyRate || 0,
                lastYear: previousYear?.occupancyRate || 0,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    const nextMonthData = roomTypeData.filter(
        (item) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.NEXT_MONTH,
    );
    const nextMonthRoomTypeData = nextMonthData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByRoomTypesItem,
        ) => {
            const { current, previousYear } = item;
            acc['nextMonthNumberOfRooms'] = {
                occupiedRooms: current?.totalOccupiedRoom || 0,
                totalRooms: current?.totalAvailableRoom || 0,
                lastYearOccupiedRooms: previousYear?.totalOccupiedRoom || 0,
                lastYearTotalRooms: previousYear?.totalAvailableRoom || 0,
            };
            acc['nextMonthAdults'] = {
                current: current?.totalGuest || 0,
                lastYear: previousYear?.totalGuest || 0,
            };
            acc['nextMonthChildren'] = {
                current: current?.totalChild || 0,
                lastYear: previousYear?.totalChild || 0,
            };
            acc['nextMonthAmount'] = {
                current: current?.totalRevenue || 0,
                lastYear: previousYear?.totalRevenue || 0,
            };
            acc['nextMonthAveragePrice'] = {
                current: current?.avgRevenue || 0,
                lastYear: previousYear?.avgRevenue || 0,
            };
            acc['nextMonthOccupancyRate'] = {
                current: current?.occupancyRate || 0,
                lastYear: previousYear?.occupancyRate || 0,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );
    return {
        ...lastMonthRoomTypeData,
        ...currentMonthRoomTypeData,
        ...nextMonthRoomTypeData,
    };
};

export const generateDailySummary = (
    salesByRoomTypesSummary: ISalesByRoomTypesSummary[],
) => {
    return salesByRoomTypesSummary.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByRoomTypesSummary,
        ) => {
            const { current, previousYear, roomType } = item;
            if (!roomType) return acc;
            acc[`numberOfOccupiedRooms${roomType.id}`] = {
                [`occupiedRooms${roomType.id}`]: current.totalOccupiedRoom,
                [`totalRooms${roomType.id}`]: current.totalAvailableRoom,
                [`lastYearOccupiedRooms${roomType.id}`]: previousYear.totalOccupiedRoom,
                [`lastYearTotalRooms${roomType.id}`]: previousYear.totalAvailableRoom,
            };
            acc[`adults${roomType.id}`] = {
                current: current.totalGuest,
                lastYear: previousYear.totalGuest,
            };
            acc[`numberOfChildren${roomType.id}`] = {
                current: current.totalChild,
                lastYear: previousYear.totalChild,
            };
            acc[`amount${roomType.id}`] = {
                current: current.totalRevenue,
                lastYear: previousYear.totalRevenue,
            };
            acc[`averagePrice${roomType.id}`] = {
                current: current.avgRevenue,
                lastYear: previousYear.avgRevenue,
            };
            acc[`occupancyRate${roomType.id}`] = {
                current: current.occupancyRate,
                lastYear: previousYear.occupancyRate,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );
};

export const convertToArray = (obj: any) => {
    return Object.entries(obj).map(([key, value]) => ({
        [key]: value as number,
    }));
};

export const generateMonthlySummary = (
    salesByRoomTypesSummary: ISalesByRoomTypesSummary[],
    filterDate: string,
) => {
    const summaryData: {
        [key: string]: {
            [key: string]: number;
        };
    } = {};
    const lastMonthData = salesByRoomTypesSummary.filter(
        (item: ISalesByRoomTypesSummary) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.LAST_MONTH,
    );
    const lastMonthSummaryData = lastMonthData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByRoomTypesSummary,
        ) => {
            const { current, previousYear } = item;
            acc['lastMonthNumberOfRooms'] = {
                ['occupiedRooms']: current.totalOccupiedRoom,
                ['totalRooms']: current.totalAvailableRoom,
                ['lastYearOccupiedRooms']: previousYear.totalOccupiedRoom,
                ['lastYearTotalRooms']: previousYear.totalAvailableRoom,
            };
            acc['lastMonthAdults'] = {
                current: current.totalGuest,
                lastYear: previousYear.totalGuest,
            };
            acc['lastMonthChildren'] = {
                current: current.totalChild,
                lastYear: previousYear.totalChild,
            };
            acc['lastMonthAmount'] = {
                current: current.totalRevenue,
                lastYear: previousYear.totalRevenue,
            };
            acc['lastMonthAveragePrice'] = {
                current: current.avgRevenue,
                lastYear: previousYear.avgRevenue,
            };
            acc['lastMonthOccupancyRate'] = {
                current: current.occupancyRate,
                lastYear: previousYear.occupancyRate,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    Object.assign(summaryData, lastMonthSummaryData);
    const currentMonthData = salesByRoomTypesSummary.filter(
        (item: ISalesByRoomTypesSummary) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.CURRENT_MONTH,
    );

    const currentMonthSummaryData = currentMonthData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByRoomTypesSummary,
        ) => {
            const { current, previousYear } = item;
            acc['currentMonthNumberOfRooms'] = {
                ['occupiedRooms']: current.totalOccupiedRoom,
                ['totalRooms']: current.totalAvailableRoom,
                ['lastYearOccupiedRooms']: previousYear.totalOccupiedRoom,
                ['lastYearTotalRooms']: previousYear.totalAvailableRoom,
            };
            acc['currentMonthAdults'] = {
                current: current.totalGuest,
                lastYear: previousYear.totalGuest,
            };
            acc['currentMonthChildren'] = {
                current: current.totalChild,
                lastYear: previousYear.totalChild,
            };
            acc['currentMonthAmount'] = {
                current: current.totalRevenue,
                lastYear: previousYear.totalRevenue,
            };
            acc['currentMonthAveragePrice'] = {
                current: current.avgRevenue,
                lastYear: previousYear.avgRevenue,
            };
            acc['currentMonthOccupancyRate'] = {
                current: current.occupancyRate,
                lastYear: previousYear.occupancyRate,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    Object.assign(summaryData, currentMonthSummaryData);

    const nextMonthData = salesByRoomTypesSummary.filter(
        (item: ISalesByRoomTypesSummary) =>
            checkDatePeriod(item.dateStart, item.dateEnd, filterDate) ===
            ShowMonth.NEXT_MONTH,
    );

    const nextMonthSummaryData = nextMonthData.reduce(
        (
            acc: { [key: string]: { [key: string]: number } },
            item: ISalesByRoomTypesSummary,
        ) => {
            const { current, previousYear } = item;
            acc['nextMonthNumberOfRooms'] = {
                ['occupiedRooms']: current.totalOccupiedRoom,
                ['totalRooms']: current.totalAvailableRoom,
                ['lastYearOccupiedRooms']: previousYear.totalOccupiedRoom,
                ['lastYearTotalRooms']: previousYear.totalAvailableRoom,
            };
            acc['nextMonthAdults'] = {
                current: current.totalGuest,
                lastYear: previousYear.totalGuest,
            };
            acc['nextMonthChildren'] = {
                current: current.totalChild,
                lastYear: previousYear.totalChild,
            };
            acc['nextMonthAmount'] = {
                current: current.totalRevenue,
                lastYear: previousYear.totalRevenue,
            };
            acc['nextMonthAveragePrice'] = {
                current: current.avgRevenue,
                lastYear: previousYear.avgRevenue,
            };
            acc['nextMonthOccupancyRate'] = {
                current: current.occupancyRate,
                lastYear: previousYear.occupancyRate,
            };
            return acc;
        },
        {} as { [key: string]: { [key: string]: number } },
    );

    Object.assign(summaryData, nextMonthSummaryData);
    return summaryData;
};
