import {
    AuthenticationDetails,
    CognitoAccessToken,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
    ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import dayjs from 'dayjs';
import { throttle } from 'lodash';
import { appConfig } from '~config/app';
import axiosService from '~plugins/axios';

export const getCognitoUserAndAuth = (username: string, password: string) => {
    const authenticationData = {
        Username: username,
        Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const poolData: ICognitoUserPoolData = {
        UserPoolId: appConfig.poolId, // Your user pool id here
        ClientId: appConfig.clientId, // Your client id here
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return {
        cognitoUser,
        authenticationDetails,
    };
};

export const amplifyLogin = async (username: string, password: string) => {
    const { cognitoUser, authenticationDetails } = getCognitoUserAndAuth(
        username,
        password,
    );
    return new Promise<{ accessToken: string; idToken: string }>((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                const accessToken = result.getAccessToken().getJwtToken();
                const idToken = result.getIdToken().getJwtToken();
                resolve({ accessToken, idToken });
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

export const getCurrentUser = () => {
    const userPool = new CognitoUserPool({
        UserPoolId: appConfig.poolId,
        ClientId: appConfig.clientId,
    });
    return userPool.getCurrentUser();
};

export const logoutSession = async () => {
    const cognitoUser = getCurrentUser();
    const session = await getSession();
    if (cognitoUser) {
        cognitoUser.signOut();
        const accessToken = session?.getAccessToken()?.getJwtToken() || '';
        const idToken = session?.getIdToken()?.getJwtToken() || '';
        if (!validateAccessToken(accessToken) || !idToken) return;
        await axiosService.post(
            '/auth/logout',
            {
                refreshToken: session?.getRefreshToken().getToken(),
            },
            {
                headers: {
                    Authorization: `Bearer ${session?.getAccessToken().getJwtToken()}`,
                    idToken: `${session?.getIdToken().getJwtToken()}`,
                },
            },
        );
    }
};

export const getSession = () => {
    return new Promise<CognitoUserSession | null>((resolve, reject) => {
        const user = getCurrentUser();
        if (!user) return resolve(null);
        user.getSession((err: Error | null, session: CognitoUserSession) => {
            if (err) {
                resolve(null);
            } else {
                resolve(session);
            }
        });
    });
};
const REFRESH_TOKEN_TIME_BUFFER_IN_SECOND =
    Number(process.env.REACT_APP_REFRESH_TOKEN_TIME_BUFFER_IN_SECOND) || 90; // second

const validateAccessToken = (accessToken: string) => {
    // check exist token
    if (!accessToken) {
        return false;
    }
    // check expired time of token
    const cognitoAccessToken = new CognitoAccessToken({ AccessToken: accessToken });
    const accessTokenExpiredTime = dayjs.unix(cognitoAccessToken.getExpiration()).tz();
    return (
        accessTokenExpiredTime.diff(dayjs().tz(), 'second') >=
        REFRESH_TOKEN_TIME_BUFFER_IN_SECOND
    );
};

export const getRefreshToken = async () => {
    return new Promise<string | null>(async (resolve, reject) => {
        const user = getCurrentUser();
        if (!user) return resolve(null);
        await user.getSession(async (err: Error | null, session: CognitoUserSession) => {
            if (err) {
                await logoutSession();
                reject(null);
            } else {
                if (session.isValid()) {
                    user.refreshSession(
                        session.getRefreshToken(),
                        (err: Error | null, session: CognitoUserSession) => {
                            if (err) {
                                reject(null);
                            } else {
                                const accessToken = session
                                    .getAccessToken()
                                    .getJwtToken();
                                if (validateAccessToken(accessToken)) {
                                    resolve(accessToken);
                                } else {
                                    reject(null);
                                }
                            }
                        },
                    );
                }
            }
        });
    });
};

export const refreshTokenThrottled = throttle(getRefreshToken, 10000, {
    trailing: false,
});

export const getToken = async () => {
    let session = await getSession();
    let accessToken: string | null | undefined = session?.getAccessToken()?.getJwtToken();
    let idToken: string | null | undefined = session?.getIdToken()?.getJwtToken();
    let refreshToken: string | null | undefined = session?.getRefreshToken()?.getToken();
    let isAccessTokenValid = validateAccessToken(accessToken || '');

    // check exist access token and id token
    if (!accessToken || !idToken || !refreshToken) {
        isAccessTokenValid = false;
        return { accessToken, idToken, isValidAccessToken: isAccessTokenValid };
    }

    // check if accessToken is expired
    let isAccessTokenExpired = false;
    const cognitoAccessToken = new CognitoAccessToken({ AccessToken: accessToken });
    const accessTokenExpiredTime = dayjs.unix(cognitoAccessToken.getExpiration()).tz();
    if (
        accessTokenExpiredTime.diff(dayjs().tz(), 'second') <
        REFRESH_TOKEN_TIME_BUFFER_IN_SECOND
    ) {
        isAccessTokenExpired = true;
    }
    // refresh token
    if (!session?.isValid() || isAccessTokenExpired) {
        try {
            accessToken = await refreshTokenThrottled();
            session = await getSession();
            idToken = session?.getIdToken().getJwtToken();
            isAccessTokenValid = true;
        } catch (error) {
            isAccessTokenValid = false;
        }
    }
    return { accessToken, idToken, isValidAccessToken: isAccessTokenValid };
};
