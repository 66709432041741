import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row, notification } from 'antd';
import {
    IMarketingChannelGroupBulkUpdateForm,
    IMarketingChannelGroupBulkUpdateFormItem,
} from '../../interfaces';
import { bulkUpdateMaketingChannelGroupsSchema } from '../../schema';
import {
    bulkUpdateMarketingChannelGroups,
    fetchMarketingChannelGroupList,
    isShowUpdateMarketingChannelGroupFormSelector,
    setIsShowUpdateMarketingChannelGroupForm,
    updateMarketingChannelGroupFormBusySelector,
    updateMarketingChannelGroupListSelector,
} from '../../marketing-channel-group.reducer';
import { ErrorMessageType } from '~common/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputText, RightDrawerLayout } from '~components';
import { showConfirm } from '~common/notification';
import { compareFormData, validateUniqueValue } from '~common/commonFunctions';
import { useEscape } from '~common/useHooks';
import './UpdateMarketingChannelGroupsForm.scss';

function UpdateMarketingChannelGroupsForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, handleSubmit, reset, setError, getValues } =
        useForm<IMarketingChannelGroupBulkUpdateForm>({
            resolver: yupResolver(bulkUpdateMaketingChannelGroupsSchema),
            defaultValues: {},
        });
    const isShowFormUpdate = useAppSelector(
        isShowUpdateMarketingChannelGroupFormSelector,
    );
    const formBusy = useAppSelector(updateMarketingChannelGroupFormBusySelector);
    const marketingChannelGroupList = useAppSelector(
        updateMarketingChannelGroupListSelector,
    );

    const closeDialog = () => {
        reset({});
        dispatch(setIsShowUpdateMarketingChannelGroupForm(false));
    };

    const _showConfirm = () => {
        if (compareFormData(getValues().items, marketingChannelGroupList)) {
            closeDialog();
            return;
        }
        showConfirm({
            title: t('marketingChannelGroup.form.confirm.cancelTitle'),
            cancelText: t('marketingChannelGroup.form.confirm.buttonCancelText'),
            okText: t('marketingChannelGroup.form.confirm.buttonDeleteText'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    const _bulkUpdateMarketingChannelGroups = useCallback(
        async (chanelGroups: IMarketingChannelGroupBulkUpdateFormItem[]) => {
            const response = await dispatch(
                bulkUpdateMarketingChannelGroups(
                    chanelGroups.map((channelGroup, index) => ({
                        id: channelGroup.id,
                        name: channelGroup.name,
                        order: index + 1,
                    })),
                ),
            );
            if (bulkUpdateMarketingChannelGroups.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t(
                            'marketingChannelGroup.form.update.updateSuccessMessage',
                        ),
                    });
                    dispatch(setIsShowUpdateMarketingChannelGroupForm(false));
                    dispatch(fetchMarketingChannelGroupList());
                } else {
                    (response.payload?.errors || []).forEach((error) => {
                        if (error.key === 'name' && error.order) {
                            setError(
                                `items.${error.order - 1}.name`,
                                { type: ErrorMessageType.MANUAL, message: error.message },
                                { shouldFocus: true },
                            );
                        }
                    });
                    notification.success({
                        message: response.payload?.message || '',
                    });
                }
            }
        },
        [],
    );
    useEffect(() => {
        reset({
            items: marketingChannelGroupList,
        });
    }, [marketingChannelGroupList]);

    const onSubmit = () => {
        handleSubmit(async (data) => {
            const updatedChanelGroups = getValues().items || [];
            if (!updatedChanelGroups.length) {
                return;
            }
            const duplicatedNameIndexes = validateUniqueValue(
                updatedChanelGroups,
                'name',
            );
            if (duplicatedNameIndexes.length) {
                duplicatedNameIndexes.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('marketingChannelGroup.form.nameDuplicated'),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkUpdateMarketingChannelGroups(data.items);
        })();
    };

    return (
        <div className="update-marketing-channel-group-form-wrapper">
            <RightDrawerLayout
                open={isShowFormUpdate}
                onClose={_showConfirm}
                onSubmit={onSubmit}
                title={t('marketingChannelGroup.form.update.title')}
                cancelText={t('common.buttonCancelText')}
                submitText={t('common.buttonSaveText')}
                className="update-marketing-channel-group-drawer"
                busy={formBusy}
                loading={formBusy}
                submitId="marketing-channel-group-form-update-submit"
            >
                <Form layout="vertical" autoComplete="off" scrollToFirstError>
                    {marketingChannelGroupList.map((channelGroup, index) => {
                        return (
                            <Row key={channelGroup.id} gutter={8}>
                                <Col span={4}>
                                    <InputText
                                        label={index === 0 ? 'ID' : ''}
                                        placeholder=""
                                        name={`items.${index}.autoGeneratedCode`}
                                        control={control}
                                        disabled={true}
                                    />
                                </Col>
                                <Col span={20}>
                                    <InputText
                                        label={
                                            index === 0
                                                ? t(
                                                      'marketingChannelGroup.form.update.marketingChannelGroupName',
                                                  )
                                                : ''
                                        }
                                        placeholder={t(
                                            'marketingChannelGroup.form.update.marketingChannelGroupNamePlaceholder',
                                        )}
                                        id={`marketing-channel-group-form-update-item.${index}.name`}
                                        name={`items.${index}.name`}
                                        control={control}
                                        disabled={channelGroup.isSeedData}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </Form>
            </RightDrawerLayout>
        </div>
    );
}

export default UpdateMarketingChannelGroupsForm;
