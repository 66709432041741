import { Col, Row } from 'antd';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from '~components';
import { ChildrenInput } from '~features/room-booking/components/ChildrenInput/ChildrenInput';
import InputAdult from '~features/room-booking/components/InputAdult/InputAdult';
import {
    InputAdultFieldName,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import { getChildrenObject } from '~features/room-booking/helper';
import { IChildBooking, IRoomBookingItem } from '~features/room-booking/interfaces';
import { DownOutlined } from '@ant-design/icons';

type Props = {
    control: any;
    setValue: any;
    bookingItem: IRoomBookingItem;
    onChangeChildrenList: (list: Record<string, number>) => void;
};
function NumberOfGuestRow(props: Props) {
    const { control, setValue, bookingItem, onChangeChildrenList } = props;
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const {
        roomBookingItemChildrenTypes,
        numberOfAdults,
        numberOfFemale,
        numberOfMale,
        numberOfOtherGenderGuest,
        bookingStatus,
    } = useMemo(() => {
        return bookingItem;
    }, [bookingItem]);
    return (
        <Row gutter={12}>
            {bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED ? (
                <>
                    <Col span={12}>
                        <InputAdult
                            label={t('roomBooking.form.scheduleBooking.adult')}
                            control={control}
                            setValue={setValue}
                            required={true}
                            isHorizontal={true}
                            fieldName={InputAdultFieldName}
                            autoSetTotalGuest={true}
                        />
                    </Col>
                    <Col span={12}>
                        <div>
                            <div className="form-item-horizontal">
                                <label className="label-horizontal">
                                    {t('roomBooking.form.scheduleBooking.child')}
                                </label>
                                <InputText
                                    label={''}
                                    name="childrenAmounts"
                                    placeholder={t(
                                        'roomBooking.detail.bookingItemCard.numberOfChild.placeholder',
                                    )}
                                    control={control}
                                    defaultValue={
                                        roomBookingItemChildrenTypes?.length
                                            ? sumBy(
                                                  roomBookingItemChildrenTypes,
                                                  (child) => child.quantity,
                                              )
                                            : undefined
                                    }
                                />
                            </div>

                            <div
                                className="input-overlay"
                                onClick={() => {
                                    setIsOpen(!isOpen);
                                }}
                            >
                                <DownOutlined
                                    className={classNames({
                                        'expand-icon': true,
                                        active: isOpen,
                                        deactive: !isOpen,
                                    })}
                                />
                            </div>
                            <ChildrenInput
                                isOpen={isOpen}
                                control={control}
                                name={(index) => `children.${index}`}
                                defaultValue={getChildrenObject(
                                    (roomBookingItemChildrenTypes || [])?.map((item) => ({
                                        ...item,
                                        typeId: item.childrenTypeId,
                                    })) as IChildBooking[],
                                )}
                                onChange={(value, total) => {
                                    setValue('childrenAmounts', total.toString());
                                    // setChildrenList(value);
                                    onChangeChildrenList(value);
                                }}
                                className="booking-form-children-input"
                            />
                        </div>
                    </Col>
                </>
            ) : (
                <>
                    <Col span={12}>
                        <span>
                            {`${t(
                                'roomBooking.detail.bookingItemCard.numberOfAdult.label',
                            )} ${numberOfAdults}`}
                        </span>
                        <span>
                            {t('roomBooking.schedule.genderBreakdown', {
                                male: numberOfMale || 0,
                                female: numberOfFemale || 0,
                                other: numberOfOtherGenderGuest || 0,
                            })}
                        </span>
                    </Col>
                    <Col span={12}>
                        {`${t(
                            'roomBooking.detail.bookingItemCard.numberOfChild.label',
                        )} ${
                            roomBookingItemChildrenTypes?.length
                                ? sumBy(
                                      roomBookingItemChildrenTypes,
                                      (item) => item.quantity,
                                  )
                                : 0
                        }`}
                    </Col>
                </>
            )}
        </Row>
    );
}

export default NumberOfGuestRow;
