import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Form, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { EditableCell } from '../EditableCell/EditableCell';
import { useGetAlmexSetting } from '../../hooks/use-get-almex-kiosk-setting-query';
import { useUpdateAlmexTerminals } from '../../hooks/use-update-almex-terminals-mutation';
import { AlmexTerminalColumn, BulkModificationType } from '../../constants';
import { AlmexKioskTerminalBody, AlmexKioskTerminalOutput } from '../../types';
import { almexKioskTerminalBodySchema } from '../../schema';
import { showConfirm } from '~common/notification';

import './AlmexTerminalTable.scss';

export const AlmexTerminalTable = () => {
    const { t } = useTranslation();
    const { isPending, data: almexSetting } = useGetAlmexSetting();
    const { mutate } = useUpdateAlmexTerminals();
    const [editingId, setEditingId] = useState<number>(0);
    const [terminals, setTerminals] = useState(almexSetting?.terminals);
    const { control, handleSubmit, setValue, reset } = useForm<AlmexKioskTerminalBody>({
        resolver: yupResolver(almexKioskTerminalBodySchema),
        mode: 'onChange',
    });

    useEffect(() => {
        setTerminals(almexSetting?.terminals);
    }, [almexSetting]);

    const handleAdd = () => {
        setEditingId(-1);
        setTerminals([
            ...(terminals || []),
            {
                id: -1,
                name: '',
                code: 0,
                enabled: false,
            },
        ]);
    };

    const handleEdit = (terminal: AlmexKioskTerminalOutput) => {
        if (editingId === -1) {
            setTerminals(almexSetting?.terminals);
        }
        setEditingId(terminal.id);
    };

    const handleRemove = (terminal: AlmexKioskTerminalOutput) => {
        showConfirm({
            title: t('kioskAlmexIntegrations.almex.modal.delete.title'),
            okText: t('kioskAlmexIntegrations.almex.ok'),
            cancelText: t('kioskAlmexIntegrations.almex.cancel'),
            onOk() {
                mutate({
                    items: [
                        {
                            action: BulkModificationType.DELETE,
                            id: terminal.id,
                        },
                    ],
                });
            },
        });
        setEditingId(0);
    };

    const handleSave = (terminal: AlmexKioskTerminalOutput) =>
        handleSubmit((data) => {
            mutate({
                items: [
                    {
                        ...(terminal.id === -1
                            ? {
                                  action: BulkModificationType.CREATE,
                              }
                            : {
                                  action: BulkModificationType.UPDATE,
                                  id: terminal.id,
                              }),
                        ...data,
                        code: Number(data.code),
                    },
                ],
            });
            setEditingId(0);
            reset();
        })();

    const handleCancel = () => {
        if (editingId === -1) {
            setTerminals(almexSetting?.terminals);
        }
        setEditingId(0);
        reset();
    };

    const columns: ColumnsType<AlmexKioskTerminalOutput> = [
        {
            title: t('kioskAlmexIntegrations.almex.kioskAlmex.terminalName'),
            render: (terminal: AlmexKioskTerminalOutput) => (
                <EditableCell
                    control={control}
                    name="name"
                    isEditing={terminal.id === editingId}
                    placeholder={t(
                        'kioskAlmexIntegrations.almex.kioskAlmex.terminalName',
                    )}
                    initValue={terminal.name}
                >
                    {terminal.name}
                </EditableCell>
            ),
            key: AlmexTerminalColumn.NAME,
            fixed: 'left',
            width: 500,
        },
        {
            title: t('kioskAlmexIntegrations.almex.kioskAlmex.terminalCode'),
            render: (terminal: AlmexKioskTerminalOutput) => (
                <EditableCell
                    inputType="number"
                    control={control}
                    name="code"
                    isEditing={terminal.id === editingId}
                    placeholder={t(
                        'kioskAlmexIntegrations.almex.kioskAlmex.terminalCode',
                    )}
                    initValue={terminal.code}
                >
                    {terminal.code}
                </EditableCell>
            ),
            key: AlmexTerminalColumn.CODE,
            fixed: 'left',
            width: 500,
        },
        {
            title: t('kioskAlmexIntegrations.almex.kioskAlmex.terminalStatus'),
            render: (terminal: AlmexKioskTerminalOutput) => (
                <EditableCell
                    inputType="switch"
                    control={control}
                    name="enabled"
                    isEditing={terminal.id === editingId}
                    initValue={terminal.enabled}
                    handleSwitchChange={(enabled) => setValue('enabled', enabled)}
                >
                    <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={terminal.enabled}
                        disabled
                    />
                </EditableCell>
            ),
            key: AlmexTerminalColumn.STATUS,
            fixed: 'left',
            width: 300,
        },
        {
            render: (terminal: AlmexKioskTerminalOutput, _, index) => {
                return terminal.id === editingId ? (
                    <>
                        <Button
                            id={`items.${index}.save`}
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => handleSave(terminal)}
                        />
                        <Button
                            id={`items.${index}.cancel`}
                            type="text"
                            icon={<CloseOutlined />}
                            onClick={() => handleCancel()}
                        />
                    </>
                ) : (
                    <>
                        <Button
                            id={`items.${index}.edit`}
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(terminal)}
                        />
                        <Button
                            id={`items.${index}.remove`}
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => handleRemove(terminal)}
                        />
                    </>
                );
            },
            key: AlmexTerminalColumn.ACTION,
            fixed: 'left',
        },
    ];

    return (
        <Form className="almex-terminal-table">
            <Table
                columns={columns}
                dataSource={terminals || []}
                pagination={false}
                rowKey="id"
                loading={isPending}
                rowClassName="editable-row"
                className="table-scrollbar"
                footer={() => (
                    <Button
                        type="dashed"
                        className="button-add"
                        id="add-terminal"
                        disabled={!!editingId}
                        onClick={handleAdd}
                    >
                        <PlusCircleOutlined className="right-register-form-icon" />
                        <span>
                            {t('kioskAlmexIntegrations.almex.kioskAlmex.addTerminal')}
                        </span>
                    </Button>
                )}
            />
        </Form>
    );
};
