export default {
    modal: {
        title: 'チェックイン',
        accountReceivable: {
            title: '未収金',
        },
        button: {
            cancel: 'キャンセル',
            checkin: 'チェックイン',
            add: '追加',
        },
        roomType: {
            label: '部屋タイプ',
            placeholder: '部屋タイプを選択',
        },
        checkinSaleItem: {
            priceLabel: '金額',
            label: '勘定科目',
            placeholder: '選択してください',
        },
        checkinPaymentMethod: {
            priceLabel: '金額',
            label: '支払い方法',
            placeholder: '支払い方法選択',
        },
        room: {
            label: '部屋名',
            placeholder: '部屋を選択',
        },
        depositNote:
            '予約から使用開始にステータスを変更します。デポジットがある場合は、選択・入力して下さい。',
    },
};
