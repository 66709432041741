import { PrinterOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Modal, notification } from 'antd';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AutoComplete, InputText } from '~components';
import {
    ReceiptByGroupMapIndex,
    ReceiptItemType,
    DEFAULT_BILL_PROVISO,
    INVOICE_PATH,
    FILE_TYPE,
} from '~features/room-booking/constants';
import { isCanceledBooking } from '~features/room-booking/helper';
import {
    convertSplitReceipt,
    isSplitAllReceiptItemDetails,
} from '~features/room-booking/helper.split-receipt';
import { IReceipt } from '~features/room-booking/interfaces';
import { roomBookingStateSelector } from '~features/room-booking/reducers/room-booking.reducer';
import {
    setGuestNameToGroupMap,
    setIsShowSplitRoomBookingReceiptPrintingModal,
    setPrintingReceiptByGroupList,
    setProvisoToGroupMap,
    splitReceiptStateSelector,
} from '~features/room-booking/reducers/split-receipt.reducer';
import { updateBillToSchema } from '~features/room-booking/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './BillToTabItem.scss';
import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { useQuery } from '@tanstack/react-query';
import { receiptFileService } from '~features/room-booking/services/receipt-file.service';
import { CacheKeys } from '~queries/queries';

interface IProps {
    groupName: string;
    groupId: string;
    receipt: IReceipt;
    guestName: string;
}

function BillToTabItem(props: IProps) {
    const { groupName, groupId, receipt, guestName } = props;

    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { control, getValues } = useForm({
        resolver: yupResolver(updateBillToSchema),
    });

    const { selectedRoomBookingDetail } = useAppSelector(roomBookingStateSelector);
    const { receiptByGroupMap } = useAppSelector(splitReceiptStateSelector);

    const totalBill = useMemo(() => {
        const tableData = convertSplitReceipt(receipt, selectedRoomBookingDetail);
        return tableData.reduce((totalBill: number, receiptBookingItem) => {
            if (isCanceledBooking(receiptBookingItem.bookingStatus)) return totalBill;

            receiptBookingItem.children?.forEach((receiptItem) => {
                if (receiptItem.status === ReceiptItemType.RECEIPT) {
                    totalBill += receiptItem?.amount || 0;
                }
            });
            return totalBill;
        }, 0);
    }, [receipt, selectedRoomBookingDetail]);

    const count = useQuery({
        queryKey: [CacheKeys.receiptFilesCount, receipt.id, pathname.endsWith(INVOICE_PATH)],
        queryFn: async () => {
            const res = await receiptFileService.getReceiptFilesCount(
                receipt.id, pathname.endsWith(INVOICE_PATH) ?
                    FILE_TYPE.INVOICE :
                    FILE_TYPE.RECEIPT
            );
            if (res.success) return res.data.count;
            throw Error(res.message);
        },
    });

    const printCurrentGroup = async () => {
        const receiptByGroupAllMap = receiptByGroupMap[ReceiptByGroupMapIndex.ALL];
        if (!isSplitAllReceiptItemDetails(receiptByGroupAllMap.receipt)) {
            Modal.warning({
                title: t('roomBooking.splitReceipt.notificationAlert.notSplitDone'),
            });
            return;
        }
        if (count.error) {
            notification.warning({
                message: t('roomBooking.splitReceipt.files.countError'),
            });
        }
        dispatch(
            setPrintingReceiptByGroupList([
                { ...receiptByGroupMap[groupId], logNumber: (count.data || 0) + 1 },
            ]),
        );
        dispatch(setIsShowSplitRoomBookingReceiptPrintingModal(true));
    };

    return (
        <div className="split-room-booking-bill-to-tab-item-wrapper">
            <div className="bill-to-tab-item-header">
                <div className="title">{groupName}</div>
                <div className="print">
                    <Button onClick={printCurrentGroup} disabled={!receipt}>
                        <PrinterOutlined />
                        {t('roomBooking.splitReceipt.billToTab.item.print')}
                    </Button>
                </div>
            </div>
            <div className="bill-to-tab-item-body">
                <Form layout="vertical" autoComplete="off" scrollToFirstError>
                    <div className="form-input">
                        <AutoComplete
                            label={t('roomBooking.splitReceipt.billToTab.item.billName')}
                            name={'billName'}
                            defaultValue={guestName}
                            options={selectedRoomBookingDetail?.guests?.map((guest) => ({
                                label: guest.yomigana,
                                value: guest.yomigana,
                            }))}
                            control={control}
                            onBlur={() =>
                                dispatch(
                                    setGuestNameToGroupMap({
                                        groupId,
                                        guestName: getValues('billName'),
                                    }),
                                )
                            }
                        />
                        <span>
                            {t('roomBooking.splitReceipt.billToTab.item.billNameSubText')}
                        </span>
                    </div>
                    <div className="form-input">
                        <InputText
                            label={t(
                                'roomBooking.splitReceipt.billToTab.item.billProviso',
                            )}
                            name={'billProviso'}
                            control={control}
                            defaultValue={DEFAULT_BILL_PROVISO}
                            maxLength={INPUT_TEXT_MAX_LENGTH}
                            onBlur={() =>
                                dispatch(
                                    setProvisoToGroupMap({
                                        groupId,
                                        proviso: getValues('billProviso'),
                                    }),
                                )
                            }
                        />
                        <span>
                            {t(
                                'roomBooking.splitReceipt.billToTab.item.billProvisoSubText',
                            )}
                        </span>
                    </div>

                    <div className="total-bill">
                        <div className="total-bill-header">
                            {t('roomBooking.splitReceipt.billToTab.item.billTotal')}
                        </div>
                        <div className="total-bill-amount">{`¥${
                            totalBill > 0 ? totalBill : 0
                        }`}</div>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default BillToTabItem;
