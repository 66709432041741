const notificationForm = {
    titlePage: 'Notification Form',
    breadcrumbs: {
        home: 'Home',
        notification: 'Notification',
        notificationSetting: 'Notification Setting',
    },
    informationTitle: 'Information Title',
    title: 'Title',
    titleInput: 'Please input title',
    deliveryTime: 'Delivery time',
    deliveryTimePlaceHolder: 'Delivery time',
    tag: 'Type',
    hotelDesignation: 'Target hotel',
    hotelDesignationPlaceHolder: 'Select a target hotel',
    detail: 'Description',
    detailInput: 'Please input description',
    submitBtn: 'Save',
    cancelBtn: 'Cancel',
    tagLenghError: 'Type must be at most {{maxLength}} characters',
    create: {
        success: 'Notification have been created succcessful!',
        error: 'Notification have been created unsucccessful!',
    },
    update: {
        success: 'Notification have been updated succcessful!',
        error: 'Notification have been updated  unsucccessful',
    },
    confirm: {
        cancelTitle: 'Do you want to cancel?',
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
    },
};

export default notificationForm;
