import { IRoom } from '~features/room/interfaces';
import { OrderDirection } from '~common/constants';
import { IBodyResponse, ICommonListQuery } from '~common/interfaces';
import { IFacility } from '~features/facility/interfaces';
import {
    IPaymentRoomBookingItem,
    IRoomBooking,
    ISaleItem,
    IScheduleItem,
    NumberObject,
} from '~features/room-booking/interfaces';
import {
    FacilityBookingDetailPageTabPane,
    FacilityBookingSearchField,
    FacilityBookingStatus,
    ReceiptByGroupMapIndex,
    ReceiptItemDetailType,
    ReceiptItemType,
} from './constants';
import { ScheduleViewMode } from '~common/constants';
import { Dayjs } from '~plugins/dayjs';
import { IPlan } from '~features/plan/interfaces';
import { IFacilityType } from '~features/facility-type/interfaces';
import { ITax } from '~features/tax/interfaces';
import { ICountryResponse } from '~features/guest/interfaces';

export interface IFacilityBooking {
    id: number;
    autoGeneratedCode: string;
    startDatetime: string;
    endDatetime: string;
    numberOfGuests: number;
    facilityId: number;
    guestId: number;
    totalAmount: number;
    roomBookingId?: number;
    isDailyClosing: boolean;
    isMonthlyClosing: boolean;
    memo: string | null;
    status: FacilityBookingStatus;
    facility: IFacility;
    guest: IGuest;
    roomBooking: IRoomBooking;
    paymentId?: number | null;
    marketingChannel?: string | null;
    createdAt?: string;
    emailAddress: string;
    facilityType?: IFacilityType;
    roomOfBookings?: {
        id: number;
        name: string;
    }[];
}

export interface IGuest {
    id: number;
    fullName: string;
    yomigana: string;
    mobilePhoneNumber: string;
    phoneNumberLandline?: string | null;
    emailAddress: string | null;
    gender: string | null;
    birthday: string | null;
    autoGeneratedCode?: string;
    countryId: number;
    address: string;
    occupation: string;
    companyName: string;
    companyYomigana: string;
    companyPhoneNumber: string;
    companyEmailAddress: string;
    postalCode: string;
}

export interface IFacilityBookingState {
    facilityBookingListQuery: IFacilityBookingListQuery;
    facilityBookingList: IFacilityBooking[];
    totalFacilityBookings: number;
    showLoading: boolean;
    searchField: FacilityBookingSearchField | null;
    isShowAdvanceSearch: boolean;
    isShowAdvanceSearchTags: boolean;
    facilityBookingAdvanceSearchForm: IFacilityBookingAdvanceSearchForm | null;
    advanceSearchListQuery: IAdvanceSearchGetListQuery | null;
    isShowCreateFacilityBookingForm: boolean;
    isShowUpdateFacilityBookingForm: boolean;
    isAdvanceSearch: boolean;
    selectedFacilityBookingDetail: IFacilityBooking | null;
    facilityBookingReceipt: IFacilityBookingReceipt | null;
    paymentMethodListForDropdown: IPaymentMethodForDropdown[];
    isCalculatingAmount: boolean;
    activeDetailTabPane: FacilityBookingDetailPageTabPane;
    showPaymentModal: boolean;
    showPaymentModalLoading: boolean;
    showUpdateFacilityBookingLoading: boolean;
    isShowAccommodationCardPrintingModal: boolean;
    isShowBulkPaymentModal: boolean;
}

export interface IFacilityBookingListQuery extends ICommonListQuery {
    autoGeneratedCode?: string;
    status?: FacilityBookingStatus[];
    facilityNames?: string[];
    guestFullName?: string;
    guestYomigana?: string;
    guestPhoneNumber?: string;
    facilityName?: string;
}

export interface IFacilityBookingExportCsvQuery {
    keyword?: string;
    autoGeneratedCode?: string;
    status?: FacilityBookingStatus[];
    guestFullName?: string;
    guestYomigana?: string;
    guestPhoneNumber?: string;
    facilityNames?: string[];
    orderBy?: string;
    orderDirection?: OrderDirection;
}

export interface IFacilityBookingAdvanceSearchForm {
    autoGeneratedCode: string;
    guestCategory: string;
    facilityIds?: number[];
    status: string[];
    guestYomigana: string;
    guestPhoneNumber: string;
    checkInDateRanges: string[];
    checkInTimeRanges: string[];
    marketingChannels: string[];
    usedBookingTypeOfGuest: string;
}

export interface ISearchTag {
    field: string;
    value: string;
    id?: number | string;
}

export interface IAdvanceSearchGetListQuery extends ICommonListQuery {
    autoGeneratedCode?: string;
    guestCategory?: string;
    facilityIds?: number[];
    status?: string[];
    guestYomigana?: string;
    guestPhoneNumber?: string;
    checkInDateRanges?: string[];
    checkInTimeRanges?: string[];
}

export interface UpdateFacilityBookingStatusItem {
    id: number;
    order?: number;
}

export interface IUpdateFacilityBookingStatus {
    items: UpdateFacilityBookingStatusItem[];
    status: FacilityBookingStatus;
}

export interface IFacilityBookingSchedule {
    parentName: string;
    date: string;
    facilityKey: string;
}

export interface IFacilityBookingScheduleItem {
    id: number;
    guest?: IGuest;
    facilityId: number;
    facility: {
        id: number;
        name: string;
    };
    facilityKey: string;
    checkInTimeWeekView: string;
    checkOutTimeWeekView: string;
    checkInTime: string;
    checkOutTime: string;
    checkInDateTime: string;
    checkOutDateTime: string;
    status: FacilityBookingStatus;
    numberOfGuests: number;
    roomBookingId?: number;
    date: string;
    rooms?: {
        id: number;
        name: string;
    }[];
    totalAmount?: number;
    facilityTypeId?: number | null;
}

export interface IFacilityBookingGroup {
    id: number;
    name: string;
    numberOfFacilities?: number;
    facilities: Record<string, IFacilityBookingScheduleItem[]>;
    parentName: string;
    parentId: number | null;
    statisticsByDate?: NumberObject;
}

export interface IFacilityBookingStatisticItem {
    date: string;
    numberOfFacilityBookings: number;
    numberOfCheckedInFacilityBookings: number;
    numberOfCheckedOutFacilityBookings: number;
    numberOfUsedFacilities: number;
    numberOfFacilities: number;
}

export type IFacilityBookingList = Record<
    string,
    Record<string, IFacilityBookingScheduleItem[]>
>;
export interface IFacilityBookingScheduleState {
    bookingGroupById: Record<string, IBookingsResponse>;
    facilityList: IScheduleItem[];
    bookingList: IFacilityBookingList;
    facilityBookingStatistic: IFacilityBookingStatisticItem[];
    facilityBookingSchedule: IFacilityBookingSchedule;
    isShowFacilityBookingScheduleListModal: boolean;
    currentFacilityGroup: number;
    currentViewMode: ScheduleViewMode;
    isDraggingBooking: boolean;
    isFetchingBookingList: boolean;
    isShowCreateFacilityBookingForm: boolean;
    isFetchingFacilityList: boolean;
    isUpdatingBooking: boolean;
    currentStatus: FacilityBookingStatus;
    statisticsByDate: Record<string, NumberObject>;
    currentDragData: {
        facilityId: number;
        booking: IFacilityBookingScheduleItem;
    } | null;
    isSelecting: boolean;
    currentWeek: number;
    numberOfRooms: number;
    startPosition: { x: number; y: number } | null;
    currentDate: string;
    panelId: string;
    collapseRooms: number[];
    startDateWeekView: string;
    endDateWeekView: string;
    startDateDayView: string;
    endDateDayView: string;
    currentEditFacilityBooking: IFacilityBookingScheduleItem | null;
    showCheckInForm: boolean;
    facilityBookingCheckIn: IFacilityBookingScheduleItem | null;
    showCheckInFormLoading: boolean;
    defaultFacilityBookingOptions: { facilityId?: number };
}

export interface IFacilityBookingItemResponse {
    id: number;
    name: string;
    status: FacilityBookingStatus;
    guestId: number;
    facilityId: number;
    endDatetime: string;
    startDatetime: string;
    numberOfGuests: number;
    guest: IGuest;
}

export interface IFacilityResponse {
    id: number;
    name: string;
    facilityBookings: IFacilityBookingItemResponse[];
}
export interface IFacilityItemResponse {
    id: number;
    name: string;
    facilities: IFacilityResponse[];
    numberOfFacilities: number;
}
export interface IFacilityBookingGroupByFacilityTypeResponse {
    items: IFacilityItemResponse[];
    numberOfFacilities: number;
    statisticsByDate: IFacilityBookingStatistic;
}

export interface IFacilityBookingGroupByFacilityType {
    id: number;
    name: string;
    facilities: IFacilityResponse[];
}

export interface IFacilityBookingStatistic {
    date: string;
    numberOfFacilityBookings: number;
    numberOfUsedFacilities: number;
}

export interface IFacilityBookingStatisticResponse {
    items: IFacilityBookingStatisticItemResponse[];
}

export interface IFacilityBookingStatisticTypeResponse {
    items: {
        id: number;
        name: string;
        facilities: {
            id: number;
            name: string;
            numberOfFacilities: number;
            statisticsByDate: {
                date: string;
                numberOfFacilityBookings: number;
                numberOfUsedFacilities: number;
            };
        }[];
        numberOfFacilities: number;
        statisticsByDate: {
            date: string;
            numberOfFacilityBookings: number;
            numberOfUsedFacilities: number;
        }[];
        businessEndTime: string;
        businessStartTime: string;
    }[];
    numberOfFacilities: number;
}

export interface IBookingsResponse {
    id: number;
    facilityId: number;
    startDatetime: string;
    endDatetime: string;
    status: FacilityBookingStatus;
    guestId: number;
    numberOfGuests: number;
    roomOfBookings?: {
        id: number;
        name: string;
    }[];
    guest?: IGuest;
    facility: {
        id: number;
        name: string;
    };
    totalAmount?: number;
}

export interface IFacilityBookingStatisticItemResponse {
    date: string;
    numberOfFacilityBookings: number;
    numberOfCheckedInFacilityBookings: number;
    numberOfCheckedOutFacilityBookings: number;
    numberOfUsedFacilities: number;
    numberOfFacilities: number;
}
export interface IFacilityBookingCreateBody {
    facilityId: number | null;
    guestId?: number | null;
    yomigana?: string | number | null;
    numberOfGuests: number;
    totalAmount?: number;
    startDatetime?: string;
    endDatetime?: string;
    fullName?: string | null;
    mobilePhoneNumber?: string | null;
    birthday?: string | null | Dayjs;
    gender: string | null;
    memo?: string | null;
    marketingChannel?: string;
    paymentId?: number;
    bookingTimes?: [Dayjs, Dayjs];
    reservationDuration?: [Dayjs, Dayjs];
    emailAddress: string | null;
}

export interface IFacilityBookingUpdate {
    id: number;
    body: IFacilityBookingCreateBody;
}

export interface IMoveBookingData {
    from: {
        typeId: number;
        facilityId: number;
    };
    to: {
        typeId: number;
        facilityId: number;
    };
    booking: IFacilityBookingScheduleItem;
}
export interface IAddBookingPayload {
    booking?: IFacilityBookingScheduleItem;
    bookingResponse?: IBookingsResponse;
}

export interface IPositionState {
    scrollLeft: number;
    scrollTop: number;
}

export interface IColumnOption {
    id: string;
    label: string;
    index: number;
    hours: string;
    day: string;
}

export interface IFacilityBookingReceiptItemDetail {
    id: number;
    hotelId: number;
    receiptItemId: number;
    saleItemId: number;
    unitPrice: number;
    quantity: number;
    amount: number;
    saleItem: ISaleItem;
    type: ReceiptItemDetailType;
    receiptItemDetailId?: number;
    groupId?: string | null; // for split receipt purpose only
    boughtAt: string;
    payAt: string;
    stayPriceTax?: number;
    saleItemTax?: number;
    bookingDetail?: string;
    printDate?: string;
}

export interface IFacilityBookingReceiptItem {
    id: number;
    createdAt?: string;
    updatedAt?: string;
    hotelId?: number;
    bookingType: string;
    bookingId: number;
    type: string;
    planId: number | null;
    paymentMethodId: number | null;
    receiptItemDetails: IFacilityBookingReceiptItemDetail[];
    plan: IPlan | null;
    paymentMethod: IPaymentMethod | null;
    paymentRoomBookingItem?: IPaymentRoomBookingItem | null;
    room?: IRoom | null;
    paymentRoomBookingItemId?: number;
    amount?: number;
}

export interface IFacilityBookingReceiptDetail {
    id: number;
    facilityId: number;
    guestId: number;
    autoGeneratedCode: string;
    facility: IFacility;
    guest: IGuest;
    receiptItems: IFacilityBookingReceiptItem[];
    status?: string;
    saleItemTax: number;
    roomBookingItems: IPaymentRoomBookingItem[];
    startDatetime: string;
    endDatetime: string;
    createdAt: string;
}

export interface IFacilityBookingReceipt {
    id: number;
    hotelId?: number;
    bookingType: string;
    bookingId: number;
    otherTaxPercentage: number | null;
    facilityBooking: IFacilityBookingReceiptDetail;
}

export interface IFacilityBookingReceiptResponse {
    receipt: IFacilityBookingReceipt;
}

export interface IUpdateFacilityBookingReceipt {
    type: string;
    id?: number;
    planId?: number;
    facilityTypeId?: number;
    paymentRoomBookingItemId?: number;
    paymentMethodId?: number;
    receiptItemDetails?: {
        id?: number;
        saleItemId?: number;
        unitPrice?: number;
        quantity?: number;
        amount?: number;
    }[];
    amount?: number;
}

export interface IPaymentMethod {
    id: number;
    name: string;
    autoGeneratedCode: string;
}

export type IPaymentMethodListDropdownResponse = IBodyResponse<{
    items: IPaymentMethod[];
}>;

export interface IPaymentMethodForDropdown {
    value: number;
    label: string;
}
export interface IFacilityBookingCheckIn {
    facilityId: number;
    paymentMethodCheckIns: {
        paymentMethodId: number;
        price: number;
    }[];
}

export interface ICheckinPaymentMethod {
    paymentMethodId: number | null;
    amount: number | null;
}

export interface IFacilityBookingReceiptTableData {
    id: number | string;
    receiptItemId?: number;
    name?: string;
    autoGeneratedCode?: string;
    date?: string;
    type?: string;
    detail?: string | null;
    saleItemId?: number;
    saleItem?: ISaleItem | null;
    guest?: IGuest | null;
    unitPrice?: number;
    quantity?: number;
    amount?: number;
    plan?: IPlan | null;
    paymentMethod?: IPaymentMethod | null;
    children?: IFacilityBookingReceiptTableData[];
    isAddition?: boolean;
    receiptItemDetailType?: ReceiptItemDetailType;
    receiptItemDetailId?: number;
    tax?: ITax;
    roomName?: string | null;
    paymentRoom?: IPaymentRoomBookingItem | null;
    paymentRoomBookingItemId?: number;
    showPaymentRoom?: boolean;
    cannotDelete?: boolean;
    isErrorAmount?: boolean;
}

export enum ReceiptFormField {
    UNIT_PRICE = 'unitPrice',
    QUANTITY = 'quantity',
    AMOUNT = 'amount',
}
export interface IGetCalculatedAmountQuery {
    facilityId: number;
    bookingId?: number;
    startDatetime: string;
    endDatetime: string;
}

export interface ICalculatedAmountResponse {
    amount: number;
}

export interface ISplitReceiptState {
    receiptByGroupMap: IReceiptByGroupMap;
    numberOfGroups: number;
    printingReceiptByGroupList: IReceiptByGroup[];
    isShowSplitFacilityBookingReceiptPrintingModal: boolean;
}

export interface ISplitFacilityBookingReceiptTableData {
    id: string;
    index?: number;
    autoGeneratedCode?: string;
    name?: string;
    bookingDate?: string;
    bookingDetail?: string;
    saleItem?: {
        id: number;
        hotelId: number;
        name: string;
        tax: ITax;
    } | null;
    guest?: IGuest | null;
    room?: IRoom | null;
    groupId: string | null;
    unitPrice?: number | null;
    quantity?: number | null;
    amount?: number | null;
    checkOutRoom?: IRoom | null;
    paymentMethod?: IPaymentMethod | null;
    type?: ReceiptItemType;
    facilityBooking?: IFacilityBookingReceiptDetail;
    receiptItem?: IFacilityBookingReceiptItem;
    receiptItemDetail?: IFacilityBookingReceiptItemDetail;
    children?: ISplitFacilityBookingReceiptTableData[];
    level: number;
    status?: string;
    printDate?: string;
}

export interface IReceiptByGroupMap {
    [groupId: string | ReceiptByGroupMapIndex]: IReceiptByGroup;
}

export interface IReceiptByGroup {
    group: IGroup | null;
    receipt: IFacilityBookingReceipt;
    guestName?: string;
    proviso?: string;
}

export interface IGroup {
    id: string;
    name?: string;
}

export interface IPrintFacilityBookingState {
    printFacilityBookingList: IFacilityBooking[];
    printFacilityBookingListQuery: IFacilityBookingListQuery;
    isPrintFacilityBookingListLoading: boolean;
    isShowFacilityBookingPrintingModal: boolean;
    countryList: ICountryResponse[];
}

export interface IPrintingFacilityReceiptItemDetail {
    printDate?: string;
    bookingDetail?: string;
    unitPrice?: number;
    quantity?: number;
    amount?: number;
}

export interface ICreateReceiptFileBody {
    number: string;
    name: string;
    total: number;
    type: string;
}
