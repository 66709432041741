import { Card, Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleViewMode, TOP_BAR_HEIGHT } from '~common/constants';
import { useEscape } from '~common/useHooks';
import { RightDrawerLayout, StaffLayoutHeader } from '~components';
import {
    drawerBodyPadding,
    drawerFooterHeight,
    drawerHeaderSize,
    FacilityBookingStatus,
    FACILITY_SCHEDULE_PAGE_PADDING,
    FILTER_FORM_FACILITY_SCHEDULE_HEIGHT,
    FILTER_FORM_FACILITY_SCHEDULE_MARGIN,
    MAX_HEIGHT_SCHEDULE_VIEW,
    MIN_HEIGHT_SCHEDULE_VIEW,
    timeControlHeight,
} from '~features/facility-booking/constants';
import {
    fetchFacilityList,
    setCurrentDate,
    setCurrentStatus,
    setCurrentViewMode,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { useAppDispatch } from '~hooks';
import customDayjs from '~plugins/dayjs';
import { ScheduleView } from './components/ScheduleView/ScheduleView';
import './FacilitySchedulePage.scss';

export const FacilitySchedulePage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isExpandSchedule, setIsExpandSchedule] = useState(false);

    const breadcrumbs = () => [
        {
            text: t('facilityBooking.list.breadcrumbs.home'),
        },
        {
            text: t('facilityBooking.list.breadcrumbs.facilityManagement'),
        },
        {
            text: t('facilityBooking.list.breadcrumbs.facilitySchedule'),
        },
    ];

    useEffect(() => {
        dispatch(fetchFacilityList({}));
        dispatch(setCurrentDate(customDayjs().fmYYYYMMDD()));
        dispatch(setCurrentViewMode(ScheduleViewMode.WEEK));
        return () => {
            dispatch(setCurrentDate(customDayjs().startOf('week')?.fmYYYYMMDD()));
            dispatch(setCurrentStatus(FacilityBookingStatus.ALL));
        };
    }, []);

    const calculateScheduleHeight = () => {
        const scheduleHeight =
            window.innerHeight -
            TOP_BAR_HEIGHT -
            timeControlHeight -
            FACILITY_SCHEDULE_PAGE_PADDING -
            FILTER_FORM_FACILITY_SCHEDULE_HEIGHT -
            FILTER_FORM_FACILITY_SCHEDULE_MARGIN;

        if (scheduleHeight > MAX_HEIGHT_SCHEDULE_VIEW) return MAX_HEIGHT_SCHEDULE_VIEW;
        if (scheduleHeight < MIN_HEIGHT_SCHEDULE_VIEW) return MIN_HEIGHT_SCHEDULE_VIEW;
        return scheduleHeight;
    };

    const onClose = () => {
        setIsExpandSchedule(false);
    };
    useEscape(onClose);

    return (
        <div className="schedule-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('facilityBooking.schedule.title')}
            />
            <Layout.Content>
                <div className="schedule-page-wrapper">
                    <Card className="schedule-card">
                        {!isExpandSchedule && (
                            <ScheduleView
                                height={calculateScheduleHeight()}
                                isExpandSchedule={isExpandSchedule}
                                setIsExpandSchedule={setIsExpandSchedule}
                            />
                        )}
                    </Card>
                </div>
                {isExpandSchedule && (
                    <RightDrawerLayout
                        open={isExpandSchedule}
                        onClose={onClose}
                        title={' '}
                        width={'100VW'}
                        footer={<div></div>}
                        className="expand-facility-booking-drawer"
                    >
                        <ScheduleView
                            height={
                                window.innerHeight -
                                drawerHeaderSize -
                                drawerBodyPadding -
                                timeControlHeight -
                                drawerFooterHeight
                            }
                            isExpandSchedule={isExpandSchedule}
                            setIsExpandSchedule={setIsExpandSchedule}
                        />
                    </RightDrawerLayout>
                )}
            </Layout.Content>
        </div>
    );
};
