const guestList = {
    title: 'Guest List',
    breadcrumbs: {
        home: 'Home',
        guest: 'Guest List',
    },
    searchColumn: {
        keyword: 'All',
        fullName: 'Guest name',
        phoneNumber: 'Phone number',
        email: 'Email address',
        usedBookingTypes: 'Label',
    },
    guestColumn: {
        id: 'Guest ID',
        fullName: 'Full name',
        yomigana: 'Yomigana',
        gender: 'Gender',
        birthday: 'Date of Birth',
        mobilePhoneNumber: 'Phone number',
        usedBookingTypes: 'Label',
        emailAddress: 'Email address',
        memo: 'Memo',
        classification: 'Guest type',
    },
    gender: {
        male: 'Male',
        female: 'Female',
        other: 'Other',
    },
    usedBookingType: {
        'room-booking': 'Accommodation',
        'facility-booking': 'Facility',
    },
    exportColumns: {
        autoGeneratedCode: 'ID',
        fullName: 'Name',
        yomigana: 'Yomigana',
        gender: 'Gender',
        birthday: 'Birthday',
        mobilePhoneNumber: 'Mobile Phone Number',
        classification: 'Classification Name',
        emailAddress: 'Email address',
        usedBookingTypes: 'Used Booking Types',
        memo: 'Memo',
    },
    modalConfirmDeletion: {
        title: 'Delete guest',
        description: 'Do you want to delete the selected guest?',
        cancelButton: 'Cancel',
        deleteButton: 'Delete',
    },
    modalDeteleSuccess: {
        title: 'Guest has been deleted',
        okButton: 'Close',
    },
    merge: {
        mergeGuest: 'Merge guests',
        buttonInfo: 'Please select guests to merge',
        instruction: 'Please choose a primary guest to merge other guest information into.',
        note: 'Note',
        warning: 'When merging, the primary guest information will overwrite other guest information.',
        ok: 'OK',
        cancel: 'Cancel',
        notification: {
            success: 'Merge guests successfully',
        },
    },
};

export default guestList;
