import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import { bulkUpdateTempBooking } from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch } from '~hooks';
import { BookingTemporaryCard } from '../BookingTemporaryCard/BookingTemporaryCard';
import './ConfirmCloseBookingModal.scss';

type Props = {
    bookings: IRoomBookingSchedule[];
    isOpen: boolean;
    handleCancel: () => void;
};

export function ConfirmCloseBookingModal(props: Props) {
    const { bookings, isOpen, handleCancel } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [count, setCount] = React.useState(60);
    const interval = React.useRef<NodeJS.Timeout | null>(null);

    const handleOk = () => {
        const ids = bookings.map((item) => item.id);
        dispatch(bulkUpdateTempBooking(ids));
        handleCancel();
    };

    const reset = () => {
        interval.current && clearInterval(interval.current);
        interval.current = null;
        setCount(60);
    };

    useEffect(() => {
        if (isOpen) {
            if (!interval.current) {
                interval.current = setInterval(() => {
                    setCount((count) => {
                        if (count - 1 === 0) {
                            interval.current && clearInterval(interval.current);
                            handleCancel();
                            reset();
                        }
                        return count - 1;
                    });
                }, 1000);
            }
        } else {
            interval.current && clearInterval(interval.current);
            interval.current = null;
            setCount(60);
        }
    }, [isOpen, handleCancel, dispatch, bookings]);

    return (
        <Modal
            title={
                <div className="confirm-title">
                    <span className="title">
                        {t('roomBooking.schedule.confirmCloseBookingTitle', {
                            minutes: count,
                        })}
                    </span>
                    <span className="sub-title">
                        {t('roomBooking.schedule.confirmCloseBooking')}
                    </span>
                </div>
            }
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={t('common.ok')}
            cancelText={t('common.cancel')}
            className={'confirm-close-booking-modal'}
            maskClosable={false}
        >
            {bookings.map((booking) => (
                <div key={booking.id} className="mb-10">
                    <BookingTemporaryCard showAction={false} booking={booking} />
                </div>
            ))}
        </Modal>
    );
}
