import { facilityCreateEn } from './en/facility-create.locale';
import { facilityListEn } from './en/facility-list.locale';
import { facilityUpdateEn } from './en/facility-update.locale';
import { facilityCreateJa } from './ja/facility-create.locale';
import { facilityListJa } from './ja/facility-list.locale';
import { facilityUpdateJa } from './ja/facility-update.locale';

const facility = {
    ja: {
        list: facilityListJa,
        create: facilityCreateJa,
        updateForm: facilityUpdateJa,
    },
    en: {
        list: facilityListEn,
        create: facilityCreateEn,
        updateForm: facilityUpdateEn,
    },
};

export default facility;
