import { InputSearch } from '~components';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import './FilterForm.scss';
import { useAppDispatch } from '~hooks';
import {
    setKeyword,
    setShowForm,
    setSelectedSaleGroupList,
} from '~features/sale-group/reducers/sale-group.reducer';
import SelectField from './SelectField/SelectField';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, GA_KEYWORD_CONTENT } from '~common/constants';
import { trackSearchKeyword } from '~plugins/analytics';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([...AdminGroup]);
    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.SALE_GROUP);
    };

    const addSaleGroup = () => {
        dispatch(
            setSelectedSaleGroupList([
                {
                    name: '',
                },
            ]),
        );
        dispatch(setShowForm(true));
    };

    return (
        <div className="filter-sale-group-wrapper">
            <div className="sale-group-left-block">
                <InputSearch
                    onSearch={onSearch}
                    addonBefore={SelectField()}
                    maxLength={30}
                    placeholder={t('guestClassification.list.searchBox')}
                />
            </div>

            {!isReadOnly && (
                <div className="sale-group-btn">
                    <Button
                        type="primary"
                        id="btn-create-sale-group"
                        onClick={addSaleGroup}
                    >
                        <PlusOutlined />
                        <span className="btn-text">
                            {t('saleGroup.list.primaryButton')}
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
