import { Button, Radio, RadioChangeEvent } from 'antd';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat, DatePickerType } from '~common/constants';
import { SingleDatePicker } from '~components';
import {
    CancellationViewMode,
    cancellationViewModeOptions,
} from '~features/report/constants';
import {
    cancellationStateSelector,
    setCancellationReportGetListQuery,
    setExportCsv,
    setIsShowTable,
} from '~features/report/reducers/report-cancellation.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import './FilterForm.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { cacellationFilterSchema } from '~features/report/schema';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedDate, setSelectedDate] = useState(customDayjs());
    const [viewMode, setViewMode] = useState(CancellationViewMode.DAY);
    const { isShowTable } = useAppSelector(cancellationStateSelector);
    const { control, setValue } = useForm({
        resolver: yupResolver(cacellationFilterSchema),
    });

    const clickDisplayButton = () => {
        dispatch(setIsShowTable(true));
        setGetListQuery(selectedDate.fmYYYYMMDD(), viewMode);
    };

    const changeDate = (date: Dayjs) => {
        setSelectedDate(date);
        setGetListQuery(date.fmYYYYMMDD(), viewMode);
    };

    useEffect(() => {
        setValue(`day`, customDayjs());
    }, []);
    const setGetListQuery = (date: string, mode: string) => {
        let query = [
            parseDate(date)?.fmYYYYMMDDHHmmss(),
            parseDate(date).add(1, 'day').subtract(1, 'second')?.fmYYYYMMDDHHmmss(),
        ];
        if (mode === CancellationViewMode.MONTH) {
            query = [
                parseDate(date).startOf('month').startOf('day')?.fmYYYYMMDDHHmmss(),
                parseDate(date).endOf('month').endOf('day')?.fmYYYYMMDDHHmmss(),
            ];
        }
        dispatch(setCancellationReportGetListQuery(query));
    };

    const onChangeViewMode = ({ target: { value } }: RadioChangeEvent) => {
        setViewMode(value);
        setGetListQuery(selectedDate.fmYYYYMMDD(), value);
    };
    const exportCSV = () => {
        dispatch(setExportCsv(true));
    };
    return (
        <div className="report-cancellation-filter-form-wrapper">
            <div className="left-filter-form">
                <span>{t('report.cancellation.filterForm.viewMode.label')}</span>
                <Radio.Group
                    options={cancellationViewModeOptions()}
                    onChange={onChangeViewMode}
                    value={viewMode}
                    optionType="button"
                />
                <span className="date-label">
                    {viewMode === CancellationViewMode.DAY
                        ? t('report.cancellation.filterForm.dateLabel')
                        : t('report.cancellation.filterForm.monthLabel')}
                </span>
                <SingleDatePicker
                    value={selectedDate}
                    format={
                        viewMode === CancellationViewMode.DAY
                            ? DateFormat.YYYY_MM_DD_HYPHEN
                            : DateFormat.YYYY_MM_HYPHEN
                    }
                    allowClear={false}
                    onChange={changeDate}
                    picker={
                        viewMode === CancellationViewMode.DAY
                            ? DatePickerType.DATE
                            : DatePickerType.MONTH
                    }
                    placeholder={
                        viewMode === CancellationViewMode.DAY
                            ? t('report.placeholder.selectDate')
                            : t('report.placeholder.selectMonth')
                    }
                    name={'day'}
                    control={control}
                />
                <Button
                    type="primary"
                    className="display-button"
                    onClick={clickDisplayButton}
                >
                    {t('report.cancellation.filterForm.displayButton')}
                </Button>
            </div>
            {isShowTable && <Button onClick={exportCSV}>{t('report.exportCSV')}</Button>}
        </div>
    );
}

export default FilterForm;
