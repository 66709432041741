import i18next from 'i18next';

export const breadcrumbs = () => [
    {
        text: i18next.t('indicator.list.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('indicator.list.breadcrumbs.indicator'),
    },
];

export enum SearchField {
    ALL = 'keyword',
    NAME = 'name',
    TYPE = 'indicatorType',
    LAYOUT = 'layout',
}

export enum OrderBy {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
    TYPE = 'indicatorType',
    LAYOUT = 'layout',
}

export enum IndicatorColumn {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
    TYPE = 'indicatorType',
    LAYOUT = 'layout',
}

export enum IndicatorType {
    ROOM = 'room',
    FACILITY = 'facility',
}

export enum IndicatorSize {
    SMALL = '6x10', // 6x10
    MEDIUM = '10x15', // 10x15
    LARGE = '15x20', // 15x20
}

export const IndicatorSizeOptions = [
    {
        value: IndicatorSize.SMALL,
        label: '6 x 10',
    },
    {
        value: IndicatorSize.MEDIUM,
        label: '10 x 15',
    },
    {
        value: IndicatorSize.LARGE,
        label: '15 x 20',
    },
];

export enum IndicatorError {
    NAME_EXISTS = 445,
}
