export const facilityListJa = {
    title: '施設設定',
    breadcrumbs: {
        home: 'ホーム',
        facility: '施設設定',
    },
    filterForm: {
        buttons: {
            create: '施設を登録',
        },
    },
    columns: {
        id: 'ID',
        name: '施設名',
        typeName: '施設タイプ名',
        basePriceTaxIncluded: '税込みの基本料金 (円)',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '設備名',
        facilityTypeName: '設備タイプ名',
        basePriceTaxIncluded: 'コネクティングルーム',
    },
    deleteFacilitiesSuccess: '施設が削除されました',
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
    },
    exportColumns: {
        autoGeneratedCode: 'ID',
        name: '設備名',
        facilityType: '設備タイプ名',
        basePriceTaxIncluded: '税込みの基本料金 (円)',
    },
    unitPrice: '円',
};
