import { IImportSaleGroup } from '~features/sale-group/interfaces';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import {
    ISaleGroupState,
    ISalelGroupGetListQuery,
    ISaleGroupForm,
    ISaleGroupUpdateForm,
} from '../interfaces';
import { SearchField, initSaleGroupListQuery } from '../constants';

import { cloneDeep, trim } from 'lodash';
import { saleGroupsService } from '../services/sale-group.service';
const initialState: ISaleGroupState = {
    saleGroupListQuery: cloneDeep(initSaleGroupListQuery),
    saleGroupList: [],
    searchField: SearchField.ALL,
    selectedSaleGroupList: [],
    selectedSaleGroup: null,
    totalSaleGroups: 0,
    showForm: false,
    showLoading: false,
    importCsvFileName: '',
    importSaleGroupList: [],
    hasImportError: false,
};

export const fetchSaleGroupList = createAsyncThunk(
    'saleGroup/fetchSaleGroupList',
    async (_, { getState }) => {
        const query = saleGroupListQuerySelector(getState() as AppState);
        return await saleGroupsService.getList(query);
    },
);

export const getSaleGroupListByIds = createAsyncThunk(
    'saleGroup/getSaleGroupListByIds',
    async (ids: number[]) => {
        return await saleGroupsService.getListByIds(ids);
    },
);

export const getSaleGroupDetail = createAsyncThunk(
    'saleGroup/getSaleGroupDetail',
    async (id: number) => {
        return await saleGroupsService.getDetail(id);
    },
);

export const createSaleGroup = createAsyncThunk(
    'saleGroup/createSaleGroup',
    async (formData: ISaleGroupForm) => {
        return await saleGroupsService.create(formData);
    },
);

export const updateSaleGroup = createAsyncThunk(
    'saleGroup/updateSaleGroup',
    async (formData: ISaleGroupUpdateForm) => {
        return await saleGroupsService.updateSaleGroup(formData.id, formData.body);
    },
);

export const importCsvSaleGroup = createAsyncThunk(
    'saleGroup/importCsv',
    async (body: IImportSaleGroup[]) => {
        return await saleGroupsService.importCsv(body);
    },
);

export const saleGroupSlice = createSlice({
    name: 'saleGroup',
    initialState,
    reducers: {
        setHasImportError: (state, action) => {
            state.hasImportError = action.payload;
        },
        setImportSaleGroupList: (state, action) => {
            state.importSaleGroupList = action.payload;
        },
        setSaleGroupListQuery: (state, action) => {
            state.saleGroupListQuery = action.payload;
        },
        setImportCsvFileName: (state, action) => {
            state.importCsvFileName = action.payload;
        },

        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: ISalelGroupGetListQuery = {
                limit: state.saleGroupListQuery.limit,
                orderBy: state.saleGroupListQuery.orderBy,
                orderDirection: state.saleGroupListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.saleGroupListQuery = query;
        },

        setSaleGroupList: (state, action) => {
            state.saleGroupList = action.payload;
        },

        setTotalSaleGroup: (state, action) => {
            state.totalSaleGroups = action.payload;
        },
        setShowForm: (state, action) => {
            state.showForm = action.payload;
        },
        setSelectedSaleGroupList: (state, action) => {
            state.selectedSaleGroupList = action.payload;
        },
        setSelectedSaleGroup: (state, action) => {
            state.selectedSaleGroup = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: ISalelGroupGetListQuery = {
                limit: state.saleGroupListQuery.limit,
                orderBy: state.saleGroupListQuery.orderBy,
                orderDirection: state.saleGroupListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.saleGroupListQuery.keyword ||
                    state.saleGroupListQuery.autoGeneratedCode ||
                    state.saleGroupListQuery.name ||
                    '',
            };
            state.saleGroupListQuery = query;
        },
        setShowLoading: (state, action) => {
            state.showLoading = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(importCsvSaleGroup.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(importCsvSaleGroup.fulfilled, (state, action) => {
            state.showLoading = false;
        });
        builder.addCase(fetchSaleGroupList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.saleGroupList = action.payload?.data?.items || [];
            state.totalSaleGroups = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchSaleGroupList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(createSaleGroup.fulfilled, (state, action) => {
            state.showLoading = false;
        });
        builder.addCase(createSaleGroup.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(updateSaleGroup.fulfilled, (state, action) => {
            state.showLoading = false;
        });
        builder.addCase(updateSaleGroup.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(getSaleGroupDetail.fulfilled, (state, action) => {
            state.showLoading = false;
        });
        builder.addCase(getSaleGroupDetail.pending, (state, action) => {
            state.showLoading = true;
        });

        builder.addCase(getSaleGroupListByIds.fulfilled, (state, action) => {
            state.showLoading = false;
            state.selectedSaleGroupList = action.payload?.data || [];
        });
        builder.addCase(getSaleGroupListByIds.pending, (state, action) => {
            state.showLoading = true;
            state.selectedSaleGroupList = [];
        });
    },
});

export const {
    setSaleGroupListQuery,
    setTotalSaleGroup,
    setSaleGroupList,
    setKeyword,
    setSearchField,
    setShowForm,
    setSelectedSaleGroupList,
    setSelectedSaleGroup,
    setShowLoading,
    setImportCsvFileName,
    setImportSaleGroupList,
    setHasImportError,
} = saleGroupSlice.actions;

export const saleGroupListQuerySelector = (state: AppState) => {
    return state.saleGroup.saleGroupListQuery;
};

export const saleGroupListSelector = (state: AppState) => {
    return state.saleGroup.saleGroupList;
};

export const showLoadingSelector = (state: AppState) => {
    return state.saleGroup.showLoading;
};

export const showFormSelector = (state: AppState) => {
    return state.saleGroup.showForm;
};

export const selectedSaleGroupListSelector = (state: AppState) => {
    return state.saleGroup.selectedSaleGroupList;
};

export const selectedSaleGroupSelector = (state: AppState) => {
    return state.saleGroup.selectedSaleGroup;
};

export const totalSaleGroupsSelector = (state: AppState) => {
    return state.saleGroup.totalSaleGroups;
};

export const totalPageSelector = (state: AppState) => {
    const { totalSaleGroups, saleGroupListQuery } = state.saleGroup;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = saleGroupListQuery;
    return Math.ceil(totalSaleGroups / limit);
};

export const saleGroupSelector = (state: AppState) => {
    return state.saleGroup;
};

export default saleGroupSlice.reducer;
