import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IGridItem } from '~features/inventory/interfaces';
import customDayjs from '~plugins/dayjs';

type Props = {
    style: React.CSSProperties;
    item: IGridItem;
    currentDate: string;
};

const today = customDayjs().format('YYYY-MM-DD');

export const SubHeader = ({ style, item, currentDate }: Props) => {
    const { t } = useTranslation();
    return (
        <div
            style={style}
            className={classNames(
                'stop-selling-cell-item',
                'stop-selling-cell-item-header',
                {
                    today: today === currentDate,
                },
            )}
        >
            <div className={'stop-selling-cell checkbox-col'}>
                {t('inventory.list.inventoryColumn.stoppingRooms')}
            </div>
            <div className="stop-selling-cell remain-room-col text-truncate">
                {t('inventory.list.inventoryColumn.availableRooms')}
            </div>
        </div>
    );
};
