import notificationListJa from './ja/notification-list.locale';
import notificationFormJa from './ja/notification-form.locale';
import notificationMessageJa from './ja/notification-message.locale';
import notificationListEn from './en/notification-list.locale';
import notificationFormEn from './en/notification-form.locale';
import notificationMessageEn from './en/notification-message.locale';

const notification = {
    ja: {
        list: notificationListJa,
        form: notificationFormJa,
        message: notificationMessageJa,
    },
    en: {
        list: notificationListEn,
        form: notificationFormEn,
        message: notificationMessageEn,
    },
};

export default notification;
