import taxListJa from './ja/tax-list.locale';
import taxListEn from './en/tax-list.locale';
const tax = {
    ja: {
        list: taxListJa,
    },
    en: {
        list: taxListEn,
    },
};

export default tax;
