import { IBodyResponse, IGetListResponse, ICsvFile } from '~common/interfaces';
import axiosService from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import { IHistory, IHistoryUpdateBody, IHistoryExportCsvQuery } from '../interfaces';

import { ICommonListQuery } from '~common/interfaces';

class BoHistoryService extends ApiService {
    getHistoryList(
        queryString: ICommonListQuery,
    ): Promise<IBodyResponse<IGetListResponse<IHistory>>> {
        return this._getList(queryString);
    }

    getHistoryDetail(id: number): Promise<IBodyResponse<IGetListResponse<IHistory>>> {
        return this._get(`${this.baseUrl}/${id}`);
    }

    update(id: number, body: IHistoryUpdateBody): Promise<IBodyResponse<IHistory>> {
        return this._patch(`${this.baseUrl}/${id}`, body);
    }

    exportCsv(query: IHistoryExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }
}
export const boHistoryService = new BoHistoryService(
    { baseUrl: '/audit-log' },
    axiosService,
);
