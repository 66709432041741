import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import './KeycardSettingPage.scss';
import KeycardSettingCard from '../components/KeycardSettingCard/KeycardSettingCard';

function KeycardSettingPage() {
    const { t } = useTranslation();

    const breadcrumbs = [
        {
            text: t('keycardSetting.keycard.breadcrumbs.home'),
        },
        {
            text: t('keycardSetting.keycard.breadcrumbs.integrations'),
        },
        {
            text: t('keycardSetting.keycard.breadcrumbs.keycardSetting'),
        },
    ];

    return (
        <div className="integrations-page-wrapper">
            <StaffLayoutHeader
                title={t('keycardSetting.keycard.breadcrumbs.keycardSetting')}
                breadcrumbs={breadcrumbs}
            />
            <div className="page-contents">
                <KeycardSettingCard />
            </div>
        </div>
    );
}

export default KeycardSettingPage;
