import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';

class FileService extends ApiService {
    getPresignedUrl(id: string | number) {
        return this._get(`${this.baseUrl}/presigned-url`, {
            params: {
                id,
            },
        }) as Promise<{ data: string }>;
    }
}

export const fileService = new FileService({ baseUrl: '/file' }, axiosService);
