import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    receiptByGroupMapSelector,
    setReceiptByGroupMap,
} from '~features/facility-booking/reducers/split-receipt.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './BottomButtonGroup.scss';

function BottomButtonGroup() {
    const { t } = useTranslation();
    const { facilityBookingId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onReturn = () => {
        dispatch(setReceiptByGroupMap({}));
        navigate(`/facility-booking/${facilityBookingId}/detail`);
    };

    return (
        <div className="split-facility-booking-bottom-button-group-wrapper">
            <Button onClick={onReturn}>
                {t('facilityBooking.splitReceipt.bottomButtonGroup.buttonReturnText')}
            </Button>
        </div>
    );
}

export default BottomButtonGroup;
