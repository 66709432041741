const stopSelling = {
    title: '在庫内訳管理',
    buttons: {
        save: '保存する',
        bulkSale: '一括売止',
        addFormItem: '追加',
    },
    cancelTitle: 'キャンセルしてもよろしいですか？',
    guideMessage:
        '1つのチェックボックスをクリックしShiftキーを押しながら水平方向にある次ののチェックボックスをクリックすると、2つのチェックボックスの間にあるチェックボックスを選択/選択解除されます。',
    monthYearFormat: '{{year}}年 {{month}}月',
    stopSellingLabel: '売止',
    bulkForm: {
        title: '一括売止設定',
        roomTypeId: {
            label: '部屋タイプ',
            placeholder: '選択してください',
        },
        roomId: {
            placeholder: '室名',
        },
        rangeDay: {
            placeholder: {
                start: '開始日',
                end: '終了日',
            },
        },
    },
    message: {
        rangeDayError: '終了日は開始日後に設定ください',
        rangeThreeMonthError: ' {{month}} ヶ月以上はブロックできません',
    },
    autoAssignRoom: '自動的にアサインされる部屋です',
};

export default stopSelling;
