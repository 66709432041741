import React from 'react';
import { IInventoryForm } from '~features/inventory/interfaces';

type Props = {
    style: React.CSSProperties;
    item: IInventoryForm;
};

export const RowHeader = ({ style, item }: Props) => {
    return (
        <div style={style} className="inventory-row-header inventory-cell">
            <span className="text-truncate">{item?.roomType?.name}</span>
        </div>
    );
};
