import { INPUT_TEXT_MAX_LENGTH, NUMBER_OF_ROOM, Regex } from '~common/constants';
import yup from '~plugins/yup';
import { BANK_ACCOUNT_TYPE } from './constants';

export const updateHotelSettingSchema = yup.object().shape({
    postalCode: yup.string().trim().isPostalCode().max(10).optional().nullable(),
    address: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .optional()
        .nullable(),
    picName: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .optional()
        .nullable(),
    picEmailAddress: yup
        .string()
        .trim()
        .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
        .max(INPUT_TEXT_MAX_LENGTH)
        .isEmail()
        .optional()
        .nullable(),
    picPhoneNumber: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .isPhone()
        .optional()
        .nullable(),
    numberOfRoom: yup
        .string()
        .transform((value) => {
            return isNaN(value) ? null : value;
        })
        .minFullAndHalfWidth(1)
        .maxFullAndHalfWidth(NUMBER_OF_ROOM)
        .nullable()
        .optional(),
    standardTimes: yup
        .array()
        .of(yup.string().optional().nullable())
        .optional()
        .nullable(),
    registeredNumber: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .optional()
        .nullable(),
    isRegisteredNumberNotObtained: yup.boolean().optional().nullable(),
});

export const hotelFormSchema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .required(),
    picName: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .optional()
        .nullable(),
    postalCode: yup.string().trim().isPostalCode().max(10).optional().nullable(),
    address: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .optional()
        .nullable(),
    picPhoneNumber: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .isPhone()
        .optional()
        .nullable(),
    picEmailAddress: yup
        .string()
        .trim()
        .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
        .max(INPUT_TEXT_MAX_LENGTH)
        .isEmail()
        .optional()
        .nullable(),
    numberOfRoom: yup
        .string()
        .transform((value) => {
            return isNaN(value) ? null : value;
        })
        .minFullAndHalfWidth(1)
        .maxFullAndHalfWidth(NUMBER_OF_ROOM)
        .nullable()
        .optional(),
    standardTimes: yup
        .array()
        .of(yup.string().optional().nullable())
        .optional()
        .nullable(),
    peCsrHotelCode: yup.string().trim().max(20).required(),
    tllSystemId: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    tllPmsUserId: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    tllPmsPassword: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    registeredNumber: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .optional()
        .nullable(),
    isRegisteredNumberNotObtained: yup.boolean().optional().nullable(),
});

export const bankAccountBodySchema = yup.object().shape({
    bankName: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    branchName: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    accountType: yup.mixed().oneOf(Object.values(BANK_ACCOUNT_TYPE)).required(),
    accountNumber: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    accountOwner: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
});
