import staffJa from './ja/staff.locale';
import guestJa from './ja/guest.locale';

import staffEn from './en/staff.locale';
import guestEn from './en/guest.locale';

const layout = {
    ja: {
        staff: staffJa,
        guest: guestJa,
    },
    en: {
        staff: staffEn,
        guest: guestEn,
    },
};

export default layout;
