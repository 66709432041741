import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Divider, Form, notification, Row } from 'antd';
import { parseDate } from '~plugins/dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showConfirm } from '~common/notification';
import { InputPrice, RightDrawerLayout, SingleSelect } from '~components';
import { FacilityBookingStatus } from '~features/facility-booking/constants';
import { paymentMethodStateSelector } from '~features/payment-method/reducers/paymentMethod.reducer';
import {
    ReceiptItemBookingType,
    ReceiptItemType,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import { IUpdateRoomBookingReceipt } from '~features/room-booking/interfaces';
import {
    getRoomBookingReceipt,
    roomBookingStateSelector,
    setIsShowPaymentModal,
    updateRoomBookingReceipt,
} from '~features/room-booking/reducers/room-booking.reducer';
import { paymentRoomBookingSchema } from '~features/room-booking/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import { PaymentMethodSelect } from '../PaymentMethodSelect/PaymentMethodSelect';
import './PaymentModal.scss';
import { getAutoGeneratedCode } from '~features/room-booking/helper';
import { formatMoney } from '~common/commonFunctions';
import { useEscape } from '~common/useHooks';

type IProp = {
    totalPrice: { totalSale: number; totalPayment: number };
    totalTax: number;
    paymentRoomBookingItemOptions: { value: number; label: string | undefined }[];
};

function PaymentModal({ totalPrice, totalTax, paymentRoomBookingItemOptions }: IProp) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [inputPaymentAmount, setInputPaymentAmount] = useState(0);

    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);
    const {
        isShowPaymentModal,
        roomBookingReceipt,
        selectedRoomBookingDetail,
        isShowUpdateReceiptLoading,
    } = useAppSelector(roomBookingStateSelector);

    const { control, reset, handleSubmit, setValue, formState } = useForm({
        resolver: yupResolver(paymentRoomBookingSchema),
    });

    useEffect(() => {
        if (isShowPaymentModal) {
            const paymentAmount = totalPrice.totalSale - totalPrice.totalPayment;
            setValue('paymentAmount', paymentAmount >= 0 ? paymentAmount : null);
            setInputPaymentAmount(paymentAmount >= 0 ? paymentAmount : 0);
        }
    }, [isShowPaymentModal]);

    const closeForm = () => {
        reset();
        setInputPaymentAmount(0);
        dispatch(setIsShowPaymentModal(false));
    };

    const bookingOptions = useMemo(() => {
        const isGroupBooking =
            roomBookingReceipt?.roomBooking?.roomBookingItems &&
            roomBookingReceipt?.roomBooking?.roomBookingItems?.length > 1;
        const roomBookingReceiptOptions =
            roomBookingReceipt?.roomBooking?.roomBookingItems
                ?.filter((roomBookingItem) => {
                    return (
                        roomBookingItem.bookingStatus ===
                            RoomBookingItemBookingStatus.CHECKED_IN ||
                        roomBookingItem.bookingStatus ===
                            RoomBookingItemBookingStatus.NOT_ARRIVED
                    );
                })
                ?.map((roomBookingItem, index) => {
                    let label = t('roomBooking.detail.roomItem');
                    if (isGroupBooking) {
                        label = `${label}${index + 1}`;
                    }
                    return {
                        label: `${label} ${getAutoGeneratedCode(
                            roomBookingReceipt?.roomBooking?.autoGeneratedCode,
                        )}`,
                        value: `hol_${roomBookingItem.id}`,
                    };
                }) || [];
        const facilityBookingReceiptOptions =
            roomBookingReceipt?.facilityBookings
                ?.filter((facilityBooking) => {
                    return (
                        facilityBooking.status === FacilityBookingStatus.RESERVED ||
                        facilityBooking.status === FacilityBookingStatus.STARTED
                    );
                })
                ?.map((facilityBooking) => {
                    return {
                        label: `${t(
                            'roomBooking.detail.facilityItem',
                        )} ${getAutoGeneratedCode(facilityBooking.autoGeneratedCode)}`,
                        value: `fac_${facilityBooking.id}`,
                    };
                }) || [];
        return [...roomBookingReceiptOptions, ...facilityBookingReceiptOptions];
    }, [roomBookingReceipt]);

    const showConfirmCancel = () => {
        showConfirm({
            title: t('facilityBooking.schedule.checkInForm.cancelTitle'),
            onOk() {
                closeForm();
            },
        });
    };
    useEscape(closeForm);

    const totalAmount = useMemo(() => {
        return totalPrice.totalSale - (totalPrice.totalPayment + inputPaymentAmount);
    }, [totalPrice.totalSale, totalPrice.totalPayment, inputPaymentAmount]);

    const changePaymentAmount = (value: number) => {
        setInputPaymentAmount(value);
    };

    const _updateRoomBookingReceipt = async (
        updateReceipt: IUpdateRoomBookingReceipt,
    ) => {
        const response = await dispatch(updateRoomBookingReceipt(updateReceipt));
        if (updateRoomBookingReceipt.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomBooking.detail.updateSuccessMessage'),
                });
                closeForm();
                if (selectedRoomBookingDetail?.id) {
                    await dispatch(getRoomBookingReceipt(selectedRoomBookingDetail?.id));
                }
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };

    const isRoomBookingReceipt = (bookingId: string) => {
        return ((bookingId as string) || '')?.split('_')?.[0] === 'hol';
    };

    const getPayAt = (bookingId: string) => {
        const isRoomBooking = isRoomBookingReceipt(bookingId);
        const selectedBookingId = +(bookingId as string).split('_')?.[1];
        if (isRoomBooking) {
            const selectedBookingItem =
                roomBookingReceipt?.roomBooking?.roomBookingItems?.find(
                    (item) => item.id === selectedBookingId,
                );
            return selectedBookingItem?.startDateOfStay
                ? parseDate(selectedBookingItem?.startDateOfStay)
                      ?.startOf('day')
                      ?.fmYYYYMMDDHHmmss('-')
                : null;
        }
        const selectedBookingItem = roomBookingReceipt?.facilityBookings?.find(
            (item) => item.id === selectedBookingId,
        );
        return selectedBookingItem?.startDatetime
            ? parseDate(selectedBookingItem?.startDatetime)
                  ?.startOf('day')
                  ?.fmYYYYMMDDHHmmss('-')
            : null;
    };

    const getPaymentRoomBookingItemId = (bookingId: string) => {
        const isRoomBooking = isRoomBookingReceipt(bookingId);
        const selectedBookingId = +(bookingId as string).split('_')?.[1];
        if (isRoomBooking) {
            const selectedBookingItem =
                roomBookingReceipt?.roomBooking?.roomBookingItems?.find(
                    (item) => item.id === selectedBookingId,
                );

            return (
                selectedBookingItem?.receiptItems?.[0]?.paymentRoomBookingItem?.id ||
                selectedBookingId
            );
        }
        const selectedBookingItem = roomBookingReceipt?.facilityBookings?.find(
            (item) => item.id === selectedBookingId,
        );
        const paymentRoomBookingItemId =
            selectedBookingItem?.receiptItems?.[0]?.paymentRoomBookingItem?.id;
        if (paymentRoomBookingItemId) {
            return paymentRoomBookingItemId;
        }
        return roomBookingReceipt?.roomBooking?.roomBookingItems?.[0]?.id || undefined;
    };

    const submit = () => {
        handleSubmit((data) => {
            const { bookingId, paymentAmount, paymentMethodId } = data;
            const isRoomBooking = isRoomBookingReceipt(bookingId);
            const selectedBookingId = +(bookingId as string).split('_')?.[1];
            const body = {
                type: ReceiptItemType.PAYMENT,
                paymentRoomBookingItemId: getPaymentRoomBookingItemId(bookingId),
                paymentMethodId,
                receiptItemDetails: [
                    {
                        amount: Number(paymentAmount),
                        payAt: getPayAt(bookingId) || undefined,
                    },
                ],
                bookingType: isRoomBooking
                    ? ReceiptItemBookingType.ROOM_BOOKING_ITEM
                    : ReceiptItemBookingType.FACILITY_BOOKING,
                bookingId: selectedBookingId,
            };
            if (roomBookingReceipt?.id) {
                _updateRoomBookingReceipt({
                    id: roomBookingReceipt?.id,
                    body: { items: [body] },
                });
            }
        })();
    };

    return (
        <div className="payment-modal">
            <RightDrawerLayout
                open={isShowPaymentModal}
                onClose={showConfirmCancel}
                onSubmit={submit}
                title={t('roomBooking.detail.receipt.paymentModal.title')}
                cancelText={t('common.buttonCancelText')}
                submitText={t('roomBooking.detail.receipt.paymentModal.calculateButton')}
                className="receipt-booking-form-wrapper"
                loading={isShowUpdateReceiptLoading}
                busy={isShowUpdateReceiptLoading}
                disabledSaveButton={!formState.isValid}
            >
                <Form layout="vertical" autoComplete="off">
                    <Row>
                        <Col span={24}>
                            <SingleSelect
                                name="bookingId"
                                label={t(
                                    'roomBooking.detail.receipt.paymentModal.bookingID.label',
                                )}
                                placeholder={t(
                                    'roomBooking.detail.receipt.paymentModal.bookingID.placeholder',
                                )}
                                control={control}
                                options={bookingOptions}
                                defaultValue={bookingOptions?.[0]?.value}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <PaymentMethodSelect
                                name="paymentMethodId"
                                label={t(
                                    'roomBooking.detail.receipt.paymentModal.paymentMethod.label',
                                )}
                                placeholder={t(
                                    'roomBooking.detail.receipt.paymentModal.paymentMethod.placeholder',
                                )}
                                allowClear
                                control={control}
                                paymentMethodOptions={paymentMethodDropDown}
                                optionLabelProp="label"
                                showSearch={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <InputPrice
                                name="paymentAmount"
                                label={t(
                                    'roomBooking.detail.receipt.paymentModal.paymentAmount.label',
                                )}
                                prefix="¥"
                                placeholder={t(
                                    'roomBooking.detail.receipt.paymentModal.paymentAmount.placeholder',
                                )}
                                onChange={(e) =>
                                    changePaymentAmount(Number(e.target?.value) || 0)
                                }
                                allowClear
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="p-8 d-flex j-between a-center">
                                <span>
                                    {t(
                                        'facilityBooking.detail.receipt.summaryTotal.totalSales',
                                    )}
                                </span>
                                <span className="price">
                                    {t('common.yen', {
                                        number: formatMoney(totalPrice.totalSale),
                                    })}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="p-8 d-flex j-between a-center">
                                <span>
                                    {t(
                                        'facilityBooking.detail.receipt.summaryTotal.otherTaxes',
                                    )}
                                </span>
                                <span className="price">
                                    {t('common.yen', {
                                        number: formatMoney(totalTax),
                                    })}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className=" p-8 payment-amount">
                                <div className=" d-flex j-between a-center">
                                    <span>
                                        {t(
                                            'facilityBooking.detail.receipt.summaryTotal.payment',
                                        )}
                                    </span>
                                    <span className="price">
                                        {t('common.yen', {
                                            number: formatMoney(totalPrice.totalPayment),
                                        })}
                                    </span>
                                </div>
                                <div className="input-payment-amount">
                                    <span>{inputPaymentAmount >= 0 ? '+' : '-'}</span>
                                    <span>￥</span>
                                    <span>{`${formatMoney(
                                        Math.abs(inputPaymentAmount),
                                    )}`}</span>
                                </div>
                            </div>
                            <Divider className="divider" plain />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="p-8 total-receivable d-flex j-between a-center">
                                <span>
                                    {t(
                                        'facilityBooking.detail.receipt.summaryTotal.totalReceivable',
                                    )}
                                </span>
                                <span className="price">
                                    {t('common.yen', {
                                        number: formatMoney(
                                            totalAmount >= 0 ? totalAmount : 0,
                                        ),
                                    })}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="p-8 d-flex j-between a-center">
                                <span>
                                    {t(
                                        'facilityBooking.detail.receipt.summaryTotal.change',
                                    )}
                                </span>
                                <span className="price">
                                    {t('common.yen', {
                                        number: formatMoney(
                                            totalAmount >= 0 ? 0 : 0 - totalAmount,
                                        ),
                                    })}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </RightDrawerLayout>
        </div>
    );
}

export default PaymentModal;
