import { parseDate } from '~plugins/dayjs';
import { cloneDeep, isEmpty } from 'lodash';
import {
    DEFAULT_BILL_PROVISO,
    Gender,
    ReceiptByGroupMapIndex,
    ReceiptItemDetailType,
    ReceiptItemType,
} from './constants';
import {
    IFacilityBookingReceipt,
    IFacilityBookingReceiptItem,
    IFacilityBookingReceiptItemDetail,
    IGroup,
    IPrintingFacilityReceiptItemDetail,
    IReceiptByGroup,
    IReceiptByGroupMap,
    ISplitFacilityBookingReceiptTableData,
} from './interfaces';

/**
 * @param groupList
 * @param receipt
 * @returns group map
 *
 * This function take a group list (room list corresponding to split by room option).
 * Create a group map that have key is the id of the group, value is the receipt splitted to that group
 * The map also have a specific key is 'ALL' that holds the original receipt
 *
 * At first, only key: 'ALL' have data in the receipt, the rest key will holds
 * an empty receipt that have receipt item details (sale item, etc.) is an empty array
 */
export const getSplitReceiptByGroupMap = (
    groupList: IGroup[],
    receipt: IFacilityBookingReceipt | null,
    defaultGuestName: string,
): IReceiptByGroupMap => {
    if (!receipt) return {};

    const emptyReceipt = Object.assign(cloneDeep(receipt), {
        facilityBooking: {
            ...receipt.facilityBooking,
            receiptItems: receipt.facilityBooking.receiptItems.map((item) => {
                return {
                    ...item,
                    receiptItemDetails: [],
                };
            }),
        },
    });

    const defaultReceipt = Object.assign(cloneDeep(receipt), {
        facilityBooking: {
            ...receipt.facilityBooking,
            receiptItems: receipt.facilityBooking.receiptItems.map((item) => {
                return {
                    ...item,
                    receiptItemDetails: item.receiptItemDetails.map((detail) => ({
                        ...detail,
                        groupId: groupList[0]?.id,
                    })),
                };
            }),
        },
    });

    return groupList.reduce(
        (receiptByGroupMap: IReceiptByGroupMap, group: IGroup, index: number) => {
            receiptByGroupMap[`${group.id}`] = {
                group,
                receipt: index === 0 ? defaultReceipt : emptyReceipt,
                guestName: defaultGuestName,
                proviso: DEFAULT_BILL_PROVISO,
            };
            return receiptByGroupMap;
        },
        {
            [ReceiptByGroupMapIndex.ALL]: {
                group: null,
                receipt: defaultReceipt,
                guestName: defaultGuestName,
            },
        } as IReceiptByGroupMap,
    );
};

/**
 * @param receipt
 *
 * This function generate data for split receipt table from receipt
 */
export const convertSplitReceipt = (
    receipt: IFacilityBookingReceipt | null,
): ISplitFacilityBookingReceiptTableData[] => {
    if (!receipt) return [];

    const facilityBooking = receipt.facilityBooking;
    const receiptItems = facilityBooking.receiptItems;

    const getBookingDetail = (receiptItemDetail: IFacilityBookingReceiptItemDetail) => {
        if (receiptItemDetail?.bookingDetail) {
            return receiptItemDetail.bookingDetail;
        }

        switch (receiptItemDetail?.type) {
            case ReceiptItemDetailType.SALE_ITEM:
                return receiptItemDetail?.saleItem?.name;
            case ReceiptItemDetailType.STAY_PRICE:
                return facilityBooking?.facility?.name;
            case ReceiptItemDetailType.PAYMENT:
                return facilityBooking.receiptItems.find(
                    (receiptItem) =>
                        !!receiptItem.receiptItemDetails.find(
                            (item) => item.id === receiptItemDetail.id,
                        ),
                )?.paymentMethod?.name;
            default:
                return '';
        }
    };

    const convertedReceiptItems: ISplitFacilityBookingReceiptTableData[] =
        receiptItems.reduce(
            (
                convertedReceiptItems: ISplitFacilityBookingReceiptTableData[],
                receiptItem: IFacilityBookingReceiptItem,
            ) => {
                if (!receiptItem.receiptItemDetails.length) return convertedReceiptItems;
                receiptItem?.receiptItemDetails.forEach((receiptItemDetail) => {
                    convertedReceiptItems.push({
                        id: `fac_${receiptItem.id}_${receiptItemDetail.id}`,
                        bookingDate:
                            receiptItem.type === ReceiptItemType.PAYMENT
                                ? parseDate(receiptItemDetail.payAt)?.fmYYYYMMDD('-')
                                : parseDate(receiptItemDetail.boughtAt)?.fmYYYYMMDD('-'),
                        groupId: receiptItemDetail.groupId || null,
                        room: receiptItem.paymentRoomBookingItem?.room || null,
                        bookingDetail: getBookingDetail(receiptItemDetail),
                        checkOutRoom: receiptItem?.paymentRoomBookingItem?.room || null,
                        guest: {
                            ...facilityBooking?.guest,
                            gender: facilityBooking?.guest?.gender as Gender,
                        },
                        unitPrice: receiptItemDetail?.unitPrice,
                        quantity: receiptItemDetail?.quantity,
                        amount: receiptItemDetail?.amount,
                        paymentMethod: receiptItem?.paymentMethod,
                        type: receiptItem.type as ReceiptItemType,
                        facilityBooking,
                        receiptItem,
                        receiptItemDetail,
                        level: 1,
                        printDate:
                            receiptItemDetail?.printDate || parseDate(facilityBooking.startDatetime).utc().fmYYYYMMDD('-'),
                    });
                });
                return convertedReceiptItems;
            },
            [],
        );
    return [
        {
            id: receipt.id.toString(),
            autoGeneratedCode: receipt?.facilityBooking?.autoGeneratedCode,
            groupId: null,
            name: `roomBooking.splitReceipt.facilityItem`,
            children: convertedReceiptItems,
            level: 0,
            status: facilityBooking.status,
        },
    ];
};

export const isSplitAllReceiptItemDetails = (receipt: IFacilityBookingReceipt | null) => {
    if (!receipt) return true;

    for (const receiptItem of receipt.facilityBooking.receiptItems) {
        for (const receiptItemDetail of receiptItem.receiptItemDetails) {
            if (isEmpty(receiptItemDetail.groupId)) return false;
        }
    }

    return true;
};

export const updateNewFacilityReceiptItemDetail = (
    receiptByGroup: IReceiptByGroup,
    updatingItem: ISplitFacilityBookingReceiptTableData,
    newReceiptItemDetail: IPrintingFacilityReceiptItemDetail,
) => {
    const updatingReceiptItem = receiptByGroup.receipt.facilityBooking.receiptItems.find(
        (item: IFacilityBookingReceiptItem) => item.id === updatingItem.receiptItem?.id,
    );
    updatingReceiptItem?.receiptItemDetails.forEach(
        (receiptItemDetail: IFacilityBookingReceiptItemDetail) => {
            if (updatingItem.receiptItemDetail?.id === receiptItemDetail.id) {
                if (newReceiptItemDetail?.bookingDetail) {
                    receiptItemDetail.bookingDetail = newReceiptItemDetail.bookingDetail;
                }

                if (newReceiptItemDetail?.printDate) {
                    receiptItemDetail.printDate = newReceiptItemDetail.printDate;
                }

                const newUnitPrice =
                    newReceiptItemDetail?.unitPrice ?? receiptItemDetail.unitPrice;
                const newQuantity =
                    newReceiptItemDetail?.quantity ?? receiptItemDetail.quantity;
                const newAmount =
                    newReceiptItemDetail?.amount ??
                    (newUnitPrice && newQuantity
                        ? newUnitPrice * newQuantity
                        : receiptItemDetail?.amount);

                receiptItemDetail.unitPrice = newUnitPrice;
                receiptItemDetail.quantity = newQuantity;
                receiptItemDetail.amount = newAmount;
            }
        },
    );
};
