import { notification } from 'antd';

function configNotification() {
    notification.config({
        placement: 'topRight',
        top: 50,
        duration: 3,
        rtl: false,
    });
}

export default configNotification;
