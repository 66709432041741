import { Layout, Spin } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { useCallback, useEffect, useState } from 'react';
import { PrintingFonts, PrintingOrientation, PrintingPaperSize } from '~common/constants';
import { PdfPrintingModal } from '~components';
import {
    printFacilityBookingListSelector,
    getCountryList,
} from '~features/facility-booking/reducers/print-facility-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './FacilityBookingPrintingModal.scss';
import { generateFacilityookingPdf } from './generateFacilityBookingContent';

interface IProps {
    isShowFacilityBookingPrintingModal: boolean;
    onClose: React.MouseEventHandler<HTMLElement>;
}

export default function FacilityBookingPrintingModal(props: IProps) {
    const { isShowFacilityBookingPrintingModal, onClose } = props;
    const { printFacilityBookingList, countryList } = useAppSelector(
        printFacilityBookingListSelector,
    );
    const [url, setUrl] = useState<string>();
    const [pdf, setPdf] = useState<TCreatedPdf>();
    const [orientation, setOrientation] = useState<PrintingOrientation>(
        PrintingOrientation.LANDSCAPE,
    );
    const [paperSize, setPaperSize] = useState<PrintingPaperSize>(PrintingPaperSize.A4);
    const [totalPage, setTotalPage] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getCountryList());
    }, []);

    useEffect(() => {
        createPdf();
    }, [printFacilityBookingList, orientation, paperSize]);

    useEffect(() => {
        cloneDeep(pdf)?.getDataUrl((dataUrl: string) => {
            setUrl(dataUrl);
        });
    }, [pdf]);

    const onPrint = useCallback(() => {
        cloneDeep(pdf)?.print();
    }, [pdf]);

    const onChangeOrientation = (orientation: PrintingOrientation) => {
        setUrl('');
        setOrientation(orientation);
    };

    const onChangePaperSize = (paperSize: PrintingPaperSize) => {
        setUrl('');
        setPaperSize(paperSize);
    };

    const createPdf = () => {
        setUrl('');
        pdfMake.fonts = PrintingFonts;
        setPdf(
            pdfMake.createPdf(
                generateFacilityookingPdf(printFacilityBookingList, {
                    orientation,
                    setTotalPage,
                    countryList,
                    paperSize,
                }),
            ),
        );
    };

    return (
        <PdfPrintingModal
            totalPage={totalPage}
            onPrint={onPrint}
            onClose={onClose}
            onChangeOrientation={onChangeOrientation}
            onChangePaperSize={onChangePaperSize}
            isShowPdfPrinting={isShowFacilityBookingPrintingModal}
            defaultOrientation={PrintingOrientation.LANDSCAPE}
        >
            <Layout className="facility-booking-printing-layout">
                <Layout.Content className="facility-booking-printing-content">
                    {url ? (
                        <iframe className="pdf-view" src={`${url}#toolbar=0`} />
                    ) : (
                        <Spin />
                    )}
                </Layout.Content>
            </Layout>
        </PdfPrintingModal>
    );
}
