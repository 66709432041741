const saleItemImport = {
    title: 'CSVアップロード',
    cancelTitle: 'Do you want to cancel?',
    cancelBtn: 'キャンセル',
    submitBtn: 'アップロード',
    importErrorKey: {
        name: '科目',
        price: '標準価格',
        isShowInBooking: '手配品リスト表示',
        taxName: '消費税区分',
    },
    importSaleItemSuccess: 'CSVファイルのアップロードに成功しました',
    error: {
        name: {
            required: '科目を入力してください',
            maxLength: '科目の最大文字数は{{maxLength}}字です',
            uniqueError: '科目は重複してはなりません',
            hasNoSpecialCharacters: '科目は特殊文字を含めません',
        },
        price: {
            required: '標準価格を入力してください',
            max: '標準価は最大で{{max}}です',
            min: '標準価格は最低0でなければいけません',
            integer: '標準価格は整数でなければいけません',
        },
        taxName: {
            required: '消費税区分を入力してください',
            maxLength: '消費税区分は最大{{maxLength}}字です',
            hasNoSpecialCharacters: '消費税区分はは特殊文字を含めません',
        },
    },
};

export default saleItemImport;
