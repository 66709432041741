import { Collapse, CollapseProps } from 'antd';
import './SingleCollapse.scss';

interface ISingleCollapseProps extends CollapseProps {
    expand: boolean;
    onChange: () => void;
    title: string;
}

export const SingleCollapse = (props: ISingleCollapseProps) => {
    const { expand, onChange, title } = props;
    return (
        <Collapse
            {...props}
            bordered={false}
            className="single-collapse"
            activeKey={expand ? 'single_collapse' : ''}
            onChange={onChange}
        >
            <Collapse.Panel
                header={title}
                key="single_collapse"
                className="create-room-type-collapse-panel"
            >
                {props.children}
            </Collapse.Panel>
        </Collapse>
    );
};
