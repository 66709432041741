export const mealReportEn = {
    title: 'Meal Report',
    breadcrumbs: {
        report: 'Report',
        meal: 'Meal Report',
    },
    filterForm: {
        dateLabel: 'Collected period:',
        displayButton: 'Display',
        datePicker: {
            placeholder: {
                start: 'Start date',
                end: 'End date',
            },
        },
    },
    table: {
        mealName: 'Product Name',
        nightOfStay: 'Night',
        subtotal: 'Subtotal',
        total: 'Total',
        allTotal: 'Total (all)',
    },
    exportColumns: {
        mealName: 'Product Name',
        nightOfStay: 'Night',
        subtotal: 'Subtotal',
        total: 'Total',
        allTotal: 'Total (all)',
    },
    exportCSV: 'CSV export',
};
