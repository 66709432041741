import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { IReportUnpaidDetailState } from '../interfaces';
import { reportService } from '../report.service';

const initialState: IReportUnpaidDetailState = {
    unpaidDetailList: [],
    showUnpaidDetailTable: false,
    showLoadingUnpaidDetailTable: false,
    selectDate: null,
};

export const getUnpaidDetailList = createAsyncThunk(
    'unpaidDetail/getUnpaidDetailList',
    async (datePeriod: { startTime: string; endTime: string }) => {
        return await reportService.getUnpaidDetail([
            datePeriod.startTime,
            datePeriod.endTime,
        ]);
    },
);

const unpaidDetailSlice = createSlice({
    name: 'unpaid-detail',
    initialState,
    reducers: {
        setShowUnpaidDetailTable: (state, action) => {
            state.showUnpaidDetailTable = action.payload;
        },
        setSelectDate: (state, action) => {
            state.selectDate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUnpaidDetailList.pending, (state, action) => {
            state.showLoadingUnpaidDetailTable = true;
        });
        builder.addCase(getUnpaidDetailList.fulfilled, (state, action) => {
            state.showLoadingUnpaidDetailTable = false;
            state.unpaidDetailList = action.payload?.data || [];
        });
    },
});

export const { setShowUnpaidDetailTable, setSelectDate } = unpaidDetailSlice.actions;

export const unpaidDetailSelector = (state: AppState) => {
    return state.reportUnpaidDetail;
};

export default unpaidDetailSlice.reducer;
