import { Button, Pagination, Table, Typography } from 'antd';
import { CsvDropdown } from '~components';
import { ReloadOutlined } from '@ant-design/icons';
import './FacilityBookingHistoryList.scss';
import { ColumnsType } from 'antd/lib/table';
import {
    exportFacilityBookingHistoryColumns,
    EXPORT_CSV_FACILITY_BOOKING_HISTORY_FILE_NAME,
    FACILITY_BOOKING_HISTORY_FILE_NAME,
    FacilityBookingHistoryColumn,
    i18FacilityBookingHistoryExportKey,
    OrderBy,
} from '~features/guest/constants';
import { useTranslation } from 'react-i18next';
import {
    IFacilityBookingHistoryForTable,
    IFacilityBookingHistory,
    IBookingHistoryExportCsvQuery,
} from '~features/guest/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    facilityBookingHistoryQuerySelector,
    getFacilityBookingHistoryData,
    getFacilityBookingHistoryDataSelector,
    setFacilityBookingHistoryQuery,
    showLoadingFacilityBookingHistorySelector,
    totalFacilityBookingSelector,
} from '~features/guest/reducers/guest.reducer';
import { downloadFile, exportCSVFile, parseDateTime } from '~common/commonFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import {
    AntdOrderDirection,
    CsvOption,
    DateFormat,
    DEFAULT_LIMIT_FOR_PAGINATION,
    OrderDirection,
} from '~common/constants';
import { TableProps } from 'antd/es/table';
import { ISorter } from '~common/interfaces';
import { guestService } from '~features/guest/services/guest.service';
import { orderBy } from 'lodash';

function FacilityBookingHistoryList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { Title } = Typography;
    const { guestId } = useParams();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const showLoading = useAppSelector(showLoadingFacilityBookingHistorySelector);
    const totalFacility = useAppSelector(totalFacilityBookingSelector);
    const facilityBookingHistoryData = useAppSelector(
        getFacilityBookingHistoryDataSelector,
    );
    const facilityBookingHistoryQuery = useAppSelector(
        facilityBookingHistoryQuerySelector,
    );

    const fetchData = () => {
        dispatch(getFacilityBookingHistoryData(Number(guestId)));
    };

    useEffect(() => {
        fetchData();
    }, [facilityBookingHistoryQuery]);

    const onRefresh = async () => {
        await fetchData();
    };

    const convertToTableData = (data: IFacilityBookingHistory | null) => {
        if (!data) return [];
        return data?.bookings?.items.map((item) => {
            return {
                id: item?.id,
                fullName: data.guest?.fullName,
                yomigana: data.guest?.yomigana,
                autoGeneratedCode: item?.autoGeneratedCode,
                startDatetime: item?.startDatetime,
                endDatetime: item?.endDatetime,
                facilityName: item?.facility?.name,
            };
        });
    };

    const tableData = convertToTableData(facilityBookingHistoryData);
    const pageCount = Math.ceil(
        totalFacility /
            (facilityBookingHistoryQuery.limit || DEFAULT_LIMIT_FOR_PAGINATION),
    );

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const rows: IFacilityBookingHistoryForTable[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundFacilityBooking = tableData.find(
                (facilityBooking) => facilityBooking.id === selectedRowKey,
            );
            if (foundFacilityBooking) rows.push(foundFacilityBooking);
        });
        return rows;
    }, [selectedRowKeys]);

    const onChange: TableProps<IFacilityBookingHistoryForTable>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setFacilityBookingHistoryQuery({
                    ...facilityBookingHistoryQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setFacilityBookingHistoryQuery({
                    ...facilityBookingHistoryQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const onChangePage = (page: number) => {
        setSelectedRowKeys([]);
        dispatch(
            setFacilityBookingHistoryQuery({ ...facilityBookingHistoryQuery, page }),
        );
    };

    const editRecord = (booking: IFacilityBookingHistoryForTable) => {
        return {
            onClick: () => {
                if (booking.id) {
                    window.open(`/facility-booking/${booking?.id}/detail`);
                }
            },
        };
    };

    const exportCsv = async (query: IBookingHistoryExportCsvQuery) => {
        const response = await guestService.exportCsvFacilityBookingHistory(
            Number(guestId),
            query,
        );
        if (response.success) {
            downloadFile(FACILITY_BOOKING_HISTORY_FILE_NAME, response.data.filePath);
        }
    };

    const convertDataToCsv = (roomBookingHistory: IFacilityBookingHistoryForTable[]) => {
        const dataOutput: any[] = [];
        roomBookingHistory.forEach((roomBooking) => {
            const data = {
                autoGeneratedCode: roomBooking.autoGeneratedCode,
                fullName: roomBooking.fullName,
                yomigana: roomBooking.yomigana,
                facilityName: roomBooking.facilityName,
                checkInTime: parseDateTime(
                    roomBooking.startDatetime ? roomBooking.startDatetime : '',
                    DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                ),
                checkOutTime: parseDateTime(
                    roomBooking.endDatetime ? roomBooking.endDatetime : '',
                    DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                ),
            };
            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportFacilityBookingHistory = async (
        facilityBookingHistory: IFacilityBookingHistoryForTable[],
    ) => {
        try {
            // Create header file csv
            const filename = `${EXPORT_CSV_FACILITY_BOOKING_HISTORY_FILE_NAME}_${new Date().getTime()}.csv`;

            const facilityBookingHistoryDataCsv =
                convertDataToCsv(facilityBookingHistory);
            await exportCSVFile(
                exportFacilityBookingHistoryColumns,
                filename,
                facilityBookingHistoryDataCsv,
                i18FacilityBookingHistoryExportKey,
            );

            return {
                filename,
                filePath: `${process.env.FILE_STORAGE_BASE_URL}/${process.env.FILE_STORAGE_GUEST_CSV_FOLDER}/${filename}`,
            };
        } catch (error) {
            throw error;
        }
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all guest
                await exportCsv({
                    orderBy: facilityBookingHistoryQuery.orderBy,
                    orderDirection: facilityBookingHistoryQuery.orderDirection,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportFacilityBookingHistory(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                // ToDo: import
                break;
            }
            default:
                break;
        }
    };

    const facilityBookingHistoryColumn: ColumnsType<IFacilityBookingHistoryForTable> = [
        {
            title: t('guest.detail.facilityBookingHistory.column.autoGeneratedCode'),
            key: FacilityBookingHistoryColumn.AUTO_GENERATED_CODE,
            sorter: true,
            dataIndex: FacilityBookingHistoryColumn.AUTO_GENERATED_CODE,
            width: '140px',
            render: (autoGeneratedCode: string) => {
                return (
                    <div>
                        <a>{autoGeneratedCode}</a>
                    </div>
                );
            },
        },
        {
            title: t('guest.detail.facilityBookingHistory.column.yomigana'),
            key: FacilityBookingHistoryColumn.YOMIGANA,
            dataIndex: FacilityBookingHistoryColumn.YOMIGANA,
            ellipsis: true,
        },
        {
            title: t('guest.detail.facilityBookingHistory.column.fullName'),
            key: FacilityBookingHistoryColumn.FULL_NAME,
            dataIndex: FacilityBookingHistoryColumn.FULL_NAME,
            ellipsis: true,
        },
        {
            title: t('guest.detail.facilityBookingHistory.column.facilityName'),
            key: FacilityBookingHistoryColumn.FACILITY_NAME,
            dataIndex: FacilityBookingHistoryColumn.FACILITY_NAME,
            ellipsis: true,
        },
        {
            title: t('guest.detail.facilityBookingHistory.column.checkInTime'),
            key: FacilityBookingHistoryColumn.START_DATE_TIME,
            dataIndex: FacilityBookingHistoryColumn.START_DATE_TIME,
            render: (startDatetime: string) => {
                return (
                    <div>
                        {startDatetime
                            ? parseDateTime(
                                  startDatetime,
                                  DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                              )
                            : ''}
                    </div>
                );
            },
        },
        {
            title: t('guest.detail.facilityBookingHistory.column.checkOutTime'),
            key: FacilityBookingHistoryColumn.END_DATE_TIME,
            dataIndex: FacilityBookingHistoryColumn.END_DATE_TIME,
            render: (endDatetime: string) => {
                return (
                    <div>
                        {endDatetime
                            ? parseDateTime(
                                  endDatetime,
                                  DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                              )
                            : ''}
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <div className="guest-facility-booking-history-list-wrapper">
                <div className="guest-facility-booking-history-list-header">
                    <span></span>
                    <Title
                        level={5}
                        className="guest-facility-booking-history-list-title"
                    >
                        {t('guest.detail.facilityBookingHistory.headerTitle')}
                    </Title>
                    <div className="guest-header-right">
                        <CsvDropdown
                            onChange={onChangeCsvOption}
                            hasSelectedColumns={selectedRows.length > 0}
                            hasImportOption={false}
                        />
                        <Button type="text" className="btn">
                            <ReloadOutlined onClick={onRefresh} />
                        </Button>
                    </div>
                </div>
                <Table
                    className="guest-facility-booking-history-list-table"
                    columns={facilityBookingHistoryColumn}
                    onChange={onChange}
                    rowSelection={rowSelection}
                    pagination={false}
                    dataSource={tableData}
                    rowKey="id"
                    loading={showLoading}
                    rowClassName={'guest-facility-booking-row'}
                    onRow={editRecord}
                />
            </div>
            {pageCount > 1 && (
                <Pagination
                    align="center"
                    defaultCurrent={facilityBookingHistoryQuery.page}
                    className="guest-facility-booking-history-pagination"
                    total={totalFacility}
                    pageSize={facilityBookingHistoryQuery.limit}
                    showSizeChanger={false}
                    onChange={onChangePage}
                />
            )}
        </>
    );
}

export default FacilityBookingHistoryList;
