import React, { useReducer, createContext, Reducer } from 'react';
import * as types from './actionTypes';
import { TAction } from './actionTypes';
import { IErrorItem } from '~common/interfaces';

interface ITaxDetailPageContextProvider {
    children: React.ReactNode;
}

interface ITaxReportState {
    isTableLoading: boolean;
    selectedMonth: string;
    error: IErrorItem[] | Error | null | undefined;
}

const initialState: ITaxReportState = {
    isTableLoading: false,
    selectedMonth: '',
    error: null,
};

export const TaxDetailPageContext = createContext<ITaxReportState>(null!);

export const TaxDetailPageDispatchContext = createContext<React.Dispatch<TAction>>(null!);

const reducer = (state: ITaxReportState, action: TAction): ITaxReportState => {
    switch (action.type) {
        case types.FETCH_TAX_SUMMARY_REQUEST:
            return {
                ...state,
                isTableLoading: true,
            };
        case types.FETCH_TAX_SUMMARY_SUCCESS:
            return {
                ...state,
                isTableLoading: false,
                error: null,
            };
        case types.FETCH_TAX_SUMMARY_FAIL:
            return {
                ...state,
                isTableLoading: false,
                error: action.payload,
            };
        case types.SET_SELECTED_MONTH:
            return {
                ...state,
                selectedMonth: action.payload,
            };
        default:
            return state;
    }
};

const TaxDetailPageContextProvider: React.FC<ITaxDetailPageContextProvider> = ({
    children,
}) => {
    const [taxDetailPageState, dispatch] = useReducer<Reducer<ITaxReportState, TAction>>(
        reducer,
        initialState,
    );

    return (
        <TaxDetailPageContext.Provider value={taxDetailPageState}>
            <TaxDetailPageDispatchContext.Provider value={dispatch}>
                {children}
            </TaxDetailPageDispatchContext.Provider>
        </TaxDetailPageContext.Provider>
    );
};

export default TaxDetailPageContextProvider;
