import { IChangedGuestItem } from '../../../../../../interfaces';
import { useTranslation } from 'react-i18next';
import ChangedGuestItem from './ChangedGuestItem';
import { useState } from 'react';
import Link from 'antd/lib/typography/Link';
import { MAX_EDIT_HISTORY_ITEM } from '~features/room-booking/constants';

interface IProps {
    title: string;
    contents: IChangedGuestItem[];
}
function HoteSmartEditHistory({ title, contents }: IProps) {
    const { t } = useTranslation();
    const maxItem = MAX_EDIT_HISTORY_ITEM;

    const [showAll, setShowAll] = useState(contents?.length < maxItem);

    const modifiedData = showAll ? contents : contents?.slice(0, maxItem);

    return (
        <div className="hotel-smart-item">
            {showAll}
            <p className="font-weight-text-600">{title}</p>
            {modifiedData?.map((content) => (
                <ChangedGuestItem
                    key={content.guestId}
                    guestId={content.guestId}
                    length={content.length}
                    change={content.change}
                />
            ))}
            {contents?.length > maxItem && (
                <Link onClick={() => setShowAll(!showAll)}>
                    {showAll
                        ? t('roomBooking.detail.hotelSmartCard.showLess')
                        : t('roomBooking.detail.hotelSmartCard.showMore')}
                </Link>
            )}
        </div>
    );
}

export default HoteSmartEditHistory;
