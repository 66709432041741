import hotelSettingFormJa from './ja/hotel-setting-form-locale';
import hotelListJa from './ja/hotel-list-locale';
import hotelFormJa from './ja/hotel-form-locale';

import hotelSettingFormEn from './en/hotel-setting-form-locale';
import hotelListEn from './en/hotel-list-locale';
import hotelFormEn from './en/hotel-form-locale';

const hotel = {
    ja: {
        settingForm: hotelSettingFormJa,
        list: hotelListJa,
        form: hotelFormJa,
    },
    en: {
        settingForm: hotelSettingFormEn,
        list: hotelListEn,
        form: hotelFormEn,
    },
};
export default hotel;
