export const salesPaymentPageJa = {
    titlePage: '売上入金表',
    breadcrumbs: {
        report: '帳票',
        salesPayment: '売上入金表',
    },
    filterForm: {
        dateLabel: '集計日:',
        displayButton: '表示',
    },
    table: {
        item: '科目',
        sales: {
            title: '売上',
            roomName: '部屋名',
            guestName: '宿泊者名',
            amount: '売上',
            totalBalanceAmout: '預かり残高',
            autoGeneratedCode: '予約番号',
            adult: '大人',
            localTax: '宿泊税',
            bathTax: '入湯税',
        },
        payment: {
            title: '入金',
            amount: '入金',
            name: '現金',
            totalUpaidAmount: '未収残高',
        },
        totalAmount: '未収残高合計',
        totalPayment: '入金合計',
        totalSales: '売上合計 (税込み)',
        totalBalanceAmount: '預かり残高合計',
    },
    exportColumns: {
        autoGeneratedCode: '予約番号',
        roomName: '部屋名',
        yomigana: '宿泊者名',
        saleName: '科目',
        saleAmount: '請求額',
        paymentName: '科目',
        paymentAmount: '入金額',
    },
};
