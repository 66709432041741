import React, { useCallback } from 'react';
import BookingTemporaryCard from '../BookingTemporaryCard/BookingTemporaryCard';
import './BookingTemporaryList.scss';
import { useAppDispatch, useAppSelector } from '~hooks';
import { Button, Card, Col, notification, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { createBookingStateSelector } from '~features/room-booking/reducers/create-booking.reducer';
import { cloneDeep } from 'lodash';
import {
    getAvailableRooms,
    roomBookingStateSelector,
} from '~features/room-booking/reducers/room-booking.reducer';

type Props = {
    watch: any;
    control: any;
    setValue: any;
    setError: any;
    clearErrors: any;
    getValues: any;
};

export const BookingTemporaryList = ({
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
}: Props) => {
    const { bookingConfirmData } = useAppSelector(createBookingStateSelector);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isAutoAssignRoomLoading } = useAppSelector(roomBookingStateSelector);

    const updateBooking = (index: number, id: number) => {
        setValue(`bookings.${index}.roomId`, id);
    };

    const _getAvailableRooms = useCallback(
        async (ids: number[]) => {
            if (ids.length === 0) {
                notification.success({
                    message: t('roomBooking.detail.message.assignedAllRoom'),
                });
                return;
            }
            const response = await dispatch(getAvailableRooms(ids));
            if (getAvailableRooms.fulfilled.match(response)) {
                if (response.payload.success) {
                    const assignRooms = response.payload?.data || [];
                    for (let i = 0; i < assignRooms.length; i++) {
                        const index = bookingConfirmData.findIndex(
                            (item) => item.booking.id === assignRooms[i].id,
                        );
                        updateBooking(index, assignRooms[i].roomId);
                    }
                    if (assignRooms.length < ids.length) {
                        notification.success({
                            message: t('roomBooking.detail.message.autoAssignRoom', {
                                unassignCount: ids.length - assignRooms.length,
                            }),
                        });
                    }
                } else {
                    notification.error({
                        message: response.payload?.message,
                    });
                }
            }
        },
        [bookingConfirmData],
    );

    const autoAssignRooms = () => {
        // get booking without room
        const _bookings = cloneDeep(
            bookingConfirmData.filter(
                (item) => !item.isAssignedRoom || !item.booking?.room?.id,
            ),
        );
        if (_bookings.length === 0) {
            notification.success({
                message: t('roomBooking.detail.message.assignedAllRoom'),
            });
            return;
        }
        _getAvailableRooms(_bookings.map((item) => item.booking.id));
    };

    return (
        <div className="booking-lock-list-wrapper pt-24">
            <Card>
                <div className="d-flex j-between a-center booking-lock-list-info pb-24">
                    <div>
                        <span className="b-title">
                            {t('roomBooking.createBooking.bookingList')}
                        </span>
                        <span className="count">{bookingConfirmData.length}</span>
                        <Button
                            type="primary"
                            className="ml-34"
                            onClick={autoAssignRooms}
                            loading={isAutoAssignRoomLoading}
                        >
                            {t('roomBooking.createBooking.autoAssignment')}
                        </Button>
                    </div>
                </div>
                <Row gutter={12}>
                    {bookingConfirmData.map((item, index) => (
                        <Col
                            span={6}
                            className="carousel-booking-item"
                            key={item.booking.id}
                        >
                            <BookingTemporaryCard
                                control={control}
                                index={index}
                                watch={watch}
                                setValue={setValue}
                                setError={setError}
                                clearErrors={clearErrors}
                                getValues={getValues}
                                booking={item.booking}
                            />
                        </Col>
                    ))}
                </Row>
            </Card>
        </div>
    );
};
