import { Button, Col, Form, Row } from 'antd';
import { useForm } from '~plugins/hook-form';
import { useTranslation } from 'react-i18next';
import { InputNumber, SingleSelect, InputText } from '~components';
import { PlusOutlined } from '@ant-design/icons';
import './CreateFacilitiesFormSetting.scss';
import { IInititalFacilitySetting } from '~features/facility/interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import { settingSchema } from '~features/facility/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { facilityTypeDropDownOptionsSelector } from '~features/facility-type/facility-type.reducer';
import { setCreateFacilityList } from '~features/facility/facility.reducer';
import { generateName } from '~common/commonFunctions';
import { getFacilityFormId } from '~features/facility/helper';

function CreateFacilityFormSetting() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const facilityTypeOptions = useAppSelector(facilityTypeDropDownOptionsSelector);

    const { control, handleSubmit, formState } = useForm<IInititalFacilitySetting>({
        resolver: yupResolver(settingSchema),
        mode: 'onChange',
    });

    const onClickButtonGenerateFacility = () => {
        handleSubmit((data) => {
            const generatedFacilityList = [];
            for (let i = 0; i < +data.quantity; i += 1) {
                generatedFacilityList.push({
                    feId: `${Date.now()}_${i}`,
                    facilityTypeId: data.facilityTypeId,
                    name: generateName(data.initialFacilityName, i),
                });
            }
            dispatch(setCreateFacilityList(generatedFacilityList));
        })();
    };

    return (
        <div className="facility-form-setting-wrapper">
            <Form layout="vertical">
                <Row className="input-row" gutter={8}>
                    <Col span={5}>
                        <InputNumber
                            label={t('facility.create.formSetting.quantity.label')}
                            placeholder={t(
                                'facility.create.formSetting.quantity.placeholder',
                            )}
                            name="quantity"
                            control={control}
                            required={true}
                            id={getFacilityFormId('quantity')}
                        />
                    </Col>
                    <Col span={14}>
                        <SingleSelect
                            label={t('facility.create.formSetting.facilityType.label')}
                            placeholder={t(
                                'facility.create.formSetting.facilityType.placeholder',
                            )}
                            name="facilityTypeId"
                            control={control}
                            options={facilityTypeOptions}
                            required={true}
                            id={getFacilityFormId('facilityTypeId')}
                        />
                    </Col>
                    <Col span={5}>
                        <InputText
                            label={t(
                                'facility.create.formSetting.initialFacilityName.label',
                            )}
                            placeholder={t(
                                'facility.create.formSetting.initialFacilityName.placeholder',
                            )}
                            name="initialFacilityName"
                            control={control}
                            required={true}
                            id={getFacilityFormId('initialFacilityName')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Button
                                type="default"
                                className="facility-generate-button"
                                disabled={!formState.isValid}
                                onClick={onClickButtonGenerateFacility}
                                id={getFacilityFormId('generate-button')}
                            >
                                <PlusOutlined />
                                <span>
                                    {t('facility.create.buttons.generateFacilities')}
                                </span>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default CreateFacilityFormSetting;
