import { CalendarOutlined, DragOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import Link from 'antd/lib/typography/Link';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '~common/commonFunctions';
import { getAutoGeneratedCode } from '~features/room-booking/helper';
import { IUnassignedBookingItem } from '~features/room-management/interfaces';
import { parseDate } from '~plugins/dayjs';
import './BookingUnassigned.scss';
import { DraggableBox } from './DraggableBox';

type Props = {
    booking: IUnassignedBookingItem;
    rooms: { value: number; label: string; parentId: number }[];
    onSetRoom?: (roomId: number, bookingId: number) => void;
    draggable: boolean;
    roomBooking: {
        otaReservationNumber: string | null;
        id: number | null;
        autoGeneratedCode: string | null;
    };
};

export const BookingUnassigned = ({
    booking,
    rooms,
    draggable,
    onSetRoom,
    roomBooking,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [memoExpanded, setMemoExpanded] = useState(false);
    const [otaMemoExpanded, setOtaMemoExpanded] = useState(false);

    const name = useMemo(() => {
        return booking.representativeGuest?.yomigana;
    }, [booking]);

    const date = useMemo(() => {
        return `${parseDate(booking.startDateOfStay)?.fmYYYYMMDD('/')} - ${parseDate(
            booking.endDateOfStay,
        )?.fmYYYYMMDD('/')}`;
    }, [booking]);

    const price = useMemo(() => {
        return formatMoney(
            booking.totalAmount && booking.totalAmount >= 0 ? booking.totalAmount : 0,
        );
    }, [booking]);

    const memo = useMemo(() => {
        return booking.memo || '';
    }, [booking]);

    const otaMemo = useMemo(() => {
        return booking.otaMemo || '';
    }, [booking]);

    const childrenCount = useMemo(() => {
        return (
            booking.roomBookingItemChildrenTypes?.reduce((total, item) => {
                return total + item.quantity;
            }, 0) || 0
        );
    }, [booking]);

    const showRoomBookingDetail = () => {
        if (roomBooking.id) {
            navigate(`/room-booking/${roomBooking.id}/detail`);
        }
    };
    const autoGeneratedCode = useMemo(() => {
        return getAutoGeneratedCode(
            roomBooking.otaReservationNumber || roomBooking.autoGeneratedCode || '',
        );
    }, [roomBooking]);
    return (
        <div className="booking-assigned-wrapper">
            <div className="d-flex booking-assigned-top">
                <div className="d-flex a-center text-truncate">
                    <UserOutlined />
                    <div className="pl-12 text-truncate">{name}</div>
                </div>

                <div
                    onClick={() => {
                        showRoomBookingDetail();
                    }}
                    className="booking-link text-truncate"
                >
                    {autoGeneratedCode}
                </div>
            </div>
            <div className="d-flex">
                <CalendarOutlined className="mt-4" />
                <div className="pl-12 booking-info text-truncate">
                    <div className="date">{date}</div>
                    <div className="text-truncate">{booking?.roomType?.name}</div>
                    <div className="text-truncate">{booking?.plan?.name}</div>
                    <div className="d-flex j-between">
                        <span>
                            {t('roomManagement.list.adultCount', {
                                count: booking?.numberOfAdults,
                            })}
                        </span>
                        {!!childrenCount && (
                            <span>
                                {t('roomManagement.list.childrenCount', {
                                    count: childrenCount,
                                })}
                            </span>
                        )}
                    </div>
                    <div>￥{price}</div>
                    {memo && (
                        <div style={{ whiteSpace: 'break-spaces' }}>
                            <Paragraph
                                key={Math.random().toString()}
                                ellipsis={
                                    !memoExpanded
                                        ? {
                                              rows: 2,
                                              expandable: true,
                                              symbol: t(
                                                  'roomManagement.list.bookingUnassigned.more',
                                              ),
                                              onExpand: (e) => {
                                                  setMemoExpanded(!memoExpanded);
                                              },
                                          }
                                        : false
                                }
                                style={{
                                    whiteSpace: 'break-spaces',
                                }}
                            >
                                <span className="text-bold">Memo: </span>
                                {memo}
                                {memoExpanded && (
                                    <Link
                                        className="ml-2"
                                        onClick={() => setMemoExpanded(false)}
                                    >
                                        {' '}
                                        {t('roomManagement.list.bookingUnassigned.less')}
                                    </Link>
                                )}
                            </Paragraph>
                        </div>
                    )}
                    {otaMemo && (
                        <div>
                            <Paragraph
                                key={Math.random().toString()}
                                ellipsis={
                                    !otaMemoExpanded
                                        ? {
                                              rows: 2,
                                              expandable: true,
                                              symbol: t(
                                                  'roomManagement.list.bookingUnassigned.more',
                                              ),
                                              onExpand: (e) => {
                                                  setOtaMemoExpanded(!otaMemoExpanded);
                                              },
                                          }
                                        : false
                                }
                                style={{ whiteSpace: 'break-spaces' }}
                            >
                                <span className="text-bold">OTA Memo: </span> {otaMemo}
                                {otaMemoExpanded && (
                                    <Link
                                        className="ml-2"
                                        onClick={() => setOtaMemoExpanded(false)}
                                    >
                                        {' '}
                                        {t('roomManagement.list.bookingUnassigned.less')}
                                    </Link>
                                )}
                            </Paragraph>
                        </div>
                    )}
                </div>
            </div>
            {rooms.length > 0 && (
                <div className="room-select">
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        options={rooms}
                        onChange={(id) => {
                            onSetRoom && onSetRoom(id, booking.id);
                        }}
                    />
                </div>
            )}
            {draggable && (
                <>
                    <DraggableBox
                        bookingId={booking?.id}
                        autoGeneratedCode={autoGeneratedCode}
                    >
                        <Button className="drag-btn">
                            <DragOutlined />
                            {t('roomManagement.list.assignment')}
                        </Button>
                    </DraggableBox>
                    <p className="drag-explanation">{t('roomManagement.list.drag')}</p>
                </>
            )}
        </div>
    );
};
