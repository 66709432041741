import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { AppState } from '~plugins/redux-toolkit/store';
import { BookingStatusType, BookingType } from '../constants';
import { IDashboardState } from '../interfaces';
import { DEFAULT_LIMIT_FOR_LIST_DASHBOARD } from './../constants';
import { IDashboardBookingOverview } from './../interfaces';
import { dashboardService } from './../services/dashboard.service';

const defaultBookingListQuery = {
    orderBy: null,
    limit: DEFAULT_LIMIT_FOR_LIST_DASHBOARD,
    orderDirection: null,
    page: DEFAULT_FIRST_PAGE,
    datePeriod: [
        customDayjs().startOf('day')?.fmYYYYMMDDHHmmss(),
        customDayjs().endOf('day')?.fmYYYYMMDDHHmmss(),
    ],
};

const initialState: IDashboardState = {
    bookingList: [],
    bookingOverview: {} as IDashboardBookingOverview,
    bookingListLoading: false,
    bookingListQuery: cloneDeep(defaultBookingListQuery),
    bookingListTotal: 0,
    bookingStatusType: BookingStatusType.newBooking,
    bookingType: BookingType.room,
};

export const getBookingList = createAsyncThunk(
    'dashboard/getBookingList',
    async (_, { getState }) => {
        const { bookingListQuery, bookingStatusType, bookingType } = (
            getState() as AppState
        ).dashboard;
        return await dashboardService.getBookingList({
            ...bookingListQuery,
            bookingStatusType: bookingStatusType,
            bookingType,
        });
    },
);
export const getDashboardBookingOverview = createAsyncThunk(
    'dashboard/getBookingOverview',
    async (data?: { start?: string; end?: string }) => {
        const { start, end } = data || {};
        const _start = start ? parseDate(start) : customDayjs().startOf('day');
        const _end = end ? parseDate(end) : customDayjs().endOf('day');
        const startOfDay = _start.fmYYYYMMDDHHmmss();
        const endOfDay = _end.fmYYYYMMDDHHmmss();
        const queryString = {
            datePeriod: [startOfDay, endOfDay],
        };
        return await dashboardService.getDashboardBookingOverview(queryString);
    },
);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setBookingStatusType(state, action) {
            state.bookingStatusType = action.payload;
        },
        setBookingType(state, action) {
            state.bookingType = action.payload;
        },
        setBookingListQuery(state, action) {
            state.bookingListQuery = action.payload;
        },
        sortBookingList(state, action) {
            state.bookingList = action.payload;
        },
        setBookingListLoading(state, action) {
            state.bookingListLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBookingList.pending, (state) => {
            state.bookingList = [];
            state.bookingListLoading = true;
        });
        builder.addCase(getBookingList.fulfilled, (state, action) => {
            state.bookingListLoading = false;
            state.bookingList = action.payload?.data?.items || [];
            state.bookingListTotal = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(getBookingList.rejected, (state) => {
            state.bookingListLoading = false;
        });
        builder.addCase(getDashboardBookingOverview.fulfilled, (state, action) => {
            state.bookingOverview =
                action.payload?.data || ({} as IDashboardBookingOverview);
        });
    },
});

export const {
    setBookingStatusType,
    setBookingType,
    setBookingListQuery,
    sortBookingList,
    setBookingListLoading,
} = dashboardSlice.actions;

export const dashboardSelector = (state: AppState) => state.dashboard;

export const bookingListQuerySelector = (state: AppState) =>
    state.dashboard.bookingListQuery;

export const bookingPageCountSelector = (state: AppState) => {
    const { bookingListTotal, bookingListQuery } = state.dashboard;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = bookingListQuery;
    return Math.ceil(bookingListTotal / limit);
};

export const dashboardBookingOverviewSelector = (state: AppState) =>
    state.dashboard.bookingOverview;

export default dashboardSlice.reducer;
