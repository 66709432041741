import closingBookingPageJa from './ja/closing-booking-page.locale';
import closingBookingPageEn from './en/closing-booking-page.locale';

export const closingBooking = {
    ja: {
        page: closingBookingPageJa,
    },
    en: {
        page: closingBookingPageEn,
    },
};
