import dayjs from 'dayjs';
import { Col, Row, Tabs, Tooltip } from 'antd';
import './RepresentativeGuestInfo.scss';
import { PhoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '~hooks';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FieldTab } from '~features/room-booking/constants';

function RepresentativeGuestInfo() {
    const { t } = useTranslation();
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const { phoneOfRepresentativeGuest, phoneOfReserverGuest } = useMemo(() => {
        if (!selectedRoomBookingDetail)
            return { phoneOfRepresentativeGuest: '', phoneOfReserverGuest: '' };

        const { representativeGuest = null, reserverGuest = null } =
            selectedRoomBookingDetail;
        return {
            phoneOfRepresentativeGuest:
                representativeGuest?.mobilePhoneNumber ||
                representativeGuest?.phoneNumberLandline ||
                '',
            phoneOfReserverGuest:
                reserverGuest?.mobilePhoneNumber ||
                reserverGuest?.phoneNumberLandline ||
                '',
        };
    }, [selectedRoomBookingDetail]);

    return (
        <div className="representative-guest-info">
            <Row gutter={24}>
                <Col span={6}>
                    <Row gutter={12}>
                        <Col span={12}>
                            <p className="guest-info-label mini-text font-weight-text-300">
                                {t('roomBooking.detail.guestInfo.representativeGuest')}
                            </p>
                            <p className="yomigana-guest medium-text font-weight-text-300 text-truncate">
                                <Link
                                    to={
                                        '/guest/' +
                                        selectedRoomBookingDetail?.representativeGuest
                                            ?.id +
                                        '/detail'
                                    }
                                >
                                    {
                                        selectedRoomBookingDetail?.representativeGuest
                                            ?.yomigana
                                    }
                                </Link>
                            </p>
                            <p className="full-name-guest medium-text font-weight-text-600 text-truncate">
                                {selectedRoomBookingDetail?.representativeGuest?.fullName}
                            </p>
                            <a
                                href={`tel:${selectedRoomBookingDetail?.representativeGuest?.mobilePhoneNumber}`}
                                className="phone-number-guest mini-text font-weight-text-400"
                            >
                                {phoneOfRepresentativeGuest ? (
                                    <div className="text-truncate">
                                        <PhoneOutlined className="phone-icon" />
                                        {phoneOfRepresentativeGuest}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </a>

                            <a
                                href={`mailto:${selectedRoomBookingDetail?.representativeGuest?.emailAddress}`}
                                className="email-address-guest mini-text font-weight-text-400 text-truncate"
                            >
                                {
                                    selectedRoomBookingDetail?.representativeGuest
                                        ?.emailAddress
                                }
                            </a>
                        </Col>
                        <Col span={12}>
                            <p className="guest-info-label mini-text font-weight-text-300">
                                {t('roomBooking.detail.guestInfo.reserverGuest')}{' '}
                            </p>
                            {selectedRoomBookingDetail?.representativeGuestId ===
                            selectedRoomBookingDetail?.reserverGuestId ? (
                                <p className="mini-text font-weight-text-400">
                                    {t(
                                        'roomBooking.detail.guestInfo.reserverIsTheRepresentative',
                                    )}
                                </p>
                            ) : (
                                <>
                                    <p className="yomigana-guest medium-text font-weight-text-300 text-truncate">
                                        {
                                            selectedRoomBookingDetail?.reserverGuest
                                                ?.yomigana
                                        }
                                    </p>
                                    <p className="full-name-guest medium-text font-weight-text-600 text-truncate">
                                        {
                                            selectedRoomBookingDetail?.reserverGuest
                                                ?.fullName
                                        }
                                    </p>
                                    <a
                                        href={`tel:${selectedRoomBookingDetail?.reserverGuest?.mobilePhoneNumber}`}
                                        className="phone-number-guest mini-text font-weight-text-400"
                                    >
                                        {phoneOfReserverGuest && (
                                            <span>
                                                {phoneOfReserverGuest ? (
                                                    <div className="text-truncate">
                                                        <PhoneOutlined className="phone-icon" />
                                                        {phoneOfReserverGuest}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        )}
                                    </a>
                                    <a
                                        href={`mailto:${selectedRoomBookingDetail?.reserverGuest?.emailAddress}`}
                                        className="email-address-guest mini-text font-weight-text-400 text-truncate"
                                    >
                                        {
                                            selectedRoomBookingDetail?.reserverGuest
                                                ?.emailAddress
                                        }
                                    </a>
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col span={18}>
                    <Row gutter={12} className="h-100">
                        <Col span={4}>
                            <p className="text-truncate guest-info-label mini-text font-weight-text-300">
                                {t('roomBooking.detail.guestInfo.marketingChannel')}
                            </p>
                            <div className="marketing-channel-name large-text font-weight-text-500 text-truncate">
                                {!selectedRoomBookingDetail?.marketingChannel
                                    ?.isPullFromTll ? (
                                    selectedRoomBookingDetail?.marketingChannel?.name ||
                                    ''
                                ) : (
                                    <Tooltip
                                        title={t(
                                            'roomBooking.detail.message.marketingChannelNotExist',
                                        )}
                                    >
                                        <div className="marketing-channel-error text-truncate">
                                            {selectedRoomBookingDetail?.marketingChannel
                                                ?.name || ''}
                                        </div>
                                    </Tooltip>
                                )}
                            </div>

                            <p className="text-truncate guest-info-label mini-text font-weight-text-300 pt-8">
                                {t('roomBooking.detail.guestInfo.bookingTime')}
                            </p>
                            <p className="mini-text font-weight-text-400 text-truncate">
                                {dayjs(
                                    selectedRoomBookingDetail?.roomBookingItems[0]
                                        .createdAt,
                                ).fmYYYYMMDDHHmm()}
                            </p>
                        </Col>
                        <Col span={20}>
                            <Tabs
                                className="memo-tabs"
                                defaultActiveKey={FieldTab.MEMO}
                                items={[
                                    {
                                        label: t(
                                            'roomBooking.detail.detailPagetabs.memo',
                                        ),
                                        key: FieldTab.MEMO,
                                        children: (
                                            <div className="room-booking-memo-wrapper">
                                                {selectedRoomBookingDetail?.memo}
                                            </div>
                                        ),
                                    },
                                    {
                                        label: t(
                                            'roomBooking.createBooking.otaMemoPlaceholder',
                                        ),
                                        key: FieldTab.OTA_MEMO,
                                        children: (
                                            <div className="room-booking-memo-wrapper">
                                                {selectedRoomBookingDetail?.otaMemo}
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default RepresentativeGuestInfo;
