import React, { useCallback, useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import localStorageAuthService from '~common/authStorage';
import { isDevelopment, logDebug } from '~common/commonFunctions';
import { getToken, logoutSession } from '~plugins/amplify';
import { SOCKET_BASE_URL, SocketEvents } from './socket';
import { SocketContext } from './socketContext';
import { useAuth } from '../../AuthProvider';
import { useNavigate } from 'react-router-dom';

type SocketProviderProps = {
    children: React.ReactNode;
};

const SocketProvider = ({ children }: SocketProviderProps) => {
    const [socket, setSocket] = useState<Socket>();
    const auth = useAuth();
    const navigate = useNavigate();

    const logout = useCallback(async () => {
        await logoutSession();
        auth.logout();
        navigate('/login', { replace: true });
    }, []);

    const initSocket = useCallback(async () => {
        const { accessToken, idToken, isValidAccessToken } = await getToken();
        // logout when the access token does not validate
        if (!isValidAccessToken) {
            logout();
            return;
        }
        const socket = io(SOCKET_BASE_URL, {
            reconnection: true,
            extraHeaders: {
                Authorization: `Bearer ${accessToken}`,
                idToken: `${idToken}`,
                hotelId: localStorageAuthService.getHotelId(),
            },
            auth: {
                token: accessToken,
            },
            transports: ['polling', 'websocket'],
        });
        setSocket(socket);
    }, []);

    useEffect(() => {
        initSocket();
    }, [initSocket]);

    useEffect(() => {
        if (socket) {
            socket.on(SocketEvents.CONNECT, async () => {
                logDebug('Socket connected', socket);

                const { token } = socket.auth as { token: string };
                // get new access token
                const { accessToken, isValidAccessToken } = await getToken();
                // logout when the access token does not validate
                if (!isValidAccessToken) {
                    logout();
                    return;
                }

                // check token before emit
                if (accessToken === token) {
                    socket.emit(SocketEvents.WEB_APP_USER_LOGIN);
                } else {
                    // false: init new socket instance
                    socket.disconnect();
                    initSocket();
                }
            });
            if (isDevelopment()) {
                // debug for development
                socket.on(SocketEvents.CONNECT_ERROR, (err) => {
                    console.log(`Socket connect error due to ${err.message}`);
                });
            }
        }
        return () => {
            if (socket?.connected) {
                logDebug('disconnect socket ', socket);
                socket.disconnect();
            }
        };
    }, [socket, initSocket]);
    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
