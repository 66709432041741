import { Button, Card, Form, Layout, Radio, RadioChangeEvent, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchRoomBookingDetail,
    showLoadingSelector,
    selectedRoomBookingDetailSelector,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '~hooks';
import './OTACancelationRobotPage.scss';
import { StaffLayoutHeader, InputTextArea } from '~components';

import { getAutoGeneratedCode } from '~features/room-booking/helper';
import OTACancelationRobotModal from './components/OTACancelationRobotModal/OTACancelationRobotModal';

const { Content } = Layout;

function OTACancelationRobotPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoading = useAppSelector(showLoadingSelector);
    const { roomBookingId } = useParams();
    const parsedRoomBookingId = Number(roomBookingId);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cancelationFee, setCancelationFee] = useState<'free' | 'paid'>('free');
    const onChange = (e: RadioChangeEvent) => {
        setCancelationFee(e.target.value);
    };
    const { control, handleSubmit } = useForm();

    const breadcrumbs = () => [
        {
            text: t('roomBooking.list.breadcrumbs.home'),
        },
        {
            text: t('roomBooking.list.breadcrumbs.roomBooking'),
        },
        {
            text: t('roomBooking.detail.breadcrumbs.OtaCancelationRobot'),
        },
    ];

    const _getDetail = useCallback(async (id: number) => {
        await dispatch(fetchRoomBookingDetail(id));
    }, []);

    useEffect(() => {
        if (isNaN(parsedRoomBookingId)) {
            navigate('/404');
        }
        _getDetail(parsedRoomBookingId);
    }, [roomBookingId]);

    const cancelBooking = () => {
        handleSubmit(async (data) => {
            // eslint-disable-next-line no-console
            console.log({ ...data, cancelationFee });
        })();
    };

    return (
        <div className="cancelation-robot">
            <Spin spinning={isLoading}>
                <StaffLayoutHeader
                    breadcrumbs={breadcrumbs()}
                    title={t('roomBooking.detail.breadcrumbs.OtaCancelationRobot')}
                />
                <Content>
                    <div className="cancelation-robot__card-wrapper">
                        <Card
                            className="cancelation-robot__card"
                            title={t('roomBooking.detail.otaCancelation.cancelResId', {
                                resId: getAutoGeneratedCode(
                                    selectedRoomBookingDetail?.autoGeneratedCode || '',
                                ),
                            })}
                        >
                            <Form>
                                <div className="cancelation-robot__card-content">
                                    <div className="cancelation-robot__data">
                                        <div>
                                            {t(
                                                'roomBooking.detail.guestInfo.reserverGuest',
                                            )}
                                            :
                                        </div>
                                        <div className="cancelation-robot__data-value">
                                            {
                                                selectedRoomBookingDetail?.reserverGuest
                                                    ?.yomigana
                                            }
                                        </div>
                                        <div className="cancelation-robot__data-value">
                                            {
                                                selectedRoomBookingDetail?.reserverGuest
                                                    ?.fullName
                                            }
                                        </div>
                                    </div>
                                    <div className="cancelation-robot__data">
                                        <div>OTA:</div>
                                        <div className="cancelation-robot__data-value">
                                            Rakuten Travel
                                        </div>
                                    </div>
                                    <div className="cancelation-robot__data">
                                        <div>
                                            {t(
                                                'roomBooking.detail.otaCancelation.AdminSetting',
                                            )}
                                            :
                                        </div>
                                        <div className="cancelation-robot__data-value">
                                            {t(
                                                'roomBooking.detail.otaCancelation.completed',
                                            )}
                                        </div>
                                        <div>
                                            <Button
                                                size="small"
                                                type="link"
                                                onClick={() => setIsModalOpen(true)}
                                            >
                                                {t(
                                                    'roomBooking.detail.otaCancelation.AdminSettingBtn',
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="cancelation-robot__input">
                                        <div>
                                            {t('roomBooking.detail.otaCancelation.fee')}:
                                        </div>
                                        <Radio.Group
                                            onChange={onChange}
                                            value={cancelationFee}
                                        >
                                            <Radio value="free">
                                                {t(
                                                    'roomBooking.detail.otaCancelation.free',
                                                )}
                                            </Radio>
                                            <Radio value="paid">
                                                {t(
                                                    'roomBooking.detail.otaCancelation.paid',
                                                )}
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                    <div className="cancelation-robot__input">
                                        <div>
                                            {t(
                                                'roomBooking.detail.otaCancelation.memoLabel',
                                            )}
                                        </div>
                                        <InputTextArea
                                            label=""
                                            placeholder={t(
                                                'roomBooking.detail.otaCancelation.memoPlaceholder',
                                            )}
                                            name="memo"
                                            control={control}
                                            id="ota-cancelation-form-memo"
                                            allowClear
                                            rows={3}
                                        />
                                    </div>
                                    <div className="cancelation-robot__data">
                                        <Button
                                            size="large"
                                            className="cancelation-robot__action-btn"
                                            onClick={() =>
                                                navigate(
                                                    `/room-booking/${roomBookingId}/detail`,
                                                )
                                            }
                                        >
                                            {t(
                                                'roomBooking.detail.otaCancelation.cancelBtn',
                                            )}
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            danger
                                            className="cancelation-robot__action-btn"
                                            onClick={cancelBooking}
                                        >
                                            {t(
                                                'roomBooking.detail.otaCancelation.submitBtn',
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </Card>
                    </div>
                    <OTACancelationRobotModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    />
                </Content>
            </Spin>
        </div>
    );
}

export default OTACancelationRobotPage;
