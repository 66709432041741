import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { mealReportBreadcrumbs } from '~features/report/constants';
import { mealReportStateSelector } from '~features/report/reducers/meal-report.reducer';
import { useAppSelector } from '~hooks';
import FilterForm from './components/FilterForm/FilterForm';
import MealReportTable from './components/MealReportTable/MealReportTable';

export default function MealReportPage() {
    const { t } = useTranslation();
    const { isShowTable } = useAppSelector(mealReportStateSelector);

    return (
        <div>
            <StaffLayoutHeader
                title={t('report.mealReport.title')}
                breadcrumbs={mealReportBreadcrumbs()}
            />

            <FilterForm />
            {isShowTable && <MealReportTable />}
        </div>
    );
}
