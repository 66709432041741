import React from 'react';
import { Button, Table, Tooltip } from 'antd';
import './SaleItemTable.scss';
import Column from 'antd/lib/table/Column';
import { QuestionCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '~hooks';
import { selectedPlanSelector } from '~features/plan/reducers/plan.reducer';
import { IPlanSaleItem } from '~features/plan/interfaces';
import { PlanSaleItemListColumn } from '~features/plan/constants';
import { currencyFormat } from '~features/plan/helper';
function SaleItemTable() {
    const { t } = useTranslation();
    const selectedPlan = useAppSelector(selectedPlanSelector);

    return (
        <div className="plan-sale-item-detail-list-wrapper">
            <div className="form-title">
                <span className="mr-8">{t('plan.detail.tab1.saleItem.title')}</span>
            </div>
            <span>{t('plan.detail.tab1.saleItem.detailTitle')}</span>
            <div className="plan-sale-item-detail-list">
                <Table
                    dataSource={selectedPlan?.planSaleItems || []}
                    pagination={false}
                    locale={{
                        emptyText: <div></div>,
                    }}
                    rowKey="id"
                >
                    <Column
                        align="left"
                        width={50}
                        title={`${t('plan.create.saleItemsForm.columns.id')}`}
                        dataIndex=""
                        key={PlanSaleItemListColumn.ID}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            return <div>{index + 1}</div>;
                        }}
                    />
                    <Column
                        title={`${t('plan.create.saleItemsForm.columns.name')}`}
                        width={232}
                        key={PlanSaleItemListColumn.NAME}
                        render={(planSaleItem: IPlanSaleItem, item, index) => {
                            return <div>{planSaleItem?.saleItem?.name}</div>;
                        }}
                    />
                    <Column
                        title={`${t('plan.create.saleItemsForm.columns.price')}`}
                        width={232}
                        dataIndex=""
                        key={PlanSaleItemListColumn.PRICE_ITEM}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            return (
                                <div>
                                    {saleItem?.price
                                        ? currencyFormat(saleItem?.price)
                                        : 0}
                                </div>
                            );
                        }}
                    />
                    <Column
                        title={`${t('plan.create.saleItemsForm.columns.quantity')}`}
                        width={140}
                        dataIndex=""
                        key={PlanSaleItemListColumn.NUMBER_OF_PEOPLE}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            return <div>{saleItem?.quantity}</div>;
                        }}
                    />
                    <Column
                        title={
                            <Tooltip
                                title={t(
                                    'plan.create.saleItemsForm.columns.isForOnePeopleHover',
                                )}
                            >
                                <span className="title-hover">
                                    {`${t(
                                        'plan.create.saleItemsForm.columns.isForOnePeople',
                                    )}`}
                                    <QuestionCircleOutlined color=" #00A0C3;" />
                                </span>
                            </Tooltip>
                        }
                        width={120}
                        dataIndex=""
                        align="center"
                        key={PlanSaleItemListColumn.IS_FOR_ONE_PEOPLE}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            if (saleItem?.isForOnePeople) {
                                return (
                                    <CheckCircleOutlined className="icon-check-outlined" />
                                );
                            } else {
                                return <div></div>;
                            }
                        }}
                    />
                    <Column
                        title={
                            <Tooltip
                                title={t(
                                    'plan.create.saleItemsForm.columns.isFirstDayOnlyHover',
                                )}
                            >
                                <span className="title-hover">
                                    {`${t(
                                        'plan.create.saleItemsForm.columns.isFirstDayOnly',
                                    )}`}
                                    <QuestionCircleOutlined color=" #00A0C3;" />
                                </span>
                            </Tooltip>
                        }
                        width={120}
                        dataIndex=""
                        align="center"
                        key={PlanSaleItemListColumn.IS_FIRST_DAY_ONLY}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            if (saleItem?.isFirstDayOnly) {
                                return (
                                    <CheckCircleOutlined className="icon-check-outlined" />
                                );
                            } else {
                                return <div></div>;
                            }
                        }}
                    />
                    <Column
                        title={`${t('plan.create.saleItemsForm.columns.totalPrices')}`}
                        width={150}
                        dataIndex=""
                        align="center"
                        key={PlanSaleItemListColumn.TOTAL_PRICES}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            if (saleItem?.price && saleItem?.quantity) {
                                return (
                                    <div>
                                        {saleItem.price && saleItem.quantity
                                            ? currencyFormat(
                                                  saleItem.price * saleItem.quantity,
                                              )
                                            : 0}
                                    </div>
                                );
                            } else if (saleItem?.isForOnePeople) {
                                return (
                                    <div>
                                        {saleItem.price
                                            ? currencyFormat(saleItem.price)
                                            : 0}
                                    </div>
                                );
                            }
                        }}
                    />
                </Table>
            </div>
        </div>
    );
}

export default SaleItemTable;
