export const mealReportJa = {
    title: '食事レポート',
    breadcrumbs: {
        report: '帳票',
        meal: '食事レポート',
    },
    filterForm: {
        dateLabel: '集計期間:',
        displayButton: '表示',
        datePicker: {
            placeholder: {
                start: '開始日',
                end: '終了日',
            },
        },
    },
    table: {
        mealName: '食事',
        nightOfStay: '泊目',
        subtotal: '小計',
        total: '合計',
        allTotal: '総合計',
    },
    exportColumns: {
        mealName: '食事',
        nightOfStay: '泊目',
        subtotal: '小計',
        total: '合計',
        allTotal: '総合計',
    },
    exportCSV: 'CSVエクスポート',
};
