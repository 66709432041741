const roomList = {
    title: 'Room setting',
    breadcrumbs: {
        home: 'Home',
        roomSetting: 'Room setting',
        createIndividual: 'Create room',
    },
    numberOfRoom: 'Number of rooms: ',
    roomColumn: {
        num: '#',
        id: 'ID',
        name: 'Room name',
        type: 'Room type name',
        connectingRooms: 'Connecting room',
    },
    importErrorKey: {
        name: 'Room name',
        roomTypeName: 'Room type name',
        relatedRoomNames: 'Connecting room name',
    },
    filterForm: {
        buttonCreateRoomWithConnecting: 'Connecting room setting',
        buttonCreateManyRooms: 'Create multiple rooms',
        buttonRoomOrderSettings: 'Room order settings',
    },
    message: {
        deleteRoomsSuccess: 'Room have been deleted successfully',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Name',
        roomTypeName: 'Room Type Name',
        connectingRoom: 'Connecting Rooms',
    },

    exportColumns: {
        name: 'Room name',
        roomTypeName: 'Room Type Name',
        connectingRoom: 'Connecting Rooms',
        autoGeneratedCode: 'ID',
    },
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
    },
    deleteErrorModal: {
        title: 'Error',
        description: 'Cannot delete following rooms',
    },
};

export default roomList;
