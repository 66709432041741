import { UnorderedListOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InputSearch } from '~components';
import {
    DateType,
    RoomBookingItemBookingStatus,
    SearchField,
} from '~features/room-booking/constants';
import {
    bookingSearchKeywordSelector,
    changeDateTypeQuery,
    changeRoomBookingStatusQuery,
    dateTypeBookingSearchSelector,
    roomBookingStateSelector,
    setBookingItemSearchStatus,
    setBookingSearchKeyword,
    setDateTypeBookingSearch,
    setKeyword,
    setRoomBookingListQuery,
    setSearchField,
    setShowAdvanceSearchForm,
    setShowPreparationListForm,
} from '~features/room-booking/reducers/room-booking.reducer';
import { setShowCreateBookingForm } from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './FilterForm.scss';
import SelectField from './SelectField/SelectField';
import { trackSearchKeyword } from '~plugins/analytics';
import { GA_KEYWORD_CONTENT } from '~common/constants';
import { CreateBookingButton } from '~features/room-booking/pages/SchedulePage/components/CreateBookingButton/CreateBookingButton';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [status, setStatus] = useState<RoomBookingItemBookingStatus>(
        RoomBookingItemBookingStatus.ALL,
    );
    const dateTypeBookingSearch = useAppSelector(dateTypeBookingSearchSelector);
    const prevDateType = useRef(DateType.ALL);
    const bookingSearchKeyword = useAppSelector(bookingSearchKeywordSelector);

    const { roomBookingListQuery, isAdvanceSearch } = useAppSelector(
        roomBookingStateSelector,
    );

    const onSearch = () => {
        dispatch(setBookingSearchKeyword(bookingSearchKeyword));
        dispatch(setDateTypeBookingSearch(DateType.ALL));
        dispatch(setKeyword({ bookingSearchKeyword, status }));
        trackSearchKeyword(bookingSearchKeyword || '', GA_KEYWORD_CONTENT.ROOM_BOOKING);
    };

    const onChangeStatus = (status: RoomBookingItemBookingStatus) => {
        setStatus(status);
        dispatch(setBookingItemSearchStatus(status));
        dispatch(changeRoomBookingStatusQuery(status));
    };

    const onChangeDateType = (dateType: DateType) => {
        dispatch(setDateTypeBookingSearch(dateType));
        dispatch(changeDateTypeQuery(dateType));
    };

    const navigate = useNavigate();
    const onClickCreateButton = () => {
        dispatch(setShowCreateBookingForm(true));
        navigate({
            pathname: '/room-booking/schedule',
        });
    };

    const showAdvanceSearchForm = () => {
        dispatch(setBookingSearchKeyword(''));
        setStatus(RoomBookingItemBookingStatus.ALL);
        dispatch(setBookingItemSearchStatus(RoomBookingItemBookingStatus.ALL));
        dispatch(setDateTypeBookingSearch(DateType.ALL));
        dispatch(setShowAdvanceSearchForm(true));
        dispatch(setSearchField(SearchField.ALL));
        if (!isAdvanceSearch) {
            dispatch(setRoomBookingListQuery({ ...roomBookingListQuery }));
        }
    };

    const onChangeKeywordSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setBookingSearchKeyword(e.target.value));
        if (e.target.value && dateTypeBookingSearch !== DateType.ALL) {
            prevDateType.current = dateTypeBookingSearch;
            dispatch(setDateTypeBookingSearch(DateType.ALL));
        } else if (!e.target.value) {
            dispatch(setDateTypeBookingSearch(prevDateType.current));
            prevDateType.current = DateType.ALL;
        }
    };

    const showPreparationListForm = () => {
        dispatch(setShowPreparationListForm(true));
    };

    return (
        <div className="room-booking-filter-form-wrapper">
            <div className="filter-form">
                <InputSearch
                    placeholder={t('common.searchPlaceholder')}
                    onSearch={onSearch}
                    onChange={onChangeKeywordSearch}
                    value={bookingSearchKeyword}
                    maxLength={30}
                    addonBefore={<SelectField />}
                />
                <Button onClick={showAdvanceSearchForm}>
                    <span className="btn-text">
                        {t('roomBooking.list.filterForm.buttonDetailSearch')}
                    </span>
                </Button>
                <Button onClick={showPreparationListForm}>
                    <UnorderedListOutlined />
                    {t('roomBooking.list.filterForm.buttonPreparationList')}
                </Button>
                <div>
                    <label className="mr-8">
                        {t('roomBooking.list.filterForm.status.label')}:
                    </label>
                    <Select
                        onChange={onChangeStatus}
                        value={status}
                        defaultValue={RoomBookingItemBookingStatus.ALL}
                        className="status-select"
                        placeholder={t('roomBooking.list.filterForm.status.label')}
                        options={Object.values(RoomBookingItemBookingStatus).map(
                            (value) => ({
                                label: t(`roomBooking.page.bookingStatus.${value}`),
                                value,
                            }),
                        )}
                    />
                </div>
                <div>
                    <label className="mr-8">
                        {t('roomBooking.list.filterForm.dateType.label')}:
                    </label>
                    <Select
                        onChange={onChangeDateType}
                        value={dateTypeBookingSearch}
                        defaultValue={DateType.ALL}
                        className="date-type-select"
                        placeholder={t('roomBooking.list.filterForm.dateType.label')}
                        options={Object.values(DateType).map((value) => ({
                            label: t(`roomBooking.list.filterForm.dateType.${value}`),
                            value,
                        }))}
                        disabled={!!bookingSearchKeyword}
                    />
                </div>
            </div>
            <CreateBookingButton createReservation={onClickCreateButton} />
        </div>
    );
}

export default FilterForm;
