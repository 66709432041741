import { PrinterOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Modal } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete, InputText } from '~components';
import {
    DEFAULT_BILL_PROVISO,
    FacilityBookingStatus,
    ReceiptByGroupMapIndex,
    ReceiptItemType,
} from '~features/facility-booking/constants';
import {
    convertSplitReceipt,
    isSplitAllReceiptItemDetails,
} from '~features/facility-booking/helper.split-receipt';
import { IFacilityBookingReceipt } from '~features/facility-booking/interfaces';
import { facilityBookingListStateSelector } from '~features/facility-booking/reducers/facility-booking.reducer';
import {
    setGuestNameToGroupMap,
    setIsShowSplitFacilityBookingReceiptPrintingModal,
    setPrintingReceiptByGroupList,
    setProvisoToGroupMap,
    splitReceiptStateSelector,
} from '~features/facility-booking/reducers/split-receipt.reducer';
import { updateBillToSchema } from '~features/facility-booking/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './BillToTabItem.scss';
import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';

interface IProps {
    groupName: string;
    groupId: string;
    receipt: IFacilityBookingReceipt;
}

function BillToTabItem(props: IProps) {
    const { groupName, groupId, receipt } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { control, getValues } = useForm({
        resolver: yupResolver(updateBillToSchema),
    });

    const { selectedFacilityBookingDetail } = useAppSelector(
        facilityBookingListStateSelector,
    );
    const { receiptByGroupMap } = useAppSelector(splitReceiptStateSelector);

    const totalBill = useMemo(() => {
        if (receipt?.facilityBooking?.status === FacilityBookingStatus.CANCELLED)
            return 0;
        const tableData = convertSplitReceipt(receipt);
        return tableData.reduce((totalBill: number, receiptBookingItem) => {
            receiptBookingItem.children?.forEach((receiptItem) => {
                if (receiptItem.type === ReceiptItemType.PAYMENT) {
                    totalBill -= receiptItem?.amount || 0;
                } else {
                    totalBill += receiptItem?.amount || 0;
                }
            });
            return totalBill;
        }, 0);
    }, [receipt]);

    const printCurrentGroup = () => {
        const receiptByGroupAllMap = receiptByGroupMap[ReceiptByGroupMapIndex.ALL];
        if (!isSplitAllReceiptItemDetails(receiptByGroupAllMap.receipt)) {
            Modal.warning({
                title: t('facilityBooking.splitReceipt.notificationAlert.notSplitDone'),
            });
            return;
        }
        dispatch(setPrintingReceiptByGroupList([receiptByGroupMap[groupId]]));
        dispatch(setIsShowSplitFacilityBookingReceiptPrintingModal(true));
    };

    return (
        <div className="split-facility-booking-bill-to-tab-item-wrapper">
            <div className="bill-to-tab-item-header">
                <div className="title">{groupName}</div>
                <div className="print">
                    <Button onClick={printCurrentGroup}>
                        <PrinterOutlined />
                        {t('facilityBooking.splitReceipt.billToTab.item.print')}
                    </Button>
                </div>
            </div>
            <div className="bill-to-tab-item-body">
                <Form layout="vertical" autoComplete="off" scrollToFirstError>
                    <div className="form-input">
                        <AutoComplete
                            label={t(
                                'facilityBooking.splitReceipt.billToTab.item.billName',
                            )}
                            name={'billName'}
                            defaultValue={selectedFacilityBookingDetail?.guest?.yomigana}
                            options={[
                                {
                                    label: selectedFacilityBookingDetail?.guest?.yomigana,
                                    value: selectedFacilityBookingDetail?.guest?.yomigana,
                                },
                            ]}
                            control={control}
                            onBlur={() =>
                                dispatch(
                                    setGuestNameToGroupMap({
                                        groupId,
                                        guestName: getValues('billName'),
                                    }),
                                )
                            }
                        />
                        <span>
                            {t(
                                'facilityBooking.splitReceipt.billToTab.item.billNameSubText',
                            )}
                        </span>
                    </div>
                    <div className="form-input">
                        <InputText
                            label={t(
                                'facilityBooking.splitReceipt.billToTab.item.billProviso',
                            )}
                            name={'billProviso'}
                            control={control}
                            defaultValue={DEFAULT_BILL_PROVISO}
                            maxLength={INPUT_TEXT_MAX_LENGTH}
                            onBlur={() =>
                                dispatch(
                                    setProvisoToGroupMap({
                                        groupId,
                                        proviso: getValues('billProviso'),
                                    }),
                                )
                            }
                        />
                        <span>
                            {t(
                                'facilityBooking.splitReceipt.billToTab.item.billProvisoSubText',
                            )}
                        </span>
                    </div>

                    <div className="total-bill">
                        <div className="total-bill-header">
                            {t('facilityBooking.splitReceipt.billToTab.item.billTotal')}
                        </div>
                        <div className="total-bill-amount">{`¥${totalBill}`}</div>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default BillToTabItem;
