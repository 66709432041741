const roomFormIndividual = {
    quantity: 'Quantity',
    id: '#',
    roomName: 'Room name',
    roomType: 'Room type',
    startRoomName: 'Starting room number',
    delete: 'Delete',
    generateRoomsBtn: 'Generate',
    deleteBtn: 'Room name',
    cancelBtn: 'Cancel',
    submitBtn: 'To Reservation Overview',
    addRoomBtn: 'Add a row',
    importBtn: 'Upload',
    createSuccessMessage: 'Rooms have been created successful!',
    updateSuccessMessage: 'Rooms have been updated successful!',
    importSuccessMessage: 'CSV file has been uploaded!',
    updateForm: {
        title: 'Edit room',
    },
    roomNameDuplicated: 'Room name is duplicated',
    cancelTitle: 'Do you want to cancel?',
    placeholder: {
        roomType: 'Select room type',
    },
};

export default roomFormIndividual;
