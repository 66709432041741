import guestClassificationListJa from './ja/guest-classification-list.locale';
import guestClassificationFormJa from './ja/guest-classification-form.locale';
import guestClassificationListEn from './en/guest-classification-list.locale';
import guestClassificationFormEn from './en/guest-classification-form.locale';

const guestClassification = {
    ja: {
        list: guestClassificationListJa,
        form: guestClassificationFormJa,
    },
    en: {
        list: guestClassificationListEn,
        form: guestClassificationFormEn,
    },
};

export default guestClassification;
