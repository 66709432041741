import React, { useCallback, useEffect } from 'react';
import { Button, Layout, notification, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../constants';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import './SmtpSettingPage.scss';
import {
    showLoadingSelector,
    updateSmtpSetting,
    setShowLoading,
    getSmtpSetting,
    smtpSettingSelector,
} from '../reducers/smtp-setting.reducer';
import { updateSmtpSchema } from '../schema';
import { ISmtpSettingItem } from '../interfaces';
import SmtpSettingForm from './components/SmtpSettingForm/SmtpSettingForm';
import { showConfirm } from '~common/notification';
import { ErrorMessageType } from '~common/constants';
const { Footer, Content } = Layout;

function SmtpSettingPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showLoading = useAppSelector(showLoadingSelector);
    const selectSmtp = useAppSelector(smtpSettingSelector);

    useEffect(() => {
        dispatch(getSmtpSetting());
    }, []);

    const { control, reset, handleSubmit, setError } = useForm({
        resolver: yupResolver(updateSmtpSchema),
    });

    useEffect(() => {
        const transformSmtpSeting: { [key: string]: string } = {};
        selectSmtp.forEach((item: ISmtpSettingItem) => {
            transformSmtpSeting[item.key] = item.value;
        });
        reset({
            serverName: transformSmtpSeting?.serverName,
            password: transformSmtpSeting?.password,
            smtpServer: transformSmtpSeting?.smtpServer,
            smtpEncryption: transformSmtpSeting?.smtpEncryption,
            smtpPort: transformSmtpSeting?.smtpPort,
            userName: transformSmtpSeting?.userName,
            connectionSecurity: transformSmtpSeting?.connectionSecurity,
        });
    }, [selectSmtp]);

    const _updateSmtpSetting = useCallback(async (formData: ISmtpSettingItem[]) => {
        const response = await dispatch(updateSmtpSetting(formData));
        if (updateSmtpSetting.fulfilled.match(response)) {
            if (response?.payload?.success) {
                notification.success({
                    message: t('smtpSetting.smtpForm.message.updateSuccess'),
                });
                setShowLoading(false);
                return;
            }
            notification.error({
                message: t('smtpSetting.smtpForm.message.updateError'),
                description: response?.payload?.message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                setError(
                    error.key,
                    { type: ErrorMessageType.MANUAL, message: error.message },
                    { shouldFocus: true },
                );
            });
        }
    }, []);
    const onCancel = () => {
        showConfirm({
            title: t('smtpSetting.smtpForm.message.cancelTitle'),
            cancelText: t('smtpSetting.smtpForm.message.buttonCancelText'),
            okText: t('smtpSetting.smtpForm.message.buttonDeleteText'),
            onOk() {
                dispatch(getSmtpSetting());
            },
        });
    };

    const onSubmit = () => {
        handleSubmit(async (data) => {
            const transformSmtpSettingDatas = Object.keys(data)
                .map((item) => {
                    return {
                        key: item,
                        value: data[item] ? data[item].toString() : undefined,
                    };
                })
                .filter((data) => {
                    return data.value;
                });
            _updateSmtpSetting(transformSmtpSettingDatas);
        })();
    };

    return (
        <div className="smtp-setting-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('smtpSetting.smtpForm.title')}
            />
            <Content>
                <SmtpSettingForm control={control} />
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space align="end">
                        <Button onClick={onCancel}>
                            {t('smtpSetting.smtpForm.cancelBtn')}
                        </Button>
                        <Button type="primary" onClick={onSubmit} loading={showLoading}>
                            {t('smtpSetting.smtpForm.submitBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default SmtpSettingPage;
