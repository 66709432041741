import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../../constants';
import './GuestClassificationListPage.scss';
import FilterForm from './components/FilterForm/FilterForm';
import GuestClassificationList from './components/GuestClassificationList/GuestClassificationList';
import GuestClassificationLForm from './components/GuestClassificationForm/GuestClassificationLForm';
import { Layout } from 'antd';
function GuestClassificationListPage() {
    const { t } = useTranslation();
    return (
        <div className="guest-classification-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('guestClassification.list.title')}
            />
            <Layout.Content>
                <div className="guest-classification-page-wrapper">
                    <div className="guest-classification-page-content">
                        <FilterForm />
                        <GuestClassificationList />
                    </div>
                    <GuestClassificationLForm />
                </div>
            </Layout.Content>
        </div>
    );
}

export default GuestClassificationListPage;
