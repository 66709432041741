import { IDropDownOption } from '~common/interfaces';
import i18next from '~plugins/i18next/i18n';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
export const CSV_EXPORT_FILE_NAME = 'facility-type.csv';
export const DISPLAY_NAME_MAX_LENGTH = 30;
export const ABBREVIATION_MAX_LENGTH = 10;
export const BUSINESS_TIME_GAP = 10; // 10 minutes gap

export enum FacilityTypeOrderBy {
    ID = 'id',
    NAME = 'name',
    ABBREVIATION = 'abbreviation',
    BUSINESS_START_TIME = 'businessStartTime',
    BUSINESS_END_TIME = 'businessEndTime',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
}

export const initFacilityTypeListQuery = {
    keyword: '',
    page: 1,
    name: '',
    abbreviation: '',
    autoGeneratedCode: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: FacilityTypeOrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
};

export const breadcrumbs = () => [
    {
        text: i18next.t('facilityType.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('facilityType.list.breadcrumbs.facilityType'),
    },
];

export const enum FacilityTypeColumn {
    ID = 'id',
    HOTEL_ID = 'hotelId',
    NAME = 'name',
    ABBREVIATION = 'abbreviation',
    PRICE = 'price',
    DURATION = 'duration',
    BUSINESS_START_TIME = 'businessStartTime',
    BUSINESS_END_TIME = 'businessEndTime',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
}

export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    NAME = 'name',
    ABBREVIATION = 'abbreviation',
}

export const salesItemOptions: IDropDownOption[] = [
    {
        value: 1,
        label: 'Sales item 1',
    },
    {
        value: 2,
        label: 'Sales item 2',
    },
    {
        value: 3,
        label: 'Sales item 3',
    },
];

// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'facility-type';
export const exportColumns = [
    'autoGeneratedCode',
    'name',
    'abbreviation',
    'businessTimes',
    'price',
    'duration',
];
export const i18ExportKey = 'facilityType.list';
