import { Button, Col, Layout, notification, Row, Space } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import {
    errorMessagesBookingFile,
    getBookingConfirmId,
    getChildrenToForm,
    mapCreateRoomBookingBody,
} from '~features/room-booking/helper';
import {
    ICreateBookingFormData,
    ICreateBookingFormDataItem,
    IGuest,
    IMemberAttachment,
} from '~features/room-booking/interfaces';
import {
    createBooking,
    createBookingStateSelector,
    resetBookingForm,
} from '~features/room-booking/reducers/create-booking.reducer';
import { setBookingTemporaryList } from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import './BookingConfirmPage.scss';
import { BookingDetail } from './components/BookingDetail/BookingDetail';
import { GuestBookingInfo } from './components/GuestBookingInfo/GuestBookingInfo';
import { GuestInfoCard } from './components/GuestInfoCard/GuestInfoCard';
import { UPLOAD_FILE_ERROR_I18N_KEY } from '~features/guest/constants';

export const BookingConfirmPage = () => {
    const { t } = useTranslation();
    const breadcrumbs = () => [
        {
            text: t('roomBooking.page.breadcrumbs.home'),
        },
        {
            text: t('roomBooking.page.breadcrumbs.schedule'),
        },
        {
            text: t('roomBooking.page.breadcrumbs.createBooking'),
        },
        {
            text: t('roomBooking.page.breadcrumbs.bookingConfirm'),
        },
    ];

    const submit = () => {
        const formData = makeFormData();
        _createBooking(formData);
    };
    const dispatch = useAppDispatch();
    const { bookingConfirmData, bookingInfo, isCreatingBooking } = useAppSelector(
        createBookingStateSelector,
    );
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (bookingConfirmData.length === 0) {
            navigate('/room-booking/schedule');
            return;
        }
    }, []);

    const _createBooking = async (formData: ICreateBookingFormData) => {
        const response = await dispatch(
            createBooking(mapCreateRoomBookingBody(formData)),
        );
        if (createBooking.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomBooking.confirmPage.create.success'),
                });
                const messageKey = response.payload
                    ?.message as keyof typeof errorMessagesBookingFile;
                if (
                    messageKey &&
                    Object.keys(errorMessagesBookingFile).includes(messageKey)
                ) {
                    notification.error({
                        message: errorMessagesBookingFile[messageKey],
                    });
                }
                if (response.payload?.message === UPLOAD_FILE_ERROR_I18N_KEY) {
                    notification.error({
                        message: t(`guest.update.${UPLOAD_FILE_ERROR_I18N_KEY}`),
                    });
                }
                dispatch(resetBookingForm());
                dispatch(setBookingTemporaryList([]));
                navigate('/room-booking/schedule');
                return;
            }
            const errorKey = response.payload
                ?.message as keyof typeof errorMessagesBookingFile;
            if (errorKey && Object.keys(errorMessagesBookingFile).includes(errorKey)) {
                notification.error({
                    message: errorMessagesBookingFile[errorKey],
                });
                return;
            }
            notification.error({
                message: t('roomBooking.confirmPage.create.error'),
                description: response.payload?.message || '',
            });
            goBack();
        }
    };

    const makeFormData = (): ICreateBookingFormData => {
        const isOnlyRepresentative = bookingInfo.isReserverTheRepresentative || false;
        const representativeGuestInfor = bookingInfo.representativeGuest;
        const roomBookingItems: ICreateBookingFormDataItem[] = bookingConfirmData.map(
            (item) => {
                const startDateOfStay = parseDate(item.booking?.stayingStartDate)
                    .startOf('day')
                    .fmYYYYMMDDHHmmss();
                const endDateOfStay = parseDate(item.booking?.stayingEndDate)
                    .startOf('day')
                    .fmYYYYMMDDHHmmss();
                const roomRepresentativeGuest: IMemberAttachment = item.booking?.guest
                    ?.index
                    ? bookingInfo.attachments[item.booking?.guest?.index - 1]
                    : {
                          yomigana: null,
                          fullName: null,
                          phone: null,
                          gender: null,
                          birthday: null,
                          emailAddress: null,
                      };
                const isSameRepresentativeGuestWithBookingGroup =
                    representativeGuestInfor.yomigana === roomRepresentativeGuest.yomigana
                        ? true
                        : false;
                return {
                    roomId: item.booking.roomId || null,
                    roomTypeId:
                        item.booking?.room?.id || item.booking?.roomId
                            ? null
                            : item.booking?.roomType?.id,
                    planId: item.booking?.plan?.id || null,
                    memo: null,
                    startDateOfStay,
                    endDateOfStay,
                    numberOfAdults: Number(item.booking?.numberOfAdults || 0),
                    numberOfMale: Number(item.booking?.numberOfMale || 0),
                    numberOfFemale: Number(item.booking?.numberOfFemale || 0),
                    numberOfOtherGenderGuest: Number(
                        item.booking?.numberOfOtherGenderGuest || 0,
                    ),
                    id: item.booking?.id,
                    roomBookingItemChildrenTypes: getChildrenToForm(
                        item.booking?.children,
                        !!item.booking.id,
                    ),
                    isDayUse: item.booking?.isDayUse,
                    checkInTime: item.booking?.checkInTime,
                    checkOutTime: item.booking?.checkOutTime,
                    isRepresentativeRoom: item.booking?.isRepresentativeRoom,
                    guestsIndex: isSameRepresentativeGuestWithBookingGroup
                        ? null
                        : item.booking?.guest?.index
                        ? item.booking?.guest?.index - 1
                        : null,
                    isSameRepresentativeGuestWithBookingGroup:
                        isSameRepresentativeGuestWithBookingGroup,
                } as ICreateBookingFormDataItem;
            },
        );
        const attachments =
            bookingInfo.attachments?.filter((attachment) => {
                return attachment.yomigana;
            }) || [];
        const guests = attachments
            .filter((guest) => {
                return (
                    guest.yomigana !== bookingInfo.representativeGuest.yomigana ||
                    guest.id !== bookingInfo.representativeGuest?.id
                );
            })
            .map((guest) => {
                const _guest: IGuest = {
                    id: guest.id || undefined,
                    fullName: !guest.id ? guest.fullName?.trim() || null : null,
                    yomigana: !guest.id
                        ? (guest.yomigana as string)?.trim() || null
                        : null,
                    mobilePhoneNumber: !guest.id ? guest.phone?.trim() || null : null,
                    emailAddress: !guest.id ? guest.emailAddress?.trim() || null : null,
                    birthday: !guest.id ? guest.birthday : null,
                };
                if (guest.gender) {
                    _guest.gender = !guest.id ? guest.gender : undefined;
                }
                return _guest;
            });

        const representativeGuestId = bookingInfo.representativeGuest?.id;
        const reserverGuestId = !isOnlyRepresentative
            ? bookingInfo.reserverGuest.id
            : null;
        return {
            representativeGuestId: representativeGuestId,
            representativeGuestFullName: !representativeGuestId
                ? bookingInfo.representativeGuest?.fullName?.trim() || null
                : null,
            representativeGuestYomigana: !representativeGuestId
                ? bookingInfo.representativeGuest?.yomigana?.trim() || null
                : null,
            representativeGuestMobilePhoneNumber: !representativeGuestId
                ? bookingInfo.representativeGuest?.phone?.trim() || null || null
                : null,
            representativeGuestEmailAddress: !representativeGuestId
                ? bookingInfo.representativeGuest?.emailAddress?.trim() || null
                : null,
            representativeGuestBirthday: !representativeGuestId
                ? bookingInfo.birthday || null
                : null,
            representativeGuestGender: !representativeGuestId
                ? bookingInfo.gender || null
                : null,
            isReserverTheRepresentative: isOnlyRepresentative,
            reserverGuestId: reserverGuestId,
            reserverGuestFullName:
                !reserverGuestId && !isOnlyRepresentative
                    ? bookingInfo.reserverGuest?.fullName?.trim() || null
                    : null,
            reserverGuestYomigana:
                !reserverGuestId && !isOnlyRepresentative
                    ? bookingInfo.reserverGuest?.yomigana?.trim() || null
                    : null,
            reserverGuestMobilePhoneNumber:
                !reserverGuestId && !isOnlyRepresentative
                    ? bookingInfo.reserverGuest?.phone?.trim() || null
                    : null,
            reserverGuestEmailAddress:
                !reserverGuestId && !isOnlyRepresentative
                    ? bookingInfo.reserverGuest?.emailAddress?.trim() || null
                    : null,
            marketingChannelId: bookingInfo.marketingChannelId,
            memo: bookingInfo.memo?.trim() || null,
            otaMemo: bookingInfo.otaMemo?.trim() || null,
            roomBookingItems: roomBookingItems,
            guests,
            files: bookingInfo.files,
        };
    };

    return (
        <div className="booking-confirm-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('roomBooking.page.breadcrumbs.bookingConfirm')}
                isShowButtonBack
            />
            <Layout.Content>
                <div className="booking-confirm-page-content">
                    <Row gutter={24}>
                        <Col span={16}>
                            {bookingConfirmData.map((item, index) => {
                                return (
                                    <BookingDetail
                                        index={index}
                                        key={`${index}`}
                                        item={item}
                                    />
                                );
                            })}
                        </Col>
                        <Col span={8}>
                            <GuestInfoCard />
                            <GuestBookingInfo />
                        </Col>
                    </Row>
                </div>
            </Layout.Content>
            <Layout.Footer>
                <Space className="d-flex j-end">
                    <Button onClick={goBack}>
                        {t('roomBooking.createBooking.cancel')}
                    </Button>
                    <Button
                        type="primary"
                        id={getBookingConfirmId('btn-submit')}
                        onClick={submit}
                        loading={isCreatingBooking}
                    >
                        {t('roomBooking.createBooking.submit')}
                    </Button>
                </Space>
            </Layout.Footer>
        </div>
    );
};
