import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';
import { SingleSelect } from '~components';
import { IBookingReceiptTableData } from '~features/room-booking/interfaces';
import dayjs, { parseDate } from '~plugins/dayjs';

type Props = {
    control: any;
    item: IBookingReceiptTableData;
    isEditMode: boolean;
};
function DateBookingColumn(props: Props) {
    const { control, item, isEditMode } = props;
    const isCanEdit = useMemo(() => {
        return isEditMode && !item.isCheckout && item.level === 1 && !item.children;
    }, [isEditMode, item]);

    const generateFormattedOptions = (
        startDatetime: string,
        endDatetime: string,
        bookingCreationDate: string,
    ) => {
        const options = new Set<string>();
        const endDate = parseDate(endDatetime);
        const startDate = parseDate(startDatetime);

        for (
            let date = startDate;
            date.isSameOrBefore(endDate, 'day');
            date = date.add(1, 'day')
        ) {
            options.add(date.fmYYYYMMDD('-'));
        }

        options.add(parseDate(bookingCreationDate).fmYYYYMMDD('-'));
        options.add(dayjs().fmYYYYMMDD('-'));

        return Array.from(options)
            .sort()
            .map((option) => ({
                value: option,
                label: option,
            }));
    };

    const formattedOptions = useMemo(() => {
        return generateFormattedOptions(
            item.startDatetime!,
            item.endDatetime!,
            item.bookingCreationDate!,
        );
    }, [item]) as DefaultOptionType[];

    return isCanEdit ? (
        <SingleSelect
            label=""
            name={`${item.id}.bookingDate`}
            control={control}
            options={formattedOptions}
            onClick={(e) => {
                e.stopPropagation();
            }}
        />
    ) : (
        <span>
            {item.bookingDate && item.status
                ? parseDate(item.bookingDate)?.fmYYYYMMDD('-')
                : ''}
        </span>
    );
}

export default DateBookingColumn;
