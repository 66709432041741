import { Input } from 'antd';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { fullWidthNumConvert } from '~common/commonFunctions';
import { Regex } from '~common/constants';
import { IInventoryForm } from '~features/inventory/interfaces';
import { todayDayjs } from '~plugins/dayjs';

type Props = {
    style: React.CSSProperties;
    item: IInventoryForm;
    currentDate: string;
    isEditMode: boolean;
    rowIndex: number;
    isError?: boolean;
    changeStoppingRoom: (
        value: number | string | null,
        date: string,
        rowIndex: number,
        id?: number,
    ) => void;
};

const today = todayDayjs.format('YYYY-MM-DD');

export const CellItem = ({
    style,
    item,
    currentDate,
    rowIndex,
    isError,
    isEditMode = false,
    changeStoppingRoom,
}: Props) => {
    const intPattern = new RegExp(`^[0-9０-９]+$`);
    const [stoppingRoom, setStoppingRoom] = useState<string>('');
    const gridItem = useMemo(() => {
        const _item = item.day?.[currentDate];
        setStoppingRoom(_item?.stoppingRooms?.toString() || '');
        return _item;
    }, [item, currentDate]);
    return (
        <div
            style={style}
            className={classNames('inventory-cell-item', {
                today: today === currentDate,
            })}
        >
            <div className={classNames('inventory-cell', 'checkbox-col')}>
                {isEditMode ? (
                    <div className="stopping-room-input">
                        <Input
                            placeholder="0"
                            value={stoppingRoom}
                            status={isError ? 'error' : undefined}
                            onChange={(event) => {
                                setStoppingRoom(event.target.value);
                            }}
                            onBlur={(event) => {
                                let value = event.target.value;

                                if (value && !intPattern.test(value)) {
                                    event.target.value = '';
                                    return;
                                }
                                if (value && Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                                    value = fullWidthNumConvert(value) || '';
                                }
                                setStoppingRoom(value);
                                changeStoppingRoom(
                                    value,
                                    currentDate,
                                    rowIndex,
                                    item.roomType?.id,
                                );
                            }}
                        />
                    </div>
                ) : (
                    <span
                        className={classNames('', {
                            'gray-color': !(gridItem?.stoppingRooms || 0),
                        })}
                    >
                        {gridItem?.stoppingRooms || 0}
                    </span>
                )}
            </div>
            <div className="inventory-cell remain-room-col">
                <span
                    className={classNames('', {
                        'red-color': !(gridItem?.availableRooms || 0),
                        'available-rooms-negative': (gridItem?.availableRooms || 0) < 0,
                    })}
                >
                    {gridItem?.availableRooms || 0}
                </span>
            </div>
        </div>
    );
};
