import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '../../common/constants';
import { initRoomListQuery, SearchField } from './constants';
import {
    IImportRoom,
    IRoom,
    IRoomBulkCreateFormItem,
    IRoomBulkUpdateFormItem,
    IRoomCreateBody,
    IRoomGetListDropDownQuery,
    IRoomGetListQuery,
    IRoomState,
    IRoomUpdateBody,
} from './interfaces';
import trim from 'lodash/trim';
import { roomService } from './services/room.service';
import cloneDeep from 'lodash/cloneDeep';
import { IIndicatorItemDropDown } from '~features/indicator/interfaces';
import { sortBy } from 'lodash';

const initialState: IRoomState = {
    roomListQuery: cloneDeep(initRoomListQuery),
    totalRooms: 0,
    showLoadingList: false,
    updateIndvidualRoomFormBusy: false,
    isShowUpdateIndividualRoomForm: false,
    isFetchingRoom: false,
    roomList: [],
    roomDropdownList: [],
    individualRoomList: [],
    updateIndividualRoomList: [],
    selectedRoomList: [],
    editRoom: null,
    searchField: SearchField.ALL,
    roomDropDownList: [],
    importRoomList: [],
    importCsvFileName: '',
    hasImportError: false,
};

export const fetchRoomList = createAsyncThunk(
    'roomType/fetchRoomList',
    async (_, { getState }) => {
        const query = roomListQuerySelector(getState() as AppState);
        return await roomService.getList(query);
    },
);

export const getRoomListByIds = createAsyncThunk(
    'roomType/getRoomListByIds',
    async (ids: number[]) => {
        return await roomService.getListByIds(ids);
    },
);

export const createRoom = createAsyncThunk(
    'roomType/createRoom',
    async (formData: IRoomCreateBody) => {
        return await roomService.create(formData);
    },
);

export const bulkCreateRooms = createAsyncThunk(
    'room/bulkCreateRooms',
    async (formData: IRoomBulkCreateFormItem[]) => {
        return await roomService.bulkCreate(formData);
    },
);

export const bulkUpdateRooms = createAsyncThunk(
    'room/bulkUpdateRooms',
    async (formData: IRoomBulkUpdateFormItem[]) => {
        return await roomService.bulkUpdate(formData);
    },
);

export const updateRoom = createAsyncThunk(
    'room/updateRoom',
    async ({ id, formData }: { id: number; formData: IRoomUpdateBody }) => {
        return await roomService.update(id, formData);
    },
);

export const getRoomDetail = createAsyncThunk(
    'room/getRoomDetail',
    async (id: number) => {
        return await roomService.getDetail(id);
    },
);

export const getListForDropDown = createAsyncThunk(
    'room/getListForDropDown',
    async (query: IRoomGetListDropDownQuery) => {
        return await roomService.getListForDropDown(query);
    },
);

export const getDropDownByRoomType = createAsyncThunk(
    'room/getDropDownByRoomType',
    async (ids: number[]) => {
        return await roomService.getDropDownByRoomType(ids);
    },
);

export const importCsvRoom = createAsyncThunk(
    'room/importCsvRoom',
    async (importRoomBody: IImportRoom[]) => {
        return await roomService.importRoomCsv(importRoomBody);
    },
);

export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        setRoomListQuery: (state, action) => {
            state.roomListQuery = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IRoomGetListQuery = {
                limit: state.roomListQuery.limit,
                orderBy: state.roomListQuery.orderBy,
                orderDirection: state.roomListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.roomListQuery = query;
        },
        setRoomList: (state, action) => {
            state.roomList = action.payload;
        },
        setTotalRooms: (state, action) => {
            state.totalRooms = action.payload;
        },
        setIndividualRoomList: (state, action) => {
            state.individualRoomList = action.payload;
        },
        setUpdateIndividualRoomList: (state, action) => {
            state.updateIndividualRoomList = action.payload;
        },
        setSelectedRoomList: (state, action) => {
            state.selectedRoomList = action.payload;
        },
        setEditRoom: (state, action) => {
            state.editRoom = action.payload;
        },
        setImportRoomList: (state, action) => {
            state.importRoomList = action.payload;
        },
        setImportCsvFileName: (state, action) => {
            state.importCsvFileName = action.payload;
        },
        setHasImportError: (state, action) => {
            state.hasImportError = action.payload;
        },
        setShowLoadingList: (state, action) => {
            state.showLoadingList = action.payload;
        },
        setIsShowUpdateIndividualRoomForm: (state, action) => {
            state.isShowUpdateIndividualRoomForm = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IRoomGetListQuery = {
                limit: state.roomListQuery.limit,
                orderBy: state.roomListQuery.orderBy,
                orderDirection: state.roomListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.roomListQuery.keyword ||
                    state.roomListQuery.autoGeneratedCode ||
                    state.roomListQuery.name ||
                    state.roomListQuery.roomTypeName ||
                    '',
            };
            state.roomListQuery = query;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRoomList.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.roomList = action.payload?.data?.items || [];
            state.totalRooms = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchRoomList.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(getRoomListByIds.fulfilled, (state, action) => {
            state.updateIndvidualRoomFormBusy = false;
            state.updateIndividualRoomList = action.payload?.data || [];
        });
        builder.addCase(getRoomListByIds.pending, (state, action) => {
            state.updateIndvidualRoomFormBusy = true;
            state.updateIndividualRoomList = [];
        });
        builder.addCase(bulkCreateRooms.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(importCsvRoom.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(importCsvRoom.fulfilled, (state, action) => {
            state.showLoadingList = false;
        });
        builder.addCase(bulkCreateRooms.fulfilled, (state, action) => {
            state.showLoadingList = false;
            if (action.payload?.success) {
                state.individualRoomList = [];
            }
        });

        builder.addCase(updateRoom.fulfilled, (state, action) => {
            state.editRoom = null;
        });
        builder.addCase(getListForDropDown.fulfilled, (state, action) => {
            state.roomDropDownList = sortBy(action.payload?.data?.items || [], 'name');
        });

        builder.addCase(getDropDownByRoomType.pending, (state, action) => {
            state.isFetchingRoom = true;
        });
        builder.addCase(getDropDownByRoomType.fulfilled, (state, action) => {
            const items = sortBy(action.payload.data?.items || [], 'name');
            state.roomDropdownList = items;
            state.isFetchingRoom = false;
        });
    },
});

export const {
    setRoomListQuery,
    setTotalRooms,
    setRoomList,
    setKeyword,
    setIndividualRoomList,
    setUpdateIndividualRoomList,
    setSelectedRoomList,
    setEditRoom,
    setIsShowUpdateIndividualRoomForm,
    setSearchField,
    setImportRoomList,
    setImportCsvFileName,
    setHasImportError,
    setShowLoadingList,
    resetState,
} = roomSlice.actions;

export const roomStateSelector = (state: AppState) => {
    return state.room;
};
export const roomListSelector = (state: AppState) => {
    return state.room.roomList;
};
export const roomListQuerySelector = (state: AppState) => {
    return state.room.roomListQuery;
};
export const totalRoomsSelector = (state: AppState) => {
    return state.room.totalRooms;
};
export const showLoadingListSelector = (state: AppState) => {
    return state.room.showLoadingList;
};
export const updateIndvidualRoomFormBusySelector = (state: AppState) => {
    return state.room.updateIndvidualRoomFormBusy;
};

export const searchFieldSelector = (state: AppState) => {
    return state.room.searchField;
};

export const updateIndividualRoomListSelector = (state: AppState) => {
    return state.room.updateIndividualRoomList;
};
export const isShowUpdateIndividualRoomFormSelector = (state: AppState) => {
    return state.room.isShowUpdateIndividualRoomForm;
};

export const individualRoomListSelector = (state: AppState) => {
    return state.room.individualRoomList;
};

export const selectedRoomList = (state: AppState) => {
    return state.room.selectedRoomList;
};

export const editRoomSelector = (state: AppState) => {
    return state.room.editRoom;
};

export const roomDropDownListSelector = (state: AppState) => {
    return state.room.roomDropDownList;
};

export const importRoomListSelector = (state: AppState) => {
    return state.room.importRoomList;
};

export const importCsvFileNameSelector = (state: AppState) => {
    return state.room.importCsvFileName;
};

export const hasImportErrorSelector = (state: AppState) => {
    return state.room.hasImportError;
};

export const roomDropDownListGroupSelector = (state: AppState) => {
    const { roomDropDownList } = state.room;
    const list: Record<string, IIndicatorItemDropDown> = {};
    roomDropDownList.forEach((item) => {
        const key = 'type_' + item.roomTypeId;
        const _item = {
            ...item,
            parentId: item.roomTypeId,
            parentName: item.roomTypeName,
            abbreviation: item.abbreviation,
        };
        if (!list[key]) {
            list[key] = {
                id: item.roomTypeId,
                name: item.roomTypeName,
                children: [_item],
            };
        } else {
            list[key].children.push(_item);
        }
    });
    return list;
};
export const dropdownGroupByRoomTypeSelector = (state: AppState) => {
    const { roomDropdownList } = state.room;
    const result: Record<string, IRoom[]> = {};
    roomDropdownList.forEach((item) => {
        const id = 'id_' + item.roomTypeId;
        if (result[id]) {
            result[id].push(item);
        } else {
            result[id] = [item];
        }
    });
    return result;
};

export const totalPageSelector = (state: AppState) => {
    const { totalRooms, roomListQuery } = state.room;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = roomListQuery;
    return Math.ceil(totalRooms / limit);
};

export default roomSlice.reducer;
