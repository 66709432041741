export const reportEn = {
    breadcrumbs: {
        home: 'Home',
        report: 'Summary',
    },
    occupancyHeatMap: 'Occupancy heat map',
    inflowRouteRatio: 'Monthly inflow ratio (by channel)',
    topSections: {
        totalAmount: 'Total Sales',
        adr: 'ADR',
        averageOccupancyRoom: 'Average occupancy rate',
        bookingCount: 'Number of bookings',
    },
    currency: '¥',
    unit: 'booking',
    percent: '%',
    changeCurrentMonth: 'Change to current month',
    diagram: {
        notations: {
            sale: 'Sales',
            lastSale: 'Last year sales',
            more: 'ADR',
            lastMore: 'Last year ADR',
        },
        sale: 'Sales',
        lastSale: 'Last year sales',
        more: 'ADR',
        lastMore: 'Last year ADR',
        leftCol: 'ADR (JPY)',
        rightCol: 'Sales (10,000 JPY)',
        dayRangeOptions: {
            days30: '30 days',
            days90: '90 days',
            days180: '180 days',
        },
    },
    pieChart: {
        marketingChannel: {
            label: 'Number of bookings',
            empty: '0',
        },
    },
    exportCSV: 'CSV export',
    placeholder: {
        rangeDatePicker: {
            start: 'Start date',
            end: 'End date',
        },
        rangeMonthPicker: {
            start: 'Start month',
            end: 'End month',
        },
        selectDate: 'Select date',
        selectMonth: 'Select month',
    },
};
