const paymentDetails = {
    title: '入金レポート表',
    report: '帳票',
    datePeriod: {
        label: '支払期間',
        button: '表示',
    },
    paymentMethod: {
        label: '入金方法',
        placeholder: '科目を選択',
    },
    column: {
        checkInDate: '日付',
        registrationTime: '登録日時',
        roomName: '部屋名',
        representativeName: '宿泊者名',
        unpaidAmount: '支払金額',
    },
    total: '合計',
    yen: '¥{{value}}',
    all: '全て',
    print: {
        datePeriod: '{{start}} to {{end}}',
    },
};

export default paymentDetails;
