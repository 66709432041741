import React, { useCallback, useEffect, useState } from 'react';
import './RoomTypeForm.scss';
import { useTranslation } from 'react-i18next';
import { RightDrawerLayout, InputText, InputNumber } from '~components';
import { Form, Col, Row, notification } from 'antd';
import {
    IRoomTypeCreateBody,
    IRoomTypeUpdateRequest,
    IMaketingChannelForm,
} from '../../interfaces';
import { useAppSelector, useAppDispatch } from '~hooks';
import {
    fetchRoomTypeList,
    selectedRoomTypeSelector,
    setSelectedRoomType,
    getRoomTypeListForDropdown,
    createRoomType,
    formBusySelector,
    updateRoomType,
    getRoomTypeDetail,
    setShowForm,
    showFormSelector,
    roomTypeDropdownOptionsSelector,
} from '../../room-type.reducer';
import { schema } from '../../schema';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    fetchMarketingChannelDropDown,
    marketingChannelDropDownsSelector,
} from '~features/room-booking/reducers/room-booking.reducer';
import { compareFormData } from '~common/commonFunctions';
import { showConfirm } from '~common/notification';
import { getRoomTypeFormId } from '~features/room-type/helper';
import { fetchRoomTypeListForDropdown } from '~features/plan/reducers/plan.reducer';
import { ErrorMessageType, HttpStatus } from '~common/constants';
import { useEscape } from '~common/useHooks';

interface IProp {}

type IRoomTypeForm = IRoomTypeCreateBody & {
    channels: {
        linkCode?: string;
        linkCodeUseDay?: string;
        channelId: number;
    }[];
};

function RoomTypeForm(props: IProp) {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, watch, setError, getValues } =
        useForm<IRoomTypeForm>({
            resolver: yupResolver(schema),
        });
    const roomTypeOptions = useAppSelector(roomTypeDropdownOptionsSelector);
    const selectedRoomType = useAppSelector(selectedRoomTypeSelector);
    const marketingChannels = useAppSelector(marketingChannelDropDownsSelector);
    const formBusy = useAppSelector(formBusySelector);
    const showForm = useAppSelector(showFormSelector);
    const [formDataDefault, setFormDataDefault] = useState<IRoomTypeCreateBody | {}>({});
    const [marketingChannelList, setMarketingChannelList] = useState<
        IMaketingChannelForm[]
    >([]);

    const makeFormData = (): IRoomTypeCreateBody => {
        const {
            abbreviation,
            roomTypeCode,
            name,
            standardCapacity,
            channels = [],
        } = getValues();
        return {
            abbreviation,
            name,
            roomTypeCode,
            standardCapacity: standardCapacity ? Number(standardCapacity) : null,
            marketingChannelIds: channels
                .filter((channel) => channel.channelId)
                .map((channel) => channel.channelId),
        };
    };

    const closeDialog = (reload?: boolean) => {
        dispatch(setShowForm(false));
        setSelectedChannelIds([]);
        dispatch(setSelectedRoomType(null));
        reset();
        setMarketingChannelList([]);
        if (reload) {
            dispatch(fetchRoomTypeList());
        }
    };

    const _createRoomType = useCallback(async (formData: IRoomTypeCreateBody) => {
        const response = await dispatch(createRoomType(formData));
        if (createRoomType.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomType.form.create.success'),
                });
                closeDialog(true);
                return;
            } else {
                (response.payload?.errors || []).forEach((error) => {
                    if (
                        error.key === 'name' ||
                        error.key === 'roomTypeCode' ||
                        error.key === 'abbreviation' ||
                        error.key === 'standardCapacity'
                    ) {
                        setError(
                            `${error.key}`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    } else if (error.key === 'marketingChannelIds' && error.order) {
                        setError(
                            `channels.${error.order}.channelId`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                });
            }
            notification.error({
                message: t('roomType.form.create.error'),
                description: response.payload?.errors?.[0]?.message || '',
            });
        }
    }, []);

    const _updateRoomType = useCallback(async (formData: IRoomTypeUpdateRequest) => {
        const response = await dispatch(updateRoomType(formData));
        if (updateRoomType.fulfilled.match(response)) {
            if (response?.payload?.success) {
                notification.success({
                    message: t('roomType.form.update.success'),
                });
                closeDialog(true);
                return;
            }
            if (response?.payload?.status === HttpStatus.ITEM_NOT_FOUND) {
                closeDialog(true);
            } else {
                (response.payload?.errors || []).forEach((error) => {
                    if (
                        error.key === 'name' ||
                        error.key === 'roomTypeCode' ||
                        error.key === 'abbreviation' ||
                        error.key === 'standardCapacity'
                    ) {
                        setError(
                            `${error.key}`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    } else if (error.key === 'marketingChannelIds' && error.order) {
                        setError(
                            `channels.${error.order}.channelId`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                });
            }
            notification.error({
                message: t('roomType.form.update.error'),
                description: response?.payload?.errors?.[0]?.message || '',
            });
        }
    }, []);

    const onSubmit = () => {
        handleSubmit((values) => {
            const _formData = makeFormData();
            const mapFormData: IRoomTypeCreateBody = {
                ..._formData,
            };
            if (selectedRoomType) {
                _updateRoomType({
                    id: selectedRoomType.id,
                    body: mapFormData,
                });
            } else {
                _createRoomType(mapFormData);
            }
        })();
    };

    const [selectedChannelIds, setSelectedChannelIds] = useState<number[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (showForm) {
            dispatch(
                getRoomTypeListForDropdown({
                    isConnectingRoomType: false,
                    isPullFromTll: false,
                }),
            );
            dispatch(fetchMarketingChannelDropDown({}));
            if (selectedRoomType?.id) {
                _getRoomTypeDetail();
            }
        } else {
            // reset form
            reset({
                name: '',
                abbreviation: '',
                standardCapacity: null,
            });
        }
    }, [showForm, dispatch, selectedRoomType]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name?.includes('channelId')) {
                const ids =
                    value.channels
                        ?.filter((item) => item?.channelId)
                        ?.map((item) => item?.channelId) || [];
                setSelectedChannelIds(ids as number[]);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const initFormData = (data: IRoomTypeCreateBody) => {
        const _channels = data?.marketingChannelIds?.map((id) => {
            return {
                channelId: id,
                linkCode: marketingChannels.find((item) => item.id === id)?.linkageCode,
                linkCodeUseDay: marketingChannels.find((item) => item.id === id)
                    ?.linkageCodeDayUse,
            };
        });
        const formData = {
            id: data?.id,
            name: data?.name,
            roomTypeCode: data?.roomTypeCode,
            abbreviation: data?.abbreviation,
            standardCapacity: data?.standardCapacity,
            channels: _channels,
        };
        reset(formData);
        setFormDataDefault(makeFormData());
    };

    const _getRoomTypeDetail = useCallback(async () => {
        if (!selectedRoomType?.id) return;
        const [response, roomTypeResponse] = await Promise.all([
            dispatch(getRoomTypeDetail(selectedRoomType.id)),
            dispatch(fetchRoomTypeListForDropdown({})),
        ]);
        if (
            getRoomTypeDetail.fulfilled.match(response) &&
            fetchRoomTypeListForDropdown.fulfilled.match(roomTypeResponse)
        ) {
            const { success, data, message } = response.payload;
            if (success) {
                initFormData({
                    id: data?.id,
                    name: data?.name,
                    roomTypeCode: data?.roomTypeCode,
                    abbreviation: data?.abbreviation,
                    standardCapacity: data?.standardCapacity,
                    marketingChannelIds:
                        data?.roomTypeAndMarketingChannels?.map(
                            (_item) => _item.marketingChannelId,
                        ) || [],
                });
                const marketingChannelList =
                    data?.roomTypeAndMarketingChannels?.map(() => {
                        return {
                            channel: null,
                            code: null,
                            linkCode: null,
                        };
                    }) || [];
                if (marketingChannelList?.length > 0) {
                    setMarketingChannelList(marketingChannelList);
                }
                const selectedChannelIds = data?.roomTypeAndMarketingChannels?.map(
                    (marketingChannel) => {
                        return marketingChannel.marketingChannelId;
                    },
                );
                setSelectedChannelIds(selectedChannelIds || []);
            } else {
                notification.error({
                    message: message || '',
                });
            }
        }
    }, [selectedRoomType, updateRoomType, reset, roomTypeOptions]);

    useEffect(() => {
        setFormDataDefault(makeFormData());
    }, []);

    const _showConfirm = () => {
        if (compareFormData(makeFormData(), formDataDefault)) {
            closeDialog();
            return;
        }

        showConfirm({
            title: t('roomType.form.confirm.cancelTitle'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    return (
        <RightDrawerLayout
            open={showForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={
                selectedRoomType
                    ? t('roomType.form.updateTitle')
                    : t('roomType.form.createTitle')
            }
            cancelText={t('common.buttonCancelText')}
            submitText={
                selectedRoomType
                    ? t('common.buttonSaveText')
                    : t('roomType.form.createButton')
            }
            className="create-room-type-drawer"
            busy={formBusy}
            loading={formBusy}
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                <Row className="input-row" gutter={8}>
                    <Col span={12}>
                        <InputText
                            label={t('roomType.form.roomName')}
                            placeholder={t('roomType.form.roomNamePlaceholder')}
                            name="name"
                            control={control}
                            required
                            id={getRoomTypeFormId('name')}
                        />
                    </Col>
                    <Col span={12}>
                        <InputText
                            label={t('roomType.form.roomTypeCode')}
                            placeholder={t('roomType.form.roomTypeCodePlaceholder')}
                            name="roomTypeCode"
                            control={control}
                            required
                            id={getRoomTypeFormId('roomTypeCode')}
                        />
                    </Col>
                </Row>
                <Row className="input-row" gutter={8}>
                    <Col span={12}>
                        <InputText
                            label={t('roomType.form.abbreviation')}
                            placeholder={t('roomType.form.abbreviationPlaceholder')}
                            name="abbreviation"
                            control={control}
                            required
                            id={getRoomTypeFormId('abbreviation')}
                        />
                    </Col>
                    <Col span={12}>
                        <InputNumber
                            label={t('roomType.form.standardCapacity')}
                            placeholder={t('roomType.form.standardCapacityPlaceholder')}
                            name="standardCapacity"
                            control={control}
                            required
                            id={getRoomTypeFormId('standardCapacity')}
                        />
                    </Col>
                </Row>
            </Form>
        </RightDrawerLayout>
    );
}

export default RoomTypeForm;
