import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { selectedFacilityBookingDetailSelector } from '~features/facility-booking/reducers/facility-booking.reducer';
import { useAppSelector } from '~hooks';
import { PhoneOutlined, UserOutlined, ArrowRightOutlined } from '@ant-design/icons';
import './FacilityBookingInformation.scss';
import { parseDateTime } from '~common/commonFunctions';
import { DateFormat } from '~common/constants';
import { ReactComponent as RoomBookingIcon } from '~assets/images/room-booking.svg';
import { useMemo } from 'react';
import { getBookingId } from '~features/room-booking/helper';
import { Link } from 'react-router-dom';

function FacilityBookingInformation() {
    const selectedFacilityBookingDetail = useAppSelector(
        selectedFacilityBookingDetailSelector,
    );
    const { t } = useTranslation();

    const hasRoomBooking = selectedFacilityBookingDetail?.roomBooking?.id;

    const roomBookingNameList = hasRoomBooking
        ? selectedFacilityBookingDetail?.roomBooking?.rooms?.map((room) => {
              return `${room.name} `;
          })
        : '';

    const phoneOfGuest = useMemo(() => {
        if (!selectedFacilityBookingDetail) return '';
        const { guest } = selectedFacilityBookingDetail;
        return guest.mobilePhoneNumber || guest.phoneNumberLandline || '';
    }, [selectedFacilityBookingDetail]);

    return (
        <div className="facility-booking-info">
            <Row gutter={24}>
                <Col span={3}>
                    <p className="mini-text">
                        {t('facilityBooking.detail.facilityBookingInfo.user')}
                    </p>
                    <p className="mini-text text-truncate">
                        {selectedFacilityBookingDetail?.guest?.fullName}
                    </p>
                    <p className="large-text font-weight-text-600 text-truncate">
                        <Link
                            to={
                                '/guest/' +
                                selectedFacilityBookingDetail?.guest?.id +
                                '/detail'
                            }
                        >
                            {selectedFacilityBookingDetail?.guest?.yomigana}
                        </Link>
                    </p>
                    {hasRoomBooking ? (
                        <p className="medium-text">
                            <RoomBookingIcon className="boom-booking-icon" />
                            {roomBookingNameList}
                        </p>
                    ) : (
                        ''
                    )}

                    {phoneOfGuest && (
                        <p className="text-truncate">
                            <PhoneOutlined style={{ marginRight: '5px' }} />
                            {phoneOfGuest}
                        </p>
                    )}
                </Col>
                <Col span={3}>
                    <p className="mini-text">
                        {t(
                            'facilityBooking.detail.facilityBookingInfo.hotelReservation.title',
                        )}
                    </p>
                    {selectedFacilityBookingDetail?.roomBooking?.autoGeneratedCode ? (
                        <p>
                            {`${t(
                                'facilityBooking.detail.facilityBookingInfo.hotelReservation.yes',
                            )} (ID: `}
                            <a
                                href={`/room-booking/${selectedFacilityBookingDetail?.roomBooking?.id}/detail`}
                            >
                                {getBookingId(selectedFacilityBookingDetail)}
                            </a>
                            )
                        </p>
                    ) : (
                        t(
                            'facilityBooking.detail.facilityBookingInfo.hotelReservation.no',
                        )
                    )}
                </Col>
                <Col span={6}>
                    <p className="mini-text">
                        {t('facilityBooking.detail.facilityBookingInfo.detail')}
                    </p>
                    <div className="facility-booking-detail-wrapper">
                        <p className="large-text font-weight-text-600 text-truncate">
                            {selectedFacilityBookingDetail?.facility?.name}
                        </p>
                        <p className="medium-text text-truncate">
                            {selectedFacilityBookingDetail?.facilityType?.name}
                        </p>
                        <p className="number-of-guests">
                            <UserOutlined style={{ marginRight: '5px' }} />
                            {selectedFacilityBookingDetail?.numberOfGuests}
                            {t('facilityBooking.detail.personText')}
                        </p>
                        <p className="mb-2 mini-text">{`${t(
                            'facilityBooking.detail.facilityBookingInfo.stayTime',
                        )} (${t('common.standardTimeTitle')})`}</p>
                        <div className="facility-booking-detail-time">
                            <div>
                                <p className="medium-text">
                                    {parseDateTime(
                                        selectedFacilityBookingDetail?.startDatetime
                                            ? selectedFacilityBookingDetail?.startDatetime
                                            : '',
                                        DateFormat.YYYY_MM_DD_SLASH,
                                    )}
                                </p>
                                <p className="large-text font-weight-text-600">
                                    {parseDateTime(
                                        selectedFacilityBookingDetail?.startDatetime
                                            ? selectedFacilityBookingDetail?.startDatetime
                                            : '',
                                        DateFormat.HH_MM_COLON,
                                    )}
                                </p>
                            </div>
                            <ArrowRightOutlined className="arrow-icon" />
                            <div>
                                <p className="medium-text">
                                    {parseDateTime(
                                        selectedFacilityBookingDetail?.endDatetime
                                            ? selectedFacilityBookingDetail?.endDatetime
                                            : '',
                                        DateFormat.YYYY_MM_DD_SLASH,
                                    )}
                                </p>
                                <p className="large-text font-weight-text-600">
                                    {parseDateTime(
                                        selectedFacilityBookingDetail?.endDatetime
                                            ? selectedFacilityBookingDetail?.endDatetime
                                            : '',
                                        DateFormat.HH_MM_COLON,
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <p className="mini-text">
                        {t('facilityBooking.detail.facilityBookingInfo.memo')}
                    </p>
                    <div className="facility-booking-memo-wrapper">
                        {selectedFacilityBookingDetail?.memo}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default FacilityBookingInformation;
