import axios from 'axios';
import { Dayjs, parseDate } from '~plugins/dayjs';
import { forEach, intersection, uniq } from 'lodash';
import isPlainObject from 'lodash/isPlainObject';
import mapKeys from 'lodash/mapKeys';
import trim from 'lodash/trim';
import { TableCell } from 'pdfmake/interfaces';
import { IColumnOption } from '~features/facility-booking/interfaces';
import { HotelPermission } from '~features/hotel/constants';
import i18next from '~plugins/i18next/i18n';
import localStorageAuthService from './authStorage';
import { DateFormat, Regex, UserGroup } from './constants';
import dayjs from 'dayjs';
import { WorkSheet, utils, writeFile } from 'xlsx';

export function isJson(str: string): boolean {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isStringify<T>(obj: T | Record<string, unknown>): boolean {
    try {
        JSON.stringify(obj);
    } catch (e) {
        return false;
    }
    return true;
}

export function trimData(body: any): void {
    const trimValue = (item: any) => {
        mapKeys(item, (value, key) => {
            // trim string value
            if (typeof value === 'string') {
                item[key] = trim(value);
            }

            // iterate array
            else if (Array.isArray(value)) {
                value.forEach((subValue, index) => {
                    // trim string value
                    if (typeof subValue === 'string' && !trim(subValue as string)) {
                        value[index] = trim(subValue);
                    } else if (isPlainObject(subValue)) {
                        trimValue(subValue);
                    }
                });
            } else if (isPlainObject(value)) {
                trimValue(value);
            }
        });
    };

    trimValue(body);
}

export const downloadFile = async (fileName: string, url: string): Promise<void> => {
    const res = await axios.get(url, {
        responseType: 'arraybuffer',
    });
    const blob = new Blob([res.data], { type: 'text' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const validateUniqueValue = (items: any[], key: string): number[] => {
    const duplicatedItems: number[] = [];
    for (let index = 0; index < items.length - 1; index += 1) {
        const item = items[index];
        const restItems = items.slice(index + 1);
        const duplicatedIndex = restItems.findIndex(
            (it) => it[key] && item[key] && it[key] === item[key],
        );
        if (duplicatedIndex > -1) {
            duplicatedItems.push(index);
            duplicatedItems.push(index + duplicatedIndex + 1);
        }
    }
    return uniq(duplicatedItems);
};

export function parseErrorMessage(message: string): string {
    const [tx, data] = message.split('|');
    if (!data) {
        return i18next.t(tx);
    }
    const dataParsed = isJson(data) ? JSON.parse(data) : {};
    const pathArray = dataParsed.path.split('.');
    dataParsed.path = /\./.test(dataParsed.path)
        ? pathArray[pathArray.length - 1]
        : dataParsed.path;
    return i18next.t(tx, dataParsed);
}

export const parseDateTime = (
    dateTime: Date | string,
    dateTimeFormat = DateFormat.YYYY_MM_DD_HYPHEN,
): string => {
    if (!parseDate(dateTime).isValid()) {
        return '';
    }
    return parseDate(dateTime).format(dateTimeFormat);
};

export const generateName = (startName: string, order: number): string => {
    let name = '';
    if (/^-?\d+$/.test(startName)) {
        name = `${+startName + order}`;
        const startWith0 = [];

        while (
            startName[startWith0.length] === '0' &&
            startWith0.length < startName.length
        ) {
            startWith0.push(0);
        }
        if (name.length > `${+startName}`.length) {
            startWith0.splice(0, 1);
        }
        return startWith0.join('') + name;
    }

    let lastDigitCount = 0;
    // count the total digits at the end of startName
    while (
        /^-?\d+$/.test(
            startName.substring(startName.length - lastDigitCount - 1, startName.length),
        )
    ) {
        lastDigitCount++;
    }
    const firstCharacters = startName.substring(0, startName.length - lastDigitCount);
    const lastCharacters = startName.substring(
        startName.length - lastDigitCount,
        startName.length,
    );

    if (Number(lastCharacters[0]) === 0) {
        let generateNumber = (Number(lastCharacters) + order).toString();
        const generateNumberLength = generateNumber.length;

        for (let i = 1; i <= lastCharacters.length - generateNumberLength; i++) {
            generateNumber = '0' + generateNumber;
        }
        name = `${firstCharacters}${generateNumber}`;
    } else {
        name = `${firstCharacters}${
            lastDigitCount === 0 && order === 0 ? '' : +(+lastCharacters || 0) + order
        }`;
    }

    return name;
};

export const compareFormData = (formData: any, originalData: any): boolean => {
    return JSON.stringify(formData) === JSON.stringify(originalData);
};

export async function downloadCSVFile(content: string, fileName: string) {
    let contentType = 'text/csv';
    const csvFile = new Blob([content], { type: contentType });
    const url = window.URL.createObjectURL(csvFile);

    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

export async function exportCSVFile(
    columns: string[],
    fileName: string,
    data: any[],
    i18Key: string,
    option?: { preHeader: string },
) {
    // append data to this file
    const preHeader = option?.preHeader ? `${option?.preHeader}\n` : '';
    const fields = columns.map((field) =>
        i18next.t<string>(`${i18Key}.exportColumns.${field}`),
    );
    let csvContent = `\uFEFF${preHeader}${fields.map((h) => h).join()}`;
    (data || []).forEach((item) => {
        const p: any[] = [];
        columns.forEach((field) => {
            const fieldData = item[field] ? item[field] : item[field] === 0 ? 0 : '';
            p.push(fieldData);
        });
        csvContent = `${csvContent}\n${p.join()}`;
    });
    let contentType = 'text/csv';
    const csvFile = new Blob([csvContent], { type: contentType });
    const url = window.URL.createObjectURL(csvFile);

    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

export const filterExportCSVBody = (body: any) => {
    mapKeys(body, (value, key) => {
        // remove string contain only space characters
        if (typeof value === 'string') {
            if (value) {
                body[key] = value.trim();
            } else {
                body[key] = undefined;
            }
        }
        // iterate array, remove empty array
        else if (Array.isArray(value) && value.length === 0) {
            body[key] = undefined;
        }
    });
};

export const calculatePositionLeft = (options: {
    from: Dayjs;
    to: Dayjs;
    widthOfColumn: number;
    extend?: number;
    space?: number;
}) => {
    const { from, to, widthOfColumn, extend = 0, space = 8 } = options;
    const diff = to.diff(from, 'minutes');
    const left = diff * widthOfColumn + extend;
    return left + space / 2;
};

export const calculateWidth = (option: {
    from: string;
    to: string;
    widthOfColumn: number;
    space?: number;
}) => {
    const { from, to, space = 8, widthOfColumn } = option;
    if (from === to) {
        return widthOfColumn;
    }
    const dateFrom = parseDate(from);
    const dateTo = parseDate(to);
    const diff = dateTo.diff(dateFrom, 'minutes');

    if (!diff) return widthOfColumn;
    let width = diff * widthOfColumn;
    return width - space;
};

export const getDayColumnsForWeek = (date: string, before = 2, after = 2) => {
    const items: { id: string; label: string; month: number }[] = [];
    const start = parseDate(date).subtract(before, 'week').startOf('week');
    const end = parseDate(date).add(after, 'week').endOf('week');
    const days = end.diff(start, 'day');
    for (let i = 0; i <= days; i++) {
        const id = start.clone().add(i, 'day').fmYYYYMMDD('-');
        items.push({
            id,
            label: start.clone().add(i, 'day').format('D (ddd)'),
            month: start.clone().add(i, 'day').month() + 1,
        });
    }
    return items;
};

export const getDaysInMonth = (month: Dayjs | null) => {
    let date = dayjs(month).startOf('month');
    const days = [];
    while (date.month() === month?.month()) {
        days.push(date);
        date = date.add(1, 'day');
    }
    return days;
};

export const addMoreColumnsForWeek = (start: Dayjs, past = false, days = 7) => {
    const items: { id: string; label: string; month: number }[] = [];
    let _start = past ? start.clone().subtract(days, 'day') : start;
    for (let i = 0; i < days; i++) {
        const id = _start.clone().add(i, 'day').fmYYYYMMDD('-');
        items.push({
            id,
            label: _start.clone().add(i, 'day').format('D (ddd)'),
            month: _start.clone().add(i, 'day').month() + 1,
        });
    }
    return items;
};

export const getHoursColumnsForDay = (date: string) => {
    const items: IColumnOption[] = [];
    const start = parseDate(date).subtract(2, 'day').startOf('day');
    const end = parseDate(date).add(2, 'day').endOf('day');
    const days = end.diff(start, 'day');
    for (let index = 0; index <= days; index++) {
        const day = start.clone().add(index, 'days').fmYYYYMMDD('-');
        for (let i = 0; i < 24; i++) {
            items.push({
                id: day + ` ${i < 10 ? '0' + i : i}:00`,
                day: day,
                hours: `${i < 10 ? '0' + i : i}:00`,
                index: i,
                label: i.toString(),
            });
        }
    }
    return items;
};

export const addMoreColumnsForDay = (start: Dayjs, past = false, days = 1) => {
    const items: IColumnOption[] = [];
    let _start = past ? start.clone().subtract(days, 'day') : start;
    for (let index = 0; index < days; index++) {
        const day = _start.clone().add(index, 'days').fmYYYYMMDD('-');
        for (let i = 0; i < 24; i++) {
            items.push({
                id: day + ` ${i < 10 ? '0' + i : i}:00`,
                day: day,
                hours: `${i < 10 ? '0' + i : i}:00`,
                index: i,
                label: i.toString(),
            });
        }
    }
    return items;
};

export const formatMoney = function (price: number, n = 0, x = 3, s = ',', c = '.') {
    if (!price) return 0;
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = price.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(
        new RegExp(re, 'g'),
        '$&' + (s || ','),
    );
};

export const formatMoneyWithSymbol = function (price: number, symbol: string = '¥') {
    return `${symbol}${formatMoney(price)}`;
};

export const checkUserPermission = (permissions: UserGroup[]) => {
    if (!permissions?.length) {
        return true;
    }

    const selectedHotel = localStorageAuthService.getSelectedHotel();

    const userGroups = localStorageAuthService.getUser()?.groups || [];

    if (
        userGroups[0].includes(UserGroup.HOTEL_ADMIN) &&
        selectedHotel?.permission === HotelPermission.HOTEL
    ) {
        userGroups[0] = UserGroup.HOTEL_OWNER;
    }

    return intersection(userGroups, permissions).length > 0;
};

export const checkNotAllowedUsers = (notAllowedUsers: UserGroup[]) => {
    const userGroups = localStorageAuthService.getUser()?.groups || [];
    return intersection(userGroups, notAllowedUsers).length > 0;
};

export const isDevelopment = () => {
    return process.env.NODE_ENV !== 'production';
};

export const logDebug = (...args: any) => {
    if (isDevelopment()) {
        console.log(...args);
    }
};

/**
 * @param rowData.stack: content of the column
 * @param rowData.colSpan: number of empty columns will be added to row
 * @returns An array contains row data and empty columns
 *
 * This function pad @colSpan number of empty columns to fill up a row of the table
 */
export const makeRowWithCellSpan = (
    rowData: { stack: TableCell; colSpan: number; rowSpan?: number }[],
) => {
    const result = [];
    for (let index = 0; index < rowData.length; index++) {
        const row = rowData[index];
        row.rowSpan = row.rowSpan || 1;
        result.push(row);
        for (let col = 1; col < row.colSpan; col++) {
            result.push({
                text: '',
                rowSpan: row.rowSpan,
            });
        }
    }
    return result;
};

/**
 * @param text                  : text to chunk
 * @param lineMaxLength         : max length of an element in chunk
 * @param forceBreak            : (default = false) break word if the word is too long
 * @param wordBreakAddOnAfter   : (default = '') Add after word that is broken when forceBreak
 *
 *
 * This function divide long text into array of text (chunks) that one element's max length is lineMaxLength
 */
export const textToChunksWithMaxLength = (
    text: string | undefined | null,
    lineMaxLength: number,
    forceBreak = false,
    wordBreakAddOnAfter = '',
): string[] => {
    if (!text) return [];

    if (text.length <= lineMaxLength) return [text];

    const result: string[] = [];
    const wordList = text.split(' ').concat('\0');
    wordList.reduce((previous: string, current: string) => {
        if (current === '\0') {
            previous && result.push(previous);
            return '';
        }

        const _length = previous.length + current.length;

        if (_length >= lineMaxLength) {
            if (previous) result.push(previous);

            if (!forceBreak) return current;

            if (current.length === lineMaxLength) return current;

            const wordChunks = current.match(new RegExp(`.{1,${lineMaxLength}}`, 'g'));
            for (const word of wordChunks || []) {
                if (word.length === lineMaxLength)
                    result.push(`${word}${wordBreakAddOnAfter}`);
                else return word;
            }

            return '';
        }

        return previous.length ? `${previous} ${current}` : current;
    }, '');

    return result;
};

export function paseXmlObject(body: any): void {
    const paseObject = (data: any) => {
        forEach(data, (item, key) => {
            if (
                Array.isArray(item) &&
                item?.length === 1 &&
                Object.keys(item?.[0]).length === 1 &&
                item?.[0][Object.keys(item?.[0])[0]].length === 1 &&
                !(
                    item?.[0][Object.keys(item?.[0])[0]][0] instanceof Object ||
                    Array.isArray(item?.[0][Object.keys(item?.[0])[0]][0])
                )
            ) {
                data[key] = item?.[0][Object.keys(item?.[0])[0]];
            } else if (item instanceof Object || Array.isArray(item)) {
                paseObject(item);
            }
        });
    };
    paseObject(body);
}

export const countImportError = (errors: Record<number, any>) => {
    let totalErrors = 0;
    Object.keys(errors).forEach((order) => {
        Object.keys(errors[+order])
            .filter((key) => errors[+order]?.[key]?.length)
            .forEach((key) => {
                totalErrors++;
            });
    });
    return totalErrors;
};

export const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(':');
    return +hours * 60 + +minutes;
};

export function isDataDetailFetched(dataInReduxStore: any, idParam: string | undefined) {
    return dataInReduxStore && !!idParam && dataInReduxStore?.id === +idParam;
}

export const fullWidthNumConvert = (fullWidthNum?: string) => {
    return fullWidthNum?.replace(Regex.FULL_WIDTH_CHARACTERS, (ch) => {
        if (ch.charCodeAt(0) === 0x30fc || ch.charCodeAt(0) === 0xff0d) {
            return String.fromCharCode(0x2d);
        }
        if (ch.charCodeAt(0) === 0xff0b) {
            return String.fromCharCode(0x2b);
        }
        return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
    });
};

export const exportCsvFileWithXlsx = (data: any, fileName: string, headers: string[]) => {
    // Had to create a new workbook and then add the header
    const workbook = utils.book_new();
    const worksheet: WorkSheet = utils.json_to_sheet([]);
    utils.sheet_add_aoa(worksheet, [headers]);
    // Starting in the second row to avoid overriding and skipping headers
    utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(workbook, worksheet);
    // Create an csv file and try to save to {fileName}.csv
    writeFile(workbook, fileName, { type: 'string', bookType: 'csv' });
};

export const replaceBreakLine = (str: string, replaceValue?: string) => {
    const _replaceValue = replaceValue || ' ';
    return str.replace(/(?:\r\n|\r|\n)/g, _replaceValue);
};
