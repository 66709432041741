import { scroller } from 'react-scroll';

export function scrollToErrorField(errorField: string) {
    scroller.scrollTo(errorField, {
        duration: 300,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'staff-layout-content',
        offset: -420,
    });
}
