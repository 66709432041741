import { IHistory } from '~features/fo-operation-history/interfaces';
import { cloneDeep } from 'lodash';
import {
    AuditLogModules,
    AuditLogActions,
} from '~features/fo-operation-history/constants';
import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import i18next from '~plugins/i18next/i18n';

export function sanitizeLogData(key: string, value: any, module: AuditLogModules) {
    let sanitizedValue = value;
    // check if value is a date
    const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/;
    if (pattern.test(value)) sanitizedValue = parseDate(sanitizedValue)?.fmYYYYMMDD();

    if (/^(true)$|^(false)$/.test(value))
        sanitizedValue = i18next.t(`history.detail.value.${value}`);

    if (key === 'price' && typeof value === 'number') {
        sanitizedValue = i18next.t('history.detail.value.yen', {
            value,
        });
    }

    return sanitizedValue ?? '';
}

function sanitizeChildrenType(logBody: any, action: AuditLogActions) {
    switch (action) {
        case AuditLogActions.UPDATE:
            if (logBody?.priceType) {
                for (let key in logBody.priceType) {
                    if (logBody.priceType[key] === 'fixed') {
                        logBody.priceType[key] = i18next.t(
                            'history.detail.value.childrenType.fixed',
                        );
                    } else
                        logBody.priceType[key] = i18next.t(
                            'history.detail.value.childrenType.pricePerAdult',
                        );
                }
            }
            if (logBody?.priceValue) {
                if (!logBody?.priceType) break;
                for (let key in logBody.priceValue) {
                    if (
                        logBody.priceType[key] ===
                        i18next.t('history.detail.value.childrenType.fixed')
                    ) {
                        logBody.priceValue[key] = i18next.t('history.detail.value.yen', {
                            value: logBody.priceValue[key],
                        });
                    } else
                        logBody.priceValue[key] = i18next.t(
                            'history.detail.value.childrenType.pricePerAdultValue',
                            { value: logBody.priceValue[key] },
                        );
                }
            }
            break;
        default:
            if (typeof logBody.priceType === 'string') {
                if (logBody.priceType === 'fixed') {
                    logBody.priceType = i18next.t(
                        'history.detail.value.childrenType.fixed',
                    );
                } else
                    logBody.priceType = i18next.t(
                        'history.detail.value.childrenType.pricePerAdult',
                    );
            }
            if (typeof logBody.priceValue === 'number') {
                if (
                    logBody.priceType ===
                    i18next.t('history.detail.value.childrenType.fixed')
                ) {
                    logBody.priceValue = i18next.t('history.detail.value.yen', {
                        value: logBody.priceValue,
                    });
                } else
                    logBody.priceValue = i18next.t(
                        'history.detail.value.childrenType.pricePerAdultValue',
                        { value: logBody.priceValue },
                    );
            }
            break;
    }
}
function sanitizeFacilityType(logBody: any, action: AuditLogActions) {
    const formatTime = (value: number) => {
        const hour = Math.round(value / 3600);
        const minute = Math.round(value / 60) - hour * 60;
        return `${hour.toString().padStart(2, '0')} ${i18next.t(
            `history.detail.value.hour`,
        )} ${minute.toString().padStart(2, '0')} ${i18next.t(
            `history.detail.value.minute`,
        )}`;
    };
    // display facility type number
    logBody.facilityTypeNumber = logBody.id;

    switch (action) {
        case AuditLogActions.BULK_UPDATE:
            if (logBody.price !== null) {
                for (let key in logBody.price) {
                    logBody.price[key] = i18next.t('history.detail.value.yen', {
                        value: logBody.price[key],
                    });
                }
            }
            if (logBody.duration !== null) {
                for (let key in logBody.duration)
                    logBody.duration[key] = formatTime(logBody.duration[key]);
            }
            break;
        default:
            logBody.price = i18next.t('history.detail.value.yen', {
                value: logBody.price,
            });
            if (logBody.duration !== null) {
                logBody.duration = formatTime(logBody.duration);
            }
            break;
    }
}
function sanitizeGuestClassification(logBody: any) {
    const color = logBody.color;
    if (typeof color === 'string') {
        logBody.color = '#' + logBody.color;
    } else if (typeof color === 'object' && color !== null) {
        for (let key in color) {
            color[key] = '#' + color[key];
        }
    }
}
function sanitizePlanRankCalendar(logBody: any, action: AuditLogActions) {
    switch (action) {
        case AuditLogActions.BULK_UPDATE:
            if (logBody.status) {
                for (let key in logBody.status) {
                    if (logBody.status[key] !== null)
                        logBody.status[key] = i18next.t(
                            'history.detail.value.' + logBody.status[key],
                        );
                }
            }
            break;
        default:
            if (logBody.status) {
                logBody.status = i18next.t('history.detail.value.' + logBody.status);
            }
            break;
    }
}
function sanitizeRoom(logBody: any, action: AuditLogActions) {
    const cleaningStatus = logBody.cleaningStatus;
    if (action === AuditLogActions.BULK_UPDATE) {
        if (cleaningStatus) {
            for (let key in cleaningStatus) {
                if (cleaningStatus[key] !== null)
                    cleaningStatus[key] = i18next.t(
                        'history.detail.value.' + cleaningStatus[key],
                    );
            }
        }
    } else {
        logBody.cleaningStatus = i18next.t('history.detail.value.' + cleaningStatus);
    }
}
function sanitizeFacilityBooking(logBody: any, action: AuditLogActions) {
    const status = logBody.status;
    if (
        action === AuditLogActions.UPDATE ||
        action === AuditLogActions.UPDATE_STATUS ||
        action === AuditLogActions.CHECK_IN
    ) {
        if (status) {
            for (let key in status) {
                if (status[key] !== null) {
                    status[key] = i18next.t('history.detail.value.' + status[key]);
                }
            }
        }
    } else {
        logBody.status = i18next.t('history.detail.value.' + logBody.status);
    }
}
function sanitizeReceipt(logsBody: any, action: AuditLogActions) {
    if (action === AuditLogActions.BULK_UPDATE) {
        if (Array.isArray(logsBody))
            logsBody?.forEach((logBody: any) => {
                if (logBody.createdData) {
                    if (logBody.createdData.bookingType) {
                        logBody.createdData.bookingType = i18next.t(
                            'history.detail.value.' + logBody.createdData.bookingType,
                        );
                    }
                    if (logBody.createdData.type) {
                        logBody.createdData.type = i18next.t(
                            'history.detail.value.' + logBody.createdData.type,
                        );
                    }
                    if (logBody.createdData.receiptItemDetails) {
                        logBody.createdData.receiptItemDetails.forEach(
                            (item: any) =>
                                (item.type = i18next.t(
                                    'history.detail.value.' + item.type,
                                )),
                        );
                    }
                }
                if (logBody.deletedData) {
                    if (Array.isArray(logBody.deletedData)) {
                        logBody.deletedData.forEach((item: any) => {
                            item.bookingType = i18next.t(
                                'history.detail.value.' + item.bookingType,
                            );
                            item.type = i18next.t('history.detail.value.' + item.type);
                            item.receiptItemDetails.forEach(
                                (item: any) =>
                                    (item.type = i18next.t(
                                        'history.detail.value.' + item.type,
                                    )),
                            );
                        });
                    } else {
                        logBody.deletedData.bookingType = i18next.t(
                            'history.detail.value.' + logBody.deletedData.bookingType,
                        );
                        logBody.deletedData.type = i18next.t(
                            'history.detail.value.' + logBody.deletedData.type,
                        );
                        logBody.deletedData.receiptItemDetails.forEach(
                            (item: any) =>
                                (item.type = i18next.t(
                                    'history.detail.value.' + item.type,
                                )),
                        );
                    }
                }
            });
    }
}
function sanitizeSaleItem(logBody: any, action: AuditLogActions) {
    logBody.saleItemNumber = logBody.id;
    if (action === AuditLogActions.BULK_UPDATE) {
        if (logBody.tax) {
            logBody.tax.beforeData.taxName = logBody.tax.beforeData.name;
            delete logBody.tax.beforeData.name;
            logBody.tax.afterData.taxName = logBody.tax.afterData.name;
            delete logBody.tax.afterData.name;
        }
    } else {
        if (logBody.tax) {
            logBody.tax.forEach((tax: Record<string, string>) => {
                tax.taxName = tax.name;
                delete tax.name;
            });
        }
    }
}
function sanitizeRoomBooking(logBody: any, action: AuditLogActions) {
    switch (action) {
        case AuditLogActions.CREATE:
            logBody?.companionBookingItems?.forEach((item: any) => {
                if (item.bookingStatus) {
                    item.bookingStatus = i18next.t(
                        'history.detail.value.' + item.bookingStatus,
                    );
                }
            });
            break;
        default:
            break;
    }
}
function sanitizeRoomBookingItem(logBody: any, action: AuditLogActions) {
    if (action === AuditLogActions.UPDATE_STATUS) {
        if (logBody.bookingStatus) {
            for (let key in logBody.bookingStatus) {
                logBody.bookingStatus[key] = i18next.t(
                    'history.detail.value.' + logBody.bookingStatus[key],
                );
            }
        }
    }
}

// sanitize log body data before generate tree
export function sanitizeLogBody(history: IHistory) {
    const result = cloneDeep(history.description.body) || [];

    result.forEach((logBody: any) => {
        switch (history.targetObjectKey) {
            case AuditLogModules.CHILDREN_TYPE:
                sanitizeChildrenType(logBody, history.action);
                break;
            case AuditLogModules.FACILITY_TYPE:
                sanitizeFacilityType(logBody, history.action);
                break;

            case AuditLogModules.GUEST_CLASSIFICATION:
                sanitizeGuestClassification(logBody);
                break;
            case AuditLogModules.PLAN_RANK_CALENDAR:
                sanitizePlanRankCalendar(logBody, history.action);
                break;
            case AuditLogModules.ROOM:
                sanitizeRoom(logBody, history.action);
                break;
            case AuditLogModules.FACILITY_BOOKING:
                sanitizeFacilityBooking(logBody, history.action);
                break;
            case AuditLogModules.RECEIPT:
                sanitizeReceipt(logBody, history.action);
                break;

            case AuditLogModules.SALE_ITEM:
                sanitizeSaleItem(logBody, history.action);
                break;
            case AuditLogModules.ROOM_BOOKING:
                sanitizeRoomBooking(logBody, history.action);
                break;
            case AuditLogModules.ROOM_BOOKING_ITEM:
                sanitizeRoomBookingItem(logBody, history.action);
                break;
            default:
                break;
        }
    });

    return result;
}
