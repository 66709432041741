import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, notification, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compareFormData, validateUniqueValue } from '~common/commonFunctions';
import { ErrorMessageType } from '~common/constants';
import { showConfirm } from '~common/notification';
import { useEscape } from '~common/useHooks';
import {
    Checkbox,
    InputPrice,
    InputText,
    RightDrawerLayout,
    SingleSelect,
} from '~components';
import { saleItemTaxTypes } from '~features/sale-item/constants';
import {
    ISaleItemBulkUpdateForm,
    ISaleItemFormItem,
} from '~features/sale-item/interfaces';
import {
    bulkUpdateSaleItems,
    fetchSaleItemList,
    getSaleGroupListForDropdown,
    getTaxListForDropdown,
    selectedSaleItemListDetailSelector,
    setSelectedSaleItemListDetail,
    setShowForm,
    showFormSelector,
    showLoadingFormSelector,
    taxListDropdownSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { bulkUpdateSaleItemsSchema } from '~features/sale-item/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './UpdateSaleItemForm.scss';

function UpdateSaleItemForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showForm = useAppSelector(showFormSelector);
    const showLoadingForm = useAppSelector(showLoadingFormSelector);
    const selectedSaleItemListDetail = useAppSelector(selectedSaleItemListDetailSelector);
    const taxList = useAppSelector(taxListDropdownSelector);
    const [formBusy, setFormBusy] = useState<boolean>(false);

    const { control, reset, handleSubmit, setError, getValues } =
        useForm<ISaleItemBulkUpdateForm>({
            resolver: yupResolver(bulkUpdateSaleItemsSchema),
        });

    useEffect(() => {
        dispatch(getSaleGroupListForDropdown());
        dispatch(getTaxListForDropdown({ taxTypes: saleItemTaxTypes }));
    }, []);

    useEffect(() => {
        const checkedSaleItemList = selectedSaleItemListDetail.map((item) => {
            const indexOfTaxId = taxList.findIndex((tax) => tax.value === item.taxId);
            return {
                ...item,
                taxId: indexOfTaxId !== -1 ? item.taxId : undefined,
            };
        });
        reset({
            items: checkedSaleItemList,
        });
    }, [selectedSaleItemListDetail, taxList]);

    const closeDialog = (refreshData?: boolean) => {
        dispatch(setSelectedSaleItemListDetail([]));
        dispatch(setShowForm(false));
        reset();
        if (refreshData) {
            dispatch(fetchSaleItemList());
        }
    };

    const _bulkUpdateSaleItem = useCallback(async (saleItemList: ISaleItemFormItem[]) => {
        setFormBusy(true);
        const response = await dispatch(
            bulkUpdateSaleItems(
                saleItemList.map((saleItem, index) => {
                    return {
                        id: saleItem.id,
                        name: saleItem.name,
                        price: +saleItem.price,
                        taxId: saleItem.taxId,
                        isShowInBooking: saleItem.isShowInBooking,
                        order: index + 1,
                    };
                }),
            ),
        );
        if (bulkUpdateSaleItems.fulfilled.match(response)) {
            if (response.payload.success) {
                notification.success({
                    message: t('saleItem.update.message.updateSuccess'),
                });
                closeDialog(true);
            } else {
                (response.payload?.errors || []).forEach((error) => {
                    if (error.key === 'name' && error.order) {
                        setError(
                            `items.${error.order - 1}.${error.key}`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                });
                notification.error({
                    message: response.payload?.message || '',
                });
            }
            setFormBusy(false);
        }
    }, []);

    const onSubmit = async () => {
        handleSubmit(async (data) => {
            const saleItemList = getValues().items || [];
            if (!saleItemList.length) {
                return;
            }
            const duplicatedNameIndexes = validateUniqueValue(saleItemList, 'name');
            if (duplicatedNameIndexes.length) {
                duplicatedNameIndexes.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('saleItem.update.message.nameDuplicated'),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkUpdateSaleItem(data.items);
        })();
    };

    const showConfirmCancel = () => {
        if (compareFormData(getValues().items, selectedSaleItemListDetail)) {
            closeDialog();
            return;
        }

        showConfirm({
            title: t('saleItem.update.confirm.cancelTitle'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    return (
        <RightDrawerLayout
            open={showForm}
            busy={formBusy}
            loading={showLoadingForm}
            onClose={showConfirmCancel}
            onSubmit={onSubmit}
            title={t('saleItem.update.title')}
            cancelText={t('common.buttonCancelText')}
            submitText={t('common.buttonSaveText')}
            className="sale-item-form-drawer"
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                {selectedSaleItemListDetail?.map((saleItem, index) => {
                    return (
                        <div key={saleItem.id} className="sale-item-detail-wrapper">
                            <Row gutter={10}>
                                <Col span={12}>
                                    <InputText
                                        label={t('saleItem.update.form.name')}
                                        name={`items.${index}.name`}
                                        control={control}
                                        required
                                        disabled={saleItem.isSeedData}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={5}>
                                    <InputPrice
                                        label={t('saleItem.update.form.price')}
                                        name={`items.${index}.price`}
                                        control={control}
                                        required
                                    />
                                </Col>
                                <Col span={10}>
                                    <SingleSelect
                                        label={t('saleItem.update.form.tax')}
                                        name={`items.${index}.taxId`}
                                        control={control}
                                        showSearch={false}
                                        options={taxList}
                                        required
                                        disabled={saleItem.isSeedData}
                                    />
                                </Col>
                                <Col span={9}>
                                    <Form.Item
                                        label={t('saleItem.update.form.isShowInBooking')}
                                    >
                                        <Checkbox
                                            label={t(
                                                'saleItem.update.form.isShowInBookingTitle',
                                            )}
                                            name={`items.${index}.isShowInBooking`}
                                            control={control}
                                            disabled={saleItem.isSeedData}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
            </Form>
        </RightDrawerLayout>
    );
}

export default UpdateSaleItemForm;
