import { Modal } from 'antd';
import classNames from 'classnames';
import { parseDate } from '~plugins/dayjs';
import React, { HTMLProps, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { checkRangeDateTimeOverlapping } from '~features/room-booking/helper';
import { IScheduleItem } from '~features/room-booking/interfaces';
import {
    scheduleStateSelector,
    setPanelId,
    setSelecting,
    setStartPosition,
    setVisibleCreateBookingPopover,
} from '~features/room-booking/reducers/schedule.reducer';
import {
    endSelectHour,
    getDateFromElement,
    removeSelection,
    useScheduleEvent,
} from '~features/room-booking/util';
import { useAppDispatch, useAppSelector } from '~hooks';

type Props = HTMLProps<HTMLDivElement> & {
    isToday: boolean;
    disabled: boolean;
    item: IScheduleItem;
    rowIndex: number;
    columnIndex: number;
    day: string;
    hours: string;
};

export const CellItem = ({
    isToday,
    disabled,
    item,
    rowIndex,
    columnIndex,
    day,
    hours,
    children,
    style,
    ...rest
}: Props) => {
    const dispatch = useAppDispatch();
    const panelId = `room-${item.parentId}-${item.id}`;
    const { t } = useTranslation();
    // handle select day for create booking
    const { startPosition, isSelecting, visibleCreateBookingPopover, bookingGroupById } =
        useAppSelector(scheduleStateSelector);
    const startSelect = useCallback(
        (position: { x: number; y: number }) => {
            if (visibleCreateBookingPopover) {
                return;
            }
            dispatch(setSelecting(true));
            dispatch(setStartPosition(position));
            dispatch(setPanelId(panelId));
        },
        [panelId, visibleCreateBookingPopover],
    );
    const _endSelect = useCallback(
        (position: { x: number; y: number }) => {
            if (visibleCreateBookingPopover) {
                return;
            }
            dispatch(setSelecting(false));
            if (!startPosition) {
                removeSelection();
                triggerScroll(true);
                return;
            }
            const options = {
                panelId,
                startPosition,
                currentCursor: position,
                onclose: () => {
                    triggerScroll(true);
                },
            };
            const { startDate, endDate, startTime, endTime } =
                getDateFromElement(options);

            const availableDates = checkRangeDateTimeOverlapping({
                start: parseDate(startDate + ' ' + startTime),
                end: parseDate(endDate + ' ' + endTime),
                bookingGroupById,
                roomKey: `${item.parentId}-${item.id}`,
            });

            if (availableDates.length === 0) {
                dispatch(setVisibleCreateBookingPopover(true));
                endSelectHour(options);
            } else {
                removeSelection();
                Modal.error({
                    title: t('roomBooking.schedule.dateRageInvalid'),
                    centered: true,
                    content: (
                        <>
                            <div>{t('roomBooking.schedule.bookingOccupied')}</div>
                            <ul>
                                {availableDates.map((date) => {
                                    return <li>{date}</li>;
                                })}
                            </ul>
                        </>
                    ),
                });
                triggerScroll(true);
                return;
            }
        },
        [startPosition, visibleCreateBookingPopover],
    );
    const { triggerScroll, today } = useScheduleEvent();
    return (
        <div
            style={{
                ...style,
            }}
            {...rest}
            className={classNames('day-view-cell-item-wrapper', {
                'pl-4': item.parentId,
                'pr-4': item.parentId,
            })}
        >
            {item.parentId ? (
                <div
                    className={classNames('day-item border-top', {
                        [panelId]: true,
                        disabled: disabled,
                    })}
                >
                    <div
                        className={classNames('cell-content', {
                            [panelId]: true,
                        })}
                        onClick={() => {
                            if (disabled || today > day) {
                                return;
                            }
                            triggerScroll(false);
                            if (!isSelecting) {
                                startSelect({ x: columnIndex, y: rowIndex });
                            } else {
                                _endSelect({ x: columnIndex, y: rowIndex });
                            }
                        }}
                        data-x={columnIndex}
                        data-y={rowIndex}
                        data-room_type_id={item.parentId}
                        data-room_id={item.id}
                        data-hours={hours}
                        data-day={day}
                    ></div>
                    {children}
                </div>
            ) : (
                <div className="schedule-collapse-header-item"></div>
            )}
        </div>
    );
};
