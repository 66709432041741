import { IBodyResponse } from '~common/interfaces';
import { ApiService } from '~plugins/axios/api';
import { IPaymentMethodDropDown, IPaymentMethodGetListQuery } from '../interfaces';
import { axiosInstanceV2 } from '~plugins/axios';

class PaymentMethodServiceV2 extends ApiService {
    getDropdown(queryString?: IPaymentMethodGetListQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params: queryString,
        }) as Promise<
            IBodyResponse<{
                items: IPaymentMethodDropDown[];
            }>
        >;
    }
}

export const paymentMethodServiceV2 = new PaymentMethodServiceV2(
    {
        baseUrl: '/payment-method',
    },
    axiosInstanceV2,
);
