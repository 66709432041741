import i18next from '~plugins/i18next/i18n';

export const breadcrumbs = () => {
    return [
        {
            text: i18next.t('facility.list.breadcrumbs.home'),
        },
        {
            text: i18next.t('facility.list.breadcrumbs.facility'),
        },
    ];
};

export const breadcrumbsCreatePage = () => {
    return [
        ...breadcrumbs(),
        {
            text: i18next.t('facility.create.breadcrumbs.create'),
        },
    ];
};

export enum IFacilityOrderBy {
    ID = 'id',
    NAME = 'name',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    FACILITY_TYPE_NAME = 'facilityTypeName',
}

export const enum FacilityColumn {
    ID = 'id',
    NAME = 'name',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    FACILITY_TYPE_NAME = 'facilityTypeName',
}

export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    NAME = 'name',
    TYPE = 'facilityTypeName',
}

export const CSV_EXPORT_FILE_NAME = 'facility.csv';

export const enum FacilityTableColumn {
    ID = 'id',
    NAME = 'name',
    FACILITY_TYPE = 'facilityType',
    ACTION = 'action',
}

// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'facility';
export const exportColumns = ['autoGeneratedCode', 'name', 'facilityType'];
export const i18ExportKey = 'facility.list';
export const MAX_FACILITY_ITEMS = 99;
export const CREATE_FACILITIES_FORM_HEIGHT = 560;
