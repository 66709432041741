import customDayjs from '~plugins/dayjs';
import React from 'react';
import classNames from 'classnames';

type Props = {
    style: React.CSSProperties;
    item: { label: string; id: string };
};

const today = customDayjs().format('YYYY-MM-DD');

export const CellHeader = ({ style, item }: Props) => {
    return (
        <div style={style} className="inventory-cell-header">
            <div
                className={classNames('inventory-cell-header-item', {
                    today: item.id === today,
                })}
            >
                {item.label}
            </div>
        </div>
    );
};
