import { PaymentDetailsSearchField } from './constants';
import { MEMO_MAX_LENGTH } from '~common/constants';
import yup from '~plugins/yup';

export const updateMemoCancelBooking = yup.object().shape({
    memo: yup.string().trim().max(MEMO_MAX_LENGTH).nullable().optional(),
});

export const paymentDetailsFilterSchema = yup.object().shape({
    [PaymentDetailsSearchField.PAYMENT_METHOD_IDS]: yup.array().min(1),
});

export const currentGuestFilterSchema = yup.object().shape({
    day: yup.string(),
});

export const salesByCategoryFilterSchema = yup.object().shape({
    day: yup.string(),
});

export const salesByPlansFilterSchema = yup.object().shape({
    day: yup.string(),
});

export const salesByOtaFilterSchema = yup.object().shape({
    day: yup.string(),
});

export const cacellationFilterSchema = yup.object().shape({
    day: yup.string(),
});

export const salesPaymentFilterSchema = yup.object().shape({
    day: yup.string(),
});

export const localTaxFilterSchema = yup.object().shape({
    day: yup.string(),
});

export const operationStatusFilterSchema = yup.object().shape({
    day: yup.string(),
});

export const paymentSummaryFilterSchema = yup.object().shape({
    day: yup.string(),
});

export const unpaidDetailFilterSchema = yup.object().shape({
    day: yup.string(),
});
