import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import yup from '~plugins/yup';
export const createGuestClassificationSchema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters()
        .required(),
});
