import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IMealReportState } from '~features/report/interfaces';
import { reportService } from '~features/report/report.service';
import { AppState } from '~plugins/redux-toolkit/store';

const initialState: IMealReportState = {
    mealReportQuery: {
        datePeriod: [],
    },
    mealReportData: [],
    isLoading: false,
    isShowTable: false,
    isExportCsv: false,
};

export const getMealReport = createAsyncThunk(
    'mealReport/getMealReport',
    async (_, { getState }) => {
        const query = mealReportQuerySelector(getState() as AppState);
        return await reportService.getMealReport(query);
    },
);

const mealReportSlice = createSlice({
    name: 'meal-report',
    initialState,
    reducers: {
        setIsShowTable: (state, action) => {
            state.isShowTable = action.payload;
        },
        setMealReportQuery: (state, action) => {
            state.mealReportQuery = action.payload;
        },
        setExportCsv: (state, action) => {
            state.isExportCsv = action.payload;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMealReport.pending, (state, action) => {
            state.isLoading = true;
            state.isShowTable = true;
        });
        builder.addCase(getMealReport.fulfilled, (state, action) => {
            state.mealReportData = action.payload?.data || [];
            state.isLoading = false;
        });
    },
});

export const { setIsShowTable, setMealReportQuery, setExportCsv, resetState } =
    mealReportSlice.actions;

export const mealReportStateSelector = (state: AppState) => state.mealReport;

export const mealReportQuerySelector = (state: AppState) => {
    return state.mealReport.mealReportQuery;
};

export const mealReportSelector = (state: AppState) => {
    return state.mealReport.mealReportData;
};

export const exportCsvMealReportSelector = (state: AppState) => {
    return state.mealReport.isExportCsv;
};

export default mealReportSlice.reducer;
