const roomTypeList = {
    title: '部屋タイプ設定',
    breadcrumbs: {
        home: 'ホーム',
        roomTypeSetting: '部屋タイプ設定',
    },
    roomColumn: {
        id: 'ID',
        type: '部屋タイプ名',
        roomTypeCode: '部屋タイプコード',
        abbreviation: '略称',
        connectingRooms: 'コネクティングルーム',
        standardCapacity: '標準定員',
        order: '#',
        marketingChannel: '販売チャネル',
    },
    filterForm: {},
    message: {
        oneChoose: '編集するものは一つだけ選択してください',
        deleteRoomTypesSuccess: '部屋タイプが削除されました',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '部屋タイプ名',
        roomTypeCode: '部屋タイプコード',
        abbreviation: '略称',
        connectingRoomTypeName: 'コネクティングルーム',
    },

    exportColumns: {
        name: '部屋タイプ名',
        roomTypeCode: '部屋タイプコード',
        abbreviation: '略称',
        standardCapacity: '標準定員',
        connectingRoomType: 'コネクティングルーム',
        autoGeneratedCode: 'ID',
        marketingChannel: '販売チャネル',
    },
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
    },
    importTitle: 'アップロードプレビュー',
    cancelTitle: 'キャンセルしてもよろしいですか？',
    cancelButton: 'キャンセル',
    uploadButton: 'アップロード',
    importSuccess: 'CSVファイルのアップロードに成功しました',
};

export default roomTypeList;
