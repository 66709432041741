import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
import i18next from '~plugins/i18next/i18n';

export enum OrderBy {
    ID = 'id',
    NAME = 'name',
}

export const enum RoomTypeColumn {
    ID = 'id',
    NAME = 'name',
    ROOM_TYPE_CODE = 'roomTypeCode',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    ABBREVATION = 'abbreviation',
    STANDARD_CAPACITY = 'standardCapacity',
}

export const breadcrumbs = () => [
    {
        text: i18next.t('roomType.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('roomType.list.breadcrumbs.roomTypeSetting'),
    },
];

export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    NAME = 'name',
    ROOM_TYPE_CODE = 'roomTypeCode',
    ABBREVATION = 'abbreviation',
}

export const FILE_NAME = 'room_type.csv';

// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'room_type';
export const exportColumns = [
    'autoGeneratedCode',
    'name',
    'roomTypeCode',
    'abbreviation',
    'standardCapacity',
    'marketingChannel',
];
export const i18ExportKey = 'roomType.list';

export const initRoomTypeListQuery = {
    keyword: '',
    name: '',
    roomTypeCode: '',
    autoGeneratedCode: '',
    abbreviation: '',
    connectingRoomTypeName: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const MAX_CONNECTING_NUMBER = 99;
export const MAX_STANDARD_CAPACITY = 99;

export const enum ImportRoomTypeColumn {
    NAME = 'name',
    ROOM_TYPE_CODE = 'roomTypeCode',
    ABBREVATION = 'abbreviation',
    STANDARD_CAPACITY = 'standardCapacity',
    MARKETING_CHANNEL = 'marketingChannel',
}

export const ROOM_TYPE_NAME_MAX_LENGTH = 30;
export const ABBREVIATION_MAX_LENGTH = 10;
