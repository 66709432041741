import { Space, Typography } from 'antd';
import HotelSmartSelfCheckIn from './HotelSmartSelfCheckIn';
import HotelSmartContactInfo from './HotelSmartContactInfo';
import { useTranslation } from 'react-i18next';
import HotelSmartEditHistory from './EditHistory/HotelSmartEditHistory';
import './HotelSmart.scss';
import TransactionHistory from './TransactionHistory/TransactionHistory';
import {
    hsUrlDetailSelector,
    hsEditHistorySelector,
    isHsUrlDetailSyncSelector,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useAppSelector } from '~hooks';
import { SelfCheckInStatus } from '~features/room-booking/constants';
import HotelSmartKeyCodes from './HotelSmartKeyCodes';
import { ITransactionItem } from '~features/room-booking/interfaces';

const { Text } = Typography;

function HotelSmart() {
    const hsDetailData = useAppSelector(hsUrlDetailSelector);
    const editHistoryData = useAppSelector(hsEditHistorySelector);
    const isHsUrlDetailSync = useAppSelector(isHsUrlDetailSyncSelector);
    const { t } = useTranslation();
    const billings = hsDetailData.billings;
    const modifiedBillingData = billings.map((billing: ITransactionItem) => {
        return { ...billing, amount: billing.amount * -1 };
    });
    const isHSSync = isHsUrlDetailSync;
    const formatSelfCheckInStatus = (status: SelfCheckInStatus) => {
        switch (status) {
            case SelfCheckInStatus.CHECKED_IN:
                return t('roomBooking.detail.hotelSmartCard.checkedIn');
            case SelfCheckInStatus.NOT_ARRIVED:
                return t('roomBooking.detail.hotelSmartCard.notArrived');
            case SelfCheckInStatus.PMS_MANUAL_CHECK_IN:
                return t('roomBooking.detail.hotelSmartCard.pmsManualCheckedIn');
        }
    };
    const RenderContents = () => {
        return isHSSync ? (
            <div>
                <Space align="start" className="hotel-smart-pane">
                    <HotelSmartContactInfo
                        title={t('roomBooking.detail.hotelSmartCard.message')}
                        mail={hsDetailData.guestEmail}
                        phone={hsDetailData.guestPhone}
                    />
                    <HotelSmartEditHistory
                        title={t('roomBooking.detail.hotelSmartCard.myPage')}
                        contents={editHistoryData.data}
                    />
                    <HotelSmartSelfCheckIn
                        title={t('roomBooking.detail.hotelSmartCard.selfCheckIn')}
                        checkInStatus={formatSelfCheckInStatus(
                            hsDetailData.selfCheckInStatus,
                        )}
                    />
                    <HotelSmartKeyCodes
                        title={t('roomBooking.detail.hotelSmartCard.keyCodes')}
                        keyCodes={hsDetailData.keyCodes}
                    />
                    <TransactionHistory
                        billings={modifiedBillingData}
                        payments={hsDetailData.payments}
                    />
                </Space>
            </div>
        ) : (
            <Text type="danger">
                {t('roomBooking.detail.hotelSmartCard.error.syncWithHS')}
            </Text>
        );
    };

    return (
        <div>
            <RenderContents />
        </div>
    );
}

export default HotelSmart;
