import { yupResolver } from '@hookform/resolvers/yup';
import { Button, notification, Space, Spin, Tabs } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { parseDateTime } from '~common/commonFunctions';
import { ErrorMessageType, UploadFileStatus } from '~common/constants';
import { showConfirm } from '~common/notification';
import { StaffLayoutHeader } from '~components';
import GuestBasicInfoForm from '~features/guest/components/GuestBasicInfoForm/GuestBasicInfoForm';
import GuestCompanyInfoForm from '~features/guest/components/GuestCompanyInfoForm/GuestCompanyInfoForm';
import {
    getErrorKeyTabPane,
    getGuestFormId,
    mapUpdateGuestBody,
    validateFileExtension,
    validateFileSize,
} from '~features/guest/helper';
import { IFile, IGuestForm } from '~features/guest/interfaces';
import {
    fetchGuestDetail,
    guestSelector,
    selectedGuestDetailSelector,
    setSelectedGuestDetail,
    showLoadingListSelector,
    updateGuest,
} from '~features/guest/reducers/guest.reducer';
import { scrollToErrorField } from '~features/guest/util';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import {
    ACCEPTED_FILE_EXTENSIONS,
    breadcrumbsUpdate,
    errorMessagesFile,
    GuestPageTabPane,
    MAX_NUMBER_OF_FILES_PER_GUEST,
    MAX_SIZE_FILE,
    UPLOAD_FILE_ERROR_I18N_KEY,
} from '../../constants';
import { updateGuestSchema } from '../../schema';
import './UpdateGuestPage.scss';

function UpdateGuestPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { guestId } = useParams();
    const isLoading = useAppSelector(showLoadingListSelector);
    const { isCreateOrUpdateLoading } = useAppSelector(guestSelector);
    const guestDetail = useAppSelector(selectedGuestDetailSelector);
    const [activeKeyTabPane, setActiveKeyTabPane] = useState(
        GuestPageTabPane.BASIC_INFO_TAB_PANE,
    );

    const {
        control,
        handleSubmit,
        setError,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(updateGuestSchema),
    });

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(fetchGuestDetail(id));
        if (fetchGuestDetail.fulfilled.match(response)) {
            if (!response.payload?.data) {
                navigate('/404');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _updateGuest = useCallback(
        async (guest: IGuestForm) => {
            const response = await dispatch(
                updateGuest({
                    id: Number(guestId),
                    formData: mapUpdateGuestBody(guest),
                }),
            );
            if (updateGuest.fulfilled.match(response)) {
                if (response.payload?.success) {
                    const messageKey = response.payload
                        ?.message as keyof typeof errorMessagesFile;
                    if (
                        messageKey &&
                        Object.keys(errorMessagesFile).includes(messageKey)
                    ) {
                        notification.error({
                            message: errorMessagesFile[messageKey],
                        });
                    }
                    if (response.payload?.message === UPLOAD_FILE_ERROR_I18N_KEY) {
                        notification.error({
                            message: t(`guest.update.${UPLOAD_FILE_ERROR_I18N_KEY}`),
                        });
                    }
                    notification.success({
                        message: t('guest.form.updateSuccessMessage'),
                    });
                    navigate(`/guest/${guestId}/detail`);
                } else {
                    const errorList = response.payload?.errors || [];
                    errorList.forEach((error) => {
                        let message = error.message;
                        if (error.key === 'files') {
                            message = t(error.message);
                        }
                        setError(
                            error.key,
                            { type: ErrorMessageType.MANUAL, message },
                            { shouldFocus: true },
                        );
                        notification.error({
                            message,
                        });
                    });
                    if (errorList?.[0]?.key) {
                        scrollToErrorField(getGuestFormId(errorList?.[0]?.key));
                    }
                    const errorKey = response.payload
                        ?.message as keyof typeof errorMessagesFile;
                    if (errorKey && Object.keys(errorMessagesFile).includes(errorKey)) {
                        notification.error({
                            message: errorMessagesFile[errorKey],
                        });
                        return;
                    }
                    setActiveKeyTabPane(
                        getErrorKeyTabPane(
                            errorList.map((error) => {
                                return error?.key;
                            }),
                            activeKeyTabPane,
                        ),
                    );
                }
            }
        },
        [activeKeyTabPane, guestId, dispatch, navigate, setError, t],
    );

    useEffect(() => {
        if (typeof Number(guestId) === 'number') {
            _getDetail(Number(guestId));
        } else {
            navigate('/404');
        }
        return () => {
            dispatch(setSelectedGuestDetail(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guestId, _getDetail]);

    useEffect(() => {
        if (guestDetail) {
            reset({
                ...guestDetail,
                birthday: guestDetail.birthday && parseDate(guestDetail.birthday),
                mobilePhoneNumber:
                    guestDetail?.mobilePhoneNumber ||
                    guestDetail?.phoneNumberLandline ||
                    '',
                files: guestDetail.filesInformation?.map((file) => ({
                    ...file,
                    status: UploadFileStatus.DONE,
                })),
            });
        }
    }, [guestDetail, reset]);

    useEffect(() => {
        const errorKeys = Object.keys(errors);
        if (errorKeys?.length) {
            setActiveKeyTabPane(getErrorKeyTabPane(errorKeys, activeKeyTabPane));
            scrollToErrorField(getGuestFormId(errorKeys?.[0]));
        }
    }, [errors, activeKeyTabPane]);

    const checkAttachments = (attachmentsOfGuest: IFile[]) => {
        if (attachmentsOfGuest.length > MAX_NUMBER_OF_FILES_PER_GUEST) {
            return {
                isError: true,
                message: t('guest.form.uploadFile.message.maxCount', {
                    maxCount: MAX_NUMBER_OF_FILES_PER_GUEST,
                }),
            };
        }
        const validFileSize = validateFileSize(attachmentsOfGuest, MAX_SIZE_FILE);
        if (validFileSize.isError) {
            return { isError: true, message: validFileSize.message };
        }
        const { isError, message } = validateFileExtension(
            attachmentsOfGuest,
            ACCEPTED_FILE_EXTENSIONS,
        );
        if (isError) {
            return { isError: true, message };
        }
        return { isError: false, message: '' };
    };

    const onSubmit = async () => {
        handleSubmit(async (data) => {
            const updateGuest = data as IGuestForm;
            if (updateGuest.birthday && updateGuest.birthday !== null) {
                updateGuest.birthday = parseDateTime(updateGuest.birthday);
            }
            const attachmentsOfGuest = updateGuest.files || [];
            const { isError, message } = checkAttachments(attachmentsOfGuest);
            if (isError) {
                setError(
                    `files`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message,
                    },
                    { shouldFocus: true },
                );
                return;
            }

            const files = attachmentsOfGuest?.filter(
                (file) => file.status === UploadFileStatus.DONE,
            );
            _updateGuest({ ...updateGuest, files });
        })();
    };

    const onCancel = () => {
        navigate(`/guest/${guestId}/detail`);
    };

    const showConfirmCancel = () => {
        showConfirm({
            title: t('guest.form.confirm.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    return (
        <div className="update-guest-page">
            <Spin spinning={isLoading}>
                <StaffLayoutHeader
                    title={t('guest.update.title')}
                    breadcrumbs={breadcrumbsUpdate()}
                />
                <Content className="update-guest-content">
                    <Tabs
                        activeKey={activeKeyTabPane}
                        onChange={(keyTabPane) => {
                            setActiveKeyTabPane(keyTabPane as GuestPageTabPane);
                        }}
                        className="update-guest-tabs"
                        items={[
                            {
                                key: GuestPageTabPane.BASIC_INFO_TAB_PANE,
                                label: t('guest.update.tabPane.basicInfo'),
                                children: (
                                    <GuestBasicInfoForm
                                        control={control}
                                        setValue={setValue}
                                        isShowUploadFile={!!guestDetail?.id}
                                    />
                                ),
                            },
                            {
                                key: GuestPageTabPane.COMPANY_INFO_TAB_PANE,
                                label: t('guest.update.tabPane.companyInfo'),
                                children: <GuestCompanyInfoForm control={control} />,
                            },
                        ]}
                    ></Tabs>
                </Content>
                <Footer>
                    <div className="footer-btn-group">
                        <Space>
                            <Button onClick={showConfirmCancel}>
                                {t('guest.form.cancelBtn')}
                            </Button>
                            <Button
                                onClick={onSubmit}
                                type="primary"
                                loading={isCreateOrUpdateLoading}
                            >
                                {t('guest.form.updateBtn')}
                            </Button>
                        </Space>
                    </div>
                </Footer>
            </Spin>
        </div>
    );
}

export default UpdateGuestPage;
