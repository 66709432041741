import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DEFAULT_SCROLL_Y_STOPPING_ROOM_COUNT_TABLE,
    HEADER_STOPPING_ROOM_COUNT_TABLE_HEIGHT,
    StoppingRoomCountColumn,
} from '~features/inventory/constants';
import { IRoomTypeDropdown } from '~features/room-type/interfaces';
import { useAppSelector } from '~hooks';
import { showLoadingStoppingRoomCountTableSelector } from '~features/inventory/reducers/inventory.reducer';
import { InputNumber } from '~components';

type Props = {
    control: any;
    roomTypeList: IRoomTypeDropdown[];
};
function StoppingRoomCountTable(props: Props) {
    const { control, roomTypeList } = props;
    const { t } = useTranslation();
    const tableRef = useRef<HTMLDivElement>(null);

    const showLoadingStoppingRoomCountTable = useAppSelector(
        showLoadingStoppingRoomCountTableSelector,
    );
    const stoppingRoomCountColumn: ColumnsType<IRoomTypeDropdown> = [
        {
            title: t('inventory.stoppingRoomCountForm.tableColumn.roomTypeName'),
            key: StoppingRoomCountColumn.NAME,
            dataIndex: StoppingRoomCountColumn.NAME,
            width: '40%',
            ellipsis: true,
        },
        {
            title: t('inventory.stoppingRoomCountForm.tableColumn.totalRooms'),
            key: StoppingRoomCountColumn.TOTAL_ROOMS,
            dataIndex: StoppingRoomCountColumn.TOTAL_ROOMS,
            width: '30%',
        },
        {
            title: t('inventory.stoppingRoomCountForm.tableColumn.stoppingRoomCount'),
            key: StoppingRoomCountColumn.STOPPING_ROOM_COUNT,
            width: '30%',
            render: (roomType: IRoomTypeDropdown) => {
                return (
                    <div className="stopping-room-count-input">
                        <InputNumber
                            label=""
                            name={`roomType-${roomType.id}.stoppingRoomCount`}
                            control={control}
                            required
                        />
                    </div>
                );
            },
        },
    ];
    const getHeight = () => {
        if (!tableRef.current?.clientHeight) {
            return DEFAULT_SCROLL_Y_STOPPING_ROOM_COUNT_TABLE;
        }
        if (
            tableRef.current?.clientHeight > HEADER_STOPPING_ROOM_COUNT_TABLE_HEIGHT &&
            tableRef.current?.clientHeight < DEFAULT_SCROLL_Y_STOPPING_ROOM_COUNT_TABLE
        ) {
            return DEFAULT_SCROLL_Y_STOPPING_ROOM_COUNT_TABLE;
        }
        return tableRef.current?.clientHeight - HEADER_STOPPING_ROOM_COUNT_TABLE_HEIGHT;
    };

    return (
        <div className="table-wrapper" ref={tableRef}>
            <Table
                className="stopping-room-count-table table-scrollbar"
                columns={stoppingRoomCountColumn}
                dataSource={roomTypeList}
                pagination={false}
                loading={showLoadingStoppingRoomCountTable}
                rowKey="id"
                scroll={{
                    y: getHeight(),
                }}
            />
        </div>
    );
}

export default StoppingRoomCountTable;
