import classNames from 'classnames';
import React, {
    useRef,
    useState,
    useLayoutEffect,
    useMemo,
    MutableRefObject,
} from 'react';
import { VariableSizeList, VariableSizeGrid } from 'react-window';
import './GridView.scss';

const absolute = 'absolute';

const getText = (value: string) => String(value === undefined ? '' : value);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const renderColumnHeader = (params) => {
    const {
        index,
        style,
        data: { columns, render },
    } = params;
    return render ? (
        render({ columnIndex: index, style })
    ) : (
        <div style={style}>{columns[index].label || columns[index].id || ''}</div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const renderRowHeader = (params) => {
    const {
        index,
        style,
        data: { render },
    } = params;
    return render ? (
        render({
            rowIndex: index,
            style,
        })
    ) : (
        <div style={style}>{index + 1}</div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const renderCell = (params) => {
    let {
        columnIndex,
        rowIndex,
        style,
        data: { recordset, footerIndex, width, columns, Footer, render },
    } = params;
    if (footerIndex === rowIndex) {
        if (columnIndex === 0) {
            style = { ...style, width };
            return (
                <div style={style}>
                    <Footer />
                </div>
            );
        }
        return null;
    }
    if (render) {
        return render({
            rowIndex,
            columnIndex,
            style,
        });
    } else {
        const record = recordset[rowIndex];
        const value = (record || {})[columns[columnIndex].id];
        style = { ...style, overflow: 'hidden', textOverflow: 'ellipsis' };
        return <div style={style}>{getText(value)}</div>;
    }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const calcColumnSize = (params) => {
    const {
        value,
        column,
        columnHeaderWidth,
        columnHeaderHeight,
        lineHeight,
        columnHorizontalPadding,
        columnVerticalPadding,
        textContext,
    } = params;
    let columnHeight = column.height || columnHeaderHeight;
    let columnWidth = column.width || columnHeaderWidth;
    if (columnHeight && columnWidth) {
        return [columnHeight, columnWidth];
    }
    if (!textContext) {
        return [0, 0];
    }
    const text = getText(value);
    const label = !columnWidth ? column.label || column.id : '';
    const metrics = textContext.measureText(text.length > label.length ? text : label);
    const valueWidth = metrics.width;
    if (typeof value !== 'string') {
        return [
            lineHeight + columnVerticalPadding,
            column.width || (columnWidth || valueWidth) + columnHorizontalPadding,
        ];
    }
    if (!columnWidth) {
        const words = value.split(' ').length;
        columnWidth =
            words > 5 /* A sentence?  */ ? Math.round(valueWidth / 2) : valueWidth;
    }
    if (columnWidth >= valueWidth + columnVerticalPadding) {
        return [
            lineHeight + columnVerticalPadding,
            (column.width || valueWidth) + columnHorizontalPadding,
        ];
    }
    const lines = Math.ceil(valueWidth / columnWidth);
    columnHeight = lines * lineHeight;
    if (column.maxHeight && columnHeight > column.maxHeight) {
        return [
            column.maxHeight,
            (column.width || columnWidth) + columnHorizontalPadding,
        ];
    }
    return [
        columnHeight + columnVerticalPadding,
        (column.width || columnWidth) + columnHorizontalPadding,
    ];
};

export interface ICell {
    rowIndex: number;
    style: React.CSSProperties;
    columnIndex: number;
}

type Props = {
    height?: number;
    maxHeight: number;
    width: number;
    columnHeaderWidth: number;
    columns: {
        id: string;
        label: string;
        height?: number;
        maxHeight?: number;
        width?: number;
    }[];
    recordset: Array<any>;
    footerRenderer?: (data: ICell) => React.ReactNode;
    columnHeaderRenderer: (data: ICell) => React.ReactNode;
    columnSubHeaderRenderer?: (data: ICell) => React.ReactNode;
    cellRenderer: (data: ICell) => React.ReactNode;
    rowHeaderRenderer: (data: ICell) => React.ReactNode;
    getRowContentHeight?: (index: number) => number;
    rowHeaderWidth?: number;
    lineHeight?: number;
    columnHorizontalPadding?: number;
    columnVerticalPadding?: number;
    verticalPadding: number;
    columnHeaderHeight?: number;
    columnSubHeaderHeight?: number;
    columnHeaderProps?: any;
    rowHeaderProps?: any;
    bodyProps?: any;
    gridRef: MutableRefObject<VariableSizeGrid>;
    outerRef?: MutableRefObject<HTMLElement | undefined>;
    style?: React.CSSProperties;
    className?: string;
    onScroll?: (param: { scrollLeft: number; scrollTop: number }) => void;
    scrollToTopOnNewRecordset?: boolean;
    showSubHeader?: boolean;
    gridViewContentHeightRef?: MutableRefObject<number | undefined>;
};

export const GridView = (props: Props) => {
    let {
        height,
        width,
        recordset,
        footerRenderer: Footer,
        columns,
        columnHeaderHeight,
        columnSubHeaderHeight,
        columnHeaderWidth,
        showSubHeader,
        columnHeaderRenderer,
        columnSubHeaderRenderer,
        cellRenderer,
        rowHeaderRenderer,
        rowHeaderWidth = 0,
        columnHeaderProps,
        rowHeaderProps,
        bodyProps,
        maxHeight,
        gridRef,
        outerRef,
        gridViewContentHeightRef,
        scrollToTopOnNewRecordset,
        lineHeight,
        style,
        columnHorizontalPadding,
        columnVerticalPadding,
        verticalPadding,
        className,
        onScroll,
        getRowContentHeight,
        ...rest
    } = props;

    const [font, setFont] = useState('Arial');
    const [textContext, setTextContex] = useState<CanvasRenderingContext2D>();
    useLayoutEffect(() => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context) {
            context.font = font;
            setTextContex(context);
        }
    }, [font, columns]);

    if (!lineHeight && textContext) {
        const fontSize = parseFloat(textContext.font);
        lineHeight = fontSize + fontSize / 4;
    }
    if (!columnHeaderHeight) {
        if (lineHeight) {
            columnHeaderHeight = lineHeight;
        } else {
            columnHeaderHeight = 0;
        }
    }

    const [rowHeights, columnWidths, totalHeight] = useMemo(() => {
        const rowHeights: number[] = [];
        const columnWidths: number[] = [];
        let totalHeight = 0;
        const calcColumnsSize = (record: any) => {
            let recordRowHeight = 0;
            let i = 0;
            for (const column of columns) {
                const value = record[column.id];
                const [columnHeight, columnWidth] = calcColumnSize({
                    value,
                    column,
                    lineHeight,
                    columnHorizontalPadding,
                    columnVerticalPadding,
                    textContext,
                    columnHeaderHeight,
                    columnHeaderWidth,
                });
                if (columnHeight > recordRowHeight) {
                    recordRowHeight = columnHeight;
                }
                if (!columnWidths[i] || columnWidth > columnWidths[i]) {
                    columnWidths[i] = columnWidth;
                }
                i++;
                totalHeight += recordRowHeight;
            }
            rowHeights.push(recordRowHeight);
        };
        if (recordset.length) {
            recordset.forEach(calcColumnsSize);
        } else {
            calcColumnsSize({});
        }
        return [rowHeights, columnWidths, totalHeight];
    }, [
        recordset,
        columns,
        lineHeight,
        columnHorizontalPadding,
        columnVerticalPadding,
        textContext,
        columnHeaderWidth,
        columnHeaderHeight,
    ]);

    const _getRowHeaderHeight = (i: number) => rowHeights[i] || 0;
    const mayBeRef = useRef<VariableSizeGrid>();

    if (!gridRef) {
        gridRef = mayBeRef as MutableRefObject<VariableSizeGrid>;
    }
    const innerRef = useRef<any>(null);
    const headerRef = useRef<any>(null);
    const subHeaderRef = useRef<any>(null);
    const rowHeaderRef = useRef<any>(null);

    const _onScroll = (params: any) => {
        const { scrollLeft, scrollTop } = params;
        headerRef.current.scrollTo(scrollLeft);
        subHeaderRef?.current?.scrollTo(scrollLeft);
        if (rowHeaderRef.current) {
            rowHeaderRef.current.scrollTo(scrollTop);
        }
        if (onScroll) {
            onScroll({ scrollLeft, scrollTop });
        }
    };

    useLayoutEffect(() => {
        if (scrollToTopOnNewRecordset) {
            gridRef?.current?.scrollTo({ scrollLeft: 0, scrollTop: 0 });
        }
        gridRef?.current?.resetAfterRowIndex(0);
        if (rowHeaderRef.current) {
            rowHeaderRef.current.resetAfterIndex(0);
        }
    }, [recordset, rowHeights, columnWidths, scrollToTopOnNewRecordset, gridRef]);

    useLayoutEffect(() => {
        setFont(window.getComputedStyle(innerRef.current, null).getPropertyValue('font'));
    }, [style, props.className]);

    const footerIndex = Footer ? recordset.length : -1;
    const rowCount = recordset.length + (Footer ? 1 : 0);
    const hasRowHeader = rowHeaderWidth > 0;
    const gridWidth = width - rowHeaderWidth;
    const columnsWidth = columnWidths.reduce((w, width) => w + width, 0);
    let widthIsNotEnough = gridWidth < columnsWidth;
    let requiredHeight = columnHeaderHeight + totalHeight;

    let heightIsNotEnough;
    if (height === undefined) {
        height = requiredHeight + verticalPadding;
    } else {
        heightIsNotEnough = requiredHeight > height;
    }
    if (height > maxHeight) {
        height = maxHeight;
        heightIsNotEnough = true;
        if (gridWidth < columnsWidth) {
            widthIsNotEnough = true;
        }
    }

    if (gridViewContentHeightRef) {
        gridViewContentHeightRef.current = height - columnHeaderHeight;
    }

    useLayoutEffect(() => {
        headerRef.current?.resetAfterIndex(0);
        subHeaderRef?.current?.resetAfterIndex(0);
        gridRef?.current?.resetAfterColumnIndex(0);
    }, [columns, rowHeights, columnWidths, gridRef]);

    const getColumnWidth = (i: number) => columnWidths[i] || 0;
    const headerMarginRight = 0;
    const columnHeaderMarginBottom = 0;

    return (
        <div
            {...rest}
            className={classNames(className, 'grid-view-wrapper')}
            style={{ ...(style || {}), width, position: 'relative', height }}
        >
            <div
                style={{
                    position: absolute,
                    top: 0,
                    left: rowHeaderWidth,
                    zIndex: 15,
                    backgroundColor: '#ffffff',
                }}
            >
                <VariableSizeList
                    ref={headerRef}
                    layout="horizontal"
                    height={columnHeaderHeight}
                    width={gridWidth - headerMarginRight}
                    itemCount={columns.length}
                    itemSize={getColumnWidth}
                    {...columnHeaderProps}
                    itemData={{ columns, render: columnHeaderRenderer }}
                    style={{
                        overflow: 'hidden',
                        ...((columnHeaderProps && columnHeaderProps.style) || {}),
                    }}
                >
                    {renderColumnHeader}
                </VariableSizeList>
                {showSubHeader && (
                    <VariableSizeList
                        ref={subHeaderRef}
                        layout="horizontal"
                        height={columnSubHeaderHeight}
                        width={gridWidth - headerMarginRight}
                        itemCount={columns.length}
                        itemSize={getColumnWidth}
                        {...columnHeaderProps}
                        itemData={{ columns, render: columnSubHeaderRenderer }}
                        style={{
                            overflow: 'hidden',
                            ...((columnHeaderProps && columnHeaderProps.style) || {}),
                        }}
                    >
                        {renderColumnHeader}
                    </VariableSizeList>
                )}
            </div>
            {hasRowHeader && (
                <div
                    style={{
                        position: absolute,
                        left: 0,
                        top: columnHeaderHeight + (columnSubHeaderHeight || 0),
                        zIndex: 20,
                        backgroundColor: '#ffffff',
                    }}
                    className="grid-view-row-header-wrapper"
                >
                    <VariableSizeList
                        ref={rowHeaderRef}
                        height={height - columnHeaderHeight - columnHeaderMarginBottom}
                        width={rowHeaderWidth}
                        itemCount={recordset.length}
                        itemSize={getRowContentHeight || _getRowHeaderHeight}
                        {...rowHeaderProps}
                        itemData={{ render: rowHeaderRenderer }}
                        style={{
                            overflow: 'hidden',
                            ...((rowHeaderProps && rowHeaderProps.style) || {}),
                        }}
                    >
                        {renderRowHeader}
                    </VariableSizeList>
                </div>
            )}
            <div
                style={{
                    position: absolute,
                    left: rowHeaderWidth,
                    top: columnHeaderHeight + (columnSubHeaderHeight || 0),
                }}
            >
                <VariableSizeGrid
                    ref={gridRef}
                    className="grid-view-content"
                    innerRef={innerRef}
                    outerRef={outerRef}
                    height={height - columnHeaderHeight}
                    width={gridWidth}
                    rowCount={rowCount}
                    rowHeight={getRowContentHeight || _getRowHeaderHeight}
                    columnCount={columns.length}
                    columnWidth={getColumnWidth}
                    onScroll={_onScroll}
                    itemData={{
                        recordset,
                        footerIndex,
                        width: width - headerMarginRight,
                        columns,
                        Footer,
                        render: cellRenderer,
                    }}
                    {...bodyProps}
                >
                    {renderCell}
                </VariableSizeGrid>
            </div>
        </div>
    );
};

GridView.defaultProps = {
    columnHorizontalPadding: 0,
    columnVerticalPadding: 0,
    verticalPadding: 0,
    maxHeight: 1000,
    columnHeaderWidth: 20,
};
