const guestForm = {
    basicInfo: {
        contentTitle: 'Guest Information',
        attachmentTitle: 'Attachment',
        fullName: {
            label: 'Full name',
            placeholder: 'Ex: Yamada Tarou',
        },
        yomigana: {
            label: 'Full name (Yomigana)',
            placeholder: 'Ex: Yamada Tarou',
        },
        mobilePhoneNumber: {
            label: 'Phone number',
            placeholder: 'Ex: 070123000',
        },
        emailAddress: {
            label: 'Email address',
            placeholder: 'Ex: example@tabist.com',
        },
        birthday: {
            label: 'Date of Birth',
            placeholder: 'yyyy/mm/dd',
        },
        gender: {
            label: 'Gender',
            placeholder: 'Select gender',
            male: 'Male',
            female: 'Female',
            other: 'Other',
        },
        postalCode: {
            label: 'Postal code',
            placeholder: '000000',
        },
        address: {
            label: 'Address',
            placeholder: 'Tokyoe, Minata-ku, Kaigan 1-7-1',
        },
        country: {
            label: 'Nationality',
            placeholder: 'Select nationality',
        },
        classification: {
            label: 'Guest type setting',
            vip: 'VIP',
            regular: 'Regular',
            beCareful: 'Be careful',
            clearBtn: 'Delete',
        },
        memo: {
            label: 'Memo',
            placeholder: 'Please enter other information',
        },
        image: {
            title: 'Drag and drop image to upload',
            description: 'Maximum upload file size',
        },
    },
    companyInfo: {
        title: 'Company information',
        companyName: {
            label: 'Company name',
            placeholder: 'Ex: Tabist',
        },
        companyYomigana: {
            label: 'Company name (Yomigana)',
            placeholder: 'Ex: Tabist',
        },
        companyPhoneNumber: {
            label: 'Phone number',
            placeholder: 'Ex: 090-0000-0000',
        },
        companyEmailAddress: {
            label: 'Email address',
            placeholder: 'Ex: taro.yamada@tabist.com',
        },
        companyPostalCode: {
            label: 'Postal code',
            placeholder: '000000',
        },
        companyAddress: {
            label: 'Address',
            placeholder: 'Tokyo, Minato-ku, kaigan, 1-7-1',
        },
        companyWebsite: {
            label: 'Website',
            placeholder: 'ex: http://tabist.co.jp',
        },
    },
    addBtn: 'New Guest',
    cancelBtn: 'Cancel',
    createBtn: 'Register',
    updateBtn: 'Save',
    createSuccessMessage: 'Guest have been created succcessful!',
    updateSuccessMessage: 'Guest have been updated succcessful!',
    confirm: { cancelTitle: 'Do you want to cancel?' },
    uploadFile: {
        message: {
            acceptTypeError: 'Only allow the following formats: {{format}}',
            maxCount: 'Maximum number of attachments per guest: {{maxCount}}',
            guestDataNotJSON: 'Guest data is not JSON type',
            guestDataRequire: 'Guest data is required field',
        },
    },
};

export default guestForm;
