import { IBodyResponse, IGetListResponse } from '~common/interfaces';
import axiosService from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import { ITax, ITaxBulkUpdateBody } from '../interfaces';

class TaxService extends ApiService {
    getDropdown(): Promise<IBodyResponse<{ items: ITax[] }>> {
        return this._get(`${this.baseUrl}/dropdown`);
    }

    getList(): Promise<IBodyResponse<ITax[]>> {
        return this._get(this.baseUrl);
    }

    bulkUpdate(
        body: ITaxBulkUpdateBody,
    ): Promise<IBodyResponse<IGetListResponse<ITax[]>>> {
        return this._post(`${this.baseUrl}/bulk-save`, body);
    }
}

export const taxService = new TaxService({ baseUrl: '/tax' }, axiosService);
