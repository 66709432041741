import { Button, Spin, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import GuestDetailCompanyInfo from '~features/guest/components/GuestDetailCompanyInfo/GuestDetailCompanyInfo';
import GuestBookingHistory from '~features/guest/components/GuestBookingHistory/GuestBookingHistory';
import {
    fetchGuestDetail,
    resetBookingHistorySearchQuery,
    setActiveGuestPageTabPane,
    showLoadingListSelector,
} from '~features/guest/reducers/guest.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import GuestDetailBasicInfo from '../../components/GuestDetailBasicInfo/GuestDetailBasicInfo';
import { breadcrumbsDetail, GuestPageTabPane } from '../../constants';
import './GuestDetailPage.scss';
import { getGuestClassificationListForDropdown } from '~features/guest-classification/guest-classification.reducer';

function GuestDetailPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { guestId } = useParams();
    const isLoading = useAppSelector(showLoadingListSelector);

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(fetchGuestDetail(id));
        if (fetchGuestDetail.fulfilled.match(response)) {
            if (!response.payload?.data) {
                navigate('/404');
            }
        }
    }, []);

    //This re-renders when the route changes
    useEffect(() => {
        if (typeof Number(guestId) === 'number') {
            _getDetail(Number(guestId));
        } else {
            navigate('/404');
        }
    }, []);

    useEffect(() => {
        dispatch(getGuestClassificationListForDropdown({}));
    }, []);

    const onChangeTabPane = (activeKey: string) => {
        dispatch(setActiveGuestPageTabPane(activeKey));
        if (activeKey === GuestPageTabPane.BOOKING_HISTORY_TAB_PANE) {
            setIsShowEditButton(false);
            dispatch(resetBookingHistorySearchQuery());
        } else {
            setIsShowEditButton(true);
        }
    };

    const onClickEditButton = () => {
        navigate(`/guest/${guestId}/update`);
    };

    const [isShowEditButton, setIsShowEditButton] = useState(true);
    return (
        <div className="guest-detail-page">
            <Spin spinning={isLoading}>
                <StaffLayoutHeader
                    breadcrumbs={breadcrumbsDetail()}
                    title={t('guest.detail.title')}
                    rightComponent={
                        isShowEditButton ? (
                            <div>
                                <Button
                                    className="btn-edit"
                                    type="primary"
                                    onClick={onClickEditButton}
                                >
                                    {t('guest.detail.editButton')}
                                </Button>
                            </div>
                        ) : undefined
                    }
                />
                <Content className="guest-detail-content">
                    <Tabs
                        onChange={onChangeTabPane}
                        className="guest-detail-tabs"
                        items={[
                            {
                                key: GuestPageTabPane.BASIC_INFO_TAB_PANE,
                                label: t('guest.detail.tabPane.basicInfo'),
                                children: <GuestDetailBasicInfo />,
                            },
                            {
                                key: GuestPageTabPane.COMPANY_INFO_TAB_PANE,
                                label: t('guest.detail.tabPane.companyInfo'),
                                children: <GuestDetailCompanyInfo />,
                            },
                            {
                                key: GuestPageTabPane.BOOKING_HISTORY_TAB_PANE,
                                label: t('guest.detail.tabPane.bookingHistory'),
                                children: <GuestBookingHistory />,
                            },
                        ]}
                    ></Tabs>
                </Content>
            </Spin>
        </div>
    );
}

export default GuestDetailPage;
