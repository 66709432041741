import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { salesByOtaBreadcrumbs } from '~features/report/constants';
import { salesByOtaSelector } from '~features/report/reducers/sales-by-ota.reducer';
import { useAppSelector } from '~hooks';
import FilterForm from './FilterForm/FilterForm';
import SalesByOtaTable from './SalesByOtaTable/SalesByOtaTable';

function SalesByOtaPage() {
    const { t } = useTranslation();
    const { isShowSalesByOtaTable } = useAppSelector(salesByOtaSelector);

    return (
        <div className="sales-by-plans-page">
            <StaffLayoutHeader
                breadcrumbs={salesByOtaBreadcrumbs()}
                title={t('report.salesByOta.title')}
            />
            <Layout.Content>
                <div className="sales-by-ota-page-wrapper">
                    <div className="sales-by-ota-page-content">
                        <FilterForm />
                        {isShowSalesByOtaTable && <SalesByOtaTable />}
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default SalesByOtaPage;
