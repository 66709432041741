import { IBodyResponse } from '~common/interfaces';
import axiosService from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import {
    ICancellationReport,
    ICurrentGuestQuery,
    ICurrentGuestReport,
    IDiagram,
    ILocalTaxDetail,
    ILocalTaxSummaryQuery,
    IMealReport,
    IMealReportQuery,
    IOccupancyHeatMap,
    IOperationStatus,
    IOperationStatusGetListQuery,
    IPaymentDetail,
    IPaymentDetailsQuery,
    IPaymentSummary,
    IPaymentSummaryQuery,
    IReportKutchanTaxDataItems,
    IReportTaxParams,
    IReportTokyoTaxDataItems,
    ISalesByCategory,
    ISalesByCategoryQuery,
    ISalesPayment,
    ISalesSummary,
    ISalesSummaryQuery,
    ITopSectionOverview,
    ITopSectionQuery,
    IUnpaidDetail,
    IUpdateCancellationReport,
} from './interfaces';

import { parseDate } from '~plugins/dayjs';

class ReportService extends ApiService {
    getTopSectionOverview(query: ITopSectionQuery) {
        const [start, end] = query.datePeriod;
        const startOfDay = parseDate(start)?.fmYYYYMMDDHHmmss();
        const endOfDay = parseDate(end)?.fmYYYYMMDDHHmmss();
        const queryString = {
            datePeriod: [startOfDay, endOfDay],
        };
        return this._get(`${this.baseUrl}/top-section-overview`, {
            params: queryString,
        }) as Promise<IBodyResponse<ITopSectionOverview>>;
    }

    getSalesPayment(datePeriod: string[]) {
        return this._get(`${this.baseUrl}/sale-payment-detail`, {
            params: { datePeriod },
        }) as Promise<IBodyResponse<ISalesPayment[]>>;
    }

    getSalesSummaryData(params: ISalesSummaryQuery) {
        return this._get(`${this.baseUrl}/sale-summary`, {
            params,
        }) as Promise<IBodyResponse<ISalesSummary[]>>;
    }

    getCancellationData(datePeriod: string[]) {
        return this._get(`${this.baseUrl}/cancellation-no-show-booking`, {
            params: { datePeriod },
        }) as Promise<IBodyResponse<ICancellationReport[]>>;
    }

    updateBookingCancellation(updateData: IUpdateCancellationReport) {
        return this._patch(`/room-booking/cancel-booking/${updateData.id}`, {
            ...updateData.body,
        }) as Promise<IBodyResponse<ICancellationReport[]>>;
    }
    getOperationStatusData(query: IOperationStatusGetListQuery) {
        return this._get(`${this.baseUrl}/operation-status`, {
            params: query,
        }) as Promise<IBodyResponse<IOperationStatus[]>>;
    }

    getPaymentDetails(params: IPaymentDetailsQuery) {
        return this._get(this.baseUrl + '/payment-detail', { params }) as Promise<
            IBodyResponse<IPaymentDetail[]>
        >;
    }

    getOccupancyHeatMap(query: ITopSectionQuery) {
        return this._get(this.baseUrl + '/occupancy-heat-map', {
            params: query,
        }) as Promise<IBodyResponse<IOccupancyHeatMap[]>>;
    }

    getSalesByCategory(params: ISalesByCategoryQuery) {
        return this._get(`${this.baseUrl}/sale-by-category`, {
            params,
        }) as Promise<IBodyResponse<ISalesByCategory[]>>;
    }

    getUnpaidDetail(datePeriod: string[]) {
        return this._get(`${this.baseUrl}/unpaid-detail`, {
            params: { datePeriod },
        }) as Promise<IBodyResponse<IUnpaidDetail[]>>;
    }

    getDiagramData(query: ITopSectionQuery) {
        return this._get(this.baseUrl + '/adr-and-amount-diagram-section', {
            params: query,
        }) as Promise<IBodyResponse<IDiagram[]>>;
    }

    getPaymentSummary(params: IPaymentSummaryQuery) {
        return this._get(`${this.baseUrl}/payment-summary`, {
            params,
        }) as Promise<IBodyResponse<IPaymentSummary[]>>;
    }

    getTaxSummary(params: ILocalTaxSummaryQuery) {
        return this._get(`${this.baseUrl}/tax-summary`, {
            params,
        }) as Promise<IBodyResponse<ILocalTaxDetail[]>>;
    }
    getCurrentGuest(params: ICurrentGuestQuery) {
        return this._get(`${this.baseUrl}/current-guest`, {
            params,
        }) as Promise<IBodyResponse<ICurrentGuestReport[]>>;
    }

    getMealReport(params: IMealReportQuery) {
        return this._get(`${this.baseUrl}/meal-report`, {
            params,
        }) as Promise<IBodyResponse<IMealReport[]>>;
    }

    getTaxReport(params: IReportTaxParams) {
        return this._get('/report/tax', {
            params,
        }) as Promise<
            IBodyResponse<{
                items: IReportTokyoTaxDataItems[] | IReportKutchanTaxDataItems[];
            }>
        >;
    }

    getPlanDropdown() {
        return this._get('/plan/dropdown') as Promise<
            IBodyResponse<{
                items: any[];
            }>
        >;
    }

    getMarketingChannelsDropdown() {
        return this._get('/marketing-channel/dropdown') as Promise<
            IBodyResponse<{
                items: any[];
            }>
        >;
    }
}
export const reportService = new ReportService({ baseUrl: '/reporting' }, axiosService);
