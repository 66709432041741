import { yupResolver } from '@hookform/resolvers/yup';
import { Button, notification, Space, Tabs } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { parseDateTime } from '~common/commonFunctions';
import { ErrorMessageType, UploadFileStatus } from '~common/constants';
import { showConfirm } from '~common/notification';
import { StaffLayoutHeader } from '~components';
import GuestBasicInfoForm from '~features/guest/components/GuestBasicInfoForm/GuestBasicInfoForm';
import GuestCompanyInfoForm from '~features/guest/components/GuestCompanyInfoForm/GuestCompanyInfoForm';
import {
    getErrorKeyTabPane,
    getGuestFormId,
    mapCreateGuestBody,
    validateFileExtension,
    validateFileSize,
} from '~features/guest/helper';
import { IFile, IGuestForm } from '~features/guest/interfaces';
import { createGuest, guestSelector } from '~features/guest/reducers/guest.reducer';
import { scrollToErrorField } from '~features/guest/util';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import {
    ACCEPTED_FILE_EXTENSIONS,
    breadcrumbsCreate,
    errorMessagesFile,
    GuestPageTabPane,
    MAX_NUMBER_OF_FILES_PER_GUEST,
    MAX_SIZE_FILE,
    UPLOAD_FILE_ERROR_I18N_KEY,
} from '../../constants';
import { createGuestSchema } from '../../schema';
import './CreateGuestPage.scss';

function CreateGuestPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [activeKeyTabPane, setActiveKeyTabPane] = useState(
        GuestPageTabPane.BASIC_INFO_TAB_PANE,
    );

    const { isCreateOrUpdateLoading } = useAppSelector(guestSelector);

    const {
        control,
        handleSubmit,
        getValues,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(createGuestSchema),
    });

    useEffect(() => {
        if (Object.keys(errors).length) {
            setActiveKeyTabPane(
                getErrorKeyTabPane(Object.keys(errors), activeKeyTabPane),
            );
            scrollToErrorField(getGuestFormId(Object.keys(errors)?.[0]));
        }
    }, [errors]);

    const _createGuest = useCallback(
        async (guest: IGuestForm) => {
            const response = await dispatch(createGuest(mapCreateGuestBody(guest)));
            if (createGuest.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('guest.form.createSuccessMessage'),
                    });
                    if (response.payload?.message === UPLOAD_FILE_ERROR_I18N_KEY) {
                        notification.error({
                            message: t(`guest.update.${UPLOAD_FILE_ERROR_I18N_KEY}`),
                        });
                    }
                    navigate('/guest');
                } else {
                    const errorList = response.payload?.errors || [];
                    errorList.forEach((error) => {
                        setError(
                            error.key,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                        notification.error({
                            message: error.message,
                        });
                    });
                    if (errorList?.[0]?.key) {
                        scrollToErrorField(getGuestFormId(errorList?.[0]?.key));
                    }
                    const errorKey = response.payload
                        ?.message as keyof typeof errorMessagesFile;
                    if (errorKey && Object.keys(errorMessagesFile).includes(errorKey)) {
                        notification.error({
                            message: errorMessagesFile[errorKey],
                        });
                        return;
                    }
                    setActiveKeyTabPane(
                        getErrorKeyTabPane(
                            errorList.map((error) => {
                                return error?.key;
                            }),
                            activeKeyTabPane,
                        ),
                    );
                }
            }
        },
        [activeKeyTabPane],
    );

    const checkAttachments = (attachmentsOfGuest: IFile[]) => {
        if (attachmentsOfGuest.length > MAX_NUMBER_OF_FILES_PER_GUEST) {
            return {
                isError: true,
                message: t('guest.form.uploadFile.message.maxCount', {
                    maxCount: MAX_NUMBER_OF_FILES_PER_GUEST,
                }),
            };
        }
        const validFileSize = validateFileSize(attachmentsOfGuest, MAX_SIZE_FILE);
        if (validFileSize.isError) {
            return { isError: true, message: validFileSize.message };
        }
        const { isError, message } = validateFileExtension(
            attachmentsOfGuest,
            ACCEPTED_FILE_EXTENSIONS,
        );
        if (isError) {
            return { isError: true, message };
        }
        return { isError: false, message: '' };
    };

    const onSubmit = async () => {
        handleSubmit(async (data) => {
            const newGuest = getValues() as IGuestForm;
            if (newGuest.birthday && newGuest.birthday !== null) {
                newGuest.birthday = parseDateTime(newGuest.birthday);
            }
            const attachmentsOfGuest = newGuest.files || [];

            const { isError, message } = checkAttachments(attachmentsOfGuest);
            if (isError) {
                setError(
                    `files`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message,
                    },
                    { shouldFocus: true },
                );
                return;
            }

            const files = attachmentsOfGuest?.filter(
                (file) => file.status === UploadFileStatus.DONE,
            );
            _createGuest({ ...newGuest, files });
        })();
    };

    const onCancel = () => {
        navigate('/guest');
    };

    const showConfirmCancel = () => {
        showConfirm({
            title: t('guest.form.confirm.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    return (
        <div className="create-guest-page">
            <StaffLayoutHeader
                title={t('guest.create.title')}
                breadcrumbs={breadcrumbsCreate()}
            />
            <Content className="create-guest-content">
                <Tabs
                    activeKey={activeKeyTabPane}
                    onChange={(keyTabPane) => {
                        setActiveKeyTabPane(keyTabPane as GuestPageTabPane);
                    }}
                    className="create-guest-tabs"
                    items={[
                        {
                            key: GuestPageTabPane.BASIC_INFO_TAB_PANE,
                            label: t('guest.create.tabPane.basicInfo'),
                            children: (
                                <GuestBasicInfoForm
                                    control={control}
                                    setValue={setValue}
                                    isShowUploadFile={true}
                                    isCreateForm={true}
                                />
                            ),
                        },
                        {
                            key: GuestPageTabPane.COMPANY_INFO_TAB_PANE,
                            label: t('guest.create.tabPane.companyInfo'),
                            children: <GuestCompanyInfoForm control={control} />,
                        },
                    ]}
                ></Tabs>
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space>
                        <Button onClick={showConfirmCancel}>
                            {t('guest.form.cancelBtn')}
                        </Button>
                        <Button
                            onClick={onSubmit}
                            type="primary"
                            id={getGuestFormId('btn-submit')}
                            loading={isCreateOrUpdateLoading}
                        >
                            {t('guest.form.createBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default CreateGuestPage;
