import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import {
    IFacilityBookingReceiptDetail,
    IFacilityBookingReceiptItem,
    IFacilityBookingReceiptItemDetail,
} from '~features/facility-booking/interfaces';
import {
    DEFAULT_NUMBER_OF_GROUPS,
    ReceiptByGroupMapIndex,
    SelectSplitBillTypeOptions,
} from '~features/room-booking/constants';
import { AppState } from '~plugins/redux-toolkit/store';
import { ISplitReceiptState } from '../interfaces';
import {
    IReceiptItemDetails,
    IRoomBookingItemReceipt,
    IRoomBookingItemsReceiptItem,
} from './../interfaces';

const initialState: ISplitReceiptState = {
    selectedSplitBillType: SelectSplitBillTypeOptions.BASED_ON_ROOM,
    receiptByGroupMap: {},
    numberOfGroups: DEFAULT_NUMBER_OF_GROUPS,
    printingReceiptByGroupList: [],
    isShowSplitRoomBookingReceiptPrintingModal: false,
};

export const splitRoomBookingReceiptSlice = createSlice({
    name: 'split-room-booking-receipt',
    initialState,
    reducers: {
        setSelectedSplitBillType: (state, action) => {
            state.selectedSplitBillType = action.payload;
        },
        setNumberOfGroups: (state, action) => {
            state.numberOfGroups = action.payload;
        },
        setPrintingReceiptByGroupList: (state, action) => {
            state.printingReceiptByGroupList = action.payload;
        },
        setIsShowSplitRoomBookingReceiptPrintingModal: (state, action) => {
            state.isShowSplitRoomBookingReceiptPrintingModal = action.payload;
        },
        setReceiptByGroupMap: (state, action) => {
            state.receiptByGroupMap = action.payload;
        },
        setGuestNameToGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string;
                guestName: string;
            }>,
        ) => {
            const { groupId, guestName } = action.payload;
            state.receiptByGroupMap[groupId].guestName = guestName;
        },
        setProvisoToGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string;
                proviso?: string;
            }>,
        ) => {
            const { groupId, proviso } = action.payload;
            state.receiptByGroupMap[groupId].proviso = proviso;
        },
        /**
         * Helper methods that:
         * - Add receiptItemDetails
         * - Remove receiptItemDetails
         * - Update receipt in the key 'ALL' of the group map
         *
         * These methods modify the receiptItemDetails list (sale item, etc.) in
         * the specific receiptItem of the roomBookingItem in the group's receipt
         */
        addRoomBookingReceiptItemsInGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string | null;
                roomBookingItem: IRoomBookingItemReceipt;
                receiptItem: IRoomBookingItemsReceiptItem;
                receiptItemDetails: IReceiptItemDetails[];
            }>,
        ) => {
            const { groupId, roomBookingItem, receiptItem, receiptItemDetails } =
                action.payload;
            const roomBookingItems =
                state.receiptByGroupMap[groupId || ReceiptByGroupMapIndex.ALL].receipt
                    ?.roomBooking.roomBookingItems;
            const toUpdateRoomBookingItem = roomBookingItems.find(
                (item) => item.id === roomBookingItem.id,
            );
            if (!toUpdateRoomBookingItem) return;
            const toUpdateReceiptItem = toUpdateRoomBookingItem?.receiptItems?.find(
                (item) => item.id === receiptItem.id,
            );
            if (!toUpdateReceiptItem) return;

            const newReceiptItemDetails = toUpdateReceiptItem.receiptItemDetails.concat(
                receiptItemDetails.map((detail) => ({
                    ...detail,
                    groupId,
                })),
            );
            toUpdateReceiptItem.receiptItemDetails = uniqBy(newReceiptItemDetails, 'id');
            state.receiptByGroupMap[
                groupId || ReceiptByGroupMapIndex.ALL
            ].receipt!.roomBooking.roomBookingItems = roomBookingItems;
            return;
        },
        removeRoomBookingReceiptItemsInGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string | null;
                roomBookingItem: IRoomBookingItemReceipt;
                receiptItem: IRoomBookingItemsReceiptItem;
                receiptItemDetails: IReceiptItemDetails[];
            }>,
        ) => {
            const { groupId, roomBookingItem, receiptItem, receiptItemDetails } =
                action.payload;
            const roomBookingItems =
                state.receiptByGroupMap[groupId || ReceiptByGroupMapIndex.ALL].receipt!
                    .roomBooking.roomBookingItems;

            const toUpdateRoomBookingItem = roomBookingItems.find(
                (item) => item.id === roomBookingItem.id,
            );
            if (!toUpdateRoomBookingItem) return;
            const toUpdateReceiptItem = toUpdateRoomBookingItem?.receiptItems?.find(
                (item) => item.id === receiptItem.id,
            );
            if (!toUpdateReceiptItem) return;

            toUpdateReceiptItem.receiptItemDetails =
                toUpdateReceiptItem.receiptItemDetails.filter(
                    (item) => !receiptItemDetails.find((i) => i.id === item.id),
                );

            state.receiptByGroupMap[
                groupId || ReceiptByGroupMapIndex.ALL
            ].receipt!.roomBooking.roomBookingItems = roomBookingItems;
        },
        updateRoomBookingReceiptItemsInGroupAllMap: (
            state,
            action: PayloadAction<{
                groupId: string | null;
                roomBookingItem: IRoomBookingItemReceipt;
                receiptItem: IRoomBookingItemsReceiptItem;
                receiptItemDetails: IReceiptItemDetails[];
            }>,
        ) => {
            const { groupId, roomBookingItem, receiptItem, receiptItemDetails } =
                action.payload;
            const roomBookingItems =
                state.receiptByGroupMap[ReceiptByGroupMapIndex.ALL].receipt?.roomBooking
                    .roomBookingItems;
            const toUpdateRoomBookingItem = roomBookingItems.find(
                (item) => item.id === roomBookingItem.id,
            );
            if (!toUpdateRoomBookingItem) return;
            const toUpdateReceiptItem = toUpdateRoomBookingItem?.receiptItems?.find(
                (item) => item.id === receiptItem.id,
            );
            if (!toUpdateReceiptItem) return;

            toUpdateReceiptItem.receiptItemDetails =
                toUpdateReceiptItem.receiptItemDetails.map((detail) => {
                    if (receiptItemDetails.find((i) => i.id === detail.id)) {
                        return {
                            ...detail,
                            groupId,
                        };
                    }
                    return detail;
                });

            state.receiptByGroupMap[
                ReceiptByGroupMapIndex.ALL
            ].receipt!.roomBooking.roomBookingItems = roomBookingItems;
            return;
        },
        addFacilityBookingReceiptItemsInGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string | null;
                facilityBooking: IFacilityBookingReceiptDetail;
                receiptItem: IFacilityBookingReceiptItem;
                receiptItemDetails: IFacilityBookingReceiptItemDetail[];
            }>,
        ) => {
            const { groupId, facilityBooking, receiptItem, receiptItemDetails } =
                action.payload;
            const facilityBookings =
                state.receiptByGroupMap[groupId || ReceiptByGroupMapIndex.ALL].receipt
                    ?.facilityBookings;

            const toUpdateFacilityBooking = facilityBookings.find(
                (item) => item.id === facilityBooking.id,
            );
            if (!toUpdateFacilityBooking) return;

            const toUpdateReceiptItem = toUpdateFacilityBooking.receiptItems.find(
                (item) => item.id === receiptItem.id,
            );
            if (!toUpdateReceiptItem) return;

            const newReceiptItemDetails = toUpdateReceiptItem.receiptItemDetails.concat(
                receiptItemDetails.map((detail) => ({
                    ...detail,
                    groupId,
                })),
            );
            toUpdateReceiptItem.receiptItemDetails = uniqBy(newReceiptItemDetails, 'id');
            state.receiptByGroupMap[
                groupId || ReceiptByGroupMapIndex.ALL
            ].receipt!.facilityBookings = facilityBookings;
            return;
        },
        removeFacilityBookingReceiptItemsInGroupMap: (
            state,
            action: PayloadAction<{
                groupId: string | null;
                facilityBooking: IFacilityBookingReceiptDetail;
                receiptItem: IFacilityBookingReceiptItem;
                receiptItemDetails: IFacilityBookingReceiptItemDetail[];
            }>,
        ) => {
            const { groupId, facilityBooking, receiptItem, receiptItemDetails } =
                action.payload;
            const facilityBookings =
                state.receiptByGroupMap[groupId || ReceiptByGroupMapIndex.ALL].receipt
                    ?.facilityBookings;

            const toUpdateFacilityBooking = facilityBookings.find(
                (item) => item.id === facilityBooking.id,
            );
            if (!toUpdateFacilityBooking) return;

            const toUpdateReceiptItem = toUpdateFacilityBooking.receiptItems.find(
                (item) => item.id === receiptItem.id,
            );
            if (!toUpdateReceiptItem) return;

            toUpdateReceiptItem.receiptItemDetails =
                toUpdateReceiptItem.receiptItemDetails.filter(
                    (item) => !receiptItemDetails.find((i) => i.id === item.id),
                );
            state.receiptByGroupMap[
                groupId || ReceiptByGroupMapIndex.ALL
            ].receipt!.facilityBookings = facilityBookings;
        },
        updateFacilityBookingReceiptItemsInGroupAllMap: (
            state,
            action: PayloadAction<{
                groupId: string | null;
                facilityBooking: IFacilityBookingReceiptDetail;
                receiptItem: IFacilityBookingReceiptItem;
                receiptItemDetails: IFacilityBookingReceiptItemDetail[];
            }>,
        ) => {
            const { groupId, facilityBooking, receiptItem, receiptItemDetails } =
                action.payload;
            const facilityBookings =
                state.receiptByGroupMap[ReceiptByGroupMapIndex.ALL].receipt
                    ?.facilityBookings;

            const toUpdateFacilityBooking = facilityBookings.find(
                (item) => item.id === facilityBooking.id,
            );
            if (!toUpdateFacilityBooking) return;

            const toUpdateReceiptItem = toUpdateFacilityBooking.receiptItems.find(
                (item) => item.id === receiptItem.id,
            );
            if (!toUpdateReceiptItem) return;

            toUpdateReceiptItem.receiptItemDetails =
                toUpdateReceiptItem.receiptItemDetails.map((detail) => {
                    if (receiptItemDetails.find((i) => i.id === detail.id)) {
                        return {
                            ...detail,
                            groupId,
                        };
                    }
                    return detail;
                });

            state.receiptByGroupMap[
                ReceiptByGroupMapIndex.ALL
            ].receipt!.facilityBookings = facilityBookings;
            return;
        },
        resetReceiptByGroupMap: (state) => {
            state.receiptByGroupMap = {};
        },
    },
});

export const {
    setSelectedSplitBillType,
    setNumberOfGroups,
    setPrintingReceiptByGroupList,
    setIsShowSplitRoomBookingReceiptPrintingModal,
    setReceiptByGroupMap,
    setGuestNameToGroupMap,
    setProvisoToGroupMap,
    addRoomBookingReceiptItemsInGroupMap,
    removeRoomBookingReceiptItemsInGroupMap,
    updateRoomBookingReceiptItemsInGroupAllMap,
    addFacilityBookingReceiptItemsInGroupMap,
    removeFacilityBookingReceiptItemsInGroupMap,
    updateFacilityBookingReceiptItemsInGroupAllMap,
    resetReceiptByGroupMap,
} = splitRoomBookingReceiptSlice.actions;

export const splitReceiptStateSelector = (state: AppState) => {
    return state.splitRoomBookingReceipt;
};

export const selectedSplitBillTypeSelector = (state: AppState) => {
    return state.splitRoomBookingReceipt.selectedSplitBillType;
};

export const receiptByGroupMapSelector = (state: AppState) => {
    return state.splitRoomBookingReceipt.receiptByGroupMap;
};

export default splitRoomBookingReceiptSlice.reducer;
