import * as React from 'react';
import { Dayjs } from '~plugins/dayjs';
import { DatePicker } from '../DatePicker/DatePicker';
import {
    PickerProps,
    RangePickerProps,
} from 'antd/es/date-picker/generatePicker/interface';

export type TimePickerProps = Omit<PickerProps<Dayjs>, 'picker'>;
export type TimeRangePickerProps = Omit<RangePickerProps<Dayjs>, 'picker'>;

export const TimePicker = React.forwardRef<
    React.ComponentRef<typeof DatePicker>,
    TimePickerProps
>((props, ref) => {
    return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />;
});

TimePicker.displayName = 'TimePicker';
