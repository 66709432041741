import { CalendarOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Col, notification, Row, Tag } from 'antd';
import { parseDate } from '~plugins/dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import './BookingTemporaryCard.scss';
import { sumBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    deleteBookingItem,
    fetchRoomBookingStatisticByDate,
    removeBookingTmp,
    scheduleStateSelector,
    setCurrentBookingEditing,
    setShowCreateBookingForm,
    setVisibleCreateBookingPopover,
} from '~features/room-booking/reducers/schedule.reducer';
import { ModalConfirmDeletion, TextTruncate } from '~components';
import { formatMoney } from '~common/commonFunctions';

type Props = {
    booking: IRoomBookingSchedule;
    showAction?: boolean;
};

export const BookingTemporaryCard = ({ booking, showAction }: Props) => {
    const { currentViewMode } = useAppSelector(scheduleStateSelector);
    const dispatch = useAppDispatch();
    const date = useMemo(() => {
        const start = parseDate(booking.stayingStartDate)?.fmYYYYMMDD('/');
        const end = parseDate(booking.stayingEndDate)?.fmYYYYMMDD('/');
        return `${start} - ${end}`;
    }, [booking]);

    const childrenCount = useMemo(() => {
        const { children } = booking;
        return sumBy(children, (child) => child.quantity);
    }, [booking]);

    const { t } = useTranslation();

    const editBooking = () => {
        dispatch(setCurrentBookingEditing(booking));
        dispatch(setShowCreateBookingForm(true));
        dispatch(setVisibleCreateBookingPopover(false));
    };

    const onConfirmDeletion = async () => {
        const response = await dispatch(deleteBookingItem(booking.id));
        if (deleteBookingItem.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomBooking.schedule.message.deleteTmpSuccess'),
                });
                if (currentViewMode) {
                    dispatch(fetchRoomBookingStatisticByDate());
                }
                dispatch(removeBookingTmp({ bookings: [booking] }));
                return;
            }
        }
        notification.error({
            message: t('roomBooking.schedule.message.deleteTmpFaild'),
        });
    };

    const deleteBooking = () => {
        ModalConfirmDeletion({
            deletedItems: [],
            buttonCancelText: t('common.buttonCancelText'),
            buttonDeleteText: t('common.buttonDeleteText'),
            okButtonProps: { danger: true },
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    return (
        <Card
            className="booking-tmp-card"
            actions={
                showAction
                    ? [
                          <EditOutlined onClick={editBooking} key="edit" />,
                          <DeleteOutlined key="delete" onClick={deleteBooking} />,
                      ]
                    : []
            }
        >
            <Row>
                <Col span={24}>
                    <Tag icon={<CalendarOutlined />}>{date}</Tag>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="room-type-name">
                    <TextTruncate text={booking.roomType?.name} />
                    /
                    <TextTruncate text={booking.room?.name} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TextTruncate
                        text={booking.plan?.name || t('roomBooking.schedule.withoutMeal')}
                    />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <span>
                        {t('roomBooking.schedule.adult')}: {booking.numberOfAdults}
                    </span>
                    <span>
                        &nbsp;
                        {booking.numberOfMale ||
                        booking.numberOfFemale ||
                        booking.numberOfOtherGenderGuest
                            ? t('roomBooking.schedule.genderBreakdown', {
                                  male: booking.numberOfMale || 0,
                                  female: booking.numberOfFemale || 0,
                                  other: booking.numberOfOtherGenderGuest || 0,
                              })
                            : ''}
                    </span>
                    <span className="ml-8">
                        {t('roomBooking.schedule.kid')}: {childrenCount}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <span>
                        {t('roomBooking.createBooking.subTotal', {
                            subTotal: booking.price
                                ? formatMoney(booking.price || 0)
                                : '',
                        })}
                    </span>
                </Col>
            </Row>
        </Card>
    );
};

BookingTemporaryCard.defaultProps = {
    showAction: true,
};
