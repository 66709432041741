const saleGroupList = {
    title: '集計設定',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        saleGroup: '集計設定',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '集計グループ名',
        saleItemName: '集計科目',
    },
    searchBox: '検索',
    primaryButton: '新規集計グループを登録',
    saleGroupListColumn: {
        num: '#',
        id: 'ID',
        saleGroupName: 'グループ名',
        saleItemName: '科目',
    },
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
        title: '集計グループの削除',
        description: '下記を削除してもよろしいでしょうか？',
    },
    exportColumns: {
        autoGeneratedCode: 'ID',
        name: '集計グループ名',
        saleItemName: '集計対象',
    },
    confirm: {
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
        cancelTitle: 'キャンセルしてもよろしいですか？',
        cantDelete: `売上グループ項目が削除できません`,
    },
    message: {
        deleteSuccess: '売上グループ項目が削除されました',
    },
};

export default saleGroupList;
