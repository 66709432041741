const common = {
    ok: 'はい',
    cancel: 'いいえ',
    searchPlaceholder: '検索',
    buttonCancelText: 'キャンセル',
    buttonSaveText: '保存',
    buttonDeleteText: '削除',
    buttonCloseText: '閉じる',
    logout: 'ログアウト',
    somethingWentWrong: 'エラーが発生しました',
    language: {
        en: 'English',
        ja: '日本語',
        lang: '言語',
    },
    sidebar: {
        coupon: 'クーポン',
        pms: {
            title: 'PMS',
            menus: {
                dashboard: {
                    title: 'ダッシュボード',
                    submenu: {},
                },
                roomBooking: {
                    title: '宿泊予約',
                    submenu: {
                        list: '宿泊予約一覧',
                        schedule: '宿泊スケジュール',
                    },
                },
                facility: {
                    title: '設備予約',
                    submenu: {
                        schedule: '設備スケジュール',
                        list: '設備予約一覧',
                    },
                },
                guests: {
                    title: 'ゲスト',
                    submenu: {},
                },
                roomManagement: {
                    title: '部屋管理',
                    submenu: {},
                },
                report: {
                    title: '帳票',
                    category: {
                        earnings: '売上',
                        deposits: '入出金',
                        others: 'その他',
                    },
                    submenu: {
                        summary: 'サマリー',
                        saleSummary: '売上明細',
                        salesPayment: '売上入金表',
                        payment: '入金明細',
                        salesByCategory: '部屋タイプ別売上表',
                        unpaidDetail: '未収金明細表',
                        paymentSummary: '入金明細表',
                        cancellation: 'キャンセルレポート',
                        operationStatus: '稼働レポート',
                        paymentDetails: '入金レポート表',
                        taxDetail: '宿泊税・入湯税明細表',
                        currentGuest: '当日予約レポート',
                        mealReport: '食事レポート',
                        salesByPlansReport: 'プラン別売上表',
                        salesByOtaReport: 'OTA別売上表',
                    },
                },
                closingBooking: {
                    title: '締め作業',
                    submenu: {},
                },
                frontSetting: {
                    title: 'フロント設定',
                    category: {
                        roomEquipmentSettings: '部屋/設備設定',
                        planSettings: 'プラン設定',
                        salesDepositTaxSettings: '売上/入金/税設定',
                        channelSettings: 'チャネル設定',
                        others: 'その他',
                    },
                    submenu: {
                        hotelSetting: '施設情報',
                        planSetting: 'プラン管理',
                        planRank: 'ランクカレンダー設定',
                        roomTypeSetting: '部屋タイプ設定',
                        roomSetting: '部屋設定',
                        indicatorSetting: 'インジゲータ設定',
                        equipmentTypeSetting: '設備タイプ設定',
                        equipmentSetting: '設備設定',
                        saleItemSetting: '売上科目設定',
                        saleGroupSetting: '売上集計設定',
                        paymentMethodSetting: '入金科目設定',
                        marketingChannelSetting: '販売チャネル設定',
                        marketingChannelGroupSetting: '販売チャネルグループ設定',
                        guestClassificationSettings: 'ゲスト識別設定',
                        inventoryBreakdownManagement: '在庫管理',
                        taxSetting: '税金設定',
                        childrenTypeSetting: '子供区分設定',
                        siteController: 'サイトコントローラー',
                        history: '履歴',
                    },
                },
                integrations: {
                    title: '外部連携',
                    submenu: {
                        hotelSmart: 'HOTEL SMART',
                        kioskAlmex: 'Almex自動精算機',
                        keycardSetting: 'カードキー設定',
                    },
                },
                boSetting: {
                    title: 'BO設定',
                    submenu: {
                        privilegeManagement: '施設管理',
                        smtpSetting: {
                            title: 'インフラ設定',
                            submenu: {
                                mailServerSetting: '送信メールサーバー',
                                systemIntegrationSettting: 'システム識別..',
                            },
                        },
                        operationHistory: '操作履歴',
                        notificationSetting: 'お知らせ設定',
                        csvBookingUpload: 'CSV予約移行',
                    },
                },
            },
        },
        pricing: '価格管理',
    },
    topbar: {
        staffHeader: {
            confirmationList: '確認リスト',
            hotelDropdownList: {
                searchBar: {
                    placeholder: 'IDやホテル名を入力',
                },
            },
        },
    },
    modalConfirmDeletion: {
        title: '削除の確認',
        description: '下記の項目を削除してもよろしいですか？',
    },
    marketingChannelGroupDropdown: {
        title: '販売チャネルグループ名',
    },
    csvDropdown: {
        csvFile: 'CSVファイル',
        importSelection: 'インポート',
        exportSelection: '選択項目をエクスポート',
        exportAll: '全てをエクスポート',
        importXML: 'XMLのインポート',
    },
    uploadFile: {
        title: 'ファイルをクリックドラッグしてアップロード',
        hint: '単一または一括アップロードのサポートしています。',
        message: {
            maxSizeError: 'Maximum size of each attachment {{maxSize}}Mb',
            acceptTypeError: 'Incorrect file format',
            uploadFail: 'Upload fail',
            fileDuplicate: 'Duplicate files',
            maxNumberOfFiles: 'The maximum number of files is {{count}}',
        },
    },
    importCsv: {
        title: 'ファイルアップロード',
        numData: 'アップロード結果 {{totalData}} 件',
        error: {
            title: '入力エラーが{{totalErrors}}件あります。該当箇所を修正の上、再度アップロードを行ってください',
            message: '{{order}}行目 {{key}}: {{message}}',
        },
        rules: {
            empty: 'The file field is required',
            tooBig: 'File size must be less than 10GB',
            invalidType: {
                excel: 'File format must be excel',
                xml: 'File format must be XML',
            },
        },
    },
    schedule: {
        viewMode: {
            day: '日',
            week: '週',
            month: '月',
            year: '年',
        },
        checkin: {
            label: 'C/I: ',
        },
        checkout: {
            label: 'C/O: ',
        },
        today: '本日',
    },
    gender: {
        male: '男性',
        female: '女性',
        other: 'その他',
    },
    status: {
        active: 'チェックイン',
        inactive: 'チェックアウト',
    },
    print: {
        print: '印刷',
        page: 'ページ',
        type: '送信先',
        pdf: 'に保存',
        all: 'すべて',
        orientation: 'レイアウト',
        portrait: '縦',
        landscape: '横向き',
        moreSetting: '詳細設定',
        paperSize: 'Paper size',
    },
    symbol: {
        semicolon: ';',
    },
    standardTimeTitle: '日本標準時',
    yen: '￥{{number}}',
};

export default common;
