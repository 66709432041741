import { ReloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CsvDropdown, ModalConfirmDeletion } from '~components';
import {
    exportColumns,
    EXPORT_CSV_FILE_NAME,
    FILE_NAME,
    i18ExportKey,
    OrderBy,
    SaleItemColumn,
    initSaleItemListQuery,
} from '~features/sale-item/constants';
import {
    ISaleItemExportCsvQuery,
    ISaleItems,
    IImportSaleItem,
} from '~features/sale-item/interfaces';
import { ISorter } from '~common/interfaces';
import { TableProps } from 'antd/es/table';
import {
    fetchSaleItemList,
    getSaleItemListDetail,
    saleItemListQuerySelector,
    saleItemListSelector,
    setSaleItemListQuery,
    setShowForm,
    showLoadingSelector,
    totalPageSelector,
    totalSaleItemSelector,
    setImportSaleItemList,
    setImportCsvFileName,
    setShowLoadingForm,
} from '~features/sale-item/reducers/sale-item.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    AntdOrderDirection,
    OrderDirection,
    CsvOption,
    AdminGroup,
    UserGroup,
    cellTextErrorStyle,
} from '~common/constants';
import './SaleItemList.scss';
import { saleItemService } from '~features/sale-item/services/sale-item.service';
import {
    checkUserPermission,
    downloadFile,
    exportCSVFile,
} from '~common/commonFunctions';
import { cloneDeep, orderBy } from 'lodash';
import ImportCsv from '../../../../components/ImportCsv/ImportCsv';
import trim from 'lodash/trim';
import { saleItemSchema } from '~common/validatorSchema';

interface IDataOutput {
    autoGeneratedCode: string;
    name: string;
    price: string;
    isShowInBooking: string;
    tax: string | undefined;
}

function SaleItemList() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const saleItemList = useAppSelector(saleItemListSelector);
    const saleItemListQuery = useAppSelector(saleItemListQuerySelector);
    const pageCount = useAppSelector(totalPageSelector);
    const totalSaleItem = useAppSelector(totalSaleItemSelector);
    const showLoading = useAppSelector(showLoadingSelector);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);
    const importCsvRef = useRef<{
        onClickImport: () => void;
    }>(null);

    useEffect(() => {
        return () => {
            dispatch(setSaleItemListQuery(cloneDeep(initSaleItemListQuery)));
        };
    }, []);

    const fetchData = () => {
        dispatch(fetchSaleItemList());
    };

    useEffect(() => {
        if (
            saleItemList.length === 0 &&
            saleItemListQuery?.page &&
            saleItemListQuery?.page > 1
        ) {
            const page = saleItemListQuery?.page - 1;
            dispatch(setSaleItemListQuery({ ...saleItemListQuery, page }));
        }
    }, [saleItemList]);

    useEffect(() => {
        fetchData();
    }, [saleItemListQuery]);

    const onRefresh = async () => {
        await fetchData();
        setSelectedRowKeys([]);
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const onChange: TableProps<ISaleItems>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;

        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setSaleItemListQuery({
                    ...saleItemListQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setSaleItemListQuery({
                    ...saleItemListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        return (
            saleItemList.filter((saleItem) => selectedRowKeys.includes(saleItem.id)) || []
        );
    }, [selectedRowKeys, saleItemList]);

    const convertPrice = (price: number) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const onChangePage = (page: number) => {
        setSelectedRowKeys([]);
        dispatch(setSaleItemListQuery({ ...saleItemListQuery, page }));
    };
    const showConfirmDialog = () => {
        const canNotDeleteItems = selectedRows.filter((row) => row.isSeedData === true);
        if (canNotDeleteItems?.length) {
            Modal.error({
                title: t('saleItem.list.message.canNotDeleteDefaultData'),
                content: (
                    <ul>
                        {canNotDeleteItems?.map((item) => (
                            <li key={item.autoGeneratedCode}>{item.name}</li>
                        ))}
                    </ul>
                ),
                okText: t('common.ok'),
            });
        } else {
            ModalConfirmDeletion({
                deletedItems: selectedRows.map((saleItem) => {
                    return saleItem.name;
                }),
                onClickButtonDelete: onConfirmDeletion,
                buttonCancelText: t('saleItem.list.modalConfirmDeletion.cancelBtn'),
                buttonDeleteText: t('saleItem.list.modalConfirmDeletion.deleteBtn'),
                okButtonProps: { danger: true },
            });
        }
    };

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });

        const response = await saleItemService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('saleItem.list.message.deleteSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const sortDataToCsv = (datas: ISaleItems[]) => {
        const newDatas = datas.sort(function (data1, data2) {
            return data2.id - data1.id;
        });
        return newDatas;
    };

    const convertDataToCsv = (saleItemList: ISaleItems[]) => {
        const dataOutput: IDataOutput[] = [];
        const newSaleItems = sortDataToCsv(saleItemList);
        newSaleItems.forEach((saleItem) => {
            const data = {
                autoGeneratedCode: saleItem.autoGeneratedCode,
                name: saleItem.name,
                price: `￥ ${saleItem.price}`,
                isShowInBooking: saleItem.isShowInBooking
                    ? t('saleItem.list.showInBooking.display')
                    : t('saleItem.list.showInBooking.hidden'),
                tax: saleItem.tax.name,
            };

            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportSaleItemList = async (saleItemList: ISaleItems[]) => {
        // Create header file csv
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        const saleItemDataCsv = convertDataToCsv(saleItemList);
        await exportCSVFile(exportColumns, filename, saleItemDataCsv, i18ExportKey);

        return {
            filename,
            filePath: `${process.env.FILE_STORAGE_BASE_URL}/${process.env.FILE_STORAGE_GUEST_CSV_FOLDER}/${filename}`,
        };
    };

    const exportCsv = async (query: ISaleItemExportCsvQuery) => {
        const response = await saleItemService.exportCsv(query);
        if (response.success) {
            downloadFile(FILE_NAME, response.data.filePath);
        }
    };

    const mapSaleItemDataToImportCsv = (saleItems: IImportSaleItem[]) => {
        const importSaleItems: IImportSaleItem[] = [];

        saleItems.forEach((saleItem, index) => {
            importSaleItems.push({
                order: index + 1,
                name: trim(saleItem.name),
                price: +saleItem.price,
                isShowInBooking:
                    saleItem.isShowInBooking === t('saleItem.list.showInBooking.display')
                        ? true
                        : false,
                taxName: trim(saleItem.taxName),
            });
        });

        dispatch(setImportSaleItemList(importSaleItems));
    };

    const setImportSaleItemCsvFileName = (fileName: string) => {
        dispatch(setImportCsvFileName(fileName));
    };

    const handleLoadFile = () => {
        dispatch(setShowLoadingForm(true));
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all sale item
                await exportCsv({
                    keyword: saleItemListQuery.keyword,
                    autoGeneratedCode: saleItemListQuery.autoGeneratedCode,
                    name: saleItemListQuery.name,
                    taxName: saleItemListQuery.taxName,
                    orderBy: saleItemListQuery.orderBy,
                    orderDirection: saleItemListQuery.orderDirection,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportSaleItemList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                if (importCsvRef?.current) {
                    importCsvRef.current.onClickImport();
                }
                break;
            }
            default:
                break;
        }
    };

    const onClickButtonEdit = () => {
        const canNotDeleteItems = selectedRows.filter((row) => row.isSeedData === true);
        if (canNotDeleteItems?.length) {
            Modal.error({
                title: t('saleItem.list.message.canNotEditDefaultData'),
                content: (
                    <ul>
                        {canNotDeleteItems?.map((item) => (
                            <li key={item.autoGeneratedCode}>{item.name}</li>
                        ))}
                    </ul>
                ),
                okText: t('common.ok'),
            });
        } else {
            const selectedIds = selectedRows.map((row) => {
                return row.id;
            });
            dispatch(getSaleItemListDetail(selectedIds));
            dispatch(setShowForm(true));
        }
    };

    const onClickOneRow = (record: ISaleItems) => {
        if (isReadOnly || record.isSeedData) {
            return {
                onClick: () => {
                    return;
                },
            };
        }
        return {
            onClick: () => {
                dispatch(getSaleItemListDetail([record?.id]));
                dispatch(setShowForm(true));
            },
        };
    };

    const getSaleItemCellTextStyle = (saleItem: ISaleItems) => {
        return saleItemSchema.isValidSync(saleItem) ? {} : cellTextErrorStyle;
    };

    const saleItemColumns: ColumnsType<ISaleItems> = [
        {
            title: t('saleItem.list.saleItemColumn.id'),
            width: '120px',
            key: SaleItemColumn.AUTO_GENERATED_CODE,
            sorter: true,
            dataIndex: SaleItemColumn.AUTO_GENERATED_CODE,
            render: (autoGeneratedCode: string) => {
                return <a>{autoGeneratedCode}</a>;
            },
        },
        {
            title: t('saleItem.list.saleItemColumn.name'),
            width: '25%',
            key: SaleItemColumn.NAME,
            ellipsis: true,
            sorter: true,
            dataIndex: SaleItemColumn.NAME,
            onCell: (saleItem: ISaleItems) => {
                return {
                    style: getSaleItemCellTextStyle(saleItem),
                };
            },
        },
        {
            title: t('saleItem.list.saleItemColumn.price'),
            width: '25%',
            key: SaleItemColumn.PRICE,
            dataIndex: SaleItemColumn.PRICE,
            sorter: true,
            onCell: (saleItem: ISaleItems) => {
                return {
                    style: getSaleItemCellTextStyle(saleItem),
                };
            },
            render: (price: number) => {
                return <span>{`￥ ${convertPrice(price)}`}</span>;
            },
        },
        {
            title: t('saleItem.list.saleItemColumn.showInBooking'),
            key: SaleItemColumn.IS_SHOW_IN_BOOKING,
            width: '15%',
            dataIndex: SaleItemColumn.IS_SHOW_IN_BOOKING,
            sorter: true,
            onCell: (saleItem: ISaleItems) => {
                return {
                    style: getSaleItemCellTextStyle(saleItem),
                };
            },
            render: (isShowInBooking: boolean) => {
                return (
                    <span>
                        {isShowInBooking
                            ? t('saleItem.list.showInBooking.display')
                            : t('saleItem.list.showInBooking.hidden')}
                    </span>
                );
            },
        },
        {
            title: t('saleItem.list.saleItemColumn.tax'),
            key: SaleItemColumn.TAX,
            width: '15%',
            dataIndex: '',
            sorter: true,
            onCell: (saleItem: ISaleItems) => {
                return {
                    style: getSaleItemCellTextStyle(saleItem),
                };
            },
            render: (saleItem: ISaleItems) => {
                return <span>{saleItem.tax.name}</span>;
            },
            ellipsis: true,
        },
    ];
    return (
        <>
            <div className="sale-item-list-wrapper">
                <ImportCsv
                    importHeader={[
                        SaleItemColumn.NAME,
                        SaleItemColumn.PRICE,
                        SaleItemColumn.IS_SHOW_IN_BOOKING,
                        SaleItemColumn.TAX,
                    ]}
                    mapData={mapSaleItemDataToImportCsv}
                    setFilename={setImportSaleItemCsvFileName}
                    handleLoadFile={handleLoadFile}
                    ref={importCsvRef}
                    previousPage={'/front-settings/sale-item/import-csv'}
                />
                <div className="sale-item-list-header">
                    {!isReadOnly && selectedRows.length > 0 && (
                        <div className="sale-item-header-left">
                            <Button
                                type="text"
                                className="btn"
                                onClick={onClickButtonEdit}
                            >
                                <EditOutlined />
                            </Button>
                            <Button
                                type="text"
                                className="btn"
                                onClick={showConfirmDialog}
                            >
                                <DeleteOutlined />
                            </Button>
                        </div>
                    )}
                    <div className="sale-item-header-right">
                        <CsvDropdown
                            onChange={onChangeCsvOption}
                            hasSelectedColumns={selectedRows.length > 0}
                            hasImportOption={!isReadOnly}
                        />
                        <Button type="text" className="btn" onClick={onRefresh}>
                            <ReloadOutlined />
                        </Button>
                    </div>
                </div>
                <Table
                    className="sale-item-list-table"
                    pagination={false}
                    loading={showLoading}
                    onChange={onChange}
                    columns={saleItemColumns}
                    dataSource={saleItemList}
                    rowSelection={rowSelection}
                    onRow={onClickOneRow}
                    rowKey="id"
                />
            </div>
            {pageCount > 1 && (
                <Pagination
                    align="center"
                    defaultCurrent={saleItemListQuery.page}
                    current={saleItemListQuery.page}
                    total={totalSaleItem}
                    pageSize={saleItemListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                    className="sale-item-list-pagination"
                />
            )}
        </>
    );
}

export default SaleItemList;
