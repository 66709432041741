import i18next from 'i18next';
import { TaxType } from '~common/constants';

export const breadcrumbs = () => [
    {
        text: i18next.t('tax.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('tax.list.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('tax.list.breadcrumbs.tax'),
    },
];

export enum PricingType {
    FIX = 'fix',
    PERCENTAGE = 'percentage',
}

export enum TaxColumn {
    TYPE = 'type',
    IS_ACTIVE = 'isActive',
    NAME = 'name',
}

export const DISPLAY_NAME_MAX_LENGTH = 15;

export interface IGetTaxListForDropdownQuery {
    keyword?: string;
    taxTypes?: TaxType[];
}
