import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse } from '~common/interfaces';
import { ICSVBookingUploadResponse } from '../interfaces';

class CSVBookingUploadService extends ApiService {
    postCSVBookingUpload(parameters: FormData) {
        return this._post(`${this.baseUrl}/upload`, parameters) as Promise<
            IBodyResponse<ICSVBookingUploadResponse[]>
        >;
    }
}

export const csvBookingUploadService = new CSVBookingUploadService(
    { baseUrl: '/room-booking-csv' },
    axiosService,
);
