import { Modal, Tooltip } from 'antd';
import classNames from 'classnames';
import { parseDate } from '~plugins/dayjs';
import { isNull } from 'lodash';
import React, { HTMLProps, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { checkValidRangeDate } from '~features/facility-booking/helper';
import { IFacilityBookingList } from '~features/facility-booking/interfaces';
import {
    setPanelId,
    setSelecting,
    setStartPosition,
    facilityBookingStateSelector,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { endSelect } from '~features/facility-booking/util';
import { IScheduleItem } from '~features/room-booking/interfaces';
import {
    getDateFromElement,
    removeSelection,
    useScheduleEvent,
} from '~features/room-booking/util';
import { useAppDispatch, useAppSelector } from '~hooks';
import { BookingList } from './BookingList';

type Props = HTMLProps<HTMLDivElement> & {
    isToday: boolean;
    item: IScheduleItem;
    rowIndex: number;
    columnIndex: number;
    day: string;
    bookingList: IFacilityBookingList;
};

export const CellItem = ({
    isToday,
    item,
    rowIndex,
    columnIndex,
    day,
    style,
    bookingList,
    ...rest
}: Props) => {
    const dispatch = useAppDispatch();
    const panelId = `facility_${item.parentId}_${item.id}`;
    const { t } = useTranslation();
    const bookings = useMemo(() => {
        const facilityKey = `facility-${item.id}`;
        return bookingList[day]?.[facilityKey] || [];
    }, [bookingList, item]);
    // handle select day for create booking
    const { startPosition, isSelecting, statisticsByDate, facilityList } = useAppSelector(
        facilityBookingStateSelector,
    );
    const { triggerScroll, today } = useScheduleEvent();

    const remain = useMemo(() => {
        if (!item.numberOfRooms) {
            return null;
        }
        const values = statisticsByDate[day] || [];
        if (item.parentId) {
            return 0;
        }
        let count = 0;
        const facilities = facilityList.filter(
            (facility) => facility.parentId === item.id,
        );
        for (let index = 0; index < facilities.length; index++) {
            const facility = facilities[index];
            if (facility.parentId) {
                count += values[`facility-${facility.id}`] || 0;
            }
        }
        return item.numberOfRooms - count;
    }, [facilityList, statisticsByDate, day, item]);

    const startSelect = useCallback(
        (position: { x: number; y: number }) => {
            dispatch(setSelecting(true));
            dispatch(setStartPosition(position));
            dispatch(setPanelId(panelId));
        },
        [panelId],
    );
    const _endSelect = useCallback(
        (position: { x: number; y: number }) => {
            dispatch(setSelecting(false));
            if (!startPosition) {
                removeSelection();
                triggerScroll(true);
                return;
            }
            const options = {
                panelId,
                startPosition,
                currentCursor: position,
                onClose: () => {
                    triggerScroll(true);
                },
            };
            const { startDate, endDate } = getDateFromElement(options);
            // TODO validate time
            if (
                checkValidRangeDate({
                    start: parseDate(startDate).endOf('day'),
                    end: parseDate(endDate).startOf('day'),
                    facilityKey: 'facility-' + item.id,
                    statisticByDate: statisticsByDate,
                })
            ) {
                endSelect(options);
            } else {
                removeSelection();
                Modal.error({
                    title: t('roomBooking.schedule.dateRageInvalid'),
                    centered: true,
                });
                triggerScroll(true);
                return;
            }
        },
        [startPosition],
    );

    return (
        <div
            style={{
                ...style,
                paddingLeft: 4,
                paddingRight: 4,
                paddingBottom: 12,
            }}
            {...rest}
            className="week-cell-item-wrapper"
        >
            {item.parentId ? (
                <div
                    className={classNames('day-item border-top ', {
                        today: isToday,
                        [panelId]: true,
                    })}
                >
                    <div
                        className={classNames('cell-content', {
                            [panelId]: true,
                        })}
                        onClick={() => {
                            if (day < today) {
                                return;
                            }
                            triggerScroll(false);
                            if (!isSelecting) {
                                startSelect({ x: columnIndex, y: rowIndex });
                            } else {
                                _endSelect({ x: columnIndex, y: rowIndex });
                            }
                        }}
                        data-x={columnIndex}
                        data-y={rowIndex}
                        data-facility_type_id={item.parentId}
                        data-facility_id={item.id}
                        data-day={day}
                    >
                        {bookings.length > 0 && (
                            <BookingList
                                bookings={bookings}
                                facilityTypeName={item.name}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <Tooltip title={t('facilityBooking.schedule.remaining')}>
                    <div
                        className={classNames('schedule-collapse-header-item', {
                            'color-red': !isNull(remain) && remain <= 0,
                            'color-yellow': !isNull(remain) && remain > 0 && remain <= 3,
                        })}
                    >
                        {remain}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};
