import { RightDrawerLayout } from '~components';
import './UpdateFacilityBookingModal.scss';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useTranslation } from 'react-i18next';
import {
    getFacilityBookingDetail,
    isCalculatingAmountSelector,
    isShowUpdateFacilityBookingForm,
    selectedFacilityBookingDetailSelector,
    showUpdateFacilityBookingLoadingSelector,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import UpdateFacilityBookingForm from '~features/facility-booking/components/UpdateFacilityBookingForm/UpdateFacilityBookingForm';
import { useEscape } from '~common/useHooks';

function UpdateFacilityBookingModal() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showForm = useAppSelector(isShowUpdateFacilityBookingForm);
    const formBusy = useAppSelector(showUpdateFacilityBookingLoadingSelector);
    const isCalculatingAmount = useAppSelector(isCalculatingAmountSelector);
    const selectedFacilityBookingDetail = useAppSelector(
        selectedFacilityBookingDetailSelector,
    );

    const [totalAmount, setTotalAmount] = useState(0);

    const bookingFormRef = useRef<{
        onSubmit: () => void;
        onCloseForm: () => void;
    }>(null);
    const submit = () => {
        bookingFormRef.current?.onSubmit();
    };

    const closeModal = () => {
        bookingFormRef.current?.onCloseForm();
    };
    useEscape(closeModal);

    useEffect(() => {
        if (showForm) {
            setTotalAmount(selectedFacilityBookingDetail?.totalAmount || 0);
        }
    }, [showForm, selectedFacilityBookingDetail]);

    const changeAmount = (amount: number) => {
        setTotalAmount(amount);
    };

    const updateSuccess = () => {
        if (selectedFacilityBookingDetail?.id) {
            dispatch(getFacilityBookingDetail(selectedFacilityBookingDetail?.id));
        }
    };

    return (
        <RightDrawerLayout
            open={showForm}
            onClose={() => closeModal()}
            onSubmit={submit}
            title={t('facilityBooking.detail.updateForm.title')}
            cancelText={t('common.buttonCancelText')}
            submitText={t('common.buttonSaveText')}
            className="create-facility-booking-drawer"
            busy={formBusy}
            footer={
                <Row>
                    <Col span={12}>
                        <div className="facility-booking-total-price">
                            <label>
                                {t('facilityBooking.createForm.form.total.label')}
                            </label>
                            <div className="total-number">
                                <p className="price-number">
                                    {t('facilityBooking.createForm.priceNumber', {
                                        priceNumber: totalAmount > 0 ? totalAmount : 0,
                                    })}
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="btn-group">
                            <Button
                                className="cancel-button"
                                onClick={() => closeModal()}
                            >
                                {t('common.buttonCancelText')}
                            </Button>
                            <Button
                                type="primary"
                                className="save-button"
                                onClick={submit}
                                loading={formBusy}
                            >
                                {t('common.buttonSaveText')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            }
        >
            <Spin spinning={isCalculatingAmount}>
                <UpdateFacilityBookingForm
                    className="update-facility-booking-form-modal"
                    ref={bookingFormRef}
                    booking={selectedFacilityBookingDetail}
                    isShowForm={showForm}
                    onChangeAmount={changeAmount}
                    onUpdateSuccess={updateSuccess}
                />
            </Spin>
        </RightDrawerLayout>
    );
}

export default UpdateFacilityBookingModal;
