export enum SocketEvents {
    CONNECT_ERROR = 'connect_error',
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    WEB_APP_USER_LOGIN = 'web_app_user_login',
    WEB_APP_CLOSING_BOOKING_NOTIFICATION = 'web_app_closing_booking_notification',
    WEB_APP_EXPIRED_TEMP_ROOM_BOOKING_NOTIFICATION = 'web_app_expired_temp_room_booking_notification',
    WEB_APP_DELETED_EXPIRED_TEMP_ROOM_BOOKING_NOTIFICATION = 'web_app_deleted_expired_temp_room_booking_notification',
    WEB_APP_SEND_NOTIFICATION = 'web_app_send_notification',
    WEB_APP_SEND_BOOKING_NOTIFICATION = 'web_app_send_booking_notification',
    WEB_APP_TRIGGER_TLL_ROOM_BOOKING_NOTIFICATION = 'web_app_trigger_tll_room_booking_notification',
    WEB_APP_SEND_UNCONFIRMATION_BOOKING_NOTIFICATION = 'web_app_send_unconfirmation_booking_notification',
    WEB_APP_SEND_CHANGE_STATUS_ROOM_BOOKING = 'web_app_send_change_status_room_booking',
}

export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_SERVER_URL as string;
