const planList = {
    title: 'プラン設定',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        plan: 'プラン管理',
        createPlan: '新規プランの作成',
        updatePlan: 'プランの編集',
    },
    primaryButton: 'プランを追加する',
    planTypeOptions: {
        stay: '宿泊',
        day_use: 'デイユース',
    },
    planListColumn: {
        id: 'ID',
        planName: 'プラン名',
        planType: 'タイプ',
        checkInTime: 'C/I',
        checkOutTime: 'C/O',
    },
    confirm: {
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
        cancelTitle: 'キャンセルしてもよろしいですか？',
    },
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
        deleteSuccess: 'プランが削除されました',
    },
};

export default planList;
