const facilityBookingDetailJa = {
    title: '施設予約詳細',
    breadcrumbs: {
        facilityBookingDetail: '施設予約詳細',
    },
    detailPageTabs: {
        detail: '施設予約',
        receipt: 'レシート',
        bookingInfo: '代表者情報',
        attachment: 'ファイル',
    },
    detailPageCards: {
        resId: 'RES ID: {{resId}}',
    },
    updateForm: {
        title: '施設予約を編集',
    },
    message: {
        updateSuccess: '施設予約が更新されました',
        updateError: '施設予約が更新されませんでした',
        checkInTimeError: '開始時刻は{{currentTime}}以降、もしくは同時刻に変更ください',
        checkInOutError: '終了時刻は{{currentTime}}以降、もしくは同時刻に変更ください',
        startTimeGreaterEndError:
            '最低でも滞在終了時刻は滞在開始時刻の10分後変更ください',
        businessTimeError:
            '予約時間はこちらの営業時間に設定ください: {{startTime}} ~ {{endTime}}',
    },
    deleteTitle: '削除してもよろしいですか？',
    cancelTitle: 'キャンセルしてもよろしいですか？',

    receiptTableColumn: {
        id: '予約ID',
        date: '日付',
        status: 'タイプ',
        facilityDetail: '詳細',
        guestName: '宿泊者',
        roomName: '部屋名',
        unitPrice: '単価',
        quantity: '数量',
        total: '金額',
        checkoutRoom: '精算部屋',
        payment: '支払い方法',
        paymentPlaceholder: '支払方法',
        saleItemPlaceholder: '科目を選択',
        paymentRoom: '精算部屋',
    },

    receipt: {
        title: '詳細',
        button: {
            edit: '編集',
            splitReceipt: '領収書発行',
            splitInvoice: '請求書発行',
            calculate: '精算',
            reset: 'リセット',
            payment: '入金',
            earnings: '売上',
            bulkPayment: '一括支払い',
        },
        status: {
            payment: '入金',
            earnings: '売上',
        },
        summaryTotal: {
            totalReceivable: '未収金',
            totalSales: '売上合計（消費税込）',
            otherTaxes: '内消費税等',
            payment: '入金',
            change: '預かり余剰金',
            deposit: '未収金',
            summary: 'まとめ',
        },
        paymentSetting: {
            title: '支払い設定変更',
            allRooms: '各部屋ごとに支払いが請求されます',
            representativeRoom: '代表部屋に対して支払いが請求されます',
            allDetails: '全ての詳細',
            allRoomCharges: '全ての室料',
            questionChangeConfirm: '代表部屋に全ての支払いを請求しますか？',
        },
        updateSuccessMessage: 'レシートが更新されました',
        deleteSuccessMessage: 'レシートが削除されました',
        booking: '宿泊',
        resetPopup: {
            title: '編集内容を破棄してもよろしいでしょうか？',
            yesButton: 'はい',
            noButton: 'いいえ',
        },
        bulkPaymentModal: {
            paymentDate: '支払日',
            paymentMethod: '入金科目',
            createBulkPayment: '一括支払い作成',
            cancel: 'キャンセル',
            updateSuccessMessage: '支払いが成功裏に作成されました。',
        }
    },
    receiptStatus: {
        payment: '入金',
        receipt: '売上',
    },
    facilityBookingInfo: {
        user: '利用者',
        hotelReservation: {
            title: '宿泊予約',
            yes: '有',
            no: '無',
        },
        detail: '詳細',
        memo: 'メモ',
        stayTime: '滞在期間',
    },
    personText: '人',
    facility: '設備',
    paymentModal: {
        title: '精算確認',
        calculateButton: '精算',
        bookingId: {
            label: '予約ID',
            placeholder: '予約ID',
        },
        paymentMethod: {
            label: '入金科目',
            placeholder: '入金科目を選択してください',
        },
        paymentAmount: {
            label: '金額',
            placeholder: '0',
        },
    },
    modalConfirmDeletion: {
        cancelButton: 'キャンセル',
        deleteButton: '削除',
    },
    modalDeleteError: {
        title: 'エラー',
        description: '以下の部屋を削除できませんでした。',
        closeButton: '閉じる',
    },
};

export default facilityBookingDetailJa;
