import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { InputSearch } from '~components';
import { useAppDispatch } from '~hooks';
import { AdminGroup, GA_KEYWORD_CONTENT } from '~common/constants';
import { checkUserPermission } from '~common/commonFunctions';
import { trackSearchKeyword } from '~plugins/analytics';
import SelectField from './SelectField/SelectField';
import { setKeyword } from '../../room.reducer';
import './FilterForm.scss';

function FilterForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([...AdminGroup]);

    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.ROOM);
    };

    const addRoom = () => {
        navigate('/front-settings/room/create-individual');
    };

    const onClickRoomOrderSettings = () => {
        navigate('/front-settings/room/room-order-settings');
    };

    return (
        <div className="room-filter-form-wrapper">
            <InputSearch
                placeholder={t('common.searchPlaceholder')}
                maxLength={30}
                onSearch={onSearch}
                addonBefore={SelectField()}
            />

            {!isReadOnly && (
                <div className="btn-group">
                    <Button type="primary" className="filter-buttons" onClick={addRoom}>
                        <PlusOutlined />
                        {t('room.list.filterForm.buttonCreateManyRooms')}
                    </Button>
                    <Button className="filter-buttons" onClick={onClickRoomOrderSettings}>
                        <UnorderedListOutlined />
                        {t('room.list.filterForm.buttonRoomOrderSettings')}
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
