import { Button, Dropdown, Modal, notification, Popconfirm, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    EditOutlined,
    ExceptionOutlined,
    FileDoneOutlined,
    PlusOutlined,
    DeleteOutlined,
    CaretRightOutlined,
    CaretDownOutlined,
    DownOutlined,
    RedEnvelopeOutlined,
} from '@ant-design/icons';
import './FacilityBookingReceiptTabPane.scss';
import { TableProps } from 'antd/es/table';
import SummaryTotalReceipt from './SummaryTotalReceipt/SummaryTotalReceipt';
import { ColumnsType } from 'antd/lib/table';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    bulkDeleteReceiptItems,
    facilityBookingListStateSelector,
    getFacilityBookingReceipt,
    setShowBulkPaymentModal,
    setShowPaymentModal,
    setTotalAmount,
    updateFacilityBookingReceipt,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    InputNumber,
    InputPrice,
    ModalConfirmDeletion,
    SelectSaleItem,
    SingleSelect,
} from '~components';
import {
    FacilityBookingDetailPageTabPane,
    Gender,
    PaymentSettings,
    ReceiptItemType,
    ReceiptItemDetailType,
    FacilityBookingStatus,
    MAX_LENGTH_QUANTITY,
} from '~features/facility-booking/constants';
import {
    IFacilityBookingReceiptTableData,
    IPaymentMethod,
    IUpdateFacilityBookingReceipt,
    ReceiptFormField,
} from '~features/facility-booking/interfaces';
import { getSaleItemListForDropdown } from '~features/sale-item/reducers/sale-item.reducer';
import { updateFacilityBookingReceiptSchema } from '~features/facility-booking/schema';
import { showConfirm } from '~common/notification';
import { ISaleItemDropdown } from '~features/sale-item/interfaces';
import {
    calculateTaxSaleItem,
    findPaymentRoom,
    findReceiptRowById,
} from '~features/facility-booking/helper';
import PaymentModal from './PaymentModal/PaymentModal';
import { PaymentMethodSelect } from '~features/room-booking/pages/RoomBookingDetailPage/components/RoomBookingReceiptTabPane/PaymentMethodSelect/PaymentMethodSelect';
import {
    getPaymentMethodDropdown,
    paymentMethodStateSelector,
} from '~features/payment-method/reducers/paymentMethod.reducer';
import { IPaymentRoomBookingItem } from '~features/room-booking/interfaces';
import { useNavigate } from 'react-router-dom';
import { MAX_PRICE, MIN_PRICE } from '~common/constants';
import { BulkPaymentModal } from './BulkPaymentModal/BulkPaymentModal';

function FacilityBookingReceiptTabPane() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { facilityBookingReceipt, selectedFacilityBookingDetail, activeDetailTabPane } =
        useAppSelector(facilityBookingListStateSelector);
    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);
    const onChange: TableProps<IFacilityBookingReceiptTableData>['onChange'] = () => {
        // TODO this is for pagination, sort, filter logic later
    };

    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { control, setValue, reset, handleSubmit, getValues } = useForm({
        resolver: yupResolver(updateFacilityBookingReceiptSchema),
    });
    const [receiptTableData, setReceiptTableData] = useState<
        IFacilityBookingReceiptTableData[]
    >([]);
    const [deleteReceiptItemIds, setDeleteReceiptItemIds] = useState<number[]>([]);
    const [openPaymentSettingPopConfirm, setOpenPaymentSettingPopConfirm] =
        useState(false);
    const [selectedPaymentSetting, setSelectedPaymentSetting] = useState<string | null>(
        null,
    );
    const [representativeBooking, setRepresentativeBooking] =
        useState<IPaymentRoomBookingItem | null>(null);
    const [expandRowIds, setExpandRowIds] = useState<(number | string)[]>([]);
    const [isCheckout, setIsCheckout] = useState(false);

    const errorAmountRowIds = useRef<string[]>([]);

    const isCanceledBooking = useMemo(() => {
        return (
            facilityBookingReceipt?.facilityBooking?.status ===
            FacilityBookingStatus.CANCELLED
        );
    }, [facilityBookingReceipt]);

    const initReceiptTableData = useMemo(() => {
        if (!facilityBookingReceipt) return [];
        let results: IFacilityBookingReceiptTableData[] = [];
        facilityBookingReceipt?.facilityBooking?.receiptItems?.forEach((receiptItem) => {
            const receiptItemDetailList: IFacilityBookingReceiptTableData[] =
                receiptItem?.receiptItemDetails?.map((receiptItemDetail, index) => {
                    return {
                        id: `${facilityBookingReceipt.id}_${receiptItem.id}_${receiptItemDetail.id}`,
                        receiptItemId: receiptItem.id,
                        date:
                            receiptItem?.type === ReceiptItemType.RECEIPT
                                ? receiptItemDetail?.boughtAt
                                : receiptItemDetail?.payAt,
                        type: receiptItem.type,
                        receiptItemDetailType: receiptItemDetail.type,
                        receiptItemDetailId: receiptItemDetail.id,
                        guest:
                            {
                                ...facilityBookingReceipt.facilityBooking?.guest,
                                gender: facilityBookingReceipt.facilityBooking?.guest
                                    ?.gender as Gender,
                            } || null,
                        detail:
                            receiptItemDetail?.type === ReceiptItemDetailType.SALE_ITEM
                                ? receiptItemDetail?.saleItem?.name
                                : receiptItemDetail?.type ===
                                  ReceiptItemDetailType.STAY_PRICE
                                ? facilityBookingReceipt.facilityBooking?.facility?.name
                                : '',
                        saleItemId: receiptItemDetail?.saleItem?.id,
                        saleItem: receiptItemDetail?.saleItem || null,
                        unitPrice: receiptItemDetail?.unitPrice,
                        quantity: receiptItemDetail?.quantity,
                        amount: receiptItemDetail?.amount,
                        paymentMethod: receiptItem?.paymentMethod,
                        tax: receiptItemDetail?.saleItem?.tax,
                        roomName: findPaymentRoom(
                            facilityBookingReceipt?.facilityBooking?.roomBookingItems,
                        )?.room?.name,
                        paymentRoom: receiptItem?.paymentRoomBookingItem?.room
                            ? receiptItem?.paymentRoomBookingItem
                            : findPaymentRoom(
                                  facilityBookingReceipt?.facilityBooking
                                      ?.roomBookingItems,
                              ),
                        paymentRoomBookingItemId: receiptItem?.paymentRoomBookingItem?.id,
                        showPaymentRoom: index === 0,
                        cannotDelete: receiptItem?.receiptItemDetails?.some(
                            (receiptItemDetail) => {
                                return (
                                    receiptItemDetail?.type ===
                                    ReceiptItemDetailType.STAY_PRICE
                                );
                            },
                        ),
                    };
                });
            results = results.concat(receiptItemDetailList);
        });

        return [
            {
                id: facilityBookingReceipt.id,
                autoGeneratedCode:
                    facilityBookingReceipt?.facilityBooking?.autoGeneratedCode,
                name: t('facilityBooking.detail.facility'),
                children: results,
            },
        ];
    }, [facilityBookingReceipt, t]);
    useEffect(() => {
        dispatch(getSaleItemListForDropdown({}));
        dispatch(getPaymentMethodDropdown());
    }, []);

    useEffect(() => {
        setReceiptTableData(initReceiptTableData);
        const _representativeBooking =
            facilityBookingReceipt?.facilityBooking?.roomBookingItems?.find(
                (roomBookingItem) => {
                    return roomBookingItem.isRepresentativeRoom;
                },
            ) || null;

        setRepresentativeBooking(_representativeBooking);
        setExpandRowIds(
            initReceiptTableData.map((receipt) => {
                return receipt.id;
            }),
        );
        if (
            facilityBookingReceipt?.facilityBooking?.status ===
                FacilityBookingStatus.FINISHED ||
            facilityBookingReceipt?.facilityBooking?.status ===
                FacilityBookingStatus.CANCELLED
        ) {
            setIsCheckout(true);
        }
    }, [facilityBookingReceipt]);

    useEffect(() => {
        if (
            activeDetailTabPane ===
            FacilityBookingDetailPageTabPane.FACILITY_BOOKING_DETAIL
        ) {
            onCancel();
        }
    }, [activeDetailTabPane]);

    useEffect(() => {
        const initData: {
            [id: string]: {
                id: number;
                receiptItemId?: number;
                saleItemId?: number;
                unitPrice?: number;
                quantity?: number;
                type: string;
                paymentMethodId?: number;
                amount?: number;
                paymentRoomBookingItemId?: number;
                paymentAmount?: number;
                date?: string;
                receiptItemDetailType?: string;
            };
        } = {};
        if (initReceiptTableData?.length) {
            const receiptItemDetailList = initReceiptTableData?.[0]?.children;
            receiptItemDetailList?.forEach((receiptItemDetail) => {
                if (receiptItemDetail.type === ReceiptItemType.RECEIPT) {
                    initData[`${receiptItemDetail.id}`] = {
                        id: receiptItemDetail.receiptItemDetailId as number,
                        receiptItemId: receiptItemDetail.receiptItemId,
                        saleItemId: receiptItemDetail.saleItemId || undefined,
                        unitPrice: receiptItemDetail.unitPrice,
                        quantity: receiptItemDetail.quantity,
                        paymentAmount: receiptItemDetail.amount,
                        type: receiptItemDetail.type,
                        receiptItemDetailType: receiptItemDetail.receiptItemDetailType,
                        paymentRoomBookingItemId: receiptItemDetail?.paymentRoom?.id,
                        date: parseDate(receiptItemDetail?.date)?.fmYYYYMMDDHHmmss(),
                    };
                    return;
                }
                if (receiptItemDetail.type === ReceiptItemType.PAYMENT) {
                    initData[`${receiptItemDetail.id}`] = {
                        id: receiptItemDetail.receiptItemDetailId as number,
                        receiptItemId: receiptItemDetail.receiptItemId,
                        type: receiptItemDetail.type,
                        receiptItemDetailType: receiptItemDetail.receiptItemDetailType,
                        paymentMethodId: receiptItemDetail?.paymentMethod?.id,
                        paymentAmount: receiptItemDetail.amount,
                        paymentRoomBookingItemId: receiptItemDetail?.paymentRoom?.id,
                        date: parseDate(receiptItemDetail?.date)?.fmYYYYMMDDHHmmss(),
                    };
                }
            });
            reset({ items: initData });
        }
    }, [initReceiptTableData, isEditMode, reset]);

    const totalPrice = useMemo(() => {
        let totalSale = 0;
        let totalPayment = 0;
        let totalTax = 0;
        if (isCanceledBooking) {
            return {
                totalSale,
                totalPayment,
                totalTax,
            };
        }

        receiptTableData?.forEach((receiptBookingItem) => {
            if (receiptBookingItem.children) {
                receiptBookingItem.children?.forEach((receiptItem) => {
                    if (receiptItem.type === ReceiptItemType.PAYMENT) {
                        totalPayment += receiptItem?.amount || 0;
                    } else {
                        totalSale += receiptItem?.amount || 0;
                        if (receiptItem?.tax) {
                            const tax = calculateTaxSaleItem(
                                receiptItem?.tax,
                                receiptItem?.quantity || 0,
                                receiptItem?.unitPrice || 0,
                            );
                            totalTax += tax;
                        }
                    }
                });
            }
        });

        return {
            totalSale,
            totalPayment,
            totalTax,
        };
    }, [receiptTableData, isCanceledBooking]);

    const dateOptions = useMemo(() => {
        const _dateOptions = [];
        let currentDate = parseDate(
            facilityBookingReceipt?.facilityBooking?.startDatetime,
        )
            .utc()
            .startOf('day');
        const endDatetime = parseDate(
            facilityBookingReceipt?.facilityBooking?.endDatetime,
        )
            .utc()
            .startOf('day');

        while (currentDate.isSameOrBefore(endDatetime, 'day')) {
            _dateOptions.push({
                label: currentDate.fmYYYYMMDD(),
                value: currentDate.fmYYYYMMDDHHmmss(),
            });
            currentDate = currentDate.add(1, 'day').startOf('day');
        }

        return _dateOptions;
    }, [facilityBookingReceipt]);

    const onClickButtonEdit = () => {
        setIsEditMode(true);
    };

    const resetReceipt = () => {
        setReceiptTableData(initReceiptTableData);
        reset();
        setSelectedRowKeys([]);
        setDeleteReceiptItemIds([]);
    };

    const onCancel = () => {
        setIsEditMode(false);
        resetReceipt();
    };

    const onClickButtonCancel = () => {
        showConfirm({
            title: t('facilityBooking.detail.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const receiptRowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const _selectedRows: IFacilityBookingReceiptTableData[] = [];
        receiptTableData.forEach((receipt) => {
            receipt?.children?.forEach((receiptItem) => {
                if (selectedRowKeys.includes(receiptItem.id)) {
                    _selectedRows.push(receiptItem);
                }
            });
        });

        return _selectedRows;
    }, [selectedRowKeys]);

    const addNewPayment = (item: IFacilityBookingReceiptTableData) => {
        const selectedSaleItemList =
            receiptTableData
                .find((receipt) => {
                    return receipt.id === item.id;
                })
                ?.children?.filter((receiptItem) => {
                    return (
                        selectedRowKeys.includes(receiptItem.id) &&
                        receiptItem.type === ReceiptItemType.RECEIPT
                    );
                }) || [];

        const _amount = selectedSaleItemList.reduce((total, saleItem) => {
            return total + (saleItem?.amount || 0);
        }, 0);

        const newReceiptItem = {
            isAddition: true,
            id: `${item?.id}_${Date.now()}`,
            type: ReceiptItemType.PAYMENT,
            receiptItemDetailType: ReceiptItemDetailType.PAYMENT,
            amount: _amount || 1,
            paymentMethod: null,
            paymentRoomBookingItemId: paymentRoomOptions?.length ? null : undefined,
        };
        const receiptData = cloneDeep(item);
        receiptData.children?.push(newReceiptItem as IFacilityBookingReceiptTableData);
        setReceiptTableData([receiptData]);
        setValue(
            `items.${newReceiptItem.id}.receiptItemDetailType`,
            ReceiptItemDetailType.PAYMENT,
        );
        setValue(`items.${newReceiptItem.id}.type`, newReceiptItem.type);
        setValue(`items.${newReceiptItem.id}.isAddition`, true);
        setValue(`items.${newReceiptItem.id}.paymentAmount`, newReceiptItem.amount);
        setValue(
            `items.${newReceiptItem.id}.paymentRoomBookingItemId`,
            representativeBooking?.id || paymentRoomOptions?.[0]?.value,
        );
    };

    const addNewReceipt = (item: IFacilityBookingReceiptTableData) => {
        const newReceiptItem = {
            isAddition: true,
            id: `${item?.id}_${Date.now()}`,
            type: ReceiptItemType.RECEIPT,
            guest: facilityBookingReceipt?.facilityBooking?.guest,
            unitPrice: 0,
            quantity: 1,
            amount: 0,
            receiptItemDetailType: ReceiptItemDetailType.SALE_ITEM,
            roomName: representativeBooking?.room?.name || paymentRoomOptions?.[0]?.label,
            paymentRoomBookingItemId: paymentRoomOptions?.length ? null : undefined,
        };
        const receiptData = cloneDeep(item);
        receiptData.children?.push(newReceiptItem as IFacilityBookingReceiptTableData);
        setReceiptTableData([receiptData]);
        setValue(
            `items.${newReceiptItem.id}.receiptItemDetailType`,
            ReceiptItemDetailType.SALE_ITEM,
        );
        setValue(`items.${newReceiptItem.id}.type`, newReceiptItem.type);
        setValue(`items.${newReceiptItem.id}.isAddition`, true);
        setValue(`items.${newReceiptItem.id}.quantity`, 1);
        setValue(
            `items.${newReceiptItem.id}.paymentRoomBookingItemId`,
            representativeBooking?.id || paymentRoomOptions?.[0]?.value,
        );
    };

    const _updateReceiptItems = useCallback(
        async (id: number, body: IUpdateFacilityBookingReceipt[]) => {
            const updateResponse = await dispatch(
                updateFacilityBookingReceipt({ id, body }),
            );
            if (updateFacilityBookingReceipt.fulfilled.match(updateResponse)) {
                if (updateResponse.payload?.success) {
                    notification.success({
                        message: t('facilityBooking.detail.receipt.updateSuccessMessage'),
                    });

                    const totalAmount = (updateResponse.payload?.data || []).reduce(
                        (total, item) => {
                            if (item.type === ReceiptItemType.PAYMENT) {
                                total -= item.amount || 0;
                            } else {
                                total += item.amount || 0;
                            }
                            return total;
                        },
                        0,
                    );
                    dispatch(setTotalAmount(totalAmount));
                } else {
                    notification.error({
                        message: updateResponse.payload?.message,
                    });
                }
                if (selectedFacilityBookingDetail?.id) {
                    dispatch(
                        getFacilityBookingReceipt(selectedFacilityBookingDetail?.id),
                    );
                }
                setIsEditMode(false);
                reset();
                setSelectedRowKeys([]);
                setDeleteReceiptItemIds([]);
            }
        },
        [],
    );

    const _updateFacilityBookingReceipt = useCallback(
        async (id: number, body: IUpdateFacilityBookingReceipt[]) => {
            if (deleteReceiptItemIds.length) {
                const deleteResponse = await dispatch(
                    bulkDeleteReceiptItems(deleteReceiptItemIds),
                );
                if (bulkDeleteReceiptItems.fulfilled.match(deleteResponse)) {
                    if (deleteResponse.payload?.success) {
                        _updateReceiptItems(id, body);
                    } else {
                        notification.error({
                            message: deleteResponse.payload?.message,
                        });
                        onCancel();
                    }
                }
                setDeleteReceiptItemIds([]);
            } else {
                _updateReceiptItems(id, body);
            }
        },
        [deleteReceiptItemIds],
    );

    const onClickButtonSubmit = () => {
        handleSubmit(async (data) => {
            if (errorAmountRowIds.current.length > 0) return;
            const receiptNewItemList: IUpdateFacilityBookingReceipt[] = Object.keys(
                data.items,
            )
                .filter((key) => {
                    return !data.items[key].receiptItemId;
                })
                .map((key) => {
                    if (data.items[key].type === ReceiptItemType.PAYMENT) {
                        return {
                            type: data.items[key].type,
                            paymentMethodId: data.items[key].paymentMethodId,
                            paymentRoomBookingItemId:
                                data.items[key].paymentRoomBookingItemId,
                            receiptItemDetails: [
                                {
                                    amount: +data.items[key].paymentAmount,
                                    payAt: data.items[key].date,
                                },
                            ],
                        };
                    }

                    return {
                        type: data.items[key].type,
                        paymentRoomBookingItemId:
                            data.items[key].paymentRoomBookingItemId,
                        receiptItemDetails: [
                            {
                                saleItemId: +data.items[key].saleItemId,
                                unitPrice: +data.items[key].unitPrice,
                                amount: +data.items[key].paymentAmount,
                                quantity: +data.items[key].quantity,
                                boughtAt: data.items[key].date,
                            },
                        ],
                    };
                });

            const _receiptUpdateItemList: IUpdateFacilityBookingReceipt[] = Object.keys(
                data.items,
            )
                .filter((key) => {
                    return data.items[key].receiptItemId;
                })
                .map((key) => {
                    if (data.items[key].type === ReceiptItemType.PAYMENT) {
                        return {
                            id: data.items[key].receiptItemId,
                            type: data.items[key].type,
                            paymentMethodId: data.items[key].paymentMethodId,
                            paymentRoomBookingItemId:
                                data.items[key].paymentRoomBookingItemId,
                            receiptItemDetails: [
                                {
                                    id: data.items[key].id,
                                    amount: +data.items[key].paymentAmount,
                                    payAt: data.items[key].date,
                                },
                            ],
                        };
                    }
                    return {
                        id: data.items[key].receiptItemId,
                        type: data.items[key].type,
                        paymentRoomBookingItemId:
                            data.items[key].paymentRoomBookingItemId,
                        receiptItemDetails: [
                            {
                                id: data.items[key].id,
                                saleItemId: data.items[key].saleItemId,
                                unitPrice: +data.items[key].unitPrice,
                                quantity: +data.items[key].quantity,
                                amount: +data.items[key].paymentAmount,
                                boughtAt: data.items[key].date,
                            },
                        ],
                    };
                });

            const receiptItemsObject: {
                [id: number]: IUpdateFacilityBookingReceipt;
            } = {};
            _receiptUpdateItemList.forEach((receiptItem) => {
                if (receiptItemsObject[receiptItem.id as number]) {
                    receiptItemsObject[receiptItem.id as number].receiptItemDetails =
                        receiptItemsObject[
                            receiptItem.id as number
                        ].receiptItemDetails?.concat(
                            receiptItem.receiptItemDetails || [],
                        );
                } else {
                    receiptItemsObject[receiptItem.id as number] = receiptItem;
                }
            });

            const receiptUpdateItemList = Object.values(receiptItemsObject);

            const formData = [...receiptUpdateItemList, ...receiptNewItemList];
            if (facilityBookingReceipt?.id) {
                _updateFacilityBookingReceipt(facilityBookingReceipt?.id, formData);
            }
        })();
    };

    const isMaxPrice = (price: number) => {
        return price <= MAX_PRICE && price >= MIN_PRICE;
    };

    const checkAmount = (id: string, amount: number) => {
        const validate = isMaxPrice(amount);
        const idIndex = errorAmountRowIds.current.indexOf(id);
        if (validate) {
            if (idIndex > -1) {
                errorAmountRowIds.current.splice(idIndex, 1);
            }
        } else {
            if (idIndex === -1) {
                errorAmountRowIds.current.push(id);
            }
        }
        return validate;
    };

    const onChangeSaleItem = (saleItem: ISaleItemDropdown, id: string) => {
        if (saleItem?.price || saleItem?.price === 0) {
            setValue(`items.${id}.unitPrice`, saleItem?.price, { shouldValidate: true });
        }
        const _receiptDataTable = cloneDeep(receiptTableData);
        const receiptRow = findReceiptRowById(_receiptDataTable, id);
        if (receiptRow) {
            receiptRow.saleItemId = saleItem?.id;
            receiptRow.unitPrice = saleItem?.price || 0;
            receiptRow.amount = (saleItem?.price || 0) * (receiptRow?.quantity || 0);
            receiptRow.detail = saleItem.name;
            receiptRow.tax = saleItem?.tax;
            receiptRow.isErrorAmount = !checkAmount(id, receiptRow.amount || 0);
            setValue(`items.${id}.paymentAmount`, receiptRow.amount, {
                shouldValidate: true,
            });
        }
        setReceiptTableData(_receiptDataTable);
    };

    const changeUnitPriceOrQuantity = (
        value: number,
        id: string,
        fieldUpdate: string,
    ) => {
        const _receiptDataTable = cloneDeep(receiptTableData);
        const receiptRow = findReceiptRowById(_receiptDataTable, id);
        if (!receiptRow) return;
        switch (fieldUpdate) {
            case ReceiptFormField.QUANTITY:
                receiptRow.quantity = value || 0;
                receiptRow.amount = (value || 0) * (receiptRow?.unitPrice || 0);
                break;

            case ReceiptFormField.UNIT_PRICE:
                receiptRow.unitPrice = value || 0;
                receiptRow.amount = (value || 0) * (receiptRow?.quantity || 0);
                break;

            case ReceiptFormField.AMOUNT:
                receiptRow.amount = value || 0;
                if (
                    receiptRow.type === ReceiptItemType.RECEIPT &&
                    !!receiptRow.quantity
                ) {
                    receiptRow.unitPrice = Math.floor(
                        receiptRow.amount / receiptRow.quantity,
                    );
                    setValue(`items.${id}.unitPrice`, receiptRow.unitPrice, {
                        shouldValidate: true,
                    });
                }
                break;
            default:
                break;
        }
        setValue(`items.${id}.paymentAmount`, receiptRow.amount, {
            shouldValidate: true,
        });
        receiptRow.isErrorAmount = !checkAmount(id, receiptRow.amount || 0);
        setReceiptTableData(_receiptDataTable);
    };

    const showPaymentModal = () => {
        dispatch(setShowPaymentModal(true));
    };

    const onChangePaymentMethod = (paymentMethodId: number, id: string) => {
        if (paymentMethodId) {
            setValue(`items.${id}.paymentMethodId`, paymentMethodId);
        }
        const findPaymentMethod = paymentMethodDropDown.find((paymentMethod) => {
            return paymentMethod.id === paymentMethodId;
        });
        const _receiptDataTable = cloneDeep(receiptTableData);
        const receiptRow = findReceiptRowById(_receiptDataTable, id);
        if (receiptRow) {
            receiptRow.paymentMethod = {
                name: findPaymentMethod?.name || '',
                id: paymentMethodId,
                autoGeneratedCode: findPaymentMethod?.autoGeneratedCode || '',
            };
        }
        setReceiptTableData(_receiptDataTable);
    };

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            buttonCancelText: t(
                'facilityBooking.detail.modalConfirmDeletion.cancelButton',
            ),
            buttonDeleteText: t(
                'facilityBooking.detail.modalConfirmDeletion.deleteButton',
            ),
            okButtonProps: { danger: true },
            wrapClassName: 'delete-item-modal',
            deletedItems: selectedRows.map((row) => {
                if (row.receiptItemDetailType === ReceiptItemDetailType.STAY_PRICE) {
                    return `${t('facilityBooking.detail.facility')} ${
                        facilityBookingReceipt?.facilityBooking?.autoGeneratedCode
                    } - ${row.detail}`;
                }
                return `${t('facilityBooking.detail.facility')} ${
                    facilityBookingReceipt?.facilityBooking?.autoGeneratedCode
                } - ${t(`facilityBooking.detail.receiptStatus.${row?.type}`)} ${
                    row.detail ? `- ${row.detail}` : ''
                }`;
            }),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const onConfirmDeletion = async () => {
        const stayList = selectedRows.filter((row) => {
            return row?.cannotDelete;
        });

        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });

        const _receiptTableData = cloneDeep(receiptTableData);
        _receiptTableData.forEach((receipt) => {
            receipt.children = receipt.children?.filter((receiptItem) => {
                return !selectedIds.includes(receiptItem.id);
            });
        });

        const _receiptItem: {
            [id: string]: {
                id?: number;
                receiptItemId?: number;
                saleItemId?: number | null;
                unitPrice?: number | null;
                quantity?: number;
                type?: string;
                paymentMethodId?: number | null;
                amount?: number;
                paymentRoomBookingItemId?: number | null;
            };
        } = {};
        const formData = getValues().items;
        Object.keys(formData).forEach((key) => {
            if (!selectedIds.includes(key)) {
                _receiptItem[key] = formData[key];
            }
        });

        if (stayList.length) {
            Modal.error({
                title: t('facilityBooking.detail.modalDeleteError.title'),
                content: (
                    <>
                        <span>
                            {t('facilityBooking.detail.modalDeleteError.description')}
                        </span>
                        <ul className="vertical-delete-list">
                            {stayList.map((item) => (
                                <li key={item.id}>{`${t(
                                    'facilityBooking.detail.facility',
                                )} ${
                                    facilityBookingReceipt?.facilityBooking
                                        ?.autoGeneratedCode
                                } - ${item.detail}`}</li>
                            ))}
                        </ul>
                    </>
                ),
                okText: t('facilityBooking.detail.modalDeleteError.closeButton'),
                centered: true,
            });
            return;
        }

        if (
            selectedRows.some((row) => {
                return !row.isAddition;
            })
        ) {
            const ids = selectedRows
                ?.filter((row) => {
                    return !row?.isAddition;
                })
                .map((row) => {
                    return row?.receiptItemDetailId;
                });

            setDeleteReceiptItemIds([...deleteReceiptItemIds, ...(ids as number[])]);
        }
        setReceiptTableData(_receiptTableData);
        setValue('items', _receiptItem);
        setSelectedRowKeys([]);
    };

    const paymentRoomOptions = useMemo(() => {
        return facilityBookingReceipt?.facilityBooking?.roomBookingItems
            ?.filter((roomBooking) => {
                return roomBooking?.room;
            })
            ?.map((roomBooking) => {
                return {
                    value: roomBooking?.id,
                    label: roomBooking?.room?.name,
                };
            });
    }, [facilityBookingReceipt]);

    const changePaymentSetting = (key: string) => {
        setOpenPaymentSettingPopConfirm(true);
        setSelectedPaymentSetting(key);
    };

    const handleVisibleChange = (isVisible: boolean) => {
        if (openPaymentSettingPopConfirm) {
            setOpenPaymentSettingPopConfirm(isVisible);
        } else {
            setOpenPaymentSettingPopConfirm(false);
        }
    };

    const paymentSettingOptions = useMemo(() => {
        return Object.values(PaymentSettings).map((setting) => {
            return {
                label: t(`facilityBooking.detail.receipt.paymentSetting.${setting}`),
                key: setting,
                onClick: () => {
                    changePaymentSetting(setting);
                },
            };
        });
    }, []);

    const changeAllItemToRepresentativeRoom = (
        representativeBooking: IPaymentRoomBookingItem | null,
    ) => {
        const ids = Object.keys(getValues().items);
        if (representativeBooking?.id) {
            ids.forEach((id) => {
                setValue(
                    `items.${id}.paymentRoomBookingItemId`,
                    representativeBooking?.id,
                );
            });
        }
    };

    const changeAllRoomFreeRepresentativeRoom = (
        representativeBooking: IPaymentRoomBookingItem | null,
    ) => {
        const formData = getValues().items;
        if (representativeBooking?.id) {
            Object.keys(formData).forEach((id) => {
                if (
                    formData[id]?.saleItemId ||
                    formData[id]?.isAddition ||
                    formData[id]?.paymentMethodId
                ) {
                    setValue(
                        `items.${id}.paymentRoomBookingItemId`,
                        representativeBooking?.id,
                    );
                }
            });
        }
    };

    const changePaymentSettingConfirm = () => {
        if (selectedPaymentSetting === PaymentSettings.ALL_ROOMS) {
            changeAllItemToRepresentativeRoom(representativeBooking);
        } else {
            changeAllRoomFreeRepresentativeRoom(representativeBooking);
        }
    };

    const onChangePaymentRoom = (roomId: number, id: string) => {
        const _receiptDataTable = cloneDeep(receiptTableData);
        const receiptRow = findReceiptRowById(_receiptDataTable, id);
        if (receiptRow) {
            receiptRow.paymentRoomBookingItemId = roomId;
        }
        setReceiptTableData(_receiptDataTable);
    };

    const splitReceipt = () => {
        navigate(`split-receipt`);
    };

    const splitInvoice = () => {
        navigate(`split-invoice`);
    };

    const isCanEditQuantity = (item: IFacilityBookingReceiptTableData) => {
        const { type, receiptItemDetailType, showPaymentRoom, isAddition } = item;
        return (
            type === ReceiptItemType.RECEIPT &&
            receiptItemDetailType === ReceiptItemDetailType.SALE_ITEM &&
            (showPaymentRoom || isAddition)
        );
    };

    const isCanEditSaleItem = (item: IFacilityBookingReceiptTableData) => {
        const { receiptItemDetailType, isAddition, showPaymentRoom } = item;
        return (
            receiptItemDetailType === ReceiptItemDetailType.SALE_ITEM &&
            (isAddition || showPaymentRoom)
        );
    };

    const isCanEditBookingDate = (item: IFacilityBookingReceiptTableData) => {
        const { receiptItemDetailType, isAddition, showPaymentRoom } = item;
        return (
            receiptItemDetailType !== ReceiptItemDetailType.STAY_PRICE &&
            (isAddition || showPaymentRoom)
        );
    };

    const getPaymentMethodOptions = (
        paymentMethod: IPaymentMethod | null | undefined,
    ) => {
        if (!paymentMethod) return paymentMethodDropDown;
        const findMethod = paymentMethodDropDown.find(
            (method) => method.id === paymentMethod.id,
        );
        if (findMethod) return paymentMethodDropDown;
        const { id, name, autoGeneratedCode } = paymentMethod;
        return [
            ...paymentMethodDropDown,
            {
                id,
                name,
                autoGeneratedCode,
            },
        ];
    };

    const showBulkPaymentModal = () => {
        dispatch(setShowBulkPaymentModal(true));
    };

    const facilityBookingReceiptColumns: ColumnsType<IFacilityBookingReceiptTableData> = [
        {
            title: t('facilityBooking.detail.receiptTableColumn.date'),
            className: `${isEditMode ? 'date-cell' : ''}`,
            onCell: (item: IFacilityBookingReceiptTableData) => {
                return {
                    colSpan: item.name ? 5 : 1,
                };
            },
            width: isEditMode ? '146px' : '120px',
            render: (item: IFacilityBookingReceiptTableData) => {
                return item.name ? (
                    <>
                        <span className="booking-name">{`${item.name}${item.autoGeneratedCode}`}</span>
                        {isEditMode ? (
                            <span className="payment-button-group">
                                <Button
                                    size="small"
                                    type="primary"
                                    ghost
                                    onClick={() => {
                                        addNewPayment(item);
                                    }}
                                >
                                    <PlusOutlined />
                                    {t('roomBooking.detail.receipt.button.payment')}
                                </Button>
                                <Button
                                    size="small"
                                    danger
                                    onClick={() => {
                                        addNewReceipt(item);
                                    }}
                                >
                                    <PlusOutlined />
                                    {t('facilityBooking.detail.receipt.button.earnings')}
                                </Button>
                            </span>
                        ) : (
                            ''
                        )}
                    </>
                ) : isEditMode && isCanEditBookingDate(item) ? (
                    <SingleSelect
                        label=""
                        name={`items.${item.id}.date`}
                        popupMatchSelectWidth={false}
                        control={control}
                        options={dateOptions}
                        showSearch={false}
                    />
                ) : (
                    <span>{item.date ? parseDate(item.date)?.fmYYYYMMDD('-') : ''}</span>
                );
            },
            key: 'date',
        },
        {
            title: t('facilityBooking.detail.receiptTableColumn.status'),
            width: '80px',
            onCell: (item: IFacilityBookingReceiptTableData) => {
                return {
                    colSpan: item.name ? 0 : 1,
                };
            },
            render: (item: IFacilityBookingReceiptTableData) => {
                return item?.type ? (
                    <span
                        className={`receipt-status receipt-status-${item?.type} text-truncate`}
                    >
                        {t(`facilityBooking.detail.receiptStatus.${item?.type}`)}
                    </span>
                ) : (
                    ''
                );
            },
            key: 'status',
        },
        {
            title: t('facilityBooking.detail.receiptTableColumn.facilityDetail'),
            ellipsis: true,
            onCell: (item: IFacilityBookingReceiptTableData) => {
                return {
                    colSpan: item.name ? 0 : 1,
                };
            },
            key: 'facilityDetail',
            render: (item: IFacilityBookingReceiptTableData) => {
                return isEditMode && isCanEditSaleItem(item) ? (
                    <SelectSaleItem
                        label=""
                        placeholder={t(
                            'facilityBooking.detail.receiptTableColumn.saleItemPlaceholder',
                        )}
                        required
                        name={`items.${item.id}.saleItemId`}
                        control={control}
                        showSearch={true}
                        dropdownStyle={{
                            minWidth: '380px',
                            maxWidth: '480px',
                        }}
                        optionLabelProp="label"
                        popupMatchSelectWidth={false}
                        onChange={(value) => {
                            onChangeSaleItem(value, `${item.id}`);
                        }}
                        selectedValue={item?.saleItemId}
                        selectedSaleItemIds={[]}
                        additionalItems={item.saleItem ? [item.saleItem] : []}
                    />
                ) : (
                    <span>
                        {item.type === ReceiptItemType.PAYMENT
                            ? ''
                            : item.detail
                            ? item.detail
                            : ''}
                    </span>
                );
            },
        },
        {
            title: t('facilityBooking.detail.receiptTableColumn.guestName'),
            ellipsis: true,
            onCell: (item: IFacilityBookingReceiptTableData) => {
                return {
                    colSpan: item.name ? 0 : 1,
                };
            },
            key: 'guest',
            render: (item: IFacilityBookingReceiptTableData) => {
                return (
                    <span>
                        {item.type === ReceiptItemType.PAYMENT
                            ? ''
                            : item.guest
                            ? item.guest?.yomigana
                            : ''}
                    </span>
                );
            },
        },
        {
            title: t('facilityBooking.detail.receiptTableColumn.roomName'),
            ellipsis: true,
            width: paymentRoomOptions?.length ? '' : '75px',
            onCell: (item: IFacilityBookingReceiptTableData) => {
                return {
                    colSpan: item.name ? 0 : 1,
                };
            },
            key: 'roomName',
            render: (item: IFacilityBookingReceiptTableData) => {
                return (
                    <span>
                        {item?.type === ReceiptItemType.RECEIPT &&
                        (item?.showPaymentRoom || item?.isAddition)
                            ? item?.roomName
                            : ''}
                    </span>
                );
            },
        },
        {
            title: t('facilityBooking.detail.receiptTableColumn.unitPrice'),
            width: '136px',
            render: (item: IFacilityBookingReceiptTableData) => {
                return isEditMode &&
                    (item?.isAddition ||
                        item.receiptItemDetailType !== ReceiptItemDetailType.PAYMENT) ? (
                    item.unitPrice || item.unitPrice === 0 ? (
                        <InputPrice
                            name={`items.${item.id}.unitPrice`}
                            label=""
                            control={control}
                            min={1}
                            allowClear={true}
                            onChange={(e) => {
                                changeUnitPriceOrQuantity(
                                    Number(e?.target?.value),
                                    `${item.id}`,
                                    ReceiptFormField.UNIT_PRICE,
                                );
                            }}
                            className="unit-price-input"
                        />
                    ) : (
                        ''
                    )
                ) : (
                    <span>
                        {typeof item.unitPrice === 'number' ? `¥${item?.unitPrice}` : ''}
                    </span>
                );
            },
            key: 'unitPrice',
        },
        {
            title: t('facilityBooking.detail.receiptTableColumn.quantity'),
            width: '80px',
            key: 'quantity',
            render: (item: IFacilityBookingReceiptTableData) => {
                return isEditMode && isCanEditQuantity(item) ? (
                    item.quantity || item.quantity === 0 ? (
                        <InputNumber
                            name={`items.${item.id}.quantity`}
                            label=""
                            control={control}
                            allowClear
                            min={1}
                            maxLength={MAX_LENGTH_QUANTITY}
                            onChange={(e) => {
                                changeUnitPriceOrQuantity(
                                    Number(e?.target?.value),
                                    `${item.id}`,
                                    ReceiptFormField.QUANTITY,
                                );
                            }}
                            className="quantity-input"
                        />
                    ) : (
                        ''
                    )
                ) : (
                    <span>{item.quantity ? item.quantity : ''}</span>
                );
            },
        },
        {
            title: t('facilityBooking.detail.receiptTableColumn.total'),
            width: '138px',
            render: (item: IFacilityBookingReceiptTableData) => {
                return isEditMode && !item.children ? (
                    <InputPrice
                        name={`items.${item.id}.paymentAmount`}
                        label=""
                        control={control}
                        allowClear={true}
                        onChange={(e) => {
                            changeUnitPriceOrQuantity(
                                Number(e?.target?.value),
                                `${item.id}`,
                                ReceiptFormField.AMOUNT,
                            );
                        }}
                        className="payment-amount-input"
                    />
                ) : (
                    <div>
                        <span
                            className={
                                item?.type === 'payment'
                                    ? 'total-payment'
                                    : 'total-earnings'
                            }
                        >
                            {typeof item.amount === 'number' ? `¥${item?.amount}` : ''}
                        </span>
                        {isEditMode && item.isErrorAmount && (
                            <div className="text-error">
                                {item?.amount && item?.amount > 0
                                    ? t(
                                          'roomBooking.detail.receipt.message.amountTooLarge',
                                      )
                                    : t(
                                          'roomBooking.detail.receipt.message.amountTooSmall',
                                      )}
                            </div>
                        )}
                    </div>
                );
            },
            key: 'amount',
        },
        {
            title: t('facilityBooking.detail.receiptTableColumn.paymentRoom'),
            ellipsis: true,
            width: paymentRoomOptions?.length ? '' : '90px',
            key: 'paymentRoom',
            render: (item: IFacilityBookingReceiptTableData) => {
                return paymentRoomOptions?.length &&
                    (item?.showPaymentRoom || item?.isAddition) ? (
                    isEditMode ? (
                        <SingleSelect
                            label=""
                            name={`items.${item.id}.paymentRoomBookingItemId`}
                            control={control}
                            options={paymentRoomOptions}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            popupMatchSelectWidth={false}
                            onChange={(value) => {
                                onChangePaymentRoom(value, `${item.id}`);
                            }}
                        />
                    ) : (
                        <span>{item?.paymentRoom?.room?.name}</span>
                    )
                ) : (
                    ''
                );
            },
        },
        {
            title: t('facilityBooking.detail.receiptTableColumn.payment'),
            ellipsis: true,
            render: (item: IFacilityBookingReceiptTableData) => {
                return isEditMode && item.type === ReceiptItemType.PAYMENT ? (
                    <PaymentMethodSelect
                        label=""
                        placeholder={t(
                            'facilityBooking.detail.receiptTableColumn.paymentPlaceholder',
                        )}
                        name={`items.${item.id}.paymentMethodId`}
                        control={control}
                        paymentMethodOptions={getPaymentMethodOptions(item.paymentMethod)}
                        optionLabelProp="label"
                        onChange={(value) => {
                            onChangePaymentMethod(value, `${item.id}`);
                        }}
                        showSearch={true}
                        popupMatchSelectWidth={300}
                        placement={'bottomRight'}
                    />
                ) : (
                    <span>
                        {item?.paymentMethod?.name ? `${item?.paymentMethod?.name}` : ''}
                    </span>
                );
            },
            key: 'payment',
        },
    ];

    return (
        <div className="facility-booking-receipt-tab-pane">
            <div className="header-receipt">
                <div className="header-receipt-title">
                    <h2>{t('facilityBooking.detail.receipt.title')}</h2>
                    <p>{`(${t('common.standardTimeTitle')})`}</p>
                </div>
                <div className="header-button-action-wrapper">
                    {isEditMode ? (
                        <div className="left-header-button-action">
                            <Popconfirm
                                title={
                                    <div className="change-booking-status-title">
                                        {selectedPaymentSetting ===
                                        PaymentSettings.ALL_ROOMS ? (
                                            <span className="change-payment-setting-option">
                                                {t(
                                                    `facilityBooking.detail.receipt.paymentSetting.allDetails`,
                                                )}
                                            </span>
                                        ) : (
                                            <span className="change-payment-setting-option">
                                                {t(
                                                    `facilityBooking.detail.receipt.paymentSetting.allRoomCharges`,
                                                )}
                                            </span>
                                        )}
                                        <span>
                                            {t(
                                                `facilityBooking.detail.receipt.paymentSetting.questionChangeConfirm`,
                                            )}
                                        </span>
                                    </div>
                                }
                                open={openPaymentSettingPopConfirm}
                                placement="topRight"
                                okText={t(`roomBooking.detail.receipt.button.yes`)}
                                cancelText={t(`roomBooking.detail.receipt.button.no`)}
                                onCancel={() => {
                                    setOpenPaymentSettingPopConfirm(false);
                                }}
                                onOpenChange={handleVisibleChange}
                                onConfirm={changePaymentSettingConfirm}
                            >
                                <Dropdown menu={{ items: paymentSettingOptions }}>
                                    <Button>
                                        <Space>
                                            {t(
                                                'facilityBooking.detail.receipt.paymentSetting.title',
                                            )}
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </Popconfirm>
                            {selectedRowKeys.length > 0 && (
                                <Button
                                    type="text"
                                    className="delete-button"
                                    onClick={showConfirmDialog}
                                >
                                    <DeleteOutlined />
                                </Button>
                            )}
                        </div>
                    ) : (
                        <div className="right-header-button-action">
                            <Button onClick={onClickButtonEdit} disabled={isCheckout}>
                                <EditOutlined />
                                {t('facilityBooking.detail.receipt.button.edit')}
                            </Button>
                            <Button onClick={splitReceipt}>
                                <FileDoneOutlined />
                                {t('facilityBooking.detail.receipt.button.splitReceipt')}
                            </Button>
                            <Button onClick={splitInvoice}>
                                <ExceptionOutlined />
                                {t('facilityBooking.detail.receipt.button.splitInvoice')}
                            </Button>
                            <Button onClick={showBulkPaymentModal}>
                                <RedEnvelopeOutlined />
                                {t('facilityBooking.detail.receipt.button.bulkPayment')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <Table
                rowSelection={
                    isEditMode
                        ? {
                              ...receiptRowSelection,
                              checkStrictly: false,
                          }
                        : undefined
                }
                columns={facilityBookingReceiptColumns}
                dataSource={receiptTableData}
                onChange={onChange}
                pagination={false}
                className="receipt-table"
                rowKey="id"
                expandable={{
                    expandedRowKeys: expandRowIds,
                    onExpand: (expanded, record) => {
                        expanded ? setExpandRowIds([record.id]) : setExpandRowIds([]);
                    },
                    expandIcon: ({ expanded, onExpand, record }) =>
                        record?.children ? (
                            expanded ? (
                                <CaretDownOutlined
                                    onClick={(e) => {
                                        onExpand(record, e);
                                    }}
                                />
                            ) : (
                                <CaretRightOutlined
                                    onClick={(e) => onExpand(record, e)}
                                />
                            )
                        ) : (
                            <></>
                        ),
                }}
            />
            <SummaryTotalReceipt totalPrice={totalPrice} />
            <div className="bottom-button-group">
                {isEditMode ? (
                    <>
                        <Button onClick={onClickButtonCancel}>
                            {t(`common.buttonCancelText`)}
                        </Button>
                        <Popconfirm
                            placement="topRight"
                            title={t('facilityBooking.detail.receipt.resetPopup.title')}
                            onConfirm={resetReceipt}
                            okText={t(
                                `facilityBooking.detail.receipt.resetPopup.yesButton`,
                            )}
                            cancelText={t(
                                `facilityBooking.detail.receipt.resetPopup.noButton`,
                            )}
                        >
                            <Button>
                                {t(`facilityBooking.detail.receipt.button.reset`)}
                            </Button>
                        </Popconfirm>

                        <Button type="primary" onClick={onClickButtonSubmit}>
                            {t(`common.buttonSaveText`)}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={showPaymentModal} disabled={isCheckout}>
                            {t(`facilityBooking.detail.receipt.button.calculate`)}
                        </Button>
                    </>
                )}
            </div>
            <PaymentModal
                facilityBookingReceipt={facilityBookingReceipt}
                totalPrice={totalPrice}
            />
            <BulkPaymentModal
                facilityBookingReceipt={facilityBookingReceipt}
                totalPrice={totalPrice}
            />
        </div>
    );
}

export default FacilityBookingReceiptTabPane;
