const staffLayout = {
    notification: '新着情報',
    adminNotification: 'お知らせ',
    temporaryBooking: '確認リスト',
    temporaryBookingTitle: '在庫仮押さえ予約',
    confirmedBookingTitle: '予約を確認しました',
    confirmedFacilityBookingTitle: '設備予約を確認しました',
    updatedRoomBookingTitle: '宿泊予約のステータスを変更しました',
    updatedFacilityBookingTitle: '設備予約のステータスを変更しました',
    notificationDate: '{{month}}月{{date}}日',
    newTag: 'NEW',
    noData: 'No data',
    button: {
        tabist: '新着情報',
        tllNotification: 'お知らせ',
    },
    notificationTitle: {
        room_booking_change_status: '宿泊予約のステータスを変更しました',
        facility_booking_change_status: '設備予約のステータスを変更しました',
        tll_create_room_booking: 'OTAから宿泊予約を作成しました',
        tll_modify_room_booking: 'OTAから宿泊予約を変更しました',
        room_booking_confirm: '宿泊予約を作成しました',
        facility_booking_create: '設備予約を作成しました',
    },
    adminNotificationItem: {
        closingBooking: '【締め作業】締められない予約があります',
    },
};

export default staffLayout;
