import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button } from 'antd';
import { InputNumber, InputText, SingleSelect } from '~components';
import { useForm } from '~plugins/hook-form';
import { useTranslation } from 'react-i18next';
import { IInititalRoomSetting } from '../../interfaces';
import './CreateIndividualRoomsFormSetting.scss';
import { roomTypeDropdownOptionsSelector } from '~features/room-type/room-type.reducer';
import { setIndividualRoomList } from '../../room.reducer';
import { generateName } from '~common/commonFunctions';
import { getIndividualRoomsFormId } from '~features/room/helpers';
import { settingSchema } from '~features/room/schema';

function CreateIndividualRoomsFormSetting() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, handleSubmit, reset, formState } = useForm<IInititalRoomSetting>({
        resolver: yupResolver(settingSchema),
    });
    const roomTypeOptions = useAppSelector(roomTypeDropdownOptionsSelector);

    const initFormData = () => {
        reset({
            quantity: undefined,
            roomTypeId: undefined,
            initialRoomName: '',
        });
    };

    useEffect(() => {
        initFormData();
    }, []);

    const onClickButtonGenerateRoom = () => {
        handleSubmit(async (data) => {
            const generatedRoomList = [];
            for (let i = 0; i < Number(data.quantity); i += 1) {
                generatedRoomList.push({
                    feId: `${Date.now()}_${i}`,
                    roomTypeId: data.roomTypeId,
                    name: generateName(data.initialRoomName, i),
                });
            }
            dispatch(setIndividualRoomList(generatedRoomList));
        })();
    };

    return (
        <div className="individual-rooms-form-setting-wrapper">
            <Form layout="vertical">
                <Row className="input-row" gutter={8}>
                    <Col span={5}>
                        <InputNumber
                            label={t('room.roomFormIndividual.quantity')}
                            placeholder={t('room.roomFormIndividual.quantity')}
                            name="quantity"
                            control={control}
                            required={true}
                            id={getIndividualRoomsFormId('quantity')}
                        />
                    </Col>
                    <Col span={12}>
                        <SingleSelect
                            label={t('room.roomFormIndividual.roomType')}
                            placeholder={t(
                                'room.roomFormIndividual.placeholder.roomType',
                            )}
                            name="roomTypeId"
                            control={control}
                            options={roomTypeOptions}
                            id={getIndividualRoomsFormId('roomTypeId')}
                            required
                        />
                    </Col>
                    <Col span={7}>
                        <InputText
                            label={t('room.roomFormIndividual.startRoomName')}
                            placeholder={t('room.roomFormIndividual.startRoomName')}
                            name="initialRoomName"
                            control={control}
                            id={getIndividualRoomsFormId('initialRoomName')}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Button
                                type="default"
                                className="room-generate-button"
                                onClick={onClickButtonGenerateRoom}
                                disabled={!formState.isValid}
                                id={getIndividualRoomsFormId('room-generate-button')}
                            >
                                <PlusOutlined className="room-generate-icon" />
                                <span>
                                    {t('room.roomFormIndividual.generateRoomsBtn')}
                                </span>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default CreateIndividualRoomsFormSetting;
