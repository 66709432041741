import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InputPrice } from '~components';
import { ReceiptFormField, ReceiptItemType } from '~features/room-booking/constants';
import { IBookingReceiptTableData } from '~features/room-booking/interfaces';

type Props = {
    control: any;
    item: IBookingReceiptTableData;
    isEditMode: boolean;
    onChangeAmount: (value: number, id: string, fieldUpdate: string) => void;
};
function AmountColumn(props: Props) {
    const { control, item, isEditMode, onChangeAmount } = props;
    const { t } = useTranslation();
    const isCanEdit = useMemo(() => {
        return isEditMode && !item.isCheckout && !item.children;
    }, [isEditMode, item]);

    return isCanEdit ? (
        <>
            <InputPrice
                label=""
                name={`${item.id}.amount`}
                allowClear={true}
                onChange={(e) =>
                    onChangeAmount(
                        Number(e.target?.value),
                        item.id,
                        ReceiptFormField.AMOUNT,
                    )
                }
                control={control}
            />
        </>
    ) : (
        <div>
            <span
                className={classNames({
                    'total-payment': item?.status === ReceiptItemType.PAYMENT,
                    'total-earnings':
                        item?.status === ReceiptItemType.RECEIPT && !!item.children,
                })}
            >
                {typeof item.amount === 'number' ? `¥${item?.amount}` : ''}
            </span>
            {isCanEdit && item.isErrorAmount && (
                <div className="text-error">
                    {item?.amount && item?.amount > 0
                        ? t('roomBooking.detail.receipt.message.amountTooLarge')
                        : t('roomBooking.detail.receipt.message.amountTooSmall')}
                </div>
            )}
        </div>
    );
}

export default AmountColumn;
