/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '~features/sale-item/constants';
import { Button, Layout, notification, Space } from 'antd';

import { Footer } from 'antd/lib/layout/layout';
import { showConfirm } from '~common/notification';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useNavigate } from 'react-router-dom';
import {
    hasImportErrorSelector,
    importCsvSaleItem,
    importSaleItemListSelector,
    setHasImportError,
    setImportSaleItemList,
    setShowLoadingForm,
    showLoadingFormSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { IImportSaleItem } from '~features/sale-item/interfaces';
import { uniq } from 'lodash';
import './ImportCsvSaleItemPage.scss';
import ImportCsvSaleItem from '~features/sale-item/components/ImportCsvSaleItem/ImportCsvSaleItem';

const { Content } = Layout;

function ImportCsvSaleItemPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const showLoading = useAppSelector(showLoadingFormSelector);
    const hasError = useAppSelector(hasImportErrorSelector);
    const importSaleItemList = useAppSelector(importSaleItemListSelector);
    const [responseError, setResponseError] = useState<Record<number, any>>({});

    useEffect(() => {
        dispatch(setShowLoadingForm(false));
        if (!importSaleItemList?.length) {
            navigate('/front-settings/sale-item');
        }
    }, []);

    const _showConfirm = () => {
        showConfirm({
            title: t('saleItem.import.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    const _importSaleItems = useCallback(async (saleItem: IImportSaleItem[]) => {
        const response = await dispatch(importCsvSaleItem(saleItem));
        if (importCsvSaleItem.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('saleItem.import.importSaleItemSuccess'),
                });
                navigate('/front-settings/sale-item');
            } else {
                let errors = {};
                uniq(
                    (response.payload?.errors || []).map((error) => error.order),
                ).forEach((order) => {
                    if (order) {
                        let currentError = {};
                        (response.payload?.errors || [])
                            .filter((error) => error.order === order)
                            .forEach((error) => {
                                if (
                                    (error.key === 'name' ||
                                        error.key === 'taxName' ||
                                        error.key === 'isShowInBooking' ||
                                        error.key === 'price') &&
                                    error.order
                                ) {
                                    currentError = {
                                        ...currentError,
                                        [error.key]: error.message,
                                    };
                                }
                            });
                        errors = {
                            ...errors,
                            [order]: currentError,
                        };
                    }
                });
                dispatch(setHasImportError(true));
                setResponseError(errors);
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    const onCancel = () => {
        dispatch(setImportSaleItemList([]));
        navigate('/front-settings/sale-item');
    };

    const onSubmit = () => {
        _importSaleItems(importSaleItemList);
    };
    return (
        <div className="import-csv-sale-item-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('saleItem.import.title')}
            />
            <Content>
                <div className="import-sale-item-form-wrapper">
                    <div className="import-sale-item-list-content">
                        <ImportCsvSaleItem responseError={responseError} />
                    </div>
                </div>
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space align="end">
                        <Button onClick={_showConfirm}>
                            {t('saleItem.import.cancelBtn')}
                        </Button>
                        <Button
                            id="sale-item-form-import-csv-submit"
                            type="primary"
                            onClick={onSubmit}
                            loading={showLoading}
                            disabled={hasError}
                        >
                            {t('saleItem.import.submitBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default ImportCsvSaleItemPage;
