import i18next from '~plugins/i18next/i18n';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';

export enum OrderBy {
    ID = 'id',
    TITLE = 'title',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
}

export enum SearchField {
    ALL = 'keyword',
}

export const initNotificationListQuery = {
    keyword: '',
    autoGeneratedCode: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const enum NotificationListColumn {
    ID = 'autoGeneratedCode',
    TITLE = 'title',
    HOTEL = 'hotelName',
    TAG = 'tag',
}

export enum AdminNotificationTag {
    CLOSING_BOOKING = 'closing-booking',
}

export const notificationListBreadcrumbs = () => [
    {
        text: i18next.t('notification.list.breadcrumbs.setting'),
    },
    {
        text: i18next.t('notification.list.breadcrumbs.notification'),
    },
];

export const notificationFormBreadcrumbs = () => [
    {
        text: i18next.t('notification.form.breadcrumbs.home'),
    },
    {
        text: i18next.t('notification.form.breadcrumbs.notification'),
    },
    {
        text: i18next.t('notification.form.breadcrumbs.notificationSetting'),
    },
];

export enum FromSource {
    PMS = 'pms',
    TLL = 'tll',
}

export const enum BookingModuleNotification {
    ROOM_BOOKING = 'ROOM_BOOKING',
    FACILITY_BOOKING = 'FACILITY_BOOKING',
}

export const enum ActionNotification {
    UPDATE = 'UPDATE',
    CREATE = 'CREATE',
}

export enum NotificationType {
    ROOM_BOOKING_CHANGE_STATUS = 'room_booking_change_status',
    FACILITY_BOOKING_CHANGE_STATUS = 'facility_booking_change_status',
    TLL_CREATE_ROOM_BOOKING = 'tll_create_room_booking',
    TLL_MODIFY_ROOM_BOOKING = 'tll_modify_room_booking',
}
