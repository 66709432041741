import { storage } from '~common/localStorage';
import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse } from '~common/interfaces';
import { ISiteController } from '../interfaces';

const hotelId = storage.get('hotelId');

class SiteControllerService extends ApiService {
    getSiteController() {
        return this._get(`${this.baseUrl}/${hotelId}/site-controller`) as Promise<
            IBodyResponse<ISiteController>
        >;
    }

    updateSiteController(body: ISiteController) {
        return this._patch(
            `${this.baseUrl}/${hotelId}/tll-site-controller`,
            body,
        ) as Promise<IBodyResponse<ISiteController>>;
    }
}

const siteControllerService = new SiteControllerService(
    { baseUrl: '/hotel' },
    axiosService,
);
export default siteControllerService;
