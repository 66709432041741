const roomBookingList = {
    title: 'Room booking list',
    breadcrumbs: {
        home: 'Home',
        roomBooking: 'Room booking',
        bookingList: 'Room booking list',
    },
    column: {
        id: 'Booking number',
        guestYomigana: 'Yomigana',
        guestFullName: 'Guest name',
        roomName: 'Room name',
        status: 'Status',
        checkInTime: 'C/I',
        endDateOfStay: 'C/O',
        payment: 'Payment',
        paymentNumber: 'Unpaid amount',
        memo: 'Memo',
        cleaning: 'Cleaning status',
        category: 'Category',
        bookingDate: 'Booking date',
        marketingChannel: 'Marketing channel',
        planName: 'Plan',
        advance: 'Prepaid',
        cleaningStatus: {
            cleaned: 'Cleaned',
            uncleaned: 'Uncleaned',
        },
    },
    bookingCategory: {
        group: 'Group booking',
        individual: 'Individual booking',
        day_use: 'Day-use',
    },
    dropdown: {
        title: 'Change status',
    },
    statusPopConfirm: {
        title: 'Change status',
        content: 'Do you want to change status to {{status}}?',
        okText: 'Yes',
        cancelText: 'No',
    },
    checkoutModalConfirm: {
        title: 'Confirmation of status change',
        content: 'Do you want to change status to {{status}}?',
        okText: 'Change',
        cancelText: 'Cancel',
    },
    notArriveModalConfirm: {
        title: 'Confirmation of status change',
        content: 'Change booking status to reserved.',
        okText: 'Change',
        cancelText: 'Cancel',
    },
    cancelModalConfirm: {
        title: 'Are you sure to cancel this booking?',
    },
    statusModalConfirm: {
        success: 'Update status success',
        error: 'Change booking status failed!',
        errorChangeStatus:
            'Cannot change status from {{beforeStatus}} to {{afterStatus}}',
        okText: 'Close',
    },
    update: {
        cleaningStatusTitle: 'Are you sure to change status?',
    },
    importXml: {
        success: 'Import xml file successfully',
    },
    filterForm: {
        buttonCreateRoomBooking: 'New booking',
        buttonDetailSearch: 'Search detail',
        buttonRemoveFilter: 'Delete all filters',
        buttonPreparationList: 'Preparation list',
        status: {
            label: 'Status',
            all: 'All',
            not_arrived: 'Reserved',
            staying: 'Checked in',
            departure: 'Checked out',
            cancelled: 'Cancelled',
        },
        dateType: {
            label: 'Date',
            all: 'All',
            today: 'Today',
            tomorrow: 'Tomorrow',
        },
        searchColumn: {
            keyword: 'All',
            autoGeneratedCode: 'Booking number',
            guestYomigana: 'Yomigana',
            guestFullName: 'Guest name',
        },
    },
    advanceSearchForm: {
        buttonSearchText: 'Search',
        autoGeneratedCode: {
            label: 'Booking ID',
            placeholder: 'Enter booking ID',
        },
        planIds: {
            label: 'Plan',
            placeholder: 'Select plan',
        },
        marketingChannelIds: {
            label: 'Marketing channel',
            placeholder: 'Select marketing channel',
            defaultValue: 'Walk in',
        },
        roomBookingItemStatus: {
            label: 'Status',
            placeholder: 'Select status',
        },
        guestYomigana: {
            label: 'Guest name (yomigana)',
            placeholder: 'Enter guest name',
        },
        guestMobilePhoneNumber: {
            label: 'Phone number',
            placeholder: 'Enter phone number',
        },
        receptionPeriod: {
            label: 'Date',
            placeholder: 'Select date',
        },
        stayPeriod: {
            label: 'Duration of stay',
            placeholder: 'Select date',
        },
        endDatePeriod: {
            label: 'Check out',
            placeholder: {
                start: 'Start time',
                end: 'End time',
                selectDate: 'Select date',
            },
        },
        startDatePeriod: {
            label: 'Check in',
            placeholder: {
                start: 'Start time',
                end: 'End time',
                selectDate: 'Select date',
            },
        },
        memo: {
            label: 'OTA memo',
            placeholder: 'Enter memo',
        },
    },
    message: {
        bulkDeleteSuccess: 'Delete room booking success',
        deleteByStatusError: 'The following room bookings could not be deleted',
        titleDelete: 'Error',
        cannotCheckIn: 'The following room bookings could not be checked in',
        checkoutSplittedBookingsError:
            'Splitted bookings have booking with future checkout date',
    },
    exportColumns: {
        autoGeneratedCode: 'Booking ID',
        yomigana: 'Yomigana',
        fullname: 'Guest name',
        roomName: 'Room name',
        category: 'Category',
        bookingDate: 'Reservation date',
        marketingChannel: 'Marketing channel',
        plan: 'Plan',
        status: 'Status',
        checkInTime: 'C/I',
        checkOutTime: 'C/O',
        cleaningStatus: 'Cleaning',
        payment: 'Payment',
        price: 'Unpaid amount',
        memo: 'Memo',
    },
    modalConfirmDeletion: {
        deleteButton: 'Delete',
        cancelButton: 'Cancel',
    },
    checkoutDialog: {
        checkoutSuccess: 'Status has been changed to checked out',
        unpaidTitle: 'Cannot settle due to unpaid amount',
        content: 'Please go to booking details and settle',
        okText: 'Go to booking details',
        cancelText: 'Cancel',
    },
    preparationListForm: {
        title: 'Preparation list',
        startDate: 'Start date',
        endDate: 'End date',
        saleItem: 'Sales item',
        status: 'Status',
        displayedContentMode: {
            label: 'Displayed content',
            total: 'Total',
            perRoom: 'per room',
        },
        searchButton: 'Search',
        downloadButton: 'Download',
        printButton: 'Print',
        column: {
            date: 'Date',
            roomName: 'Room name',
            guestName: 'Guest name',
            saleItemName: 'Sales item',
            quantity: 'Quantity',
            price: 'Price',
        },
        cancelTitle: 'Do you want to cancel?',
        allOption: 'All',
        exportColumns: {
            date: 'Date',
            roomName: 'Room name',
            guestName: 'Guest name',
            saleItemName: 'Sales item',
            quantity: 'Quantity',
            price: 'Price',
        },
    },
};

export default roomBookingList;
