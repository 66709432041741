import React from 'react';
import './LoginPage.scss';
import { Layout } from 'antd';
import LoginForm from '~features/auth/components/LoginForm/LoginForm';
const { Content } = Layout;

function LoginPage() {
    return (
        <Layout>
            <Content>
                <div className="wrapper-login-form">
                    <LoginForm />
                </div>
            </Content>
        </Layout>
    );
}

export default LoginPage;
