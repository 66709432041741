const facilityTypeForm = {
    title: '新しい設備タイプの登録',
    updateTitle: '設備タイプの編集',
    name: {
        label: '表示名',
        placeholder: '例：シングル',
    },
    abbreviation: {
        label: '略称',
        placeholder: '例: S',
    },
    basicUsageTime: {
        label: '基本利用時間',
        placeholder: '基本利用時間',
    },
    businessTime: {
        label: '営業時間',
        placeholder: {
            start: '開始時間',
            end: '終了時間',
        },
    },
    salesItem: {
        label: '売上科目',
        placeholder: '科目を選択',
    },
    flatRate: {
        label: '定額料金',
        placeholder: '1000',
    },
    basicChargeHour: {
        label: '基本料金 /時間単位',
        placeholder: '1000円',
    },
    switchFixedAmount: {
        label: '定額',
    },
    timeUnit: '時間単位',
    hourUnit: '時',
    minutesUnit: '分',
    message: {
        create: {
            success: '施設タイプが作成されました',
            error: '施設タイプが作成できませんでした',
        },
        update: {
            success: '施設タイプが更新されました',
        },
        nameDuplicated: '名前が重複していまうす',
        abbreviationDuplicated: 'Abbreviation name already exists',
        durationError: '時間単位は入力必要科目です',
        businessTimesError: '終了時刻は開始時間より最低10分以降である必要があります',
    },
    buttons: {
        addSalesItem: '科目を追加',
        submit: '登録',
    },
    unitPrice: '円',
};

export default facilityTypeForm;
