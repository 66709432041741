import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    IBookingHistoryQuery,
    IGuestForm,
    IGuestGetDropDownListQuery,
    IGuestGetListQuery,
    IGuestState,
} from '../interfaces';
import { guestService } from '~features/guest/services/guest.service';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import {
    guestDefault,
    GuestPageTabPane,
    HistorySearchField,
    initFacilityBookingHistoryQuery,
    initGuestListQuery,
    initRoomBookingHistoryQuery,
    SearchField,
} from '../constants';
import trim from 'lodash/trim';
import cloneDeep from 'lodash/cloneDeep';

const initialState: IGuestState = {
    guestListQuery: cloneDeep(initGuestListQuery),
    guestList: [],
    keyword: '',
    totalGuests: 0,
    newGuest: {
        ...guestDefault,
    },
    updateGuest: {
        ...guestDefault,
    },
    showLoadingList: false,
    searchField: SearchField.ALL,
    countryDropdownOptions: [],
    selectedGuestDetail: null,
    countryList: [],
    guestListForDropDown: [],
    roomBookingHistory: null,
    roomBookingHistoryQuery: {
        ...initRoomBookingHistoryQuery,
    },
    showLoadingRoomBookingHistory: false,
    totalRoomBooking: 0,
    facilityBookingHistory: null,
    facilityBookingHistoryQuery: {
        ...initFacilityBookingHistoryQuery,
    },
    showLoadingFacilityBookingHistory: false,
    totalFacilityBooking: 0,
    bookingHistorySearchField: HistorySearchField.ALL,
    classificationList: [],
    isCreateOrUpdateLoading: false,
    activeGuestPageTabPane: GuestPageTabPane.BASIC_INFO_TAB_PANE,
    numberOfStayedGuest: 0,
    isShowMergeGuestsModal: false,
};

export const fetchGuestList = createAsyncThunk(
    'guest/fetchGuestList',
    async (_, { getState }) => {
        const query = guestListQuerySelector(getState() as AppState);
        return await guestService.getList(query);
    },
);

export const fetchGuestDetail = createAsyncThunk(
    'guest/fetchGuestDetail',
    async (id: number) => {
        return await guestService.getDetail(id);
    },
);

export const getGuestListForDropdown = createAsyncThunk(
    'guest/getListForDropdown',
    async (query: IGuestGetDropDownListQuery, { getState }) => {
        const keyword = keywordSelector(getState() as AppState);
        const { withRoomBooking = false } = query;
        return await guestService.getListForDropdown({ withRoomBooking, keyword });
    },
);

export const getCountryList = createAsyncThunk('guest/getCountryList', async () => {
    return await guestService.getCountryList();
});

export const createGuest = createAsyncThunk(
    'guest/createGuest',
    async (formData: IGuestForm | FormData) => {
        return await guestService.create(formData);
    },
);

export const updateGuest = createAsyncThunk(
    'guest/updateGuest',
    async ({ id, formData }: { id: number; formData: IGuestForm | FormData }) => {
        return await guestService.update(id, formData);
    },
);

export const getCountryListForDropdown = createAsyncThunk(
    'guest/getCountryListForDropdown',
    async () => {
        return await guestService.getCountryListForDropdown();
    },
);

export const getRoomBookingHistoryData = createAsyncThunk(
    'guest/getRoomBookingHistoryData',
    async (id: number, { getState }) => {
        const query = roomBookingHistoryQuerySelector(getState() as AppState);
        return await guestService.getRoomBookingHistoryData(id, query);
    },
);

export const getFacilityBookingHistoryData = createAsyncThunk(
    'guest/getFacilityBookingHistoryData',
    async (id: number, { getState }) => {
        const query = facilityBookingHistoryQuerySelector(getState() as AppState);
        return await guestService.getFacilityBookingHistoryData(id, query);
    },
);

export const getClassificationList = createAsyncThunk(
    'guest/getClassificationList',
    async () => {
        return await guestService.getClassificationList();
    },
);

export const getCountStayedGuest = createAsyncThunk(
    'guest/getCountStayedGuest',
    async (guestId: number) => {
        return await guestService.getCountStayedGuest(guestId);
    },
);

const guestSlice = createSlice({
    name: 'guest',
    initialState,
    reducers: {
        setGuestList: (state, action) => {
            state.guestList = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IGuestGetListQuery = {
                orderBy: state.guestListQuery.orderBy,
                limit: state.guestListQuery.limit,
                orderDirection: state.guestListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.guestListQuery = query;
        },
        setGuestListQuery: (state, action) => {
            state.guestListQuery = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IGuestGetListQuery = {
                orderBy: state.guestListQuery.orderBy,
                limit: state.guestListQuery.limit,
                orderDirection: state.guestListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.guestListQuery.keyword ||
                    state.guestListQuery.fullName ||
                    state.guestListQuery.phoneNumber ||
                    state.guestListQuery.email ||
                    state.guestListQuery.usedBookingTypes ||
                    '',
            };
            state.guestListQuery = query;
        },
        setRoomBookingHistoryQuery: (state, action) => {
            state.roomBookingHistoryQuery = action.payload;
        },
        setFacilityBookingHistoryQuery: (state, action) => {
            state.facilityBookingHistoryQuery = action.payload;
        },
        setBookingHistorySearchField: (state, action) => {
            state.bookingHistorySearchField = action.payload;
            const roomBookingQuery: IBookingHistoryQuery = {
                orderBy: state.roomBookingHistoryQuery.orderBy,
                limit: state.roomBookingHistoryQuery.limit,
                orderDirection: state.roomBookingHistoryQuery.orderDirection,
                page: state.roomBookingHistoryQuery.page,
                [state.bookingHistorySearchField || HistorySearchField.ALL]:
                    state.roomBookingHistoryQuery.keyword ||
                    state.roomBookingHistoryQuery.autoGeneratedCode ||
                    state.roomBookingHistoryQuery.roomName ||
                    '',
            };
            if (action.payload !== HistorySearchField.FACILITY_NAME) {
                state.roomBookingHistoryQuery = roomBookingQuery;
            }

            const facilityBookingQuery: IBookingHistoryQuery = {
                orderBy: state.facilityBookingHistoryQuery.orderBy,
                limit: state.facilityBookingHistoryQuery.limit,
                orderDirection: state.facilityBookingHistoryQuery.orderDirection,
                page: state.facilityBookingHistoryQuery.page,
                [state.bookingHistorySearchField || HistorySearchField.ALL]:
                    state.facilityBookingHistoryQuery.keyword ||
                    state.facilityBookingHistoryQuery.autoGeneratedCode ||
                    state.facilityBookingHistoryQuery.facilityName ||
                    '',
            };
            if (action.payload !== HistorySearchField.ROOM_NAME) {
                state.facilityBookingHistoryQuery = facilityBookingQuery;
            }
        },
        setBookingHistorySearchQuery: (state, action) => {
            const { checkInDateTimePeriod, checkOutDateTimePeriod } = action.payload;
            const keyword = trim(action.payload?.keyword || '');
            const roomBookingQuery: IBookingHistoryQuery = {
                orderBy: state.roomBookingHistoryQuery.orderBy,
                limit: state.roomBookingHistoryQuery.limit,
                orderDirection: state.roomBookingHistoryQuery.orderDirection,
                page: state.roomBookingHistoryQuery.page,
                [state.bookingHistorySearchField || HistorySearchField.ALL]: keyword,
                checkInDateTimePeriod,
                checkOutDateTimePeriod,
            };
            if (state.bookingHistorySearchField !== HistorySearchField.FACILITY_NAME) {
                state.roomBookingHistoryQuery = roomBookingQuery;
            }
            const facilityBookingQuery: IBookingHistoryQuery = {
                orderBy: state.facilityBookingHistoryQuery.orderBy,
                limit: state.facilityBookingHistoryQuery.limit,
                orderDirection: state.facilityBookingHistoryQuery.orderDirection,
                page: state.facilityBookingHistoryQuery.page,
                [state.bookingHistorySearchField || HistorySearchField.ALL]: keyword,
                checkInDateTimePeriod,
                checkOutDateTimePeriod,
            };
            if (state.bookingHistorySearchField !== HistorySearchField.ROOM_NAME) {
                state.facilityBookingHistoryQuery = facilityBookingQuery;
            }
        },
        setActiveGuestPageTabPane: (state, action) => {
            state.activeGuestPageTabPane = action.payload;
        },
        resetBookingHistorySearchQuery: (state) => {
            state.bookingHistorySearchField = HistorySearchField.ALL;
            state.roomBookingHistoryQuery = { ...initRoomBookingHistoryQuery };
            state.facilityBookingHistoryQuery = { ...initFacilityBookingHistoryQuery };
        },
        setSelectedGuestDetail: (state, action) => {
            state.selectedGuestDetail = action.payload;
        },
        setShowMergeGuestsModal: (state, action) => {
            state.isShowMergeGuestsModal = action.payload;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGuestList.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(fetchGuestList.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.guestList = action.payload?.data?.items || [];
            state.totalGuests = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(createGuest.pending, (state, action) => {
            state.isCreateOrUpdateLoading = true;
        });
        builder.addCase(createGuest.fulfilled, (state, action) => {
            state.isCreateOrUpdateLoading = false;
            state.newGuest = action.payload?.data || {
                ...guestDefault,
            };
        });
        builder.addCase(updateGuest.pending, (state, action) => {
            state.isCreateOrUpdateLoading = true;
        });
        builder.addCase(updateGuest.fulfilled, (state, action) => {
            state.isCreateOrUpdateLoading = false;
            state.updateGuest = action.payload?.data || {
                ...guestDefault,
            };
        });
        builder.addCase(getCountryListForDropdown.fulfilled, (state, action) => {
            state.countryDropdownOptions = (action.payload?.data?.items || []).map(
                (item) =>
                    ({
                        label: item.nameJp,
                        value: item.id,
                    } || []),
            );
        });
        builder.addCase(fetchGuestDetail.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(fetchGuestDetail.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.selectedGuestDetail = action.payload?.data || null;
        });
        builder.addCase(getCountryList.fulfilled, (state, action) => {
            state.countryList = action.payload?.data?.items || [];
        });
        builder.addCase(getGuestListForDropdown.fulfilled, (state, action) => {
            state.guestListForDropDown = action.payload?.data?.items || [];
        });
        builder.addCase(getRoomBookingHistoryData.pending, (state, action) => {
            state.showLoadingRoomBookingHistory = true;
        });
        builder.addCase(getRoomBookingHistoryData.fulfilled, (state, action) => {
            state.showLoadingRoomBookingHistory = false;
            state.roomBookingHistory = action.payload?.data || null;
            state.totalRoomBooking = action.payload?.data?.bookings?.totalItems || 0;
        });
        builder.addCase(getFacilityBookingHistoryData.pending, (state, action) => {
            state.showLoadingFacilityBookingHistory = true;
        });
        builder.addCase(getFacilityBookingHistoryData.fulfilled, (state, action) => {
            state.showLoadingFacilityBookingHistory = false;
            state.facilityBookingHistory = action.payload?.data || null;
            state.totalFacilityBooking = action.payload?.data?.bookings?.totalItems || 0;
        });
        builder.addCase(getClassificationList.fulfilled, (state, action) => {
            state.classificationList = action.payload?.data?.items || [];
        });
        builder.addCase(getCountStayedGuest.fulfilled, (state, action) => {
            state.numberOfStayedGuest = action.payload?.data || 0;
        });
    },
});

export const {
    setGuestList,
    setGuestListQuery,
    setSearchField,
    setKeyword,
    setRoomBookingHistoryQuery,
    setFacilityBookingHistoryQuery,
    setBookingHistorySearchField,
    setBookingHistorySearchQuery,
    resetBookingHistorySearchQuery,
    setActiveGuestPageTabPane,
    setSelectedGuestDetail,
    setShowMergeGuestsModal,
    resetState,
} = guestSlice.actions;

export const keywordSelector = (state: AppState) => {
    return state.guest.keyword;
};

export const guestSelector = (state: AppState) => state.guest;

export const guestListSelector = (state: AppState) => {
    return state.guest.guestList;
};

export const guestListQuerySelector = (state: AppState) => {
    return state.guest.guestListQuery;
};

export const showLoadingListSelector = (state: AppState) => {
    return state.guest.showLoadingList;
};

export const totalGuestSelector = (state: AppState) => {
    return state.guest.totalGuests;
};

export const selectedGuestDetailSelector = (state: AppState) => {
    return state.guest.selectedGuestDetail;
};

export const countryListlSelector = (state: AppState) => {
    return state.guest.countryList;
};

export const totalPageSelector = (state: AppState) => {
    const { totalGuests, guestListQuery } = state.guest;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = guestListQuery;
    return Math.ceil(totalGuests / limit);
};

export const countryDropdownSelector = (state: AppState) => {
    return state.guest.countryDropdownOptions;
};

export const guestListForDropDownSelector = (state: AppState) => {
    return state.guest.guestListForDropDown;
};

export const getRoomBookingHistoryDataSelector = (state: AppState) => {
    return state.guest.roomBookingHistory;
};

export const getFacilityBookingHistoryDataSelector = (state: AppState) => {
    return state.guest.facilityBookingHistory;
};

export const roomBookingHistoryQuerySelector = (state: AppState) => {
    return state.guest.roomBookingHistoryQuery;
};

export const facilityBookingHistoryQuerySelector = (state: AppState) => {
    return state.guest.facilityBookingHistoryQuery;
};

export const showLoadingRoomBookingHistorySelector = (state: AppState) => {
    return state.guest.showLoadingRoomBookingHistory;
};

export const totalRoomBookingSelector = (state: AppState) => {
    return state.guest.totalRoomBooking;
};

export const showLoadingFacilityBookingHistorySelector = (state: AppState) => {
    return state.guest.showLoadingFacilityBookingHistory;
};

export const totalFacilityBookingSelector = (state: AppState) => {
    return state.guest.totalFacilityBooking;
};

export const classificationListSelector = (state: AppState) => {
    return state.guest.classificationList;
};

export const numberOfStayedGuestSelector = (state: AppState) => {
    return state.guest.numberOfStayedGuest;
};

export const showMergeGuestsModal = (state: AppState) => {
    return state.guest.isShowMergeGuestsModal;
};

export default guestSlice.reducer;
