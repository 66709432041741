export const paymentMethodFormJa = {
    title: '入金科目の編集',
    confirm: {
        buttonDeleteText: 'はい',
        buttonCancelText: 'いいえ',
        cancelTitle: 'キャンセルしてもよろしいですか？',
    },
    deleteConfirm: {
        cancelText: 'キャンセル',
        deleteText: '削除',
    },
    createForm: {
        title: '入金科目の登録',
        createTextButton: '登録',
        create: {
            success: '入金科目が登録されました',
            failed: '入金科目が登録できませんでした',
        },
    },
    updateForm: {
        title: '入金科目の編集',
        updateTextButton: '保存',
        update: {
            success: '入金科目が更新されました',
            failed: '入金科目が更新できませんでした',
        },
    },
    name: '入金科目名',
    id: 'ID',
};
