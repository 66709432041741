const guestCreate = {
    title: 'ゲスト情報の作成',
    breadcrumbs: {
        home: 'ホーム',
        create: 'ゲスト情報の作成',
    },
    tabPane: {
        basicInfo: '基本情報',
        companyInfo: '会社情報',
    },
};

export default guestCreate;
