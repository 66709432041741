const saleGroupFormImport = {
    quantity: 'Quantity',
    id: '#',
    roomName: 'Room name',
    roomType: 'Room type',
    startRoomName: 'Starting room number',
    delete: 'Delete',
    generateRoomsBtn: 'Generate',
    deleteBtn: 'Room name',
    cancelBtn: 'Cancel',
    submitBtn: 'Save',
    addRoomBtn: 'Add a row',
    importBtn: 'Upload',
    createSuccessMessage: 'Sale Groups have been created successfully!',
    updateSuccessMessage: 'Sale Groups have been updated successfully!',
    importSuccessMessage: 'CSV file has been uploaded!',
    updateForm: {
        title: 'Edit room',
    },
    saleGroupNameDuplicated: 'Sale Group name is duplicated',
    cancelTitle: 'Do you want to cancel?',
    placeholder: {
        roomType: 'Select room type',
    },
    error: {
        name: {
            uniqueError: 'Sales group must be unique',
            required: 'Enter sales group',
            maxLength: 'Sales group must be at most {{max}} characters',
            hasNoSpecialCharacters: 'Sales group must contain no special characters',
        },
        saleItems: {
            required: 'Enter sales items',
            maxLength: 'Sales items must be at most {{max}} characters',
            duplicate: 'Sale item name is duplicated in group',
            hasNoSpecialCharacters: 'Sales items must contain no special characters',
        },
        key: {
            name: 'Sales group',
            saleItems: 'Sales item',
        },
    },
    showMore: 'and {{errorNumber}} more...',
    showLess: 'show less',
};

export default saleGroupFormImport;
