import { Button, Tag } from 'antd';
import {
    advanceSearchListQuerySelector,
    clearListQuery,
    facilityBookingAdvanceSearchFormSelector,
    setAdvanceSearchListQuery,
    setFacilityBookingAdvanceSearchForm,
    setIsAdvanceSearch,
    setIsShowAdvanceSearchTags,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { DeleteOutlined } from '@ant-design/icons';
import './AdvanceSearchTags.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
    IFacilityBookingAdvanceSearchForm,
    ISearchTag,
} from '~features/facility-booking/interfaces';
import {
    FacilityBookingAdvanceSearchFields,
    UsedBookingTypeOfGuest,
} from '~features/facility-booking/constants';
import { facilityDropDownOptionsSelector } from '~features/facility/facility.reducer';

function AdvanceSearchTags() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [advanceSearchTags, setAdvanceSearchTags] = useState<ISearchTag[]>([]);
    const advanceSearchListQuery = useAppSelector(advanceSearchListQuerySelector);
    const advanceSearchForm = useAppSelector(facilityBookingAdvanceSearchFormSelector);
    const facilityDropDownOptions = useAppSelector(facilityDropDownOptionsSelector);

    useEffect(() => {
        if (!advanceSearchForm) return;
        let searchTags: ISearchTag[] = [];
        // generate search tag array to advance search list query
        Object.values(FacilityBookingAdvanceSearchFields).forEach((field: string) => {
            const advanceSearch =
                advanceSearchForm[field as keyof IFacilityBookingAdvanceSearchForm];
            switch (field) {
                case FacilityBookingAdvanceSearchFields.AUTO_GENERATED_CODE:
                case FacilityBookingAdvanceSearchFields.GUEST_YOSIGANA:
                case FacilityBookingAdvanceSearchFields.GUEST_PHONE_NUMBER:
                    if (advanceSearch) {
                        searchTags.push({
                            field,
                            value: advanceSearch as string,
                        });
                    }
                    break;

                case FacilityBookingAdvanceSearchFields.STATUS:
                    if (advanceSearch && advanceSearch.length > 0) {
                        const tags = (advanceSearch as string[]).map((tag) => {
                            return {
                                field,
                                value: t(`facilityBooking.list.bookingStatus.${tag}`),
                                id: tag,
                            };
                        });
                        searchTags = [...searchTags, ...tags];
                    }
                    break;
                case FacilityBookingAdvanceSearchFields.USED_BOOKING_TYPE_OF_GUEST:
                    if (advanceSearch && advanceSearch !== UsedBookingTypeOfGuest.ALL) {
                        searchTags.push({
                            field,
                            value: t(
                                `facilityBooking.list.guestClassification.${advanceSearch}`,
                            ),
                            id: advanceSearch as string,
                        });
                    }
                    break;

                case FacilityBookingAdvanceSearchFields.FACILITY_IDS:
                    if (advanceSearch && advanceSearch.length > 0) {
                        const tags = (advanceSearch as number[]).map((tag) => {
                            const facility = facilityDropDownOptions.find((option) => {
                                return option.value === +tag;
                            });
                            return {
                                field,
                                value: facility?.label || '',
                                id: facility?.value as number,
                            };
                        });
                        searchTags = [...searchTags, ...tags];
                    }
                    break;

                case FacilityBookingAdvanceSearchFields.BOOKING_DATE_PERIOD:
                case FacilityBookingAdvanceSearchFields.BOOKING_TIME_PERIOD:
                    if (advanceSearch && advanceSearch.length > 0) {
                        searchTags.push({
                            field,
                            value: `${advanceSearch[0]} ~ ${advanceSearch[1]}`,
                        });
                    }

                    break;
                default:
                    break;
            }
        });
        setAdvanceSearchTags(searchTags);
    }, [advanceSearchForm]);

    const removeTag = (selectedTag: ISearchTag) => {
        const searchTags = advanceSearchTags.filter((searchTag) => {
            if (
                selectedTag.field === searchTag.field &&
                selectedTag.id === searchTag.id
            ) {
                return false;
            }
            return true;
        });

        if (searchTags.length === 0) {
            dispatch(setIsShowAdvanceSearchTags(false));
            dispatch(setIsAdvanceSearch(false));
        }
        setAdvanceSearchTags([]);
        // update advance search list query
        let updateAdvanceSearchQuery;
        switch (selectedTag.field) {
            case FacilityBookingAdvanceSearchFields.AUTO_GENERATED_CODE:
            case FacilityBookingAdvanceSearchFields.USED_BOOKING_TYPE_OF_GUEST:
            case FacilityBookingAdvanceSearchFields.GUEST_YOSIGANA:
            case FacilityBookingAdvanceSearchFields.GUEST_PHONE_NUMBER:
                updateAdvanceSearchQuery = { [selectedTag.field]: undefined };
                break;

            case FacilityBookingAdvanceSearchFields.STATUS:
                const status = advanceSearchForm?.status?.filter((status) => {
                    return status !== selectedTag.id;
                });
                updateAdvanceSearchQuery = { status };
                break;

            case FacilityBookingAdvanceSearchFields.FACILITY_IDS:
                const facilityIds = advanceSearchForm?.facilityIds?.filter(
                    (facilityId) => {
                        return +facilityId !== selectedTag.id;
                    },
                );
                updateAdvanceSearchQuery = { facilityIds };
                break;

            case FacilityBookingAdvanceSearchFields.BOOKING_DATE_PERIOD:
            case FacilityBookingAdvanceSearchFields.BOOKING_TIME_PERIOD:
                updateAdvanceSearchQuery = { [selectedTag.field]: [] };
                break;
            default:
                break;
        }
        const query = {
            ...advanceSearchListQuery,
            ...updateAdvanceSearchQuery,
        };
        dispatch(setAdvanceSearchListQuery(query));
        dispatch(
            setFacilityBookingAdvanceSearchForm({
                ...advanceSearchForm,
                ...updateAdvanceSearchQuery,
            }),
        );
    };

    const removeAllFilter = () => {
        dispatch(clearListQuery());
        setAdvanceSearchTags([]);
    };

    return (
        <div className="search-tag-wrapper">
            <p className="search-label">
                {t('roomBooking.list.advanceSearchForm.buttonSearchText')}:
            </p>
            <div className="search-tags">
                {advanceSearchTags.map((tag) => {
                    return (
                        <Tag
                            className="search-tag"
                            closable
                            key={`${tag.field}_${tag.value}`}
                            onClose={() => removeTag(tag)}
                        >
                            {`${t(
                                `facilityBooking.list.advanceSearchForm.${tag.field}.label`,
                            )}: ${tag.value}`}
                        </Tag>
                    );
                })}
                <Button
                    type="text"
                    danger
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={removeAllFilter}
                >
                    {t('roomBooking.list.filterForm.buttonRemoveFilter')}
                </Button>
            </div>
        </div>
    );
}

export default AdvanceSearchTags;
