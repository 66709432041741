import { UploadOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { uniq } from 'lodash';
import trim from 'lodash/trim';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INPUT_TEXT_MAX_LENGTH, Regex } from '~common/constants';
import { InputText, ImportCsvError } from '~components';
import { MAX_MARKETING_CHANNEL_NAME } from '~features/marketing-channel/constants';
import {
    ABBREVIATION_MAX_LENGTH,
    MAX_STANDARD_CAPACITY,
    ROOM_TYPE_NAME_MAX_LENGTH,
} from '~features/room-type/constants';
import { IImportRoomTypeBodyItem } from '~features/room-type/interfaces';
import {
    hasImportErrorSelector,
    importCsvFileNameSelector,
    importRoomTypeListSelector,
    setHasImportError,
} from '~features/room-type/room-type.reducer';
import { IImportError } from '~features/sale-group/interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './ImportCsvRoomType.scss';

interface IProp {
    responseError: Record<number, any>;
}

function ImportCsvRoomType(prop: IProp) {
    const { responseError } = prop;
    const { control } = useForm({});
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const hasError = useAppSelector(hasImportErrorSelector);
    const [errors, setError] = useState<Record<number, any>>({});
    const importRoomTypeList = useAppSelector(importRoomTypeListSelector);
    const importCsvFileName = useAppSelector(importCsvFileNameSelector);

    useEffect(() => {
        setError(responseError);
    }, [responseError]);

    useEffect(() => {
        validateData();
    }, []);

    const validateData = () => {
        let hasError = false;
        let errors = {};

        importRoomTypeList.forEach((roomType) => {
            const nameError = validateName(roomType?.name, roomType?.order);
            const roomTypeCodeError = validateRoomTypeCode(
                roomType?.roomTypeCode,
                roomType?.order,
            );
            const abbreviationError = validateAbbreviation(
                roomType?.abbreviation,
                roomType?.order,
            );
            const standardCapacityError = validateStandardCapacity(
                roomType?.standardCapacity,
            );
            const marketingChannelError = validateMarketingChannels(
                roomType.marketingChannels,
            );
            errors = {
                ...errors,
                [roomType?.order]: {
                    name: nameError,
                    roomTypeCode: roomTypeCodeError,
                    abbreviation: abbreviationError,
                    standardCapacity: standardCapacityError,
                    marketingChannel: marketingChannelError,
                },
            };
            if (
                nameError ||
                roomTypeCodeError ||
                abbreviationError ||
                standardCapacityError ||
                marketingChannelError
            ) {
                hasError = true;
            }
        });

        dispatch(setHasImportError(hasError));
        setError(errors);
    };

    const validateName = (name: string, order: number) => {
        if (!trim(name)) {
            return t('roomType.import.error.name.required');
        }
        if (name?.length > INPUT_TEXT_MAX_LENGTH) {
            return t('roomType.import.error.name.maxLength', {
                maxLength: INPUT_TEXT_MAX_LENGTH,
            });
        }
        if (
            importRoomTypeList.some(
                (roomType) => roomType.name === name && roomType?.order !== order,
            )
        ) {
            return t('roomType.import.error.name.unique');
        }
        if (name.match(Regex.GENERAL_SPECIAL_CHARACTERS)) {
            return t('roomType.import.error.name.hasNoSpecialCharacters');
        }
        return '';
    };

    const validateRoomTypeCode = (roomTypeCode: string, order: number) => {
        if (!trim(roomTypeCode)) {
            return t('roomType.import.error.roomTypeCode.required');
        }
        if (roomTypeCode?.length > INPUT_TEXT_MAX_LENGTH) {
            return t('roomType.import.error.roomTypeCode.maxLength', {
                maxLength: INPUT_TEXT_MAX_LENGTH,
            });
        }
        if (
            importRoomTypeList.some(
                (roomType) =>
                    roomType.roomTypeCode === roomTypeCode && roomType?.order !== order,
            )
        ) {
            return t('roomType.import.error.roomTypeCode.unique');
        }
        return '';
    };

    const validateAbbreviation = (abbreviation: string, order: number) => {
        if (!trim(abbreviation)) {
            return t('roomType.import.error.abbreviation.required');
        }
        if (abbreviation?.length > INPUT_TEXT_MAX_LENGTH) {
            return t('roomType.import.error.abbreviation.maxLength', {
                maxLength: INPUT_TEXT_MAX_LENGTH,
            });
        }
        if (
            importRoomTypeList.some(
                (roomType) =>
                    roomType?.abbreviation === abbreviation && roomType?.order !== order,
            )
        ) {
            return t('roomType.import.error.abbreviation.unique');
        }
        return '';
    };

    const validateStandardCapacity = (standardCapacity: number | string) => {
        if (!trim(standardCapacity as string) && standardCapacity !== 0) {
            return t('roomType.import.error.standardCapacity.required');
        }
        if (standardCapacity > MAX_STANDARD_CAPACITY) {
            return t('roomType.import.error.standardCapacity.max', {
                max: MAX_STANDARD_CAPACITY,
            });
        }
        if (standardCapacity <= 0) {
            return t('roomType.import.error.standardCapacity.min');
        }
        if (typeof standardCapacity === 'string') {
            return t('roomType.import.error.standardCapacity.number');
        }
        return '';
    };

    const validateMarketingChannels = (names?: string[]) => {
        if (!names?.length) return '';

        if (names.length !== uniq(names).length) {
            return t('roomType.import.error.marketingChannel.unique');
        }

        if (names.some((name) => name.length > INPUT_TEXT_MAX_LENGTH)) {
            return t('roomType.import.error.marketingChannel.maxLength', {
                max: INPUT_TEXT_MAX_LENGTH,
            });
        }
        if (names?.some((name) => name.match(Regex.GENERAL_SPECIAL_CHARACTERS))) {
            return t('roomType.import.error.marketingChannel.hasNoSpecialCharacters');
        }
        return '';
    };

    const importRoomTypeColumn: ColumnsType<IImportRoomTypeBodyItem> = [
        {
            title: t('roomType.list.roomColumn.order'),
            width: '60px',
            onCell: () => {
                return {
                    style: { backgroundColor: '#E6F6F9' },
                };
            },
            render: (roomType: IImportRoomTypeBodyItem) => {
                return <div>{roomType?.order}</div>;
            },
        },
        {
            title: t('roomType.list.roomColumn.type'),
            onCell: (roomType: IImportRoomTypeBodyItem) => {
                if (errors[roomType?.order]?.name) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
            render: (roomType: IImportRoomTypeBodyItem) => {
                return <div>{roomType?.name}</div>;
            },
            ellipsis: true,
        },
        {
            title: t('roomType.list.roomColumn.roomTypeCode'),
            onCell: (roomType: IImportRoomTypeBodyItem) => {
                if (errors[roomType?.order]?.roomTypeCode) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
            render: (roomType: IImportRoomTypeBodyItem) => {
                return <div>{roomType?.roomTypeCode}</div>;
            },
            ellipsis: true,
        },
        {
            title: t('roomType.list.roomColumn.abbreviation'),
            onCell: (roomType: IImportRoomTypeBodyItem) => {
                if (errors[roomType.order]?.abbreviation) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
            render: (roomType: IImportRoomTypeBodyItem) => {
                return <div>{roomType?.abbreviation}</div>;
            },
            ellipsis: true,
        },
        {
            title: t('roomType.list.roomColumn.standardCapacity'),
            onCell: (roomType: IImportRoomTypeBodyItem) => {
                if (errors[roomType.order]?.standardCapacity) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
            render: (roomType: IImportRoomTypeBodyItem) => {
                return <div>{roomType?.standardCapacity}</div>;
            },
        },
        {
            title: t('roomType.list.roomColumn.marketingChannel'),
            onCell: (roomType: IImportRoomTypeBodyItem) => {
                if (errors[roomType.order]?.marketingChannel) {
                    return {
                        style: { backgroundColor: '#F9ECEC' },
                    };
                } else {
                    return {};
                }
            },
            render: (roomType: IImportRoomTypeBodyItem) => {
                return (
                    <div>
                        <ul style={{ listStyle: 'none', padding: 0, marginBottom: 0 }}>
                            {roomType?.marketingChannels?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                );
            },
            ellipsis: true,
        },
    ];

    const errorList = useMemo(() => {
        const _errorItems: IImportError[] = [];
        Object.keys(errors)?.forEach((order) => {
            Object.keys(errors[+order])?.forEach((key) => {
                if (errors[+order]?.[key]) {
                    _errorItems.push({
                        order: +order,
                        key,
                        message: errors[+order]?.[key],
                    });
                }
            });
        });
        return _errorItems;
    }, [errors]);

    return (
        <div>
            <div className="import-csv-room-type-header">
                <div className="import-csv-room-type-file-name-wrapper">
                    <InputText
                        label={t('common.importCsv.title')}
                        name="title"
                        control={control}
                        required
                        onKeyUp={(e) => {
                            e.preventDefault();
                        }}
                        disabled
                    />
                    <div className="file-name">
                        <UploadOutlined />
                        <span className="file-name-content">{importCsvFileName}</span>
                    </div>
                </div>

                {hasError && (
                    <ImportCsvError
                        errors={errorList}
                        i18nErrorKey={'roomType.import.importErrorKey'}
                    />
                )}

                {importRoomTypeList?.length !== 0 && (
                    <div className="import-csv-room-type-total-data">
                        {t('common.importCsv.numData', {
                            totalData: importRoomTypeList?.length,
                        })}
                    </div>
                )}
            </div>
            <Table
                columns={importRoomTypeColumn}
                dataSource={importRoomTypeList}
                pagination={false}
                className="import-csv-room-type-table"
                rowKey="order"
            />
        </div>
    );
}

export default ImportCsvRoomType;
