import { Tooltip } from 'antd';

type Props = {
    text: string;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    maxLength?: number;
    key?: string | number;
    className?: string[];
};

export const TextTruncate = ({ text, placement, maxLength, key, className }: Props) => {
    const formatTitle = (text: string, maxLength?: number) => {
        if (maxLength && text.length > maxLength) {
            const newTitle = text.slice(0, maxLength);
            return <>{`${newTitle}...`}</>;
        }
        return <>{text}</>;
    };
    if (text) {
        return (
            <Tooltip placement={placement} title={formatTitle(text, maxLength)}>
                <div key={key} className={'text-truncate ' + className?.join(' ')}>
                    {text}
                </div>
            </Tooltip>
        );
    } else {
        return <div className={className?.join(' ')} />;
    }
};

TextTruncate.defaultProps = {
    placement: 'left',
};
