const taxList = {
    title: 'Tax setting',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        tax: 'Tax setting',
    },
    header: {
        title: 'Please set up accommodation tax and hot bath tax',
        button: {
            save: 'Save',
        },
    },
    columns: {
        name: 'Tax name',
        isActive: 'Apply',
        displayName: 'Displayed name',
    },
    taxType: {
        bathTax1: 'Hot bath (other)',
        bathTax2: 'Hot bath (adult)',
        bathTax3: 'Hot bath (children)',
        bathTax4: 'Hot bath (day-use)',
        bathTax5: 'Accommodation tax(Kyoto0)',
        bathTax6: 'Accommodation tax(Kyoto20000)',
        bathTax7: 'Accommodation tax(Kyoto50000)',
        bathTax8: 'Accommodation tax(Osaka15000)',
        bathTax9: 'Accommodation tax(Osaka20000)',
        bathTax10: 'Accommodation tax(Osaka7000)',
        bathTax11: 'Accommodation tax(Tokyo10000)',
        bathTax12: 'Accommodation tax(Tokyo15000)',
        bathTax13: 'Accommodation tax(Fukuokacity0)',
        bathTax14: 'Accommodation tax(Fukuokacity20000)',
        bathTax15: 'Accommodation tax(Fukuokaprefecture))',
        bathTax16: 'Accommodation tax(Kanazawa0)',
        bathTax17: 'Accommodation tax(Kanazawa20000)',
        localTax1: 'Accommodation tax(Tokyo10000)',
        localTax2: 'Accommodation tax(Tokyo15000)',
        tax1: 'Consumption tax(10%)',
        tax2: 'Consumption tax(8%)',
        tax3: 'Consumption tax(0%)',
        kutchanTax: 'Accommodation Tax (Kutchan-cho, Hokkaido)',
    },
    taxTypeDescription: {
        bathTax1:
            '※ Additional items will be added to the details, but please manually enter the amounts',
        bathTax2: '※ An automatic addition of 150 yen will be included in the details',
        bathTax3:
            '※ Items will be added to the details, but please enter the amounts manually',
        bathTax4:
            '※ An automatic addition of 75 yen will be included in the details of the day use reservation',
        bathTax15:
            '※ Please select here for areas other than Fukuoka City, including Kitakyushu City',
        bathTax16: '※ Applies to stays after April 1, 2019',
        bathTax17: '※ Applies to stays after April 1, 2019',
    },
    update: {
        nameDuplicated: 'Name is duplicated',
        success: 'Tax setting has been updated successfully.',
        failed: 'Update tax setting failed',
    },
};

export default taxList;
