import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { useAppDispatch, useAppSelector } from '~hooks';
import { useMergeGuests } from '~features/guest/hooks/useMergeGuests';
import { fetchGuestList, setShowMergeGuestsModal, showMergeGuestsModal } from '~features/guest/reducers/guest.reducer';
import { IGuest } from '~features/guest/interfaces';
import { GuestColumn } from '~features/guest/constants';
import { parseDateTime } from '~common/commonFunctions';
import { DateFormat } from '~common/constants';

import './MergeGuestsModal.scss';

type Props = {
    selectedGuests: IGuest[];
    setSelectedRowKeys: (keys: number[]) => void;
};

function MergeGuestsModal({ selectedGuests, setSelectedRowKeys }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowMergeGuestsModal = useAppSelector(showMergeGuestsModal);
    const [primaryGuestId, setPrimaryGuestId] = useState<number>(0);
    const { isPending, mutateAsync } = useMergeGuests();

    useEffect(() => {
        if (selectedGuests?.length && !primaryGuestId) {
            setPrimaryGuestId(selectedGuests[0].id);
        }
    }, [selectedGuests]);

    const onSubmit = () => {
        mutateAsync({
            primaryGuestId,
            guestIds: selectedGuests.map(guest => guest.id),
        })
        .then(() => {
            dispatch(fetchGuestList());
            setSelectedRowKeys([]);
            onClose();
        });
    };

    const onClose = () => {
        dispatch(setShowMergeGuestsModal(false));
    };

    const columns: ColumnsType<IGuest> = [
        {
            title: t('guest.list.guestColumn.id'),
            key: GuestColumn.AUTO_GENERATED_CODE,
            width: 110,
            render: (guest: IGuest) => {
                return (
                    <span>
                        <a>{guest.autoGeneratedCode}</a>
                    </span>
                );
            },
        },
        {
            title: t('guest.list.guestColumn.yomigana'),
            key: GuestColumn.YOMIGANA,
            width: 200,
            dataIndex: GuestColumn.YOMIGANA,
            ellipsis: true,
        },
        {
            title: t('guest.list.guestColumn.fullName'),
            key: GuestColumn.NAME,
            width: 200,
            dataIndex: GuestColumn.NAME,
            ellipsis: true,
        },
        {
            title: t('guest.list.guestColumn.gender'),
            key: GuestColumn.GENDER,
            width: 80,
            dataIndex: GuestColumn.GENDER,
            render: (gender: string) => {
                return (
                    <span className="text-truncate">
                        {gender ? t(`guest.list.gender.${gender}`) : ''}
                    </span>
                );
            },
        },
        {
            title: t('guest.list.guestColumn.birthday'),
            key: GuestColumn.BIRTHDAY,
            width: 140,
            dataIndex: GuestColumn.BIRTHDAY,
            render: (birthday: string) => {
                return (
                    <span className="text-truncate">
                        {birthday
                            ? parseDateTime(birthday, DateFormat.YYYY_MM_DD_SLASH)
                            : ''}
                    </span>
                );
            },
        },
        {
            title: t('guest.list.guestColumn.mobilePhoneNumber'),
            key: GuestColumn.PHONE,
            width: 150,
            render: (item: IGuest) => {
                return (
                    <span className="text-truncate">
                        {item.mobilePhoneNumber || item.phoneNumberLandline || ''}
                    </span>
                );
            },
        },
        {
            title: t('guest.list.guestColumn.emailAddress'),
            key: GuestColumn.EMAIL,
            width: 230,
            ellipsis: true,
            dataIndex: GuestColumn.EMAIL,
        },
    ];

    return (
        <Modal
            className="merge-guest-modal-wrapper"
            title={t('guest.list.merge.mergeGuest')}
            open={isShowMergeGuestsModal}
            width={800}
            okText={t('guest.list.merge.ok')}
            cancelText={t('guest.list.merge.cancel')}
            onOk={onSubmit}
            onCancel={onClose}
            confirmLoading={isPending}
        >
            <div className="note-wrapper">
                <span className="instruction">{t('guest.list.merge.instruction')}</span>
                <span className="note">{`${t('guest.list.merge.note')}: `}</span>
                <span>{t('guest.list.merge.warning')}</span>
            </div>
            <Table
                columns={columns}
                dataSource={selectedGuests}
                pagination={false}
                rowKey="id"
                rowSelection={{
                    type: 'radio',
                    defaultSelectedRowKeys: [selectedGuests?.[0]?.id],
                    selectedRowKeys: [primaryGuestId],
                    onChange: (keys) => setPrimaryGuestId(Number(keys[0])),
                }}
                onRow={(record) => ({
                    onClick: () => setPrimaryGuestId(Number(record.id)),
                })}
                scroll={{ x: 'scroll' }}
                className="table-scrollbar"
            />
        </Modal>
    )
}

export default MergeGuestsModal;