import roomBookingListJa from './ja/room-booking-list.locale';
import bookingFormJa from './ja/booking-form.locale';
import { bookingPageJa } from './ja/booking-page.locale';
import roomBookingDetailJa from './ja/room-booking-detail.locale';
import createBookingJa from './ja/create-booking.locale';
import updateBookingJa from './ja/update-booking.locale';
import confirmPageJa from './ja/confirm-page.locale';
import { scheduleJa } from './ja/schedule.locale';
import roomBookingCheckinJa from './ja/room-booking-checkin.locale';
import { printingJa } from './ja/room-booking-printing.locale';
import splitReceiptJa from './ja/split-receipt.locale';
import prefecturesEn from './en/prefectures.locale';
import countriesEn from './en/countries.locale';

import roomBookingListEn from './en/room-booking-list.locale';
import bookingFormEn from './en/booking-form.locale';
import { bookingPageEn } from './en/booking-page.locale';
import roomBookingDetailEn from './en/room-booking-detail.locale';
import createBookingEn from './en/create-booking.locale';
import updateBookingEn from './en/update-booking.locale';
import confirmPageEn from './en/confirm-page.locale';
import { scheduleEn } from './en/schedule.locale';
import roomBookingCheckinEn from './en/room-booking-checkin.locale';
import { printingEn } from './en/room-booking-printing.locale';
import splitReceiptEn from './en/split-receipt.locale';
import prefecturesJa from './ja/prefectures.locale';
import countriesJa from './ja/countries.locale';

const roomBooking = {
    ja: {
        list: roomBookingListJa,
        form: bookingFormJa,
        page: bookingPageJa,
        detail: roomBookingDetailJa,
        createBooking: createBookingJa,
        updateBooking: updateBookingJa,
        schedule: scheduleJa,
        confirmPage: confirmPageJa,
        roomBookingCheckin: roomBookingCheckinJa,
        printing: printingJa,
        splitReceipt: splitReceiptJa,
        prefectures: prefecturesJa,
        countries: countriesJa,
    },
    en: {
        list: roomBookingListEn,
        form: bookingFormEn,
        page: bookingPageEn,
        detail: roomBookingDetailEn,
        createBooking: createBookingEn,
        updateBooking: updateBookingEn,
        schedule: scheduleEn,
        confirmPage: confirmPageEn,
        roomBookingCheckin: roomBookingCheckinEn,
        printing: printingEn,
        splitReceipt: splitReceiptEn,
        prefectures: prefecturesEn,
        countries: countriesEn,
    },
};

export default roomBooking;
