import { FullscreenOutlined, ImportOutlined } from '@ant-design/icons';
import { Button, Modal, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ScheduleViewMode } from '~common/constants';
import { BookingScheduleHeader } from '~components';
import {
    RoomBookingEvent,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import { getBookingScheduleDefault } from '~features/room-booking/model';
import {
    scheduleStateSelector,
    setCurrentBookingEditing,
    setCurrentDate,
    setCurrentStatus,
    setCurrentViewMode,
    setDateHeaderFilterUnassignForm,
    setIsShowUnassignList,
    setShowCreateBookingForm,
    setVisibleCreateBookingPopover,
} from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { Dayjs, parseDate, todayDayjs } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import { AutoAssignModal } from '../AutoAssignModal/AutoAssignModal';
import { BookingTemporaryList } from '../BookingTemporaryList/BookingTemporaryList';
import { CheckinModal } from '../CheckinModal/CheckinModal';
import { ConfirmCloseBookingModal } from '../ConfirmCloseBookingModal/ConfirmCloseBookingModal';
import { DeletedBookingModal } from '../ConfirmCloseBookingModal/DeletedBookingModal';
import { ListBookingModal } from '../ConfirmCloseBookingModal/ListBookingModal';
import { CreateBookingButton } from '../CreateBookingButton/CreateBookingButton';
import { CreateModal } from '../CreateModal/CreateModal';
import { ScheduleDayView } from '../ScheduleDayView/ScheduleDayView';
import { ScheduleMonthView } from '../ScheduleMonthView/ScheduleMonthView';
import { ScheduleWeekView } from '../ScheduleWeekView/ScheduleWeekView';
import { UnassignListSidebar } from '../UnassignListSidebar/UnassignListSidebar';
import './ScheduleTabPane.scss';
import {
    getBookingUnassignedList,
    setUnassignRoomList,
} from '~features/room-management/reducers/room-management.reducer';
import dayjs from 'dayjs';

interface Props {
    height?: number;
    isExpandSchedule: boolean;
    setIsExpandSchedule: (isExpandSchedule: boolean) => void;
    dialogData: {
        bookings: IRoomBookingSchedule[];
        isOpen: boolean;
    };
    setDialogData: (dialogData: {
        bookings: IRoomBookingSchedule[];
        isOpen: boolean;
    }) => void;
    dialogDeletedData: {
        bookings: IRoomBookingSchedule[];
        isOpen: boolean;
    };
    setDialogDeletedData: (dialogDeletedData: {
        bookings: IRoomBookingSchedule[];
        isOpen: boolean;
    }) => void;
}

export const ScheduleTabPane = ({
    height,
    isExpandSchedule,
    setIsExpandSchedule,
    dialogData,
    setDialogData,
    dialogDeletedData,
    setDialogDeletedData,
}: Props) => {
    const { t } = useTranslation();
    const {
        currentDate,
        currentStatus,
        currentViewMode,
        bookingTemporaryList,
        isShowCreateBookingForm,
        currentBookingEditing,
        defaultBookingOptions,
    } = useAppSelector(scheduleStateSelector);
    const dispatch = useAppDispatch();
    const { emitter } = useMitt();

    async function getBookingUnassigned() {
        let start = '';
        let end = '';

        switch (currentViewMode) {
            case ScheduleViewMode.DAY:
                start = currentDate ?? todayDayjs.format('YYYY-MM-DD');
                end = currentDate ?? todayDayjs.format('YYYY-MM-DD');
                break;

            case ScheduleViewMode.WEEK:
                start = todayDayjs.format('YYYY-MM-DD');
                end = todayDayjs.add(1, 'week').format('YYYY-MM-DD');
                break;

            case ScheduleViewMode.MONTH:
                start = dayjs(currentDate).set('date', 1).format('YYYY-MM-DD');
                end = dayjs(currentDate).endOf('month').format('YYYY-MM-DD');
                break;

            default:
                break;
        }
        dispatch(setDateHeaderFilterUnassignForm([start, end]));
        const bookingsUnassign = await dispatch(getBookingUnassignedList({ start, end }));
        if (getBookingUnassignedList.fulfilled.match(bookingsUnassign)) {
            setUnassignRoomList(bookingsUnassign.payload.data.items);
        }
    }

    const onChangeDate = (value: Dayjs) => {
        dispatch(setCurrentDate(value.fmYYYYMMDD()));
        emitter.emit(RoomBookingEvent.CHANGE_DATE, value.fmYYYYMMDD());
    };
    const onChangeView = (value: ScheduleViewMode) => {
        dispatch(setCurrentViewMode(value));
    };

    const onChangeStatus = (value: RoomBookingItemBookingStatus) => {
        dispatch(setCurrentStatus(value));
    };

    const createReservation = () => {
        dispatch(setShowCreateBookingForm(true));
        dispatch(setVisibleCreateBookingPopover(false));
    };

    const onShowUnassignList = () => {
        dispatch(setIsShowUnassignList(true));
        getBookingUnassigned();
    };

    const handleCancel = () => {
        setDialogData({
            isOpen: false,
            bookings: [],
        });
    };
    const handleCancelDeletedModal = () => {
        setDialogDeletedData({
            isOpen: false,
            bookings: [],
        });
    };

    const scheduleBodyView = (props: { viewMode: ScheduleViewMode }) => {
        switch (props.viewMode) {
            case ScheduleViewMode.WEEK:
                return <ScheduleWeekView height={height} />;
            case ScheduleViewMode.MONTH:
                return <ScheduleMonthView />;
            case ScheduleViewMode.DAY:
                return <ScheduleDayView height={height} />;
        }
    };

    return (
        <div className="schedule-view-wrapper">
            <div className="schedule-tab-pane-header">
                <div className="header-section">
                    <span className="status-title">
                        {t('roomBooking.schedule.statusTitle')}:
                    </span>
                    <Select
                        onChange={onChangeStatus}
                        defaultValue={RoomBookingItemBookingStatus.ALL}
                        value={currentStatus}
                        className="mr-8"
                        options={Object.values(RoomBookingItemBookingStatus)
                            .filter(
                                (item) => item !== RoomBookingItemBookingStatus.CANCELLED,
                            )
                            .map((status) => {
                                return {
                                    value: status,
                                    label: t('roomBooking.page.bookingStatus.' + status),
                                };
                            })}
                    />
                </div>
                <BookingScheduleHeader
                    defaultValue={parseDate(currentDate)}
                    onChangeDate={onChangeDate}
                    onChangeView={onChangeView}
                    viewMode={currentViewMode}
                />
                <div className="header-section">
                    <CreateBookingButton createReservation={createReservation} />
                    <Button onClick={onShowUnassignList}>
                        <ImportOutlined />
                        {t('roomBooking.schedule.hover.unassignList')}
                    </Button>
                    {!isExpandSchedule && (
                        <Tooltip
                            title={t('roomBooking.schedule.hover.expandBookingList')}
                        >
                            <Button
                                onClick={() => setIsExpandSchedule(true)}
                                className="icon-button"
                            >
                                <FullscreenOutlined />
                            </Button>
                        </Tooltip>
                    )}
                </div>
            </div>
            {scheduleBodyView({
                viewMode: currentViewMode,
            })}
            {bookingTemporaryList.length > 0 && <BookingTemporaryList />}
            {isShowCreateBookingForm && (
                <Modal
                    centered
                    title=""
                    open={isShowCreateBookingForm}
                    footer={null}
                    closable={false}
                    className="create-schedule-modal"
                >
                    <CreateModal
                        booking={
                            currentBookingEditing
                                ? { ...currentBookingEditing }
                                : {
                                      ...getBookingScheduleDefault(),
                                      ...defaultBookingOptions,
                                  }
                        }
                        usePopover={false}
                        close={() => {
                            dispatch(setCurrentBookingEditing(null));
                            dispatch(setShowCreateBookingForm(false));
                        }}
                    />
                </Modal>
            )}
            <CheckinModal />
            <ConfirmCloseBookingModal
                isOpen={dialogData.isOpen}
                bookings={dialogData.bookings}
                handleCancel={handleCancel}
            />
            <DeletedBookingModal
                isOpen={dialogDeletedData.isOpen}
                bookings={dialogDeletedData.bookings}
                handleCancel={handleCancelDeletedModal}
            />
            <ListBookingModal />
            <UnassignListSidebar />
            <AutoAssignModal />
        </div>
    );
};
