const roomTypeForm = {
    createTitle: 'Create room type',
    updateTitle: 'Edit room type',
    primaryButton: 'Create room type',
    roomName: 'Displayed name',
    roomNamePlaceholder: 'Ex: Single',
    roomTypeCode: 'Room type code',
    roomTypeCodePlaceholder: 'Ex: S',
    abbreviation: 'Abbreviation',
    abbreviationPlaceholder: 'Ex: s',
    standardCapacity: 'Standard capacity',
    standardCapacityPlaceholder: 'Ex: 1',
    salesChannelTitle: 'Marketing channel',
    selectRoomType: 'Room type',
    selectRoomTypePlaceholder: 'Select room type',
    channels: 'Channel/ Channel group',
    linkCode: 'Linkage code',
    linkCodeUseDay: 'Linkage code (day-use))',
    codePlacehoder: 'Code',
    addition: 'Add',
    titleRoomSetting: 'Connecting room setting',
    other: 'Other',
    roomType: 'Room type',
    numberOfConnections: 'Number of connections',
    update: {
        error: 'Update room type error',
        success: 'Update room type success',
    },
    create: {
        success: 'Create room type success',
        error: 'Create room type error',
    },
    confirm: {
        cancelTitle: 'Do you want to cancel?',
    },
    placeholder: {
        roomType: 'Select room type',
    },
    createButton: 'Create',
};

export default roomTypeForm;
