import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { InputSearch } from '~components';
import './FilterForm.scss';
import SelectField from './SelectField/SelectField';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setKeyword } from '../../reducers/guest.reducer';
import { GA_KEYWORD_CONTENT, INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { trackSearchKeyword } from '~plugins/analytics';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.GUEST);
    };

    const createGuest = () => {
        navigate('/guest/create-guest');
    };
    return (
        <div className="filter-guest-list-wrapper">
            <div className="guest-list-left-block">
                <InputSearch
                    addonBefore={SelectField()}
                    onSearch={onSearch}
                    placeholder={t('common.searchPlaceholder')}
                    max={INPUT_TEXT_MAX_LENGTH}
                />
            </div>
            <div className="guest-list-btn">
                <Button type="primary" onClick={createGuest}>
                    <PlusOutlined />
                    <span className="btn-text">{t('guest.form.addBtn')}</span>
                </Button>
            </div>
        </div>
    );
}

export default FilterForm;
