import { Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkUserPermission } from '~common/commonFunctions';
import { UserGroup } from '~common/constants';
import { useRoomOptions } from '~common/useHooks';
import { SingleSelect } from '~components';
import { PlanRankStatus } from '~features/plan/constants';
import { planDropdownSelector } from '~features/plan/reducers/plan.reducer';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import { IRoomBookingItem } from '~features/room-booking/interfaces';
import { roomTypeListDropdownSelector } from '~features/room-type/room-type.reducer';
import { roomDropDownListSelector } from '~features/room/room.reducer';
import { useAppSelector } from '~hooks';

type Props = {
    control: any;
    setValue: any;
    bookingItem: IRoomBookingItem;
    isFromTll?: boolean;
};
function RoomRoomTypePlanRow(props: Props) {
    const { control, setValue, bookingItem, isFromTll } = props;
    const { t } = useTranslation();

    const [currentRoomType, setCurrentRoomType] = useState<number | null>(
        bookingItem.roomType?.id || null,
    );
    const [currentPlan, setCurrentPlan] = useState<number | null>(
        bookingItem.plan?.id || null,
    );

    const [roomOptions, isRoomOptionsLoading] = useRoomOptions(
        bookingItem,
        currentRoomType,
    );

    const roomDropDownList = useAppSelector(roomDropDownListSelector);
    const roomTypeDropdown = useAppSelector(roomTypeListDropdownSelector);
    const planDropDownList = useAppSelector(planDropdownSelector);

    const planDropdownActive = useMemo(() => {
        if (isFromTll) {
            return planDropDownList.filter(
                (option) =>
                    option.planRankStatus === PlanRankStatus.ACTIVE ||
                    option.value === bookingItem.plan?.id,
            );
        }
        return planDropDownList.filter(
            (option) => option.planRankStatus === PlanRankStatus.ACTIVE,
        );
    }, [planDropDownList, bookingItem, isFromTll]);

    const planOptionOfBookingItem = useMemo(() => {
        return planDropDownList.find((option) => option.value === bookingItem.plan?.id);
    }, [planDropDownList, bookingItem]);

    useEffect(() => {
        const isRoomExist = roomDropDownList.some(
            (room) => room.id === bookingItem.room?.id,
        );
        if (isRoomExist) {
            setValue('roomId', bookingItem.room?.id);
        }
    }, [roomDropDownList, setValue, bookingItem]);

    const roomTypeOptions = useMemo(() => {
        let _options = roomTypeDropdown.map((roomType) => {
            return {
                label: roomType.name,
                value: roomType.id,
            };
        });
        if (currentPlan) {
            const plan = planDropdownActive.find((option) => {
                return option.value === currentPlan;
            });
            _options = _options.filter((option) => {
                return plan?.roomTypeIds?.includes(Number(option.value));
            });
        }
        if (
            bookingItem.roomType?.id &&
            !_options.find((option) => option.value === bookingItem.roomType?.id)
        ) {
            _options.push({
                value: bookingItem.roomType?.id,
                label: bookingItem.roomType?.name,
            });
        }
        return _options;
    }, [roomTypeDropdown, currentPlan, planDropdownActive, bookingItem]);

    const planDropDownOptions = useMemo(() => {
        let _options = planDropdownActive;
        if (currentRoomType) {
            _options = planDropdownActive.filter((plan) => {
                return plan.roomTypeIds?.includes(currentRoomType);
            });
        }
        if (
            planOptionOfBookingItem &&
            !_options.find((item) => item.value === planOptionOfBookingItem.value)
        ) {
            _options.push(planOptionOfBookingItem);
        }
        return _options;
    }, [planDropdownActive, currentRoomType, planOptionOfBookingItem]);

    const { canEditRoomType, canEditRoom, canEditPlan } = useMemo(() => {
        // Allow ADMIN user to edit fields regardless the status
        const isAdmin = checkUserPermission([UserGroup.ADMIN]);
        const status = bookingItem.bookingStatus;
        const canEditRoom =
            isAdmin ||
            status === RoomBookingItemBookingStatus.NOT_ARRIVED ||
            status === RoomBookingItemBookingStatus.CHECKED_IN;

        const canEditRoomType =
            isAdmin || status === RoomBookingItemBookingStatus.NOT_ARRIVED;
        const canEditPlan =
            isAdmin || status === RoomBookingItemBookingStatus.NOT_ARRIVED;

        return { canEditRoomType, canEditRoom, canEditPlan };
    }, [bookingItem]);

    useEffect(() => {
        const selectedRoomType = roomTypeDropdown.find((roomType) => {
            return roomType.id === currentRoomType;
        });
        setValue('standardCapacity', selectedRoomType?.standardCapacity || 0);
    }, [currentRoomType, roomTypeDropdown, setValue]);

    return (
        <>
            {/* room type */}
            <Row>
                <Col span={24}>
                    {canEditRoomType ? (
                        <SingleSelect
                            label=""
                            name="roomTypeId"
                            placeholder={t(
                                'roomBooking.detail.bookingItemCard.roomTypeId.placeholder',
                            )}
                            control={control}
                            options={roomTypeOptions}
                            onChange={(value) => {
                                setCurrentRoomType(value);
                                setValue('roomId', null);
                            }}
                            allowClear={true}
                        />
                    ) : (
                        <span className="margin-bottom-row-20">
                            {bookingItem.roomType?.name}
                        </span>
                    )}
                </Col>
            </Row>
            {/* room */}
            <Row>
                <Col span={24}>
                    <SingleSelect
                        label=""
                        name="roomId"
                        placeholder={t(
                            'roomBooking.detail.bookingItemCard.roomId.placeholder',
                        )}
                        disabled={!canEditRoom}
                        control={control}
                        options={roomOptions}
                        allowClear={true}
                        loading={isRoomOptionsLoading}
                    />
                </Col>
            </Row>
            {/* plan */}
            <Row>
                <Col span={24}>
                    {canEditPlan ? (
                        <SingleSelect
                            label=""
                            name="planId"
                            placeholder={t(
                                'roomBooking.detail.bookingItemCard.planId.placeholder',
                            )}
                            control={control}
                            options={planDropDownOptions}
                            onChange={(value) => {
                                setCurrentPlan(value);
                            }}
                            allowClear={true}
                        />
                    ) : (
                        <>
                            {bookingItem.plan?.name && (
                                <span className="margin-bottom-row-20">
                                    {bookingItem.plan?.name ||
                                        t(`roomBooking.detail.receipt.plan.notExist`)}
                                </span>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </>
    );
}

export default RoomRoomTypePlanRow;
