/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, notification } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { showConfirm } from '~common/notification';
import { InputText } from '~components';
import {
    transformCreateRankCalendarFormData,
    transformPlanRankDurations,
} from '~features/rank-calendar/helper';
import {
    rankCalendarFormSelector,
    selectedRankCalendarDetailSelector,
    updateRankCalendar,
} from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './UpdateActionForm.scss';

interface IProp {
    control: any;
    setValue: any;
    getValues: any;
    handleSubmit: any;
}

function UpdateActionForm({ control, setValue, getValues, handleSubmit }: IProp) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rankCalendarDetail = useAppSelector(selectedRankCalendarDetailSelector);
    const rankCalendarForm = useAppSelector(rankCalendarFormSelector);

    useEffect(() => {
        setValue('name', rankCalendarDetail?.name);
    }, [rankCalendarDetail?.name]);

    const showConfirmCancel = () => {
        showConfirm({
            title: t('rankCalendar.form.confirm.cancelTitle'),
            onOk() {
                navigate(
                    `/front-settings/rank-calendar/${rankCalendarDetail?.id}/detail`,
                );
            },
        });
    };

    const onSubmit = async () => {
        if (rankCalendarDetail?.id && rankCalendarForm) {
            handleSubmit(async () => {
                const formData = {
                    name: getValues().name,
                    monday: rankCalendarForm.monday,
                    tuesday: rankCalendarForm.tuesday,
                    wednesday: rankCalendarForm.wednesday,
                    thursday: rankCalendarForm.thursday,
                    friday: rankCalendarForm.friday,
                    saturday: rankCalendarForm.saturday,
                    sunday: rankCalendarForm.sunday,
                    planRankDurations: transformPlanRankDurations(
                        rankCalendarForm.planRankDurations,
                    ),
                };
                const response = await dispatch(
                    updateRankCalendar({
                        id: rankCalendarDetail.id,
                        formData: transformCreateRankCalendarFormData(formData),
                    }),
                );
                if (updateRankCalendar.fulfilled.match(response)) {
                    if (response.payload?.success) {
                        notification.success({
                            message: t('rankCalendar.form.update.successMessage'),
                        });
                        navigate(
                            `/front-settings/rank-calendar/${rankCalendarDetail?.id}/detail`,
                        );
                        return;
                    }
                    notification.error({
                        message: t('rankCalendar.form.update.errorMessage'),
                        description: response.payload?.errors?.[0]?.message || '',
                    });
                }
            })();
        }
    };

    return (
        <div className="rank-calendar-update-action-form-wrapper">
            <div className="rank-calendar-update-action-form-left">
                <InputText
                    label={t('rankCalendar.form.name.label')}
                    placeholder={t('rankCalendar.form.name.placeholder')}
                    name="name"
                    control={control}
                    maxLength={INPUT_TEXT_MAX_LENGTH}
                    width={'300px'}
                />
            </div>
            <div className="rank-calendar-update-action-form-right">
                <Button onClick={showConfirmCancel}>
                    <span>{t('rankCalendar.update.actionForm.cancelBtn')}</span>
                </Button>
                <Button type="primary" onClick={onSubmit}>
                    {t('rankCalendar.update.actionForm.updateBtn')}
                </Button>
            </div>
        </div>
    );
}

export default UpdateActionForm;
