export const indicatorCreate = {
    title: 'Add new page',
    updateTitle: 'Indicator details',
    indicatorName: 'Indicator name',
    size: 'Displayed size',
    selectRoom: 'Select room',
    selectMultipleBox: 'Select multiple boxes',
    type: {
        room: 'Room',
        facility: 'Facility',
    },
    addRoom: 'Room',
    update: 'Save',
    assignment: 'Assign',
    reset: 'Reset',
    searchRoomName: 'Search room name',
    submit: 'Create indicator',
    cancel: 'Cancel',
    updateSuccess: 'Changes have been saved successfully!',
    createSuccess: 'Indicator created successfully.',
    helpText: 'Click on cells to add them to indicator',
    pleaseSelectACell: 'Please select a cell',
    changeSizeConfirm:
        'Room assigned to boxes outside of {{size}} area will be unassigned. Are you sure to do this?',
    changeTypeConfirm: 'Switch the Indicator type will reset the grid',
    error: {
        duplicateName: `Indicator name should be unique. Please try again.`,
    },
};
