import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, notification, Row } from 'antd';
import { cloneDeep, compact } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compareFormData } from '~common/commonFunctions';
import { ErrorMessageType } from '~common/constants';
import { showConfirm } from '~common/notification';
import { RightDrawerLayout } from '~components';
import {
    MAX_BLOCK_MONTH_DURATION,
    StoppingInventoryType,
    stopSellingFormItemDefault,
} from '~features/inventory/constants';
import { getStartDateAndEndDateByDay } from '~features/inventory/helper';
import { IStoppingRoomItem, IStopSellingFormItem } from '~features/inventory/interfaces';
import {
    inventoryStopSellingStateSelector,
    setIsShowBulkStopSellingForm,
} from '~features/inventory/reducers/inventory-stop-selling.reducer';
import {
    bulkSaleSettingInsertRoomType,
    inventoryListStateSelector,
} from '~features/inventory/reducers/inventory.reducer';
import { bulkStopSellingResolver } from '~features/inventory/schema';
import {
    getRoomTypeListForDropdown,
    roomTypeDropdownOptionsSelector,
} from '~features/room-type/room-type.reducer';
import {
    getListForDropDown,
    roomDropDownListSelector,
} from '~features/room/room.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import './BulkStopSellingModal.scss';
import StopSellingFormItem from './StopSellingFormItem/StopSellingFormItem';

type Prop = {
    onSubmitSuccess?: () => void;
};

function BulkStopSellingModal(prop: Prop) {
    const { onSubmitSuccess } = prop;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [stopSellingFormList, setStopSellingFormList] = useState<
        IStopSellingFormItem[]
    >([
        {
            ...stopSellingFormItemDefault,
        },
    ]);
    const { isShowBulkStopSellingForm } = useAppSelector(
        inventoryStopSellingStateSelector,
    );
    const { showLoading } = useAppSelector(inventoryListStateSelector);
    const roomTypeDropdownOptions = useAppSelector(roomTypeDropdownOptionsSelector);
    const roomDropDowns = useAppSelector(roomDropDownListSelector);

    const { control, setValue, formState, reset, handleSubmit, getValues, setError } =
        useForm({
            resolver: bulkStopSellingResolver,
        });

    useEffect(() => {
        if (isShowBulkStopSellingForm) {
            dispatch(
                getRoomTypeListForDropdown({
                    isPullFromTll: false,
                }),
            );
            dispatch(getListForDropDown({}));
        }
    }, [isShowBulkStopSellingForm, dispatch]);

    const closeDialog = () => {
        dispatch(setIsShowBulkStopSellingForm(false));
        setStopSellingFormList([
            {
                ...stopSellingFormItemDefault,
            },
        ]);
        reset({
            items: [
                {
                    ...stopSellingFormItemDefault,
                },
            ],
        });
    };

    const makeFormData = () => {
        const { items } = getValues();
        const _items = items.map((item: any) => {
            return {
                roomTypeId: item.roomTypeId,
                roomIds: item.roomIds,
                rangeDay:
                    item.rangeDay?.length > 0
                        ? [
                              parseDate(item.rangeDay[0])?.fmYYYYMMDD(),
                              parseDate(item.rangeDay[1])?.fmYYYYMMDD(),
                          ]
                        : null,
            };
        });
        return { items: _items };
    };

    const _showConfirm = () => {
        if (
            compareFormData(
                { items: [{ ...stopSellingFormItemDefault }] },
                makeFormData(),
            )
        ) {
            closeDialog();
            return;
        }
        showConfirm({
            title: t('inventory.stopSelling.cancelTitle'),
            cancelText: t('common.buttonCancelText'),
            okText: t('common.ok'),
            onOk() {
                closeDialog();
            },
        });
    };

    const removeItem = (index: number) => {
        const dataForm = getValues();
        dataForm.items.splice(index, 1);
        reset(dataForm);
        const _stopSellingFormList = (
            (dataForm.items as IStopSellingFormItem[]) || []
        ).map((item) => {
            return {
                roomTypeId: item.roomTypeId,
                roomIds: item.roomIds,
                rangeDay: item.rangeDay,
            };
        });
        setStopSellingFormList(_stopSellingFormList);
    };

    const addItem = () => {
        const _stopSellingFormList = cloneDeep(stopSellingFormList);
        _stopSellingFormList.push({
            ...stopSellingFormItemDefault,
        });
        setStopSellingFormList(_stopSellingFormList);
    };

    const _bulkStopInventory = async (body: IStoppingRoomItem[]) => {
        const response = await dispatch(bulkSaleSettingInsertRoomType(body));
        if (bulkSaleSettingInsertRoomType.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('inventory.list.message.updateSuccess'),
                });
                closeDialog();
                if (onSubmitSuccess) {
                    onSubmitSuccess();
                }
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    };

    const validateRangeDay = (items: IStopSellingFormItem[]) => {
        let isValidate = true;
        items.forEach((item, index) => {
            if (
                parseDate(item.rangeDay?.[1]).diff(
                    parseDate(item.rangeDay?.[0]),
                    'day',
                ) === 0
            ) {
                isValidate = false;
                setError(`items.${index}.rangeDay`, {
                    type: ErrorMessageType.MANUAL,
                    message: t('inventory.stopSelling.message.rangeDayError'),
                });
            }
            // Cannot block for more than 3 months
            if (
                parseDate(item.rangeDay?.[1]).isAfter(
                    parseDate(item.rangeDay?.[0]).add(MAX_BLOCK_MONTH_DURATION, 'month'),
                    'day',
                )
            ) {
                isValidate = false;
                setError(`items.${index}.rangeDay`, {
                    type: ErrorMessageType.MANUAL,
                    message: t('inventory.stopSelling.message.rangeThreeMonthError', {
                        month: MAX_BLOCK_MONTH_DURATION,
                    }),
                });
            }
        });
        return isValidate;
    };

    const onSubmit = () => {
        handleSubmit((data) => {
            const { items } = data;
            if (!validateRangeDay(items)) return;
            const body = items.map((item: IStopSellingFormItem) => {
                // map startDate, endDate by check in/out hotel
                let { startDate, endDate } = getStartDateAndEndDateByDay(
                    item.rangeDay?.[0] as string,
                    item.rangeDay?.[1] as string,
                );
                return {
                    type: StoppingInventoryType.ROOM_TYPE,
                    roomTypeId: item.roomTypeId,
                    stoppingRoomCount: item.roomIds?.length || 0,
                    startDate,
                    endDate,
                    stoppingInventoryItems: item.roomIds?.map((roomId) => {
                        return { roomId };
                    }),
                };
            });
            _bulkStopInventory(body);
        })();
    };

    const changeRoomType = (index: number, value: number) => {
        const _stopSellingFormList = cloneDeep(stopSellingFormList);
        _stopSellingFormList[index].roomTypeId = value;
        setStopSellingFormList(_stopSellingFormList);
    };

    return (
        <RightDrawerLayout
            open={isShowBulkStopSellingForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={t('inventory.stopSelling.bulkForm.title')}
            cancelText={t('common.buttonCancelText')}
            submitText={t('common.buttonSaveText')}
            className="bulk-stop-selling-drawer"
            destroyOnClose={true}
            disabledSaveButton={!formState.isValid || stopSellingFormList.length === 0}
            busy={showLoading}
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                <div>
                    {stopSellingFormList.map((stopSellingItem, index) => {
                        return (
                            <div key={index}>
                                <StopSellingFormItem
                                    control={control}
                                    index={index}
                                    item={stopSellingItem}
                                    roomDropDowns={roomDropDowns}
                                    roomTypeOptions={roomTypeDropdownOptions}
                                    disableRoomTypeIds={compact(
                                        stopSellingFormList.map(
                                            (item) => item.roomTypeId,
                                        ),
                                    )}
                                    setValue={setValue}
                                    removeStopSellingItem={removeItem}
                                    onChangeRoomType={changeRoomType}
                                />
                            </div>
                        );
                    })}
                </div>
                <Row>
                    <Col span={22}>
                        <Button
                            type="dashed"
                            block
                            icon={<PlusCircleOutlined />}
                            onClick={addItem}
                            disabled={
                                stopSellingFormList.length >=
                                roomTypeDropdownOptions.length
                            }
                        >
                            {t('inventory.stopSelling.buttons.addFormItem')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </RightDrawerLayout>
    );
}

export default memo(BulkStopSellingModal);
