import { AuditLogActions } from '~features/bo-operation-history/constants';
import { IHistory } from '~features/bo-operation-history/interfaces';
import { cloneDeep } from 'lodash';
import { AuditLogModules } from '~features/bo-operation-history/constants';
import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import i18next from '~plugins/i18next/i18n';

export function sanitizeLogData(key: string, value: any, module: AuditLogModules) {
    let sanitizedValue = value;
    // check if value is a date
    const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/;
    if (pattern.test(value)) sanitizedValue = parseDate(sanitizedValue)?.fmYYYYMMDD();

    return sanitizedValue ?? '';
}

function sanitizeGeneralSetting(logBody: any, action: AuditLogActions) {
    let result: Record<string, any> = [{ beforeData: {}, afterData: {} }];
    if (action === AuditLogActions.UPDATE) {
        logBody.forEach(({ beforeData, afterData }: any) => {
            if (afterData) {
                if (afterData.key === 'smtpEncryption')
                    afterData.value = i18next.t(
                        'boHistory.detail.value.' + afterData.value,
                    );
                result[0].afterData[afterData.key] = afterData.value;
            }
            if (beforeData) {
                if (beforeData.key === 'smtpEncryption')
                    beforeData.value = i18next.t(
                        'boHistory.detail.value.' + beforeData.value,
                    );
                result[0].beforeData[beforeData.key] = beforeData.value;
            }
        });
    }
    return result;
}

export function sanitizeLogBody(history: IHistory) {
    let result = cloneDeep(history.description.body) || [];

    if (history.targetObjectKey === AuditLogModules.GENERAL_SETTING)
        result = sanitizeGeneralSetting(result, history.action);

    return result;
}
