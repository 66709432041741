import { Layout } from 'antd';
import { ReactComponent as TabistCompassLogo } from '~assets/images/tabist-compass.svg';
import { MenuLanguage } from '../MenuLanguage/MenuLanguage';
import './GuestLayoutHeader.scss';
const { Header } = Layout;

export function GuestLayoutHeader() {
    return (
        <Header className="header-guest-wrapper">
            <div className="header-logo">
                <TabistCompassLogo className="header-guest-logo" />
            </div>
            <MenuLanguage trigger={['hover']} />
        </Header>
    );
}
