import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { salesByCategorySelector } from '~features/report/reducers/sales-by-category.reducer';
import { roomTypeDropdownOptionsSelector } from '~features/room-type/room-type.reducer';
import { useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import { CacheKeys } from '~queries/queries';
import DailySalesByCategoryTable from './DailySalesByCategoryTable';
import MonthlySalesByCategoryTable from './MonthlySalesByCategoryTable';
import './SalesByCategoryTable.scss';
import {
    generateDailyRoomTypeData,
    generateDailySummary,
    generateMonthlyRoomTypeData,
    generateMonthlySummary,
} from './helper';
import {
    DailySummaryDataType,
    ISalesByRoomTypesResponse,
    MonthlySummaryDataType,
} from './interfaces';

function SalesByCategoryTable() {
    const { t } = useTranslation();
    const [salesByRoomTypes, setSalesByRoomTypes] =
        useState<ISalesByRoomTypesResponse | null>(null);
    const roomTypeList = useAppSelector(roomTypeDropdownOptionsSelector);
    const { filterRoomTypeId, filterDate, isShowByDay } = useAppSelector(
        salesByCategorySelector,
    );

    const { data, isFetching } = useQuery({
        queryKey: [CacheKeys.getSalesByRoomTypesKey],
        enabled: false,
    });

    useEffect(() => {
        if (data) {
            setSalesByRoomTypes(data as ISalesByRoomTypesResponse);
        }
    }, [data]);

    const { daysInMonth, filterMonth, filterYear } = useMemo(() => {
        return {
            daysInMonth: parseDate(filterDate).daysInMonth(),
            filterMonth: parseDate(filterDate).month() + 1,
            filterYear: parseDate(filterDate).year(),
        };
    }, [filterDate]);

    const filteredRoomTypeList = useMemo(() => {
        return roomTypeList.filter((roomType) => {
            if (!filterRoomTypeId) return true;
            return roomType.value === filterRoomTypeId;
        });
    }, [roomTypeList, filterRoomTypeId]);

    const dailyDataSource = useMemo(() => {
        if (!salesByRoomTypes) return [];
        return Array.from({ length: daysInMonth }, (_, index) => {
            const date = index + 1;
            return {
                id: `${filterMonth}/${date}`,
                date: `${filterYear}/${filterMonth}/${date}`,
                ...generateDailyRoomTypeData(
                    salesByRoomTypes?.items,
                    filterYear,
                    filterMonth,
                    date,
                ),
            };
        });
    }, [salesByRoomTypes, filterYear, filterMonth, daysInMonth]);

    const dailySummaryData = useMemo(() => {
        if (!salesByRoomTypes || !isShowByDay) return;
        return generateDailySummary(salesByRoomTypes.totals);
    }, [salesByRoomTypes]);

    const monthlyDataSource = useMemo(() => {
        if (!salesByRoomTypes) return [];
        return filteredRoomTypeList.map((roomType) => {
            return {
                roomType: roomType.label,
                id: roomType.value,
                ...generateMonthlyRoomTypeData(
                    salesByRoomTypes.items,
                    Number(roomType.value),
                    filterDate,
                ),
            };
        });
    }, [salesByRoomTypes, filteredRoomTypeList, filterDate, data]);

    const monthlySummaryData = useMemo(() => {
        if (!salesByRoomTypes || isShowByDay) return;
        return generateMonthlySummary(salesByRoomTypes.totals, filterDate);
    }, [salesByRoomTypes, isShowByDay]);

    return (
        <div className="report-sales-by-category-table-wrapper">
            <div className="table-header">{t('report.salesByCategory.tableHeader')}</div>

            {isShowByDay ? (
                <DailySalesByCategoryTable
                    dataSource={dailyDataSource}
                    isLoading={isFetching}
                    filteredRoomTypeList={filteredRoomTypeList}
                    summaryData={dailySummaryData as DailySummaryDataType}
                />
            ) : (
                <MonthlySalesByCategoryTable
                    dataSource={monthlyDataSource}
                    isLoading={isFetching}
                    summaryData={monthlySummaryData as MonthlySummaryDataType}
                />
            )}
        </div>
    );
}

export default SalesByCategoryTable;
