import { INPUT_TEXT_MAX_LENGTH, MEMO_MAX_LENGTH, Regex } from '~common/constants';
import yup from '~plugins/yup';
import {
    ACCEPTED_FILE_EXTENSIONS,
    MAX_SIZE_FILE,
    POSTAL_CODE_MAX_LENGTH,
} from './constants';

export const FileSchema = {
    path: yup.string().max(INPUT_TEXT_MAX_LENGTH).required(),
    fileName: yup.string().max(INPUT_TEXT_MAX_LENGTH).required(),
    originalName: yup.string().max(INPUT_TEXT_MAX_LENGTH).optional(),
    extension: yup
        .string()
        .max(INPUT_TEXT_MAX_LENGTH)
        .oneOf(ACCEPTED_FILE_EXTENSIONS)
        .optional(),
    mimetype: yup.string().max(INPUT_TEXT_MAX_LENGTH).optional(),
    size: yup.number().strict().min(0).max(MAX_SIZE_FILE).optional(),
};

export const createGuestSchema = yup.object().shape({
    fullName: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters()
        .nullable(),
    yomigana: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters()
        .required(),
    mobilePhoneNumber: yup
        .string()
        .trim()
        .isPhone()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable()
        .optional(),
    emailAddress: yup
        .string()
        .isEmail()
        .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable()
        .optional(),
    birthday: yup.date().nullable(),
    postalCode: yup.string().trim().isPostalCode().max(POSTAL_CODE_MAX_LENGTH).nullable(),
    address: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters()
        .nullable(),
    memo: yup.string().trim().max(MEMO_MAX_LENGTH).nullable(),
    companyName: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters()
        .nullable(),
    companyYomigana: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters()
        .nullable(),
    companyPhoneNumber: yup
        .string()
        .trim()
        .isPhone()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyEmailAddress: yup
        .string()
        .isEmail()
        .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyPostalCode: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyAddress: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyWebsite: yup
        .string()
        .hasNoSpecialCharacters(Regex.WEBSITE_SPECIAL_CHARACTERS)
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    classificationId: yup.number().nullable().optional(),
});

export const updateGuestSchema = yup.object().shape({
    fullName: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters()
        .nullable(),
    yomigana: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters()
        .required(),
    mobilePhoneNumber: yup
        .string()
        .trim()
        .isPhone()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable()
        .optional(),
    emailAddress: yup
        .string()
        .isEmail()
        .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable()
        .optional(),
    birthday: yup.date().nullable(),
    postalCode: yup.string().isPostalCode().trim().max(POSTAL_CODE_MAX_LENGTH).nullable(),
    address: yup
        .string()
        .hasNoSpecialCharacters()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    memo: yup.string().trim().nullable(),
    companyName: yup
        .string()
        .hasNoSpecialCharacters()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyYomigana: yup
        .string()
        .hasNoSpecialCharacters()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyPhoneNumber: yup
        .string()
        .trim()
        .isPhone()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyEmailAddress: yup
        .string()
        .isEmail()
        .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyPostalCode: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyAddress: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    companyWebsite: yup
        .string()
        .hasNoSpecialCharacters(Regex.WEBSITE_SPECIAL_CHARACTERS)
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable(),
    classificationId: yup.number().nullable().optional(),
});
