import { FieldTimeOutlined, UserOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { MutableRefObject, useCallback, useMemo, WheelEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import './BookingCard.scss';
import { DragBox } from './DragBox';
import { showBookingDetailModal, useScheduleEvent } from '~features/room-booking/util';

import { Dayjs } from '~plugins/dayjs';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import { useAppDispatch } from '~hooks';
import {
    setIsDragging,
    setSelecting,
    unassignBooking,
    updateBookingItemAndTmp,
} from '~features/room-booking/reducers/schedule.reducer';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useBookingCardHover } from '~common/useHooks';
import { VariableSizeGrid } from 'react-window';

type BookingCardProps = {
    booking: IRoomBookingSchedule;
    endDrag: (booking: IRoomBookingSchedule, monitor: any) => void;
    time: {
        start: Dayjs;
        end: Dayjs;
    };
    width: number;
    left: number;
    top: number;
    gridRef: MutableRefObject<VariableSizeGrid>;
    outerRef: MutableRefObject<HTMLElement | undefined>;
    gridViewContentHeightRef?: MutableRefObject<number | undefined>;
    onMouseDown: (e: any) => void;
    onWheel?: (e: WheelEvent<HTMLDivElement>) => void;
};

const WIDTH_OF_CHARACTER = 8; // px

export const BookingCard = ({
    booking,
    endDrag,
    time,
    onMouseDown,
    top,
    width,
    left,
    gridRef,
    outerRef,
    gridViewContentHeightRef,
    onWheel,
}: BookingCardProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const statusText = useMemo(() => {
        const { status } = booking;
        if (!status) return '';
        return t('roomBooking.page.bookingStatus.' + status);
    }, [booking, t]);

    const cardClasses = classNames('room-booking-card-wrapper', {
        ['status-' + booking.status]: true,
        'booking-tmp': booking.isTmp,
    });

    const textDesc = useMemo(() => {
        const _checkOutTime = booking.checkOutTime ? ' C/O ' + booking.checkOutTime : '';
        return 'C/I ' + booking.checkInTime + _checkOutTime;
    }, [booking]);

    const { triggerScroll, emitter } = useScheduleEvent();

    const contentWidth = useMemo(() => {
        return (
            WIDTH_OF_CHARACTER *
                (booking.guest.yomigana?.length || 0 + statusText.length) +
            10 +
            16 // 10: icon, 16: padding
        );
    }, [booking, time]);

    const renderItem = useCallback(
        (showStatus = true) => {
            return (
                <div>
                    <div
                        className="booking-card-title"
                        style={{
                            display:
                                contentWidth < width
                                    ? 'flex'
                                    : !showStatus && booking.isTmp
                                    ? 'flex'
                                    : 'inline-block',
                        }}
                    >
                        <span className="guest-name mr-4">{booking.guest.yomigana}</span>
                        {showStatus && <span className="status-name">{statusText}</span>}
                    </div>

                    <div className="booking-card-content">
                        <FieldTimeOutlined className={classNames('mr-4')} />
                        <span className="text-desc mr-4">{textDesc}</span>

                        <UserOutlined className="mr-4" />
                        <span className="text-desc">{booking.numberOfAdults}</span>
                    </div>
                </div>
            );
        },
        [booking, textDesc, contentWidth, width, statusText],
    );

    const {
        onMouseLeave,
        onMouseEnter: _onMouseEnter,
        removeElement,
        createNewElement,
        onMouseDown: _onMouseDown,
    } = useBookingCardHover(
        renderItem,
        () => {
            triggerScroll(true);
        },
        true,
    );

    const editBooking = (e: any) => {
        if (booking.status === RoomBookingItemBookingStatus.CANCELLED) {
            return;
        }
        const element = createNewElement(e);

        dispatch(setSelecting(false));
        dispatch(setIsDragging(false));
        showBookingDetailModal({
            booking,
            element,
            navigate: navigate,
            onClose: () => {
                triggerScroll(true);
                element.remove();
                removeElement();
            },
            emitter,
            onUnassignBooking: (booking) => {
                dispatch(unassignBooking({ bookings: [booking] }));
            },
            onUnassignBookingSuccess: (booking) => {
                const _booking = cloneDeep(booking);
                _booking.room = { id: null, name: '' };
                dispatch(updateBookingItemAndTmp({ bookings: [_booking] }));
            },
            onUnassignBookingError: (booking) => {
                dispatch(updateBookingItemAndTmp({ bookings: [booking] }));
            },
            isShowEditBtn: true,
            position: { pageX: e.pageX, pageY: e.pageY },
        });
    };

    return (
        <DragBox
            booking={booking}
            width={width}
            left={left}
            top={top}
            gridRef={gridRef}
            outerRef={outerRef}
            gridViewContentHeightRef={gridViewContentHeightRef}
            hideSourceOnDrag={true}
            endDrag={endDrag}
            onMouseLeave={onMouseLeave}
            onMouseEnter={(e) => {
                _onMouseEnter(e);
                triggerScroll(true);
            }}
            onMouseDown={(e) => {
                _onMouseDown(editBooking);
                onMouseDown(e);
            }}
            onWheel={(e) => {
                if (onWheel) onWheel(e);
            }}
        >
            <div className={cardClasses} onClick={editBooking}>
                {renderItem()}
            </div>
        </DragBox>
    );
};
