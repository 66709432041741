import { Badge, Button, Collapse, Pagination, Spin } from 'antd';
import _, { difference, intersectionWith } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import { getBookingId } from '~features/room-booking/helper';
import { getAvailableRoomsOfBookingItems } from '~features/room-booking/reducers/create-booking.reducer';
import { IUnassignedBookingItem } from '~features/room-management/interfaces';
import {
    getBookingUnassignedList,
    roomManagementSelector,
    setAvailableRoomsByBooking,
    setCurrentUnassignPage,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { BookingUnassigned } from '../BookingUnassigned/BookingUnassigned';
import { CustomDragLayer } from '../BookingUnassigned/CustomDragLayer';
import './BookingUnassignedList.scss';
import classNames from 'classnames';
import { useMitt } from '~plugins/mitt';
import { RoomManagementEvent } from '~features/room-management/constants';
import { getUnassignedScreen } from '~features/room-management/helper';

const { Panel } = Collapse;

type Props = {
    draggable: boolean;
    rooms: {
        value: number;
        label: string;
        parentId: number;
        disabledDates: Record<string, boolean>;
    }[];
    plans: { value: number; roomTypeIds: number[] }[];
    onSetRoom?: (roomId: number, bookingId: number) => void;
    isManagementScreen?: boolean;
};

export const BookingUnassignedList = ({
    draggable,
    onSetRoom,
    rooms,
    plans,
    isManagementScreen,
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { emitter } = useMitt();

    const [bookingActive, setBookingActive] = React.useState<string[]>([]);
    const {
        unassignedList,
        isGettingUnassignedList,
        availableRoomsByBooking,
        currentUnassignPage,
        dateFilterUnassignBookingList,
    } = useAppSelector(roomManagementSelector);
    const onChangeCollapse = (key: any) => {
        setBookingActive(key);
    };

    const _getAvailableRoomsOfBookingItems = useCallback(
        async (ids: number[]) => {
            const response = await dispatch(getAvailableRoomsOfBookingItems(ids));
            if (getAvailableRoomsOfBookingItems.fulfilled?.match(response)) {
                if (response.payload?.success) {
                    dispatch(setAvailableRoomsByBooking(response.payload?.data || {}));
                }
            }
        },
        [dispatch],
    );

    const getUnassigntedList = useMemo(() => {
        return getUnassignedScreen(unassignedList, isManagementScreen);
    }, [unassignedList]);

    const _unassignedList = useMemo(() => {
        const getUnassigntedList = getUnassignedScreen(
            unassignedList,
            isManagementScreen,
        );
        const list = _.chunk(getUnassigntedList, DEFAULT_LIMIT_FOR_PAGINATION);
        const data = list?.[currentUnassignPage - 1] || [];
        return {
            data,
            totalPage: list.length,
            pageSize: Math.ceil(list.length / DEFAULT_LIMIT_FOR_PAGINATION),
        };
    }, [currentUnassignPage, unassignedList]);

    useEffect(() => {
        if (_unassignedList?.data?.length) {
            _getAvailableRoomsOfBookingItems(
                _unassignedList?.data?.map((item) => item.id),
            );
        }
    }, [_unassignedList, _getAvailableRoomsOfBookingItems]);

    useEffect(() => {
        if (_unassignedList?.data?.length === 0 && currentUnassignPage > 1) {
            dispatch(
                getBookingUnassignedList({
                    start: dateFilterUnassignBookingList?.[0],
                    end: dateFilterUnassignBookingList?.[1],
                    isFromBookingSchedule: false,
                }),
            );
        }
    }, [_unassignedList]);

    useEffect(() => {
        emitter.on(RoomManagementEvent.CHANGE_SELLING_STATUS_ROOM, (val) => {
            if (val) {
                _getAvailableRoomsOfBookingItems(
                    _unassignedList?.data?.map((item) => item.id),
                );
            }
        });
        return () => {
            emitter.off(RoomManagementEvent.CHANGE_SELLING_STATUS_ROOM);
        };
    }, [emitter, _unassignedList, _getAvailableRoomsOfBookingItems]);

    const toggleList = () => {
        const getUnassigntedList = getUnassignedScreen(
            unassignedList,
            isManagementScreen,
        );
        setBookingActive(
            bookingActive.length === getUnassigntedList.length
                ? []
                : getUnassigntedList.map((item) => `booking-${item.id}`),
        );
    };

    const isExpand = useMemo(() => {
        const getUnassigntedList = getUnassignedScreen(
            unassignedList,
            isManagementScreen,
        );
        const unassigntedListKey = getUnassigntedList.map((item) => `booking-${item.id}`);
        const differenceActiveKey = difference(bookingActive, unassigntedListKey);
        if (differenceActiveKey.length) {
            setBookingActive(getUnassigntedList.map((item) => `booking-${item.id}`));
            return true;
        }
        return bookingActive.length === getUnassigntedList.length;
    }, [bookingActive, unassignedList]);

    const availableRoomOptions = (item: IUnassignedBookingItem) => {
        // in room booking schedule, draggable: false
        return intersectionWith(
            rooms,
            availableRoomsByBooking[item.id] || [],
            (a, b) => a.value === b.id,
        ).filter((room) => {
            return item.roomTypeId === room.parentId;
        });
    };

    const onChangePage = (page: number) => {
        dispatch(setCurrentUnassignPage(page));
    };

    return (
        <div className="booking-unassigned-list-wrapper">
            <Spin spinning={isGettingUnassignedList}>
                <div className="booking-unassigned-list-header">
                    <div className="d-flex j-between a-center">
                        <span className="title">
                            {t('roomManagement.list.unassignList')}
                        </span>
                        <Button
                            onClick={toggleList}
                            className="btn-close-all"
                            type="text"
                        >
                            {t(
                                'roomManagement.list.' +
                                    (isExpand ? 'closeAll' : 'expandAll'),
                            )}
                        </Button>
                    </div>
                    <div className="d-flex a-center">
                        <Badge size="default" count={getUnassigntedList.length} />
                        <span className="ml-8">
                            {t('roomManagement.list.unassignCount')}
                        </span>
                    </div>
                </div>
                <div className="booking-unassigned-list-content">
                    {_unassignedList.data.length === 0 ? (
                        <div className="booking-unassigned-list-no-data">
                            {t('roomManagement.list.unassignListSearchNotFound')}
                        </div>
                    ) : (
                        <>
                            <Collapse
                                activeKey={bookingActive}
                                onChange={onChangeCollapse}
                                className={classNames({
                                    'has-scroll': draggable,
                                })}
                            >
                                {_unassignedList.data.map((item) => {
                                    const roomBooking = item.roomBooking || {};
                                    return (
                                        <Panel
                                            header={t('roomManagement.list.bookingId', {
                                                id: getBookingId(item),
                                            })}
                                            className="text-truncate"
                                            key={`booking-${item.id}`}
                                        >
                                            <BookingUnassigned
                                                rooms={availableRoomOptions(item)}
                                                booking={item}
                                                roomBooking={roomBooking}
                                                draggable={draggable}
                                                onSetRoom={onSetRoom}
                                            />
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                            {_unassignedList.totalPage > 1 && (
                                <Pagination
                                    align="center"
                                    className="unassign-list-pagination"
                                    defaultCurrent={currentUnassignPage}
                                    current={currentUnassignPage}
                                    total={_unassignedList.totalPage}
                                    pageSize={_unassignedList.pageSize}
                                    onChange={onChangePage}
                                    showSizeChanger={false}
                                    size="small"
                                />
                            )}
                            {draggable && <CustomDragLayer />}
                        </>
                    )}
                </div>
            </Spin>
        </div>
    );
};

BookingUnassignedList.defaultProps = {
    draggable: true,
    rooms: [],
    plans: [],
};
