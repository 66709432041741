import React from 'react';
import './Tab1.scss';

import PlanOverview from './PlanOverview/PlanOverview';
import SaleItemTable from './SaleItemTable/SaleItemTable';

function Tab1() {
    return (
        <div className="plan-detail-information-screan">
            <div className="plan-detail-tab1-wrapper">
                <PlanOverview />
                <SaleItemTable />
            </div>
        </div>
    );
}

export default Tab1;
