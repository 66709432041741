import React from 'react';
import { Select, SelectProps, Form, Row, Col } from 'antd';
import { Controller } from '~plugins/hook-form';
import './PaymentMethodSelect.scss';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import { parseErrorMessage } from '~common/commonFunctions';
import classNames from 'classnames';
import { IPaymentMethodDropDown } from '~features/payment-method/interfaces';
interface ISingleSelect extends SelectProps {
    name: string;
    label: string;
    defaultValue: string | number | (string | number)[];
    control: any;
    paymentMethodOptions: IPaymentMethodDropDown[];
    disableValues?: (string | number)[];
    tooltip?: LabelTooltipType;
    required?: boolean;
    hideHelpText?: boolean;
}

export function PaymentMethodSelect(props: ISingleSelect) {
    const {
        tooltip,
        control,
        name,
        defaultValue,
        label,
        onChange,
        options,
        fieldNames,
        disableValues,
        required,
        hideHelpText,
        showSearch = true,
        paymentMethodOptions,
        ...rest
    } = props;
    return (
        <div
            className={classNames('single-select-wrapper', {
                'hide-help-text': hideHelpText,
            })}
        >
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            required={required}
                            tooltip={tooltip}
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                        >
                            <Select
                                {...rest}
                                showSearch={showSearch}
                                onBlur={field.onBlur}
                                value={
                                    field.value || field.value === 0 ? field.value : null
                                }
                                ref={field.ref}
                                onChange={(...agrs) => {
                                    if (onChange) {
                                        onChange(...agrs);
                                    }
                                    const val = agrs?.[0] || null;
                                    field.onChange(val);
                                }}
                                dropdownStyle={{
                                    minWidth: '300px',
                                }}
                                filterOption={(input, option) => {
                                    const filteredOptions = (
                                        paymentMethodOptions || []
                                    ).filter((_option) => {
                                        return (
                                            _option.autoGeneratedCode
                                                ?.toString()
                                                ?.includes(input.toLowerCase()) ||
                                            _option.name
                                                ?.toString()
                                                ?.toLowerCase()
                                                ?.includes(input.toLowerCase())
                                        );
                                    });
                                    return (
                                        filteredOptions.findIndex(
                                            (item) => item.id === option?.value,
                                        ) > -1
                                    );
                                }}
                            >
                                {paymentMethodOptions?.map((item, index) => {
                                    const val = item.id;
                                    const disabled =
                                        disableValues?.includes(val) || false;
                                    return (
                                        <Select.Option
                                            key={val}
                                            value={val}
                                            label={item.name}
                                            disabled={disabled}
                                        >
                                            <Row>
                                                <Col span={8}>
                                                    {item.autoGeneratedCode}
                                                </Col>
                                                <Col span={16}>
                                                    <div className="text-truncate">
                                                        {item.name}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

PaymentMethodSelect.defaultProps = {
    defaultValue: null,
    required: false,
    options: [],
    disableValues: [],
};
