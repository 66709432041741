const login = {
    loginPageTitle: 'アカウントにログイン',
    email: 'メールアドレス *',
    inputEmail: 'メールアドレスを入力ください',
    password: 'パスワード *',
    inputPassword: 'パスワードをご入力ください',
    forgotPassword: 'パスワードを忘れた場合：',
    resetPassword: 'パスワードをリセット',
    buttonLoginText: 'ログイン',
    errorMessage: 'ユーザー名またはパスワードが違います',
    passwordAttempts: 'パスワード最大入力回数が更新されました',
};

export default login;
