import React from 'react';
import { Col, Row } from 'antd';
import './CreateMarketingChannelsForm.scss';
import { IMarketingChannelBulkCreateFormItem } from '../../interfaces';
import CreateMarketingChannelsFormSetting from '../CreateMarketingChannelsFormSetting/CreateMarketingChannelsFormSetting';
import CreateFormMarketingChannelList from '../CreateFormMarketingChannelList/CreateFormMarketingChannelList';

interface IProp {
    control: any;
    currentMarketingChannelList: IMarketingChannelBulkCreateFormItem[];
}
function CreateMarketingChannelsForm(props: IProp) {
    return (
        <div className="marketing-channels-form-wrapper">
            <Row gutter={24}>
                <Col span={7}>
                    <CreateMarketingChannelsFormSetting />
                </Col>
                <Col span={17}>
                    <CreateFormMarketingChannelList
                        currentMarketingChannelList={props.currentMarketingChannelList}
                        control={props.control}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default CreateMarketingChannelsForm;
