import { Badge, Checkbox, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IGridItem, ISelectedCheckBox } from '~features/inventory/interfaces';
import {
    setFirstCheckbox,
    toggleCheckBoxChild,
    toggleCheckBoxChildrenByShift,
    toggleCheckBoxParent,
    toggleCheckBoxParentByShift,
} from '~features/inventory/reducers/inventory-stop-selling.reducer';
import { useAppDispatch } from '~hooks';
import { todayDayjs } from '~plugins/dayjs';

type Props = {
    style: React.CSSProperties;
    item: IGridItem;
    currentDate: string;
    firstCheckbox: ISelectedCheckBox | null;
};

const today = todayDayjs.format('YYYY-MM-DD');

export const CellItem = ({ style, item, currentDate, firstCheckbox }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const toggleCheckBoxesByShiftKey = () => {
        if (!!firstCheckbox?.parentId) {
            dispatch(
                toggleCheckBoxChildrenByShift({
                    date: currentDate,
                }),
            );
        } else {
            dispatch(toggleCheckBoxParentByShift({ date: currentDate }));
        }
    };

    const toggleCheckBox = (event: CheckboxChangeEvent) => {
        if (item.parentId) {
            dispatch(
                toggleCheckBoxChild({
                    id: item.id,
                    date: currentDate,
                    parentId: item.parentId,
                }),
            );
        } else {
            dispatch(
                toggleCheckBoxParent({
                    id: item.id,
                    date: currentDate,
                }),
            );
        }

        if (!event.nativeEvent.shiftKey || !firstCheckbox) {
            dispatch(
                setFirstCheckbox({
                    id: item.id,
                    date: currentDate,
                    value: event.target.checked,
                    parentId: item.parentId,
                }),
            );
        }

        if (event.nativeEvent.shiftKey && firstCheckbox) {
            toggleCheckBoxesByShiftKey();
        }
    };

    const girdItem = useMemo(() => {
        return item?.date?.[currentDate];
    }, [item, currentDate]);

    return (
        <div
            style={style}
            className={classNames('stop-selling-cell-item', {
                today: today === currentDate,
            })}
        >
            <div
                className={classNames('stop-selling-cell', 'checkbox-col', {
                    'pl-24': !!item.parentId,
                })}
            >
                <Tooltip
                    title={
                        girdItem?.isAutoBooked
                            ? t('inventory.stopSelling.autoAssignRoom')
                            : ''
                    }
                >
                    <Badge dot={girdItem?.isAutoBooked}>
                        <Checkbox
                            name={`${item.id}_${currentDate}.isChecked`}
                            disabled={girdItem?.isBooked}
                            checked={girdItem?.isChecked}
                            onChange={toggleCheckBox}
                        >
                            {t('inventory.stopSelling.stopSellingLabel')}
                        </Checkbox>
                    </Badge>
                </Tooltip>
            </div>
            <div className="stop-selling-cell remain-room-col">
                {!item.parentId ? item.date?.[`${currentDate}`]?.availableRooms : ''}
            </div>
        </div>
    );
};
