import {
    DownOutlined,
    EditOutlined,
    EllipsisOutlined,
    ScheduleOutlined,
    StopOutlined,
    UpOutlined,
} from '@ant-design/icons';
import { Button, notification, Popover } from 'antd';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showConfirm } from '~common/notification';
import { TextTruncate } from '~components';
import { getStartDateAndEndDateByDay } from '~features/inventory/helper';
import { RoomBookingEvent } from '~features/room-booking/constants';
import {
    RoomManagementEvent,
    StopRoomCause,
    StopSellingStatus,
} from '~features/room-management/constants';
import {
    IChangeSellingStatusRoom,
    IRoomManagementItem,
} from '~features/room-management/interfaces';
import {
    changeSellingStatusRoom,
    getRoomListStatus,
    getRoomStatus,
    roomManagementSelector,
    setSelectedRoomStopSellingCause,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import './RoomDetail.scss';

type Props = {
    room: IRoomManagementItem;
    isOpen: boolean;
    isReadOnly?: boolean;
    onClose: () => void;
};

export const RoomStopSellingDetail = (props: Props) => {
    const { room, isReadOnly } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isCollapse, setIsCollapse] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const { currentDate } = useAppSelector(roomManagementSelector);
    const roomType = useMemo(() => {
        return t('roomManagement.list.detail.vacancyTitle', {
            name: room.roomType?.name,
        });
    }, [room, t]);
    const date = useMemo(() => {
        return parseDate(room.endDate || null).isValid() &&
            parseDate(room.startDate || null).isValid()
            ? `${parseDate(room.startDate)?.fmYYYYMMDD('/')} - ${parseDate(
                  room.endDate,
              )?.fmYYYYMMDD('/')}`
            : parseDate(currentDate)?.fmYYYYMMDD('/');
    }, [currentDate, room]);

    useEffect(() => {
        setIsDisabled(customDayjs().diff(parseDate(currentDate), 'day') > 0);
    }, [currentDate]);

    useEffect(() => {
        if (props.isOpen) {
            setIsCollapse(props.isOpen);
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (props.isOpen) {
            dispatch(setSelectedRoomStopSellingCause(room.reason));
        }
        return () => {
            dispatch(setSelectedRoomStopSellingCause(null));
        };
    }, [props.isOpen]);

    const onClickButtonSeeMore = () => {
        setIsCollapse(!isCollapse);
    };

    const { emitter } = useMitt();
    const onClickEditButton = () => {
        props.onClose();
        dispatch(getRoomStatus({ id: room.id, currentDate }));
        emitter.emit(RoomBookingEvent.TOGGLE_UPDATE_ROOM_STATUS, true);
    };

    const stopRoomCauseIcon = useMemo(() => {
        switch (room.reason as StopRoomCause) {
            case StopRoomCause.INVENTORY_ADJUSTMENT:
                return <ScheduleOutlined />;
            case StopRoomCause.ROOM_ISSUES:
                return <StopOutlined />;
            case StopRoomCause.OTHERS:
                return <EllipsisOutlined />;
            default:
                return;
        }
    }, [room]);

    const onConfirmClearStatus = async () => {
        const { startDate, endDate } = getStartDateAndEndDateByDay(
            parseDate(currentDate)?.fmYYYYMMDDHHmmss(),
            parseDate(currentDate)?.fmYYYYMMDDHHmmss(),
        );
        const mappedData = {
            startDate,
            endDate,
            status: StopSellingStatus.VACANT,
        } as IChangeSellingStatusRoom;
        const response = await dispatch(
            changeSellingStatusRoom({ roomId: room!.id, body: mappedData }),
        );
        if (changeSellingStatusRoom.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomManagement.list.update.continueSelling'),
                });
                dispatch(getRoomListStatus());
                emitter.emit(RoomManagementEvent.CHANGE_SELLING_STATUS_ROOM, true);
            } else {
                notification.error({
                    message: t('common.somethingWentWrong'),
                    description: response.payload?.message || '',
                });
            }
        }
    };

    const showConfirmClearStatus = () => {
        props.onClose();
        showConfirm({
            title: t('roomManagement.list.clearStatusDialog.title'),
            content: t('roomManagement.list.clearStatusDialog.content'),
            cancelText: t('roomManagement.list.clearStatusDialog.cancelText'),
            okText: t('roomManagement.list.clearStatusDialog.okText'),
            onOk: onConfirmClearStatus,
        });
    };

    return (
        <Popover
            open={props.isOpen}
            content={
                <div className="room-detail-popover">
                    <div className="d-flex j-between a-center mb-16">
                        <div className="room-name mb-0 w-100">
                            <TextTruncate text={room.name || ''} />
                        </div>
                        {!isReadOnly && (
                            <div className="w-100 btn-edit">
                                <Button
                                    type="text"
                                    disabled={isDisabled}
                                    className="popover-header-button"
                                    onClick={onClickEditButton}
                                >
                                    <EditOutlined />
                                    {t('roomManagement.list.editButton')}
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className="room-type mb-4 text-truncate">{roomType}</div>
                    <div className="cause d-flex a-center mb-4">
                        {stopRoomCauseIcon}
                        {t(`roomManagement.list.causeOptions.${room.reason}`)}
                    </div>
                    <div className="room-date mb-20">{date}</div>
                    <div
                        style={{ maxHeight: `${isCollapse ? '42px' : ''}` }}
                        className={classNames('memo-block', {
                            collapse: !isCollapse,
                        })}
                    >
                        {room.memo}
                    </div>
                    {!isReadOnly && (
                        <>
                            <div>
                                <Button
                                    type="text"
                                    className="see-more-button"
                                    onClick={onClickButtonSeeMore}
                                >
                                    {t('roomManagement.list.seeMoreButton')}
                                    {isCollapse ? <DownOutlined /> : <UpOutlined />}
                                </Button>
                            </div>
                            <div className="actions">
                                <Button
                                    className="mr-8"
                                    disabled={isDisabled}
                                    onClick={showConfirmClearStatus}
                                >
                                    {t('roomManagement.list.clearStatusButton')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            }
            trigger="click"
            placement="bottom"
            onOpenChange={props.onClose}
            overlayClassName="room-detail-popover-wrapper"
        ></Popover>
    );
};
