import roomListJa from './ja/room-list.locale';
import roomFormJa from './ja/room-form.locale';
import roomFormIndividualJa from './ja/room-form-individual.locale';

import roomListEn from './en/room-list.locale';
import roomFormEn from './en/room-form.locale';
import roomFormIndividualEn from './en/room-form-individual.locale';
import roomOrderSettingsJa from './ja/room-order-settings.locale';
import roomOrderSettingsEn from './en/room-order-settings.locale';

const room = {
    ja: {
        list: roomListJa,
        form: roomFormJa,
        roomFormIndividual: roomFormIndividualJa,
        roomOrderSettings: roomOrderSettingsJa,
    },
    en: {
        list: roomListEn,
        form: roomFormEn,
        roomFormIndividual: roomFormIndividualEn,
        roomOrderSettings: roomOrderSettingsEn,
    },
};

export default room;
