import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse, ICommonListQuery } from '~common/interfaces';
import {
    IDetailIndicator,
    IIndicatorBulkDeleteItem,
    IIndicatorForm,
    IIndicatorListResponse,
} from '../interfaces';

class IndicatorService extends ApiService {
    getList(queryString: ICommonListQuery) {
        return this._getList(queryString) as Promise<IIndicatorListResponse>;
    }

    bulkDelete(bulkDeleteBody: IIndicatorBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    create(formData: IIndicatorForm) {
        return this._post(`${this.baseUrl}`, formData) as Promise<
            IBodyResponse<{ message: string }>
        >;
    }

    update(id: number, formData: IIndicatorForm) {
        return this._patch(`${this.baseUrl}/${id}`, formData) as Promise<
            IBodyResponse<{ message: string }>
        >;
    }

    detail(id: number) {
        return this._get(`${this.baseUrl}/${id}`) as Promise<
            IBodyResponse<IDetailIndicator>
        >;
    }
}

export const indicatorService = new IndicatorService(
    { baseUrl: '/indicator' },
    axiosService,
);
