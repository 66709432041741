import { Tabs } from 'antd';
import { orderBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AntdOrderDirection,
    LodashOrderDirection,
    OrderDirection,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import {
    BookingStatusType,
    BookingType,
    DashboardColumns,
} from '~features/dashboard/constants';
import {
    bookingPageCountSelector,
    dashboardSelector,
    getBookingList,
    setBookingListLoading,
    setBookingListQuery,
    setBookingStatusType,
    setBookingType,
    sortBookingList,
} from '~features/dashboard/reducers/dashboard.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import { BookingGrid } from './BookingGrid';
import './BookingListCard.scss';

export const BookingListCard = () => {
    const { t } = useTranslation();
    const {
        bookingList,
        bookingListQuery,
        bookingListLoading,
        bookingListTotal,
        bookingStatusType,
        bookingType,
    } = useAppSelector(dashboardSelector);
    const pageCount = useAppSelector(bookingPageCountSelector);
    const dispatch = useAppDispatch();

    const onChange = useCallback(
        (sorter: ISorter) => {
            const { order, field, columnKey } = sorter as ISorter;
            const _field = field || columnKey || 'id';
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;

            dispatch(
                setBookingListQuery({
                    ...bookingListQuery,
                    orderDirection: _order,
                    orderBy: order ? _field : 'id',
                }),
            );
            dispatch(getBookingList());
        },
        [bookingListQuery, dispatch],
    );

    const onSortBookingList = (sorted: ISorter) => {
        const { order, columnKey } = sorted as ISorter;
        let _field = columnKey || 'id';
        const _order =
            order === OrderDirection.ASC
                ? LodashOrderDirection.ASC
                : LodashOrderDirection.DESC;
        dispatch(setBookingListLoading(true));
        dispatch(
            setBookingListQuery({
                ...bookingListQuery,
                orderDirection: order,
                orderBy: order ? _field : null,
            }),
        );
        let tempBookingList = [...bookingList];
        switch (_field) {
            case DashboardColumns.START_DATE_OF_STAY:
                tempBookingList.sort((curBooking, nextBooking) => {
                    if (
                        parseDate(
                            `${parseDate(curBooking.startDateOfStay).fmYYYYMMDD()} ${
                                curBooking.checkInTime
                            }`,
                        ).isAfter(
                            parseDate(
                                `${parseDate(nextBooking.startDateOfStay).fmYYYYMMDD()} ${
                                    nextBooking.checkInTime
                                }`,
                            ),
                        )
                    )
                        return _order === LodashOrderDirection.ASC ? 1 : -1;
                    return _order === LodashOrderDirection.ASC ? -1 : 1;
                });
                dispatch(sortBookingList(tempBookingList));
                break;
            case DashboardColumns.START_TIME_DATE:
                tempBookingList.sort((curBooking, nextBooking) => {
                    if (
                        parseDate(curBooking.startDatetime).isAfter(
                            parseDate(nextBooking.startDatetime),
                        )
                    )
                        return _order === LodashOrderDirection.ASC ? 1 : -1;
                    return _order === LodashOrderDirection.ASC ? -1 : 1;
                });
                dispatch(sortBookingList(tempBookingList));
                break;
            case DashboardColumns.FULL_NAME:
                tempBookingList.sort((curBooking, nextBooking) => {
                    const nameA =
                        curBooking?.roomBooking?.representativeGuest?.fullName ||
                        nextBooking?.guest?.fullName ||
                        '';
                    const nameB =
                        nextBooking?.roomBooking?.representativeGuest?.fullName ||
                        nextBooking?.guest?.fullName ||
                        '';
                    if (nameA > nameB) {
                        return _order === LodashOrderDirection.ASC ? 1 : -1;
                    }
                    if (nameA < nameB) {
                        return _order === LodashOrderDirection.ASC ? -1 : 1;
                    }
                    // names must be equal
                    return 0;
                });
                dispatch(sortBookingList(tempBookingList));
                break;
            case DashboardColumns.ROOM:
                tempBookingList.sort((curBooking, nextBooking) => {
                    const nameA = curBooking?.room?.name || '';
                    const nameB = nextBooking?.room?.name || '';
                    if (nameA > nameB) {
                        return _order === LodashOrderDirection.ASC ? 1 : -1;
                    }
                    if (nameA < nameB) {
                        return _order === LodashOrderDirection.ASC ? -1 : 1;
                    }
                    // names must be equal
                    return 0;
                });
                dispatch(sortBookingList(tempBookingList));
                break;
            default:
                tempBookingList = [...orderBy(bookingList, _field, _order)];
                dispatch(sortBookingList(tempBookingList));
                break;
        }
        dispatch(setBookingListLoading(false));
    };

    const onChangePage = useCallback(
        (page: number) => {
            dispatch(setBookingListQuery({ ...bookingListQuery, page }));
            dispatch(getBookingList());
        },
        [bookingListQuery, dispatch],
    );

    const onChangeTypeTab = (type: string) => {
        dispatch(setBookingType(type));
        dispatch(
            setBookingListQuery({
                ...bookingListQuery,
                orderBy: null,
            }),
        );
        dispatch(getBookingList());
    };

    const bookingItems = useMemo(() => {
        return [
            {
                label: t('dashboard.bookingList.tab.hotelReservation'),
                key: BookingType.room,
                children: (
                    <BookingGrid
                        pageCount={pageCount}
                        data={bookingList}
                        query={bookingListQuery}
                        total={bookingListTotal}
                        loading={bookingListLoading}
                        onChange={onSortBookingList}
                        isRoomBooking={true}
                        onChangePage={onChangePage}
                        type={bookingStatusType}
                    />
                ),
            },
            {
                label: t('dashboard.bookingList.tab.facilityReservation'),
                key: BookingType.facility,
                children: (
                    <BookingGrid
                        pageCount={pageCount}
                        data={bookingList}
                        query={bookingListQuery}
                        total={bookingListTotal}
                        loading={bookingListLoading}
                        onChange={onSortBookingList}
                        onChangePage={onChangePage}
                        type={bookingStatusType}
                        isRoomBooking={false}
                    />
                ),
            },
        ];
    }, [
        bookingList,
        bookingListLoading,
        bookingListQuery,
        bookingListTotal,
        onChange,
        onChangePage,
        pageCount,
        t,
        bookingStatusType,
    ]);

    const items = useMemo(() => {
        return [
            {
                label: t('dashboard.bookingList.tab.newArrivalReservation'),
                key: BookingStatusType.newBooking,
                children: (
                    <Tabs
                        onChange={onChangeTypeTab}
                        className="child-tab"
                        items={bookingItems}
                        activeKey={bookingType}
                    />
                ),
            },
            {
                label: t('dashboard.bookingList.tab.todayReservation'),
                key: BookingStatusType.willCheckIn,
                children: (
                    <Tabs
                        onChange={onChangeTypeTab}
                        className="child-tab"
                        items={bookingItems}
                        activeKey={bookingType}
                    />
                ),
            },
        ];
    }, [bookingItems, bookingType, dispatch, t, bookingListQuery]);

    useEffect(() => {
        return () => {
            dispatch(setBookingStatusType(BookingStatusType.newBooking));
            dispatch(setBookingType(BookingType.room));
            dispatch(
                setBookingListQuery({
                    ...bookingListQuery,
                }),
            );
        };
    }, [dispatch]);

    return (
        <div className="dashboard-booking-list-card-wrapper">
            <Tabs
                onChange={(status) => {
                    dispatch(setBookingStatusType(status));
                    dispatch(setBookingType(BookingType.room));
                    dispatch(
                        setBookingListQuery({
                            ...bookingListQuery,
                            orderBy: null,
                        }),
                    );
                    dispatch(getBookingList());
                }}
                activeKey={bookingStatusType}
                type="card"
                className="parent-tab"
                items={items}
            />
        </div>
    );
};
