import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    searchKeywordSelector,
    setSearchField,
} from '../../../marketing-channel.reducer';
import { SearchField } from '../../../constants';
import './SelectField.scss';
import { useAppSelector } from '~hooks';

const { Option } = Select;

function SelectField() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const keyword = useAppSelector(searchKeywordSelector);
    const handleChange = (columnName: SearchField) => {
        dispatch(setSearchField({ columnName, keyword }));
    };
    return (
        <Select
            defaultValue={SearchField.ALL}
            onChange={handleChange}
            className="marketing-channel-select-search"
        >
            {Object.values(SearchField).map((columnName, index) => {
                return (
                    <Option value={columnName} key={index}>
                        {t(`marketingChannel.list.searchColumn.${columnName}`)}
                    </Option>
                );
            })}
        </Select>
    );
}

export default SelectField;
