import { Modal } from 'antd';
import classNames from 'classnames';
import { HTMLProps, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { checkValidRangeDateTime } from '~features/facility-booking/helper';
import {
    facilityBookingStateSelector,
    setPanelId,
    setSelecting,
    setStartPosition,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { endSelectHour } from '~features/facility-booking/util';
import { IScheduleItem } from '~features/room-booking/interfaces';
import {
    getDateFromElement,
    removeSelection,
    useScheduleEvent,
} from '~features/room-booking/util';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';

type Props = HTMLProps<HTMLDivElement> & {
    item: IScheduleItem;
    rowIndex: number;
    columnIndex: number;
    hour: string;
    day: string;
};

export const CellItem = ({
    item,
    rowIndex,
    columnIndex,
    hour,
    day,
    children,
    style,
    ...rest
}: Props) => {
    const dispatch = useAppDispatch();
    const panelId = `facility_${item.parentId}_${item.id}`;
    const { t } = useTranslation();
    // handle select day for create booking
    const { startPosition, isSelecting, bookingList } = useAppSelector(
        facilityBookingStateSelector,
    );
    const { triggerScroll, today } = useScheduleEvent();
    const startSelect = useCallback(
        (position: { x: number; y: number }) => {
            dispatch(setSelecting(true));
            dispatch(setStartPosition(position));
            dispatch(setPanelId(panelId));
        },
        [panelId],
    );
    const _endSelect = useCallback(
        (position: { x: number; y: number }) => {
            dispatch(setSelecting(false));
            if (!startPosition) {
                removeSelection();
                triggerScroll(true);
                return;
            }
            const options = {
                panelId,
                startPosition,
                currentCursor: position,
                onClose: () => {
                    triggerScroll(true);
                },
            };
            const { startDate, endDate, startTime, endTime } =
                getDateFromElement(options);

            if (
                checkValidRangeDateTime({
                    start: parseDate(`${startDate} ${startTime}`),
                    end: parseDate(`${endDate} ${endTime}`),
                    bookingList: bookingList,
                    facilityKey: 'facility-' + item.id,
                })
            ) {
                endSelectHour(options);
            } else {
                removeSelection();
                Modal.error({
                    title: t('roomBooking.schedule.dateRageInvalid'),
                    centered: true,
                });
                triggerScroll(true);
                return;
            }
        },
        [startPosition],
    );
    return (
        <div
            style={{
                ...style,
                paddingLeft: item.parentId ? 4 : 0,
                paddingRight: item.parentId ? 4 : 0,
                paddingBottom: 12,
            }}
            {...rest}
            className="day-cell-item-wrapper"
        >
            {item.parentId ? (
                <div
                    className={classNames('day-item border-top ', {
                        [panelId]: true,
                    })}
                >
                    <div
                        className={classNames('cell-content', {
                            [panelId]: true,
                        })}
                        onClick={() => {
                            if (day < today) {
                                return;
                            }
                            triggerScroll(false);
                            if (!isSelecting) {
                                startSelect({ x: columnIndex, y: rowIndex });
                            } else {
                                _endSelect({ x: columnIndex, y: rowIndex });
                            }
                        }}
                        data-x={columnIndex}
                        data-y={rowIndex}
                        data-facility_type_id={item.parentId}
                        data-facility_id={item.id}
                        data-hours={hour}
                        data-day={day}
                    ></div>
                    {children}
                </div>
            ) : (
                <div className="schedule-collapse-header-item"></div>
            )}
        </div>
    );
};
