const hotelSettingForm = {
    title: 'Hotel Setting',
    breadcrumbs: {
        home: 'Home',
        hotel: 'Front Setting',
        hotelSetting: 'Hotel Setting',
    },
    cancelBtn: 'Cancel',
    submitBtn: 'Submit',
    editButton: 'Edit',
    hotelName: 'Hotel name',
    createdAt: 'Created at',
    picName: 'Name of person in-charge',
    picNameInput: 'Ex: Yamda Tarou',
    postalCode: 'Postal Code',
    inputPostalCode: '1057510',
    address: 'Address',
    inputAddress: 'Tokyo, Minato-ku, Kaigan 1-7-1 Tokyo Port City Takeshiba, WeWork11F',
    picPhoneNumber: 'Phone number',
    picPhoneNumberInput: 'Ex: 0701234567',
    picEmailAddress: 'Email address',
    picEmailAddressInput: 'Example: taroyamada@example.com',
    numberOfRooms: 'Number of room',
    inputRoomsNumber: 'Ex: 10000',
    standardCheckIn: 'Default check-in time',
    checkIn: 'Select time',
    standardCheckOut: 'Default check-out time',
    checkOut: 'Select time',
    registeredNumber: 'Registered Number',
    isRegisteredNumberNotObtained: 'Registered number has not been obtained',
    companyName: 'Company name',
    updateSuccess: 'Hotel has been updated successfully',
    updateError: 'Hotel has been updated unsuccessfully',
    confirm: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'OK',
        cancelTitle: 'Do you want to cancel?',
    },
    bankAccount: {
        title: 'Bank accounts',
        titleAdd: 'Add bank account',
        titleEdit: 'Edit bank account',
        titleDelete: 'Do you want to remove this bank account?',
        btnAdd: 'Bank account',
        btnDelete: 'Remove account',
        bankName: 'Bank name',
        branchName: 'Branch name',
        accountType: 'Account type',
        saving: 'Saving',
        checking: 'Checking',
        accountNumber: 'Account number',
        accountOwner: 'Account owner',
        save: 'Save',
        cancel: 'Cancel',
        noData: 'No data',
        notification: {
            successCreate: 'Create bank account successfully',
            successUpdate: 'Update bank account successfully',
            successDelete: 'Delete bank account successfully',
        },
    },
};
export default hotelSettingForm;
