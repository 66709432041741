import classNames from 'classnames';
import customDayjs from '~plugins/dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    style: React.CSSProperties;
    currentDate: string;
};

const today = customDayjs().format('YYYY-MM-DD');

export const SubHeader = ({ style, currentDate }: Props) => {
    const { t } = useTranslation();
    return (
        <div
            style={style}
            className={classNames('inventory-cell-item', 'inventory-cell-item-header', {
                today: today === currentDate,
            })}
        >
            <div className={'inventory-cell checkbox-col'}>
                {t('inventory.list.inventoryColumn.stoppingRooms')}
            </div>
            <div className="inventory-cell remain-room-col text-truncate">
                {t('inventory.list.inventoryColumn.availableRooms')}
            </div>
        </div>
    );
};
