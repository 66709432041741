export const printingEn = {
    registrationCard: 'Accommodation card',
    checkIn: 'ご到着日 (Check in)',
    checkOut: 'ご出発日 (Check out)',
    yomigana: 'フリガナ',
    name: 'お名前 (Name)',
    dateOfBirth: '生年月日 (Date of birth)',
    gender: '性別 (Gender)',
    guestGender: {
        male: 'Male',
        female: 'Female',
        other: 'Other',
    },
    homeAddress: 'ご住所 (Home address)',
    contactNumber: '電話番号 (Contact number)',
    emailAddress: 'メールアドレス (Email address)',
    occupation: 'ご職業 (Occupation)',
    companyName: '会社名 (Company name)',
    companyPhoneNumber: '会社電話番号{{break}}(Company phone number)',
    companyEmailAddress: '会社のメールアドレス{{break}}(Company email address)',
    passportNo: 'パスポート番号 (Passport number)',
    nationality: '国籍 (Nationality)',
    previousPlaceOfStay: '前泊地 (Previous place to stay)',
    nextPlaceOfStay: '後泊地 (Next place to stay)',
    memo: '備考 (Memo)',
    roomNumber: '部屋番号 (Room number)',
    signature: 'ご署名 (Signature)',
    notification: {
        noSuchRoomBooking: "You don't have any booking on this day",
        biggerGuestCards:
            'Total number of bookings is {{totalBookings}} which is bigger than limit',
        noPrintGuestCard: 'All booking items are canceled',
    },
    preparationList: {
        headerTitle: 'Preparation list ',
        date: '{{year}}/{{month}}月{{day}}/ ({{dayOfWeek}})',
        day: {
            monday: 'Mon',
            tuesday: 'Tue',
            wednesday: 'Wed',
            thursday: 'Thu',
            friday: 'Fri',
            saturday: 'Sat',
            sunday: 'Sun',
        },
        order: 'No',
    },
    cancelTitle: 'Do you want to cancel?',
};
