import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NoData } from '~components';
import { IStatisticRoomType } from '~features/dashboard/interfaces';
import { dashboardBookingOverviewSelector } from '~features/dashboard/reducers/dashboard.reducer';
import { useAppSelector } from '~hooks';
import './RoomOccupancyCard.scss';
import RoomOccupancyItem from './RoomOccupancyItem/RoomOccupancyItem';

export const RoomOccupancyCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { statisticRoomType } = useAppSelector(dashboardBookingOverviewSelector);

    return (
        <div className="room-occupancy-card-wrapper">
            <div className="title">{t('dashboard.roomOccupancyCard.title')}</div>
            {statisticRoomType?.length > 0 && (
                <div className="content">
                    <Space size={[5, 10]} wrap>
                        {statisticRoomType?.map((roomType: IStatisticRoomType) => (
                            <RoomOccupancyItem key={roomType.id} roomType={roomType} />
                        ))}
                    </Space>
                </div>
            )}
            {statisticRoomType?.length === 0 && <NoData />}
            <span className="link" onClick={(e) => navigate('/room-booking/schedule')}>
                {t('dashboard.roomOccupancyCard.link')}
            </span>
        </div>
    );
};
