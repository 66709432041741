import loginJa from './ja/login.locale';
import loginEn from './en/login.locale';

const auth = {
    ja: {
        login: loginJa,
    },
    en: {
        login: loginEn,
    },
};

export default auth;
