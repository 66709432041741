import React, { useEffect, useMemo, useState } from 'react';
import { Select, SelectProps, Form, Row, Col } from 'antd';
import { Controller } from '~plugins/hook-form';
import './SelectSaleItem.scss';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import { parseErrorMessage } from '~common/commonFunctions';
import { ISaleItemDropdown } from '~features/sale-item/interfaces';
import { DefaultOptionType } from 'antd/lib/select';
import { useAppSelector } from '~hooks';
import { saleItemListDropdownSelector } from '~features/sale-item/reducers/sale-item.reducer';
import { ISaleItem } from '~features/room-booking/interfaces';
import { differenceBy } from 'lodash';

interface ISingleSelect extends SelectProps {
    name: string;
    label: string;
    control: any;
    selectedValue?: number;
    tooltip?: LabelTooltipType;
    required?: boolean;
    selectedSaleItemIds: number[];
    additionalItems?: ISaleItem[];
}

const { Option } = Select;

export function SelectSaleItem(props: ISingleSelect) {
    const {
        tooltip,
        control,
        name,
        selectedValue,
        label,
        onChange,
        fieldNames,
        required,
        selectedSaleItemIds,
        additionalItems = [],
        ...rest
    } = props;

    const [selected, setSelected] = useState<number | string | undefined>(undefined);
    const [options, setOptions] = useState<ISaleItemDropdown[]>([]);
    const saleItemOptions = useAppSelector(saleItemListDropdownSelector);

    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue]);

    useEffect(() => {
        const transferOptions = saleItemOptions.map((saleItem: ISaleItemDropdown) => {
            const isDisable =
                (selectedSaleItemIds?.includes(saleItem.id) &&
                    saleItem.id !== selected) ||
                false;
            return {
                ...saleItem,
                isDisable,
            };
        });
        setOptions(transferOptions);
    }, [saleItemOptions, selectedSaleItemIds, selected]);

    const saleItemDropDownOptions = useMemo(() => {
        const intersectionSaleItem = differenceBy(additionalItems, options, 'id') || [];
        return [...options, ...intersectionSaleItem];
    }, [options, additionalItems]);

    return (
        <div className="single-select-sale-item-wrapper">
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';

                    const selectChange = (
                        value: string | number,
                        option: DefaultOptionType | DefaultOptionType[],
                    ) => {
                        let response: ISaleItemDropdown | string | number | undefined =
                            value;
                        setSelected(value);

                        if (value && Number(value)) {
                            response = saleItemOptions.find((saleItem) => {
                                return saleItem.id === Number(value);
                            });
                        }

                        if (onChange) {
                            onChange(response, option);
                        }

                        field.onChange(value);
                    };

                    return (
                        <Form.Item
                            required={required}
                            tooltip={tooltip}
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                        >
                            <Select
                                {...rest}
                                onBlur={field.onBlur}
                                value={selected}
                                ref={field.ref}
                                onChange={(...args) => {
                                    selectChange(...args);
                                }}
                                filterOption={(input, option) => {
                                    const filteredOptions = options.filter((option) => {
                                        return (
                                            option?.name
                                                ?.toLowerCase()
                                                ?.includes(input.toLowerCase()) ||
                                            option?.autoGeneratedCode
                                                ?.toLowerCase()
                                                ?.includes(input.toLowerCase())
                                        );
                                    });
                                    return (
                                        filteredOptions.findIndex(
                                            (saleItem) =>
                                                saleItem?.name === option?.label,
                                        ) >= 0
                                    );
                                }}
                            >
                                {saleItemDropDownOptions.map((saleItem) => {
                                    return (
                                        <Option
                                            key={saleItem.id}
                                            value={saleItem.id}
                                            label={saleItem.name}
                                            disabled={saleItem.isDisable}
                                        >
                                            <Row
                                                gutter={6}
                                                className="sale-item-options"
                                                id={`${name}-${saleItem.name}`}
                                            >
                                                <Col span={10}>
                                                    {saleItem.autoGeneratedCode}
                                                </Col>
                                                <Col span={14}>{saleItem.name}</Col>
                                            </Row>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

SelectSaleItem.defaultProps = {
    required: false,
};
