import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';

const initialState = {
    isExportCsv: false,
    isShowSalesByOtaTable: false,
    isShowByDaily: false,
    selectedDate: '',
    selectedMarketingChannelId: 0,
};

const salesByOtaSlice = createSlice({
    name: 'sales-by-ota',
    initialState,
    reducers: {
        setExportCsv: (state, action) => {
            state.isExportCsv = action.payload;
        },
        setShowSalesByOtaTable: (state, action) => {
            state.isShowSalesByOtaTable = action.payload;
        },
        setIsShowByDaily: (state, action) => {
            state.isShowByDaily = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setSelectedMarketingChannelId: (state, action) => {
            state.selectedMarketingChannelId = action.payload;
        },
    },
});

export const {
    setExportCsv,
    setShowSalesByOtaTable,
    setIsShowByDaily,
    setSelectedDate,
    setSelectedMarketingChannelId,
} = salesByOtaSlice.actions;

export const salesByOtaSelector = (state: AppState) => {
    return state.reportSalesByOta;
};

export default salesByOtaSlice.reducer;
