const smtpSettingForm = {
    title: '送信メールサーバーの登録',
    breadcrumbs: {
        home: 'ホーム',
        BOsetting: 'BO設定',
        infrastructureSettings: 'インフラ設定',
        smtpSetting: '送信メールサーバー',
    },
    serverName: '名前',
    serverNameInput: '例：SMTP 1',
    password: 'パスワード',
    smtpServer: 'SMTPサーバー',
    smtpServerInput: '例： 8765434567',
    smtpEncryption: 'SMTP暗号化',
    smtpEncryptionOption: {
        yes: 'はい',
        no: 'いいえ',
    },
    smtpPort: 'SMTPポート',
    userName: 'ユーザー名',
    userNameInput: 'example@tabist.com',
    connectionSecurity: '接続セキュリティー',
    connectionSecurityOption: {
        'SSL/TLS': 'SSL/TLS',
    },
    cancelBtn: 'キャンセル',
    submitBtn: '接続をテスト',
    message: {
        updateSuccess: 'メールサーバーが更新されました',
        updateError: 'メールサーバーの更新ができませんでした',
        cancelTitle: 'キャンセルしてもよろしいですか？',
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
    },
};
export default smtpSettingForm;
