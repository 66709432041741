const guestClassificationForm = {
    cancelBtnText: 'Cancel',
    submitBtnText: 'Register',
    editBtnText: 'Edit',
    category: 'Guest type',
    categoryInput: 'VIP',
    edit: {
        title: 'Guest type',
    },
    guestCategory: 'Guest type name',
    guestCategoryInput: 'Ex: Default',
    color: 'Color',
    create: {
        title: 'Guest type registration',
        success: 'Guest type has been created successfully',
        error: 'Failed to create guest type',
    },

    update: {
        title: 'Edit guest type',
        success: 'Guest type has been updated succcessful!',
        error: 'Failed to edit guest type',
    },
};
export default guestClassificationForm;
