import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../../constants';
import './MarketingGroupListPage.scss';
import { Layout } from 'antd';
import FilterForm from '../../components/FilterForm/FilterForm';
import MarketingChannelGroupList from '../../components/MarketingChannelGroupList/MarketingChannelGroupList';
import CreateMarketingChannelGroupForm from '../../components/CreateMarketingChannelGroupForm/CreateMarketingChannelGroupForm';
import UpdateMarketingChannelGroupsForm from '../../components/UpdateMarketingChannelGroupsForm/UpdateMarketingChannelGroupsForm';

function ChannelGroupListPage() {
    const { t } = useTranslation();
    return (
        <div className="marketing-channel-group-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('marketingChannelGroup.list.title')}
            />
            <Layout.Content>
                <div className="marketing-channel-group-page-wrapper">
                    <div className="marketing-channel-group-page-content">
                        <FilterForm />
                        <MarketingChannelGroupList />
                    </div>
                    <CreateMarketingChannelGroupForm />
                    <UpdateMarketingChannelGroupsForm />
                </div>
            </Layout.Content>
        </div>
    );
}

export default ChannelGroupListPage;
