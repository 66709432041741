import { Controller } from 'react-hook-form';
import { Select, Form } from 'antd';

import './InputDropdown.scss';

export interface InputDropDownProps {
    control: any;
    name: string;
    label?: string;
    options: { label: string; value: string | number }[];
}

export const InputDropdown: React.FC<InputDropDownProps> = ({
    control,
    name,
    label,
    options,
}) => {
    return (
        <div className="custom-input-wrapper">
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Form.Item label={label}>
                        <Select {...field}>
                            {options.map((option) => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
            />
        </div>
    );
};
