import { Select, SelectProps } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGroup } from '~features/room-booking/interfaces';

interface IProps extends SelectProps {
    selectedGroupId?: string;
    groupList: IGroup[];
    onChangeValue?: (previousValue: string | undefined, nextValue: string) => void;
    style?: CSSProperties;
}

function SelectGroup(props: IProps) {
    const { selectedGroupId, groupList, onChangeValue, onChange, style = {} } = props;

    const { t } = useTranslation();

    const [value, setValue] = useState<string | undefined>(selectedGroupId);

    useEffect(() => {
        setValue(selectedGroupId);
    }, [selectedGroupId]);

    return (
        <Select
            className="split-room-booking-group-select"
            placeholder={t(
                'roomBooking.splitReceipt.selectSplitBillTypePanel.placeholder',
            )}
            value={value}
            onChange={(...args) => {
                if (onChangeValue) {
                    onChangeValue(value, args[0]);
                }

                if (onChange) {
                    onChange(...args);
                }
            }}
            options={groupList.map((group) => ({
                value: group.id,
                label: group.name,
                disabled: group.id === selectedGroupId,
            }))}
            dropdownStyle={{ maxWidth: '300px' }}
            popupMatchSelectWidth={false}
            style={style}
        />
    );
}

export default SelectGroup;
