import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    searchFieldSelector,
    setSearchField,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { SearchField } from '~features/room-booking/constants';

const { Option } = Select;

function SelectField() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchField = useSelector(searchFieldSelector);

    const onChange = (columnName: SearchField) => {
        dispatch(setSearchField(columnName));
    };

    return (
        <Select
            defaultValue={SearchField.ALL}
            onChange={onChange}
            value={searchField}
            className="column-name-select"
        >
            {Object.values(SearchField).map((columnName, index) => {
                return (
                    <Option value={columnName} key={index}>
                        {t(`roomBooking.list.filterForm.searchColumn.${columnName}`)}
                    </Option>
                );
            })}
        </Select>
    );
}

export default SelectField;
