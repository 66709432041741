import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './FilterForm.scss';
import { useNavigate } from 'react-router-dom';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';

function FilterForm() {
    const { t } = useTranslation();
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const navigate = useNavigate();

    const createIndicator = () => {
        navigate('/front-settings/indicator/create');
    };
    if (isReadOnly) return null;
    return (
        <div className="filter-indicator-list-wrapper">
            {!isReadOnly && (
                <div>
                    <Button type="primary" onClick={createIndicator}>
                        <PlusOutlined />
                        <span>{t('indicator.list.createBtn')}</span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
