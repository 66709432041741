import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICSVBookingUploadResponse, ICSVBookingUploadState } from '../interfaces';
import { csvBookingUploadService } from '../services/csv-booking-upload.services';
import { AppState } from '~plugins/redux-toolkit/store';

const initialState: ICSVBookingUploadState = {
    csvBookingUploadData: [],
    isCSVBookingUploadLoading: false,
};

export const postCSVBookingUpload = createAsyncThunk(
    'csvBookingUpload/postCSVBookingUpload',
    async (body: FormData) => {
        return await csvBookingUploadService.postCSVBookingUpload(body);
    },
);

export const csvBookingUploadSlice = createSlice({
    name: 'csv-booking-upload',
    initialState,
    reducers: {
        setCSVBookingUploadData: (state, action) => {
            state.csvBookingUploadData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(postCSVBookingUpload.pending, (state) => {
            state.isCSVBookingUploadLoading = true;
            state.csvBookingUploadData = [];
        });
        builder.addCase(postCSVBookingUpload.fulfilled, (state, action) => {
            state.isCSVBookingUploadLoading = false;
            const arrayData: ICSVBookingUploadResponse[] = [];
            Object.entries(action.payload).forEach(([key, value]) => {
                if (key !== 'data' && key !== 'success') {
                    arrayData.push(value);
                }
            });
            state.csvBookingUploadData = arrayData;
        });
    },
});

export const csvBookingUploadSelector = (state: AppState) => {
    return state.csvBookingUploadStore;
};

export default csvBookingUploadSlice.reducer;
