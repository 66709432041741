import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../../constants';
import './MarketingChannelListPage.scss';
import { Layout } from 'antd';
import FilterForm from '../../components/FilterForm/FilterForm';
import MarketingChannelList from '../../components/MarketingChannelList/MarketingChannelList';
import UpdateMarketingChannelsForm from '../../components/UpdateMarketingChannelsForm/UpdateMarketingChannelsForm';

function MarketingChannelListPage() {
    const { t } = useTranslation();
    return (
        <div className="marketing-channel-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('marketingChannel.list.title')}
            />
            <Layout.Content>
                <div className="marketing-channel-page-wrapper">
                    <div className="marketing-channel-page-content">
                        <FilterForm />
                        <MarketingChannelList />
                    </div>
                    <UpdateMarketingChannelsForm />
                </div>
            </Layout.Content>
        </div>
    );
}

export default MarketingChannelListPage;
