import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '../../common/constants';
import { initMarketingChannelGroupListQuery, SearchField } from './constants';

import { cloneDeep, trim } from 'lodash';
import {
    IMarketingChannelGroupBulkCreateFormItem,
    IMarketingChannelGroupBulkUpdateFormItem,
    IMarketingChannelGroupDropDownQuery,
    IMarketingChannelGroupGetListQuery,
    IMarketingChannelGroupState,
} from './interfaces';
import { marketingChannelGroupsService } from './services/marketing-channel-group.api';
const initialState: IMarketingChannelGroupState = {
    marketingChannelGroupListQuery: cloneDeep(initMarketingChannelGroupListQuery),
    totalMarketingChannelGroups: 0,
    marketingChannelGroupList: [],
    createMarketingChannelGroupList: [
        {
            name: '',
        },
    ],
    updateMarketingChannelGroupList: [],
    marketingChannelGroupDropdownOptions: [],
    selectedMarketingChannelGroup: null,
    isShowUpdateMarketingChannelGroupForm: false,
    updateMarketingChannelGroupFormBusy: false,
    showLoading: false,
    formBusy: false,
    showForm: false,
    searchField: SearchField.ALL,
};

export const fetchMarketingChannelGroupList = createAsyncThunk(
    'marketingChannelGroup/fetchMarketingChannelGroupList',
    async (_, { getState }) => {
        const query = marketingChannelGroupListQuerySelector(getState() as AppState);
        return await marketingChannelGroupsService.getList(query);
    },
);

export const getMarketingChannelGroupListForDropdown = createAsyncThunk(
    'marketingChannelGroup/getMarketingChannelGroupListForDropdown',
    async (params: IMarketingChannelGroupDropDownQuery) => {
        return await marketingChannelGroupsService.getMarketingChannelGroupListForDropdown(
            { ...params, isPullFromTll: params.isPullFromTll || false },
        );
    },
);

export const getMarketingChannelGroupListByIds = createAsyncThunk(
    'marketingChannelGroup/getMarketingChannelGroupListByIds',
    async (ids: number[]) => {
        return await marketingChannelGroupsService.getListByIds(ids);
    },
);

export const bulkCreateMarketingChannelGroups = createAsyncThunk(
    'marketingChannelGroup/bulkCreateMarketingChannelGroups',
    async (formData: IMarketingChannelGroupBulkCreateFormItem[]) => {
        return await marketingChannelGroupsService.bulkCreate(formData);
    },
);

export const bulkUpdateMarketingChannelGroups = createAsyncThunk(
    'room/bulkUpdateMarketingChannelGroups',
    async (formData: IMarketingChannelGroupBulkUpdateFormItem[]) => {
        return await marketingChannelGroupsService.bulkUpdate(formData);
    },
);

export const getMarketingChannelGroupDetail = createAsyncThunk(
    'marketingChannelGroup/getMarketingChannelGroupDetail',
    async (id: number) => {
        return await marketingChannelGroupsService.getDetail(id);
    },
);

export const marketingChannelGroupSlice = createSlice({
    name: 'marketingChannelGroup',
    initialState,
    reducers: {
        setMarketingChannelGroupListQuery: (state, action) => {
            state.marketingChannelGroupListQuery = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IMarketingChannelGroupGetListQuery = {
                limit: state.marketingChannelGroupListQuery.limit,
                orderBy: state.marketingChannelGroupListQuery.orderBy,
                orderDirection: state.marketingChannelGroupListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.marketingChannelGroupListQuery = query;
        },
        setCreateMarketingChannelGroupList: (state, action) => {
            state.createMarketingChannelGroupList = action.payload;
        },
        setUpdateMarketingChannelGroupList: (state, action) => {
            state.updateMarketingChannelGroupList = action.payload;
        },
        setMarketingChannelGroupList: (state, action) => {
            state.marketingChannelGroupList = action.payload;
        },
        setTotalMarketingChannelGroup: (state, action) => {
            state.totalMarketingChannelGroups = action.payload;
        },
        setMarketingChannelGroupDropdownOptions: (state, action) => {
            state.marketingChannelGroupDropdownOptions = action.payload;
        },
        setSelectedMarketingChannelGroup: (state, action) => {
            state.selectedMarketingChannelGroup = action.payload;
        },
        setShowForm: (state, action) => {
            state.showForm = action.payload;
        },
        setIsShowUpdateMarketingChannelGroupForm: (state, action) => {
            state.isShowUpdateMarketingChannelGroupForm = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IMarketingChannelGroupGetListQuery = {
                limit: state.marketingChannelGroupListQuery.limit,
                orderBy: state.marketingChannelGroupListQuery.orderBy,
                orderDirection: state.marketingChannelGroupListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.marketingChannelGroupListQuery.keyword ||
                    state.marketingChannelGroupListQuery.autoGeneratedCode ||
                    state.marketingChannelGroupListQuery.name ||
                    '',
            };
            state.marketingChannelGroupListQuery = query;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getMarketingChannelGroupListForDropdown.fulfilled,
            (state, action) => {
                state.marketingChannelGroupDropdownOptions = (
                    action.payload?.data?.items || []
                ).map(
                    (item) =>
                        ({
                            label: item.name,
                            value: item.id,
                        } || []),
                );
            },
        );
        builder.addCase(fetchMarketingChannelGroupList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.marketingChannelGroupList = action.payload?.data?.items || [];
            state.totalMarketingChannelGroups = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchMarketingChannelGroupList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(getMarketingChannelGroupListByIds.fulfilled, (state, action) => {
            state.updateMarketingChannelGroupFormBusy = false;
            state.updateMarketingChannelGroupList = action.payload?.data || [];
        });
        builder.addCase(getMarketingChannelGroupListByIds.pending, (state, action) => {
            state.updateMarketingChannelGroupFormBusy = true;
            state.updateMarketingChannelGroupList = [];
        });
        builder.addCase(bulkCreateMarketingChannelGroups.fulfilled, (state, action) => {
            state.showLoading = false;
            if (action.payload?.success) {
                state.createMarketingChannelGroupList = [];
            }
        });
        builder.addCase(bulkCreateMarketingChannelGroups.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(getMarketingChannelGroupDetail.fulfilled, (state, action) => {});
    },
});

export const {
    setMarketingChannelGroupListQuery,
    setKeyword,
    setMarketingChannelGroupDropdownOptions,
    setCreateMarketingChannelGroupList,
    setMarketingChannelGroupList,
    setTotalMarketingChannelGroup,
    setSelectedMarketingChannelGroup,
    setUpdateMarketingChannelGroupList,
    setIsShowUpdateMarketingChannelGroupForm,
    setShowForm,
    setSearchField,
} = marketingChannelGroupSlice.actions;

export const marketingChannelGroupsSelector = (state: AppState) => {
    return state.marketingChannelGroup.marketingChannelGroupList;
};

export const marketingChannelGroupDropdownOptionsSelector = (state: AppState) => {
    return state.marketingChannelGroup.marketingChannelGroupDropdownOptions;
};

export const showLoadingSelector = (state: AppState) => {
    return state.marketingChannelGroup.showLoading;
};

export const searchFieldSelector = (state: AppState) => {
    return state.marketingChannelGroup.searchField;
};

export const marketingChannelGroupListQuerySelector = (state: AppState) => {
    return state.marketingChannelGroup.marketingChannelGroupListQuery;
};

export const formBusySelector = (state: AppState) => {
    return state.marketingChannelGroup.formBusy;
};

export const showFormSelector = (state: AppState) => {
    return state.marketingChannelGroup.showForm;
};

export const marketingChannelGroupListSelector = (state: AppState) => {
    return state.marketingChannelGroup.marketingChannelGroupList;
};

export const createMarketingChannelGroupListSelector = (state: AppState) => {
    return state.marketingChannelGroup.createMarketingChannelGroupList;
};

export const selectedMarketingChannelGroupSelector = (state: AppState) => {
    return state.marketingChannelGroup.selectedMarketingChannelGroup;
};

export const updateMarketingChannelGroupListSelector = (state: AppState) => {
    return state.marketingChannelGroup.updateMarketingChannelGroupList;
};

export const updateMarketingChannelGroupFormBusySelector = (state: AppState) => {
    return state.marketingChannelGroup.updateMarketingChannelGroupFormBusy;
};

export const isShowUpdateMarketingChannelGroupFormSelector = (state: AppState) => {
    return state.marketingChannelGroup.isShowUpdateMarketingChannelGroupForm;
};
export const totalMarketingsChannelGroupSelector = (state: AppState) => {
    return state.marketingChannelGroup.totalMarketingChannelGroups;
};

export const totalPageSelector = (state: AppState) => {
    const { totalMarketingChannelGroups, marketingChannelGroupListQuery } =
        state.marketingChannelGroup;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = marketingChannelGroupListQuery;
    return Math.ceil(totalMarketingChannelGroups / limit);
};
export default marketingChannelGroupSlice.reducer;
