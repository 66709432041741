import { Tabs } from 'antd';
import type { UploadFile as IUploadFile } from 'antd/es/upload/interface';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadFileStatus } from '~common/constants';
import { InputTextArea, UploadFileCustom } from '~components';
import {
    ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS,
    FieldTab,
    MAX_NUMBER_OF_FILES_PER_BOOKING,
    MAX_SIZE_OF_EACH_BOOKING_FILE,
} from '~features/room-booking/constants';
import { createBookingStateSelector } from '~features/room-booking/reducers/create-booking.reducer';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { useAppSelector } from '~hooks';
import './MemoAndFileTabs.scss';

type Props = {
    control: any;
    isFrozen?: boolean;
    isCreateForm?: boolean;
};
function MemoAndFileTabs(props: Props) {
    const { control, isFrozen, isCreateForm } = props;
    const { t } = useTranslation();
    const { bookingInfo } = useAppSelector(createBookingStateSelector);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const [currentTab, setCurrentTab] = useState<FieldTab>(FieldTab.MEMO);

    const onChange = (key: string) => {
        setCurrentTab(key as FieldTab);
    };

    const fileList = useMemo(() => {
        if (!selectedRoomBookingDetail?.filesInformation?.length) return [];
        return selectedRoomBookingDetail?.filesInformation?.map((file) => {
            return {
                uid: `${file.id}`,
                name: file.originalName,
                status: UploadFileStatus.DONE,
                url: file.url,
                path: file.path,
                size: file.size,
                fileName: file.fileName,
                type: file.mimetype,
            } as IUploadFile;
        });
    }, [selectedRoomBookingDetail]);

    const fileCreateList = useMemo(() => {
        if (!bookingInfo?.files?.length) return [];
        return bookingInfo?.files?.map((file) => {
            return {
                uid: `${file.id}`,
                name: file.originalName,
                status: UploadFileStatus.DONE,
                url: file.url,
                path: file.path,
                size: file.size,
                fileName: file.fileName,
                type: file.mimetype,
            } as IUploadFile;
        });
    }, [bookingInfo]);

    return (
        <Tabs
            activeKey={currentTab}
            onChange={onChange}
            className="memo-tab"
            items={[
                {
                    key: FieldTab.MEMO,
                    label: t('roomBooking.createBooking.memo'),
                    children: (
                        <InputTextArea
                            placeholder={t('roomBooking.createBooking.memoPlaceholder')}
                            name="memo"
                            control={control}
                            disabled={isFrozen}
                            label=""
                            rows={6}
                        />
                    ),
                },
                {
                    key: FieldTab.OTA_MEMO,
                    label: t('roomBooking.createBooking.otaMemo'),
                    children: (
                        <InputTextArea
                            placeholder={t(
                                'roomBooking.createBooking.otaMemoPlaceholder',
                            )}
                            name="otaMemo"
                            control={control}
                            label=""
                            disabled={isFrozen}
                            rows={6}
                        />
                    ),
                },
                {
                    key: FieldTab.FILE,
                    label: t('roomBooking.createBooking.file'),
                    children: (
                        <UploadFileCustom
                            title={t('common.uploadFile.title')}
                            subTitle={t('common.uploadFile.hint')}
                            name="files"
                            control={control}
                            label=""
                            pathKey="room-booking"
                            maxCount={MAX_NUMBER_OF_FILES_PER_BOOKING}
                            maxSize={MAX_SIZE_OF_EACH_BOOKING_FILE}
                            multiple={true}
                            listType="picture"
                            acceptTypes={ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS}
                            defaultFileList={isCreateForm ? fileCreateList : fileList}
                            disabled={isFrozen}
                        />
                    ),
                },
            ]}
        />
    );
}

export default MemoAndFileTabs;
