import { IErrorItem } from '~common/interfaces';

export const FETCH_TAX_SUMMARY_REQUEST = 'FETCH_TAX_SUMMARY_REQUEST';
export const FETCH_TAX_SUMMARY_SUCCESS = 'FETCH_TAX_SUMMARY_SUCCESS';
export const FETCH_TAX_SUMMARY_FAIL = 'FETCH_TAX_SUMMARY_FAIL';

export const SET_SELECTED_MONTH = 'SET_SELECTED_MONTH';

export const SET_SHOW_TAX_DETAIL_TABLE = 'SET_SHOW_TAX_DETAIL_TABLE';

type TFetchTaxSummaryRequestAction = {
    type: typeof FETCH_TAX_SUMMARY_REQUEST;
};

type TFetchTaxSummarySuccessAction = {
    type: typeof FETCH_TAX_SUMMARY_SUCCESS;
};

type TFetchTaxSummaryFailAction = {
    type: typeof FETCH_TAX_SUMMARY_FAIL;
    payload: IErrorItem[] | Error | null | undefined;
};

type TSetSelectedMonthAction = {
    type: typeof SET_SELECTED_MONTH;
    payload: string;
};

export type TAction =
    | TSetSelectedMonthAction
    | TFetchTaxSummaryRequestAction
    | TFetchTaxSummarySuccessAction
    | TFetchTaxSummaryFailAction;
