import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import './PageFooter.scss';
import { Footer } from 'antd/lib/layout/layout';
interface IProps {
    submit: any;
    disabled: boolean;
}
const PageFooter = ({ submit, disabled }: IProps) => {
    const { t } = useTranslation();
    return (
        <Footer className="integrations-page-fotter">
            <Button onClick={submit} disabled={disabled} type="primary">
                {t('integrations.integrations.save')}
            </Button>
        </Footer>
    );
};
export default PageFooter;
