const siteController = {
    title: 'サイトコントローラー設定',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        siteController: 'サイトコントローラー設定',
    },
    form: {
        description:
            'シーナッツ社から通知される「連動用ユーザID」、「連動用パスワード」を設定してください。',
        accountSetting: 'アカウント設定',
        tllName: { label: 'サイトコントローラー' },
        tllBookingAcquisitionUrl: {
            label: '連携用URL',
            placeholder: 'https://www.tl-lincoln.net/',
        },
        tllPmsUserId: { label: '連携用ID', placeholder: 'ユーザーID入力' },
        tllPmsPassword: { label: '連携用パスワード', placeholder: 'パスワード入力' },
    },
    toggleBox: {
        description:
            '下記のスイッチをオフにするとサイトコントローラーとの連携を一時的に停止します。連携停止後、もう一度連携をする場合はスイッチをオンに戻し連携を再開してください。',
        content: 'サイトコントローラとの連携:',
        on: 'ON',
        off: 'OFF',
    },
    confirm: {
        title: 'サイトコントローラとの連携を一時停止しますか？',
        okText: '一時停止する',
    },
    footer: {
        cancelBtn: 'キャンセル',
        submitBtn: '保存',
    },
    confirmCancel: 'キャンセルしてもよろしいですか？',
    notification: {
        success: 'サイトコントローラが更新されました',
        error: 'エラーが起こりました',
        connectedSiteController: 'サイトコントローラが接続されました',
        disconnectedSiteController: 'サイトコントローラの接続が解除されました',
    },
};
export default siteController;
