import i18next from 'i18next';

export const breadcrumbs = () => [
    {
        text: i18next.t('integrations.integrations.breadcrumbs.home'),
    },
    {
        text: i18next.t('integrations.integrations.breadcrumbs.integrations'),
    },
];
