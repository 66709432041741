import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '~hooks';
import NotificationFormWrapper from '~features/admin-notification/components/NotificationFormWrapper/NotificationFormWrapper';

import './UpdateNotificationPage.scss';
import {
    getNotificationDetail,
    showLoadingSelector,
} from '~features/admin-notification/reducers/notification.reducer';
function UpdateNotificationPage() {
    const dispatch = useAppDispatch();
    const { notificationId } = useParams();
    const navigate = useNavigate();
    const isLoading = useAppSelector(showLoadingSelector);
    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(getNotificationDetail(id));
        if (getNotificationDetail.fulfilled.match(response)) {
            if (!response.payload.data) {
                navigate('/404');
            }
        }
    }, []);

    useEffect(() => {
        if (typeof Number(notificationId) === 'number') {
            _getDetail(Number(notificationId));
        } else {
            navigate('/404');
        }
    }, []);
    return (
        <div className="update-notification-page">
            <Spin spinning={isLoading}>
                <NotificationFormWrapper />
            </Spin>
        </div>
    );
}

export default UpdateNotificationPage;
