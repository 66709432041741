import React from 'react';
import { useAppDispatch, useAppSelector } from '~hooks';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { roomTypeDropdownOptionsSelector } from '~features/room-type/room-type.reducer';
import {
    individualRoomListSelector,
    setIndividualRoomList,
} from '~features/room/room.reducer';
import { Button, Form, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import {
    CREATE_ROOMS_FORM_HEIGHT,
    IndividualRoomTableColumn,
    MAX_ROOMS,
} from '../../constants';
import { IRoomBulkCreateFormItem } from '../../interfaces';
import { InputText, SingleSelect } from '~components';
import './CreateIndividualRoomsFormRoomList.scss';
import { getIndividualRoomsFormId } from '~features/room/helpers';

interface IProp {
    control: any;
    currentIndividualRoomList: IRoomBulkCreateFormItem[];
}

function CreateIndividualRoomsFormRoomList(props: IProp) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const individualRoomList = useAppSelector(individualRoomListSelector);
    const roomTypeOptions = useAppSelector(roomTypeDropdownOptionsSelector);

    const onClickButtonAdd = () => {
        dispatch(
            setIndividualRoomList([
                ...props.currentIndividualRoomList,
                {
                    name: '',
                    roomTypeId: null,
                    feId: `${Date.now()}_${individualRoomList.length}`,
                },
            ]),
        );
    };

    const onClickButtonRemove = (room: IRoomBulkCreateFormItem) => {
        dispatch(
            setIndividualRoomList(
                props.currentIndividualRoomList.filter(
                    (individualRoom) => individualRoom.feId !== room.feId,
                ),
            ),
        );
    };

    return (
        <div className="individual-rooms-form-room-list-wrapper">
            <Form layout="vertical">
                <Table
                    dataSource={individualRoomList}
                    pagination={false}
                    locale={{
                        emptyText: <div></div>,
                    }}
                    rowKey="feId"
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={onClickButtonAdd}
                            disabled={individualRoomList.length >= MAX_ROOMS}
                            className="button-add"
                            id={getIndividualRoomsFormId('add-room')}
                        >
                            <PlusCircleOutlined className="right-register-form-icon" />
                            <span>{t('room.roomFormIndividual.addRoomBtn')}</span>
                        </Button>
                    )}
                    scroll={{ y: CREATE_ROOMS_FORM_HEIGHT }}
                >
                    <Column
                        align="left"
                        width={50}
                        title={`${t('room.roomFormIndividual.id')}`}
                        dataIndex=""
                        key={IndividualRoomTableColumn.ID}
                        render={(room: IRoomBulkCreateFormItem, item, index) => {
                            return <div>{index + 1}</div>;
                        }}
                    />
                    <Column
                        title={`${t('room.roomFormIndividual.roomName')}`}
                        dataIndex=""
                        key={IndividualRoomTableColumn.NAME}
                        render={(room: IRoomBulkCreateFormItem, item, index) => {
                            return (
                                <InputText
                                    label=""
                                    placeholder={t('room.roomFormIndividual.roomName')}
                                    name={`items.${index}.name`}
                                    control={props.control}
                                    id={getIndividualRoomsFormId(`items.${index}.name`)}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('room.roomFormIndividual.roomType')}`}
                        ellipsis={true}
                        dataIndex=""
                        width="35%"
                        key={IndividualRoomTableColumn.ID}
                        render={(
                            individualRoom: IRoomBulkCreateFormItem,
                            item,
                            index,
                        ) => {
                            return (
                                <SingleSelect
                                    label=""
                                    placeholder={t(
                                        'room.roomFormIndividual.placeholder.roomType',
                                    )}
                                    name={`items.${index}.roomTypeId`}
                                    control={props.control}
                                    options={roomTypeOptions}
                                    id={getIndividualRoomsFormId(
                                        `items.${index}.roomTypeId`,
                                    )}
                                />
                            );
                        }}
                    />

                    <Column
                        title={`${t('room.roomFormIndividual.delete')}`}
                        align="center"
                        width={80}
                        dataIndex=""
                        key={IndividualRoomTableColumn.ACTION}
                        render={(room: IRoomBulkCreateFormItem, _, index) => {
                            return (
                                <Button
                                    id={getIndividualRoomsFormId(
                                        `items.${index}.roomTypeId`,
                                    )}
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    onClick={() => onClickButtonRemove(room)}
                                />
                            );
                        }}
                    />
                </Table>
            </Form>
        </div>
    );
}

export default CreateIndividualRoomsFormRoomList;
