import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './FilterForm.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '~hooks';
import { setIsShowCreateForm } from '~features/facility-type/facility-type.reducer';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const createFaclityType = () => {
        dispatch(setIsShowCreateForm(true));
    };

    return (
        <div className="facility-type-filter-form-wrapper">
            <div className="btn-group">
                <Button
                    type="primary"
                    id={`btn-create-facility-type`}
                    className="btn-room"
                    onClick={createFaclityType}
                >
                    <PlusOutlined />
                    <span className="btn-text">
                        {t('facilityType.list.filterForm.buttons.create')}
                    </span>
                </Button>
            </div>
        </div>
    );
}

export default FilterForm;
