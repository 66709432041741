import { Modal } from 'antd';
import classNames from 'classnames';
import {
    CSSProperties,
    FC,
    useCallback,
    MutableRefObject,
    ReactNode,
    useEffect,
    useState,
    WheelEvent,
} from 'react';
import {
    IDropData,
    IGetBookingPrice,
    IRoomBookingSchedule,
} from '~features/room-booking/interfaces';
import { getBookingPrice } from '~features/room-booking/reducers/create-booking.reducer';
import { useDrag, useDragDropManager } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { VariableSizeGrid } from 'react-window';
import {
    scheduleStateSelector,
    setCurrentDragData,
    setIsDragging,
    updateBookingItem,
    updateBookingItemAndTmp,
} from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { showConfirm } from '~common/notification';

const styleDefault: CSSProperties = {
    position: 'absolute',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    zIndex: 2,
};

export interface BoxProps {
    booking: IRoomBookingSchedule;
    hideSourceOnDrag?: boolean;
    children?: ReactNode;
    style?: CSSProperties;
    left: number;
    top: number;
    gridRef: MutableRefObject<VariableSizeGrid>;
    outerRef: MutableRefObject<HTMLElement | undefined>;
    gridViewContentHeightRef?: MutableRefObject<number | undefined>;
    width: number;
    endDrag: (booking: IRoomBookingSchedule, monitor: any) => void;
    onMouseLeave?: (e: any) => void;
    onMouseEnter?: (e: any) => void;
    onMouseDown?: (e: any) => void;
    onWheel?: (e: WheelEvent<HTMLDivElement>) => void;
}

export const DragBox: FC<BoxProps> = ({
    booking,
    hideSourceOnDrag,
    children,
    style,
    left,
    top,
    gridRef,
    outerRef,
    gridViewContentHeightRef,
    width,
    endDrag,
    onMouseLeave,
    onMouseEnter,
    onMouseDown,
    onWheel,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { currentDragData } = useAppSelector(scheduleStateSelector);
    const [isClickHold, setIsClickHold] = useState(false);

    const _getBookingPrice = useCallback(
        async (toRoomId: number) => {
            const planId = booking.plan?.id || 0;
            const numberOfAdults = booking.numberOfAdults || 0;
            const query: IGetBookingPrice = {
                roomTypeId: toRoomId,
                startDateOfStay: booking.stayingStartDate,
                endDateOfStay: booking.stayingEndDate,
                numberOfAdults: Number(numberOfAdults),
                planId: planId,
                childrenTypeQuantities: booking.children.map((item, index) => ({
                    childrenTypeId: item.typeId as number,
                    quantity: item.quantity as number,
                })),
                id: booking.id,
            };
            const response = await dispatch(getBookingPrice(query));
            if (getBookingPrice.fulfilled.match(response)) {
                const { data } = response.payload;
                if (data) {
                    return data.amount || 0;
                }
            }
        },
        [booking, dispatch],
    );

    const updateBooking = async (
        item: IRoomBookingSchedule,
        dropResult: IDropData,
        isUpdateReceipt: boolean,
    ) => {
        const {
            roomId: toRoomId,
            roomTypeId: toTypeId,
            roomName,
            roomTypeName,
        } = dropResult;
        const newBooking = {
            ...booking,
            room: { name: roomName, id: toRoomId },
            roomId: toRoomId,
            roomType: { name: roomTypeName, id: toTypeId },
            roomTypeId: toTypeId,
            roomKey: `${toTypeId}-${toRoomId}`,
        };
        dispatch(
            updateBookingItemAndTmp({
                bookings: [newBooking],
            }),
        );
        const response = await dispatch(
            updateBookingItem({
                booking: {
                    ...item,
                    room: { name: roomName, id: toRoomId },
                    roomType: { name: roomTypeName, id: toTypeId },
                },
                isUpdateReceipt,
            }),
        );

        if (updateBookingItem.fulfilled.match(response)) {
            if (response.payload?.success) {
                const price = await _getBookingPrice(toTypeId);
                dispatch(
                    updateBookingItemAndTmp({
                        bookings: [{ ...newBooking, price }],
                    }),
                );
                return;
            }
            Modal.error({
                title: t('common.somethingWentWrong'),
                content: response.payload.message,
                centered: true,
            });
            dispatch(
                updateBookingItemAndTmp({
                    bookings: [
                        {
                            ...booking,
                        },
                    ],
                }),
            );
        }
    };

    const showConfirmChangeReceipt = (
        item: IRoomBookingSchedule,
        dropResult: IDropData,
    ) => {
        showConfirm({
            okText: t('roomBooking.detail.confirmUpdateReceipt.notUpdateButton'),
            cancelText: t('roomBooking.detail.confirmUpdateReceipt.updateButton'),
            title: t(
                'roomBooking.detail.confirmUpdateReceipt.changeRoomTypeConfirmTitle',
            ),
            onOk: async () => {
                updateBooking(item, dropResult as IDropData, false);
            },
            onCancel: async () => {
                updateBooking(item, dropResult as IDropData, true);
            },
        });
    };

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'box',
            item: booking,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            isDragging: (monitor) => {
                return (
                    currentDragData?.booking?.id === booking.id ||
                    booking.id === monitor.getItem().id
                );
            },
            end: async (item, monitor) => {
                dispatch(setIsDragging(false));
                dispatch(setCurrentDragData(null));
                setIsClickHold(false);
                endDrag(item, monitor);
                const dropResult = monitor.getDropResult();
                if (!dropResult) return;
                const { roomId: toRoomId, roomTypeId: toTypeId } =
                    dropResult as IDropData;
                if (toRoomId === booking.room.id && toTypeId === booking.roomType.id) {
                    return;
                }
                if (toRoomId && toTypeId) {
                    if (toTypeId !== booking.roomType?.id) {
                        showConfirmChangeReceipt(item, dropResult as IDropData);
                    } else {
                        updateBooking(item, dropResult as IDropData, true);
                    }
                }
            },
        }),
        [booking],
    );

    const dragDropManager = useDragDropManager();
    const monitor = dragDropManager.getMonitor();

    // in charge of scroll when dragging from top or bottom
    useEffect(
        () =>
            monitor.subscribeToOffsetChange(() => {
                const offset = monitor.getClientOffset();
                const GRID_VIEW_HEIGHT = gridViewContentHeightRef?.current ?? 0;
                const LOC_ON_TOP_TO_SCROLL = window.innerHeight - GRID_VIEW_HEIGHT; // top of grid view
                const LOC_ON_BTM_TO_SCROLL = window.innerHeight - 24; // padding from bottom
                let IS_MAX_BOTTOM_SCROLL = false;

                const {
                    scrollTop,
                    scrollLeft,
                }: {
                    scrollTop: number;
                    scrollLeft: number;
                } = {
                    scrollLeft: 0,
                    scrollTop: 0,
                    ...gridRef.current.state,
                };

                if (GRID_VIEW_HEIGHT && GRID_VIEW_HEIGHT && outerRef.current) {
                    IS_MAX_BOTTOM_SCROLL =
                        outerRef.current.scrollHeight - GRID_VIEW_HEIGHT <= scrollTop;
                }

                if (offset && offset.y <= LOC_ON_TOP_TO_SCROLL) {
                    gridRef.current.scrollTo({
                        scrollLeft: scrollLeft,
                        scrollTop: scrollTop - (LOC_ON_TOP_TO_SCROLL - offset.y), // higher the position of drag, faster it scrolls upwards
                    });
                } else if (
                    offset &&
                    !IS_MAX_BOTTOM_SCROLL &&
                    offset.y >= LOC_ON_BTM_TO_SCROLL
                ) {
                    gridRef.current.scrollTo({
                        scrollLeft: scrollLeft,
                        scrollTop: scrollTop + 15, // rate of downward scroll set to constant
                    });
                }
            }),
        [gridRef, outerRef, gridViewContentHeightRef, monitor],
    );

    useEffect(() => {
        dispatch(setIsDragging(isDragging));
        dispatch(
            setCurrentDragData(
                isDragging
                    ? {
                          roomId: booking.room.id,
                          typeId: booking.roomType.id,
                          booking,
                      }
                    : null,
            ),
        );
    }, [isDragging, booking]);

    if (isDragging && hideSourceOnDrag) {
        return <div ref={drag} />;
    }

    return (
        <div
            className={classNames('drag-box')}
            ref={drag}
            style={{
                ...styleDefault,
                ...style,
                left,
                top,
                width,
                display: !width ? 'none' : 'block',
                cursor: isClickHold ? 'move' : 'pointer',
            }}
            id={`drag-box-${booking.id}`}
            onMouseEnter={(e) => {
                document
                    .getElementById(`drag-box-${booking.id}`)
                    ?.classList?.add('drag-box-hover');
                onMouseEnter?.(e);
            }}
            onMouseLeave={(e) => {
                document
                    .getElementById(`drag-box-${booking.id}`)
                    ?.classList?.remove('drag-box-hover');
                onMouseLeave?.(e);
                dispatch(setIsDragging(false));
            }}
            onMouseDown={(e) => {
                setIsClickHold(true);
                if (onMouseDown) onMouseDown(e);
            }}
            onMouseUp={() => {
                setIsClickHold(false);
            }}
            onWheel={(e) => {
                if (onWheel) onWheel(e);
            }}
        >
            {children}
        </div>
    );
};
