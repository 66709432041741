import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import {
    IDropDownQuery,
    IRoomType,
    IRoomTypeCreateBody,
    IRoomTypeExportCsvQuery,
    IRoomTypeListDropdownResponse,
    IRoomTypeListResponse,
    IRoomtypeResponseDetail,
    IRoomTypeBulkDeleteItem,
    IImportRoomType,
    IImportRoomTypeBodyItem,
    IRoomTypeDisplayOrderBody,
    IRoomTypeDisplayOrderResponse,
} from '../interfaces';
import { IBodyResponse, ICsvFile, ICommonListQuery } from '~common/interfaces';
import { IBulkSaveStoppingRoomCountForm } from '~features/inventory/interfaces';

class RoomTypeService extends ApiService {
    get stoppingInventoryUrl() {
        return `/stopping-inventory`;
    }

    getDetail(id: number) {
        return this._getDetail(id) as Promise<IRoomtypeResponseDetail>;
    }

    getList(queryString: ICommonListQuery) {
        return this._getList(queryString) as Promise<IRoomTypeListResponse>;
    }

    create(createBody: IRoomTypeCreateBody) {
        // TODO: replace hotel id here
        return this._create<IRoomTypeCreateBody, IRoomTypeListResponse>(createBody);
    }

    update(id: number, body: IRoomTypeCreateBody) {
        return this._update<IRoomTypeCreateBody, IBodyResponse<IRoomType>>(id, body);
    }

    bulkDelete(bulkDeleteBody: IRoomTypeBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    getRoomTypeListForDropdown(params?: IDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<IRoomTypeListDropdownResponse>;
    }
    exportCsv(query: IRoomTypeExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }

    bulkSaveStoppingRoomCount(bulkSaveBody: IBulkSaveStoppingRoomCountForm[]) {
        return this._post(`${this.stoppingInventoryUrl}/init-bulk-save`, {
            items: bulkSaveBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    importRoomTypeCsv(importRoomTypeBody: IImportRoomTypeBodyItem[]) {
        return this._post(`${this.baseUrl}/import-csv`, {
            items: importRoomTypeBody,
        }) as Promise<IBodyResponse<IImportRoomType[]>>;
    }

    updateRoomTypeDisplayOrder(body: IRoomTypeDisplayOrderBody) {
        return this._patch(`${this.baseUrl}/display-order`, body) as Promise<
            IBodyResponse<IRoomTypeDisplayOrderResponse>
        >;
    }
}
export const roomTypeService = new RoomTypeService(
    { baseUrl: '/room-type' },
    axiosService,
);
