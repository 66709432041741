import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import { IGuest } from '~features/room-booking/interfaces';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import './RoomBookingAccompanierList.scss';

function RoomBookingAccompanierList() {
    const { t } = useTranslation();
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const [page, setPage] = useState(1);

    const accompanierList = useMemo(() => {
        return (
            selectedRoomBookingDetail?.guests?.filter((guest) => {
                return guest.id !== selectedRoomBookingDetail?.representativeGuest?.id;
            }) || []
        );
    }, [selectedRoomBookingDetail]);

    const accompanierColumns: ColumnsType<IGuest> = [
        {
            title: '#',
            render: (guest, _, index) => {
                return (
                    <span>{(page - 1) * DEFAULT_LIMIT_FOR_PAGINATION + index + 1}</span>
                );
            },
            width: '50px',
        },
        {
            title: t('roomBooking.createBooking.label.yomigana'),
            render: (guest: IGuest) => {
                return <span>{guest.yomigana}</span>;
            },
            ellipsis: true,
        },
        {
            title: t('roomBooking.createBooking.label.name'),
            render: (guest: IGuest) => {
                return <span>{guest.fullName}</span>;
            },
            ellipsis: true,
        },
        {
            title: t('roomBooking.createBooking.label.gender'),
            render: (guest: IGuest) => {
                return (
                    <span>
                        {guest.gender ? t(`guest.detail.gender.${guest.gender}`) : ''}
                    </span>
                );
            },
            ellipsis: true,
        },
        {
            title: t('roomBooking.createBooking.label.birthday'),
            render: (guest: IGuest) => {
                return (
                    <span>
                        {guest.birthday ? parseDate(guest.birthday)?.fmYYYYMMDD('/') : ''}
                    </span>
                );
            },
            ellipsis: true,
        },
        {
            title: t('roomBooking.createBooking.label.phone'),
            render: (guest: IGuest) => {
                return (
                    <span>
                        {guest.mobilePhoneNumber || guest.phoneNumberLandline || ''}
                    </span>
                );
            },
            ellipsis: true,
        },
    ];

    return (
        <div className="room-booking-accompanier-list">
            <Table
                columns={accompanierColumns}
                dataSource={accompanierList}
                pagination={
                    accompanierList.length > DEFAULT_LIMIT_FOR_PAGINATION
                        ? {
                              position: ['bottomCenter'],
                              pageSize: DEFAULT_LIMIT_FOR_PAGINATION,
                              onChange(current) {
                                  setPage(current);
                              },
                          }
                        : false
                }
                rowKey="id"
                scroll={{ y: 380 }}
            />
        </div>
    );
}

export default RoomBookingAccompanierList;
