import React from 'react';
import { InputSearch } from '~components';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import './FilterForm.scss';
import { useAppDispatch } from '~hooks';
import { GA_KEYWORD_CONTENT, INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { setKeyword, setShowForm } from '../../hotel.reducer';
import SelectField from './SelectField/SelectField';
import { trackSearchKeyword } from '~plugins/analytics';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.HOTEL);
    };

    const addHotel = () => {
        dispatch(setShowForm(true));
    };
    return (
        <div className="filter-hotel-wrapper">
            <div className="hotel-left-block">
                <InputSearch
                    placeholder={t('common.searchPlaceholder')}
                    onSearch={onSearch}
                    max={INPUT_TEXT_MAX_LENGTH}
                    addonBefore={SelectField()}
                />
            </div>

            <div className="hotel-btn">
                <Button type="primary" onClick={addHotel}>
                    <PlusOutlined />
                    <span className="btn-text">{t('hotel.list.primaryButton')}</span>
                </Button>
            </div>
        </div>
    );
}

export default FilterForm;
