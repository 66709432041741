import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Modal, notification, Space } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { showConfirm } from '~common/notification';
import {
    ChangingBookingStatusesMap,
    FacilityBookingStatus,
} from '~features/facility-booking/constants';
import { IFacilityBookingScheduleItem } from '~features/facility-booking/interfaces';
import { updateFacilityBookingStatus } from '~features/facility-booking/reducers/facility-booking.reducer';
import {
    removeBooking,
    setFacilityBookingCheckIn,
    setShowCheckInForm,
    updateBooking,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { useAppDispatch } from '~hooks';
import customDayjs, { parseDate } from '~plugins/dayjs';

type Props = {
    onClose?: () => void;
    booking: IFacilityBookingScheduleItem;
    navigate?: NavigateFunction;
    onChangeStatusSuccess?: (booking: IFacilityBookingScheduleItem) => void;
};

export const StatusDropdown = ({
    onClose,
    booking,
    navigate,
    onChangeStatusSuccess,
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const checkInBooking = () => {
        if (
            booking.id &&
            ChangingBookingStatusesMap[booking.status].includes(
                FacilityBookingStatus.STARTED,
            )
        ) {
            if (parseDate(booking.checkInDateTime).isAfter(customDayjs(), 'minute')) {
                notification.error({
                    message: t(
                        'facilityBooking.schedule.message.cannotCheckIn.fromReserved',
                        {
                            date: parseDate(booking.checkInDateTime)?.fmYYYYMMDDHHmm('-'),
                        },
                    ),
                });
                return;
            }
            dispatch(setFacilityBookingCheckIn(booking));
            dispatch(setShowCheckInForm(true));
            if (onClose) {
                onClose();
            }
            return;
        }

        Modal.error({
            content: t('facilityBooking.list.statusModalConfirm.errorChangeStatus', {
                beforeStatus: t(`facilityBooking.list.bookingStatus.${booking.status}`),
                afterStatus: t(
                    `facilityBooking.list.bookingStatus.${FacilityBookingStatus.STARTED}`,
                ),
            }),
            okText: t('facilityBooking.list.statusModalConfirm.okText'),
        });
        if (onClose) {
            onClose();
        }
    };

    const checkOutBooking = () => {
        if (onClose) {
            onClose();
        }
        showConfirm({
            title: t('facilityBooking.schedule.modalConfirmCheckout.title'),
            content: t('facilityBooking.schedule.modalConfirmCheckout.description'),
            cancelText: t('facilityBooking.schedule.modalConfirmCheckout.cancelBtn'),
            okText: t('facilityBooking.schedule.modalConfirmCheckout.okBtn'),
            onOk: handleCheckOutBooking,
        });
    };

    const handleCheckOutBooking = async () => {
        if (booking.id) {
            if (booking.status === FacilityBookingStatus.RESERVED) {
                Modal.error({
                    content: t('facilityBooking.list.statusModalConfirm.error'),
                    okText: t('facilityBooking.list.statusModalConfirm.okText'),
                });
                return;
            }
            const updateData = {
                items: [
                    {
                        id: booking.id,
                        order: 1,
                    },
                ],
                status: FacilityBookingStatus.FINISHED,
            };
            const response = await dispatch(updateFacilityBookingStatus(updateData));
            if (updateFacilityBookingStatus.fulfilled.match(response)) {
                if (response.payload?.success) {
                    Modal.success({
                        content: t('facilityBooking.schedule.modalCheckoutSuccess.title'),
                        okText: t('facilityBooking.schedule.modalCheckoutSuccess.okBtn'),
                        okType: 'default',
                    });
                    dispatch(
                        updateBooking({
                            booking: {
                                ...booking,
                                status: FacilityBookingStatus.FINISHED,
                            },
                        }),
                    );
                    if (onChangeStatusSuccess) {
                        onChangeStatusSuccess({
                            ...booking,
                            status: FacilityBookingStatus.FINISHED,
                        });
                    }
                } else {
                    showConfirm({
                        title: t('facilityBooking.schedule.modalCheckoutFailed.title'),
                        content: t(
                            'facilityBooking.schedule.modalCheckoutFailed.description',
                        ),
                        cancelText: t(
                            'facilityBooking.schedule.modalCheckoutFailed.cancelBtn',
                        ),
                        okText: t('facilityBooking.schedule.modalCheckoutFailed.okBtn'),
                        onOk() {
                            if (navigate) {
                                navigate(`/facility-booking/${booking.id}/detail`);
                            }
                        },
                    });
                }
            }
        }
    };

    const cancelBooking = () => {
        if (onClose) {
            onClose();
        }
        showConfirm({
            title: t('facilityBooking.schedule.modalConfirmCancel.title'),
            onOk: handleCancelBooking,
        });
    };

    const handleCancelBooking = async () => {
        if (booking.id) {
            const updateData = {
                items: [
                    {
                        id: booking.id,
                        order: 1,
                    },
                ],
                status: FacilityBookingStatus.CANCELLED,
            };
            const response = await dispatch(updateFacilityBookingStatus(updateData));
            if (updateFacilityBookingStatus.fulfilled.match(response)) {
                if (response.payload?.success) {
                    Modal.success({
                        content: t('facilityBooking.list.statusModalConfirm.success', {
                            status: t(
                                `facilityBooking.list.bookingStatus.${updateData.status}`,
                            ),
                        }),
                        okText: t('facilityBooking.list.statusModalConfirm.okText'),
                    });
                    dispatch(removeBooking(booking.id));
                    if (onChangeStatusSuccess) {
                        onChangeStatusSuccess({
                            ...booking,
                            status: FacilityBookingStatus.CANCELLED,
                        });
                    }
                } else {
                    Modal.error({
                        content: t('facilityBooking.list.statusModalConfirm.error'),
                        okText: t('facilityBooking.list.statusModalConfirm.okText'),
                    });
                }
            }
        }
    };

    const reservedBooking = () => {
        if (onClose) {
            onClose();
        }
        showConfirm({
            title: t('facilityBooking.schedule.modalConfirmReserve.title'),
            onOk: handleReservedBooking,
        });
    };

    const handleReservedBooking = async () => {
        if (booking.id) {
            const updateData = {
                items: [
                    {
                        id: booking.id,
                        order: 1,
                    },
                ],
                status: FacilityBookingStatus.RESERVED,
            };
            const response = await dispatch(updateFacilityBookingStatus(updateData));
            if (updateFacilityBookingStatus.fulfilled.match(response)) {
                if (response.payload?.success) {
                    Modal.success({
                        content: t('facilityBooking.list.statusModalConfirm.success', {
                            status: t(
                                `facilityBooking.list.bookingStatus.${updateData.status}`,
                            ),
                        }),
                        okText: t('facilityBooking.list.statusModalConfirm.okText'),
                    });
                    dispatch(
                        updateBooking({
                            booking: {
                                ...booking,
                                status: FacilityBookingStatus.RESERVED,
                            },
                        }),
                    );
                    if (onChangeStatusSuccess) {
                        onChangeStatusSuccess({
                            ...booking,
                            status: FacilityBookingStatus.RESERVED,
                        });
                    }
                } else {
                    Modal.error({
                        content: t('facilityBooking.list.statusModalConfirm.error'),
                        okText: t('facilityBooking.list.statusModalConfirm.okText'),
                    });
                }
            }
        }
    };

    const items = useMemo(() => {
        return ChangingBookingStatusesMap[booking?.status]?.map(
            (status: FacilityBookingStatus) => ({
                key: status,
                label: t(`facilityBooking.schedule.status.${status}`),
                onClick: () => {
                    if (status === FacilityBookingStatus.STARTED) {
                        return checkInBooking();
                    }
                    if (status === FacilityBookingStatus.FINISHED) {
                        return checkOutBooking();
                    }
                    if (status === FacilityBookingStatus.CANCELLED) {
                        return cancelBooking();
                    }
                    if (status === FacilityBookingStatus.RESERVED) {
                        return reservedBooking();
                    }
                },
            }),
        );
    }, []);

    return (
        <Dropdown
            menu={{ items, className: 'facility-booking-detail-status-menu' }}
            trigger={['click']}
        >
            <Button type="primary">
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        {t('facilityBooking.schedule.statusDropdown.title')}
                        <DownOutlined />
                    </Space>
                </a>
            </Button>
        </Dropdown>
    );
};
