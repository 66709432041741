import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { OrderDirection } from '~common/constants';
import { ColumnSorter } from '~components';
import { VariableSizeList as List, VariableSizeList } from 'react-window';
import { MutableRefObject } from 'react';
import { HEADER_HEIGHT } from '~features/dashboard/constants';

type ColumnData = {
    width: number;
    label: string;
    columnKey: string;
    sorter?: boolean;
};
type Prop = {
    items: ColumnData[];
    scrollbarWidth?: number;
    orderDirection?: OrderDirection | null;
    orderBy?: string | null | undefined;
    onClick?: any;
    width: number;
    headerRef: MutableRefObject<VariableSizeList>;
    renderColumnWidth: (columnIndex: number) => number;
};

function onChangeDirection(
    orderDirection: OrderDirection | null | undefined,
    orderBy: string | null | undefined,
    item: ColumnData,
) {
    if (orderDirection === OrderDirection.ASC && orderBy === item.columnKey)
        return OrderDirection.DESC;
    else if (orderDirection === OrderDirection.DESC && orderBy === item.columnKey)
        return null;
    else return OrderDirection.ASC;
}

function Header(props: Prop) {
    const { items, scrollbarWidth = 0, renderColumnWidth, width, headerRef } = props;
    const { t } = useTranslation();
    const renderTitle = (
        orderDirection: OrderDirection | null | undefined,
        orderBy: string | null | undefined,
        item: ColumnData,
    ) => {
        if (orderDirection === OrderDirection.ASC && orderBy === item.columnKey)
            return t('dashboard.sort.desc');
        else if (orderDirection === OrderDirection.DESC && orderBy === item.columnKey)
            return t('dashboard.sort.cancel');
        return t('dashboard.sort.asc');
    };
    const getItemSize = (index: number) => renderColumnWidth(index);
    const Column = ({ index, style }: any) => {
        const item = items[index];
        return (
            <Tooltip
                placement="top"
                title={renderTitle(props.orderDirection, props.orderBy, item)}
                key={index}
            >
                <div
                    key={index}
                    className={classNames('row-header-cell', {
                        isSorted: props.orderBy === item.columnKey,
                    })}
                    style={{ ...style }}
                    onClick={() => {
                        if (item.sorter !== false) {
                            props.onClick({
                                field: item.label,
                                columnKey: item.columnKey,
                                order: onChangeDirection(
                                    props.orderDirection,
                                    props.orderBy,
                                    item,
                                ),
                            });
                        }
                    }}
                >
                    {item.label}
                    {item.sorter !== false && (
                        <ColumnSorter
                            orderDirection={props.orderDirection}
                            isSorted={props.orderBy === item.columnKey}
                        />
                    )}
                </div>
            </Tooltip>
        );
    };
    return (
        <div
            className="booking-column-header-wrapper"
            style={{ paddingRight: `${scrollbarWidth}px` }}
        >
            <List
                ref={headerRef}
                className="header-list"
                height={HEADER_HEIGHT}
                itemCount={items.length}
                itemSize={getItemSize}
                layout="horizontal"
                width={width}
                style={{
                    overflow: 'hidden',
                }}
            >
                {Column}
            </List>
        </div>
    );
}

export default Header;
