import React, { ChangeEvent } from 'react';
import { Input, InputProps, Form } from 'antd';
import { Controller } from '~plugins/hook-form';
import './InputNumber.scss';
import { fullWidthNumConvert, parseErrorMessage } from '~common/commonFunctions';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { ErrorMessageType, Regex } from '~common/constants';

interface CustomInput extends InputProps {
    name: string;
    label: string;
    decimal: number;
    min: number;
    isInt: boolean;
    isShowIconArrow: boolean;
    defaultValue: string | number;
    control: any;
    max?: number;
    hideHelpText?: boolean;
}

export function InputNumber(props: CustomInput) {
    const {
        control,
        name,
        defaultValue,
        label,
        decimal,
        isInt,
        onChange,
        max,
        min,
        required,
        isShowIconArrow,
        addonAfter = null,
        hideHelpText,
        onBlur,
        ...rest
    } = props;
    const intPattern = new RegExp(Regex.INT_PATTERN);
    const floatPattern = new RegExp(Regex.FLOAT_PATTERN);

    return (
        <div
            className={classNames('custom-input-number-wrapper', {
                'hide-help-text': hideHelpText,
            })}
        >
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message
                        ? fieldState.error?.type === ErrorMessageType.WARNING
                            ? 'warning'
                            : 'error'
                        : '';
                    return (
                        <Form.Item
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            required={required}
                        >
                            <Input
                                {...field}
                                {...rest}
                                onChange={(event) => {
                                    const val = event.target.value;
                                    if (val && isInt && !intPattern.test(val)) {
                                        event.target.value = '';
                                        return;
                                    }
                                    if (
                                        val &&
                                        !intPattern.test(val) &&
                                        !floatPattern.test(val)
                                    ) {
                                        event.target.value = '';
                                        return;
                                    }
                                    if (max && Number(val) > max) {
                                        event.target.value = max.toString();
                                    }

                                    if (onChange) {
                                        onChange(event);
                                    }
                                    field.onChange(event);
                                }}
                                onBlur={(event) => {
                                    const value = event.target.value;
                                    if (Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                                        event.target.value =
                                            fullWidthNumConvert(value) || '';
                                        if (onChange) {
                                            onChange(event);
                                        }
                                        field.onChange(event);
                                    }
                                    if (onBlur) onBlur(event);
                                }}
                                addonAfter={
                                    isShowIconArrow ? (
                                        <div className="arrow-control">
                                            <UpOutlined
                                                onClick={() => {
                                                    if (rest.disabled) return;
                                                    let value = field.value;
                                                    if (
                                                        Regex.FULL_WIDTH_CHARACTERS.test(
                                                            value,
                                                        )
                                                    ) {
                                                        value =
                                                            fullWidthNumConvert(value);
                                                    }
                                                    const val = Number(value) + 1;
                                                    if (max && val > max) return;
                                                    field.onChange(val);
                                                    if (onChange) {
                                                        const event: ChangeEvent<HTMLInputElement> =
                                                            {
                                                                target: {
                                                                    value: `${val}`,
                                                                },
                                                            } as ChangeEvent<HTMLInputElement>;
                                                        onChange(event);
                                                    }
                                                }}
                                            />

                                            <DownOutlined
                                                onClick={(e) => {
                                                    if (rest.disabled) return;
                                                    let value = field.value;
                                                    if (
                                                        Regex.FULL_WIDTH_CHARACTERS.test(
                                                            value,
                                                        )
                                                    ) {
                                                        value =
                                                            fullWidthNumConvert(value);
                                                    }
                                                    const val = Number(value) - 1;
                                                    if (val < min) return;
                                                    field.onChange(val);
                                                    if (onChange) {
                                                        const event: ChangeEvent<HTMLInputElement> =
                                                            {
                                                                target: {
                                                                    value: `${val}`,
                                                                },
                                                            } as ChangeEvent<HTMLInputElement>;
                                                        onChange(event);
                                                    }
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        addonAfter
                                    )
                                }
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

InputNumber.defaultProps = {
    defaultValue: '',
    isInt: true,
    isShowIconArrow: false,
    decimal: 2,
    min: 0,
};
