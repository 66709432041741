import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';

import { IBodyResponse } from '~common/interfaces';
import { IChild } from '../interfaces';

class ChildrenService extends ApiService {
    getDropdown() {
        return this._get(`${this.baseUrl}/dropdown`) as Promise<
            IBodyResponse<{ items: IChild[] }>
        >;
    }
}

export const childrenService = new ChildrenService(
    { baseUrl: '/children-type' },
    axiosService,
);
