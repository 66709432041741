import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { ReactComponent as ScanKeycardIcon } from '~assets/images/scan_keycard.svg';
import { ReactComponent as CheckCircleIcon } from '~assets/images/check_circle.svg';
import { ReactComponent as CircleErrorIcon } from '~assets/images/circle_error.svg';
import './ReissueKeycardModal.scss';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

enum ScanState {
    SCANNING = 'scanning',
    SUCCESS = 'success',
    FAILED = 'failed',
}

type Prop = {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

function ReissueKeycardModal({ isModalOpen, setIsModalOpen }: Prop) {
    const { t } = useTranslation();
    const [cardScanState, setCardScanState] = useState<ScanState>(ScanState.SCANNING);

    useEffect(() => {
        // to simulate scanning success or failed
        if (isModalOpen) {
            setCardScanState(ScanState.SCANNING);
            setTimeout(() => {
                const randomState =
                    Math.random() < 0.5 ? ScanState.SUCCESS : ScanState.FAILED;
                setCardScanState(randomState);
            }, 1500);
        }
    }, [isModalOpen]);

    const reissueIcon = useMemo(() => {
        switch (cardScanState) {
            case ScanState.SCANNING:
                return <ScanKeycardIcon />;
            case ScanState.SUCCESS:
                return <CheckCircleIcon />;
            case ScanState.FAILED:
                return <CircleErrorIcon />;
        }
    }, [cardScanState]);

    const reissueTitle = useMemo(() => {
        switch (cardScanState) {
            case ScanState.SCANNING:
                return (
                    <div className="reissue-modal__title">
                        {t('roomBooking.detail.keycard.reissueState.scanning')}
                    </div>
                );
            case ScanState.SUCCESS:
                return (
                    <div className="reissue-modal__title reissue-modal__title-success">
                        {t('roomBooking.detail.keycard.reissueState.success')}
                    </div>
                );
            case ScanState.FAILED:
                return (
                    <div className="reissue-modal__title reissue-modal__title-failed">
                        {t('roomBooking.detail.keycard.reissueState.failed')}
                    </div>
                );
        }
    }, [cardScanState]);

    return (
        <Modal
            title={t('roomBooking.detail.keycard.reIssueCard')}
            open={isModalOpen}
            centered
            onCancel={() => setIsModalOpen(false)}
            width={440}
            className="reissue-modal"
            footer={false}
        >
            <div className="reissue-modal__wrapper">
                {reissueIcon}
                {reissueTitle}
                {cardScanState === ScanState.SCANNING && (
                    <Button size="large" block onClick={() => setIsModalOpen(false)}>
                        {t('roomBooking.detail.keycard.cancel')}
                    </Button>
                )}
                {cardScanState === ScanState.FAILED && (
                    <Button type="primary" size="large" block>
                        {t('roomBooking.detail.keycard.tryAgain')}
                    </Button>
                )}
                {[ScanState.FAILED, ScanState.SUCCESS].includes(cardScanState) && (
                    <Button size="large" block onClick={() => setIsModalOpen(false)}>
                        {t('roomBooking.detail.keycard.close')}
                    </Button>
                )}
            </div>
        </Modal>
    );
}

export default ReissueKeycardModal;
