import HttpMiddlewareService from './service';
import axios, { AxiosRequestConfig } from 'axios';
import CommonMiddleware from './middlewares/commonMiddleware';
import AuthMiddleware from './middlewares/authMiddleware';

const options: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL_PMS,
    responseType: 'json',
};

const optionsV2: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL_PMS_V2,
    responseType: 'json',
};

const axiosInstance = axios.create(options);

const axiosInstanceV2 = axios.create(optionsV2);

const axiosService = new HttpMiddlewareService(axiosInstance);
axiosService.register([new CommonMiddleware(), new AuthMiddleware()]);

const axiosServiceV2 = new HttpMiddlewareService(axiosInstanceV2);
axiosServiceV2.register([new CommonMiddleware(), new AuthMiddleware()]);

export default axiosInstance;
export { axiosInstanceV2 };
