import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { ITagDropDownQuery, ITagState } from '../interfaces';
import { tagService } from '../services/tag.service';

const initialState: ITagState = {
    tagListForDropDowm: [],
};

export const getTagListForDropdown = createAsyncThunk(
    'tag/getListForDropdowm',
    async (params: ITagDropDownQuery) => {
        return await tagService.getDropdown(params);
    },
);

const tagSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {
        setTagListForDropdown: (state, action) => {
            state.tagListForDropDowm = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTagListForDropdown.fulfilled, (state, action) => {
            state.tagListForDropDowm = action.payload?.data?.items || [];
        });
    },
});

export const { setTagListForDropdown } = tagSlice.actions;

export const tagListDropdownSelector = (state: AppState) => {
    return state.tag.tagListForDropDowm;
};

export default tagSlice.reducer;
