const roomList = {
    title: '部屋設定',
    breadcrumbs: {
        home: 'ホーム',
        roomSetting: '部屋設定',
        createIndividual: '部屋設定の登録',
    },
    numberOfRoom: '部屋数: ',
    roomColumn: {
        num: '#',
        id: 'ID',
        name: '部屋名',
        type: '部屋タイプ名',
        connectingRooms: 'コネクティングルーム',
    },
    importErrorKey: {
        name: '部屋名',
        roomTypeName: '部屋タイプ名',
        relatedRoomNames: 'コネクティングルーム',
    },
    filterForm: {
        buttonCreateRoomWithConnecting: 'コネクティングルーム設定',
        buttonCreateManyRooms: '部屋の種類と各部屋の追加',
        buttonRoomOrderSettings: '部屋順序設定',
    },
    message: {
        deleteRoomsSuccess: '部屋を削除しました',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '部屋名',
        roomTypeName: '部屋タイプ名',
        connectingRoom: 'コネクティングルーム',
    },

    exportColumns: {
        name: '部屋名',
        roomTypeName: '部屋タイプ名',
        connectingRoom: 'コネクティングルーム',
        autoGeneratedCode: 'ID',
    },
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
    },
    deleteErrorModal: {
        title: 'エラー',
        description: '以下の部屋を削除できませんでした。',
    },
};

export default roomList;
