import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse } from '~common/interfaces';
import { ISmtpResponse, ISmtpSettingItem } from '../interfaces';

class SmtpsService extends ApiService {
    getSmtpSetting() {
        return this._get(`${this.baseUrl}`, {}) as Promise<
            IBodyResponse<ISmtpSettingItem[]>
        >;
    }

    updateSmtpSetting(updateBody: ISmtpSettingItem[]) {
        return this._patch(`${this.baseUrl}`, {
            items: updateBody,
        }) as Promise<IBodyResponse<ISmtpResponse>>;
    }
}

export const smtpService = new SmtpsService(
    { baseUrl: '/general-setting/smtp' },
    axiosService,
);
