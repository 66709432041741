import { Layout } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import {
    resetState,
    salesPaymentStateSelector,
} from '~features/report/reducers/report-sales-payment.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { salesPaymentBreadcrumbs } from '../../constants';
import FilterForm from './components/FilterForm/FilterForm';
import SalesPaymentTable from './components/SalesPaymentTable/SalesPaymentTable';
import './SalesPaymentPage.scss';

const { Content } = Layout;

function SalesPaymentPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isShowTable } = useAppSelector(salesPaymentStateSelector);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);

    return (
        <div className="sales-payment-page-wrapper">
            <StaffLayoutHeader
                breadcrumbs={[...salesPaymentBreadcrumbs()]}
                title={t('report.salesPayment.titlePage')}
            />
            <Content>
                <div className="room-page-wrapper">
                    <FilterForm />
                    {isShowTable && <SalesPaymentTable />}
                </div>
            </Content>
        </div>
    );
}

export default SalesPaymentPage;
