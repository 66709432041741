import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { trim } from 'lodash';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
import { IIndicatorItemDropDown } from '~features/indicator/interfaces';
import { AppState } from '~plugins/redux-toolkit/store';
import { IFacilityOrderBy, SearchField } from './constants';
import {
    IFacilitiesBulkCreateItem,
    IFacilityBulkUpdateFormItem,
    IFacilityDropDownListQuery,
    IFacilityGetListQuery,
    IFacilityState,
} from './interfaces';
import { facilityService } from './services/facility.api';

const initialState: IFacilityState = {
    facilityListQuery: {
        keyword: '',
        name: '',
        facilityTypeName: '',
        autoGeneratedCode: '',
        limit: DEFAULT_LIMIT_FOR_PAGINATION,
        orderBy: IFacilityOrderBy.ID,
        orderDirection: DEFAULT_ORDER_DIRECTION,
        page: DEFAULT_FIRST_PAGE,
    },
    totalFacilities: 0,
    showLoading: false,
    facilityList: [],
    createFacilityList: [],
    isShowUpdateFacilityForm: false,
    updateFacilityList: [], // selected facilities to bulk update
    updateFacilityFormBusy: false,
    searchField: SearchField.ALL,
    facilityDropDownOptions: [],
    facilityDropDownList: [],
};

export const fetchFacilityList = createAsyncThunk(
    'faciltiy/getFacilityList',
    (_, { getState }) => {
        const query = facilityListQuerySelector(getState() as AppState);
        return facilityService.getFacilityList(query);
    },
);

export const bulkCreateFacilities = createAsyncThunk(
    'facility/bulkCreateFacilities',
    async (formData: IFacilitiesBulkCreateItem[]) => {
        return await facilityService.bulkCreate(formData);
    },
);

export const getFacilityListByIds = createAsyncThunk(
    'facility/getFacilityListByIds',
    async (ids: number[]) => {
        return await facilityService.getListByIds(ids);
    },
);

export const bulkUpdateFacilities = createAsyncThunk(
    'facility/bulkUpdateFacilities',
    async (bulkUpdateBody: IFacilityBulkUpdateFormItem[]) => {
        return await facilityService.bulkUpdate(bulkUpdateBody);
    },
);

export const fetchFacilityDropDown = createAsyncThunk(
    'facility/fetchFacilityDropDown',
    async (query: IFacilityDropDownListQuery) => {
        return await facilityService.getDropDown(query);
    },
);

export const facilitySlice = createSlice({
    name: 'facility',
    initialState,
    reducers: {
        setFacilityListQuery: (state, action) => {
            state.facilityListQuery = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IFacilityGetListQuery = {
                limit: state.facilityListQuery.limit,
                orderBy: state.facilityListQuery.orderBy,
                orderDirection: state.facilityListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.facilityListQuery = query;
        },
        setFacilityList: (state, action) => {
            state.facilityList = action.payload;
        },
        setTotalFacilities: (state, action) => {
            state.totalFacilities = action.payload;
        },
        setCreateFacilityList: (state, aciton) => {
            state.createFacilityList = aciton.payload;
        },
        setIsShowUpdateFacilityForm: (state, action) => {
            state.isShowUpdateFacilityForm = action.payload;
        },
        setUpdateFacilityList: (state, action) => {
            state.updateFacilityList = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IFacilityGetListQuery = {
                limit: state.facilityListQuery.limit,
                orderBy: state.facilityListQuery.orderBy,
                orderDirection: state.facilityListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.facilityListQuery.keyword ||
                    state.facilityListQuery.autoGeneratedCode ||
                    state.facilityListQuery.name ||
                    state.facilityListQuery.facilityTypeName ||
                    '',
            };
            state.facilityListQuery = query;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFacilityList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.facilityList = action.payload?.data?.items || [];
            state.totalFacilities = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchFacilityList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(bulkCreateFacilities.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(bulkCreateFacilities.fulfilled, (state, action) => {
            state.showLoading = false;
            if (action.payload?.success) {
                state.createFacilityList = [];
            }
        });
        builder.addCase(getFacilityListByIds.pending, (state, action) => {
            state.updateFacilityFormBusy = true;
        });
        builder.addCase(getFacilityListByIds.fulfilled, (state, action) => {
            state.updateFacilityFormBusy = false;
            state.updateFacilityList = action.payload?.data || [];
        });
        builder.addCase(bulkUpdateFacilities.pending, (state, action) => {
            state.updateFacilityFormBusy = true;
        });
        builder.addCase(bulkUpdateFacilities.fulfilled, (state, action) => {
            state.updateFacilityFormBusy = false;
            if (action.payload?.success) {
                state.updateFacilityList = [];
            }
        });
        builder.addCase(fetchFacilityDropDown.fulfilled, (state, action) => {
            state.facilityDropDownList = action.payload?.data?.items || [];
            state.facilityDropDownOptions = (action.payload?.data?.items || []).map(
                (item) =>
                    ({
                        label: item.name,
                        value: item.id,
                    } || []),
            );
        });
    },
});

export const {
    setFacilityListQuery,
    setKeyword,
    setFacilityList,
    setTotalFacilities,
    setCreateFacilityList,
    setIsShowUpdateFacilityForm,
    setUpdateFacilityList,
    setSearchField,
    resetState,
} = facilitySlice.actions;

export const facilityListStateSelector = (state: AppState) => {
    return state.facility;
};

export const facilityListSelector = (state: AppState) => {
    return state.facility.facilityList;
};

export const facilityListQuerySelector = (state: AppState) => {
    return state.facility.facilityListQuery;
};

export const totalFacilitiesSelector = (state: AppState) => {
    return state.facility.totalFacilities;
};

export const searchFieldSelector = (state: AppState) => {
    return state.facility.searchField;
};

export const showLoadingSelector = (state: AppState) => {
    return state.facility.showLoading;
};

export const totalPageSelector = (state: AppState) => {
    const { totalFacilities, facilityListQuery } = state.facility;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = facilityListQuery;
    return Math.ceil(totalFacilities / limit);
};

export const createFacilityListSelector = (state: AppState) => {
    return state.facility.createFacilityList;
};

export const isShowUpdateFacilityFormSelector = (state: AppState) => {
    return state.facility.isShowUpdateFacilityForm;
};

export const updateFacilityListSelector = (state: AppState) => {
    return state.facility.updateFacilityList;
};

export const updateFacilityFormBusySelector = (state: AppState) => {
    return state.facility.updateFacilityFormBusy;
};

export const facilityDropDownOptionsSelector = (state: AppState) => {
    return state.facility.facilityDropDownOptions;
};

export const facilityDropDownGroupSelector = (state: AppState) => {
    const { facilityDropDownList } = state.facility;
    const list: Record<string, IIndicatorItemDropDown> = {};
    facilityDropDownList.forEach((item) => {
        const key = 'type_' + item.facilityType.id;
        const _item = {
            ...item,
            parentId: item.facilityType.id,
            parentName: item.facilityType.name,
            abbreviation: item.facilityType.abbreviation,
        };
        if (!list[key]) {
            list[key] = {
                id: item.facilityType.id,
                name: item.facilityType.name,
                children: [_item],
            };
        } else {
            list[key].children.push(_item);
        }
    });
    return list;
};

export default facilitySlice.reducer;
