import { DayOfWeek } from './../../../../common/constants';
export const dashboardJa = {
    controlOverlay: {
        bookingOverview: '本日の状況',
        bookingList: '予約状況',
        notification: 'お知らせ',
        roomOccupancy: '本日の残室数',
        contact: 'サポート/お問い合わせ',
    },
    bookingList: {
        columns: {
            id: '予約ID',
            guestName: '名前 (ヨミガナ）',
            fullName: '宿泊者名',
            time: 'C/I - C/O',
            room: '部屋名',
            facility: '設備名',
            totalAmount: '未収金',
            status: 'ステータス',
            receptionDate: '受付日',
            memo: 'メモ',
            roomCleanStatus: '清掃状況',
        },
        roomCleanStatus: {
            cleaned: '清掃済',
            uncleaned: '未清掃',
        },
        tab: {
            hotelReservation: '宿泊予約',
            todayReservation: '本日の未到着予約',
            newArrivalReservation: '新着の予約',
            facilityReservation: '設備予約',
        },
    },
    bookingOverview: {
        totalReservations: '予約数',
        todayCheckin: '本日のチェックイン',
        notArrivedYet: '未到着',
        arrived: '到着',
        todayCheckout: '本日のチェックアウト',
        scheduledDeparture: '出発予定',
        departed: '出発済',
        todayOccupationRate: '本日の稼働率',
        totalGuests: '滞在中合計人数',
        dayOfWeek: {
            [DayOfWeek.SUNDAY]: '日',
            [DayOfWeek.MONDAY]: '月',
            [DayOfWeek.TUESDAY]: '火',
            [DayOfWeek.WEDNESDAY]: '水',
            [DayOfWeek.THURSDAY]: '木',
            [DayOfWeek.FRIDAY]: '金',
            [DayOfWeek.SATURDAY]: '土',
        },
    },
    roomOccupancyCard: {
        title: '本日の残室数',
        link: 'スケジュールから予約を確認',
    },
    notificationCard: {
        title: 'お知らせ',
        new: 'NEW',
        contentCard: '「{{title}}」{{content}} ',
        closingBooking: '【締め作業】締められない予約があります',
    },
    contactCard: {
        title: 'サポートセンターへのお問い合わせ',
        phone: '0570-066-906',
        email: 'partner.support@tabist.co.jp',
    },
    sort: {
        asc: 'クリックして昇順に並び替える',
        desc: 'クリックして降順に並び替える',
        cancel: 'クリックして並び替えを止める',
    },
};
