import React from 'react';
import { Checkbox, CheckboxProps, Form } from 'antd';
import { Controller } from '~plugins/hook-form';
import './CheckboxGroup.scss';
import { IDropDownOption } from '~common/interfaces';
import { parseErrorMessage } from '~common/commonFunctions';

const CheckboxGroupAntd = Checkbox.Group;

interface ICheckboxGroup extends CheckboxProps {
    name: string;
    required: boolean;
    label: string;
    defaultValue: string | number;
    control: any;
    options: never[] | IDropDownOption[];
}

export function CheckboxGroup(props: ICheckboxGroup) {
    const { control, name, defaultValue, label, onChange, required, ...rest } = props;
    return (
        <div className="checkbox-group-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            label={''}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            required={required}
                        >
                            <CheckboxGroupAntd
                                {...rest}
                                value={field.value ? field.value : null}
                                ref={field.ref}
                                onChange={(...agrs) => {
                                    field.onChange(...agrs);
                                }}
                            ></CheckboxGroupAntd>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

CheckboxGroup.defaultProps = {
    defaultValue: null,
    required: false,
    options: [],
    disableValues: [],
};
