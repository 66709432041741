import {
    IDashboardBookingOverview,
    IDashboardBookingOverviewQuery,
} from './../interfaces';
import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBookingTableItem, IGetBookingQuery } from '../interfaces';
import { IBodyResponse } from '~common/interfaces';

class DashboardService extends ApiService {
    getDashboardBookingOverview(params: IDashboardBookingOverviewQuery) {
        return this._get(`${this.baseUrl}/booking-overview`, {
            params,
        }) as Promise<IBodyResponse<IDashboardBookingOverview>>;
    }

    getBookingList(query: IGetBookingQuery) {
        return this._get(this.baseUrl + '/booking-list', {
            params: query,
        }) as Promise<IBodyResponse<{ items: IBookingTableItem[]; totalItems: number }>>;
    }
}

export const dashboardService = new DashboardService(
    { baseUrl: '/dashboard' },
    axiosService,
);
