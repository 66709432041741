import { paymentMethodFormJa } from './ja/payment-method-form.locale';
import { paymentMethodListJa } from './ja/payment-method-list.locale';
import { paymentMethodFormEn } from './en/payment-method-form.locale';
import { paymentMethodListEn } from './en/payment-method-list.locale';
const paymentMethod = {
    ja: {
        list: paymentMethodListJa,
        form: paymentMethodFormJa,
    },
    en: {
        list: paymentMethodListEn,
        form: paymentMethodFormEn,
    },
};

export default paymentMethod;
