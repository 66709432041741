import {
    CalendarOutlined,
    EditOutlined,
    LeftOutlined,
    RightOutlined,
    StopOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, SupportLanguage, UserGroup } from '~common/constants';
import { currentLanguageSelector } from '~common/reducers/app.reducer';
import { useThrottle } from '~common/useHooks';
import { DatePicker } from '~components';
import { setIsShowBulkStopSellingForm } from '~features/inventory/reducers/inventory-stop-selling.reducer';
import {
    getInventoryListData,
    inventoryListStateSelector,
    setCurrentDate,
    setIsEditMode,
    setSelectedDate,
} from '~features/inventory/reducers/inventory.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import localeJa from 'antd/es/date-picker/locale/ja_JP';
import localeEn from 'antd/es/date-picker/locale/en_US';

function TimeControl() {
    const { t } = useTranslation();
    const { currentDate, isEditMode } = useAppSelector(inventoryListStateSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const { throttle } = useThrottle();
    const currentLang = useAppSelector(currentLanguageSelector);

    const onBackDate = () => {
        throttle(() => {
            let _currentDate = parseDate(currentDate).subtract(1, 'month');
            if (_currentDate.month() === customDayjs().month()) {
                _currentDate = customDayjs();
            }
            dispatch(setCurrentDate(_currentDate?.fmYYYYMMDD()));
            dispatch(getInventoryListData());
        }, 1000);
    };
    const onNextDate = () => {
        throttle(() => {
            dispatch(
                setCurrentDate(parseDate(currentDate).add(1, 'month')?.fmYYYYMMDD()),
            );
            dispatch(getInventoryListData());
        }, 1000);
    };

    const DatePickerLanguage = useMemo(() => {
        if (currentLang === SupportLanguage.EN) return localeEn;
        return localeJa;
    }, [currentLang]);

    const selectOneDate = (date: Dayjs) => {
        setShowDatePicker(false);
        dispatch(setSelectedDate(date.fmYYYYMMDD()));
        if (!parseDate(date).isSame(parseDate(currentDate), 'month')) {
            dispatch(
                setCurrentDate(
                    parseDate(date).isSame(customDayjs(), 'month')
                        ? customDayjs().fmYYYYMMDD()
                        : date.startOf('month')?.fmYYYYMMDD(),
                ),
            );
            dispatch(getInventoryListData());
        }
    };

    const openDatePickerChange = (open: boolean) => {
        setShowDatePicker(open);
    };

    const disabledDate = (current: Dayjs) => {
        return current.isBefore(customDayjs(), 'day');
    };

    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const openBulkStopSellingModal = () => {
        dispatch(setIsShowBulkStopSellingForm(true));
    };

    const goToStopSellingPage = () => {
        navigate('stop-selling');
    };

    const changeEditMode = () => {
        dispatch(setIsEditMode(true));
    };

    return (
        <div className="inventory-time-control-wrapper">
            <div className="action-form-top">
                <div>
                    <Button
                        type="link"
                        className="text-button"
                        onClick={() => {
                            setShowDatePicker(true);
                        }}
                    >
                        <CalendarOutlined />
                        {t('inventory.list.dateDesignation')}
                    </Button>
                    <DatePicker
                        className="date-picker"
                        open={showDatePicker}
                        locale={DatePickerLanguage}
                        onChange={selectOneDate}
                        disabledDate={disabledDate}
                        onOpenChange={openDatePickerChange}
                    />
                </div>
                <div>
                    <Button
                        className="previous-button"
                        onClick={onBackDate}
                        disabled={
                            parseDate(currentDate)?.fmYYYYMM() <= customDayjs().fmYYYYMM()
                        }
                    >
                        <LeftOutlined />
                    </Button>
                    <Button className="next-button" onClick={onNextDate}>
                        <RightOutlined />
                    </Button>
                </div>
            </div>
            <div className="action-form-bottom">
                {!isReadOnly && (
                    <>
                        <Button
                            type="link"
                            className="text-button stop-selling-setting-button"
                            onClick={goToStopSellingPage}
                            disabled={isEditMode}
                        >
                            <StopOutlined />
                            <span className="label-button">
                                {t('inventory.list.stopSellingButton')}
                            </span>
                        </Button>
                        <Button
                            type="link"
                            className="text-button"
                            onClick={changeEditMode}
                            disabled={isEditMode}
                        >
                            <EditOutlined />
                            {t('inventory.list.editButton')}
                        </Button>
                        {isEditMode && (
                            <Button
                                type="link"
                                className="text-button"
                                onClick={openBulkStopSellingModal}
                            >
                                <StopOutlined />
                                {t('inventory.list.bulkStopSellingButton')}
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default memo(TimeControl);
