import { PhoneOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createBookingStateSelector } from '~features/room-booking/reducers/create-booking.reducer';
import { useAppSelector } from '~hooks';
import './GuestInfoCard.scss';

export const GuestInfoCard = () => {
    const { t } = useTranslation();
    const { bookingInfo } = useAppSelector(createBookingStateSelector);
    return (
        <Card
            className="guest-info-card"
            title={t('roomBooking.confirmPage.reservationContact')}
        >
            <div className="text-truncate">
                {bookingInfo.representativeGuest?.fullName}
            </div>
            <div className="name text-truncate">
                {bookingInfo.representativeGuest?.yomigana}
            </div>
            {bookingInfo.representativeGuest?.phone && (
                <div className="phone pr-8 text-truncate">
                    <PhoneOutlined className="mr-5" />
                    {bookingInfo.representativeGuest?.phone}
                </div>
            )}
            <div className="email text-truncate">
                {bookingInfo.representativeGuest?.emailAddress}
            </div>
        </Card>
    );
};
