const csvBookingUpload = {
    title: 'CSV予約移行',
    breadcrumbs: {
        backOffice: 'BO設定',
        csvBookingUpload: 'CSV予約移行',
    },
    buttons: {
        uploadFile: 'ファイルを選択',
        submit: '実行',
    },
    notifications: {
        success: 'CSV予約移行は成功しました',
    },
    statusMessages: {
        notCSVFileType: 'CSV以外のファイルタイプを登録できません',
        generalError: 'エラーが発生しました',
        successStatus: 'CSV予約移行は成功しました。',
        successStatusSubtext: '登録した件数：{{numberOfItems}}件',
    },
    errorMessages: {
        bookingAlreadyExist: '予約IDはPMSにある予約と重複しています',
        invalidOTACode: 'OTAコードは登録できません',
        invalidPlanName: 'プラン名は登録できません',
        invalidRoomType: '部屋タイプは登録できません',
        invalidReserver: '予約者情報は登録できません',
        invalidGuest: '宿泊者情報は登録できません',
        invalidAmount: '料金は登録できません',
        invalidCheckInTime: 'チェックイン時間は登録できません',
        invalidMailAddress: 'メールアドレスは登録できません',
        invalidStatus: 'ステータスは登録できません',
        invalidNumberOfDays: '滞在期間は登録できません',
        paymentMethodNotFound: '支払方法は登録できません',
        fileNotUploaded: 'ファイルは見つかりませんでした',
        loggedInHotelIdIsDifferentFromCSVHotelId: 'CSVの施設IDは使用中の施設と異なります',
        maxLineLimitExceeded: 'CSVの行数が登録可能な最大行数を超えています',
        noContentFound: 'アップロードされたCSVに登録可能な中身は見つかりませんでした',
        // CSV Parse Errors
        tabistHotelIdEmpty: 'tabist_hotel_idは必須項目です。',
        groupBookingIdEmpty: 'group_booking_idは必須項目です。',
        reservationNameEmpty: 'reservation_nameは必須項目です。',
        reservationFuriganaEmpty: 'reservation_person_furiganaは必須項目です。',
        planNameEmpty: 'plan_nameは必須項目です。',
        reservationPhoneNumberEmpty: 'reservation_person_phone_numberは必須項目です。',
        roomTypeEmpty: 'room_typeは必須項目です。',
        numberOfPeopleEmpty: 'number_of_peopleは有効な数字でなければなりません。',
        reservationDateInvalid: 'reservation_dateは日付でなければなりません。',
        checkInDateInvalid: 'check_in_dateは日付でなければなりません。',
        checkOutDateInvalid: 'check_out_dateは日付でなければなりません。',
        totalAmountInvalid: 'total_amountは有効な数字でなければなりません。',
        advanceAmountInvalid: 'advance_deposit_amountは有効な数字でなければなりません。',
        pointsInvalid: 'amount_of_points_usedは有効な数字でなければなりません。',
        checkInTimeEmpty: 'check_in_timeは必須項目です。',
        statusEmpty: 'statusは必須項目です。',
        bookingCreationFailed: '予約の登録に失敗しました。',
    },
};

export default csvBookingUpload;
