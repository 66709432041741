import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';
import {
    childrenTypeListSelector,
    setIsShowForm,
    setSelectedChildrenTypeList,
    showLoadingListSelector,
} from '~features/children-type/reducers/children-type.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './FilterForm.scss';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showLoadingList = useAppSelector(showLoadingListSelector);

    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const childrenTypeList = useAppSelector(childrenTypeListSelector);

    const isDisableAddButton = useMemo(
        () => childrenTypeList.length >= 10 || showLoadingList,
        [childrenTypeList, showLoadingList],
    );

    const createChildrenType = () => {
        dispatch(setIsShowForm(true));
        dispatch(setSelectedChildrenTypeList([]));
    };
    if (isReadOnly) return null;

    return (
        <div className="children-type-filter-form-wrapper">
            {!isReadOnly && (
                <div className="btn-group">
                    <Button
                        type="primary"
                        id={`btn-create-children-type`}
                        className="btn-room"
                        onClick={createChildrenType}
                        disabled={isDisableAddButton}
                    >
                        <PlusOutlined />
                        <span className="btn-text">
                            {t('childrenType.list.filterForm.buttons.create')}
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
