import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { salesByOtaSelector } from '~features/report/reducers/sales-by-ota.reducer';
import { useAppSelector } from '~hooks';
import { CacheKeys } from '~queries/queries';
import { ISalesByOtaResponse } from '../interfaces';
import DailySalesByOtaTable from './DailySalesByOtaTable';
import MonthlySalesByOtaTable from './MonthlySalesByOtaTable';
import './SalesByOtaTable.scss';

function SalesByOtaTable() {
    const { t } = useTranslation();
    const { isShowByDaily, selectedDate, selectedMarketingChannelId } =
        useAppSelector(salesByOtaSelector);

    const { data, isFetching } = useQuery<ISalesByOtaResponse | undefined>({
        queryKey: [CacheKeys.getSalesByOtaReportKey],
        enabled: false,
    });
    if (!data) {
        return null;
    }

    return (
        <div className="report-sales-by-ota-table-wrapper">
            <div className="table-header">{t('report.salesByOta.tableHeader')}</div>
            {isShowByDaily ? (
                <DailySalesByOtaTable data={data} loading={isFetching} />
            ) : (
                <MonthlySalesByOtaTable
                    data={data}
                    loading={isFetching}
                    selectedDate={selectedDate}
                />
            )}
        </div>
    );
}

export default SalesByOtaTable;
