import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterForm from '../../components/FilterForm/FilterForm';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../../constants';
import RoomTypeList from '../../components/RoomTypeList/RoomTypeList';
import RightModalLayout from '../../components/RoomTypeForm/RoomTypeForm';

import './RoomTypeListPage.scss';
import { Layout } from 'antd';

function RoomTypeListPage() {
    const { t } = useTranslation();

    return (
        <Layout className="room-type-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('roomType.list.title')}
            />
            <Layout.Content>
                <div className="room-type-page-wrapper">
                    <div className="room-type-page-content">
                        <FilterForm />
                        <RoomTypeList />
                    </div>
                </div>
            </Layout.Content>
            <RightModalLayout />
        </Layout>
    );
}

export default RoomTypeListPage;
