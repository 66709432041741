import { Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import ActionForm from '~features/rank-calendar/pages/RankCalendarDetailPage/components/ActionForm/ActionForm';
import RankCalendarDetail from '~features/rank-calendar/pages/RankCalendarDetailPage/components/RankCalendarDetail/RankCalendarDetail';
import { breadcrumbsDetail } from '~features/rank-calendar/constants';
import {
    fetchRankCalendarDetail,
    showLoadingListSelector,
} from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './RankCalendarDetailPage.scss';

function RankCalendarDetailPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { rankCalendarId } = useParams();

    const showLoading = useAppSelector(showLoadingListSelector);

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(fetchRankCalendarDetail(id));
        if (fetchRankCalendarDetail.fulfilled.match(response)) {
            if (!response.payload?.data) {
                navigate('/404');
            }
        }
    }, []);

    //This re-renders when the route changes
    useEffect(() => {
        if (typeof Number(rankCalendarId) === 'number') {
            _getDetail(Number(rankCalendarId));
        } else {
            navigate('/404');
        }
    }, [rankCalendarId]);

    return (
        <div className="rank-calendar-detail-page">
            <Spin spinning={showLoading}>
                <StaffLayoutHeader
                    breadcrumbs={breadcrumbsDetail()}
                    title={t('rankCalendar.detail.title')}
                />
                <Content className="rank-calendar-detail-content">
                    <ActionForm />
                    <RankCalendarDetail />
                </Content>
            </Spin>
        </div>
    );
}

export default RankCalendarDetailPage;
