import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../constants';
import { useForm } from '~plugins/hook-form';
import { useAppDispatch, useAppSelector } from '~hooks';
import { hotelSmartSchemaResolver } from '../schema';
import HotelSmartCard from '../components/HotelSmartCard/HotelSmartCard';
import { IIntegrationsState } from '../interfaces';
import {
    getHotelSmartConfig,
    integrateHotelSmart,
    integrationstStateSelector,
    setHotelSmart,
    updateHotelSmart,
} from '../reducers/integrations.reducer';
import { useEffect, useState } from 'react';
import PageFooter from '../components/PageFooter/PageFooter';
import { parseDate } from '~plugins/dayjs';

import './IntegrationsPage.scss';

function IntegraionsPage() {
    const { t } = useTranslation();
    const [isFirstIntegration, setIsFirstIntegration] = useState(true);
    const storedIntegrations = useAppSelector<IIntegrationsState>(
        integrationstStateSelector,
    );
    const dispatch = useAppDispatch();

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        resolver: hotelSmartSchemaResolver,
    });
    const fetchData = async () => {
        const response = await dispatch(getHotelSmartConfig()).catch();
        if (getHotelSmartConfig.fulfilled.match(response)) {
            setIsFirstIntegration(!response.payload?.success);
            if (response.payload?.success) {
                dispatch(setHotelSmart(response.payload.data));
            }
        }
    };

    useEffect(() => {
        fetchData();
        reset({
            username: storedIntegrations?.hotelSmart?.username,
            enableIntegration: storedIntegrations?.hotelSmart?.enableIntegration,
            houseId: storedIntegrations?.hotelSmart?.houseId,
            password: storedIntegrations?.hotelSmart?.password,
            checkedInDate: storedIntegrations?.hotelSmart?.checkedInDate
                ? parseDate(storedIntegrations?.hotelSmart?.checkedInDate)
                : undefined,
        });
    }, [storedIntegrations]);

    const submit = () => {
        handleSubmit(async (data) => {
            const { enableIntegration, username, password, checkedInDate, houseId } =
                data;

            const parsedDate = parseDate(checkedInDate).format(
                'YYYY-MM-DDT[00:00:00+09:00]',
            );
            const checkedInDateISO = new Date(parsedDate).toISOString();

            const formData = {
                enableIntegration,
                username,
                password,
                checkedInDate: checkedInDateISO,
                houseId: Number(houseId),
            };

            const response = isFirstIntegration
                ? await dispatch(integrateHotelSmart(formData))
                : await dispatch(updateHotelSmart(formData));

            if (
                (integrateHotelSmart.fulfilled.match(response) ||
                    updateHotelSmart.fulfilled.match(response)) &&
                response.payload?.success
            ) {
                dispatch(setHotelSmart(formData));
                notification.success({
                    message: t('integrations.integrations.notification.success'),
                });
                return;
            }
            notification.error({
                message: t('integrations.integrations.notification.error'),
            });
        })();
    };

    return (
        <div className="integrations-page-wrapper">
            <StaffLayoutHeader
                title={t('integrations.integrations.title')}
                breadcrumbs={breadcrumbs()}
            />
            <div className="page-contents">
                <HotelSmartCard
                    control={control}
                    hotelSmart={storedIntegrations.hotelSmart}
                />
            </div>
            <PageFooter submit={submit} disabled={!isDirty} />
        </div>
    );
}

export default IntegraionsPage;
