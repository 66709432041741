import { IFacilityBooking } from '../../facility-booking/interfaces';
import { IRoomBooking } from '../../room-booking/interfaces';
import { IClosingBookingListQuery } from '../interfaces';
import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse, IGetListResponse } from '~common/interfaces';

class ClosingBookingService extends ApiService {
    getRoomClosingList(queryString: IClosingBookingListQuery) {
        return this._get('room-booking/closing', {
            params: queryString,
        }) as Promise<IBodyResponse<IGetListResponse<IRoomBooking>>>;
    }
    getFacilityClosingList(queryString: IClosingBookingListQuery) {
        return this._get('facility-booking/closing', {
            params: queryString,
        }) as Promise<IBodyResponse<IGetListResponse<IFacilityBooking>>>;
    }
}
export const closingBookingService = new ClosingBookingService(
    { baseUrl: '/' },
    axiosService,
);
