import {
    IBookingReceiptTableData,
    IPaymentMethod,
} from '~features/room-booking/interfaces';
import './PaymentMethodColumn.scss';
import { PaymentMethodSelect } from '../../PaymentMethodSelect/PaymentMethodSelect';
import { ReceiptItemType } from '~features/room-booking/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '~hooks';
import { paymentMethodStateSelector } from '~features/payment-method/reducers/paymentMethod.reducer';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { IPaymentMethodDropDown } from '~features/payment-method/interfaces';
import { DEPOSIT, DEPOSIT_POINT } from '~features/payment-method/constants';

type Props = {
    control: any;
    item: IBookingReceiptTableData;
    isCanEdit: (item: IBookingReceiptTableData) => boolean;
};
function PaymentMethodColumn(props: Props) {
    const { control, item, isCanEdit } = props;
    const { t } = useTranslation();
    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);

    const isPaymentItem = useMemo(() => {
        return item.level === 1 && item.status === ReceiptItemType.PAYMENT;
    }, [item]);

    const getPaymentMethodOptions = (
        paymentMethod: IPaymentMethod | null | undefined,
    ) => {
        if (!paymentMethod) return paymentMethodDropDown;
        const findMethod = paymentMethodDropDown.find(
            (method) => method.id === paymentMethod.id,
        );
        if (findMethod) return paymentMethodDropDown;
        const { id, name, autoGeneratedCode } = paymentMethod;
        return [
            ...paymentMethodDropDown,
            {
                id,
                name,
                autoGeneratedCode,
            },
        ];
    };

    const getDisabledValues = (paymentMethodDropDown: IPaymentMethodDropDown[]) => {
        return paymentMethodDropDown
            .filter((method) => method.disabled)
            .map((method) => method.id);
    };

    const checkPaymentMethodDisabled = (item: IBookingReceiptTableData): boolean => {
        const isDisabledPaymentMethod =
            getDisabledValues(paymentMethodDropDown).length > 0;
        const restrictedPaymentMethods = [DEPOSIT, DEPOSIT_POINT];
        const includeRestrictedPaymentMethod = restrictedPaymentMethods.includes(
            item.paymentMethod?.name as string,
        );

        return (
            !item.isAddition && isDisabledPaymentMethod && includeRestrictedPaymentMethod
        );
    };

    return isCanEdit(item) && isPaymentItem ? (
        <>
            <PaymentMethodSelect
                label=""
                placeholder={t('roomBooking.detail.receipt.paymentMethod.placeholder')}
                name={`${item.id}.paymentMethodId`}
                control={control}
                paymentMethodOptions={getPaymentMethodOptions(item.paymentMethod)}
                disableValues={getDisabledValues(paymentMethodDropDown)}
                disabled={checkPaymentMethodDisabled(item)}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                optionLabelProp="label"
                dropdownStyle={{
                    minWidth: '200px',
                }}
                placement={'bottomRight'}
                popupMatchSelectWidth={300}
            />
        </>
    ) : (
        <span className="payment-method-cell">
            {!item.paymentMethod?.isPullFromTll ? (
                item.paymentMethod?.name || ''
            ) : (
                <Tooltip title={t('roomBooking.detail.message.paymentMethodNotExist')}>
                    <div className={classNames('text-truncate', 'booking-error')}>
                        {item.paymentMethod?.name || ''}
                    </div>
                </Tooltip>
            )}
        </span>
    );
}

export default PaymentMethodColumn;
