import { Button, Col, Divider, Form, Radio, Row, Table } from 'antd';

import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exportCSVFile, formatMoney, parseDateTime } from '~common/commonFunctions';
import { DateFormat, LodashOrderDirection } from '~common/constants';
import { useEscape } from '~common/useHooks';
import { RangePicker, RightDrawerLayout, SingleSelect } from '~components';
import {
    DisplayedContentMode,
    EXPORT_PREPARATION_LIST_FILE_NAME,
    i18ExportPreparationListKey,
    PreparationListColumn,
    preparationListColumns,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import { IPreparationListTableData } from '~features/room-booking/interfaces';
import {
    setIsShowPreparationListPrintingModal,
    setPrintPreparationListData,
} from '~features/room-booking/reducers/print-booking.reducer';
import {
    getRoomBookingPreparationList,
    roomBookingPreparationListSelector,
    setRoomBookingPreparationList,
    setShowPreparationListForm,
    showLoadingPreparationListTableSelector,
    showPreparationListFormSelector,
} from '~features/room-booking/reducers/room-booking.reducer';
import {
    getSaleItemListForDropdown,
    saleItemListDropdownSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { Dayjs, parseDate, todayDayjs } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import PreparationListPrintModal from '../PreparationListPrintModal/PreparationListPrintModal';
import './PreparationListForm.scss';

function PreparationListForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showPreparationListForm = useAppSelector(showPreparationListFormSelector);
    const showLoadingPreparationListTable = useAppSelector(
        showLoadingPreparationListTableSelector,
    );
    const saleItemList = useAppSelector(saleItemListDropdownSelector);
    const [status, setStatus] = useState(RoomBookingItemBookingStatus.ALL);
    const [displayedContentMode, setDisplayedContentMode] = useState(
        DisplayedContentMode.TOTAL,
    );
    const { control, setValue, getValues } = useForm();
    const roomBookingPreparationList = useAppSelector(roomBookingPreparationListSelector);
    const allOption = 'all';
    const [tableColumns, setTableColumns] = useState(preparationListColumns);

    useEffect(() => {
        resetForm();
    }, []);

    useEffect(() => {
        if (showPreparationListForm) {
            dispatch(getSaleItemListForDropdown({ isShowInBooking: true }));
        } else {
            dispatch(getSaleItemListForDropdown({}));
        }
    }, [showPreparationListForm]);

    const saleItemOptions = useMemo(() => {
        return [
            {
                value: allOption,
                label: t('roomBooking.list.preparationListForm.allOption'),
            },
            ...saleItemList.map((saleItem) => {
                return {
                    value: saleItem.id,
                    label: saleItem.name,
                };
            }),
        ];
    }, [saleItemList]);

    const resetForm = () => {
        setValue('saleItemId', allOption);
        setValue('stayPeriod', [todayDayjs, todayDayjs.add(1, 'day')]);
        setStatus(RoomBookingItemBookingStatus.ALL);
        setDisplayedContentMode(DisplayedContentMode.TOTAL);
        setTableColumns(preparationListColumns);
    };

    const closeForm = () => {
        dispatch(setShowPreparationListForm(false));
        dispatch(setRoomBookingPreparationList([]));
        resetForm();
    };

    const showConfirmCancel = () => {
        closeForm();
    };
    useEscape(closeForm);

    const onChangeDisplayedContentMode = (value: DisplayedContentMode) => {
        setDisplayedContentMode(value);
        if (value === DisplayedContentMode.TOTAL) {
            setTableColumns(preparationListColumns);
        } else {
            const tableColumns = cloneDeep(preparationListColumns);
            tableColumns.splice(1, 0, 'roomName', 'guestName');
            setTableColumns(tableColumns);
        }
    };

    const onChangeStatus = (value: RoomBookingItemBookingStatus) => {
        setStatus(value);
    };

    const disabledDate = (current: Dayjs) => {
        return current.isBefore(todayDayjs.subtract(1, 'day'));
    };

    const tableData = useMemo(() => {
        let result: IPreparationListTableData[] = [];
        const dataList: {
            date: string;
            roomName?: string;
            guestName: string;
            saleItem: { price: number; id: number; name: string; quantity: number };
        }[] = [];
        roomBookingPreparationList?.forEach((roomBooking) => {
            const saleItemIds = saleItemList?.map((saleItem) => {
                return saleItem?.id;
            });
            const guestName = roomBooking?.representativeGuest?.yomigana;
            roomBooking?.roomBookingItems?.forEach((roomBookingItem) => {
                const roomName = roomBookingItem?.room?.name;
                roomBookingItem?.receiptItemDetails?.forEach((receiptItem) => {
                    if (saleItemIds.includes(receiptItem?.saleItem?.id)) {
                        dataList.push({
                            date: receiptItem?.boughtAt as string,
                            roomName,
                            guestName,
                            saleItem: {
                                ...receiptItem?.saleItem,
                                price: receiptItem?.unitPrice || 0,
                                quantity: receiptItem?.quantity || 0,
                            },
                        });
                    }
                });
            });
        });

        const objectData: {
            [key: string]: IPreparationListTableData;
        } = {};
        dataList.forEach((data) => {
            const key = `${data.date}_${data.roomName}_${data.guestName}_${data?.saleItem?.price}_${data?.saleItem?.id}`;
            if (objectData[key]) {
                objectData[key] = {
                    ...objectData[key],
                    quantity: objectData[key]?.quantity + data.saleItem?.quantity,
                };
            } else {
                objectData[key] = {
                    id: key,
                    date: parseDateTime(data?.date, 'MM/DD' as DateFormat),
                    roomName: data?.roomName,
                    guestName: data?.guestName,
                    saleItemName: data?.saleItem?.name,
                    quantity: data?.saleItem?.quantity,
                    price: data?.saleItem?.price,
                };
            }
        });

        result = [...Object.values(objectData)];
        if (displayedContentMode === DisplayedContentMode.TOTAL) {
            const objectData: {
                [key: string]: IPreparationListTableData;
            } = {};
            const _result = cloneDeep(result);
            _result.forEach((item) => {
                const key = `${item.date}_${item.price}_${item.saleItemName}`;
                if (objectData[key]) {
                    objectData[key] = {
                        ...objectData[key],
                        quantity: objectData[key]?.quantity + item?.quantity,
                    };
                } else {
                    objectData[key] = {
                        id: key,
                        date: item?.date,
                        saleItemName: item?.saleItemName,
                        quantity: item?.quantity,
                        price: item?.price,
                    };
                }
            });
            return orderBy(
                [...Object.values(objectData)],
                ['date'],
                [LodashOrderDirection.ASC],
            );
        }

        return orderBy(result, ['date'], [LodashOrderDirection.ASC]);
    }, [roomBookingPreparationList, displayedContentMode]);

    const onClickButtonSearch = async () => {
        const formData = getValues();
        const startDate = parseDate(formData?.stayPeriod[0])
            .startOf('day')
            .fmYYYYMMDDHHmmss();
        const endDate = parseDate(formData?.stayPeriod[1])
            .endOf('day')
            .fmYYYYMMDDHHmmss();

        await dispatch(
            getRoomBookingPreparationList({
                roomBookingItemStatus: status === allOption ? null : status,
                saleItemId:
                    formData.saleItemId === allOption ? null : formData.saleItemId,
                stayPeriod: [startDate, endDate],
            }),
        );
    };

    const onClickButtonPrint = () => {
        const printData = tableData.map((data) => {
            return { ...data, price: data?.price * data?.quantity };
        });
        dispatch(setIsShowPreparationListPrintingModal(true));
        dispatch(setPrintPreparationListData(printData));
    };

    const onClickButtonDownload = async () => {
        try {
            const filename = `${EXPORT_PREPARATION_LIST_FILE_NAME}.csv`;
            const _tableData = tableData.map((data) => {
                return { ...data, price: data?.price * data?.quantity };
            });

            await exportCSVFile(
                tableColumns,
                filename,
                _tableData,
                i18ExportPreparationListKey,
            );
        } catch (error) {
            throw error;
        }
    };

    const preparationListTableColumns = useCallback(() => {
        const getColumnWidth = (totalModeWidth: string, perRoomModeWidth: string) => {
            return displayedContentMode === DisplayedContentMode.TOTAL
                ? totalModeWidth
                : perRoomModeWidth;
        };
        return tableColumns.map((column) => {
            if (column === PreparationListColumn.PRICE) {
                return {
                    title: t(`roomBooking.list.preparationListForm.column.${column}`),
                    width: getColumnWidth('180px', '100px'),
                    key: column,
                    render(rowData: IPreparationListTableData) {
                        return <div>{formatMoney(rowData.price * rowData.quantity)}</div>;
                    },
                };
            }
            return {
                title: t(`roomBooking.list.preparationListForm.column.${column}`),
                ellipsis: true,
                width:
                    column === PreparationListColumn.DATE ||
                    column === PreparationListColumn.QUANTITY
                        ? getColumnWidth('120px', '80px')
                        : '',
                key: column,
                dataIndex: column,
            };
        });
    }, [tableColumns]);

    return (
        <>
            <RightDrawerLayout
                open={showPreparationListForm}
                onClose={showConfirmCancel}
                onSubmit={onClickButtonPrint}
                title={t('roomBooking.list.preparationListForm.title')}
                footer={
                    <div className="custom-drawer-footer">
                        <Row gutter={8}>
                            <Col span={12}>
                                <Button
                                    onClick={onClickButtonDownload}
                                    disabled={
                                        tableData.length === 0 ||
                                        showLoadingPreparationListTable
                                    }
                                    className={
                                        tableData.length === 0 ||
                                        showLoadingPreparationListTable
                                            ? 'disabled-button'
                                            : ''
                                    }
                                >
                                    {t(
                                        'roomBooking.list.preparationListForm.downloadButton',
                                    )}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    onClick={onClickButtonPrint}
                                    disabled={
                                        tableData.length === 0 ||
                                        showLoadingPreparationListTable
                                    }
                                    className={
                                        tableData.length === 0 ||
                                        showLoadingPreparationListTable
                                            ? 'disabled-button'
                                            : ''
                                    }
                                >
                                    {t(
                                        'roomBooking.list.preparationListForm.printButton',
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
                className={'preparation-list-wrapper'}
            >
                <Form layout="vertical" className="preparation-list-form">
                    <Row gutter={12} className="preparation-list-row">
                        <Col span={18} className="range-picker-wrapper">
                            <RangePicker
                                name="stayPeriod"
                                label={
                                    <div className="range-picker-label">
                                        <div className="range-picker-label-start">
                                            {t(
                                                'roomBooking.list.preparationListForm.startDate',
                                            )}
                                        </div>
                                        <div className="range-picker-label-end">
                                            {t(
                                                'roomBooking.list.preparationListForm.endDate',
                                            )}
                                        </div>
                                    </div>
                                }
                                control={control}
                                allowClear={false}
                                disabledDate={disabledDate}
                            />
                        </Col>
                        <Col span={6} className="sale-item-select">
                            <SingleSelect
                                name="saleItemId"
                                label={t('roomBooking.list.preparationListForm.saleItem')}
                                control={control}
                                options={saleItemOptions}
                            />
                        </Col>
                    </Row>
                    <Row className="preparation-list-row">
                        <Form.Item
                            label={t(
                                'roomBooking.list.preparationListForm.displayedContentMode.label',
                            )}
                        >
                            <Radio.Group
                                value={displayedContentMode}
                                onChange={(e) => {
                                    onChangeDisplayedContentMode(e?.target?.value);
                                }}
                            >
                                {Object.values(DisplayedContentMode).map((value) => {
                                    return (
                                        <Radio.Button value={value} key={value}>
                                            {t(
                                                `roomBooking.list.preparationListForm.displayedContentMode.${value}`,
                                            )}
                                        </Radio.Button>
                                    );
                                })}
                            </Radio.Group>
                        </Form.Item>
                    </Row>
                    <Row className="preparation-list-row">
                        <Form.Item
                            label={t('roomBooking.list.preparationListForm.status')}
                        >
                            <Radio.Group
                                value={status}
                                onChange={(e) => {
                                    onChangeStatus(e?.target?.value);
                                }}
                            >
                                {Object.values(RoomBookingItemBookingStatus)
                                    .filter((value) => {
                                        return (
                                            value !==
                                            RoomBookingItemBookingStatus.CANCELLED
                                        );
                                    })
                                    .map((value) => {
                                        return (
                                            <Radio.Button value={value} key={value}>
                                                {t(
                                                    `roomBooking.page.bookingStatus.${value}`,
                                                )}
                                            </Radio.Button>
                                        );
                                    })}
                            </Radio.Group>
                        </Form.Item>
                    </Row>
                    <Row className="preparation-list-row">
                        <Col span={24}>
                            <Button
                                type="primary"
                                className="search-button"
                                onClick={onClickButtonSearch}
                                loading={showLoadingPreparationListTable}
                            >
                                {t('roomBooking.list.preparationListForm.searchButton')}
                            </Button>
                        </Col>
                    </Row>
                    <Divider className="divider" />
                    <Table
                        className="preparation-list-table"
                        columns={preparationListTableColumns()}
                        dataSource={tableData}
                        pagination={false}
                        rowKey="id"
                        scroll={{ y: 'calc(100vh - 610px)' }}
                        loading={showLoadingPreparationListTable}
                    />
                </Form>
            </RightDrawerLayout>
            <PreparationListPrintModal
                stayPeriod={getValues().stayPeriod}
                tableColumns={tableColumns}
            />
        </>
    );
}

export default PreparationListForm;
