import React, { useEffect, useMemo, useRef, useState } from 'react';
import { calculatePositionLeft, calculateWidth } from '~common/commonFunctions';
import { EmitterGlobalEvent, MINUTES_PER_HOUR } from '~common/constants';
import {
    CELL_HEADER_HEIGHT,
    CELL_HEIGHT,
    CELL_WIDTH,
    HEADER_HEIGHT_DAY_VIEW,
} from '~features/facility-booking/constants';
import {
    facilityBookingStateSelector,
    facilityFilterSelector,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { RoomBookingEvent } from '~features/room-booking/constants';
import { useAppSelector } from '~hooks';
import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import { DropBox } from './DropBox';

type Props = {
    cellWidth: number;
    startTime: Dayjs;
    endTime: Dayjs;
};

export const DropBoxList = ({ cellWidth, startTime, endTime }: Props) => {
    const facilityList = useAppSelector(facilityFilterSelector);
    const time = useMemo(
        () => ({
            start: startTime,
            end: endTime,
        }),
        [startTime, endTime],
    );
    const { currentDragData } = useAppSelector(facilityBookingStateSelector);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const { emitter } = useMitt();
    const bookingPosition = useRef<
        Record<string, Record<string, { left: number; width: number }>>
    >({});
    useEffect(() => {
        emitter.on(EmitterGlobalEvent.SCROLL, (scroll: { x: number; y: number }) => {
            setPosition(scroll);
        });
        emitter.on(
            RoomBookingEvent.UPDATE_BOOKING_POSITION,
            (data: {
                bookingId: number;
                left: number;
                roomId: number;
                width: number;
            }) => {
                if (bookingPosition.current['room_' + data.roomId]) {
                    bookingPosition.current['room_' + data.roomId][
                        'booking_' + data.bookingId
                    ] = {
                        left: data.left,
                        width: data.width,
                    };
                } else {
                    bookingPosition.current['room_' + data.roomId] = {
                        ['booking_' + data.bookingId]: {
                            left: data.left,
                            width: data.width,
                        },
                    };
                }
            },
        );
        emitter.on(
            RoomBookingEvent.REMOVE_BOOKING_POSITION,
            (data: { bookingId: number; roomId: number }) => {
                if (bookingPosition.current['room_' + data.roomId]) {
                    delete bookingPosition.current['room_' + data.roomId][
                        'booking_' + data.bookingId
                    ];
                }
            },
        );
    }, []);

    const { booking } = currentDragData || {};
    if (!booking) {
        return null;
    }

    const renderList = () => {
        const list: React.ReactNode[] = [];
        const left =
            calculatePositionLeft({
                from: time.start,
                to: parseDate(booking.checkInDateTime),
                widthOfColumn: cellWidth / MINUTES_PER_HOUR,
            }) + CELL_WIDTH;
        let top = HEADER_HEIGHT_DAY_VIEW + 8;
        const width = calculateWidth({
            from: parseDate(booking.checkInDateTime)?.fmYYYYMMDDHHmm('-'),
            to: parseDate(booking.checkOutDateTime)?.fmYYYYMMDDHHmm('-'),
            widthOfColumn: cellWidth / MINUTES_PER_HOUR,
        });
        const _left = left - position.x;
        for (let index = 0; index < facilityList.length; index++) {
            const facility = facilityList[index];
            if (!facility.parentId) {
                top += CELL_HEADER_HEIGHT;
            } else {
                const _positions =
                    facility.id === booking.facilityId
                        ? {}
                        : bookingPosition.current['room_' + facility.id] || {};
                const invalid =
                    Object.values(_positions).findIndex((item) => {
                        let check = item.left >= _left && item.left <= _left + width;
                        check =
                            check ||
                            (item.left + item.width >= _left &&
                                item.left + item.width <= _left + width);
                        check =
                            check ||
                            (item.left <= _left &&
                                item.left + item.width >= _left + width);
                        return check;
                    }) > -1;
                if (!invalid) {
                    list.push(
                        <DropBox
                            key={facility.id}
                            booking={booking}
                            facilityId={facility.id}
                            left={left - position.x}
                            top={top - position.y}
                            width={width}
                        />,
                    );
                }
                top += CELL_HEIGHT;
            }
        }
        return list;
    };
    return <>{renderList()}</>;
};
