import { Pagination, Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { parseDate } from '~plugins/dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    AntdOrderDirection,
    DEFAULT_FIRST_PAGE,
    OrderDirection,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import { TextTruncate } from '~components';
import {
    FacilityBookingColumn,
    FacilityBookingOrderBy,
    FacilityBookingStatus,
} from '~features/facility-booking/constants';
import { IFacilityBooking } from '~features/facility-booking/interfaces';
import {
    roomClosingStateSelector,
    totalFaciltiyClosingPageSelector,
    setRoomClosingListQuery,
} from '~features/closing-booking/reducers/closing-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './FacilityClosingList.scss';
import { formatMoney } from '~common/commonFunctions';

function FacilityClosingList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const pageCount = useAppSelector(totalFaciltiyClosingPageSelector);
    const {
        closingBookingListQuery: roomClosingListQuery,
        totalFacilityClosings,
        facilityClosingList,
        showLoading,
    } = useAppSelector(roomClosingStateSelector);

    const onChangePage = (page: number) => {
        dispatch(setRoomClosingListQuery({ ...roomClosingListQuery, page }));
    };

    const onChange: TableProps<IFacilityBooking>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setRoomClosingListQuery({
                    ...roomClosingListQuery,
                    page: DEFAULT_FIRST_PAGE,
                    orderBy: FacilityBookingOrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setRoomClosingListQuery({
                    ...roomClosingListQuery,
                    page: DEFAULT_FIRST_PAGE,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const showFacilityBookingDetail = (facilityBooking: IFacilityBooking) => {
        navigate(`/facility-booking/${facilityBooking.id}/detail`);
    };
    const goToRoomBookingDetail = (booking: IFacilityBooking) => {
        if (booking.roomBooking?.id) {
            navigate(`/room-booking/${booking.roomBooking?.id}/detail`);
        }
    };

    const facilityBookingColumns: ColumnsType<IFacilityBooking> = [
        {
            title: t('facilityBooking.list.column.autoGeneratedCode'),
            onCell: () => {
                return {
                    style: { color: '#1c3e86' },
                };
            },
            render: (booking: IFacilityBooking) => {
                return <span>{booking.autoGeneratedCode}</span>;
            },
            key: FacilityBookingColumn.ID,
            sorter: true,
            fixed: 'left',
        },
        {
            title: t('facilityBooking.list.column.yomigana'),
            render: (booking: IFacilityBooking) => {
                return (
                    <div className="text-truncate">{booking?.guest?.yomigana || ''}</div>
                );
            },
            key: FacilityBookingColumn.YOMIGANA,
            sorter: true,
            fixed: 'left',
        },
        {
            title: t('facilityBooking.list.column.fullName'),
            render: (booking: IFacilityBooking) => {
                return (
                    <div className="text-truncate">{booking.guest?.fullName || ''}</div>
                );
            },
            key: FacilityBookingColumn.GUEST_FULL_NAME,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.mobilePhoneNumber'),
            render: (booking: IFacilityBooking) => {
                return <span>{booking.guest?.mobilePhoneNumber}</span>;
            },
            key: FacilityBookingColumn.GUEST_PHONE_NUMBER,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.facilityName'),
            render: (booking: IFacilityBooking) => {
                return <div className="text-truncate">{booking.facility?.name}</div>;
            },
            key: FacilityBookingColumn.FACILITY_NAME,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.status'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span>
                        {booking.status === FacilityBookingStatus.RESERVED
                            ? t('closingBooking.page.closingStatus.not_checked_in')
                            : t('closingBooking.page.closingStatus.not_checked_out')}
                    </span>
                );
            },
            key: FacilityBookingColumn.STATUS,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.bookingDate'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span>
                        {booking?.createdAt
                            ? parseDate(booking.createdAt)?.fmYYYYMMDD('-')
                            : ''}
                    </span>
                );
            },
            key: FacilityBookingColumn.BOOKING_DATE,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.startDatetime'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span>
                        {booking?.startDatetime
                            ? parseDate(booking.startDatetime)?.fmYYYYMMDDHHmm('-')
                            : ''}
                    </span>
                );
            },
            key: FacilityBookingColumn.START_DATE_TIME,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.endDatetime'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span>
                        {booking.endDatetime
                            ? parseDate(booking.endDatetime)?.fmYYYYMMDDHHmm('-')
                            : ''}
                    </span>
                );
            },
            key: FacilityBookingColumn.END_DATE_TIME,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.roomBookingId.title'),
            render: (booking: IFacilityBooking) => {
                return booking.roomBooking?.id ? (
                    <span>
                        {t('facilityBooking.list.column.roomBookingId.yes')} (ID:{' '}
                        <a
                            style={{ color: '#4DBDD5' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                goToRoomBookingDetail(booking);
                            }}
                        >
                            {booking.roomBooking?.autoGeneratedCode}
                        </a>
                        )
                    </span>
                ) : (
                    <span>{t('facilityBooking.list.column.roomBookingId.no')}</span>
                );
            },
            key: FacilityBookingColumn.ROOM_BOOKING_ID,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.paymentStatus.title'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span>
                        {booking.totalAmount <= 0
                            ? t('facilityBooking.list.column.paymentStatus.already')
                            : t('facilityBooking.list.column.paymentStatus.not')}
                    </span>
                );
            },
            key: FacilityBookingColumn.PAYMENT_STATUS,
            sorter: true,
            width: '100px',
        },
        {
            title: t('facilityBooking.list.column.paymentAmount'),
            render: (booking: IFacilityBooking) => {
                return (
                    <div>
                        ￥{' '}
                        {booking.totalAmount > 0
                            ? `${formatMoney(booking.totalAmount)}`
                            : '0'}
                    </div>
                );
            },
            key: FacilityBookingColumn.PAYMENT_AMOUNT,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.memo'),
            sorter: true,
            render: (booking: IFacilityBooking) => {
                return <TextTruncate text={booking.memo || ''} />;
            },
            key: FacilityBookingColumn.MEMO,
        },
    ];

    return (
        <div className="facility-closing-list">
            <Table
                columns={facilityBookingColumns}
                dataSource={facilityClosingList}
                onChange={onChange}
                pagination={false}
                rowKey="id"
                scroll={{ x: 2000 }}
                className="table-scrollbar"
                loading={showLoading}
                onRow={(record) => {
                    return {
                        // click row
                        onClick: () => {
                            showFacilityBookingDetail(record);
                        },
                    };
                }}
            />

            {pageCount > 1 && (
                <Pagination
                    align="center"
                    className="closing-booking-pagination"
                    defaultCurrent={roomClosingListQuery.page}
                    current={roomClosingListQuery.page}
                    total={totalFacilityClosings}
                    pageSize={roomClosingListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default FacilityClosingList;
