import { Form, Input, InputProps } from 'antd';
import classNames from 'classnames';
import { fullWidthNumConvert, parseErrorMessage } from '~common/commonFunctions';
import { Controller } from '~plugins/hook-form';
import './InputPhoneNumber.scss';
import { Regex } from '~common/constants';

interface CustomInput extends InputProps {
    name: string;
    label: string;
    isShowIconArrow: boolean;
    defaultValue: string | number;
    control: any;
    hideHelpText?: boolean;
    isNotConvertToHalfWidth?: boolean;
}

export function InputPhoneNumber(props: CustomInput) {
    const {
        control,
        name,
        defaultValue,
        label,
        onChange,
        required,
        isShowIconArrow,
        addonAfter = null,
        hideHelpText,
        isNotConvertToHalfWidth,
        ...rest
    } = props;
    const phonePattern = new RegExp(Regex.PHONE_PATTERN);
    const prePhonePattern = new RegExp(Regex.PRE_PHONE_PATTERN);

    return (
        <div
            className={classNames('custom-input-number-wrapper', {
                'hide-help-text': hideHelpText,
            })}
        >
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            required={required}
                        >
                            <Input
                                {...field}
                                {...rest}
                                onChange={(event) => {
                                    const val = event.target.value;

                                    if (val?.length === 1 && !prePhonePattern.test(val)) {
                                        event.target.value = '';
                                        return;
                                    }

                                    if (
                                        val &&
                                        !phonePattern.test(val) &&
                                        val?.length > 1
                                    ) {
                                        event.target.value = '';
                                        return;
                                    }
                                    if (onChange) {
                                        onChange(event);
                                    }
                                    field.onChange(event);
                                }}
                                onBlur={(event) => {
                                    if (isNotConvertToHalfWidth) return;
                                    const value = event.target.value;
                                    if (Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                                        event.target.value =
                                            fullWidthNumConvert(value) || '';
                                        if (onChange) {
                                            onChange(event);
                                        }
                                        field.onChange(event);
                                    }
                                }}
                                // addonAfter={
                                //     isShowIconArrow ? (
                                //         <div className="arrow-control">
                                //             <UpOutlined
                                //                 onClick={() => {
                                //                     if (rest.disabled) return;
                                //                     const val = Number(field.value) + 1;
                                //                     if ((max || max === 0) && val > max)
                                //                         return;
                                //                     field.onChange(val);
                                //                     if (onChange) {
                                //                         const event: ChangeEvent<HTMLInputElement> =
                                //                             {
                                //                                 target: {
                                //                                     value: `${val}`,
                                //                                 },
                                //                             } as ChangeEvent<HTMLInputElement>;
                                //                         onChange(event);
                                //                     }
                                //                 }}
                                //             />

                                //             <DownOutlined
                                //                 onClick={(e) => {
                                //                     if (rest.disabled) return;
                                //                     const val = Number(field.value) - 1;
                                //                     if (val < min) return;
                                //                     field.onChange(val);
                                //                     if (onChange) {
                                //                         const event: ChangeEvent<HTMLInputElement> =
                                //                             {
                                //                                 target: {
                                //                                     value: `${val}`,
                                //                                 },
                                //                             } as ChangeEvent<HTMLInputElement>;
                                //                         onChange(event);
                                //                     }
                                //                 }}
                                //             />
                                //         </div>
                                //     ) : (
                                //         addonAfter
                                //     )
                                // }
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

InputPhoneNumber.defaultProps = {
    defaultValue: '',
    isShowIconArrow: false,
};
