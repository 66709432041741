const roomTypeImport = {
    error: {
        name: {
            required: 'Room type name is required',
            maxLength: 'Room type name must be at most {{maxLength}} characters',
            unique: 'Room type name must be unique',
            hasNoSpecialCharacters: 'Room type name must contain no special characters',
        },
        roomTypeCode: {
            required: 'Room type code is required',
            maxLength: 'Room type code must be at most {{maxLength}} characters',
            unique: 'Room type code must be unique',
        },
        abbreviation: {
            required: 'Abbreviation is required',
            maxLength: 'Abbreviation must be at most {{maxLength}} characters',
            unique: 'Abbreviation must be unique',
        },
        standardCapacity: {
            required: 'Standard capacity is required',
            max: 'Standard capacity must be less than or equal to {{max}}',
            min: 'Standard capacity must be greater than or equal to 1',
            number: 'Standard capacity must be a number',
        },
        marketingChannel: {
            maxLength: 'Marketing channel name must be at most {{max}} characters',
            unique: 'Marketing channel name must be unique',
            hasNoSpecialCharacters:
                'Marketing channel name must contain no special characters',
        },
    },
    importErrorKey: {
        name: 'Room type name',
        roomTypeCode: 'Room type code',
        abbreviation: 'Abbreviation',
        standardCapacity: 'Standard capacity',
        marketingChannel: 'Marketing channel name',
    },
};

export default roomTypeImport;
