import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
} from '../../../common/constants';
import { IPlanState, IPlanDropDownQuery } from '../interfaces';
import { PlanType, SearchField, initPlanListQuery, PlanRankStatus } from '../constants';
import { cloneDeep, trim } from 'lodash';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import { planService } from '../services/plan.service';
import { rankCalendarService } from '~features/rank-calendar/services/rank-calendar.service';
import { IRankCalendarDropDownQuery } from '~features/rank-calendar/interfaces';
import { IDropDownQuery } from '~features/room-type/interfaces';
import { roomTypeService } from '~features/room-type/services/room-type.service';
import { IPlanCreateBody, IPlanGetListQuery } from '~features/plan/interfaces';
import compact from 'lodash/compact';
import { IGetTaxListForDropdownQuery } from '~features/tax/constants';
import { mapPlanRoomTypePricings } from '../helper';

const initialState: IPlanState = {
    planListQuery: cloneDeep(initPlanListQuery),
    planList: [],
    rankCalenderDropdowns: [],
    roomTypeDropdowns: [],
    planDropdownOptions: [],
    tllRankDropdowns: [],
    taxListForDropDown: [],
    selectedPlan: null,
    searchField: SearchField.ALL,
    totalPlans: 0,
    showLoading: false,
    formStep: 1,
    isFetchingGetPlanDropdown: false,
};

export const fetchPlanList = createAsyncThunk(
    'plan/fetchPlanList',
    async (_, { getState }) => {
        const query = planListQuerySelector(getState() as AppState);
        return await planService.getList(query);
    },
);

export const fetchPlanDetail = createAsyncThunk(
    'plan/fetchPlanDetail',
    async (id: number) => {
        return await planService.getDetail(id);
    },
);

export const getPlanDropdown = createAsyncThunk(
    'plan/getPlanDropdown',
    async (params: IPlanDropDownQuery) => {
        return await planService.getDropdown({
            ...params,
            includeDataFromTll: params.includeDataFromTll || false,
        });
    },
);

export const fetchRankCalendarListForDropdown = createAsyncThunk(
    'rankCalendar/getRankCalendarListForDropdown',
    async (params: IRankCalendarDropDownQuery) => {
        return await rankCalendarService.getRankCalendarListForDropdown(params);
    },
);

export const fetchRoomTypeListForDropdown = createAsyncThunk(
    'room-Type/getRoomTypeListForDropdown',
    async (params: IDropDownQuery) => {
        return await roomTypeService.getRoomTypeListForDropdown(params);
    },
);

export const fetchTaxListForDropdown = createAsyncThunk(
    'plan/getTaxListForDropdown',
    async (query: IGetTaxListForDropdownQuery) => {
        return await planService.getTaxListForDropdown(query);
    },
);

export const createPlan = createAsyncThunk(
    'plan/createPlan',
    async (formData: IPlanCreateBody) => {
        return await planService.create(formData);
    },
);

export const updatePlan = createAsyncThunk(
    'plan/updatePlan',
    async ({ id, formData }: { id: number; formData: IPlanCreateBody }) => {
        return await planService.update(id, formData);
    },
);

const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        setPlanListQuery: (state, action) => {
            state.planListQuery = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IPlanGetListQuery = {
                limit: state.planListQuery.limit,
                orderBy: state.planListQuery.orderBy,
                orderDirection: state.planListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.planListQuery = query;
        },
        setPlanList: (state, action) => {
            state.planList = action.payload;
        },
        setTotalPlans: (state, action) => {
            state.totalPlans = action.payload;
        },
        setPlanDropdownOptions: (state, action) => {
            state.planDropdownOptions = action.payload.data.items;
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload;
        },
        setFormStep: (state, action) => {
            state.formStep = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IPlanGetListQuery = {
                limit: state.planListQuery.limit,
                orderBy: state.planListQuery.orderBy,
                orderDirection: state.planListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.planListQuery.keyword || state.planListQuery.name || '',
            };
            state.planListQuery = query;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRankCalendarListForDropdown.fulfilled, (state, action) => {
            state.rankCalenderDropdowns = action.payload?.data?.items || [];
        });
        builder.addCase(fetchTaxListForDropdown.fulfilled, (state, action) => {
            state.taxListForDropDown =
                (action.payload?.data?.items || []).map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                }) || [];
        });
        builder.addCase(fetchRoomTypeListForDropdown.fulfilled, (state, action) => {
            state.roomTypeDropdowns = action.payload?.data?.items || [];
        });

        builder.addCase(fetchPlanDetail.pending, (state, action) => {
            state.showLoading = true;
        });

        builder.addCase(fetchPlanDetail.fulfilled, (state, action) => {
            state.showLoading = false;
            const selectedPlan = action.payload?.data || {};
            const { planRoomTypes } = selectedPlan;
            const oldRoomTypeIds = planRoomTypes?.map(
                (planRoomType) => planRoomType.roomTypeId,
            );
            const roomTypeIds = union(oldRoomTypeIds);
            selectedPlan.roomTypeIds = compact(roomTypeIds || []);
            // add feId
            selectedPlan.planRoomTypes?.forEach((planRoomType) => {
                planRoomType?.planRoomTypePricings?.forEach(
                    (planRoomTypePricing) =>
                        (planRoomTypePricing.feId = planRoomTypePricing.id),
                );
                planRoomType.feId = planRoomType.id;
            });
            selectedPlan.planRoomTypes = uniqBy(selectedPlan.planRoomTypes, 'roomTypeId');
            selectedPlan.planSaleItems?.forEach((planSaleItem) => {
                planSaleItem.feId = planSaleItem.id;
            });

            mapPlanRoomTypePricings(selectedPlan.planRoomTypes);
            state.selectedPlan = selectedPlan;
        });
        builder.addCase(getPlanDropdown.pending, (state) => {
            state.isFetchingGetPlanDropdown = true;
        });
        builder.addCase(getPlanDropdown.fulfilled, (state, action) => {
            state.planDropdownOptions = (action.payload?.data?.items || [])?.map(
                (item) =>
                    ({
                        label: item.name,
                        value: item.id,
                        roomTypeIds: item.roomTypeIds || [],
                        type: item.planType as PlanType,
                        checkInTime: item.checkInTime,
                        checkOutTime: item.checkOutTime,
                        planRankStatus: item.planRankStatus,
                    } || []),
            );
            state.isFetchingGetPlanDropdown = false;
        });
        builder.addCase(fetchPlanList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.planList = action.payload?.data?.items || [];
            state.totalPlans = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchPlanList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(createPlan.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(createPlan.fulfilled, (state, action) => {
            state.showLoading = false;
        });
        builder.addCase(updatePlan.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(updatePlan.fulfilled, (state, action) => {
            state.showLoading = false;
        });
    },
});

export const {
    setPlanDropdownOptions,
    setPlanListQuery,
    setPlanList,
    setTotalPlans,
    setSelectedPlan,
    setSearchField,
    setFormStep,
    setKeyword,
    resetState,
} = planSlice.actions;

export const planStateSelector = (state: AppState) => state.plan;

export const planListSelector = (state: AppState) => {
    return state.plan.planList;
};

export const planListQuerySelector = (state: AppState) => {
    return state.plan.planListQuery;
};

export const planDropdownSelector = (state: AppState) => {
    return state.plan.planDropdownOptions;
};

export const planDropdownActiveSelector = (state: AppState) => {
    return (
        state.plan.planDropdownOptions?.filter(
            (item) => item.planRankStatus === PlanRankStatus.ACTIVE,
        ) || []
    );
};

export const selectedPlanSelector = (state: AppState) => {
    return state.plan.selectedPlan;
};

export const formStepSelector = (state: AppState) => {
    return state.plan.formStep;
};

export const showLoadingSelector = (state: AppState) => {
    return state.plan.showLoading;
};
export const totalPlanSelector = (state: AppState) => {
    return state.plan.totalPlans;
};

export const taxListDropdownSelector = (state: AppState) => {
    return state.plan.taxListForDropDown;
};

export const rankCalendarDropDownsSelector = (state: AppState) => {
    return state.plan.rankCalenderDropdowns;
};

export const roomTypeDropDownsSelector = (state: AppState) => {
    return state.plan.roomTypeDropdowns?.filter((item) => !item.isPullFromTll) || [];
};

export const totalPageSelector = (state: AppState) => {
    const { totalPlans, planListQuery } = state.plan;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = planListQuery;
    return Math.ceil(totalPlans / limit);
};

export const planReducer = planSlice.reducer;
