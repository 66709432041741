import { MIN_PRICE } from './../../common/constants';
import { INPUT_TEXT_MAX_LENGTH, MAX_INTEGER, MAX_PRICE } from '~common/constants';
import yup from '~plugins/yup';

export const createFacilityTypeSchema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .required(),
    abbreviation: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    businessTimes: yup.array().of(yup.string().required()).required(),
    price: yup
        .string()
        .minPriceFullAndHalfWidth(MIN_PRICE)
        .maxPriceFullAndHalfWidth(MAX_PRICE)
        .required(),
    timeUnit: yup
        .object()
        .shape({
            hour: yup.string().maxFullAndHalfWidth(MAX_INTEGER).nullable().optional(),
            minute: yup.string().maxFullAndHalfWidth(MAX_INTEGER).nullable().optional(),
        })
        .nullable()
        .optional(),
});

export const bulkUpdateFacilityTypesSchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                name: yup
                    .string()
                    .trim()
                    .hasNoSpecialCharacters()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .required(),
                abbreviation: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
                businessTimes: yup.array().of(yup.string().required()).required(),
                price: yup
                    .string()
                    .minPriceFullAndHalfWidth(MIN_PRICE)
                    .maxPriceFullAndHalfWidth(MAX_PRICE)
                    .required(),
                timeUnit: yup
                    .object()
                    .shape({
                        hour: yup.string().maxFullAndHalfWidth(MAX_INTEGER).optional(),
                        minute: yup.string().maxFullAndHalfWidth(MAX_INTEGER).optional(),
                    })
                    .optional(),
            })
            .required(),
    ),
});
