import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardControlOverlay } from '~features/dashboard/constants';
import './ControlOverlay.scss';
import {
    SnippetsOutlined,
    AuditOutlined,
    NotificationOutlined,
    ContactsOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { scroller } from 'react-scroll';
import { ReactComponent as RoomIcon } from '~assets/images/room_icon.svg';

export const ControlOverlay = () => {
    const { t } = useTranslation();

    const icons = useMemo(() => {
        return {
            [DashboardControlOverlay.bookingOverview]: <SnippetsOutlined />,
            [DashboardControlOverlay.bookingList]: <AuditOutlined />,
            [DashboardControlOverlay.notification]: <NotificationOutlined />,
            [DashboardControlOverlay.contact]: <ContactsOutlined />,
            [DashboardControlOverlay.roomOccupancy]: <RoomIcon className="room-icon" />,
        };
    }, []);
    const options = useMemo(() => {
        return Object.values(DashboardControlOverlay).map((item) => {
            return {
                label: t('dashboard.controlOverlay.' + item),
                value: item,
                icon: icons[item],
            };
        });
    }, [t, icons]);
    const scrollTo = useCallback((item: DashboardControlOverlay) => {
        scroller.scrollTo(item, {
            duration: 300,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'staff-layout-content',
            offset: -200,
        });
    }, []);
    return (
        <div className="dashboard-control-overlay-item">
            {options.map((item) => {
                return (
                    <div key={item.value}>
                        <Tooltip title={item.label} placement="right">
                            <div
                                className="control-overlay-item"
                                key={item.value}
                                onClick={() => scrollTo(item.value)}
                            >
                                {item.icon}
                            </div>
                        </Tooltip>
                    </div>
                );
            })}
        </div>
    );
};
