import i18next from 'i18next';

export const breadcrumbs = () => [
    {
        text: i18next.t('inventory.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('inventory.list.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('inventory.list.breadcrumbs.inventory'),
    },
];

export enum StoppingRoomCountColumn {
    ID = 'id',
    NAME = 'name',
    TOTAL_ROOMS = 'totalRooms',
    STOPPING_ROOM_COUNT = 'stoppingRoomCount',
}

export const stopSellingFormItemDefault = {
    roomTypeId: null,
    roomIds: [],
    rangeDay: null,
};

export enum StoppingInventoryType {
    ROOM_TYPE = 'room_type',
    FACILITY_TYPE = 'facility_type',
}

export const MAX_HEIGHT_GRID_VIEW = 700;
export const MIN_HEIGHT_GRID_VIEW = 200;
export const INVENTORY_PAGE_FOOTER_HEIGHT = 80;
export const INVENTORY_PAGE_PADDING = 25;

export const INVENTORY_COLUMN_WIDTH = 126;
export const STOP_SELLING_COLUMN_WIDTH = 146;
export const MAX_STOPPING_ROOMS = 99;
export const CELL_HEIGHT = 33;
export const HEADER_HEIGHT = 62;
export const HEADER_TOP_PADDING = 30;
export const ROW_HEADER_WIDTH = 253;
export const MIN_WIDTH_CELL = 168;
export const INVENTORY_ROW_HEADER_WIDTH = 270;
export const INVENTORY_CELL_HEIGHT = 56;
export const MAX_BLOCK_MONTH_DURATION = 3;

// For stopping room count form modal
export const DEFAULT_SCROLL_Y_STOPPING_ROOM_COUNT_TABLE = 400;
export const HEADER_STOPPING_ROOM_COUNT_TABLE_HEIGHT = 55;
