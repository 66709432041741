import { useEffect, useMemo } from 'react';
import { getSplitReceiptByGroupMap } from '~features/facility-booking/helper.split-receipt';
import {
    IFacilityBookingReceipt,
    IReceiptByGroupMap,
} from '~features/facility-booking/interfaces';
import { facilityBookingListStateSelector } from '~features/facility-booking/reducers/facility-booking.reducer';
import {
    setReceiptByGroupMap,
    splitReceiptStateSelector,
} from '~features/facility-booking/reducers/split-receipt.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import BillToTab from '../BillToTab/BillToTab';
import ReceiptDetailTab from './ReceiptDetailTab/ReceiptDetailTab';
import './SplitBillByGroup.scss';

interface IProps {
    receipt: IFacilityBookingReceipt | null;
}

function SplitBillByRoom(props: IProps) {
    const { receipt } = props;
    const dispatch = useAppDispatch();
    const { selectedFacilityBookingDetail } = useAppSelector(
        facilityBookingListStateSelector,
    );
    const { numberOfGroups } = useAppSelector(splitReceiptStateSelector);

    const groupList = useMemo(() => {
        return Array.from(Array(numberOfGroups).keys()).map((number) => ({
            id: `${number + 1}`,
            name: `${number + 1}`,
        }));
    }, [numberOfGroups]);

    useEffect(() => {
        generateReceiptByGroupMap(receipt);
    }, [receipt, groupList, selectedFacilityBookingDetail]);

    const generateReceiptByGroupMap = (receipt: IFacilityBookingReceipt | null) => {
        const receiptByGroupMap: IReceiptByGroupMap = getSplitReceiptByGroupMap(
            groupList,
            receipt,
            selectedFacilityBookingDetail?.guest.yomigana || '',
        );
        dispatch(setReceiptByGroupMap(receiptByGroupMap));
    };
    return (
        <div className="split-facility-booking-split-bill-by-group-wrapper">
            <BillToTab />
            <ReceiptDetailTab />
        </div>
    );
}

export default SplitBillByRoom;
