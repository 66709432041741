import { yupResolver } from '@hookform/resolvers/yup';
import { mapValues } from 'lodash';
import { MAX_INTEGER } from '~common/constants';
import yup from '~plugins/yup';

export const bulkSaveStoppingRoomCountSchema = yup.lazy((object) =>
    yup.object(
        mapValues(object, () =>
            yup.object({
                totalRooms: yup.number().required(),
                stoppingRoomCount: yup
                    .string()
                    .maxFullAndHalfWidth(yup.ref('totalRooms'))
                    .required(),
            }),
        ),
    ),
);

const bulkStopSellingSchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                roomTypeId: yup.number().max(MAX_INTEGER).required(),
                roomIds: yup
                    .array()
                    .min(1)
                    .of(yup.number().max(MAX_INTEGER).required())
                    .required(),
                rangeDay: yup.mixed().required(),
            })
            .required(),
    ),
});

export const bulkStopSellingResolver = yupResolver(bulkStopSellingSchema);
export const bulkStopInventoryInsertResolver = yupResolver(
    yup.lazy((object) =>
        yup.object(
            mapValues(object, () =>
                yup.object({
                    totalAvailableRooms: yup.number(),
                    stoppingRooms: yup
                        .number()
                        .transform((value) => Number(value))
                        .min(0)
                        .max(yup.ref('totalAvailableRooms'))
                        .required(),
                }),
            ),
        ),
    ),
);
