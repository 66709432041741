import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkUserPermission, formatMoney } from '~common/commonFunctions';
import {
    AdminGroup,
    AntdOrderDirection,
    OrderDirection,
    UserGroup,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import { ModalConfirmDeletion } from '~components';
import {
    ChildrenTypeColumn,
    ChildrenTypeOrderBy,
    PriceType,
} from '~features/children-type/constants';
import { IChildrenType } from '~features/children-type/interfaces';
import {
    childrenTypeListQuerySelector,
    childrenTypeListSelector,
    fetchChildrenTypeList,
    setChildrenTypeListQuery,
    setIsShowForm,
    setSelectedChildrenTypeList,
    showLoadingListSelector,
} from '~features/children-type/reducers/children-type.reducer';
import { childrenTypeService } from '~features/children-type/services/children-type.service';
import { useAppDispatch, useAppSelector } from '~hooks';
import './ChildrenTypeList.scss';

function ChildrenTypeList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const childrenTypeListQuery = useAppSelector(childrenTypeListQuerySelector);
    const childrenTypeList = useAppSelector(childrenTypeListSelector);
    const showLoadingList = useAppSelector(showLoadingListSelector);
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const rows: IChildrenType[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundType = childrenTypeList.find(
                (type: IChildrenType) => type.autoGeneratedCode === selectedRowKey,
            );
            if (foundType) rows.push(foundType);
        });
        return rows;
    }, [selectedRowKeys]);

    const onClickOneRow = (childrenType: IChildrenType) => {
        if (isReadOnly) {
            return {
                onClick: () => {
                    return;
                },
            };
        }
        return {
            onClick: () => {
                editChildrenType(childrenType);
            },
        };
    };

    const handleEdit = () => {
        dispatch(setIsShowForm(true));
        dispatch(setSelectedChildrenTypeList(selectedRows));
    };
    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await childrenTypeService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('childrenType.list.delete.success'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const showConfirmDialog = () => {
        const selectedChildrenTypes = childrenTypeList.filter(
            (childrenType: IChildrenType) => {
                return selectedRowKeys.includes(childrenType.autoGeneratedCode);
            },
        );

        const selectedChildrenTypesForSiteController = selectedChildrenTypes.filter(
            (childrenType: IChildrenType) => childrenType.linkageCode,
        );

        if (
            selectedChildrenTypesForSiteController &&
            selectedChildrenTypesForSiteController.length
        ) {
            Modal.error({
                content: t('childrenType.list.delete.siteControllerError'),
                okText: t('common.ok'),
            });
            return;
        }
        ModalConfirmDeletion({
            description: t('childrenType.list.delete.confirm'),
            okButtonProps: { danger: true },
            deletedItems: selectedChildrenTypes.map((childrenType: IChildrenType) =>
                childrenType.name.toString(),
            ),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const fetchData = () => {
        dispatch(fetchChildrenTypeList());
    };

    useEffect(() => {
        fetchData();
    }, [childrenTypeListQuery]);

    const editChildrenType = (childrenType: IChildrenType) => {
        dispatch(setIsShowForm(true));
        dispatch(setSelectedChildrenTypeList([childrenType]));
    };

    const onChange: TableProps<IChildrenType>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        // this is for pagination, sort, filter logic later
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setChildrenTypeListQuery({
                    ...childrenTypeListQuery,
                    orderBy: ChildrenTypeOrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setChildrenTypeListQuery({
                    ...childrenTypeListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const childrenTypeColumns: ColumnsType<IChildrenType> = [
        {
            title: t('childrenType.list.columns.id'),
            width: '120px',
            key: ChildrenTypeColumn.AUTO_GENERATED_CODE,
            sorter: true,

            onCell: () => {
                return {
                    style: { color: '#1c3e86' },
                };
            },
            render: (childrenType: IChildrenType) => {
                return <>{childrenType.autoGeneratedCode}</>;
            },
        },
        {
            title: t('childrenType.list.columns.name'),
            dataIndex: ChildrenTypeColumn.NAME,
            sorter: true,
            ellipsis: true,
        },
        {
            title: t('childrenType.list.columns.priceValue'),
            render: (childrenType: IChildrenType) => {
                return childrenType.priceType === PriceType.FIXED ? (
                    <div>
                        {' '}
                        {t('childrenType.list.yen', {
                            value: formatMoney(childrenType.priceValue),
                        })}{' '}
                    </div>
                ) : (
                    <div>
                        {' '}
                        {t('childrenType.list.pricePerAdult', {
                            value: childrenType.priceValue,
                        })}
                    </div>
                );
            },
            key: ChildrenTypeColumn.PRICE_VALUE,
            sorter: true,
        },
        {
            title: t('childrenType.list.columns.linkageCode'),
            dataIndex: ChildrenTypeColumn.LINKAGE_CODE,
            sorter: true,
        },
    ];

    return (
        <div className="children-type-list-wrapper">
            <div className="children-type-list-header">
                {!isReadOnly && selectedRowKeys?.length > 0 && (
                    <div className="list-header-left">
                        {selectedRowKeys?.length === 1 && (
                            <Button
                                type="text"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={handleEdit}
                            />
                        )}
                        <Button
                            type="text"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={showConfirmDialog}
                        />
                    </div>
                )}
            </div>

            <Table
                rowSelection={rowSelection}
                columns={childrenTypeColumns}
                dataSource={childrenTypeList}
                pagination={false}
                rowKey="autoGeneratedCode"
                onRow={onClickOneRow}
                loading={showLoadingList}
                scroll={{ y: '400px' }}
                onChange={onChange}
            />
        </div>
    );
}

export default ChildrenTypeList;
