import { Button, Card, Col, notification, Row, Tag, Tooltip } from 'antd';
import { CalendarOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import './RoomBookingItemGridView.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    bulkDeleteRoomBookingItems,
    selectedRoomBookingDetailSelector,
    setSelectedRoomBookingDetail,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useMemo } from 'react';
import { parseDate } from '~plugins/dayjs';
import { showRoomBookingItemDetailModal } from '~features/room-booking/util';
import { IRoomBookingDetail, IRoomBookingItem } from '~features/room-booking/interfaces';
import { sumBy } from 'lodash';
import { ModalConfirmDeletion, NoData } from '~components';
import { useNavigate } from 'react-router-dom';
import { setSelecting } from '~features/room-booking/reducers/schedule.reducer';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import { BookingErrorModal } from '~features/room-booking/pages/RoomBookingListPage/components/RoomBookingList/BookingErrorModal';
import classNames from 'classnames';
import {
    checkRoomBookingItemCanDelete,
    getRoomTypeName,
    getRoomTypeTitleError,
    isRoomTypeError,
} from '~features/room-booking/helper';
import { isFrozenBooking } from '~features/room-booking/helper.update-booking';
import { checkUserPermission } from '~common/commonFunctions';
import { UserGroup } from '~common/constants';

type Props = {
    roomBookingDetail: IRoomBookingDetail | null;
    fetchRoomBookingDetail: (id: number) => void;
};

function RoomBookingItemGridView({ roomBookingDetail, fetchRoomBookingDetail }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAdmin, isHotelAdmin } = useMemo(() => {
        return {
            isAdmin: checkUserPermission([UserGroup.ADMIN]),
            isHotelAdmin: checkUserPermission([UserGroup.HOTEL_ADMIN]),
        };
    }, []);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const isBookingWalkIn = !selectedRoomBookingDetail?.marketingChannel?.isPullFromTll;
    const roomBookingItems = useMemo(() => {
        return roomBookingDetail?.roomBookingItems || [];
    }, [roomBookingDetail]);

    const isFrozen = useMemo(() => {
        return isFrozenBooking(roomBookingItems);
    }, [roomBookingItems]);

    const handleEditRoomBookingItem = (roomBookingItem: IRoomBookingItem) => {
        const element = document.getElementById(
            `room-booking-item-grid-id-${roomBookingItem.id}`,
        );
        if (!element) {
            return;
        }
        dispatch(setSelecting(false));
        showRoomBookingItemDetailModal({
            bookingItem: roomBookingItem,
            roomBookingId: roomBookingDetail?.id || 0,
            isFromTll: !!roomBookingDetail?.tllDataId,
            element,
            isSingleBooking: roomBookingItems?.length === 1,
            isFrozen: isFrozen,
        });
    };

    const onConfirmDeletion = async (booking: IRoomBookingItem) => {
        if (
            !checkRoomBookingItemCanDelete(
                booking,
                isAdmin,
                isHotelAdmin && isBookingWalkIn,
            )
        ) {
            BookingErrorModal({
                title: t('roomBooking.list.message.titleDelete'),
                okText: t('roomBooking.list.statusModalConfirm.okText'),
                description: t(
                    'roomBooking.detail.deleteConfirmDialog.deleteByStatusError',
                ),
                errorItems: [],
            });
            return;
        }
        if (!Number(booking.id)) return;
        const response = await dispatch(
            bulkDeleteRoomBookingItems([booking.id as number]),
        );
        if (bulkDeleteRoomBookingItems.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomBooking.detail.message.deleteSuccess'),
                });
                if (
                    roomBookingDetail &&
                    roomBookingDetail?.roomBookingItems?.length - 1 > 0
                ) {
                    fetchRoomBookingDetail(roomBookingDetail.id);
                } else {
                    navigate('/room-booking');
                    dispatch(setSelectedRoomBookingDetail(null));
                }
                return;
            }
            notification.error({
                message: response.payload?.message || '',
            });
        }
    };

    const deleteBookingItem = (roomBookingItem: IRoomBookingItem) => {
        ModalConfirmDeletion({
            deletedItems: [],
            buttonCancelText: t('common.buttonCancelText'),
            buttonDeleteText: t('common.buttonDeleteText'),
            okButtonProps: { danger: true },
            onClickButtonDelete: () => onConfirmDeletion(roomBookingItem),
        });
    };

    return (
        <div className="room-booking-item-grid-view">
            <Row gutter={12}>
                {roomBookingItems.map((roomBookingItem) => {
                    const guest = roomBookingItem.representativeGuest;
                    const roomName =
                        roomBookingItem.bookingStatus !==
                        RoomBookingItemBookingStatus.CANCELLED
                            ? roomBookingItem?.room?.name
                            : '';
                    return (
                        <Col span={6} key={roomBookingItem.id}>
                            <Card
                                className="booking-detail-card"
                                title={
                                    <div className="guest-name-header">
                                        <p className="full-name-guest medium-text font-weight-text-600">
                                            {guest?.fullName}
                                            {roomBookingItems.length > 1 &&
                                                roomBookingItem.isRepresentativeRoom && (
                                                    <span className="representative-guest">
                                                        {` ${t(
                                                            'roomBooking.detail.bookingItemCard.representativeGuest',
                                                        )}`}
                                                    </span>
                                                )}
                                        </p>
                                        <p className="yomigana-guest  mini-text font-weight-text-400">
                                            {guest?.yomigana}
                                        </p>
                                    </div>
                                }
                                actions={[
                                    <Button
                                        key="button-edit-room-booking-item"
                                        type="text"
                                        block
                                        className="medium-text font-weight-text-400"
                                        icon={
                                            <EditOutlined
                                                key="edit"
                                                style={{ fontSize: 20 }}
                                            />
                                        }
                                        onClick={() =>
                                            handleEditRoomBookingItem(roomBookingItem)
                                        }
                                    >
                                        <div
                                            style={{ position: 'absolute' }}
                                            id={`room-booking-item-grid-id-${roomBookingItem.id}`}
                                        />
                                    </Button>,
                                    <Button
                                        key="button-delete-room-booking-item"
                                        type="text"
                                        block
                                        className="medium-text font-weight-text-400"
                                        icon={<DeleteOutlined key="delete" />}
                                        onClick={() => deleteBookingItem(roomBookingItem)}
                                    />,
                                ]}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Tag
                                            className="stay-time-tag medium-text font-weight-text-500"
                                            icon={<CalendarOutlined />}
                                        >
                                            {`${parseDate(
                                                roomBookingItem.startDateOfStay,
                                            )?.fmYYYYMMDD()} - ${parseDate(
                                                roomBookingItem.endDateOfStay,
                                            )?.fmYYYYMMDD()}`}
                                        </Tag>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div className="room-type-name-row medium-text font-weight-text-400">
                                            <Tooltip
                                                title={getRoomTypeTitleError(
                                                    roomBookingItem.roomType,
                                                )}
                                            >
                                                <div
                                                    className={classNames(
                                                        'text-truncate',
                                                        {
                                                            'room-type-error':
                                                                isRoomTypeError(
                                                                    roomBookingItem.roomType,
                                                                ),
                                                        },
                                                    )}
                                                >
                                                    {getRoomTypeName(
                                                        roomBookingItem.roomType,
                                                    )}
                                                </div>
                                            </Tooltip>
                                            <div className="text-truncate">
                                                {`/ ${roomName || ''}`}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <span
                                            className={`room-booking-status room-booking-status-${roomBookingItem.bookingStatus} text-truncate`}
                                        >
                                            {t(
                                                `roomBooking.page.bookingStatus.${roomBookingItem.bookingStatus}`,
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div className="plan-name medium-text font-weight-text-400 text-truncate">
                                            {!roomBookingItem.plan?.isPullFromTll &&
                                            !!roomBookingItem.plan?.id ? (
                                                roomBookingItem.plan?.name ||
                                                t(
                                                    `roomBooking.detail.receipt.plan.notExist`,
                                                )
                                            ) : (
                                                <Tooltip
                                                    title={t(
                                                        'roomBooking.detail.message.planNotExist',
                                                    )}
                                                >
                                                    <span className="text-truncate plan-name-error">
                                                        {roomBookingItem.plan?.name ||
                                                            t(
                                                                `roomBooking.detail.receipt.plan.notExist`,
                                                            )}
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={14}>
                                        <div className="medium-text font-weight-text-400">
                                            <span>
                                                {`${t('roomBooking.schedule.adult')}: ${
                                                    roomBookingItem.numberOfAdults
                                                } `}
                                            </span>
                                            <span>
                                                {t(
                                                    'roomBooking.schedule.genderBreakdown',
                                                    {
                                                        male:
                                                            roomBookingItem.numberOfMale ||
                                                            0,
                                                        female:
                                                            roomBookingItem.numberOfFemale ||
                                                            0,
                                                        other:
                                                            roomBookingItem.numberOfOtherGenderGuest ||
                                                            0,
                                                    },
                                                )}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={10}>
                                        <div className="medium-text font-weight-text-400">
                                            {`${t('roomBooking.schedule.kid')}: ${
                                                roomBookingItem
                                                    .roomBookingItemChildrenTypes?.length
                                                    ? sumBy(
                                                          roomBookingItem.roomBookingItemChildrenTypes,
                                                          (item) => item.quantity,
                                                      )
                                                    : 0
                                            }`}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    );
                })}

                {roomBookingItems.length === 0 && (
                    <Col span={24}>
                        <div className="no-data-wrapper">
                            <NoData />
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default RoomBookingItemGridView;
