import { reportJa } from './ja/report';
import { cancellationPageJa } from './ja/report-cancellation.locale';
import { operationStatusPageJa } from './ja/report-operation-status.locale';
import { salesPaymentPageJa } from './ja/report-sales-payment.locale';
import { salesByCategoryJa } from './ja/sales-by-category.locale';
import salesSummaryJa from './ja/sales-summary.locale';
import unpaidDetailJa from './ja/unpaid-detail.locale';
import paymentSummaryJa from './ja/payment-summary.locale';
import paymentDetailsJa from './ja/report-payment-details.locale';

import paymentSummaryEn from './en/payment-summary.locale';
import { cancellationPageEn } from './en/report-cancellation.locale';
import { operationStatusPageEn } from './en/report-operation-status.locale';
import paymentDetailsEn from './en/report-payment-details.locale';
import { salesPaymentPageEn } from './en/report-sales-payment.locale';
import { reportEn } from './en/report';
import { salesByCategoryEn } from './en/sales-by-category.locale';
import salesSummaryEn from './en/sales-summary.locale';
import unpaidDetailEn from './en/unpaid-detail.locale';
import localTaxJa from './ja/local-tax.locale';
import localTaxEn from './en/local-tax.locale';
import { currentGuestReportJa } from './ja/report-current-guest.locale';
import { currentGuestReportEn } from './en/report-current-guest.locale';
import { mealReportJa } from './ja/report-meal.locale';
import { mealReportEn } from './en/report-meal.locale';
import { salesByPlansReportJa } from './ja/sales-by-plans.locale';
import { salesByPlansReportEn } from './en/sales-by-plans.locale';
import { salesByOtaReportJa } from './ja/sales-by-ota.locale';
import { salesByOtaReportEn } from './en/sales-by-ota.locale';

export const report = {
    ja: {
        ...reportJa,
        salesPayment: salesPaymentPageJa,
        salesByCategory: salesByCategoryJa,
        salesSummary: salesSummaryJa,
        unpaidDetail: unpaidDetailJa,
        paymentSummary: paymentSummaryJa,
        cancellation: cancellationPageJa,
        operationStatus: operationStatusPageJa,
        paymentDetails: paymentDetailsJa,
        localTax: localTaxJa,
        currentGuest: currentGuestReportJa,
        mealReport: mealReportJa,
        salesByPlans: salesByPlansReportJa,
        salesByOta: salesByOtaReportJa,
    },
    en: {
        ...reportEn,
        salesPayment: salesPaymentPageEn,
        salesByCategory: salesByCategoryEn,
        salesSummary: salesSummaryEn,
        unpaidDetail: unpaidDetailEn,
        paymentSummary: paymentSummaryEn,
        cancellation: cancellationPageEn,
        operationStatus: operationStatusPageEn,
        paymentDetails: paymentDetailsEn,
        localTax: localTaxEn,
        currentGuest: currentGuestReportEn,
        mealReport: mealReportEn,
        salesByPlans: salesByPlansReportEn,
        salesByOta: salesByOtaReportEn,
    },
};
