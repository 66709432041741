import { Content } from 'antd/lib/layout/layout';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import {
    facilityBookingListStateSelector,
    getFacilityBookingDetail,
    getFacilityBookingReceipt,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { setIsShowSplitFacilityBookingReceiptPrintingModal } from '~features/facility-booking/reducers/split-receipt.reducer';
import { getSaleItemListForDropdown } from '~features/sale-item/reducers/sale-item.reducer';
import { INVOICE_PATH } from '~features/room-booking/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import BottomButtonGroup from './components/BottomButtonGroup/BottomButtonGroup';
import NotificationAlert from './components/NotificationAlert/NotificationAlert';
import SelectSplitBillTypePanel from './components/SelectSplitBillTypePanel/SelectSplitBillTypePanel';
import SplitBillByGroup from './components/SplitBillByGroup/SplitBillByGroup';
import SplitFacilityBookingReceiptPrintingModal from './components/SplitFacilityBookingPrintingModal/SplitFacilityBookingReceiptPrintingModal';
import './SplitFacilityBookingReceiptPage.scss';

function SplitFacilityBookingReceiptPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { facilityBookingId } = useParams();
    const { facilityBookingReceipt } = useAppSelector(facilityBookingListStateSelector);
    const breadcrumbs = () => [
        {
            text: t('facilityBooking.splitReceipt.home'),
        },
        {
            text: t('facilityBooking.splitReceipt.facilityBookingList'),
        },
        {
            text: t('facilityBooking.splitReceipt.splitReceipt'),
        },
    ];

    useEffect(() => {
        dispatch(getSaleItemListForDropdown({}));
        return () => {
            dispatch(setIsShowSplitFacilityBookingReceiptPrintingModal(false));
        };
    }, []);

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(getFacilityBookingDetail(id));
        if (getFacilityBookingDetail.fulfilled.match(response)) {
            if (!response.payload?.success) {
                navigate('/404');
            }
        }
    }, []);
    const _getReceipt = useCallback(async (id: number) => {
        const response = await dispatch(getFacilityBookingReceipt(id));
        if (getFacilityBookingDetail.fulfilled.match(response)) {
            if (!response.payload?.success) {
                navigate('/404');
            }
        }
    }, []);

    useEffect(() => {
        if (typeof Number(facilityBookingId) === 'number') {
            _getDetail(Number(facilityBookingId));
            _getReceipt(Number(facilityBookingId));
        } else {
            navigate('/404');
        }
    }, []);

    return (
        <div className="split-facility-booking-receipt-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={pathname.endsWith(INVOICE_PATH) ? t('facilityBooking.splitReceipt.titleInvoice') : t('facilityBooking.splitReceipt.title')}
            />
            <NotificationAlert />
            <Content className="split-receipt-page-content">
                <SelectSplitBillTypePanel />
                <SplitBillByGroup receipt={facilityBookingReceipt} />
            </Content>
            <BottomButtonGroup />
            <SplitFacilityBookingReceiptPrintingModal />
        </div>
    );
}

export default SplitFacilityBookingReceiptPage;
