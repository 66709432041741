import classNames from 'classnames';
import { HTMLProps } from 'react';
import { bookingPercent } from '~features/room-booking/helper';
import { IScheduleItem } from '~features/room-booking/interfaces';
import {
    scheduleStateSelector,
    setDateHeaderFilterUnassignForm,
    setIsShowUnassignList,
} from '~features/room-booking/reducers/schedule.reducer';
import { getBookingUnassignedList } from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';

type Props = HTMLProps<HTMLDivElement> & {
    column: {
        id: string;
        label: string;
    };
    row: IScheduleItem;
    today: string;
};

export const Header = ({ column, style, today, row }: Props) => {
    const { statisticsByDate, numberOfRooms } = useAppSelector(scheduleStateSelector);
    const dispatch = useAppDispatch();
    const unassigned = (statisticsByDate[column.id]?.['totalUnassigned'] || 0) as number;
    const booked = (statisticsByDate[column.id]?.['totalBooked'] || 0) as number;
    const stoppedRoomCount = (statisticsByDate[column.id]?.['totalStoppedRoomCount'] ||
        0) as number;
    const percent = bookingPercent(booked, numberOfRooms - stoppedRoomCount);

    const onShowUnassignList = () => {
        const dateSelector = parseDate(column.id).fmYYYYMMDD();
        dispatch(setDateHeaderFilterUnassignForm([dateSelector, dateSelector]));
        dispatch(setIsShowUnassignList(true));
        dispatch(
            getBookingUnassignedList({
                start: dateSelector,
                end: dateSelector,
                isFromBookingSchedule: false,
            }),
        );
    };
    return (
        <div style={{ ...style }} className="header-item">
            <div className="header-item-row-container">
                <div className="header-item-row">
                    <span
                        className={classNames('header-item-percent', {
                            'color-darkest-red': percent === 100,
                            'color-dark-red': percent < 100 && percent >= 90,
                            'color-medium-red': percent < 90 && percent >= 80,
                            'color-light-red': percent < 80 && percent >= 70,
                        })}
                    >
                        {percent}%
                    </span>
                </div>
                <div className="header-item-row">
                    <span
                        className={classNames('header-item-day', {
                            today: column.id === today,
                        })}
                    >
                        {column.label}
                    </span>
                </div>
                <div className="header-item-row">
                    <span
                        className="header-item-count"
                        onClick={() => {
                            onShowUnassignList();
                        }}
                    >
                        {unassigned}
                    </span>
                </div>
            </div>
        </div>
    );
};
