import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _, { cloneDeep, trim } from 'lodash';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    ReadNotificationType,
} from '~common/constants';
import { AppState } from '~plugins/redux-toolkit/store';
import { initNotificationListQuery, SearchField } from '../constants';
import {
    IBookingNotification,
    INotificationCreateBody,
    INotificationGetListQuery,
    INotificationState,
} from '../interfaces';
import { notificationsService } from '../services/notification.service';

const initialState: INotificationState = {
    notificationListQuery: cloneDeep(initNotificationListQuery),
    notificationList: [],
    changeStatusBooingNotificationList: [],
    adminNotificationList: [],
    temporaryBookingNotificationList: [],
    showLoading: false,
    searchField: SearchField.ALL,
    selectedNotification: null,
    totalNotifications: 0,
    adminNotifyReadTimeAt: '',
    changeStatusBookingNotifyReadTimeAt: '',
    temporaryBookingNotifyReadTimeAt: '',
    temporaryBookingNotificationMessage: '',
};

export const fetchNotificationList = createAsyncThunk(
    'notification/fetchNotification',
    async (_, { getState }) => {
        const query = notificationListQuerySelector(getState() as AppState);
        return await notificationsService.getList(query);
    },
);

export const getAdminNotificationList = createAsyncThunk(
    'notification/getAdminNotificationList',
    async (query: INotificationGetListQuery) => {
        return await notificationsService.getAdminNotificationList();
    },
);

export const getNotificationList = createAsyncThunk(
    'notification/getNotificationList',
    async () => {
        return await notificationsService.getNotificationList();
    },
);

export const getNotificationDetail = createAsyncThunk(
    'notification/getNotificationDetail',
    async (id: number) => {
        return await notificationsService.getDetail(id);
    },
);

export const createNotification = createAsyncThunk(
    'notification/createNotification',
    async (formData: INotificationCreateBody) => {
        return await notificationsService.create(formData);
    },
);

export const getTemporaryBookingItemList = createAsyncThunk(
    'notification/getTemporaryBookingItemList',
    async (_, { getState }) => {
        return await notificationsService.getTemporaryBookingItemList();
    },
);

export const updateReadNotificationTime = createAsyncThunk(
    'notification/updateReadNotificationTime',
    async (body: { type: string }) => {
        return await notificationsService.updateReadNotificationTime(body);
    },
);

export const getReadNotificationTime = createAsyncThunk(
    'notification/getReadNotificationTime',
    async () => {
        return await notificationsService.getReadNotificationTime();
    },
);

export const updateNotification = createAsyncThunk(
    'notification/updateNotification',
    async ({ id, formData }: { id: number; formData: INotificationCreateBody }) => {
        return await notificationsService.update(id, formData);
    },
);

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotificationListQuery: (state, action) => {
            state.notificationListQuery = action.payload;
        },
        setNotificationList: (state, action) => {
            state.notificationList = action.payload;
        },
        setChangeStatusBookingNotificationList: (state, action) => {
            state.changeStatusBooingNotificationList = _.orderBy(
                action.payload,
                'updatedAt',
                'desc',
            );
        },
        setAdminNotificationList: (state, action) => {
            state.adminNotificationList = _.orderBy(action.payload, 'updatedAt', 'desc');
        },
        setTemporaryBookingNotificationList: (state, action) => {
            state.temporaryBookingNotificationList = _.orderBy(
                action.payload,
                'updatedAt',
                'desc',
            );
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: INotificationGetListQuery = {
                limit: state.notificationListQuery.limit,
                orderBy: state.notificationListQuery.orderBy,
                orderDirection: state.notificationListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.notificationListQuery = query;
        },
        setTotalNotification: (state, action) => {
            state.totalNotifications = action.payload;
        },
        setSelectedNotification: (state, action) => {
            state.selectedNotification = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchNotificationList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.notificationList = action.payload?.data?.items || [];
            state.totalNotifications = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(getAdminNotificationList.fulfilled, (state, action) => {
            const notificationList =
                action.payload?.data?.adminNotifications?.items || [];
            state.adminNotificationList = _.orderBy(
                notificationList,
                'updatedAt',
                'desc',
            );
        });
        builder.addCase(getNotificationList.fulfilled, (state, action) => {
            const notificationList = action.payload?.data?.items || [];
            state.changeStatusBooingNotificationList = _.orderBy(
                notificationList,
                'updatedAt',
                'desc',
            );
        });
        builder.addCase(fetchNotificationList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(createNotification.fulfilled, (state, action) => {
            state.showLoading = false;
        });
        builder.addCase(getTemporaryBookingItemList.fulfilled, (state, action) => {
            const bookingTmpResponse = action.payload?.data || [];
            const tmpBookingNotificationList = bookingTmpResponse.map((booking) => ({
                updatedAt: booking.createdAt,
                type: ReadNotificationType.TEMPORARY_BOOKING,
                rawData: {
                    endDate: booking.endDateOfStay,
                    startDate: booking.startDateOfStay,
                    id: booking.id,
                },
            })) as IBookingNotification[];
            state.temporaryBookingNotificationList = _.orderBy(
                tmpBookingNotificationList,
                'updatedAt',
                'desc',
            );
        });
        builder.addCase(createNotification.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(updateNotification.fulfilled, (state, action) => {
            state.showLoading = false;
        });
        builder.addCase(updateNotification.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(updateReadNotificationTime.fulfilled, (state, action) => {
            switch (action.payload?.type) {
                case ReadNotificationType.ADMIN_NOTIFICATIONS:
                    state.adminNotifyReadTimeAt = action.payload.readAt;
                    break;
                case ReadNotificationType.ROOM_BOOKING_CHANGE_STATUS:
                    state.changeStatusBookingNotifyReadTimeAt = action.payload.readAt;
                    break;
                case ReadNotificationType.ROOM_BOOKING:
                    state.changeStatusBookingNotifyReadTimeAt = action.payload.readAt;
                    break;
                case ReadNotificationType.TEMPORARY_BOOKING:
                    state.temporaryBookingNotifyReadTimeAt = action.payload.readAt;
                    break;
                default:
                    break;
            }
        });
        builder.addCase(getReadNotificationTime.fulfilled, (state, action) => {
            (action.payload?.data || []).forEach((item) => {
                switch (item.type) {
                    case ReadNotificationType.ADMIN_NOTIFICATIONS:
                        state.adminNotifyReadTimeAt = item.readAt;
                        break;
                    case ReadNotificationType.ROOM_BOOKING_CHANGE_STATUS:
                        state.changeStatusBookingNotifyReadTimeAt = item.readAt;
                        break;
                    case ReadNotificationType.ROOM_BOOKING:
                        state.changeStatusBookingNotifyReadTimeAt = item.readAt;
                        break;
                    case ReadNotificationType.TEMPORARY_BOOKING:
                        state.temporaryBookingNotifyReadTimeAt = item.readAt;
                        break;
                    default:
                        break;
                }
            });
        });
        builder.addCase(getNotificationDetail.fulfilled, (state, action) => {
            state.showLoading = false;
            state.selectedNotification = action.payload?.data || {};
        });
        builder.addCase(getNotificationDetail.pending, (state, action) => {
            state.showLoading = true;
        });
    },
});

export const {
    setNotificationListQuery,
    setNotificationList,
    setKeyword,
    setTotalNotification,
    setSelectedNotification,
    setChangeStatusBookingNotificationList,
    setAdminNotificationList,
    setTemporaryBookingNotificationList,
} = notificationSlice.actions;

export const notificationStateSelector = (state: AppState) => {
    return state.notification;
};

export const notificationListQuerySelector = (state: AppState) => {
    return state.notification.notificationListQuery;
};

export const notificationListSelector = (state: AppState) => {
    return state.notification.notificationList;
};

export const pmsNotificationListByHotelIdSelector = (state: AppState) => {
    return state.notification.changeStatusBooingNotificationList;
};

export const tllNotificationListByHotelIdSelector = (state: AppState) => {
    return state.notification.adminNotificationList;
};

export const selectedNotificationSelector = (state: AppState) => {
    return state.notification.selectedNotification;
};

export const showLoadingSelector = (state: AppState) => {
    return state.notification.showLoading;
};

export const totalNotificationsSelector = (state: AppState) => {
    return state.notification.totalNotifications;
};

export const totalPageSelector = (state: AppState) => {
    const { totalNotifications, notificationListQuery } = state.notification;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = notificationListQuery;
    return Math.ceil(totalNotifications / limit);
};

export default notificationSlice.reducer;
