import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { exportCSVFile, formatMoney } from '~common/commonFunctions';
import { ISalesPayment, ISalesPaymentTableData } from '~features/report/interfaces';
import {
    salesPaymentStateSelector,
    setExportCsv,
} from '~features/report/reducers/report-sales-payment.reducer';
import { ReceiptItemType } from '~features/room-booking/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import './SalesPaymentTable.scss';
import {
    ReportTableCsvFileName,
    ReportTableId,
    exportSalesPaymentReportColumns,
} from '~features/report/constants';
import {
    groupSalesPaymentList,
    getReceiptDetailSalesName,
} from '~features/report/helper';
import { getAutoGeneratedCode } from '~features/room-booking/helper';

function SalesPaymentTable() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { showLoading, salesPaymentList, isExportCsv } = useAppSelector(
        salesPaymentStateSelector,
    );

    const mapReceiptItemsDetailToTableRow = (
        salesPayment: ISalesPayment,
        index: number,
    ) => {
        const salesItems = salesPayment.receiptItemDetails?.filter(
            (item) => item.type !== ReceiptItemType.PAYMENT,
        );
        const totalSalesAmount = sumBy(salesItems, 'amount');

        const paymentItems = salesPayment.receiptItemDetails?.filter(
            (item) => item.type === ReceiptItemType.PAYMENT,
        );
        const totalPaymentAmount = sumBy(paymentItems, 'amount');
        let _items: ISalesPaymentTableData[] = [];
        if (paymentItems.length < salesItems.length) {
            _items = salesItems.map((receiptItem, index) => {
                const item: ISalesPaymentTableData = {
                    id: receiptItem.id,
                    roomName: salesPayment.roomName,
                    representativeGuest: salesPayment.representativeGuest,
                    rowSpan: !!index ? 0 : salesItems.length + 1,
                    sales: {
                        name: getReceiptDetailSalesName(receiptItem),
                        amount: receiptItem.amount,
                    },
                    autoGeneratedCode: salesPayment.autoGeneratedCode,
                    roomBookingId: salesPayment.roomBookingId,
                };
                if (paymentItems[index]) {
                    Object.assign(item, {
                        payment: {
                            name:
                                paymentItems[index].paymentMethod?.name ||
                                t('report.salesPayment.table.payment.name'),
                            amount: paymentItems[index]?.amount,
                        },
                    });
                }
                return item;
            });
        } else {
            _items = paymentItems.map((receiptItem, index) => {
                const item: ISalesPaymentTableData = {
                    id: receiptItem.id,
                    roomName: salesPayment.roomName,
                    representativeGuest: salesPayment.representativeGuest,
                    rowSpan: !!index ? 0 : paymentItems.length + 1,
                    payment: {
                        name:
                            receiptItem.paymentMethod?.name ||
                            t('report.salesPayment.table.payment.name'),
                        amount: receiptItem.amount,
                    },
                    autoGeneratedCode: salesPayment.autoGeneratedCode,
                    roomBookingId: salesPayment.roomBookingId,
                };
                if (salesItems[index]) {
                    Object.assign(item, {
                        sales: {
                            name: getReceiptDetailSalesName(salesItems[index]),
                            amount: salesItems[index]?.amount,
                        },
                    });
                }
                return item;
            });
        }
        if (_items.length) {
            _items.push({
                id: `summary_${index}`,
                rowSpan: 0,
                roomName: salesPayment.roomName,
                representativeGuest: salesPayment.representativeGuest,
                isSummary: true,
                sales: {
                    name: t('report.salesPayment.table.sales.totalBalanceAmout'),
                    amount:
                        salesPayment.balanceAmount > 0 ? salesPayment.balanceAmount : 0,
                },
                payment: {
                    name: t('report.salesPayment.table.payment.totalUpaidAmount'),
                    amount: salesPayment.unpaidAmount > 0 ? salesPayment.unpaidAmount : 0,
                },
                autoGeneratedCode: salesPayment.autoGeneratedCode,
                roomBookingId: salesPayment.roomBookingId,
            });
        }
        return { items: _items, totalSalesAmount, totalPaymentAmount };
    };

    const tableData = useMemo(() => {
        let _tableData: ISalesPaymentTableData[] = [];
        let totalSalesAmount = 0;
        let totalPaymentAmount = 0;
        let totalUnpaidAmount = 0;
        let totalBalanceAmount = 0;
        const groupedSalesPaymentList = groupSalesPaymentList(salesPaymentList);
        groupedSalesPaymentList.forEach((salesPayment, index) => {
            totalUnpaidAmount +=
                salesPayment.unpaidAmount > 0 ? salesPayment.unpaidAmount : 0;
            totalBalanceAmount +=
                salesPayment.balanceAmount > 0 ? salesPayment.balanceAmount : 0;

            const tableRowMap = mapReceiptItemsDetailToTableRow(salesPayment, index);
            totalSalesAmount += tableRowMap.totalSalesAmount;
            totalPaymentAmount += tableRowMap.totalPaymentAmount;
            _tableData = [..._tableData, ...tableRowMap.items];
        });
        if (groupedSalesPaymentList.length) {
            _tableData.push({
                id: 'totalAmount',
                rowSpan: 1,
                roomName: '',
                representativeGuest: null,
                isSummary: true,
                isTotalBillRow: true,
                sales: {
                    name: t('report.salesPayment.table.totalBalanceAmount'),
                    amount: totalBalanceAmount,
                },
                payment: {
                    name: t('report.salesPayment.table.totalAmount'),
                    amount: totalUnpaidAmount,
                },
                autoGeneratedCode: null,
                roomBookingId: null,
            });

            _tableData.push({
                id: 'summary',
                rowSpan: 1,
                roomName: '',
                representativeGuest: null,
                isSummary: true,
                isTotalBillRow: true,
                sales: {
                    name: t('report.salesPayment.table.totalSales'),
                    amount: totalSalesAmount,
                },
                payment: {
                    name: t('report.salesPayment.table.totalPayment'),
                    amount: totalPaymentAmount,
                },
                autoGeneratedCode: null,
                roomBookingId: null,
            });
        }
        return _tableData;
    }, [salesPaymentList, t]);

    const mapHeader = () => {
        const salesHeaderString = Array.from({ length: 4 }, () => '');
        salesHeaderString.unshift(t('report.salesPayment.table.sales.title'));
        const paymentHeaderString = [t('report.salesPayment.table.payment.title'), ''];
        return [...salesHeaderString, ...paymentHeaderString].join();
    };

    const mapExportCsvData = () => {
        const test = tableData.map((row) => {
            const concatRoomName = row.roomName?.split(', ');
            return {
                autoGeneratedCode: row.autoGeneratedCode,
                roomName: concatRoomName?.join('; '),
                yomigana: row.representativeGuest?.yomigana || '',
                saleName: row.sales ? row.sales?.name : '',
                saleAmount: row.sales
                    ? t('common.yen', {
                          number: row.sales?.amount || 0,
                      })
                    : '',
                paymentName: row.payment ? row.payment.name : '',
                paymentAmount: row.payment
                    ? t('common.yen', {
                          number: row.payment?.amount || 0,
                      })
                    : '',
            };
        });
        return test;
    };

    useEffect(() => {
        if (isExportCsv) {
            const moreHeader = mapHeader();
            exportCSVFile(
                exportSalesPaymentReportColumns,
                `${ReportTableCsvFileName.SALES_PAYMENT}.csv`,
                mapExportCsvData() || [],
                'report.salesPayment',
                { preHeader: moreHeader },
            );
            dispatch(setExportCsv(false));
        }
    }, [isExportCsv, dispatch]);

    const saleBookingColumns: ColumnsType<ISalesPaymentTableData> = [
        {
            title: t('report.salesPayment.table.sales.autoGeneratedCode'),
            onCell: (item, index) => {
                return {
                    rowSpan: item.rowSpan,
                };
            },
            render: (item) => {
                return (
                    <div
                        className={classNames('text-truncate-cell', {
                            'summary-cell': item.isSummary,
                            'border-right-none-cell': item.isTotalBillRow,
                        })}
                    >
                        {getAutoGeneratedCode(item.autoGeneratedCode)}
                    </div>
                );
            },
            width: '7%',
        },
        {
            title: t('report.salesPayment.table.sales.roomName'),
            onCell: (item, index) => {
                return {
                    rowSpan: item.rowSpan,
                };
            },
            render: (item) => {
                return (
                    <div
                        className={classNames('text-truncate-cell', {
                            'summary-cell': item.isSummary,
                            'border-right-none-cell': item.isTotalBillRow,
                        })}
                    >
                        {item.roomName}
                    </div>
                );
            },
            width: '13%',
        },
        {
            title: t('report.salesPayment.table.sales.guestName'),
            onCell: (item) => {
                return {
                    rowSpan: item.rowSpan,
                };
            },
            render: (item) => {
                return (
                    <div
                        className={classNames('text-truncate-cell', {
                            'summary-cell': item.isSummary,
                            'border-right-none-cell': item.isTotalBillRow,
                        })}
                    >
                        {item.representativeGuest?.yomigana}
                    </div>
                );
            },
            width: '13%',
        },
        {
            title: t('report.salesPayment.table.item'),
            render: (item) => {
                return (
                    <div
                        className={classNames('text-truncate-cell', {
                            'summary-cell': item.isSummary,
                        })}
                    >
                        {item.sales?.name}
                    </div>
                );
            },
            width: '16%',
        },
        {
            title: t('report.salesPayment.table.sales.amount'),
            render: (item) => {
                return (
                    <div
                        className={classNames('amount-cell', {
                            'summary-cell': item.isSummary,
                            'total-amount-bill-cell': item.isTotalBillRow,
                        })}
                    >
                        {item.sales?.amount || item.sales?.amount === 0
                            ? t('common.yen', {
                                  number: formatMoney(item.sales?.amount),
                              })
                            : ''}
                    </div>
                );
            },
            width: '16%',
        },
    ];
    const paymentBookingColumns: ColumnsType<ISalesPaymentTableData> = [
        {
            title: t('report.salesPayment.table.item'),
            render: (item) => {
                return (
                    <div
                        className={classNames('text-truncate-cell', {
                            'summary-cell': item.isSummary,
                        })}
                    >
                        {item.payment?.name || ''}
                    </div>
                );
            },
            width: '16%',
        },
        {
            title: t('report.salesPayment.table.payment.amount'),
            render: (item) => {
                return (
                    <div
                        className={classNames('amount-cell', {
                            'summary-cell': item.isSummary,
                            'total-amount-bill-cell': item.isTotalBillRow,
                        })}
                    >
                        {item.payment?.amount || item.payment?.amount === 0
                            ? t('common.yen', {
                                  number: formatMoney(item.payment?.amount),
                              })
                            : ''}
                    </div>
                );
            },
            width: '16%',
        },
    ];

    const salesPaymentColumns: ColumnsType<ISalesPaymentTableData> = [
        {
            title: () => {
                return (
                    <div className="sales-header-table">
                        {t('report.salesPayment.table.sales.title')}
                    </div>
                );
            },
            key: 'sale',
            children: saleBookingColumns,
        },
        {
            title: t('report.salesPayment.table.payment.title'),
            key: 'payment',
            children: paymentBookingColumns,
        },
    ];

    return (
        <div className="sales-payment-table-wrapper">
            <Table
                id={ReportTableId.SALES_PAYMENT}
                columns={salesPaymentColumns}
                dataSource={tableData}
                loading={showLoading}
                rowKey="id"
                pagination={false}
            />
        </div>
    );
}

export default SalesPaymentTable;
