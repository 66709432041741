import { IBookingReceiptTableData } from '~features/room-booking/interfaces';
import './BookingIdColumn.scss';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReceiptItemType } from '~features/room-booking/constants';

type Props = {
    item: IBookingReceiptTableData;
    displayBookingId: (item: IBookingReceiptTableData) => string;
    isCanEdit: (item: IBookingReceiptTableData) => boolean;
    addSaleItem: (item: IBookingReceiptTableData, type: ReceiptItemType) => void;
};

function BookingIdColumn(props: Props) {
    const { item, displayBookingId, isCanEdit, addSaleItem } = props;
    const { t } = useTranslation();

    if (!item.name) return null;

    return (
        <span className="booking-id-cell">
            <span>{displayBookingId(item)}</span>
            {item.name && isCanEdit(item) && (
                <span className="payment-button-group">
                    <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={(e) => {
                            e.stopPropagation();
                            addSaleItem(item, ReceiptItemType.PAYMENT);
                        }}
                    >
                        <PlusOutlined />
                        {t('roomBooking.detail.receipt.button.payment')}
                    </Button>
                    <Button
                        size="small"
                        danger
                        onClick={(e) => {
                            e.stopPropagation();
                            addSaleItem(item, ReceiptItemType.RECEIPT);
                        }}
                    >
                        <PlusOutlined />
                        {t('roomBooking.detail.receipt.button.earnings')}
                    </Button>
                </span>
            )}
        </span>
    );
}

export default BookingIdColumn;
