import { Badge, Modal } from 'antd';
import { parseDate } from '~plugins/dayjs';
import { useNavigate } from 'react-router-dom';
import { FacilityBookingStatusColor } from '~features/facility-booking/constants';
import {
    bookingFilterSelector,
    facilityBookingScheduleListSelector,
    setIsShowFacilityBookingScheduleListModal,
    showFacilityBookingScheduleListModalSelector,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { showBookingDetailModal } from '~features/facility-booking/util';
import { useAppDispatch, useAppSelector } from '~hooks';
import './FacilityBookingScheduleListModal.scss';
import { useMemo } from 'react';
import { IFacilityBookingScheduleItem } from '~features/facility-booking/interfaces';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';

type Prop = {
    onChangeStatusSuccess?: (booking: IFacilityBookingScheduleItem) => void;
    isCanEdit?: boolean;
};

export const FacilityBookingScheduleListModal = ({
    onChangeStatusSuccess,
    isCanEdit,
}: Prop) => {
    const dispatch = useAppDispatch();
    const isShowModal = useAppSelector(showFacilityBookingScheduleListModalSelector);
    const facilityBookingSchedule = useAppSelector(facilityBookingScheduleListSelector);
    const bookingList = useAppSelector(bookingFilterSelector);
    const isReadOnly = useMemo(() => {
        return !checkUserPermission([
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ]);
    }, []);
    const bookings = useMemo(() => {
        const { facilityKey, date } = facilityBookingSchedule;
        if (!facilityKey) return [];
        return bookingList[date]?.[facilityKey] || [];
    }, [facilityBookingSchedule, bookingList]);

    const onClose = () => {
        dispatch(setIsShowFacilityBookingScheduleListModal(false));
    };
    const navigate = useNavigate();
    const modalTitle = (
        <div>
            {parseDate(facilityBookingSchedule?.date || '')?.fmYYYYMMDDJp()}
            <span className="facility-name">{facilityBookingSchedule.parentName}</span>
        </div>
    );
    return (
        <Modal
            title={modalTitle}
            centered
            open={isShowModal}
            className="facility-booking-schedule-list-modal"
            onCancel={onClose}
            key="facility-booking-list-modal"
            footer={null}
            width={260}
            bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '4px',
                overflow: 'hidden auto',
                maxHeight: '300px',
                padding: '24px 20px',
            }}
        >
            {bookings?.map((booking, index) => (
                <div
                    className="facility-booking-schedule-item"
                    key={`${booking.id}_${index}`}
                    onClick={(e) => {
                        const element = e.currentTarget.children?.[0];
                        showBookingDetailModal({
                            booking,
                            element: element as HTMLElement,
                            navigate: navigate,
                            onChangeStatusSuccess: onChangeStatusSuccess,
                            isReadOnly,
                            isShowEditBtn: isCanEdit,
                            position: { pageX: e.pageX, pageY: e.pageY },
                        });
                    }}
                >
                    <div></div>
                    <div className="text-truncate">
                        {booking.checkInTime} - {booking.checkOutTime}
                        <Badge color={FacilityBookingStatusColor[booking.status]} />
                        <span className="guest-name">{booking.guest?.yomigana}</span>
                    </div>
                </div>
            ))}
        </Modal>
    );
};
