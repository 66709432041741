const stoppingRoomCountForm = {
    title: 'Stopping inventory initial setting',
    alert: {
        title: 'Set initial number of stopped rooms',
        button: 'Set',
        description:
            'Set the intial number of stopped rooms in each room type.  To edit room status on a daily basis, go to management screen.',
    },
    tableColumn: {
        roomTypeName: 'Room type name',
        totalRooms: 'Number of rooms',
        stoppingRoomCount: 'Initial value',
    },
    cancelButton: 'Cancel',
    submitButton: 'Save',
    saveStoppingRoomCountSuccess: 'Update success',
    cancelTitle: 'Do you want to cancel ?',
};

export default stoppingRoomCountForm;
