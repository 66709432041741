export const roomManagementEn = {
    title: 'Room management',
    roomIndicator: 'Room indicator',
    cleaningList: 'Cleaning list',
    selectIndicator: 'Select page',
    showBookingList: 'Unassigned list',
    expandBookingList: 'Display full screen',
    roomStatus: {
        all: 'All',
        stay: 'Checked in',
        vacancy: 'Vacant',
        stop_selling: 'Stopped',
        finished: 'Finished',
        checked_in: 'Checked in',
        not_arrived: 'Reserved',
        checked_out: 'Checked out',
    },
    facilityStatus: {
        all: 'All',
        stay: 'Checked out',
        vacancy: 'Vacant',
        reserved: 'Reserved',
        started: 'Started',
        finished: 'Finished',
    },
    plan: 'Plan',
    planPlaceholder: 'Select plan',
    dayUse: 'Day-use',
    checked: 'ON',
    unChecked: 'OFF',
    cleaned: 'Cleaned',
    notCleaned: 'Uncleaned',
    stopSelling: 'Stopped',
    reserve: 'Reserved',
    statusCollapse: {
        title: 'Status',
        checkIn: 'Check in',
        checkOut: 'Check out',
        notAssigned: 'Unassigned',
        roomOccupancyRate: 'Occupancy rate',
    },
    unassignList: 'Unassigned list',
    closeAll: 'Collapse all',
    expandAll: 'Expand all',
    openAll: 'Expand all',
    unassignCount: 'Unassigned count',
    unassignListSearchNotFound: 'Search result not found.',
    bookingId: 'Booking ID {{id}}',
    adultCount: 'Adults {{count}}',
    childrenCount: 'Children {{count}}',
    assignment: 'Assign',
    autoAssignment: 'Auto-assign',
    drag: 'Drag & drop to the desired room',
    cancel: 'Cancel',
    autoAssign: {
        title: 'Auto-assign',
        desc: 'Please review the assignment list and if there are no issues, proceed with the assignment.',
    },
    columns: {
        period: 'Duration',
        guests: 'Guest',
        roomType: 'Room type',
        room: 'Room',
        remarks: 'Remark',
    },
    drawer: {
        title: 'Room stop selling setting',
        desc: 'Room status will be changed. Please enter the new status, duration, and reason',
        statusLabel: 'Status',
        dateLabel: 'Duration of the change',
        causeLabel: 'Reason',
        remarkLabel: 'Remark',
        remarkPlaceholder: 'Enter remark',
    },
    causeOptions: {
        INVENTORY_ADJUSTMENT: 'Inventory adjustment',
        ROOM_ISSUES: 'Room issue',
        OTHERS: 'Other',
    },
    detail: {
        vacancyTitle: 'Vacancy - {{name}}',
    },
    empty: 'N/A',
    cancelReserve: 'Cancel booking',
    checkIn: 'Check in',
    unassignButton: 'Unassign',
    unassignDialog: {
        title: 'Unassigning room',
        content: 'Do you want to unassigned selected rooms?',
        cancelText: 'Cancel',
        okText: 'Unassign',
    },
    clearStatusButton: 'Delete',
    editButton: 'Edit',
    clearStatusDialog: {
        title: 'Room status will be changed to vacant',
        content: 'Do you want to change the room status to vacant?',
        cancelText: 'Cancel',
        okText: 'Proceed',
    },
    seeMoreButton: 'View more',
    stopSellingStatus: {
        vacant: 'Vacant',
        unavailable: 'Stopped',
    },
    update: {
        stopSelling: 'Room have been updated to stop selling!',
        cleaningStatus: 'Room cleaning status have been updated successful!',
        continueSelling: 'Room have been updated to continue selling!',
    },
    currentDate: 'Today',
    assignRoomSuccess: 'Room has been unassigned',
    bookingUnassigned: {
        more: 'View more',
        less: 'View less',
    }
};
