import { parseDate } from '~plugins/dayjs';
import { IBodyResponse } from '~common/interfaces';
import { IndicatorType } from '~features/indicator/constants';
import axiosService from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import {
    IChangeSellingStatusRoom,
    IDetailRoomIndicatorManagement,
    IRoomManagementBookingOverview,
    IRoomManagementBookingOverviewQuery,
    IRoomStatus,
    IUnassignedBookingItem,
    IUpdateRoomCleaningStatus,
} from '../interfaces';
import { IBookingsResponse } from '~features/facility-booking/interfaces';

class RoomManagementService extends ApiService {
    get indicatorUrl() {
        return `${this.baseUrl}/indicator`;
    }
    get roomBookingUrl() {
        return `${this.baseUrl}/room-booking`;
    }
    get roomUrl() {
        return `${this.baseUrl}/room`;
    }
    get stoppingInventoryUrl() {
        return `${this.baseUrl}/stopping-inventory`;
    }
    get facilityUrl() {
        return `${this.baseUrl}/facility-booking`;
    }
    get roomManagementUrl() {
        return `${this.baseUrl}/room/room-management`;
    }

    getRoomListStatus(roomIds: number[], date: string) {
        return this._get(`${this.roomUrl}/status`, {
            params: {
                roomIds,
                startDate: parseDate(date).startOf('day').fmYYYYMMDDHHmmss(),
                endDate: parseDate(date).endOf('day').fmYYYYMMDDHHmmss(),
            },
        }) as Promise<IBodyResponse<{ items: IRoomStatus[] }>>;
    }

    getIndicatorDropdown(type: IndicatorType) {
        return this._get(`${this.indicatorUrl}/dropdown`) as Promise<
            IBodyResponse<{ items: IDetailRoomIndicatorManagement[] }>
        >;
    }

    assignBookingToRoom(data: { id: number; roomId: number }[]) {
        return this._patch(`${this.roomBookingUrl}/item-and-temporary/bulk-assign-room`, {
            items: data,
        }) as Promise<IBodyResponse<any>>;
    }

    unassignBooking(ids: number[]) {
        return this._patch(
            `${this.roomBookingUrl}/item-and-temporary/bulk-unassign-room`,
            { ids },
        ) as Promise<IBodyResponse<any>>;
    }

    changeSellingStatusRoom(roomId: number, body: IChangeSellingStatusRoom) {
        return this._post(
            `${this.stoppingInventoryUrl}/room/${roomId}/change-selling-status`,
            body,
        ) as Promise<IBodyResponse<IBodyResponse<null>>>;
    }

    updateCleaningStatus(body: IUpdateRoomCleaningStatus) {
        return this._patch(`${this.roomUrl}/cleaning-status`, {
            items: [body],
        }) as Promise<IBodyResponse<IBodyResponse<IUpdateRoomCleaningStatus>>>;
    }

    getBookingUnassignedList({
        startDate,
        endDate,
        isFromBookingSchedule,
    }: {
        startDate: string;
        endDate: string;
        isFromBookingSchedule?: boolean;
    }) {
        const datePeriod: string[] | undefined = !isFromBookingSchedule
            ? [startDate, endDate]
            : undefined;
        return this._get(`${this.roomBookingUrl}/item-and-temporary/unassigned-room`, {
            params: {
                datePeriod,
            },
        }) as Promise<IBodyResponse<{ items: IUnassignedBookingItem[] }>>;
    }

    getFacilityBookings(date: string, ids: number[]) {
        return this._get(`${this.facilityUrl}/schedule`, {
            params: {
                startDate: parseDate(date)?.startOf('day')?.fmYYYYMMDDHHmmss(),
                endDate: parseDate(date)?.endOf('day')?.fmYYYYMMDDHHmmss(),
                facilityIds: ids,
            },
        }) as Promise<IBodyResponse<IBookingsResponse[]>>;
    }

    getRoomManagementBookingOverview(params: IRoomManagementBookingOverviewQuery) {
        return this._get(`${this.roomManagementUrl}/booking-overview`, {
            params,
        }) as Promise<IBodyResponse<IRoomManagementBookingOverview>>;
    }
}
export const roomManagementService = new RoomManagementService(
    { baseUrl: '' },
    axiosService,
);
