export const printingJa = {
    registrationCard: '宿泊カード',
    checkIn: 'ご到着日 (Check in)',
    checkOut: 'ご出発日 (Check out)',
    yomigana: 'フリガナ',
    name: 'お名前 (Name)',
    dateOfBirth: '生年月日 (Date of birth)',
    gender: '性別 (Gender)',
    guestGender: {
        male: '男',
        female: '女性',
        other: '他',
    },
    homeAddress: 'ご住所 (Home address)',
    contactNumber: '電話番号 (Contact number)',
    emailAddress: 'メールアドレス (Email address)',
    occupation: 'ご職業 (Occupation)',
    companyName: '会社名 (Company name)',
    companyPhoneNumber: '会社電話番号{{break}}(Company phone number)',
    companyEmailAddress: '会社のメールアドレス{{break}}(Company email address)',
    passportNo: 'パスポート番号 (Passport number)',
    nationality: '国籍 (Nationality)',
    previousPlaceOfStay: '前泊地 (Previous place to stay)',
    nextPlaceOfStay: '後泊地 (Next place to stay)',
    memo: '備考 (Memo)',
    roomNumber: '部屋番号 (Room number)',
    signature: 'ご署名 (Signature)',
    notification: {
        noSuchRoomBooking: '当日の予約はございません',
        biggerGuestCards: '予約数が {{totalBookings}} 最大予約数を超えています',
        noPrintGuestCard: '前予約項目が削除されました',
    },
    preparationList: {
        headerTitle: '手配リスト ',
        date: '{{year}}年{{month}}月{{day}}日 ({{dayOfWeek}})',
        day: {
            monday: '月',
            tuesday: '火',
            wednesday: '水',
            thursday: '木',
            friday: '金',
            saturday: '土',
            sunday: '日',
        },
        order: 'No',
    },
    cancelTitle: 'キャンセルしてもよろしいですか？',
};
