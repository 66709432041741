const saleGroupFormImport = {
    quantity: '数量',
    id: '#',
    roomName: '部屋名',
    roomType: '部屋タイプ',
    startRoomName: '開始番号',
    delete: '削除',
    generateRoomsBtn: '適応する',
    deleteBtn: 'ルーム名',
    cancelBtn: 'キャンセル',
    submitBtn: '保存',
    addRoomBtn: '行を追加',
    importBtn: 'アップロード',
    createSuccessMessage: 'セールグループが登録されました',
    updateSuccessMessage: 'セールグループが更新されました',
    importSuccessMessage: 'CSVファイルのアップロードに成功しました!',
    updateForm: {
        title: '部屋の編集',
    },
    saleGroupNameDuplicated: 'セールグループ名が重複しています',
    cancelTitle: 'キャンセルしてもよろしいですか？',
    placeholder: {
        roomType: '部屋タイプを選択',
    },
    error: {
        name: {
            uniqueError: '集計グループは重複してはなりません',
            required: '集計グループを入力してください',
            maxLength: '集計グループの最大文字数は{{max}}字です',
            hasNoSpecialCharacters: '集計グループは特殊文字を含めません',
        },
        saleItems: {
            required: '集計科目を入力してください',
            maxLength: '集計科目の最大文字数は{{max}}字です',
            duplicate: 'セール項目名がグループ内で重複しています',
            hasNoSpecialCharacters: '集計科目は特殊文字を含めません',
        },
        key: {
            name: '集計グループ',
            saleItems: '集計科目',
        },
    },
    showMore: 'あと {{errorNumber}} 増やす...',
    showLess: '表示数を少なくする',
};

export default saleGroupFormImport;
