import inventoryListJa from './ja/inventory-list.locale';
import stopSellingJa from './ja/stop-selling.locale';
import stoppingRoomCountFormJa from './ja/stopping-room-count-form.locale';

import inventoryListEn from './en/inventory-list.locale';
import stopSellingEn from './en/stop-selling.locale';
import stoppingRoomCountFormEn from './en/stopping-room-count-form.locale';

const inventory = {
    ja: {
        list: inventoryListJa,
        stopSelling: stopSellingJa,
        stoppingRoomCountForm: stoppingRoomCountFormJa,
    },
    en: {
        list: inventoryListEn,
        stopSelling: stopSellingEn,
        stoppingRoomCountForm: stoppingRoomCountFormEn,
    },
};

export default inventory;
