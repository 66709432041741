import { IRoomTypeDropdown } from '~features/room-type/interfaces';

export const convertRoomTypeOptions = (roomTypeOptions: IRoomTypeDropdown[]) => {
    return roomTypeOptions.map((roomType) => {
        return {
            value: roomType.id,
            label: roomType.name,
        };
    });
};

export function getIndividualRoomsFormId(name: string) {
    return `form-individual-rooms-${name}`;
}
