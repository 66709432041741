import { INPUT_TEXT_MAX_LENGTH, Regex } from '~common/constants';
import yup from '~plugins/yup';

export const bulkCreateMarketingChannelGroupsSchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                name: yup
                    .string()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .hasNoSpecialCharacters(Regex.NAME_SPECIAL_CHARACTERS)
                    .required(),
            })
            .required(),
    ),
});

export const bulkUpdateMaketingChannelGroupsSchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                name: yup
                    .string()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .hasNoSpecialCharacters(Regex.NAME_SPECIAL_CHARACTERS)
                    .required(),
            })
            .required(),
    ),
});
