import { MIN_PRICE } from './../../common/constants';
import {
    MEMO_MAX_LENGTH,
    INPUT_TEXT_MAX_LENGTH,
    MAX_PRICE,
    Regex,
} from '~common/constants';
import yup from '~plugins/yup';
import { MAX_QUANTITY } from './constants';

export const createPlanStep1Schema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .hasNoSpecialCharacters(Regex.PLAN_NAME_SPECIAL_CHARACTERS)
        .max(INPUT_TEXT_MAX_LENGTH)
        .required(),
    memo: yup.string().max(MEMO_MAX_LENGTH).nullable().optional(),
    taxId: yup.number().required(),
    planType: yup.string().optional(),
    checkInOutTimes: yup.array().of(yup.string().required()).required(),
    cleaningFrequency: yup.number().optional(),
    planSaleItems: yup
        .array()
        .optional()
        .nullable()
        .of(
            yup.object().shape({
                saleItemId: yup.number().required(),
                price: yup
                    .string()
                    .minPriceFullAndHalfWidth(MIN_PRICE)
                    .maxPriceFullAndHalfWidth(MAX_PRICE)
                    .required(),
                quantity: yup.mixed().when(`isForOnePeople`, {
                    is: true,
                    then: yup.mixed().oneOf([null]),
                    otherwise: yup
                        .string()
                        .minFullAndHalfWidth(1)
                        .maxFullAndHalfWidth(MAX_QUANTITY)
                        .required(),
                }),
                isForOnePeople: yup.boolean(),
            }),
        ),
});

export const createPlanStep2Schema = yup.object().shape({
    planRankId: yup.number().required(),
    roomTypeIds: yup.array().of(yup.number().required()).required().min(1),
    planRoomTypes: yup.array().of(
        yup.object().shape({
            roomTypeId: yup.number().nullable(true).optional(),
            peopleCount: yup.number().nullable(true).optional(),
            planGroupCode: yup
                .string()
                .trim()
                .hasNoSpecialCharacters(Regex.GENERAL_SPECIAL_CHARACTERS)
                .max(INPUT_TEXT_MAX_LENGTH)
                .required(),
            planRoomTypePricings: yup.array().of(
                yup
                    .object()
                    .nullable(true)
                    .shape({
                        price: yup
                            .string()
                            .transform((value) => (isNaN(value) ? null : value))
                            .minPriceFullAndHalfWidth(MIN_PRICE)
                            .maxPriceFullAndHalfWidth(MAX_PRICE)
                            .nullable()
                            .optional(),
                    }),
            ),
        }),
    ),
});
