const guestClassificationForm = {
    cancelBtnText: 'キャンセル',
    submitBtnText: '登録する',
    editBtnText: '編集する',
    category: 'ゲスト識別名',
    categoryInput: '上客',
    edit: {
        title: 'ゲスト識別',
    },
    guestCategory: '名前',
    guestCategoryInput: '例：デフォルト',
    color: '色',
    create: {
        title: 'ゲスト識別の登録',
        success: 'ゲスト識別が登録されました',
        error: 'ゲスト識別が登録されませんでした',
    },

    update: {
        title: 'ゲスト識別の編集',
        success: 'ゲスト識別が更新されました',
        error: 'ゲスト識別が更新されませんでした',
    },
};
export default guestClassificationForm;
