import { createSlice } from '@reduxjs/toolkit';
import { IReportSalesByCategoryState } from '~features/report/interfaces';
import { AppState } from '~plugins/redux-toolkit/store';

const initialState: IReportSalesByCategoryState = {
    salesByCategoryList: [],
    showLoadingSalesByCategoryTable: false,
    showSalesByCategoryTable: false,
    filterRoomTypeId: null,
    filterDate: '',
    isShowByDay: false,
    isExportCsv: false,
};

const salesByCategorySlice = createSlice({
    name: 'sales-by-category',
    initialState,
    reducers: {
        setShowSalesByCategoryTable: (state, action) => {
            state.showSalesByCategoryTable = action.payload;
        },
        setFilterRoomTypeId: (state, action) => {
            state.filterRoomTypeId = action.payload;
        },
        setFilterDate: (state, action) => {
            state.filterDate = action.payload;
        },
        setExportCsv: (state, action) => {
            state.isExportCsv = action.payload;
        },
        setIsShowByDay: (state, action) => {
            state.isShowByDay = action.payload;
        },
    },
});

export const {
    setShowSalesByCategoryTable,
    setFilterRoomTypeId,
    setFilterDate,
    setIsShowByDay,
    setExportCsv,
} = salesByCategorySlice.actions;

export const salesByCategorySelector = (state: AppState) => {
    return state.reportSalesByCategory;
};

export default salesByCategorySlice.reducer;
