import { Card, Tabs } from 'antd';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormTab } from '~features/room-booking/constants';
import { numberOfGuestsSelector } from '~features/room-booking/reducers/create-booking.reducer';
import { useAppSelector } from '~hooks';
import GuestInfoTabPanel from '../GuestInfoTabPanel/GuestInfoTabPanel';
import { MembersCard } from '../MembersCard/MembersCard';
import MemoAndFileTabs from './MemoAndFileTabs/MemoAndFileTabs';
import './GeneralBookingCard.scss';
import { IMarketingChannel } from '~features/marketing-channel/interfaces';

type Props = {
    control: any;
    setValue: any;
    clearErrors: any;
    getValues: any;
    formTab: FormTab;
    disabled?: boolean;
    marketingFromTll?: string;
    additionalMarketingChannel?: IMarketingChannel;
    isFrozen?: boolean;
    isCreateForm?: boolean;
    onChangeFormTab: (key: string) => void;
};

function GeneralBookingCard(props: Props) {
    const {
        control,
        setValue,
        clearErrors,
        getValues,
        formTab,
        disabled,
        marketingFromTll,
        additionalMarketingChannel,
        isFrozen,
        isCreateForm,
        onChangeFormTab,
    } = props;
    const numberOfGuests = useAppSelector(numberOfGuestsSelector);

    const members = useWatch({
        control,
        name: 'members',
    });

    const isSingleMember = useMemo(() => {
        return numberOfGuests <= 1;
    }, [numberOfGuests]);

    return (
        <Card className="general-booking-card">
            <Tabs
                className="booking-form-tab"
                activeKey={formTab}
                onChange={onChangeFormTab}
                items={[
                    {
                        key: FormTab.BASIC_INFO,
                        label: '',
                        children: (
                            <>
                                <GuestInfoTabPanel
                                    control={control}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                    getValues={getValues}
                                    disabled={disabled}
                                    marketingFromTll={marketingFromTll}
                                    additionalMarketingChannel={
                                        additionalMarketingChannel
                                    }
                                />
                                <MemoAndFileTabs
                                    control={control}
                                    isFrozen={isFrozen}
                                    isCreateForm={isCreateForm}
                                />
                            </>
                        ),
                    },
                ].concat(
                    !isSingleMember || !!members?.length
                        ? [
                              {
                                  key: FormTab.COMPANION_INFO,
                                  label: '',
                                  children: (
                                      <MembersCard
                                          control={control}
                                          setValue={setValue}
                                          isFrozen={isFrozen}
                                      />
                                  ),
                              },
                          ]
                        : [],
                )}
            />
        </Card>
    );
}

export default GeneralBookingCard;
