import { AppState } from '~plugins/redux-toolkit/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    IAdvanceSearchGetListQuery,
    IPrintBookingState,
    IRoomBookingGetListQuery,
} from '../interfaces';
import { roomBookingListQueryDefault } from '../constants';
import { roomBookingService } from '../services/room-booking.service';
import { guestService } from '~features/guest/services/guest.service';

const initialState: IPrintBookingState = {
    printRoomBookingList: [],
    printRoomBookingListQuery: {
        ...roomBookingListQueryDefault,
    },
    isPrintRoomBookingListLoading: false,
    isShowRoomBookingPrintingModal: false,
    countryList: [],
    isShowPreparationListPrintingModal: false,
    printPreparationListData: [],
    pdfFileName: '',
};

export const fetchPrintRoomBookingList = createAsyncThunk(
    'print-room-booking',
    async (query: IRoomBookingGetListQuery | IAdvanceSearchGetListQuery) => {
        return await roomBookingService.getList(query);
    },
);

export const getCountryList = createAsyncThunk(
    'print-room-booking/getCountryList',
    async () => {
        return await guestService.getCountryList();
    },
);

export const printBookingSlice = createSlice({
    name: 'print-room-booking',
    initialState,
    reducers: {
        setPrintingRoomBookingList: (state, action) => {
            state.printRoomBookingList = action.payload;
        },
        setPrintRoomBookingListQuery: (state, action) => {
            state.printRoomBookingListQuery = action.payload;
        },
        setIsShowRoomBookingPrintingModal: (state, action) => {
            state.isShowRoomBookingPrintingModal = action.payload;
        },
        setIsShowPreparationListPrintingModal: (state, action) => {
            state.isShowPreparationListPrintingModal = action.payload;
        },
        setPrintPreparationListData: (state, action) => {
            state.printPreparationListData = action.payload;
        },
        setPdfFileName: (state, action) => {
            state.pdfFileName = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPrintRoomBookingList.pending, (state, action) => {
            state.isPrintRoomBookingListLoading = true;
        });
        builder.addCase(fetchPrintRoomBookingList.fulfilled, (state, action) => {
            state.isPrintRoomBookingListLoading = false;
            state.printRoomBookingList = action.payload?.data?.items || [];
        });
        builder.addCase(getCountryList.fulfilled, (state, action) => {
            state.countryList = action.payload?.data?.items || [];
        });
    },
});

export const {
    setPrintingRoomBookingList,
    setPrintRoomBookingListQuery,
    setIsShowRoomBookingPrintingModal,
    setIsShowPreparationListPrintingModal,
    setPrintPreparationListData,
    setPdfFileName,
} = printBookingSlice.actions;

export const printRoomBookingListSelector = (state: AppState) => {
    return state.printBooking.printRoomBookingList;
};

export const printRoomBookingListQuerySelector = (state: AppState) => {
    return state.printBooking.printRoomBookingListQuery;
};

export const isPrintRoomBookingListLoadingSelector = (state: AppState) => {
    return state.printBooking.isPrintRoomBookingListLoading;
};

export const isShowRoomBookingPrintingModalSelector = (state: AppState) => {
    return state.printBooking.isShowRoomBookingPrintingModal;
};

export const countryListSelector = (state: AppState) => {
    return state.printBooking.countryList;
};

export const isShowPreparationListPrintingModalSelector = (state: AppState) => {
    return state.printBooking.isShowPreparationListPrintingModal;
};

export const printPreparationListDataSelector = (state: AppState) => {
    return state.printBooking.printPreparationListData;
};

export const pdfFileNameSelector = (state: AppState) => {
    return state.printBooking.pdfFileName;
};

export default printBookingSlice.reducer;
