import { ThemeConfig } from 'antd/lib';
import { theme as themeAntd } from 'antd';
const { defaultAlgorithm } = themeAntd;

// const colorPrimary = '#1C3E86';
const colorTextBase = '#0B1936';

const base: ThemeConfig = {
    cssVar: true,
    hashed: false,
    algorithm: (seedToken) =>
        defaultAlgorithm({
            ...seedToken,
            colorPrimary: '#1C3E86',
            colorSuccess: '#3A9554',
            colorInfo: '#1C3E86',
            colorWarning: '#ECB31D',
            colorError: '#C53C3C',
            colorLink: '#004AE8',
            colorTextBase: '#0B1936',
            colorBgBase: '#fff',
            fontSize: 14,
            borderRadius: 4,
            fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontFamilyCode:
                'SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace',
        }),
    token: {
        colorPrimaryHover: '#004AE8',
        colorTextSecondary: '#3C4966',
        colorTextDisabled: '#d8d9d9',
        colorIcon: 'inherit',
        controlItemBgActiveHover: 'rgba(0, 74, 232, .2)',
        controlItemBgActive: 'rgba(0, 74, 232, .1)',
        controlOutline: 'rgba(0, 74, 232, .2)',
    },
    components: {
        Button: {
            colorTextDisabled: '#B1B6C2',
            colorBgContainerDisabled: '#ECEDF0',
            borderColorDisabled: '#ECEDF0',
            primaryShadow: '0 2px 0 rgba(0, 0, 0, 0.015)',
            onlyIconSize: 18,
            colorBorder: '#1C3E86',
        },
        Collapse: {
            headerBg: '#fafafa',
        },
        Descriptions: {
            labelBg: '#fafafa',
            titleMarginBottom: 20,
            itemPaddingBottom: 16,
            colonMarginRight: 8,
            colonMarginLeft: 2,
            extraColor: colorTextBase,
        },
        Divider: {
            textPaddingInline: '1em',
            orientationMargin: 0.05,
            verticalMarginInline: '8px',
        },
        Layout: {
            bodyBg: '#f6f6f6',
            headerBg: '#fff',
            headerHeight: 'auto',
            headerPadding: '20px 24px',
            footerPadding: '24px',
            footerBg: '#fff',
            siderBg: '#fff',
            triggerHeight: 48,
            triggerBg: '#002140',
            triggerColor: '#fff',
            zeroTriggerWidth: 36,
            zeroTriggerHeight: 42,
            lightSiderBg: '#fff',
            lightTriggerBg: '#fff',
            margin: 0,
            headerColor: colorTextBase,
            lightTriggerColor: colorTextBase,
        },
        Menu: {
            itemHoverBg: 'rgba(0, 74, 232, .1)',
            itemActiveBg: 'rgba(0, 74, 232, .2)',
        },
        Popover: {
            zIndexBase: 1000,
        },
        Tag: {
            defaultBg: '#e8ecf3',
        },
        Table: {
            headerBg: '#E8ECF3',
            colorIcon: '#B1B6C2',
        },
        Radio: {
            dotSize: 8,
        },
    },
};

export const theme: ThemeConfig = base;
