import { DeleteOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Card, Popover, notification } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { formatMoney } from '~common/commonFunctions';
import { useEscape } from '~common/useHooks';
import { ModalConfirmDeletion, TextTruncate } from '~components';
import { IFacilityBookingScheduleItem } from '~features/facility-booking/interfaces';
import {
    getFacilityBookingDetail,
    showLoadingSelector,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import {
    facilityBookingStateSelector,
    removeBooking,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { facilityBookingService } from '~features/facility-booking/services/facility-booking.service';
import { showUpdateBookingFormModal } from '~features/facility-booking/util';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import './BookingDetail.scss';
import { StatusDropdown } from './StatusDropdown';

type Props = {
    onClose?: () => void;
    isOpen: boolean;
    booking: IFacilityBookingScheduleItem;
    element?: HTMLElement;
    navigate?: NavigateFunction;
    onChangeStatusSuccess?: (booking: IFacilityBookingScheduleItem) => void;
    isReadOnly?: boolean;
    isShowEditBtn?: boolean;
    position?: { pageX: number; pageY: number };
};

export const BookingDetail = ({
    onClose,
    booking,
    isOpen,
    navigate,
    isReadOnly,
    isShowEditBtn,
    onChangeStatusSuccess,
    element,
    position,
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isOpenPopover, setOpenPopover] = useState(true);

    const date = useMemo(() => {
        const start = parseDate(booking.date)?.fmYYYYMMDD('/');
        const end = parseDate(booking.checkOutDateTime)?.fmYYYYMMDD('/');
        if (start === end) {
            return `${parseDate(booking.date)?.fmYYYYMMDD('/')} ${
                booking.checkInTime
            } - ${booking.checkOutTime}`;
        }
        return `${start} ${booking.checkInTime} - ${end} ${booking.checkOutTime}`;
    }, [booking]);
    const { facilityList } = useAppSelector(facilityBookingStateSelector);
    const isLoading = useAppSelector(showLoadingSelector);
    useEscape(() => onClose && onClose());

    const showConfirmDeleteBooking = () => {
        ModalConfirmDeletion({
            title: t('facilityBooking.schedule.modalConfirmDeletion.title'),
            description: t('facilityBooking.schedule.modalConfirmDeletion.description'),
            buttonCancelText: t(
                'facilityBooking.schedule.modalConfirmDeletion.cancelBtn',
            ),
            buttonDeleteText: t(
                'facilityBooking.schedule.modalConfirmDeletion.deleteBtn',
            ),
            deletedItems: [],
            okButtonProps: { danger: true },
            onClickButtonDelete: onConfirmDeletion,
        });
        if (onClose) {
            onClose();
        }
    };

    const onConfirmDeletion = async () => {
        const response = await facilityBookingService.bulkDelete([booking.id]);
        if (response.success) {
            notification.success({
                message: t('facilityBooking.list.message.deleteRoomTypesSuccess'),
            });
            dispatch(removeBooking(booking.id));
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const facilityType = useMemo(() => {
        const facility = facilityList.find(
            (item) => item.id === booking.facilityId && item.parentId,
        );
        return {
            id: facility?.parentId,
            name: facility?.parentName,
        };
    }, [booking, facilityList]);

    const gotoDetailPage = () => {
        onClose && onClose();
        window.open(`/facility-booking/${booking.id}/detail`);
    };

    const openEditForm = async () => {
        const response = await dispatch(getFacilityBookingDetail(booking.id));
        if (getFacilityBookingDetail.fulfilled.match(response)) {
            if (response.payload?.success) {
                const bookingDetail = response.payload?.data;
                const el = document.createElement('div');
                el.style.left = (position?.pageX || 0) + 'px';
                el.style.top = (position?.pageY || 0) + 'px';
                el.style.position = 'absolute';
                document.body.appendChild(el);
                showUpdateBookingFormModal({
                    booking: bookingDetail,
                    element: el,
                    onClose: () => {
                        onClose?.();
                        el.remove();
                    },
                });
                setOpenPopover(false);
            }
        }
    };

    const renderContent = () => {
        return (
            <Card className="facility-detail-booking-card">
                <div className="detail-booking-card-header">
                    <div className="header-left">
                        <TextTruncate text={booking.facility?.name} />
                    </div>
                    {!isReadOnly && (
                        <div className="header-right">
                            <Button type="text" onClick={showConfirmDeleteBooking}>
                                <DeleteOutlined className="remove-icon" />
                                {t('facilityBooking.schedule.remove')}
                            </Button>
                        </div>
                    )}
                </div>
                <div className="detail-booking-card-content">
                    <div className="detail-booking-card-row">
                        <TextTruncate text={booking.guest?.yomigana || ''} />
                    </div>
                    <div className="detail-booking-card-row">
                        <TextTruncate text={facilityType?.name || ''} />
                    </div>
                    <div className="detail-booking-card-row">
                        <span>{`${date} (${t('common.standardTimeTitle')})`}</span>
                    </div>
                    <div className="detail-booking-card-row">
                        <span>￥{formatMoney(booking?.totalAmount || 0)}</span>
                    </div>
                    {!isReadOnly && (
                        <div className="detail-booking-card-row d-flex j-between a-center">
                            <Button
                                type="text"
                                className="detail-btn pt-0"
                                onClick={gotoDetailPage}
                            >
                                {t('facilityBooking.schedule.showDetail')}
                                <RightOutlined />
                            </Button>
                            {isShowEditBtn && (
                                <Button
                                    loading={isLoading}
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={openEditForm}
                                />
                            )}
                        </div>
                    )}
                </div>
                {!isReadOnly && (
                    <div className="detail-booking-card-footer">
                        <StatusDropdown
                            onClose={onClose}
                            booking={{ ...booking, facilityTypeId: facilityType?.id }}
                            navigate={navigate}
                            onChangeStatusSuccess={onChangeStatusSuccess}
                        />
                    </div>
                )}
            </Card>
        );
    };

    return (
        <Popover
            content={renderContent()}
            trigger="click"
            open={isOpen && isOpenPopover}
            overlayClassName="facility-detail-booking-modal"
            onOpenChange={onClose}
            placement="bottom"
        ></Popover>
    );
};
