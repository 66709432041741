import { Button, Modal, notification, Popconfirm, Table, Tooltip } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IChildBooking,
    IChildBookingAmounts,
    IGetBookingPrice,
    IRoomBookingDetail,
    IRoomBookingItem,
    IUpdateBookingItemStatus,
    IUpdateRoomBookingItem,
    IUpdateRoomBookingItemBody,
} from '~features/room-booking/interfaces';
import {
    bulkDeleteRoomBookingItems,
    isUpdatingBookingSelector,
    selectedRoomBookingDetailSelector,
    setSelectedRoomBookingDetail,
    updateBookingItemStatus,
    updateRoomBookingItem,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './RoomBookingItemListView.scss';
import { ColumnsType } from 'antd/lib/table';
import customDayjs, { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import {
    EditOutlined,
    DeleteOutlined,
    CheckOutlined,
    CloseOutlined,
    MinusOutlined,
    DownOutlined,
} from '@ant-design/icons';
import { roomBookingItemBookingStatusOptions } from '~features/room-booking/util';
import _, { debounce, sumBy } from 'lodash';
import { InputText, ModalConfirmDeletion, RangePicker, SingleSelect } from '~components';
import { useForm } from '~plugins/hook-form';
import {
    getListForDropDown,
    roomDropDownListSelector,
} from '~features/room/room.reducer';
import { DateFormat, ErrorMessageType, UserGroup } from '~common/constants';
import {
    ChangingBookingStatusesMap,
    InputAdultFieldName,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateRoomBookingItemListModeSchema } from '~features/room-booking/schema';
import { planDropdownSelector } from '~features/plan/reducers/plan.reducer';
import {
    roomTypeListDropdownSelector,
    roomTypeDropdownOptionsSelector,
} from '~features/room-type/room-type.reducer';
import { ChildrenInput } from '~features/room-booking/components/ChildrenInput/ChildrenInput';
import { childrenStateSelector } from '~features/children-type/reducers/children.reducer';
import {
    checkRoomBookingItemCanDelete,
    getAutoGeneratedCode,
    getChildrenObject,
    getRoomTypeName,
    getRoomTypeTitleError,
    isRoomTypeError,
    validateDayUseTimeIn24H,
    validateGenderBreakdownOfGuest,
    validateStayingDate,
} from '~features/room-booking/helper';
import classNames from 'classnames';
import { BookingErrorModal } from '~features/room-booking/pages/RoomBookingListPage/components/RoomBookingList/BookingErrorModal';
import {
    setSelectedRoomBookingSchedule,
    setShowCheckInForm,
} from '~features/room-booking/reducers/schedule.reducer';
import { getBookingScheduleDefault } from '~features/room-booking/model';
import {
    createBookingStateSelector,
    getBookingPrice,
} from '~features/room-booking/reducers/create-booking.reducer';
import { showConfirm } from '~common/notification';
import {
    isChangeReceiptPrice,
    isFrozenBooking,
} from '~features/room-booking/helper.update-booking';
import { IRoomGetListDropDownQuery } from '~features/room/interfaces';
import InputAdult from '~features/room-booking/components/InputAdult/InputAdult';
import { useRoomOptions } from '~common/useHooks';
import { checkUserPermission } from '~common/commonFunctions';
import { useNavigate } from 'react-router-dom';

type Props = {
    roomBookingDetail: IRoomBookingDetail | null;
    fetchRoomBookingDetail: (id: number) => void;
};

function RoomBookingItemListView({ roomBookingDetail, fetchRoomBookingDetail }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAdmin, isHotelAdmin } = useMemo(() => {
        return {
            isAdmin: checkUserPermission([UserGroup.ADMIN]),
            isHotelAdmin: checkUserPermission([UserGroup.HOTEL_ADMIN]),
        };
    }, []);

    const [editingKey, setEditingKey] = useState<number | undefined>(undefined);
    const [currentRoomType, setCurrentRoomType] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [currentPlan, setCurrentPlan] = useState<number | null>(null);
    const [openChangeStatusPopConfirm, setOpenChangeStatusPopConfirm] = useState(false);

    const roomTypeDropdownOptions = useAppSelector(roomTypeDropdownOptionsSelector);
    const roomTypeDropdown = useAppSelector(roomTypeListDropdownSelector);
    const roomDropDownList = useAppSelector(roomDropDownListSelector);
    const plansOptions = useAppSelector(planDropdownSelector);
    const { children } = useAppSelector(childrenStateSelector);
    const formBusy = useAppSelector(isUpdatingBookingSelector);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const isBookingWalkIn = !selectedRoomBookingDetail?.marketingChannel?.isPullFromTll;
    const [childrenList, setChildrenList] = useState<Record<string, number>>();
    const { isCheckingPlan } = useAppSelector(createBookingStateSelector);

    const selectedBookingStatus = useRef<string>('');

    const { control, handleSubmit, reset, setError, setValue, getValues } = useForm({
        resolver: yupResolver(updateRoomBookingItemListModeSchema),
    });

    const isFrozen = useMemo(() => {
        return isFrozenBooking(roomBookingDetail?.roomBookingItems || []);
    }, [roomBookingDetail?.roomBookingItems]);

    const editingBooking = useMemo(() => {
        return roomBookingDetail?.roomBookingItems?.find((booking) => {
            return booking.id === editingKey;
        });
    }, [editingKey, roomBookingDetail]);

    const guestOptions = useMemo(() => {
        return roomBookingDetail?.guests?.map((guest) => {
            return {
                label: guest.yomigana,
                value: guest.id,
            };
        });
    }, [roomBookingDetail]);

    const [roomOptions, isRoomOptionsLoading] = useRoomOptions(
        editingBooking ?? null,
        currentRoomType,
    );

    const planDropDownOptions = useMemo(() => {
        if (currentRoomType)
            return plansOptions.filter((plan) => {
                return plan.roomTypeIds?.includes(currentRoomType);
            });
        return plansOptions;
    }, [plansOptions, currentRoomType]);

    const roomsTypeOptions = useMemo(() => {
        if (currentPlan) {
            const plan = plansOptions.find((option) => {
                return option.value === currentPlan;
            });
            return roomTypeDropdownOptions.filter((option) => {
                return plan?.roomTypeIds?.includes(Number(option.value));
            });
        }
        return roomTypeDropdownOptions;
    }, [roomTypeDropdownOptions, currentPlan]);

    const onConfirmDeletion = async (booking: IRoomBookingItem) => {
        if (
            !checkRoomBookingItemCanDelete(
                booking,
                isAdmin,
                isHotelAdmin && isBookingWalkIn,
            )
        ) {
            BookingErrorModal({
                title: t('roomBooking.list.message.titleDelete'),
                okText: t('roomBooking.list.statusModalConfirm.okText'),
                description: t(
                    'roomBooking.detail.deleteConfirmDialog.deleteByStatusError',
                ),
                errorItems: [],
            });
            return;
        }
        if (!Number(booking.id)) return;
        const response = await dispatch(
            bulkDeleteRoomBookingItems([booking.id as number]),
        );
        if (bulkDeleteRoomBookingItems.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomBooking.detail.message.deleteSuccess'),
                });
                if (
                    roomBookingDetail &&
                    roomBookingDetail?.roomBookingItems?.length - 1 > 0
                ) {
                    fetchRoomBookingDetail(roomBookingDetail.id);
                } else {
                    navigate('/room-booking');
                    dispatch(setSelectedRoomBookingDetail(null));
                }
                return;
            }
            notification.error({
                message: response.payload?.message || '',
            });
        }
    };

    const deleteBookingItem = (roomBookingItem: IRoomBookingItem) => {
        ModalConfirmDeletion({
            deletedItems: [],
            buttonCancelText: t('common.buttonCancelText'),
            buttonDeleteText: t('common.buttonDeleteText'),
            okButtonProps: { danger: true },
            onClickButtonDelete: () => onConfirmDeletion(roomBookingItem),
        });
    };

    const initForm = (booking: IRoomBookingItem) => {
        const selectedRoomType = roomTypeDropdown.find((roomType) => {
            return roomType.id === booking.roomType?.id;
        });
        reset({
            representativeGuestId: booking.representativeGuestId || undefined,
            bookingStatus: booking.bookingStatus,
            planId: booking.plan?.id,
            roomId: booking.room?.id,
            roomTypeId: booking.roomType?.id,
            numberOfAdults: booking.numberOfAdults,
            numberOfMale: booking.numberOfMale || null,
            numberOfFemale: booking.numberOfFemale || null,
            numberOfOtherGenderGuest: booking.numberOfOtherGenderGuest || null,
            standardCapacity: selectedRoomType?.standardCapacity || 0,
            dateOfStayPeriod: [
                parseDate(
                    `${parseDate(booking.startDateOfStay)?.fmYYYYMMDD()} ${
                        booking.checkInTime || ''
                    }`,
                ),
                parseDate(
                    `${parseDate(booking.endDateOfStay)?.fmYYYYMMDD()} ${
                        booking.checkOutTime || ''
                    }`,
                ),
            ],
        });
    };

    useEffect(() => {
        if (editingBooking) {
            initForm(editingBooking);
        }
    }, [roomBookingDetail]);

    const edit = (booking: IRoomBookingItem) => {
        initForm(booking);
        setCurrentRoomType(booking.roomType?.id || null);
        setCurrentPlan(booking.plan?.id || null);
        setEditingKey(booking.id as number);
        fetchRoomDropDown({
            roomBookingItemId: Number(booking.id) || undefined,
            roomBookingStayPeriod: [
                `${parseDate(booking.startDateOfStay).fmYYYYMMDD('-')} ${parseTime(
                    booking.checkInTime,
                ).fmHHmmss()}`,
                `${parseDate(booking.endDateOfStay).fmYYYYMMDD('-')} ${parseTime(
                    booking.checkOutTime,
                ).fmHHmmss()}`,
            ],
            isDayUse: !!booking.isDayUse,
        });
    };

    const _getBookingPrice = async () => {
        const roomTypeId = getValues('roomTypeId');
        const planId = getValues('planId');
        const numberOfAdults = getValues('numberOfAdults');
        const [start, end] = getValues('dateOfStayPeriod') as [Dayjs, Dayjs];
        if (!roomTypeId || !planId || !numberOfAdults || !start || !end) return false;

        const query: IGetBookingPrice = {
            roomTypeId: roomTypeId,
            startDateOfStay: start.fmYYYYMMDD(),
            endDateOfStay: end.fmYYYYMMDD(),
            numberOfAdults: Number(numberOfAdults),
            planId: planId,
            childrenTypeQuantities: children.map((item, index) => ({
                childrenTypeId: item.id,
                quantity: childrenList?.[`id_${item.id}`] || 0,
            })),
        };
        const response = await dispatch(getBookingPrice(query));
        if (getBookingPrice.fulfilled.match(response)) {
            const { data } = response.payload;
            if (!data) {
                notification.error({
                    message:
                        response?.payload?.message ||
                        t('roomBooking.createBooking.message.calculateAmountError'),
                });
                return false;
            }
            return true;
        }
        return true;
    };

    const checkPriceAndUpdateBooking = async (
        updateRoomBookingItem: IUpdateRoomBookingItemBody,
        roomBookingItem: IRoomBookingItem,
    ) => {
        const check = await _getBookingPrice();
        if (!check) {
            setError('planId', {
                type: ErrorMessageType.MANUAL,
                message: t('roomBooking.form.planInvalidPrice'),
            });
            return;
        }
        const {
            bookingStatus,
            roomId,
            roomTypeId,
            roomBookingItemChildrenTypes = [],
        } = updateRoomBookingItem;
        if (bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED) {
            updateRoomBookingItem.roomTypeId = roomId ? undefined : roomTypeId;
            updateRoomBookingItem.roomBookingItemChildrenTypes =
                roomBookingItemChildrenTypes.map((item) => {
                    return {
                        ...item,
                        childrenTypeId: !item?.id ? item.childrenTypeId : undefined,
                    };
                });
        }
        _updateRoomBookingItem({
            id: Number(roomBookingItem.id),
            body: updateRoomBookingItem,
        });
    };

    const showConfirmChangeReceipt = (
        bookingItemBody: IUpdateRoomBookingItemBody,
        roomBookingItem: IRoomBookingItem,
    ) => {
        showConfirm({
            okText: t('roomBooking.detail.confirmUpdateReceipt.updateButton'),
            cancelText: t('roomBooking.detail.confirmUpdateReceipt.notUpdateButton'),
            title: t('roomBooking.detail.confirmUpdateReceipt.confirmTitle'),
            onOk: async () => {
                await checkPriceAndUpdateBooking(
                    {
                        ...bookingItemBody,
                        isUpdateReceipt: true,
                    },
                    roomBookingItem,
                );
            },
            onCancel: async () => {
                await checkPriceAndUpdateBooking(
                    {
                        ...bookingItemBody,
                        isUpdateReceipt: false,
                    },
                    roomBookingItem,
                );
            },
        });
    };

    const saveBookingItem = (roomBookingItem: IRoomBookingItem) => {
        handleSubmit(async (data) => {
            const {
                representativeGuestId,
                bookingStatus,
                planId,
                roomId,
                dateOfStayPeriod,
                roomTypeId,
                numberOfAdults,
                numberOfMale,
                numberOfFemale,
                numberOfOtherGenderGuest,
            } = data;
            if (!editingBooking) return;
            if (editingBooking.isDayUse && dateOfStayPeriod) {
                const validIn24H = validateDayUseTimeIn24H(
                    [parseDate(dateOfStayPeriod[0]), parseDate(dateOfStayPeriod[1])],
                    [parseDate(dateOfStayPeriod[0]), parseDate(dateOfStayPeriod[1])],
                );

                if (!validIn24H) {
                    setError('dateOfStayPeriod', {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.time24hPeriodError'),
                    });
                    return;
                }
            }

            if (!editingBooking.isDayUse && dateOfStayPeriod) {
                const validStayingDate = validateStayingDate([
                    parseDate(parseDate(dateOfStayPeriod[0])?.fmYYYYMMDD()),
                    parseDate(parseDate(dateOfStayPeriod[1])?.fmYYYYMMDD()),
                ]);

                if (!validStayingDate) {
                    setError('dateOfStayPeriod', {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.datePeriodError'),
                    });
                    return;
                }
            }
            // check change room booking item status
            if (
                bookingStatus !== roomBookingItem.bookingStatus &&
                !ChangingBookingStatusesMap[roomBookingItem.bookingStatus]?.includes(
                    bookingStatus as RoomBookingItemBookingStatus,
                )
            ) {
                setError(
                    'bookingStatus',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.detail.message.cannotChangeStatus', {
                            status: t(`roomBooking.page.bookingStatus.${bookingStatus}`),
                        }),
                    },
                    { shouldFocus: true },
                );
                return;
            }

            if (
                !validateGenderBreakdownOfGuest({
                    numberOfAdults,
                    numberOfMale,
                    numberOfFemale,
                    numberOfOtherGenderGuest,
                })
            ) {
                setError(
                    'numberOfAdults',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.genderBreakdownError'),
                    },
                    { shouldFocus: true },
                );
                return;
            }

            const _children: IChildBookingAmounts[] = [];

            _.forEach(data.children, (val, index: number) => {
                const child = roomBookingItem.roomBookingItemChildrenTypes?.find(
                    (child) => {
                        return child.childrenTypeId === children[index].id;
                    },
                );
                _children.push({
                    id: child?.id || undefined,
                    quantity: Number(val),
                    childrenTypeId: children[index].id,
                });
            });

            let bookingItemBody: IUpdateRoomBookingItemBody = {
                roomId,
                bookingStatus,
                representativeGuestId: representativeGuestId || null,
                isRepresentativeRoom: roomBookingItem.isRepresentativeRoom,
                endDateOfStay: parseDate(dateOfStayPeriod[1])
                    .startOf('day')
                    .fmYYYYMMDDHHmmss('-'),
                isUpdateReceipt: true,
            };

            if (
                roomBookingItem.bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED
            ) {
                bookingItemBody = {
                    ...bookingItemBody,
                    planId,
                    roomTypeId,
                    numberOfAdults: Number(numberOfAdults),
                    numberOfMale: Number(numberOfMale) || 0,
                    numberOfFemale: Number(numberOfFemale) || 0,
                    numberOfOtherGenderGuest: Number(numberOfOtherGenderGuest) || 0,
                    checkInTime: parseDate(dateOfStayPeriod[0])?.fmHHmm(),
                    checkOutTime: parseDate(dateOfStayPeriod[1])?.fmHHmm(),
                    startDateOfStay: parseDate(dateOfStayPeriod[0])
                        .startOf('day')
                        .fmYYYYMMDDHHmmss('-'),
                    roomBookingItemChildrenTypes: _children,
                };
            }
            if (isChangeReceiptPrice(bookingItemBody, roomBookingItem)) {
                checkPriceAndUpdateBooking(bookingItemBody, roomBookingItem);
            } else {
                showConfirmChangeReceipt(bookingItemBody, roomBookingItem);
            }
        })();
    };

    const cancelEditing = (id?: number) => {
        setEditingKey(undefined);
        setCurrentRoomType(null);
    };

    const isEditing = (record: IRoomBookingItem) => record.id === editingKey;

    const canEditField = (
        item: IRoomBookingItem,
        status: RoomBookingItemBookingStatus[],
    ) => {
        return status.includes(item.bookingStatus as RoomBookingItemBookingStatus);
    };

    const _updateRoomBookingItem = useCallback(
        async (roomBookingItem: IUpdateRoomBookingItem) => {
            const response = await dispatch(updateRoomBookingItem(roomBookingItem));
            if (updateRoomBookingItem.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('roomBooking.detail.message.updateSuccess'),
                    });
                    if (roomBookingDetail) {
                        fetchRoomBookingDetail(roomBookingDetail.id);
                    }
                    cancelEditing();
                } else {
                    (response.payload?.errors || []).forEach((error) => {
                        if (
                            error.key === 'startDateOfStay' ||
                            error.key === 'endDateOfStay'
                        ) {
                            setError(
                                'dateOfStayPeriod',
                                { type: ErrorMessageType.MANUAL, message: error.message },
                                { shouldFocus: true },
                            );
                        }
                        setError(
                            error.key,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    });
                    notification.error({
                        message: response.payload?.message || '',
                    });
                }
            }
        },
        [],
    );

    useEffect(() => {
        const selectedRoomType = roomTypeDropdown.find((roomType) => {
            return roomType.id === currentRoomType;
        });

        setValue('standardCapacity', selectedRoomType?.standardCapacity || 0);
    }, [currentRoomType]);

    const childrenCount = (booking: IRoomBookingItem) => {
        return sumBy(booking.roomBookingItemChildrenTypes, 'quantity');
    };

    const handleVisibleChange = (isVisible: boolean) => {
        if (openChangeStatusPopConfirm) {
            setOpenChangeStatusPopConfirm(isVisible);
            if (!isVisible) {
                setValue('bookingStatus', editingBooking?.bookingStatus);
            }
        } else {
            setOpenChangeStatusPopConfirm(false);
            setValue('bookingStatus', editingBooking?.bookingStatus);
        }
    };

    const showCheckInModal = (bookingItem: IRoomBookingItem) => {
        const {
            id,
            startDateOfStay,
            endDateOfStay,
            roomBookingId,
            checkInTime,
            checkOutTime,
            isDayUse,
        } = bookingItem;
        if (parseDate(startDateOfStay).isAfter(customDayjs(), 'day')) {
            notification.error({
                message: t('roomBooking.schedule.message.cannotCheckIn.fromNotArrived', {
                    date: parseDate(startDateOfStay)?.fmYYYYMMDD('-'),
                }),
            });
            return;
        }
        dispatch(setShowCheckInForm(true));
        dispatch(
            setSelectedRoomBookingSchedule({
                ...getBookingScheduleDefault(),
                roomBookingId: roomBookingId,
                id: Number(id) || 0,
                roomType: bookingItem.roomType || { id: null, name: '' },
                room: {
                    id: bookingItem.room?.id || null,
                    name: bookingItem.room?.name || '',
                },
            }),
        );
        // get room drop down
        dispatch(
            getListForDropDown({
                roomBookingItemId: Number(id) || 0,
                isDayUse: isDayUse,
                roomTypeId: bookingItem.roomType?.id || undefined,
                roomBookingStayPeriod: [
                    `${parseDate(startDateOfStay).fmYYYYMMDD()} ${parseTime(
                        checkInTime,
                    ).fmHHmmss()}`,
                    `${parseDate(endDateOfStay).fmYYYYMMDD()} ${parseTime(
                        checkOutTime,
                    ).fmHHmmss()}`,
                ],
            }),
        );
    };

    const _updateBookingItemStatus = async (body: IUpdateBookingItemStatus) => {
        const response = await dispatch(updateBookingItemStatus(body));
        setOpenChangeStatusPopConfirm(false);
        if (updateBookingItemStatus.fulfilled.match(response)) {
            if (response.payload?.success) {
                let message = null;
                if (body.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT) {
                    message = t(
                        'facilityBooking.schedule.modalConfirmCheckout.description',
                    );
                }
                notification.success({
                    message: message || t('roomBooking.list.statusModalConfirm.success'),
                });
                if (roomBookingDetail) {
                    fetchRoomBookingDetail(roomBookingDetail.id);
                }
                setEditingKey(undefined);
            } else {
                if (body.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT) {
                    if (response.payload?.errors?.length) {
                        const autoGeneratedCode = getAutoGeneratedCode(
                            roomBookingDetail?.autoGeneratedCode || '',
                        );
                        BookingErrorModal({
                            title: t('roomBooking.list.checkoutDialog.unpaidTitle'),
                            okText: t('roomBooking.list.statusModalConfirm.okText'),
                            description: t('roomBooking.list.checkoutDialog.content'),
                            errorItems: [
                                roomBookingDetail?.representativeGuest?.fullName
                                    ? `${autoGeneratedCode} - ${roomBookingDetail.representativeGuest?.yomigana} - ${roomBookingDetail?.representativeGuest?.fullName}`
                                    : `${autoGeneratedCode} - ${roomBookingDetail?.representativeGuest?.yomigana}`,
                            ],
                        });
                    }
                } else {
                    Modal.error({
                        title: t('roomBooking.list.statusPopConfirm.title'),
                        content: response.payload?.message,
                        okText: t('roomBooking.list.statusModalConfirm.okText'),
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (!editingBooking) return;
        const isRoomExist = roomDropDownList.some(
            (room) => room.id === editingBooking.room?.id,
        );
        setValue('roomId', isRoomExist ? editingBooking.room?.id : null);
    }, [roomDropDownList, setValue, editingKey, roomBookingDetail, editingBooking]);

    const fetchRoomDropDown = debounce((query: IRoomGetListDropDownQuery) => {
        dispatch(getListForDropDown(query));
    }, 1000);

    const changeDateOfStayPeriod = (values: any, formatString: [string, string]) => {
        fetchRoomDropDown({
            roomBookingItemId: Number(editingBooking?.id) || undefined,
            roomBookingStayPeriod: [
                parseDate(formatString[0]).fmYYYYMMDDHHmmss('-'),
                parseDate(formatString[1]).fmYYYYMMDDHHmmss('-'),
            ],
            isDayUse: !!editingBooking?.isDayUse,
        });
    };

    const changeBookingStatusConfirm = async () => {
        const bookingStatus = getValues('bookingStatus');
        if (!editingBooking) return;
        if (
            bookingStatus !== editingBooking?.bookingStatus &&
            !ChangingBookingStatusesMap[editingBooking?.bookingStatus]?.includes(
                bookingStatus as RoomBookingItemBookingStatus,
            )
        ) {
            setError(
                'bookingStatus',
                {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.detail.message.cannotChangeStatus', {
                        status: t(`roomBooking.page.bookingStatus.${bookingStatus}`),
                    }),
                },
                { shouldFocus: true },
            );
            setOpenChangeStatusPopConfirm(false);
            return;
        }
        if (bookingStatus === RoomBookingItemBookingStatus.CHECKED_IN) {
            showCheckInModal(editingBooking);
            return;
        }

        _updateBookingItemStatus({
            bookingStatus: bookingStatus as RoomBookingItemBookingStatus,
            ids: [editingBooking.id as number],
        });
    };
    const changeBookingStatusCancel = () => {
        setOpenChangeStatusPopConfirm(false);
        setValue('bookingStatus', editingBooking?.bookingStatus);
    };

    const handleChangStatus = (key: string) => {
        setOpenChangeStatusPopConfirm(true);
        selectedBookingStatus.current = key;
    };

    const roomBookingItemColumns: ColumnsType<IRoomBookingItem> = [
        {
            title: '#',
            render: (_, item, index) => {
                return <span>{index + 1}</span>;
            },
            width: '60px',
            align: 'center',
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: t('roomBooking.detail.roomBookingItemColumns.yomigana'),
            render: (item: IRoomBookingItem) => {
                return isEditing(item) ? (
                    <SingleSelect
                        label=""
                        placeholder={t(
                            'roomBooking.detail.bookingItemCard.guestYomigana.placeholder',
                        )}
                        name="representativeGuestId"
                        required
                        control={control}
                        options={guestOptions}
                        disabled={canEditField(item, [
                            RoomBookingItemBookingStatus.CHECKED_OUT,
                            RoomBookingItemBookingStatus.CANCELLED,
                        ])}
                    />
                ) : (
                    <span>{item.representativeGuest?.yomigana || ''}</span>
                );
            },
            ellipsis: true,
            width: 200,
        },
        {
            title: t('roomBooking.detail.roomBookingItemColumns.bookingStatus'),
            render: (item: IRoomBookingItem) => {
                return isEditing(item) ? (
                    <div className="change-booking-status-wrapper">
                        <SingleSelect
                            label=""
                            name="bookingStatus"
                            placeholder={t(
                                'roomBooking.detail.bookingItemCard.status.placeholder',
                            )}
                            control={control}
                            disabled={isFrozen}
                            options={roomBookingItemBookingStatusOptions()}
                            onChange={handleChangStatus}
                        />
                        <Popconfirm
                            title={
                                <div className="change-booking-status-title">
                                    <span className="title-status-pop-confirm">
                                        {t('roomBooking.list.statusPopConfirm.title')}
                                    </span>
                                    <br />
                                    {t('roomBooking.list.statusPopConfirm.content', {
                                        status: t(
                                            `roomBooking.page.bookingStatus.${selectedBookingStatus.current}`,
                                        ),
                                    })}
                                </div>
                            }
                            open={openChangeStatusPopConfirm}
                            placement="bottom"
                            overlayClassName="change-booking-status-popconfirm"
                            okText={t('roomBooking.list.statusPopConfirm.okText')}
                            cancelText={t('roomBooking.list.statusPopConfirm.cancelText')}
                            onOpenChange={handleVisibleChange}
                            onConfirm={changeBookingStatusConfirm}
                            onCancel={changeBookingStatusCancel}
                        >
                            <Button className="change-status-button" />
                        </Popconfirm>
                    </div>
                ) : (
                    <span
                        className={`room-booking-status room-booking-status-${item.bookingStatus}`}
                    >
                        {t(`roomBooking.page.bookingStatus.${item.bookingStatus}`)}
                    </span>
                );
            },
            ellipsis: true,
            width: 180,
        },
        {
            title: t('roomBooking.detail.roomBookingItemColumns.roomTypeName'),
            render: (item: IRoomBookingItem) => {
                return isEditing(item) &&
                    canEditField(item, [RoomBookingItemBookingStatus.NOT_ARRIVED]) ? (
                    <SingleSelect
                        label=""
                        placeholder={t(
                            'roomBooking.detail.bookingItemCard.roomTypeId.placeholder',
                        )}
                        name={`roomTypeId`}
                        options={roomsTypeOptions}
                        control={control}
                        onChange={(value) => {
                            setCurrentRoomType(value);
                            setValue('roomId', null);
                        }}
                        popupMatchSelectWidth={300}
                    />
                ) : (
                    <Tooltip title={getRoomTypeTitleError(item.roomType)}>
                        <div
                            className={classNames('text-truncate', {
                                'booking-error': isRoomTypeError(item.roomType),
                            })}
                        >
                            {getRoomTypeName(item.roomType)}
                        </div>
                    </Tooltip>
                );
            },
            ellipsis: true,
            width: 150,
        },
        {
            title: t('roomBooking.detail.roomBookingItemColumns.planName'),
            render: (item: IRoomBookingItem) => {
                return isEditing(item) &&
                    canEditField(item, [RoomBookingItemBookingStatus.NOT_ARRIVED]) ? (
                    <SingleSelect
                        label=""
                        name="planId"
                        placeholder={t(
                            'roomBooking.detail.bookingItemCard.planId.placeholder',
                        )}
                        control={control}
                        options={planDropDownOptions}
                        popupMatchSelectWidth={300}
                    />
                ) : (
                    <span>
                        {!item.plan?.isPullFromTll && !!item.plan?.id ? (
                            item.plan?.name || ''
                        ) : (
                            <Tooltip title={t('roomBooking.detail.message.planNotExist')}>
                                <div
                                    className={classNames(
                                        'text-truncate',
                                        'booking-error',
                                    )}
                                >
                                    {item.plan?.name ||
                                        t(`roomBooking.detail.receipt.plan.notExist`)}
                                </div>
                            </Tooltip>
                        )}
                    </span>
                );
            },
            ellipsis: true,
            width: 160,
        },
        {
            title: t('roomBooking.detail.roomBookingItemColumns.roomName'),
            render: (item: IRoomBookingItem) => {
                return isEditing(item) &&
                    canEditField(item, [
                        RoomBookingItemBookingStatus.NOT_ARRIVED,
                        RoomBookingItemBookingStatus.CHECKED_IN,
                    ]) ? (
                    <SingleSelect
                        label=""
                        name="roomId"
                        placeholder={t(
                            'roomBooking.detail.bookingItemCard.roomId.placeholder',
                        )}
                        control={control}
                        options={roomOptions}
                        loading={isRoomOptionsLoading}
                    />
                ) : item.bookingStatus !== RoomBookingItemBookingStatus.CANCELLED ? (
                    <span>{item.room?.name || ''}</span>
                ) : (
                    ''
                );
            },
            ellipsis: true,
            width: 160,
        },
        {
            title: t('roomBooking.detail.roomBookingItemColumns.numberOfAdults'),
            render: (item: IRoomBookingItem) => {
                return isEditing(item) &&
                    canEditField(item, [RoomBookingItemBookingStatus.NOT_ARRIVED]) ? (
                    <InputAdult
                        label={t('roomBooking.form.scheduleBooking.adult')}
                        control={control}
                        setValue={setValue}
                        required={true}
                        isHorizontal={true}
                        isHideLabel={true}
                        className="booking-form-input-adult"
                        fieldName={InputAdultFieldName}
                        autoSetTotalGuest={true}
                    />
                ) : (
                    <>
                        <span>{item.numberOfAdults || 0}</span>
                        <span>
                            {t('roomBooking.schedule.genderBreakdown', {
                                male: item.numberOfMale || 0,
                                female: item.numberOfFemale || 0,
                                other: item.numberOfOtherGenderGuest || 0,
                            })}
                        </span>
                    </>
                );
            },
            ellipsis: true,
            width: 150,
        },
        {
            title: t('roomBooking.detail.roomBookingItemColumns.numberOfChildren'),
            render: (item: IRoomBookingItem) => {
                return isEditing(item) &&
                    canEditField(item, [RoomBookingItemBookingStatus.NOT_ARRIVED]) ? (
                    <>
                        <div>
                            <InputText
                                label=""
                                name="childrenCount"
                                placeholder={t('roomBooking.form.scheduleBooking.child')}
                                control={control}
                                defaultValue={
                                    item?.roomBookingItemChildrenTypes?.length
                                        ? childrenCount(item)
                                        : undefined
                                }
                            />

                            <div
                                className="input-overlay"
                                onClick={() => {
                                    setIsOpen(!isOpen);
                                }}
                            >
                                <DownOutlined
                                    className={classNames({
                                        'expand-icon': true,
                                        active: isOpen,
                                        deactive: !isOpen,
                                    })}
                                />
                            </div>
                        </div>
                        <div
                            className={classNames('child-content', {
                                open: isOpen,
                                close: !isOpen,
                            })}
                        >
                            <ChildrenInput
                                isOpen={isOpen}
                                control={control}
                                name={(index) => `children.${index}`}
                                defaultValue={getChildrenObject(
                                    (item.roomBookingItemChildrenTypes || [])?.map(
                                        (item) => ({
                                            ...item,
                                            typeId: item.childrenTypeId,
                                        }),
                                    ) as IChildBooking[],
                                )}
                                onChange={(value, total) => {
                                    setValue('childrenCount', total.toString());
                                    setChildrenList(value);
                                }}
                                className="booking-form-children-input"
                            />
                        </div>
                    </>
                ) : (
                    <span>
                        {sumBy(
                            item.roomBookingItemChildrenTypes,
                            (item) => item.quantity,
                        ) || 0}
                    </span>
                );
            },
            ellipsis: true,
            width: 200,
        },
        {
            title: `${t('roomBooking.detail.roomBookingItemColumns.stayTime')} (${t(
                'common.standardTimeTitle',
            )})`,
            render: (item: IRoomBookingItem) => {
                return isEditing(item) ? (
                    <RangePicker
                        label=""
                        name="dateOfStayPeriod"
                        placeholder={[
                            t(
                                'roomBooking.detail.bookingItemCard.dateOfStayPeriod.placeholder.start',
                            ),
                            t(
                                'roomBooking.detail.bookingItemCard.dateOfStayPeriod.placeholder.end',
                            ),
                        ]}
                        allowClear={false}
                        disabled={[
                            !canEditField(item, [
                                RoomBookingItemBookingStatus.NOT_ARRIVED,
                            ]),
                            canEditField(item, [
                                RoomBookingItemBookingStatus.CHECKED_OUT,
                                RoomBookingItemBookingStatus.CANCELLED,
                            ]),
                        ]}
                        disabledDate={(current) => {
                            // Can not select days before today
                            return current.isBefore(todayDayjs.subtract(2, 'day'), 'day');
                        }}
                        control={control}
                        showTime={canEditField(item, [
                            RoomBookingItemBookingStatus.NOT_ARRIVED,
                        ])}
                        format={
                            canEditField(item, [RoomBookingItemBookingStatus.NOT_ARRIVED])
                                ? DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON
                                : DateFormat.YYYY_MM_DD_HYPHEN
                        }
                        onChange={changeDateOfStayPeriod}
                    />
                ) : (
                    <span>
                        {`${parseDate(item.startDateOfStay)?.fmYYYYMMDD('-')} ${
                            parseTime(item.checkInTime)?.fmHHmm() || ''
                        }`}
                        <MinusOutlined className="minus-icon" />
                        {`${parseDate(item.endDateOfStay)?.fmYYYYMMDD('-')} ${
                            parseTime(item.checkOutTime)?.fmHHmm() || ''
                        }`}
                    </span>
                );
            },
            key: 'id',
        },
        {
            title: t('roomBooking.detail.roomBookingItemColumns.action'),
            render: (roomBookingItem: IRoomBookingItem) => {
                return isEditing(roomBookingItem) ? (
                    <>
                        <div className="save-cancel-group-button">
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => saveBookingItem(roomBookingItem)}
                                loading={isCheckingPlan || formBusy}
                                icon={<CheckOutlined />}
                                disabled={canEditField(roomBookingItem, [
                                    RoomBookingItemBookingStatus.CHECKED_OUT,
                                    RoomBookingItemBookingStatus.CANCELLED,
                                ])}
                            />
                            <Button
                                onClick={() =>
                                    cancelEditing(roomBookingItem.id as number)
                                }
                                size="small"
                                icon={<CloseOutlined />}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Button
                            type="text"
                            shape="circle"
                            onClick={() => edit(roomBookingItem)}
                            disabled={editingKey !== undefined}
                            icon={<EditOutlined />}
                        />
                        <Button
                            shape="circle"
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => deleteBookingItem(roomBookingItem)}
                        />
                    </>
                );
            },
            width: '100px',
            align: 'center',
            fixed: 'right',
        },
    ];

    return (
        <div className="room-booking-item-table">
            <Table
                columns={roomBookingItemColumns}
                dataSource={roomBookingDetail?.roomBookingItems || []}
                pagination={false}
                rowKey="id"
                scroll={{ x: 1750, y: 380 }}
                rowClassName="editable-row"
                className="table-scrollbar"
            />
        </div>
    );
}

export default RoomBookingItemListView;
