import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import FilterForm from './components/FilterForm/FilterForm';
import IndicatorList from './components/IndicatorList/IndicatorList';
import { breadcrumbs } from '~features/indicator/constants';
import './IndicatorListPage.scss';
import { useEffect } from 'react';
import { useAppDispatch } from '~hooks';
import { resetQuery } from '~features/indicator/reducers/indicator.reducer';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';

function IndicatorListPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
        UserGroup.HOTEL_FRONT,
    ]);
    useEffect(() => {
        return () => {
            dispatch(resetQuery());
        };
    }, []);

    return (
        <div className="indicator-list-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('indicator.list.title')}
            />
            <Layout.Content>
                <div className="indicator-list-page-wrapper">
                    <div className="indicator-list-page-content">
                        {!isReadOnly && <FilterForm />}
                        <IndicatorList />
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default IndicatorListPage;
