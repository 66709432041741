const saleGroupList = {
    title: 'Sales setting',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        saleGroup: 'Sales setting',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Sales group name',
        saleItemName: 'Sales item',
    },
    searchBox: 'Search',
    primaryButton: 'Create new sales group',
    saleGroupListColumn: {
        num: '#',
        id: 'ID',
        saleGroupName: 'Sales group name',
        saleItemName: 'Sales item name',
    },
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
        title: 'Delete sales group',
        description: 'Do you want to delete following sales group?',
    },
    exportColumns: {
        autoGeneratedCode: 'ID',
        name: 'Sales group name',
        saleItemName: 'Sales item name',
    },
    confirm: {
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
        cancelTitle: 'Do you want to cancel?',
        cantDelete: `Can't delete sale group item`,
    },
    message: {
        deleteSuccess: 'Sale Groups have been deleted successfully',
    },
};

export default saleGroupList;
