import { Table } from 'antd';
import { ColumnGroupType } from 'antd/es/table';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat } from '~common/constants';
import { FormatMoney, FormatNumber, FormatPercent } from '~common/helper';
import { IDropDownOption } from '~common/interfaces';
import { ReportTableCsvFileName, ReportTableId } from '~features/report/constants';
import { exportCSVReport } from '~features/report/helper';
import {
    salesByCategorySelector,
    setExportCsv,
} from '~features/report/reducers/sales-by-category.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import { DailySummaryDataType } from './interfaces';

interface Props {
    dataSource: any[];
    isLoading: boolean;
    filteredRoomTypeList: IDropDownOption[];
    summaryData: DailySummaryDataType;
}

const { Cell: SummaryCell, Row: SummaryRow } = Table.Summary;

const DailySalesByCategoryTable: React.FC<Props> = ({
    dataSource,
    isLoading,
    filteredRoomTypeList,
    summaryData,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isExportCsv } = useAppSelector(salesByCategorySelector);

    useEffect(() => {
        if (isExportCsv) {
            exportCSVReport(
                ReportTableId.SALE_BY_CATEGORY_DAILY,
                `${ReportTableCsvFileName.SALE_BY_CATEGORY_DAILY}.csv`,
            );
            dispatch(setExportCsv(false));
        }
    }, [isExportCsv, dispatch]);

    const columns: ColumnsType<any> | ColumnGroupType<any> = [
        {
            className: 'date-header-cell',
            title: t('report.salesByCategory.salesByCategoryColumn.date'),
            dataIndex: 'date',
            key: 'date',
            width: 150,
            fixed: 'left',
            render: (date) => (
                <span>{parseDate(date).format(DateFormat.MM_DD_YYYY_SLASH)}</span>
            ),
        },
        {
            title: t('report.salesByCategory.salesByCategoryColumn.roomType'),
            className: 'room-type-header-cell daily-room-type-header-cell',
            key: 'roomType',
            children: filteredRoomTypeList.map((roomType) => ({
                title: roomType.label,
                dataIndex: roomType.value.toString(),
                className: 'small-cell header-cell',
                key: roomType.value,
                width: 150,
                children: [
                    {
                        title: t(
                            'report.salesByCategory.salesByCategoryColumn.numberOfRooms',
                        ),
                        className: 'small-cell',
                        dataIndex: `numberOfOccupiedRooms${roomType.value}`,
                        key: `numberOfRooms-${roomType.value}`,
                        render: (_, record) => {
                            const currentRoomType =
                                record[`numberOfOccupiedRooms${roomType.value}`];
                            if (!currentRoomType) return null;
                            const {
                                [`occupiedRooms${roomType.value}`]: occupiedRooms,
                                [`totalRooms${roomType.value}`]: totalRooms,
                                [`lastYearOccupiedRooms${roomType.value}`]:
                                    lastYearOccupiedRooms,
                                [`lastYearTotalRooms${roomType.value}`]:
                                    lastYearTotalRooms,
                            } = currentRoomType;

                            return (
                                <>
                                    {FormatNumber.format(occupiedRooms)}/
                                    {FormatNumber.format(totalRooms)}{' '}
                                    <span className="gray-color" key={roomType.value}>
                                        {`(${FormatNumber.format(
                                            lastYearOccupiedRooms,
                                        )}/${FormatNumber.format(lastYearTotalRooms)})`}
                                    </span>
                                </>
                            );
                        },
                    },
                    {
                        title: t('report.salesByCategory.salesByCategoryColumn.adults'),
                        className: 'small-cell',
                        dataIndex: `adults${roomType.value}`,
                        key: `adults-${roomType.value}`,
                        render: (_, record) => {
                            if (!record[`adults${roomType.value}`]) return null;
                            const { current, lastYear } =
                                record[`adults${roomType.value}`];
                            return (
                                <>
                                    {FormatNumber.format(current)}
                                    <span className="gray-color">{` (${FormatNumber.format(
                                        lastYear,
                                    )})`}</span>
                                </>
                            );
                        },
                    },
                    {
                        title: t('report.salesByCategory.salesByCategoryColumn.children'),
                        className: 'small-cell',
                        dataIndex: `numberOfChildren${roomType.value}`,
                        key: `children-${roomType.value}`,
                        render: (_, record) => {
                            if (!record[`numberOfChildren${roomType.value}`]) return null;
                            const { current, lastYear } =
                                record[`numberOfChildren${roomType.value}`];
                            return (
                                <>
                                    {FormatNumber.format(current)}{' '}
                                    <span className="gray-color">{`(${FormatNumber.format(
                                        lastYear,
                                    )})`}</span>
                                </>
                            );
                        },
                    },
                    {
                        title: t('report.salesByCategory.salesByCategoryColumn.amount'),
                        className: 'small-cell',
                        dataIndex: `amount${roomType.value}`,
                        key: `amount-${roomType.value}`,
                        render: (_, record) => {
                            if (!record[`amount${roomType.value}`]) return null;
                            const { current, lastYear } =
                                record[`amount${roomType.value}`];
                            return (
                                <>
                                    {FormatMoney.format(current)}
                                    <span className="gray-color">
                                        {` (${FormatMoney.format(lastYear)})`}
                                    </span>
                                </>
                            );
                        },
                    },
                    {
                        title: t(
                            'report.salesByCategory.salesByCategoryColumn.averagePrice',
                        ),
                        className: 'small-cell',
                        dataIndex: `averagePrice${roomType.value}`,
                        key: `averagePrice-${roomType.value}`,
                        render: (_, record) => {
                            if (!record[`averagePrice${roomType.value}`]) return null;
                            const { current, lastYear } =
                                record[`averagePrice${roomType.value}`];
                            return (
                                <>
                                    {FormatMoney.format(current)}
                                    <span className="gray-color">
                                        {` (${FormatMoney.format(lastYear)})`}
                                    </span>
                                </>
                            );
                        },
                    },
                    {
                        title: t(
                            'report.salesByCategory.salesByCategoryColumn.occupancyRate',
                        ),
                        className: 'small-cell',
                        dataIndex: `occupancyRate${roomType.value}`,
                        key: `occupancyRate-${roomType.value}`,
                        render: (_, record) => {
                            if (!record[`occupancyRate${roomType.value}`]) return null;
                            const { current, lastYear } =
                                record[`occupancyRate${roomType.value}`];
                            return (
                                <>
                                    {FormatPercent.format(current)}
                                    <span className="gray-color">
                                        {` (${FormatPercent.format(lastYear)})`}
                                    </span>
                                </>
                            );
                        },
                    },
                ],
            })),
        },
    ];

    const summaryColumns = columns
        .filter((column) => column.key === 'roomType')
        .map((column) =>
            (column as ColumnGroupType<any>).children.map(
                (child) => (child as ColumnGroupType<any>).children,
            ),
        )
        .flat(2);

    const showSummaryNumberOfRooms = (columnDataIndex: string, columnKey: string) => {
        if (!summaryData[columnDataIndex]) return null;
        const roomTypeId = columnKey.split('-')[1];
        const {
            [`occupiedRooms${roomTypeId}`]: occupiedRooms,
            [`totalRooms${roomTypeId}`]: totalRooms,
            [`lastYearOccupiedRooms${roomTypeId}`]: lastYearOccupiedRooms,
            [`lastYearTotalRooms${roomTypeId}`]: lastYearTotalRooms,
        } = summaryData[columnDataIndex];
        return (
            <>
                {FormatNumber.format(occupiedRooms)}/{FormatNumber.format(totalRooms)}{' '}
                <span className="gray-color">
                    {`(${FormatNumber.format(
                        lastYearOccupiedRooms,
                    )}/${FormatNumber.format(lastYearTotalRooms)})`}
                </span>
            </>
        );
    };

    const showSummaryNumberOfGuests = (columnDataIndex: string) => {
        if (!summaryData[columnDataIndex]) return null;
        const { current, lastYear } = summaryData[columnDataIndex];
        return (
            <>
                {FormatNumber.format(current)}
                <span className="gray-color">{` (${FormatNumber.format(
                    lastYear,
                )})`}</span>
            </>
        );
    };

    const showSummaryAmount = (columnDataIndex: string) => {
        if (!summaryData[columnDataIndex]) return null;
        const { current, lastYear } = summaryData[columnDataIndex];
        return (
            <>
                {FormatMoney.format(current)}
                <span className="gray-color">{` (${FormatMoney.format(lastYear)})`}</span>
            </>
        );
    };

    const showSummaryOccupancyRate = (columnDataIndex: string) => {
        if (!summaryData[columnDataIndex]) return null;
        const { current, lastYear } = summaryData[columnDataIndex];
        return (
            <>
                {FormatPercent.format(current)}
                <span className="gray-color">{` (${FormatPercent.format(
                    lastYear,
                )})`}</span>
            </>
        );
    };

    const renderSummaryCell = (column: ColumnGroupType<any> | ColumnType<any>) => {
        if (!summaryData) return null;
        const columnDataIndex = (column as ColumnType<any>).dataIndex?.toString();
        const columnKey = column.key?.toString();

        if (columnKey?.includes('numberOfRooms')) {
            return showSummaryNumberOfRooms(columnDataIndex!, columnKey);
        }
        if (columnKey?.includes('adults') || columnKey?.includes('children')) {
            return showSummaryNumberOfGuests(columnDataIndex!);
        }

        if (columnKey?.includes('amount') || columnKey?.includes('averagePrice')) {
            return showSummaryAmount(columnDataIndex!);
        }

        if (columnKey?.includes('occupancyRate')) {
            return showSummaryOccupancyRate(columnDataIndex!);
        }
    };

    return (
        <>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                loading={isLoading}
                className="sales-by-category-table table-scrollbar"
                rowKey="id"
                scroll={{ x: true }}
                id={ReportTableId.SALE_BY_CATEGORY_DAILY}
                summary={() => {
                    return (
                        <SummaryRow>
                            <SummaryCell index={0} className="total-cell">
                                {t('report.salesByCategory.total')}
                            </SummaryCell>
                            {summaryColumns.map((column, index) => {
                                return (
                                    <SummaryCell key={index} index={index + 1}>
                                        {renderSummaryCell(column)}
                                    </SummaryCell>
                                );
                            })}
                        </SummaryRow>
                    );
                }}
            />
        </>
    );
};

export default DailySalesByCategoryTable;
