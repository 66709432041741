import { useCallback, useEffect } from 'react';
import {
    getRoomListFromReceipt,
    getSplitReceiptByGroupMap,
} from '~features/room-booking/helper.split-receipt';
import { IGroup, IReceipt, IReceiptByGroupMap } from '~features/room-booking/interfaces';
import { roomBookingStateSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { setReceiptByGroupMap } from '~features/room-booking/reducers/split-receipt.reducer';
import { IRoom } from '~features/room/interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import BillToTab from '../BillToTab/BillToTab';
import ReceiptDetailTab from './ReceiptDetailTab/ReceiptDetailTab';
import './SplitBillByRoom.scss';

interface IProps {
    receipt: IReceipt | null;
}

function SplitBillByRoom({ receipt }: IProps) {
    const dispatch = useAppDispatch();
    const { selectedRoomBookingDetail } = useAppSelector(roomBookingStateSelector);

    const generateReceiptByRoomMap = useCallback(
        (receipt: IReceipt | null) => {
            const roomList: IRoom[] = getRoomListFromReceipt(receipt);
            const groupList: IGroup[] = roomList.map((room) => ({
                id: room?.id?.toString(),
                name: room?.name,
            }));
            const splitBillByRoom = true;
            const receiptByRoomMap: IReceiptByGroupMap = getSplitReceiptByGroupMap(
                groupList,
                receipt,
                selectedRoomBookingDetail?.representativeGuest?.yomigana || '',
                splitBillByRoom,
            );

            dispatch(setReceiptByGroupMap(receiptByRoomMap));
        },
        [dispatch, selectedRoomBookingDetail?.representativeGuest?.yomigana],
    );

    useEffect(() => {
        generateReceiptByRoomMap(receipt);
    }, [generateReceiptByRoomMap, receipt]);

    return (
        <div className="split-room-booking-split-bill-by-room-wrapper">
            <BillToTab />
            <ReceiptDetailTab />
        </div>
    );
}

export default SplitBillByRoom;
