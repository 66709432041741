import smtpSettingFormJa from './ja/smtp-setting-form-locale';
import smtpSettingFormEn from './en/smtp-setting-form-locale';

const smtpSetting = {
    ja: {
        smtpForm: smtpSettingFormJa,
    },
    en: {
        smtpForm: smtpSettingFormEn,
    },
};

export default smtpSetting;
