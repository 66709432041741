import { Layout, Spin, Tabs } from 'antd';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import {
    breadcrumbs,
    FacilityBookingDetailPageTabPane,
} from '~features/facility-booking/constants';
import {
    getFacilityBookingDetail,
    getFacilityBookingReceipt,
    setActiveDetailTabPane,
    setIsShowUpdateFacilityBookingForm,
    showLoadingSelector,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import FacilityBookingDetailTabPane from './components/FacilityBookingDetailTabPane/FacilityBookingDetailTabPane';
import FacilityBookingReceiptTabPane from './components/FacilityBookingReceiptTabPane/FacilityBookingReceiptTabPane';
import './FacilityBookingDetailPage.scss';
import UpdateFacilityBookingModal from '~features/facility-booking/pages/FacilityBookingDetailPage/UpdateFacilityBookingModal/UpdateFacilityBookingModal';

const { Content } = Layout;

function FacilityBookingDetailPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { facilityBookingId } = useParams();
    const navigate = useNavigate();
    const showLoading = useAppSelector(showLoadingSelector);

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(getFacilityBookingDetail(id));
        if (getFacilityBookingDetail.fulfilled.match(response)) {
            if (!response.payload?.success) {
                navigate('/404');
            }
        }
    }, []);
    const _getReceipt = useCallback(async (id: number) => {
        const response = await dispatch(getFacilityBookingReceipt(id));
        if (getFacilityBookingDetail.fulfilled.match(response)) {
            if (!response.payload?.success) {
                navigate('/404');
            }
        }
    }, []);

    //This re-renders when the route changes
    useEffect(() => {
        if (typeof Number(facilityBookingId) === 'number') {
            _getDetail(Number(facilityBookingId));
        } else {
            navigate('/404');
        }
        return () => {
            dispatch(setIsShowUpdateFacilityBookingForm(false));
        };
    }, []);

    const changeDetailTab = (activeTab: string) => {
        if (activeTab === FacilityBookingDetailPageTabPane.FACILITY_BOOKING_RECEIPT) {
            _getReceipt(Number(facilityBookingId));
        }
        dispatch(setActiveDetailTabPane(activeTab));
    };
    return (
        <div className="facility-booking-detail-page">
            <Spin spinning={showLoading}>
                <StaffLayoutHeader
                    breadcrumbs={[
                        ...breadcrumbs(),
                        {
                            text: t(
                                'facilityBooking.detail.breadcrumbs.facilityBookingDetail',
                            ),
                        },
                    ]}
                    title={t('facilityBooking.detail.title')}
                />
                <Content>
                    <Tabs
                        onChange={changeDetailTab}
                        items={[
                            {
                                key: FacilityBookingDetailPageTabPane.FACILITY_BOOKING_DETAIL,
                                label: (
                                    <span className="facility-booking-tab-pane-title">
                                        {t(
                                            'facilityBooking.detail.detailPageTabs.detail',
                                        )}
                                    </span>
                                ),
                                children: <FacilityBookingDetailTabPane />,
                            },
                            {
                                key: FacilityBookingDetailPageTabPane.FACILITY_BOOKING_RECEIPT,
                                label: (
                                    <span className="facility-booking-tab-pane-title">
                                        {t(
                                            'facilityBooking.detail.detailPageTabs.receipt',
                                        )}
                                    </span>
                                ),
                                children: <FacilityBookingReceiptTabPane />,
                            },
                        ]}
                    ></Tabs>
                    <UpdateFacilityBookingModal />
                </Content>
            </Spin>
        </div>
    );
}

export default FacilityBookingDetailPage;
