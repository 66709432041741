import { IPrintRoomCleaning } from './interfaces';
import customDayjs from '~plugins/dayjs';

export const getNumberOfNights = (roomCleaning: IPrintRoomCleaning) => {
    const numberOfNight = customDayjs()?.diff(
        roomCleaning?.roomBookingItem?.startDateOfStay,
        'day',
    );
    return numberOfNight + 1;
};

export const getRoomStatus = (roomCleaning: IPrintRoomCleaning) => {
    return roomCleaning?.status;
};

export const getNumberOfGuest = (roomCleaning: IPrintRoomCleaning) => {
    const numberOfAdults = roomCleaning?.roomBookingItem?.numberOfAdults;
    const numberOfChildren = roomCleaning?.roomBookingItem?.numberOfChildren;
    if (numberOfAdults && numberOfChildren) {
        return `${numberOfAdults} (${numberOfChildren})`;
    }
    if (numberOfAdults) {
        return `${numberOfAdults}`;
    }
    return '';
};
