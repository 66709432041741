import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RightDrawerLayout, InputText } from '~components';
import { Form, Col, Row, notification, Input } from 'antd';
import './GuestClassificationForm.scss';
import { useAppSelector, useAppDispatch } from '~hooks';
import {
    showFormSelector,
    setShowForm,
    setSelectedGuestClassification,
    fetchGuestClassificationList,
    selectedGuestClassificationSelector,
    formBusySelector,
    getGuestClassificationListForDropdown,
    createGuestClassification,
    updateGuestClassification,
    getGuestClassificationDetail,
} from '../../../../guest-classification.reducer';
import { createGuestClassificationSchema } from '../../../../schema';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessageType, HttpStatus } from '~common/constants';
import { compareFormData } from '~common/commonFunctions';
import {
    IGuestClassificationCreateBody,
    IGuestClassificationUpdateRequest,
} from '../../../../interfaces';
import { showConfirm } from '~common/notification';
import { useEscape } from '~common/useHooks';
import { getGuestClassFormId } from '~features/guest-classification/helper';

function GuestClassificationLForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [formDataDefault, setFormDataDefault] = useState<
        IGuestClassificationCreateBody | {}
    >({});
    const [colorSelected, setColorSelected] = useState<string>('ff0000');
    const { control, handleSubmit, getValues, reset, setError } = useForm({
        resolver: yupResolver(createGuestClassificationSchema),
    });
    const selectedGuestClassification = useAppSelector(
        selectedGuestClassificationSelector,
    );

    useEffect(() => {
        dispatch(getGuestClassificationListForDropdown({}));
    }, []);

    const formBusy = useAppSelector(formBusySelector);
    const showForm = useAppSelector(showFormSelector);

    const makeFormData = (): IGuestClassificationCreateBody => {
        const { name } = getValues();
        return {
            name,
            color: colorSelected,
        };
    };

    const initFormData = (data: IGuestClassificationCreateBody) => {
        setColorSelected(data?.color || '');
        const formData = {
            name: data?.name,
            color: data?.color,
        };
        reset(formData);
        setFormDataDefault(makeFormData());
    };

    useEffect(() => {
        if (showForm) {
            if (selectedGuestClassification?.id) {
                _getGuestClassificationDetail();
            }
        } else {
            reset({
                name: '',
            });
        }
    }, [showForm, dispatch, selectedGuestClassification]);

    const _getGuestClassificationDetail = useCallback(async () => {
        if (!selectedGuestClassification?.id) return;
        const response = await dispatch(
            getGuestClassificationDetail(selectedGuestClassification.id),
        );

        if (getGuestClassificationDetail.fulfilled.match(response)) {
            const { success, data, message } = response.payload;
            if (success) {
                initFormData({
                    name: data?.name,
                    color: data?.color,
                });
            } else {
                notification.error({
                    message: message,
                });
            }
        }
    }, [selectedGuestClassification, updateGuestClassification, reset]);

    const _createGuestClassification = useCallback(
        async (formData: IGuestClassificationCreateBody) => {
            const response = await dispatch(createGuestClassification(formData));
            if (createGuestClassification.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('guestClassification.form.create.success'),
                    });
                    closeDialog(true);
                    return;
                }

                (response.payload?.errors || []).forEach((error) => {
                    if (error.key === 'name') {
                        setError(
                            error.key,
                            { type: ErrorMessageType.MANUAL, message: error?.message },
                            { shouldFocus: true },
                        );
                    }
                });

                notification.error({
                    message: t('guestClassification.form.create.error'),
                    description: response.payload?.message || [],
                });
            }
        },
        [],
    );

    const _updateGuestClassification = useCallback(
        async (formData: IGuestClassificationUpdateRequest) => {
            const response = await dispatch(updateGuestClassification(formData));
            if (updateGuestClassification.fulfilled.match(response)) {
                if (response?.payload?.success) {
                    notification.success({
                        message: t('guestClassification.form.update.success'),
                    });
                    closeDialog(true);
                    return;
                }
                if (response?.payload?.status === HttpStatus.ITEM_NOT_FOUND) {
                    closeDialog(true);
                }
                (response.payload?.errors || []).forEach((error) => {
                    if (error.key === 'name') {
                        setError(
                            error.key,
                            { type: ErrorMessageType.MANUAL, message: error?.message },
                            { shouldFocus: true },
                        );
                    }
                });
                notification.error({
                    message: t('guestClassification.form.update.error'),
                    description: response?.payload?.message || '',
                });
            }
        },
        [],
    );

    const _showConfirm = () => {
        if (compareFormData(makeFormData(), formDataDefault)) {
            closeDialog();
            return;
        }
        showConfirm({
            title: t('guestClassification.list.confirm.cancelTitle'),
            cancelText: t('guestClassification.list.confirm.buttonCancelText'),
            okText: t('guestClassification.list.confirm.buttonDeleteText'),
            onOk() {
                closeDialog();
            },
        });
    };

    const closeDialog = (reload?: boolean) => {
        dispatch(setShowForm(false));
        dispatch(setSelectedGuestClassification(null));
        setColorSelected('ff0000');
        if (reload) {
            dispatch(fetchGuestClassificationList());
        }
    };
    useEscape(closeDialog);

    const onSubmit = () => {
        handleSubmit((value) => {
            const _formData = makeFormData();
            if (selectedGuestClassification) {
                _updateGuestClassification({
                    id: selectedGuestClassification.id,
                    body: _formData,
                });
            } else {
                _createGuestClassification(_formData);
            }
        })();
    };

    const changeHandler = (colors: any) => {
        setColorSelected(colors.target.value.slice(1));
    };

    return (
        <RightDrawerLayout
            open={showForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={
                selectedGuestClassification
                    ? t('guestClassification.form.edit.title')
                    : t('guestClassification.form.create.title')
            }
            cancelText={t('common.buttonCancelText')}
            submitText={
                selectedGuestClassification
                    ? t('guestClassification.form.editBtnText')
                    : t('guestClassification.form.submitBtnText')
            }
            className="create-guest-classification-drawer"
            busy={formBusy}
            loading={formBusy}
            submitId={getGuestClassFormId('btn-submit')}
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                <Row className="input-row">
                    <Col span={24}>
                        <InputText
                            label={t('guestClassification.form.guestCategory')}
                            placeholder={t('guestClassification.form.guestCategoryInput')}
                            name="name"
                            control={control}
                            required
                            id={getGuestClassFormId('name')}
                        />
                    </Col>
                </Row>
                <Row className="color-list-group">
                    <Col>{t('guestClassification.form.color')}</Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Input
                            type="color"
                            value={`#${colorSelected}`}
                            onChange={changeHandler}
                            id={getGuestClassFormId('color')}
                        />
                    </Col>
                </Row>
            </Form>
        </RightDrawerLayout>
    );
}

export default GuestClassificationLForm;
