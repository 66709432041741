import {
    AuditLogActions,
    AuditLogModules,
} from '~features/bo-operation-history/constants';

const historyList = {
    breadcrumbs: {
        backOffice: 'BO設定',
        history: '履歴',
    },
    title: '履歴',
    columns: {
        createdAt: '作成日時',
        picName: '担当者',
        activityTitle: '操作項目',
    },
    searchColumns: {
        // picName: '担当者',
        createdByEmail: '担当者（メール）',
        activityTitle: '操作項目',
    },
    detail: '詳細',
    noData: '表示するデータはありません',
    operationContents: '※操作内容',
    exportColumns: {
        createdAt: '日付',
        picName: '担当者',
        actionTitle: '操作項目',
    },
    activityTitle: {
        [`${AuditLogActions.UPDATE}${AuditLogModules.GENERAL_SETTING}`]: '一般設定更新',
        [`${AuditLogActions.CREATE}${AuditLogModules.ADMIN_NOTIFICATION}`]:
            '管理者通知作成',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.ADMIN_NOTIFICATION}`]:
            '管理者通知一括削除',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ADMIN_NOTIFICATION}`]:
            '管理者通知更新',
        [`${AuditLogActions.CREATE}${AuditLogModules.HOTEL}`]: 'ホテル作成',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.HOTEL}`]: 'ホテル一括更新',
        [`${AuditLogActions.UPDATE}${AuditLogModules.HOTEL}`]: 'ホテル更新',
    },
    filter: {
        CREATE: '作成',
        UPDATE: '更新',
        DELETE: '削除',
        CHECK_IN: 'チェックイン',
        CHECK_OUT: 'チェックアウト',
        CANCEL: 'キャンセル',
        [AuditLogModules.GENERAL_SETTING]: '一般設定',
        [AuditLogModules.ADMIN_NOTIFICATION]: '管理者通知',
        [AuditLogModules.HOTEL]: 'ホテル',
    },
};

export default historyList;
