import { Modal, Radio, RadioChangeEvent } from 'antd';
import customDayjs from '~plugins/dayjs';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IBodyResponse, IGetListResponse } from '~common/interfaces';
import {
    AccommodationCardPrintingCheckInOption,
    MAX_PRINT_GUEST_CARD,
    roomBookingListQueryDefault,
} from '~features/room-booking/constants';
import {
    IAdvanceSearchGetListQuery,
    IRoomBooking,
} from '~features/room-booking/interfaces';

import {
    fetchPrintRoomBookingList,
    isPrintRoomBookingListLoadingSelector,
    printRoomBookingListQuerySelector,
    setIsShowRoomBookingPrintingModal,
    setPrintingRoomBookingList,
    setPrintRoomBookingListQuery,
    setPdfFileName,
} from '~features/room-booking/reducers/print-booking.reducer';
import {
    isShowAccommodationCardPrintingModalSelector,
    setIsShowAccommodationCardPrintingModal,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './RoomBookingAccommodationCardPrintPopup.scss';
import { RoomBookingItemBookingStatus } from '~features/fo-operation-history/constants';
import { showConfirm } from '~common/notification';

export default function RoomBookingAccommodationCardPrintPopup() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const printRoomBookingListQuery = useAppSelector(printRoomBookingListQuerySelector);

    const isPrintRoomBookingListLoading = useAppSelector(
        isPrintRoomBookingListLoadingSelector,
    );

    const isShowAccommodationCardPrintingModal = useAppSelector(
        isShowAccommodationCardPrintingModalSelector,
    );
    const [
        accommodationCardPrintingCheckInOption,
        setAccommodationCardPrintingCheckInOption,
    ] = useState<AccommodationCardPrintingCheckInOption>(
        AccommodationCardPrintingCheckInOption.TODAY,
    );

    useEffect(() => {
        const period = accommodationCheckIn[accommodationCardPrintingCheckInOption];
        dispatch(
            setPrintRoomBookingListQuery({
                ...roomBookingListQueryDefault,
                ...(!isEmpty(period.checkInDayStartTime) && {
                    checkInDateTimePeriod: [
                        period.checkInDayStartTime,
                        period.checkInDayEndTime,
                    ],
                }),
                roomBookingItemStatus: [
                    RoomBookingItemBookingStatus.NOT_ARRIVED,
                    RoomBookingItemBookingStatus.CHECKED_IN,
                    RoomBookingItemBookingStatus.CHECKED_OUT,
                ],
                limit: MAX_PRINT_GUEST_CARD,
            }),
        );
    }, [accommodationCardPrintingCheckInOption]);

    const onChangeAccommodationCardPrintingCheckInOption = (e: RadioChangeEvent) => {
        setAccommodationCardPrintingCheckInOption(e.target.value);
    };

    const onCloseAccommodationCardPrintingModal = () => {
        dispatch(setIsShowAccommodationCardPrintingModal(false));
        setAccommodationCardPrintingCheckInOption(
            AccommodationCardPrintingCheckInOption.TODAY,
        );
    };

    const getAccommodationCheckInOption = (offsetDay: number) => ({
        checkInDayStartTime: customDayjs()
            .add(offsetDay, 'day')
            .startOf('day')
            .fmYYYYMMDDHHmmss(),
        checkInDayEndTime: customDayjs()
            .add(offsetDay, 'day')
            .endOf('day')
            .fmYYYYMMDDHHmmss(),
        date: +customDayjs().add(offsetDay, 'day').format('DD'),
        month: +customDayjs().add(offsetDay, 'day').format('MM'),
    });

    const accommodationCheckIn = {
        [AccommodationCardPrintingCheckInOption.TODAY]: getAccommodationCheckInOption(0),
        [AccommodationCardPrintingCheckInOption.TOMORROW]:
            getAccommodationCheckInOption(1),
        [AccommodationCardPrintingCheckInOption.THE_DAY_AFTER_TOMORROW]:
            getAccommodationCheckInOption(2),
        [AccommodationCardPrintingCheckInOption.EMPTY_CARD]: {
            checkInDayStartTime: '',
            checkInDayEndTime: '',
            date: '',
            month: '',
        },
    };

    const countGuestCards = (roomBookingList: IRoomBooking[]) => {
        return roomBookingList.reduce((totalGuestCards, booking) => {
            totalGuestCards += booking.roomBookingItems?.length || 0;
            return totalGuestCards;
        }, 0);
    };

    const setDataForPrintScreen = () => {
        dispatch(setIsShowAccommodationCardPrintingModal(false));
        dispatch(setIsShowRoomBookingPrintingModal(true));
        setAccommodationCardPrintingCheckInOption(
            AccommodationCardPrintingCheckInOption.TODAY,
        );
        dispatch(
            setPdfFileName(
                `${t(
                    `roomBooking.detail.accommodationCardPrinting.modal.${accommodationCardPrintingCheckInOption}FirstPart`,
                )}${t(
                    `roomBooking.detail.accommodationCardPrinting.modal.${accommodationCardPrintingCheckInOption}SecondPart`,
                    {
                        month: accommodationCheckIn[
                            accommodationCardPrintingCheckInOption
                        ].month,
                        date: accommodationCheckIn[accommodationCardPrintingCheckInOption]
                            .date,
                    },
                )}`,
            ),
        );
    };

    const openPrintScreen = async () => {
        const response = await fetchData();
        const roomBookingList = (
            response?.payload as IBodyResponse<IGetListResponse<IRoomBooking>>
        )?.data?.items;
        if (!!response && !roomBookingList?.length) {
            Modal.error({
                content: t('roomBooking.printing.notification.noSuchRoomBooking'),
                okText: t('common.ok'),
            });
            return;
        }
        const totalGuestCards = countGuestCards(roomBookingList || []);
        if (totalGuestCards >= MAX_PRINT_GUEST_CARD) {
            showConfirm({
                title: t('roomBooking.printing.notification.biggerGuestCards', {
                    totalBookings: totalGuestCards,
                }),
                okText: t('common.ok'),
                cancelText: t('common.cancel'),
                onOk() {
                    setDataForPrintScreen();
                },
            });
            return;
        }
        setDataForPrintScreen();
    };

    const fetchData = async () => {
        if (
            (printRoomBookingListQuery as IAdvanceSearchGetListQuery)
                ?.checkInDateTimePeriod
        ) {
            return await dispatch(fetchPrintRoomBookingList(printRoomBookingListQuery));
        } else {
            await dispatch(setPrintingRoomBookingList([]));
            return undefined;
        }
    };

    return (
        <Modal
            className="accommodation-card-printing-modal"
            title={`${t('roomBooking.detail.accommodationCardPrinting.modal.title')}`}
            cancelText={`${t(
                'roomBooking.detail.accommodationCardPrinting.modal.cancel',
            )}`}
            okText={`${t('roomBooking.detail.accommodationCardPrinting.modal.ok')}`}
            open={isShowAccommodationCardPrintingModal}
            onOk={openPrintScreen}
            onCancel={onCloseAccommodationCardPrintingModal}
            confirmLoading={isPrintRoomBookingListLoading}
            width={572}
        >
            <Radio.Group
                className="check-in-option-radio-group"
                onChange={onChangeAccommodationCardPrintingCheckInOption}
                value={accommodationCardPrintingCheckInOption}
            >
                {Object.values(AccommodationCardPrintingCheckInOption).map(
                    (option: AccommodationCardPrintingCheckInOption) => {
                        return (
                            <Radio value={option} key={option}>
                                <span className="check-in-label bold">
                                    {t(
                                        `roomBooking.detail.accommodationCardPrinting.modal.${option}FirstPart`,
                                    )}
                                </span>
                                &nbsp;
                                <span className="check-in-label">
                                    {t(
                                        `roomBooking.detail.accommodationCardPrinting.modal.${option}SecondPart`,
                                        {
                                            month: accommodationCheckIn[option].month,
                                            date: accommodationCheckIn[option].date,
                                        },
                                    )}
                                </span>
                            </Radio>
                        );
                    },
                )}
            </Radio.Group>
        </Modal>
    );
}
