import React, { useEffect, useRef } from 'react';
import { getHotelListForDropdown } from '~features/hotel/hotel.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { Button, Space } from 'antd';
import { StaffLayoutHeader } from '~components';
import { useTranslation } from 'react-i18next';
import { notificationFormBreadcrumbs } from '~features/admin-notification/constants';
import { Footer, Content } from 'antd/lib/layout/layout';
import NotificationForm from './NotificationForm/NotificationForm';
import './NotificationFormWrapper.scss';
import { getTagListForDropdown } from '~features/tag/reducers/tag.reducer';
import { showLoadingSelector } from '~features/admin-notification/reducers/notification.reducer';

function NotificationFormWrapper() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showLoading = useAppSelector(showLoadingSelector);
    const notificationFormRef = useRef<{
        onCancelForm: Function;
        onSubmitForm: Function;
    }>(null);

    useEffect(() => {
        dispatch(getHotelListForDropdown({}));
        dispatch(getTagListForDropdown({}));
    }, []);

    const onClickButtonSubmit = () => {
        notificationFormRef?.current?.onSubmitForm();
    };

    const onClickButtonCancel = () => {
        notificationFormRef?.current?.onCancelForm();
    };
    return (
        <div className="notification-form-wrapper">
            <StaffLayoutHeader
                breadcrumbs={notificationFormBreadcrumbs()}
                title={t('notification.form.titlePage')}
            />
            <Content>
                <NotificationForm ref={notificationFormRef} />
            </Content>
            <Footer>
                <div className="notification-form-footer">
                    <Space align="end">
                        <Button onClick={onClickButtonCancel}>
                            {t('notification.form.cancelBtn')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={onClickButtonSubmit}
                            loading={showLoading}
                        >
                            {t('notification.form.submitBtn')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default NotificationFormWrapper;
