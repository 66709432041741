import {
    AuditLogActions,
    AuditLogModules,
} from '~features/bo-operation-history/constants';

const historyList = {
    breadcrumbs: {
        backOffice: 'Back Office Setting',
        history: 'History',
    },
    title: 'History',
    columns: {
        createdAt: 'Creation Time',
        picName: 'Created By',
        activityTitle: 'Operation Type',
    },
    searchColumns: {
        // picName: '担当者',
        createdByEmail: 'Created By (Email)',
        activityTitle: 'Operation Type',
    },
    detail: 'Description',
    noData: 'No data',
    operationContents: '※ Opereation Content',
    exportColumns: {
        createdAt: 'Creaetion Time',
        picName: 'Created By',
        actionTitle: 'Operation Type',
    },
    activityTitle: {
        [`${AuditLogActions.UPDATE}${AuditLogModules.GENERAL_SETTING}`]:
            'Update General Setting',
        [`${AuditLogActions.CREATE}${AuditLogModules.ADMIN_NOTIFICATION}`]:
            'Create Admin Notification',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.ADMIN_NOTIFICATION}`]:
            'Bulk Delete Admin Notification',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ADMIN_NOTIFICATION}`]:
            'Update Admin Notification',
        [`${AuditLogActions.CREATE}${AuditLogModules.HOTEL}`]: 'Create Hotel',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.HOTEL}`]: 'Bulk Delete Hotel',
        [`${AuditLogActions.UPDATE}${AuditLogModules.HOTEL}`]: 'Update Hotel',
    },
    filter: {
        CREATE: 'Create',
        UPDATE: 'Update',
        DELETE: 'Delete',
        CHECK_IN: 'Check in',
        CHECK_OUT: 'Check out',
        CANCEL: 'Cancel',
        [AuditLogModules.GENERAL_SETTING]: 'General Setting',
        [AuditLogModules.ADMIN_NOTIFICATION]: 'Admin Notification',
        [AuditLogModules.HOTEL]: 'Hotel',
    },
};

export default historyList;
