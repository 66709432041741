export const bookingPageEn = {
    reservation: {
        title: 'Booking overview',
        contentTitle: 'Enter guest information',
        guestInfo: 'Guest information',
        memo: 'Memo',
        totalNumberOfReservations: 'Total number of bookings',
        totalIncludeTax: 'Total (tax included)',
        represent: '(representative)',
        yomigana: 'Tanaka Mada',
    },
    tab: {
        guserInformation: 'Basic information',
        conpanionInformation: 'Accompanion information',
    },
    guestInfo: {
        infomation: 'Guest information',
        representative: '(representative)',
        guestNameYomigana: 'Guest name (yomigana)',
        guestName: 'Guest name',
        reserverGuestNameYomigana: 'Reserver name (yomigana)',
        reserverGuestName: 'Reserver name',
        phone: 'Phone number',
        email: 'Email address',
        whoPay: 'Payer',
        person: 'Reserver',
        reservationPeron: 'Reserver',
        stayInfo: 'Information of stay',
        checkIn: 'Check in',
        checkOut: 'Check out',
        salesChannel: 'Marketing channel',
    },
    bookingStatus: {
        all: 'All',
        checked_in: 'Checked in',
        not_arrived: 'Reserved',
        checked_out: 'Checked out',
        cancelled: 'Cancelled',
        booked: 'Booked',
        cancelled_no_show_true: 'Cancelled (no show only)',
        cancelled_no_show_false: 'Cancelled (excluding no show)',
    },
    bookingCleaningStatus: {
        cleaned: 'Clean',
        uncleaned: 'Unclean',
    },
    breadcrumbs: {
        home: 'Home',
        schedule: 'Room booking',
        createBooking: 'Booking overview',
        updateBooking: 'Edit booking',
        bookingConfirm: 'Confirm booking',
    },
    noShow: {
        label: 'No show',
    },
    splipBookingProtected: {
        reason: 'Please resolve overlapped booking dates first to split booking',
    },
};
