const roomForm = {
    edit: {
        title: 'Edit room',
        idCol: 'ID',
        roomNameCol: 'Room name',
        roomTypeCol: 'Room type',
        success: 'Update room success',
        error: 'Update room error',
    },
    importTitle: 'Upload CSV',
    title: 'Connecting room setting',
    connectingRoomType: 'Room type',
    connectingRoomName: 'Connecting room name',
    roomName: 'Room name',
    roomType: 'Room type',
    familyType: 'Family type',
    create: {
        error: 'Create room error',
        success: 'Create room success',
    },
    update: {
        error: 'Update room error',
        success: 'Update room success',
    },
    placeholder: {
        familyType: 'Select room type',
        connectingRoomName: 'Ex: 101-C',
    },
    import: {
        error: {
            name: {
                required: 'Enter room name',
                maxLength: 'Room name must be at most 30 characters',
                uniqueError: 'Room name must be unique',
                hasNoSpecialCharacters: 'Room name must contain no special characters',
            },
            roomTypeName: {
                required: 'Enter room type',
                maxLength: 'Room type must be at most 30 characters',
                hasNoSpecialCharacters: 'Room type must contain no special characters',
            },
            relatedRoomNames: {
                maxLength: 'Connecting room name must be at most 30 characters',
            },
        },
    },
};

export default roomForm;
