import { Button, Form, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { useTranslation } from 'react-i18next';
import { InputText, SingleSelect } from '~components';
import { IFacilitiesBulkCreateItem } from '~features/facility/interfaces';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import './CreateFacilitiesFormFacilityList.scss';
import {
    CREATE_FACILITIES_FORM_HEIGHT,
    FacilityTableColumn,
    MAX_FACILITY_ITEMS,
} from '~features/facility/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    createFacilityListSelector,
    setCreateFacilityList,
} from '~features/facility/facility.reducer';
import { facilityTypeDropDownOptionsSelector } from '~features/facility-type/facility-type.reducer';
interface IProp {
    control: any;
    currentFacilityList: IFacilitiesBulkCreateItem[];
}

function CreateFacilitiesFormFacilityList(props: IProp) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const createFacilityList = useAppSelector(createFacilityListSelector);
    const facilityTypeOptions = useAppSelector(facilityTypeDropDownOptionsSelector);

    const onClickButtonAdd = () => {
        dispatch(
            setCreateFacilityList([
                ...props.currentFacilityList,
                {
                    name: '',
                    facilityTypeId: null,
                    feId: `${Date.now()}_${createFacilityList.length}`,
                },
            ]),
        );
    };

    const onClickButtonRemove = (facility: IFacilitiesBulkCreateItem) => {
        dispatch(
            setCreateFacilityList(
                props.currentFacilityList.filter((f) => f.feId !== facility.feId),
            ),
        );
    };

    return (
        <div className="facilities-form-facility-list-wrapper">
            <Form layout="vertical">
                <Table
                    dataSource={createFacilityList}
                    pagination={false}
                    locale={{
                        emptyText: <div></div>,
                    }}
                    rowKey="feId"
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={onClickButtonAdd}
                            className="button-add"
                            disabled={createFacilityList.length >= MAX_FACILITY_ITEMS}
                        >
                            <PlusCircleOutlined className="right-register-form-icon" />
                            <span>{t('facility.create.buttons.addFacilities')}</span>
                        </Button>
                    )}
                    scroll={{ y: CREATE_FACILITIES_FORM_HEIGHT }}
                >
                    <Column
                        align="left"
                        width={70}
                        title={`${t('facility.create.columns.id')}`}
                        key={FacilityTableColumn.ID}
                        render={(facility: IFacilitiesBulkCreateItem, item, index) => {
                            return <div className="individual-id">{index + 1}</div>;
                        }}
                    />
                    <Column
                        title={`${t('facility.create.columns.facilityName')}`}
                        key={FacilityTableColumn.NAME}
                        render={(facility: IFacilitiesBulkCreateItem, item, index) => {
                            return (
                                <InputText
                                    label=""
                                    placeholder={t(
                                        'facility.create.columns.facilityName',
                                    )}
                                    name={`items.${index}.name`}
                                    control={props.control}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('facility.create.columns.facilityType')}`}
                        key={FacilityTableColumn.FACILITY_TYPE}
                        width="35%"
                        ellipsis={true}
                        render={(facility: IFacilitiesBulkCreateItem, item, index) => {
                            return (
                                <SingleSelect
                                    label=""
                                    placeholder={t(
                                        'facility.create.columns.facilityType',
                                    )}
                                    allowClear
                                    name={`items.${index}.facilityTypeId`}
                                    control={props.control}
                                    options={facilityTypeOptions}
                                />
                            );
                        }}
                    />

                    <Column
                        title={`${t('facility.create.columns.action')}`}
                        align="center"
                        width={80}
                        key={FacilityTableColumn.ACTION}
                        render={(facility: IFacilitiesBulkCreateItem) => {
                            return (
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    onClick={() => onClickButtonRemove(facility)}
                                />
                            );
                        }}
                    />
                </Table>
            </Form>
        </div>
    );
}

export default CreateFacilitiesFormFacilityList;
