const splitReceipt = {
    home: 'Home',
    roomBookingList: 'Booking list',
    splitReceipt: 'Receipt splitting',
    title: 'Receipt splitting',
    titleInvoice: 'Invoice splitting',
    room: 'Room',
    group: 'Group',
    roomItem: 'Accommodation',
    facilityItem: 'Facility',
    splitGroup: 'Split group',
    all: 'All',
    notificationAlert: {
        warning:
            'You can edit the number of splits and the details mentioned on the receipt. However, editing the receipt will not change the contents of the Details tab.',
        notSplitDone: 'Please assign room numbers before printing',
    },
    files: {
        listError: 'Failed to retrieve receipt issue history.',
        countError: 'Failed to retrieve receipt issue number.',
        uploadLoading: 'Saving receipt file to receipt issue history...',
        uploadSuccess: 'Receipt issue successfully saved.',
        uploadError: 'Failed to save receipt issue.',
    },
    selectSplitBillTypePanel: {
        placeholder: 'Select a number',
        title: 'Receipt splitting',
        divisionType: 'Division type',
        split: 'Split',
        basedOnRoom: 'By room',
        basedOnGroup: 'By group',
        confirmationText:
            'Would you like to change the selected item to the group {{name}}?',
        buttonConfirmText: 'Yes',
    },
    billToTab: {
        basedOnRoomTitle: 'Split by room',
        basedOnGroupTitle: 'Split by group',
        printAll: 'Print all',
        issueHistory: 'Receipt issue history',
        invoiceIssueHistory: 'Invoice issue history',
        cannotPrintCancelledBooking: "Cancelled booking receipt can't be printed",
        issueHistoryTable: {
            createDate: 'Creation date',
            number: 'Receipt #',
            name: 'Receipt name',
            total: 'Total',
            downloadPdf: 'Download PDF',
        },
        item: {
            title: 'Group 1',
            print: 'Print',
            billName: 'Recipient',
            billNameSubText: 'Mr./Ms.',
            billProviso: 'Disclaimer',
            billProvisoSubText: 'as',
            billTotal: 'Total',
        },
    },
    receiptDetailTab: {
        title: 'Receipt detail',
        invoiceTitle: 'Invoice detail',
        selectGroup: 'Change split group',
        item: {
            receiptTableColumn: {
                date: 'Date',
                bookingDetails: 'Detail',
                guestName: 'Guest name',
                roomName: 'Room name',
                unitPrice: 'Unit price',
                quantity: 'Quantity',
                amount: 'Amount',
                checkOutRoom: 'Settlement room',
                paymentMethod: 'Payment method',
            },
        },
        bookingDetailNoRoom: 'Accommodation fee (Adults:{{numberOfAdults}})',
        bookingDetailNoChildren:
            'Accommodation fee ({{roomName}}/adult:{{numberOfAdults}})',
        bookingDetailChildren:
            'Accommodation fee ({{roomName}}/{{childrenTypeName}}:{{numberOfAdults}})',
        bookingDetailChildrenNoRoom:
            'Accommodation fee ({{childrenTypeName}}:{{numberOfAdults}})',
    },
    bottomButtonGroup: {
        buttonReturnText: 'Back',
    },
    print: {
        issueDate: 'Issue date',
        receiptNumber: 'Receipt number',
        receipt: 'Receipt',
        invoiceNumber: 'Invoice number',
        invoice: 'Invoice',
        registeredNumber: 'Registered number',
        nameSubText: 'Mr./Ms.',
        amount: 'Amount',
        invoiceAmount: 'Amount',
        tax: 'Included consumption tax',
        provisoSubText: '但',
        stampPlaceholder: 'Revenue stamp',
        reservationNumber: 'Booking ID',
        stayPeriod: 'Duration of stay',
        dateText: 'Date',
        reservation: 'RESERVATION #',
        bookingDetails: 'Statement of use',
        paymentDetails: 'Statement of payment',
        roomPrice: 'Room price',
        reducedSalesTaxRate: 'Subject to reduced sales tax',
        target: 'Subject to',
        taxAmount: 'Sales tax amount',
        otherTaxAmount: 'Other tax amount',
        totalTaxAmount: 'Sales tax amount',
        table: {
            bookingDate: 'Date',
            bookingDetail: 'Details',
            unitPrice: 'Unit price',
            quantity: 'Quantity',
            amount: 'Amount',
            subtotal: 'Subtotal',
            total: 'Total',
        },
        prepaidAmount: 'Prepaid amount',
        paidAmount: 'Paid amount',
        taxBreakDown: 'Tax breakdown',
        bankAccounts: 'Bank accounts',
        bank: 'Bank',
        branch: 'Branch',
        accountNumber: 'Account number',
        accountOwner: 'Account owner',
        bankAccountNote: 'Please bear the bank transfer fee.',
    },
};

export default splitReceipt;
