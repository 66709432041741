import { InputNumber } from '~components';
import './InputAdult.scss';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Gender } from '~features/guest/constants';
import { useWatch } from 'react-hook-form';

type Props = {
    label: string;
    control: any;
    setValue: any;
    isHorizontal?: boolean;
    isHideLabel?: boolean;
    className?: string;
    required?: boolean;
    totalFieldId?: string;
    fieldName: Record<string, string>;
    autoSetTotalGuest?: boolean;
    onchange?: (
        numberOfAdult?: number,
        genderBreakdown?: { total: number; male: number; female: number; other: number },
    ) => void;
};

function InputAdult(props: Props) {
    const {
        label,
        control,
        setValue,
        isHorizontal,
        className,
        required,
        totalFieldId,
        isHideLabel,
        fieldName,
        autoSetTotalGuest,
        onchange,
    } = props;
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const numberOfMale = useWatch({
        control: control,
        name: fieldName.male,
    });
    const numberOfFemale = useWatch({
        control: control,
        name: fieldName.female,
    });
    const numberOfOther = useWatch({
        control: control,
        name: fieldName.other,
    });

    useEffect(() => {
        if (!autoSetTotalGuest) return;
        if (!numberOfMale && !numberOfFemale && !numberOfOther) {
            return;
        }
        const totalGuest =
            Number(numberOfMale || 0) +
            Number(numberOfFemale || 0) +
            Number(numberOfOther || 0);
        setValue(fieldName.adult, totalGuest, { shouldValidate: true });
        onchange?.(totalGuest, {
            total: totalGuest,
            male: numberOfMale,
            female: numberOfFemale,
            other: numberOfOther,
        });
    }, [
        numberOfMale,
        numberOfFemale,
        numberOfOther,
        setValue,
        fieldName,
        autoSetTotalGuest,
    ]);

    return (
        <div className={classNames('input-adult-wrapper', className)}>
            <div className="input-total-adult d-flex">
                {!isHideLabel && isHorizontal && (
                    <div className="input-total-adult-label">{label}</div>
                )}
                <InputNumber
                    label={!isHorizontal ? label : ''}
                    placeholder={t('roomBooking.form.placeholder.adults')}
                    name={fieldName.adult}
                    control={control}
                    required={required}
                    id={totalFieldId}
                    onChange={(e) => {
                        onchange?.(Number(e.target?.value), {
                            total: Number(e.target?.value),
                            male: numberOfMale,
                            female: numberOfFemale,
                            other: numberOfOther,
                        });
                    }}
                />
            </div>
            <div
                className={classNames('button-overlay', {
                    'top-0': isHorizontal,
                })}
                onClick={() => {
                    setOpen(!isOpen);
                }}
            >
                <DownOutlined
                    className={classNames({
                        'expand-icon': true,
                        active: isOpen,
                        deactive: !isOpen,
                    })}
                />
            </div>
            <div
                className={classNames('input-gender-wrapper', {
                    open: isOpen,
                    close: !isOpen,
                })}
            >
                {Object.values(Gender).map((val) => {
                    return (
                        <div key={val} className="input-gender-row d-flex">
                            <div className="text-truncate gender-input-label">
                                {t(`common.gender.` + val)}
                            </div>
                            <InputNumber
                                label={''}
                                placeholder={t('roomBooking.form.placeholder.adults')}
                                name={fieldName[val]}
                                control={control}
                                required={required}
                                isShowIconArrow={true}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default InputAdult;
