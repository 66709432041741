const childrenTypeList = {
    title: '子供区分設定',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        childrenType: '子供区分設定',
    },
    filterForm: {
        buttons: {
            create: '子供区分を登録',
        },
    },
    yen: '￥{{value}}',
    columns: {
        id: '並び順',
        name: '子供区分名',
        priceValue: '大人１名あたりの料金',
        linkageCode: '連携コード',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: '並び順',
        name: '子供区分名',
        linkageCode: '連携コード',
    },
    pricePerAdult: `大人１名あたり料金の{{value}}%`,
    delete: {
        confirm: '子供区分を削除してもよろしいでしょうか？',
        siteControllerError: 'サイトコントローラー向けの子供区分は削除できません。',
        success: '削除しました。',
        failed: '子供区分削除失敗',
    },
};

export default childrenTypeList;
