const planDetail = {
    title: 'プラン詳細',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        plan: 'プラン管理',
        planDetail: 'プラン詳細',
    },
    editButton: '編集',
    tab1: {
        title: 'プラン概要',
        planOverview: {
            title: 'プラン概要',
            name: '料金カレンダー',
            detail: 'プラン詳細',
            planType: 'プランタイプ',
            taxId: '税設定',
            checkInTime: 'チェックイン',
            checkOutTime: 'チェックアウト',
            cleaningFrequency: '連泊中の清掃頻度',
            cleaningFreqOption: {
                '0': 'チェックアウト時のみ',
                '1': '毎日 + チェックアウト',
                '2': '2日目 + チェックアウト',
                '3': '3日目 + チェックアウト',
                '4': '4日目 + チェックアウト',
                '5': '5日目 + チェックアウト',
                '6': '6日目 + チェックアウト',
                '7': '7日目 + チェックアウト',
            },
        },
        saleItem: {
            title: '項目設定',
            detailTitle: 'プランに含まれるもの',
        },
    },
    tab2: {
        title: '販売管理・料金設定',
        rankCalendarAndRoomType: {
            title: '販売管理・料金設定',
            rankCalendar: '料金カレンダー',
            roomType: '販売する部屋タイプ',
        },
        planRoomTypePricing: {
            title: 'プラン設定',
            planGroup: 'プラングループコード',
            people: '料金設定',
            planGroupColumn: {
                code: 'プラングループコード',
                codeOTA: 'OTAプランコード',
                nameOTA: 'OTAプラン名',
            },
            numberOfPeople: '人数',
            person: '人',
            manJa: '円',
        },
    },
    planListColumn: {
        id: 'ID',
        planName: 'プラン名',
        planType: 'タイプ',
    },
    confirm: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
        cancelTitle: 'キャンセルしてもよろしいですか？',
    },
    message: {
        deleteSuccess: 'プランが削除されました',
    },
};

export default planDetail;
