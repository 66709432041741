const marketingChannelForm = {
    primaryButton: '販売チャネルを登録',
    createForm: {
        title: '販売チャネルの登録',
        submitBtn: '保存',
        quantity: '数量',
        cancelBtn: 'キャンセル',
        addMarketingChannel: '行を追加',
        id: '#',
        generateMarketingChannelsBtn: '適用する',
        marketingChannelGroupId: '販売チャネルグループ名',
        marketingChannelNameDuplicated: '販売チャネル名が重複しています',
        marketingChannelCodeDuplicated: '販売チャネルのリンケージコードは重複しています',
        marketingChannelCodeDayUseDuplicated:
            '販売チャネルのデイユースコードが重複しています',
        marketingChannelName: '販売チャネル名',
        marketingChannelGroupName: '販売チャネルグループ名',
        linkageCode: '連携コード',
        linkageCodeDayUse: '連携コード（デイユース）',
        delete: '削除',
        createSuccessMessage: '販売チャネルが作成されました',
        placeholder: {
            linkageCode: 'コード',
            linkageCodeDayUse: 'コード',
            marketingChannelName: '販売チャネル名を入力してください',
            marketingChannelGroupName: 'グループを選択',
        },
    },
    updateForm: {
        title: '販売チャネルの編集',
        submitBtn: '保存',
        cancelBtn: 'キャンセル',
        marketingChannelGroupName: '販売チャネルグループ名',
        marketingChannelName: '販売チャネル名',
        linkageCode: '連携コード',
        updateSuccessMessage: '販売チャネルがこうしんされました',
        marketingChannelNameDuplicated: '販売チャネル名が重複しています',
    },
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
    },
    confirm: {
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
        cancelTitle: 'キャンセルしてもよろしいですか？',
    },
};
export default marketingChannelForm;
