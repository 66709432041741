export default {
    reservationContact: 'Reserver contact information',
    totalPrice: 'Total (tax included)',
    subtotal: 'Subtotal',
    create: {
        success: 'Created successfully',
        error: 'Failed to create',
    },
    lengthOfStay: 'Duration of stay',
    adult: 'Adults',
    codomo: 'Chidlren',
    reservationPerson: 'Reserver',
    guest: 'Guest',
    sameGuest: 'Same information as guest',
    marketingChannel: 'Marketing channel',
    representative: '(Representative)',
    representativeRoom: '(Representative)',
    totalTax: 'Included taxes',
    numberOfPeople: '{{adult}} adults {{children}} children',
};
