import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_ORDER_DIRECTION,
    DEFAULT_LIMIT_FOR_PAGINATION,
} from '../../common/constants';
import { cloneDeep, trim } from 'lodash';
import { OrderBy, SearchField, initGuestClassificationListQuery } from './constants';
import {
    IGuestClassificationState,
    IGuestClassificationGetListQuery,
    IGuestClassificationDropDownQuery,
    IGuestClassificationCreateBody,
    IGuestClassificationUpdateRequest,
} from './interfaces';
import { guestClassificationService } from './services/guest-classification.api';

const initialState: IGuestClassificationState = {
    guestClassificationListQuery: cloneDeep(initGuestClassificationListQuery),
    guestClassificationList: [],
    guestClassificationDropdownOptions: [],
    formBusy: false,
    showLoadingList: false,
    selectedGuestClassification: null,
    totalGuestClassifications: 0,
    searchField: SearchField.ALL,
    showForm: false,
};

export const fetchGuestClassificationList = createAsyncThunk(
    'guestClassification/fetchGuestClassificationList',
    async (_, { getState }) => {
        const query = guestClassificationListQuerySelector(getState() as AppState);
        return await guestClassificationService.getList(query);
    },
);

export const getGuestClassificationDetail = createAsyncThunk(
    'guestClassification/getGuestClassificationDetail',
    async (id: number) => {
        return await guestClassificationService.getDetail(id);
    },
);

export const getGuestClassificationListForDropdown = createAsyncThunk(
    'guestClassification/getGuestClassificationListForDropdown',
    async (params: IGuestClassificationDropDownQuery) => {
        return await guestClassificationService.getGuestClassificationListForDropdown(
            params,
        );
    },
);

export const createGuestClassification = createAsyncThunk(
    'guestClassification/createGuestClassification',
    async (formData: IGuestClassificationCreateBody) => {
        return await guestClassificationService.create(formData);
    },
);

export const updateGuestClassification = createAsyncThunk(
    'hotel/updateHotel',
    async (formData: IGuestClassificationUpdateRequest) => {
        return await guestClassificationService.updateGuestClassification(
            formData.id,
            formData.body,
        );
    },
);

export const guestClassificationSlice = createSlice({
    name: 'guestClassificationSlice',
    initialState,
    reducers: {
        setGuestClassificationListQuery: (state, action) => {
            state.guestClassificationListQuery = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IGuestClassificationGetListQuery = {
                limit: state.guestClassificationListQuery.limit,
                orderBy: state.guestClassificationListQuery.orderBy,
                orderDirection: state.guestClassificationListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.guestClassificationListQuery = query;
        },
        setGuestClassificationList: (state, action) => {
            state.guestClassificationList = action.payload;
        },
        setShowForm: (state, action) => {
            state.showForm = action.payload;
        },
        setSelectedGuestClassification: (state, action) => {
            state.selectedGuestClassification = action.payload;
        },
        setTotalGuestClassifications: (state, action) => {
            state.totalGuestClassifications = action.payload;
        },
        setGuestClassificationDropdownOptions: (state, action) => {
            state.guestClassificationDropdownOptions = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IGuestClassificationGetListQuery = {
                limit: state.guestClassificationListQuery.limit,
                orderBy: state.guestClassificationListQuery.orderBy,
                orderDirection: state.guestClassificationListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.guestClassificationListQuery.keyword ||
                    state.guestClassificationListQuery.autoGeneratedCode ||
                    state.guestClassificationListQuery.name ||
                    '',
            };
            state.guestClassificationListQuery = query;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGuestClassificationList.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.guestClassificationList = action.payload?.data?.items || [];
            state.totalGuestClassifications = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchGuestClassificationList.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(
            getGuestClassificationListForDropdown.fulfilled,
            (state, action) => {
                state.guestClassificationDropdownOptions =
                    action.payload?.data?.items || [];
            },
        );
        builder.addCase(getGuestClassificationDetail.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(getGuestClassificationDetail.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(createGuestClassification.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(createGuestClassification.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(updateGuestClassification.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(updateGuestClassification.pending, (state, action) => {
            state.formBusy = true;
        });
    },
});

export const {
    setGuestClassificationListQuery,
    setGuestClassificationList,
    setKeyword,
    setSearchField,
    setTotalGuestClassifications,
    setShowForm,
    setSelectedGuestClassification,
    setGuestClassificationDropdownOptions,
} = guestClassificationSlice.actions;

export const guestClassificationListQuerySelector = (state: AppState) => {
    return state.guestClassification.guestClassificationListQuery;
};

export const showLoadingListSelector = (state: AppState) => {
    return state.guestClassification.showLoadingList;
};

export const guestClassificationDropdownOptionsSelector = (state: AppState) => {
    return state.guestClassification.guestClassificationDropdownOptions;
};

export const guestClassificationListSelector = (state: AppState) => {
    return state.guestClassification.guestClassificationList;
};
export const showFormSelector = (state: AppState) => {
    return state.guestClassification.showForm;
};

export const formBusySelector = (state: AppState) => {
    return state.guestClassification.formBusy;
};

export const selectedGuestClassificationSelector = (state: AppState) => {
    return state.guestClassification.selectedGuestClassification;
};

export const totalGuestClassificationsSelector = (state: AppState) => {
    return state.guestClassification.totalGuestClassifications;
};

export const totalPageSelector = (state: AppState) => {
    const { totalGuestClassifications, guestClassificationListQuery } =
        state.guestClassification;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = guestClassificationListQuery;
    return Math.ceil(totalGuestClassifications / limit);
};

export default guestClassificationSlice.reducer;
