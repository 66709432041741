const localTaxEn = {
    title: 'Hot bath and accommodation tax summary',
    breadcrumbs: {
        report: 'Report',
        localTax: 'Hot bath and accommodation tax summary',
    },
    filterForm: {
        dateLabel: 'Collected month:',
        filterButton: 'Display',
        taxTypeLabel: 'Tax Category',
        taxTypeDropdownOptions: {
            tokyoLocalTax: 'Tokyo Local Tax',
            kutchanLocalTax: 'Kutchan Local Tax',
            bathTax: 'Hot Bath Tax',
        },
    },
    localTaxDetailColumn: {
        taxName: 'Accommodation tax',
        localTax1: 'Accommodation tax (Tokyo10000~)',
        localTax2: 'Accommodation tax (Tokyo15000~)',
        totalQuantity: 'Quantity',
        totalAmount: 'Amount',
        day: 'Day',
        currency: '¥',
    },
    kutchanTaxDetailColumn: {
        kutchanTaxHeader: 'Accommodation Tax (Kutchan-cho, Hokkaido)',
        numberOfGuests: 'Guests',
        totalBookingSales: 'Total booking sales',
        totalTaxableSales: 'Total taxable sales',
        totalTaxAmount: 'Total tax amount',
        totalKutchanTax: 'Total',
    },
    bathTaxDetailColumn: {
        bathTaxHeader: 'Hot bath tax',
        bathTax2Header: 'Hot bath (adult)',
        bathTax4Header: 'Hot bath (day-use)',
        quantity: 'Quantity',
        amount: 'Amount',
        day: 'Day',
        currency: '¥',
    },
};

export default localTaxEn;
