import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import { Upload, Button, notification, Tooltip } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { DeleteFilled } from '@ant-design/icons';
import localStorageAuthService from '~common/authStorage';
import {
    postCSVBookingUpload,
    csvBookingUploadSelector,
} from '../reducers/csv-booking-upload.reducer';
import { ICSVBookingUploadResponse, IStatusItems } from '../interfaces';
import { getErrorMessagesList, getErrorMessage } from '../helper';
import './CSVBookingUploadComponent.scss';

const CSVBookingUploadComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isCSVBookingUploadLoading } = useAppSelector(csvBookingUploadSelector);
    const hotel = localStorageAuthService.getSelectedHotel();
    const [listStatusMessages, setListStatusMessages] = useState<string[]>([]);
    const [fileToUpload, setFileToUpload] = useState<UploadFile | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(true);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [statusMessageSubtext, setStatusMessageSubtext] = useState<string | null>(null);
    const uploadProps: UploadProps = {
        accept: '.csv',
        beforeUpload: (file) => {
            if (file.type !== 'text/csv') {
                setListStatusMessages([
                    t('csvBookingUploadLocale.text.statusMessages.notCSVFileType'),
                ]);
                setUploadSuccess(false);
                setFileToUpload(null);
                setFileName(null);
                setErrorMessages([]);
            } else {
                setFileToUpload(file);
                setFileName(file.name);
                setListStatusMessages([]);
                setUploadSuccess(true);
                setErrorMessages([]);
            }
            setStatusMessageSubtext(null);
            return false;
        },
        maxCount: 1,
        showUploadList: false,
    };

    const handleDeleteFile = () => {
        setFileToUpload(null);
        setFileName(null);
    };

    const handleClickUpload = async () => {
        if (fileToUpload && hotel) {
            const formData = new FormData();
            formData.append('file', fileToUpload as RcFile);
            const uploadResponse = await dispatch(postCSVBookingUpload(formData));
            if (postCSVBookingUpload.fulfilled?.match(uploadResponse)) {
                if (uploadResponse.payload.success) {
                    const arrayData: ICSVBookingUploadResponse[] = [];
                    Object.entries(uploadResponse.payload).forEach(([key, value]) => {
                        if (key !== 'data' && key !== 'success') {
                            arrayData.push(value);
                        }
                    });
                    validateItems(arrayData);
                } else {
                    const statusText = getErrorMessage(uploadResponse.payload.message);
                    const displayedStatusText =
                        statusText.length !== 0
                            ? statusText
                            : [
                                  t(
                                      'csvBookingUploadLocale.text.statusMessages.generalError',
                                  ),
                              ];
                    setListStatusMessages(displayedStatusText);
                    setStatusMessageSubtext(null);
                    setUploadSuccess(false);
                }
                setFileToUpload(null);
                setFileName(null);
            }
        }
    };

    const validateItems = (arrayData: ICSVBookingUploadResponse[]) => {
        const errorItems: IStatusItems[] = [];
        arrayData.forEach((data) => {
            if (data.status === 'error') {
                const { originalGroupId, status, message, autoGeneratedCode } = data;
                errorItems.push({
                    originalGroupId,
                    status,
                    message,
                    autoGeneratedCode,
                });
            }
        });
        if (errorItems.length === 0) {
            notification.success({
                message: t('csvBookingUploadLocale.text.notifications.success'),
            });
            setUploadSuccess(true);
            setListStatusMessages([
                t('csvBookingUploadLocale.text.statusMessages.successStatus'),
            ]);
            setStatusMessageSubtext(
                t('csvBookingUploadLocale.text.statusMessages.successStatusSubtext', {
                    numberOfItems: arrayData.length,
                }),
            );
        } else {
            const errorMessagesList = getErrorMessagesList(errorItems);
            setErrorMessages(errorMessagesList);
        }
    };

    return (
        <div className="csv-booking-upload_wrapper">
            <div className="file-upload_container">
                <Upload {...uploadProps}>
                    <Button className="select-button" type="primary">
                        {t('csvBookingUploadLocale.text.buttons.uploadFile')}
                    </Button>
                </Upload>
                {fileToUpload !== null && (
                    <div className="file-upload_item">
                        <Tooltip title={fileName}>
                            <span className="file-upload_name">{fileName}</span>
                        </Tooltip>
                        <Button
                            className="delete-button"
                            type="text"
                            icon={<DeleteFilled style={{ fontSize: 26, width: 24 }} />}
                            onClick={handleDeleteFile}
                        />
                    </div>
                )}
            </div>
            <Button
                className="submit-button"
                type="primary"
                onClick={handleClickUpload}
                disabled={fileToUpload === null}
                loading={isCSVBookingUploadLoading}
            >
                {t('csvBookingUploadLocale.text.buttons.submit')}
            </Button>
            {errorMessages.length > 0 ? (
                <div>
                    {errorMessages.map((errorMessage, index) => (
                        <div className="file-upload_error" key={index}>
                            {errorMessage}
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    className={
                        uploadSuccess ? 'file-upload_success' : 'file-upload_error'
                    }
                >
                    {listStatusMessages.length !== 0 &&
                        listStatusMessages.map((arrayItem, index) => (
                            <div key={index}>{arrayItem}</div>
                        ))}
                    <div>{statusMessageSubtext}</div>
                </div>
            )}
        </div>
    );
};

export default CSVBookingUploadComponent;
