import { scroller } from 'react-scroll';

export function scrollToClass(className: string) {
    scroller.scrollTo(className, {
        duration: 300,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'staff-layout-content',
        offset: -200,
    });
}
