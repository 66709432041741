const bookingForm = {
    title: '予約詳細',
    reservationForm: {
        title: '仮予約',
        cancelBtn: '戻る',
        nextBtn: '次へ',
        addBtn: '予約を追加',
        fixedPrice: '固定価格',
        guestName: '宿泊者名前 (代表)',
        salesChannel: '販売チャネル',
        salesChannelPlaceholder: '販売チャネルを選択',
        stayTime: '滞在期間',
        checkInTime: 'チェックイン',

        roomInfo: {
            title: '部屋',
            roomType: '部屋タイプ',
            planType: 'プランタイプ',
            adultPerson: '大人',
            childPerson: '子ども',
        },
    },
    scheduleBooking: {
        createTitle: '新規予約の作成',
        updateTitle: '新規予約を編集',
        plan: 'プラン',
        adult: '大人',
        child: '子供',
        lengthOfStay: '滞在期間',
        dayUse: 'デイユース',
        roomType: '部屋タイプ',
        roomName: '部屋名',
        submitButton: '作成',
        caveButton: '更新',
        subTotalTitle: '料金',
    },
    placeholder: {
        phone: '例：0801239090',
        name: '例：山田　太郎',
        nameYomigana: '例：やまだ　たろう',
        email: '例：yamada@example.com',
        saleChannel: '販売チャネルを選択',
        lengthOfStay: {
            start: '開始日',
            end: '終了日',
        },
        checkInTime: 'チェックイン時間',
        checkOutTime: 'チェックアウト時間',
        roomName: '部屋を選択',
        planName: 'プランを選択',
        roomType: '部屋のタイプを選択',
        adults: '0',
        birthday: '年/月/日',
        gender: '性別を選択',
        roomId: '部屋名を選択',
        startDateOfStay: '開始日',
        endDateOfStay: '終了日',
    },
    message: {
        requiredAdult: '大人が必要です',
        exceedPeople: '収容人数を超えています',
        time24hPeriodError: '0時間以上24時間未満の時間を選択してください',
        checkInTimeError: 'チェックイン時刻が2日前より早くなります',
        checkInDateTimeError:
            'チェックイン時刻はチェックアウト時刻以前でないといけません',
        datePeriodError: '最終日は開始日以降でないといけません',
        startDateError: '2日前以降の開始日を選択してください',
        notCheckInOutTime:
            '標準チェックイン時刻と標準チェックアウト時刻がホテルの設定で決まっていません',
        genderBreakdownError: '総性別数は、各性別のゲスト数と等しくなければなりません',
        parentBookingItemShouldComeFirstError:
            '分割前の元となる親予約の日付が先である必要があります。分割された子予約の日付が親の日付より後になるように変更してください。',
        parentChildrenBookingItemsOverlappingError:
            '分割した予約の期間が重複しています。重複を解消してください。',
    },
    popupCancel: {
        cancelTitle: 'キャンセルしてもよろしいですか？',
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
    },
    planInvalidPrice:
        '選択したプラン・人数に料金が設定されていないため、滞在を作成できませんでした',
    uploadFile: {
        message: {
            acceptTypeError: 'Only allow the following formats: {{format}}',
            maxCount: 'Maximum number of attachments per guest: {{maxCount}}',
            roomBookingDataNotJSON: 'Room booking data is not JSON type',
            roomBookingDataRequire: 'Room booking data is required field',
        },
    },
    overbooking: {
        title: '現在、十分な在庫がありません。オーバーブッキングとなる可能性がありますが、続行しますか？',
        warningResponse: '空室がありません',
        warningTLLResponse: 'こちらの部屋のTLLは一時的に利用できません',
    },
};

export default bookingForm;
