import React, { useCallback, useEffect } from 'react';
import './CreateMarketingChannelGroupForm.scss';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { RightDrawerLayout, InputText } from '~components';
import { Form, Button, Col, Row, notification } from 'antd';

import {
    IMarketingChannelGroupBulkCreateFormItem,
    IMarketingChannelGroupBulkCreateForm,
} from '../../interfaces';
import { useAppSelector, useAppDispatch } from '~hooks';
import {
    fetchMarketingChannelGroupList,
    bulkCreateMarketingChannelGroups,
    setShowForm,
    showFormSelector,
    setCreateMarketingChannelGroupList,
    createMarketingChannelGroupListSelector,
    showLoadingSelector,
} from '../../marketing-channel-group.reducer';
import { bulkCreateMarketingChannelGroupsSchema } from '../../schema';
import { showConfirm } from '~common/notification';
import { useForm } from '~plugins/hook-form';
import { ErrorMessageType } from '~common/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateUniqueValue } from '~common/commonFunctions';
import { useEscape } from '~common/useHooks';

function CreateMarketingChannelGroupForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const marketingChannelGroupList = useAppSelector(
        createMarketingChannelGroupListSelector,
    );
    const { control, handleSubmit, setError, reset, getValues } =
        useForm<IMarketingChannelGroupBulkCreateForm>({
            resolver: yupResolver(bulkCreateMarketingChannelGroupsSchema),
            defaultValues: {},
        });
    const closeDialog = () => {
        dispatch(setShowForm(false));
        reset();
        dispatch(fetchMarketingChannelGroupList());
        dispatch(
            setCreateMarketingChannelGroupList([
                {
                    name: '',
                },
            ]),
        );
    };

    const _showConfirm = () => {
        showConfirm({
            title: t('marketingChannelGroup.form.confirm.cancelTitle'),
            cancelText: t('marketingChannelGroup.form.confirm.buttonCancelText'),
            okText: t('marketingChannelGroup.form.confirm.buttonDeleteText'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    // set MarketingChannelGroupList form when has any change
    useEffect(() => {
        reset({
            items: marketingChannelGroupList,
        });
    }, [marketingChannelGroupList]);

    const showForm = useAppSelector(showFormSelector);
    const showLoading = useAppSelector(showLoadingSelector);

    const _bulkCreateMarketingChannelGroups = useCallback(
        async (chanelGroups: IMarketingChannelGroupBulkCreateFormItem[]) => {
            const response = await dispatch(
                bulkCreateMarketingChannelGroups(
                    chanelGroups.map((channelGroup, index) => ({
                        name: channelGroup.name,
                        order: index + 1,
                    })),
                ),
            );

            if (bulkCreateMarketingChannelGroups.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('marketingChannelGroup.form.create.success'),
                    });
                    closeDialog();
                } else {
                    (response.payload?.errors || []).forEach((error) => {
                        if (error.key === 'name' && error.order) {
                            setError(
                                `items.${error.order - 1}.name`,
                                { type: ErrorMessageType.MANUAL, message: error.message },
                                { shouldFocus: true },
                            );
                        }
                    });
                    notification.error({
                        message: response.payload?.message || '',
                    });
                }
            }
        },
        [],
    );

    const onClickButtonRemove = (
        channelGroup: IMarketingChannelGroupBulkCreateFormItem,
    ) => {
        dispatch(
            setCreateMarketingChannelGroupList(
                marketingChannelGroupList.filter(
                    (chanelGroups) => chanelGroups.feId !== channelGroup.feId,
                ),
            ),
        );
    };

    const onSubmit = () => {
        handleSubmit(async (data) => {
            const newMarketingChannelGroups = getValues().items || [];
            if (!newMarketingChannelGroups.length) {
                return;
            }
            const duplicatedNameIndexs = validateUniqueValue(
                newMarketingChannelGroups,
                'name',
            );
            if (duplicatedNameIndexs.length) {
                duplicatedNameIndexs.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('marketingChannelGroup.form.nameDuplicated'),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkCreateMarketingChannelGroups(data.items);
        })();
    };

    return (
        <RightDrawerLayout
            open={showForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={t('marketingChannelGroup.form.title')}
            cancelText={t('common.buttonCancelText')}
            submitText={t('marketingChannelGroup.form.submitText')}
            className="create-marketing-channel-group-drawer"
            busy={showLoading}
            submitId="marketing-channel-group-form-submit"
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                {marketingChannelGroupList.map((group, index) => {
                    return (
                        <div className="marketing-channel-group-input-wrapper">
                            {marketingChannelGroupList.length === 1 && (
                                <Row
                                    key={group.feId}
                                    className="input-marketing-channel-group-item"
                                >
                                    <Col span={24}>
                                        <InputText
                                            label={t(
                                                'marketingChannelGroup.form.saleChannelGroupName',
                                            )}
                                            placeholder={t(
                                                'marketingChannelGroup.form.saleChannelGroupNamePlaceholder',
                                            )}
                                            id={`marketing-channel-group-form-item.${index}.name`}
                                            name={`items.${index}.name`}
                                            control={control}
                                        />
                                    </Col>
                                </Row>
                            )}
                            {marketingChannelGroupList.length > 1 && (
                                <Row>
                                    <Col span={23}>
                                        <InputText
                                            label={t(
                                                'marketingChannelGroup.form.saleChannelGroupName',
                                            )}
                                            placeholder={t(
                                                'marketingChannelGroup.form.saleChannelGroupNamePlaceholder',
                                            )}
                                            id={`marketing-channel-group-form-item.${index}.name`}
                                            name={`items.${index}.name`}
                                            control={control}
                                        />
                                    </Col>
                                    <Col span={1}>
                                        <Form.Item label=" " className="btn-delete">
                                            <Button
                                                type="text"
                                                id={`marketing-channel-group-form.btn-add-more`}
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                    onClickButtonRemove(group);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    );
                })}
            </Form>
        </RightDrawerLayout>
    );
}

export default CreateMarketingChannelGroupForm;
