import React from 'react';
import { Form, TimePicker } from 'antd';
import './TimePickerCustom.scss';
import { Controller } from '~plugins/hook-form';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import { parseErrorMessage } from '~common/commonFunctions';
import { Dayjs } from '~plugins/dayjs';

type ITimeRangePicker = {
    name: string;
    defaultValue?: (string | null | Dayjs)[];
    labelStart?: string;
    labelEnd?: string;
    control: any;
    required?: boolean;
    useDayjs?: boolean;
    tooltip?: LabelTooltipType;
    placeholder?: [string, string];
    onChange?: (value: Dayjs[] | null) => void;
};

export const TimeRangePickerCustom = (props: ITimeRangePicker) => {
    const {
        control,
        name,
        defaultValue,
        labelStart,
        labelEnd,
        onChange,
        required,
        tooltip,
        placeholder,
    } = props;

    return (
        <div className="time-picker-custom-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    return (
                        <Form.Item
                            label={
                                labelStart || labelEnd ? (
                                    <div className="time-picker-label">
                                        {!!labelStart && (
                                            <div className="time-picker-label-start">
                                                {labelStart}
                                            </div>
                                        )}
                                        {!!labelEnd && (
                                            <div className="time-picker-label-end">
                                                {labelEnd}
                                            </div>
                                        )}
                                    </div>
                                ) : null
                            }
                            validateStatus={fieldState.error ? 'error' : ''}
                            help={parseErrorMessage(fieldState.error?.message || '')}
                            tooltip={tooltip}
                            required={required}
                        >
                            <TimePicker.RangePicker
                                {...field}
                                placeholder={placeholder}
                                showSecond={false}
                                needConfirm={false}
                                onChange={(value) => {
                                    const validValue =
                                        value && value[0] && value[1]
                                            ? (value as [Dayjs, Dayjs])
                                            : null;

                                    if (onChange) {
                                        onChange(validValue);
                                    }
                                    field.onChange(validValue);
                                }}
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
};
