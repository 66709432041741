import React, { useEffect, useMemo, useState } from 'react';
import { ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { notification, Table, Pagination, Tag, Button, Card } from 'antd';
import type { TableProps } from 'antd/es/table';
import { AntdOrderDirection, OrderDirection } from '~common/constants';
import {
    OrderBy,
    NotificationListColumn,
    initNotificationListQuery,
} from '~features/admin-notification/constants';
import { useTranslation } from 'react-i18next';
import { notificationsService } from '~features/admin-notification/services/notification.service';
import {
    fetchNotificationList,
    notificationListQuerySelector,
    notificationListSelector,
    showLoadingSelector,
    totalPageSelector,
    totalNotificationsSelector,
    setNotificationListQuery,
    setSelectedNotification,
} from '~features/admin-notification/reducers/notification.reducer';
import { INotification } from '~features/admin-notification/interfaces';
import { useAppSelector, useAppDispatch } from '~hooks';
import { ModalConfirmDeletion } from '~components';
import { ISorter } from '~common/interfaces';
import { ColumnsType } from 'antd/lib/table';

import './NotificationList.scss';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';

function NotificationList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const notificationList = useAppSelector(notificationListSelector);
    const notificationListQuery = useAppSelector(notificationListQuerySelector);
    const pageCount = useAppSelector(totalPageSelector);
    const showLoading = useAppSelector(showLoadingSelector);
    const totalNotifications = useAppSelector(totalNotificationsSelector);

    const onChange: TableProps<INotification>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        dispatch(
            setNotificationListQuery({
                ...notificationListQuery,
                orderBy: order ? _field || OrderBy.ID : OrderBy.ID,
                orderDirection:
                    order === AntdOrderDirection.ASC
                        ? OrderDirection.ASC
                        : OrderDirection.DESC,
            }),
        );
    };

    const onRefresh = async () => {
        await fetchData();
        setSelectedRowKeys([]);
    };

    const onSelectChange = (_selectedRowKeys: React.Key[]) => {
        const notifications: INotification[] = [];
        _selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundNotification = notificationList.find(
                (notificationList: INotification) =>
                    notificationList.id === selectedRowKey,
            );
            if (foundNotification) notifications.push(foundNotification);
        });
        setSelectedRowKeys(_selectedRowKeys);
    };

    useEffect(() => {
        return () => {
            dispatch(setNotificationListQuery(cloneDeep(initNotificationListQuery)));
        };
    }, []);

    const fetchData = () => {
        dispatch(fetchNotificationList());
    };

    useEffect(() => {
        if (
            notificationList.length === 0 &&
            notificationListQuery?.page &&
            notificationListQuery?.page > 1
        ) {
            const page = notificationListQuery?.page - 1;
            dispatch(setNotificationListQuery({ ...notificationListQuery, page }));
        }
    }, [notificationList]);

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await notificationsService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('notification.list.messageDeleteSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [notificationListQuery]);

    const onChangePage = (page: number) => {
        dispatch(setNotificationListQuery({ ...notificationListQuery, page }));
    };

    const onClickOneRow = (record: INotification) => {
        return {
            onClick: () => {
                dispatch(setSelectedNotification(record));
                if (typeof record.id === 'number') {
                    navigate(`${record.id}/update`);
                }
            },
        };
    };

    const selectedRows = useMemo(() => {
        const rows: INotification[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundNotification = notificationList.find(
                (notification: INotification) => notification.id === selectedRowKey,
            );
            if (foundNotification) rows.push(foundNotification);
        });
        return rows;
    }, [selectedRowKeys, notificationList]);

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            buttonCancelText: t('notification.list.delete.buttonCancelText'),
            buttonDeleteText: t('notification.list.delete.buttonDeleteText'),
            okButtonProps: { danger: true },
            deletedItems: selectedRows.map((row) => row.title),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const NotificationColumns: ColumnsType<INotification> = [
        {
            title: t('notification.list.notificationListColumn.id'),
            width: '120px',
            onCell: () => {
                return {
                    style: { color: '#1c3e86' },
                };
            },
            render: (notification: INotification) => {
                return <a>{notification.autoGeneratedCode}</a>;
            },
            key: NotificationListColumn.ID,
            sorter: true,
        },
        {
            title: t('notification.list.notificationListColumn.hotelDesignation'),
            width: '300px',
            dataIndex: '',
            render: (notification: INotification) => {
                return (
                    <div>
                        <ul className="notification-item">
                            {notification.hotels?.map((hotel) => {
                                return <li key={hotel.name}>{hotel.name}</li>;
                            })}
                        </ul>
                    </div>
                );
            },
            key: NotificationListColumn.HOTEL,
        },
        {
            title: t('notification.list.notificationListColumn.title'),
            width: '300px',
            dataIndex: '',
            render: (notification: INotification) => {
                return (
                    <div className="notification-item-title">{notification.title}</div>
                );
            },
            key: NotificationListColumn.TITLE,
            sorter: true,
        },
        {
            title: t('notification.list.notificationListColumn.type'),
            width: '300px',
            dataIndex: '',
            render: (notification: INotification) => {
                return (
                    <div>
                        <ul className="notification-item">
                            {notification.tags?.map((item) => {
                                return (
                                    <li key={item.id} className="tag-notification-item">
                                        <Tag color="green" className="tag-name">
                                            {item.tag}
                                        </Tag>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            },
            key: NotificationListColumn.TAG,
        },
    ];
    return (
        <div className="notification-list-wrapper">
            <Card
                className="notification-card"
                title={
                    <div className="notification-list-header">
                        {selectedRows.length > 0 && (
                            <div className="list-header-left">
                                <Button
                                    type="text"
                                    className="btn-icon"
                                    onClick={showConfirmDialog}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )}

                        <div className="list-header-right">
                            <ReloadOutlined className="size-icon" onClick={onRefresh} />
                        </div>
                    </div>
                }
            >
                <Table
                    rowSelection={rowSelection}
                    columns={NotificationColumns}
                    dataSource={notificationList}
                    onChange={onChange}
                    pagination={false}
                    rowKey="id"
                    className="notification-list-table"
                    loading={showLoading}
                    scroll={{ y: 400 }}
                    onRow={onClickOneRow}
                />
            </Card>

            {pageCount > 1 && (
                <Pagination
                    align="center"
                    className="notification-list-pagination"
                    defaultCurrent={notificationListQuery.page}
                    current={notificationListQuery.page}
                    total={totalNotifications}
                    pageSize={notificationListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default NotificationList;
