import { CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useMemo, useState } from 'react';
import { MAX_ITEM_ERRORS } from '~features/sale-group/constants';
import { IImportError } from '~features/sale-group/interfaces';
import './ImportCsvError.scss';

type Prop = {
    errors: IImportError[];
    i18nErrorKey: string;
};
export function ImportCsvError(prop: Prop) {
    const { errors = [], i18nErrorKey } = prop;
    const { t } = useTranslation();
    const [isShowMore, setIsShowMore] = useState(false);

    const errorList = useMemo(() => {
        if (isShowMore) {
            return errors;
        }
        return errors.slice(0, MAX_ITEM_ERRORS);
    }, [errors, isShowMore]);

    return (
        <div className="import-csv-sale-group-error">
            <CloseCircleFilled className="import-csv-sale-group-error-icon" />
            <div className="import-csv-sale-group-error-content">
                <div className="import-csv-sale-group-error-title">
                    {t('common.importCsv.error.title', {
                        totalErrors: errors.length || 0,
                    })}
                </div>

                <div className="import-csv-sale-group-error-list">
                    {errorList?.map((item, index) => {
                        return (
                            <div key={index}>
                                {t('common.importCsv.error.message', {
                                    order: item.order,
                                    key: t(`${i18nErrorKey}.${item.key}`),
                                    message: item.message,
                                })}
                            </div>
                        );
                    })}
                </div>
                {errors.length > MAX_ITEM_ERRORS && (
                    <div
                        className="show-more-link"
                        onClick={() => {
                            setIsShowMore(!isShowMore);
                        }}
                    >
                        {!isShowMore
                            ? t('saleGroup.import.showMore', {
                                  errorNumber: errors.length - MAX_ITEM_ERRORS,
                              })
                            : t('saleGroup.import.showLess')}
                    </div>
                )}
            </div>
        </div>
    );
}
