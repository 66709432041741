import { Table } from 'antd';
import { ColumnGroupType, ColumnType, ColumnsType } from 'antd/lib/table';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormatMoney, FormatNumber } from '~common/helper';
import {
    ReportTableCsvFileName,
    ReportTableId,
    ShowMonth,
} from '~features/report/constants';
import { exportCSVReport } from '~features/report/helper';
import {
    salesByPlansSelector,
    setExportCsv,
} from '~features/report/reducers/sales-by-plans.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';

interface Props {
    dataSource: any[];
    loading: boolean;
    summaryData: {
        [x: string]:
            | {
                  [key: string]: number;
              }
            | {
                  [key: string]: number;
              }
            | {
                  [key: string]: number;
              };
    };
}
const { Cell: SummaryCell, Row: SummaryRow } = Table.Summary;

export function MonthlySalesByPlans({ dataSource, loading, summaryData }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isExportCsv } = useAppSelector(salesByPlansSelector);

    useEffect(() => {
        if (isExportCsv) {
            exportCSVReport(
                ReportTableId.SALES_BY_PLANS_MONTHLY,
                `${ReportTableCsvFileName.SALES_BY_PLANS_MONTHLY}.csv`,
            );
            dispatch(setExportCsv(false));
        }
    }, [isExportCsv, dispatch]);

    const renderChildrenColumns = (key: string) => {
        return [
            {
                title: t('report.salesByPlans.tableColumns.totalBookedRoom'),
                dataIndex: `${key}TotalBookedRoom`,
                key: `${key}TotalBookedRoom`,
                render: (data: any) => {
                    if (!data) return null;
                    const { current, previousYear } = data;
                    return (
                        <>
                            {FormatNumber.format(current)}{' '}
                            <span className="gray-color">
                                ({FormatNumber.format(previousYear)})
                            </span>
                        </>
                    );
                },
            },
            {
                title: t('report.salesByPlans.tableColumns.totalAdult'),
                dataIndex: `${key}TotalAdult`,
                key: `${key}TotalAdult`,
                render: (data: any) => {
                    if (!data) return null;
                    const { current, previousYear } = data;
                    return (
                        <>
                            {FormatNumber.format(current)} {''}
                            <span className="gray-color">
                                ({FormatNumber.format(previousYear)})
                            </span>
                        </>
                    );
                },
            },
            {
                title: t('report.salesByPlans.tableColumns.totalChild'),
                dataIndex: `${key}TotalChild`,
                key: `${key}TotalChild`,
                render: (data: any) => {
                    if (!data) return null;
                    const { current, previousYear } = data;
                    return (
                        <>
                            {FormatNumber.format(current)}{' '}
                            <span className="gray-color">
                                ({FormatNumber.format(previousYear)})
                            </span>
                        </>
                    );
                },
            },
            {
                title: t('report.salesByPlans.tableColumns.totalRevenue'),
                dataIndex: `${key}TotalRevenue`,
                key: `${key}TotalRevenue`,
                render: (data: any) => {
                    if (!data) return null;
                    const { current, previousYear } = data;
                    return (
                        <>
                            {FormatMoney.format(current)}
                            {''}
                            <span className="gray-color">
                                ({FormatMoney.format(previousYear)})
                            </span>
                        </>
                    );
                },
            },
            {
                title: t('report.salesByPlans.tableColumns.avgRevenue'),
                dataIndex: `${key}AvgRevenue`,
                key: `${key}AvgRevenue`,
                render: (data: any) => {
                    if (!data) return null;
                    const { current, previousYear } = data;
                    return (
                        <>
                            {FormatMoney.format(current)}
                            {''}
                            <span className="gray-color">
                                ({FormatMoney.format(previousYear)})
                            </span>
                        </>
                    );
                },
            },
        ];
    };

    const columns: ColumnsType<any> | ColumnGroupType<any> = [
        {
            title: t('report.salesByPlans.tableColumns.plan'),
            fixed: 'left',
            dataIndex: 'plan',
            key: 'plan',
            width: 200,
            render: (plan: string) => {
                return <div>{plan}</div>;
            },
        },
        ...Object.values(ShowMonth).map((month) => {
            return {
                title: t(`report.salesByCategory.salesByCategoryColumn.${month}`),
                className: 'header-cell',
                children: renderChildrenColumns(month),
            };
        }),
    ];

    const summaryColumns = columns
        .filter((column) => column.key !== 'plan')
        .map((column) => (column as ColumnGroupType<any>).children)
        .flat();

    const showSummaryNumber = (columnDataIndex: string) => {
        let total = 0;
        let previous = 0;
        dataSource?.forEach((item: any) => {
            if (!item[columnDataIndex]) return;
            const currentPlan = item[columnDataIndex];
            const { current, previousYear } = currentPlan;
            total += current;
            previous += previousYear;
        });
        return (
            <>
                {FormatNumber.format(total)} {''}
                <span className="gray-color">({FormatNumber.format(previous)})</span>
            </>
        );
    };

    const showSummaryAmount = (columnDataIndex: string) => {
        let total = 0;
        let previous = 0;
        dataSource?.forEach((item: any) => {
            if (!item[columnDataIndex]) return;
            const currentPlan = item[columnDataIndex];
            const { current, previousYear } = currentPlan;
            total += current;
            previous += previousYear;
        });
        return (
            <>
                {FormatMoney.format(total)} {''}
                <span className="gray-color">({FormatMoney.format(previous)})</span>
            </>
        );
    };

    const showSummaryAvgRevenue = (columnDataIndex: string) => {
        if (!summaryData || !summaryData[columnDataIndex]) return;
        const { current, previousYear } = summaryData[columnDataIndex];
        if (
            columnDataIndex.includes('AvgRevenue') ||
            columnDataIndex.includes('TotalRevenue')
        ) {
            return (
                <>
                    {FormatMoney.format(current)} {''}
                    <span className="gray-color">
                        ({FormatMoney.format(previousYear)})
                    </span>
                </>
            );
        } else {
            return (
                <>
                    {FormatNumber.format(current)} {''}
                    <span className="gray-color">
                        ({FormatNumber.format(previousYear)})
                    </span>
                </>
            );
        }
    };

    const renderSummaryCell = (column: ColumnGroupType<any> | ColumnType<any>) => {
        const columnDataIndex = (column as ColumnType<any>).dataIndex?.toString();

        const columnKey = column.key?.toString();

        if (
            columnKey?.includes('TotalBookedRoom') ||
            columnKey?.includes('TotalChild') ||
            columnKey?.includes('TotalAdult')
        ) {
            return showSummaryNumber(columnDataIndex!);
        }
        if (columnKey?.includes('TotalRevenue')) {
            return showSummaryAmount(columnDataIndex!);
        }

        if (columnKey?.includes('AvgRevenue')) {
            return showSummaryAvgRevenue(columnDataIndex!);
        }
    };
    const renderSummary = () => {
        return (
            <>
                <SummaryRow>
                    <SummaryCell index={0} colSpan={1}>
                        {t('report.salesByPlans.tableColumns.total')}
                    </SummaryCell>
                    {summaryColumns.map((column, index) => (
                        <SummaryCell key={index} index={index + 1}>
                            {renderSummaryCell(column)}
                        </SummaryCell>
                    ))}
                </SummaryRow>
            </>
        );
    };

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            pagination={false}
            bordered
            rowKey={'id'}
            scroll={{ x: true }}
            className="sales-by-plans-table table-scrollbar"
            id={ReportTableId.SALES_BY_PLANS_MONTHLY}
            summary={() => renderSummary()}
        />
    );
}
