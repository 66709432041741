const guestClassificationList = {
    title: 'Guest type setting',
    breadcrumbs: {
        home: 'Home',
        guestClassification: 'Guest type setting',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Marketing channel',
    },
    searchBox: 'Search',
    textSearch: 'Search:',
    primaryButton: 'Guest type registration',
    guestClassificationListColumn: {
        id: 'ID',
        category: 'Guest type',
        categoryColor: 'Color',
    },
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
        title: 'Confirmation',
    },
    confirm: {
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
        cancelTitle: 'Do you want to cancel?',
        cantDelete: `Can't delete guest type`,
    },
    message: {
        deleteSuccess: 'Guest type has been deleted successfully',
    },
    exportColumns: {
        autoGeneratedCode: 'ID',
        name: 'Guest type',
        color: 'Color',
    },
};

export default guestClassificationList;
