const saleItemList = {
    title: 'Sales item setting',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        saleItem: 'Sales item setting',
    },
    saleItemColumn: {
        num: '#',
        id: 'ID',
        name: 'Item',
        price: 'Standard price',
        showInBooking: 'Display in preparation list',
        tax: 'Consumption tax type',
    },
    createBtn: 'Create new sales item',
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Item',
        taxName: 'Consumption tax type',
    },
    showInBooking: {
        hidden: 'Tax free',
        display: 'Display',
    },
    message: {
        deleteSuccess: 'Sale item have been deleted successfully',
        canNotEditDefaultData: `You can not edit the default data`,
        canNotDeleteDefaultData: `You can not delete the default data`,
    },
    modalConfirmDeletion: {
        cancelBtn: 'Cancel',
        deleteBtn: 'Delete',
    },
    exportColumns: {
        autoGeneratedCode: 'ID',
        name: 'Item',
        price: 'Standard price',
        tax: 'Consumption tax type',
        isShowInBooking: 'Display in preparation list',
    },
};

export default saleItemList;
