import React, { useEffect, useMemo } from 'react';
import { ScheduleBookingMonthView } from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    facilityBookingStateSelector,
    fetchFacilityBookingStatisticByDate,
    setCurrentDate,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { Dayjs } from '~plugins/dayjs';
import { FacilityBookingOccupanyRateRange } from '~features/facility-booking/constants';
import { debounce } from 'lodash';

export const ScheduleMonthView = () => {
    const dispatch = useAppDispatch();
    const { currentDate, isFetchingFacilityList, facilityBookingStatistic } =
        useAppSelector(facilityBookingStateSelector);

    const onChangeDate = (value: Dayjs) => {
        dispatch(setCurrentDate(value.fmYYYYMMDD()));
    };

    useEffect(() => {
        fetchData();
    }, [currentDate]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = debounce(() => {
        dispatch(fetchFacilityBookingStatisticByDate());
    }, 500);

    const calendarData = useMemo(() => {
        return facilityBookingStatistic.map((item) => {
            return {
                date: item.date,
                numberOfBookings: item.numberOfFacilityBookings,
                numberOfCheckedInBookings: item.numberOfCheckedInFacilityBookings,
                numberOfCheckedOutBookings: item.numberOfCheckedOutFacilityBookings,
                occupanyRate: Math.round(
                    (item.numberOfUsedFacilities / item.numberOfFacilities) * 100,
                ),
            };
        });
    }, [facilityBookingStatistic]);

    return (
        <ScheduleBookingMonthView
            calendarData={calendarData}
            isFetching={isFetchingFacilityList}
            onChangeDate={onChangeDate}
            currentDate={currentDate}
            occupanyRateRange={FacilityBookingOccupanyRateRange}
        />
    );
};
