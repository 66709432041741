import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
import { AppState } from '~plugins/redux-toolkit/store';
import { FacilityTypeOrderBy, SearchField, initFacilityTypeListQuery } from './constants';
import {
    IFacilityTypeCreate,
    IFacilityTypeDropDownQuery,
    IFacilityTypeGetListQuery,
    IFacilityTypeState,
} from './interfaces';
import { facilityTypeService } from './services/facility-type.api';
import { cloneDeep, trim } from 'lodash';

export const fetchFacilityTypeList = createAsyncThunk(
    'facility-type',
    async (_, { getState }) => {
        const query = facilityTypeListQuerySelector(getState() as AppState);
        return await facilityTypeService.getList(query);
    },
);

export const createFacilityType = createAsyncThunk(
    'create-facility-type',
    async (body: IFacilityTypeCreate) => {
        return await facilityTypeService.create(body);
    },
);

export const fetchFacilityTypeListByIds = createAsyncThunk(
    'facility-type/ids',
    async (ids: number[]) => {
        return await facilityTypeService.getListByIs(ids);
    },
);

export const bulkUpdateFacilityType = createAsyncThunk(
    'facility-type/bulk-update',
    async (body: IFacilityTypeCreate[]) => {
        return await facilityTypeService.bulkUpdate(body);
    },
);

export const getFacilityTypeListForDropDown = createAsyncThunk(
    'facility-type/getFacilityTypeListForDropDown',
    async (query: IFacilityTypeDropDownQuery) => {
        return await facilityTypeService.getListForDropdown(query);
    },
);

const initialState: IFacilityTypeState = {
    facilityTypeListQuery: cloneDeep(initFacilityTypeListQuery),
    totalFacilityTypes: 0,
    showLoadingList: false,
    facilityTypeList: [],
    isShowCreateForm: false,
    isShowUpdateForm: false,
    formBusy: false,
    selectedFacilityTypeList: [],
    facilityTypeListDropDownOptions: [],
    searchField: SearchField.ALL,
};

export const facilityTypeSlice = createSlice({
    name: 'facility-type',
    initialState,
    reducers: {
        setFacilityTypeListQuery: (state, action) => {
            state.facilityTypeListQuery = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IFacilityTypeGetListQuery = {
                limit: state.facilityTypeListQuery.limit,
                orderBy: state.facilityTypeListQuery.orderBy,
                orderDirection: state.facilityTypeListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.facilityTypeListQuery = query;
        },
        setFacilityTypeList: (state, action) => {
            state.facilityTypeList = action.payload;
        },
        setTotalFacilityTypes: (state, action) => {
            state.totalFacilityTypes = action.payload;
        },
        setIsShowCreateForm: (state, action) => {
            state.isShowCreateForm = action.payload;
        },
        setIsShowUpdateForm: (state, action) => {
            state.isShowUpdateForm = action.payload;
        },
        setSelectedFacilityTypeList: (state, action) => {
            state.selectedFacilityTypeList = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IFacilityTypeGetListQuery = {
                limit: state.facilityTypeListQuery.limit,
                orderBy: state.facilityTypeListQuery.orderBy,
                orderDirection: state.facilityTypeListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.facilityTypeListQuery.keyword ||
                    state.facilityTypeListQuery.abbreviation ||
                    state.facilityTypeListQuery.autoGeneratedCode ||
                    state.facilityTypeListQuery.name ||
                    '',
            };
            state.facilityTypeListQuery = query;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFacilityTypeList.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.facilityTypeList = action.payload?.data?.items || [];
            state.totalFacilityTypes = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchFacilityTypeList.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(createFacilityType.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(createFacilityType.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(bulkUpdateFacilityType.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(bulkUpdateFacilityType.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(fetchFacilityTypeListByIds.fulfilled, (state, action) => {
            state.formBusy = false;
            state.selectedFacilityTypeList = action.payload?.data || [];
        });
        builder.addCase(fetchFacilityTypeListByIds.pending, (state, action) => {
            state.formBusy = true;
            state.selectedFacilityTypeList = [];
        });
        builder.addCase(getFacilityTypeListForDropDown.fulfilled, (state, action) => {
            state.facilityTypeListDropDownOptions = (
                action.payload?.data?.items || []
            ).map(
                (item) =>
                    ({
                        label: item.name,
                        value: item.id,
                    } || []),
            );
        });
    },
});

export const {
    setFacilityTypeListQuery,
    setKeyword,
    setFacilityTypeList,
    setTotalFacilityTypes,
    setIsShowCreateForm,
    setIsShowUpdateForm,
    setSearchField,
    setSelectedFacilityTypeList,
    resetState,
} = facilityTypeSlice.actions;

export const facilityTypeListSelector = (state: AppState) => {
    return state.facilityType.facilityTypeList;
};

export const facilityTypeListQuerySelector = (state: AppState) => {
    return state.facilityType.facilityTypeListQuery;
};

export const totalPageSelector = (state: AppState) => {
    const { totalFacilityTypes, facilityTypeListQuery } = state.facilityType;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = facilityTypeListQuery;
    return Math.ceil(totalFacilityTypes / limit);
};

export const searchFieldSelector = (state: AppState) => {
    return state.facilityType.searchField;
};

export const totalFacilityTypesSelector = (state: AppState) => {
    return state.facilityType.totalFacilityTypes;
};

export const showLoadingListSelector = (state: AppState) => {
    return state.facilityType.showLoadingList;
};

export const showCreateFormSelector = (state: AppState) => {
    return state.facilityType.isShowCreateForm;
};

export const showUpdateFormSelector = (state: AppState) => {
    return state.facilityType.isShowUpdateForm;
};

export const formBusySelector = (state: AppState) => {
    return state.facilityType.formBusy;
};

export const selectedFacilityTypeListSelector = (state: AppState) => {
    return state.facilityType.selectedFacilityTypeList;
};

export const facilityTypeDropDownOptionsSelector = (state: AppState) => {
    return state.facilityType.facilityTypeListDropDownOptions;
};

export default facilityTypeSlice.reducer;
