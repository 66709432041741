import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormatMoney, FormatPercent, calculatePercentage } from '~common/helper';
import { ReportTableId } from '~features/report/constants';
import { CacheKeys } from '~queries/queries';
import { ISalesSummaryDetail, ISalesSummaryReportResponse } from '../../interfaces';
import './SalesSummaryTable.scss';

function RoomSalesSummaryTable() {
    const ROOM_TYPE = 'Room Price';
    const { t } = useTranslation();

    const { data, isLoading, isRefetching } = useQuery({
        queryKey: [CacheKeys.getSalesSummaryReportKey],
        enabled: false,
    });

    const dataSource = useMemo(() => {
        if (!data) return [];
        return (data as ISalesSummaryReportResponse[])
            .map((item: ISalesSummaryReportResponse) => {
                return {
                    key: item.salesName,
                    ...item,
                };
            })
            .sort((a, b) => {
                if (a.salesType === ROOM_TYPE) {
                    return -1;
                } else if (b.salesType === ROOM_TYPE) {
                    return 1;
                } else {
                    return 0;
                }
            });
    }, [data]);

    const columns: ColumnsType<ISalesSummaryReportResponse> = [
        {
            title: t('report.salesSummary.salesSummaryColumn.earnings'),
            className: 'header-cell',
            children: [
                {
                    title: t('report.salesSummary.salesSummaryColumn.saleItem'),
                    dataIndex: 'salesName',
                },
            ],
        },
        {
            title: t('report.salesSummary.salesSummaryColumn.currentTerm'),
            className: 'header-cell',
            dataIndex: 'current',
            children: [
                {
                    title: t('report.salesSummary.salesSummaryColumn.quantity'),
                    className: 'align-center',
                    render: (item: ISalesSummaryReportResponse) => {
                        return <span>{item.current.quantity}</span>;
                    },
                },
                {
                    title: t('report.salesSummary.salesSummaryColumn.price'),
                    className: 'price-cell',
                    render: (item: ISalesSummaryReportResponse) => {
                        return (
                            <>
                                <span>{FormatMoney.format(item.current.revenue)}</span>
                            </>
                        );
                    },
                },
            ],
        },
        {
            title: t('report.salesSummary.salesSummaryColumn.lastYear'),
            className: 'header-cell',
            children: [
                {
                    title: t('report.salesSummary.salesSummaryColumn.quantity'),
                    render: (item: ISalesSummaryReportResponse) => {
                        return <span>{item.previousYear.quantity}</span>;
                    },
                },
                {
                    title: t('report.salesSummary.salesSummaryColumn.price'),
                    className: 'price-cell',
                    render: (item: ISalesSummaryReportResponse) => {
                        return (
                            <span>{FormatMoney.format(item.previousYear.revenue)} </span>
                        );
                    },
                },
            ],
        },
        {
            title: t('report.salesSummary.salesSummaryColumn.comparison'),
            className: 'header-cell',
            children: [
                {
                    title: t('report.salesSummary.salesSummaryColumn.quantity'),
                    render: (item: ISalesSummaryReportResponse) => {
                        return (
                            <span>
                                {FormatPercent.format(
                                    calculatePercentage(
                                        item.current.quantity,
                                        item.previousYear.quantity,
                                    ),
                                )}
                            </span>
                        );
                    },
                },
                {
                    title: t('report.salesSummary.salesSummaryColumn.price'),
                    className: 'price-cell',
                    render: (item: ISalesSummaryReportResponse) => {
                        return (
                            <span>
                                {FormatPercent.format(
                                    calculatePercentage(
                                        item.current.revenue,
                                        item.previousYear.revenue,
                                    ),
                                )}
                            </span>
                        );
                    },
                },
            ],
        },
    ];

    const calculateSumByKey = (
        data: readonly ISalesSummaryReportResponse[],
        key: keyof ISalesSummaryReportResponse,
        keyType: keyof ISalesSummaryDetail,
    ) => {
        return data.reduce((total, item) => {
            return total + (item[key] as ISalesSummaryDetail)[keyType] || 0;
        }, 0);
    };

    const renderSummaryRow = (data: readonly ISalesSummaryReportResponse[]) => {
        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                    {t('report.salesSummary.total')}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                    {calculateSumByKey(data, 'current', 'quantity')}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} className="price-cell">
                    {FormatMoney.format(calculateSumByKey(data, 'current', 'revenue'))}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                    {calculateSumByKey(data, 'previousYear', 'quantity')}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} className="price-cell">
                    {FormatMoney.format(
                        calculateSumByKey(data, 'previousYear', 'revenue'),
                    )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                    {FormatPercent.format(
                        calculatePercentage(
                            calculateSumByKey(data, 'current', 'quantity'),
                            calculateSumByKey(data, 'previousYear', 'quantity'),
                        ),
                    )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                    {FormatPercent.format(
                        calculatePercentage(
                            calculateSumByKey(data, 'current', 'revenue'),
                            calculateSumByKey(data, 'previousYear', 'revenue'),
                        ),
                    )}
                </Table.Summary.Cell>
            </Table.Summary.Row>
        );
    };

    return (
        <div className="report-sales-summary_wrapper">
            <Table
                loading={isLoading || isRefetching}
                columns={columns}
                pagination={false}
                dataSource={dataSource}
                className="report-sales-summary-table"
                id={ReportTableId.SALES_SUMMARY}
                rowKey="key"
                summary={(data) => renderSummaryRow(data)}
            />
        </div>
    );
}

export default RoomSalesSummaryTable;
