export const currentGuestReportJa = {
    titlePage: '当日予約レポート',
    person: '人',
    breadcrumbs: {
        report: '帳票',
        currentGuest: '当日予約レポート',
    },
    filterForm: {
        dateLabel: '集計日:',
        displayButton: '表示',
    },
    genderBreakDown: {
        total: '大人合計人数',
        male: '男性合計人数',
        female: '女性合計人数',
        other: 'その他合計人数',
    },
    childrenBreakDown: {
        total: '子供合計',
        other: '子供その他',
    },
    guestTable: {
        roomName: '部屋名',
        guestName: '代表宿泊者名',
        roomGuestName: '部屋利用者名',
        numberOfGuestInRoom: '人数',
        nightOfStay: '泊目',
        checkInTime: 'C/I時間',
        plan: 'プラン',
        salePaymentItem: '売上/入金',
        date: '日付',
        numberOfItem: '数量',
        priceOfItem: '単価',
        mealItem: '食事',
        memo: 'メモ',
        totalChildren: '子供',
        guestAultsKey: {
            numberOfAdults: '大人: ',
            numberOfMale: '男性: ',
            numberOfFemale: '女性: ',
            numberOfOtherGenderGuest: 'その他: ',
        },
        receiptItemDetail: {
            roomPrice: '宿泊料',
            payment: '入金',
        },
    },
    exportColumns: {
        roomName: '部屋',
        guestName: '利用者名',
        roomGuestName: '客室利用者名',
        numberOfGuestInRoom: '部屋人数',
        nightOfStay: '泊目',
        checkInTime: '到着時間',
        plan: 'プラン',
        salePaymentItem: '商品/入金',
        date: '日付',
        numberOfItem: '数量',
        priceOfItem: '単価',
        mealItem: '食事',
        dateMeal: '日付',
        numberOfMealItem: '数量',
        memo: 'メモ',
    },
    exportCSV: 'CSVエクスポート',
};
