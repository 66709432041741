import { IBodyResponse } from '~common/interfaces';
import axiosService from '~plugins/axios';
import { ApiService } from '~plugins/axios/api';
import { IhotelsmartBookingUrlDetail, IHSChangeHistory } from '../interfaces';

class RoomBookingHotelSmart extends ApiService {
    getHSUrlDetail(id: number) {
        return this._get(
            `${this.baseUrl}/hotel-smart-booking-url-detail/${id}`,
        ) as Promise<IBodyResponse<IhotelsmartBookingUrlDetail>>;
    }
    getHSEditHistory(id: number) {
        return this._get(
            `${this.baseUrl}/hotel-smart-my-page-edit-history/${id}`,
        ) as Promise<IBodyResponse<IHSChangeHistory>>;
    }
}
export const roomBookingHotelSmart = new RoomBookingHotelSmart(
    { baseUrl: '/hotel-smart' },
    axiosService,
);
