const marketingChannelGroupForm = {
    primaryButton: '販売チャネルグループを追加する',
    title: '販売チャネルグループの作成',
    saleChannelGroupName: '販売チャネルグループ名',
    saleChannelGroupNamePlaceholder: '販売チャネルグループ名',
    addition: '追加',
    submitText: '登録',
    create: {
        success: '販売チャネルグループが作成されました',
    },
    update: {
        title: '販売チャネルグループの編集',
        marketingChannelGroupName: '販売チャネルグループ名',
        marketingChannelGroupNamePlaceholder: '',
        updateSuccessMessage: '販売チャネルグループが更新されました',
    },
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
    },
    nameDuplicated: '名前が重複しています',
    confirm: {
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
        cancelTitle: 'キャンセルしてもよろしいですか？',
    },
};

export default marketingChannelGroupForm;
