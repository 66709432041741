import React, { useEffect, useMemo } from 'react';
import { ScheduleBookingMonthView } from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    setCurrentDate,
    scheduleStateSelector,
    fetchRoomBookingStatisticByDate,
} from '~features/room-booking/reducers/schedule.reducer';

import { RoomBookingOccupanyRateRange } from '~features/room-booking/constants';
import { debounce } from 'lodash';
import { Dayjs } from '~plugins/dayjs';

export const ScheduleMonthView = () => {
    const dispatch = useAppDispatch();
    const { currentDate, bookingStatisticList, isFetchingRoomList } =
        useAppSelector(scheduleStateSelector);

    const onChangeDate = (value: Dayjs) => {
        dispatch(setCurrentDate(value.fmYYYYMMDD()));
    };

    useEffect(() => {
        fetchData();
    }, [currentDate]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = debounce(() => {
        dispatch(fetchRoomBookingStatisticByDate());
    }, 500);

    const calendarData = useMemo(() => {
        return bookingStatisticList.map((item) => {
            return {
                date: item.date,
                numberOfBookings: item.numberOfRoomBookingItems,
                numberOfCheckedInBookings: item.numberOfCheckedInRoomBookingItems,
                numberOfCheckedOutBookings: item.numberOfCheckedOutRoomBookingItems,
                occupanyRate: Math.round(
                    (item.bookedRoomCount / item.numberOfRooms) * 100,
                ),
            };
        });
    }, [bookingStatisticList]);

    return (
        <ScheduleBookingMonthView
            calendarData={calendarData}
            isFetching={isFetchingRoomList}
            onChangeDate={onChangeDate}
            currentDate={currentDate}
            occupanyRateRange={RoomBookingOccupanyRateRange}
        />
    );
};
