import { InputSearch } from '~components';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import './FilterForm.scss';
import { useAppDispatch } from '~hooks';
import { useNavigate } from 'react-router-dom';
import { setKeyword } from '~features/plan/reducers/plan.reducer';
import { checkNotAllowedUsers, checkUserPermission } from '~common/commonFunctions';
import {
    AdminGroup,
    GA_KEYWORD_CONTENT,
    HotelUsers,
    INPUT_TEXT_MAX_LENGTH,
} from '~common/constants';
import { trackSearchKeyword } from '~plugins/analytics';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.PLAN);
    };
    const isReadOnly = !checkUserPermission([...AdminGroup]);

    const addPlan = () => {
        navigate('/front-settings/plan/create-plan');
    };

    return (
        <div className="filter-plan-wrapper">
            <div className="plan-left-block">
                <InputSearch
                    onSearch={onSearch}
                    max={INPUT_TEXT_MAX_LENGTH}
                    placeholder={t('common.searchPlaceholder')}
                />
            </div>

            {!isReadOnly && (
                <div className="plan-btn">
                    <Button
                        type="primary"
                        onClick={addPlan}
                        disabled={checkNotAllowedUsers([...HotelUsers])}
                    >
                        <PlusOutlined />
                        <span className="btn-text">{t('plan.list.primaryButton')}</span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
