import { SolutionOutlined } from '@ant-design/icons';
import { Button, Dropdown, notification } from 'antd';
import { parseDate } from '~plugins/dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    AdminGroup,
    DRAWER_WIDTH_DEFAULT,
    ReadNotificationType,
    UserGroup,
} from '~common/constants';
import { NoData } from '~components';
import { IBookingNotification } from '~features/admin-notification/interfaces';
import {
    getTemporaryBookingItemList,
    notificationStateSelector,
    updateReadNotificationTime,
} from '~features/admin-notification/reducers/notification.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { SocketEvents } from '~plugins/socket/socket';
import { useSocket } from '~plugins/socket/socketContext';
import BookingNotificationItem from '../BookingNotificationItem/BookingNotificationItem';
import './TemporaryBookingDropdown.scss';
import { checkUserPermission } from '~common/commonFunctions';

export function TemporaryBookingLayout() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const socket = useSocket();
    const isHavePermission = checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
        UserGroup.HOTEL_FRONT,
    ]);

    const { temporaryBookingNotificationList, temporaryBookingNotifyReadTimeAt } =
        useAppSelector(notificationStateSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [currentTemporaryBookings, setCurrentTemporaryBookings] = useState<
        IBookingNotification[]
    >(temporaryBookingNotificationList.slice(0, 10));

    useEffect(() => {
        if (!socket || !isHavePermission) return;
        socket.on(
            SocketEvents.WEB_APP_SEND_UNCONFIRMATION_BOOKING_NOTIFICATION,
            async (data) => {
                dispatch(getTemporaryBookingItemList());
                if (data !== '') {
                    notification.success({
                        message: data,
                    });
                }
            },
        );

        return () => {
            socket?.off(SocketEvents.WEB_APP_SEND_UNCONFIRMATION_BOOKING_NOTIFICATION);
        };
    }, [socket]);

    useEffect(() => {
        if (temporaryBookingNotificationList.length) {
            if (currentTemporaryBookings.length) {
                setCurrentTemporaryBookings(
                    temporaryBookingNotificationList.slice(
                        0,
                        currentTemporaryBookings.length + 1,
                    ),
                );
            } else {
                setCurrentTemporaryBookings(
                    temporaryBookingNotificationList.slice(0, 10),
                );
            }
        }
    }, [temporaryBookingNotificationList]);

    const handleScroll = (event: any) => {
        const target = event.target;

        if (
            target.scrollHeight - target.scrollTop - target.clientHeight <= 1 &&
            temporaryBookingNotificationList?.length
        ) {
            setCurrentTemporaryBookings(
                currentTemporaryBookings.concat(
                    temporaryBookingNotificationList.slice(
                        currentTemporaryBookings.length,
                        currentTemporaryBookings.length + 10,
                    ),
                ),
            );
        }
    };

    const onOpenTemporaryBooking = () => {
        setIsOpen(false);
        navigate('/room-booking/schedule');
    };

    const updateReadNotifyTime = () => {
        dispatch(
            updateReadNotificationTime({ type: ReadNotificationType.TEMPORARY_BOOKING }),
        );
    };

    const changeOpenDropDown = (val: boolean) => {
        setIsOpen(val);
        setCurrentTemporaryBookings(temporaryBookingNotificationList.slice(0, 10));
        if (!val) {
            updateReadNotifyTime();
        }
    };

    const isUnread = (updatedAt?: string) => {
        if (!updatedAt || !temporaryBookingNotifyReadTimeAt) return true;
        return parseDate(updatedAt).isAfter(parseDate(temporaryBookingNotifyReadTimeAt));
    };

    useEffect(() => {
        if (isHavePermission) {
            dispatch(getTemporaryBookingItemList());
        }
    }, []);

    return (
        <Dropdown
            trigger={['click']}
            placement="bottom"
            open={isOpen}
            onOpenChange={changeOpenDropDown}
            overlayClassName="temporary-booking-dropdown"
            dropdownRender={(menu) => (
                <div className="temporary-booking-dropdown-content">
                    <div className="temporary-booking-dropdown-drawer-title">
                        {t('layout.staff.temporaryBooking')}
                    </div>
                    <div
                        className="temporary-booking-dropdown-content-container"
                        onScroll={handleScroll}
                    >
                        {currentTemporaryBookings.length !== 0 &&
                            currentTemporaryBookings.map((temporaryBooking) => (
                                <BookingNotificationItem
                                    key={temporaryBooking.rawData?.id}
                                    notificationItem={temporaryBooking}
                                    type={ReadNotificationType.TEMPORARY_BOOKING}
                                    isUnread={isUnread(temporaryBooking.updatedAt)}
                                    title={t('layout.staff.temporaryBookingTitle')}
                                    onOpenBooking={onOpenTemporaryBooking}
                                    isShowTime={true}
                                />
                            ))}
                        {currentTemporaryBookings.length === 0 && <NoData />}
                    </div>
                </div>
            )}
        >
            <Button
                type="link"
                className={
                    isOpen
                        ? 'temporary-booking-dropdown-title-active'
                        : 'temporary-booking-dropdown-title'
                }
            >
                <SolutionOutlined style={{ fontSize: '16px', marginRight: '12px' }} />
                <div>
                    {t('common.topbar.staffHeader.confirmationList')}
                    {!!temporaryBookingNotificationList.length && (
                        <span>{` (${temporaryBookingNotificationList.length})`}</span>
                    )}
                </div>
            </Button>
        </Dropdown>
    );
}

TemporaryBookingLayout.defaultProps = {
    busy: false,
    loading: false,
    width: DRAWER_WIDTH_DEFAULT,
};
