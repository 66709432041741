import yup from '~plugins/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MEMO_MAX_LENGTH } from '~common/constants';
import { StopRoomCause } from './constants';

export const roomStatusFormSchema = yup.object().shape({
    remark: yup.string().max(MEMO_MAX_LENGTH).nullable().when('cause', {
        is: StopRoomCause.OTHERS,
        then: yup.string().required(),
    }),
    date: yup.mixed().required(),
});

export const roomStatusFormSchemaResolver = yupResolver(roomStatusFormSchema);
