export const facilityCreateEn = {
    title: 'Create facility',
    breadcrumbs: {
        create: 'create-facility',
    },
    formSetting: {
        quantity: {
            label: 'Quantity',
            placeholder: 'Example: 1',
        },
        facilityType: {
            label: 'Facility type',
            placeholder: 'Select facility type',
        },
        initialFacilityName: {
            label: 'Facility name',
            placeholder: 'Example: 101',
        },
    },
    buttons: {
        generateFacilities: 'Generate facilities',
        addFacilities: 'Create facilities',
    },
    columns: {
        id: 'ID',
        facilityName: 'Facility name',
        facilityType: 'Facility type',
        basePriceTaxIncluded: 'Base price tax (￥)',
        action: 'Action',
        pricePerPerson: 'Price per person',
    },
    facilityNameDuplicated: 'Facility na me is duplicated',
    createSuccessMessage: 'Facilities have been created succcessful!',
    confirm: {
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
        cancelTitle: 'Do you want to cancel?',
    },
};
