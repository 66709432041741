import { memo } from 'react';
import { Drawer } from 'antd';
import { useIsMobile } from '~common/useHooks';
import SiderMenu from './SiderMenu/SiderMenu';
import './Sidebar.scss';
interface ISidebar {
    isDrawerOpen: boolean;
    handleOnCloseDrawer: () => void;
}

export function Sidebar(props: ISidebar) {
    const isMobile = useIsMobile();
    const { isDrawerOpen = false, handleOnCloseDrawer } = props;
    return isMobile ? (
        <Drawer
            rootClassName="sidebar-drawer"
            placement="left"
            open={isDrawerOpen}
            closable={false}
            onClose={handleOnCloseDrawer}
        >
            <SiderMenu handleOnCloseDrawer={handleOnCloseDrawer} />
        </Drawer>
    ) : (
        <SiderMenu />
    );
}

export default memo(Sidebar);
