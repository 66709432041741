import { Progress } from 'antd';
import React from 'react';
import './BookingStatusSingle.scss';
import CountUp from 'react-countup';

type Props = {
    title?: string;
    subTitle: string;
    left: {
        label: string;
        value: number;
        color: string;
    };
    right: {
        label: string;
        value: number;
        color: string;
    };
};

export const BookingStatusSingle = (props: Props) => {
    const { title, subTitle, left, right } = props;

    let leftPercentage;
    if (left.value === 0 && right.value === 0) leftPercentage = 50;
    else leftPercentage = Math.round((left.value / (left.value + right.value)) * 100);

    return (
        <div className="dashboard-booking-overview-status-single-wrapper">
            <div className="booking-status-single-header">
                <div className="title">{title}</div>
                <div>{subTitle}</div>
            </div>
            <div className="booking-status-single-content">
                <div className="d-flex f-col a-center">
                    <div>{left.label}</div>
                    <div className="value">
                        <CountUp end={left.value} />
                    </div>
                </div>
                <div className="d-flex f-col a-center">
                    <div>{right.label}</div>
                    <div className="value">
                        <CountUp end={right.value} />
                    </div>
                </div>
            </div>
            <div className="booking-status-single-footer">
                <Progress
                    strokeLinecap="butt"
                    percent={leftPercentage}
                    showInfo={false}
                    strokeColor={left.color}
                    trailColor={right.color}
                />

                <div className="d-flex a-center">
                    <div className="label-wrapper">
                        <div
                            style={{ backgroundColor: left.color }}
                            className="label-color"
                        ></div>
                        {left.label}
                    </div>
                    <div className="label-wrapper">
                        <div
                            style={{ backgroundColor: right.color }}
                            className="label-color"
                        ></div>
                        {right.label}
                    </div>
                </div>
            </div>
        </div>
    );
};
