const paymentSummaryEn = {
    title: 'Payment summary',
    breadcrumbs: {
        report: 'Report',
        paymentSummary: 'Payment summary',
    },
    filterForm: {
        aggregationMethod: {
            label: 'Payment method',
            daily: 'Per day',
            month: 'Per month',
        },
        dateLabel: 'Date',
        monthLabel: 'Month',
        filterButton: 'Display',
    },
    table: {
        paymentMethod: 'Payment method',
        monthlyAccumulative: 'Monthly accummulative',
    },
    total: 'Total',
    unit: '¥',
};

export default paymentSummaryEn;
