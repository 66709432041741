export const scheduleJa = {
    title: '宿泊スケジュール',
    statusTitle: 'ステータス',
    remaining: '残り部屋',
    breadcrumbs: {
        home: 'ホーム',
        schedule: '宿泊予約',
        scheduleSetting: '宿泊スケジュール',
        groupBooking: '新規団体予約',
    },
    createReservation: '新規予約',
    confirmCloseBookingTitle: 'すべての仮予約は {{minutes}} 秒後に削除されます',
    confirmCloseBooking: '予約を維持しますか？',
    bookingsDeleted: 'すべての予約が削除されました',
    bookingMoreList: '予約リスト',
    hover: {
        expandBookingList: '全画面表示',
        unassignList: '未アサインリスト表示',
    },
    status: {
        all: '全て',
        not_arrived: '未到着',
        checked_in: '滞在',
        checked_out: '出発',
        cancelled: 'キャンセル',
    },
    tab: {
        schedule: '宿泊スケジュール',
    },
    viewType: {
        day: '日',
        week: '週',
        month: '月',
        year: '年',
    },
    collapseTitle: '一括で閉じる',
    collapseShowLess: '一括で開く',
    notAssigned: '未アサイン',
    unspecified: '未指定',
    resevationOverview: '予約概要へ',
    currentBooking: '予約一覧',
    selectChildTitle: '子供区別を選択',
    withoutMeal: '素泊まり',
    adult: '大人',
    genderBreakdown: '({{male}} 男, {{female}} 女, {{other}} 他)',
    kid: '子',
    childType: '子供',
    person: '人',
    dateRageInvalid: '日付範囲が無効です',
    bookingOccupied: '当日の夜は部屋の利用が不可能です',
    message: {
        deleteTmpSuccess: '仮予約が削除されました',
        deleteTmpFaild: '仮予約の削除が失敗しました',
        updateTmpSuccess: '仮予約の更新ができました',
        checkinSuccess: 'チェックイン部屋の予約ができました',
        cannotCheckIn: {
            fromNotArrived: '{{date}}以前にはチェックインできません',
            fromCheckOut:
                'こちらの予約のチェックアウト日が過ぎているのでチェックインできません',
        },
    },
    remainingRoom: '残り部屋',
    sync: 'TLL予約を同期',
    syncBookingStart: '予約をTLL同期しています, 数分経ってからもう一度更新お願いします。',
    createBookingOptions: {
        generalBooking: '新規一般予約',
        groupBooking: '新規団体予約',
    },
    groupBookingTable: {
        id: '#',
        roomType: '部屋タイプ',
        numberOfRooms: '部屋数',
        plan: 'プラン',
        adult: '大人（一部屋当たり）',
        children: '子供',
        dayUse: 'デイユース',
        lengthOfStay: '滞在期間',
        delete: '削除',
        addRowButton: '部屋グループを追加',
        placeHolder: {
            roomType: '部屋を選択',
            planName: 'プランを選択',
        },
    },
    scheduleCellPopup: {
        title: '予約作成または売り止めを選択してください',
        createBooking: '予約作成',
        stopRooms: '売り止め',
    },
    stoppingRoomsModal: {
        stoppingRooms: '売り止め',
        releasingRooms: '売り止め解除',
        form: {
            roomType: '部屋',
            stoppingPeriod: {
                label: '売り止め期間',
                start: '開始日',
                end: '終了日',
            },
            cause: '原因',
            reason: '理由',
        },
    },
};
