const roomBookingList = {
    title: '宿泊予約一覧',
    breadcrumbs: {
        home: 'ホーム',
        roomBooking: '宿泊予約',
        bookingList: '宿泊予約一覧',
    },
    column: {
        id: '予約番号',
        guestYomigana: 'ヨミガナ',
        guestFullName: '宿泊者名',
        roomName: '部屋名',
        status: 'ステータス',
        checkInTime: 'C/I',
        endDateOfStay: 'C/O',
        payment: '決済',
        paymentNumber: '未収金',
        memo: 'メモ',
        cleaning: '清掃',
        category: 'カテゴリー',
        bookingDate: '予約日',
        marketingChannel: '販売チャネル',
        planName: 'プラン',
        advance: '事前',
        cleaningStatus: {
            cleaned: '清掃済',
            uncleaned: '未清掃',
        },
    },
    bookingCategory: {
        group: '団体予約',
        individual: '個人予約',
        day_use: 'デイユース',
    },
    dropdown: {
        title: 'ステータスを変更',
    },
    statusPopConfirm: {
        title: 'ステータスを変更',
        content: '{{status}}に変更します。よろしいでしょうか？',
        okText: 'はい',
        cancelText: 'いいえ',
    },
    checkoutModalConfirm: {
        title: 'ステータス変更の確認',
        content: 'ステータスは「{{status}}」に変更しますがよろしいでしょうか？',
        okText: '変更',
        cancelText: 'キャンセル',
    },
    notArriveModalConfirm: {
        title: 'ステータス変更の確認',
        content: '予約ステータスを未到着に変更',
        okText: '変更',
        cancelText: 'キャンセル',
    },
    cancelModalConfirm: {
        title: 'こちらの予約をキャンセルしてもよろしいですか？',
    },
    statusModalConfirm: {
        success: 'ステータスが更新されました',
        error: '予約ステータスの更新に失敗しました',
        errorChangeStatus:
            '{{beforeStatus}} スターテスから {{afterStatus}}　には変更できません',
        okText: '閉じる',
    },
    update: {
        cleaningStatusTitle: 'ステータスを変更してもよろしいですか？',
    },
    importXml: {
        success: 'xml file のインポート成功',
    },
    filterForm: {
        buttonCreateRoomBooking: '新規予約',
        buttonDetailSearch: '詳細検索',
        buttonRemoveFilter: '全てのフィルターを削除',
        buttonPreparationList: '手配リスト',
        status: {
            label: 'ステータス',
            all: '全て',
            not_arrived: '未到着',
            staying: '滞在',
            departure: '出発',
            cancelled: 'キャンセル',
        },
        dateType: {
            label: '日付',
            all: '全て',
            today: '今日',
            tomorrow: '明日',
        },
        searchColumn: {
            keyword: '全て',
            autoGeneratedCode: '予約番号',
            guestYomigana: 'ヨミガナ',
            guestFullName: '宿泊者',
        },
    },
    advanceSearchForm: {
        buttonSearchText: '検索',
        autoGeneratedCode: {
            label: '予約番号',
            placeholder: '予約IDを入力',
        },
        planIds: {
            label: 'プラン',
            placeholder: 'プラン名を選択',
        },
        marketingChannelIds: {
            label: '販売チャネル',
            placeholder: '販売チャネルを選択',
            defaultValue: 'ウォークイン',
        },
        roomBookingItemStatus: {
            label: 'ステータス',
            placeholder: 'ステータスを選択',
        },
        guestYomigana: {
            label: '宿泊者名 (ヨミガナ)',
            placeholder: '宿泊者名を入力',
        },
        guestMobilePhoneNumber: {
            label: '電話番号',
            placeholder: '電話番号を入力',
        },
        receptionPeriod: {
            label: '受付日',
            placeholder: '日付を選択',
        },
        stayPeriod: {
            label: '宿泊日',
            placeholder: '日付を選択',
        },
        endDatePeriod: {
            label: 'チェックアウト',
            placeholder: {
                start: '開始時間',
                end: '終了時間',
                selectDate: '日付を選択',
            },
        },
        startDatePeriod: {
            label: 'チェックイン',
            placeholder: {
                start: '開始時間',
                end: '終了時間',
                selectDate: '日付を選択',
            },
        },
        memo: {
            label: 'メモ・OTA備考',
            placeholder: 'メモを入力',
        },
    },
    message: {
        bulkDeleteSuccess: '部屋予約成功を削除',
        deleteByStatusError: '以下の部屋予約は削除できませんでした',
        titleDelete: 'エラー',
        cannotCheckIn: '以下の部屋予約はチェックインできませんでした',
        checkoutSplittedBookingsError:
            '関連する予約の中に、未来のチェックアウト日が存在します',
    },
    exportColumns: {
        autoGeneratedCode: '予約番号',
        yomigana: 'ヨミガナ',
        fullname: '予約者',
        roomName: '部屋名',
        category: 'カテゴリー',
        bookingDate: '予約日',
        marketingChannel: '販売チャネル',
        plan: 'プラン',
        status: 'ステータス',
        checkInTime: 'C/I',
        checkOutTime: 'C/O',
        cleaningStatus: '清掃',
        payment: '決済',
        price: '未収金',
        memo: 'メモ',
    },
    modalConfirmDeletion: {
        deleteButton: '削除',
        cancelButton: 'キャンセル',
    },
    checkoutDialog: {
        checkoutSuccess: 'ステータスが使用終了に変更されました。',
        unpaidTitle: '未集金のため精算されていません',
        content: '予約詳細に移動し、精算してください。',
        okText: '予約詳細に移動',
        cancelText: 'キャンセル',
    },
    preparationListForm: {
        title: '手配リスト',
        startDate: '開始日',
        endDate: '完了日',
        saleItem: '科目',
        status: 'ステータス',
        displayedContentMode: {
            label: '表示内容',
            total: '合計',
            perRoom: '部屋別',
        },
        searchButton: '検索',
        downloadButton: 'ダウンロード',
        printButton: '印刷',
        column: {
            date: '日付',
            roomName: '部屋名',
            guestName: '宿泊者',
            saleItemName: '科目',
            quantity: '数量',
            price: '売上',
        },
        cancelTitle: 'キャンセルしてもよろしいですか？',
        allOption: '全て',
        exportColumns: {
            date: '日付',
            roomName: '部屋名',
            guestName: '宿泊者',
            saleItemName: '科目',
            quantity: '数量',
            price: '売上',
        },
    },
};

export default roomBookingList;
