import rankCalendarListJa from './ja/rank-calendar-list.locale';
import rankCalendarDetailJa from './ja/rank-calendar-detail.locale';
import rankCalendarFormJa from './ja/rank-calendar-form.locale';
import rankCalendarUpdateJa from './ja/rank-calendar-update.locale';

import rankCalendarListEn from './en/rank-calendar-list.locale';
import rankCalendarDetailEn from './en/rank-calendar-detail.locale';
import rankCalendarFormEn from './en/rank-calendar-form.locale';
import rankCalendarUpdateEn from './en/rank-calendar-update.locale';

const rankCalendar = {
    ja: {
        list: rankCalendarListJa,
        detail: rankCalendarDetailJa,
        form: rankCalendarFormJa,
        update: rankCalendarUpdateJa,
    },
    en: {
        list: rankCalendarListEn,
        detail: rankCalendarDetailEn,
        form: rankCalendarFormEn,
        update: rankCalendarUpdateEn,
    },
};

export default rankCalendar;
