export default {
    reservationContact: '予約者連絡情報',
    totalPrice: '合計 (税込み)',
    subtotal: '小計',
    create: {
        success: '登録されました',
        error: '登録に失敗しました',
    },
    lengthOfStay: '滞在期間',
    adult: '大人',
    codomo: '子',
    reservationPerson: '予約者',
    guest: '宿泊者',
    sameGuest: '宿泊者と同じ',
    marketingChannel: '販売チャネル',
    representative: '（代表者）',
    representativeRoom: '（代表者）',
    totalTax: '内消費税等',
    numberOfPeople: '{{adult}}大人{{children}}子',
};
