import React from 'react';
import { Table } from 'antd';
import { useSplitBookingScheduleGrid } from '~features/room-booking/hooks/useSplitBookingScheduleGrid';

import './SplitBookingScheduleGrid.scss';

export type Props = {
    startDate: string;
    endDate: string;
    setSelectedDates: React.Dispatch<React.SetStateAction<string[]>>;
    selectedDates: string[] | undefined;
    originalBookingDates: string[];
};

const SplitBookingScheduleGrid = ({
    startDate,
    endDate,
    setSelectedDates,
    selectedDates,
    originalBookingDates,
}: Props) => {
    const { isFetching, columns, dataSource } = useSplitBookingScheduleGrid({
        startDate,
        endDate,
        setSelectedDates,
        selectedDates,
        originalBookingDates,
    });

    return (
        <Table
            key={dataSource.length}
            loading={isFetching}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ x: 1500, y: window.innerHeight - 400 }}
            size="small"
            bordered
            expandable={{ defaultExpandAllRows: true }}
        />
    );
};

export default React.memo(SplitBookingScheduleGrid);
