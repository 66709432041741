import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_CHILDREN_TYPE_LIST_QUERY } from '../constants';
import { IChildrenTypeFormBody, IChildrenTypeState } from '../interfaces';
import { childrenTypeService } from '../services/children-type.service';

export const getChildrenTypeDropdown = createAsyncThunk(
    'children-type/getChildrenDropdown',
    async () => {
        return await childrenTypeService.getDropdown();
    },
);

export const fetchChildrenTypeList = createAsyncThunk(
    'children-type',
    async (_, { getState }) => {
        const query = childrenTypeListQuerySelector(getState() as AppState);
        return await childrenTypeService.getList(query);
    },
);

export const createChildrenType = createAsyncThunk(
    'create-children-type',
    async (body: IChildrenTypeFormBody) => {
        return await childrenTypeService.create(body);
    },
);

export const updateChildrenType = createAsyncThunk(
    'children-type/update',
    async ({ id, body }: { id: number; body: IChildrenTypeFormBody }) => {
        return await childrenTypeService.update(id, body);
    },
);

const initialState: IChildrenTypeState = {
    childrenTypeListQuery: cloneDeep(DEFAULT_CHILDREN_TYPE_LIST_QUERY),
    childrenTypeList: [],
    showLoadingList: false,
    isShowForm: false,
    formBusy: false,
    selectedChildrenTypeList: [],
    childrenTypeListDropDownOptions: [],
};

const childrenTypeSlice = createSlice({
    name: 'children-type',
    initialState,
    reducers: {
        setChildrenTypeListQuery: (state, action) => {
            state.childrenTypeListQuery = action.payload;
        },
        setChildrenTypeList: (state, action) => {
            state.childrenTypeList = action.payload;
        },
        setIsShowForm: (state, action) => {
            state.isShowForm = action.payload;
        },
        setSelectedChildrenTypeList: (state, action) => {
            state.selectedChildrenTypeList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchChildrenTypeList.pending, (state, action) => {
            state.showLoadingList = true;
        });
        builder.addCase(fetchChildrenTypeList.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.childrenTypeList = action.payload?.data?.items || [];
        });
        builder.addCase(createChildrenType.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(createChildrenType.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(updateChildrenType.pending, (state, action) => {
            state.formBusy = true;
        });
        builder.addCase(updateChildrenType.fulfilled, (state, action) => {
            state.formBusy = false;
        });
        builder.addCase(getChildrenTypeDropdown.fulfilled, (state, action) => {
            state.childrenTypeListDropDownOptions = (
                action.payload?.data?.items || []
            ).map(
                (item) =>
                    ({
                        label: item.name,
                        value: item.autoGeneratedCode || item.id,
                    } || []),
            );
        });
    },
});

export const {
    setChildrenTypeListQuery,
    setChildrenTypeList,
    setIsShowForm,
    setSelectedChildrenTypeList,
} = childrenTypeSlice.actions;

export const childrenStateSelector = (state: AppState) => state.childrenType;

export const childrenTypeListQuerySelector = (state: AppState) => {
    return state.childrenType.childrenTypeListQuery;
};

export const childrenTypeListSelector = (state: AppState) => {
    return state.childrenType.childrenTypeList;
};

export const showLoadingListSelector = (state: AppState) => {
    return state.childrenType.showLoadingList;
};

export const showFormSelector = (state: AppState) => {
    return state.childrenType.isShowForm;
};

export const formBusySelector = (state: AppState) => {
    return state.childrenType.formBusy;
};

export const selectedChildrenTypeListSelector = (state: AppState) => {
    return state.childrenType.selectedChildrenTypeList;
};

export const childrenTypeDropDownOptionsSelector = (state: AppState) => {
    return state.childrenType.childrenTypeListDropDownOptions;
};

export const childrenTypeReducer = childrenTypeSlice.reducer;
