import { useAppSelector, useAppDispatch } from '~hooks';
import './ScheduleView.scss';
import { BookingScheduleHeader } from '~components';
import {
    facilityBookingStateSelector,
    isShowCreateFacilityBookingFormSelector,
    setCurrentDate,
    setCurrentStatus,
    setCurrentViewMode,
    setDefaultFacilityBookingOptions,
    setIsShowCreateFacilityBookingForm,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { ScheduleViewMode } from '~common/constants';
import { ScheduleWeekView } from '../ScheduleWeekView/ScheduleWeekView';
import { ScheduleDayView } from '../ScheduleDayView/ScheduleDayView';
import { ScheduleMonthView } from '../ScheduleMonthView/ScheduleMonthView';
import { Dayjs, parseDate } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import { bookingScheduleDefault } from '~features/facility-booking/model';
import { RoomBookingEvent } from '~features/room-booking/constants';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Select } from 'antd';
import { PlusOutlined, FullscreenOutlined } from '@ant-design/icons';
import { FacilityBookingStatus } from '~features/facility-booking/constants';
import { FacilityBookingScheduleListModal } from '../FacilityBookingScheduleListModal/FacilityBookingListScheduleModal';
import { CreateFacilityBookingModal } from '../CreateFacilityBookingModal/CreateFacilityBookingModal';
import FacilityBookingCheckInForm from '../FacilityBookingCheckInForm/FacilityBookingCheckInForm';

interface Props {
    height?: number;
    isExpandSchedule: boolean;
    setIsExpandSchedule: (isExpandSchedule: boolean) => void;
}

export const ScheduleView = ({
    height,
    isExpandSchedule,
    setIsExpandSchedule,
}: Props) => {
    const {
        currentDate,
        currentViewMode,
        currentStatus,
        currentEditFacilityBooking,
        defaultFacilityBookingOptions,
    } = useAppSelector(facilityBookingStateSelector);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { emitter } = useMitt();

    const onChangeDate = (value: Dayjs) => {
        dispatch(setCurrentDate(value.fmYYYYMMDD()));
        emitter.emit(RoomBookingEvent.CHANGE_DATE, value.fmYYYYMMDD());
    };
    const onChangeView = (value: ScheduleViewMode) => {
        dispatch(setCurrentViewMode(value));
    };

    const onChangeStatus = (value: FacilityBookingStatus) => {
        dispatch(setCurrentStatus(value));
    };

    const isShowForm = useAppSelector(isShowCreateFacilityBookingFormSelector);

    const createReservation = () => {
        dispatch(setIsShowCreateFacilityBookingForm(true));
        dispatch(setDefaultFacilityBookingOptions({}));
    };

    const scheduleBodyView = (props: { viewMode: ScheduleViewMode }) => {
        switch (props.viewMode) {
            case ScheduleViewMode.WEEK:
                return <ScheduleWeekView height={height} />;
            case ScheduleViewMode.MONTH:
                return <ScheduleMonthView />;
            case ScheduleViewMode.DAY:
                return <ScheduleDayView height={height} />;
        }
    };

    return (
        <div className="schedule-view-wrapper">
            <div className="schedule-tab-pane-header">
                <div className="header-section">
                    <span className="status-title">
                        {t('roomBooking.schedule.statusTitle')}:
                    </span>
                    <Select
                        onChange={onChangeStatus}
                        defaultValue={FacilityBookingStatus.ALL}
                        value={currentStatus}
                        options={Object.values(FacilityBookingStatus)
                            .filter((item) => item !== FacilityBookingStatus.CANCELLED)
                            .map((status) => {
                                return {
                                    value: status,
                                    label: t(
                                        'facilityBooking.list.bookingStatus.' + status,
                                    ),
                                };
                            })}
                    />
                </div>
                <BookingScheduleHeader
                    defaultValue={parseDate(currentDate)}
                    onChangeDate={onChangeDate}
                    onChangeView={onChangeView}
                    viewMode={currentViewMode}
                />
                <div className="header-section">
                    <Button onClick={createReservation} type="primary">
                        <PlusOutlined size={11} />
                        {t('roomBooking.schedule.createReservation')}
                    </Button>
                    {!isExpandSchedule && (
                        <Button
                            onClick={() => setIsExpandSchedule(true)}
                            className="icon-button"
                        >
                            <FullscreenOutlined />
                        </Button>
                    )}
                </div>
            </div>
            {scheduleBodyView({
                viewMode: currentViewMode,
            })}
            <FacilityBookingScheduleListModal isCanEdit={true} />
            {isShowForm && (
                <Modal
                    centered
                    title=""
                    open={isShowForm}
                    footer={null}
                    closable={false}
                    className="create-facility-booking-modal"
                >
                    <CreateFacilityBookingModal
                        booking={
                            currentEditFacilityBooking
                                ? { ...currentEditFacilityBooking }
                                : {
                                      ...bookingScheduleDefault(),
                                      ...defaultFacilityBookingOptions,
                                  }
                        }
                        usePopover={false}
                        close={() => {
                            dispatch(setIsShowCreateFacilityBookingForm(false));
                        }}
                    />
                </Modal>
            )}
            <FacilityBookingCheckInForm />
        </div>
    );
};
