import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { IRoomTypeDropdown } from '~features/room-type/interfaces';
import { InputText, SingleSelect } from '~components';
import { IPlanRoomType, IPlanRoomTypePricing } from '~features/plan/interfaces';
import { IDropDownOption } from '~common/interfaces';
import PlanRoomTypePricingTable from './PlanRoomTypePricingTable/PlanRoomTypePricingTable';
import './PlanRoomTypePricingsForm.scss';
import { transformPlanRoomTypePricings, getFormPlanId } from '~features/plan/helper';
import { HotelUsers, TllRankDropdown } from '~common/constants';
import { cloneDeep } from 'lodash';
import { defaultPlanRoomTypePricings } from '~features/plan/constants';
import { checkNotAllowedUsers } from '~common/commonFunctions';

type Iprops = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: any;
    roomType: IRoomTypeDropdown;
    planRoomType: IPlanRoomType;
    indexPlanRoomType: number;
};

function PlanRoomTypePricingsForm({
    control,
    roomType,
    indexPlanRoomType,
    planRoomType,
    setValue,
}: Iprops) {
    const { t } = useTranslation();
    const [peopleCount, setPeopleCount] = useState<number>(
        planRoomType?.peopleCount || 0,
    );

    const [pricingRoomTypeList, setPricingRoomTypeList] = useState<
        {
            feId: number;
            planRoomTypePricings: IPlanRoomTypePricing[];
        }[]
    >(
        transformPlanRoomTypePricings(
            planRoomType?.planRoomTypePricings?.length
                ? planRoomType.planRoomTypePricings
                : cloneDeep(defaultPlanRoomTypePricings),
        ),
    );
    const [peopleCountOption, setPeopleCountOption] = useState<IDropDownOption[]>([]);

    useEffect(() => {
        const numberOfPeopleDropdown = [];
        numberOfPeopleDropdown.push({
            value: 0,
            label: `${t('plan.create.planRoomTypePricingsForm.bulk')}`,
        });
        for (
            let i = 0;
            i < (roomType?.standardCapacity || planRoomType?.peopleCount || 0);
            i++
        ) {
            numberOfPeopleDropdown.push({
                value: i + 1,
                label: `${i + 1}${t('plan.create.planRoomTypePricingsForm.person')}`,
            });
        }
        setPeopleCountOption(numberOfPeopleDropdown);
    }, []);

    const onChangeNumberOfPeople = (numberPeople: number) => {
        setPeopleCount(numberPeople);
        const pricingListGroupByPeopleCount: {
            feId: number;
            planRoomTypePricings: IPlanRoomTypePricing[];
        }[] = [];
        const pricingList: IPlanRoomTypePricing[] = [];
        if (numberPeople === 0) {
            const row: IPlanRoomTypePricing[] = [];
            TllRankDropdown.forEach((tllRank, index) => {
                row.push({
                    id: index,
                    peopleCount: 0,
                    tllRank: tllRank.value,
                    price: null,
                });
                pricingList.push({
                    id: index,
                    feId: +`${Date.now()}${Math.random() * 1000}`,
                    peopleCount: 0,
                    tllRank: tllRank.value,
                    price: null,
                });
            });
            pricingListGroupByPeopleCount.push({
                feId: +`${Date.now()}${Math.random() * 1000}`,
                planRoomTypePricings: row,
            });
        } else {
            for (let i = 0; i < numberPeople; i++) {
                const row: IPlanRoomTypePricing[] = [];
                TllRankDropdown.forEach((tllRank, index) => {
                    row.push({
                        id: i * TllRankDropdown.length + index,
                        feId: +`${Date.now()}${Math.random() * 1000}`,
                        peopleCount: i + 1,
                        tllRank: tllRank.value,
                        price: null,
                    });
                    pricingList.push({
                        id: i * TllRankDropdown.length + index,
                        feId: +`${Date.now()}${Math.random() * 1000}`,
                        peopleCount: i + 1,
                        tllRank: tllRank.value,
                        price: null,
                    });
                });
                pricingListGroupByPeopleCount.push({
                    feId: +`${Date.now()}${Math.random() * 1000}`,
                    planRoomTypePricings: row,
                });
            }
        }

        setPricingRoomTypeList(pricingListGroupByPeopleCount);
        setValue(`planRoomTypes.${indexPlanRoomType}.planRoomTypePricings`, pricingList);
        setValue(`planRoomTypes.${indexPlanRoomType}.peopleCount`, numberPeople);
    };

    useEffect(() => {
        setValue(`planRoomTypes.${indexPlanRoomType}.roomTypeId`, roomType.id);
    }, [roomType]);

    return (
        <div className="plan-room-type-pricings-form">
            <Row gutter={8}>
                <div className="form-title pl-24 room-type-title">
                    <span className="text-truncate">{roomType.name}</span>
                </div>
                <div className="form-title">
                    <span>{t('plan.create.planRoomTypePricingsForm.title')}</span>
                </div>
            </Row>
            <Row gutter={12} className="plan-group-people-selector">
                <div className="selector-group-plan">
                    <span className="title-selected">
                        {t('plan.create.planRoomTypePricingsForm.planGroup')}
                    </span>
                    <InputText
                        disabled={checkNotAllowedUsers([...HotelUsers])}
                        control={control}
                        name={`planRoomTypes.${indexPlanRoomType}.planGroupCode`}
                        label=""
                        placeholder={t(
                            'plan.create.planRoomTypePricingsForm.planGroupInput',
                        )}
                    />
                </div>
                <div className="selector-number-of-people">
                    <span className="title-selected">
                        {t('plan.create.planRoomTypePricingsForm.pricing')}
                    </span>
                    <SingleSelect
                        label=""
                        placeholder={t(
                            'plan.create.planRoomTypePricingsForm.planGroupInput',
                        )}
                        name={`planRoomTypes.${indexPlanRoomType}.peopleCount`}
                        id={getFormPlanId(
                            `planRoomTypes.${indexPlanRoomType}.peopleCount`,
                        )}
                        control={control}
                        options={peopleCountOption}
                        onChange={(value) => {
                            onChangeNumberOfPeople(value);
                        }}
                    />
                </div>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <PlanRoomTypePricingTable
                        control={control}
                        indexPlanRoomType={indexPlanRoomType}
                        planRoomTypePricings={pricingRoomTypeList || []}
                        peopleCount={peopleCount}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default PlanRoomTypePricingsForm;
