import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './ImportCsv.scss';
import { EXCEL_ACCEPTED_FILE_TYPES, MAX_FILE_SIZE_IN_BYTE } from '~common/constants';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

interface CustomInput {
    importHeader: string[];
    mapData: (data: any[]) => void;
    setFilename: (filename: string) => void;
    handleLoadFile: () => void;
    previousPage: string;
}

function ImportCsv(props: CustomInput, ref: any) {
    const { importHeader, mapData, setFilename, handleLoadFile, previousPage } = props;
    const { t } = useTranslation();
    const [importErrorMessage, setImportErrorMessage] = useState('');
    const inputRef = useRef<any>(null);
    const navigate = useNavigate();
    useImperativeHandle(ref, () => ({
        // this function is used in parent component
        onClickImport() {
            if (inputRef.current) {
                inputRef.current.click();
            }
        },
    }));

    const validateFileData = (file: File) => {
        const isValidFileSize = file?.size < MAX_FILE_SIZE_IN_BYTE;
        if (!file) {
            setImportErrorMessage(t('common.importCsv.rules.empty') as string);
            return false;
        }
        if (file.name) {
            const finalFileName = file.name.split('.');
            if (
                !EXCEL_ACCEPTED_FILE_TYPES.includes(
                    finalFileName[finalFileName.length - 1],
                )
            ) {
                setImportErrorMessage(
                    t('common.importCsv.rules.invalidType.excel') as string,
                );
                return false;
            }
        }
        if (!isValidFileSize) {
            setImportErrorMessage(t('common.importCsv.rules.tooBig') as string);
            return false;
        }
        return true;
    };

    const paseData = async (file: File) => {
        if (validateFileData(file)) {
            const fileText = await file.arrayBuffer();
            const workbook = XLSX.read(fileText, { codepage: 65001 });
            const data = XLSX.utils.sheet_to_json(
                workbook.Sheets[workbook.SheetNames[0]],
                {
                    header: importHeader,
                    raw: false,
                },
            );

            data.splice(0, 1); //delete header row\
            mapData(data);
            navigate(previousPage);
        }
    };

    const selectFile = (event: any) => {
        handleLoadFile();
        const file = event.target.files[0] as File;
        const reader = new FileReader();
        if (file?.size) {
            reader.readAsText(file);
            paseData(file);
            setFilename(file?.name);
        }
    };

    useEffect(() => {
        if (importErrorMessage?.length) {
            notification.error({
                message: importErrorMessage,
            });
        }
    }, [importErrorMessage]);

    useEffect(() => {
        setImportErrorMessage('');
    }, []);

    return (
        <div className="import-csv-wrapper">
            <input
                type="file"
                ref={inputRef}
                accept={EXCEL_ACCEPTED_FILE_TYPES.join(',')}
                onChange={selectFile}
                className="custom-file-input"
                hidden
            />
        </div>
    );
}

export default forwardRef(ImportCsv);
