import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, notification, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputText, RangePicker, RightDrawerLayout, SingleSelect } from '~components';
import {
    createRankCalendar,
    fetchRankCalendarList,
    formBusySelector,
    selectedRankCalendarDetailSelector,
    setShowForm,
    showFormSelector,
    isUpdatingSelector,
    rankCalendarFormSelector,
    setRankCalendarForm,
} from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { createRankCalendarSchema } from '~features/rank-calendar/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import './RankCalendarForm.scss';
import { useEffect, useState } from 'react';
import { IRankDuration, IRankCalendarForm } from '~features/rank-calendar/interfaces';
import {
    ErrorMessageType,
    INPUT_TEXT_MAX_LENGTH,
    TllRank,
    TllRankDropdown,
} from '~common/constants';
import { compareFormData, parseDateTime } from '~common/commonFunctions';
import { showConfirm } from '~common/notification';
import { useEscape } from '~common/useHooks';
import {
    CalendarDay,
    initRankCalendar,
    TLL_RANK_UNCHANGED,
} from '~features/rank-calendar/constants';

import { Dayjs, parseDate, todayDayjs } from '~plugins/dayjs';
import {
    mergePlanRankDurations,
    transformCreateRankCalendarFormData,
} from '~features/rank-calendar/helper';

function RankCalendarForm() {
    const showForm = useAppSelector(showFormSelector);
    const formBusy = useAppSelector(formBusySelector);
    const rankCalendarDetail = useAppSelector(selectedRankCalendarDetailSelector);
    const rankCalendarForm = useAppSelector(rankCalendarFormSelector);
    const isUpdating = useAppSelector(isUpdatingSelector);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { Title } = Typography;
    const [rankDurationList, setRankDurationList] = useState<IRankDuration[]>([]);
    const [formDataDefault, setFormDataDefault] =
        useState<IRankCalendarForm>(initRankCalendar);

    const { control, getValues, handleSubmit, reset, setValue, setError } = useForm({
        resolver: yupResolver(createRankCalendarSchema),
    });
    const updateTllRankDropdown = [
        ...TllRankDropdown,
        {
            label: t('rankCalendar.form.noChangeOption'),
            value: TLL_RANK_UNCHANGED,
        },
    ];

    useEffect(() => {
        setFormDataDefault({
            ...formDataDefault,
            monday: isUpdating
                ? (TLL_RANK_UNCHANGED as TllRank)
                : TllRankDropdown[0]?.value || TllRank.A,
            tuesday: isUpdating
                ? (TLL_RANK_UNCHANGED as TllRank)
                : TllRankDropdown[0]?.value || TllRank.A,
            wednesday: isUpdating
                ? (TLL_RANK_UNCHANGED as TllRank)
                : TllRankDropdown[0]?.value || TllRank.A,
            thursday: isUpdating
                ? (TLL_RANK_UNCHANGED as TllRank)
                : TllRankDropdown[0]?.value || TllRank.A,
            friday: isUpdating
                ? (TLL_RANK_UNCHANGED as TllRank)
                : TllRankDropdown[0]?.value || TllRank.A,
            saturday: isUpdating
                ? (TLL_RANK_UNCHANGED as TllRank)
                : TllRankDropdown[0]?.value || TllRank.A,
            sunday: isUpdating
                ? (TLL_RANK_UNCHANGED as TllRank)
                : TllRankDropdown[0]?.value || TllRank.A,
        });
    }, [isUpdating]);

    const resetForm = () => {
        setRankDurationList([]);
        reset(formDataDefault);
    };

    useEffect(() => {
        resetForm();
    }, [isUpdating, formDataDefault, showForm]);

    const closeDialog = (reload?: boolean) => {
        dispatch(setShowForm(false));
        resetForm();
        if (reload) {
            dispatch(fetchRankCalendarList());
        }
    };

    const deteteRankDuration = (id: string, index: number) => {
        if (!id) return;
        const planDurations = rankDurationList.filter((item) => {
            return item.id !== id;
        });
        setRankDurationList(planDurations);
    };

    useEffect(() => {
        if (rankDurationList.length === 0) {
            setValue('planDurations', []);
            return;
        }
        setValue('planDurations', rankDurationList);
        rankDurationList.forEach((rankDuration, index) => {
            setValue(`planDurations.${index}.duration`, rankDuration.duration);
            setValue(`planDurations.${index}.tllRank`, rankDuration.tllRank);
        });
    }, [rankDurationList]);

    const addRankDuration = () => {
        setRankDurationList([
            ...rankDurationList,
            { id: Date.now().toString(), duration: null, tllRank: null },
        ]);
    };

    const makeFormData = (): IRankCalendarForm => {
        const {
            name,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            planDurations = [],
        } = getValues();
        return {
            name,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            planRankDurations: planDurations
                .filter((planDuration: { duration: Date[]; tllRank: TllRank }) => {
                    return planDuration.duration && planDuration.tllRank;
                })
                .map(
                    (
                        planDuration: { duration: Date[]; tllRank: TllRank },
                        index: number,
                    ) => {
                        return {
                            tllRank: planDuration.tllRank,
                            startDate: parseDateTime(planDuration.duration[0]),
                            endDate: parseDateTime(planDuration.duration[1]),
                            order: index + 1,
                        };
                    },
                ),
        };
    };

    const _createRankCalendar = async (formData: IRankCalendarForm) => {
        const response = await dispatch(
            createRankCalendar(transformCreateRankCalendarFormData(formData)),
        );
        if (createRankCalendar.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('rankCalendar.form.create.successMessage'),
                });
                closeDialog(true);
                return;
            }
            notification.error({
                message: t('rankCalendar.form.create.errorMessage'),
                description: response.payload?.errors?.[0]?.message || '',
            });
        }
    };

    const _updateRankCalendar = (_formData: IRankCalendarForm) => {
        if (rankCalendarForm) {
            dispatch(
                setRankCalendarForm(mergePlanRankDurations(_formData, rankCalendarForm)),
            );
        }
        closeDialog();
    };

    const checkOverlapTimeRange = (
        dateArray: {
            order: number;
            startDate: string;
            endDate: string;
        }[],
    ) => {
        dateArray.sort(function (d1, d2) {
            return parseDate(d1.startDate).unix() - parseDate(d2.startDate).unix();
        });
        for (let i = 1; i < dateArray.length; i++) {
            if (
                parseDate(dateArray[i - 1].endDate)
                    .add(1, 'day')
                    .isAfter(parseDate(dateArray[i].startDate))
            )
                return {
                    isOverlap: true,
                    order: dateArray[i].order,
                };
        }
        return {
            isOverlap: false,
        };
    };

    const onSubmit = () => {
        const _formData = makeFormData();
        const planRankDurations = _formData?.planRankDurations.map(
            (planRankDuration, index) => {
                return {
                    order: index + 1,
                    startDate: planRankDuration.startDate,
                    endDate: planRankDuration.endDate,
                };
            },
        );

        const checkOverlap = checkOverlapTimeRange(planRankDurations);
        if (checkOverlap.isOverlap) {
            if (checkOverlap?.order) {
                setError(
                    `planDurations.${checkOverlap?.order - 1}.duration`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('rankCalendar.form.overlapMessage'),
                    },
                    { shouldFocus: true },
                );
            }

            notification.error({
                message: t('rankCalendar.form.overlapMessage'),
            });
            return;
        }
        handleSubmit(() => {
            if (isUpdating) {
                _updateRankCalendar(_formData);
                return;
            }
            _createRankCalendar(_formData);
        })();
    };

    const showConfirmCancel = () => {
        if (compareFormData(makeFormData(), formDataDefault)) {
            closeDialog();
            return;
        }

        showConfirm({
            title: t('rankCalendar.form.confirm.cancelTitle'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    useEffect(() => {
        if (isUpdating && rankCalendarDetail) {
            setValue('name', rankCalendarDetail?.name);
        }
    }, [rankCalendarDetail, showForm]);

    const onChangeDuration = (value: Dayjs[] | null, index: number) => {
        rankDurationList[index].duration = value;
    };

    const onChangeTllRank = (value: TllRank | null, index: number) => {
        rankDurationList[index].tllRank = value;
    };

    const disabledDate = (current: Dayjs) => {
        // Can not select days before today
        return current.isBefore(todayDayjs, 'day');
    };

    return (
        <RightDrawerLayout
            open={showForm}
            onClose={showConfirmCancel}
            onSubmit={onSubmit}
            title={
                isUpdating
                    ? t('rankCalendar.form.updateTitle')
                    : t('rankCalendar.form.createTitle')
            }
            cancelText={t('common.buttonCancelText')}
            submitText={
                isUpdating
                    ? t('rankCalendar.form.updateBtn')
                    : t('rankCalendar.form.createBtn')
            }
            busy={!isUpdating && formBusy}
            loading={!isUpdating && formBusy}
            submitId="rank-calendar-form-submit"
        >
            <Form layout="vertical">
                <Row>
                    <Col span={16}>
                        {isUpdating ? (
                            t('rankCalendar.form.updateDescription')
                        ) : (
                            <InputText
                                label={t('rankCalendar.form.name.label')}
                                placeholder={t('rankCalendar.form.name.placeholder')}
                                name="name"
                                control={control}
                                required
                                maxLength={INPUT_TEXT_MAX_LENGTH}
                                id="rank-calendar-form-name"
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Title level={5} className="field-title">
                            {t('rankCalendar.form.dailySettings.title')}
                        </Title>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    {Object.values(CalendarDay).map((calendarDay, index) => (
                        <Col key={index} span={5}>
                            <SingleSelect
                                control={control}
                                name={calendarDay}
                                label={t(
                                    `rankCalendar.form.dailySettings.${calendarDay}`,
                                )}
                                showSearch={false}
                                options={
                                    isUpdating ? updateTllRankDropdown : TllRankDropdown
                                }
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        <Title level={5} className="field-title">
                            {t('rankCalendar.form.rankDuration.title')}
                        </Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {rankDurationList.map((rankDuration, index) => {
                            return (
                                <Row gutter={16} key={rankDuration.id}>
                                    <Col span={17}>
                                        <RangePicker
                                            label={
                                                index === 0
                                                    ? t(
                                                          'rankCalendar.form.rankDuration.duration.label',
                                                      )
                                                    : ''
                                            }
                                            name={`planDurations.${index}.duration`}
                                            control={control}
                                            placeholder={[
                                                t(
                                                    'rankCalendar.form.rankDuration.duration.placeholder.startDate',
                                                ),
                                                t(
                                                    'rankCalendar.form.rankDuration.duration.placeholder.endDate',
                                                ),
                                            ]}
                                            onChange={(value) => {
                                                onChangeDuration(
                                                    value
                                                        ? [
                                                              parseDate(value[0]),
                                                              parseDate(value[1]),
                                                          ]
                                                        : null,
                                                    index,
                                                );
                                            }}
                                            disabledDate={disabledDate}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <SingleSelect
                                            control={control}
                                            name={`planDurations.${index}.tllRank`}
                                            label={
                                                index === 0
                                                    ? t(
                                                          'rankCalendar.form.rankDuration.tllRank.label',
                                                      )
                                                    : ''
                                            }
                                            placeholder={t(
                                                'rankCalendar.form.rankDuration.tllRank.placeholder',
                                            )}
                                            allowClear
                                            showSearch={false}
                                            options={TllRankDropdown}
                                            onChange={(value) => {
                                                onChangeTllRank(
                                                    value ? value : null,
                                                    index,
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col span={1}>
                                        <Form.Item label={index === 0 ? ' ' : ''}>
                                            <DeleteOutlined
                                                onClick={() => {
                                                    deteteRankDuration(
                                                        rankDuration.id,
                                                        index,
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                    <Col span={24}>
                        <Button
                            type="dashed"
                            block
                            className="addition-button"
                            onClick={addRankDuration}
                        >
                            <PlusCircleOutlined />
                            {t('rankCalendar.form.additionBtn')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </RightDrawerLayout>
    );
}

export default RankCalendarForm;
