import marketingChannelGroupListJa from './ja/marketing-channel-group-list.locale';
import marketingChannelGroupFormJa from './ja/marketing-channel-group-form.locale';
import marketingChannelGroupListEn from './en/marketing-channel-group-list.locale';
import marketingChannelGroupFormEn from './en/marketing-channel-group-form.locale';
const marketingChannelGroup = {
    ja: {
        list: marketingChannelGroupListJa,
        form: marketingChannelGroupFormJa,
    },
    en: {
        list: marketingChannelGroupListEn,
        form: marketingChannelGroupFormEn,
    },
};

export default marketingChannelGroup;
