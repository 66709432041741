import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { operationStatusBreadcrumbs } from '~features/report/constants';
import './OperationStatusPage.scss';
import OperationStatusTable from './components/OperationStatusTable/OperationStatusTable';
import FilterForm from './components/FilterForm/FilterForm';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~hooks';
import { fetchMarketingChannelDropDown } from '~features/marketing-channel/marketing-channel.reducer';
import { operationStatusStateSelector } from '~features/report/reducers/report-operation-status.reducer';

const { Content } = Layout;

function OperationStatusPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isShowTable } = useAppSelector(operationStatusStateSelector);

    useEffect(() => {
        dispatch(fetchMarketingChannelDropDown({}));
    }, []);

    return (
        <div>
            <StaffLayoutHeader
                breadcrumbs={[...operationStatusBreadcrumbs()]}
                title={t('report.operationStatus.titlePage')}
            />
            <Content>
                <div className="cancellation-page-wrapper">
                    <FilterForm />
                    {isShowTable && <OperationStatusTable />}
                </div>
            </Content>
        </div>
    );
}

export default OperationStatusPage;
