import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { trim, cloneDeep } from 'lodash';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
import { AppState } from '~plugins/redux-toolkit/store';
import { IndicatorSize, IndicatorType, OrderBy, SearchField } from '../constants';
import {
    ICellSelected,
    IIndicatorForm,
    IIndicatorListQuery,
    IIndicatorState,
    IRoomInfo,
} from '../interfaces';
import { indicatorService } from '../services/indicator.service';

export const defaultCellSelected = {
    [IndicatorType.ROOM]: {},
    [IndicatorType.FACILITY]: {},
};

const indicatorListQueryDefault = {
    keyword: '',
    autoGeneratedCode: '',
    name: '',
    indicatorType: '',
    layout: '',
    orderBy: OrderBy.ID,
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

const initialState: IIndicatorState = {
    indicatorListQuery: cloneDeep(indicatorListQueryDefault),
    indicatorList: [],
    totalIndicator: 0,
    showListLoading: false,
    isFetchingDetail: false,
    isIndicatorSubmitting: false,
    searchField: SearchField.ALL,
    cellSelected: {
        [IndicatorType.ROOM]: {},
        [IndicatorType.FACILITY]: {},
    },
    assignedList: {
        [IndicatorType.ROOM]: {},
        [IndicatorType.FACILITY]: {},
    },
    settingType: IndicatorType.ROOM,
    settingSize: IndicatorSize.SMALL,
    isMultipleSelect: false,
    indicatorName: '',
};

export const fetchIndicatorList = createAsyncThunk(
    'indicator/fetchIndicatorList',
    async (_, { getState }) => {
        const query = indicatorListQuerySelector(getState() as AppState);
        return await indicatorService.getList(query);
    },
);

export const createIndicator = createAsyncThunk(
    'indicator/createIndicator',
    async (formData: IIndicatorForm) => {
        return await indicatorService.create(formData);
    },
);

export const getIndicatorDetail = createAsyncThunk(
    'indicator/getIndicatorDetail',
    async (id: number) => {
        return await indicatorService.detail(id);
    },
);

export const updateIndicator = createAsyncThunk(
    'indicator/updateIndicator',
    async (data: { id: number; formData: IIndicatorForm }) => {
        return await indicatorService.update(data.id, data.formData);
    },
);

const indicatorSlice = createSlice({
    name: 'indicator',
    initialState,
    reducers: {
        setIndicatorListQuery: (state, action) => {
            state.indicatorListQuery = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: IIndicatorListQuery = {
                orderBy: state.indicatorListQuery.orderBy,
                limit: state.indicatorListQuery.limit,
                orderDirection: state.indicatorListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.indicatorListQuery.keyword ||
                    state.indicatorListQuery.autoGeneratedCode ||
                    state.indicatorListQuery.name ||
                    state.indicatorListQuery.indicatorType ||
                    state.indicatorListQuery.layout ||
                    '',
            };
            state.indicatorListQuery = query;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IIndicatorListQuery = {
                orderBy: state.indicatorListQuery.orderBy,
                limit: state.indicatorListQuery.limit,
                orderDirection: state.indicatorListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.indicatorListQuery = query;
        },
        setCellSelected: (state, action) => {
            state.cellSelected = action.payload;
        },
        updateCellSelected: (state, action: PayloadAction<ICellSelected>) => {
            const { x, y, type } = action.payload;
            if (!state.cellSelected[type]) {
                state.cellSelected[type] = {};
            }
            state.cellSelected[type][`${y}-${x}`] = action.payload;
        },
        setSettingSize: (state, action) => {
            state.settingSize = action.payload;
        },
        setAssignedList: (state, action) => {
            state.assignedList = action.payload;
        },
        updateAssignedList: (state, action: PayloadAction<IRoomInfo[]>) => {
            action.payload.forEach((item) => {
                state.assignedList[item.type][`${item.y}-${item.x}`] = item;
            });
        },
        removeRoomSetting: (
            state,
            action: PayloadAction<{ x: number; y: number; type: IndicatorType }>,
        ) => {
            const { x, y, type } = action.payload;
            delete state.assignedList[type][`${y}-${x}`];
        },
        setSettingType: (state, action) => {
            state.settingType = action.payload;
        },
        setMultipleSelect: (state, action) => {
            state.isMultipleSelect = action.payload;
        },
        setIndicatorName: (state, action) => {
            state.indicatorName = action.payload;
        },
        resetForm: (state, action) => {
            state.cellSelected =
                action.payload.cellSelected || cloneDeep(defaultCellSelected);
            state.assignedList =
                action.payload.assignedList || cloneDeep(defaultCellSelected);
            state.settingType = action.payload.settingType || IndicatorType.ROOM;
            state.settingSize = action.payload.settingSize || IndicatorSize.SMALL;
            state.isMultipleSelect = action.payload.isMultipleSelect || false;
            state.indicatorName = action.payload.indicatorName || '';
        },
        resetQuery: (state) => {
            state.indicatorListQuery = cloneDeep(indicatorListQueryDefault);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchIndicatorList.pending, (state, action) => {
            state.showListLoading = true;
        });
        builder.addCase(fetchIndicatorList.fulfilled, (state, action) => {
            state.indicatorList = action.payload?.data?.items || [];
            state.totalIndicator = action.payload?.data?.totalItems || 0;
            state.showListLoading = false;
        });
        builder.addCase(createIndicator.pending, (state, action) => {
            state.isIndicatorSubmitting = true;
        });
        builder.addCase(createIndicator.fulfilled, (state, action) => {
            state.isIndicatorSubmitting = false;
        });
        builder.addCase(updateIndicator.pending, (state, action) => {
            state.isIndicatorSubmitting = true;
        });
        builder.addCase(updateIndicator.fulfilled, (state, action) => {
            state.isIndicatorSubmitting = false;
        });
        builder.addCase(getIndicatorDetail.pending, (state, action) => {
            state.isFetchingDetail = true;
        });
        builder.addCase(getIndicatorDetail.fulfilled, (state, action) => {
            state.isFetchingDetail = false;
        });
    },
});

export const {
    setCellSelected,
    setSettingSize,
    removeRoomSetting,
    setAssignedList,
    updateAssignedList,
    setSettingType,
    setMultipleSelect,
    setIndicatorName,
    updateCellSelected,
    resetForm,
    resetQuery,
} = indicatorSlice.actions;

export const { setIndicatorListQuery, setSearchField, setKeyword } =
    indicatorSlice.actions;

export const indicatorSelector = (state: AppState) => {
    return state.indicator;
};

export const indicatorListSelector = (state: AppState) => {
    return state.indicator.indicatorList;
};

export const cellSelectedSelector = (state: AppState) => {
    return state.indicator.cellSelected;
};

export const indicatorListQuerySelector = (state: AppState) => {
    return state.indicator.indicatorListQuery;
};

export const totalIndicatorSelector = (state: AppState) => {
    return state.indicator.totalIndicator;
};

export const totalPageSelector = (state: AppState) => {
    const { totalIndicator, indicatorListQuery } = state.indicator;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = indicatorListQuery;
    return Math.ceil(totalIndicator / limit);
};

export const showListLoadingSelector = (state: AppState) => {
    return state.indicator.showListLoading;
};

export default indicatorSlice.reducer;
