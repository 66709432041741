import { Form, Input, InputProps } from 'antd';
import classNames from 'classnames';
import { fullWidthNumConvert, parseErrorMessage } from '~common/commonFunctions';
import { Regex } from '~common/constants';
import { Controller } from '~plugins/hook-form';
import './InputPostalCode.scss';

interface CustomInput extends InputProps {
    name: string;
    label: string;
    isShowIconArrow: boolean;
    defaultValue: string | number;
    control: any;
    hideHelpText?: boolean;
}

export function InputPostalCode(props: CustomInput) {
    const {
        control,
        name,
        defaultValue,
        label,
        onChange,
        required,
        isShowIconArrow,
        hideHelpText,
        ...rest
    } = props;
    const intPattern = new RegExp(Regex.POSTAL_CODE_PATTERN);

    return (
        <div
            className={classNames('custom-input-postal-code-wrapper', {
                'hide-help-text': hideHelpText,
            })}
        >
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            required={required}
                        >
                            <Input
                                {...field}
                                {...rest}
                                onChange={(event) => {
                                    const val = event.target.value;
                                    if (val && !intPattern.test(val)) {
                                        event.target.value = '';
                                        return;
                                    }
                                    if (onChange) {
                                        onChange(event);
                                    }
                                    field.onChange(event);
                                }}
                                onBlur={(event) => {
                                    const value = event.target.value;
                                    if (Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                                        event.target.value =
                                            fullWidthNumConvert(value) || '';
                                        if (onChange) {
                                            onChange(event);
                                        }
                                        field.onChange(event);
                                    }
                                }}
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

InputPostalCode.defaultProps = {
    defaultValue: '',
    isShowIconArrow: false,
};
