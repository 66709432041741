import { Radio, RadioChangeEvent, Select } from 'antd';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    DEFAULT_NUMBER_OF_GROUPS,
    INVOICE_PATH,
    MAX_NUMBER_OF_SPLIT_RECEIPT_GROUP,
    SelectSplitBillTypeOptions,
} from '~features/room-booking/constants';
import {
    selectedSplitBillTypeSelector,
    setNumberOfGroups,
    setSelectedSplitBillType,
    splitReceiptStateSelector,
} from '~features/room-booking/reducers/split-receipt.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './SelectSplitBillTypePanel.scss';

interface IProps {
    isShowSplitByRoom: boolean;
}

interface IOption {
    value: SelectSplitBillTypeOptions;
    label: string;
}

function SelectSplitBillTypePanel(props: IProps) {
    const { isShowSplitByRoom } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const selectedSplitBillType = useAppSelector(selectedSplitBillTypeSelector);
    const { numberOfGroups } = useAppSelector(splitReceiptStateSelector);

    const numberOfGroupsOptions = useMemo(() => {
        return Array.from(Array(MAX_NUMBER_OF_SPLIT_RECEIPT_GROUP).keys()).map(
            (number) => ({
                label: `${number + 1}${t(
                    'roomBooking.splitReceipt.selectSplitBillTypePanel.split',
                )}`,
                value: number + 1,
            }),
        );
    }, []);

    const selectSplitBillTypeOptions = useMemo(() => {
        const options: IOption[] = [];
        for (const option of Object.values(SelectSplitBillTypeOptions)) {
            if (
                !isShowSplitByRoom &&
                option === SelectSplitBillTypeOptions.BASED_ON_ROOM
            ) {
                continue;
            }
            options.push({
                value: option,
                label: t(`roomBooking.splitReceipt.selectSplitBillTypePanel.${option}`),
            });
        }

        return options;
    }, [isShowSplitByRoom]);

    useEffect(() => {
        if (selectSplitBillTypeOptions.length > 1) {
            dispatch(setSelectedSplitBillType(SelectSplitBillTypeOptions.BASED_ON_ROOM));
        } else {
            dispatch(setSelectedSplitBillType(SelectSplitBillTypeOptions.BASED_ON_GROUP));
        }
    }, [selectSplitBillTypeOptions]);

    const onChange = (event: RadioChangeEvent) => {
        const {
            target: { value },
        } = event;

        dispatch(setSelectedSplitBillType(value));
    };

    const onChangeNumberOfGroups = (number: number) => {
        dispatch(setNumberOfGroups(number));
    };

    useEffect(() => {
        return () => {
            dispatch(setNumberOfGroups(DEFAULT_NUMBER_OF_GROUPS)); // reset number of groups
        };
    }, []);

    const isShowSplitGroupSelect = useMemo(() => {
        return selectedSplitBillType === SelectSplitBillTypeOptions.BASED_ON_GROUP;
    }, [selectedSplitBillType]);

    return (
        <div className="split-room-booking-select-split-bill-type-wrapper">
            <div className="title">
                {pathname.endsWith(INVOICE_PATH) ? t('roomBooking.splitReceipt.titleInvoice') : t('roomBooking.splitReceipt.selectSplitBillTypePanel.title')}
            </div>
            <div className="body">
                <div className="select-split-bill-type">
                    <div className="division-type-title">
                        {t(
                            'roomBooking.splitReceipt.selectSplitBillTypePanel.divisionType',
                        )}
                    </div>
                    <Radio.Group
                        options={selectSplitBillTypeOptions}
                        optionType="button"
                        value={selectedSplitBillType}
                        onChange={onChange}
                    />
                </div>
                {isShowSplitGroupSelect && (
                    <div className="group-number-select">
                        <div className="split-title">
                            {t('roomBooking.splitReceipt.selectSplitBillTypePanel.split')}
                        </div>
                        <Select
                            options={numberOfGroupsOptions}
                            value={numberOfGroups}
                            onChange={onChangeNumberOfGroups}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default SelectSplitBillTypePanel;
