export const appConfig = {
    region: process.env.REACT_APP_COGNITO_REGION || '',
    poolId: process.env.REACT_APP_COGNITO_POOL_ID || '',
    domain: process.env.REACT_APP_COGNITO_DOMAIN || '',
    clientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID || '',
    resetPasswordURL: process.env.REACT_APP_PRICE_ENGINE_WEB_RESET_PASSWORD || '',
    freshChatToken:
        process.env.REACT_APP_FRESH_CHAT_TOKEN_API ||
        '4c81e157-a3ac-423f-a28c-5daeea568dab',
};
