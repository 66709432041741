import { Button, Card, Popover } from 'antd';
import { QueryClientProvider } from '@tanstack/react-query';
import { useAppSelector } from '~hooks';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import { scheduleStateSelector } from '~features/room-booking/reducers/schedule.reducer';
import StoppingRoomsModal from '../StoppingRoomsModal/StoppingRoomsModal';
import queryClient from '~queries/queries';
import { CreateModal } from '../CreateModal/CreateModal';
import { useState } from 'react';
import {
    ScheduleCellPopupActions,
    StoppingRoomsActions,
} from '~features/room-booking/constants';
import { useTranslation } from 'react-i18next';

type Props = {
    booking: IRoomBookingSchedule;
    isBlockedRoomsSelecting: boolean;
    onClose: () => void;
};
type ScheduleCellPopupActionType = keyof typeof ScheduleCellPopupActions;

const ScheduleCellPopup = ({ booking, isBlockedRoomsSelecting, onClose }: Props) => {
    const { t } = useTranslation();
    const { visibleCreateBookingPopover } = useAppSelector(scheduleStateSelector);
    const [currentModalType, setCurrentModalType] = useState<ScheduleCellPopupActionType>(
        ScheduleCellPopupActions.ACTION_SELECT,
    );

    const getContent = () => {
        // Releasing Rooms Modal case
        if (isBlockedRoomsSelecting) {
            return (
                <QueryClientProvider client={queryClient}>
                    <StoppingRoomsModal
                        close={onClose}
                        booking={booking}
                        usePopover={true}
                        actionType={StoppingRoomsActions.RELEASE_ROOM}
                    />
                </QueryClientProvider>
            );
        }

        // Action Select Popup case
        if (currentModalType === ScheduleCellPopupActions.ACTION_SELECT) {
            return (
                <Card
                    title={t('roomBooking.schedule.scheduleCellPopup.title')}
                    className="create-booking-card"
                >
                    <div className="booking-card-footer">
                        <Button onClick={onClose}>{t('common.buttonCancelText')}</Button>
                        <Button
                            className="btn-submit"
                            type="primary"
                            onClick={() =>
                                setCurrentModalType(
                                    ScheduleCellPopupActions.CREATE_BOOKING,
                                )
                            }
                        >
                            {t('roomBooking.schedule.scheduleCellPopup.createBooking')}
                        </Button>
                        <Button
                            className="btn-submit"
                            type="primary"
                            onClick={() =>
                                setCurrentModalType(ScheduleCellPopupActions.BLOCK_ROOMS)
                            }
                        >
                            {t('roomBooking.schedule.scheduleCellPopup.stopRooms')}
                        </Button>
                    </div>
                </Card>
            );
        }

        // Creating Booking Modal case
        if (currentModalType === ScheduleCellPopupActions.CREATE_BOOKING) {
            return <CreateModal booking={booking} close={onClose} usePopover={false} />;
        }

        // Blocking Rooms Modal case
        return (
            <QueryClientProvider client={queryClient}>
                <StoppingRoomsModal
                    close={onClose}
                    booking={booking}
                    usePopover={true}
                    actionType={StoppingRoomsActions.BLOCK_ROOM}
                />
            </QueryClientProvider>
        );
    };

    return (
        <Popover
            content={getContent()}
            trigger="click"
            open={visibleCreateBookingPopover}
            overlayClassName="create-booking-modal"
            placement="left"
        />
    );
};

export default ScheduleCellPopup;
