const roomOrderSettings = {
    title: '部屋順序設定',
    breadCrumbs: {
        roomOrderSettings: '部屋順序設定',
    },
    header: {
        sortOption: {
            ascending: '部屋で並び替え（昇順）',
            descending: '部屋で並び替え（降順）',
        },
        button: {
            expandAll: 'すべて開く',
            collapseAll: 'すべて閉じる',
        },
    },
    notification: {
        success: '部屋順序が変更されました',
    },
};

export default roomOrderSettings;
