import { useTranslation } from 'react-i18next';

interface IProps {
    title: string;
    phone?: string;
    mail?: string;
}

function HotelSmartContactInfo({ title, phone, mail }: IProps) {
    const { t } = useTranslation();
    return (
        <div className="hotel-smart-item">
            <p className="font-weight-text-600">{title}</p>
            {phone && (
                <p>
                    {t('roomBooking.detail.hotelSmartCard.phone')}: {phone}
                </p>
            )}
            {mail && (
                <p>
                    {t('roomBooking.detail.hotelSmartCard.mail')}: {mail}
                </p>
            )}
        </div>
    );
}

export default HotelSmartContactInfo;
