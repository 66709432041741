const guestUpdate = {
    title: 'ゲスト情報の編集',
    breadcrumbs: {
        home: 'ホーム',
        update: 'ゲスト情報の編集',
    },
    tabPane: {
        basicInfo: '基本情報',
        companyInfo: '会社情報',
        bookingHistory: '履歴',
    },
    file: {
        notExist: 'There is a attachment which is not found',
        notBelongToGuest: 'There is a attachment which is not belong to this guest',
        guestFile: {
            notExist: 'There is a guest attachment which is not found',
        },
    },
    uploadFile: {
        upload: {
            error: 'An error occurred while uploading the file',
        },
    },
};

export default guestUpdate;
