import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { planListBreadcrumbs } from '../../constants';
import './PlanListPage.scss';
import { Layout } from 'antd';
import FilterForm from './components/FilterForm/FilterForm';
import PlanList from './components/PlanList/PlanList';
function PlanListPage() {
    const { t } = useTranslation();
    return (
        <div className="plan-list-page">
            <StaffLayoutHeader
                breadcrumbs={planListBreadcrumbs()}
                title={t('plan.list.title')}
            />
            <Layout.Content>
                <div className="plan-page-wrapper">
                    <div className="plan-page-content">
                        <FilterForm />
                        <PlanList />
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default PlanListPage;
