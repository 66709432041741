import React from 'react';
import { useTranslation } from 'react-i18next';
import TransactionDetail from './TransactionDetail';
import { ITransactionItem } from '~features/room-booking/interfaces';

interface IProps {
    billings: ITransactionItem[];
    payments: ITransactionItem[];
}
export default function TransactionHistory({ billings, payments }: IProps) {
    const { t } = useTranslation();
    return (
        <div>
            <p className="font-weight-text-600">
                {t('roomBooking.detail.hotelSmartCard.addSalesPayment')}
            </p>
            <TransactionDetail
                title={t('roomBooking.detail.hotelSmartCard.billings')}
                transactions={billings}
                showAll={false}
            />
            <TransactionDetail
                title={t('roomBooking.detail.hotelSmartCard.payments')}
                transactions={payments}
                showAll={false}
            />
        </div>
    );
}
