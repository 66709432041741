const guestClassificationList = {
    title: 'ゲスト識別設定',
    breadcrumbs: {
        home: 'ホーム',
        guestClassification: 'ゲスト識別設定',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '販売チャネル',
    },
    searchBox: '検索',
    textSearch: '検索:',
    primaryButton: 'ゲスト識別登録',
    guestClassificationListColumn: {
        id: 'ID',
        category: 'ゲスト識別',
        categoryColor: '色',
    },
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
        title: '確認',
    },
    confirm: {
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
        cancelTitle: 'キャンセルしてもよろしいですか？',
        cantDelete: `ゲスト識別項目が削除できません`,
    },
    message: {
        deleteSuccess: 'ゲスト識別が削除されました',
    },
    exportColumns: {
        autoGeneratedCode: 'ID',
        name: 'ゲスト識別',
        color: '色',
    },
};

export default guestClassificationList;
