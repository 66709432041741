import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { cancellationBreadcrumbs } from '~features/report/constants';
import { cancellationStateSelector } from '~features/report/reducers/report-cancellation.reducer';
import { useAppSelector } from '~hooks';
import CancellationTable from './components/CancellationTable/CancellationTable';
import FilterForm from './components/FilterForm/FilterForm';
import UpdateMemoModal from './components/UpdateMemoModal/UpdateMemoModal';
import './CancellationPage.scss';

const { Content } = Layout;

function CancellationPage() {
    const { t } = useTranslation();
    const { isShowTable } = useAppSelector(cancellationStateSelector);
    return (
        <div>
            <StaffLayoutHeader
                breadcrumbs={[...cancellationBreadcrumbs()]}
                title={t('report.cancellation.titlePage')}
            />
            <Content>
                <div className="cancellation-page-wrapper">
                    <FilterForm />
                    {isShowTable && <CancellationTable />}
                    <UpdateMemoModal />
                </div>
            </Content>
        </div>
    );
}

export default CancellationPage;
