import { Button, Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './BookingTemporaryGridMode.scss';
import { BookingTemporaryCard } from '../../BookingTemporaryCard/BookingTemporaryCard';
import { useAppSelector } from '~hooks';
import { scheduleStateSelector } from '~features/room-booking/reducers/schedule.reducer';
import { useMemo, useRef, useState } from 'react';
import { CarouselRef } from 'antd/lib/carousel';

function BookingTemporaryGridMode() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const { bookingTemporaryList } = useAppSelector(scheduleStateSelector);
    const slider = useRef<CarouselRef>(null);
    const slidesPerRow = 4;

    const canNext = useMemo(
        () => currentIndex + 1 < Math.ceil(bookingTemporaryList.length / slidesPerRow),
        [bookingTemporaryList, currentIndex],
    );
    const canBack = useMemo(() => currentIndex > 0, [currentIndex]);
    const onChange = (key: string) => {
        if (key === 'next') {
            if (canNext) {
                setCurrentIndex(currentIndex + 1);
                slider.current?.next();
            }
        } else if (key === 'prev') {
            if (canBack) {
                setCurrentIndex(currentIndex - 1);
                slider.current?.prev();
            }
        }
    };
    return (
        <div className="booking-temporary-grid-mode">
            <Button
                className="absolute top left h-100 btn-control"
                disabled={!canBack}
                onClick={() => onChange('prev')}
            >
                <LeftOutlined />
            </Button>
            <Carousel
                effect="scrollx"
                dots={false}
                slidesPerRow={slidesPerRow}
                initialSlide={currentIndex}
                ref={slider}
            >
                {bookingTemporaryList.map((booking) => (
                    <div className="carousel-booking-item" key={booking.id}>
                        <BookingTemporaryCard booking={booking} />
                    </div>
                ))}
            </Carousel>
            <Button
                className="absolute right top h-100 btn-control"
                disabled={!canNext}
                onClick={() => onChange('next')}
            >
                <RightOutlined />
            </Button>
        </div>
    );
}

export default BookingTemporaryGridMode;
