import { Card, Divider } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '~common/commonFunctions';
import { IBookingConfirmDataItem } from '~features/room-booking/interfaces';
import { createBookingStateSelector } from '~features/room-booking/reducers/create-booking.reducer';
import { useAppSelector } from '~hooks';
import './GuestBookingInfo.scss';

export const GuestBookingInfo = () => {
    const { t } = useTranslation();
    const { bookingInfo, bookingConfirmData } = useAppSelector(
        createBookingStateSelector,
    );

    const totalPrice = useMemo(() => {
        const amount = bookingConfirmData.reduce((acc, cur) => {
            return acc + (cur.booking.price || 0);
        }, 0);
        return formatMoney(amount);
    }, [bookingConfirmData]);

    const totalTax = useMemo(() => {
        const amount = bookingConfirmData.reduce((acc, cur) => {
            return acc + (cur.booking.tax || 0);
        }, 0);
        return formatMoney(amount);
    }, [bookingConfirmData]);

    const renderRow = (item: IBookingConfirmDataItem) => {
        return (
            <div className="row-info mb-12" key={`${item.booking?.id}`}>
                <div className="row-info-label">
                    <div className="text-truncate-50">{item.booking?.roomType?.name}</div>
                    /<div className="text-truncate-50">{item.booking?.room?.name}</div>
                </div>
                <div className="row-info-value">
                    <div className="name">
                        <div className="text-truncate">
                            {item.booking?.guest?.yomigana ||
                                bookingInfo.representativeGuest?.yomigana}
                        </div>
                        {item.booking?.isRepresentativeRoom && (
                            <div className="representative-room">
                                {t('roomBooking.confirmPage.representativeRoom')}
                            </div>
                        )}
                    </div>
                    <div className="price">¥{formatMoney(item.booking.price || 0)}</div>
                </div>
            </div>
        );
    };
    return (
        <Card className="guest-booking-info-card mt-24">
            <div className="total d-flex j-between a-center">
                <div>{t('roomBooking.confirmPage.totalPrice')}</div>
                <div className="price">¥ {totalPrice}</div>
            </div>
            <Divider />
            {bookingConfirmData.map((item) => {
                return renderRow(item);
            })}
            <div className="row-info mb-12">
                <div className="row-info-value">
                    <span className="name">{t('roomBooking.confirmPage.totalTax')}</span>
                    <span className="price">¥ {totalTax}</span>
                </div>
            </div>
        </Card>
    );
};
