import { ReceiptItemType } from './../../../room-booking/constants';
import {
    ReceiptItemBookingType,
    ReceiptItemDetailType,
    RoomBookingItemBookingStatus,
} from './../../constants';
import { FacilityBookingStatus } from '~features/facility-booking/constants';

const historyDetail = {
    memoInput: '備考を入力',
    goToPage: 'ページへ移動',
    update: {
        success: '履歴が更新されました',
        failed: '履歴が更新されませんでした',
    },
    memo: 'メモ',
    activityType: 'アクティビティタイプ',
    noData: 'データがありません',
    applicableRoomValue: '宿泊{{value}}',
    key: {
        childrenType: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            afterData: '後',
            beforeData: '前',
            name: '名前',
            priceType: '値段タイプ',
            priceValue: '値段数値',
            linkageCode: 'リンケージコード',
        },
        facility: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            afterData: '後',
            beforeData: '前',
            facilityType: '施設タイプ',
        },
        facilityBooking: {
            createdData: '作成データ',
            deletedData: '削除データ',
            status: 'ステータス',
            afterData: '後',
            beforeData: '前',
            facility: '施設',
            name: '名前',
            saleItemCheckIns: 'セール項目チェックイン',
            endDatetime: '終了日',
            startDatetime: '開始日',
            guest: 'ゲスト',
            birthday: '生年月日',
            fullName: '名前',
            yomigana: 'よみがな',
            emailAddress: 'Email',
            mobilePhoneNumber: '携帯電話番号',
            numberOfGuests: 'ゲスト人数',
            memo: 'メモ',
            price: '値段',
            saleItem: 'セール項目',
            tax: '税',
            type: 'タイプ',
            isActive: 'アクティブです',
            createdAt: 'に作成',
            createdBy: 'が作成',
            deletedAt: 'に削除',
            deletedBy: 'が削除',
            updatedAt: 'に更新',
            updatedBy: 'が削除',
            pricingType: '値段タイプ',
            pricingValue: '値段数値',
            isSeedData: 'シードデータ',
            isShowInBooking: '予約に表示',
            autoGeneratedCode: 'コード',
        },
        facilityType: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            afterData: '後',
            beforeData: '前',
            saleItems: 'セール項目',
            abbreviation: '省略',
            duration: '期間',
            price: '値段',
            businessEndTime: '営業終了時刻',
            businessStartTime: '営業開始時刻',
            facilityTypeNumber: '施設タイプ番号',
            currency: '円',
        },
        guestClassification: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            color: '色',
            beforeData: '前',
            afterData: '後',
        },
        marketingChannel: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            afterData: '後',
            beforeData: '前',
            linkageCode: 'リンケージコード',
            linkageCodeDayUse: 'デイユースリンケージコード',
            marketingChannelGroup: '販売チャネルグループ',
        },
        paymentMethod: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            afterData: '後',
            beforeData: '前',
        },
        room: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            memo: 'メモ',
            name: '名前',
            afterData: '後',
            beforeData: '前',
            roomType: '部屋タイプ',
            roomTypeId: '部屋タイプID',
            cleaningStatus: '清掃ステータス',
        },
        saleGroup: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            afterData: '後',
            beforeData: '前',
            saleItems: '販売項目',
        },
        saleItem: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            tax: '税',
            name: '名前',
            taxName: '税名Tax',
            beforeData: '前',
            afterData: '後',
            price: '値段',
            isShowInBooking: '予約に表示',
            taxId: '税ID',
            saleItemNumber: '販売項目番号',
        },
        marketingChannelGroup: {
            createdData: '作成されたデータ',
            autoGeneratedCode: 'ID',
            deletedData: '削除されたデータ',
            name: '名前',
            afterData: '後',
            beforeData: '前',
        },
        plan: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            afterData: '後',
            planRoomTypes: '部屋プランタイプ',
            roomType: '部屋タイプ',
            beforeData: '前',
            standardCapacity: '標準収容人数',
            planRoomTypeId: 'プラン部屋タイプID',
            roomTypeId: '部屋タイプID',
            peopleCount: '人数',
            planRoomTypePricings: '部屋プランタイプ値段P',
            price: '値段',
            tllRank: 'TLLランク',
            planSaleItems: 'セールプラン項目',
            tax: '税',
            memo: 'メモ',
            taxId: '税ID',
            planRank: 'プランランク',
            planType: 'プランタイプ',
            planRankId: 'プランランク',
            checkInTime: 'チェックイン時刻',
            checkOutTime: 'チェックアウト時刻',
            quantity: '数量',
            saleItem: '販売項目',
            isFirstDayOnly: '初日のみ',
            isForOnePeople: '1人用',
        },
        tax: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            afterData: '後',
            beforeData: '前',
            isActive: 'アクティブ',
        },
        guest: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            fullName: '名前',
            afterData: '後',
            beforeData: '前',
            memo: 'メモ',
            gender: '性別',
            address: '住所',
            country: '国',
            key: 'キー',
            birthday: '生年月日',
            yomigana: 'よみがな',
            countryId: '国ID',
            postalCode: '郵便番号',
            companyName: '会社',
            emailAddress: 'Email',
            classification: 'ゲスト識別',
            classificationId: 'ゲスト識別ID',
            name: '名前',
            companyAddress: '会社住所',
            companyYomigana: '会社よみがな',
            companyPostalCode: '会社郵便番号',
            mobilePhoneNumber: '電話番号',
            companyPhoneNumber: '会社電話番号',
            companyEmailAddress: '会社メール',
        },
        roomType: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            marketingChannels: '販売チャネル',
            abbreviation: '省力',
            standardCapacity: '標準収容人数',
            stoppingRoomCount: '部屋カウント締め',
            afterData: '後',
            beforeData: '前',
            roomTypeCode: '部屋タイプコード',
        },
        indicator: {
            autoGeneratedCode: 'ID',
            name: '名前',
            afterData: '後',
            beforeData: '前',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            autoGeneratedCodeIndicator: '自動生成コード',
            indicatorItems: 'インジケータ項目',
            room: '部屋',
            roomId: '部屋ID',
            colIndex: '列',
            rowIndex: '行',
            layout: 'レイアウト',
            indicatorType: 'インジケータ項目',
        },
        planRankCalendar: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            name: '名前',
            afterData: '後',
            beforeData: '前',
            planRankDurations: 'プランランク期間',
            endDate: '終了日',
            startDate: '開始日',
            tllRank: 'TLLランク',
            dayOfWeek: '曜日',
            status: 'ステータス',
        },
        roomBooking: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            representativeBookingItem: '代表者予約項目',
            memo: 'メモ',
            status: 'ステータス',
            reserverGuest: '予約者フゲスト',
            gender: '性別',
            birthday: '生年月日',
            fullName: '名前',
            yomigana: 'よみがな',
            emailAddress: 'メール',
            mobilePhoneNumber: '携帯電話番号',
            marketingChannel: '販売チャネル',
            name: '名前',
            room: '部屋',
            roomBookingItems: '部屋予約項目',
            plan: 'プラン',
            isDayUse: 'デイユース',
            checkInTime: 'チェックイン時刻',
            checkOutTime: 'チェックアウト時刻',
            bookingStatus: '予約ステータス',
            endDateOfStay: '宿泊最終日',
            startDateOfStay: '宿泊開始日',
            numberOfAdults: '大人の人数',
            isRepresentativeRoom: '代表者の部屋',
            roomBookingItemChildrenTypes: '部屋予約項目子供区分',
            quantity: '数量',
            representativeGuest: '代表者ゲスト',
            isReserverTheRepresentative: '予約者は代表者本人ですか？',
            beforeData: '前',
            afterData: '後',
            roomType: '部屋タイプ',
            guests: 'ゲスト',
            companionBookingItems: '予約詳細',
            usageDetails: '使用詳細',
            date: '日付',
            amount: '金額',
            planSaleItemNames: 'プランの販売項目名',
            numberOfChildren: '子供の人数',
            pricingSettings: '値段設定',
            tllRank: 'TLLランク',
            planSaleItems: 'プランの販売項目',
            planRoomTypePricing: 'プランのルームタイプ値段',
            price: '値段',
            peopleCount: '人数',
            numberOfMale: '男性',
            numberOfFemale: '女性',
            numberOfOtherGenderGuest: 'その他',
        },
        overbooking: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            representativeBookingItem: '代表者予約項目',
            memo: 'メモ',
            status: 'ステータス',
            reserverGuest: '予約者ゲスト',
            gender: '性別',
            birthday: '生年月日',
            fullName: '名前',
            yomigana: 'よみがな',
            emailAddress: 'メール',
            mobilePhoneNumber: '携帯電話番号',
            marketingChannel: '販売チャネル',
            name: '名前',
            room: '部屋',
            roomBookingItems: '部屋予約項目',
            plan: 'プラン',
            isDayUse: 'デイユース',
            checkInTime: 'チェックイン時刻',
            checkOutTime: 'チェックアウト時刻',
            bookingStatus: '予約ステータス',
            endDateOfStay: '宿泊最終日',
            startDateOfStay: '宿泊開始日',
            numberOfAdults: '大人の人数',
            isRepresentativeRoom: '代表者の部屋',
            roomBookingItemChildrenTypes: '部屋予約項目子供区分',
            quantity: '数量',
            representativeGuest: '代表者ゲスト',
            isReserverTheRepresentative: '予約者は代表者本人ですか？',
            beforeData: '前',
            afterData: '後',
            roomType: '部屋タイプ',
            guests: 'ゲスト',
            companionBookingItems: '予約詳細',
            usageDetails: '使用詳細',
            date: '日付',
            amount: '金額',
            planSaleItemNames: 'プランの販売項目名',
            numberOfChildren: '子供の人数',
            pricingSettings: '値段設定',
            tllRank: 'TLLランク',
            planSaleItems: 'プランの販売項目',
            planRoomTypePricing: 'プランのルームタイプ値段',
            price: '値段',
            peopleCount: '人数',
            numberOfMale: '男性',
            numberOfFemale: '女性',
            numberOfOtherGenderGuest: 'その他',
        },
        roomBookingItem: {
            gender: '性別',
            birthday: '生年月日',
            fullName: '名前',
            yomigana: 'よみがな',
            emailAddress: 'メール',
            mobilePhoneNumber: '携帯電話番号',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            beforeData: '前',
            afterData: '後',
            bookingStatus: '予約ステータス',
            autoGeneratedCode: 'ID',
            room: '部屋',
            roomName: '部屋名',
            name: '部屋名',
            saleItemCheckIns: 'チェックインに販売科目',
            price: '値段',
            saleItem: '販売項目',
            tax: '税',
            type: 'タイプ',
            isActive: 'アクティブ',
            priceType: '値段タイプ',
            priceValue: '値段数値',
            createdAt: 'に作成',
            isSeedData: 'シードデータ',
            isShowInBooking: '予約に表示',
            endDateOfStay: '宿泊最終日',
            isRepresentativeRoom: '代表者部屋',
            roomBookingItemChildrenTypes: '部屋予約項目子供区分',
            quantity: '数量',
            memo: 'メモ',
            plan: 'プラン',
            planName: 'プラン',
            isDayUse: 'デイユース',
            roomType: '部屋タイプ',
            roomTypeName: '部屋タイプ',
            checkInTime: 'チェックイン時刻',
            checkOutTime: 'チェックアウト時刻',
            numberOfAdults: '大人の人数',
            startDateOfStay: '宿泊開始日',
            representativeGuest: '代表者ゲスト',
            childrenType: '子供区分',
            paymentMethodCheckIns: 'チェックインに支払い方法',
            paymentMethod: '支払い方法',
            linkageCode: '連携コード',
            numberOfMale: '男性',
            numberOfFemale: '女性',
            numberOfOtherGenderGuest: 'その他',
            roomGuest: '部屋利用者',
            applicableRoom: '宿泊',
            roomBookingItem: '部屋予約項目',
        },
        roomBookingItemTemporary: {
            representativeGuest: '代表者ゲスト',
            priceType: '値段タイプ',
            isSeedData: 'はシードデータ',
            priceValue: '値段数値',
            linkageCode: 'リンケージコード',
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            memo: 'メモ',
            plan: 'プラン',
            name: '名前',
            planId: 'プランID',
            roomId: '部屋ID',
            isDayUse: 'はデイユース',
            roomTypeId: '部屋タイプID',
            checkInTime: 'チェックイン時刻',
            checkOutTime: 'チェックアウト時刻',
            bookingStatus: '予約ステータス',
            numberOfAdults: '大人の人数',
            endDateOfStay: '宿泊最終日',
            startDateOfStay: '宿泊開始日',
            isRepresentativeRoom: 'は代表者の部屋',
            representativeGuestId: '代表者ゲストのID',
            room: '部屋',
            roomType: '部屋タイプ',
            roomBookingItemChildrenTypes: '部屋予約項目子供区分',
            quantity: '数量',
            childrenType: '子供区分',
            beforeData: '前',
            afterData: '後',
            numberOfMale: '男性',
            numberOfFemale: '女性',
            numberOfOtherGenderGuest: 'その他',
        },
        receipt: {
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            type: 'タイプ',
            autoGeneratedCode: 'コード',
            beforeData: '前',
            afterData: '後',
            bookingType: '予約タイプ',
            boughtAt: 'に購入',
            paymentMethod: '支払い方法',
            receiptItemDetails: '領収書項目詳細',
            amount: '量',
            quantity: '数値',
            saleItem: '販売科目',
            tax: '税',
            name: '名前',
            isActive: 'アクティブ',
            createdAt: 'に作成',
            createdBy: 'が作成',
            deletedAt: 'に削除',
            deletedBy: 'が削除',
            updatedAt: 'に更新',
            updatedBy: 'が更新',
            pricingType: '値段タイプ',
            pricingValue: '値段数値',
            isSeedData: 'シードデータ',
            isShowInBooking: '予約に表示',
            unitPrice: '単価',
            price: '値段',
            payAt: '支払い時刻',
            saleGroups: '販売グループ',
            representativeGuest: '代表宿泊者',
            fullName: '名前',
            yomigana: 'よみがな',
            createdByChannel: '作成',
            room: '部屋名',
            applicableRoom: '宿泊',
        },
        receiptItemDetail: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            amount: '金額',
            quantity: '数量',
            unitPrice: '単価',
            receiptItem: '領収書項目',
            type: 'タイプ',
            bookingType: '予約タイプ',
            paymentMethod: '支払い方法',
            name: '名前',
            representativeGuest: '代表宿泊者',
            fullName: '名前',
            yomigana: 'よみがな',
            createdByChannel: '作成',
            room: '部屋名',
            applicableRoom: '宿泊',
        },
        stoppingInventory: {
            autoGeneratedCode: 'ID',
            createdData: '作成データ',
            deletedData: '削除データ',
            endDate: '終了日',
            roomType: '部屋タイプ',
            name: '名前',
            beforeData: '前',
            afterData: '後',
            startDate: '開始日',
            stoppingRoomCount: '売止部屋の数量',
            stoppingInventoryItems: '売り止め部屋科目',
            room: '部屋',
            memo: 'メモ',
            reason: '理由',
            status: 'ステータス',
        },
        hotel: {
            autoGeneratedCode: 'ID',
            createdData: '作成されたデータ',
            deletedData: '削除されたデータ',
            address: '住所',
            beforeData: '前',
            afterData: '後',
            picName: '写真名',
            postalCode: '郵便番号',
            numberOfRoom: '部屋数',
            picPhoneNumber: '担当者電話番号',
            picEmailAddress: '担当者メールアドレス',
            standardCheckInTime: '標準チェックイン時刻',
            tllName: 'TLL名前',
            isTllConnected: 'TLLに接続',
            tllPmsPassword: 'TLL PMSパスワード',
            tllBookingAcquisitionUrl: 'TLL予約取得URL',
        },
    },
    value: {
        childrenType: {
            fixed: '定価',
            pricePerAdult: '大人１名あたりの料金',
            pricePerAdultValue: `大人１名あたり料金の{{value}}%`,
        },
        yen: '￥{{value}}',
        hour: '時',
        minute: '分',
        true: 'はい',
        false: 'いいえ',
        inactive: '休止中',
        active: 'アクティブ',
        cleaned: '清掃済み',
        uncleaned: '清掃済み',
        [FacilityBookingStatus.CANCELLED]: 'キャンセル済み',
        [FacilityBookingStatus.FINISHED]: '終了済み',
        [FacilityBookingStatus.RESERVED]: '予約済み',
        [FacilityBookingStatus.STARTED]: '開始済み',
        [ReceiptItemBookingType.ROOM_BOOKING]: '',
        [ReceiptItemBookingType.ROOM_BOOKING_ITEM]: '部屋予約項目',
        [ReceiptItemBookingType.FACILITY_BOOKING]: '施設予約',
        [ReceiptItemDetailType.OTHER_TAX]: 'その他税',
        [ReceiptItemDetailType.SALE_ITEM]: '販売項目',
        [ReceiptItemDetailType.STAY_PRICE]: '宿泊費',
        [ReceiptItemType.RECEIPT]: '領収書',
        [ReceiptItemType.PAYMENT]: '支払い',
        [RoomBookingItemBookingStatus.CHECKED_IN]: '滞在中',
        [RoomBookingItemBookingStatus.CHECKED_OUT]: '出発',
        [RoomBookingItemBookingStatus.NOT_ARRIVED]: '未到着',
    },
};

export default historyDetail;
