const stopSelling = {
    title: 'Inventory management',
    buttons: {
        save: 'Save',
        bulkSale: 'Bulk stop',
        addFormItem: 'Add',
    },
    cancelTitle: 'Do you want to cancel?',
    guideMessage:
        'Click a checkbox and hold Shift key then select another box to select all checkboxes in between',
    monthYearFormat: 'year {{year}} month {{month}}',
    stopSellingLabel: 'Stop',
    bulkForm: {
        title: 'Bulk stop setting',
        roomTypeId: {
            label: 'Room type',
            placeholder: 'Select room type',
        },
        roomId: {
            placeholder: 'Room name',
        },
        rangeDay: {
            placeholder: {
                start: 'Start date',
                end: 'End date',
            },
        },
    },
    message: {
        rangeDayError: 'End date must be greater than start date',
        rangeThreeMonthError: 'Cannot block for more than {{month}} months',
    },
    autoAssignRoom: 'This is auto assign room',
};

export default stopSelling;
