import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import {
    IBookingConfirmDataItem,
    IBookingGuestInfo,
    ICreateBookingFormData,
    ICreateBookingState,
    IGetBookingPrice,
    IMemberAttachment,
    IGroupBookingItem,
} from '../interfaces';
import { bookingInfoDefault } from '../model';
import { roomBookingService } from '../services/room-booking.service';

const initialState: ICreateBookingState = {
    isCreatingBooking: false,
    isCheckingPlan: false,
    representativeRoom: null,
    bookingConfirmData: [],
    currentBookingSelected: 0,
    bookingInfo: { ...bookingInfoDefault },
    focusBooking: {},
    groupBookingPriceList: [],
    isFetchingGroupBookingPriceList: false,
};

export const createBooking = createAsyncThunk(
    'createBooking/createBooking',
    async (formData: ICreateBookingFormData | FormData) => {
        return await roomBookingService.createBooking(formData);
    },
);

export const getBookingPrice = createAsyncThunk(
    'createBooking/getBookingPrice',
    async (data: IGetBookingPrice) => {
        return await roomBookingService.getBookingPrice({
            ...data,
        });
    },
);

export const getGroupBookingPrice = createAsyncThunk(
    'createBooking/getGroupBookingPrice',
    async (data: IGetBookingPrice[]) => {
        return await roomBookingService.getGroupBookingPrice(data);
    },
);

export const getAvailableRoomsOfBookingItems = createAsyncThunk(
    'room-booking/getAvailableRoomsOfBookingItems',
    async (ids: number[]) => {
        return await roomBookingService.getAvailableRoomsOfBookingItems(ids);
    },
);

export const createBookingSlice = createSlice({
    name: 'room-booking',
    initialState,
    reducers: {
        setCreateBooking: (state, action) => {
            state.isCreatingBooking = action.payload;
        },
        setRepresentativeRoom: (state, action) => {
            state.representativeRoom = action.payload;
        },
        setCurrentBookingSelected: (state, action: PayloadAction<number>) => {
            state.currentBookingSelected = action.payload;
        },
        setBookingConfirmData: (
            state,
            action: PayloadAction<IBookingConfirmDataItem[]>,
        ) => {
            state.bookingConfirmData = action.payload;
        },
        updateBookingConfirmItem: (
            state,
            action: PayloadAction<IBookingConfirmDataItem>,
        ) => {
            const index = state.bookingConfirmData.findIndex(
                (item) => item.booking.id === action.payload.booking.id,
            );
            state.bookingConfirmData[index] = action.payload;
        },
        updateBookingPrice: (
            state,
            action: PayloadAction<{ id: number; price: number; tax: number }>,
        ) => {
            const { id, price, tax } = action.payload;
            const index = state.bookingConfirmData.findIndex(
                (item) => item.booking.id === id,
            );
            state.bookingConfirmData[index].booking = {
                ...state.bookingConfirmData[index].booking,
                price: price,
                tax: tax,
            };
        },
        setBookingInfo(state, action: PayloadAction<IBookingGuestInfo>) {
            state.bookingInfo = action.payload;
        },
        setGuestsAttachments(state, action: PayloadAction<IMemberAttachment[]>) {
            state.bookingInfo.attachments = action.payload;
        },
        resetBookingForm(state) {
            state.representativeRoom = null;
            state.bookingConfirmData = [];
            state.bookingInfo = { ...bookingInfoDefault };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBookingPrice.pending, (state, action) => {
            const bookingId = action.meta?.arg?.id || 0;
            state.focusBooking = { ...state.focusBooking, [bookingId]: true };
            state.isCheckingPlan = true;
        });
        builder.addCase(getBookingPrice.fulfilled, (state, action) => {
            state.isCheckingPlan = false;
            const bookingId = action.meta?.arg?.id || 0;
            state.focusBooking = { ...state.focusBooking, [bookingId]: false };
        });
        builder.addCase(createBooking.fulfilled, (state, action) => {
            state.isCreatingBooking = false;
        });
        builder.addCase(createBooking.pending, (state, action) => {
            state.isCreatingBooking = true;
        });
        builder.addCase(getGroupBookingPrice.pending, (state, action) => {
            state.isFetchingGroupBookingPriceList = true;
        });
        builder.addCase(getGroupBookingPrice.fulfilled, (state, action) => {
            state.groupBookingPriceList = action.payload;
            state.isFetchingGroupBookingPriceList = false;
        });
    },
});

export const {
    setCreateBooking,
    setRepresentativeRoom,
    setBookingConfirmData,
    setCurrentBookingSelected,
    updateBookingConfirmItem,
    setBookingInfo,
    setGuestsAttachments,
    resetBookingForm,
    updateBookingPrice,
} = createBookingSlice.actions;

export const createBookingStateSelector = (state: AppState) => {
    return state.createBooking;
};

export const activeBookingConfirmItemSelector = (state: AppState) => {
    const { currentBookingSelected, bookingConfirmData } = state.createBooking;
    const index = bookingConfirmData.findIndex(
        (item) => item.booking.id === currentBookingSelected,
    );
    return bookingConfirmData[index];
};

export const numberOfGuestsSelector = (state: AppState) => {
    let numberOfGuests = 0;
    const { bookingConfirmData } = state.createBooking;
    bookingConfirmData.forEach((item) => {
        numberOfGuests += Number(item.booking.numberOfAdults || 0);
    });
    return numberOfGuests;
};

export const attachmentsSelector = (state: AppState) => {
    const { bookingInfo } = state.createBooking;
    const options = bookingInfo.attachments
        .filter((item) => item.yomigana && item.phone)
        .map((item) => ({
            label: item.yomigana,
            value: item.id,
        }));
    return options;
};

export const membersSelector = (state: AppState) => {
    const { bookingInfo } = state.createBooking;
    return bookingInfo.attachments;
};

export const isSingleMembersSelector = (state: AppState) => {
    const { bookingInfo } = state.createBooking;
    return bookingInfo.attachments.length === 0;
};

export const isSingleBookingSelector = (state: AppState) => {
    const { bookingConfirmData } = state.createBooking;
    return bookingConfirmData.length === 1;
};

export default createBookingSlice.reducer;
