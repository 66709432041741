import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import FacilityList from '~features/facility/components/FacilityList/FacilityList';
import FilterForm from '~features/facility/components/FilterForm/FilterForm';
import UpdateFacilitiesForm from '~features/facility/components/UpdateFacilitiesForm/UpdateFacilitiesForm';
import { breadcrumbs } from '~features/facility/constants';
import './FacilityPage.scss';

const { Content } = Layout;
function FacilityPage() {
    const { t } = useTranslation();
    return (
        <div className="facility-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('facility.list.title')}
            />
            <Content>
                <div className="facility-content">
                    <FilterForm />
                    <FacilityList />
                </div>
                <UpdateFacilitiesForm />
            </Content>
        </div>
    );
}

export default FacilityPage;
