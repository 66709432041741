import React, { useEffect, useMemo } from 'react';
import { Col, Collapse, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    getRoomManagementBookingOverview,
    roomManagementBookingOverviewSelector,
    roomManagementSelector,
} from '~features/room-management/reducers/room-management.reducer';
import { debounce } from 'lodash';

const { Panel } = Collapse;

export const StatusCollapse = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [expand, setExpand] = React.useState(true);
    const onChange = (key: any) => {
        setExpand(!expand);
    };
    const { currentDate, isGettingDetail } = useAppSelector(roomManagementSelector);
    const bookingOverview = useAppSelector(roomManagementBookingOverviewSelector);

    const fetchData = debounce(() => {
        dispatch(
            getRoomManagementBookingOverview({
                start: currentDate,
                end: currentDate,
            }),
        );
    }, 500);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [currentDate, isGettingDetail]);

    const options = useMemo(() => {
        return [
            {
                label: t('roomManagement.list.statusCollapse.checkIn'),
                value: `${bookingOverview?.checkedInCount || 0}/${
                    bookingOverview?.willCheckInCount || 0
                }`,
            },
            {
                label: t('roomManagement.list.statusCollapse.checkOut'),
                value: `${bookingOverview?.checkedOutCount || 0}/${
                    bookingOverview?.willCheckOutCount || 0
                }`,
            },
            {
                label: t('roomManagement.list.statusCollapse.roomOccupancyRate'),
                value: `${Math.round((bookingOverview?.occupancyRate || 0) * 100)}%`,
            },
        ];
    }, [bookingOverview]);

    return (
        <Collapse defaultActiveKey={expand ? 'status' : ''} onChange={onChange}>
            <Panel header={t('roomManagement.list.statusCollapse.title')} key="status">
                {options.map((item, index) => (
                    <Row key={index}>
                        <Col span={16}>
                            <span>{item.label}</span>
                        </Col>
                        <Col span={8}>
                            <span>{item.value}</span>
                        </Col>
                    </Row>
                ))}
            </Panel>
        </Collapse>
    );
};
