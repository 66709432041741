import classNames from 'classnames';
import React from 'react';
import { IGridItem } from '~features/inventory/interfaces';

type Props = {
    style: React.CSSProperties;
    item: IGridItem;
};

export const RowHeader = ({ style, item }: Props) => {
    return (
        <div style={style} className="stop-selling-row-header stop-selling-cell">
            <span
                className={classNames('text-truncate', {
                    'pl-12': !!item.parentId,
                })}
            >
                {item?.name}
            </span>
        </div>
    );
};
