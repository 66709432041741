import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import {
    IFacilityType,
    IFacilityTypeGetListQuery,
    IFacilityTypeBulkDeleteItem,
    IFacilityTypeExportCsvQuery,
    IFacilityTypeCreate,
    IFacilityTypeDropDownQuery,
    IFacilityTypeDropdown,
} from '../interfaces';
import { IBodyResponse, IGetListResponse, ICsvFile } from '~common/interfaces';

class FacilityTypeService extends ApiService {
    getList(queryString: IFacilityTypeGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IFacilityType>>
        >;
    }

    getListByIs(ids: number[]) {
        return this._get(`${this.baseUrl}/get-by-ids`, {
            params: { ids },
        }) as Promise<IBodyResponse<IFacilityType[]>>;
    }

    bulkDelete(bulkDeleteBody: IFacilityTypeBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    exportCsv(query: IFacilityTypeExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }

    create(facilityType: IFacilityTypeCreate) {
        return this._post(this.baseUrl, facilityType) as Promise<IBodyResponse<boolean>>;
    }

    bulkUpdate(body: IFacilityTypeCreate[]) {
        return this._patch(`${this.baseUrl}/bulk-update`, {
            items: body,
        }) as Promise<IBodyResponse<boolean>>;
    }

    getListForDropdown(params?: IFacilityTypeDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<IBodyResponse<IGetListResponse<IFacilityTypeDropdown>>>;
    }
}

export const facilityTypeService = new FacilityTypeService(
    { baseUrl: '/facility-type' },
    axiosService,
);
