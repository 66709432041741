import { Spin } from 'antd';
import {
    memo,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GridOnItemsRenderedProps, VariableSizeGrid } from 'react-window';
import { addMoreColumnsForWeek, getDayColumnsForWeek } from '~common/commonFunctions';
import { EmitterGlobalEvent } from '~common/constants';
import { IDirection, IScrollStatus } from '~common/interfaces';
import { useHandleScroll, usePrevious, useThrottle } from '~common/useHooks';
import { AutoSizer, GridView, ICell } from '~components';
import {
    CELL_HEADER_HEIGHT,
    CELL_HEADER_ROW_WIDTH,
    CELL_HEIGHT,
    CELL_WIDTH,
    HEADER_HEIGHT,
} from '~features/facility-booking/constants';
import {
    bookingFilterSelector,
    facilityBookingStateSelector,
    fetchMoreBookingList,
    getBookingList,
    resetWeekViewState,
    setCollapseRooms,
    setCurrentDate,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { CollapseBtn } from '~features/room-booking/components/CollapseBtn/CollapseBtn';
import { RoomBookingEvent } from '~features/room-booking/constants';
import { updateStyleForCellSelected } from '~features/room-booking/util';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate, todayDayjs } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';
import { CellItem } from './CellItem';
import { Header } from './Header';
import { RoomHeader } from './RoomHeader';
import './ScheduleWeekView.scss';

const ScheduleWeekViewComponent = (props: { height?: number }) => {
    const dispatch = useAppDispatch();
    const {
        isFetchingFacilityList,
        startPosition,
        isSelecting,
        isDraggingBooking,
        panelId,
        currentStatus,
        currentDragData,
        collapseRooms,
        isFetchingBookingList,
        currentDate,
        facilityList,
    } = useAppSelector(facilityBookingStateSelector);
    const { height } = props;
    const [columns, setColumns] = useState(getDayColumnsForWeek(currentDate));
    const bookingList = useAppSelector(bookingFilterSelector);
    const { throttle } = useThrottle();
    const containerRef = useRef<HTMLDivElement>(null);
    const { emitter } = useMitt();
    const outerRef = useRef<HTMLElement>();
    const gridRef = useRef<VariableSizeGrid>() as MutableRefObject<VariableSizeGrid>;
    const isLoadMore = useRef(false);

    const facilityListFilter = useMemo(() => {
        const results = facilityList.filter((item) => {
            return (
                !item.parentId ||
                (item.parentId && !collapseRooms.includes(item.parentId))
            );
        });
        return results;
    }, [facilityList, collapseRooms]);

    // handle scroll
    const onStopScroll = useCallback(
        (direction: IDirection, status: IScrollStatus) => {
            if (isFetchingBookingList) {
                return;
            }
            if (status === 'start' && direction === 'right') {
                throttle(() => {
                    loadMorePast();
                }, 1000);
            } else if (status === 'end' && direction === 'left') {
                throttle(() => {
                    loadMoreFuture();
                }, 1000);
            }
        },
        [isFetchingBookingList, columns],
    );

    const {
        scrollDirectionX,
        onMouseDown,
        onMouseUp,
        onMouseMove,
        onMouseLeave,
        elementRef,
        isScrollXAble,
        onWheel,
        scrollStatus,
    } = useHandleScroll({ onStopScroll });

    useEffect(() => {
        isScrollXAble.current = !isDraggingBooking;
    }, [isDraggingBooking, isScrollXAble]);
    // end handle scroll

    // handle select day for create booking
    useEffect(() => {
        const _updateStyleForCellSelected = (x: number) => {
            if (!startPosition) return;
            updateStyleForCellSelected(panelId, startPosition, x);
        };

        const onMouseMove = (event: MouseEvent) => {
            if (!isSelecting) {
                return;
            }
            const element = event.target as HTMLElement;
            const x = element.dataset.x;
            const y = element.dataset.y;
            const date = element.dataset.day;
            if (x && y) {
                if (date && date < today) {
                    return;
                }
                _updateStyleForCellSelected(Number(x));
            }
        };
        document.addEventListener('mousemove', onMouseMove);
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
        };
    }, [isSelecting, dispatch, startPosition, panelId]);

    // end handle select day for create booking
    const today = todayDayjs.fmYYYYMMDD('-');

    const renderHeader = useCallback(
        ({ columnIndex, style }: ICell) => {
            const column = columns[columnIndex];
            return <Header column={column} style={style} today={today} />;
        },
        [columns, today],
    );

    const renderCell = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            const row = facilityListFilter[rowIndex];
            return (
                <CellItem
                    isToday={columns[columnIndex].id === today}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    item={row}
                    day={columns[columnIndex].id}
                    style={style}
                    bookingList={bookingList}
                ></CellItem>
            );
        },
        [
            facilityListFilter,
            currentStatus,
            currentDragData,
            columns,
            today,
            bookingList,
            dispatch,
        ],
    );

    const renderRowHeader = useCallback(
        ({ rowIndex, style }: ICell) => {
            const row = facilityListFilter[rowIndex];
            return <RoomHeader item={row} style={style} />;
        },
        [collapseRooms, facilityListFilter],
    );

    const getRowContentHeight = useCallback(
        (rowIndex: number) => {
            if (facilityListFilter[rowIndex].parentId) {
                return CELL_HEIGHT + 20;
            }
            return CELL_HEADER_HEIGHT;
        },
        [facilityListFilter],
    );

    const togglePanelAll = useCallback(() => {
        const ids = facilityListFilter
            .filter((item) => !item.parentId)
            .map((item) => item.id);
        if (collapseRooms.length === ids.length) {
            dispatch(setCollapseRooms([]));
        } else {
            dispatch(setCollapseRooms(ids));
        }
    }, [collapseRooms, facilityListFilter]);

    useEffect(() => {
        emitter.on(RoomBookingEvent.CHANGE_DATE, (date: string) => {
            const _columns = getDayColumnsForWeek(date);
            setColumns(_columns);
            fetchBookingList(_columns[0].id, _columns[_columns.length - 1].id);
        });

        return () => {
            emitter.off(RoomBookingEvent.CHANGE_DATE);
        };
    }, []);

    useEffect(() => {
        fetchBookingList(columns[0].id, columns[columns.length - 1].id);
        return () => {
            dispatch(resetWeekViewState());
        };
    }, []);

    // handle scroll to current date
    const isFetchingBookingListOld = usePrevious<boolean>(isFetchingBookingList);
    useEffect(() => {
        if (isFetchingBookingListOld && !isFetchingBookingList && !isLoadMore.current) {
            const todayIndex = columns.findIndex(
                (item) =>
                    item.id === parseDate(currentDate).startOf('week')?.fmYYYYMMDD(),
            );
            if (todayIndex > -1) {
                setTimeout(() => {
                    gridRef.current?.scrollToItem({
                        columnIndex: todayIndex,
                        align: 'start',
                    });
                }, 0);
            }
        }
    }, [isFetchingBookingList, columns, currentDate]);

    // handle load more item
    const fetchBookingList = async (start: string, end: string) => {
        isLoadMore.current = false;
        await dispatch(
            getBookingList({
                start,
                end,
            }),
        );
    };
    const loadMoreFuture = async () => {
        isLoadMore.current = true;
        const lastDay = columns[columns.length - 1].id;
        const moreColumns = addMoreColumnsForWeek(
            parseDate(lastDay).add(1, 'day'),
            false,
            14,
        );
        setColumns([...columns, ...moreColumns]);
        await dispatch(
            fetchMoreBookingList({
                start: moreColumns[0].id,
                end: moreColumns[moreColumns.length - 1].id,
            }),
        );
    };
    const loadMorePast = async () => {
        isLoadMore.current = true;
        const firstDay = columns[0].id;
        const moreColumns = addMoreColumnsForWeek(parseDate(firstDay), true);
        setColumns([...moreColumns, ...columns]);
        dispatch(setCurrentDate(moreColumns[0].id));
        await dispatch(
            fetchMoreBookingList({
                start: moreColumns[0].id,
                end: moreColumns[moreColumns.length - 1].id,
            }),
        );
    };

    const _onScroll = useCallback(
        ({ scrollLeft, scrollTop }: any) => {
            const scrollWidth = outerRef.current?.scrollWidth || 0;
            const clientWidth = containerRef.current?.clientWidth || 0;
            emitter.emit(EmitterGlobalEvent.SCROLL, { x: scrollLeft, y: scrollTop });
            if (!scrollLeft) {
                scrollStatus.current = 'start';
            } else if (scrollWidth === scrollLeft + clientWidth - CELL_HEADER_ROW_WIDTH) {
                scrollStatus.current = 'end';
            } else {
                scrollStatus.current = 'middle';
            }
        },
        [outerRef, gridRef, containerRef, columns],
    );

    const onItemsRendered = useCallback(
        ({ visibleColumnStartIndex }: GridOnItemsRenderedProps) => {
            if (!elementRef.current) {
                elementRef.current = outerRef.current;
            }
            const column = columns[visibleColumnStartIndex];
            if (scrollDirectionX.current && column?.id) {
                dispatch(setCurrentDate(column.id));
            }
        },
        [columns, currentDate],
    );

    const isExpand = useMemo(() => {
        const ids = facilityListFilter
            .filter((item) => !item.parentId)
            .map((item) => item.id);
        return collapseRooms.length === ids.length;
    }, [collapseRooms, facilityListFilter]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div
                className="facility-schedule-table-wrapper"
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseLeave}
                onWheel={onWheel}
                ref={containerRef}
            >
                <Spin
                    spinning={isFetchingFacilityList || isFetchingBookingList}
                    style={{ minHeight: 400 }}
                >
                    <AutoSizer disableHeight>
                        {({ width }) => {
                            let cellWidth = (width - CELL_HEADER_ROW_WIDTH) / 7;
                            if (cellWidth < CELL_WIDTH) {
                                cellWidth = CELL_WIDTH;
                            }
                            return (
                                <GridView
                                    gridRef={gridRef}
                                    style={{
                                        fontSize: 12,
                                    }}
                                    outerRef={outerRef}
                                    className={'schedule-table'}
                                    height={height}
                                    width={width}
                                    columns={columns}
                                    recordset={facilityListFilter}
                                    rowHeaderWidth={CELL_HEADER_ROW_WIDTH}
                                    columnHeaderWidth={cellWidth}
                                    columnHeaderHeight={HEADER_HEIGHT}
                                    columnHeaderRenderer={renderHeader}
                                    rowHeaderRenderer={renderRowHeader}
                                    cellRenderer={renderCell}
                                    getRowContentHeight={getRowContentHeight}
                                    onScroll={_onScroll}
                                    bodyProps={{
                                        onItemsRendered,
                                    }}
                                />
                            );
                        }}
                    </AutoSizer>
                    <div
                        className="header-action"
                        style={{ width: CELL_HEADER_ROW_WIDTH, height: HEADER_HEIGHT }}
                    >
                        <CollapseBtn isExpand={isExpand} onClick={togglePanelAll} />
                    </div>
                </Spin>
            </div>
        </DndProvider>
    );
};

export const ScheduleWeekView = memo(ScheduleWeekViewComponent);
