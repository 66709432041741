import roomTypeListJa from './ja/room-type-list.locale';
import roomTypeFormJa from './ja/room-type-form.locale';
import roomTypeImportJa from './ja/room-type-import.locale';

import roomTypeListEn from './en/room-type-list.locale';
import roomTypeFormEn from './en/room-type-form.locale';
import roomTypeImportEn from './en/room-type-import.locale';

const roomType = {
    ja: {
        list: roomTypeListJa,
        form: roomTypeFormJa,
        import: roomTypeImportJa,
    },
    en: {
        list: roomTypeListEn,
        form: roomTypeFormEn,
        import: roomTypeImportEn,
    },
};

export default roomType;
