import { Button, Card, Table, Tag } from 'antd';
import {
    CreditCardOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowRightOutlined,
} from '@ant-design/icons';
import { parseDateTime } from '~common/commonFunctions';
import { DateFormat } from '~common/constants';
import './RoomBookingKeycardTabPane.scss';
import { useTranslation } from 'react-i18next';
import AddKeycardModal from './AddKeycardModal/AddKeycardModal';
import { useState } from 'react';
import ReissueKeycardModal from './ReissueKeycardModal/ReissueKeycardModal';

interface IKeycardData {
    id: string;
    reissueNumber: number;
    checkInDateTime: string;
    checkOutDateTime: string;
    issueDate: string;
    status: string;
}

export enum KeycardStatus {
    ACTIVE = 'active',
    RETURNED = 'returned',
    LOST = 'lost',
}

export enum ModalMode {
    ADD = 'add',
    EDIT = 'edit',
}

function RoomBookingKeycardTabPane() {
    const { t } = useTranslation();
    const [isAddKeyModalOpen, setIsAddKeyModalOpen] = useState(false);
    const [isReissueKeyModalOpen, setIsReissueKeyModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.ADD);

    const getKeyStatusColor = (status: string) => {
        switch (status) {
            case KeycardStatus.ACTIVE:
                return 'green';
            case KeycardStatus.RETURNED:
                return 'blue';
            case KeycardStatus.LOST:
                return 'red';
        }
    };

    const addKeycard = () => {
        setIsAddKeyModalOpen(true);
        setModalMode(ModalMode.ADD);
    };

    const editKeycard = () => {
        setIsAddKeyModalOpen(true);
        setModalMode(ModalMode.EDIT);
    };

    const keycardColumns = [
        {
            key: 'id',
            title: 'ID',
            render: (item: IKeycardData) => <div>{item.id}</div>,
            width: 100,
        },
        {
            key: 'reissueNumber',
            title: t('roomBooking.detail.keycard.reissueNumber'),
            render: (item: IKeycardData) => <div>{item.reissueNumber}</div>,
            width: 180,
        },
        {
            key: 'cico',
            title: 'C/I - C/O',
            render: (item: IKeycardData) => {
                return item.checkInDateTime && item.checkOutDateTime ? (
                    <div className="keycard__table-cico-wrapper">
                        <div className="keycard__table-cico">
                            <strong>{parseDateTime(item.checkInDateTime)}</strong>
                            <span>
                                {parseDateTime(
                                    item.checkInDateTime,
                                    DateFormat.HH_MM_COLON,
                                )}
                            </span>
                        </div>
                        <ArrowRightOutlined />
                        <div className="keycard__table-cico">
                            <strong>{parseDateTime(item.checkOutDateTime)}</strong>
                            <span>
                                {parseDateTime(
                                    item.checkOutDateTime,
                                    DateFormat.HH_MM_COLON,
                                )}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div></div>
                );
            },
            width: 280,
        },
        {
            key: 'issue',
            title: t('roomBooking.detail.keycard.issueDate'),
            render: (item: IKeycardData) => (
                <div>{item.issueDate && parseDateTime(item.issueDate)}</div>
            ),
            width: 150,
        },
        {
            key: 'status',
            title: t('roomBooking.detail.keycard.statusColumn'),
            render: (item: IKeycardData) => (
                <div>
                    {item.status && (
                        <Tag color={getKeyStatusColor(item.status)}>
                            {t(`roomBooking.detail.keycard.status.${item.status}`)}
                        </Tag>
                    )}
                </div>
            ),
        },
        {
            key: 'action',
            title: '',
            render: () => (
                <div className="keycard__table-action-btn">
                    <Button
                        size="small"
                        type="link"
                        onClick={() => setIsReissueKeyModalOpen(true)}
                    >
                        {t('roomBooking.detail.keycard.reIssue')}
                    </Button>
                    <Button size="small" type="text" onClick={() => editKeycard()}>
                        <EditOutlined />
                    </Button>
                    <Button size="small" type="text">
                        <DeleteOutlined />
                    </Button>
                </div>
            ),
            width: 300,
        },
    ];

    const MOCK_DATA: IKeycardData[] = [
        {
            id: '00008',
            reissueNumber: 0,
            checkInDateTime: '2024-08-22T10:30:00Z',
            checkOutDateTime: '2024-08-23T10:30:00Z',
            issueDate: '2024-08-22T10:30:00Z',
            status: KeycardStatus.ACTIVE,
        },
        {
            id: '00009',
            reissueNumber: 0,
            checkInDateTime: '2024-08-22T10:30:00Z',
            checkOutDateTime: '2024-08-23T10:30:00Z',
            issueDate: '2024-08-22T10:30:00Z',
            status: KeycardStatus.RETURNED,
        },
        {
            id: '00009',
            reissueNumber: 0,
            checkInDateTime: '2024-08-22T10:30:00Z',
            checkOutDateTime: '2024-08-23T10:30:00Z',
            issueDate: '2024-08-22T10:30:00Z',
            status: KeycardStatus.LOST,
        },
    ];

    return (
        <>
            <div className="keycard">
                <Card>
                    <div className="keycard__header">
                        <div className="keycard__title">103 (3)</div>
                        <Button type="primary" onClick={() => addKeycard()}>
                            <CreditCardOutlined />
                            <span>{t('roomBooking.detail.keycard.addKey')}</span>
                        </Button>
                    </div>
                    <Table
                        columns={keycardColumns}
                        dataSource={MOCK_DATA}
                        rowKey="key"
                        className="keycard__table"
                        pagination={false}
                    />
                </Card>
                <Card>
                    <div className="keycard__header">
                        <div className="keycard__title">200 (0)</div>
                        <Button type="primary">
                            <CreditCardOutlined />
                            <span>{t('roomBooking.detail.keycard.addKey')}</span>
                        </Button>
                    </div>
                </Card>
                <Card>
                    <div className="keycard__header">
                        <div className="keycard__title">206 (0)</div>
                        <Button type="primary">
                            <CreditCardOutlined />
                            <span>{t('roomBooking.detail.keycard.addKey')}</span>
                        </Button>
                    </div>
                </Card>
            </div>
            <AddKeycardModal
                isModalOpen={isAddKeyModalOpen}
                setIsModalOpen={setIsAddKeyModalOpen}
                mode={modalMode}
            />
            <ReissueKeycardModal
                isModalOpen={isReissueKeyModalOpen}
                setIsModalOpen={setIsReissueKeyModalOpen}
            />
        </>
    );
}

export default RoomBookingKeycardTabPane;
