import { Select } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchFacilityList } from '~features/facility-booking/reducers/facility-schedule.reducer';
import { IndicatorType } from '~features/indicator/constants';
import { RoomManagementStatus } from '~features/room-management/constants';
import { IDetailRoomIndicatorManagement } from '~features/room-management/interfaces';
import {
    getIndicatorDropdown,
    roomManagementSelector,
    setCurrentIndicatorId,
    getRoomListStatus,
    getBookingUnassignedList,
    getFacilityBookings,
    setIsShowBookingList,
    roomManagementDetailSelector,
    setCurrentStatus,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppSelector, useAppDispatch } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import './IndicatorSelect.scss';

export const IndicatorSelect = () => {
    const { indicatorDropdown, currentIndicatorId, currentDate } =
        useAppSelector(roomManagementSelector);
    const currentIndicator = useAppSelector(roomManagementDetailSelector);
    const dispatch = useAppDispatch();
    const options = useMemo(() => {
        return indicatorDropdown.map((item) => {
            return {
                value: item.id,
                // TODO: Build base Select component due to empty string in label return value
                label: item.name || ' ',
            };
        });
    }, [indicatorDropdown]);
    const { t } = useTranslation();

    const fetchBookingData = (items: IDetailRoomIndicatorManagement[]) => {
        if (items[0]?.indicatorType === IndicatorType.FACILITY) {
            dispatch(getFacilityBookings());
            dispatch(
                fetchFacilityList({
                    startDate: parseDate(currentDate).startOf('day').fmYYYYMMDDHHmmss(),
                    endDate: parseDate(currentDate).endOf('day').fmYYYYMMDDHHmmss(),
                }),
            );
        }

        if (items[0]?.indicatorType === IndicatorType.ROOM) {
            dispatch(getRoomListStatus());
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await dispatch(getIndicatorDropdown());
            if (getIndicatorDropdown.fulfilled.match(response)) {
                if (response.payload?.success) {
                    fetchBookingData(response.payload?.data?.items || []);
                }
            }
        };
        fetchData();
    }, []);

    const onChange = (val: string) => {
        dispatch(setCurrentIndicatorId(val));
        const newIndicator = indicatorDropdown.find((item) => item.id === +val);
        if (newIndicator?.indicatorType !== currentIndicator?.indicatorType) {
            dispatch(setCurrentStatus(RoomManagementStatus.ALL));
        }
        if (newIndicator?.indicatorType === IndicatorType.ROOM) {
            dispatch(getRoomListStatus());
            dispatch(getBookingUnassignedList());
        } else {
            dispatch(getFacilityBookings());
            dispatch(
                fetchFacilityList({
                    startDate: parseDate(currentDate).startOf('day').fmYYYYMMDDHHmmss(),
                    endDate: parseDate(currentDate).endOf('day').fmYYYYMMDDHHmmss(),
                }),
            );
            dispatch(setIsShowBookingList(false));
        }
    };

    return (
        <div className="indicator-select-wrapper">
            <span className="mr-20">{t('roomManagement.list.selectIndicator')}:</span>
            <Select
                options={options}
                showSearch
                value={currentIndicatorId as any}
                className="indicator-select"
                onChange={onChange}
                getPopupContainer={(triggerNode) =>
                    triggerNode?.parentNode || document.body
                }
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </div>
    );
};
