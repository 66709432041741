const smtpSettingForm = {
    title: 'Create email server',
    breadcrumbs: {
        home: 'Home',
        BOsetting: 'Back office setting',
        infrastructureSettings: 'Infra setting',
        smtpSetting: 'Email server',
    },
    serverName: 'Name',
    serverNameInput: 'Ex: SMTP 1',
    password: 'Password',
    smtpServer: 'SMTP Server',
    smtpServerInput: 'Ex: 8765434567',
    smtpEncryption: 'SMTP encryption',
    smtpEncryptionOption: {
        yes: 'Yes',
        no: 'No',
    },
    smtpPort: 'SMTP Port',
    userName: 'User name',
    userNameInput: 'example@tabist.com',
    connectionSecurity: 'Connection security',
    connectionSecurityOption: {
        'SSL/TLS': 'SSL/TLS',
    },
    cancelBtn: 'Cancel',
    submitBtn: 'Test connection',
    message: {
        updateSuccess: 'Mail Server has been updated successfully',
        updateError: 'Mail Server has been updated unsuccessfully',
        cancelTitle: 'Do you want to cancel?',
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
    },
};
export default smtpSettingForm;
