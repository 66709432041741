const rankCalendarList = {
    title: 'ランクカレンダー設定',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        rankCalendar: 'ランクカレンダー設定',
    },
    addBtn: '新規カレンダー',
    rankCalendarColumn: {
        id: 'ID',
        calendarName: 'カレンダー名',
        status: '状況',
    },
    message: {
        deleteRankCalendarsSuccess: 'ランクカレンターが削除されました',
        cannotDeleteRankCalendar: '有効なカレンダーは削除できません',
        cannotDeleteInitRankCalendar: `Cannot delete rank calendar item`,
    },
    status: {
        active: '有効',
        inactive: '無効',
    },
};

export default rankCalendarList;
