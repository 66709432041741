import { Content } from 'antd/lib/layout/layout';
import { useEffect } from 'react';
import { StaffLayoutHeader } from '~components';
import {
    isShowCurrentGuestTableSelector,
    resetCurrentGuestState,
} from '~features/report/reducers/current-guest.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import FilterForm from './components/FilterForm/FilterForm';
import CurrentGuestTable from './components/CurrentGuestTable/CurrentGuestTable';
import { useTranslation } from 'react-i18next';
import { currentGuestBreadcrumbs } from '~features/report/constants';
import BreakDownGuest from './components/BreakDownGuest/BreakDownGuest';

function CurrentGuestPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowTable = useAppSelector(isShowCurrentGuestTableSelector);
    useEffect(() => {
        return () => {
            dispatch(resetCurrentGuestState());
        };
    }, []);
    return (
        <div className="current-guest-page-wrapper">
            <StaffLayoutHeader
                breadcrumbs={[...currentGuestBreadcrumbs()]}
                title={t('report.currentGuest.titlePage')}
            />
            <Content>
                <div className="content-page-wrapper">
                    <FilterForm />
                    {isShowTable && (
                        <>
                            <BreakDownGuest />
                            <CurrentGuestTable />
                        </>
                    )}
                </div>
            </Content>
        </div>
    );
}

export default CurrentGuestPage;
