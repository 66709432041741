import { Button, Card, Layout, Row, Col, Typography } from 'antd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { parseDate } from '~plugins/dayjs';
import SplitBookingScheduleGrid from './components/SplitBookingScheduleGrid';
import { StaffLayoutHeader } from '~components';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useSplitBooking } from '~features/room-booking/hooks/useSplitBooking';
import { useAppSelector } from '~hooks';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';

import './RoomBookingItemDetailPage.scss';

const RoomBookingItemDetailPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { roomBookingId } = useParams();
    const { Content, Footer } = Layout;
    const breadcrumbs = () => [
        {
            text: t('roomBooking.list.breadcrumbs.home'),
        },
        {
            text: t('roomBooking.list.breadcrumbs.roomBooking'),
        },
        {
            text: t('roomBooking.detail.breadcrumbs.roomBookingDetail'),
        },
    ];

    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    if (!selectedRoomBookingDetail) {
        return <Navigate to={`/room-booking/${roomBookingId}/detail`} />;
    }

    const {
        roomBookingItem,
        targetRoomBookingItems,
        currentBookingDates,
        selectedDates,
        setSelectedDates,
        splitBookingIsPending,
        splitBooking,
    } = useSplitBooking(selectedRoomBookingDetail);

    return (
        <>
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('roomBooking.detail.breadcrumbs.roomBookingDetail')}
            />
            <Content className="booking-item-detail-page-content">
                <Card
                    className="schedule-card"
                    bodyStyle={{ padding: 0 }}
                    title={
                        <Row gutter={12}>
                            {targetRoomBookingItems?.[0].representativeGuest && (
                                <Col>
                                    <Typography>
                                        {
                                            targetRoomBookingItems?.[0]
                                                .representativeGuest.fullName
                                        }
                                    </Typography>
                                </Col>
                            )}
                            <Col>
                                {parseDate(roomBookingItem?.startDateOfStay).format(
                                    'YYYY-M-D',
                                )}
                                <ArrowRightOutlined className="ml-8 mr-8" />
                                {parseDate(roomBookingItem?.endDateOfStay).format(
                                    'YYYY-M-D',
                                )}
                            </Col>
                        </Row>
                    }
                >
                    {roomBookingItem?.startDateOfStay &&
                        roomBookingItem?.endDateOfStay &&
                        currentBookingDates && (
                            <SplitBookingScheduleGrid
                                startDate={roomBookingItem?.startDateOfStay}
                                endDate={roomBookingItem?.endDateOfStay}
                                selectedDates={selectedDates}
                                setSelectedDates={setSelectedDates}
                                originalBookingDates={currentBookingDates}
                            />
                        )}
                </Card>
            </Content>
            <Footer>
                <Row justify="end" gutter={8}>
                    <Col>
                        <Button
                            onClick={() =>
                                navigate(`/room-booking/${roomBookingId}/detail`)
                            }
                        >
                            {t('common.buttonCancelText')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            loading={splitBookingIsPending}
                            disabled={!selectedDates.length}
                            onClick={() => {
                                splitBooking();
                            }}
                        >
                            {t('inventory.stopSelling.buttons.save')}
                        </Button>
                    </Col>
                </Row>
            </Footer>
        </>
    );
};

export default RoomBookingItemDetailPage;
