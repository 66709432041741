import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import ChildrenTypeForm from '~features/children-type/components/ChildrenTypeForm/ChildrenTypeForm';
import ChildrenTypeList from '~features/children-type/components/ChildrenTypeList/ChildrenTypeList';
import FilterForm from '~features/children-type/components/FilterForm/FilterForm';
import { breadcrumbs } from '~features/children-type/constants';
import './ChildrenTypePage.scss';

const { Content } = Layout;

function ChildrenTypePage() {
    const { t } = useTranslation();

    return (
        <div className="children-type-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('childrenType.list.title')}
            />

            <Content>
                <div className="children-type-content">
                    <FilterForm />
                    <ChildrenTypeList />
                </div>
                <ChildrenTypeForm />
            </Content>
        </div>
    );
}

export default ChildrenTypePage;
