import { Button, Card, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, IdcardOutlined, EditOutlined } from '@ant-design/icons';
import './FacilityBookingDetailTabPane.scss';
import {
    FacilityBookingDetailTab,
    facilityBookingListQueryDefault,
    FacilityBookingStatus,
} from '~features/facility-booking/constants';
import { useState } from 'react';
import FacilityBookingInformation from './FacilityBookingInformation/FacilityBookingInformation';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    selectedFacilityBookingDetailSelector,
    setIsShowUpdateFacilityBookingForm,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { facilityBookingService } from '~features/facility-booking/services/facility-booking.service';
import { useNavigate } from 'react-router-dom';
import { showConfirm } from '~common/notification';
import {
    printFacilityBookingListSelector,
    setIsShowFacilityBookingPrintingModal,
    setPrintFacilityBookingListQuery,
    setPrintingFacilityBookingList,
} from '~features/facility-booking/reducers/print-facility-booking.reducer';
import FacilityBookingPrintingModal from '~features/facility-booking/components/FacilityBookingPrintingModal/FacilityBookingPrintingModal';

function FacilityBookingDetailTabPane() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [activeTabKey, setActiveTabKey] = useState<string>(
        FacilityBookingDetailTab.BOOKING_INFORMATION,
    );
    const selectedFacilityBookingDetail = useAppSelector(
        selectedFacilityBookingDetailSelector,
    );
    const { isShowFacilityBookingPrintingModal } = useAppSelector(
        printFacilityBookingListSelector,
    );
    const navigate = useNavigate();
    const tabList = [
        {
            key: FacilityBookingDetailTab.BOOKING_INFORMATION,
            tab: t('facilityBooking.detail.detailPageTabs.bookingInfo'),
        },
        {
            key: FacilityBookingDetailTab.ATTACHMENT,
            tab: t('facilityBooking.detail.detailPageTabs.attachment'),
        },
    ];

    const contentList: Record<string, React.ReactNode> = {
        [FacilityBookingDetailTab.BOOKING_INFORMATION]: <FacilityBookingInformation />,
        [FacilityBookingDetailTab.ATTACHMENT]: (
            <p>{t('facilityBooking.detail.detailPageTabs.attachment')}</p>
        ),
    };

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const editFacilityBooking = () => {
        dispatch(setIsShowUpdateFacilityBookingForm(true));
    };

    const _showConfirm = () => {
        showConfirm({
            title: t('facilityBooking.detail.deleteTitle'),
            cancelText: t('common.buttonCancelText'),
            okText: t('common.buttonSaveText'),
            onOk() {
                deleteFacilityBooking();
            },
        });
    };

    const deleteFacilityBooking = async () => {
        if (selectedFacilityBookingDetail?.id) {
            const selectedIds = [selectedFacilityBookingDetail.id];
            const response = await facilityBookingService.bulkDelete(selectedIds);
            if (response.success) {
                notification.success({
                    message: t('facilityBooking.list.message.deleteRoomTypesSuccess'),
                });
                navigate('/facility-booking');
            } else {
                notification.error({
                    message: response.message,
                });
            }
        }
    };

    const openPrintScreen = () => {
        if (selectedFacilityBookingDetail?.status === FacilityBookingStatus.CANCELLED) {
            Modal.warning({
                content: t('facilityBooking.printing.notification.noPrintGuestCard'),
                okText: t('common.ok'),
            });
            return;
        }
        dispatch(setPrintingFacilityBookingList([selectedFacilityBookingDetail]));
        dispatch(
            setPrintFacilityBookingListQuery({
                ...facilityBookingListQueryDefault,
            }),
        );
        dispatch(setIsShowFacilityBookingPrintingModal(true));
    };

    const closePrintModal = () => {
        showConfirm({
            title: t('facilityBooking.detail.cancelTitle'),
            onOk() {
                dispatch(setIsShowFacilityBookingPrintingModal(false));
            },
        });
    };

    return (
        <div className="facility-booking-detail-tab-pane">
            <Card
                className="facility-booking-detail-card"
                style={{ width: '100%' }}
                title={`${t('facilityBooking.detail.detailPageCards.resId', {
                    resId: selectedFacilityBookingDetail?.autoGeneratedCode,
                })}`}
                tabList={tabList}
                extra={
                    <div className="icon-group">
                        <Button
                            type="text"
                            shape="circle"
                            icon={<EditOutlined className="icon-size" />}
                            onClick={editFacilityBooking}
                        />
                        <Button
                            type="text"
                            shape="circle"
                            icon={<IdcardOutlined className="icon-size" />}
                            onClick={openPrintScreen}
                        />
                        <Button
                            type="text"
                            shape="circle"
                            icon={<DeleteOutlined className="icon-size" />}
                            onClick={_showConfirm}
                        />
                    </div>
                }
                activeTabKey={activeTabKey}
                onTabChange={(key) => {
                    onTabChange(key);
                }}
            >
                {contentList[activeTabKey]}
            </Card>
            <FacilityBookingPrintingModal
                isShowFacilityBookingPrintingModal={isShowFacilityBookingPrintingModal}
                onClose={closePrintModal}
            />
        </div>
    );
}

export default FacilityBookingDetailTabPane;
