const marketingChannelGroupList = {
    title: '販売チャネルグループ設定',
    breadcrumbs: {
        home: 'ホーム',
        marketingChannel: '販売チャネル',
        marketingChannelGroupSetting: '販売チャネルグループ設定',
    },
    channelGroupColumn: {
        id: 'ID',
        marketingChannelGroupName: 'チャネルグループ名',
    },
    message: {
        deleteChannelGroupSuccess: '販売チャネルグループが削除されました',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '販売チャネルグループ名',
    },
    searchBox: '検索',
    exportColumns: {
        name: '販売チャネルグループ名',
        autoGeneratedCode: 'ID',
    },
    initData: {
        cantEdit: `販売チャネルグループ名が変更できません`,
        cantDelete: `販売チャネルの項目が削除できません`,
    },
};

export default marketingChannelGroupList;
