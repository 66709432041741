export const kioskAlmexIntegrationJa = {
    breadcrumbs: {
        home: 'ホーム',
        integrations: '外部連携',
        almex: 'Almex自動精算機',
    },
    notification: {
        success: '更新されました',
        error: '更新に失敗しました',
        update: {
            setting: {
                success: 'Almex設定更新しました',
            },
        },
    },
    title: 'Almex連携設定',
    save: '保存',
    ok: 'はい',
    cancel: 'キャンセル',
    enabled: '連携中',
    disabled: '未連携',
    kioskAlmex: {
        roomKeyOptions: 'ルームキー種別',
        roomKeySystemId: 'システムID',
        terminalName: 'キオスク端末名',
        terminalCode: 'キオスク端末コード',
        terminalStatus: 'ステータス',
        addTerminal: 'キオスク端末追加',
    },
    keyOptions: {
        none: 'なし',
        miwa: 'MIWA IC',
        goal: 'GOAL IC',
        showa: 'ショウワIC',
    },
    modal: {
        delete: {
            title: '下記の項目を削除してもよろしいですか？',
        },
    },
};
