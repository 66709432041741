import { ArrowRightOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Collapse, Row, Space } from 'antd';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IBookingConfirmDataItem } from '~features/room-booking/interfaces';
import { createBookingStateSelector } from '~features/room-booking/reducers/create-booking.reducer';
import { useAppSelector } from '~hooks';
import './BookingDetail.scss';
import { marketingChannelDropDownsSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { formatMoney } from '~common/commonFunctions';

type Props = {
    item: IBookingConfirmDataItem;
    index: number;
};

export const BookingDetail = ({ item, index }: Props) => {
    const { t } = useTranslation();
    const { bookingInfo } = useAppSelector(createBookingStateSelector);
    const marketingChannels = useAppSelector(marketingChannelDropDownsSelector);

    const representativeGuest = useMemo(() => {
        const hasGuest = !!item.booking.guest.index;
        let _guest = null;
        if (item.booking.guest.index) {
            _guest = bookingInfo.attachments[item.booking.guest.index - 1];
        }
        return {
            phone: hasGuest ? _guest?.phone : bookingInfo.representativeGuest?.phone,
            fullName: hasGuest
                ? _guest?.fullName
                : bookingInfo.representativeGuest?.fullName,
            yomigana: hasGuest
                ? _guest?.yomigana
                : bookingInfo.representativeGuest?.yomigana,
        };
    }, [bookingInfo, item]);
    const reserverGuest = useMemo(() => {
        return bookingInfo.reserverGuest;
    }, [bookingInfo]);
    const checkOutTime = useMemo(() => {
        return item.booking.checkOutTime;
    }, [item]);
    const checkInTime = useMemo(() => {
        return item.booking.checkInTime;
    }, [item]);
    const marketingChannel = useMemo(() => {
        return marketingChannels.find((marketingChannel) => {
            return marketingChannel.id === bookingInfo.marketingChannelId;
        });
    }, [bookingInfo, marketingChannels]);
    const checkInDate = useMemo(() => {
        return item.booking.stayingStartDate;
    }, [item]);
    const checkOutDate = useMemo(() => {
        return item.booking.stayingEndDate;
    }, [item]);
    const childrenCount = useMemo(() => {
        const { children } = item.booking;
        return sumBy(children, 'quantity');
    }, [item]);
    const isRepresentativeRoom = useMemo(() => {
        return item.booking.isRepresentativeRoom;
    }, [item]);

    return (
        <div
            className={classNames('booking-detail-wrapper', {
                'mt-24': index > 0,
            })}
        >
            <Collapse
                className="booking-detail-collapse"
                defaultActiveKey={index === 0 ? '1' : ''}
                expandIconPosition="end"
            >
                <Collapse.Panel header={representativeGuest?.yomigana as string} key="1">
                    <div className="guest-info">
                        <Row>
                            <Col span={8}>
                                <div className="guest mb-6">
                                    {t('roomBooking.confirmPage.guest')}
                                </div>
                                <div className="name-hira mb-6 pr-8 text-truncate">
                                    {representativeGuest?.fullName}
                                </div>
                                <div className="name mb-6 pr-8 d-flex">
                                    <div className="text-truncate">
                                        {representativeGuest?.yomigana as string}
                                    </div>
                                    {isRepresentativeRoom && (
                                        <div className="representative-room">
                                            {t(
                                                'roomBooking.confirmPage.representativeRoom',
                                            )}
                                        </div>
                                    )}
                                </div>
                                {representativeGuest?.phone && (
                                    <div className="phone mb-6 pr-8 text-truncate">
                                        <PhoneOutlined className="mr-5" />
                                        {representativeGuest?.phone}
                                    </div>
                                )}
                            </Col>
                            <Col span={8}>
                                <div className="person mb-6">
                                    {t('roomBooking.confirmPage.reservationPerson')}
                                </div>
                                {bookingInfo.isReserverTheRepresentative ? (
                                    <div className="same-as-guest mb-6">
                                        ({t('roomBooking.confirmPage.sameGuest')})
                                    </div>
                                ) : (
                                    <>
                                        <div className="name-hira mb-6 text-truncate">
                                            {reserverGuest.fullName}
                                        </div>
                                        <div className="name mb-6 text-truncate">
                                            {reserverGuest.yomigana}
                                        </div>
                                        {reserverGuest.phone && (
                                            <div className="phone mb-6 pr-8 text-truncate">
                                                <PhoneOutlined className="mr-5" />
                                                {reserverGuest.phone}
                                            </div>
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <div className="booking-info mt-30">
                        <Row gutter={24}>
                            <Col span={12}>
                                <div className="text-truncate">
                                    {item.booking?.plan?.name}
                                </div>
                                <div className="room-name">
                                    <div className="text-truncate-50">
                                        {item.booking?.roomType?.name}
                                    </div>
                                    /
                                    <div className="text-truncate-50">
                                        {item.booking?.room?.name}
                                    </div>
                                </div>
                                <div>
                                    <UserOutlined />
                                    <span>
                                        {t('roomBooking.confirmPage.numberOfPeople', {
                                            adult: item.booking.numberOfAdults,
                                            children: childrenCount,
                                        })}
                                    </span>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>{`${t('roomBooking.confirmPage.lengthOfStay')} (${t(
                                    'common.standardTimeTitle',
                                )})`}</div>
                                <div className="d-flex">
                                    <div>
                                        <div className="date">{checkInDate}</div>
                                        <div>{checkInTime}</div>
                                    </div>
                                    <ArrowRightOutlined className="arrow-right" />
                                    <div>
                                        <div className="date">{checkOutDate}</div>
                                        <div>{checkOutTime}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="channel-info mt-24">
                        <div className="text-label">
                            {t('roomBooking.confirmPage.marketingChannel')}
                        </div>
                        <div className="text-value text-truncate">
                            {marketingChannel?.name}
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
            <div className="booking-detail-footer">
                <Space>
                    <span>{t('roomBooking.confirmPage.subtotal')}</span>
                    <span>¥{formatMoney(item.booking.price || 0)}</span>
                </Space>
            </div>
        </div>
    );
};
