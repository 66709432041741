const closingBookingPage = {
    title: 'Daily Closing',
    breadcrumbs: {
        home: 'Home',
        tightenWork: 'Room Closing',
    },
    filterForm: {
        status: 'Status:',
    },
    closingStatus: {
        all: 'All',
        not_checked_in: 'Not checked-in',
        not_checked_out: 'Not checked-out',
    },
    tabs: {
        room: 'Room closing',
        facility: 'Facility closing',
    },
    noBookingToDelete: 'There is no booking to delete',
};

export default closingBookingPage;
