import { useCallback, useEffect, useState } from 'react';
import { Button, Form, notification } from 'antd';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useTranslation } from 'react-i18next';
import RoomCleaningList from './RoomCleaningList/RoomCleaningList';
import RoomCleaningListMweb from './RoomCleaningListMweb/RoomCleaningListMweb';
import { bulkUpdateCleaningStatus } from '~features/room-cleaning/shema';
import './TabCleaningList.scss';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    bulkUpdateRoomCleanings,
    fetchRoomCleaningList,
    roomCleaningListSelector,
    setRoomCleaningList,
    selectedRoomCleaningListSelector,
    cleanAllRooms,
    dirtyAllRooms,
    clearAllRoomMemos,
} from '~features/room-cleaning/reducers/room-cleaning.reducer';
import {
    IRoomCleaning,
    IRoomCleaningBulkUpdateFormItem,
} from '~features/room-cleaning/interfaces';
import { showConfirm } from '~common/notification';
import { cloneDeep } from 'lodash';
import { CleaningStatus } from '~features/room-booking/constants';
import { CleaningOptions } from '~features/room-cleaning/constants';
import { useIsMobile } from '~common/useHooks';

function TabCleaningList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(bulkUpdateCleaningStatus),
    });

    const [editingKey, setEditingKey] = useState<number | undefined>(undefined);
    const roomCleaningList = useAppSelector(roomCleaningListSelector);
    const roomCleaningListSelected = useAppSelector(selectedRoomCleaningListSelector);
    const [isDisableCleanSelectedRoomBtn, setIsDisableCleanSelectedRoomBtn] =
        useState(true);
    const [isDisableDirtySelectedRoomBtn, setIsDisableDirtySelectedRoomBtn] =
        useState(true);
    const [isDisableCleanMemoSelectedRoomBtn, setIsDisableCleanMemoSelectedRoomBtn] =
        useState(true);

    const onChangeCleaningStatus = (value: string, roomCleaning: IRoomCleaning) => {
        const formData = [
            {
                id: roomCleaning.id,
                cleaningStatus:
                    value === CleaningStatus.CLEANED
                        ? CleaningStatus.UNCLEANED
                        : CleaningStatus.CLEANED,
                memo: roomCleaning.memo,
            },
        ];
        showConfirm({
            title: t('roomCleaning.list.confirm.titleSwitchStatus'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            onOk() {
                _bulkUpdateRoomCleanings(formData);
            },
        });
    };

    const onUpdateMemo = (roomCleaning: IRoomCleaning) => {
        const updateMemoRoomCleaning = roomCleaningList
            .filter((item) => {
                return item.id === roomCleaning.id;
            })
            .map((roomCleaning) => {
                return {
                    id: roomCleaning.id,
                    cleaningStatus: roomCleaning.cleaningStatus,
                    memo: roomCleaning.memo,
                };
            });
        showConfirm({
            title: t('roomCleaning.list.confirm.titleUpdateMemo'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            onOk() {
                _bulkUpdateRoomCleanings(updateMemoRoomCleaning);
            },
        });
    };
    const onChangeMemoRoomCleaning = (value: string, roomCleaning: IRoomCleaning) => {
        const cloneRoomCleaning = cloneDeep(roomCleaningList);
        cloneRoomCleaning.forEach((item) => {
            if (item.id === roomCleaning.id) {
                item.memo = value;
            }
        });
        dispatch(setRoomCleaningList(cloneRoomCleaning));
    };

    const _bulkUpdateRoomCleanings = useCallback(
        async (roomCleanings: IRoomCleaningBulkUpdateFormItem[]) => {
            const response = await dispatch(
                bulkUpdateRoomCleanings(
                    roomCleanings.map((roomCleaning) => ({
                        id: roomCleaning.id,
                        cleaningStatus: roomCleaning.cleaningStatus,
                        memo: roomCleaning.memo ? roomCleaning.memo : null,
                    })),
                ),
            );
            if (bulkUpdateRoomCleanings.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('roomCleaning.list.updateSuccessMessage'),
                    });
                    setEditingKey(undefined);
                    dispatch(fetchRoomCleaningList());
                } else {
                    notification.error({
                        message: response.payload?.errors?.[0]?.message || '',
                    });
                }
            }
        },
        [],
    );

    const _cleanAllRooms = useCallback(async () => {
        const response = await dispatch(cleanAllRooms());
        if (cleanAllRooms.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomCleaning.list.updateSuccessMessage'),
                });
                dispatch(fetchRoomCleaningList());
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    const _dirtyAllRooms = useCallback(async () => {
        const response = await dispatch(dirtyAllRooms());
        if (dirtyAllRooms.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomCleaning.list.updateSuccessMessage'),
                });
                dispatch(fetchRoomCleaningList());
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    const _clearAllRoomMemos = useCallback(async () => {
        const response = await dispatch(clearAllRoomMemos());
        if (clearAllRoomMemos.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomCleaning.list.updateSuccessMessage'),
                });
                dispatch(fetchRoomCleaningList());
            } else {
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    useEffect(() => {
        if (roomCleaningListSelected.length === 0) {
            setIsDisableCleanSelectedRoomBtn(true);
            setIsDisableCleanMemoSelectedRoomBtn(true);
            setIsDisableDirtySelectedRoomBtn(true);
        } else {
            const hasEnableBtn = roomCleaningListSelected.some(
                (item) => item.cleaningStatus === CleaningStatus.UNCLEANED,
            );
            const hasEnableDirtyBtn = roomCleaningListSelected.some(
                (item) => item.cleaningStatus === CleaningStatus.CLEANED,
            );
            const hasEnableClearMemoBtn = roomCleaningListSelected.some(
                (item) => item.memo,
            );

            hasEnableBtn
                ? setIsDisableCleanSelectedRoomBtn(false)
                : setIsDisableCleanSelectedRoomBtn(true);
            hasEnableClearMemoBtn
                ? setIsDisableCleanMemoSelectedRoomBtn(false)
                : setIsDisableCleanMemoSelectedRoomBtn(true);
            hasEnableDirtyBtn
                ? setIsDisableDirtySelectedRoomBtn(false)
                : setIsDisableDirtySelectedRoomBtn(true);
        }
    }, [roomCleaningListSelected]);

    const cleanRoom = () => {
        handleSubmit(() => {
            const idsSelected = roomCleaningListSelected.map((item) => {
                return item.id;
            });
            const updateDataForm = roomCleaningList
                .filter((item) => {
                    return idsSelected.includes(item.id);
                })
                .map((roomCleaning) => {
                    return {
                        id: roomCleaning.id,
                        cleaningStatus: CleaningStatus.CLEANED,
                        memo: roomCleaning.memo,
                    };
                });
            showConfirm({
                title: t('roomCleaning.list.confirm.titleCleanSelectedRoom'),
                cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
                okText: t('roomCleaning.list.confirm.buttonDeleteText'),
                onOk() {
                    _bulkUpdateRoomCleanings(updateDataForm);
                },
            });
        })();
    };

    const cleanAllRoom = () => {
        showConfirm({
            title: t('roomCleaning.list.confirm.titleCleanAllRoom'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            onOk() {
                _cleanAllRooms();
            },
        });
    };

    const durtyAllRoom = () => {
        showConfirm({
            title: t('roomCleaning.list.confirm.titleDirtyAllRoom'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            onOk() {
                _dirtyAllRooms();
            },
        });
    };

    const clearAllMemo = () => {
        showConfirm({
            title: t('roomCleaning.list.confirm.titleCleanAllMemo'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            onOk() {
                _clearAllRoomMemos();
            },
        });
    };
    const updateRoomCleaningSelection = (
        roomCleaningList: IRoomCleaning[],
        option: CleaningOptions,
    ) => {
        const idsSelected = roomCleaningList.map((item) => {
            return item.id;
        });
        const updateDataForm = roomCleaningList
            .filter((item) => {
                return idsSelected.includes(item.id);
            })
            .map((roomCleaning) => {
                if (option === CleaningOptions.DIRTY_SELECTION) {
                    return {
                        id: roomCleaning.id,
                        cleaningStatus: CleaningStatus.UNCLEANED,
                        memo: roomCleaning.memo,
                    };
                }
                return {
                    id: roomCleaning.id,
                    cleaningStatus: roomCleaning.cleaningStatus,
                    memo: null,
                };
            });
        showConfirm({
            title:
                option === CleaningOptions.DIRTY_SELECTION
                    ? t('roomCleaning.list.confirm.titleDirtySelectedRoom')
                    : t('roomCleaning.list.confirm.titleCleanSelectedMemo'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            onOk() {
                _bulkUpdateRoomCleanings(updateDataForm);
            },
        });
    };

    const onClickCleaningOption = (value: CleaningOptions) => {
        switch (value) {
            case CleaningOptions.DIRTY_SELECTION: {
                updateRoomCleaningSelection(roomCleaningListSelected, value);
                break;
            }
            case CleaningOptions.CLEAR_MEMO_SELECTION: {
                updateRoomCleaningSelection(roomCleaningListSelected, value);
                break;
            }
            case CleaningOptions.DIRTY_ALL: {
                durtyAllRoom();
                break;
            }
            case CleaningOptions.CLEAR_ALL_MEMO: {
                clearAllMemo();
                break;
            }
        }
    };
    return (
        <div className="tab_cleaning_list_wrapper">
            <Form layout="vertical">
                <div className="action-room-cleaning-wrapper">
                    <div className="room-cleaning-left-block"></div>

                    <div className="room-cleaning-btn">
                        <>
                            {!isMobile && (
                                <Button
                                    disabled={isDisableDirtySelectedRoomBtn}
                                    onClick={() => {
                                        onClickCleaningOption(
                                            CleaningOptions.DIRTY_SELECTION,
                                        );
                                    }}
                                >
                                    <span className="btn-text">
                                        {t(
                                            'roomCleaning.list.cleaningOption.dirtyRoomSelection',
                                        )}
                                    </span>
                                </Button>
                            )}
                            <Button
                                type="primary"
                                onClick={() => {
                                    onClickCleaningOption(CleaningOptions.DIRTY_ALL);
                                }}
                            >
                                <span className="btn-text">
                                    {t('roomCleaning.list.cleaningOption.dirtyAllRoom')}
                                </span>
                            </Button>
                            {!isMobile && (
                                <Button
                                    disabled={isDisableCleanMemoSelectedRoomBtn}
                                    onClick={() => {
                                        onClickCleaningOption(
                                            CleaningOptions.CLEAR_MEMO_SELECTION,
                                        );
                                    }}
                                >
                                    <span className="btn-text">
                                        {t(
                                            'roomCleaning.list.cleaningOption.clearRoomMemoSelection',
                                        )}
                                    </span>
                                </Button>
                            )}
                            <Button
                                type="primary"
                                onClick={() => {
                                    onClickCleaningOption(CleaningOptions.CLEAR_ALL_MEMO);
                                }}
                            >
                                <span className="btn-text">
                                    {t(
                                        'roomCleaning.list.cleaningOption.clearAllRoomMemo',
                                    )}
                                </span>
                            </Button>
                            {!isMobile && (
                                <Button
                                    disabled={isDisableCleanSelectedRoomBtn}
                                    onClick={cleanRoom}
                                >
                                    <span className="btn-text">
                                        {t('roomCleaning.list.cleanSelectedRoom')}
                                    </span>
                                </Button>
                            )}
                            <Button type="primary" onClick={cleanAllRoom}>
                                <span className="btn-text">
                                    {t('roomCleaning.list.cleanAllRoomBtn')}
                                </span>
                            </Button>
                        </>
                    </div>
                </div>
                {isMobile ? (
                    <RoomCleaningListMweb control={control} reset={reset} />
                ) : (
                    <RoomCleaningList
                        control={control}
                        reset={reset}
                        onChangeCleaningStatus={onChangeCleaningStatus}
                        onChangeMemoRoomCleaning={onChangeMemoRoomCleaning}
                        onUpdateMemo={onUpdateMemo}
                        setEditingKey={setEditingKey}
                        editingKey={editingKey}
                    />
                )}
            </Form>
        </div>
    );
}

export default TabCleaningList;
