const rankCalendarForm = {
    createTitle: '新規カレンダーの作成',
    updateTitle: '詳細設定',
    updateDescription: '適用したいランクを設定してください',
    createBtn: '作成',
    name: {
        label: 'カレンダー名',
        placeholder: 'カレンダー名を入力してください',
    },
    dailySettings: {
        title: '日別設定',
        monday: '月曜日',
        tuesday: '火曜日',
        wednesday: '水曜日',
        thursday: '木曜日',
        friday: '金曜日',
        saturday: '土曜日',
        sunday: '日曜日',
    },
    rankDuration: {
        title: 'カスタムランク',
        duration: {
            label: '期間',
            placeholder: {
                startDate: '開始日',
                endDate: '終了日',
            },
        },
        tllRank: {
            label: 'ランク',
            placeholder: 'ランクを選択',
        },
    },
    additionBtn: '追加',
    create: {
        successMessage: 'ランクカレンターが作成されました',
        errorMessage: 'ランクカレンダー作成エラー',
    },
    confirm: { cancelTitle: 'キャンセルしてもよろしいですか' },
    updateBtn: '適用',
    update: {
        successMessage: 'ランクカレンターが更新されました',
        errorMessage: 'ランクカレンターエラーを更新',
    },
    overlapMessage: 'プランランク期間が重複しています',
    status: {
        active: '有効',
    },
    noChangeOption: '変更なし',
};

export default rankCalendarForm;
