import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import './RightDrawerLayout.scss';
import { Button, Col, Drawer, DrawerProps, Row, Space, Spin } from 'antd';
import classNames from 'classnames';
import { DRAWER_WIDTH_DEFAULT } from '~common/constants';

interface IProp extends DrawerProps {
    title: string;
    cancelText?: string;
    submitText?: string;
    submitId?: string;
    width?: number | string;
    disabledSaveButton?: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    busy: boolean;
    loading: boolean;
    isCloseOutside?: boolean;
}

export function RightDrawerLayout(props: IProp) {
    const {
        width,
        open,
        title,
        onClose,
        onSubmit,
        submitText,
        cancelText,
        children,
        className,
        busy,
        placement,
        footer,
        loading,
        disabledSaveButton,
        submitId,
        isCloseOutside,
        ...rest
    } = props;
    const { t } = useTranslation();
    const dClasses = classNames('custom-drawer', className);
    return (
        <Drawer
            placement={placement || 'right'}
            width={width}
            closable={false}
            open={open}
            rootClassName={dClasses}
            title={title}
            extra={
                <Space>
                    <CloseOutlined onClick={onClose} />
                </Space>
            }
            footer={
                footer || (
                    <div className="custom-drawer-footer">
                        <Row gutter={8}>
                            <Col span={12}>
                                {!!cancelText && (
                                    <Button className="cancel-button" onClick={onClose}>
                                        {cancelText}
                                    </Button>
                                )}
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    className="save-button"
                                    id={submitId}
                                    onClick={onSubmit}
                                    disabled={disabledSaveButton}
                                    loading={busy}
                                >
                                    {submitText || t('common.buttonSaveText')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )
            }
            styles={{
                footer: {
                    paddingLeft: 24,
                    paddingRight: 24,
                },
            }}
            onClose={isCloseOutside ? onClose : undefined}
            {...rest}
        >
            <Spin spinning={loading}>
                <div className="custom-drawer-container">{children}</div>
            </Spin>
        </Drawer>
    );
}

RightDrawerLayout.defaultProps = {
    busy: false,
    loading: false,
    width: DRAWER_WIDTH_DEFAULT,
};
