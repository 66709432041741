const notificationForm = {
    titlePage: 'お知らせの作成',
    breadcrumbs: {
        home: 'ホーム',
        notification: 'お知らせ',
        notificationSetting: 'お知らせ設定',
    },
    informationTitle: '詳細情報',
    title: 'タイトル',
    titleInput: 'タイトルをご記入ください',
    deliveryTime: '配信日時',
    deliveryTimePlaceHolder: '配信日時',
    tag: 'タイプ',
    hotelDesignation: 'ホテル指定',
    hotelDesignationPlaceHolder: '選択してください',
    detail: '詳細',
    detailInput: '詳細をご記入ください',
    submitBtn: '保存',
    cancelBtn: 'キャンセル',
    tagLenghError: 'タイプ は最大 {{maxLength}} 字です',
    create: {
        success: 'お知らせが作成されました',
        error: 'お知らせが作成されませんでした',
    },
    update: {
        success: 'お知らせが更新されました!',
        error: 'お知らせが更新されませんでした',
    },
    confirm: {
        cancelTitle: 'キャンセルしますか?',
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
    },
};

export default notificationForm;
