import { reportService } from '../report.service';
import { ISalesPaymentState } from '../interfaces';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { parseDate } from '~plugins/dayjs';

const initialState: ISalesPaymentState = {
    salesPaymentList: [],
    showLoading: false,
    isShowTable: false,
    isExportCsv: false,
};

export const getSalesPaymentList = createAsyncThunk(
    'salesPayment/getSalesPaymentList',
    async (datePeriod: string[]) => {
        return await reportService.getSalesPayment([
            parseDate(datePeriod[0])?.fmYYYYMMDDHHmmss(),
            parseDate(datePeriod[1])?.fmYYYYMMDDHHmmss(),
        ]);
    },
);

const salesPaymentSlice = createSlice({
    name: 'salesPayment',
    initialState,
    reducers: {
        setIsShowTable: (state, action) => {
            state.isShowTable = action.payload;
        },
        setExportCsv: (state, action) => {
            state.isExportCsv = action.payload;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSalesPaymentList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(getSalesPaymentList.fulfilled, (state, action) => {
            state.salesPaymentList = action.payload?.data || [];
            state.showLoading = false;
        });
    },
});

export const { setIsShowTable, resetState, setExportCsv } = salesPaymentSlice.actions;

export const salesPaymentStateSelector = (state: AppState) => {
    return state.reportSalesPayment;
};

export default salesPaymentSlice.reducer;
