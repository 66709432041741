import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Routes, useLocation } from 'react-router-dom';
import { analytics } from './service';

// Google Analytics v3 id, we are keeping this for general tabist analytics
const googleAnalyticsId = process.env.REACT_APP_GA_MEASUREMENT_ID || '';

export function RoutesWithAnalytics({ children }: { children: React.ReactNode }) {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize(googleAnalyticsId);
    }, []);

    useEffect(() => {
        analytics.page({
            path: location.pathname,
            search: location.search,
        });
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return <Routes>{children}</Routes>;
}
