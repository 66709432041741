import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Layout, notification, Space } from 'antd';
import { useCallback, useEffect } from 'react';
import { useForm } from '~plugins/hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { validateUniqueValue } from '~common/commonFunctions';
import { ErrorMessageType } from '~common/constants';
import { StaffLayoutHeader } from '~components';
import { getFacilityTypeListForDropDown } from '~features/facility-type/facility-type.reducer';
import CreateFacilitiesForm from '~features/facility/components/CreateFacilitiesForm/CreateFacilitiesForm';
import { breadcrumbsCreatePage } from '~features/facility/constants';
import {
    bulkCreateFacilities,
    createFacilityListSelector,
    setCreateFacilityList,
    showLoadingSelector,
} from '~features/facility/facility.reducer';
import { IFacilitiesBulkCreateItem } from '~features/facility/interfaces';
import { showConfirm } from '~common/notification';
import { bulkCreateFacilitySchema } from '~features/facility/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import './CreateFacilityPage.scss';
import { getFacilityFormId } from '~features/facility/helper';

const { Footer, Content } = Layout;

function CreateFacilityPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const formBusy = useAppSelector(showLoadingSelector);

    const { control, getValues, reset, handleSubmit, setError } = useForm({
        resolver: yupResolver(bulkCreateFacilitySchema),
    });

    const _bulkCreateFacilities = useCallback(
        async (facilities: IFacilitiesBulkCreateItem[]) => {
            const response = await dispatch(
                bulkCreateFacilities(
                    facilities.map((facility, index) => ({
                        name: facility.name,
                        facilityTypeId: facility.facilityTypeId,
                        order: index + 1,
                    })),
                ),
            );
            if (bulkCreateFacilities.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('facility.create.createSuccessMessage'),
                    });
                    navigate(-1);
                } else {
                    (response.payload?.errors || []).forEach((error) => {
                        if (
                            (error.key === 'name' || error.key === 'facilityTypeId') &&
                            error.order
                        ) {
                            setError(
                                `items.${error.order - 1}.${error.key}`,
                                { type: ErrorMessageType.MANUAL, message: error.message },
                                { shouldFocus: true },
                            );
                        }
                    });
                    notification.error({
                        message: response.payload?.message,
                    });
                }
            }
        },
        [],
    );

    const onSubmit = () => {
        handleSubmit((data) => {
            const newFacilities = getValues().items || [];
            if (!newFacilities.length) {
                return;
            }
            const duplicatedNameIndexs = validateUniqueValue(newFacilities, 'name');
            if (duplicatedNameIndexs.length) {
                duplicatedNameIndexs.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('facility.create.facilityNameDuplicated'),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkCreateFacilities(data.items);
        })();
    };

    const onCancel = () => {
        showConfirm({
            title: t('facility.create.confirm.cancelTitle'),
            cancelText: t('facility.create.confirm.buttonCancelText'),
            okText: t('facility.create.confirm.buttonDeleteText'),
            onOk() {
                navigate('/front-settings/facility');
            },
        });
    };

    const facilityList = useAppSelector(createFacilityListSelector);
    // set facilityList form when has any change
    useEffect(() => {
        reset({
            items: facilityList,
        });
    }, [facilityList]);

    useEffect(() => {
        dispatch(getFacilityTypeListForDropDown({}));
        return () => {
            // Anything in here is fired on component unmount.
            dispatch(setCreateFacilityList([]));
        };
    }, []);

    return (
        <div className="create-facilities-page">
            <StaffLayoutHeader
                isShowButtonBack={true}
                breadcrumbs={breadcrumbsCreatePage()}
                title={t('facility.create.title')}
            />
            <Content>
                <CreateFacilitiesForm
                    control={control}
                    currentFacilitiesList={getValues()?.items}
                />
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space align="end">
                        <Button onClick={onCancel}>{t('common.buttonCancelText')}</Button>
                        <Button
                            type="primary"
                            id={getFacilityFormId('btn-submit')}
                            onClick={onSubmit}
                            loading={formBusy}
                            disabled={getValues()?.items?.length === 0}
                        >
                            {t('common.buttonSaveText')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default CreateFacilityPage;
