const facilityTypeList = {
    title: '設備タイプ設定',
    breadcrumbs: {
        home: 'ホーム',
        facilityType: '設備タイプ設定',
    },
    filterForm: {
        buttons: {
            create: '設備タイプを登録',
        },
    },
    yen: '円',
    hour: '時',
    minute: '分',
    columns: {
        id: 'ID',
        name: '設備タイプ名',
        abbreviation: '略称',
        businessTime: '営業時間',
        price: '定額料金',
        duration: '基本料金',
        basicCharge: '{{price}}円/{{hour}}時{{minute}}分',
    },
    searchColumn: {
        keyword: '全て',
        autoGeneratedCode: 'ID',
        name: '設備タイプ名',
        abbreviation: '略称',
    },
    deleteFacilityTypesSuccess: '設備タイプが削除されました',

    exportColumns: {
        autoGeneratedCode: 'ID',
        name: '設備タイプ名',
        abbreviation: '略称',
        businessTimes: '営業時間',
        price: '定額料金',
        duration: '基本料金',
    },
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
    },
};

export default facilityTypeList;
