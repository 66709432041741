export const integrationsEn = {
    breadcrumbs: {
        home: 'Home',
        integrations: 'Integrations',
    },
    notification: {
        success: 'Sccess',
        error: 'Error',
    },
    title: 'Integrations',
    save: 'Save',
    hotelSmart: {
        title: 'HOTEL SMART',
        userId: 'User ID',
        password: 'Password',
        checkedInDate: 'Checked-in date',
        houseId: 'House ID',
    },
};
