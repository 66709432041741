import { Form, Input } from 'antd';
import React, { useEffect, useRef } from 'react';
import { SearchProps } from 'antd/lib/input';
import { Controller, useForm } from '~plugins/hook-form';
import { parseErrorMessage } from '~common/commonFunctions';
import yup from '~plugins/yup';
import './InputSearch.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import trim from 'lodash/trim';
import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';

interface CustomInput extends SearchProps {
    label?: string;
    defaultValue: string;
    placeholder?: string;
    resolver?: any;
    max: number;
    allowClear?: boolean;
    setDefaultValue?: string;
}

export function InputSearch(props: CustomInput) {
    const {
        defaultValue,
        resolver,
        label,
        placeholder,
        onChange,
        max,
        onSearch,
        setDefaultValue,
        allowClear = false,
        maxLength = INPUT_TEXT_MAX_LENGTH,
        ...rest
    } = props;
    const name = 'keyword';
    const filterSchema = yup.object().shape({
        [name]: yup.string().max(max),
    });
    const { control, handleSubmit, setValue } = useForm<{ [name]: string }>({
        resolver: yupResolver(filterSchema),
    });

    const inputRef = useRef<any>(null);

    const _onSearch = () => {
        handleSubmit((data) => {
            if (onSearch) {
                inputRef.current?.blur();
                onSearch(data[name]);
            }
        })();
    };

    useEffect(() => {
        setValue(`keyword`, setDefaultValue || '');
    }, [setDefaultValue]);

    return (
        <div className="search-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                        >
                            <Input.Search
                                {...field}
                                {...rest}
                                ref={inputRef}
                                allowClear={allowClear}
                                maxLength={maxLength}
                                onChange={(...agrs) => {
                                    if (onChange) {
                                        onChange(...agrs);
                                    }
                                    field.onChange(...agrs);
                                }}
                                onSearch={_onSearch}
                                placeholder={placeholder}
                                onBlur={(event) => {
                                    field.onChange(trim(event.target.value));
                                    field.onBlur();
                                }}
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

InputSearch.defaultProps = {
    defaultValue: '',
    name: 'keyword',
    max: 100,
};
