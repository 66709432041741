import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Form,
    FormInstance,
    Layout,
    Row,
    Space,
    Spin,
    Tabs,
    notification,
} from 'antd';
import { sumBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DeepRequired, FieldErrorsImpl, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { isDataDetailFetched } from '~common/commonFunctions';
import { ErrorMessageType, UploadFileStatus } from '~common/constants';
import { StaffLayoutHeader } from '~components';
import { getChildrenDropdown } from '~features/children-type/reducers/children.reducer';
import {
    MAX_NUMBER_OF_FILES_PER_GUEST,
    UPLOAD_FILE_ERROR_I18N_KEY,
} from '~features/guest/constants';
import { validateFileExtension } from '~features/guest/helper';
import { IFile } from '~features/guest/interfaces';
import { getGuestListForDropdown } from '~features/guest/reducers/guest.reducer';
import { getPlanDropdown } from '~features/plan/reducers/plan.reducer';
import {
    ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS,
    FormTab,
    MAX_NUMBER_OF_FILES_PER_BOOKING,
    MAX_SIZE_OF_EACH_BOOKING_FILE,
    RoomBookingEvent,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import {
    errorMessagesBookingFile,
    validateFileSize,
} from '~features/room-booking/helper';
import {
    isFrozenBooking,
    transformUpdateBookingForm,
    transformUpdateBookingFormData,
} from '~features/room-booking/helper.update-booking';
import { IUpdateBookingFormData } from '~features/room-booking/interfaces';
import {
    fetchMarketingChannelDropDown,
    fetchRoomBookingDetail,
    selectedRoomBookingDetailSelector,
    showLoadingSelector,
    updateBooking,
} from '~features/room-booking/reducers/room-booking.reducer';
import { updateBookingSchemaResolver } from '~features/room-booking/schema';
import { getRoomTypeListForDropdown } from '~features/room-type/room-type.reducer';
import { getListForDropDown } from '~features/room/room.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useMitt } from '~plugins/mitt';
import GeneralBookingCard from '../CreateBookingPage/components/GeneralBookingCard/GeneralBookingCard';
import RoomBookingItemGridView from '../RoomBookingDetailPage/components/RoomBookingDetailTabPane/RoomBookingItemGridView/RoomBookingItemGridView';
import RoomBookingItemListView from '../RoomBookingDetailPage/components/RoomBookingDetailTabPane/RoomBookingItemListView/RoomBookingItemListView';
import { CheckinModal } from '../SchedulePage/components/CheckinModal/CheckinModal';
import './RoomBookingEditPage.scss';

function RoomBookingEditPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { roomBookingId } = useParams();

    const breadcrumbs = () => [
        {
            text: t('roomBooking.page.breadcrumbs.home'),
        },
        {
            text: t('roomBooking.page.breadcrumbs.schedule'),
        },
        {
            text: t('roomBooking.page.breadcrumbs.updateBooking'),
        },
    ];
    const {
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        clearErrors,
        setError,
        formState: { errors },
    } = useForm({
        resolver: updateBookingSchemaResolver,
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const [formTab, setFormTab] = useState<FormTab>(FormTab.BASIC_INFO);
    const [isListMode, setIsListMode] = useState<boolean>(false);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const isFetching = useAppSelector(showLoadingSelector);

    const formRef = useRef<FormInstance>(null);

    const canEdit = useMemo(() => {
        // booking status is check-out or check-in
        const isHasCheckInOrCheckOutBooking =
            selectedRoomBookingDetail?.roomBookingItems?.some(
                (childBooking) =>
                    childBooking.bookingStatus ===
                        RoomBookingItemBookingStatus.CHECKED_IN ||
                    childBooking.bookingStatus ===
                        RoomBookingItemBookingStatus.CHECKED_OUT,
            );
        // all item bookings are canceled
        const isHasAllCancelBooking = selectedRoomBookingDetail?.roomBookingItems?.every(
            (childBooking) =>
                childBooking.bookingStatus === RoomBookingItemBookingStatus.CANCELLED,
        );

        if (isHasCheckInOrCheckOutBooking || isHasAllCancelBooking) {
            return false;
        }
        return true;
    }, [selectedRoomBookingDetail]);

    const isFrozen = useMemo(() => {
        return isFrozenBooking(selectedRoomBookingDetail?.roomBookingItems || []);
    }, [selectedRoomBookingDetail?.roomBookingItems]);

    const marketingChannel = useMemo(() => {
        return selectedRoomBookingDetail?.marketingChannel;
    }, [selectedRoomBookingDetail]);

    const isShowMemberTab = useMemo(() => {
        const numberOfGuests =
            sumBy(selectedRoomBookingDetail?.roomBookingItems, 'numberOfAdults') || 0;
        return numberOfGuests > 1 || (selectedRoomBookingDetail?.guests?.length || 0) > 1;
    }, [selectedRoomBookingDetail]);

    const fetchData = () => {
        dispatch(fetchRoomBookingDetail(Number(roomBookingId)));
    };

    useEffect(() => {
        dispatch(getGuestListForDropdown({}));
        dispatch(fetchMarketingChannelDropDown({}));
        dispatch(getListForDropDown({}));
        dispatch(getChildrenDropdown());
        if (!isDataDetailFetched(selectedRoomBookingDetail, roomBookingId)) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (selectedRoomBookingDetail) {
            reset({
                ...transformUpdateBookingForm(selectedRoomBookingDetail),
            });
            const isFromTll = !!selectedRoomBookingDetail?.tllDataId;
            dispatch(getPlanDropdown({ includeDataFromTll: isFromTll }));
            dispatch(getRoomTypeListForDropdown({ isPullFromTll: isFromTll }));
        }
    }, [selectedRoomBookingDetail, reset, setValue, dispatch]);

    const goBack = () => {
        navigate(`/room-booking/${roomBookingId}/detail`);
    };

    const scrollToErrors = (errors: FieldErrorsImpl<DeepRequired<FieldValues>>) => {
        const errorKeys = Object.keys(errors);
        if (!errorKeys.length) {
            return;
        }
        if (errors.members) {
            if (formTab !== FormTab.COMPANION_INFO) {
                setFormTab(FormTab.COMPANION_INFO);
            }
        } else {
            if (formTab !== FormTab.BASIC_INFO) {
                setFormTab(FormTab.BASIC_INFO);
            }
        }
    };

    const _updateBooking = async (formData: IUpdateBookingFormData | FormData) => {
        const response = await dispatch(
            updateBooking({ id: Number(roomBookingId), formData }),
        );
        if (updateBooking.fulfilled.match(response)) {
            if (response.payload?.success) {
                const messageKey = response.payload
                    ?.message as keyof typeof errorMessagesBookingFile;
                if (
                    messageKey &&
                    Object.keys(errorMessagesBookingFile).includes(messageKey)
                ) {
                    notification.error({
                        message: errorMessagesBookingFile[messageKey],
                    });
                }
                if (response.payload?.message === UPLOAD_FILE_ERROR_I18N_KEY) {
                    notification.error({
                        message: t(`guest.update.${UPLOAD_FILE_ERROR_I18N_KEY}`),
                    });
                }
                notification.success({
                    message: t('roomBooking.updateBooking.update.success'),
                });
                navigate(`/room-booking/${roomBookingId}/detail`);
                return;
            }
            notification.error({
                message: t('roomBooking.updateBooking.update.error'),
                description: response.payload?.message || '',
            });

            // show error message when uploading file
            const errorKey = response.payload
                ?.message as keyof typeof errorMessagesBookingFile;
            if (errorKey && Object.keys(errorMessagesBookingFile).includes(errorKey)) {
                notification.error({
                    message: errorMessagesBookingFile[errorKey],
                });
                return;
            }
        }
    };

    useEffect(() => {
        scrollToErrors(errors);
    }, [errors]);

    const checkAttachments = (attachmentsOfGuest: IFile[]) => {
        if (attachmentsOfGuest.length > MAX_NUMBER_OF_FILES_PER_BOOKING) {
            return {
                isError: true,
                message: t('guest.form.uploadFile.message.maxCount', {
                    maxCount: MAX_NUMBER_OF_FILES_PER_GUEST,
                }),
            };
        }
        const validFileSize = validateFileSize(
            attachmentsOfGuest,
            MAX_SIZE_OF_EACH_BOOKING_FILE,
        );
        if (validFileSize.isError) {
            return { isError: true, message: validFileSize.message };
        }
        const { isError, message } = validateFileExtension(
            attachmentsOfGuest,
            ACCEPTED_ROOM_BOOKING_FILE_EXTENSIONS,
        );
        if (isError) {
            return { isError: true, message };
        }
        return { isError: false, message: '' };
    };

    const submit = () => {
        handleSubmit((data) => {
            const attachmentsOfBooking = data.files || [];

            const { isError, message } = checkAttachments(attachmentsOfBooking);

            if (isError) {
                setError(
                    `files`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message,
                    },
                    { shouldFocus: true },
                );
                return;
            }

            const files = attachmentsOfBooking?.filter(
                (file: IFile) => file.status === UploadFileStatus.DONE,
            );
            const updateRoomBookingFormData = transformUpdateBookingFormData({
                isReserverTheRepresentative: data.isReserverTheRepresentative,
                representativeGuest: data.representativeGuest,
                reserverGuest: data.reserverGuest,
                marketingChannelId: data.marketingChannelId,
                birthday: data.birthday,
                gender: data.gender,
                memo: data.memo,
                otaMemo: data.otaMemo,
                members: data.members,
                files,
            });
            _updateBooking(updateRoomBookingFormData);
        })();
    };

    const changeViewMode = () => {
        setIsListMode(!isListMode);
    };

    const { emitter } = useMitt();

    useEffect(() => {
        emitter.on(RoomBookingEvent.CHANGE_TAB, (tab: FormTab) => {
            setFormTab(tab);
        });

        return () => {
            emitter.off(RoomBookingEvent.CHANGE_TAB);
        };
    }, []);

    return (
        <div className="edit-booking-page">
            <Spin spinning={isFetching}>
                <StaffLayoutHeader
                    breadcrumbs={breadcrumbs()}
                    title={t('roomBooking.page.breadcrumbs.updateBooking')}
                    isShowButtonBack
                />
                <Layout.Content>
                    <div className="edit-booking-page-tab-header">
                        <Tabs
                            activeKey={formTab}
                            onChange={(key) => setFormTab(key as FormTab)}
                            items={[
                                {
                                    key: FormTab.BASIC_INFO,
                                    label: t('roomBooking.updateBooking.basicInfo'),
                                },
                            ].concat(
                                isShowMemberTab
                                    ? [
                                          {
                                              key: FormTab.COMPANION_INFO,
                                              label: t(
                                                  'roomBooking.updateBooking.companionInfo',
                                              ),
                                          },
                                      ]
                                    : [],
                            )}
                        />
                    </div>
                    <div className="edit-booking-page-content">
                        <Form layout="vertical" ref={formRef} scrollToFirstError>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <GeneralBookingCard
                                        control={control}
                                        formTab={formTab}
                                        disabled={!canEdit}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        getValues={getValues}
                                        isFrozen={isFrozen}
                                        onChangeFormTab={(key) =>
                                            setFormTab(key as FormTab)
                                        }
                                        marketingFromTll={
                                            marketingChannel?.isPullFromTll
                                                ? marketingChannel?.name
                                                : undefined
                                        }
                                        additionalMarketingChannel={marketingChannel}
                                    />
                                </Col>
                            </Row>
                        </Form>
                        <Card
                            className="card-booking-info"
                            title={
                                <div>
                                    <span className="b-title">
                                        {t(
                                            'roomBooking.detail.detailPageCards.bookingList',
                                        )}
                                    </span>
                                    {selectedRoomBookingDetail?.roomBookingItems
                                        ?.length && (
                                        <span className="count">
                                            {
                                                selectedRoomBookingDetail
                                                    ?.roomBookingItems?.length
                                            }
                                        </span>
                                    )}
                                </div>
                            }
                            extra={
                                !isListMode ? (
                                    <Button
                                        type="text"
                                        shape="circle"
                                        onClick={changeViewMode}
                                        icon={
                                            <UnorderedListOutlined className="icon-size" />
                                        }
                                    />
                                ) : (
                                    <Button
                                        type="text"
                                        shape="circle"
                                        onClick={changeViewMode}
                                        icon={<AppstoreOutlined className="icon-size" />}
                                    />
                                )
                            }
                        >
                            {isListMode ? (
                                <RoomBookingItemListView
                                    roomBookingDetail={selectedRoomBookingDetail}
                                    fetchRoomBookingDetail={(id) =>
                                        dispatch(fetchRoomBookingDetail(id))
                                    }
                                />
                            ) : (
                                <RoomBookingItemGridView
                                    roomBookingDetail={selectedRoomBookingDetail}
                                    fetchRoomBookingDetail={(id) =>
                                        dispatch(fetchRoomBookingDetail(id))
                                    }
                                />
                            )}
                        </Card>
                    </div>
                </Layout.Content>
                <Layout.Footer>
                    <Space className="d-flex j-end">
                        <Button onClick={goBack}>
                            {t('roomBooking.updateBooking.cancel')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={submit}
                            loading={isFetching}
                            disabled={isFrozen}
                        >
                            {t('roomBooking.updateBooking.submit')}
                        </Button>
                    </Space>
                </Layout.Footer>
                <CheckinModal
                    isLoadedData={true}
                    onChangeBookingStatusSuccess={() => fetchData()}
                />
            </Spin>
        </div>
    );
}

export default RoomBookingEditPage;
