import { InputProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { UseFormGetValues, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputNumber, SingleSelect } from '~components';
import { PriceType } from '~features/children-type/constants';
import { getChildrenTypeFormId } from '~features/children-type/helper';
import { IChildrenType } from '~features/children-type/interfaces';
import './InputPriceType.scss';

interface ICustomInput extends InputProps {
    label: string;
    defaultValue?: string | number;
    control: any;
    required?: boolean;
    defaultPriceType?: PriceType;
    getValues: UseFormGetValues<IChildrenType>;
    trigger: UseFormTrigger<IChildrenType>;
}

function InputPriceType({
    control,
    defaultPriceType = PriceType.FIXED,
    defaultValue,
    getValues,
    trigger,
}: ICustomInput) {
    const { t } = useTranslation();
    const [currentPriceType, setCurrentPriceType] = useState(defaultPriceType);

    const selectAfter = useMemo(
        () =>
            currentPriceType === PriceType.FIXED ? (
                <>{t('childrenType.form.yen')}</>
            ) : (
                <>{t('childrenType.form.percentage')}</>
            ),
        [currentPriceType],
    );

    const options = [
        {
            label: t('childrenType.form.priceType.fixed'),
            value: PriceType.FIXED,
        },
        {
            label: t('childrenType.form.priceType.pricePerAdult'),
            value: PriceType.PRICE_PER_ADULT,
        },
    ];

    useEffect(() => {
        const priceValue = getValues('priceValue');
        if (priceValue) {
            trigger();
        }
    }, [currentPriceType]);

    const selectBefore = (
        <SingleSelect
            id={getChildrenTypeFormId('price-type')}
            defaultValue={currentPriceType}
            onChange={(priceType) => setCurrentPriceType(priceType as PriceType)}
            name="priceType"
            label=""
            control={control}
            options={options}
            hideHelpText={true}
            showSearch={false}
        />
    );

    return (
        <div className="input-price-type-wrapper">
            <InputNumber
                id={getChildrenTypeFormId('price-value')}
                name="priceValue"
                label={t('childrenType.form.priceValue.label')}
                required={true}
                control={control}
                addonBefore={selectBefore}
                addonAfter={selectAfter}
                defaultValue={defaultValue}
            />
        </div>
    );
}

export default InputPriceType;
