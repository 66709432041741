import { PlusOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent } from 'antd';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trackSearchKeyword } from '~plugins/analytics';
import { GA_KEYWORD_CONTENT } from '~common/constants';
import { InputSearch } from '~components';
import {
    FacilityBookingSearchField,
    FacilityBookingStatus,
} from '~features/facility-booking/constants';
import {
    changeBookingStatusQuery,
    facilityBookingListStateSelector,
    setFacilityBookingListQuery,
    setIsShowAdvanceSearch,
    setKeyword,
    setSearchField,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { setIsShowCreateFacilityBookingForm } from '~features/facility-booking/reducers/facility-schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './FilterForm.scss';
import SelectField from './SelectField/SelectField';

function FilterForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [keywordSearch, setKeywordSearch] = useState('');
    const { isAdvanceSearch, facilityBookingListQuery } = useAppSelector(
        facilityBookingListStateSelector,
    );
    const [status, setStatus] = useState<FacilityBookingStatus>(
        FacilityBookingStatus.ALL,
    );

    const onSearch = (keyword: string) => {
        dispatch(setKeyword({ keyword, status }));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.FACILITY_BOOKING);
    };

    const onChangeKeywordSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setKeywordSearch(e.target.value);
    };

    const onChangeStatus = (e: RadioChangeEvent) => {
        setStatus(e.target.value);
        dispatch(changeBookingStatusQuery(e.target.value));
    };

    const showAdvanceSearchForm = () => {
        setKeywordSearch('');
        if (!isAdvanceSearch) {
            dispatch(setFacilityBookingListQuery({ ...facilityBookingListQuery }));
        }
        dispatch(setSearchField(FacilityBookingSearchField.ALL));
        dispatch(setIsShowAdvanceSearch(true));
        setStatus(FacilityBookingStatus.ALL);
    };

    const onClickCreateButton = () => {
        dispatch(setIsShowCreateFacilityBookingForm(true));
        navigate('/facility-booking/schedule');
    };

    return (
        <div className="facility-booking-filter-form-wrapper">
            <div className="filter-form">
                <InputSearch
                    onSearch={onSearch}
                    onChange={onChangeKeywordSearch}
                    value={keywordSearch}
                    maxLength={30}
                    addonBefore={<SelectField />}
                    placeholder={t('common.searchPlaceholder')}
                />
                <Button onClick={showAdvanceSearchForm}>
                    <span className="btn-text">
                        {t('facilityBooking.list.buttons.detailSearch')}
                    </span>
                </Button>
                <div className="status-radio-group">
                    <label className="status-label">
                        {t('facilityBooking.list.filterForm.status.label')}
                    </label>
                    <Radio.Group
                        onChange={onChangeStatus}
                        defaultValue={FacilityBookingStatus.ALL}
                        value={status}
                    >
                        {Object.values(FacilityBookingStatus).map((value, index) => {
                            return (
                                <Radio.Button value={value} key={index}>
                                    {t(`facilityBooking.list.bookingStatus.${value}`)}
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                </div>
            </div>
            <Button type="primary" className="btn-room" onClick={onClickCreateButton}>
                <PlusOutlined />
                <span className="btn-text">
                    {t('facilityBooking.list.buttons.createBooking')}
                </span>
            </Button>
        </div>
    );
}

export default FilterForm;
