import { Col, Form, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputPassword, InputText, SingleSelect } from '~components';
import { SiteControllerName } from '~features/site-controller/constants';
import { getSiteControllerFormId } from '~features/site-controller/helpers';
import {
    getSiteController,
    siteControllerSelector,
} from '~features/site-controller/reducers/site-controller.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './SiteControllerForm.scss';

interface IProps {
    control: any;
    reset: any;
}

function SiteControllerForm({ control, reset }: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const siteController = useAppSelector(siteControllerSelector);

    useEffect(() => {
        dispatch(getSiteController());
    }, [dispatch]);

    useEffect(() => {
        reset(siteController);
    }, [siteController]);

    return (
        <div className="site-controller-form-wrapper">
            <Form layout="vertical">
                <h3 className="form-title">{t('siteController.form.description')}</h3>
                <Row gutter={122}>
                    <Col span={10}>
                        <SingleSelect
                            label={t('siteController.form.tllName.label')}
                            name={`tllName`}
                            control={control}
                            options={[
                                {
                                    label: SiteControllerName.TL_LINCOLN,
                                    value: SiteControllerName.TL_LINCOLN,
                                },
                            ]}
                        />
                    </Col>
                    <Col span={10}>
                        <InputText
                            label={t(
                                'siteController.form.tllBookingAcquisitionUrl.label',
                            )}
                            control={control}
                            name={'tllBookingAcquisitionUrl'}
                            id={getSiteControllerFormId('tllBookingAcquisitionUrl')}
                            placeholder={t(
                                'siteController.form.tllBookingAcquisitionUrl.placeholder',
                            )}
                            required={true}
                        />
                    </Col>
                </Row>
                <h2 className="form-account-setting">
                    {t('siteController.form.accountSetting')}
                </h2>

                <Row gutter={122}>
                    <Col span={10}>
                        <InputText
                            label={t('siteController.form.tllPmsUserId.label')}
                            placeholder={t(
                                'siteController.form.tllPmsUserId.placeholder',
                            )}
                            control={control}
                            name={'tllPmsUserId'}
                            id={getSiteControllerFormId('tllPmsUserId')}
                            required={true}
                        />
                    </Col>
                    <Col span={10}>
                        <InputPassword
                            label={t('siteController.form.tllPmsPassword.label')}
                            placeholder={t(
                                'siteController.form.tllPmsPassword.placeholder',
                            )}
                            control={control}
                            name={'tllPmsPassword'}
                            id={getSiteControllerFormId('tllPmsPassword')}
                            required={true}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default SiteControllerForm;
