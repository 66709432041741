/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Pagination, Table, notification } from 'antd';
import type { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { checkNotAllowedUsers, checkUserPermission } from '~common/commonFunctions';
import {
    AdminGroup,
    AntdOrderDirection,
    HotelUsers,
    OrderDirection,
    SupportLanguage,
    cellAutoGeneratedCodeStyle,
    cellTextErrorStyle,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import { planSchema } from '~common/validatorSchema';
import { ModalConfirmDeletion } from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    OrderBy,
    PlanListColumn,
    PlanType,
    initPlanListQuery,
} from '../../../../constants';
import { IPlan } from '../../../../interfaces';
import {
    fetchPlanList,
    planListQuerySelector,
    planListSelector,
    setPlanListQuery,
    setSelectedPlan,
    showLoadingSelector,
    totalPageSelector,
    totalPlanSelector,
} from '../../../../reducers/plan.reducer';
import { planService } from '../../../../services/plan.service';
import './PlanList.scss';

function PlanList() {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const planList = useAppSelector(planListSelector);
    const planListQuery = useAppSelector(planListQuerySelector);
    const pageCount = useAppSelector(totalPageSelector);
    const showLoadingList = useAppSelector(showLoadingSelector);
    const totalPlans = useAppSelector(totalPlanSelector);
    const isReadOnly = !checkUserPermission([...AdminGroup]);

    const onChange: TableProps<IPlan>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setPlanListQuery({
                    ...planListQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setPlanListQuery({
                    ...planListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setPlanListQuery({ ...initPlanListQuery }));
        };
    }, []);

    const onRefresh = async () => {
        await fetchData();
        setSelectedRowKeys([]);
    };

    const onSelectChange = (_selectedRowKeys: React.Key[]) => {
        const plans: IPlan[] = [];
        _selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundGuestClass = planList.find(
                (plan: IPlan) => plan.id === selectedRowKey,
            );
            if (foundGuestClass) plans.push(foundGuestClass);
        });
        setSelectedRowKeys(_selectedRowKeys);
    };

    const fetchData = () => {
        dispatch(fetchPlanList());
    };

    useEffect(() => {
        if (planList.length === 0 && planListQuery?.page && planListQuery?.page > 1) {
            const page = planListQuery?.page - 1;
            dispatch(setPlanListQuery({ ...planListQuery, page }));
        }
    }, [planList]);

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await planService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('plan.list.delete.deleteSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [planListQuery]);

    const onChangePage = (page: number) => {
        dispatch(setPlanListQuery({ ...planListQuery, page }));
    };

    const selectedRows = useMemo(() => {
        const rows: IPlan[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundPlan = planList.find((plan: IPlan) => plan.id === selectedRowKey);
            if (foundPlan) rows.push(foundPlan);
        });
        return rows;
    }, [selectedRowKeys, planList]);

    const isJaLanguage = useMemo(() => {
        return i18n.language === SupportLanguage.JA;
    }, [i18n.language]);

    const editRecord = (plan: IPlan) => {
        dispatch(setSelectedPlan(plan));
        if (typeof plan.id === 'number') {
            navigate(`${plan.id}/detail`);
        }
    };

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            buttonCancelText: t('plan.list.delete.buttonCancelText'),
            buttonDeleteText: t('plan.list.delete.buttonDeleteText'),
            okButtonProps: { danger: true },
            deletedItems: selectedRows.map((row) => row.name.toString()),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getPlanCellTextStyle = (plan: IPlan) => {
        if (plan.isPullFromTll) return cellTextErrorStyle;
        return planSchema.isValidSync(plan) ? {} : cellTextErrorStyle;
    };

    const planColumns: ColumnsType<IPlan> = [
        {
            title: t('plan.list.planListColumn.id'),
            width: '10%',
            onCell: () => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                };
            },
            render: (plan: IPlan) => {
                return <span>{plan.autoGeneratedCode}</span>;
            },
            key: PlanListColumn.ID,
            sorter: true,
        },
        {
            title: t('plan.list.planListColumn.planName'),
            width: '30%',
            dataIndex: '',
            onCell: (plan: IPlan) => {
                return {
                    style: getPlanCellTextStyle(plan),
                };
            },
            render: (plan: IPlan) => {
                return <div className="plan-name-item">{plan.name}</div>;
            },
            key: PlanListColumn.NAME,
            sorter: true,
        },
        {
            title: t('plan.list.planListColumn.planType'),
            width: '30%',
            dataIndex: '',
            key: PlanListColumn.PLAN_TYPE,
            onCell: (plan: IPlan) => {
                return {
                    style: getPlanCellTextStyle(plan),
                };
            },
            render: (plan: IPlan) => {
                return (
                    <div>
                        {plan.planType === PlanType.STAY
                            ? t('plan.list.planTypeOptions.stay')
                            : t('plan.list.planTypeOptions.day_use')}
                    </div>
                );
            },
            sorter: true,
        },
        {
            title: () => {
                return (
                    <span className={classNames({ 'title-time': isJaLanguage })}>
                        {t('plan.list.planListColumn.checkInTime')}
                    </span>
                );
            },
            width: '15%',
            dataIndex: '',
            key: PlanListColumn.CHECK_IN_TIME,
            onCell: (plan: IPlan) => {
                return {
                    style: getPlanCellTextStyle(plan),
                };
            },
            render: (plan: IPlan) => {
                return <div>{plan.checkInTime}</div>;
            },
            sorter: true,
        },
        {
            title: () => {
                return (
                    <span className={classNames({ 'title-time': isJaLanguage })}>
                        {t('plan.list.planListColumn.checkOutTime')}
                    </span>
                );
            },
            width: '15%',
            dataIndex: '',
            key: PlanListColumn.CHECK_OUT_TIME,
            onCell: (plan: IPlan) => {
                return {
                    style: getPlanCellTextStyle(plan),
                };
            },
            render: (plan: IPlan) => {
                return (
                    <div>
                        <div>{plan.checkOutTime}</div>
                    </div>
                );
            },
            sorter: true,
        },
    ];

    return (
        <div className="plan-list-wrapper">
            <Card
                className="plan-card"
                title={
                    <div className="plan-list-header">
                        {!isReadOnly && selectedRows.length > 0 && (
                            <div className="list-header-left">
                                <Button
                                    type="text"
                                    className="btn-icon"
                                    onClick={showConfirmDialog}
                                    disabled={checkNotAllowedUsers([...HotelUsers])}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )}

                        <div className="list-header-right">
                            <ReloadOutlined className="size-icon" onClick={onRefresh} />
                        </div>
                    </div>
                }
            >
                <Table
                    rowSelection={rowSelection}
                    columns={planColumns}
                    dataSource={planList}
                    onChange={onChange}
                    pagination={false}
                    rowKey="id"
                    loading={showLoadingList}
                    scroll={{ y: 400 }}
                    rowClassName="plan-row"
                    onRow={(record, rowIndex) => {
                        return {
                            // click row
                            onClick: (event) => {
                                editRecord(record);
                            },
                        };
                    }}
                />
            </Card>

            {pageCount > 1 && (
                <Pagination
                    align="center"
                    className="plan-list-pagination"
                    defaultCurrent={planListQuery.page}
                    current={planListQuery.page}
                    total={totalPlans}
                    pageSize={planListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default PlanList;
