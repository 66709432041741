import React from 'react';
import { Button } from 'antd';
import './NotFoundContent.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NotFoundContent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onCancel = () => {
        navigate('/front-settings/room');
    };
    return (
        <div className="not-found-wrapper">
            <p className="not-found-title">{t('error.notFound.title')}</p>
            <p>{t('error.notFound.content')}</p>
            <Button onClick={onCancel} className="not-found-btn">
                {t('error.notFound.backToHome')}
            </Button>
        </div>
    );
}

export default NotFoundContent;
