export const facilityUpdateJa = {
    title: '施設の編集',
    form: {
        id: 'ID',
        name: {
            label: '施設名',
            placeholder: '施設名',
        },
        facilityType: {
            label: '施設タイプ',
            placeholder: '施設タイプ',
        },
        basePriceTaxIncluded: {
            label: '基本料金 (円)',
            placeholder: '基本料金 (円)',
        },
        confirm: {
            buttonCancelText: 'いいえ',
            buttonDeleteText: 'はい',
            cancelTitle: 'キャンセルしますか?',
        },
    },
    updateSuccessMessage: '施設が更新されました!',
};
