import { Button, Card, Col, Form, Row, Space } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import localStorageAuthService from '~common/authStorage';
import {
    InputNumber,
    InputPhoneNumber,
    InputPostalCode,
    InputText,
    Checkbox,
    TimePickerField,
} from '~components';
import { formBusySelector, getListHotelByPeCsrHotelCodes, selectedHotelSettingSelector } from '~features/hotel/hotel.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate, parseTime } from '~plugins/dayjs';
import './HotelSettingForm.scss';

interface IProps {
    control: any;
    setValue: any;
    watch: any;
    isEditing: boolean;
    onSubmit: () => void;
    onCancel: () => void;
}

function HotelSettingForm({ control, setValue, watch, isEditing, onSubmit, onCancel }: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const hotelPms = useAppSelector(selectedHotelSettingSelector);
    const formBusy = useAppSelector(formBusySelector);

    useEffect(() => {
        const operatableHotels =
            localStorageAuthService
                .getUser()
                ?.pmsHotels?.map((hotel) => hotel.peCsrHotelCode) || [];
        dispatch(getListHotelByPeCsrHotelCodes(operatableHotels));
    }, []);

    const watchedIsRegisteredNumberNotObtained = watch('isRegisteredNumberNotObtained');

    useEffect(() => {
        if (
            watchedIsRegisteredNumberNotObtained &&
            !watch(`isRegisteredNumberNotObtained`)[0]
        ) {
            setValue(`registeredNumber`, '');
        }
    }, [setValue, watchedIsRegisteredNumberNotObtained]);

    return (
        <Card className="hotel-setting-form-wrapper">
            <Form layout="vertical" className="hotel-setting-form">
                <Row gutter={[16, 16]}>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.hotelName')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <InputText
                                label=""
                                placeholder={t('hotel.settingForm.hotelName')}
                                name="name"
                                control={control}
                            />
                        ) : (
                            <span>{hotelPms?.name}</span>
                        )}
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.companyName')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <InputText
                                label=""
                                placeholder={t('hotel.settingForm.companyName')}
                                name="companyName"
                                control={control}
                            />
                        ) : (
                            <div>{hotelPms?.companyName}</div>)
                        }
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.picEmailAddress')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <InputText
                                label=""
                                placeholder={t('hotel.settingForm.picEmailAddressInput')}
                                name="picEmailAddress"
                                control={control}
                            />
                        ) : (
                            <span>{hotelPms?.picEmailAddress}</span>)
                        }
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.postalCode')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <InputPostalCode
                                label=""
                                placeholder={t('hotel.settingForm.inputPostalCode')}
                                name="postalCode"
                                control={control}
                                maxLength={20}
                            />
                        ) : (
                            <span>{hotelPms?.postalCode}</span>)
                        }
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.createdAt')}
                    </Col>
                    <Col span={16}>
                        <span>{parseDate(hotelPms?.createdAt)?.fmYYYYMMDD('/')}</span>
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.picName')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <InputText
                                label=""
                                placeholder={t('hotel.settingForm.picNameInput')}
                                name="picName"
                                control={control}
                            />
                        ) : (
                            <span>{hotelPms?.picName}</span>)
                        }
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.picPhoneNumber')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <InputPhoneNumber
                                label=""
                                placeholder={t('hotel.settingForm.picPhoneNumberInput')}
                                name="picPhoneNumber"
                                control={control}
                            />
                        ) : (
                            <span>{hotelPms?.picPhoneNumber}</span>)
                        }
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.address')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <InputText
                                label=""
                                placeholder={t('hotel.settingForm.inputAddress')}
                                name="address"
                                control={control}
                            />
                        ) : (
                                <span>{hotelPms?.address}</span>
                        )}
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.standardCheckIn')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <TimePickerField
                                label=""
                                placeholder={t('hotel.settingForm.standardCheckIn')}
                                name="standardCheckInTime"
                                control={control}
                                defaultValue={parseTime(hotelPms?.standardCheckInTime)}
                                format="HH:mm"
                            />
                        ) : (
                            <span>{hotelPms?.standardCheckInTime}</span>
                        )}
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.standardCheckOut')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <TimePickerField
                                label=""
                                placeholder={t('hotel.settingForm.standardCheckOut')}
                                name="standardCheckOutTime"
                                control={control}
                                defaultValue={parseTime(hotelPms?.standardCheckOutTime)}
                                format="HH:mm"
                            />
                        ) : (
                            <span>{hotelPms?.standardCheckOutTime}</span>
                        )}
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.numberOfRooms')}
                    </Col>
                    <Col span={16}>
                        {isEditing ? (
                            <InputNumber
                                label=""
                                placeholder={t('hotel.settingForm.inputRoomsNumber')}
                                name="numberOfRoom"
                                control={control}
                            />
                        ) : (
                            <span>{hotelPms?.numberOfRoom}</span>)
                        }
                    </Col>
                    <Col span={8} className="hotel-detail-label">
                        {t('hotel.settingForm.registeredNumber')}
                    </Col>
                    <Col span={8}>
                        {isEditing ? (
                            <InputText
                                label=""
                                placeholder={t('hotel.settingForm.registeredNumber')}
                                name="registeredNumber"
                                control={control}
                                disabled={watch(['isRegisteredNumberNotObtained'])[0]}
                            />
                        ) : (
                            <span>{hotelPms?.registeredNumber}</span>)
                        }
                    </Col>
                    <Col span={8}>
                        {isEditing && (
                            <Checkbox
                                label={t(
                                    'hotel.settingForm.isRegisteredNumberNotObtained',
                                )}
                                name="isRegisteredNumberNotObtained"
                                control={control}
                                defaultChecked={!hotelPms?.registeredNumber}
                            />
                        )}
                    </Col>
                </Row>
                {isEditing && (
                    <Space align="end">
                        <Button
                            type="primary"
                            onClick={onSubmit}
                            loading={formBusy}
                        >
                            {t('hotel.settingForm.submitBtn')}
                        </Button>
                        <Button onClick={onCancel}>
                            {t('hotel.settingForm.cancelBtn')}
                        </Button>
                    </Space>
                )}
            </Form>
        </Card>
    );
}

export default HotelSettingForm;
