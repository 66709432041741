import { useCallback, useEffect, useMemo } from 'react';
import { getSplitReceiptByGroupMap } from '~features/room-booking/helper.split-receipt';
import { IReceipt, IReceiptByGroupMap } from '~features/room-booking/interfaces';
import { roomBookingStateSelector } from '~features/room-booking/reducers/room-booking.reducer';
import {
    setReceiptByGroupMap,
    splitReceiptStateSelector,
} from '~features/room-booking/reducers/split-receipt.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import BillToTab from '../BillToTab/BillToTab';
import ReceiptDetailTab from './ReceiptDetailTab/ReceiptDetailTab';
import './SplitBillByGroup.scss';

interface IProps {
    receipt: IReceipt | null;
}

function SplitBillByRoom({ receipt }: IProps) {
    const dispatch = useAppDispatch();
    const { selectedRoomBookingDetail } = useAppSelector(roomBookingStateSelector);

    const { numberOfGroups } = useAppSelector(splitReceiptStateSelector);

    const groupList = useMemo(() => {
        return Array.from(Array(numberOfGroups).keys()).map((number) => ({
            id: `${number + 1}`,
            name: `${number + 1}`,
        }));
    }, [numberOfGroups]);

    const generateReceiptByGroupMap = useCallback(
        (receipt: IReceipt | null) => {
            const receiptByGroupMap: IReceiptByGroupMap = getSplitReceiptByGroupMap(
                groupList,
                receipt,
                selectedRoomBookingDetail?.representativeGuest?.yomigana || '',
            );
            dispatch(setReceiptByGroupMap(receiptByGroupMap));
        },
        [dispatch, groupList, selectedRoomBookingDetail?.representativeGuest?.yomigana],
    );

    useEffect(() => {
        generateReceiptByGroupMap(receipt);
    }, [receipt, groupList, selectedRoomBookingDetail, generateReceiptByGroupMap]);

    return (
        <div className="split-room-booking-split-bill-by-group-wrapper">
            <BillToTab />
            <ReceiptDetailTab />
        </div>
    );
}

export default SplitBillByRoom;
