import { INPUT_TEXT_MAX_LENGTH, MEMO_MAX_LENGTH, Regex } from '~common/constants';
import yup from '~plugins/yup';

export const createNotificationSchema = yup.object().shape({
    title: yup
        .string()
        .trim()
        .hasNoSpecialCharacters(Regex.MEMO_SPECIAL_CHARACTERS)
        .max(INPUT_TEXT_MAX_LENGTH)
        .required(),
    content: yup
        .string()
        .trim()
        .hasNoSpecialCharacters(Regex.MEMO_SPECIAL_CHARACTERS)
        .max(MEMO_MAX_LENGTH)
        .required(),
    hotelIds: yup.array().of(yup.number().required()).required().min(1),
    tags: yup.array().of(yup.mixed()),
});
