import { Layout, Spin } from 'antd';
import { t } from 'i18next';
import { cloneDeep, differenceBy } from 'lodash';
import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { Content, PageSize, TDocumentDefinitions } from 'pdfmake/interfaces';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PrintingFonts, PrintingOrientation, PrintingPaperSize } from '~common/constants';
import { PdfPrintingModal } from '~components';
import { paymentMethodStateSelector } from '~features/payment-method/reducers/paymentMethod.reducer';
import {
    ALL_PAYMENT_METHOD_VALUE,
    PAYMENT_COLUMN_INDEX_START,
    PRINTED_PAYMENT_METHOD_SHOW_LIMIT,
} from '~features/report/constants';
import {
    IPaymentDetail,
    IPaymentDetailsReceiptItem,
    IPaymentMethod,
} from '~features/report/interfaces';
import {
    paymentDetailsQuerySelector,
    paymentDetailsSelector,
    paymentMethodListForReportSelector,
    setIsShowPaymentDetailPrintingModal,
} from '~features/report/reducers/report-payment-details.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import './PaymentDetailPrintingModal.scss';
import { formatMoney } from '~common/commonFunctions';
interface IProps {
    isShowPaymentDetailPrintingModal: boolean;
}

interface IOptions {
    orientation: PrintingOrientation;
    setTotalPage: (totalPage: number) => void;
    paperSize: PrintingPaperSize;
}

export default function PaymentDetailPrintingModal(props: IProps) {
    const [url, setUrl] = useState<string>();
    const [pdf, setPdf] = useState<TCreatedPdf>();
    const [orientation, setOrientation] = useState<PrintingOrientation>(
        PrintingOrientation.PORTRAIT,
    );

    const [paperSize, setPaperSize] = useState<PrintingPaperSize>(PrintingPaperSize.A4);
    const [totalPage, setTotalPage] = useState(0);
    const dispatch = useAppDispatch();
    const { isShowPaymentDetailPrintingModal } = props;
    const { paymentDetailList } = useAppSelector(paymentDetailsSelector);
    const { paymentMethodDropDown } = useAppSelector(paymentMethodStateSelector);
    const { selectedPaymentMethodIds } = useAppSelector(paymentDetailsSelector);
    const paymentMethodList = useAppSelector(paymentMethodListForReportSelector);
    const paymentDetailsQuery = useAppSelector(paymentDetailsQuerySelector);

    const paymentMethodListForPrint = useMemo(() => {
        const diff = differenceBy(paymentMethodList, paymentMethodDropDown, 'id');
        return [...paymentMethodDropDown, ...diff];
    }, [paymentMethodDropDown, paymentMethodList]);

    const isAllMethodsSelected = useMemo(() => {
        return selectedPaymentMethodIds.includes(ALL_PAYMENT_METHOD_VALUE);
    }, [selectedPaymentMethodIds]);

    const onClose = () => {
        dispatch(setIsShowPaymentDetailPrintingModal(false));
    };

    useEffect(() => {
        createPdf();
    }, [paymentDetailList, orientation, paperSize]);

    useEffect(() => {
        cloneDeep(pdf)?.getDataUrl((dataUrl: string) => {
            setUrl(dataUrl);
        });
    }, [pdf]);

    const onPrint = useCallback(() => {
        cloneDeep(pdf)?.print();
    }, [pdf]);

    const onChangeOrientation = (orientation: PrintingOrientation) => {
        setUrl('');
        setOrientation(orientation);
    };

    const onChangePaperSize = (paperSize: PrintingPaperSize) => {
        setUrl('');
        setPaperSize(paperSize);
    };

    const createPdf = async () => {
        setUrl('');
        pdfMake.fonts = PrintingFonts;
        setPdf(
            pdfMake.createPdf(
                generatePdf({
                    orientation,
                    setTotalPage,
                    paperSize,
                }),
            ),
        );
    };

    const selectedPaymentMethod = () => {
        let _paymentMethods: IPaymentMethod[] = [];
        if (isAllMethodsSelected) {
            _paymentMethods = cloneDeep(paymentMethodListForPrint);
        } else {
            _paymentMethods = paymentMethodListForPrint.filter((method) =>
                selectedPaymentMethodIds.includes(method.id),
            );
        }
        const uniquePaymentMethods = _paymentMethods.filter((obj, index) => {
            return index === _paymentMethods.findIndex((o) => obj.id === o.id);
        });
        return uniquePaymentMethods.sort((pre, next) => {
            return pre.id - next.id;
        });
    };

    const sanitizedPaymentDetailList = useMemo(() => {
        const result: Record<string, any>[] = [];
        paymentDetailList?.forEach((paymentDetail: IPaymentDetail) => {
            paymentDetail?.receiptItems.forEach((item: IPaymentDetailsReceiptItem) => {
                const row: Record<string, any> = {};
                row.id = item?.id;
                row.checkInDate = parseDate(item?.payAt)?.fmYYYYMMDD('/');

                row.registrationTime = parseDate(item?.createdAt).fmYYYYMMDDHHmmss();

                row.roomBookingId = paymentDetail?.roomBookingId || 0;
                row.roomName = paymentDetail?.room?.[0]?.name || '';
                row.representativeName = paymentDetail?.representativeGuest?.yomigana;
                row.unpaidAmount = Number(paymentDetail?.unpaidAmount);
                row.paymentMethods = [] as IPaymentMethod[];
                row.paymentMethods.push({
                    id: item?.paymentMethodId,
                    amount: item?.amount,
                });

                paymentMethodListForPrint.forEach((item) => {
                    if (item.id !== row.paymentMethods?.[0]?.id) {
                        row.paymentMethods.push({ id: item.id, amount: 0 });
                    }
                });
                row.paidAmount = row.paymentMethods.reduce(
                    (paidAmount: number, paymentMethod: IPaymentMethod) =>
                        (paidAmount += paymentMethod.amount || 0),
                    0,
                );

                result.push(row);
            });
        });
        result.forEach((item) => {
            item.paymentMethods.sort((pre: IPaymentMethod, next: IPaymentMethod) => {
                return pre.id - next.id;
            });
        });
        return result;
    }, [paymentDetailList]);

    const tableHeader = () => {
        const headerIncludeAllPayment = [
            t('report.paymentDetails.column.checkInDate'),
            t('report.paymentDetails.column.registrationTime'),
            t('report.paymentDetails.column.roomName'),
            t('report.paymentDetails.column.representativeName'),
            t('report.paymentDetails.column.unpaidAmount'),
            ...selectedPaymentMethod().map((payment) => {
                return payment.name;
            }),
        ];

        const result = [];
        let offset = PAYMENT_COLUMN_INDEX_START;
        for (let i = 0; i < headerIncludeAllPayment.length; i++) {
            let row = headerIncludeAllPayment.slice(0, PAYMENT_COLUMN_INDEX_START);
            for (let j = offset; j < offset + PRINTED_PAYMENT_METHOD_SHOW_LIMIT; j++) {
                if (headerIncludeAllPayment[j]) row.push(headerIncludeAllPayment[j]);
            }
            if (row.length > PAYMENT_COLUMN_INDEX_START) result.push(row);
            offset += PRINTED_PAYMENT_METHOD_SHOW_LIMIT;
        }
        return result;
    };

    const availablePaymentMethodColumn = () => {
        const result = [];
        for (let i = 0; i < tableHeader.length - PAYMENT_COLUMN_INDEX_START; i++) {
            result.push('');
        }
        return result;
    };

    const paidAmountTotal = (): string => {
        let total = 0;
        sanitizedPaymentDetailList.forEach((paymentDetail) => {
            total += paymentDetail.paidAmount;
        });
        return `${t('report.paymentDetails.yen', {
            value: formatMoney(total),
        })}`;
    };

    const paymentMethodByTotalRow = () => {
        const paymentMethodTotalById = {} as Record<string, any>;

        sanitizedPaymentDetailList.forEach((paymentDetail) => {
            paymentDetail.paymentMethods.forEach((paymentMethod: IPaymentMethod) => {
                const isSelectedPaymentMethod = selectedPaymentMethodIds.find((id) => {
                    return id === paymentMethod.id;
                });
                if (isSelectedPaymentMethod || isAllMethodsSelected) {
                    paymentMethodTotalById['id_' + paymentMethod.id] =
                        (paymentMethodTotalById['id_' + paymentMethod.id] || 0) +
                        paymentMethod.amount;
                }
            });
        });

        const result = [];

        const arrPaymentMethodByTotalRow = Object.values(paymentMethodTotalById).map(
            (value) =>
                t('report.paymentDetails.yen', {
                    value: formatMoney(value),
                }),
        );

        let offset = 0;
        for (let i = 0; i < arrPaymentMethodByTotalRow.length; i++) {
            let row = arrPaymentMethodByTotalRow.slice(
                offset,
                offset + PRINTED_PAYMENT_METHOD_SHOW_LIMIT,
            );
            if (row.length > 0) result.push(row);
            offset += PRINTED_PAYMENT_METHOD_SHOW_LIMIT;
        }

        return result;
    };

    const paymentMethodCols = (paymentMethods: IPaymentMethod[]) => {
        const result: string[] = [];

        paymentMethods.forEach((payment) => {
            const isSelectedPaymentMethod = selectedPaymentMethodIds.find((id) => {
                return id === payment.id;
            });
            if (isSelectedPaymentMethod || isAllMethodsSelected)
                result.push(
                    `${t('report.paymentDetails.yen', {
                        value: formatMoney(payment?.amount || 0),
                    })}`,
                );
        });
        return result;
    };

    const tableBody = () => {
        let body: any[] = [];
        sanitizedPaymentDetailList.forEach((paymentDetail) => {
            let row = [
                paymentDetail.checkInDate,
                paymentDetail.registrationTime,
                paymentDetail.roomName,
                paymentDetail.representativeName,
                `${t('report.paymentDetails.yen', {
                    value: formatMoney(paymentDetail.paidAmount),
                })}`,
                ...paymentMethodCols(paymentDetail.paymentMethods),
            ];
            body.push(row);
        });

        const result = [];
        const temp = [...body];
        let paymentLength = 1;
        let offset = PAYMENT_COLUMN_INDEX_START;

        while (paymentLength <= selectedPaymentMethod().length) {
            const table = [];
            for (let i = 0; i < body.length; i++) {
                let row = temp[i].slice(0, PAYMENT_COLUMN_INDEX_START);
                for (
                    let j = offset;
                    j < offset + PRINTED_PAYMENT_METHOD_SHOW_LIMIT;
                    j++
                ) {
                    if (temp[i][j]) row.push(temp[i][j]);
                }
                if (row.length > PAYMENT_COLUMN_INDEX_START) table.push(row);
            }
            if (table.length > 0) result.push(table);
            offset += PRINTED_PAYMENT_METHOD_SHOW_LIMIT;

            paymentLength++;
        }
        return result;
    };

    const generatePdf = (options: IOptions): TDocumentDefinitions => {
        const {
            orientation = PrintingOrientation.PORTRAIT,
            setTotalPage,
            paperSize = PrintingPaperSize.A4,
        } = options;

        const isPortrait = orientation === PrintingOrientation.PORTRAIT;

        let content: Content = [
            sanitizedPaymentDetailList.length === 0
                ? []
                : tableHeader().map((header, index) => {
                      const paymentItemWidth = () => {
                          const res = [];
                          for (
                              let i = 0;
                              i < header.length - PAYMENT_COLUMN_INDEX_START;
                              i++
                          ) {
                              res.push('*');
                          }
                          return res;
                      };
                      return [
                          {
                              style: 'paymentDetailTable',
                              table: {
                                  widths: [50, 50, 50, 50, 50, ...paymentItemWidth()],
                                  body: [
                                      header,
                                      ...tableBody()[index],
                                      [
                                          {
                                              colSpan: 4,
                                              alignment: 'right',
                                              text: `${t('report.paymentDetails.total')}`,
                                          },
                                          '',
                                          '',
                                          availablePaymentMethodColumn(),
                                          paidAmountTotal(),
                                          ...paymentMethodByTotalRow()[index],
                                      ],
                                  ],
                              },
                          },
                      ];
                  }),
        ];

        return {
            pageSize: paperSize.toUpperCase() as PageSize,
            pageOrientation: orientation,
            header: (currentPage, pageCount) => ({
                columns: [
                    {
                        columns: [
                            {
                                text: `${t('report.paymentDetails.title')}`,
                                bold: true,
                            },
                            {
                                text: `${t('report.paymentDetails.print.datePeriod', {
                                    start: parseDate(
                                        paymentDetailsQuery.datePeriod?.[0],
                                    ).fmYYYYMMDD('/'),
                                    end: parseDate(
                                        paymentDetailsQuery.datePeriod?.[1],
                                    ).fmYYYYMMDD('/'),
                                })}`,
                                alignment: 'right',
                            },
                        ],
                    },
                ],
                style: 'pageHeader',
                margin: [40, 20, 40, 40],
                width: '*',
            }),
            footer: (currentPage, pageCount) => {
                setTotalPage(pageCount);
                return {
                    text: `${currentPage}/${pageCount}`,
                    style: 'pageFooter',
                };
            },
            content,
            styles: {
                pageHeader: {
                    fontSize: 12,
                    margin: [0, 0, 0, 0],
                },
                pageSubHeader: {
                    fontSize: 12,
                },
                header: {
                    fontSize: isPortrait ? 18 : 14,
                    bold: true,
                },
                paymentDetailTable: {
                    fontSize: 8,
                    margin: [0, 0, 0, 16],
                },
                pageFooter: {
                    fontSize: 8,
                    alignment: 'right',
                    margin: [0, 8, 40, 0],
                },
            },
        };
    };

    return (
        <PdfPrintingModal
            totalPage={totalPage}
            onPrint={onPrint}
            onClose={onClose}
            onChangeOrientation={onChangeOrientation}
            onChangePaperSize={onChangePaperSize}
            isShowPdfPrinting={isShowPaymentDetailPrintingModal}
        >
            <Layout className="payment-detail-printing-layout">
                <Layout.Content className="payment-detail-printing-content">
                    {url ? (
                        <iframe className="pdf-view" src={`${url}#toolbar=0`} />
                    ) : (
                        <Spin />
                    )}
                </Layout.Content>
            </Layout>
        </PdfPrintingModal>
    );
}
