import { Col, Row } from 'antd';
import { IFacilitiesBulkCreateItem } from '~features/facility/interfaces';
import CreateFacilitiesFormFacilityList from '../CreateFacilitiesFormFacilityList/CreateFacilitiesFormFacilityList';
import CreateFacilitiesFormSetting from '../CreateFacilitiesFormSetting/CreateFacilitiesFormSetting';
import './CreateFacilitiesForm.scss';

interface IProp {
    control: any;
    currentFacilitiesList: IFacilitiesBulkCreateItem[];
}

function CreateFacilitiesForm(props: IProp) {
    return (
        <div className="facility-form-wrapper">
            <Row gutter={24}>
                <Col span={11}>
                    <CreateFacilitiesFormSetting />
                </Col>
                <Col span={13}>
                    <CreateFacilitiesFormFacilityList
                        currentFacilityList={props.currentFacilitiesList}
                        control={props.control}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default CreateFacilitiesForm;
