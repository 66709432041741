import { Button, notification, Space, Spin } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import SiteControllerForm from '~features/site-controller/components/SiteControllerForm/SiteControllerForm';
import SiteControllerToggleBox from '~features/site-controller/components/SiteControllerToggleBox/SiteControllerToggleBox';
import { breadcrumbs } from '~features/site-controller/constants';
import './SiteControllerPage.scss';
import { siteControllerSchema } from '~features/site-controller/schema';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showConfirm } from '~common/notification';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    formBusySelector,
    isTllConnectedSelector,
    setSiteController,
    siteControllerSelector,
    updateSiteController,
    isLoadingSelector,
    setIsTllConnected,
} from '~features/site-controller/reducers/site-controller.reducer';
import { ISiteController } from '~features/site-controller/interfaces';
import { AdminGroup, ErrorMessageType, UserGroup } from '~common/constants';
import { checkUserPermission } from '~common/commonFunctions';

function SiteControllerPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([...AdminGroup, UserGroup.HOTEL_OWNER]);
    const {
        control,
        handleSubmit,
        reset,
        setError,
        formState: { isDirty },
    } = useForm({
        resolver: yupResolver(siteControllerSchema),
    });

    const siteController = useAppSelector(siteControllerSelector);
    const formBusy = useAppSelector(formBusySelector);
    const isTllConnected = useAppSelector(isTllConnectedSelector);
    const loading = useAppSelector(isLoadingSelector);

    const onSubmit = (isChangeToggleBox?: boolean, isConnected?: boolean) => {
        handleSubmit(async (values) => {
            const { tllBookingAcquisitionUrl, tllName, tllPmsPassword, tllPmsUserId } =
                values;
            const updatedSiteController: ISiteController = {
                isTllConnected,
                tllBookingAcquisitionUrl,
                tllName,
                tllPmsPassword,
                tllPmsUserId,
            };
            if (isChangeToggleBox) {
                dispatch(setIsTllConnected(isConnected));
                Object.assign(updatedSiteController, {
                    isTllConnected: isConnected,
                });
            }
            const response = await dispatch(updateSiteController(updatedSiteController));
            if (updateSiteController.fulfilled.match(response)) {
                if (response.payload?.success) {
                    dispatch(setSiteController(updatedSiteController));
                    notification.success({
                        message: t('siteController.notification.success'),
                    });
                    return;
                }

                notification.error({
                    message: t('siteController.notification.error'),
                    description: response.payload?.message || '',
                });

                (response.payload?.errors || []).forEach((error) => {
                    if (
                        error.key === 'tllPmsUserId' ||
                        error.key === 'tllPmsPassword' ||
                        error.key === 'tllName' ||
                        error.key === 'tllBookingAcquisitionUrl' ||
                        error.key === 'isTllConnected'
                    ) {
                        setError(
                            error.key,
                            {
                                type: ErrorMessageType.MANUAL,
                                message: error?.message,
                            },
                            { shouldFocus: true },
                        );
                    }
                });
            }
        })();
    };

    const onCancel = () => {
        showConfirm({
            title: t('siteController.confirmCancel'),
            cancelText: t('common.buttonCancelText'),
            okText: t('common.buttonCloseText'),
            onOk() {
                reset(siteController);
            },
        });
    };

    const onChangeToggleBox = (isConnected: boolean) => {
        onSubmit(true, isConnected);
    };

    return (
        <div className="site-controller-page">
            <StaffLayoutHeader
                title={t('siteController.title')}
                breadcrumbs={breadcrumbs()}
            />
            <Content>
                <div className="site-controller-content">
                    <Spin spinning={loading}>
                        <SiteControllerForm reset={reset} control={control} />
                        <SiteControllerToggleBox
                            handleSubmit={onChangeToggleBox}
                            isReadOnly={isReadOnly}
                        />
                    </Spin>
                </div>
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space size="middle">
                        <Button onClick={onCancel}>
                            {t('siteController.footer.cancelBtn')}
                        </Button>
                        {!isReadOnly && (
                            <Button
                                type="primary"
                                className="btn-primary"
                                onClick={() => onSubmit()}
                                loading={formBusy}
                                disabled={!isDirty || isReadOnly}
                            >
                                {t('siteController.footer.submitBtn')}
                            </Button>
                        )}
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default SiteControllerPage;
