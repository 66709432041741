const rankCalendarUpdate = {
    title: 'ランクカレンダー詳細',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        rankCalendar: 'ランクカレンダー設定',
        rankCalendarDetail: 'ランクカレンダー詳細',
    },
    actionForm: {
        label: 'カレンダー名',
        cancelBtn: 'キャンセル',
        updateBtn: '保存',
    },
    advancedSettingBtn: '詳細設定',
    bulkUpdateBtn: '一括で変更',
    note: '1つのチェックボックスをクリックしShiftキーを押しながら水平方向にある2番目のチェックボックスをクリックすると、2つのチェックボックスの間にあるチェックボックスを選択/選択解除されます。',
    modalUpdate: {
        title: '選択済みを変更',
        label: 'ランク',
        placeholder: 'ランクを選択',
        cancelBtn: 'キャンセル',
        submitBtn: '適用',
    },
};

export default rankCalendarUpdate;
