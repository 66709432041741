const saleGroupForm = {
    title: 'Create sales group',
    editTitle: 'Edit sales group',
    importTitle: 'Upload CSV',
    name: 'Sales group',
    namePlaceholder: 'Sales group name',
    salesItemLabel: 'Sales item',
    salesItemPlaceHolder: 'Select sales item',
    addition: 'Add sales item',
    submitBtn: 'Create',
    message: {
        createSuccess: 'Sale Groups have been created successfull',
        createError: 'Sale Groups have been created unsuccessfull',
        updateSuccess: 'Sale Groups have been updated successfull',
        updateError: 'Sale Groups have been updated unsuccessfull',
    },
    nameDuplicated: 'Name is duplicated',
    editButton: 'Save',
    cancelButton: 'Cancel',
    confirm: {
        cancelTitle: 'Do you want to cancel?',
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
    },
};

export default saleGroupForm;
