import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomBookingEvent } from '~features/room-booking/constants';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import { useMitt } from '~plugins/mitt';
import { BookingTemporaryCard } from '../BookingTemporaryCard/BookingTemporaryCard';
import './ConfirmCloseBookingModal.scss';

export function ListBookingModal() {
    const [bookings, setBookings] = useState<IRoomBookingSchedule[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const { emitter } = useMitt();

    useEffect(() => {
        emitter.on(RoomBookingEvent.SHOW_MORE_BOOKINGS, (data) => {
            setBookings(data);
            setIsOpen(true);
        });

        return () => {
            emitter.off(RoomBookingEvent.SHOW_MORE_BOOKINGS);
        };
    }, []);

    const handleOk = () => {
        setIsOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            title={t('roomBooking.schedule.bookingMoreList')}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={t('common.ok')}
            className={'confirm-close-booking-modal'}
            footer={null}
        >
            {bookings.map((booking) => (
                <div key={booking.id} className="mb-10">
                    <BookingTemporaryCard showAction={true} booking={booking} />
                </div>
            ))}
        </Modal>
    );
}
