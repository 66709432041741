import { Button } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomBookingEvent } from '~features/room-booking/constants';
import {
    getBookingUnassignedList,
    roomManagementSelector,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useMitt } from '~plugins/mitt';
import { BookingUnassignedList } from '../BookingUnassignedList/BookingUnassignedList';
import './BookingRightList.scss';
import { StatusCollapse } from './StatusCollapse';
import _ from 'lodash';

export const BookingRightList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { emitter } = useMitt();
    const { unassignedList, currentUnassignPage } =
        useAppSelector(roomManagementSelector);

    const handleAutoAssign = () => {
        emitter.emit(RoomBookingEvent.TOGGLE_AUTO_ASSIGN_MODAL, true);
    };

    const _unassignedListManagementScreen = useMemo(() => {
        return unassignedList?.filter((item) => !item.isDayUse);
    }, [unassignedList]);

    useEffect(() => {
        if (_unassignedListManagementScreen?.length === 0 && currentUnassignPage > 1) {
            dispatch(getBookingUnassignedList());
        }
    }, [_unassignedListManagementScreen]);

    return (
        <div className="booking-right-list-wrapper">
            <StatusCollapse />
            {_unassignedListManagementScreen.length > 0 && (
                <>
                    <BookingUnassignedList isManagementScreen={true} />
                    <Button
                        className="btn-auto-assign mt-20"
                        onClick={handleAutoAssign}
                        type="primary"
                    >
                        {t('roomManagement.list.autoAssignment')}
                    </Button>
                </>
            )}
        </div>
    );
};
