import { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import localStorageAuthService from '~common/authStorage';
import { trimData } from '~common/commonFunctions';
import { IBodyResponse, ICommonListQuery, IGetListResponse } from '~common/interfaces';
import { getToken, logoutSession } from '~plugins/amplify';

interface IServiceOption {
    baseUrl: string;
}

export class ApiService {
    client: AxiosInstance;
    baseUrl: string;

    constructor(params: IServiceOption, axios: AxiosInstance) {
        this.client = axios;
        this.baseUrl = params.baseUrl;
    }

    get detailUrl(): string {
        return this.baseUrl;
    }

    get createUrl(): string {
        return this.baseUrl;
    }

    get updateUrl(): string {
        return this.baseUrl;
    }

    get deleteUrl(): string {
        return this.baseUrl;
    }

    useClient(axios: AxiosInstance): void {
        this.client = axios;
    }

    beforeCreate<P>(params: P): P {
        trimData(params);
        return params;
    }

    beforeUpdate<P>(params: P): P {
        trimData(params);
        return params;
    }

    async isValidToken() {
        const { isValidAccessToken, idToken } = await getToken();
        const isValid = isValidAccessToken && idToken;
        if (!isValid) {
            localStorageAuthService.resetAll();
            logoutSession();
            window.location.href = '/login';
        }
        return isValid;
    }

    stringify<T>(queryString: T) {
        return qs.stringify(queryString);
    }

    async _getList<T>(
        queryString: ICommonListQuery,
    ): Promise<IBodyResponse<IGetListResponse<T>>> {
        const isValid = await this.isValidToken();
        if (!isValid) return {} as any;
        return this.client.get(`${this.baseUrl}`, {
            params: queryString,
        });
    }

    async _getDetail<R>(id: number | string): Promise<R> {
        const isValid = await this.isValidToken();
        if (!isValid) return {} as any;
        return this.client.get<R, R>(this.detailUrl + '/' + id);
    }

    async _create<P, R>(params: P): Promise<R> {
        const isValid = await this.isValidToken();
        if (!isValid) return {} as any;
        params = this.beforeCreate<P>(params);
        return this.client.post(this.createUrl, params);
    }

    async _update<P, R>(id: number | string, params: P): Promise<R> {
        const isValid = await this.isValidToken();
        if (!isValid) return {} as any;
        params = this.beforeUpdate<P>(params);
        return this.client.patch(this.updateUrl + '/' + id, params);
    }

    async _deleteItem<R>(id: number | string): Promise<R> {
        const isValid = await this.isValidToken();
        if (!isValid) return {} as any;
        return this.client.delete<R, R>(this.deleteUrl + '/' + id);
    }

    async _get<P, R>(url: string, config?: AxiosRequestConfig<P>): Promise<R> {
        const isValid = await this.isValidToken();
        if (!isValid) return {} as any;
        return this.client.get(url, config);
    }

    async _patch<P, R>(
        url: string,
        data?: P,
        config?: AxiosRequestConfig<P>,
    ): Promise<R> {
        const isValid = await this.isValidToken();
        if (!isValid) return {} as any;
        return this.client.patch(url, data, config);
    }

    async _post<P, R>(url: string, data?: P, config?: AxiosRequestConfig<P>): Promise<R> {
        const isValid = await this.isValidToken();
        if (!isValid) return {} as any;
        return this.client.post(url, data, config);
    }

    async _delete<P, R>(url: string, config?: AxiosRequestConfig<P>): Promise<R> {
        const isValid = await this.isValidToken();
        if (!isValid) return {} as any;
        return this.client.delete(url, config);
    }
}
