import { IGuest } from '~features/room-booking/interfaces';
import { Gender } from '~features/guest/constants';
import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import { RoomBookingItemBookingStatus } from './constants';
import {
    IBookingGuestInfo,
    IGuestInfo,
    IRoomBookingSchedule,
    IUpdateRoomBookingForm,
} from './interfaces';

export const guestDefault: IGuestInfo = {
    id: null,
    fullName: null,
    yomigana: null,
    phone: null,
    emailAddress: null,
    birthday: null,
    gender: null,
};

export const guestFormDefault: IGuest = {
    id: null,
    fullName: null,
    yomigana: null,
    mobilePhoneNumber: null,
    emailAddress: null,
    birthday: null,
};

export const representativeGuestFormDefault = {
    representativeGuestId: null,
    representativeGuestFullName: null,
    representativeGuestYomigana: null,
    representativeGuestMobilePhoneNumber: null,
    representativeGuestEmailAddress: null,
    representativeGuestBirthday: null,
    representativeGuestGender: null,
};

export const reserverGuestFormDefault = {
    reserverGuestId: null,
    reserverGuestFullName: null,
    reserverGuestYomigana: null,
    reserverGuestMobilePhoneNumber: null,
    reserverGuestEmailAddress: null,
};

export const bookingInfoDefault: IBookingGuestInfo = {
    representativeGuest: { ...guestDefault },
    reserverGuest: { ...guestDefault },
    attachments: [],
    marketingChannelId: null,
    isReserverTheRepresentative: false,
    memo: null,
    otaMemo: null,
    birthday: null,
    gender: null,
    checkInTime: null,
    checkOutTime: null,
};

export const getBookingScheduleDefault = (): IRoomBookingSchedule => {
    const now = todayDayjs
        .set('hour', todayDayjs.get('hours') + Math.ceil(todayDayjs.get('minutes') / 60))
        .set('minute', 0);
    return {
        id: 0,
        stayingStartDate: todayDayjs.fmYYYYMMDD('-'),
        stayingEndDate: todayDayjs.add(1, 'day')?.fmYYYYMMDD('-'),
        status: RoomBookingItemBookingStatus.NOT_ARRIVED,
        room: {
            id: null,
            name: '',
        },
        roomType: {
            id: null,
            name: '',
        },
        plan: {
            id: null,
            name: '',
        },
        guest: { ...guestDefault },
        checkInTime: now.fmHHmm(),
        checkOutTime: now.clone().add(3, 'hours')?.fmHHmm(),
        numberOfAdults: 0,
        isTmp: true,
        isDayUse: false,
        children: [],
        roomKey: '',
    };
};

export const roombookingFormDefault: IUpdateRoomBookingForm = {
    representativeGuest: {
        id: 0,
        fullName: '',
        yomigana: '',
        phone: '',
        emailAddress: '',
        birthday: null,
        gender: Gender.OTHER,
    },
    reserverGuest: {
        id: 0,
        fullName: '',
        yomigana: '',
        phone: '',
        emailAddress: '',
        birthday: null,
        gender: Gender.OTHER,
    },
    members: [],
    marketingChannelId: 0,
    isReserverTheRepresentative: false,
    memo: '',
    otaMemo: '',
    birthday: null,
    gender: Gender.OTHER,
};
