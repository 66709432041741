const hotelList = {
    title: 'ホテル管理',
    breadcrumbs: {
        home: 'BO設定',
        hotel: 'ホテル管理',
    },
    primaryButton: '宿泊を追加する',
    searchColumn: {
        keyword: '全て',
        name: 'ホテル名',
        peCsrHotelCode: 'ホテルID',
        picName: '担当者名',
        postalCode: '郵便番号',
        address: '住所',
        picPhoneNumber: '連絡先電話番号',
        picEmailAddress: '連絡先メール',
    },
    hotelListColumn: {
        hotelName: 'ホテル名',
        peCsrHotelCode: 'ホテルID',
        tllPmsUserId: '連動用ID',
        picName: '担当者名',
        postalCode: '郵便番号',
        address: '住所',
        picPhoneNumber: '連絡先電話番号',
        picEmailAddress: '連絡先メール',
        numberOfRoom: '部屋数',
        standardCheckInTime: '標準チェックイン',
        standardCheckOutTime: '標準チェックアウト',
        status: 'ステータス',
        permission: 'ホテルカテゴリー',
        tagStatus: {
            active: '有効',
            inactive: '無効',
        },
        tagPermission: {
            pms: 'Tabist ホテル',
        },
    },
    delete: {
        buttonCancelText: 'キャンセル',
        buttonDeleteText: '削除',
        deleteHotelsSuccess: 'ホテルが削除されました',
    },
    exportColumns: {
        peCsrHotelCode: 'ホテル ID',
        name: 'ホテル名',
        tllPmsUserId: '連動用ID',
        picName: '担当者名',
        postalCode: '郵便番号',
        address: '住所',
        picPhoneNumber: '連絡先電話番号',
        picEmailAddress: '連絡先メール',
        numberOfRoom: '部屋数',
        standardCheckInTime: '標準チェックイン',
        standardCheckOutTime: '標準チェックアウト',
        status: 'ステータス',
        permission: 'ホテルカテゴリー',
    },
};
export default hotelList;
