import { Layout, Spin, Tabs, Button, Space } from 'antd';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import { planDetailBreadrumbs, PlanDetailPageTabPane } from '../../constants';
import './PlanDetailPage.scss';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    showLoadingSelector,
    fetchPlanDetail,
} from '~features/plan/reducers/plan.reducer';
import Tab1 from './components/Tab1/Tab1';
import Tab2 from './components/Tab2/Tab2';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup } from '~common/constants';

const { Content, Footer } = Layout;

function PlanDetailPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { planId } = useParams();
    const isLoading = useAppSelector(showLoadingSelector);
    const isReadOnly = !checkUserPermission([...AdminGroup]);
    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(fetchPlanDetail(id));
        if (fetchPlanDetail.fulfilled.match(response)) {
            if (!response.payload?.data) {
                navigate('/404');
            }
        }
    }, []);

    const onCancel = () => {
        navigate('/front-settings/plan');
    };
    //This re-renders when the route changes
    useEffect(() => {
        if (typeof Number(planId) === 'number') {
            _getDetail(Number(planId));
        } else {
            navigate('/404');
        }
    }, []);

    const onClickEditButton = () => {
        navigate(`/front-settings/plan/${planId}/update`);
    };

    return (
        <div className="plan-detail-page">
            <Spin spinning={isLoading}>
                <StaffLayoutHeader
                    breadcrumbs={planDetailBreadrumbs()}
                    title={t('plan.detail.title')}
                    rightComponent={
                        !isReadOnly && (
                            <Button
                                className="btn-edit"
                                type="primary"
                                onClick={onClickEditButton}
                            >
                                {t('plan.detail.editButton')}
                            </Button>
                        )
                    }
                />

                <Content className="plan-detail-content">
                    <Tabs
                        className="plan-detail-tabs"
                        items={[
                            {
                                key: PlanDetailPageTabPane.PLAN_INFORMATION_TAB,
                                label: t('plan.detail.tab1.title'),
                                children: <Tab1 />,
                            },
                            {
                                key: PlanDetailPageTabPane.PLAN_PRICING_TAB,
                                label: t('plan.detail.tab2.title'),
                                children: <Tab2 />,
                            },
                        ]}
                    ></Tabs>
                </Content>
                <Footer>
                    <div className="footer-btn-group">
                        <Space align="end">
                            <>
                                <Button onClick={onCancel}>
                                    {t('plan.create.buttonBack')}
                                </Button>
                            </>
                        </Space>
                    </div>
                </Footer>
            </Spin>
        </div>
    );
}

export default PlanDetailPage;
