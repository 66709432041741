import { Dropdown, Modal, Select, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { PrintingOrientation, PrintingPaperSize } from '~common/constants';
import { showConfirm } from '~common/notification';
import './PdfPrintingModal.scss';

interface IProps {
    isShowPdfPrinting: boolean;
    totalPage: number;
    onChangeOrientation: (value: PrintingOrientation) => void;
    onChangePaperSize?: (value: PrintingPaperSize) => void;
    onClose: React.MouseEventHandler<HTMLElement>;
    onPrint: React.MouseEventHandler<HTMLElement>;
    children: React.ReactElement;
    defaultOrientation?: PrintingOrientation;
    defaultPaperSize?: PrintingPaperSize;
    isShowControlBar?: boolean;
    okButtonLoading?: boolean;
}

export function PdfPrintingModal(props: IProps) {
    const { t } = useTranslation();
    const {
        isShowPdfPrinting,
        totalPage,
        children,
        onPrint,
        onClose,
        onChangeOrientation,
        onChangePaperSize,
        defaultOrientation,
        defaultPaperSize,
        isShowControlBar = true,
        okButtonLoading = false,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const openMoreSetting = (isOpen: boolean) => {
        setIsOpen(isOpen);
    };
    return (
        <Modal
            className="pdf-printing-modal"
            cancelText={`${t('common.buttonCancelText')}`}
            okText={`${t('common.print.print')}`}
            open={isShowPdfPrinting}
            onCancel={onClose}
            onOk={onPrint}
            okButtonProps={{ loading: okButtonLoading }}
        >
            <div className="pdf-printing-wrapper">
                <div className="printing-content">{children}</div>
                {isShowControlBar && (
                    <div className="printing-control">
                        <div className="top-section">
                            <div className="header">
                                <span className="text">{t('common.print.print')}</span>
                                <span className="text">{`${totalPage} ${t(
                                    'common.print.page',
                                )}`}</span>
                            </div>
                            <div className="control-group">
                                <div className="control-field">
                                    <span className="text">{t('common.print.type')}</span>
                                    <Select
                                        defaultValue={'pdf'}
                                        className="select-field"
                                        options={[
                                            {
                                                value: 'pdf',
                                                label: t('common.print.pdf'),
                                            },
                                        ]}
                                    ></Select>
                                </div>
                                <div className="control-field">
                                    <span className="text">{t('common.print.page')}</span>
                                    <Select
                                        defaultValue={'all'}
                                        className="select-field"
                                        options={[
                                            {
                                                value: 'all',
                                                label: t('common.print.all'),
                                            },
                                        ]}
                                    ></Select>
                                </div>
                                <div className="control-field">
                                    <span className="text">
                                        {t('common.print.orientation')}
                                    </span>
                                    <Select
                                        defaultValue={
                                            defaultOrientation ||
                                            PrintingOrientation.PORTRAIT
                                        }
                                        className="select-field"
                                        options={[
                                            {
                                                value: PrintingOrientation.PORTRAIT,
                                                label: t('common.print.portrait'),
                                            },
                                            {
                                                value: PrintingOrientation.LANDSCAPE,
                                                label: t('common.print.landscape'),
                                            },
                                        ]}
                                        onChange={onChangeOrientation}
                                    ></Select>
                                </div>
                                <div className="more-setting-print-wrapper">
                                    <Dropdown
                                        trigger={['click']}
                                        placement="bottom"
                                        open={isOpen}
                                        onOpenChange={openMoreSetting}
                                        overlayClassName="more-setting-print-dropdown"
                                        dropdownRender={(menu) => {
                                            return (
                                                <div className="more-setting-print-content">
                                                    <div className="more-setting-option">
                                                        {t('common.print.paperSize')}
                                                        <Select
                                                            defaultValue={
                                                                defaultPaperSize ||
                                                                PrintingPaperSize.A4
                                                            }
                                                            className="select-field"
                                                            options={Object.values(
                                                                PrintingPaperSize,
                                                            ).map((item) => {
                                                                return {
                                                                    value: item,
                                                                    label: item,
                                                                };
                                                            })}
                                                            onChange={onChangePaperSize}
                                                        ></Select>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    >
                                        <Space className="more-setting-title">
                                            {t('common.print.moreSetting')}
                                            <DownOutlined />
                                        </Space>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}
