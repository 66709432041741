import { Table, TableColumnsType } from 'antd';
import { ISalesByOtaDetail, ISalesByOtaResponse } from '../interfaces';
import { COLUMN_KEYS, getDailyData, getDailySummaryData } from './helper';
import { ReportTableCsvFileName, ReportTableId } from '~features/report/constants';
import { FormatMoney, FormatNumber } from '~common/helper';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    salesByOtaSelector,
    setExportCsv,
} from '~features/report/reducers/sales-by-ota.reducer';
import { useEffect, useState } from 'react';
import { exportCSVReport } from '~features/report/helper';
import { parseDate } from '~plugins/dayjs';
import { DateFormat } from '~common/constants';

type Props = {
    data: ISalesByOtaResponse;
    loading: boolean;
};
const { Cell: SummaryCell, Row: SummaryRow } = Table.Summary;

const renderCellContent = (
    current: number,
    previous: number,
    isMoneyFormat?: boolean,
) => {
    const formattedCurrent = isMoneyFormat
        ? FormatMoney.format(current)
        : FormatNumber.format(current);
    const formattedPrevious = isMoneyFormat
        ? FormatMoney.format(previous)
        : FormatNumber.format(previous);
    return (
        <>
            {formattedCurrent} <span className="gray-color">({formattedPrevious})</span>
        </>
    );
};

function DailySalesByOtaTable({ data, loading }: Props) {
    const [tableData, setTableData] = useState<
        | (
              | Record<
                    string,
                    {
                        current: ISalesByOtaDetail;
                        previousYear: ISalesByOtaDetail;
                    }
                >
              | {
                    [key: string]: string;
                }
          )[]
        | null
    >(null);
    const { t } = useTranslation();
    const { isExportCsv } = useAppSelector(salesByOtaSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isExportCsv) {
            exportCSVReport(
                ReportTableId.SALES_BY_OTA_DAILY,
                `${ReportTableCsvFileName.SALES_BY_OTA_DAILY}.csv`,
            );
            dispatch(setExportCsv(false));
        }
    }, [isExportCsv, dispatch]);

    useEffect(() => {
        const dataSource = Object.values(getDailyData(data as ISalesByOtaResponse));
        setTableData(dataSource);
    }, [data]);
    const marketingChannels = Array.from(
        new Set(data.items.map((item) => item.marketingChannel.id)),
    );

    const marketingChannelMap = new Map<number, string>();

    data.items.forEach((item) => {
        marketingChannelMap.set(item.marketingChannel.id, item.marketingChannel.name);
    });

    const columns: TableColumnsType<any> = [
        {
            title: t('report.salesByOta.tableColumns.date'),
            dataIndex: 'date',
            key: 'date',
            width: 150,
            fixed: 'left',
            render: (date) => {
                return <span>{parseDate(date).format(DateFormat.MM_DD_YYYY_SLASH)}</span>;
            },
        },
        {
            title: t('report.salesByOta.tableColumns.ota'),
            className: 'header-cell',
            key: 'ota',
            children: marketingChannels.map((channel) => ({
                title: marketingChannelMap.get(channel),
                className: 'header-cell',
                key: `${channel}`,
                children: [
                    {
                        title: t('report.salesByOta.tableColumns.totalBookedRoom'),
                        key: `${channel}-${COLUMN_KEYS.TOTAL_BOOKED_ROOM}`,
                        width: 100,
                        render: (_, record) => {
                            if (!record[channel]) return null;
                            const { current, previousYear } = record[channel];
                            return renderCellContent(
                                current.totalBookedRoom,
                                previousYear.totalBookedRoom,
                            );
                        },
                    },
                    {
                        title: t('report.salesByOta.tableColumns.totalAdult'),
                        key: `${channel}-${COLUMN_KEYS.TOTAL_GUEST}`,
                        width: 100,
                        render: (_, record) => {
                            if (!record[channel]) return null;
                            const { current, previousYear } = record[channel];
                            return renderCellContent(
                                current.totalGuest,
                                previousYear.totalGuest,
                            );
                        },
                    },
                    {
                        title: t('report.salesByOta.tableColumns.totalChild'),
                        key: `${channel}-${COLUMN_KEYS.TOTAL_CHILD}`,
                        width: 100,
                        render: (_, record) => {
                            if (!record[channel]) return null;
                            const { current, previousYear } = record[channel];
                            return renderCellContent(
                                current.totalChild,
                                previousYear.totalChild,
                            );
                        },
                    },
                    {
                        title: t('report.salesByOta.tableColumns.totalRevenue'),
                        key: `${channel}-${COLUMN_KEYS.TOTAL_REVENUE}`,
                        width: 100,
                        render: (_, record) => {
                            if (!record[channel]) return null;
                            const { current, previousYear } = record[channel];
                            return renderCellContent(
                                current.totalRevenue,
                                previousYear.totalRevenue,
                                true,
                            );
                        },
                    },
                    {
                        title: t('report.salesByOta.tableColumns.avgRevenue'),
                        key: `${channel}-${COLUMN_KEYS.AVG_REVENUE}`,
                        width: 100,
                        render: (_, record) => {
                            if (!record[channel]) return null;
                            const { current, previousYear } = record[channel];
                            return renderCellContent(
                                current.avgRevenue,
                                previousYear.avgRevenue,
                                true,
                            );
                        },
                    },
                ],
            })),
        },
    ];

    const summaryColumns = columns
        .filter((column) => column.key === 'ota')
        .map((column) =>
            (column as ColumnGroupType<any>).children.map(
                (child) => (child as ColumnGroupType<any>).children,
            ),
        )
        .flat(2);

    const summaryData = getDailySummaryData(data as ISalesByOtaResponse);

    const renderSummaryCell = (column: ColumnGroupType<any> | ColumnType<any>) => {
        const key = column.key?.toString();
        if (!key) return null;

        const [marketingChannel, columnType] = key.split('-');
        if (!marketingChannel || !columnType) return null;

        const summary = summaryData[marketingChannel];
        if (!summary) return null;

        const { current, previousYear } = summary;
        switch (columnType) {
            case COLUMN_KEYS.TOTAL_REVENUE:
            case COLUMN_KEYS.AVG_REVENUE:
                return renderCellContent(
                    current[columnType as keyof ISalesByOtaDetail],
                    previousYear[columnType as keyof ISalesByOtaDetail],
                    true,
                );
            default:
                return renderCellContent(
                    current[columnType as keyof ISalesByOtaDetail],
                    previousYear[columnType as keyof ISalesByOtaDetail],
                );
        }
    };
    return (
        <Table
            dataSource={tableData ? tableData : []}
            scroll={{ x: 'max-content' }}
            columns={columns}
            pagination={false}
            loading={loading}
            bordered={true}
            className="sales-by-ota-table table-scrollbar"
            rowKey="date"
            sticky={{
                offsetHeader: 50,
                offsetScroll: 0,
            }}
            id={ReportTableId.SALES_BY_OTA_DAILY}
            summary={() => {
                return (
                    <>
                        <SummaryRow>
                            <SummaryCell className="header-cell" index={0}>
                                Total
                            </SummaryCell>
                            {summaryColumns.map((column, index) => {
                                return (
                                    <SummaryCell
                                        className="header-cell"
                                        key={index}
                                        index={index + 1}
                                    >
                                        {renderSummaryCell(column)}
                                    </SummaryCell>
                                );
                            })}
                        </SummaryRow>
                    </>
                );
            }}
        />
    );
}

export default DailySalesByOtaTable;
