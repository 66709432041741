import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { bankAccountServiceV2 } from '../services/bank-account.service.v2';

const deleteBankAccount = async (id: number) => {
    const response = await bankAccountServiceV2.deleteBankAccount(id);
    if (response.success) {
        return response.data;
    }
    throw response.errors![0];
};

export const useDeleteBankAccount = () => {
    const { t } = useTranslation();
    return useMutation({
        mutationFn: deleteBankAccount,
        onSuccess: () => {
            notification.success({
                message: t('hotel.settingForm.bankAccount.notification.successDelete'),
            });
        },
        onError: (error) => {
            notification.error({
                message: t('common.somethingWentWrong'),
                description: `${error}`,
            });
        },
    });
};
