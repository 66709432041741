import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { t } from 'i18next';
import localStorageAuthService from '~common/authStorage';
import { dayList, PrintingOrientation } from '~common/constants';
import { IPreparationListTableData } from '~features/room-booking/interfaces';
import {
    formatMoney,
    makeRowWithCellSpan,
    textToChunksWithMaxLength,
} from '~common/commonFunctions';
import { Dayjs } from '~plugins/dayjs';

interface IOptions {
    orientation: PrintingOrientation;
    setTotalPage: (totalPage: number) => void;
}

export const generatePreparationListPdf = (
    printPreparationListData: IPreparationListTableData[],
    stayPeriod: Dayjs[],
    options: IOptions,
    tableColumns: string[],
): TDocumentDefinitions => {
    const { orientation = PrintingOrientation.LANDSCAPE, setTotalPage } = options;
    const hotel = localStorageAuthService.getSelectedHotel();
    const content: Content = generatePreparationListContent(
        printPreparationListData,
        tableColumns,
    ) as unknown as Content;
    const startDate = stayPeriod?.[0];
    const endDate = stayPeriod?.[1];

    return {
        pageSize: 'A4',
        pageOrientation: orientation,
        header: (currentPage, pageCount) => ({
            columns: [
                {
                    text: `${t('roomBooking.printing.preparationList.headerTitle')} - ${
                        hotel?.name
                    }`,
                    bold: true,
                    width: 'auto',
                    margin: [0, 0, 20, 0],
                },
                {
                    text: `${t('roomBooking.printing.preparationList.date', {
                        year: startDate?.year(),
                        month: startDate?.month(),
                        day: startDate?.date(),
                        dayOfWeek: t(
                            `roomBooking.printing.preparationList.day.${
                                dayList[startDate?.day()]
                            }`,
                        ),
                    })} ー ${t('roomBooking.printing.preparationList.date', {
                        year: endDate?.year(),
                        month: endDate?.month(),
                        day: endDate?.date(),
                        dayOfWeek: t(
                            `roomBooking.printing.preparationList.day.${
                                dayList[endDate?.day()]
                            }`,
                        ),
                    })}`,
                },
                {
                    text: `${currentPage}/${pageCount}`,
                    alignment: 'right',
                    width: 80,
                },
            ],
            style: 'pageHeader',
            margin: [40, 10],
            width: '*',
        }),
        footer: (currentPage, pageCount) => {
            setTotalPage(pageCount);
            return {
                text: '',
            };
        },
        content,
        styles: {
            pageHeader: {
                fontSize: 12,
                color: '#555757',
            },
            header: {
                fontSize: 18,
                bold: true,
            },
            table: {
                fontSize: 12,
            },
            headerText: {
                color: '#FFFFFF',
            },
            contentText: {
                color: '#8E2A2A',
                bold: true,
            },
        },
    };
};

const generatePreparationListContent = (
    printPreparationListData: IPreparationListTableData[],
    tableColumns: string[],
) => {
    const perRoomModeTableColumns = 6;
    const NUMBER_OF_COLUMNS = 20;
    const isPerRoomMode = tableColumns?.length === perRoomModeTableColumns;
    return [
        {
            style: 'table',
            table: {
                widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                    .map(() => '*')
                    .concat('auto'),
                body: [
                    isPerRoomMode
                        ? makeRowWithCellSpan([
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              `roomBooking.printing.preparationList.order`,
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 1,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.date',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 2,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.roomName',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 3,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.guestName',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 4,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.saleItemName',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 4,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.quantity',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 2,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.price',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 4,
                              },
                          ])
                        : makeRowWithCellSpan([
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              `roomBooking.printing.preparationList.order`,
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 1,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.date',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 3,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.saleItemName',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 8,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.quantity',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 3,
                              },
                              {
                                  stack: [
                                      {
                                          alignment: 'center',
                                          text: t(
                                              'roomBooking.list.preparationListForm.column.price',
                                          ),
                                          style: 'headerText',
                                      },
                                  ],
                                  colSpan: 5,
                              },
                          ]),
                    ...printPreparationListData.map((data, index) => {
                        if (isPerRoomMode) {
                            return makeRowWithCellSpan([
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: index + 1,
                                        },
                                    ],
                                    colSpan: 1,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: data?.date,
                                        },
                                    ],
                                    colSpan: 2,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: textToChunksWithMaxLength(
                                                data?.roomName,
                                                14,
                                                true,
                                            ),
                                        },
                                    ],
                                    colSpan: 3,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: textToChunksWithMaxLength(
                                                data?.guestName,
                                                20,
                                                true,
                                            ),
                                        },
                                    ],
                                    colSpan: 4,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: textToChunksWithMaxLength(
                                                data?.saleItemName,
                                                20,
                                                true,
                                            ),
                                        },
                                    ],
                                    colSpan: 4,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: data?.quantity,
                                        },
                                    ],
                                    colSpan: 2,
                                },
                                {
                                    stack: [
                                        {
                                            alignment: 'center',
                                            text: formatMoney(data?.price),
                                        },
                                    ],
                                    colSpan: 4,
                                },
                            ]);
                        }
                        return makeRowWithCellSpan([
                            {
                                stack: [
                                    {
                                        alignment: 'center',
                                        text: index + 1,
                                    },
                                ],
                                colSpan: 1,
                            },
                            {
                                stack: [
                                    {
                                        alignment: 'center',
                                        text: data?.date,
                                    },
                                ],
                                colSpan: 3,
                            },
                            {
                                stack: [
                                    {
                                        alignment: 'center',
                                        text: textToChunksWithMaxLength(
                                            data?.saleItemName,
                                            40,
                                            true,
                                        ),
                                    },
                                ],
                                colSpan: 8,
                            },
                            {
                                stack: [
                                    {
                                        alignment: 'center',
                                        text: data?.quantity,
                                    },
                                ],
                                colSpan: 3,
                            },
                            {
                                stack: [
                                    {
                                        alignment: 'center',
                                        text: formatMoney(data?.price),
                                    },
                                ],
                                colSpan: 5,
                            },
                        ]);
                    }),
                ],
            },
            layout: {
                hLineWidth: () => 2,
                vLineWidth: () => 2,
                hLineColor: 'white',
                vLineColor: 'white',
                fillColor: (rowIndex: number) => {
                    return rowIndex === 0 ? '#1C3E86' : '#F6F6F6';
                },
                color: (rowIndex: number) => {
                    return rowIndex === 0 ? '#FFFFFF' : '#8E2A2A';
                },
                paddingTop: () => 6,
                paddingBottom: () => 6,
            },
        },
    ];
};
