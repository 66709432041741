import { Switch } from 'antd';
import { useUpdateAlmexSetting } from '../../hooks/use-update-almex-kiosk-setting-mutation';

type SwitchProps = {
    enabled?: boolean;
};
export const AlmexSettingSwitch = (props: SwitchProps) => {
    const { isPending: isMutatePending, mutate } = useUpdateAlmexSetting();

    return (
        <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={props.enabled}
            loading={isMutatePending}
            onChange={(enabled) => mutate({ enabled })}
        />
    );
};
