import { Card, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { dashboardBookingOverviewSelector } from '~features/dashboard/reducers/dashboard.reducer';
import { useAppSelector } from '~hooks';
import './OccupancyRateCard.scss';
import CountUp from 'react-countup';

export const OccupancyRateCard = () => {
    const { t } = useTranslation();
    const { overviewData, occupancyRate } = useAppSelector(
        dashboardBookingOverviewSelector,
    );

    return (
        <Card className="dashboard-occupancy-rate-card-wrapper">
            <div className="content">
                <div>{t('dashboard.bookingOverview.todayOccupationRate')}</div>
                <div className="value">
                    <CountUp end={occupancyRate * 100} suffix="%" />
                </div>
            </div>
            <Divider />
            <div className="footer">
                <div>{t('dashboard.bookingOverview.totalGuests')}</div>
                <div className="total-guests">{overviewData?.stayingGuestCount}</div>
            </div>
        </Card>
    );
};
