import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { bankAccountServiceV2 } from '../services/bank-account.service.v2';
import { BankAccountCreateBody } from '../interfaces';

const createBankAccount = async (input: BankAccountCreateBody) => {
    const response = await bankAccountServiceV2.createBankAccount(input);
    if (response.success) {
        return response.data;
    }
    throw response.errors![0];
};

export const useCreateBankAccount = () => {
    const { t } = useTranslation();
    return useMutation({
        mutationFn: createBankAccount,
        onSuccess: () => {
            notification.success({
                message: t('hotel.settingForm.bankAccount.notification.successCreate'),
            });
        },
        onError: (error) => {
            notification.error({
                message: t('common.somethingWentWrong'),
                description: `${error}`,
            });
        },
    });
};
