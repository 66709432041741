import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { sumBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '~common/commonFunctions';
import { dayList } from '~common/constants';
import { ReportTableId, UnpaidDetailColumn } from '~features/report/constants';
import { convertTimeStringToMinutes } from '~features/report/helper';
import { IUnpaidDetailTableData } from '~features/report/interfaces';
import { unpaidDetailSelector } from '~features/report/reducers/unpaid-detail.reducer';
import { useAppSelector } from '~hooks';
import { Dayjs, parseDate } from '~plugins/dayjs';
import './UnpaidDetailTable.scss';

function UnpaidDetailTable() {
    const { t } = useTranslation();
    const { unpaidDetailList, showLoadingUnpaidDetailTable, selectDate } =
        useAppSelector(unpaidDetailSelector);

    const tableData: IUnpaidDetailTableData[] = useMemo(() => {
        return unpaidDetailList.map((unpaidDetail) => {
            const startDateOfStay = parseDate(unpaidDetail?.startDateOfStay).add(
                convertTimeStringToMinutes(unpaidDetail?.checkInTime),
                'minute',
            );
            const endDateOfStay = parseDate(unpaidDetail?.endDateOfStay).add(
                convertTimeStringToMinutes(unpaidDetail?.checkOutTime),
                'minute',
            );

            return {
                id: unpaidDetail?.id,
                roomName: unpaidDetail?.roomName || '',
                guestName: unpaidDetail?.representativeGuest?.yomigana,
                checkInTime: startDateOfStay,
                checkOutTime: endDateOfStay,
                unpaidAmount: unpaidDetail?.unpaidAmount || 0,
            };
        });
    }, [unpaidDetailList, selectDate]);

    const unpaidDetailColumns: ColumnsType<IUnpaidDetailTableData> = [
        {
            title: t('report.unpaidDetail.unpaidDetailColumn.roomName'),
            dataIndex: UnpaidDetailColumn.ROOM_NAME,
            ellipsis: true,
        },
        {
            title: t('report.unpaidDetail.unpaidDetailColumn.guestName'),
            dataIndex: UnpaidDetailColumn.GUEST_NAME,
            ellipsis: true,
        },
        {
            title: t('report.unpaidDetail.unpaidDetailColumn.checkIn'),
            dataIndex: UnpaidDetailColumn.CHECK_IN_TIME,
            className: 'text-center',
            width: '180px',
            render: (checkInTime: Dayjs) => {
                return `${checkInTime.fmYYYYMMDD()} (${t(
                    `report.unpaidDetail.day.${dayList[checkInTime.day()]}`,
                )})`;
            },
        },
        {
            title: t('report.unpaidDetail.unpaidDetailColumn.checkOut'),
            dataIndex: UnpaidDetailColumn.CHECK_OUT_TIME,
            className: 'text-center',
            width: '180px',
            render: (checkOutTime: Dayjs) => {
                return `${checkOutTime.fmYYYYMMDD()} (${t(
                    `report.unpaidDetail.day.${dayList[checkOutTime.day()]}`,
                )})`;
            },
        },
        {
            title: t('report.unpaidDetail.unpaidDetailColumn.unpaid'),
            dataIndex: UnpaidDetailColumn.UNPAID_AMOUNT,
            className: 'text-right',
            width: '15%',
            render: (unpaidAmount: number) => {
                return `${t('report.unpaidDetail.unit')}${formatMoney(unpaidAmount)}`;
            },
        },
    ];

    return (
        <div>
            <Table
                columns={unpaidDetailColumns}
                loading={showLoadingUnpaidDetailTable}
                dataSource={tableData}
                pagination={false}
                rowKey="id"
                className="report-unpaid-detail-table"
                id={ReportTableId.UNPAID_DETAIL}
                summary={(data) => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell
                                index={0}
                                className="total-title-cell hide-border-right"
                            />
                            <Table.Summary.Cell
                                index={1}
                                className="total-title-cell hide-border-right"
                            />
                            <Table.Summary.Cell
                                index={2}
                                className="total-title-cell hide-border-right"
                            />
                            <Table.Summary.Cell
                                index={4}
                                className="text-right total-title-cell"
                            >
                                {t('report.unpaidDetail.total')}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                                index={5}
                                className="text-right total-cell"
                            >
                                {`${t('report.unpaidDetail.unit')}${formatMoney(
                                    sumBy(data, UnpaidDetailColumn.UNPAID_AMOUNT),
                                )}`}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </div>
    );
}

export default UnpaidDetailTable;
