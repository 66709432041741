import classNames from 'classnames';
import React, { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { facilityBookingStateSelector } from '~features/facility-booking/reducers/facility-schedule.reducer';
import { bookingPercent } from '~features/room-booking/helper';
import { useAppSelector } from '~hooks';

type Props = HTMLProps<HTMLDivElement> & {
    column: {
        id: string;
        label: string;
    };
    today: string;
};

export const Header = ({ column, style, today }: Props) => {
    const { statisticsByDate, numberOfRooms } = useAppSelector(
        facilityBookingStateSelector,
    );
    const booked = statisticsByDate[column.id]?.['total'] || 0;
    const percent = bookingPercent(booked, numberOfRooms);
    const { t } = useTranslation();
    return (
        <div style={{ ...style }} className="header-item">
            <div className="header-item-row-container">
                <div className="header-item-row">
                    <span
                        className={classNames('header-item-percent', {
                            'color-darkest-red': percent === 100,
                            'color-dark-red': percent < 100 && percent >= 90,
                            'color-medium-red': percent < 90 && percent >= 80,
                            'color-light-red': percent < 80 && percent >= 70,
                        })}
                    >
                        {percent}%
                    </span>
                </div>
                <div className="header-item-row">
                    <span
                        className={classNames('header-item-day', {
                            today: column.id === today,
                        })}
                    >
                        {column.label}
                    </span>
                </div>
                <div className="header-item-row">
                    <span className="header-item-count">{booked}</span>
                </div>
            </div>
        </div>
    );
};
