import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { AppState } from '~plugins/redux-toolkit/store';
import { ICancellationReportState, IUpdateCancellationReport } from '../interfaces';
import { reportService } from '../report.service';

const initialState: ICancellationReportState = {
    cancellationReportGetListQuery: [
        customDayjs().startOf('day')?.fmYYYYMMDDHHmmss(),
        customDayjs().endOf('day')?.fmYYYYMMDDHHmmss(),
    ],
    cancellationList: [],
    showLoading: false,
    isShowTable: false,
    isShowMemoModal: false,
    selectedUpdateField: '',
    selectedCancelBooking: null,
    isExportCsv: false,
};

export const getCancellationList = createAsyncThunk(
    'cancellation/getCancellationList',
    async (datePeriod: string[]) => {
        return await reportService.getCancellationData([
            parseDate(datePeriod[0])?.fmYYYYMMDDHHmmss(),
            parseDate(datePeriod[1])?.fmYYYYMMDDHHmmss(),
        ]);
    },
);

export const updateCancellationBooking = createAsyncThunk(
    'cancellation/updateCancellationBooking',
    async (body: IUpdateCancellationReport) => {
        return await reportService.updateBookingCancellation(body);
    },
);

const cancellationSlice = createSlice({
    name: 'cancellation',
    initialState,
    reducers: {
        setCancellationReportGetListQuery: (state, action) => {
            state.cancellationReportGetListQuery = action.payload;
        },
        setSelectedUpdateField: (state, action) => {
            state.selectedUpdateField = action.payload;
        },
        setSelectedCancelBooking: (state, action) => {
            state.selectedCancelBooking = action.payload;
        },
        setIsShowMemoModal: (state, action) => {
            state.isShowMemoModal = action.payload;
        },
        setIsShowTable: (state, action) => {
            state.isShowTable = action.payload;
        },
        setExportCsv: (state, action) => {
            state.isExportCsv = action.payload;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCancellationList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(getCancellationList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.cancellationList = action.payload?.data || [];
        });
    },
});

export const {
    resetState,
    setIsShowTable,
    setIsShowMemoModal,
    setSelectedUpdateField,
    setSelectedCancelBooking,
    setCancellationReportGetListQuery,
    setExportCsv,
} = cancellationSlice.actions;

export const cancellationStateSelector = (state: AppState) => {
    return state.reportCancellation;
};

export default cancellationSlice.reducer;
