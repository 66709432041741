import { INPUT_TEXT_MAX_LENGTH, Regex } from '~common/constants';
import yup from '~plugins/yup';
export const paymentMethodSchema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters(Regex.NAME_SPECIAL_CHARACTERS)
        .required(),
});
