const roomTypeForm = {
    createTitle: '部屋タイプの登録',
    updateTitle: '部屋タイプの編集',
    primaryButton: '部屋タイプを登録',
    roomName: '表示名',
    roomNamePlaceholder: '例: シングル',
    roomTypeCode: '部屋タイプコード',
    roomTypeCodePlaceholder: '例：S',
    abbreviation: '略称',
    abbreviationPlaceholder: '例: s',
    standardCapacity: '標準 定員',
    standardCapacityPlaceholder: '例: 1',
    salesChannelTitle: '販売チャネル',
    selectRoomType: '部屋タイプ',
    selectRoomTypePlaceholder: '部屋タイプをを選択',
    channels: 'チャネル/チャネルグループ',
    linkCode: '連携コード',
    linkCodeUseDay: '連携コード（デイユース)',
    codePlacehoder: 'コード',
    addition: '追加',
    titleRoomSetting: 'コネクティングルーム設定',
    other: 'Other',
    roomType: '部屋タイプ',
    numberOfConnections: 'コネクト数',
    update: {
        error: '部屋タイプ更新エラー',
        success: '部屋タイプが更新されました',
    },
    create: {
        success: '部屋タイプが登録されました',
        error: '部屋タイプ登録エラー',
    },
    confirm: {
        cancelTitle: 'キャンセルしてもよろしいですか？',
    },
    placeholder: {
        roomType: '部屋タイプを選択',
    },
    createButton: '登録',
};

export default roomTypeForm;
