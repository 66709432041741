import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';

const initialState = {
    isExportCsv: false,
    isShowSalesByPlansTable: false,
    isShowByDaily: false,
    selectedDate: '',
    selectedPlanId: 0,
};

const salesByPlansSlice = createSlice({
    name: 'sales-by-plans',
    initialState,
    reducers: {
        setExportCsv: (state, action) => {
            state.isExportCsv = action.payload;
        },
        setShowSalesByPlansTable: (state, action) => {
            state.isShowSalesByPlansTable = action.payload;
        },
        setIsShowByDaily: (state, action) => {
            state.isShowByDaily = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setSelectedPlanId: (state, action) => {
            state.selectedPlanId = action.payload;
        },
    },
});

export const {
    setExportCsv,
    setShowSalesByPlansTable,
    setIsShowByDaily,
    setSelectedDate,
    setSelectedPlanId,
} = salesByPlansSlice.actions;

export const salesByPlansSelector = (state: AppState) => {
    return state.reportSalesByPlans;
};

export default salesByPlansSlice.reducer;
