import { IBookingReceiptTableData } from '~features/room-booking/interfaces';
import './DetailReceiptColumn.scss';
import { ReceiptItemDetailType, ReceiptItemType } from '~features/room-booking/constants';
import classNames from 'classnames';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type Props = {
    item: IBookingReceiptTableData;
    expanded: boolean;
};
function DetailReceiptCell(props: Props) {
    const { item, expanded } = props;
    const { t } = useTranslation();

    const detailName = useMemo(() => {
        let detailName = item.plan?.name || item.facility?.name || item.saleItem?.name;
        if (!detailName && item.level === 2) {
            detailName = t('roomBooking.detail.receipt.adult');
            switch (item.receiptItemDetailType) {
                case ReceiptItemDetailType.LOCAL_TAX:
                case ReceiptItemDetailType.BATH_TAX:
                    detailName = item.currentTax?.name;
                    break;
                case ReceiptItemDetailType.STAY_PRICE_CHILDREN:
                    detailName = item.childrenType?.name;
                    break;
                case ReceiptItemDetailType.SALE_ITEM:
                    detailName =
                        item.saleItem?.name ||
                        t('roomBooking.detail.receipt.item.notExist');
                    break;
            }
        }
        return detailName;
    }, [item, t]);

    const detailNameError = useMemo(() => {
        return (
            (item.plan && !item.plan.id) ||
            (item.plan?.name && item.plan?.isPullFromTll) ||
            (item.saleItem && !item.saleItem.name)
        );
    }, [item]);

    return (
        <div className="sale-item-plan-cell">
            {item.status !== ReceiptItemType.PAYMENT && (
                <div
                    className={classNames('sale-item-plan text-truncate', {
                        'red-color': detailNameError,
                    })}
                >
                    {detailName}
                </div>
            )}
            {item.children && item.status === ReceiptItemType.RECEIPT && (
                <div className="expand-plan-icon">
                    <CaretDownOutlined
                        className={classNames({
                            active: !expanded,
                            deactive: expanded,
                        })}
                    />
                </div>
            )}
        </div>
    );
}

export default DetailReceiptCell;
