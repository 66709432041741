import { lazy, object } from 'yup';
import { isNaN } from 'lodash';
import {
    INPUT_TEXT_MAX_LENGTH,
    MAX_PRICE,
    MEMO_MAX_LENGTH,
    MIN_PRICE,
    Regex,
} from '~common/constants';
import yup from '~plugins/yup';
import mapValues from 'lodash/mapValues';
import { ReceiptItemType } from '~features/room-booking/constants';
import { MAX_GUEST_IN_FACILITY_BOOKING, MAX_QUANTITY } from './constants';
import { ReceiptItemDetailType } from '~features/room-booking/constants';

export const advanceSearchSchema = yup.object().shape({
    autoGeneratedCode: yup.string().max(INPUT_TEXT_MAX_LENGTH).optional(),
    guestCategory: yup.string().max(INPUT_TEXT_MAX_LENGTH).optional(),
    facilityIds: yup.array().of(yup.number().optional()),
    status: yup.array().of(yup.string().optional()),
    guestYomigana: yup.string().max(INPUT_TEXT_MAX_LENGTH).optional(),
    guestPhoneNumber: yup.string().isPhone().max(INPUT_TEXT_MAX_LENGTH).optional(),
    marketingChannels: yup.array().of(yup.string().optional()),
});

export const createFacilityBookingSchema = yup.object().shape({
    facilityId: yup.number().required(),
    numberOfGuests: yup
        .string()
        .required()
        .minFullAndHalfWidth(1)
        .maxFullAndHalfWidth(MAX_GUEST_IN_FACILITY_BOOKING),
    reservationDuration: yup.mixed().required(),
    mobilePhoneNumber: yup
        .string()
        .isPhone()
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable()
        .optional(),
    emailAddress: yup
        .string()
        .isEmail()
        .trim()
        .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
        .max(INPUT_TEXT_MAX_LENGTH)
        .nullable()
        .optional(),
    guestId: yup
        .number()
        .transform((val: number) => (isNaN(val) ? null : val))
        .nullable(),
    yomigana: yup
        .mixed()
        .when('guestId', {
            is: null,
            then: yup
                .string()
                .trim()
                .hasNoSpecialCharacters()
                .max(INPUT_TEXT_MAX_LENGTH)
                .required(),
        })
        .required(),
    fullName: yup
        .string()
        .max(INPUT_TEXT_MAX_LENGTH)
        .hasNoSpecialCharacters()
        .nullable()
        .optional(),
    birthday: yup.mixed().optional(),
    gender: yup.mixed().optional(),
    memo: yup.string().max(MEMO_MAX_LENGTH).nullable().optional(),
});

export const updateFacilityBookingReceiptSchema = yup.object().shape({
    items: yup.lazy((object) =>
        yup.object(
            mapValues(object, () =>
                yup.object({
                    receiptItemDetailType: yup.string().notRequired().nullable(),
                    type: yup.string().notRequired().nullable(),
                    unitPrice: yup.string().when('receiptItemDetailType', {
                        is: (value: string) => {
                            return value !== ReceiptItemDetailType.PAYMENT;
                        },
                        then: yup
                            .string()
                            .required()
                            .minPriceFullAndHalfWidth(MIN_PRICE)
                            .maxPriceFullAndHalfWidth(MAX_PRICE),
                    }),
                    quantity: yup.string().when('receiptItemDetailType', {
                        is: (value: string) => {
                            return value !== ReceiptItemDetailType.PAYMENT;
                        },
                        then: yup
                            .string()
                            .required()
                            .minFullAndHalfWidth(0)
                            .maxFullAndHalfWidth(MAX_QUANTITY),
                    }),
                    paymentAmount: yup
                        .string()
                        .required()
                        .minPriceFullAndHalfWidth(MIN_PRICE)
                        .maxPriceFullAndHalfWidth(MAX_PRICE),
                    paymentMethodId: yup.number().when('receiptItemDetailType', {
                        is: (value: string) => {
                            return value === ReceiptItemDetailType.PAYMENT;
                        },
                        then: yup.number().required(),
                    }),
                    saleItemId: yup.number().when('isAddition', {
                        is: true,
                        then: yup.number().when('type', {
                            is: ReceiptItemType.RECEIPT,
                            then: yup.number().required(),
                        }),
                    }),
                    paymentRoomBookingItemId: yup.number().when('$exist', {
                        is: true,
                        then: yup.number().required(),
                    }),
                    date: yup.string().when('$exist', {
                        is: true,
                        then: yup.string().required(),
                    }),
                }),
            ),
        ),
    ),
});

export const checkInFacilityBookingSchema = yup.object().shape({
    facilityId: yup.number().required(),
    paymentMethodCheckIns: yup.array().of(
        yup.object().shape({
            amount: yup
                .string()
                .required()
                .minPriceFullAndHalfWidth(MIN_PRICE)
                .maxPriceFullAndHalfWidth(MAX_PRICE),
            paymentMethodId: yup.number().required(),
        }),
    ),
});

export const paymentFacilityBookingSchema = yup.object().shape({
    bookingId: yup.string().required(),
    paymentMethodId: yup.number().required(),
    paymentAmount: yup
        .string()
        .minPriceFullAndHalfWidth(MIN_PRICE)
        .maxPriceFullAndHalfWidth(MAX_PRICE)
        .required(),
});

export const updateBillToSchema = yup.object().shape({
    billName: yup.string().max(120).nullable(),
    billProviso: yup.string().max(INPUT_TEXT_MAX_LENGTH).nullable(),
});

export const updateFacilityBookingSplitReceiptSchema = lazy((obj) => {
    return object(
        mapValues(obj, () =>
            yup.object({
                saleItemId: yup.number().required(),
                unitPrice: yup
                    .string()
                    .minPriceFullAndHalfWidth(MIN_PRICE)
                    .maxPriceFullAndHalfWidth(MAX_PRICE)
                    .required(),
                quantity: yup.string().required(),
            }),
        ),
    );
});

export const bulkPaymentSchema = yup.object().shape({
    paymentDate: yup.string().required(),
    paymentMethodId: yup.number().required(),
});
