import React, { useEffect, useMemo, useState } from 'react';
import { ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, notification, Table, Pagination, Card, Tag } from 'antd';
import type { TableProps } from 'antd/es/table';
import { downloadFile, exportCSVFile } from '~common/commonFunctions';
import {
    AntdOrderDirection,
    CsvOption,
    EmitterGlobalEvent,
    OrderDirection,
} from '~common/constants';
import {
    exportColumns,
    EXPORT_CSV_FILE_NAME,
    FILE_NAME,
    HotelListColumn,
    HotelPermission,
    HotelStatus,
    i18ExportKey,
    initHotelListQuery,
} from '../../constants';
import { useTranslation } from 'react-i18next';
import { hotelService } from '../../services/hotel.api';
import {
    fetchHotelList,
    hotelListQuerySelector,
    hotelListSelector,
    setHotelListQuery,
    setSelectedHotel,
    totalPageSelector,
    totalHotelsSelector,
    showLoadingListSelector,
    setShowForm,
} from '../../hotel.reducer';
import { IHotel, IHotelExportCsvQuery } from '../../interfaces';
import { useAppSelector, useAppDispatch } from '~hooks';
import { ModalConfirmDeletion, CsvDropdown } from '~components';
import './HotelList.scss';
import { ISorter } from '~common/interfaces';
import { ColumnsType } from 'antd/lib/table';
import { cloneDeep, orderBy } from 'lodash';
import { useMitt } from '~plugins/mitt';

interface ICSVData {
    peCsrHotelCode: string;
    name: string;
    tllPmsUserId: string;
    picName?: string;
    postalCode?: string;
    address?: string;
    picPhoneNumber?: string;
    picEmailAddress?: string;
    numberOfRoom?: number;
    standardCheckInTime?: string;
    standardCheckOutTime?: string;
    status: string;
    permission: string;
}

function HotelList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const hotelListQuery = useAppSelector(hotelListQuerySelector);
    const hotelList = useAppSelector(hotelListSelector);
    const showLoadingList = useAppSelector(showLoadingListSelector);

    const pageCount = useAppSelector(totalPageSelector);
    const totalHotels = useAppSelector(totalHotelsSelector);
    const onChange: TableProps<IHotel>['onChange'] = (pagination, filters, sorter) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        dispatch(
            setHotelListQuery({
                ...hotelListQuery,
                orderBy: order ? _field || HotelListColumn.ID : HotelListColumn.ID,
                orderDirection:
                    order === AntdOrderDirection.ASC
                        ? OrderDirection.ASC
                        : OrderDirection.DESC,
            }),
        );
    };

    const { emitter } = useMitt();

    useEffect(() => {
        return () => {
            dispatch(setHotelListQuery(cloneDeep(initHotelListQuery)));
        };
    }, []);

    const onRefresh = async () => {
        await fetchData();
        setSelectedRowKeys([]);
    };

    const onSelectChange = (_selectedRowKeys: React.Key[]) => {
        const hotels: IHotel[] = [];
        _selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundHotel = hotelList.find(
                (hotel: IHotel) => hotel.id === selectedRowKey,
            );
            if (foundHotel) hotels.push(foundHotel);
        });
        setSelectedRowKeys(_selectedRowKeys);
    };
    const fetchData = () => {
        dispatch(fetchHotelList());
    };

    useEffect(() => {
        if (hotelList.length === 0 && hotelListQuery?.page && hotelListQuery?.page > 1) {
            const page = hotelListQuery.page - 1;
            dispatch(setHotelListQuery({ ...hotelListQuery, page }));
        }
    }, [hotelList]);

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await hotelService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('hotel.list.delete.deleteHotelsSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
            emitter.emit(EmitterGlobalEvent.LOCAL_STORAGE_CHANGE, true);
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [hotelListQuery]);

    const onChangePage = (page: number) => {
        dispatch(setHotelListQuery({ ...hotelListQuery, page }));
    };

    const editRecord = (hotel: IHotel) => {
        dispatch(setShowForm(true));
        dispatch(setSelectedHotel(hotel));
    };

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            buttonCancelText: t('hotel.list.delete.buttonCancelText'),
            buttonDeleteText: t('hotel.list.delete.buttonDeleteText'),
            okButtonProps: { danger: true },
            deletedItems: selectedRows.map((row) => row.name.toString()),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const exportCsv = async (query: IHotelExportCsvQuery) => {
        const response = await hotelService.exportCsv(query);
        if (response.success) {
            downloadFile(FILE_NAME, response.data.filePath);
        }
    };

    const sortDataToCsv = (datas: IHotel[]) => {
        const newDatas = datas.sort(function (data1, data2) {
            return data2.id - data1.id;
        });
        return newDatas;
    };

    const convertDataToCsv = (hotels: IHotel[]) => {
        const newHotels = sortDataToCsv(hotels);
        const dataOutput: ICSVData[] = [];
        newHotels.forEach((hotel) => {
            const data = {
                peCsrHotelCode: hotel.peCsrHotelCode,
                name: hotel.name,
                tllPmsUserId: hotel.tllPmsUserId,
                picName: hotel.picName,
                postalCode: hotel.postalCode,
                address: hotel.address,
                picPhoneNumber: hotel.picPhoneNumber,
                picEmailAddress: hotel.picEmailAddress,
                numberOfRoom: hotel.numberOfRoom,
                standardCheckInTime: hotel.standardCheckInTime,
                standardCheckOutTime: hotel.standardCheckOutTime,
                status:
                    hotel.status === HotelStatus.ACTIVE
                        ? t('hotel.list.hotelListColumn.tagStatus.active')
                        : t('hotel.list.hotelListColumn.tagStatus.inactive'),
                permission:
                    hotel.permission === HotelPermission.PMS
                        ? t('hotel.list.hotelListColumn.tagPermission.pms')
                        : '',
            };
            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['peCsrHotelCode'], ['desc']);
    };

    const exportHotelList = async (hotels: IHotel[]) => {
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        // Convert to file csv
        const roomDataCsvs = convertDataToCsv(hotels);
        await exportCSVFile(exportColumns, filename, roomDataCsvs, i18ExportKey);
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all hotel
                await exportCsv({
                    keyword: hotelListQuery.keyword,
                    autoGeneratedCode: hotelListQuery.autoGeneratedCode,
                    name: hotelListQuery.name,
                    picName: hotelListQuery.picName,
                    postalCode: hotelListQuery.postalCode,
                    address: hotelListQuery.address,
                    picPhoneNumber: hotelListQuery.picPhoneNumber,
                    picEmailAddress: hotelListQuery.picEmailAddress,
                    orderBy: hotelListQuery.orderBy,
                    orderDirection: hotelListQuery.orderDirection,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportHotelList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                // ToDo: import
                break;
            }
            default:
                break;
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const rows: IHotel[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundHotel = hotelList.find(
                (hotel: IHotel) => hotel.id === selectedRowKey,
            );
            if (foundHotel) rows.push(foundHotel);
        });
        return rows;
    }, [selectedRowKeys, hotelList]);

    const hotelColumns: ColumnsType<IHotel> = [
        {
            title: t('hotel.list.hotelListColumn.peCsrHotelCode'),
            width: '150px',
            onCell: () => {
                return {
                    style: { color: '#1c3e86' },
                };
            },
            render: (hotel: IHotel) => {
                return (
                    <div>
                        <a
                            onClick={() => {
                                editRecord(hotel);
                            }}
                        >
                            {hotel.peCsrHotelCode}
                        </a>
                    </div>
                );
            },
            key: HotelListColumn.PE_CSR_HOTEL_CODE,
            sorter: true,
            fixed: 'left',
        },
        {
            title: t('hotel.list.hotelListColumn.hotelName'),
            width: '300px',
            dataIndex: '',
            key: HotelListColumn.NAME,
            render: (hotel: IHotel) => {
                return <div className="hotel-item-field">{hotel.name}</div>;
            },
            sorter: true,
            fixed: 'left',
        },
        {
            title: t('hotel.list.hotelListColumn.tllPmsUserId'),
            width: '250px',
            dataIndex: '',
            render: (hotel: IHotel) => {
                return (
                    <div className="hotel-item-field id-field">{hotel.tllPmsUserId}</div>
                );
            },
            key: HotelListColumn.TLL_PMS_USER_ID,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.picName'),
            width: '180px',
            dataIndex: '',
            render: (hotel: IHotel) => {
                return <div className="hotel-item-field">{hotel.picName}</div>;
            },
            key: HotelListColumn.PIC_NAME,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.postalCode'),
            width: '200px',
            dataIndex: HotelListColumn.POSTAL_CODE,
            key: HotelListColumn.POSTAL_CODE,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.address'),
            width: '200px',
            dataIndex: '',
            render: (hotel: IHotel) => {
                return <div className="hotel-item-field">{hotel.address}</div>;
            },
            key: HotelListColumn.ADDRESS,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.picPhoneNumber'),
            width: '300px',
            dataIndex: '',
            render: (hotel: IHotel) => {
                return <div className="hotel-item-field">{hotel.picPhoneNumber}</div>;
            },
            key: HotelListColumn.PIC_PHONE_NUMBER,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.picEmailAddress'),
            width: '350px',
            dataIndex: HotelListColumn.PIC_EMAIL_ADDRESS,
            key: HotelListColumn.PIC_EMAIL_ADDRESS,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.numberOfRoom'),
            width: '150px',
            dataIndex: HotelListColumn.NUMBER_OF_ROOM,
            key: HotelListColumn.NUMBER_OF_ROOM,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.standardCheckInTime'),
            width: '200px',
            dataIndex: HotelListColumn.DEFAULT_CHECK_IN_TIME,
            key: HotelListColumn.DEFAULT_CHECK_IN_TIME,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.standardCheckOutTime'),
            width: '200px',
            dataIndex: HotelListColumn.DEFAULT_CHECK_OUT_TIME,
            key: HotelListColumn.DEFAULT_CHECK_OUT_TIME,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.status'),
            width: '200px',
            dataIndex: '',
            render: (hotel: IHotel) => {
                if (hotel.status === 'active') {
                    return (
                        <div>
                            <Tag color="green">
                                {t('hotel.list.hotelListColumn.tagStatus.active')}
                            </Tag>
                        </div>
                    );
                } else {
                    return (
                        <Tag color="red">
                            {t('hotel.list.hotelListColumn.tagStatus.inactive')}
                        </Tag>
                    );
                }
            },
            key: HotelListColumn.STATUS,
            sorter: true,
        },
        {
            title: t('hotel.list.hotelListColumn.permission'),
            width: '200px',
            dataIndex: '',
            render: (hotel: IHotel) => {
                if (hotel.permission === HotelPermission.PMS) {
                    return (
                        <div>
                            <Tag color="green">
                                {t('hotel.list.hotelListColumn.tagPermission.pms')}
                            </Tag>
                        </div>
                    );
                } else {
                    return;
                }
            },
            key: HotelListColumn.STATUS,
            sorter: true,
        },
    ];
    return (
        <div className="hotel-list-wrapper">
            <Card
                className="hotel-card"
                title={
                    <div className="hotel-list-header">
                        {selectedRows.length > 0 && (
                            <div className="list-header-left">
                                <Button
                                    type="text"
                                    className="btn-icon"
                                    onClick={showConfirmDialog}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )}

                        <div className="list-header-right">
                            <CsvDropdown
                                onChange={onChangeCsvOption}
                                hasSelectedColumns={selectedRows.length > 0}
                                hasImportOption={false}
                            />
                            <ReloadOutlined className="size-icon" onClick={onRefresh} />
                        </div>
                    </div>
                }
            >
                <Table
                    rowSelection={rowSelection}
                    columns={hotelColumns}
                    dataSource={hotelList}
                    onChange={onChange}
                    onRow={(record) => {
                        return {
                            // click row
                            onClick: () => {
                                editRecord(record);
                            },
                        };
                    }}
                    pagination={false}
                    rowClassName="hotel-row"
                    rowKey="id"
                    className="hotel-list-table table-scrollbar"
                    loading={showLoadingList}
                    scroll={{ x: 2000 }}
                />
            </Card>

            {pageCount > 1 && (
                <Pagination
                    align="center"
                    className="hotel-list-pagination"
                    defaultCurrent={hotelListQuery.page}
                    current={hotelListQuery.page}
                    total={totalHotels}
                    pageSize={hotelListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default HotelList;
