import { IHotelsmartKeyCode } from '~features/room-booking/interfaces';
interface Props {
    title: string;
    keyCodes: IHotelsmartKeyCode[];
}

function HotelSmartKeyCodes({ title, keyCodes }: Props) {
    return (
        <div className="hotel-smart-item">
            <p className="font-weight-text-600">{title}</p>
            {keyCodes.map((keyCode) => (
                <div key={keyCode.roomId}>
                    <p>
                        {keyCode.roomName}: {keyCode.keyCode}
                    </p>
                </div>
            ))}
        </div>
    );
}

export default HotelSmartKeyCodes;
