const splitReceipt = {
    home: 'Home',
    facilityBookingList: 'Facility booking',
    splitReceipt: 'Receipt splitting',
    title: 'Receipt splitting',
    titleInvoice: 'Invoice splitting',
    room: 'Room',
    group: 'Split',
    roomItem: 'Accommodation',
    facilityItem: 'Facility',
    splitGroup: 'Split group',
    all: 'All',
    notificationAlert: {
        warning:
            'It is possible to edit the number of receipt copies and the content of the receipt. Even if you make edits to the receipt, the details in each tab will remain unchanged.',
        notSplitDone: 'Please assign room numbers before printing',
    },
    selectSplitBillTypePanel: {
        placeholder: 'Select number',
        title: 'Receipt splitting',
        divisionType: 'Spliting method',
        split: 'Split',
        basedOnRoom: 'per room',
        basedOnGroup: 'per group',
        confirmationText:
            'Would you like to change the selected items to group「{{name}}」',
        buttonConfirmText: 'Yes',
    },
    billToTab: {
        title: 'Specify group spliting',
        printAll: 'Print all',
        issueHistory: 'Receipt issue history',
        invoiceIssueHistory: 'Invoice issue history',
        item: {
            title: 'Group 1',
            print: 'Print',
            billName: 'Recipient',
            billNameSubText: 'Mr./Ms.',
            billProviso: 'Disclaimer',
            billProvisoSubText: 'as',
            billTotal: 'Total',
        },
    },
    receiptDetailTab: {
        title: 'Receipt detail',
        invoiceTitle: 'Invoice detail',
        selectGroup: 'Change number of split group',
        item: {
            receiptTableColumn: {
                date: 'Date',
                bookingDetails: 'Detail',
                guestName: 'Guest name',
                roomName: 'Room name',
                unitPrice: 'Unit price',
                quantity: 'Quantity',
                amount: 'Amount',
                checkOutRoom: 'Settlement room',
                paymentMethod: 'Payment method',
            },
        },
        bookingDetailNoChildren: 'Room price ({{roomName}}/Adults:{{numberOfAdults}})',
        bookingDetail:
            'Room pricce ({{roomName}}/Adults:{{numberOfAdults}} Children:{{numberOfChildren}})',
    },
    bottomButtonGroup: {
        buttonReturnText: 'Back',
    },
    print: {
        issueDate: 'Issue Date',
        receiptNumber: 'Number',
        receipt: 'Receipt',
        invoiceNumber: 'Invoice number',
        invoice: 'Invoice',
        registeredNumber: 'Registered number',
        nameSubText: 'Mr./Ms.',
        amount: 'Amount',
        invoiceAmount: 'Amount',
        tax: 'Consumption taxes',
        provisoSubText: 'Disclaimer',
        stampPlaceholder: '',
        reservationNumber: 'Booking ID',
        stayPeriod: 'Usage date',
        dateText: 'Date',
        reservation: 'RESERVATION #',
        bookingDetails: 'Statement of usage',
        paymentDetails: 'Statement of payment',
        reducedSalesTaxRate: 'Subject to reduced sales tax',
        target: 'Subject to',
        taxAmount: 'Sales tax amount',
        otherTaxAmount: 'Other tax amount',
        totalTaxAmount: 'Sales tax amount',
        table: {
            bookingDate: 'Date',
            bookingDetail: 'Detail',
            unitPrice: 'Unit price',
            quantity: 'Quantity',
            amount: 'Amount',
            subtotal: 'Subtotal',
            total: 'Total',
        },
        prepaidAmount: 'Prepaid amount',
        paidAmount: 'Paid amount',
        bankAccounts: 'Bank accounts',
        bank: 'Bank',
        branch: 'Branch',
        accountNumber: 'Account number',
        accountOwner: 'Account owner',
        bankAccountNote: 'Please bear the bank transfer fee.',
    },
};

export default splitReceipt;
