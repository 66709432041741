const childrenTypeForm = {
    childTypeName: {
        label: 'Children Type Name',
    },
    priceValue: {
        label: 'Price',
    },
    priceType: {
        label: 'Children Linkage Code',
        fixed: 'Fixed Price',
        pricePerAdult: 'Price per adult',
    },
    linkageCode: {
        label: 'Children Linkage Code',
    },
    name: {
        label: 'Abbreviation',
    },
    yen: 'JPY',
    percentage: '%',
    confirm: {
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
        cancelTitle: 'Do you want to cancel?',
    },
    createForm: {
        title: 'Children Type Registration',
        create: {
            success: 'Children type has been created successfully.',
            failed: 'Failed to create children type',
        },
    },
    updateForm: {
        title: 'Edit Children Type',
        update: {
            success: 'Children type has been modified successfully.',
            failed: 'Failed to modify children type',
        },
    },
};

export default childrenTypeForm;
