import { IPaymentDetailsState, IPaymentMethod } from './../interfaces';
import { defaultPaymentDetailsQuery } from './../constants';
import { reportService } from '~features/report/report.service';
import { AppState } from '~plugins/redux-toolkit/store';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep, uniqBy } from 'lodash';

export const getPaymentDetails = createAsyncThunk(
    'report/get-payment-details',
    async (_, { getState }) => {
        const query = paymentDetailsQuerySelector(getState() as AppState);
        return await reportService.getPaymentDetails(query);
    },
);

const initialState: IPaymentDetailsState = {
    paymentDetailsQuery: cloneDeep(defaultPaymentDetailsQuery),
    paymentDetailList: [],
    selectedPaymentMethodIds: [],
    isLoading: false,
    isShowTable: false,
    isShowPaymentDetailPrintingModal: false,
    isExportCsv: false,
};

const paymentDetailsSlice = createSlice({
    name: 'paymentDetails',
    initialState,
    reducers: {
        setIsShowTable: (state, action) => {
            state.isShowTable = action.payload;
        },
        setPaymentDetailsQuery: (state, action) => {
            state.paymentDetailsQuery = action.payload;
        },
        setSelectedPaymentMethodIds: (state, action) => {
            state.selectedPaymentMethodIds = action.payload;
        },
        setExportCsv: (state, action) => {
            state.isExportCsv = action.payload;
        },
        resetState: () => {
            return initialState;
        },
        setIsShowPaymentDetailPrintingModal: (state, action) => {
            state.isShowPaymentDetailPrintingModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPaymentDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getPaymentDetails.fulfilled, (state, action) => {
            state.paymentDetailList = action.payload?.data || [];
            state.isLoading = false;
        });
    },
});

export const {
    setIsShowTable,
    setPaymentDetailsQuery,
    setSelectedPaymentMethodIds,
    setExportCsv,
    resetState,
    setIsShowPaymentDetailPrintingModal,
} = paymentDetailsSlice.actions;

export const paymentDetailsQuerySelector = (state: AppState) => {
    return state.reportPaymentDetails.paymentDetailsQuery;
};

export const paymentDetailsSelector = (state: AppState) => state.reportPaymentDetails;

export const isShowPaymentDetailPrintingModalSelector = (state: AppState) => {
    return state.reportPaymentDetails.isShowPaymentDetailPrintingModal;
};

export const selectedPaymentMethodIdsSelector = (state: AppState) => {
    return state.reportPaymentDetails.selectedPaymentMethodIds;
};

export const paymentMethodListForReportSelector = (state: AppState) => {
    const { paymentDetailList } = state.reportPaymentDetails;
    let methods: IPaymentMethod[] = [];
    paymentDetailList?.forEach((item) => {
        const _methods = item.receiptItems?.map(
            (receiptItem) => receiptItem.paymentMethod,
        );
        methods = [...methods, ..._methods];
    });

    return uniqBy(methods, 'id');
};

export default paymentDetailsSlice.reducer;
