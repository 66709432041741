import { Layout, Spin } from 'antd';

import { cloneDeep } from 'lodash';
import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrintingFonts, PrintingOrientation } from '~common/constants';
import { showConfirm } from '~common/notification';
import { PdfPrintingModal } from '~components';
import {
    isShowPreparationListPrintingModalSelector,
    printPreparationListDataSelector,
    setIsShowPreparationListPrintingModal,
} from '~features/room-booking/reducers/print-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { Dayjs } from '~plugins/dayjs';
import { generatePreparationListPdf } from './generatePreparationListPdfContent';
import './PreparationListPrintModal.scss';

interface IProps {
    tableColumns: string[];
    stayPeriod: Dayjs[];
}

function PreparationListPrintModal(props: IProps) {
    const { stayPeriod, tableColumns } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isShowPreparationListPrintingModal = useAppSelector(
        isShowPreparationListPrintingModalSelector,
    );
    const printPreparationListData = useAppSelector(printPreparationListDataSelector);
    const [orientation, setOrientation] = useState<PrintingOrientation>(
        PrintingOrientation.LANDSCAPE,
    );
    const [url, setUrl] = useState<string>();
    const [pdf, setPdf] = useState<TCreatedPdf>();
    const [totalPage, setTotalPage] = useState(0);

    const onChangeOrientation = (orientation: PrintingOrientation) => {
        setUrl('');
        setOrientation(orientation);
    };

    const onClose = () => {
        dispatch(setIsShowPreparationListPrintingModal(false));
    };

    const showConfirmCancel = () => {
        showConfirm({
            title: t('roomBooking.list.preparationListForm.cancelTitle'),
            onOk() {
                onClose();
            },
        });
    };

    const onPrint = useCallback(() => {
        cloneDeep(pdf)?.print();
    }, [pdf]);

    const createPdf = () => {
        pdfMake.fonts = PrintingFonts;
        setUrl('');
        setPdf(
            pdfMake.createPdf(
                generatePreparationListPdf(
                    printPreparationListData,
                    stayPeriod,
                    {
                        orientation,
                        setTotalPage,
                    },
                    tableColumns,
                ),
            ),
        );
    };

    useEffect(() => {
        createPdf();
    }, [printPreparationListData, orientation]);

    useEffect(() => {
        cloneDeep(pdf)?.getDataUrl((dataUrl: string) => {
            setUrl(dataUrl);
        });
    }, [pdf]);
    return (
        <PdfPrintingModal
            totalPage={totalPage}
            onPrint={onPrint}
            onClose={showConfirmCancel}
            onChangeOrientation={onChangeOrientation}
            defaultOrientation={orientation}
            isShowPdfPrinting={isShowPreparationListPrintingModal}
            isShowControlBar={false}
            okButtonLoading={!url}
        >
            <Layout className="preparation-list-printing-layout">
                <Layout.Content className="preparation-list-printing-content">
                    {url ? (
                        <iframe className="pdf-view" src={`${url}#toolbar=0`} />
                    ) : (
                        <Spin />
                    )}
                </Layout.Content>
            </Layout>
        </PdfPrintingModal>
    );
}

export default PreparationListPrintModal;
