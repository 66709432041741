const rankCalendarUpdate = {
    title: 'Rank calendar details',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        rankCalendar: 'Rank calendar setting',
        rankCalendarDetail: 'Rank calendar details',
    },
    actionForm: {
        label: 'Calendar name',
        cancelBtn: 'Cancel',
        updateBtn: 'Save',
    },
    advancedSettingBtn: 'Detail setting',
    bulkUpdateBtn: 'Bulk update',
    note: 'Click on one checkbox and hold Shift key, then select another checkbox to select all checkboxes in between',
    modalUpdate: {
        title: 'Change selected items',
        label: 'Rank',
        placeholder: 'Select rank',
        cancelBtn: 'Cancel',
        submitBtn: 'Apply',
    },
};

export default rankCalendarUpdate;
