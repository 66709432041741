import React from 'react';
import { StaffLayoutHeader } from '~components';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import CSVBookingUploadComponent from '../components/CSVBookingUploadComponent';
import { breadcrumbs } from '../constants';
const { Content } = Layout;

const CSVBookingUploadPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="csv-booking-upload-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('csvBookingUploadLocale.text.title')}
            />
            <Content>
                <CSVBookingUploadComponent />
            </Content>
        </div>
    );
};

export default CSVBookingUploadPage;
