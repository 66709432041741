import customDayjs, { parseDate, parseTime } from '~plugins/dayjs';
import _, { isEqual } from 'lodash';
import {
    IGridDateItem,
    IGridItem,
    IStopSellingInventoryTableData,
    IInventory,
    IInventoryFormItem,
    IInventoryForm,
    IStoppingRoomItem,
} from './interfaces';
import localStorageAuthService from '~common/authStorage';
import { DefaultCheckInOutTime } from '~features/hotel/constants';
import { DEFAULT_TIME_ZONE } from '~features/room-management/constants';
import dayjs from 'dayjs';

export const makeGridData = (data: IStopSellingInventoryTableData[]) => {
    const results: IGridItem[] = [];
    const makeDate = (item: Record<string, IGridDateItem>) => {
        const date: Record<string, IGridDateItem> = {};
        _.forEach(item, (val, day) => {
            date[parseDate(day)?.fmYYYYMMDD()] = val;
        });
        return date;
    };
    _.forEach(data, (item) => {
        results.push({
            id: item.id,
            name: item.name,
            parentId: null,
            date: makeDate(item.days),
        });
        _.forEach(item.children, (child) => {
            results.push({
                id: child.id,
                parentId: item.id,
                name: child.name,
                date: makeDate(child.days),
            });
        });
    });
    return results;
};

export const getStartDateAndEndDate = (
    _startDate: string | Date,
    _endDate: string | Date | null,
) => {
    // get check in and check out time in hotel setting
    const pmsHotel = localStorageAuthService.getSelectedHotel();
    let startDateTime: string | null = null;
    let endDateTime: string | null = null;
    let checkInTime =
        pmsHotel?.standardCheckInTime || DefaultCheckInOutTime.CHECK_IN_TIME;
    let checkOutTime =
        pmsHotel?.standardCheckOutTime || DefaultCheckInOutTime.CHECK_OUT_TIME;
    startDateTime = `${parseDate(_startDate)?.fmYYYYMMDD()} ${checkInTime}`;
    if (_endDate) {
        if (parseTime(checkInTime)?.isAfter(parseTime(checkOutTime))) {
            endDateTime = `${parseDate(_endDate)
                .add(1, 'day')
                .fmYYYYMMDD()} ${checkOutTime}`;
        } else {
            endDateTime = `${parseDate(_endDate)?.fmYYYYMMDD()} ${checkOutTime}`;
        }
    }
    return {
        startDate: parseDate(startDateTime)?.fmYYYYMMDDHHmmss('-'),
        endDate: _endDate
            ? parseDate(endDateTime).tz(DEFAULT_TIME_ZONE, true)?.fmYYYYMMDDHHmmss('-')
            : customDayjs().tz(DEFAULT_TIME_ZONE, true)?.fmYYYYMMDDHHmmss('-'),
    };
};

export const getStartDateAndEndDateByDay = (
    _startDate: string | Date,
    _endDate: string | Date | null,
) => {
    return {
        startDate: parseDate(_startDate)?.startOf('day')?.fmYYYYMMDDHHmmss('-'),
        endDate: _endDate
            ? parseDate(_endDate)?.endOf('day')?.fmYYYYMMDDHHmmss('-')
            : customDayjs().endOf('day')?.fmYYYYMMDDHHmmss('-'),
    };
};

export const mapInventoryGridItem = (inventoryList: IInventory[]) => {
    const result: IInventoryForm[] = [];
    inventoryList.forEach((roomTypeData) => {
        const roomTypeName = Object.keys(roomTypeData)[0];
        const _day: Record<string, IInventoryFormItem> = {};
        let roomTypeId;
        roomTypeData[roomTypeName].forEach((data) => {
            roomTypeId = data.roomTypeId;
            const mappedDate = parseDate(data.day)?.fmYYYYMMDD();
            _day[mappedDate] = {
                availableRooms: data?.availableRooms,
                stoppingRooms: data?.stoppingRooms,
                totalAvailableRooms: data?.stoppingRooms + data?.availableRooms,
            };
        });
        result.push({
            roomType: {
                id: roomTypeId,
                name: roomTypeName,
            },
            day: {
                ..._day,
            },
        });
    });
    return result;
};

export const groupByStoppingRoomItems = (
    groupRoomByDate: Record<string, Record<string, { roomId: number }[]>>,
) => {
    const formData = Object.entries(groupRoomByDate).flatMap((item) => {
        const roomTypeId = +item[0].split('_')[1];

        return Object.entries(item[1]).reduce((acc, i) => {
            const indexOfTargetStoppingInventoryItem = acc?.findIndex(
                (stoppingRoomItem) => {
                    return (
                        dayjs(stoppingRoomItem.endDate)
                            .add(1, 'day')
                            .format('YYYY-MM-DD') === dayjs(i[0]).format('YYYY-MM-DD') &&
                        isEqual(
                            stoppingRoomItem?.stoppingInventoryItems?.sort((a, b) =>
                                a.roomId > b.roomId ? 1 : -1,
                            ),
                            i[1].sort((a, b) => (a.roomId > b.roomId ? 1 : -1)),
                        )
                    );
                },
            );

            if (indexOfTargetStoppingInventoryItem >= 0) {
                acc[indexOfTargetStoppingInventoryItem] = {
                    ...acc[indexOfTargetStoppingInventoryItem],
                    endDate: dayjs(acc[indexOfTargetStoppingInventoryItem].endDate)
                        .add(1, 'day')
                        .format('YYYY-MM-DD'),
                };

                return acc;
            }

            const result = {
                startDate: i[0],
                endDate: i[0],
                roomTypeId: roomTypeId,
                stoppingInventoryItems: i[1],
                stoppingRoomCount: i[1].length,
                type: 'room_type',
            };

            acc.push(result);

            return acc;
        }, [] as IStoppingRoomItem[]);
    });

    return formData;
};
