export const bookingPageJa = {
    reservation: {
        title: '新規予約の概要',
        contentTitle: 'ゲスト情報入力',
        guestInfo: 'ゲスト情報',
        memo: 'メモ',
        totalNumberOfReservations: '総予約数',
        totalIncludeTax: '合計 (税込み)',
        represent: '(代表)',
        yomigana: 'タナカ マダ',
    },
    tab: {
        guserInformation: '基本情報',
        conpanionInformation: '同行者情報',
    },
    guestInfo: {
        infomation: '宿泊者情報',
        representative: '（代表）',
        guestNameYomigana: '宿泊者名前 (ヨミガナ)',
        guestName: '宿泊者名前',
        reserverGuestNameYomigana: '予約者名前 (ヨミガナ)',
        reserverGuestName: '予約者名前',
        phone: '電話番号',
        email: 'メールアドレス',
        whoPay: 'プランを支払い者',
        person: '予約者',
        reservationPeron: '予約者',
        stayInfo: '滞在情報',
        checkIn: 'チェックイン',
        checkOut: 'チェックアウト',
        salesChannel: '販売チャネル',
    },
    bookingStatus: {
        all: '全て',
        checked_in: '滞在',
        not_arrived: '未到着',
        checked_out: '出発',
        cancelled: 'キャンセル',
        booked: '予約しました',
        cancelled_no_show_true: 'キャンセル（ノーショーのみ）',
        cancelled_no_show_false: 'キャンセル（ノーショー以外）',
    },
    bookingCleaningStatus: {
        cleaned: '済',
        uncleaned: '未',
    },
    breadcrumbs: {
        home: 'ホーム',
        schedule: '宿泊予約',
        createBooking: '新規予約の概要',
        updateBooking: '宿泊予約の編集',
        bookingConfirm: '予約確認',
    },
    noShow: {
        label: 'ノーショー',
    },
    splipBookingProtected: {
        reason: '中抜け・部屋移動をするには、重複している予約日を解消してください。',
    },
};
