import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { ITaxState } from '../interfaces';
import { taxService } from '../services/tax.service';
import { ITaxBulkUpdateBody } from './../interfaces';

const initialState: ITaxState = {
    taxList: [],
    showLoading: false,
    taxListDropDownOptions: [],
};

export const getTaxDropdown = createAsyncThunk('tax/getTaxDropdown', async () => {
    return await taxService.getDropdown();
});

export const fetchTaxList = createAsyncThunk('tax/fetchTaxList', async () => {
    return await taxService.getList();
});

export const bulkUpdateTaxList = createAsyncThunk(
    'tax/bulkUpdate',
    async (body: ITaxBulkUpdateBody) => {
        return await taxService.bulkUpdate(body);
    },
);

const taxSlice = createSlice({
    name: 'tax',
    initialState,
    reducers: {
        setTaxList: (state, action) => {
            state.taxList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTaxList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(fetchTaxList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.taxList = action.payload?.data || [];
        });
        builder.addCase(bulkUpdateTaxList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(bulkUpdateTaxList.fulfilled, (state, action) => {
            state.showLoading = false;
        });
        builder.addCase(getTaxDropdown.fulfilled, (state, action) => {
            state.taxListDropDownOptions = (action.payload?.data?.items || []).map(
                (item) =>
                    ({
                        label: item?.name || '',
                        value: item.type,
                    } || []),
            );
        });
    },
});

export const { setTaxList } = taxSlice.actions;

export const taxListSelector = (state: AppState) => {
    return state.tax.taxList;
};

export const showLoadingSelector = (state: AppState) => {
    return state.tax.showLoading;
};

export const taxListDropdownSelector = (state: AppState) => {
    return state.tax.taxListDropDownOptions;
};

export default taxSlice.reducer;
