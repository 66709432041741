const marketingChannelGroupForm = {
    primaryButton: 'Create marketing channel group',
    title: 'Create marketing channel group',
    saleChannelGroupName: 'Marketing channel group name',
    saleChannelGroupNamePlaceholder: 'Markeeting channel group name',
    addition: 'Add',
    submitText: 'Register',
    create: {
        success: 'Create marketing channel groups created successfull',
    },
    update: {
        title: 'Edit marketing channel group',
        marketingChannelGroupName: 'Marketing channel group name',
        marketingChannelGroupNamePlaceholder: '',
        updateSuccessMessage: 'Marketing channel groups have been updated succcessful!',
    },
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
    },
    nameDuplicated: 'Name is duplicated',
    confirm: {
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
        cancelTitle: 'Do you want to cancel?',
    },
};

export default marketingChannelGroupForm;
