export const salesPaymentPageEn = {
    titlePage: 'Sales payment details',
    breadcrumbs: {
        report: 'Report',
        salesPayment: 'Sales payment details',
    },
    filterForm: {
        dateLabel: 'Date:',
        displayButton: 'Display',
    },
    table: {
        item: 'Sales item',
        sales: {
            title: 'Sales',
            roomName: 'Room name',
            guestName: 'Guest name',
            amount: 'Amount',
            totalBalanceAmout: 'Balance',
            autoGeneratedCode: 'Booking ID',
            adult: 'Adults',
            localTax: 'Local Tax',
            bathTax: 'Bath Tax',
        },
        payment: {
            title: 'Payment',
            amount: 'Amount',
            name: 'Cash',
            totalUpaidAmount: 'Unpaid amount',
        },
        totalAmount: 'Total unpaid amount',
        totalPayment: 'Total payment',
        totalSales: 'Total sales (tax included)',
        totalBalanceAmount: 'Total balance',
    },
    exportColumns: {
        autoGeneratedCode: 'Booking ID',
        roomName: 'Room name',
        yomigana: 'Guest name',
        saleName: 'Sales item',
        saleAmount: 'Amount',
        paymentName: 'Sales item',
        paymentAmount: 'Amount',
    },
};
