import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '../../constants';
import { RoomOrderSettings } from '../../components/RoomOrderSettings';

const { Content } = Layout;

const RoomOrderSettingsPage: React.FC = () => {
    const { t } = useTranslation();
    const roomOrderSettingsBreadCrumbs = () => {
        return [
            ...breadcrumbs(),
            {
                text: t('room.roomOrderSettings.breadCrumbs.roomOrderSettings'),
            },
        ];
    };

    return (
        <div className="room-order-settings-page">
            <StaffLayoutHeader
                breadcrumbs={[...roomOrderSettingsBreadCrumbs()]}
                title={t('room.roomOrderSettings.title')}
            />
            <Content>
                <RoomOrderSettings />
            </Content>
        </div>
    );
};

export default RoomOrderSettingsPage;
