import childrenTypeFormJa from './ja/children-type-form.locale';
import childrenTypeListJa from './ja/children-type-list.locale';
import childrenTypeFormEn from './en/children-type-form.locale';
import childrenTypeListEn from './en/children-type-list.locale';

const childrenType = {
    ja: {
        list: childrenTypeListJa,
        form: childrenTypeFormJa,
    },
    en: {
        list: childrenTypeListEn,
        form: childrenTypeFormEn,
    },
};

export default childrenType;
