import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import sumBy from 'lodash/sumBy';
import { ReportTableId, TaxTypes } from '~features/report/constants';
import { parseDate } from '~plugins/dayjs';
import classNames from 'classnames';
import { DateFormat, dayList } from '~common/constants';
import { formatMoney } from '~common/commonFunctions';
import { useAppDispatch } from '~hooks';
import { getReportTax } from '~features/report/reducers/local-tax-detail.reducer';
import { CacheKeys } from '~queries/queries';
import { IReportKutchanTaxDataItems } from '~features/report/interfaces';
import {
    TaxDetailPageContext,
    TaxDetailPageDispatchContext,
} from '../../context/TaxDetailPageContext';
import * as types from '../../context/actionTypes';
import './KutchanTaxTable.scss';

const KutchanTaxTable: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const contextDispatch = useContext(TaxDetailPageDispatchContext);
    const {
        selectedMonth,
        isTableLoading,
        error: errorState,
    } = useContext(TaxDetailPageContext);
    const queryClient = useQueryClient();

    const fetchReportTax = async () => {
        contextDispatch({ type: types.FETCH_TAX_SUMMARY_REQUEST });
        const params = {
            year: parseDate(selectedMonth).year(),
            month: parseDate(selectedMonth).month() + 1,
            type: TaxTypes.KUTCHAN_TAX,
        };
        return await dispatch(getReportTax(params));
    };

    const { data: reportTaxData, error: queryError }: any = useQuery({
        queryKey: [CacheKeys.getReportKutchanTaxKey, selectedMonth],
        queryFn: fetchReportTax,
        enabled: true,
    });

    const getReportTaxData: any = queryClient.getQueryData([
        CacheKeys.getReportKutchanTaxKey,
        selectedMonth,
    ]);

    const initialReportTaxList = useMemo(() => {
        let reportTaxArray: IReportKutchanTaxDataItems[] = [];
        if (
            getReportTaxData &&
            getReportTaxData.payload &&
            getReportTaxData.payload.data
        ) {
            reportTaxArray = getReportTaxData.payload.data.items;
        }
        return reportTaxArray;
    }, [getReportTaxData]);

    const [reportTaxList, setReportTaxList] =
        useState<IReportKutchanTaxDataItems[]>(initialReportTaxList);

    useEffect(() => {
        if (reportTaxData && reportTaxData.payload) {
            const { data, errors } = reportTaxData.payload;
            if (!data || queryError instanceof Error || errors) {
                contextDispatch({
                    type: types.FETCH_TAX_SUMMARY_FAIL,
                    payload: queryError || errors,
                });
                setReportTaxList([]);
            } else {
                contextDispatch({ type: types.FETCH_TAX_SUMMARY_SUCCESS });
                setReportTaxList(data.items);
            }
        }
    }, [reportTaxData]);

    const kutchanTaxReportTableColumns: ColumnsType<any> = [
        {
            title: '',
            dataIndex: '',
            width: '100px',
            className: 'text-center',
            ellipsis: true,
            children: [
                {
                    children: [
                        {
                            title: t('report.localTax.localTaxDetailColumn.day'),
                            className: 'text-center',
                            width: '100px',
                            ellipsis: true,
                            render: (data: IReportKutchanTaxDataItems) => {
                                const { date } = data;
                                const dayOfWeek = parseDate(date).day();
                                return (
                                    <div
                                        className={classNames('', {
                                            saturday: dayOfWeek === 6,
                                            sunday: dayOfWeek === 0,
                                        })}
                                    >
                                        <span>
                                            {parseDate(date).format(DateFormat.DD)}
                                        </span>
                                        <span>
                                            (
                                            {t(
                                                `report.operationStatus.table.day.${dayList[dayOfWeek]}`,
                                            )}
                                            )
                                        </span>
                                    </div>
                                );
                            },
                        },
                    ],
                },
            ],
        },
        {
            title: t('report.localTax.localTaxDetailColumn.taxName'),
            dataIndex: '',
            className: 'text-center',
            width: '180px',
            ellipsis: true,
            children: [
                {
                    title: t('report.localTax.kutchanTaxDetailColumn.kutchanTaxHeader'),
                    className: 'text-center',
                    ellipsis: true,
                    children: [
                        {
                            title: t(
                                'report.localTax.kutchanTaxDetailColumn.numberOfGuests',
                            ),
                            className: 'text-center',
                            render: (data: IReportKutchanTaxDataItems) => {
                                const { taxDetails } = data;
                                const { kutchanTax } = taxDetails;
                                return <span>{kutchanTax.guestCount}</span>;
                            },
                        },
                        {
                            title: t(
                                'report.localTax.kutchanTaxDetailColumn.totalBookingSales',
                            ),
                            className: 'text-right',
                            render: (data: IReportKutchanTaxDataItems) => {
                                const { taxDetails } = data;
                                const { kutchanTax } = taxDetails;
                                return (
                                    <span>
                                        {`${t(
                                            `report.localTax.localTaxDetailColumn.currency`,
                                        )}${formatMoney(kutchanTax.salesAmount)}`}
                                    </span>
                                );
                            },
                        },
                        {
                            title: t(
                                'report.localTax.kutchanTaxDetailColumn.totalTaxableSales',
                            ),
                            className: 'text-right',
                            render: (data: IReportKutchanTaxDataItems) => {
                                const { taxDetails } = data;
                                const { kutchanTax } = taxDetails;
                                return (
                                    <span>
                                        {`${t(
                                            `report.localTax.localTaxDetailColumn.currency`,
                                        )}${formatMoney(kutchanTax.taxableAmount)}`}
                                    </span>
                                );
                            },
                        },
                        {
                            title: t(
                                'report.localTax.kutchanTaxDetailColumn.totalTaxAmount',
                            ),
                            className: 'text-right',
                            render: (data: IReportKutchanTaxDataItems) => {
                                const { taxDetails } = data;
                                const { kutchanTax } = taxDetails;
                                return (
                                    <span>
                                        {`${t(
                                            `report.localTax.localTaxDetailColumn.currency`,
                                        )}${formatMoney(kutchanTax.taxAmount)}`}
                                    </span>
                                );
                            },
                        },
                    ],
                },
            ],
        },
    ];

    return (
        <Table
            columns={kutchanTaxReportTableColumns}
            dataSource={errorState ? [] : reportTaxList}
            bordered
            loading={isTableLoading}
            pagination={false}
            rowKey="id"
            className="report-kutchan-tax-detail-table"
            id={ReportTableId.KUTCHAN_LOCAL_TAX}
            summary={(data) => {
                return (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} className="text-center">
                            {t('report.localTax.kutchanTaxDetailColumn.totalKutchanTax')}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} className="text-center">
                            {sumBy(data, 'taxDetails.kutchanTax.guestCount')}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="text-right">
                            {`${t(
                                'report.localTax.localTaxDetailColumn.currency',
                            )}${formatMoney(
                                sumBy(data, 'taxDetails.kutchanTax.salesAmount'),
                            )}`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} className="text-right">
                            {`${t(
                                'report.localTax.localTaxDetailColumn.currency',
                            )}${formatMoney(
                                sumBy(data, 'taxDetails.kutchanTax.taxableAmount'),
                            )}`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4} className="text-right">
                            {`${t(
                                'report.localTax.localTaxDetailColumn.currency',
                            )}${formatMoney(
                                sumBy(data, 'taxDetails.kutchanTax.taxAmount'),
                            )}`}
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
        />
    );
};

export default KutchanTaxTable;
