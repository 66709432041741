import type { CSSProperties, FC } from 'react';
import { useDrop } from 'react-dnd';

const style: CSSProperties = {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
};

type Props = {
    x: number;
    y: number;
    roomId: number;
};

export const DropBox: FC<Props> = ({ x, y, roomId }) => {
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: 'box',
            drop: () => ({ x, y, roomId }),
            collect: (monitor: any) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [],
    );

    return (
        <div
            ref={drop}
            style={{
                ...style,
                border: isOver ? '1px solid #00a0c3' : 'none',
            }}
        ></div>
    );
};
