const childrenTypeList = {
    title: 'Children Type Setting',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front Setting',
        childrenType: 'Children Type Setting',
    },
    filterForm: {
        buttons: {
            create: 'Children Type Registration',
        },
    },
    yen: '￥{{value}}',
    columns: {
        id: 'ID',
        name: 'Children Type Name',
        priceValue: 'Price per adult',
        linkageCode: 'Children Type Linkage Code',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Children Type Name',
        linkageCode: 'Children Type Linkage Code',
    },
    pricePerAdult: 'Price per adult {{value}}%',
    delete: {
        confirm: 'Do you want to delete this children type?',
        siteControllerError: 'Cannot delete children types for site controller',
        success: 'Children type has been deleted',
        failed: 'Failed to delete children type',
    },
};

export default childrenTypeList;
