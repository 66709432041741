import { parseDate, todayDayjs } from '~plugins/dayjs';
import i18next, { t } from 'i18next';
import { Content, TDocumentDefinitions, PageSize } from 'pdfmake/interfaces';
import localStorageAuthService from '~common/authStorage';
import { makeRowWithCellSpan } from '~common/commonFunctions';
import { PrintingOrientation, PrintingPaperSize } from '~common/constants';
import { IAccommodationCardTable } from './interfaces';
import { ICountryResponse } from '~features/guest/interfaces';
import { IFacilityBooking, IGuest } from '~features/facility-booking/interfaces';

interface IOptions {
    orientation: PrintingOrientation;
    setTotalPage: (totalPage: number) => void;
    countryList: ICountryResponse[];
    paperSize: PrintingPaperSize;
}

export const generateFacilityookingPdf = (
    facilityBookingDetailsList: IFacilityBooking[],
    options: IOptions,
): TDocumentDefinitions => {
    const {
        orientation = PrintingOrientation.PORTRAIT,
        setTotalPage,
        countryList,
        paperSize = PrintingPaperSize.A4,
    } = options;

    const isPortrait = orientation === PrintingOrientation.PORTRAIT;

    let content: Content = facilityBookingDetailsList.length
        ? []
        : (generateFacilityBookingContent(isPortrait, false) as Content[]);

    for (let i = 0, n = facilityBookingDetailsList.length - 1; i <= n; i++) {
        const facilityBookingDetail = facilityBookingDetailsList[i];
        const { guest } = facilityBookingDetail;
        content = content.concat(
            generateFacilityBookingContent(
                isPortrait,
                i !== n,
                facilityBookingDetail,
                guest,
                countryList,
            ) as Content,
        );
    }

    return {
        pageSize: paperSize.toUpperCase() as PageSize,
        pageOrientation: orientation,
        header: () => ({
            text: todayDayjs.fmYYYYMMDD('/'),
            style: 'pageHeader',
            margin: [40, 20, 0, 0],
        }),
        footer: (currentPage, pageCount) => {
            setTotalPage(pageCount);

            return {
                text: `${currentPage}/${pageCount}`,
                style: 'pageFooter',
                alignment: 'right',
                margin: [0, 0, 30, 0],
            };
        },
        content,
        styles: {
            pageHeader: {
                fontSize: 10,
            },
            header: {
                fontSize: isPortrait ? 18 : 14,
                bold: true,
            },
            subheader: {
                fontSize: 10,
            },
            tableInvoice: {
                fontSize: 10,
            },
            bookingName: {
                fontSize: 14,
                bold: true,
            },
        },
    };
};

const generateFacilityBookingContent = (
    isPortrait: boolean,
    pageBreak?: boolean,
    facilityBookingDetail?: IFacilityBooking,
    guest?: IGuest,
    countryList: ICountryResponse[] = [],
) => {
    const NUMBER_OF_COLUMNS = 20;
    const hotel = localStorageAuthService.getSelectedHotel();
    const facilityBookingContent: IAccommodationCardTable = {
        title: i18next.t('facilityBooking.printing.registrationCard'),
        companyInfo: {
            name: hotel?.name || '',
            address: hotel?.address || '',
            phone: hotel?.picPhoneNumber || '',
        },
    };

    const getCountryById = (countryId: number) => {
        return countryList.find((country) => country.id === countryId);
    };

    return [
        {
            columns: [
                {
                    text: facilityBookingContent.title,
                    style: 'header',
                    width: '50%',
                    alignment: 'left',
                },
                {
                    text: facilityBookingContent.companyInfo.name,
                    style: 'header',
                    width: '50%',
                    alignment: 'right',
                },
            ],
            margin: [0, 5],
        },
        {
            columns: [
                {
                    columns: [
                        {
                            text: `${i18next.t('facilityBooking.printing.checkIn')}:`,
                        },
                        facilityBookingDetail && {
                            text: parseDate(
                                facilityBookingDetail.startDatetime,
                            )?.fmYYYYMMDD('/'),
                        },
                    ],
                    alignment: 'left',
                },
                {
                    text: facilityBookingContent.companyInfo.address,
                    width: '50%',
                    alignment: 'right',
                },
            ],
            style: 'subheader',
            margin: [0, 5],
        },
        {
            columns: [
                {
                    columns: [
                        {
                            text: `${i18next.t('facilityBooking.printing.checkOut')}:`,
                        },
                        facilityBookingDetail && {
                            text: parseDate(
                                facilityBookingDetail.endDatetime,
                            )?.fmYYYYMMDD('/'),
                        },
                    ],

                    alignment: 'left',
                },
                {
                    text: facilityBookingContent.companyInfo.phone,
                    width: '50%',
                    alignment: 'right',
                },
            ],
            style: 'subheader',
            margin: [0, 0, 0, isPortrait ? 25 : 5],
        },
        {
            style: 'tableInvoice',
            margin: [0, 10],
            table: {
                // Split the table into NUMBER_OF_COLUMNS column
                // Incase text is too long, it is wrapped
                widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                    .map(() => `${100 / NUMBER_OF_COLUMNS}%`)
                    .concat('*'),
                body: [
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.2,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.yomigana',
                                    )}`,
                                    margin: [0, 5],
                                },
                                {
                                    text: `${i18next.t('facilityBooking.printing.name')}`,
                                    margin: [0, 5],
                                },
                            ],
                        },
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: guest?.yomigana ?? '\n',
                                    margin: [0, 5],
                                },
                                {
                                    canvas: [
                                        {
                                            type: 'line',
                                            x1: 0,
                                            y1: 0,
                                            x2: isPortrait ? 130 : 200,
                                            y2: 0,
                                            dash: { length: 3, space: 3 },
                                        },
                                    ],
                                },
                                {
                                    text: guest?.fullName ?? '\n',
                                    margin: [0, 5],
                                },
                            ],
                        },
                        {
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.dateOfBirth',
                                    )}`,
                                    margin: [0, 5],
                                },
                                {
                                    alignment: 'center',
                                    columns: [
                                        {
                                            text: guest?.birthday
                                                ? `${parseDate(guest.birthday).format(
                                                      'YYYY',
                                                  )}`
                                                : '',
                                        },
                                        {
                                            text: '/',
                                        },
                                        {
                                            text: guest?.birthday
                                                ? `${parseDate(guest.birthday).format(
                                                      'MM',
                                                  )}`
                                                : '',
                                        },
                                        {
                                            text: '/',
                                        },
                                        {
                                            text: guest?.birthday
                                                ? `${parseDate(guest.birthday).format(
                                                      'DD',
                                                  )}`
                                                : '',
                                        },
                                    ],
                                    margin: [0, 5],
                                },
                            ],
                            colSpan: NUMBER_OF_COLUMNS * 0.35,
                        },
                        {
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.gender',
                                    )}`,
                                    margin: [0, 5],
                                },
                                {
                                    text: guest?.gender
                                        ? `${t(
                                              `facilityBooking.printing.guestGender.${guest.gender}`,
                                          )}`
                                        : '\n',
                                    margin: [0, 5],
                                },
                            ],
                            colSpan: NUMBER_OF_COLUMNS * 0.15,
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.homeAddress',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: guest?.address ?? '\n',
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.35
                                : NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.contactNumber',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: guest?.mobilePhoneNumber ?? '\n',
                                },
                            ],
                        },
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.35
                                : NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.emailAddress',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: guest?.emailAddress ?? '\n',
                                    marginBottom: -100,
                                    lineHeight: 0.9,
                                },
                            ],
                        },
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.3
                                : NUMBER_OF_COLUMNS * 0.4,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.occupation',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: guest?.occupation ?? '\n',
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.35
                                : NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.companyName',
                                    )}`,
                                },
                                {
                                    text:
                                        (guest?.companyName || guest?.companyYomigana) ??
                                        '\n',
                                },
                            ],
                        },
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.35
                                : NUMBER_OF_COLUMNS * 0.3,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.companyPhoneNumber',
                                        { break: isPortrait ? '\n' : '' },
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: guest?.companyPhoneNumber ?? '\n',
                                },
                            ],
                        },
                        {
                            colSpan: isPortrait
                                ? NUMBER_OF_COLUMNS * 0.3
                                : NUMBER_OF_COLUMNS * 0.4,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.companyEmailAddress',
                                        { break: isPortrait ? '\n' : '' },
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: guest?.companyEmailAddress ?? '\n',
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.5,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.passportNo',
                                    )}`,
                                    margin: [0, 0, 0, 18],
                                },
                            ],
                        },
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.5,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.nationality',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: guest?.countryId
                                        ? getCountryById(guest.countryId)?.nameJp ?? '\n'
                                        : '\n',
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.5,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.previousPlaceOfStay',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text:
                                        facilityBookingContent?.previousPlaceOfStay ??
                                        '\n',
                                },
                            ],
                        },
                        {
                            colSpan: NUMBER_OF_COLUMNS * 0.5,
                            stack: [
                                {
                                    text: `${i18next.t(
                                        'facilityBooking.printing.nextPlaceOfStay',
                                    )}`,
                                    margin: [0, 0, 0, 5],
                                },
                                {
                                    text: facilityBookingContent?.nextPlaceOfStay ?? '\n',
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: NUMBER_OF_COLUMNS,
                            stack: [
                                {
                                    text: `${i18next.t('facilityBooking.printing.memo')}`,
                                    margin: [0, 0, 0, 18],
                                },
                            ],
                            rowSpan: isPortrait ? 5 : 2,
                        },
                    ]),
                    ...Array.from(Array(isPortrait ? 4 : 1).keys()).map(() => {
                        return makeRowWithCellSpan([
                            {
                                colSpan: NUMBER_OF_COLUMNS,
                                stack: [{}],
                            },
                        ]);
                    }),
                ],
            },
            layout: {
                paddingTop: (rowIndex: number) => {
                    return rowIndex === 0 ? 0 : isPortrait ? 10 : 5;
                },
                paddingBottom: (rowIndex: number) => {
                    return rowIndex === 0 ? 0 : 10;
                },
                paddingLeft: (rowIndex: number) => {
                    return 10;
                },
                paddingRight: (rowIndex: number) => {
                    return 10;
                },
            },
        },
        {
            columns: [
                {
                    margin: [0, isPortrait ? 20 : 10],
                    style: 'tableInvoice',
                    table: {
                        widths: [150],
                        body: [
                            [
                                {
                                    stack: [
                                        {
                                            text: `${i18next.t(
                                                'facilityBooking.printing.facilityNumber',
                                            )}`,
                                            margin: [0, 5, 0, 10],
                                            alignment: 'center',
                                        },
                                        {
                                            alignment: 'center',
                                            text:
                                                facilityBookingDetail?.facility?.name ??
                                                '\n',
                                            margin: [0, 0, 0, 10],
                                            style: 'bookingName',
                                        },
                                    ],
                                },
                            ],
                        ],
                        layout: {
                            paddingLeft: (rowIndex: number) => {
                                return 10;
                            },
                        },
                    },
                },
                {
                    stack: [
                        {
                            text: `${i18next.t('facilityBooking.printing.signature')}`,
                            margin: [0, isPortrait ? 20 : 15, 0, 30],
                        },
                        {
                            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 250, y2: 0 }],
                        },
                    ],
                    ...(pageBreak && { pageBreak: 'after' }),
                },
            ],
        },
    ];
};
