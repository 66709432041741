const taxList = {
    title: '税金設定',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        tax: '税金設定',
    },
    header: {
        title: '宿泊税金・入湯税などの税設定を行ってください',
        button: {
            save: '保存',
        },
    },
    columns: {
        name: '税金タイプ',
        isActive: '使用する',
        displayName: '表示名',
    },
    taxType: {
        bathTax1: '入湯税(その他)',
        bathTax2: '入湯税(大人)',
        bathTax3: '入湯税(子供)',
        bathTax4: '入湯税(日帰り)',
        bathTax5: '宿泊税(京都0)',
        bathTax6: '宿泊税(京都20000)',
        bathTax7: '宿泊税(京都50000)',
        bathTax8: '宿泊税(大阪15000)',
        bathTax9: '宿泊税(大阪20000)',
        bathTax10: '宿泊税(大阪7000)',
        bathTax11: '宿泊税(東京10000)',
        bathTax12: '宿泊税(東京15000)',
        bathTax13: '宿泊税(福岡市0)',
        bathTax14: '宿泊税(福岡市20000)',
        bathTax15: '宿泊税(福岡県)',
        bathTax16: '宿泊税(金沢0)',
        bathTax17: '宿泊税(金沢20000)',
        localTax1: '宿泊税(東京10000)',
        localTax2: '宿泊税(東京15000)',
        tax1: '消費税(課税10%)',
        tax2: '消費税(課税8%)',
        tax3: '消費税(非課税)',
        kutchanTax: '宿泊税(北海道 虻田郡俱知安町)',
    },
    taxTypeDescription: {
        bathTax1: '※ 明細に項目が追加されますが、金額は手動で入力してください',
        bathTax2: '※ 自動で150円が明細に追加されます',
        bathTax3: '※ 明細に項目が追加されますが、金額は手動で入力してください',
        bathTax4: '※ 自動で75円がデイユース予約の明細に追加されます',
        bathTax15: '※ 北九州市を含む、福岡市以外はこちらを選択してください',
        bathTax16: '※ 2019/04/01以降の宿泊が対象',
        bathTax17: '※ 2019/04/01以降の宿泊が対象',
    },
    update: {
        nameDuplicated: '名称が重複されています',
        success: '税設定が更新されました。',
        failed: '税設定の更新が失敗しました。',
    },
};

export default taxList;
