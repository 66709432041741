import React from 'react';
import NotificationFormWrapper from '~features/admin-notification/components/NotificationFormWrapper/NotificationFormWrapper';
import './CreateNotificationPage.scss';

function CreateNotificationPage() {
    return (
        <div className="create-notification-page">
            <NotificationFormWrapper />
        </div>
    );
}

export default CreateNotificationPage;
