import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { parseDateTime } from '~common/commonFunctions';
import { DateFormat } from '~common/constants';
import {
    countryListlSelector,
    getCountryList,
    selectedGuestDetailSelector,
} from '~features/guest/reducers/guest.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './GuestDetailBasicInfo.scss';
import { useEffect } from 'react';
import { guestClassificationDropdownOptionsSelector } from '~features/guest-classification/guest-classification.reducer';
import { PaperClipOutlined } from '@ant-design/icons';
import { fileService } from '~features/file/file.service';

function GuestDetailBasicInfo() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedGuestDetail = useAppSelector(selectedGuestDetailSelector);
    const countryList = useAppSelector(countryListlSelector);
    const classificationList = useAppSelector(guestClassificationDropdownOptionsSelector);

    const country = countryList.find((country) => {
        return country.id === selectedGuestDetail?.countryId;
    });

    const handleDownloadFile = async (file: any) => {
        const url = await fileService.getPresignedUrl(file.id);
        window.open(url.data);
    };

    useEffect(() => {
        dispatch(getCountryList());
    }, []);
    return (
        <div className="guest-detail-basic-info">
            <div className="guest-detail-basic-info-content">
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.fullName')}
                    </Col>
                    <Col span={5}>{selectedGuestDetail?.fullName}</Col>
                    <Col span={3} className="guest-detail-label">
                        {t('guest.detail.label.yomigana')}
                    </Col>
                    <Col span={12}>{selectedGuestDetail?.yomigana}</Col>
                </Row>
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.mobilePhoneNumber')}
                    </Col>
                    <Col span={5}>
                        {selectedGuestDetail?.mobilePhoneNumber ||
                            selectedGuestDetail?.phoneNumberLandline ||
                            ''}
                    </Col>
                    <Col span={3} className="guest-detail-label">
                        {t('guest.detail.label.emailAddress')}
                    </Col>
                    <Col span={5}>{selectedGuestDetail?.emailAddress}</Col>
                    <Col span={2} className="guest-detail-label">
                        {t('guest.detail.label.gender')}
                    </Col>
                    <Col span={5}>
                        {selectedGuestDetail?.gender
                            ? t(`guest.detail.gender.${selectedGuestDetail?.gender}`)
                            : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.country')}
                    </Col>
                    <Col span={5}>{country?.nameJp}</Col>
                    <Col span={3} className="guest-detail-label">
                        {t('guest.detail.label.postalCode')}
                    </Col>
                    <Col span={5}>{selectedGuestDetail?.postalCode}</Col>
                    <Col span={2} className="guest-detail-label">
                        {t('guest.detail.label.address')}
                    </Col>
                    <Col span={5}>{selectedGuestDetail?.address}</Col>
                </Row>
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.classification')}
                    </Col>
                    <Col span={5}>
                        {
                            classificationList.find((classification) => {
                                return (
                                    classification.id ===
                                    selectedGuestDetail?.classificationId
                                );
                            })?.name
                        }
                    </Col>
                    <Col span={3} className="guest-detail-label">
                        {t('guest.detail.label.birthday')}
                    </Col>
                    <Col span={12}>
                        {selectedGuestDetail?.birthday
                            ? parseDateTime(
                                  selectedGuestDetail?.birthday,
                                  DateFormat.YYYY_MM_DD_SLASH,
                              )
                            : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.memo')}
                    </Col>
                    <Col span={20}>{selectedGuestDetail?.memo}</Col>
                </Row>
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.identificationCard')}
                    </Col>
                    <Col span={20}>
                        {selectedGuestDetail?.filesInformation?.map((file) => {
                            return (
                                <Button
                                    key={file.id}
                                    type="link"
                                    onClick={() => handleDownloadFile(file)}
                                    icon={<PaperClipOutlined />}
                                    className="attachment-link"
                                >
                                    <span className="text-truncate">
                                        {file.originalName}
                                    </span>
                                </Button>
                            );
                        })}
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default GuestDetailBasicInfo;
