const csvBookingUpload = {
    title: 'CSV Booking Migration',
    breadcrumbs: {
        backOffice: 'Back Office Setting',
        csvBookingUpload: 'CSV Booking Migration',
    },
    buttons: {
        uploadFile: 'ファイルを選択',
        submit: '実行',
    },
    notifications: {
        success: 'CSV予約移行は成功しました',
    },
    statusMessages: {
        notCSVFileType: 'CSV以外のファイルタイプを登録できません',
        generalError: 'エラーが発生しました',
        successStatus: 'CSV予約移行は成功しました。',
        successStatusSubtext: '登録した件数：{{numberOfItems}}件',
    },
    errorMessages: {
        bookingAlreadyExist: 'This booking already exists within the PMS',
        invalidOTACode: 'The inputted OTA code is invalid',
        invalidPlanName: 'The inputted plan name is invalid',
        invalidRoomType: 'The inputted room type is invalid',
        invalidReserver: 'The inputted reserver information is invalid',
        invalidGuest: 'The inputted guest information is invalid',
        invalidAmount: 'The inputted price is invalid',
        invalidCheckInTime: 'The inputted check-in time is invalid',
        invalidStatus: 'The inputted booking status is invalid',
        invalidNumberOfDays: 'The inputted period of stay is invalid',
        invalidMailAddress: 'The inputted mail address is invalid',
        paymentMethodNotFound: 'The inputted payment method is not found within the PMS',
        fileNotUploaded: 'No file found',
        loggedInHotelIdIsDifferentFromCSVHotelId:
            'The hotel ID in the uploaded CSV is different from the hotel currently in use',
        maxLineLimitExceeded: 'The uploaded file exceeds the maximum row limit',
        noContentFound: 'No content was found in the uploaded file',
        // CSV Parse Errors
        tabistHotelIdEmpty: 'tabist_hotel_id should not be empty',
        groupBookingIdEmpty: 'group_booking_id should not be empty',
        reservationNameEmpty: 'reservation_name should not be empty',
        reservationFuriganaEmpty: 'reservation_person_furigana should not be empty',
        planNameEmpty: 'plan_name should not be empty',
        reservationPhoneNumberEmpty: 'reservation_phone_number should not be empty',
        roomTypeEmpty: 'room_type should not be empty',
        numberOfPeopleEmpty:
            'number_of_people must be a number conforming to the specified constraints',
        reservationDateInvalid: 'reservation_date must be a Date instance',
        checkInDateInvalid: 'check_in_date must be a Date instance',
        checkOutDateInvalid: 'check_out_date must be a Date instance',
        totalAmountInvalid:
            'total_amount must be a number conforming to the specified constraints',
        advanceAmountInvalid:
            'advance_deposit_amount must be a number conforming to the specified constraints',
        pointsInvalid:
            'amount_of_points_used must be a number conforming to the specified constraints',
        checkInTimeEmpty: 'check_in_time should not be empty',
        statusEmpty: 'status should not be empty',
        bookingCreationFailed: 'Booking creation failed',
    },
};

export default csvBookingUpload;
