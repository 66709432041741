import React, { useCallback, useEffect, useState } from 'react';
import { Spin, notification } from 'antd';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import queryClient, { CacheKeys, clearCache } from '~queries/queries';
import HeaderComponent from './HeaderComponent/HeaderComponent';
import RoomTypesDraggableComponent from './RoomTypesDraggableComponent/RoomTypesDraggableComponent';
import { RoomOrderSettingsSortOptions } from '../../constants';
import { IBookingStaticType } from '../../../room-booking/interfaces';
import { useGetRoomTypeListQuery } from '../../hooks/useGetRoomListQuery';
import { useUpdateRoomTypeOrderSettings } from '../../../room/hooks/useUpdateRoomTypeOrderSettings';
import { useUpdateRoomOrderSettings } from '../../../room/hooks/useUpdateRoomOrderSettings';
import { IRoomDisplayOrderBody } from '../../../room/interfaces';
import './RoomOrderSettings.scss';

const RoomOrderSettings: React.FC = () => {
    const { t } = useTranslation();
    const { data: roomTypeListData, isPending } = useGetRoomTypeListQuery();
    const { isPending: isMutationPending, mutate: mutateRoomTypeOrder } =
        useUpdateRoomTypeOrderSettings();
    const { isPending: isMutationUpdateRoomOrderPending, mutate: mutateRoomOrder } =
        useUpdateRoomOrderSettings();
    const [roomTypeList, setRoomTypeList] = useState<IBookingStaticType[]>([]);
    const [selectedSortOption, setSelectedSortOption] = useState<'asc' | 'desc'>('asc');
    const [activeKeys, setActiveKeys] = useState<string | string[]>([]);

    useEffect(() => {
        if (roomTypeListData) {
            const { items } = roomTypeListData;
            const sortedRoomTypeList = orderBy(
                items,
                ['displayOrder'],
                [selectedSortOption],
            );
            setRoomTypeList(sortedRoomTypeList);
        }
    }, [roomTypeListData]);

    useEffect(() => {
        return () => {
            clearCache([CacheKeys.getRoomList]);
        };
    }, []);

    const handleChangeSort = (sortOption: RoomOrderSettingsSortOptions) => {
        const sortDirection =
            sortOption === RoomOrderSettingsSortOptions.ASCENDING ? 'asc' : 'desc';
        const sortedRoomTypeList = orderBy(
            roomTypeList,
            ['displayOrder'],
            [sortDirection],
        );
        setSelectedSortOption(sortDirection);
        setRoomTypeList(sortedRoomTypeList);
    };

    const handleClickExpandAll = () => {
        const roomTypeDetailsIds = roomTypeList.map((roomTypeDetails) =>
            roomTypeDetails.id.toString(),
        );
        setActiveKeys(roomTypeDetailsIds);
    };

    const handleClickCollapseAll = () => {
        setActiveKeys([]);
    };

    const handleMutateRoomTypeOrder = useCallback(
        (body: IRoomDisplayOrderBody, updatedRoomTypeList: IBookingStaticType[]) => {
            mutateRoomTypeOrder(body, {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: [CacheKeys.getRoomList],
                    });
                    notification.success({
                        message: t('room.roomOrderSettings.notification.success'),
                    });
                    setRoomTypeList(updatedRoomTypeList);
                },
                onError: (error) => {
                    notification.error({
                        message: t('common.somethingWentWrong'),
                        description: `${error}`,
                    });
                    setRoomTypeList(roomTypeList);
                },
            });
        },
        [],
    );

    const handleMutateRoomOrder = useCallback(
        (body: IRoomDisplayOrderBody, updatedRoomTypeList: IBookingStaticType[]) => {
            mutateRoomOrder(body, {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: [CacheKeys.getRoomList],
                    });
                    notification.success({
                        message: t('room.roomOrderSettings.notification.success'),
                    });
                    setRoomTypeList(updatedRoomTypeList);
                },
                onError: (error) => {
                    notification.error({
                        message: t('common.somethingWentWrong'),
                        description: `${error}`,
                    });
                    setRoomTypeList(roomTypeList);
                },
            });
        },
        [],
    );

    return (
        <div className="room-order-settings-page_wrapper">
            <Spin
                spinning={
                    isPending || isMutationPending || isMutationUpdateRoomOrderPending
                }
            >
                <HeaderComponent
                    handleClickExpandAll={handleClickExpandAll}
                    handleClickCollapseAll={handleClickCollapseAll}
                    handleChangeSort={handleChangeSort}
                />
                <RoomTypesDraggableComponent
                    roomTypeList={roomTypeList}
                    activeKeys={activeKeys}
                    setActiveKeys={setActiveKeys}
                    handleMutateRoomTypeOrder={handleMutateRoomTypeOrder}
                    handleMutateRoomOrder={handleMutateRoomOrder}
                />
            </Spin>
        </div>
    );
};

export default RoomOrderSettings;
