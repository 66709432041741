import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ScheduleViewMode } from '~common/constants';
import { getDayColumns, getHoursColumns } from '~features/room-booking/helper';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { AppState } from '~plugins/redux-toolkit/store';
import { FacilityBookingStatus } from '../constants';
import { buildStatisticsByDate, parseBookingList, parseFacilityList } from '../helper';
import { IAddBookingPayload, IFacilityBookingScheduleState } from '../interfaces';
import { facilityBookingService } from '../services/facility-booking.service';
import {
    IBookingsResponse,
    IFacilityBookingCheckIn,
    IFacilityBookingList,
    IFacilityBookingSchedule,
    IFacilityBookingStatisticItem,
} from './../interfaces';

const initialState: IFacilityBookingScheduleState = {
    bookingGroupById: {},
    facilityList: [],
    bookingList: {},
    statisticsByDate: {},
    facilityBookingStatistic: [],
    facilityBookingSchedule: {} as IFacilityBookingSchedule,
    currentFacilityGroup: 0,
    numberOfRooms: 1,
    currentViewMode: ScheduleViewMode.WEEK,
    isShowFacilityBookingScheduleListModal: false,
    isShowCreateFacilityBookingForm: false,
    isDraggingBooking: false,
    isFetchingFacilityList: false,
    isFetchingBookingList: false,
    isUpdatingBooking: false,
    currentDragData: null,
    isSelecting: false,
    currentWeek: 1,
    startPosition: null,
    panelId: '',
    currentDate: customDayjs().startOf('week')?.fmYYYYMMDD(),
    currentStatus: FacilityBookingStatus.ALL,
    collapseRooms: [],
    startDateWeekView: customDayjs().subtract(1, 'week').startOf('week')?.fmYYYYMMDD('-'),
    endDateWeekView: customDayjs().add(1, 'week').endOf('week')?.fmYYYYMMDD('-'),
    startDateDayView: customDayjs().subtract(1, 'day').startOf('day')?.fmYYYYMMDD('-'),
    endDateDayView: customDayjs().add(1, 'day').endOf('day')?.fmYYYYMMDD('-'),
    currentEditFacilityBooking: null,
    showCheckInForm: false,
    facilityBookingCheckIn: null,
    showCheckInFormLoading: false,
    defaultFacilityBookingOptions: {},
};

export const fetchFacilityList = createAsyncThunk(
    'facilityBooking/fetchFacilityBookingList',
    async (timePeriod: { startDate?: string; endDate?: string }, { getState }) => {
        const time = timeSelector(getState() as AppState);
        let startDate = time.start.fmYYYYMMDDHHmmss('-');
        let endDate = time.end.fmYYYYMMDDHHmmss('-');
        if (timePeriod.endDate && timePeriod.startDate) {
            startDate = timePeriod.startDate;
            endDate = timePeriod.endDate;
        }
        return await facilityBookingService.getFacilityBookingStatisticType(
            startDate,
            endDate,
        );
    },
);

export const getBookingList = createAsyncThunk(
    'facilityBooking/getBookingList',
    async (date: { start: string; end: string }) => {
        return await facilityBookingService.getBookings(
            parseDate(date.start)?.fmYYYYMMDDHHmmss('-'),
            parseDate(date.end)?.fmYYYYMMDDHHmmss('-'),
        );
    },
);

export const fetchMoreBookingList = createAsyncThunk(
    'facilityBooking/fetchMoreBookingList',
    async (date: { start: string; end: string }) => {
        return await facilityBookingService.getBookings(
            parseDate(date.start)?.fmYYYYMMDDHHmmss('-'),
            parseDate(date.end)?.fmYYYYMMDDHHmmss('-'),
        );
    },
);

export const fetchFacilityBookingStatisticByDate = createAsyncThunk(
    'facilityBooking/getFacilityBookingStatisticByDate',
    async (_, { getState }) => {
        const time = monthSelector(getState() as AppState);
        return await facilityBookingService.getFacilityBookingStatisticByDate(
            time.start.fmYYYYMMDDHHmmss('-'),
            time.end.fmYYYYMMDDHHmmss('-'),
        );
    },
);

export const updateFacilityBookingFacility = createAsyncThunk(
    'facilityBooking/updateFacilityBookingFacility',
    async (payload: { id: number; facilityId: number }) => {
        return await facilityBookingService.updateFacilityBookingFacility(
            payload.id,
            payload.facilityId,
        );
    },
);

export const checkInFacilityBooking = createAsyncThunk(
    'facilityBooking/checkInFacilityBooking',
    async ({ id, body }: { id: number; body: IFacilityBookingCheckIn }) => {
        return await facilityBookingService.checkInFacilityBooking(id, body);
    },
);

const facilityBookingScheduleSlice = createSlice({
    name: 'facility-booking-schedule',
    initialState,
    reducers: {
        setIsDragging: (state, action) => {
            state.isDraggingBooking = action.payload;
        },
        setCurrentDragData: (state, action) => {
            state.currentDragData = action.payload;
        },
        setCurrentFacilityGroup: (state, action) => {
            state.currentFacilityGroup = action.payload;
        },
        setCurrentViewMode: (state, action: PayloadAction<ScheduleViewMode>) => {
            state.currentViewMode = action.payload;
            state.bookingList = {};
            switch (action.payload) {
                case ScheduleViewMode.DAY:
                    state.currentDate = customDayjs().fmYYYYMMDD();
                    break;
                case ScheduleViewMode.WEEK:
                    state.currentDate = customDayjs().startOf('week')?.fmYYYYMMDD();
                    break;
                case ScheduleViewMode.MONTH:
                    state.currentDate = customDayjs().startOf('month')?.fmYYYYMMDD();
                    break;
            }
        },
        setSelecting: (state, action) => {
            state.isSelecting = action.payload;
        },
        setStartPosition: (state, action) => {
            state.startPosition = action.payload;
        },
        setCurrentWeek: (state, action) => {
            state.currentWeek = action.payload;
        },
        setIsShowCreateFacilityBookingForm: (state, action) => {
            state.isShowCreateFacilityBookingForm = action.payload;
        },
        setCurrentDate: (state, action) => {
            state.currentDate = action.payload;
        },
        setPanelId: (state, action) => {
            state.panelId = action.payload;
        },
        setStartDateWeekView: (state, action) => {
            state.startDateWeekView = action.payload;
        },
        setEndDateDayView: (state, action) => {
            state.endDateDayView = action.payload;
        },
        setEndDateWeekView: (state, action) => {
            state.endDateWeekView = action.payload;
        },
        setStartDateDayView: (state, action) => {
            state.startDateDayView = action.payload;
        },
        setCurrentEditFacilityBooking: (state, action) => {
            state.currentEditFacilityBooking = action.payload;
        },
        setBookingList: (state, action) => {
            state.bookingList = action.payload;
        },
        setFacilityBookingStatistic: (
            state,
            action: PayloadAction<IFacilityBookingStatisticItem[]>,
        ) => {
            state.facilityBookingStatistic = action.payload;
        },
        setFacilityBookingScheduleList: (
            state,
            action: PayloadAction<IFacilityBookingSchedule>,
        ) => {
            state.facilityBookingSchedule = action.payload;
        },
        setCurrentStatus(state, action: PayloadAction<FacilityBookingStatus>) {
            state.currentStatus = action.payload;
        },
        setCollapseRooms(state, action: PayloadAction<number[]>) {
            state.collapseRooms = action.payload;
        },
        updateBooking: (state, action: PayloadAction<IAddBookingPayload>) => {
            const { booking, bookingResponse } = action.payload;
            const facilityId = booking?.facilityId || bookingResponse?.facilityId;
            const facility = state.facilityList.find(
                (item) => item.id === facilityId && item.parentId,
            );
            if (booking) {
                state.bookingGroupById['booking_' + booking.id] = {
                    id: booking.id,
                    facilityId: booking.facilityId,
                    startDatetime: booking.checkInDateTime,
                    endDatetime: booking.checkOutDateTime,
                    status: booking.status,
                    guestId: booking.guest?.id || 0,
                    numberOfGuests: booking.numberOfGuests,
                    guest: booking.guest,
                    facility: {
                        id: facility?.id || 0,
                        name: facility?.name || '',
                    },
                    totalAmount: booking?.totalAmount,
                    roomOfBookings: booking.rooms || [],
                };
            } else if (bookingResponse) {
                state.bookingGroupById['booking_' + bookingResponse.id] = bookingResponse;
            }
            state.bookingList = parseBookingList(state.bookingGroupById, {
                typeView: state.currentViewMode,
            });
            state.statisticsByDate = buildStatisticsByDate(state.bookingList);
        },
        removeBooking(state, action: PayloadAction<number>) {
            delete state.bookingGroupById['booking_' + action.payload];
            state.bookingList = parseBookingList(state.bookingGroupById, {
                typeView: state.currentViewMode,
            });
            state.statisticsByDate = buildStatisticsByDate(state.bookingList);
        },
        setIsShowFacilityBookingScheduleListModal: (state, action) => {
            state.isShowFacilityBookingScheduleListModal = action.payload;
        },
        setShowCheckInForm: (state, action) => {
            state.showCheckInForm = action.payload;
        },
        setFacilityBookingCheckIn: (state, action) => {
            state.facilityBookingCheckIn = action.payload;
        },
        setDefaultFacilityBookingOptions: (state, action) => {
            state.defaultFacilityBookingOptions = action.payload;
        },
        resetWeekViewState: (state) => {
            state.startDateWeekView = customDayjs()
                .subtract(1, 'week')
                .startOf('week')
                ?.fmYYYYMMDD('-');
            state.endDateWeekView = customDayjs()
                .add(1, 'week')
                .endOf('week')
                ?.fmYYYYMMDD('-');
        },
        resetDayViewState: (state) => {
            state.startDateDayView = customDayjs()
                .subtract(1, 'day')
                .startOf('day')
                ?.fmYYYYMMDD('-');
            state.endDateDayView = customDayjs()
                .add(1, 'day')
                .endOf('day')
                ?.fmYYYYMMDD('-');
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFacilityList.pending, (state, action) => {
            state.isFetchingFacilityList = true;
        });
        builder.addCase(fetchMoreBookingList.pending, (state, action) => {
            state.isFetchingBookingList = true;
        });
        builder.addCase(fetchFacilityBookingStatisticByDate.pending, (state, action) => {
            state.isFetchingFacilityList = true;
        });
        builder.addCase(getBookingList.pending, (state, action) => {
            state.isFetchingBookingList = true;
            state.bookingList = {};
        });
        builder.addCase(updateFacilityBookingFacility.pending, (state, action) => {
            state.isUpdatingBooking = true;
        });
        builder.addCase(updateFacilityBookingFacility.fulfilled, (state, action) => {
            state.isUpdatingBooking = false;
        });
        builder.addCase(fetchFacilityList.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.facilityList = parseFacilityList(action.payload?.data || []);
                state.numberOfRooms = action.payload.data?.numberOfFacilities || 1;
            } else {
                state.facilityList = [];
            }
            state.isFetchingFacilityList = false;
        });
        builder.addCase(getBookingList.fulfilled, (state, action) => {
            if (action.payload?.success) {
                const _bookingGroupById: Record<string, IBookingsResponse> = {};
                _.forEach(action.payload?.data || [], (booking) => {
                    _bookingGroupById['booking_' + booking.id] = booking;
                });
                state.bookingGroupById = _bookingGroupById;
                state.bookingList = parseBookingList(state.bookingGroupById, {
                    typeView: state.currentViewMode,
                });
                state.statisticsByDate = buildStatisticsByDate(state.bookingList);
            } else {
                state.bookingList = {};
            }
            state.isFetchingBookingList = false;
        });
        builder.addCase(fetchMoreBookingList.fulfilled, (state, action) => {
            if (action.payload?.success) {
                if (action.payload?.data?.length > 0) {
                    _.forEach(action.payload?.data, (booking) => {
                        state.bookingGroupById['booking_' + booking.id] = booking;
                    });
                    state.bookingList = parseBookingList(state.bookingGroupById, {
                        typeView: state.currentViewMode,
                    });
                    state.statisticsByDate = buildStatisticsByDate(state.bookingList);
                }
            }
            state.isFetchingBookingList = false;
        });

        builder.addCase(
            fetchFacilityBookingStatisticByDate.fulfilled,
            (state, action) => {
                if (action.payload?.success) {
                    state.facilityBookingStatistic = action.payload?.data?.items || [];
                } else {
                    state.facilityBookingStatistic = [];
                }
                state.isFetchingFacilityList = false;
            },
        );
        builder.addCase(checkInFacilityBooking.pending, (state, action) => {
            state.showCheckInFormLoading = true;
        });
        builder.addCase(checkInFacilityBooking.fulfilled, (state, action) => {
            state.showCheckInFormLoading = false;
        });
    },
});

export const {
    setIsDragging,
    setCurrentDragData,
    setCurrentFacilityGroup,
    setCurrentViewMode,
    setSelecting,
    setStartPosition,
    setCurrentWeek,
    setCurrentDate,
    setCollapseRooms,
    setPanelId,
    updateBooking,
    setIsShowFacilityBookingScheduleListModal,
    setFacilityBookingScheduleList,
    setCurrentEditFacilityBooking,
    setEndDateDayView,
    setEndDateWeekView,
    setStartDateWeekView,
    setStartDateDayView,
    setBookingList,
    removeBooking,
    setCurrentStatus,
    setIsShowCreateFacilityBookingForm,
    setShowCheckInForm,
    setFacilityBookingCheckIn,
    setDefaultFacilityBookingOptions,
    resetWeekViewState,
    resetDayViewState,
} = facilityBookingScheduleSlice.actions;

export const facilityBookingStateSelector = (state: AppState) =>
    state.facilityBookingSchedule;

export const weekColumnsSelector = (state: AppState) => {
    const { startDateWeekView, endDateWeekView } = state.facilityBookingSchedule;
    return getDayColumns(startDateWeekView, endDateWeekView);
};

export const daysColumnsSelector = (state: AppState) => {
    const { startDateDayView, endDateDayView } = state.facilityBookingSchedule;
    return getHoursColumns(startDateDayView, endDateDayView);
};

export const timeSelector = (state: AppState) => {
    const {
        currentViewMode,
        startDateDayView,
        endDateWeekView,
        startDateWeekView,
        endDateDayView,
    } = state.facilityBookingSchedule;
    if (currentViewMode === ScheduleViewMode.WEEK) {
        return {
            start: parseDate(startDateWeekView).subtract(1, 'week').startOf('week'),
            end: parseDate(endDateWeekView).add(1, 'week').endOf('week'),
        };
    }
    return {
        start: parseDate(startDateDayView).subtract(1, 'day').startOf('day'),
        end: parseDate(endDateDayView).add(1, 'day').endOf('day'),
    };
};

export const monthSelector = (state: AppState) => {
    const { currentDate } = state.facilityBookingSchedule;
    const start = parseDate(currentDate).startOf('month');
    const end = parseDate(currentDate).endOf('month');
    return {
        start,
        end,
    };
};

export const headerScheduleSelector = (state: AppState) => {
    const { currentViewMode, currentWeek, currentDate } = state.facilityBookingSchedule;

    const items: string[] = [];
    if (currentViewMode === ScheduleViewMode.WEEK) {
        const startDay = parseDate(currentDate)
            .add(currentWeek - 1, 'weeks')
            .startOf('week');
        const endDay = startDay.clone().endOf('week');
        for (let i = 0; i < 7; i++) {
            items.push(startDay.add(i, 'day')?.fmYYYYMMDD('-'));
        }
        return {
            time: startDay.fmYYYYMMDD() + ' ~ ' + endDay.fmYYYYMMDD(),
            items,
        };
    }
    const startOfMonth = parseDate(currentDate).startOf('month');
    const startDay = startOfMonth.clone().endOf('week');
    for (let i = 0; i < 7; i++) {
        items.push(startDay.add(i, 'day').format('D ddd'));
    }
    return {
        time:
            startOfMonth.fmYYYYMMDD() + ' ~ ' + startOfMonth.endOf('month')?.fmYYYYMMDD(),
        items,
    };
};

export const facilityFilterSelector = (state: AppState) => {
    const { facilityList, collapseRooms } = state.facilityBookingSchedule;
    const results = facilityList.filter((item) => {
        return (
            !item.parentId || (item.parentId && !collapseRooms.includes(item.parentId))
        );
    });
    return results;
};

export const bookingFilterSelector = (state: AppState) => {
    const { bookingList, currentStatus } = state.facilityBookingSchedule;
    const results: IFacilityBookingList = {};
    if (currentStatus === FacilityBookingStatus.ALL) {
        return bookingList;
    }
    _.forEach(bookingList, (fbooking, date) => {
        results[date] = {};
        _.forEach(fbooking, (booking, facilityKey) => {
            const _bookings = booking.filter((item) => {
                return item.status === currentStatus;
            });
            results[date][facilityKey] = _bookings;
        });
    });
    return results;
};

export const showFacilityBookingScheduleListModalSelector = (state: AppState) => {
    return state.facilityBookingSchedule.isShowFacilityBookingScheduleListModal;
};

export const facilityBookingScheduleListSelector = (state: AppState) => {
    return state.facilityBookingSchedule.facilityBookingSchedule;
};

export const isShowCreateFacilityBookingFormSelector = (state: AppState) => {
    return state.facilityBookingSchedule.isShowCreateFacilityBookingForm;
};

export const showCheckInFormSelector = (state: AppState) => {
    return state.facilityBookingSchedule.showCheckInForm;
};

export const facilityBookingCheckInSelector = (state: AppState) => {
    return state.facilityBookingSchedule.facilityBookingCheckIn;
};

export const showCheckInFormLoadingSelector = (state: AppState) => {
    return state.facilityBookingSchedule.showCheckInFormLoading;
};

export const facilityBookingScheduleReducer = facilityBookingScheduleSlice.reducer;
