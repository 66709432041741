export const keycardSettingJa = {
    breadcrumbs: {
        home: 'ホーム',
        integrations: '外部連携',
        keycardSetting: 'カードキー設定',
    },
    card: {
        title: 'カードキー設定を選択',
        noKey: 'カードキーなし',
        active: '有効',
        inactive: '無効',
    },
};
