import i18next from '~plugins/i18next/i18n';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
export enum OrderBy {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
    PIC_NAME = 'picName',
    POSTAL_CODE = 'postalCode',
    ADDRESS = 'address',
    PIC_PHONE_NUMBER = 'picPhoneNumber',
    PIC_EMAIL_ADDRESS = 'picEmailAddress',
    STANDARD_CHECK_IN_TIME = 'standardCheckInTime',
    STANDARD_CHECK_OUT_TIME = 'standardCheckOutTime',
    PE_CSR_HOTEL_CODE = 'peCsrHotelCode',
    TLL_SYSTEM_ID = 'tllSystemId',
    TLL_PMS_USER_ID = 'tllPmsUserId',
    TLL_PMS_PASSWORD = 'tllPmsPassword',
    STATUS = 'status',
}

export enum HotelStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export const initHotelListQuery = {
    keyword: '',
    name: '',
    autoGeneratedCode: '',
    picName: '',
    postalCode: '',
    address: '',
    picPhoneNumber: '',
    picEmailAddress: '',
    peCsrHotelCode: '',
    tllSystemId: '',
    tllPmsUserId: '',
    tllPmsPassword: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const hotelSettingBreadcrumbs = () => [
    {
        text: i18next.t('hotel.settingForm.breadcrumbs.home'),
    },
    {
        text: i18next.t('hotel.settingForm.breadcrumbs.hotel'),
    },
    {
        text: i18next.t('hotel.settingForm.breadcrumbs.hotelSetting'),
    },
];
export const hotelListBreadcrumbs = () => [
    {
        text: i18next.t('hotel.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('hotel.list.breadcrumbs.hotel'),
    },
];

export enum DefaultCheckInOutTime {
    CHECK_IN_TIME = '14:00',
    CHECK_OUT_TIME = '11:00',
}

export enum SearchField {
    ALL = 'keyword',
    TLL_PMS_USER_ID = 'peCsrHotelCode',
    NAME = 'name',
    PIC_NAME = 'picName',
    POSTAL_CODE = 'postalCode',
    ADDRESS = 'address',
    PIC_PHONE_NUMBER = 'picPhoneNumber',
    PIC_EMAIL_ADDRESS = 'picEmailAddress',
}

export const enum HotelListColumn {
    NAME = 'name',
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    PIC_NAME = 'picName',
    PE_CSR_HOTEL_CODE = 'peCsrHotelCode',
    TLL_PMS_USER_ID = 'tllPmsUserId',
    POSTAL_CODE = 'postalCode',
    ADDRESS = 'address',
    PIC_PHONE_NUMBER = 'picPhoneNumber',
    PIC_EMAIL_ADDRESS = 'picEmailAddress',
    NUMBER_OF_ROOM = 'numberOfRoom',
    DEFAULT_CHECK_IN_TIME = 'standardCheckInTime',
    DEFAULT_CHECK_OUT_TIME = 'standardCheckOutTime',
    STATUS = 'status',
    PERMISSION = 'permission',
}

export const FILE_NAME = 'hotel.csv';

// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'hotel';
export const exportColumns = [
    'peCsrHotelCode',
    'name',
    'tllPmsUserId',
    'picName',
    'postalCode',
    'address',
    'picPhoneNumber',
    'picEmailAddress',
    'numberOfRoom',
    'standardCheckInTime',
    'standardCheckOutTime',
    'status',
    'permission',
];
export const i18ExportKey = 'hotel.list';

export enum HotelPermission {
    PMS = 'pms',
    HOTEL = 'hotel',
}

export const MAX_INPUT_NUMBER_VALUE = 999999999999999999999999999999;

export enum BANK_ACCOUNT_TYPE {
    SAVING = 'saving',
    CHECKING = 'checking',
}
