import { INPUT_TEXT_MAX_LENGTH, MAX_INTEGER, MIN_ID } from '~common/constants';
import yup from '~plugins/yup';
import { MAX_FACILITY_ITEMS } from './constants';

export const settingSchema = yup.object().shape({
    quantity: yup
        .string()
        .required()
        .minFullAndHalfWidth(1)
        .maxFullAndHalfWidth(MAX_FACILITY_ITEMS),
    facilityTypeId: yup.number().required(),
    initialFacilityName: yup
        .string()
        .required()
        .max(INPUT_TEXT_MAX_LENGTH - 2),
});

export const bulkCreateFacilitySchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                name: yup
                    .string()
                    .required()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .hasNoSpecialCharacters(),
                facilityTypeId: yup.number().required().min(MIN_ID).max(MAX_INTEGER),
            })
            .required(),
    ),
});

export const bulkUpdateFacilitiesSchema = yup.object().shape({
    items: yup.array().of(
        yup
            .object()
            .shape({
                name: yup
                    .string()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .required()
                    .hasNoSpecialCharacters(),
                facilityTypeId: yup.number().required().min(1),
            })
            .required(),
    ),
});
