import { Regex } from '~common/constants';
import yup from '~plugins/yup';

export const loginSchema = yup.object().shape({
    email: yup
        .string()
        .isEmail()
        .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
        .required(),
    password: yup.string().required(),
});
