import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { salesByCategoryBreadcrumbs } from '~features/report/constants';
import { salesByCategorySelector } from '~features/report/reducers/sales-by-category.reducer';
import { useAppSelector } from '~hooks';
import FilterForm from './components/FilterForm/FilterForm';
import SalesByCategoryTable from './components/SalesByCategoryTable/SalesByCategoryTable';

function SalesByCategoryPage() {
    const { t } = useTranslation();
    const { showSalesByCategoryTable } = useAppSelector(salesByCategorySelector);
    return (
        <div className="sales-by-category-page">
            <StaffLayoutHeader
                breadcrumbs={salesByCategoryBreadcrumbs()}
                title={t('report.salesByCategory.title')}
            />
            <Layout.Content>
                <div className="sales-by-category-page-wrapper">
                    <div className="sales-by-category-page-content">
                        <FilterForm />
                        {showSalesByCategoryTable && <SalesByCategoryTable />}
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default SalesByCategoryPage;
