import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, ModalFuncProps } from 'antd';
import i18next from '~plugins/i18next/i18n';

export const showConfirm = (option: ModalFuncProps) => {
    Modal.confirm({
        ...option,
        title: option.title,
        content: option.content,
        icon: <ExclamationCircleOutlined />,
        centered: true,
        onOk() {
            if (option.onOk) {
                option.onOk();
            }
        },
        onCancel() {
            if (option.onCancel) {
                option.onCancel();
            }
        },
        cancelText: option.cancelText || i18next.t<string>('common.cancel'),
        okText: option.okText || i18next.t<string>('common.ok'),
    });
};
