import { Card, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import './KeycardSettingCard.scss';
import { useEffect, useState } from 'react';

interface Keycard {
    id: string;
    name: string;
    isActive: boolean;
}
const MOCK_CARD_KEYS: Keycard[] = [
    {
        id: '1',
        name: 'Card key brand 1',
        isActive: false,
    },
    {
        id: '2',
        name: 'Card key brand 2',
        isActive: false,
    },
    {
        id: '3',
        name: 'Card key brand 3',
        isActive: false,
    },
    {
        id: '4',
        name: 'Card key brand 4',
        isActive: false,
    },
];

const gridStyle: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
};

function KeycardSettingCard() {
    const { t } = useTranslation();
    const [keycards, setKeycards] = useState<Keycard[]>([]);
    const [isNoKeycards, setIsNoKeycards] = useState(true);

    useEffect(() => {
        setKeycards(MOCK_CARD_KEYS);
    }, []);

    useEffect(() => {
        const isNoKeycardsActive = keycards.every((keycard) => !keycard.isActive);
        setIsNoKeycards(isNoKeycardsActive);
    }, [keycards]);

    const toggleOffAllKeycardStatus = (checked: boolean) => {
        setIsNoKeycards(checked);
        if (checked) {
            const updatedKeycards = keycards.map((k) => ({ ...k, isActive: false }));
            setKeycards(updatedKeycards);
        }
    };

    const changeKeycardStatus = (checked: boolean, keycard: Keycard) => {
        const updatedKeycards = keycards.map((k) =>
            k.id === keycard.id ? { ...k, isActive: checked } : k,
        );
        setKeycards(updatedKeycards);
    };

    return (
        <Card
            bordered
            className="keycard-setting-card"
            title={t('keycardSetting.keycard.card.title')}
        >
            <Card.Grid style={gridStyle} hoverable={false}>
                <div>{t('keycardSetting.keycard.card.noKey')}</div>
                <div>
                    <span className="toggle-label">
                        {isNoKeycards
                            ? t('keycardSetting.keycard.card.active')
                            : t('keycardSetting.keycard.card.inactive')}
                    </span>
                    <Switch
                        defaultChecked
                        checked={isNoKeycards}
                        onChange={(checked) => toggleOffAllKeycardStatus(checked)}
                    />
                </div>
            </Card.Grid>
            {keycards.map((keycard) => (
                <Card.Grid key={keycard.id} style={gridStyle} hoverable={false}>
                    <div>{keycard.name}</div>
                    <div>
                        <span className="toggle-label">
                            {keycard.isActive
                                ? t('keycardSetting.keycard.card.active')
                                : t('keycardSetting.keycard.card.inactive')}
                        </span>
                        <Switch
                            checked={keycard.isActive}
                            onChange={(checked) => changeKeycardStatus(checked, keycard)}
                        />
                    </div>
                </Card.Grid>
            ))}
        </Card>
    );
}

export default KeycardSettingCard;
