import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import RankCalendarForm from '~features/rank-calendar/components/RankCalendarForm/RankCalendarForm';
import UpdateActionForm from '~features/rank-calendar/pages/RankCalendarUpdatePage/components/UpdateActionForm/UpdateActionForm';
import UpdateRankCalendar from '~features/rank-calendar/pages/RankCalendarUpdatePage/components/UpdateRankCalendar/UpdateRankCalendar';
import { breadcrumbsUpdate } from '~features/rank-calendar/constants';
import {
    fetchRankCalendarDetail,
    setIsUpdating,
    showLoadingListSelector,
} from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { updateRankCalendarSchema } from '~features/rank-calendar/schema';
import { useAppDispatch, useAppSelector } from '~hooks';

function RankCalendarUpdatePage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { rankCalendarId } = useParams();

    const showLoading = useAppSelector(showLoadingListSelector);
    const { control, setValue, getValues, handleSubmit } = useForm({
        resolver: yupResolver(updateRankCalendarSchema),
    });

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(fetchRankCalendarDetail(id));
        if (fetchRankCalendarDetail.fulfilled.match(response)) {
            if (!response.payload?.data) {
                navigate('/404');
            }
        }
    }, []);

    //This re-renders when the route changes
    useEffect(() => {
        if (typeof Number(rankCalendarId) === 'number') {
            _getDetail(Number(rankCalendarId));
        } else {
            navigate('/404');
        }
    }, [rankCalendarId]);

    useEffect(() => {
        dispatch(fetchRankCalendarDetail(Number(rankCalendarId)));
    }, []);

    useEffect(() => {
        dispatch(setIsUpdating(true));
    }, []);

    return (
        <div className="rank-calendar-update-page">
            <Spin spinning={showLoading}>
                <StaffLayoutHeader
                    breadcrumbs={breadcrumbsUpdate()}
                    title={t('rankCalendar.update.title')}
                />
                <Content className="rank-calendar-detail-content">
                    <UpdateActionForm
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        handleSubmit={handleSubmit}
                    />
                    <UpdateRankCalendar control={control} setValue={setValue} />
                </Content>
            </Spin>
            <RankCalendarForm />
        </div>
    );
}

export default RankCalendarUpdatePage;
