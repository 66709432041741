const paymentDetails = {
    title: 'Payment details',
    report: 'Report',
    datePeriod: {
        label: 'Payment time',
        button: 'Display',
    },
    paymentMethod: {
        label: 'Payment method',
        placeholder: 'Select payment meethod',
    },
    column: {
        checkInDate: 'Date',
        registrationTime: 'Registration time',
        roomName: 'Room name',
        representativeName: 'Representative guest',
        unpaidAmount: 'Unpaid amount',
    },
    total: 'Total',
    yen: '¥{{value}}',
    all: 'All',
    print: {
        datePeriod: '{{start}} to {{end}}',
    },
};

export default paymentDetails;
