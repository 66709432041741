const planCreatePage = {
    title: 'Create new plan',
    updateTitle: 'Edit plan',
    buttonBack: 'Back',
    buttonNext: 'Next',
    buttonCancel: 'Cancel',
    buttonSubmit: 'Save',
    planOverview: {
        title: 'Plan Overview',
        titleNote: '* This field is required',
        name: 'Plan name',
        namePlaceholder: 'Ex: Plan A',
        memo: 'Plan details',
        type: 'Plan type',
        typePlaceholder: 'Select plan type',
        planTypeOptions: {
            stay: 'Accommodation plan',
            day_use: 'Day-use plan',
        },
        taxSetting: 'Tax setting',
        taxPlaceholder: 'Select tax setting',
        taxOptions: {
            '10': 'Tax 10%',
            '0': 'Tax-free',
        },
        checkInTime: 'Check in',
        checkOutTime: 'Check out',
        cleaningFrequency: 'Cleaning frequency',
        cleaningFreqOption: {
            '0': 'Upon checkout',
            '1': 'Everyday + checkout',
            '2': 'Every second day + checkout',
            '3': 'Every third day + checkout',
            '4': 'Every fourth day + checkout',
            '5': 'Every fifth day + checkout',
            '6': 'Every sixth day + checkout',
            '7': 'Every seventh day + checkout',
        },
    },
    yen: 'JPY',
    price: '¥',
    saleItemsForm: {
        title: 'Sales item setting',
        describeItemPlan: 'Items included in plan',
        addSaleItem: 'Add row',
        columns: {
            id: '#',
            name: 'Sales item',
            nameInput: 'Select sales item',
            price: 'Unit price',
            priceInput: '￥0',
            quantity: 'Quantity',
            isForOnePeople: 'Apply for all',
            isForOnePeopleHover: 'Number of accommodation guests to be applied',
            isFirstDayOnly: 'First day only',
            isFirstDayOnlyHover: 'This is only applied to first day of stay',
            totalPrices: 'Total',
            totalPriceInput: '0',
            action: 'Delete',
        },
    },
    pricingSelectorForm: {
        title: 'Price setting',
        priceRank: 'Price calendar',
        priceRankDefault: 'Basic calendar',
        roomType: 'Room type',
        roomTypePlaceholder: 'Select a room type',
    },
    planRoomTypePricingsForm: {
        title: '・Plan setting',
        double: 'Double',
        single: 'Single',
        planGroup: 'Plan group code',
        planGroupInput: 'Enter a group code',
        pricing: 'Price setting',
        placehoderPricing: 'Bulk',
        person: 'People',
        rank: 'Rank',
        planPricingInput: 'Enter amount',
        bulk: 'Bulk',
    },
    planGroupCodeDuplicated: 'Plan Group Code is duplicated',
    createSuccess: 'Select a plan from Pricing Dashboard',
    updateSuccess: 'Plan has been updated successfully',
    createError: 'Cannot create plan',
    updateError: 'Cannot edit plan',
    packagePlanCode: {
        title: 'Package plan code',
        tabLabel: 'Plan code',
        addBtn: 'Add plan code',
        addModalTitle: 'Add Package Plan Code',
        editModalTitle: 'Edit Package Plan Code',
    },
};

export default planCreatePage;
