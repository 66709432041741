export const salesByCategoryEn = {
    title: 'Sales by category',
    breadcrumbs: {
        report: 'Report',
        salesByCategory: 'Sales by category',
    },
    filterForm: {
        pickerLabel: 'Month',
        roomType: 'Group',
        allOption: 'All',
        showMode: 'Daily',
        filterButton: 'Display',
    },
    salesByCategoryColumn: {
        date: 'Date',
        lastMonth: 'Last month',
        currentMonth: 'This month',
        nextMonth: 'Next month',
        roomType: 'Room type',
        numberOfRooms: 'Number of occupied rooms',
        adults: 'Adults',
        children: 'Children',
        amount: 'Amount',
        averagePrice: 'Average price',
        occupancyRate: 'Occupancy rate',
    },
    tableHeader:
        '※ The figures in parentheses indicate the same date of the previous year',
    total: 'Total (tax included)',
    unit: '¥',
    percentage: '%',
};
