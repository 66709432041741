import { useEffect } from 'react';
import './CreatePlanPage.scss';
import {
    fetchTaxListForDropdown,
    setFormStep,
    setSelectedPlan,
} from '../../reducers/plan.reducer';
import { useDispatch } from 'react-redux';
import PlanForm from '~features/plan/components/PlanForm/PlanForm';
import localStorageAuthService from '~common/authStorage';
import { planTaxTypes } from '~features/plan/constants';

function CreatePlanPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        const selectedHotel = localStorageAuthService.getSelectedHotel();
        dispatch(
            setSelectedPlan({
                checkInTime: selectedHotel?.standardCheckInTime || '',
                checkOutTime: selectedHotel?.standardCheckOutTime || '',
            }),
        );
        dispatch(fetchTaxListForDropdown({ taxTypes: planTaxTypes }) as any);
        dispatch(setFormStep(1));
    }, []);

    return (
        <div className="create-plan-page">
            <PlanForm />
        </div>
    );
}

export default CreatePlanPage;
