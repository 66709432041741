import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { trim, cloneDeep } from 'lodash';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import { IGetTaxListForDropdownQuery } from '~features/tax/constants';
import { AppState } from '~plugins/redux-toolkit/store';
import { SearchField, initSaleItemListQuery } from '../constants';

import {
    ISaleItemState,
    ISaleItemDropDownQuery,
    ISaleItemListQuery,
    ISaleItemFormItem,
    IImportSaleItem,
} from '../interfaces';
import { saleItemService } from '../services/sale-item.service';

const initialState: ISaleItemState = {
    saleItemListForDropDown: [],
    saleItemList: [],
    saleItemListQuery: cloneDeep(initSaleItemListQuery),
    showLoading: false,
    totalSaleItem: 0,
    searchField: SearchField.ALL,
    newSaleItemList: [],
    saleGroupListForDropDown: [],
    taxListForDropDown: [],
    selectedSaleItemListDetail: [],
    showForm: false,
    showLoadingForm: false,
    importSaleItemList: [],
    importCsvFileName: '',
    hasImportError: false,
};

export const getSaleItemListForDropdown = createAsyncThunk(
    'saleItem/getSaleItemListForDropdown',
    async (params: ISaleItemDropDownQuery) => {
        return await saleItemService.getDropdown(params);
    },
);

export const fetchSaleItemList = createAsyncThunk(
    'saleItem/fetchSaleItemList',
    async (_, { getState }) => {
        const query = saleItemListQuerySelector(getState() as AppState);
        return await saleItemService.getList(query);
    },
);

export const getSaleGroupListForDropdown = createAsyncThunk(
    'saleItem/getSaleGroupListForDropdown',
    async () => {
        return await saleItemService.getSaleGroupListForDropdown();
    },
);

export const getTaxListForDropdown = createAsyncThunk(
    'saleItem/getTaxForDropdown',
    async (query: IGetTaxListForDropdownQuery) => {
        return await saleItemService.getTaxListForDropdown(query);
    },
);

export const bulkCreateSaleItems = createAsyncThunk(
    'saleItem/bulkCreateSaleItems',
    async (formData: ISaleItemFormItem[]) => {
        return await saleItemService.bulkCreate(formData);
    },
);

export const getSaleItemListDetail = createAsyncThunk(
    'saleItem/getSaleItemListDetail',
    async (ids: number[]) => {
        return await saleItemService.getListDetail(ids);
    },
);

export const bulkUpdateSaleItems = createAsyncThunk(
    'saleItem/bulkUpdateSaleItems',
    async (formData: ISaleItemFormItem[]) => {
        return await saleItemService.bulkUpdate(formData);
    },
);

export const importCsvSaleItem = createAsyncThunk(
    'saleItem/importCsvSaleItem',
    async (importSaleItemBody: IImportSaleItem[]) => {
        return await saleItemService.importRoomCsv(importSaleItemBody);
    },
);

const saleItemSlice = createSlice({
    name: 'saleItem',
    initialState,
    reducers: {
        setSaleItemListForDropdown: (state, action) => {
            state.saleItemListForDropDown = action.payload;
        },
        setSaleItemListQuery: (state, action) => {
            state.saleItemListQuery = action.payload;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: ISaleItemListQuery = {
                orderBy: state.saleItemListQuery.orderBy,
                limit: state.saleItemListQuery.limit,
                orderDirection: state.saleItemListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
            };
            state.saleItemListQuery = query;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
            const query: ISaleItemListQuery = {
                orderBy: state.saleItemListQuery.orderBy,
                limit: state.saleItemListQuery.limit,
                orderDirection: state.saleItemListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]:
                    state.saleItemListQuery.keyword ||
                    state.saleItemListQuery.autoGeneratedCode ||
                    state.saleItemListQuery.name ||
                    '',
            };
            state.saleItemListQuery = query;
        },
        setNewSaleItemList: (state, action) => {
            state.newSaleItemList = action.payload;
        },
        setSelectedSaleItemListDetail: (state, action) => {
            state.selectedSaleItemListDetail = action.payload;
        },
        setShowForm: (state, action) => {
            state.showForm = action.payload;
        },
        setImportSaleItemList: (state, action) => {
            state.importSaleItemList = action.payload;
        },
        setImportCsvFileName: (state, action) => {
            state.importCsvFileName = action.payload;
        },
        setHasImportError: (state, action) => {
            state.hasImportError = action.payload;
        },
        setShowLoadingForm: (state, action) => {
            state.showLoadingForm = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSaleItemListForDropdown.fulfilled, (state, action) => {
            state.saleItemListForDropDown = action.payload?.data?.items || [];
        });
        builder.addCase(fetchSaleItemList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(fetchSaleItemList.fulfilled, (state, action) => {
            state.saleItemList = action.payload?.data?.items || [];
            state.totalSaleItem = action.payload?.data?.totalItems || 0;
            state.showLoading = false;
        });
        builder.addCase(getSaleGroupListForDropdown.fulfilled, (state, action) => {
            state.saleGroupListForDropDown =
                (action.payload?.data?.items || []).map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                }) || [];
        });
        builder.addCase(getTaxListForDropdown.fulfilled, (state, action) => {
            state.taxListForDropDown =
                (action.payload?.data?.items || []).map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                }) || [];
        });
        builder.addCase(getSaleItemListDetail.pending, (state, action) => {
            state.showLoadingForm = true;
        });
        builder.addCase(getSaleItemListDetail.fulfilled, (state, action) => {
            state.selectedSaleItemListDetail = action.payload?.data || [];
            state.showLoadingForm = false;
        });
        builder.addCase(bulkCreateSaleItems.pending, (state, action) => {
            state.showLoadingForm = true;
        });
        builder.addCase(bulkCreateSaleItems.fulfilled, (state, action) => {
            state.showLoadingForm = false;
        });
        builder.addCase(importCsvSaleItem.pending, (state, action) => {
            state.showLoadingForm = true;
        });
        builder.addCase(importCsvSaleItem.fulfilled, (state, action) => {
            state.showLoadingForm = false;
        });
    },
});

export const {
    setSaleItemListForDropdown,
    setSaleItemListQuery,
    setKeyword,
    setSearchField,
    setNewSaleItemList,
    setShowForm,
    setSelectedSaleItemListDetail,
    setImportSaleItemList,
    setImportCsvFileName,
    setHasImportError,
    setShowLoadingForm,
} = saleItemSlice.actions;

export const saleItemListDropdownSelector = (state: AppState) => {
    return state.saleItem.saleItemListForDropDown;
};

export const saleItemListQuerySelector = (state: AppState) => {
    return state.saleItem.saleItemListQuery;
};

export const saleItemListSelector = (state: AppState) => {
    return state.saleItem.saleItemList;
};

export const showLoadingSelector = (state: AppState) => {
    return state.saleItem.showLoading;
};

export const totalPageSelector = (state: AppState) => {
    const { totalSaleItem, saleItemListQuery } = state.saleItem;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = saleItemListQuery;
    return Math.ceil(totalSaleItem / limit);
};

export const totalSaleItemSelector = (state: AppState) => {
    return state.saleItem.totalSaleItem;
};

export const newSaleItemListSelector = (state: AppState) => {
    return state.saleItem.newSaleItemList;
};

export const saleGroupListDropdownSelector = (state: AppState) => {
    return state.saleItem.saleGroupListForDropDown;
};

export const taxListDropdownSelector = (state: AppState) => {
    return state.saleItem.taxListForDropDown;
};

export const selectedSaleItemListDetailSelector = (state: AppState) => {
    return state.saleItem.selectedSaleItemListDetail;
};

export const showFormSelector = (state: AppState) => {
    return state.saleItem.showForm;
};

export const showLoadingFormSelector = (state: AppState) => {
    return state.saleItem.showLoadingForm;
};

export const importSaleItemListSelector = (state: AppState) => {
    return state.saleItem.importSaleItemList;
};

export const importCsvFileNameSelector = (state: AppState) => {
    return state.saleItem.importCsvFileName;
};

export const hasImportErrorSelector = (state: AppState) => {
    return state.saleItem.hasImportError;
};

export default saleItemSlice.reducer;
