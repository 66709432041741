const unpaidDetailEn = {
    title: 'Unpaid details',
    breadcrumbs: {
        report: 'Report',
        unpaidDetail: 'Unpaid details',
    },
    filterForm: {
        dateLabel: 'Date:',
        filterButton: 'Display',
    },
    total: 'Total',
    unit: '¥',
    unpaidDetailColumn: {
        roomName: 'Room name',
        guestName: 'Guest name',
        checkIn: 'Check in date',
        checkOut: 'Check out date',
        numberOfNights: 'Number of nights',
        unpaid: 'Unpaid amount',
    },
    day: {
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
        sunday: 'Sun',
    },
};

export default unpaidDetailEn;
