const rankCalendarDetail = {
    title: 'Rank calendar details',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        rankCalendar: 'Rank calendar setting',
        rankCalendarDetail: 'Rank calendar details',
    },
    detaiViewTitle: 'Display price per month',
    calendarHeader: {
        monthText: 'Month',
    },
    actionForm: {
        label: 'Calendar name',
        deleteBtn: 'Delete',
        editBtn: 'Edit',
        activeBtn: 'Activate calendar',
    },
    modalConfirm: {
        title: 'Delete calendar',
        description: 'Do you want to delete this calendar?',
        cancelBtn: 'Cancel',
        deleteBtn: 'Delete',
    },
    modalSetActive: {
        title: 'Activate calendar',
        description: 'Do you want to activate basic calendar?',
        cancelBtn: 'Cancel',
        deleteBtn: 'Activate',
    },
    calendarDay: {
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
        sunday: 'Sun',
    },
};

export default rankCalendarDetail;
