import React from 'react';
import { Form } from 'antd';
import { Controller } from '~plugins/hook-form';
import './TimePickerField.scss';
import { TimePicker, TimePickerProps } from '../TimePicker/TimePicker';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import { parseErrorMessage } from '~common/commonFunctions';
import classNames from 'classnames';

type ITimePickerProps = TimePickerProps & {
    name: string;
    label: string;
    control: any;
    required: boolean;
    tooltip?: LabelTooltipType;
    hideHelpText?: boolean;
};

export function TimePickerField(props: ITimePickerProps) {
    const {
        control,
        name,
        defaultValue,
        label,
        onChange,
        hideHelpText,
        required,
        tooltip,
        ...rest
    } = props;
    return (
        <div
            className={classNames('time-picker-field-wrapper', {
                'hide-help-text': hideHelpText,
            })}
        >
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            tooltip={tooltip}
                            required={required}
                        >
                            <TimePicker
                                {...field}
                                {...rest}
                                onChange={(...agrs) => {
                                    if (onChange) {
                                        onChange(...agrs);
                                    }
                                    field.onChange(...agrs);
                                }}
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

TimePickerField.defaultProps = {
    defaultValue: '',
    required: false,
};
