import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { parseDate } from '~plugins/dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { planDropdownSelector } from '~features/plan/reducers/plan.reducer';
import {
    AdvanceSearchFields,
    roomBookingListQueryDefault,
} from '~features/room-booking/constants';
import { IAdvanceSearchForm, ISearchTag } from '~features/room-booking/interfaces';
import {
    advanceSearchListQuerySelector,
    clearListQuery,
    marketingChannelDropDownsSelector,
    setAdvanceSearchListQuery,
    setIsAdvanceSearch,
    setIsShowAdvanceSearchTags,
    setRoomBookingListQuery,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './AdvanceSearchTags.scss';

function AdvanceSearchTags() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [advanceSearchTags, setAdvanceSearchTags] = useState<ISearchTag[]>([]);
    const advanceSearchListQuery = useAppSelector(advanceSearchListQuerySelector);
    const marketingChannelDropDowns = useAppSelector(marketingChannelDropDownsSelector);
    const planDropdowns = useAppSelector(planDropdownSelector);

    const removeAllFilter = () => {
        dispatch(clearListQuery());
        setAdvanceSearchTags([]);
    };

    useEffect(() => {
        if (!advanceSearchListQuery) return;
        let searchTags: ISearchTag[] = [];
        // generate search tag array to advance search list query
        Object.values(AdvanceSearchFields).forEach((field: string) => {
            const advanceSearch =
                advanceSearchListQuery[field as keyof IAdvanceSearchForm];
            switch (field) {
                case AdvanceSearchFields.AUTO_GENERATE_CODE:
                case AdvanceSearchFields.GUEST_YOSIGANA:
                case AdvanceSearchFields.GUEST_MOBILE_PHONE_NUMBER:
                case AdvanceSearchFields.MEMO:
                    if (advanceSearch) {
                        searchTags.push({
                            field,
                            value: advanceSearch as string,
                        });
                    }
                    break;

                case AdvanceSearchFields.ROOM_BOOKING_ITEM_STATUS:
                    if (advanceSearch && advanceSearch.length > 0) {
                        const tags = (advanceSearch as string[]).map((tag) => {
                            return {
                                field,
                                value: t(`roomBooking.page.bookingStatus.${tag}`),
                                id: tag,
                            };
                        });
                        searchTags = [...searchTags, ...tags];
                    }
                    break;

                case AdvanceSearchFields.PLAN_IDS:
                    if (advanceSearch && advanceSearch.length > 0) {
                        const tags = (advanceSearch as number[]).map((tag) => {
                            const planOption = planDropdowns.find((option) => {
                                return option.value === tag;
                            });
                            return {
                                field,
                                value: planOption?.label || '',
                                id: planOption?.value as number,
                            };
                        });
                        searchTags = [...searchTags, ...tags];
                    }
                    break;

                case AdvanceSearchFields.MARKETING_CHANNEL_IDS:
                    if (advanceSearch && advanceSearch.length > 0) {
                        const tags = (advanceSearch as number[]).map((tag) => {
                            const salesChannelOption = marketingChannelDropDowns.find(
                                (option) => {
                                    return option.id === tag;
                                },
                            );
                            return {
                                field,
                                value: salesChannelOption?.name || '',
                                id: salesChannelOption?.id,
                            };
                        });
                        searchTags = [...searchTags, ...tags];
                    }
                    break;

                case AdvanceSearchFields.RECEPTION_PERIOD:
                case AdvanceSearchFields.STAY_PERIOD:
                case AdvanceSearchFields.START_DATE_PERIOD:
                case AdvanceSearchFields.END_DATE_PERIOD:
                    if (advanceSearch && advanceSearch.length > 0) {
                        searchTags.push({
                            field,
                            value: `${parseDate(
                                advanceSearch[0],
                            )?.fmYYYYMMDD()} ~ ${parseDate(
                                advanceSearch[1],
                            )?.fmYYYYMMDD()}`,
                        });
                    }

                    break;
                default:
                    break;
            }
        });
        setAdvanceSearchTags(searchTags);
    }, [advanceSearchListQuery, marketingChannelDropDowns, planDropdowns, t]);

    const removeTag = (tag: ISearchTag) => {
        const searchTags = advanceSearchTags.filter((searchTag) => {
            if (tag.field === searchTag.field && tag.id === searchTag.id) {
                return false;
            }
            return true;
        });

        if (searchTags.length === 0) {
            dispatch(setIsShowAdvanceSearchTags(false));
            dispatch(setIsAdvanceSearch(false));
        }
        setAdvanceSearchTags(searchTags);

        // update advance search list query
        let updateAdvanceSearchQuery;
        switch (tag.field) {
            case AdvanceSearchFields.AUTO_GENERATE_CODE:
            case AdvanceSearchFields.GUEST_YOSIGANA:
            case AdvanceSearchFields.GUEST_MOBILE_PHONE_NUMBER:
            case AdvanceSearchFields.MEMO:
                updateAdvanceSearchQuery = { [tag.field]: undefined };
                break;

            case AdvanceSearchFields.ROOM_BOOKING_ITEM_STATUS:
                const roomBookingItemStatus =
                    advanceSearchListQuery?.roomBookingItemStatus?.filter((status) => {
                        return status !== tag.id;
                    });
                updateAdvanceSearchQuery = { roomBookingItemStatus };
                break;

            case AdvanceSearchFields.PLAN_IDS:
                const planIds = advanceSearchListQuery?.planIds?.filter((planId) => {
                    return planId !== tag.id;
                });
                updateAdvanceSearchQuery = { planIds };
                break;

            case AdvanceSearchFields.MARKETING_CHANNEL_IDS:
                const marketingChannelIds =
                    advanceSearchListQuery?.marketingChannelIds?.filter(
                        (marketingChannelId) => {
                            return marketingChannelId !== tag.id;
                        },
                    );
                updateAdvanceSearchQuery = { marketingChannelIds };
                break;

            case AdvanceSearchFields.RECEPTION_PERIOD:
            case AdvanceSearchFields.STAY_PERIOD:
            case AdvanceSearchFields.START_DATE_PERIOD:
            case AdvanceSearchFields.END_DATE_PERIOD:
                updateAdvanceSearchQuery = { [tag.field]: [] };
                break;
            default:
                break;
        }
        dispatch(
            setAdvanceSearchListQuery({
                ...advanceSearchListQuery,
                ...updateAdvanceSearchQuery,
            }),
        );
        dispatch(
            setRoomBookingListQuery({
                ...roomBookingListQueryDefault,
                keyword: undefined,
                ...advanceSearchListQuery,
                ...updateAdvanceSearchQuery,
            }),
        );
    };

    return (
        <div className="search-tag-wrapper">
            <p className="search-label">
                {t('roomBooking.list.advanceSearchForm.buttonSearchText')}:
            </p>
            <div className="search-tags">
                {advanceSearchTags.map((tag) => {
                    return (
                        <Tag
                            className="search-tag"
                            closable
                            key={`${tag.field}_${tag.value}`}
                            onClose={() => removeTag(tag)}
                        >
                            {`${t(
                                `roomBooking.list.advanceSearchForm.${tag.field}.label`,
                            )}: ${tag.value}`}
                        </Tag>
                    );
                })}

                <Button
                    type="text"
                    danger
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={removeAllFilter}
                >
                    {t('roomBooking.list.filterForm.buttonRemoveFilter')}
                </Button>
            </div>
        </div>
    );
}

export default AdvanceSearchTags;
