import React, { useCallback, useMemo } from 'react';
import './SelectRoomOrFacility.scss';
import { Checkbox, Input, MenuProps, Space } from 'antd';
import { Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import { roomDropDownListGroupSelector } from '~features/room/room.reducer';
import _ from 'lodash';
import {
    indicatorSelector,
    setCellSelected,
    updateAssignedList,
    setMultipleSelect,
} from '~features/indicator/reducers/indicator.reducer';
import { IIndicatorDropDown } from '~features/room/interfaces';
import { IIndicatorItemDropDown, IRoomInfo } from '~features/indicator/interfaces';
import { facilityDropDownGroupSelector } from '~features/facility/facility.reducer';
import { IndicatorType } from '~features/indicator/constants';

export const SelectRoomOrFacility = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);
    const { cellSelected, isMultipleSelect, assignedList, settingType } =
        useAppSelector(indicatorSelector);
    const dispatch = useAppDispatch();
    const roomList = useAppSelector(roomDropDownListGroupSelector);
    const facilityList = useAppSelector(facilityDropDownGroupSelector);

    const list = useMemo(() => {
        return settingType === IndicatorType.ROOM ? roomList : facilityList;
    }, [roomList, facilityList, settingType]);
    const [keyword, setKeyword] = React.useState('');

    const inAssignList = (child: IIndicatorDropDown) => {
        const _assignedList = assignedList[settingType];
        for (const key in _assignedList) {
            if (_assignedList[key].id === child.id) {
                return true;
            }
        }
        return false;
    };

    const listWithFilter = useMemo(() => {
        const _list: Record<string, IIndicatorItemDropDown> = {};
        _.forEach(list, (item, key) => {
            const children = item.children.filter((room) => {
                return (
                    room.name.toLowerCase().includes(keyword.toLowerCase()) &&
                    !inAssignList(room)
                );
            });
            if (children.length > 0) {
                _list[key] = { ...item, children };
            }
        });
        return _list;
    }, [list, keyword]);

    const onSelectRoom = useCallback(
        (child: IIndicatorDropDown) => {
            let canAddRoom = false;
            for (const key in cellSelected[settingType]) {
                if (
                    !cellSelected[settingType][key].item &&
                    cellSelected[settingType][key].isSelected
                ) {
                    canAddRoom = true;
                    break;
                }
            }
            const check = !hasRoomSelected(child);
            const _cellSelected = _.cloneDeep(cellSelected[settingType]);
            if (check) {
                const keys = Object.keys(_cellSelected).sort();
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    if (_cellSelected[key].isSelected && !_cellSelected[key].item) {
                        _cellSelected[key].item = child;
                        break;
                    }
                }
            } else {
                for (const key in _cellSelected) {
                    if (_cellSelected[key].item?.id === child.id) {
                        _cellSelected[key].item = undefined;
                    }
                }
            }
            for (const key in _cellSelected) {
                if (!_cellSelected[key].item && _cellSelected[key].isSelected) {
                    canAddRoom = true;
                    break;
                }
            }
            if (!canAddRoom) {
                return;
            }
            const newCellSelected = _.cloneDeep(cellSelected);
            newCellSelected[settingType] = _cellSelected;
            dispatch(setCellSelected(newCellSelected));
        },
        [cellSelected],
    );

    const hasRoomSelected = (child: IIndicatorDropDown) => {
        const _cellSelected = cellSelected[settingType];
        for (const key in _cellSelected) {
            if (_cellSelected[key].item?.id === child.id) {
                return true;
            }
        }
        return false;
    };

    const items: MenuProps['items'] = useMemo(() => {
        const menu: MenuProps['items'] = [];
        _.forEach(listWithFilter, (item) => {
            menu.push({
                key: item.id,
                type: 'group',
                label: item.name,
                children: item.children.map((child) => {
                    return {
                        key: `${item.id}-${child.id}`,
                        label: child.name,
                        icon: (
                            <Checkbox
                                checked={hasRoomSelected(child) || inAssignList(child)}
                                onChange={() => {
                                    onSelectRoom(child);
                                }}
                            />
                        ),
                        onClick: () => {
                            onSelectRoom(child);
                        },
                    };
                }),
            });
        });
        return menu;
    }, [listWithFilter, assignedList, onSelectRoom]);

    const onClear = () => {
        const newCellSelected = _.cloneDeep(cellSelected);
        newCellSelected[settingType] = {};
        dispatch(setCellSelected(newCellSelected));
    };

    const assignment = () => {
        const data: IRoomInfo[] = [];
        const _cellSelected = _.cloneDeep(cellSelected[settingType]);
        _.forEach(cellSelected[settingType], (cell, key) => {
            if (cell.isSelected && cell.item) {
                data.push({
                    x: cell.x,
                    y: cell.y,
                    name: cell.item.name,
                    id: cell.item.id,
                    desc: cell.item.parentName,
                    type: settingType,
                });
                _cellSelected[key].item = undefined;
                _cellSelected[key].isSelected = false;
            }
        });
        const newCellSelected = _.cloneDeep(cellSelected);
        newCellSelected[settingType] = _cellSelected;
        dispatch(updateAssignedList(data));
        dispatch(setCellSelected(newCellSelected));
        dispatch(setMultipleSelect(false));
        setIsOpen(false);
    };

    const canAssignment = useMemo(() => {
        const _cellSelected = cellSelected[settingType];
        for (const key in _cellSelected) {
            if (_cellSelected[key].isSelected && _cellSelected[key].item) {
                return true;
            }
        }
        return false;
    }, [cellSelected, settingType]);

    return (
        <div className="select-room-or-facility-wrapper">
            <Dropdown
                open={isOpen}
                onOpenChange={() => {
                    setIsOpen(!isOpen);
                }}
                placement="bottomRight"
                disabled={!isMultipleSelect}
                menu={{ items }}
                trigger={['click']}
                overlayClassName="select-room-or-facility-dropdown"
                dropdownRender={(menu) => {
                    return (
                        <div className="dropdown-content">
                            <div className="dropdown-header mb-8">
                                <Space>
                                    <Input
                                        value={keyword}
                                        onChange={(e) => setKeyword(e.target.value)}
                                        placeholder={t('indicator.create.searchRoomName')}
                                    />
                                    <Button onClick={onClear}>
                                        {t('indicator.create.reset')}
                                    </Button>
                                </Space>
                            </div>
                            <div className="dropdown-body">{menu}</div>
                            <div className="dropdown-footer">
                                <Button
                                    type="primary"
                                    disabled={!canAssignment}
                                    onClick={assignment}
                                >
                                    {t('indicator.create.assignment')}
                                </Button>
                            </div>
                        </div>
                    );
                }}
                getPopupContainer={(triggerNode?: HTMLElement) =>
                    (triggerNode?.parentNode as HTMLElement) || document.body
                }
            >
                <Button className="select-room-btn d-flex j-between a-center">
                    {t('indicator.create.selectRoom')}
                    <DownOutlined />
                </Button>
            </Dropdown>
        </div>
    );
};
