import React from 'react';
import { useTranslation } from 'react-i18next';
import './CollapseBtn.scss';
import { Button } from 'antd';

type Props = {
    onClick: () => void;
    isExpand: boolean;
};

export const CollapseBtn = ({ onClick, isExpand }: Props) => {
    const { t } = useTranslation();
    return (
        <Button
            className="btn-collapse-all"
            onClick={() => {
                onClick();
            }}
        >
            <span>
                {isExpand
                    ? t('roomBooking.schedule.collapseShowLess')
                    : t('roomBooking.schedule.collapseTitle')}
            </span>
            <span>一</span>
        </Button>
    );
};
