import { ApiService } from '~plugins/axios/api';
import {
    IFacilitySalesReportQuery,
    IFacilitySalesResponse,
    IPaymentSummaryData,
    IPaymentSummaryRequestParameters,
    ISalesByCategoryQuery,
} from './interfaces';
import { IBodyResponse } from '~common/interfaces';
import { axiosInstanceV2 } from '~plugins/axios';
import {
    ISalesSummaryReportQuery,
    ISalesSummaryReportResponse,
} from './pages/SalesSummaryPage/interfaces';
import {
    IOperationStatusReportQuery,
    IOperationStatusResponse,
} from './pages/OperationStatusPage/interface';
import {
    IGetSalesByPlansQuery,
    ISalesByPlansResponse,
} from './pages/SalesByPlansPage/interfaces';
import { ISalesByRoomTypesResponse } from './pages/SalesByCategoryPage/components/SalesByCategoryTable/interfaces';
import {
    IGetSalesByOtaQuery,
    ISalesByOtaResponse,
} from './pages/SalesByOtaPage/interfaces';

class ReportingServiceV2 extends ApiService {
    getSalesByCategory(params: ISalesByCategoryQuery) {
        return this._get(`${this.baseUrl}/sale-by-category`, {
            params,
        }) as Promise<IBodyResponse<ISalesByRoomTypesResponse[]>>;
    }

    getFacilitySalesReport(params: IFacilitySalesReportQuery) {
        return this._get(`${this.baseUrl}/facility-sales`, {
            params,
        }) as Promise<IBodyResponse<IFacilitySalesResponse>>;
    }

    getSalesSummaryReport(params: ISalesSummaryReportQuery) {
        return this._get(`${this.baseUrl}/sales-summary`, {
            params,
        }) as Promise<IBodyResponse<ISalesSummaryReportResponse[]>>;
    }

    getOperationStatusReport(params: IOperationStatusReportQuery) {
        return this._get(`${this.baseUrl}/operation-status`, {
            params,
        }) as Promise<IBodyResponse<IOperationStatusResponse[]>>;
    }

    getSalesByPlansReport(params: IGetSalesByPlansQuery) {
        return this._get(`${this.baseUrl}/sales-by-plans`, {
            params,
        }) as Promise<IBodyResponse<ISalesByPlansResponse[]>>;
    }

    getSalesByOtaReport(params: IGetSalesByOtaQuery) {
        return this._get(`${this.baseUrl}/sales-by-ota`, {
            params,
        }) as Promise<IBodyResponse<ISalesByOtaResponse>>;
    }

    getPaymentSummaryReport(params: IPaymentSummaryRequestParameters) {
        return this._get(`${this.baseUrl}/payment-summary`, {
            params,
        }) as Promise<IBodyResponse<IPaymentSummaryData[]>>;
    }
}

export const reportingServiceV2 = new ReportingServiceV2(
    {
        baseUrl: '/reporting',
    },
    axiosInstanceV2,
);
