const roomTypeList = {
    title: 'Room type setting',
    breadcrumbs: {
        home: 'Home',
        roomTypeSetting: 'Room type setting',
    },
    roomColumn: {
        id: 'ID',
        type: 'Room type name',
        roomTypeCode: 'Room type code',
        abbreviation: 'Abbreviation',
        connectingRooms: 'Connecting room',
        standardCapacity: 'Standard capacity',
        order: '#',
        marketingChannel: 'Marketing channel',
    },
    filterForm: {},
    message: {
        oneChoose: 'Only choose one to edit',
        deleteRoomTypesSuccess: 'Room types have been deleted successfully',
    },
    searchColumn: {
        keyword: 'All',
        autoGeneratedCode: 'ID',
        name: 'Room type name',
        roomTypeCode: 'Room type code',
        abbreviation: 'Abbreviation',
        connectingRoomTypeName: 'Connecting room',
    },

    exportColumns: {
        name: 'Room type name',
        roomTypeCode: 'Room type code',
        abbreviation: 'Abbreviation',
        standardCapacity: 'Standard capacity',
        connectingRoomType: 'Connecting room',
        autoGeneratedCode: 'ID',
        marketingChannel: 'Marketing channel',
    },
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
    },
    importTitle: 'Upload preview',
    cancelTitle: 'Do you want to cancel?',
    cancelButton: 'Cancel',
    uploadButton: 'Upload',
    importSuccess: 'CSV file has been uploaded',
};

export default roomTypeList;
