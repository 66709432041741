import { useTranslation } from 'react-i18next';
import { Card, Space, Spin } from 'antd';

import { useGetAlmexSetting } from '../../hooks/use-get-almex-kiosk-setting-query';
import { AlmexSettingSwitch } from '../AlmexSettingSwitch/AlmexSettingSwitch';
import { AlmexSettingForm } from '../AlmexSettingForm/AlmexSettingForm';
import { AlmexTerminalTable } from '../AlmexTerminalTable/AlmexTerminalTable';

import './AlmexIntegrationCard.scss';

export const AlmexIntegrationCard = () => {
    const { t } = useTranslation();
    const { isPending: isQueryPending, isError, data, error } = useGetAlmexSetting();

    // To by-pass data is possibly undefined
    if (isQueryPending) {
        return <Spin spinning />;
    }
    if (isError) {
        return <span>Error: {error.message}</span>;
    }
    return (
        <Card bordered className="almex-setting-card">
            <Space align="center">
                <p className="card-title">{t('kioskAlmexIntegrations.almex.title')}</p>
                <AlmexSettingSwitch enabled={data.enabled} />
            </Space>
            <div className={data.enabled ? 'card-open-style' : 'card-close-style '}>
                <AlmexSettingForm
                    accessCode={data.accessCode}
                    keyType={data.keyType}
                    keySystemId={data.keySystemId}
                />
                <AlmexTerminalTable />
            </div>
        </Card>
    );
};
