const notificationMessage = {
    createFaclityBookingSuccess:
        '施設予約が作成されました。詳細をご覧になるためにはクリックしてください。',
    createRoomBookingItemSucces:
        '部屋予約項目が作成されました。ご覧になるためにはクリックしてください',
    updateRoomBookingStatus: '部屋予約項目が更新されました',
    updateFacilityBooking:
        '施設予約が更新されました。予約詳細をご覧になるためにはクリックしてください。',
};

export default notificationMessage;
