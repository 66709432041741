import i18next from 'i18next';

export const breadcrumbs = () => [
    {
        text: i18next.t('kioskAlmexIntegrations.almex.breadcrumbs.home'),
    },
    {
        text: i18next.t('kioskAlmexIntegrations.almex.breadcrumbs.integrations'),
    },
    {
        text: i18next.t('kioskAlmexIntegrations.almex.breadcrumbs.almex'),
    },
];

export const HotelKey = {
    None: 'NONE',
    MiwaIC: 'MIWA_IC',
    GoalIC: 'GOAL_IC',
    ShowaIC: 'SHOWA_IC',
} as const;

export const BulkModificationType = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
} as const;

export const enum AlmexTerminalColumn {
    NAME = 'terminalName',
    CODE = 'terminalCode',
    STATUS = 'status',
    ACTION = 'action',
}
