import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SearchField } from '~features/sale-group/constants';
import { setSearchField } from '~features/sale-group/reducers/sale-group.reducer';
import './SelectField.scss';

const { Option } = Select;

function SelectField() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleChange = (columnName: SearchField) => {
        dispatch(setSearchField(columnName));
    };
    return (
        <Select
            defaultValue={SearchField.ALL}
            onChange={handleChange}
            className="sale-group-select-search"
        >
            {Object.values(SearchField).map((columnName, index) => {
                return (
                    <Option value={columnName} key={index}>
                        {t(`saleGroup.list.searchColumn.${columnName}`)}
                    </Option>
                );
            })}
        </Select>
    );
}

export default SelectField;
