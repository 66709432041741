const staffLayout = {
    notification: 'New information',
    adminNotification: 'Notification',
    temporaryBooking: 'Confirmation list',
    temporaryBookingTitle: 'Temporary booking',
    confirmedBookingTitle: 'Booking is confirmed',
    confirmedFacilityBookingTitle: 'Confirmed the facility booking',
    updatedRoomBookingTitle: 'Updated room booking status',
    updatedFacilityBookingTitle: 'Updated facility booking status',
    notificationDate: '{{month}}/{{date}}',
    newTag: 'NEW',
    noData: 'No data',
    button: {
        tabist: 'New information ',
        tllNotification: 'Notification',
    },
    notificationTitle: {
        room_booking_change_status: 'Room booking change status',
        facility_booking_change_status: 'Facility booking change status',
        tll_create_room_booking: 'TLL create room booking',
        tll_modify_room_booking: 'TLL modify room booking',
        room_booking_confirm: 'Create room booking success',
        facility_booking_create: 'Create facility booking success',
    },
    adminNotificationItem: {
        closingBooking:
            '[Closing] There is one or more booking(s) that cannot be closed.',
    },
};

export default staffLayout;
