import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';

import {
    ISalelGroupGetListQuery,
    ISaleGroup,
    ISaleGroupBulkDeleteItem,
    ISaleGroupExportCsvQuery,
    ISaleGroupForm,
    IImportSaleGroup,
} from '~features/sale-group/interfaces';

import { ICsvFile, IBodyResponse, IGetListResponse } from '~common/interfaces';

class SaleGroupsService extends ApiService {
    getList(queryString: ISalelGroupGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<ISaleGroup>>
        >;
    }

    getListByIds(ids: number[]) {
        return this._get(`${this.baseUrl}/get-by-ids`, {
            params: {
                ids,
            },
        }) as Promise<IBodyResponse<ISaleGroup[]>>;
    }

    getDetail(id: number) {
        return this._getDetail(id) as Promise<IBodyResponse<ISaleGroup>>;
    }

    create(createBody: ISaleGroupForm) {
        return this._post(this.baseUrl, createBody) as Promise<IBodyResponse<ISaleGroup>>;
    }

    updateSaleGroup(id: number, updateBody: ISaleGroupForm) {
        return this._patch(`${this.baseUrl}/${id}`, updateBody) as Promise<
            IBodyResponse<ISaleGroup>
        >;
    }

    bulkDelete(bulkDeleteBody: ISaleGroupBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    exportCsv(query: ISaleGroupExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }

    importCsv(body: IImportSaleGroup[]) {
        return this._post(`${this.baseUrl}/import-csv`, {
            items: body,
        }) as Promise<IBodyResponse<IImportSaleGroup>>;
    }
}

export const saleGroupsService = new SaleGroupsService(
    { baseUrl: '/sale-group' },
    axiosService,
);
