const salesSummaryEn = {
    title: 'Sales summary',
    breadcrumbs: {
        report: 'Report',
        salesSummary: 'Sales summary',
    },
    filterForm: {
        toggleButton: 'Per group',
        aggregationMethod: {
            label: 'Payment method',
            daily: 'Daily',
            month: 'Month',
        },
        aggregationPeriod: 'Selected period',
        filterButton: 'Display',
    },
    salesSummaryColumn: {
        earnings: 'Sales',
        currentTerm: 'Current period',
        lastYear: 'Last year period',
        comparison: 'Last year comparison',
        saleItem: 'Sales items',
        quantity: 'Quantity',
        price: 'Price',
    },
    total: 'Total (tax included)',
    unit: '¥',
    ungrouped: 'Ungrouped',
    stay_price: 'Room price',
    defaultName: 'N/A',
    tab: {
        roomBooking: 'Room Booking',
        facilityBooking: 'Facility Booking',
    },
    receiptSummary: {
        uncollectedAmount: 'Uncollected Amount',
        accountReceivableAmount: 'Account Receivable Amount',
    },
};

export default salesSummaryEn;
