import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { selectedGuestDetailSelector } from '~features/guest/reducers/guest.reducer';
import { useAppSelector } from '~hooks';
import './GuestDetailCompanyInfo.scss';

function GuestDetailCompanyInfo() {
    const { t } = useTranslation();
    const selectedGuestDetail = useAppSelector(selectedGuestDetailSelector);
    return (
        <div className="guest-detail-company-info">
            <div className="guest-detail-company-info-content">
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.companyName')}
                    </Col>
                    <Col span={5}>{selectedGuestDetail?.companyName}</Col>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.companyYomigana')}
                    </Col>
                    <Col span={11}>{selectedGuestDetail?.companyYomigana}</Col>
                </Row>
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.companyEmailAddress')}
                    </Col>
                    <Col span={5}>{selectedGuestDetail?.companyEmailAddress}</Col>

                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.companyPhoneNumber')}
                    </Col>
                    <Col span={11}>{selectedGuestDetail?.companyPhoneNumber}</Col>
                </Row>
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.companyPostalCode')}
                    </Col>
                    <Col span={5}>{selectedGuestDetail?.companyPostalCode}</Col>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.companyAddress')}
                    </Col>
                    <Col span={11}>{selectedGuestDetail?.companyAddress}</Col>
                </Row>
                <Row>
                    <Col span={4} className="guest-detail-label">
                        {t('guest.detail.label.companyWebsite')}
                    </Col>
                    <Col span={20}>
                        <a
                            href={`https://${
                                selectedGuestDetail?.companyWebsite as string
                            }`}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            {selectedGuestDetail?.companyWebsite}
                        </a>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default GuestDetailCompanyInfo;
