import { Button } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './SettingCell.scss';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { IndicatorSingleSelect } from '../IndicatorSingleSelect/IndicatorSingleSelect';
import { IIndicatorDropDown } from '~features/room/interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    indicatorSelector,
    updateAssignedList,
    removeRoomSetting,
    updateCellSelected,
} from '~features/indicator/reducers/indicator.reducer';
import classNames from 'classnames';
import { IndicatorSize, IndicatorType } from '~features/indicator/constants';
import { roomDropDownListGroupSelector } from '~features/room/room.reducer';
import { facilityDropDownGroupSelector } from '~features/facility/facility.reducer';

type Props = {
    columnIndex: number;
    rowIndex: number;
};

export const SettingCell = (props: Props) => {
    const { columnIndex, rowIndex } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isHover, setIsHover] = React.useState(false);
    const [showSelect, setShowSelect] = React.useState(false);
    const { assignedList, isMultipleSelect, cellSelected, settingSize, settingType } =
        useAppSelector(indicatorSelector);

    const isAssigned = useMemo(() => {
        return !!assignedList[settingType][`${rowIndex}-${columnIndex}`];
    }, [assignedList, columnIndex, rowIndex, settingType]);

    const roomSetting = useMemo(() => {
        return assignedList[settingType][`${rowIndex}-${columnIndex}`];
    }, [assignedList, columnIndex, rowIndex, settingType]);

    const cellSelectedForType = useMemo(() => {
        return cellSelected[settingType][`${rowIndex}-${columnIndex}`];
    }, [cellSelected, columnIndex, rowIndex, settingType]);

    const roomList = useAppSelector(roomDropDownListGroupSelector);
    const facilityList = useAppSelector(facilityDropDownGroupSelector);

    const list = useMemo(() => {
        const _list = settingType === IndicatorType.ROOM ? roomList : facilityList;
        const desc: Record<string, string> = {};
        for (const key in _list) {
            _list[key].children.forEach((child) => {
                desc['id_' + child.id] = child.abbreviation;
            });
        }
        return desc;
    }, [roomList, facilityList, settingType]);

    const onMouseLeave = () => {
        setIsHover(false);
        setShowSelect(false);
    };

    const onMouseEnter = () => {
        setIsHover(true);
    };

    const onSelect = (value: IIndicatorDropDown) => {
        dispatch(
            updateAssignedList([
                {
                    x: columnIndex,
                    y: rowIndex,
                    name: value.name,
                    id: value.id,
                    desc: value.abbreviation,
                    type: settingType,
                },
            ]),
        );
    };

    const removeSetting = () => {
        dispatch(
            removeRoomSetting({
                x: columnIndex,
                y: rowIndex,
                type: settingType,
            }),
        );
    };

    const renderEmptyCell = () => {
        return (
            <div
                className={classNames('empty-cell', {
                    large: settingSize === IndicatorSize.LARGE,
                })}
            >
                <Button className="mb-8" onClick={() => setShowSelect(true)}>
                    {t('indicator.create.type.' + settingType)}
                    <PlusOutlined />
                </Button>
                {showSelect && <IndicatorSingleSelect onSelect={onSelect} />}
            </div>
        );
    };

    const renderAssignedCell = () => {
        return (
            <div className={'assigned-cell'}>
                <div className="assigned-name text-truncate">{roomSetting?.name}</div>
                <div className="assigned-desc text-truncate mb-8">
                    {list['id_' + roomSetting.id]}
                </div>
                <DeleteOutlined className="remove-btn" onClick={removeSetting} />
            </div>
        );
    };

    const renderMultipleSelect = () => {
        return (
            <div
                className={classNames('multiple-select-cell', {
                    selected: cellSelectedForType?.isSelected || false,
                    hover: isHover,
                })}
                onClick={() => {
                    dispatch(
                        updateCellSelected({
                            x: columnIndex,
                            y: rowIndex,
                            type: settingType,
                            isSelected: !cellSelectedForType?.isSelected,
                        }),
                    );
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div className="name text-truncate">
                    {cellSelectedForType?.item?.name}
                </div>
            </div>
        );
    };

    return (
        <div
            className="setting-cell-wrapper"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {!isMultipleSelect && isHover && !isAssigned && renderEmptyCell()}
            {isAssigned && renderAssignedCell()}
            {isMultipleSelect && !isAssigned && renderMultipleSelect()}
        </div>
    );
};
