import React from 'react';
import { Breadcrumb, Button, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IBreadcrumb } from '../../common/interfaces';
import './StaffLayoutHeader.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Header } = Layout;
interface IProp {
    title: string;
    breadcrumbs: IBreadcrumb[];
    isShowButtonBack?: boolean;
    onClickButtonBack?: () => void;
    rightComponent?: React.ReactNode;
    children?: React.ReactNode;
}

export function StaffLayoutHeader(props: IProp) {
    const navigate = useNavigate();
    const { isShowButtonBack = false } = props;
    const onClickButtonBack = () => {
        if (props.onClickButtonBack) {
            props.onClickButtonBack();
        } else {
            navigate(-1);
        }
    };
    return (
        <Header className="header-wrapper header-page">
            <div className="header-content">
                <Breadcrumb
                    items={props.breadcrumbs.map((breadcrumb, index) => ({
                        title: breadcrumb.text,
                        key: index,
                        className: 'staff-breadcrumb',
                    }))}
                />
                <div className="title-wrapper">
                    <div className="title">
                        {isShowButtonBack && (
                            <Button
                                onClick={onClickButtonBack}
                                className="btn-back"
                                type="text"
                                disabled
                            >
                                <ArrowLeftOutlined />
                            </Button>
                        )}
                        <div>{props.title}</div>
                    </div>
                    {props.rightComponent}
                </div>
            </div>
            {props.children}
        </Header>
    );
}

StaffLayoutHeader.defaultProps = {
    canBack: true,
};
