import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectSaleItem } from '~components';
import { ReceiptItemType } from '~features/room-booking/constants';
import { IBookingReceiptTableData } from '~features/room-booking/interfaces';
import { ISaleItemDropdown } from '~features/sale-item/interfaces';
import DetailReceiptCell from './DetailReceiptCell';
import './DetailReceiptColumn.scss';

type Props = {
    control: any;
    item: IBookingReceiptTableData;
    expanded: boolean;
    onChangeSaleItem: (saleItem: ISaleItemDropdown, id: string) => void;
    isEditMode: boolean;
};
function DetailReceiptColumn(props: Props) {
    const { item, control, expanded, isEditMode, onChangeSaleItem } = props;
    const { t } = useTranslation();
    const isCanEditSalItem = useMemo(() => {
        // only edit addition sale item
        return (
            isEditMode &&
            !item.isCheckout &&
            item.status === ReceiptItemType.RECEIPT &&
            !item.children
        );
    }, [item, isEditMode]);

    return isCanEditSalItem ? (
        <SelectSaleItem
            label=""
            placeholder={t('roomBooking.detail.receipt.saleItem.placeholder')}
            name={`${item.id}.saleItemId`}
            control={control}
            required
            dropdownStyle={{
                minWidth: '450px',
                maxWidth: '550px',
            }}
            showSearch={true}
            optionLabelProp="label"
            filterOption={(input, option) => {
                return ((option!.label || '') as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase());
            }}
            onChange={(value) => {
                onChangeSaleItem(value, item.id);
            }}
            selectedValue={item.saleItem?.id || undefined}
            selectedSaleItemIds={[]}
            popupMatchSelectWidth={false}
            additionalItems={item.saleItem ? [item.saleItem] : []}
        />
    ) : (
        <DetailReceiptCell item={item} expanded={expanded} />
    );
}

export default DetailReceiptColumn;
