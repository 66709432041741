import yupLocale from './locale';
import * as yup from 'yup';
import { fullWidthNumConvert } from '~common/commonFunctions';
import { ErrorMessageType, Regex } from '~common/constants';

yup.setLocale(yupLocale);

yup.addMethod(yup.string, 'isPhone', function () {
    return this.test({
        message: (data) => {
            return `yup.string.phone|${JSON.stringify(data)}`;
        },
        test: function (value) {
            let convertValue = value;
            if (!!value && Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                convertValue = fullWidthNumConvert(value);
            }
            return !convertValue || Regex.PHONE.test(convertValue);
        },
    });
});

yup.addMethod(yup.string, 'isAlphaNumeric', function () {
    return this.test({
        message: (data) => {
            return `yup.string.isAlphaNumeric|${JSON.stringify(data)}`;
        },
        test: function (value) {
            return !value || Regex.ALPHA_NUMERIC.test(value);
        },
    });
});

yup.addMethod(yup.string, 'isPostalCode', function () {
    return this.test({
        message: (data) => {
            return `yup.string.isPostalCode|${JSON.stringify(data)}`;
        },
        test: function (value) {
            let convertValue = value;
            if (!!value && Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                convertValue = fullWidthNumConvert(value);
            }
            return !convertValue || Regex.POSTAL_CODE.test(convertValue);
        },
    });
});

yup.addMethod(
    yup.string,
    'hasNoSpecialCharacters',
    function (regex = Regex.GENERAL_SPECIAL_CHARACTERS) {
        return this.test({
            message: (data) => {
                return `yup.string.hasNoSpecialCharacters|${JSON.stringify(data)}`;
            },
            test: function (value) {
                return !value || !regex.test(value);
            },
        });
    },
);

yup.addMethod(yup.string, 'isEmail', function () {
    return this.test({
        message: (data) => {
            return `yup.string.email|${JSON.stringify(data)}`;
        },
        test: function (value) {
            return !value || Regex.EMAIL.test(value);
        },
    });
});

yup.addMethod(yup.number, 'maxCapacityOfRoomType', function (max) {
    return this.test({
        message: (data) => {
            return `yup.number.maxCapacityOfRoomType|${JSON.stringify(data)}`;
        },
        params: { max },
        test: function (value) {
            return (value || 0) <= this.resolve(max);
        },
    });
});

yup.addMethod(yup.string, 'maxCapacityOfRoomTypeString', function (max) {
    return this.test({
        message: (data) => {
            return `yup.number.maxCapacityOfRoomType|${JSON.stringify(data)}`;
        },
        params: { max },
        test: function (value) {
            let convertValue = value;
            if (!!value && Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                convertValue = fullWidthNumConvert(value);
            }
            return !convertValue || (Number(convertValue) || 0) <= this.resolve(max);
            // return (value || 0) <= this.resolve(max);
        },
    });
});

yup.addMethod(yup.number, 'maxCapacityOfRoomNumbers', function (max) {
    return this.test({
        message: (data) => {
            return `yup.number.maxCapacityOfRoomNumbers|${JSON.stringify(data)}`;
        },
        params: { max },
        test: function (value) {
            return (
                (value || 0) <= this.resolve(max) ||
                this.createError({ type: ErrorMessageType.WARNING })
            );
        },
    });
});

yup.addMethod(yup.number, 'maxPrice', function (max) {
    return this.test({
        message: (data) => {
            return `yup.number.maxPrice|${JSON.stringify(data)}`;
        },
        params: { max },
        test: function (value) {
            return (value || 0) <= this.resolve(max);
        },
    });
});

yup.addMethod(yup.number, 'minPrice', function (min) {
    return this.test({
        message: (data) => {
            return `yup.number.minPrice|${JSON.stringify(data)}`;
        },
        params: { min },
        test: function (value) {
            return (value || 0) >= this.resolve(min);
        },
    });
});

yup.addMethod(yup.string, 'numberFullAndHalfWidth', function () {
    return this.test({
        message: (data) => {
            return `yup.mixed.notType|${JSON.stringify(data)}`;
        },
        test: function (value) {
            return !value || Regex.NUMBER_FULL_WIDTH_HALF_WIDTH.test(value);
        },
    });
});

yup.addMethod(yup.string, 'minFullAndHalfWidth', function (min) {
    return this.test({
        message: (data) => {
            return `yup.number.min|${JSON.stringify(data)}`;
        },
        params: { min },
        test: function (value) {
            let convertValue = value;
            if (!!value && Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                convertValue = fullWidthNumConvert(value);
            }
            return !convertValue || (Number(convertValue) || 0) >= this.resolve(min);
        },
    });
});

yup.addMethod(yup.string, 'maxFullAndHalfWidth', function (max) {
    return this.test({
        message: (data) => {
            return `yup.number.max|${JSON.stringify(data)}`;
        },
        params: { max },
        test: function (value) {
            let convertValue = value;
            if (!!value && Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                convertValue = fullWidthNumConvert(value);
            }
            return !convertValue || (Number(convertValue) || 0) <= this.resolve(max);
        },
    });
});

yup.addMethod(yup.string, 'maxPriceFullAndHalfWidth', function (max) {
    return this.test({
        message: (data) => {
            return `yup.number.maxPrice|${JSON.stringify(data)}`;
        },
        params: { max },
        test: function (value) {
            let convertValue = value;
            if (!!value && Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                convertValue = fullWidthNumConvert(value);
            }
            return !convertValue || (Number(convertValue) || 0) <= this.resolve(max);
        },
    });
});

yup.addMethod(yup.string, 'minPriceFullAndHalfWidth', function (min) {
    return this.test({
        message: (data) => {
            return `yup.number.minPrice|${JSON.stringify(data)}`;
        },
        params: { min },
        test: function (value) {
            let convertValue = value;
            if (!!value && Regex.FULL_WIDTH_CHARACTERS.test(value)) {
                convertValue = fullWidthNumConvert(value);
            }
            return (Number(convertValue) || 0) >= this.resolve(min);
        },
    });
});

export default yup;
