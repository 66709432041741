export enum SupportLanguage {
    JA = 'ja',
    EN = 'en',
}

export enum OrderDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum LodashOrderDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export const DEFAULT_LANGUAGE = SupportLanguage.JA;

export const DEFAULT_LIMIT_FOR_DROPDOWN = 1000;
export const DEFAULT_LIMIT_FOR_PAGINATION = 10;
export const DEFAULT_FIRST_PAGE = 1;
export const DEFAULT_ORDER_BY = 'createdAt';
export const DEFAULT_ORDER_DIRECTION = OrderDirection.DESC;
export const DEFAULT_MIN_DATE = '1970-01-01 00:00:00';
export const DEFAULT_MAX_DATE = '3000-01-01 00:00:00';
export const DEFAULT_DELAY_TIME_ON_SEARCH_INPUT = 500;
export const DRAWER_WIDTH_DEFAULT = 740;

export const MIN_ID = 1;
export const MAX_INTEGER = 2147483647;
export const MIN_PAGE_LIMIT = 1; // min item per one page
export const MIN_PAGE_VALUE = 1; // min page value
export const MAX_PAGE_LIMIT = 10000; // max item per one page
export const MAX_PAGE_VALUE = 10000; // max page value
export const INPUT_TEXT_MAX_LENGTH = 255; // max length for input text
export const TEXT_AREA_MAX_LENGTH = 2000; // max length for text area
export const POSTAL_CODE_MAX = 9999999999; // max base postal code value
export const PHOME_NUMBER_MAX = 999999999999999; // max base phone number value
export const NUMBER_OF_ROOM = 9999; // max number of room value
export const MEMO_MAX_LENGTH = 10000; // max length of memo
export const MAX_PRICE = MAX_INTEGER; // max base price sale item value
export const MIN_PRICE = -MAX_INTEGER; // max base price sale item value
export const MAX_FILE_SIZE_IN_BYTE = 2097152; // 2MB = 2097152B
export const EXCEL_ACCEPTED_FILE_TYPES = ['xls', 'xlsx', 'csv'];
export const XML_ACCEPTED_FILE_TYPES = ['xml'];

export enum OrderBy {
    ID = '_id',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
}

export enum HttpStatus {
    OK = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    CONFLICT = 409,
    UNPROCESSABLE_ENTITY = 422,
    GROUP_HAS_CHILDREN = 410,
    GROUP_MAX_QUANTITY = 412,
    ITEM_NOT_FOUND = 444,
    ITEM_ALREADY_EXIST = 445,
    ITEM_INVALID = 446,
    INTERNAL_SERVER_ERROR = 500,
    SERVICE_UNAVAILABLE = 503,
    NETWORK_ERROR = 502,
}

export enum DateFormat {
    YYYY_MM_DD_HYPHEN = 'YYYY-MM-DD',
    DD_MM_YYYY_DASH = 'DD/MM/YYYY',
    hh_mm_L_COLON = 'h:mm L',
    DD_MM_YY_DASH = 'DD/MM/YYYY',
    hh_mm = 'hh:mm',
    DD = 'DD',
    DAY_NAME_MONTH_STRING = 'dddd (DD-MM)',
    YYYY = 'YYYY',
    YYYY_MM_HYPHEN = 'YYYY-MM',
    HH_MM_SS_COLON = 'HH:mm:ss',
    HH_MM_COLON = 'HH:mm',
    YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON = 'YYYY-MM-DD HH:mm:ss',
    YYYY_MM_DD_HYPHEN_HH_MM_COLON = 'YYYY-MM-DD HH:mm',
    DD_MM_YYYY_SLASH = 'DD/MM/YYYY',
    MM_DD_YYYY_SLASH_HH_MM_SS_COLON = 'MM/DD/YYYY HH:mm:ss',
    MM_DD_YYYY_SLASH_HH_MM_COLON = 'MM/DD/YYYY HH:mm',
    MM_DD_YYYY_SLASH = 'MM/DD/YYYY',
    YYYY_MM_DD_SLASH = 'YYYY/MM/DD',
    TIMEZONE = 'YYYY-MM-DDTHH:mm:ssZ[Z]',
}

export const enum AntdOrderDirection {
    ASC = 'ascend',
    DESC = 'descend',
}

export const enum CsvOption {
    IMPORT = 'import',
    EXPORT_SELECTION = 'export_selection',
    EXPORT_ALL = 'export_all',
    IMPORT_XML = 'import_xml',
}

export enum ErrorMessageType {
    MANUAL = 'manual',
    WARNING = 'warning',
}

export const Regex = {
    PHONE: /^[0-9][0-9,\-, ]{0,254}|[0-9,+][0-9,\-, ]{1,254}$/,
    EMAIL: /^[\w+-.]+@([\w-]+\.)+[\w-]{1,255}$/,
    INT_PATTERN: `^[0-9０-９]+$`,
    FLOAT_PATTERN: `^[0-9０-９]+\\.[0-9０-９]{0,2}$`,
    INT_PRICE_PATTERN: `^[ー,-]?[0-9,０-９]+([0-9,０-９]+)?$`,
    PRE_PRICE_PATTERN: `^[-,ー,0-9,０-９]+$`,
    PHONE_PATTERN: `^[+,＋, ,0-9,０-９][0-9,０-９, ,-,-,−,ー,－]+$`,
    PRE_PHONE_PATTERN: `^[+,＋,0-9,０-９]+$`,
    POSTAL_CODE_PATTERN: `^[0-9,０-９,-,-,−,ー,－]+$`,
    ALPHA_NUMERIC: /^[a-zA-Z0-9]+$/,
    GENERAL_SPECIAL_CHARACTERS: /[$&+:;=?#|'<>,().^*!%]+/,
    TAX_SPECIAL_CHARACTERS: /[$&+:;=?#|'<>^*!]+/,
    MEMO_SPECIAL_CHARACTERS: /[$&+:;=?#|'<>^*!]+/,
    WEBSITE_SPECIAL_CHARACTERS: /[$&+;=?#|'<>^*!]+/,
    EMAIL_SPECIAL_CHARACTERS: /[$&:;=?#|'<>,()^*!%]+/,
    CHILDREN_TYPE_SPECIAL_CHARACTERS: /[$&+:;=?#|'<>,().^*!%@/\\~`"]+/,
    NAME_SPECIAL_CHARACTERS: /[$&+:;=?#|'<>,().^*!%@/\\~`"]+/,
    PLAN_NAME_SPECIAL_CHARACTERS: /[$&+;=?#|',().^*!%@/\\~`"]+/,
    PLAN_MEMO_SPECIAL_CHARACTERS: /[$&+;=?#|'^*!]+/,
    MARKETING_CHANNEL_NAME_SPECIAL_CHARACTERS: /[$&+:;=?#|'<>,()^*!%@/\\~`"]+/,
    HEX_COLOR: /^[0-9a-fA-F]{6}/,
    POSTAL_CODE: /^[0-9,-]{1,10}$/,
    NUMBER_FULL_WIDTH_HALF_WIDTH: /^[ー,-]?[0-9,０-９]+([0-9,０-９]+)?$/,
    FULL_WIDTH_CHARACTERS: /[\uFF10-\uFF19]|(\u30FC)|(\uff0b)|(\uff0d)/g,
};

export enum ScheduleViewMode {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
}

export const MINUTES_PER_DAY = 1440;
export const MINUTES_PER_HOUR = 60;

export const TllRankColors = [
    '#e6f7ff',
    '#feffe6',
    '#f6ffed',
    '#fff7e6',
    '#fcffe6',
    '#e6fffb',
    '#fff0f6',
    '#f4ffb8',
];

export enum TllRank {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
}

export const TllRankDropdown = Object.values(TllRank).map((rank) => {
    return {
        label: rank,
        value: rank,
    };
});

export enum UserGroup {
    ADMIN = 'ADMIN',
    CC_ADMIN = 'CC_ADMIN',
    CC_OPERATOR = 'CC_OPERATOR',
    OTA_ADMIN = 'OTA_ADMIN',
    OTA_OPERATOR = 'OTA_OPERATOR',
    PGX_ADMIN = 'PGX_ADMIN',
    PGX_OPERATOR = 'PGX_OPERATOR',
    HOTEL_ADMIN = 'HOTEL_ADMIN',
    HOTEL_OWNER = 'HOTEL_OWNER',
    HOTEL_OPERATOR = 'HOTEL_OPERATOR',
    HOTEL_FRONT = 'HOTEL_FRONT',
    HOTEL_CLEANING = 'HOTEL_CLEANING',
}

export const TabistTeamGroup = [
    UserGroup.ADMIN,
    UserGroup.CC_ADMIN,
    UserGroup.CC_OPERATOR,
    UserGroup.OTA_ADMIN,
    UserGroup.OTA_OPERATOR,
    UserGroup.PGX_ADMIN,
    UserGroup.PGX_OPERATOR,
];

export const AdminGroup = [...TabistTeamGroup, UserGroup.HOTEL_ADMIN];

export const HotelUsers = [
    UserGroup.HOTEL_ADMIN,
    UserGroup.HOTEL_CLEANING,
    UserGroup.HOTEL_OPERATOR,
    UserGroup.HOTEL_FRONT,
    UserGroup.HOTEL_OWNER,
];

export enum TaxType {
    BATH_TAX_1 = 'bathTax1',
    BATH_TAX_2 = 'bathTax2',
    BATH_TAX_3 = 'bathTax3',
    BATH_TAX_4 = 'bathTax4',
    BATH_TAX_5 = 'bathTax5',
    BATH_TAX_6 = 'bathTax6',
    BATH_TAX_7 = 'bathTax7',
    BATH_TAX_8 = 'bathTax8',
    BATH_TAX_9 = 'bathTax9',
    BATH_TAX_10 = 'bathTax10',
    LOCAL_TAX_1 = 'localTax1',
    LOCAL_TAX_2 = 'localTax2',
    BATH_TAX_13 = 'bathTax13',
    BATH_TAX_14 = 'bathTax14',
    BATH_TAX_15 = 'bathTax15',
    BATH_TAX_16 = 'bathTax16',
    BATH_TAX_17 = 'bathTax17',
    TAX_1 = 'tax1',
    TAX_2 = 'tax2',
    TAX_3 = 'tax3',
    KUTCHAN_TAX = 'kutchanTax',
}

export enum DayOfWeek {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
}

export enum EmitterGlobalEvent {
    LOCAL_STORAGE_CHANGE = 'GLOBAL_LOCAL_STORAGE_CHANGE',
    TRIGGER_SCROLL = 'GLOBAL_TRIGGER_SCROLL',
    SCROLL = 'GLOBAL_SCROLL',
    SCHEDULE_SCROLL = 'SCHEDULE_SCROLL',
}

export const dayList = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
];

export const JANUARY = 1;
export const DECEMBER = 12;

export enum PrintingOrientation {
    PORTRAIT = 'portrait',
    LANDSCAPE = 'landscape',
}

export enum PrintingPaperSize {
    LETTER = 'Letter',
    TABLOID = 'Tabloid',
    LEGAL = 'Legal',
    EXECUTIVE = 'Executive',
    A3 = 'A3',
    A4 = 'A4',
    A5 = 'A5',
    B4 = 'B4',
    B5 = 'B5',
}

const webUrl = `${window.location.protocol}//${window.location.host}`;
export const PrintingFonts = {
    Roboto: {
        normal: `${webUrl}/print/fonts/MPLUS1p-Regular.ttf`,
        bold: `${webUrl}/print/fonts/MPLUS1p-Bold.ttf`,
        italics: `${webUrl}/print/fonts/MPLUS1p-Black.ttf`,
        bolditalics: `${webUrl}/print/fonts/MPLUS1p-Black.ttf`,
    },
};

export enum UploadFileStatus {
    UPLOADING = 'uploading',
    DONE = 'done',
    ERROR = 'error',
    REMOVED = 'removed',
}

export enum ReadNotificationType {
    TEMPORARY_BOOKING = 'temporary_booking',
    ADMIN_NOTIFICATIONS = 'admin_notification',
    ROOM_BOOKING_CHANGE_STATUS = 'room_booking_change_status',
    ROOM_BOOKING = 'room_booking',
    FACILITY_BOOKING_CHANGE_STATUS = 'facility_booking_change_status',
}

export enum GA_EVENT {
    SEARCH_BY_KEYWORD = 'search_by_keyword',
}

export enum GA_KEYWORD_CONTENT {
    ROOM_MANAGEMENT = 'room_management',
    NOTIFICATION = 'notification',
    ROOM = 'room',
    ROOM_TYPE = 'room_type',
    MARKETING_CHANNEL = 'marketing_channel',
    PLAN = 'plan',
    SALE_ITEM = 'sale_item',
    SALE_GROUP = 'sale_group',
    HOTEL = 'hotel',
    INDICATOR = 'indicator',
    FACILITY = 'facility',
    FACILITY_BOOKING = 'facility_booking',
    GUEST = 'guest',
    GUEST_CLASSIFICATION = 'guest_classification',
    RANK_CALENDAR = 'rank_calendar',
    ROOM_BOOKING = 'room_booking',
}

export enum BookingEvents {
    BOOKING_DRAG_START = 'booking_drag_start',
    BOOKING_DRAG_END = 'booking_drag_end',
    BOOKING_DRAGGING = 'booking_dragging',
}

export const cellTextErrorStyle = { color: '#F40202' };
export const cellAutoGeneratedCodeStyle = { color: '#1C3E86' };
export const HEX_COLOR_LENGTH = 6;

// Fresh chat - FC
export const FC_ICON_WIDTH = 70;
export const FC_ICON_HEIGHT = 75;
export const FC_FRAME_ID = 'fc_frame';
export const FC_FRAME_WRAPPER_CLASS_NAME = 'fresh-chat-wrapper';

export const TOP_BAR_HEIGHT = 54;
export const STAFF_LAYOUT_HEADER_HEIGHT = 104;

export enum FilePathKey {
    GUEST = 'guest',
    ROOM_BOOKING = 'room-booking',
}
export enum DatePickerType {
    DATE = 'date',
    MONTH = 'month',
}

export enum RoomCleanStatusEnum {
    CLEANED = 'cleaned',
    UNCLEANED = 'uncleaned',
}
