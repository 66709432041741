/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, notification, Space } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import uniq from 'lodash/uniq';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showConfirm } from '~common/notification';
import { StaffLayoutHeader } from '~components';
import ImportCsvRoomType from '~features/room-type/components/ImportCsvRoomType/ImportCsvRoomType';
import { breadcrumbs } from '~features/room-type/constants';
import { IImportRoomTypeBodyItem } from '~features/room-type/interfaces';
import {
    hasImportErrorSelector,
    importCsvRoomType,
    importRoomTypeListSelector,
    setHasImportError,
    setImportRoomTypeList,
    setImportLoading,
    importLoadingSelector,
} from '~features/room-type/room-type.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './ImportCsvRoomTypePage.scss';

function ImportCsvRoomTypePage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const importLoading = useAppSelector(importLoadingSelector);
    const hasError = useAppSelector(hasImportErrorSelector);
    const importRoomTypeList = useAppSelector(importRoomTypeListSelector);
    const [responseError, setResponseError] = useState<Record<number, any>>({});

    useEffect(() => {
        dispatch(setImportLoading(false));
        if (!importRoomTypeList?.length) {
            navigate('/front-settings/room-type');
        }
    }, []);

    const onCancel = () => {
        dispatch(setImportRoomTypeList([]));
        dispatch(setHasImportError(false));
        navigate('/front-settings/room-type');
    };

    const showConfirmCancel = () => {
        showConfirm({
            title: t('roomType.list.cancelTitle'),
            onOk() {
                onCancel();
            },
        });
    };

    const importRoomType = useCallback(
        async (roomTypeList: IImportRoomTypeBodyItem[]) => {
            const response = await dispatch(importCsvRoomType(roomTypeList));
            if (importCsvRoomType.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('roomType.list.importSuccess'),
                    });
                    navigate('/front-settings/room-type');
                } else {
                    let errors = {};
                    uniq(
                        (response.payload?.errors || []).map((error) => error?.order),
                    ).forEach((order) => {
                        if (order) {
                            let currentError = {};
                            (response.payload?.errors || [])
                                .filter((error) => error?.order === order)
                                .forEach((error) => {
                                    if (
                                        (error?.key === 'name' ||
                                            error?.key === 'roomTypeCode' ||
                                            error?.key === 'abbreviation' ||
                                            error?.key === 'standardCapacity' ||
                                            error?.key === 'connectingRoomTypes' ||
                                            error?.key === 'marketingChannel') &&
                                        error?.order
                                    ) {
                                        currentError = {
                                            ...currentError,
                                            [error?.key]: error?.message,
                                        };
                                    }
                                });
                            errors = {
                                ...errors,
                                [order]: currentError,
                            };
                        }
                    });
                    dispatch(setHasImportError(true));
                    setResponseError(errors);
                    notification.error({
                        message: response.payload?.message || '',
                    });
                }
            }
        },
        [],
    );

    const onSubmit = () => {
        importRoomType(importRoomTypeList);
    };

    return (
        <div className="import-csv-room-type-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('roomType.list.importTitle')}
            />
            <Content>
                <div className="import-csv-room-type-content">
                    <ImportCsvRoomType responseError={responseError} />
                </div>
            </Content>
            <Footer>
                <div className="footer-btn-group">
                    <Space align="end">
                        <Button onClick={showConfirmCancel}>
                            {t('roomType.list.cancelButton')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={onSubmit}
                            loading={importLoading}
                            disabled={hasError}
                        >
                            {t('roomType.list.uploadButton')}
                        </Button>
                    </Space>
                </div>
            </Footer>
        </div>
    );
}

export default ImportCsvRoomTypePage;
