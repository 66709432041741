const indicatorList = {
    title: 'Room indicator',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        indicator: 'Room indicator',
    },
    searchColumn: {
        keyword: 'All',
        name: 'Room indicator name',
        indicatorType: 'Indicator Type',
        layout: 'Displayed size',
    },
    createBtn: 'Create room indicator',
    indicatorColumn: {
        id: 'ID',
        name: 'Room indicator name',
        indicatorType: 'Indicator type',
        layout: 'Displayed size',
    },
    modalConfirmDeletion: {
        title: 'Delete room indicator',
        cancelButton: 'Cancel',
        deleteButton: 'Delete',
    },
    message: { deleteSuccess: 'Indicator have been deleted successfully' },
};

export default indicatorList;
