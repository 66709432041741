import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse } from '~common/interfaces';
import {
    IInventory,
    IInventoryStopSellingListQuery,
    IStopSellingInventoryTableData,
    IStoppingRoomItem,
    IStoppingRoomInDurationItem,
} from '../interfaces';

class InventoryService extends ApiService {
    getStoppingInventory(params: IInventoryStopSellingListQuery) {
        return this._get(`${this.baseUrl}`, {
            params,
        }) as Promise<IBodyResponse<IStopSellingInventoryTableData[]>>;
    }

    getInventoryGroupByRoomType(params: IInventoryStopSellingListQuery) {
        return this._get(`${this.baseUrl}/group-by-room-type`, {
            params,
        }) as Promise<IBodyResponse<IInventory[]>>;
    }

    bulkStopInventoryInsertRoomType(body: IStoppingRoomItem[]) {
        return this._post(`${this.baseUrl}/bulk-save`, {
            items: body,
        }) as Promise<IBodyResponse<boolean>>;
    }

    bulkSaleSettingInsertRoomType(body: IStoppingRoomItem[]) {
        return this._post(`${this.baseUrl}/bulk-upsert-sale-setting`, {
            items: body,
        }) as Promise<IBodyResponse<boolean>>;
    }

    getInitStoppingRoomTypeList(payload: { date: string }) {
        return this._get(`${this.baseUrl}/init-data`, {
            params: payload,
        }) as Promise<IBodyResponse<IStoppingRoomItem[]>>;
    }

    getStopRoomInDuration(params: { startDate: string; endDate: string }) {
        return this._get(`${this.baseUrl}/stopping-room-in-duration`, {
            params,
        }) as Promise<IBodyResponse<IStoppingRoomInDurationItem[]>>;
    }
}

export const inventoryService = new InventoryService(
    { baseUrl: '/stopping-inventory' },
    axiosService,
);
