import { AppState } from '~plugins/redux-toolkit/store';
import { closingBookingListQueryDefault, ClosingBookingTabPane } from '../constants';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IClosingBookingState } from '../interfaces';
import { DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import { closingBookingService } from '../services/closing-booking.service';

const initialState: IClosingBookingState = {
    closingBookingListQuery: {
        ...closingBookingListQueryDefault,
    },
    showLoading: false,
    roomClosingList: [],
    totalRoomClosings: 0,
    facilityClosingList: [],
    totalFacilityClosings: 0,
    activeClosingBookingTab: ClosingBookingTabPane.ROOM_CLOSING,
};

export const getFacilityClosingList = createAsyncThunk(
    'roomClosing/getFacilityClosingList',
    async (_, { getState }) => {
        const state = getState() as AppState;
        return await closingBookingService.getFacilityClosingList(
            state.closingBooking.closingBookingListQuery,
        );
    },
);

export const getRoomClosingList = createAsyncThunk(
    'roomClosing/getRoomClosingList',
    async (_, { getState }) => {
        const state = getState() as AppState;
        return await closingBookingService.getRoomClosingList(
            state.closingBooking.closingBookingListQuery,
        );
    },
);

export const closingBookingSlice = createSlice({
    name: 'closing-booking',
    initialState,
    reducers: {
        setRoomClosingListQuery: (state, action) => {
            state.closingBookingListQuery = action.payload;
        },
        setActiveClosingBookingTab: (state, action) => {
            state.activeClosingBookingTab = action.payload;
        },
        resetState: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoomClosingList.pending, (state, action) => {
            state.roomClosingList = [];
            state.totalRoomClosings = 0;
            state.showLoading = true;
        });
        builder.addCase(getRoomClosingList.fulfilled, (state, action) => {
            state.totalRoomClosings = action.payload?.data?.totalItems || 0;
            state.roomClosingList = action.payload?.data?.items || [];
            state.showLoading = false;
        });
        builder.addCase(getFacilityClosingList.pending, (state, action) => {
            state.facilityClosingList = [];
            state.totalFacilityClosings = 0;
            state.showLoading = true;
        });
        builder.addCase(getFacilityClosingList.fulfilled, (state, action) => {
            state.totalFacilityClosings = action.payload?.data?.totalItems || 0;
            state.facilityClosingList = action.payload?.data?.items || [];
            state.showLoading = false;
        });
    },
});

export const { setRoomClosingListQuery, setActiveClosingBookingTab, resetState } =
    closingBookingSlice.actions;

export const roomClosingStateSelector = (state: AppState) => {
    return state.closingBooking;
};

export const totalRoomClosingPageSelector = (state: AppState) => {
    const { totalRoomClosings, closingBookingListQuery: roomClosingListQuery } =
        state.closingBooking;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = roomClosingListQuery;
    return Math.ceil(totalRoomClosings / limit);
};

export const totalFaciltiyClosingPageSelector = (state: AppState) => {
    const { totalFacilityClosings, closingBookingListQuery: roomClosingListQuery } =
        state.closingBooking;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = roomClosingListQuery;
    return Math.ceil(totalFacilityClosings / limit);
};

export default closingBookingSlice.reducer;
