import { parseDate } from '~plugins/dayjs';
import { useTranslation } from 'react-i18next';
import { INotification } from '~features/admin-notification/interfaces';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import './AdminNotificationItem.scss';
import { AdminNotificationTag } from '~features/admin-notification/constants';
import classNames from 'classnames';

type Prop = {
    notificationItem: INotification;
    isUnread: boolean;
    onClickNotificationItem: (isClosingBooking: boolean) => void;
};

function AdminNotificationItem(prop: Prop) {
    const { notificationItem, isUnread, onClickNotificationItem } = prop;
    const { t } = useTranslation();
    const isClosingBooking = notificationItem.tags?.some((notificationTag) => {
        return notificationTag.tag === AdminNotificationTag.CLOSING_BOOKING;
    });

    const renderAdminNotificationContent = () => {
        return isClosingBooking ? (
            <>{t('layout.staff.adminNotificationItem.closingBooking')}</>
        ) : (
            <>
                {notificationItem.title && <span>{`「${notificationItem.title}」`}</span>}
                {notificationItem.content}
            </>
        );
    };

    return (
        <div
            className={classNames('notification-bell', {
                'closing-booking-notify': isClosingBooking,
            })}
            key={notificationItem.id}
            onClick={() => onClickNotificationItem(isClosingBooking)}
        >
            <div className="avatar">
                <Avatar size={32} icon={<UserOutlined />} />
            </div>
            <div className="content">
                <div className="notification-bell-header">
                    <div className="notification-bell-title">
                        {t('layout.staff.notificationDate', {
                            month: parseDate(notificationItem.createdAt).month() + 1,
                            date: parseDate(notificationItem.createdAt).date(),
                        })}
                    </div>
                    <div className="notification-bell-notify-at">
                        {parseDate(notificationItem.createdAt).fmHHmm()}
                    </div>
                    {isUnread && (
                        <div className="notification-bell-new-tag">
                            {t('layout.staff.newTag')}
                        </div>
                    )}
                </div>
                <div className="notification-bell-content">
                    {renderAdminNotificationContent()}
                </div>
            </div>
        </div>
    );
}

export default AdminNotificationItem;
