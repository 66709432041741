export default {
    cleaningList: 'Cleaning list',
    date: '{{year}}/{{month}}/{{day}} ({{dayOfWeek}}) {{time}}',
    day: {
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
        sunday: 'Sun',
    },
    no: 'No',
    roomName: 'Room name',
    roomType: 'Room type',
    numberOfNights: 'Number of nights',
    isCleaned: 'Cleaning status',
    cleaned: 'Cleaned',
    uncleaned: 'Uncleaned',
    currentStatus: 'Current status',
    status: {
        all: 'All',
        stay: 'Checked in',
        vacancy: 'Vacant',
        stop_selling: 'Stopped',
        check_out: 'Checked out',
    },
    schedule: 'Scheduled check in',
    time: 'Time',
    numberOfGuests: 'Number of guests',
    memo: 'Memo',
    personInCharge: 'PIC',
    notification: {
        fetchFailed: 'An error occurred',
    },
};
