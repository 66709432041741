import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row, notification } from 'antd';
import { IRoomBulkUpdateForm, IRoomBulkUpdateFormItem } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { bulkUpdateRoomsSchema } from '../../schema';
import { InputText, RightDrawerLayout, SingleSelect } from '~components';
import {
    bulkUpdateRooms,
    fetchRoomList,
    isShowUpdateIndividualRoomFormSelector,
    setIsShowUpdateIndividualRoomForm,
    updateIndividualRoomListSelector,
    updateIndvidualRoomFormBusySelector,
} from '../../room.reducer';
import {
    getRoomTypeListForDropdown,
    roomTypeDropdownOptionsSelector,
} from '~features/room-type/room-type.reducer';
import './UpdateIndividualRoomsForm.scss';
import { ErrorMessageType } from '~common/constants';
import { compareFormData, validateUniqueValue } from '~common/commonFunctions';
import { showConfirm } from '~common/notification';
import { useEscape } from '~common/useHooks';

function UpdateIndividualRoomsForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, handleSubmit, reset, setError, getValues } =
        useForm<IRoomBulkUpdateForm>({
            resolver: yupResolver(bulkUpdateRoomsSchema),
            defaultValues: {},
        });

    const isShowForm = useAppSelector(isShowUpdateIndividualRoomFormSelector);
    const formBusy = useAppSelector(updateIndvidualRoomFormBusySelector);
    const roomList = useAppSelector(updateIndividualRoomListSelector);
    const roomTypeOptions = useAppSelector(roomTypeDropdownOptionsSelector);
    const closeDialog = () => {
        reset({});
        dispatch(setIsShowUpdateIndividualRoomForm(false));
    };
    const [formDataDefault, setFormDataDefault] = useState<
        IRoomBulkUpdateFormItem[] | []
    >([]);

    const makeFormData = () => {
        const { items } = getValues();
        return items.map((room, index) => ({
            id: room.id,
            name: room.name,
            roomTypeId: room.roomTypeId,
            order: index + 1,
        }));
    };

    const _bulkUpdateRooms = useCallback(async () => {
        const response = await dispatch(bulkUpdateRooms(makeFormData()));
        if (bulkUpdateRooms.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('room.roomFormIndividual.updateSuccessMessage'),
                });
                dispatch(setIsShowUpdateIndividualRoomForm(false));
                dispatch(fetchRoomList());
            } else {
                (response.payload?.errors || []).forEach((error) => {
                    if (
                        (error.key === 'name' || error.key === 'roomTypeId') &&
                        error.order
                    ) {
                        setError(
                            `items.${error.order - 1}.${error.key}`,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                });
                notification.error({
                    message: response.payload?.message || '',
                });
            }
        }
    }, []);

    const onSubmit = () => {
        handleSubmit(async (data) => {
            const updatedRooms = getValues().items || [];
            if (!updatedRooms.length) {
                return;
            }
            const duplicatedNameIndexs = validateUniqueValue(updatedRooms, 'name');
            if (duplicatedNameIndexs.length) {
                duplicatedNameIndexs.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('room.roomFormIndividual.roomNameDuplicated'),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkUpdateRooms();
        })();
    };

    useEffect(() => {
        reset({
            items: roomList,
        });
        setFormDataDefault(makeFormData());
    }, [roomList]);

    useEffect(() => {
        dispatch(
            getRoomTypeListForDropdown({
                isConnectingRoomType: false,
                isPullFromTll: false,
            }),
        );
    }, [isShowForm]);

    const confirmBeforeClose = () => {
        if (compareFormData(makeFormData(), formDataDefault)) {
            closeDialog();
            return;
        }
        showConfirm({
            title: t('roomType.form.confirm.cancelTitle'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    return (
        <div className="update-individual-rooms-form-wrapper">
            <RightDrawerLayout
                open={isShowForm}
                onClose={confirmBeforeClose}
                onSubmit={onSubmit}
                title={t('room.roomFormIndividual.updateForm.title')}
                cancelText={t('common.buttonCancelText')}
                submitText={t('common.buttonSaveText')}
                className="create-room-drawer"
                busy={formBusy}
                loading={formBusy}
            >
                <Form layout="vertical" autoComplete="off" scrollToFirstError>
                    {roomList.map((room, index) => {
                        return (
                            <Row key={room.id} gutter={8}>
                                <Col span={6}>
                                    <InputText
                                        label={index === 0 ? 'ID' : ''}
                                        placeholder=""
                                        name={`items.${index}.autoGeneratedCode`}
                                        control={control}
                                        disabled={true}
                                    />
                                </Col>
                                <Col span={9}>
                                    <InputText
                                        label={
                                            index === 0
                                                ? t('room.roomFormIndividual.roomName')
                                                : ''
                                        }
                                        placeholder={t(
                                            'room.roomFormIndividual.roomName',
                                        )}
                                        name={`items.${index}.name`}
                                        control={control}
                                    />
                                </Col>
                                <Col span={9}>
                                    <SingleSelect
                                        label={
                                            index === 0
                                                ? t('room.roomFormIndividual.roomType')
                                                : ''
                                        }
                                        placeholder={t(
                                            'room.roomFormIndividual.roomType',
                                        )}
                                        name={`items.${index}.roomTypeId`}
                                        control={control}
                                        options={roomTypeOptions}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </Form>
            </RightDrawerLayout>
        </div>
    );
}

export default UpdateIndividualRoomsForm;
