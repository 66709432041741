import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';

export function NoData() {
    const { t } = useTranslation();
    return (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('layout.staff.noData')}
        />
    );
}
