import { useCallback } from 'react';
import { TextTruncate } from '~components';
import { AvailableRoomTypeColor } from '~features/dashboard/constants';
import { IStatisticRoomType } from '~features/dashboard/interfaces';
import './RoomOccupancyItem.scss';

interface IProps {
    roomType?: IStatisticRoomType;
}
export default function RoomOccupancyItem({ roomType }: IProps) {
    const calculateRoomTypeColor = useCallback(
        (roomType = {} as IStatisticRoomType) => {
            let color;
            const availableRatio =
                (roomType.availableRoomCount / roomType.totalRoomCount) * 100;
            if (availableRatio === 0) color = AvailableRoomTypeColor.RED;
            else if (availableRatio < 50)
                color = AvailableRoomTypeColor.DARK_YELLOW_BROWNISH;
            else color = AvailableRoomTypeColor.BLUE;
            return color;
        },
        [roomType],
    );

    return (
        <div className="room-occupancy-item-wrapper">
            <div className="room-type">
                <TextTruncate text={roomType?.name || ''} />
            </div>
            <div
                className="room-occupancy"
                style={{ color: calculateRoomTypeColor(roomType) }}
            >
                <span className="available-room">
                    {roomType?.availableRoomCount.toString().padStart(2, '0')}
                </span>
                <span className="total-room">
                    /{roomType?.totalRoomCount.toString().padStart(2, '0')}
                </span>
            </div>
        </div>
    );
}
