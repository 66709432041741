export const salesByPlansReportJa = {
    title: 'プラン別売上表',
    breadcrumbs: {
        report: '帳票',
        salesByPlans: 'プラン別売上表',
    },
    filterForm: {
        pickerLabel: '日付',
        planLabel: 'プラン',
        allOption: '全て',
        displayMode: '日毎に表示',
        filterButton: '表示',
    },
    tableColumns: {
        date: '日付',
        lastMonth: '先月',
        currentMonth: '今月',
        nextMonth: '来月',
        plan: 'プラン',
        totalBookedRoom: '室数',
        totalAdult: '人数 (大人)',
        totalChild: '人数 (子供)',
        totalRevenue: '金額',
        avgRevenue: '平均単価',
        occupancyRate: '稼働率',
        total: '合計 (税込み)',
    },
    tableHeader: '※ グレイ字は前年同日の情報',
    total: '合計 (税込み)',
    unit: '¥',
    percentage: '%',
};
