import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse } from '~common/interfaces';
import { IIntegrationsState, IHotelSmartIntegration } from '../interfaces';

class IntegrationsService extends ApiService {
    getHotelSmartConfig() {
        return this._get(`${this.baseUrl}/hotel-smart-configuration`) as Promise<
            IBodyResponse<IIntegrationsState>
        >;
    }

    integrateHotelSmart({
        username,
        password,
        enableIntegration,
        checkedInDate,
        houseId,
    }: IHotelSmartIntegration) {
        return this._post(`${this.baseUrl}/hotel-smart-configuration`, {
            username,
            password,
            checkedInDate,
            enableIntegration,
            houseId,
        }) as Promise<IBodyResponse<IIntegrationsState>>;
    }

    updateHotelSmart(body: IHotelSmartIntegration) {
        return this._patch(`${this.baseUrl}/hotel-smart-configuration`, body) as Promise<
            IBodyResponse<IHotelSmartIntegration>
        >;
    }
}

export const integrationsService = new IntegrationsService(
    { baseUrl: '/hotel-smart' },
    axiosService,
);
