import i18next from '~plugins/i18next/i18n';

export const breadcrumbs = () => {
    return [
        {
            text: i18next.t('csvBookingUploadLocale.text.breadcrumbs.backOffice'),
        },
        {
            text: i18next.t('csvBookingUploadLocale.text.breadcrumbs.csvBookingUpload'),
        },
    ];
};

export const errorMessagesGroup = {
    BOOKING_ALREADY_EXISTS: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.bookingAlreadyExist',
    ),
    INVALID_OTA_CODE: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.invalidOTACode',
    ),
    INVALID_PLAN_NAME: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.invalidPlanName',
    ),
    INVALID_ROOM_TYPE: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.invalidRoomType',
    ),
    INVALID_RESERVER: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.invalidReserver',
    ),
    INVALID_GUEST: i18next.t('csvBookingUploadLocale.text.errorMessages.invalidGuest'),
    INVALID_AMOUNT: i18next.t('csvBookingUploadLocale.text.errorMessages.invalidAmount'),
    INVALID_CHECK_IN_TIME: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.invalidCheckInTime',
    ),
    INVALID_STATUS: i18next.t('csvBookingUploadLocale.text.errorMessages.invalidStatus'),
    INVALID_NUMBER_OF_DAYS: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.invalidNumberOfDays',
    ),
    PAYMENT_METHOD_NOT_FOUND: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.paymentMethodNotFound',
    ),
    FILE_NOT_UPLOADED: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.fileNotUploaded',
    ),
    LOGGED_IN_HOTEL_ID_IS_DIFFERENT_FROM_CSV_HOTEL_ID: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.loggedInHotelIdIsDifferentFromCSVHotelId',
    ),
    MAX_LINE_LIMIT_EXCEEDED: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.maxLineLimitExceeded',
    ),
    NO_CONTENT_FOUND: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.noContentFound',
    ),
    INVALID_MAIL_ADDRESS: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.invalidMailAddress',
    ),
    BOOKING_CREATION_FAILED: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.bookingCreationFailed',
    ),
};

export const errorMessagesSeparated = {
    tabist_hotel_id: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.tabistHotelIdEmpty',
    ),
    group_booking_id: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.groupBookingIdEmpty',
    ),
    reservation_name: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.reservationNameEmpty',
    ),
    reservation_person_furigana: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.reservationFuriganaEmpty',
    ),
    plan_name: i18next.t('csvBookingUploadLocale.text.errorMessages.planNameEmpty'),
    reservation_person_phone_number: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.reservationPhoneNumberEmpty',
    ),
    room_type: i18next.t('csvBookingUploadLocale.text.errorMessages.roomTypeEmpty'),
    number_of_people: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.numberOfPeopleEmpty',
    ),
    reservation_date: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.reservationDateInvalid',
    ),
    check_in_date: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.checkInDateInvalid',
    ),
    check_out_date: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.checkOutDateInvalid',
    ),
    total_amount: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.totalAmountInvalid',
    ),
    advance_deposit_amount: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.advanceAmountInvalid',
    ),
    amount_of_points_used: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.pointsInvalid',
    ),
    check_in_time: i18next.t(
        'csvBookingUploadLocale.text.errorMessages.checkInTimeEmpty',
    ),
    status: i18next.t('csvBookingUploadLocale.text.errorMessages.statusEmpty'),
};
