// Cognito error code list
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_AdminListUserAuthEvents.html

export enum CognitoError {
    EMAIL_REGISTERED,
    PASSWORD_ATTEMPTS_EXCEEDED,
    PASSWORD_ATTEMPTS_EXCEEDED_WAIT,
    USERNAME_SHOULD_BE_EMAIL,
    INCORRECT_USERNAME_OR_PASSWORD,
    UNEXPECTED_ERROR,
}

export const getCognitoError = (
    code: string | undefined,
    message?: string | undefined,
) => {
    switch (code) {
        case 'InvalidParameterException':
            return CognitoError.USERNAME_SHOULD_BE_EMAIL;
        case 'TooManyRequestsException':
            return CognitoError.PASSWORD_ATTEMPTS_EXCEEDED_WAIT;
        case 'LimitExceededException':
            return CognitoError.PASSWORD_ATTEMPTS_EXCEEDED_WAIT;
        case 'NotAuthorizedException':
            switch (message) {
                case 'Password attempts exceeded':
                    return CognitoError.PASSWORD_ATTEMPTS_EXCEEDED;
                case 'Attempt limit exceeded, please try after some time.':
                    return CognitoError.PASSWORD_ATTEMPTS_EXCEEDED_WAIT;
                default:
                    return CognitoError.INCORRECT_USERNAME_OR_PASSWORD;
            }
        case 'UsernameExistsException':
            return CognitoError.EMAIL_REGISTERED;
        case 'InternalErrorException':
            return CognitoError.UNEXPECTED_ERROR;
        case 'ResourceNotFoundException':
            return CognitoError.UNEXPECTED_ERROR;
        default:
            return CognitoError.UNEXPECTED_ERROR;
    }
};
