import { IPosition } from '~features/room-booking/interfaces';
import { getElementFromPosition, removeSelection } from '~features/room-booking/util';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '~plugins/redux-toolkit/store';
import { IFacilityBooking, IFacilityBookingScheduleItem } from './interfaces';
import { CreateFacilityBookingModal } from './pages/FacilitySchedulePage/components/CreateFacilityBookingModal/CreateFacilityBookingModal';
import { bookingScheduleDefault } from './model';
import { parseDate, todayDayjs } from '~plugins/dayjs';
import { BookingDetail } from './pages/FacilitySchedulePage/components/BookingDetail/BookingDetail';
import { NavigateFunction } from 'react-router-dom';
import UpdateBookingFormPopup from './pages/FacilitySchedulePage/components/UpdateBookingFormPopup/UpdateBookingFormPopup';
import { ConfigProvider } from 'antd';
import { theme } from '../../theme/theme';

export function showCreateBookingModal(
    booking: IFacilityBookingScheduleItem,
    element: HTMLElement,
    onClose?: () => void,
) {
    const root = ReactDOM.createRoot(element);
    root.render(
        <Provider store={store}>
            <ConfigProvider button={{ autoInsertSpace: false }} theme={theme}>
                <CreateFacilityBookingModal
                    booking={booking}
                    close={() => {
                        if (onClose) {
                            onClose();
                        }
                        root.unmount();
                    }}
                />
            </ConfigProvider>
        </Provider>,
    );
}

export function endSelect(options: {
    panelId: string;
    startPosition: IPosition;
    currentCursor: IPosition;
    onClose?: () => void;
}) {
    const { elementStart, elementEnd } = getElementFromPosition(options);

    if (elementStart && elementEnd) {
        // TODO: end select facility week view
        const startDate = elementStart.dataset.day;
        const endDate = elementEnd.dataset.day;
        const facilityId = elementStart.dataset.facility_id;

        let _endDateTime = '';
        const currentRoundUpTime = todayDayjs.add(1, 'hour').startOf('hour');
        const _startDateTime = parseDate(
            `${startDate} ${currentRoundUpTime.fmHHmm()}`,
        )?.fmYYYYMMDDHHmm();
        _endDateTime = parseDate(
            `${endDate} ${parseDate(_startDateTime).add(3, 'hour')?.fmHHmm()}`,
        )?.fmYYYYMMDDHHmm();
        if (parseDate(_startDateTime).isAfter(parseDate(_endDateTime))) {
            _endDateTime = parseDate(_endDateTime).add(1, 'day')?.fmYYYYMMDDHHmm();
        }

        const booking: IFacilityBookingScheduleItem = {
            ...bookingScheduleDefault(),
            checkInTime: currentRoundUpTime.fmHHmm() || '',
            checkOutTime: currentRoundUpTime.clone().add(3, 'hour')?.fmHHmm() || '',
            checkInDateTime: _startDateTime,
            checkOutDateTime: _endDateTime,
            facilityId: facilityId ? Number(facilityId) : 0,
        };
        showCreateBookingModal(booking, elementStart, () => {
            options.onClose && options.onClose();
            removeSelection();
        });
    } else {
        removeSelection();
    }
}

export function endSelectHour(options: {
    panelId: string;
    startPosition: IPosition;
    currentCursor: IPosition;
}) {
    const { elementStart, elementEnd } = getElementFromPosition(options);

    if (elementStart && elementEnd) {
        // TODO: end select facility week view
        const startDate = elementStart.dataset.day;
        const startTime = elementStart.dataset.hours;
        const facilityId = elementStart.dataset.facility_id;
        const endDate = elementEnd.dataset.day;
        const endTime = elementEnd.dataset.hours;

        const booking: IFacilityBookingScheduleItem = {
            ...bookingScheduleDefault(),
            checkInTime: startTime || '',
            checkOutTime: endTime || '',
            checkInDateTime: startDate + ' ' + startTime,
            checkOutDateTime: endDate + ' ' + endTime,
            facilityId: facilityId ? Number(facilityId) : 0,
        };

        showCreateBookingModal(booking, elementStart, () => {
            removeSelection();
        });
    } else {
        removeSelection();
    }
}

export function showBookingDetailModal(options: {
    booking: IFacilityBookingScheduleItem;
    element: HTMLElement;
    onClose?: () => void;
    navigate?: NavigateFunction;
    onChangeStatusSuccess?: (booking: IFacilityBookingScheduleItem) => void;
    isReadOnly?: boolean;
    isShowEditBtn?: boolean;
    position?: { pageX: number; pageY: number };
}) {
    removeSelection();
    const {
        booking,
        element,
        onClose,
        navigate,
        onChangeStatusSuccess,
        isReadOnly,
        isShowEditBtn,
        position,
    } = options;
    const root = ReactDOM.createRoot(element);
    root.render(
        <Provider store={store}>
            <ConfigProvider button={{ autoInsertSpace: false }} theme={theme}>
                <BookingDetail
                    booking={booking}
                    isOpen={true}
                    onClose={() => {
                        if (onClose) {
                            onClose();
                        }
                        root.unmount();
                    }}
                    navigate={navigate}
                    onChangeStatusSuccess={(booking) => {
                        if (onChangeStatusSuccess) onChangeStatusSuccess(booking);
                    }}
                    isReadOnly={isReadOnly}
                    isShowEditBtn={isShowEditBtn}
                    position={position}
                    element={element}
                />
            </ConfigProvider>
        </Provider>,
    );
}

export function showUpdateBookingFormModal(options: {
    booking: IFacilityBooking;
    element?: HTMLElement;
    onClose?: () => void;
    onUpdateSuccess?: (booking: IFacilityBookingScheduleItem) => void;
}) {
    removeSelection();
    const { booking, element, onClose } = options;
    if (!element) return;
    const root = ReactDOM.createRoot(element);
    root.render(
        <Provider store={store}>
            <ConfigProvider button={{ autoInsertSpace: false }} theme={theme}>
                <UpdateBookingFormPopup
                    booking={booking}
                    isOpen={true}
                    onClose={() => {
                        if (onClose) {
                            onClose();
                        }
                        root.unmount();
                    }}
                />
            </ConfigProvider>
        </Provider>,
    );
}
