import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseDateTime } from '~common/commonFunctions';
import { TllRank, TllRankDropdown } from '~common/constants';
import { SingleSelect } from '~components';
import { mergePlanRankDurations } from '~features/rank-calendar/helper';
import {
    rankCalendarFormSelector,
    setRankCalendarForm,
} from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { updateTllRankSchema } from '~features/rank-calendar/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './ModalUpdateDurations.scss';

interface IProps {
    control: any;
    showModal: boolean;
    onCancelModal: () => void;
    setSelectedDate: any;
    selectedDate: string[];
    setValue: any;
    objectPlanDurations: { current: { [key: string]: { tllRank: string | null } } };
}
function ModalUpdateDurations({
    showModal,
    onCancelModal,
    selectedDate,
    setSelectedDate,
    setValue,
    objectPlanDurations,
}: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const rankCalendarForm = useAppSelector(rankCalendarFormSelector);
    const [tllRank, setTllRank] = useState<string | null>(null);
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(updateTllRankSchema),
    });

    const onChange = (value: string) => {
        setTllRank(value);
    };

    useEffect(() => {
        setTllRank(TllRankDropdown?.[0]?.value);
    }, []);

    useEffect(() => {
        reset();
    }, [showModal]);

    const onSubmit = () => {
        handleSubmit(() => {
            selectedDate.forEach((date) => {
                setValue(date, tllRank);
                objectPlanDurations.current[`${parseDateTime(date)}`] = {
                    tllRank: tllRank,
                };
            });
            selectedDate.forEach((date) => {
                setValue(`checkbox.${date}`, false);
            });
            const planDurationList = Object.keys(objectPlanDurations.current).map(
                (planDuration) => {
                    return {
                        tllRank: objectPlanDurations.current[`${planDuration}`]
                            .tllRank as TllRank,
                        startDate: planDuration,
                        endDate: planDuration,
                    };
                },
            );
            if (rankCalendarForm) {
                const { planRankDurations } = rankCalendarForm;
                dispatch(
                    setRankCalendarForm(
                        mergePlanRankDurations(
                            {
                                ...rankCalendarForm,
                                planRankDurations: [
                                    ...planRankDurations,
                                    ...planDurationList,
                                ],
                            },
                            rankCalendarForm,
                        ),
                    ),
                );
            }
            setSelectedDate([]);
            onCancelModal();
        })();
    };
    return (
        <Modal
            open={showModal}
            title={t('rankCalendar.update.modalUpdate.title')}
            cancelText={t('rankCalendar.update.modalUpdate.cancelBtn')}
            okText={t('rankCalendar.update.modalUpdate.submitBtn')}
            onCancel={onCancelModal}
            onOk={onSubmit}
            width={450}
            mask={false}
            maskClosable={false}
            centered={true}
            className="update-modal-wrapper"
        >
            <Form layout="vertical">
                <SingleSelect
                    name="tllRank"
                    label={t('rankCalendar.update.modalUpdate.label')}
                    placeholder={t('rankCalendar.update.modalUpdate.placeholder')}
                    control={control}
                    options={TllRankDropdown}
                    showSearch={false}
                    onChange={onChange}
                    className="update-modal-select"
                />
            </Form>
        </Modal>
    );
}

export default ModalUpdateDurations;
