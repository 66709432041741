import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { salesByPlansSelector } from '~features/report/reducers/sales-by-plans.reducer';
import { useAppSelector } from '~hooks';
import { CacheKeys } from '~queries/queries';
import './SalesByPlansTable.scss';
import { useMemo } from 'react';
import { parseDate } from '~plugins/dayjs';
import {
    generateDailyData,
    generateDailySummary,
    generateMonthlyData,
    generateMonthlySummary,
} from './helper';
import { ISalesByPlansResponse } from '../../interfaces';
import { DailySalesByPlansTable } from './DailySalesByPlansTable';
import { MonthlySalesByPlans } from './MonthlySalesByPlansTable';

type PlanDropDownType = {
    value: number;
    label: string;
}[];

function SalesByPlansTable() {
    const { t } = useTranslation();
    const { isShowByDaily, selectedDate, selectedPlanId } =
        useAppSelector(salesByPlansSelector);

    const { data, isFetching } = useQuery({
        queryKey: [CacheKeys.getSalesByPlansReportKey],
        enabled: false,
    });

    const { data: planDropdown } = useQuery({
        queryKey: [CacheKeys.getPlanDropdown],
        enabled: true,
    });

    const { daysInMonth, filterMonth, filterYear } = useMemo(() => {
        return {
            daysInMonth: parseDate(selectedDate).daysInMonth(),
            filterMonth: parseDate(selectedDate).month() + 1,
            filterYear: parseDate(selectedDate).year(),
        };
    }, [selectedDate]);

    const filteredPlanList = useMemo(() => {
        if (planDropdown) {
            return (planDropdown as PlanDropDownType).filter((plan) => {
                if (!selectedPlanId) return true;
                return plan.value === selectedPlanId;
            });
        }
    }, [planDropdown, selectedPlanId]);

    const dailyDataSource = useMemo(() => {
        if (filteredPlanList?.length === 0) return [];
        if (data && isShowByDaily) {
            return Array.from({ length: daysInMonth }, (_, i) => {
                const date = i + 1;
                return {
                    id: `${filterMonth}/${date}/${filterYear}`,
                    date: `${filterMonth}/${date}/${filterYear}`,
                    ...generateDailyData(
                        data as ISalesByPlansResponse,
                        filterYear,
                        filterMonth,
                        date,
                    ),
                };
            });
        }
    }, [
        data,
        selectedDate,
        daysInMonth,
        filterMonth,
        filterYear,
        filteredPlanList,
        isShowByDaily,
    ]);

    const dailySummaryData = useMemo(() => {
        if (filteredPlanList?.length === 0) return [] as any;
        if (data && isShowByDaily) {
            return generateDailySummary(data as ISalesByPlansResponse);
        }
    }, [data, selectedDate, filterMonth, filterYear, filteredPlanList, isShowByDaily]);

    const monthlyDataSource = useMemo(() => {
        if (filteredPlanList?.length === 0) return [];
        if (data) {
            return filteredPlanList?.map((plan) => {
                return {
                    plan: plan.label,
                    id: plan.value,
                    ...generateMonthlyData(
                        data as ISalesByPlansResponse,
                        Number(plan.value),
                        selectedDate,
                    ),
                };
            });
        }
    }, [data, filterMonth, filterYear, filteredPlanList, selectedDate, isShowByDaily]);

    const monthlySummaryData = useMemo(() => {
        if (filteredPlanList?.length === 0) return [] as any;
        if (data) {
            return generateMonthlySummary(data as ISalesByPlansResponse, selectedDate);
        }
    }, [data, selectedDate, filterMonth, filterYear, filteredPlanList, isShowByDaily]);

    return (
        <div className="report-sales-by-plans-table-wrapper">
            <div className="table-header">{t('report.salesByPlans.tableHeader')}</div>
            {isShowByDaily ? (
                <DailySalesByPlansTable
                    dataSource={dailyDataSource}
                    summaryData={dailySummaryData}
                    filteredPlanList={filteredPlanList as PlanDropDownType}
                    loading={isFetching}
                />
            ) : (
                <MonthlySalesByPlans
                    dataSource={monthlyDataSource as any}
                    summaryData={monthlySummaryData}
                    loading={isFetching}
                />
            )}
        </div>
    );
}

export default SalesByPlansTable;
