import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';

import {
    ISaleGroupListDropdownResponse,
    ISaleItemFormItem,
    ISaleItemBulkDeleteItem,
    ISaleItemDetail,
    ISaleItemDropDownQuery,
    ISaleItemExportCsvQuery,
    ISaleItemListDropdownResponse,
    ISaleItemListResponse,
    ITaxListDropdownResponse,
    ISaleItems,
    IImportSaleItem,
} from '../interfaces';
import { IBodyResponse, ICommonListQuery, ICsvFile } from '~common/interfaces';
import { IGetTaxListForDropdownQuery } from '~features/tax/constants';
class SaleItemService extends ApiService {
    getDropdown(params: ISaleItemDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<ISaleItemListDropdownResponse>;
    }

    getList(queryString: ICommonListQuery) {
        return this._getList(queryString) as Promise<ISaleItemListResponse>;
    }

    bulkDelete(bulkDeleteBody: ISaleItemBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    exportCsv(query: ISaleItemExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }

    getSaleGroupListForDropdown() {
        return this._get(
            `/sale-group/dropdown`,
        ) as Promise<ISaleGroupListDropdownResponse>;
    }

    getTaxListForDropdown(query: IGetTaxListForDropdownQuery) {
        return this._get(`/tax/dropdown`, {
            params: { ...query },
        }) as Promise<ITaxListDropdownResponse>;
    }

    bulkCreate(bulkCreateBody: ISaleItemFormItem[]) {
        return this._post(`${this.baseUrl}/bulk-create`, {
            items: bulkCreateBody,
        }) as Promise<IBodyResponse<ISaleItems[]>>;
    }

    getListDetail(ids: number[]) {
        return this._get(`${this.baseUrl}/get-by-ids`, {
            params: {
                ids,
            },
        }) as Promise<IBodyResponse<ISaleItemDetail[]>>;
    }

    bulkUpdate(bulkUpdateBody: ISaleItemFormItem[]) {
        return this._patch(`${this.baseUrl}/bulk-update`, {
            items: bulkUpdateBody,
        }) as Promise<IBodyResponse<ISaleItems[]>>;
    }

    importRoomCsv(importSaleItemBody: IImportSaleItem[]) {
        return this._post(`${this.baseUrl}/import-csv`, {
            items: importSaleItemBody,
        }) as Promise<IBodyResponse<IImportSaleItem>>;
    }
}

export const saleItemService = new SaleItemService(
    { baseUrl: '/sale-item' },
    axiosService,
);
