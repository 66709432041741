import { Button } from 'antd';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RangePicker } from '~components';
import {
    getMealReport,
    mealReportStateSelector,
    setExportCsv,
    setIsShowTable,
    setMealReportQuery,
} from '~features/report/reducers/meal-report.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { type Dayjs } from '~plugins/dayjs';
import './FilterForm.scss';

type RangeValue = [Dayjs | null, Dayjs | null] | null;
type FormState = {
    datePeriod: RangeValue;
};

export default function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, getValues, handleSubmit } = useForm<FormState>({
        defaultValues: {
            datePeriod: [
                customDayjs().startOf('day'),
                customDayjs().add(6, 'days').endOf('day'),
            ],
        },
    });
    const { isShowTable } = useAppSelector(mealReportStateSelector);
    const [dates, setDates] = useState<RangeValue>(null);

    useEffect(() => {
        dispatch(setIsShowTable(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disabledDate = (current: Dayjs) => {
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= 7;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
        return !!tooEarly || !!tooLate;
    };

    const onOpenChange = (open: boolean) => {
        if (open) {
            setDates([null, null]);
        } else {
            setDates(null);
        }
    };

    const onSubmit = () => {
        handleSubmit(({ datePeriod }) => {
            let queryDatePeriod: [string, string];
            if (datePeriod === null || !datePeriod[0] || !datePeriod[1]) {
                const currentTime = customDayjs();
                queryDatePeriod = [
                    currentTime.startOf('day').fmYYYYMMDDHHmmss(),
                    currentTime.endOf('day').fmYYYYMMDDHHmmss(),
                ];
            } else {
                queryDatePeriod = [
                    datePeriod[0].startOf('day').fmYYYYMMDDHHmmss(),
                    datePeriod[1].endOf('day').fmYYYYMMDDHHmmss(),
                ];
            }
            dispatch(setMealReportQuery({ datePeriod: queryDatePeriod }));
            dispatch(getMealReport());
        })();
    };

    const exportCSV = () => {
        dispatch(setExportCsv(true));
    };

    return (
        <section className="report-meal-filter-form-wrapper">
            <div className="filter-form-content">
                <RangePicker
                    name="datePeriod"
                    label={t('report.mealReport.filterForm.dateLabel')}
                    placeholder={[
                        t('report.placeholder.rangeDatePicker.start'),
                        t('report.placeholder.rangeDatePicker.end'),
                    ]}
                    hideHelpText={true}
                    allowClear={false}
                    value={dates || getValues().datePeriod}
                    control={control}
                    disabledDate={disabledDate}
                    onOpenChange={onOpenChange}
                    onCalendarChange={(val) => {
                        setDates(val);
                    }}
                />
                <Button type="primary" className="submit-btn" onClick={onSubmit}>
                    {t('report.mealReport.filterForm.displayButton')}
                </Button>
            </div>
            {isShowTable && <Button onClick={exportCSV}>{t('report.exportCSV')}</Button>}
        </section>
    );
}
