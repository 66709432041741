import { useMemo } from 'react';
import './SettingTable.scss';
import { useAppDispatch, useAppSelector } from '~hooks';
import classNames from 'classnames';
import { SettingCell } from '../SettingCell/SettingCell';
import {
    getFacilityBookings,
    getRoomListStatus,
    roomManagementDetailSelector,
    validRoomsSelector,
} from '~features/room-management/reducers/room-management.reducer';
import { CheckinModal } from '~features/room-booking/pages/SchedulePage/components/CheckinModal/CheckinModal';
import { IndicatorSize } from '~features/indicator/constants';
import FacilityBookingCheckInForm from '~features/facility-booking/pages/FacilitySchedulePage/components/FacilityBookingCheckInForm/FacilityBookingCheckInForm';
import { setIsShowFacilityBookingScheduleListModal } from '~features/facility-booking/reducers/facility-schedule.reducer';
import { FacilityBookingScheduleListModal } from '~features/facility-booking/pages/FacilitySchedulePage/components/FacilityBookingScheduleListModal/FacilityBookingListScheduleModal';
import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, UserGroup } from '~common/constants';

export const SettingTable = () => {
    const roomManagementDetail = useAppSelector(roomManagementDetailSelector);
    const validRooms = useAppSelector(validRoomsSelector);
    const dispatch = useAppDispatch();

    const isReadOnly = useMemo(() => {
        return !checkUserPermission([
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ]);
    }, []);

    const settingSize = useMemo(() => {
        return roomManagementDetail?.layout || IndicatorSize.SMALL;
    }, [roomManagementDetail]);

    const columnLength = useMemo(() => {
        return Number(settingSize.split('x')[0]);
    }, [settingSize]);

    const rowLength = useMemo(() => {
        return Number(settingSize.split('x')[1]);
    }, [settingSize]);

    const fetchFacilityBookingList = () => {
        // get facility booking after change status success
        dispatch(getFacilityBookings());
        dispatch(setIsShowFacilityBookingScheduleListModal(false));
    };

    const renderContent = (params: { columnIndex: number; rowIndex: number }) => {
        const { columnIndex, rowIndex } = params;
        return (
            <div
                key={`${columnIndex}-${rowIndex}`}
                className={classNames({
                    'border-right': columnIndex !== columnLength - 1,
                    'border-bottom': rowIndex !== rowLength - 1,
                    'setting-table-cell': true,
                })}
                style={{
                    width: `calc(100% / ${columnLength})`,
                    paddingTop: `calc(100% / ${columnLength})`,
                }}
            >
                <SettingCell
                    validRooms={validRooms}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    onChangeStatusSuccess={fetchFacilityBookingList}
                    isReadOnly={isReadOnly}
                />
            </div>
        );
    };

    const generateTable = () => {
        const table = [];
        for (let i = 0; i < rowLength; i++) {
            const row = [];
            for (let j = 0; j < columnLength; j++) {
                row.push(
                    renderContent({
                        columnIndex: j,
                        rowIndex: i,
                    }),
                );
            }
            table.push(
                <div className="setting-table-row" key={i}>
                    {row}
                </div>,
            );
        }
        return table;
    };

    return (
        <div className="setting-table-wrapper">
            <div className="room-grid-view-content">{generateTable()}</div>
            <CheckinModal
                isLoadedData={true}
                onChangeBookingStatusSuccess={(id: number) => {
                    dispatch(getRoomListStatus());
                }}
            />
            <FacilityBookingCheckInForm
                fetchFacilityBookingList={fetchFacilityBookingList}
            />
            <FacilityBookingScheduleListModal
                onChangeStatusSuccess={(booking) => {
                    fetchFacilityBookingList();
                }}
            />
        </div>
    );
};
