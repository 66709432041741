import { IPaymentMethodBulkDeleteItem, IPaymentMethodFormBody } from './../interfaces';
import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse, IGetListResponse } from '~common/interfaces';
import {
    IPaymentMethod,
    IPaymentMethodGetListQuery,
    IPaymentMethodDropDown,
} from '../interfaces';
class PaymentMethodService extends ApiService {
    getDropdown() {
        return this._get(`${this.baseUrl}/dropdown`) as Promise<
            IBodyResponse<{ items: IPaymentMethodDropDown[] }>
        >;
    }

    getList(queryString: IPaymentMethodGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IPaymentMethod>>
        >;
    }

    bulkDelete(bulkDeleteBody: IPaymentMethodBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<IPaymentMethodBulkDeleteItem>>;
    }

    createPaymentMethod(paymentMethod: IPaymentMethodFormBody) {
        return this._post(this.baseUrl, paymentMethod) as Promise<
            IBodyResponse<IPaymentMethod>
        >;
    }

    updatePaymentMethod(id: number, body: IPaymentMethodFormBody) {
        return this._patch(`${this.baseUrl}/${id}`, body) as Promise<
            IBodyResponse<IPaymentMethod>
        >;
    }
}

export const paymentMethodService = new PaymentMethodService(
    { baseUrl: '/payment-method' },
    axiosService,
);
