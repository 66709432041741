import { INPUT_TEXT_MAX_LENGTH, Regex } from '~common/constants';
import yup from '~plugins/yup';

export const updateSmtpSchema = yup.object().shape({
    serverName: yup.string().trim().max(50).required(),
    password: yup.string().trim().max(20).required(),
    smtpServer: yup
        .string()
        .transform((value) => {
            return isNaN(value) ? null : value;
        })
        .nullable()
        .optional(),
    smtpEncryption: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    smtpPort: yup.string().required(),
    userName: yup
        .string()
        .trim()
        .isEmail()
        .max(50)
        .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
        .required(),
    connectionSecurity: yup.string().max(INPUT_TEXT_MAX_LENGTH).nullable().optional(),
});
