const childrenTypeForm = {
    childTypeName: {
        label: '子供区分名',
    },
    priceValue: {
        label: '料金',
    },
    priceType: {
        label: '連携コード',
        fixed: '固定',
        pricePerAdult: '大人/人 料金の',
    },
    linkageCode: {
        label: '連携コード',
    },
    name: {
        label: '名称',
    },
    yen: '円',
    percentage: '%',
    confirm: {
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
        cancelTitle: 'キャンセルしますか?',
    },
    createForm: {
        title: '子供区分の登録',
        create: {
            success: '子供区分が作成されました。',
            failed: '子供区分作成ができませんでした。',
        },
    },
    updateForm: {
        title: '子供区分の編集',
        update: {
            success: '子供区分が更新されました。',
            failed: '子供区分の更新ができませんでした。',
        },
    },
};

export default childrenTypeForm;
