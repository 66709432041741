const notificationList = {
    titlePage: 'Notification Setting',
    breadcrumbs: {
        setting: 'Setting',
        notification: 'Notification Setting',
    },
    searchBox: 'Search',
    primaryButton: 'Create Notification',
    notificationListColumn: {
        id: 'ID',
        hotelDesignation: 'Target Hotel',
        title: 'Title',
        type: 'Type',
    },
    messageDeleteSuccess: 'Notifications have been deleted successfully',
    delete: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
    },
};

export default notificationList;
