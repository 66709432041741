import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { hotelListBreadcrumbs } from '../../constants';
import './HotelListPage.scss';
import { Layout } from 'antd';
import FilterForm from '../../components/FilterForm/FilterForm';
import HotelList from '../../components/HotelList/HotelList';
import HotelForm from '../../components/HotelForm/HotelForm';

function HotelListPage() {
    const { t } = useTranslation();

    return (
        <div className="hotel-page">
            <StaffLayoutHeader
                breadcrumbs={hotelListBreadcrumbs()}
                title={t('hotel.list.title')}
            />
            <Layout.Content>
                <div className="hotel-page-wrapper">
                    <div className="hotel-page-content">
                        <FilterForm />
                        <HotelList />
                    </div>
                    <div className="hotel-form-wapper">
                        <HotelForm />
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default HotelListPage;
