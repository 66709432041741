import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './theme/index.less';
import './theme/scss/styles.scss';
import RootRouter from './router';
import './plugins/i18next/i18n';
import store from './plugins/redux-toolkit/store';
import { AuthProvider } from './AuthProvider';
import { ConfigProvider } from 'antd';
import configNotification from '~plugins/notification/configNotification';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './queries/queries';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { theme } from './theme/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
configNotification();
root.render(
    <Provider store={store}>
        <AuthProvider>
            <ConfigProvider button={{ autoInsertSpace: false }} theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <RootRouter />
                    <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
                </QueryClientProvider>
            </ConfigProvider>
        </AuthProvider>
    </Provider>,
);
