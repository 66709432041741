import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { sum } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { childrenTypeDropDownOptionsSelector } from '~features/children-type/reducers/children-type.reducer';
import { getChildrenTypeDropdown } from '~features/children-type/reducers/children-type.reducer';
import {
    currentGuestListSelector,
    isLoadingCurrentGuestSelector,
    selectedDateSelector,
} from '~features/report/reducers/current-guest.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './BreakDownGuest.scss';
import { parseDate } from '~plugins/dayjs';

function BreakDownGuest() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const currentGuestList = useAppSelector(currentGuestListSelector);
    const selectedDate = useAppSelector(selectedDateSelector);
    const childrenTypeOptions = useAppSelector(childrenTypeDropDownOptionsSelector);
    const showLoading = useAppSelector(isLoadingCurrentGuestSelector);
    useEffect(() => {
        dispatch(getChildrenTypeDropdown());
    }, []);

    const breakDownGuest = useMemo(() => {
        let totalGuest = 0;
        let totalMale = 0;
        let totalFemale = 0;
        let totalOther = 0;

        const childrenBreakDown: Record<number | string, number> = { id: 0 };

        currentGuestList.forEach((item) => {
            totalGuest += item.numberOfAdults;
            totalMale += item.numberOfMale;
            totalFemale += item.numberOfFemale;
            totalOther += item.numberOfOtherGenderGuest;

            item.roomBookingItemChildrenTypes.forEach((item) => {
                if (item.childrenTypeId) {
                    const quantity = childrenBreakDown[item.childrenTypeId] || 0;
                    childrenBreakDown[item.childrenTypeId] = quantity + item.quantity;
                }
            });
        });
        return {
            gender: {
                totalGuest,
                totalMale,
                totalFemale,
                totalOther,
                id: 0,
            },
            children: childrenBreakDown,
        };
    }, [currentGuestList, showLoading, selectedDate]);

    const genderBreakDownColumns: ColumnsType<{
        totalGuest: number;
        totalMale: number;
        totalFemale: number;
        totalOther: number;
    }> = [
        {
            title: t('report.currentGuest.genderBreakDown.total'),
            key: 'totalGuest',
            width: '114px',
            render: (item) => {
                return (
                    <span>
                        {item.totalGuest || 0}
                        {t('report.currentGuest.person')}
                    </span>
                );
            },
        },
        {
            title: t('report.currentGuest.genderBreakDown.male'),
            key: 'male',
            width: '114px',
            render: (item) => {
                return (
                    <span>
                        {item.totalMale || 0}
                        {t('report.currentGuest.person')}
                    </span>
                );
            },
        },
        {
            title: t('report.currentGuest.genderBreakDown.female'),
            key: 'female',
            width: '114px',
            render: (item) => {
                return (
                    <span>
                        {item.totalFemale || 0}
                        {t('report.currentGuest.person')}
                    </span>
                );
            },
        },
        {
            title: t('report.currentGuest.genderBreakDown.other'),
            key: 'other',
            width: '114px',
            render: (item) => {
                return (
                    <span>
                        {item.totalOther || 0}
                        {t('report.currentGuest.person')}
                    </span>
                );
            },
        },
    ];

    const childrenBreakDownColumns: ColumnsType<Record<number, number>> = useMemo(() => {
        const columns = childrenTypeOptions
            .map((item) => {
                return {
                    title: item.label,
                    key: item.value,
                    width: '114px',
                    ellipsis: true,
                    render: (children: Record<any, number>) => {
                        return (
                            <span>
                                {children[item.value] || 0}
                                {t('report.currentGuest.person')}
                            </span>
                        );
                    },
                };
            })
            .sort((childrenType1, childrenType2) =>
                childrenType1.title.localeCompare(childrenType2.title),
            );

        const otherChildrenIndex = columns.findIndex(
            (column) => column.title === t('report.currentGuest.childrenBreakDown.other'),
        );

        if (otherChildrenIndex !== -1) {
            const otherChildrenItem = columns[otherChildrenIndex];
            columns.splice(otherChildrenIndex, 1);
            columns.push(otherChildrenItem);
        }

        columns.unshift({
            title: t('report.currentGuest.childrenBreakDown.total'),
            key: 'childrenTotal',
            width: '114px',
            ellipsis: true,
            render: (children: Record<number, number>) => {
                return (
                    <span>
                        {sum(Object.values(children)) || 0}
                        {t('report.currentGuest.person')}
                    </span>
                );
            },
        });

        return columns;
    }, [childrenTypeOptions, t]);

    const widthOfColumn = useMemo(() => {
        if (childrenTypeOptions.length === 0) {
            return 116;
        }
        if (childrenTypeOptions.length + 1 > 6) return 600;
        return (childrenTypeOptions.length + 1) * 114 + 2;
    }, [childrenTypeOptions, t]);

    const scrollChidrenTable = useMemo(() => {
        if (childrenTypeOptions.length + 1 > 5) {
            return (childrenTypeOptions.length + 1) * 114;
        }
        return '';
    }, [childrenTypeOptions, t]);
    return (
        <div className="guest-break-down-wrapper">
            <Table
                dataSource={[breakDownGuest.gender]}
                columns={genderBreakDownColumns}
                size="small"
                rowKey={'id'}
                pagination={false}
                className="gender-break-down-table"
            />
            <Table
                dataSource={[breakDownGuest.children]}
                columns={childrenBreakDownColumns}
                size="small"
                rowKey={'id'}
                pagination={false}
                scroll={{ x: scrollChidrenTable }}
                className="children-break-down-table"
                style={{ maxWidth: widthOfColumn }}
            />
        </div>
    );
}

export default BreakDownGuest;
