import { Table, Tooltip, Typography } from 'antd';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
    ICurrentGuestQuery,
    ICurrentGuestSalePayment,
    ICurrentGuestTableData,
} from '~features/report/interfaces';
import {
    currentGuestListSelector,
    currentGuestQuerySelector,
    getCurrentGuestList,
    isShowCurrentGuestTableSelector,
    selectedDateSelector,
} from '~features/report/reducers/current-guest.reducer';
import './CurrentGuestTable.scss';
import { useAppDispatch, useAppSelector } from '~hooks';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { CurrentGuestOrderBy, CurrentGuestAdultsType } from '~features/report/constants';
import { parseDate } from '~plugins/dayjs';
import { childrenTypeDropDownOptionsSelector } from '~features/children-type/reducers/children-type.reducer';
import { cloneDeep, groupBy, orderBy, sumBy } from 'lodash';
import { ReceiptItemDetailType } from '~features/room-booking/constants';
import { formatMoney, replaceBreakLine } from '~common/commonFunctions';
import { getCurrentGuestSaleItem, reopenNUmberOfGuest } from '~features/report/helper';
import { TableProps } from 'antd/es/table';
import { ISorter } from '~common/interfaces';
import { AntdOrderDirection, OrderDirection } from '~common/constants';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { TextTruncate } from '~components';

const { Text } = Typography;

function CurrentGuestTable() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const query = useAppSelector(currentGuestQuerySelector);
    const isShowTable = useAppSelector(isShowCurrentGuestTableSelector);
    const currentGuestList = useAppSelector(currentGuestListSelector);
    const selectedDate = useAppSelector(selectedDateSelector);
    const childrenTypeOptions = useAppSelector(childrenTypeDropDownOptionsSelector);
    const onChange: TableProps<ICurrentGuestTableData>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        dispatch(
            getCurrentGuestList({
                ...query,
                orderBy: order
                    ? _field || CurrentGuestOrderBy.ID
                    : CurrentGuestOrderBy.ID,
                orderDirection:
                    order === AntdOrderDirection.ASC
                        ? OrderDirection.ASC
                        : OrderDirection.DESC,
            }),
        );
    };
    const fetchData = useCallback(
        (query: ICurrentGuestQuery) => {
            dispatch(getCurrentGuestList(query));
        },
        [dispatch],
    );

    useEffect(() => {
        if (isShowTable) fetchData(query);
    }, [query, isShowTable, fetchData]);

    const editRecord = (currentGuest: ICurrentGuestTableData) => {
        return {
            onClick: () => {
                if (currentGuest.roomBookingId) {
                    navigate(`/room-booking/${currentGuest.roomBookingId}/detail`);
                }
            },
        };
    };

    const mapReceiptItemsDetailToTableRow = (
        receiptItemDetails: ICurrentGuestSalePayment[],
        dateSelector: string,
    ) => {
        const cloneReceiptItemDetails = cloneDeep(receiptItemDetails);
        const salesItem = cloneReceiptItemDetails.filter(
            (item) => item.type === ReceiptItemDetailType.SALE_ITEM,
        );

        const paymentItems = cloneReceiptItemDetails.filter(
            (item) => item.type === ReceiptItemDetailType.PAYMENT,
        );
        const roomPrices = cloneReceiptItemDetails.filter(
            (item) =>
                item.type === ReceiptItemDetailType.STAY_PRICE ||
                item.type === ReceiptItemDetailType.STAY_PRICE_CHILDREN,
        );
        const localTaxes = cloneReceiptItemDetails
            .filter(
                (item) =>
                    item.type === ReceiptItemDetailType.LOCAL_TAX ||
                    item.type === ReceiptItemDetailType.BATH_TAX,
            )
            .map((item) => {
                return {
                    name: item?.tax?.name || '',
                    amount: Number(item.amount),
                    date: item.date,
                    type: item.type,
                    id: item.id,
                    planName: '',
                };
            });
        paymentItems.forEach((item) => {
            return {
                ...item,
                name: `${t('report.currentGuest.guestTable.receiptItemDetail.payment')}`,
            };
        });

        const saleItemList = getCurrentGuestSaleItem(salesItem);
        const roomPriceGroupByDate = groupBy(roomPrices, 'date');
        const roomPriceDate = Object.keys(roomPriceGroupByDate).map((key) => {
            const roomDatePrice = sumBy(roomPriceGroupByDate[key], 'amount');
            const receiptItemDateStayPrice = roomPriceGroupByDate[key]?.[0];
            return {
                ...receiptItemDateStayPrice,
                amount: roomDatePrice,
                name: `${t(
                    'report.currentGuest.guestTable.receiptItemDetail.roomPrice',
                )}`,
            };
        });

        const receiptSalePaymentItem = [
            ...roomPriceDate,
            ...localTaxes,
            ...saleItemList,
            ...paymentItems,
        ].filter(
            (item: ICurrentGuestSalePayment) =>
                parseDate(item.date).fmYYYYMMDD() ===
                parseDate(dateSelector).fmYYYYMMDD(),
        );
        return orderBy(receiptSalePaymentItem, ['date'], ['asc']);
    };

    const renderMemoTooltip = (item: ICurrentGuestTableData): ReactNode => {
        if (item.otaMemo && item.memo) {
            return (
                <>
                    <div
                        className="memo"
                        dangerouslySetInnerHTML={{
                            __html: replaceBreakLine(
                                item.memo as string,
                                '<br/>',
                            ) as string,
                        }}
                    />
                    <div
                        className="ota-memo"
                        dangerouslySetInnerHTML={{
                            __html: replaceBreakLine(
                                item.otaMemo as string,
                                '<br/>',
                            ) as string,
                        }}
                    />
                </>
            );
        }

        if (item.memo) {
            return (
                <div
                    className="memo"
                    dangerouslySetInnerHTML={{
                        __html: replaceBreakLine(item.memo as string, '<br/>') as string,
                    }}
                />
            );
        }
        return null;
    };

    const tableData: ICurrentGuestTableData[] = useMemo(() => {
        return currentGuestList.map((currentGuest) => {
            const {
                receiptItemDetails,
                roomBookingItemChildrenTypes,
                numberOfAdults,
                guest,
                roomGuest,
            } = currentGuest;
            const feId = `${Date.now()}${Math.random() * 1000}`;
            const nightOfStay =
                currentGuest.startDateOfStay && currentGuest.endDateOfStay
                    ? parseDate(currentGuest.endDateOfStay).diff(
                          currentGuest.startDateOfStay,
                          'day',
                      )
                    : '';
            const dateSelector = parseDate(selectedDate).fmYYYYMMDD();
            const startDateOfNight = parseDate(
                currentGuest?.startDateOfStay,
            ).fmYYYYMMDD();
            const numberOfNightHave =
                parseDate(dateSelector)?.diff(parseDate(startDateOfNight), 'day') + 1;

            const numberOfAdult: Record<any, number | string>[] = [];
            const numberOfChildren: Record<any, number | string>[] = [];

            const totalOfChildrenType = sumBy(roomBookingItemChildrenTypes, 'quantity');
            Object.values(CurrentGuestAdultsType).forEach((type) => {
                numberOfAdult.push({
                    name: type,
                    quantity: currentGuest[type],
                });
            });
            roomBookingItemChildrenTypes.forEach((item) => {
                childrenTypeOptions.forEach((itemOptions) => {
                    if (item.childrenTypeId === Number(itemOptions.value)) {
                        numberOfChildren.push({
                            childrenTypeId: item.childrenTypeId,
                            quantity: item.quantity,
                            name: itemOptions.label,
                        });
                    }
                });
            });

            const childrenGuests = reopenNUmberOfGuest(
                numberOfChildren,
                totalOfChildrenType,
                true,
            );
            const adultGuests = reopenNUmberOfGuest(numberOfAdult, numberOfAdults);

            const dataNumberOfGuest = [...adultGuests, ...childrenGuests];

            const salePaymentItem = mapReceiptItemsDetailToTableRow(
                receiptItemDetails,
                selectedDate,
            );
            const memoData = currentGuest.memo || '';
            const otaMemoData = currentGuest.otaMemo || '';

            return {
                id: currentGuest.id,
                roomBookingId: currentGuest.roomBookingId,
                roomName: currentGuest.roomName,
                guestName: guest,
                roomGuestName: roomGuest,
                numberOfGuest: dataNumberOfGuest,
                nightOfStay:
                    numberOfNightHave && nightOfStay
                        ? numberOfNightHave <= nightOfStay
                            ? `${numberOfNightHave}/${nightOfStay}`
                            : `${nightOfStay}/${nightOfStay}`
                        : '',
                checkInTime: currentGuest.checkInTime,
                planName: currentGuest.plan.name,
                salePaymentItem: salePaymentItem,
                memo: memoData,
                otaMemo: otaMemoData,
                feId: feId,
            };
        });
    }, [currentGuestList, selectedDate]);

    const currentGuestColumns: ColumnsType<ICurrentGuestTableData> | any = [
        {
            title: t('report.currentGuest.guestTable.roomName'),
            key: CurrentGuestOrderBy.ROOM_NAME,
            width: '200px',
            render: (item: ICurrentGuestTableData) => {
                return <TextTruncate text={item?.roomName ?? ''} />;
            },
            sorter: true,
            ellipsis: true,
        },
        {
            title: t('report.currentGuest.guestTable.guestName'),
            key: CurrentGuestOrderBy.GUEST_NAME,
            render: (item: ICurrentGuestTableData) => {
                if (item.guestName?.fullName) {
                    return (
                        <div>
                            <TextTruncate text={item.guestName?.yomigana} />
                            <TextTruncate text={`(${item.guestName?.fullName})`} />
                        </div>
                    );
                } else {
                    return <TextTruncate text={item.guestName?.yomigana ?? ''} />;
                }
            },
            sorter: true,
            ellipsis: true,
        },
        {
            title: t('report.currentGuest.guestTable.roomGuestName'),
            key: CurrentGuestOrderBy.ROOM_GUEST_NAME,
            render: (item: ICurrentGuestTableData) => {
                if (item.roomGuestName?.fullName) {
                    return (
                        <div>
                            <TextTruncate text={item.roomGuestName?.yomigana} />
                            <TextTruncate text={`(${item.roomGuestName?.fullName})`} />
                        </div>
                    );
                } else {
                    return <TextTruncate text={item.roomGuestName?.yomigana ?? ''} />;
                }
            },
            sorter: true,
            ellipsis: true,
        },
        {
            title: t('report.currentGuest.guestTable.numberOfGuestInRoom'),
            width: '100px',
            render: (item: ICurrentGuestTableData) => {
                return item.numberOfGuest?.map((guest, index) => {
                    return (
                        <div
                            key={index}
                            className={classNames('text-truncate', {
                                'bold-text': guest.isBold,
                            })}
                        >
                            {guest.name}
                        </div>
                    );
                });
            },
        },
        {
            title: t('report.currentGuest.guestTable.nightOfStay'),
            width: '50px',
            render: (item: ICurrentGuestTableData) => {
                return <span>{item.nightOfStay}</span>;
            },
        },
        {
            title: t('report.currentGuest.guestTable.checkInTime'),
            width: '90px',
            render: (item: ICurrentGuestTableData) => {
                return <span>{item.checkInTime}</span>;
            },
            key: CurrentGuestOrderBy.CHECK_IN,
            sorter: true,
            ellipsis: true,
        },
        {
            title: t('report.currentGuest.guestTable.plan'),
            width: '200px',
            render: (item: ICurrentGuestTableData) => {
                return <Text>{item.planName ?? ''}</Text>;
            },
        },
        {
            title: t('report.currentGuest.guestTable.salePaymentItem'),
            render: (item: ICurrentGuestTableData) => {
                return item.salePaymentItem?.map((salePayment, index) => {
                    if (salePayment.type === ReceiptItemDetailType.STAY_PRICE) {
                        return <TextTruncate key={index} text={salePayment.name ?? ''} />;
                    } else if (salePayment.type === ReceiptItemDetailType.SALE_ITEM) {
                        return (
                            <TextTruncate
                                key={index}
                                text={salePayment.saleItemName ?? ''}
                            />
                        );
                    } else if (salePayment.type === ReceiptItemDetailType.PAYMENT) {
                        return (
                            <TextTruncate
                                key={index}
                                text={t(
                                    'report.currentGuest.guestTable.receiptItemDetail.payment',
                                )}
                                className={['green-color']}
                            />
                        );
                    }
                    return <TextTruncate key={index} text={salePayment.name ?? ''} />;
                });
            },
        },
        {
            title: t('report.currentGuest.guestTable.numberOfItem'),
            width: '50px',
            render: (item: ICurrentGuestTableData) => {
                return item.salePaymentItem?.map((salePayment, index) => {
                    if (salePayment.type === ReceiptItemDetailType.PAYMENT) {
                        return (
                            <div
                                key={index}
                                className="text-truncate sale-item-row"
                            ></div>
                        );
                    }
                    return (
                        <div key={index} className="text-truncate sale-item-row">
                            {salePayment.quantity}
                        </div>
                    );
                });
            },
        },
        {
            title: t('report.currentGuest.guestTable.priceOfItem'),
            width: '80px',
            render: (item: ICurrentGuestTableData) => {
                return item.salePaymentItem?.map((salePayment, index) => {
                    if (salePayment.type === ReceiptItemDetailType.PAYMENT) {
                        return (
                            <TextTruncate
                                key={index}
                                text={formatMoney(Number(salePayment.amount)).toString()}
                                className={['green-color']}
                            />
                        );
                    } else if (
                        salePayment.type === ReceiptItemDetailType.LOCAL_TAX ||
                        salePayment.type === ReceiptItemDetailType.BATH_TAX ||
                        salePayment.type === ReceiptItemDetailType.STAY_PRICE
                    ) {
                        return (
                            <TextTruncate
                                key={index}
                                text={formatMoney(Number(salePayment?.amount)).toString()}
                            />
                        );
                    }
                    return (
                        <TextTruncate
                            key={index}
                            text={formatMoney(salePayment?.unitPrice || 0).toString()}
                        />
                    );
                });
            },
        },
        {
            title: t('report.currentGuest.guestTable.mealItem'),
            width: '90px',
            hidden: true,
            render: (item: ICurrentGuestTableData) => {
                return item.salePaymentItem?.map((salePayment, index) => {
                    return (
                        <TextTruncate
                            key={index}
                            text={
                                salePayment.isInMealGroup
                                    ? salePayment.saleItemName ?? ''
                                    : ''
                            }
                            className={['sale-item-row']}
                        />
                    );
                });
            },
        },
        {
            title: t('report.currentGuest.guestTable.date'),
            width: '90px',
            hidden: true,
            render: (item: ICurrentGuestTableData) => {
                return item.salePaymentItem?.map((salePayment, index) => {
                    return (
                        <TextTruncate
                            key={index}
                            text={
                                salePayment.isInMealGroup
                                    ? parseDate(salePayment.date).fmYYYYMMDD()
                                    : ''
                            }
                            className={['sale-item-row']}
                        />
                    );
                });
            },
        },
        {
            title: t('report.currentGuest.guestTable.numberOfItem'),
            width: '50px',
            hidden: true,
            render: (item: ICurrentGuestTableData) => {
                return item.salePaymentItem?.map((salePayment, index) => {
                    return (
                        <div key={index} className="text-truncate sale-item-row">
                            {salePayment.isInMealGroup ? salePayment.quantity : ''}
                        </div>
                    );
                });
            },
        },
        {
            title: t('report.currentGuest.guestTable.memo'),
            render: (item: ICurrentGuestTableData) => {
                return (
                    <div key={'memo' + item.id}>
                        <Tooltip
                            title={renderMemoTooltip(item)}
                            placement="left"
                            className="truncate-memo-three-rows"
                        >
                            {item.memo ? `${item.memo}.${item.otaMemo}` : item.otaMemo}
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <Table
                dataSource={tableData}
                columns={currentGuestColumns.filter((column: any) => !column.hidden)}
                size="small"
                onChange={onChange}
                pagination={false}
                rowKey={'id'}
                className="current-guest-table"
                rowClassName={'current-guest-table-row'}
                onRow={editRecord}
            />
        </div>
    );
}

export default CurrentGuestTable;
