export const facilityCreateJa = {
    title: '施設の追加',
    breadcrumbs: {
        create: '施設の追加',
    },
    formSetting: {
        quantity: {
            label: '数量',
            placeholder: '例: 1',
        },
        facilityType: {
            label: '施設タイプ',
            placeholder: '施設タイプを選択',
        },
        initialFacilityName: {
            label: '開始番号',
            placeholder: '例: 101',
        },
    },
    buttons: {
        generateFacilities: '適応する',
        addFacilities: '行を追加',
    },
    columns: {
        id: 'ID',
        facilityName: '施設名',
        facilityType: '施設タイプ',
        basePriceTaxIncluded: '基本料金 (円)',
        action: '削除',
        pricePerPerson: '1人様あたりの料金',
    },
    facilityNameDuplicated: '施設名が重複しています',
    createSuccessMessage: '施設が作成されました!',
    confirm: {
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
        cancelTitle: 'キャンセルしますか?',
    },
};
