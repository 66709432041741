import { AppState } from '~plugins/redux-toolkit/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    IAdvanceSearchGetListQuery,
    IPrintFacilityBookingState,
    IFacilityBookingListQuery,
} from '../interfaces';
import { guestService } from '~features/guest/services/guest.service';
import { facilityBookingListQueryDefault } from '../constants';
import { facilityBookingService } from '../services/facility-booking.service';

const initialState: IPrintFacilityBookingState = {
    printFacilityBookingList: [],
    printFacilityBookingListQuery: {
        ...facilityBookingListQueryDefault,
    },
    isPrintFacilityBookingListLoading: false,
    isShowFacilityBookingPrintingModal: false,
    countryList: [],
};

export const fetchPrintFacilityBookingList = createAsyncThunk(
    'print-facility-booking/fetchPrintFacilityBookingList',
    async (query: IFacilityBookingListQuery | IAdvanceSearchGetListQuery) => {
        return await facilityBookingService.getList(query);
    },
);

export const getCountryList = createAsyncThunk(
    'print-facility-booking/getCountryList',
    async () => {
        return await guestService.getCountryList();
    },
);

export const printFacilityBookingSlice = createSlice({
    name: 'print-facility-booking',
    initialState,
    reducers: {
        setPrintingFacilityBookingList: (state, action) => {
            state.printFacilityBookingList = action.payload;
        },
        setPrintFacilityBookingListQuery: (state, action) => {
            state.printFacilityBookingListQuery = action.payload;
        },
        setIsShowFacilityBookingPrintingModal: (state, action) => {
            state.isShowFacilityBookingPrintingModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPrintFacilityBookingList.pending, (state, action) => {
            state.isPrintFacilityBookingListLoading = true;
        });
        builder.addCase(fetchPrintFacilityBookingList.fulfilled, (state, action) => {
            state.isPrintFacilityBookingListLoading = false;
            state.printFacilityBookingList = action.payload?.data?.items || [];
        });
        builder.addCase(getCountryList.fulfilled, (state, action) => {
            state.countryList = action.payload?.data?.items || [];
        });
    },
});

export const {
    setPrintingFacilityBookingList,
    setPrintFacilityBookingListQuery,
    setIsShowFacilityBookingPrintingModal,
} = printFacilityBookingSlice.actions;

export const printFacilityBookingListSelector = (state: AppState) => {
    return state.printFacilityBooking;
};

export default printFacilityBookingSlice.reducer;
