import { useTranslation } from 'react-i18next';
import './NotificationAlert.scss';

function NotificationAlert() {
    const { t } = useTranslation();
    return (
        <div className="split-facility-booking-notification-alert">
            {t('facilityBooking.splitReceipt.notificationAlert.warning')}
        </div>
    );
}

export default NotificationAlert;
