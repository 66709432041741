import React from 'react';
import { Button, Col, Form, Row, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    InputPrice,
    InputText,
    SelectSaleItem,
    TimeRangePickerCustom,
} from '~components';
import { ISalesItem, IFacilityType } from '~features/facility-type/interfaces';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    getSaleItemListForDropdown,
    saleItemListDropdownSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { InputNumberGroup } from '../../CreateFacilityTypeForm/InputNumberGroup/FacilityTypePrice';
import compact from 'lodash/compact';
import { cloneDeep } from 'lodash';
import './UpdateFacilityTypesFormItem.scss';

type Props = {
    control: any;
    indexFacilityType: number;
    selectedFacilityTypeList: IFacilityType[];
    toggleFlatRates: boolean[];
    facilityType: IFacilityType;
    setValue: any;
    changeFlatRate: (status: boolean, facilityType: IFacilityType, index: number) => void;
};
function UpdateFacilityTypesFormItem({
    control,
    indexFacilityType,
    selectedFacilityTypeList,
    toggleFlatRates,
    facilityType,
    setValue,
    changeFlatRate,
}: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [saleItemList, setSaleItemList] = useState<ISalesItem[]>(
        facilityType?.saleItem || [],
    );
    const saleItemOptions = useAppSelector(saleItemListDropdownSelector);

    const addSalesItem = () => {
        setSaleItemList([
            ...saleItemList,
            {
                salesId: 0,
                feId: Date.now().toString(),
            },
        ]);
    };

    const changeSalesItem = (value: ISalesItem, index: number) => {
        const cloneSaleItemList = cloneDeep(saleItemList);
        cloneSaleItemList[index].id = value.id;
        setSaleItemList(cloneDeep(cloneSaleItemList));
    };

    const onRemoveSalesItem = (saleItem?: ISalesItem) => {
        if (!saleItem) return;
        if (saleItem?.autoGeneratedCode) {
            const items = saleItemList.filter((item) => {
                return item.autoGeneratedCode !== saleItem.autoGeneratedCode;
            });
            setSaleItemList(items);
            setValue(`items.${indexFacilityType}.saleItem`, items);
        } else {
            const items = saleItemList.filter((item) => {
                return item.feId !== saleItem.feId;
            });
            setSaleItemList(items);
            setValue(`items.${indexFacilityType}.saleItem`, items);
        }
    };

    useEffect(() => {
        dispatch(getSaleItemListForDropdown({}));
        setSaleItemList(facilityType?.saleItem?.filter((item) => item.id) || []);
    }, []);

    return (
        <div className="update-facility-type-form-item">
            <Row>
                <Col span={24}>
                    <InputText
                        label={t('facilityType.form.name.label')}
                        placeholder={t('facilityType.form.name.placeholder')}
                        required={true}
                        name={`items.${indexFacilityType}.name`}
                        control={control}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <InputText
                        label={t('facilityType.form.abbreviation.label')}
                        placeholder={t('facilityType.form.abbreviation.placeholder')}
                        name={`items.${indexFacilityType}.abbreviation`}
                        required={true}
                        control={control}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TimeRangePickerCustom
                        labelStart={t('facilityType.form.businessTime.label')}
                        placeholder={[
                            t('facilityType.form.businessTime.placeholder.start'),
                            t('facilityType.form.businessTime.placeholder.end'),
                        ]}
                        name={`items.${indexFacilityType}.businessTimes`}
                        required={true}
                        control={control}
                    />
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={2}>
                    <Form.Item
                        label={t('facilityType.form.switchFixedAmount.label')}
                        className="switch-amount"
                        name={`items.${indexFacilityType}.toggleFlatRate`}
                    >
                        <Switch
                            checked={toggleFlatRates[indexFacilityType]}
                            onChange={(status) =>
                                changeFlatRate(status, facilityType, indexFacilityType)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={toggleFlatRates[indexFacilityType] ? 14 : 11}>
                    <InputPrice
                        label={
                            toggleFlatRates[indexFacilityType]
                                ? t('facilityType.form.flatRate.label')
                                : t('facilityType.form.basicChargeHour.label')
                        }
                        placeholder={t('facilityType.form.flatRate.placeholder')}
                        name={`items.${indexFacilityType}.price`}
                        addonAfter={t('facilityType.form.unitPrice')}
                        required={true}
                        control={control}
                    />
                </Col>
                {!toggleFlatRates[indexFacilityType] && (
                    <Col span={11}>
                        <InputNumberGroup
                            label={t('facilityType.form.timeUnit')}
                            placeholder={'0'}
                            name={`items.${indexFacilityType}.timeUnit`}
                            required={true}
                            addonAfter={[
                                t('facilityType.form.hourUnit'),
                                t('facilityType.form.minutesUnit'),
                            ]}
                            control={control}
                        />
                    </Col>
                )}
            </Row>
            <Row>
                <Col span={24}>
                    {saleItemList.map((item, saleItemIndex) => {
                        return (
                            <div
                                key={
                                    item.autoGeneratedCode
                                        ? item.autoGeneratedCode
                                        : item.feId || Date.now().toString()
                                }
                                className="sale-item-row"
                            >
                                <div className="sale-item-select">
                                    <SelectSaleItem
                                        label={
                                            saleItemIndex === 0
                                                ? t('facilityType.form.salesItem.label')
                                                : ''
                                        }
                                        placeholder={t(
                                            'facilityType.form.salesItem.placeholder',
                                        )}
                                        onChange={(value) =>
                                            changeSalesItem(value, saleItemIndex)
                                        }
                                        name={`items.${indexFacilityType}.saleItem.${saleItemIndex}.id`}
                                        control={control}
                                        suffixIcon={null}
                                        showSearch={true}
                                        optionLabelProp="label"
                                        filterOption={(input, option) => {
                                            return (
                                                (option!.label || '') as unknown as string
                                            )
                                                .toLowerCase()
                                                .includes(input.toLowerCase());
                                        }}
                                        selectedValue={item.id || undefined}
                                        selectedSaleItemIds={compact(
                                            saleItemList.map((saleItem) => saleItem.id),
                                        )}
                                    />
                                </div>
                                <div>
                                    <Form.Item
                                        label={saleItemIndex === 0 ? ' ' : null}
                                        className="btn-delete"
                                    >
                                        <DeleteOutlined
                                            onClick={() => {
                                                onRemoveSalesItem(item);
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        );
                    })}
                    <Button
                        type="dashed"
                        block
                        className="addition"
                        disabled={
                            saleItemList.length >= 99 ||
                            saleItemOptions.length <= saleItemList.length
                        }
                        onClick={addSalesItem}
                    >
                        <PlusCircleOutlined />
                        {t('facilityType.form.buttons.addSalesItem')}
                    </Button>
                </Col>
                {indexFacilityType < selectedFacilityTypeList.length - 1 && (
                    <div className="line"></div>
                )}
            </Row>
        </div>
    );
}

export default UpdateFacilityTypesFormItem;
