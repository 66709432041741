import { AuditLogType, initHistoryListQuery } from './../constants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { trim } from 'lodash';
import { DEFAULT_FIRST_PAGE, DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import { AppState } from '~plugins/redux-toolkit/store';
import { SearchField, SelectSearchQuery } from '../constants';
import { historyService } from '../services/history.service';
import { IHistoryGetListQuery, IHistoryState, IHistoryUpdateBody } from '../interfaces';

export const fetchHistoryList = createAsyncThunk(
    'history-list',
    async ({ roomBookingId }: { roomBookingId?: number }, { getState }) => {
        const query = historyListQuerySelector(getState() as AppState);
        return historyService.getHistoryList({ ...query, bookingId: roomBookingId });
    },
);

export const updateHistory = createAsyncThunk(
    'history/update',
    async ({ id, body }: { id: number; body: IHistoryUpdateBody }) => {
        return await historyService.update(id, body);
    },
);

const initialState: IHistoryState = {
    historyList: [],
    selectedHistoryList: [],
    selectedRowKeys: [],
    historyListQuery: { ...initHistoryListQuery },
    showLoadingList: false,
    totalHistories: 0,
    selectedMemos: {},
};

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setHistoryListQuery: (state, action) => {
            state.historyListQuery = action.payload;
        },
        setSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload;
        },

        setKeyword: (state, action) => {
            const keyword = trim(action.payload || '');
            const query: IHistoryGetListQuery = {
                limit: state.historyListQuery.limit,
                orderBy: state.historyListQuery.orderBy,
                orderDirection: state.historyListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [SearchField.CREATED_BY_EMAIL]: keyword,
                type: AuditLogType.FO,
            };
            state.historyListQuery = query;
        },
        setSelectKeyword: (state, _action) => {
            let query: IHistoryGetListQuery = {
                limit: state.historyListQuery.limit,
                orderBy: state.historyListQuery.orderBy,
                orderDirection: state.historyListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                type: AuditLogType.FO,
            };
            if (_action.payload) {
                if (_action.payload.includes('&')) {
                    const [action, targetObjectKey] = _action.payload.split('&');
                    query = {
                        ...query,
                        [SelectSearchQuery.ACTION]: action,
                        [SelectSearchQuery.TARGET_OBJECT_KEY]: targetObjectKey,
                    };
                } else
                    query = {
                        ...query,
                        [SelectSearchQuery.TARGET_OBJECT_KEY]: _action.payload,
                    };
            }
            state.historyListQuery = query;
        },
        setHistoryList: (state, action) => {
            state.historyList = action.payload;
        },
        setTotalHistories: (state, action) => {
            state.totalHistories = action.payload;
        },
        setSelectedHistoryList: (state, action) => {
            state.selectedHistoryList = action.payload;
        },
        setMemo: (state, action) => {
            const { historyId, memo } = action.payload;
            state.selectedMemos[historyId] = memo;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHistoryList.fulfilled, (state, action) => {
            state.showLoadingList = false;
            state.selectedMemos = {};
            state.historyList = action.payload?.data?.items || [];
            state.totalHistories = action.payload?.data?.totalItems || 0;
            state.historyList.forEach((history) => {
                state.selectedMemos[history.id] = history.memo;
            });
        });
        builder.addCase(fetchHistoryList.pending, (state) => {
            state.showLoadingList = true;
        });
    },
});

export const {
    setHistoryListQuery,
    setKeyword,
    setSelectKeyword,
    setHistoryList,
    setTotalHistories,
    setSelectedHistoryList,
    setSelectedRowKeys,
    setMemo,
} = historySlice.actions;

export const historyListSelector = (state: AppState) => {
    return state.history.historyList;
};

export const historyListQuerySelector = (state: AppState) => {
    return state.history.historyListQuery;
};

export const totalPageSelector = (state: AppState) => {
    const { totalHistories, historyListQuery } = state.history;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = historyListQuery;
    return Math.ceil(totalHistories / limit);
};

export const totalHistoriesSelector = (state: AppState) => {
    return state.history.totalHistories;
};

export const showLoadingListSelector = (state: AppState) => {
    return state.history.showLoadingList;
};

export const selectedHistoryListSelector = (state: AppState) => {
    return state.history.selectedHistoryList;
};

export const selectedRowKeysSelector = (state: AppState) => {
    return state.history.selectedRowKeys;
};

export const selectedMemoSelector = (historyId: string) => (state: AppState) => {
    return state.history.selectedMemos[historyId];
};

export default historySlice.reducer;
