import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { DEFAULT_LIMIT_FOR_PAGINATION } from '~common/constants';
import { IRoomCleaningBulkUpdateFormItem, IRoomCleaningState } from '../interfaces';
import { cloneDeep } from 'lodash';
import { roomCleaningService } from '../services/room-cleaning.services';
import { initRoomCleaningListQuery } from '../constants';

const initialState: IRoomCleaningState = {
    roomCleaningListQuery: cloneDeep(initRoomCleaningListQuery),
    roomCleaningList: [],
    totalRoomCleannings: 0,
    selectedRoomCleaningList: [],
    showLoading: false,
};

export const fetchRoomCleaningList = createAsyncThunk(
    'room-cleaning/fetchRoomCleaning',
    async (_, { getState }) => {
        const query = roomCleaningListQuerySelector(getState() as AppState);
        return await roomCleaningService.getList(query);
    },
);

export const bulkUpdateRoomCleanings = createAsyncThunk(
    'room-cleaning/bulkupdateRoomCleaning',
    async (formData: IRoomCleaningBulkUpdateFormItem[]) => {
        return await roomCleaningService.bulkUpdate(formData);
    },
);

export const dirtyAllRooms = createAsyncThunk(
    'room-cleaning/dirty-all-room-of-hotel',
    async (_, {}) => {
        return await roomCleaningService.dirtyAllRooms();
    },
);

export const clearAllRoomMemos = createAsyncThunk(
    'room-cleaning/clear-all-room-memo-of-hotel',
    async (_, {}) => {
        return await roomCleaningService.clearAllRoomMemos();
    },
);

export const cleanAllRooms = createAsyncThunk(
    'room-cleaning/clean-all-room-of-hotel',
    async (_, {}) => {
        return await roomCleaningService.cleanAllRooms();
    },
);

export const roomCleaningSlice = createSlice({
    name: 'roomCleaning',
    initialState,
    reducers: {
        setRoomCleaningListQuery: (state, action) => {
            state.roomCleaningListQuery = action.payload;
        },
        setRoomCleaningList: (state, action) => {
            state.roomCleaningList = action.payload;
        },
        setTotalRoomCleaning: (state, action) => {
            state.totalRoomCleannings = action.payload;
        },
        setSelectedRoomCleaningList: (state, action) => {
            state.selectedRoomCleaningList = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchRoomCleaningList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.roomCleaningList = action.payload?.data?.items || [];
            state.totalRoomCleannings = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchRoomCleaningList.pending, (state, action) => {
            state.showLoading = true;
        });
    },
});

export const {
    setRoomCleaningListQuery,
    setRoomCleaningList,
    setTotalRoomCleaning,
    setSelectedRoomCleaningList,
} = roomCleaningSlice.actions;

export const roomCleaningListQuerySelector = (state: AppState) => {
    return state.roomCleaning.roomCleaningListQuery;
};

export const roomCleaningListSelector = (state: AppState) => {
    return state.roomCleaning.roomCleaningList;
};

export const showLoadingSelector = (state: AppState) => {
    return state.roomCleaning.showLoading;
};

export const selectedRoomCleaningListSelector = (state: AppState) => {
    return state.roomCleaning.selectedRoomCleaningList;
};

export const totalRoomCleaningsSelector = (state: AppState) => {
    return state.roomCleaning.totalRoomCleannings;
};

export const totalPageSelector = (state: AppState) => {
    const { totalRoomCleannings, roomCleaningListQuery } = state.roomCleaning;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = roomCleaningListQuery;
    return Math.ceil(totalRoomCleannings / limit);
};

export default roomCleaningSlice.reducer;
