import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import yup from '~plugins/yup';
import { MAX_CONNECTING_NUMBER, MAX_STANDARD_CAPACITY } from './constants';

export const schema = yup.object().shape({
    name: yup.string().hasNoSpecialCharacters().max(INPUT_TEXT_MAX_LENGTH).required(),
    roomTypeCode: yup
        .string()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .required(),
    abbreviation: yup
        .string()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .required(),
    standardCapacity: yup
        .string()
        .minFullAndHalfWidth(1)
        .maxFullAndHalfWidth(MAX_STANDARD_CAPACITY)
        .required(),
    channels: yup.array().of(
        yup
            .object()
            .shape({
                channelId: yup.number().required(),
            })
            .required(),
    ),
});
