import { useQuery } from '@tanstack/react-query';
import { bankAccountServiceV2 } from '../services/bank-account.service.v2';
import { CacheKeys } from '~queries/queries';
import { BankAccountResponseBody } from '../interfaces';

const getBankAccounts = async (): Promise<BankAccountResponseBody[]> => {
    const response = await bankAccountServiceV2.getBankAccounts();
    if (response.success) {
        return response.data;
    }
    throw response.errors![0];
};

export const useBankAccounts = () => {
    return useQuery({
        queryKey: [CacheKeys.bankAccounts],
        queryFn: getBankAccounts,
    });
};
