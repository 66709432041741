import facilityTypeFormJa from './ja/facility-type-form.locale';
import facilityTypeListJa from './ja/facility-type-list.locale';
import facilityTypeFormEn from './en/facility-type-form.locale';
import facilityTypeListEn from './en/facility-type-list.locale';

const facilityType = {
    en: {
        list: facilityTypeListEn,
        form: facilityTypeFormEn,
    },
    ja: {
        list: facilityTypeListJa,
        form: facilityTypeFormJa,
    },
};

export default facilityType;
