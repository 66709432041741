import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, notification, Row } from 'antd';
import { useCallback, useEffect } from 'react';
import { useForm } from '~plugins/hook-form';
import { useTranslation } from 'react-i18next';
import { validateUniqueValue, compareFormData } from '~common/commonFunctions';
import { ErrorMessageType, INPUT_TEXT_MAX_LENGTH } from '~common/constants';
import { SingleSelect, InputText, RightDrawerLayout } from '~components';
import {
    facilityTypeDropDownOptionsSelector,
    getFacilityTypeListForDropDown,
} from '~features/facility-type/facility-type.reducer';
import {
    bulkUpdateFacilities,
    fetchFacilityList,
    isShowUpdateFacilityFormSelector,
    setIsShowUpdateFacilityForm,
    setUpdateFacilityList,
    updateFacilityFormBusySelector,
    updateFacilityListSelector,
} from '~features/facility/facility.reducer';
import { IFacilityBulkUpdateFormItem } from '~features/facility/interfaces';
import { showConfirm } from '~common/notification';
import { useEscape } from '~common/useHooks';
import { bulkUpdateFacilitiesSchema } from '~features/facility/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import './UpdateFacilitiesForm.scss';

function UpdateFacilitiesForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, reset, handleSubmit, getValues, setError } = useForm({
        resolver: yupResolver(bulkUpdateFacilitiesSchema),
    });

    const facilityTypeOptions = useAppSelector(facilityTypeDropDownOptionsSelector);
    const isShowform = useAppSelector(isShowUpdateFacilityFormSelector);
    const formBusy = useAppSelector(updateFacilityFormBusySelector);
    const updateFacilityList = useAppSelector(updateFacilityListSelector);

    const closeDialog = () => {
        dispatch(setIsShowUpdateFacilityForm(false));
        dispatch(setUpdateFacilityList([]));
        reset();
    };

    const _showConfirm = () => {
        if (compareFormData(getValues().items, updateFacilityList)) {
            closeDialog();
            return;
        }
        showConfirm({
            title: t('facility.updateForm.form.confirm.cancelTitle'),
            cancelText: t('facility.updateForm.form.confirm.buttonCancelText'),
            okText: t('facility.updateForm.form.confirm.buttonDeleteText'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    useEffect(() => {
        reset({
            items: updateFacilityList,
        });
    }, [updateFacilityList]);

    useEffect(() => {
        dispatch(getFacilityTypeListForDropDown({}));
    }, []);

    const _bulkUpdateFacilities = useCallback(
        async (facilities: IFacilityBulkUpdateFormItem[]) => {
            const response = await dispatch(
                bulkUpdateFacilities(
                    facilities.map((facility, index) => ({
                        id: facility.id,
                        name: facility.name,
                        facilityTypeId: facility.facilityTypeId,
                        order: index + 1,
                    })),
                ),
            );
            if (bulkUpdateFacilities.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('facility.updateForm.updateSuccessMessage'),
                    });
                    dispatch(setIsShowUpdateFacilityForm(false));
                    dispatch(fetchFacilityList());
                } else {
                    (response.payload?.errors || []).forEach((error) => {
                        if (
                            (error.key === 'name' || error.key === 'facilityTypeId') &&
                            error.order
                        ) {
                            setError(
                                `items.${error.order - 1}.${error.key}`,
                                { type: ErrorMessageType.MANUAL, message: error.message },
                                { shouldFocus: true },
                            );
                        }
                    });
                    notification.error({
                        message: response.payload?.message,
                    });
                }
            }
        },
        [],
    );

    const onSubmit = () => {
        handleSubmit((data) => {
            const updateFacilities = getValues().items || [];
            if (!updateFacilities.length) {
                return;
            }
            const duplicatedNameIndexs = validateUniqueValue(updateFacilities, 'name');
            if (duplicatedNameIndexs.length) {
                duplicatedNameIndexs.forEach((index) => {
                    setError(
                        `items.${index}.name`,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('facility.create.facilityNameDuplicated'),
                        },
                        { shouldFocus: true },
                    );
                });
                return;
            }
            _bulkUpdateFacilities(data.items);
        })();
    };

    return (
        <div className="update-facilities-form-wrapper">
            <RightDrawerLayout
                open={isShowform}
                onClose={_showConfirm}
                onSubmit={onSubmit}
                title={t('facility.updateForm.title')}
                cancelText={t('common.buttonCancelText')}
                submitText={t('common.buttonSaveText')}
                className="update-facilities-drawer"
                busy={formBusy}
                loading={formBusy}
            >
                <Form layout="vertical" autoComplete="off" scrollToFirstError>
                    {updateFacilityList.map((facility, index) => {
                        return (
                            <Row key={index} gutter={8}>
                                <Col span={4}>
                                    <InputText
                                        label={index === 0 ? 'ID' : ''}
                                        placeholder=""
                                        name={`items.${index}.autoGeneratedCode`}
                                        control={control}
                                        disabled={true}
                                    />
                                </Col>
                                <Col span={10}>
                                    <InputText
                                        label={
                                            index === 0
                                                ? t('facility.updateForm.form.name.label')
                                                : ''
                                        }
                                        placeholder={t(
                                            'facility.updateForm.form.name.placeholder',
                                        )}
                                        name={`items.${index}.name`}
                                        control={control}
                                        max={INPUT_TEXT_MAX_LENGTH}
                                    />
                                </Col>
                                <Col span={10}>
                                    <SingleSelect
                                        label={
                                            index === 0
                                                ? t(
                                                      'facility.updateForm.form.facilityType.label',
                                                  )
                                                : ''
                                        }
                                        placeholder={t(
                                            'facility.updateForm.form.facilityType.placeholder',
                                        )}
                                        name={`items.${index}.facilityTypeId`}
                                        control={control}
                                        options={facilityTypeOptions}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </Form>
            </RightDrawerLayout>
        </div>
    );
}

export default UpdateFacilitiesForm;
