import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { breadcrumbs } from '~features/facility-type/constants';
import FilterForm from '~features/facility-type/components/FilterForm/FilterForm';
import './FacilityTypePage.scss';
import FacilityTypeList from '~features/facility-type/components/FacilityTypeList/FacilityTypeList';
import CreateFacilityTypeForm from '~features/facility-type/components/CreateFacilityTypeForm/CreateFacilityTypeForm';
import UpdateFacilityTypesForm from '~features/facility-type/components/UpdateFacilityTypesForm/UpdateFacilityTypesForm';

const { Content } = Layout;

function FacilityTypePage() {
    const { t } = useTranslation();

    return (
        <div className="facility-type-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('facilityType.list.title')}
            />
            <Content>
                <div className="facility-type-content">
                    <FilterForm />
                    <FacilityTypeList />
                </div>
                <CreateFacilityTypeForm />
                <UpdateFacilityTypesForm />
            </Content>
        </div>
    );
}

export default FacilityTypePage;
