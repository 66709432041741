const rankCalendarList = {
    title: 'Rank calendar setting',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        rankCalendar: 'Rank calendar setting',
    },
    addBtn: 'New calendar',
    rankCalendarColumn: {
        id: 'ID',
        calendarName: 'Calendar name',
        status: 'Status',
    },
    message: {
        deleteRankCalendarsSuccess: 'Rank Calendars have been deleted successfully',
        cannotDeleteRankCalendar: 'Cannot delete active calendar.',
        cannotDeleteInitRankCalendar: `Cannot delete rank calendar item`,
    },
    status: {
        active: 'Activated',
        inactive: 'Deactivated',
    },
};

export default rankCalendarList;
