import { IndicatorType } from '~features/indicator/constants';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import { getBookingScheduleDefault } from '~features/room-booking/model';
import { RoomManagementStatus } from './constants';
import {
    IRoomIndicatorManagementItemResponse,
    IRoomManagementItem,
    IUnassignedBookingItem,
} from './interfaces';
import { parseDate } from '~plugins/dayjs';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';

export const convertToRoomManagementItem = (
    items: IRoomIndicatorManagementItemResponse[],
) => {
    const result: Record<string, IRoomManagementItem> = {};
    items.forEach((item) => {
        const type = item.facilityId ? IndicatorType.FACILITY : IndicatorType.ROOM;
        const isRoomType = type === IndicatorType.ROOM;
        result[`${item.rowIndex}-${item.colIndex}`] = {
            id: (isRoomType ? item.room?.id : item.facility?.id) || 0,
            name: (isRoomType ? item.room?.name : item.facility?.name) || '',
            desc:
                (isRoomType
                    ? item.roomType?.abbreviation
                    : item.facilityType?.abbreviation) || '',
            status: RoomManagementStatus.VACANCY,
            x: item.colIndex,
            y: item.rowIndex,
            type: type,
            guestName: '',
            memo: item?.memo,
            reason: item.reason,
            price: 0,
            room: item.room,
            roomType: item.roomType,
        };
    });
    return result;
};

export const convertToBookingSchedule = (
    item: IUnassignedBookingItem,
): IRoomBookingSchedule => {
    const booking = getBookingScheduleDefault();
    return {
        ...booking,
        id: item.id,
        room: item.room,
        roomType: item.roomType,
        plan: item.plan,
        guest: { ...booking.guest, ...item.representativeGuest },
        stayingStartDate: parseDate(item.startDateOfStay)?.fmYYYYMMDD(),
        stayingEndDate: parseDate(item.endDateOfStay)?.fmYYYYMMDD(),
        price: item.price || 0,
        isTmp: !item.roomBooking?.id,
        roomBookingId: item.roomBooking?.id || 0,
        status: item.bookingStatus || RoomBookingItemBookingStatus.NOT_ARRIVED,
        individualUnpaidAmount: item?.individualUnpaidAmount || 0,
    };
};

export const getUnassignedScreen = (
    unassignedList: IUnassignedBookingItem[],
    isManagemntScreen?: boolean,
) => {
    if (isManagemntScreen) {
        return unassignedList?.filter((item) => !item.isDayUse);
    }
    return unassignedList;
};
