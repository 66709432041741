import i18next from '~plugins/i18next/i18n';
import { IDropDownOption } from '~common/interfaces';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';

export enum OrderBy {
    ID = 'id',
    NAME = 'name',
    ROOM_TYPE_NAME = 'type',
}

export const enum RoomColumn {
    ID = 'id',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    NAME = 'name',
    TYPE = 'roomTypeName',
}

export const enum ImportRoomColumn {
    NAME = 'name',
    TYPE = 'roomTypeName',
}

export const enum IndividualRoomTableColumn {
    ID = 'id',
    NAME = 'name',
    ROOM_TYPE = 'roomType',
    ACTION = 'action',
}

export const enum EditRoomColumn {
    ID = 'id',
    NAME = 'name',
    TYPE = 'type',
}

export const csvOptions: IDropDownOption[] = [
    {
        label: i18next.t<string>('room.list.csvSelection.importSelection'),
        value: i18next.t<string>('room.list.csvSelection.importSelection'),
    },
    {
        label: i18next.t<string>('room.list.csvSelection.exportSelection'),
        value: i18next.t<string>('room.list.csvSelection.exportSelection'),
    },
    {
        label: i18next.t<string>('room.list.csvSelection.exportAll'),
        value: i18next.t<string>('room.list.csvSelection.exportAll'),
    },
];

export const breadcrumbs = () => {
    return [
        {
            text: i18next.t('room.list.breadcrumbs.home'),
        },
        {
            text: i18next.t('room.list.breadcrumbs.roomSetting'),
        },
    ];
};
export const individualRoomBreadCrumbs = () => [
    ...breadcrumbs(),
    {
        text: i18next.t('room.list.breadcrumbs.createIndividual'),
    },
];
export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    NAME = 'name',
    TYPE = 'roomTypeName',
}

export const FILE_NAME = 'room.csv';

// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'room';
export const exportColumns = ['autoGeneratedCode', 'name', 'roomTypeName'];
export const i18ExportKey = 'room.list';

export const initRoomListQuery = {
    keyword: '',
    name: '',
    autoGeneratedCode: '',
    roomTypeName: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const ROOM_NAME_MAX_LENGTH = 30;
export const MAX_ROOMS = 99;
export const CREATE_ROOMS_FORM_HEIGHT = 560;

export enum RoomOrderSettingsSortOptions {
    ASCENDING = 'ascending',
    DESCENDING = 'descending',
}
