import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customDayjs, { parseDate } from '~plugins/dayjs';
import _ from 'lodash';
import { AppState } from '~plugins/redux-toolkit/store';
import { getStartDateAndEndDateByDay, makeGridData } from '../helper';
import {
    IInventoryStopSellingListQuery,
    IInventoryStopSellingState,
} from '../interfaces';
import { inventoryService } from '../services/inventory.service';

const initialState: IInventoryStopSellingState = {
    showLoading: false,
    stopSellingInventoryData: [],
    stopSellingGridData: [],
    isShowBulkStopSellingForm: false,
    inventoryStopSellingListQuery: {
        startDate: '',
        endDate: '',
    },
    currentDate: customDayjs().format('YYYY-MM-DD'),
    selectedDate: customDayjs().format('YYYY-MM-DD'),
    firstCheckbox: null,
};

export const getStoppingInventoryData = createAsyncThunk(
    'inventory/getStoppingInventoryData',
    async (_, { getState }) => {
        const state = getState() as AppState;
        const { currentDate } = state.inventoryStopSelling;
        const start =
            parseDate(currentDate)?.fmYYYYMM() === customDayjs().fmYYYYMM()
                ? parseDate(currentDate)
                : parseDate(currentDate).startOf('month');
        const { startDate, endDate } = getStartDateAndEndDateByDay(
            start.fmYYYYMMDD(),
            parseDate(currentDate).endOf('month')?.fmYYYYMMDD(),
        );
        const params: IInventoryStopSellingListQuery = {
            startDate: startDate,
            endDate: endDate,
        };

        return await inventoryService.getStoppingInventory(params);
    },
);

const inventoryStopSellingSlice = createSlice({
    name: 'inventoryStopSelling',
    initialState,
    reducers: {
        setIsShowBulkStopSellingForm: (state, action) => {
            state.isShowBulkStopSellingForm = action.payload;
        },
        setInventoryStopSellingListQuery: (state, action) => {
            state.inventoryStopSellingListQuery = action.payload;
        },
        resetState: () => {
            return initialState;
        },
        setCurrentDate: (state, action) => {
            state.currentDate = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        toggleCheckBoxChild: (state, action) => {
            const { id, date, parentId } = action.payload;
            const _stopSellingGridData = _.cloneDeep(state.stopSellingGridData);
            const room = _stopSellingGridData.find(
                (item) => item.id === id && item.parentId === parentId,
            );
            const roomType = _stopSellingGridData.find(
                (item) => !item.parentId && item.id === parentId,
            );
            if (!room || !roomType) return;
            if (!room.date[date]) {
                room.date[date] = {
                    isChecked: false,
                };
            }
            room.date[date].isChecked = !room.date[date].isChecked;
            const rooms = _stopSellingGridData.filter(
                (item) => item.parentId === parentId && !item.date[date]?.isBooked,
            );

            // check parent
            const checkedRoomCount = _.sumBy(rooms, (room) =>
                room.date[date].isChecked ? 1 : 0,
            );
            roomType.date[date].isChecked = rooms.length === checkedRoomCount;
            roomType.date[date].availableRooms = rooms.length - checkedRoomCount;

            state.stopSellingGridData = _stopSellingGridData;
        },
        toggleCheckBoxParent: (state, action) => {
            const { id, date } = action.payload;
            const _stopSellingGridData = _.cloneDeep(state.stopSellingGridData);
            const roomType = _stopSellingGridData.find(
                (item) => !item.parentId && item.id === id,
            );
            if (!roomType) return;
            const rooms = _stopSellingGridData.filter(
                (item) => item.parentId === id && !item.date[date]?.isBooked,
            );
            if (!roomType.date[date]) {
                roomType.date[date] = {
                    isChecked: false,
                };
            }
            roomType.date[date].isChecked = !roomType.date[date].isChecked;
            roomType.date[date].availableRooms = !roomType.date[date].isChecked
                ? rooms.length
                : 0;
            rooms.forEach((room) => {
                if (!room.date[date]) {
                    room.date[date] = {
                        isChecked: false,
                    };
                }
                room.date[date].isChecked = roomType.date[date].isChecked;
            });
            state.stopSellingGridData = _stopSellingGridData;
        },
        toggleCheckBoxChildrenByShift: (state, action) => {
            const firstCheckbox = state.firstCheckbox;
            const { date } = action.payload;
            const _stopSellingGridData = _.cloneDeep(state.stopSellingGridData);
            const room = _stopSellingGridData.find(
                (item) =>
                    item.id === firstCheckbox?.id &&
                    item.parentId === firstCheckbox?.parentId,
            );
            const roomType = _stopSellingGridData.find(
                (item) => !item.parentId && item.id === firstCheckbox?.parentId,
            );
            if (!room || !roomType) return;
            const startDate = parseDate(date).isAfter(firstCheckbox?.date)
                ? firstCheckbox?.date
                : date;
            const endDate = parseDate(date).isAfter(firstCheckbox?.date)
                ? date
                : firstCheckbox?.date;
            for (
                let d = parseDate(startDate).add(1, 'day');
                d.isSameOrBefore(parseDate(endDate), 'day');
                d = d.add(1, 'day')
            ) {
                const dateString = d.fmYYYYMMDD();
                room.date[dateString].isChecked = !!firstCheckbox?.value;
                const rooms = _stopSellingGridData.filter(
                    (item) =>
                        item.parentId === firstCheckbox?.parentId &&
                        !item.date[dateString].isBooked,
                );

                // check parent
                const checkedRoomCount = _.sumBy(rooms, (room) =>
                    room.date[dateString].isChecked ? 1 : 0,
                );
                roomType.date[dateString].isChecked = rooms.length === checkedRoomCount;
                roomType.date[dateString].availableRooms =
                    rooms.length - checkedRoomCount;
            }
            state.stopSellingGridData = _stopSellingGridData;
        },
        toggleCheckBoxParentByShift: (state, action) => {
            const firstCheckbox = state.firstCheckbox;
            if (!firstCheckbox) return;
            const { date } = action.payload;
            const _stopSellingGridData = _.cloneDeep(state.stopSellingGridData);
            const roomType = _stopSellingGridData.find(
                (item) => !item.parentId && item.id === firstCheckbox?.id,
            );
            if (!roomType) return;
            const startDate = parseDate(date).isAfter(firstCheckbox?.date)
                ? firstCheckbox?.date
                : date;
            const endDate = parseDate(date).isAfter(firstCheckbox?.date)
                ? date
                : firstCheckbox?.date;
            for (
                let d = parseDate(startDate).add(1, 'day');
                d.isSameOrBefore(parseDate(endDate), 'day');
                d = d.add(1, 'day')
            ) {
                const dateString = d.fmYYYYMMDD();
                const rooms = _stopSellingGridData.filter(
                    (item) =>
                        item.parentId === firstCheckbox?.id &&
                        !item.date[dateString].isBooked,
                );
                roomType.date[dateString].isChecked = firstCheckbox.value;
                roomType.date[dateString].availableRooms = !roomType.date[dateString]
                    .isChecked
                    ? rooms.length
                    : 0;
                rooms.forEach((room) => {
                    room.date[dateString].isChecked = firstCheckbox.value;
                });
            }
            state.stopSellingGridData = _stopSellingGridData;
        },
        setFirstCheckbox: (state, action) => {
            state.firstCheckbox = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStoppingInventoryData.pending, (state) => {
            state.showLoading = true;
        });
        builder.addCase(getStoppingInventoryData.fulfilled, (state, action) => {
            state.showLoading = false;
            const _stopSellingInventoryData = action.payload?.data || [];
            state.stopSellingGridData = makeGridData(_stopSellingInventoryData);
        });
    },
});

export const {
    setIsShowBulkStopSellingForm,
    setInventoryStopSellingListQuery,
    resetState,
    setCurrentDate,
    toggleCheckBoxParent,
    toggleCheckBoxChild,
    setFirstCheckbox,
    toggleCheckBoxChildrenByShift,
    toggleCheckBoxParentByShift,
    setSelectedDate,
} = inventoryStopSellingSlice.actions;

export const inventoryStopSellingStateSelector = (state: AppState) => {
    return state.inventoryStopSelling;
};

export const inventoryStopSellingGridDataSelector = (state: AppState) => {
    const { stopSellingGridData } = state.inventoryStopSelling;
    return stopSellingGridData;
};

export const headerColumnsSelector = (state: AppState) => {
    const { currentDate } = state.inventoryStopSelling;
    const currentMonth = customDayjs().format('YYYY-MM');
    const start =
        currentMonth === parseDate(currentDate).format('YYYY-MM')
            ? parseDate(currentDate).date()
            : 1;
    const end = parseDate(currentDate).endOf('month').date();
    const _currentDate = parseDate(currentDate);
    const _headerColumns: { label: string; id: string }[] = [];
    for (let i = start; i <= end; i++) {
        const _date = _currentDate.clone().set('date', i);
        _headerColumns.push({
            label: _date.format('DD (ddd)'),
            id: _date.fmYYYYMMDD(),
        });
    }
    return _headerColumns;
};

export default inventoryStopSellingSlice.reducer;
