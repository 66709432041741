import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import FilterForm from '~features/sale-item/components/FillterForm/FilterForm';
import SaleItemList from '~features/sale-item/components/SaleItemList/SaleItemList';
import UpdateSaleItemForm from '~features/sale-item/components/UpdateSaleItemForm/UpdateSaleItemForm';
import { breadcrumbs } from '~features/sale-item/constants';
import './SaleItemListPage.scss';

function SaleItemListPage() {
    const { t } = useTranslation();
    return (
        <div className="sale-item-list-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('saleItem.list.title')}
            />
            <Layout.Content>
                <div className="sale-item-list-page-wrapper">
                    <div className="sale-item-list-page-content">
                        <FilterForm />
                        <SaleItemList />
                    </div>
                    <UpdateSaleItemForm />
                </div>
            </Layout.Content>
        </div>
    );
}

export default SaleItemListPage;
