import { Button } from 'antd';
import { groupBy, orderBy, sumBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { exportCsvFileWithXlsx, replaceBreakLine } from '~common/commonFunctions';
import { childrenTypeDropDownOptionsSelector } from '~features/children-type/reducers/children-type.reducer';
import {
    CurrentGuestAdultsType,
    ReportTableCsvFileName,
    exportCurrentGuestColumns,
} from '~features/report/constants';
import {
    ICurrentGuestReport,
    ICurrentGuestReportCsv,
    ICurrentGuestSalePayment,
} from '~features/report/interfaces';
import {
    currentGuestListSelector,
    currentGuestStateSelector,
    selectedDateSelector,
} from '~features/report/reducers/current-guest.reducer';
import { ReceiptItemDetailType } from '~features/room-booking/constants';
import { useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';

function ExportCsvButton() {
    const { t } = useTranslation();
    const { isShowTable } = useAppSelector(currentGuestStateSelector);
    const currentGuestList = useAppSelector(currentGuestListSelector);
    const selectedDate = useAppSelector(selectedDateSelector);
    const childrenTypeOptions = useAppSelector(childrenTypeDropDownOptionsSelector);
    const childrenTypeById: Record<number, string> = useMemo(() => {
        return childrenTypeOptions.reduce((result, childrenType) => {
            Object.assign(result, {
                [childrenType.value]: childrenType.label,
            });
            return result;
        }, {});
    }, [childrenTypeOptions]);
    const mapNumberOfPeople = (item: ICurrentGuestReport) => {
        const peopleStrings: string[] = [];
        Object.values(CurrentGuestAdultsType).forEach((type) => {
            if (item[type] > 0)
                peopleStrings.push(
                    `${t(`report.currentGuest.guestTable.guestAultsKey.${type}`)}${
                        item[type]
                    }`,
                );
        });
        const totalChildren = sumBy(item.roomBookingItemChildrenTypes || [], 'quantity');
        if (totalChildren > 0) {
            peopleStrings.push(
                `${t(`report.currentGuest.guestTable.totalChildren`)}: ${totalChildren}`,
            );
            item.roomBookingItemChildrenTypes?.forEach((child) => {
                if (!child.childrenTypeId) return;
                const childrenTypeName = childrenTypeById[child.childrenTypeId];
                if (child?.quantity > 0) {
                    peopleStrings.push(`${childrenTypeName}: ${child?.quantity}`);
                }
            });
        }
        return peopleStrings.join();
    };
    const mapNightOfStay = (item: ICurrentGuestReport) => {
        const nightOfStay =
            item.startDateOfStay && item.endDateOfStay
                ? parseDate(item.endDateOfStay).diff(item.startDateOfStay, 'day')
                : '';
        const dateSelector = parseDate(selectedDate).fmYYYYMMDD();
        const startDateOfNight = parseDate(item?.startDateOfStay).fmYYYYMMDD();
        const numberOfNightHave =
            parseDate(dateSelector)?.diff(parseDate(startDateOfNight), 'day') + 1;

        return numberOfNightHave && nightOfStay
            ? numberOfNightHave <= nightOfStay
                ? `${numberOfNightHave}/${nightOfStay}`
                : `${nightOfStay}/${nightOfStay}`
            : '';
    };

    const mapGuestName = (yomigana: string, fullName: string) => {
        if (fullName) {
            return `${yomigana}(${fullName})`;
        }
        return yomigana || '';
    };

    const mapReceiptItemDetails = (receiptItemDetails: ICurrentGuestSalePayment[]) => {
        const saleItemByDate: Record<
            string,
            Record<string, ICurrentGuestSalePayment[]>
        > = {};
        const paymentItems: ICurrentGuestSalePayment[] = [];
        const stayPriceItems: ICurrentGuestSalePayment[] = [];
        const localTaxItems: ICurrentGuestSalePayment[] = [];
        receiptItemDetails.forEach((item) => {
            switch (item.type) {
                case ReceiptItemDetailType.STAY_PRICE:
                case ReceiptItemDetailType.STAY_PRICE_CHILDREN:
                    stayPriceItems.push(item);
                    break;
                case ReceiptItemDetailType.SALE_ITEM:
                    const boughtAt = parseDate(item.date).fmYYYYMMDD();
                    if (!saleItemByDate[boughtAt]) {
                        saleItemByDate[boughtAt] = {};
                    }
                    if (item.saleItemId) {
                        if (!saleItemByDate[boughtAt][item.saleItemId]) {
                            saleItemByDate[boughtAt][item.saleItemId] = [];
                        }
                        saleItemByDate[boughtAt][item.saleItemId].push(item);
                    }
                    break;
                case ReceiptItemDetailType.PAYMENT:
                    paymentItems.push(item);
                    break;
                case ReceiptItemDetailType.BATH_TAX:
                case ReceiptItemDetailType.LOCAL_TAX:
                    localTaxItems.push(item);
                    break;
            }
        });
        const saleItems: ICurrentGuestSalePayment[] = [];
        Object.keys(saleItemByDate).forEach((date) => {
            Object.keys(saleItemByDate[date]).forEach((saleItemId: string) => {
                const items = saleItemByDate[date]?.[saleItemId] || [];
                const groutItems = groupBy(items, 'unitPrice');
                Object.keys(groutItems).forEach((unitPrice) => {
                    if (groutItems[unitPrice].length === 1) {
                        saleItems.push(groutItems[unitPrice][0]);
                    } else {
                        const totalQuantity = sumBy(groutItems[unitPrice], 'quantity');
                        saleItems.push({
                            ...groutItems[unitPrice][0],
                            quantity: totalQuantity,
                        });
                    }
                });
            });
        });
        const groupStayPrice = groupBy(stayPriceItems, 'date');
        const roomPriceItems: ICurrentGuestSalePayment[] = [];
        Object.keys(groupStayPrice).forEach((date) => {
            if (groupStayPrice[date]?.length === 1) {
                roomPriceItems.push(groupStayPrice[date][0]);
            } else {
                const totalAmount = sumBy(groupStayPrice[date], 'amount');
                roomPriceItems.push({
                    ...groupStayPrice[date][0],
                    amount: totalAmount,
                });
            }
        });

        const getReceiptItemDetails = [
            ...roomPriceItems,
            ...localTaxItems,
            ...saleItems,
            ...paymentItems,
        ].filter(
            (item: ICurrentGuestSalePayment) =>
                parseDate(item.date).fmYYYYMMDD() ===
                parseDate(selectedDate).fmYYYYMMDD(),
        );
        return orderBy(getReceiptItemDetails, ['date'], ['asc']);
    };

    const getPrice = (item: ICurrentGuestSalePayment) => {
        if (item.type === ReceiptItemDetailType.SALE_ITEM) {
            if (item.unitPrice === 0) return 0;
            return item.unitPrice || '';
        }
        if (item.amount === 0) return 0;
        return item.amount || '';
    };

    const getReceiptItemName = (item: ICurrentGuestSalePayment) => {
        if (
            item.type === ReceiptItemDetailType.STAY_PRICE ||
            item.type === ReceiptItemDetailType.STAY_PRICE_CHILDREN
        ) {
            return t('report.currentGuest.guestTable.receiptItemDetail.roomPrice');
        }
        if (item.type === ReceiptItemDetailType.PAYMENT) {
            return t('report.currentGuest.guestTable.receiptItemDetail.payment');
        }
        if (
            item.type === ReceiptItemDetailType.LOCAL_TAX ||
            item.type === ReceiptItemDetailType.BATH_TAX
        ) {
            return item.tax?.name || '';
        }
        return item.saleItemName || '';
    };

    const getReceiptItemQuantity = (item: ICurrentGuestSalePayment) => {
        if (
            item.type === ReceiptItemDetailType.PAYMENT ||
            item.type === ReceiptItemDetailType.LOCAL_TAX ||
            item.type === ReceiptItemDetailType.BATH_TAX ||
            item.type === ReceiptItemDetailType.OTHER_TAX
        ) {
            return '';
        }
        return item.quantity || 0;
    };

    const exportCsv = () => {
        const csvData: ICurrentGuestReportCsv[] = [];
        currentGuestList.forEach((item) => {
            const common = {
                roomName: item.roomName || '',
                guestName: mapGuestName(item.guest?.yomigana, item.guest?.fullName),
                roomGuestName: mapGuestName(
                    item.roomGuest?.yomigana,
                    item.roomGuest?.fullName,
                ),
                numberOfPeople: mapNumberOfPeople(item),
                nightOfStay: mapNightOfStay(item),
                checkInTime: item.checkInTime,
                planName: item.plan?.name || '',
            };
            const innitReceiptItemDetail: ICurrentGuestSalePayment[] = [
                {
                    id: 0,
                    date: '',
                    type: ReceiptItemDetailType.LOCAL_TAX,
                    amount: '',
                    planName: '',
                },
            ];
            const memo = item.memo ? replaceBreakLine(item.memo) : '';
            const receiptItemDetails = mapReceiptItemDetails(item.receiptItemDetails);

            (receiptItemDetails.length
                ? receiptItemDetails
                : [...innitReceiptItemDetail]
            ).forEach((receiptItem) => {
                let detail: ICurrentGuestReportCsv = {
                    ...common,
                    receiptItemName: getReceiptItemName(receiptItem),
                    receiptItemDate: receiptItem.date
                        ? parseDate(receiptItem.date).fmYYYYMMDD()
                        : '',
                    receiptItemQuantity: getReceiptItemQuantity(receiptItem),
                    receiptItemPrice: getPrice(receiptItem),
                    /**
                     * NOTE: DO NOT DELETE
                     * This block of code is commented out TEMPORARILY
                     * for hiding the meal related columns
                     * when exporting CSV file
                     */
                    // maleSaleItemName: '',
                    // maleSaleItemDate: '',
                    // maleSaleItemQuantity: '',
                    memo,
                };
                /**
                 * NOTE: DO NOT DELETE
                 * This block of code is commented out TEMPORARILY
                 * for hiding the meal related columns
                 * when exporting CSV file
                 */
                // if (receiptItem.isInMealGroup) {
                //     detail = {
                //         ...detail,
                //         maleSaleItemName: receiptItem.saleItemName || '',
                //         maleSaleItemDate: receiptItem.date
                //             ? parseDate(receiptItem.date).fmYYYYMMDD()
                //             : '',
                //         maleSaleItemQuantity: `${receiptItem.quantity}`,
                //     };
                // }
                detail.memo = memo;
                csvData.push(detail);
            });
        });
        /**
         * NOTE: DO NOT DELETE
         * This block of code is commented out TEMPORARILY
         * for hiding the meal related columns
         * when exporting CSV file
         */
        // const heading = exportCurrentGuestColumns.map((key) =>
        //     t(`report.currentGuest.exportColumns.${key}`),
        // );
        const heading = exportCurrentGuestColumns
            .filter(
                (key) =>
                    key !== 'mealItem' &&
                    key !== 'dateMeal' &&
                    key !== 'numberOfMealItem',
            )
            .map((item) => t(`report.currentGuest.exportColumns.${item}`));

        exportCsvFileWithXlsx(
            csvData,
            `${ReportTableCsvFileName.CURRENT_GUEST}.csv`,
            heading,
        );
    };

    return isShowTable ? (
        <Button onClick={exportCsv}>{t('report.currentGuest.exportCSV')}</Button>
    ) : null;
}

export default ExportCsvButton;
