import { notification, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { showConfirm } from '~common/notification';
import {
    isTllConnectedSelector,
    siteControllerSelector,
    updateSiteController,
} from '~features/site-controller/reducers/site-controller.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './SiteControllerToggleBox.scss';
type Prop = {
    handleSubmit: (isConnected: boolean) => void;
    isReadOnly: boolean;
};
function SiteControllerToggleBox(prop: Prop) {
    const { isReadOnly, handleSubmit } = prop;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isTllConnected = useAppSelector(isTllConnectedSelector);
    const siteController = useAppSelector(siteControllerSelector);

    const _updateSiteController = async () => {
        const updatedSiteController = {
            ...siteController,
            isTllConnected: !isTllConnected,
        };
        const response = await dispatch(updateSiteController(updatedSiteController));
        if (updateSiteController.fulfilled.match(response)) {
            if (response.payload?.success) {
                if (isTllConnected)
                    notification.success({
                        message: t(
                            'siteController.notification.disconnectedSiteController',
                        ),
                    });
                else
                    notification.success({
                        message: t('siteController.notification.connectedSiteController'),
                    });
                return;
            }

            notification.error({
                message: t('siteController.notification.error'),
                description: response.payload?.message || [],
            });
        }
    };

    const onChange = (check: boolean) => {
        if (isTllConnected) {
            showConfirm({
                title: t('siteController.confirm.title'),
                cancelText: t('common.buttonCancelText'),
                okText: t('siteController.confirm.okText'),
                onOk() {
                    handleSubmit(false);
                },
            });
        } else {
            handleSubmit(true);
        }
    };

    return (
        <div className="site-controller-toggle-box-wrapper">
            <p className="toggle-desc"> {t('siteController.toggleBox.description')} </p>
            <div className="d-flex a-center">
                <p className="toggle-content">{t('siteController.toggleBox.content')} </p>
                <Switch
                    checkedChildren={t('siteController.toggleBox.on')}
                    unCheckedChildren={t('siteController.toggleBox.off')}
                    onChange={onChange}
                    checked={isTllConnected}
                    disabled={isReadOnly}
                />
            </div>
        </div>
    );
}

export default SiteControllerToggleBox;
