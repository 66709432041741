const siteController = {
    title: 'Site controller setting',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        siteController: 'Site controller setting',
    },
    form: {
        description:
            'Please set the "Linkage User ID" and "Linkage Password" provided by Seanuts.',
        accountSetting: 'Account setting',
        tllName: { label: 'Site controller' },
        tllBookingAcquisitionUrl: {
            label: 'Booking Acquisition URL',
            placeholder: 'https://www.tl-lincoln.net/',
        },
        tllPmsUserId: { label: 'User ID', placeholder: 'Enter ID' },
        tllPmsPassword: { label: 'Password', placeholder: 'Enter password' },
    },
    toggleBox: {
        description:
            'Turning off the switch below will temporarily suspend the integration with the site controller. After the integration is suspended, if you wish to resume the integration, please turn the switch back on to resume the linkage.',
        content: 'Connection to site controller:',
        on: 'ON',
        off: 'OFF',
    },
    confirm: {
        title: 'Do you want to temporarily disconnect to site controller?',
        okText: 'Pause',
    },
    footer: {
        cancelBtn: 'Cancel',
        submitBtn: 'Save',
    },
    confirmCancel: 'Do you want to cancel?',
    notification: {
        success: 'Site controller has been updated successfully',
        error: 'Something went wrong',
        connectedSiteController: 'Site controller has been connected successfully',
        disconnectedSiteController: 'Site controller has been disconnected successfully',
    },
};
export default siteController;
