export const indicatorCreate = {
    title: '新しいページの追加',
    updateTitle: 'インジケーターの詳細',
    indicatorName: 'インジケーター名',
    size: '表示サイズ',
    selectRoom: '部屋を選択',
    selectMultipleBox: '複数のボックスを選択',
    type: {
        room: '部屋',
        facility: '設備',
    },
    addRoom: '部屋',
    update: '保存',
    assignment: 'アサイン',
    reset: 'リセット',
    searchRoomName: '部屋名を検索',
    submit: 'インジケーターを作成',
    cancel: 'キャンセル',
    updateSuccess: '変更は正常に保存されました！',
    createSuccess: 'インジケーター作成は成功しました。',
    helpText: 'セルをクリックすると部屋が追加できます',
    pleaseSelectACell: 'セルを選択してください',
    changeSizeConfirm:
        'Room assigned to boxes outside of {{size}} area will be unassigned. Are you sure to do this?',
    changeTypeConfirm: 'Switch the Indicator type will reset the grid',
    error: {
        duplicateName: `インジケーター名は表内で一意である必要があります。もう一度設定してください。`,
    },
};
