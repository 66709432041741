export default {
    1: 'Hokkaido',
    2: 'Aomori',
    3: 'Iwate',
    4: 'Miyagi',
    5: 'Akita',
    6: 'Yamagata',
    7: 'Fukushima',
    8: 'Ibaraki',
    9: 'Tochigi',
    10: 'Gunma',
    11: 'Saitama',
    12: 'Chiba',
    13: 'Tokyo',
    14: 'Kanagawa',
    15: 'Niigata',
    16: 'Toyama',
    17: 'Ishikawa',
    18: 'Fukui',
    19: 'Yamanashi',
    20: 'Nagano',
    21: 'Gifu',
    22: 'Shizuoka',
    23: 'Aichi',
    24: 'Mie',
    25: 'Shiga',
    26: 'Kyoto',
    27: 'Osaka',
    28: 'Hyogo',
    29: 'Nara',
    30: 'Wakayama',
    31: 'Tottori',
    32: 'Shimane',
    33: 'Okayama',
    34: 'Hiroshima',
    35: 'Yamaguchi',
    36: 'Tokushima',
    37: 'Kagawa',
    38: 'Ehime',
    39: 'Kochi',
    40: 'Fukuoka',
    41: 'Saga',
    42: 'Nagasaki',
    43: 'Kumamoto',
    44: 'Oita',
    45: 'Miyazaki',
    46: 'Kagoshima',
    47: 'Okinawa',
};
