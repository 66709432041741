export const currentGuestReportEn = {
    titlePage: 'Current guest report',
    person: 'People',
    breadcrumbs: {
        report: 'Report',
        currentGuest: 'Current guest report',
    },
    filterForm: {
        dateLabel: 'Date:',
        displayButton: 'Display',
    },
    genderBreakDown: {
        total: 'Number of adults',
        male: 'Number of men',
        female: 'Number of women',
        other: 'Number of other genders',
    },
    childrenBreakDown: {
        total: 'Number of children',
        other: 'Number of others',
    },
    guestTable: {
        roomName: 'Room name',
        guestName: 'Guest name',
        roomGuestName: 'Guest name in room',
        numberOfGuestInRoom: 'Number of guests in room',
        nightOfStay: 'Number of nights',
        checkInTime: 'Check in time',
        plan: 'Plan',
        salePaymentItem: 'Sales/Payment',
        date: 'Date',
        numberOfItem: 'Quantity',
        priceOfItem: 'Unit price',
        mealItem: 'Meal',
        memo: 'Memo',
        totalChildren: 'Children',
        guestAultsKey: {
            numberOfAdults: 'Adult: ',
            numberOfMale: 'Men: ',
            numberOfFemale: 'Women: ',
            numberOfOtherGenderGuest: 'Other: ',
        },
        receiptItemDetail: {
            roomPrice: 'Room price',
            payment: 'Payment',
        },
    },
    exportColumns: {
        roomName: 'Room name',
        guestName: 'Guest name',
        roomGuestName: 'Guest name in room',
        numberOfGuestInRoom: 'Number of guests in room',
        nightOfStay: 'Number of nights',
        checkInTime: 'Check in time',
        plan: 'Plan',
        salePaymentItem: 'Sale/Payment',
        date: 'Date',
        numberOfItem: 'Quantity',
        priceOfItem: 'Unit price',
        mealItem: 'Meal',
        dateMeal: 'Date',
        numberOfMealItem: 'Quantity',
        memo: 'Memo',
    },
    exportCSV: 'CSV export',
};
