import { Button, Col, Form, Row, Typography } from 'antd';
import type { UploadFile as IUploadFile } from 'antd/es/upload/interface';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DateFormat,
    FilePathKey,
    INPUT_TEXT_MAX_LENGTH,
    MEMO_MAX_LENGTH,
    UploadFileStatus,
} from '~common/constants';
import {
    InputPhoneNumber,
    InputPostalCode,
    InputText,
    InputTextArea,
    RadioGroup,
    SingleDatePicker,
    SingleSelect,
    UploadFileCustom,
} from '~components';
import {
    getGuestClassificationListForDropdown,
    guestClassificationDropdownOptionsSelector,
} from '~features/guest-classification/guest-classification.reducer';
import {
    ACCEPTED_FILE_EXTENSIONS,
    Gender,
    MAX_NUMBER_OF_FILES_PER_GUEST,
    MAX_SIZE_FILE,
    UPLOAD_FILE_MAX_SIZE,
} from '~features/guest/constants';
import { getGuestFormId } from '~features/guest/helper';
import {
    countryDropdownSelector,
    getCountryListForDropdown,
    selectedGuestDetailSelector,
} from '~features/guest/reducers/guest.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { Dayjs, todayDayjs } from '~plugins/dayjs';
import './GuestBasicInfoForm.scss';

interface IProp {
    control: any;
    setValue: any;
    isShowUploadFile: boolean;
    isCreateForm?: boolean;
}

function GuestBasicInfoForm(props: IProp) {
    const { Title } = Typography;
    const { control, setValue, isShowUploadFile, isCreateForm } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [showClearBtn, setShowClearBtn] = useState(false);
    const countryOptions = useAppSelector(countryDropdownSelector);
    const classificationList = useAppSelector(guestClassificationDropdownOptionsSelector);
    const guestDetail = useAppSelector(selectedGuestDetailSelector);

    const genderOptions = [
        {
            value: Gender.MALE,
            label: t('guest.form.basicInfo.gender.male'),
        },
        {
            value: Gender.FEMALE,
            label: t('guest.form.basicInfo.gender.female'),
        },
        {
            value: Gender.OTHER,
            label: t('guest.form.basicInfo.gender.other'),
        },
    ];

    const classificationOptions = classificationList.map((classification) => {
        return {
            value: classification.id,
            label: classification.name,
        };
    });

    const disabledBirthDayDate = (current: Dayjs) => {
        // Can not select days after today
        return current.isAfter(todayDayjs.subtract(1, 'day'));
    };

    useEffect(() => {
        dispatch(getCountryListForDropdown());
        dispatch(getGuestClassificationListForDropdown({}));
    }, [dispatch]);

    useEffect(() => {
        setShowClearBtn(!!guestDetail?.classificationId);
        if (guestDetail?.files) {
        }
    }, [guestDetail]);

    const fileList = useMemo(() => {
        if (!guestDetail?.filesInformation?.length) return [];
        return guestDetail?.filesInformation?.map((file) => {
            return {
                uid: `${file.id}`,
                name: file.originalName,
                status: UploadFileStatus.DONE,
                url: file.url,
                path: file.path,
                size: file.size,
                fileName: file.fileName,
                type: file.mimetype,
            } as IUploadFile;
        });
    }, [guestDetail]);

    return (
        <div className="guest-info-form">
            <div className="guest-info-form-content">
                <Title level={5} className="guest-info-form-content-title">
                    {t('guest.form.basicInfo.contentTitle')}
                </Title>
                <Form layout="vertical">
                    <Row gutter={[24, 14]}>
                        <Col span={12}>
                            <InputText
                                label={t('guest.form.basicInfo.fullName.label')}
                                placeholder={t(
                                    'guest.form.basicInfo.fullName.placeholder',
                                )}
                                name="fullName"
                                id={getGuestFormId('fullName')}
                                allowClear
                                control={control}
                                maxLength={INPUT_TEXT_MAX_LENGTH}
                            />
                        </Col>
                        <Col span={12}>
                            <InputText
                                label={t('guest.form.basicInfo.yomigana.label')}
                                placeholder={t(
                                    'guest.form.basicInfo.yomigana.placeholder',
                                )}
                                name="yomigana"
                                id={getGuestFormId('yomigana')}
                                required={true}
                                allowClear
                                control={control}
                                maxLength={INPUT_TEXT_MAX_LENGTH}
                            />
                        </Col>
                        <Col span={12}>
                            <InputPhoneNumber
                                label={t('guest.form.basicInfo.mobilePhoneNumber.label')}
                                placeholder={t(
                                    'guest.form.basicInfo.mobilePhoneNumber.placeholder',
                                )}
                                name="mobilePhoneNumber"
                                id={getGuestFormId('mobilePhoneNumber')}
                                control={control}
                                allowClear
                            />
                        </Col>
                        <Col span={12}>
                            <InputText
                                label={t('guest.form.basicInfo.emailAddress.label')}
                                placeholder={t(
                                    'guest.form.basicInfo.emailAddress.placeholder',
                                )}
                                name="emailAddress"
                                id={getGuestFormId('emailAddress')}
                                control={control}
                                allowClear
                                maxLength={INPUT_TEXT_MAX_LENGTH}
                            />
                        </Col>
                        <Col span={12}>
                            <SingleDatePicker
                                label={t('guest.form.basicInfo.birthday.label')}
                                placeholder={t(
                                    'guest.form.basicInfo.birthday.placeholder',
                                )}
                                control={control}
                                name="birthday"
                                id={getGuestFormId('birthday')}
                                format={DateFormat.YYYY_MM_DD_SLASH}
                                disabledDate={disabledBirthDayDate}
                                inputReadOnly={false}
                            />
                        </Col>
                        <Col span={12}>
                            <SingleSelect
                                label={t('guest.form.basicInfo.gender.label')}
                                placeholder={t('guest.form.basicInfo.gender.placeholder')}
                                name="gender"
                                id={getGuestFormId('gender')}
                                control={control}
                                options={genderOptions}
                                allowClear={true}
                            />
                        </Col>
                        <Col span={12}>
                            <Row gutter={4}>
                                <Col span={6}>
                                    <InputPostalCode
                                        label={t('guest.form.basicInfo.postalCode.label')}
                                        placeholder={t(
                                            'guest.form.basicInfo.postalCode.placeholder',
                                        )}
                                        name="postalCode"
                                        id={getGuestFormId('postalCode')}
                                        control={control}
                                    />
                                </Col>
                                <Col span={18}>
                                    <InputText
                                        label={t('guest.form.basicInfo.address.label')}
                                        placeholder={t(
                                            'guest.form.basicInfo.address.placeholder',
                                        )}
                                        name="address"
                                        id={getGuestFormId('address')}
                                        control={control}
                                        maxLength={INPUT_TEXT_MAX_LENGTH}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <SingleSelect
                                label={t('guest.form.basicInfo.country.label')}
                                placeholder={t(
                                    'guest.form.basicInfo.country.placeholder',
                                )}
                                name="countryId"
                                id={getGuestFormId('countryId')}
                                control={control}
                                options={countryOptions}
                                allowClear={true}
                            />
                        </Col>
                        <Col span={12}>
                            <RadioGroup
                                label={t('guest.form.basicInfo.classification.label')}
                                control={control}
                                name="classificationId"
                                id={getGuestFormId('classificationId')}
                                options={classificationOptions}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setShowClearBtn(true);
                                    }
                                }}
                            />
                            <Button
                                className={
                                    showClearBtn ? 'show-clear-btn' : 'hide-clear-btn'
                                }
                                onClick={() => {
                                    setValue(`classificationId`, null);
                                    setShowClearBtn(false);
                                }}
                            >
                                {t('guest.form.basicInfo.classification.clearBtn')}
                            </Button>
                        </Col>
                        <Col span={22}>
                            <InputTextArea
                                label={t('guest.form.basicInfo.memo.label')}
                                name="memo"
                                id={getGuestFormId('memo')}
                                control={control}
                                placeholder={t('guest.form.basicInfo.memo.placeholder')}
                                maxLength={MEMO_MAX_LENGTH}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="guest-info-form-attachment">
                <Title level={5} className="guest-info-form-attachment-title">
                    {t('guest.form.basicInfo.attachmentTitle')}
                </Title>
                {isShowUploadFile && (
                    <>
                        <UploadFileCustom
                            title={t('guest.form.basicInfo.image.title')}
                            subTitle={
                                <span>
                                    {t('guest.form.basicInfo.image.description')}
                                    <span className="upload-file-max-size">
                                        : {UPLOAD_FILE_MAX_SIZE}MB
                                    </span>
                                </span>
                            }
                            name="files"
                            control={control}
                            label=""
                            maxCount={MAX_NUMBER_OF_FILES_PER_GUEST}
                            maxSize={MAX_SIZE_FILE}
                            multiple={true}
                            listType="picture"
                            acceptTypes={ACCEPTED_FILE_EXTENSIONS}
                            pathKey={FilePathKey.GUEST}
                            defaultFileList={isCreateForm ? [] : fileList}
                        />
                        {/* if there is an upload error, auto-scroll to the div tag which contains the error message */}
                        <div id={getGuestFormId('files')}></div>
                    </>
                )}
            </div>
        </div>
    );
}

export default GuestBasicInfoForm;
