const roomTypeImport = {
    error: {
        name: {
            required: '部屋タイプ名が必要です',
            maxLength: '部屋タイプ名最大文字数は{{maxLength}}字です',
            unique: '部屋タイプ名は重複してはなりません',
            hasNoSpecialCharacters: '部屋タイプ名は特殊文字を含めません',
        },
        roomTypeCode: {
            required: '部屋タイプコードが必要です',
            maxLength: '部屋タイプコードの最大文字数は{{maxLength}} 字です',
            unique: '部屋タイプコードは重複してはなりません',
        },
        abbreviation: {
            required: '略称が必要です',
            maxLength: '略称の最大文字数は {{maxLength}} 字です',
            unique: '略称は重複してはなりません',
        },
        standardCapacity: {
            required: '標準定員が必要です',
            max: '標準定員は最低でも{{max}}でなければいけません',
            min: '標準定員は最低1でないといけません',
            number: '標準定員は数字でなければいけません',
        },
        marketingChannel: {
            maxLength: '販売チャネル名の最大文字数は{{max}} 字です',
            unique: '販売チャネル名は重複してはなりません',
            hasNoSpecialCharacters: '販売チャネル名は特殊文字を含めません',
        },
    },
    importErrorKey: {
        name: '部屋タイプ名',
        roomTypeCode: '部屋タイプコード',
        abbreviation: '略称',
        standardCapacity: '標準定員',
        marketingChannel: '販売チャネル名',
    },
};

export default roomTypeImport;
