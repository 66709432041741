import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _, { cloneDeep } from 'lodash';
import { DateFormat } from '~common/constants';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { AppState } from '~plugins/redux-toolkit/store';
import { PredefinedDayRange, defaultTopSectionOverviewQuery } from '../constants';
import { IReportState, ITopSectionOverview } from '../interfaces';
import { reportService } from '../report.service';
import { ITopSectionQuery } from './../interfaces';

export const fetchTopSectionOverview = createAsyncThunk(
    'report/getTopSectionOverview',
    async (_, { getState }) => {
        const query = topSectionQuerySelector(getState() as AppState);
        return await reportService.getTopSectionOverview(query);
    },
);

export const getDiagramData = createAsyncThunk(
    'report/getDiagramData',
    async (_, { getState }) => {
        const query = diagramTimeSelector(getState() as AppState);
        return await reportService.getDiagramData({ datePeriod: query });
    },
);

export const fetchOccupancyHeatMap = createAsyncThunk(
    'report/getOccupancyHeatMap',
    async (query: ITopSectionQuery) => {
        return await reportService.getOccupancyHeatMap(query);
    },
);

const initialState: IReportState = {
    topSectionQuery: cloneDeep(defaultTopSectionOverviewQuery),
    topSectionOverview: {} as ITopSectionOverview,
    performanceMarketingChannel: [],
    occupancyHeatMap: [],
    isLoadingOccupancyHeatMap: false,
    isFetchingDiagramList: false,
    diagramCurrentDate: customDayjs().format('YYYY-MM'),
    diagramList: [],
    selectedPredefinedDayRange: PredefinedDayRange.DAYS_30,
};

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setTopSectionQuery: (state, action) => {
            state.topSectionQuery = action.payload;
        },
        setTopSectionOverview: (state, action) => {
            state.topSectionOverview = action.payload;
        },
        setDiagramCurrentDate: (state, action) => {
            state.diagramCurrentDate = action.payload;
        },
        setSelectedPredefinedDayRange: (state, action) => {
            state.selectedPredefinedDayRange = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTopSectionOverview.fulfilled, (state, action) => {
            state.topSectionOverview = action.payload?.data || {};
            state.performanceMarketingChannel =
                action.payload?.data?.performanceMarketingChannel || [];
        });
        builder.addCase(getDiagramData.pending, (state, action) => {
            state.isFetchingDiagramList = true;
        });
        builder.addCase(getDiagramData.fulfilled, (state, action) => {
            state.diagramList = action.payload?.data || [];
            state.isFetchingDiagramList = false;
        });
        builder.addCase(fetchOccupancyHeatMap.fulfilled, (state, action) => {
            state.isLoadingOccupancyHeatMap = false;
            state.occupancyHeatMap = action.payload?.data || [];
        });
        builder.addCase(fetchOccupancyHeatMap.pending, (state, action) => {
            state.isLoadingOccupancyHeatMap = true;
        });
    },
});

export const {
    setTopSectionQuery,
    setTopSectionOverview,
    setDiagramCurrentDate,
    setSelectedPredefinedDayRange,
} = reportSlice.actions;

export const topSectionSelector = (state: AppState) => {
    return state.reportSummary.topSectionOverview;
};
export const topSectionQuerySelector = (state: AppState) => {
    return state.reportSummary.topSectionQuery;
};

export const reportSelector = (state: AppState) => {
    return state.reportSummary;
};

export const diagramTimeSelector = (state: AppState) => {
    const { diagramCurrentDate, selectedPredefinedDayRange } = state.reportSummary;
    return [
        parseDate(diagramCurrentDate).startOf('month')?.fmYYYYMMDDHHmmss(),
        parseDate(diagramCurrentDate)
            .add(selectedPredefinedDayRange, 'month')
            .endOf('month')
            ?.fmYYYYMMDDHHmmss(),
    ];
};

export const diagramChartDataSelector = (state: AppState) => {
    const { diagramCurrentDate, diagramList, selectedPredefinedDayRange } =
        state.reportSummary;
    const startDate = parseDate(diagramCurrentDate).startOf('month');
    const endDate = parseDate(diagramCurrentDate)
        .add(selectedPredefinedDayRange, 'month')
        .endOf('month');
    const diffDay = endDate.diff(startDate, 'day');

    const labels = [];
    for (let date = 0; date <= diffDay; date++) {
        if (selectedPredefinedDayRange === PredefinedDayRange.DAYS_30) {
            labels.push(startDate.add(date, 'day').format(DateFormat.DD));
        } else {
            labels.push(startDate.add(date, 'day').fmMMDD());
        }
    }

    const _currentBarData: Record<string, number> = {};
    const _currentLineData: Record<string, number> = {};
    const _previousBarData: Record<string, number> = {};
    const _previousLineData: Record<string, number> = {};
    _.forEach(diagramList, (item) => {
        let date = parseDate(item.date).fmMMDD();
        if (selectedPredefinedDayRange === PredefinedDayRange.DAYS_30) {
            date = parseDate(item.date).format(DateFormat.DD);
        }
        _currentLineData[date] = Number(item.current?.adr) || 0;
        _currentBarData[date] = Number(item.current?.totalAmount || 0) / 1000;
        _previousLineData[date] = Number(item.previous?.adr) || 0;
        _previousBarData[date] = Number(item.previous?.totalAmount || 0) / 1000;
    });

    return {
        labels,
        currentBar: labels.map((item) => _currentBarData[item] || 0),
        currentLine: labels.map((item) => _currentLineData[item] || 0),
        previousBar: labels.map((item) => _previousBarData[item] || 0),
        previousLine: labels.map((item) => _previousLineData[item] || 0),
    };
};

export const reportStateSelector = (state: AppState) => state.reportSummary;
export const performanceMarketingChannelSelector = (state: AppState) =>
    state.reportSummary.performanceMarketingChannel;

export default reportSlice.reducer;
