export const salesByOtaReportJa = {
    title: 'OTA別売上',
    breadcrumbs: {
        report: 'レポート',
        salesByOta: 'OTA別売上',
    },
    filterForm: {
        pickerLabel: '日付',
        otaLabel: 'マーケティングチャネル',
        allOption: '全て',
        displayMode: '日次',
        filterButton: '表示',
        undefinedMarketingChannel: '販売チャネル未定義',
    },
    tableColumns: {
        date: '日付',
        lastMonth: '前月',
        currentMonth: '今月',
        nextMonth: '来月',
        ota: 'OTA名',
        totalBookedRoom: '部屋数',
        totalAdult: '大人',
        totalChild: '子供',
        totalRevenue: '総売上',
        avgRevenue: '平均価格',
        total: '合計（税込）',
        undefinedMarketingChannel: '販売チャネル未定義',
    },
    tableHeader: '※ 括弧内の数字は前年同日の値です',
    total: '合計（税込）',
    unit: '¥',
    percentage: '%',
};
