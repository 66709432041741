import React, { useState } from 'react';
import './DropdownMenu.scss';
import { Popover } from 'antd';
import classNames from 'classnames';
import { IDropDownOption } from '~common/interfaces';
import { TooltipPlacement } from 'antd/lib/tooltip';

type Props = {
    placement: TooltipPlacement | undefined;
    wrappClassName?: string;
    dropdownClassName?: string;
    children?: React.ReactNode;
    trigger?: ('contextMenu' | 'click' | 'hover')[] | undefined;
    options: IDropDownOption[];
    onClick?: (option: string | number) => void;
    customRenderItem?: (item: IDropDownOption) => React.ReactNode;
};

export function DropdownMenu({
    placement,
    trigger,
    children,
    wrappClassName,
    dropdownClassName,
    customRenderItem,
    onClick,
    options,
}: Props) {
    const wrapperClasses = classNames('dropdown-menu-wrapper', wrappClassName);
    const dropdownClasses = classNames('dropdown-menu-overlay', dropdownClassName);
    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };
    return (
        <Popover
            open={visible}
            onOpenChange={handleVisibleChange}
            content={
                <div className="custom-menu-content">
                    <ul className="custom-menu-list">
                        {options.map((option) => (
                            <li
                                key={option.value}
                                onClick={() => {
                                    if (onClick) {
                                        onClick(option.value);
                                    }
                                    handleVisibleChange(false);
                                }}
                                className="custom-menu-list-item"
                            >
                                {customRenderItem
                                    ? customRenderItem(option)
                                    : option.label}
                            </li>
                        ))}
                    </ul>
                </div>
            }
            placement={placement}
            trigger={trigger}
            overlayClassName={dropdownClasses}
        >
            <div className={wrapperClasses}>{children}</div>
        </Popover>
    );
}

DropdownMenu.defaultProps = {
    placement: 'bottom',
};
