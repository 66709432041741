import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { InputSearch } from '~components';
import SelectField from './SelectField/SelectField';
import './FilterForm.scss';
import { setKeyword } from '~features/sale-item/reducers/sale-item.reducer';
import { useDispatch } from 'react-redux';
import {
    AdminGroup,
    GA_KEYWORD_CONTENT,
    INPUT_TEXT_MAX_LENGTH,
    UserGroup,
} from '~common/constants';
import { useNavigate } from 'react-router-dom';
import { checkUserPermission } from '~common/commonFunctions';
import { trackSearchKeyword } from '~plugins/analytics';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.SALE_ITEM);
    };

    const createSaleItem = () => {
        navigate('create-sale-item');
    };

    return (
        <div className="filter-sale-item-list-wrapper">
            <div>
                <InputSearch
                    addonBefore={SelectField()}
                    onSearch={onSearch}
                    placeholder={t('common.searchPlaceholder')}
                    max={INPUT_TEXT_MAX_LENGTH}
                />
            </div>
            {!isReadOnly && (
                <div>
                    <Button type="primary" onClick={createSaleItem}>
                        <PlusOutlined />
                        <span className="btn-text">{t('saleItem.list.createBtn')}</span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
