export const operationStatusPageJa = {
    titlePage: '稼働レポート',
    breadcrumbs: {
        report: '帳票',
        operationStatus: '稼働レポート',
    },
    filterForm: {
        dateLabel: '集計月:',
        channelLabel: 'チャンネル:',
        channel: {
            all: 'すべてのチャンネル',
        },
        displayButton: '表示',
        noteInformation: 'グレイ字は前年同日の情報',
    },
    table: {
        operationStatus: '稼働状況',
        utilRate: '稼働率',
        date: '日付',
        totalRooms: '総室数',
        totalBookings: '宿泊室数',
        totalAdult: '宿泊(大人)',
        totalChildren: '宿泊(子供)',
        sales: '販売金額',
        adr: 'ADR',
        revPAR: 'RevPAR',
        numberOfRoomsRate: '稼働率',
        adultCapacityRate: '稼働率(大人)',
        day: {
            monday: '月',
            tuesday: '火',
            wednesday: '水',
            thursday: '木',
            friday: '金',
            saturday: '土',
            sunday: '日',
        },
    },
};
