import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';

import { IBodyResponse, IGetListResponse } from '~common/interfaces';
import {
    IPlan,
    IPlanGetListQuery,
    IPlanListDropdownResponse,
    IPlanDropDownQuery,
    IPlanBulkDeleteItem,
    IPlanCreateBody,
    IPlanListResponse,
} from '../interfaces';
import { ITaxListDropdownResponse } from '~features/sale-item/interfaces';
import { IGetTaxListForDropdownQuery } from '~features/tax/constants';

class PlanService extends ApiService {
    getDropdown(params: IPlanDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<IPlanListDropdownResponse>;
    }

    getDetail(id: number) {
        return this._get(`${this.baseUrl}/${id}`) as Promise<IBodyResponse<IPlan>>;
    }

    getList(queryString: IPlanGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IPlan>>
        >;
    }

    bulkDelete(bulkDeleteBody: IPlanBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    create(createBody: IPlanCreateBody) {
        return this._post<IPlanCreateBody, IPlanListResponse>(this.baseUrl, createBody);
    }

    update(id: number, updateBody: IPlanCreateBody) {
        return this._patch<IPlanCreateBody, IPlanListResponse>(
            `${this.baseUrl}/${id}`,
            updateBody,
        );
    }

    getTaxListForDropdown(query: IGetTaxListForDropdownQuery) {
        return this._get(`/tax/dropdown`, {
            params: { ...query },
        }) as Promise<ITaxListDropdownResponse>;
    }
}

export const planService = new PlanService({ baseUrl: '/plan' }, axiosService);
