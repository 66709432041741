import { Col, Row } from 'antd';
import { ISaleItemFormItem } from '~features/sale-item/interfaces';
import './CreateSaleItemsForm.scss';
import CreateSaleItemsFormSaleItemList from './CreateSaleItemsFormSaleItemList/CreateSaleItemsFormSaleItemList';
import CreateSaleItemsFormSetting from './CreateSaleItemsFormSetting/CreateSaleItemsFormSetting';

interface IProps {
    control: any;
    currentSaleItemList: ISaleItemFormItem[];
}

function CreateSaleItemsForm({ control, currentSaleItemList }: IProps) {
    return (
        <div className="create-sale-items-form">
            <Row gutter={24}>
                <Col span={6}>
                    <CreateSaleItemsFormSetting
                        currentSaleItemList={currentSaleItemList}
                    />
                </Col>
                <Col span={18}>
                    <CreateSaleItemsFormSaleItemList
                        control={control}
                        currentSaleItemList={currentSaleItemList}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default CreateSaleItemsForm;
