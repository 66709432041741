import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { IRoomBookingDetail, ISplitBookingRoomBookingItem } from '../interfaces';
import { roomBookingService } from '../services/room-booking.service';
import { useAppDispatch, useAppSelector } from '~hooks';
import { fetchRoomBookingDetail } from '../reducers/room-booking.reducer';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import { planDropdownSelector } from '~features/plan/reducers/plan.reducer';

export const useSplitBooking = (selectedRoomBookingDetail: IRoomBookingDetail) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const planDropdown = useAppSelector(planDropdownSelector);
    const { roomBookingId, roomBookingItemId } = useParams();

    const roomBookingItem = selectedRoomBookingDetail?.roomBookingItems.find(
        (roomBookingItem) => Number(roomBookingItem.id) === Number(roomBookingItemId),
    );
    const targetRoomBookingItems = selectedRoomBookingDetail?.roomBookingItems.filter(
        (roomBookingItem) =>
            Number(roomBookingItem.id) === Number(roomBookingItemId) ||
            roomBookingItem?.parentRoomBookingItemId === Number(roomBookingItemId),
    );
    const parentRoomBookingItem = targetRoomBookingItems.find(
        (roomBookingItems) => !roomBookingItems.parentRoomBookingItemId,
    );
    const originalPlanId = parentRoomBookingItem?.planId;

    const currentBookingDates = targetRoomBookingItems?.reduce(
        (currentBookingDates, roomBookingItem) => {
            const { startDateOfStay, endDateOfStay } = roomBookingItem;
            const start = parseDate(startDateOfStay).startOf('day');
            const end = parseDate(endDateOfStay).endOf('day');
            const dateDiff = parseDate(end).diff(start, 'day');
            const datesArray = Array.from({ length: dateDiff }).map((_, i) => {
                const date = {
                    date: start.add(i, 'day').format('YYYY-MM-DD'),
                    roomTypeId: roomBookingItem.roomTypeId,
                    roomId: roomBookingItem.room?.id,
                };

                return `${date.date}_${date.roomTypeId}_${date.roomId}`;
            });

            currentBookingDates.push(...datesArray);

            return currentBookingDates;
        },
        [] as string[],
    );

    const [selectedDates, setSelectedDates] = useState<string[]>(
        currentBookingDates || [],
    );

    const sortedSelectedDatesByDate = selectedDates.sort((a, b) =>
        dayjs(a.split('_')[0]) > dayjs(b.split('_')[0]) ? 1 : -1,
    );
    const selectedItems = sortedSelectedDatesByDate.reduce(
        (acc, id) => {
            const ids = id.split('_');
            const date = dayjs(ids[0]);
            const roomTypeId = Number(ids[1]);
            const roomId = Number(ids[2]);
            const index = acc.findIndex(
                (item) =>
                    item.roomTypeId === roomTypeId &&
                    item.roomId === roomId &&
                    item.endDate.diff(date) === 0,
            );

            if (index >= 0) {
                acc[index] = {
                    ...acc[index],
                    endDate: date.add(1, 'day'),
                };

                return acc;
            }

            acc.push({
                roomTypeId: roomTypeId,
                roomId: roomId,
                startDate: date,
                endDate: date.add(1, 'day'),
            });

            return acc;
        },
        [] as {
            roomTypeId: number;
            roomId: number;
            startDate: Dayjs;
            endDate: Dayjs;
        }[],
    );

    const newRoomBookingItems = selectedItems.map((item) => {
        const planId =
            planDropdown.find(
                (plan) =>
                    plan.value === originalPlanId &&
                    plan.roomTypeIds.includes(item.roomTypeId),
            )?.value ||
            planDropdown.find((plan) => plan.roomTypeIds.includes(item.roomTypeId))
                ?.value;

        return {
            roomTypeId: item.roomTypeId,
            roomId: item.roomId,
            planId: planId,
            startDateOfStay: item.startDate.format('YYYY-MM-DD HH:mm:ss'),
            endDateOfStay: item.endDate.format('YYYY-MM-DD HH:mm:ss'),
        } as ISplitBookingRoomBookingItem;
    });

    const splitBooking = async () => {
        const response = await roomBookingService.splitRoomBookingItems(
            Number(roomBookingId),
            Number(roomBookingItemId),
            { roomBookingItems: newRoomBookingItems },
        );

        if (response.success) return response.data;

        throw Error(response.message);
    };

    const { isPending, mutate } = useMutation({
        mutationFn: splitBooking,
        onSuccess: () => {
            notification.success({
                message: t('roomBooking.detail.message.updateSuccess'),
            });
            dispatch(fetchRoomBookingDetail(Number(roomBookingId)));
            navigate(`/room-booking/${roomBookingId}/detail`);
        },
        onError: (error) => {
            notification.error({
                message: t('common.somethingWentWrong'),
                description: `${error}`,
            });
        },
    });

    return {
        roomBookingId,
        roomBookingItem,
        targetRoomBookingItems,
        currentBookingDates,
        selectedDates,
        setSelectedDates,
        splitBookingIsPending: isPending,
        splitBooking: mutate,
    };
};
