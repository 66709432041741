export const reportJa = {
    breadcrumbs: {
        home: 'ホーム',
        report: 'サマリー',
    },
    occupancyHeatMap: '稼働率ヒートマップ',
    inflowRouteRatio: '当月の流入経路割合（チャネル）',
    topSections: {
        totalAmount: '売上',
        adr: 'ADR',
        averageOccupancyRoom: '平均秽備率',
        bookingCount: '予约数',
    },
    currency: '¥',
    unit: '件',
    percent: '%',
    changeCurrentMonth: '当月の推移',
    diagram: {
        notations: {
            sale: '売り上げ',
            lastSale: '前年売り上げ',
            more: 'ADR',
            lastMore: '前年ADR',
        },
        sale: '売上',
        lastSale: '前年売上',
        more: 'ADR',
        lastMore: '前年ADR',
        leftCol: 'ADR（円）',
        rightCol: '売上（万円）',
        dayRangeOptions: {
            days30: '30日',
            days90: '90日',
            days180: '180日',
        },
    },
    pieChart: {
        marketingChannel: {
            label: '予約件数',
            empty: '0伶',
        },
    },
    exportCSV: 'CSVエクスポート',
    placeholder: {
        rangeDatePicker: {
            start: '開始日',
            end: '終了日',
        },
        rangeMonthPicker: {
            start: '開始月',
            end: '終了月',
        },
        selectDate: '日付を選択',
        selectMonth: '月を選択',
    },
};
