export const facilityUpdateEn = {
    title: 'Edit facilities',
    form: {
        id: 'ID',
        name: {
            label: 'Facility name',
            placeholder: 'Facility name',
        },
        facilityType: {
            label: 'Facility type',
            placeholder: 'Facility type',
        },
        basePriceTaxIncluded: {
            label: 'Base price tax (￥)',
            placeholder: 'Base price tax (￥)',
        },
        confirm: {
            buttonCancelText: 'No',
            buttonDeleteText: 'Yes',
            cancelTitle: 'Do you want to cancel?',
        },
    },
    updateSuccessMessage: 'Facilities have been updated succcessful!',
};
