import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import _ from 'lodash';
import { AppState } from '~plugins/redux-toolkit/store';
import { IOperationStatusGetListQuery, IOperationStatusState } from '../interfaces';
import { reportService } from '../report.service';

const initialState: IOperationStatusState = {
    operationStatusGetListQuery: {
        datePeriod: [
            todayDayjs.startOf('month').startOf('day')?.fmYYYYMMDDHHmmss('-'),
            todayDayjs
                .add(1, 'month')
                .startOf('month')
                .startOf('day')
                .subtract(1, 'second')
                .fmYYYYMMDDHHmmss('-'),
        ],
    },
    operationStatusList: [],
    showLoading: false,
    isShowTable: false,
};

export const getOperationStatusList = createAsyncThunk(
    'operationStatus/getOperationStatusList',
    async (query: IOperationStatusGetListQuery) => {
        return await reportService.getOperationStatusData({
            ...query,
            datePeriod: [
                parseDate(query.datePeriod[0])?.fmYYYYMMDDHHmmss(),
                parseDate(query.datePeriod[1])?.fmYYYYMMDDHHmmss(),
            ],
        });
    },
);

const operationStatusSlice = createSlice({
    name: 'operationStatus',
    initialState,
    reducers: {
        setIsShowTable: (state, action) => {
            state.isShowTable = action.payload;
        },
        setOperationStatusGetListQuery: (state, action) => {
            state.operationStatusGetListQuery = action.payload;
        },
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOperationStatusList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(getOperationStatusList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.operationStatusList = _.sortBy(action.payload?.data || [], 'date');
        });
    },
});


export const { setIsShowTable, setOperationStatusGetListQuery, resetState } =
    operationStatusSlice.actions;

export const operationStatusStateSelector = (state: AppState) => {
    return state.reportOperationStatus;
};

export default operationStatusSlice.reducer;
