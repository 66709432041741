import { MIN_PRICE } from '~common/constants';
import { MAX_LINKAGE_CODE_DAY_USE } from './../features/marketing-channel/constants';
import { IndicatorSize, IndicatorType } from '~features/indicator/constants';
import { PlanType } from '~features/plan/constants';
import { PlanRankStatus } from './../features/plan/constants';
import { PriceType } from '~features/children-type/constants';
import { Gender } from '~features/guest/constants';
import { MAX_LINKAGE_CODE } from '~features/marketing-channel/constants';
import { CleaningStatus } from '~features/room-booking/constants';
import { MAX_STANDARD_CAPACITY } from '~features/room-type/constants';
import yup from '~plugins/yup';
import {
    HEX_COLOR_LENGTH,
    INPUT_TEXT_MAX_LENGTH,
    MAX_INTEGER,
    MAX_PRICE,
    MEMO_MAX_LENGTH,
    MIN_ID,
    Regex,
} from './constants';

export const NameSchema = yup.string().trim().max(INPUT_TEXT_MAX_LENGTH);
export const IdSchema = yup.number().min(MIN_ID).max(MAX_INTEGER);
export const AutoGeneratedCodeSchema = NameSchema;
export const AbbreviationSchema = NameSchema;
export const ColorSchema = yup
    .string()
    .matches(Regex.HEX_COLOR)
    .trim()
    .max(HEX_COLOR_LENGTH);
export const PhoneNumberSchema = yup.string().trim().isPhone();
export const EmailAddressSchema = yup
    .string()
    .isEmail()
    .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
    .trim()
    .max(INPUT_TEXT_MAX_LENGTH);
export const GenderNullableSchema = yup.string().oneOf([null, ...Object.values(Gender)]);
export const MemoSchema = yup
    .string()
    .trim()
    .max(MEMO_MAX_LENGTH)
    .hasNoSpecialCharacters(Regex.MEMO_SPECIAL_CHARACTERS);
export const PriceSchema = yup.number().minPrice(MIN_PRICE).max(MAX_PRICE);

export const roomSchema = yup.object().shape({
    name: NameSchema.required(),
    roomTypeId: IdSchema.required(),
    autoGeneratedCode: AbbreviationSchema.required(),
    cleaningStatus: yup.mixed<CleaningStatus>().oneOf(Object.values(CleaningStatus)),
});

export const roomTypeSchema = yup.object().shape({
    name: NameSchema.required(),
    abbreviation: AbbreviationSchema.required(),
    standardCapacity: yup.number().min(1).max(MAX_STANDARD_CAPACITY).required(),
    isConnectingRoomType: yup.bool().required(),
    autoGeneratedCode: AutoGeneratedCodeSchema,
    roomTypeCode: yup
        .string()
        .hasNoSpecialCharacters()
        .max(INPUT_TEXT_MAX_LENGTH)
        .required(),
});

export const saleGroupSchema = yup.object().shape({
    name: NameSchema.required(),
});

export const guestClassifcationSchema = yup.object().shape({
    name: NameSchema.hasNoSpecialCharacters().required(),
    color: ColorSchema.required(),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
});

export const guestSchema = yup.object().shape({
    yomigana: NameSchema.required(),
    mobilePhoneNumber: PhoneNumberSchema.nullable(),
    emailAddress: EmailAddressSchema.nullable(),
    gender: GenderNullableSchema.nullable(),
    birthday: yup.date().nullable(),
    memo: yup.string().trim().max(MEMO_MAX_LENGTH).nullable(),
    companyName: NameSchema.nullable(),
    companyYomigana: NameSchema.nullable(),
    companyPhoneNumber: PhoneNumberSchema.nullable(),
    companyEmailAddress: EmailAddressSchema.nullable(),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
});

export const saleItemSchema = yup.object().shape({
    name: NameSchema.hasNoSpecialCharacters().required(),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
    price: PriceSchema.required(),
    taxId: IdSchema.required(),
    isShowInBooking: yup.bool().required(),
});

export const paymentMethodSchema = yup.object().shape({
    isSeedData: yup.bool(),
    name: yup.mixed().when('isSeedData', {
        is: false,
        then: NameSchema.hasNoSpecialCharacters().required(),
        otherwise: NameSchema.required(),
    }),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
});

export const childrenTypeSchema = yup.object().shape({
    isSeedData: yup.bool(),
    name: yup.mixed().when('isSeedData', {
        is: false,
        then: NameSchema.hasNoSpecialCharacters().required(),
        otherwise: NameSchema.required(),
    }),
    linkageCode: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(MAX_LINKAGE_CODE)
        .nullable()
        .required(),
    priceType: yup.mixed<PriceType>().oneOf(Object.values(PriceType)),
    priceValue: PriceSchema.required(),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
});

export const rankCalendarSchema = yup.object().shape({
    isSeedData: yup.bool(),
    name: yup.mixed().when('isSeedData', {
        is: false,
        then: NameSchema.hasNoSpecialCharacters().required(),
        otherwise: NameSchema.required(),
    }),
    status: yup.mixed<PlanRankStatus>().oneOf(Object.values(PlanRankStatus)),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
});

export const planSchema = yup.object().shape({
    name: NameSchema.hasNoSpecialCharacters().required(),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
    planType: yup.mixed<PlanType>().oneOf(Object.values(PlanType)),
    checkInTime: yup.string().nullable(),
    checkOutTime: yup.string().nullable(),
});

export const indicatorSchema = yup.object().shape({
    name: NameSchema.required(),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
    indicatorType: yup
        .mixed<IndicatorType>()
        .oneOf(Object.values(IndicatorType))
        .required(),
    layout: yup.mixed<IndicatorSize>().oneOf(Object.values(IndicatorSize)).required(),
});

export const marketingChannelSchema = yup.object().shape({
    isSeedData: yup.bool(),
    name: yup.mixed().when('isSeedData', {
        is: false,
        then: NameSchema.hasNoSpecialCharacters(
            Regex.MARKETING_CHANNEL_NAME_SPECIAL_CHARACTERS,
        ).required(),
        otherwise: NameSchema.required(),
    }),
    marketingChannelGroupId: IdSchema.required(),
    linkageCode: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(MAX_LINKAGE_CODE)
        .nullable()
        .required(),
    linkageCodeDayUse: yup
        .string()
        .trim()
        .hasNoSpecialCharacters()
        .max(MAX_LINKAGE_CODE_DAY_USE)
        .nullable()
        .optional(),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
});

export const marketingChannelGroupSchema = yup.object().shape({
    isSeedData: yup.bool(),
    name: yup.mixed().when('isSeedData', {
        is: false,
        then: NameSchema.hasNoSpecialCharacters().required(),
        otherwise: NameSchema.required(),
    }),
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
});

export const roomBookingSchema = yup.object().shape({
    autoGeneratedCode: AutoGeneratedCodeSchema.required(),
    representativeGuest: yup.object().shape({
        id: IdSchema.required(),
        yomigana: NameSchema.required(),
    }),
    createdAt: yup.string().required(),
    marketingChannel: yup.object().shape({
        id: IdSchema.required(),
        name: NameSchema.required(),
    }),
});

export const roomBookingItemSchema = yup.object().shape({
    createdAt: yup.string().required(),
    marketingChannel: yup.object().shape({
        id: IdSchema.required(),
        name: NameSchema.required(),
    }),
    plan: yup.object().shape({
        id: IdSchema.required(),
        name: NameSchema.required(),
    }),
    bookingStatus: yup.string().required(),
    endDateOfStay: yup.string().required(),
    startDateOfStay: yup.string().required(),
    price: yup.number().max(MAX_PRICE).required(),
});
