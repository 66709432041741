import { BellOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, notification, Radio } from 'antd';
import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import localStorageAuthService from '~common/authStorage';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_DROPDOWN,
    DRAWER_WIDTH_DEFAULT,
    ReadNotificationType,
} from '~common/constants';
import { NoData } from '~components';
import {
    ActionNotification,
    BookingModuleNotification,
} from '~features/admin-notification/constants';
import {
    IBookingNotification,
    INotification,
    INotificationGetListQuery,
} from '~features/admin-notification/interfaces';
import {
    getAdminNotificationList,
    getNotificationList,
    getReadNotificationTime,
    notificationStateSelector,
    setChangeStatusBookingNotificationList,
    setAdminNotificationList,
    updateReadNotificationTime,
    setTemporaryBookingNotificationList,
} from '~features/admin-notification/reducers/notification.reducer';
import { FacilityBookingStatus } from '~features/facility-booking/constants';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import { useAppDispatch, useAppSelector } from '~hooks';
import { SocketEvents } from '~plugins/socket/socket';
import { useSocket } from '~plugins/socket/socketContext';
import AdminNotificationItem from '../AdminNotificationItem/AdminNotificationItem';
import BookingNotificationItem from '../BookingNotificationItem/BookingNotificationItem';
import './Notification.scss';
import { getOriginalBookingId } from '~features/room-booking/reducers/room-booking.reducer';

export function NotificationLayout() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const socket = useSocket();

    const {
        changeStatusBooingNotificationList,
        adminNotificationList,
        adminNotifyReadTimeAt,
        changeStatusBookingNotifyReadTimeAt,
    } = useAppSelector(notificationStateSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [currentNotifications, setCurrentNotifications] = useState<INotification[]>(
        changeStatusBooingNotificationList,
    );
    const [notificationType, setNotificationType] = useState<ReadNotificationType>(
        ReadNotificationType.ROOM_BOOKING,
    );

    const fetchAdminNotificationList = (query: INotificationGetListQuery) => {
        dispatch(getAdminNotificationList(query));
    };

    const fetchNotificationList = () => {
        dispatch(getNotificationList());
    };

    const updateReadNotifyTime = (type?: string) => {
        dispatch(updateReadNotificationTime({ type: type || notificationType }));
    };

    const getReadNotifyTime = () => {
        dispatch(getReadNotificationTime());
    };

    useEffect(() => {
        fetchAdminNotificationList({
            page: DEFAULT_FIRST_PAGE,
            limit: DEFAULT_LIMIT_FOR_DROPDOWN,
            isCreatedByPms: true,
        });
        fetchNotificationList();
        getReadNotifyTime();
        return () => {
            dispatch(setTemporaryBookingNotificationList([]));
        };
    }, []);

    const handleScroll = (event: any) => {
        const target = event.target;
        if (target.scrollHeight - target.scrollTop - target.clientHeight <= 1) {
            if (notificationType === ReadNotificationType.ROOM_BOOKING) {
                if (
                    currentNotifications.length <
                    changeStatusBooingNotificationList?.length
                ) {
                    setCurrentNotifications(
                        currentNotifications.concat(
                            changeStatusBooingNotificationList.slice(
                                currentNotifications.length,
                                currentNotifications.length + 10,
                            ),
                        ),
                    );
                }
            } else {
                if (currentNotifications.length < adminNotificationList?.length) {
                    setCurrentNotifications(
                        currentNotifications.concat(
                            adminNotificationList.slice(
                                currentNotifications.length,
                                currentNotifications.length + 10,
                            ),
                        ),
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (!socket) return;
        socket.on(SocketEvents.WEB_APP_SEND_NOTIFICATION, async (data) => {
            dispatch(setAdminNotificationList(data.items || []));
        });
        socket.on(SocketEvents.WEB_APP_SEND_CHANGE_STATUS_ROOM_BOOKING, async (data) => {
            const userId = localStorageAuthService.getUser()?.cognitoId;
            const userData = data.items?.find((item: any) => {
                return Object.keys(item)?.[0] === userId;
            });

            if (userId && userData?.[userId]) {
                dispatch(setChangeStatusBookingNotificationList(userData[userId]));
            }
        });
        socket.on(SocketEvents.WEB_APP_SEND_BOOKING_NOTIFICATION, (data) => {
            if (data.module === BookingModuleNotification.FACILITY_BOOKING) {
                if (data.action === ActionNotification.CREATE) {
                    notification.success({
                        message: t('notification.message.createFaclityBookingSuccess'),
                        onClick: () => {
                            navigate(`/facility-booking/${data.targetObjectId}/detail`);
                        },
                    });
                    return;
                } else if (data.action === ActionNotification.UPDATE) {
                    notification.success({
                        message: t('notification.message.updateFacilityBooking'),
                        onClick: () => {
                            navigate(`/facility-booking/${data.targetObjectId}/detail`);
                        },
                    });
                    return;
                }
            } else if (data.module === BookingModuleNotification.ROOM_BOOKING) {
                if (data.action === ActionNotification.CREATE) {
                    notification.success({
                        message: t('notification.message.createRoomBookingItemSucces'),
                        onClick: () => {
                            navigate(`/room-booking/schedule`);
                        },
                    });
                } else if (data.action === ActionNotification.UPDATE) {
                    notification.success({
                        message: t('notification.message.updateRoomBookingStatus'),
                        onClick: () => {
                            navigate(`/room-booking/schedule`);
                        },
                    });
                }
            }
        });
        return () => {
            socket?.off(SocketEvents.WEB_APP_SEND_NOTIFICATION);
            socket?.off(SocketEvents.WEB_APP_SEND_BOOKING_NOTIFICATION);
            socket?.off(SocketEvents.WEB_APP_SEND_CHANGE_STATUS_ROOM_BOOKING);
        };
    }, [socket]);

    const totalUnreadNotifications = useMemo(() => {
        const numberOfUnreadAdmin = adminNotificationList.reduce((total, item) => {
            if (!adminNotifyReadTimeAt) return (total += 1);
            return (total += parseDate(item.updatedAt).isAfter(adminNotifyReadTimeAt)
                ? 1
                : 0);
        }, 0);

        const numberOfUnreadChangeStatusBooking =
            changeStatusBooingNotificationList.reduce((total, item) => {
                if (!changeStatusBookingNotifyReadTimeAt) return (total += 1);
                return (total += parseDate(item.updatedAt).isAfter(
                    changeStatusBookingNotifyReadTimeAt,
                )
                    ? 1
                    : 0);
            }, 0);

        return numberOfUnreadAdmin + numberOfUnreadChangeStatusBooking;
    }, [
        changeStatusBooingNotificationList,
        adminNotificationList,
        adminNotifyReadTimeAt,
        changeStatusBookingNotifyReadTimeAt,
    ]);

    useEffect(() => {
        if (
            notificationType === ReadNotificationType.ROOM_BOOKING &&
            changeStatusBooingNotificationList.length
        ) {
            if (currentNotifications.length) {
                setCurrentNotifications(
                    changeStatusBooingNotificationList.slice(
                        0,
                        currentNotifications.length + 1,
                    ),
                );
            } else {
                setCurrentNotifications(changeStatusBooingNotificationList.slice(0, 10));
            }
        }
    }, [changeStatusBooingNotificationList]);

    useEffect(() => {
        if (
            notificationType === ReadNotificationType.ADMIN_NOTIFICATIONS &&
            adminNotificationList.length
        ) {
            setCurrentNotifications(
                adminNotificationList.slice(0, currentNotifications.length + 1),
            );
        }
    }, [adminNotificationList]);

    useEffect(() => {
        if (notificationType === ReadNotificationType.ROOM_BOOKING) {
            setCurrentNotifications(changeStatusBooingNotificationList.slice(0, 10));
        } else {
            setCurrentNotifications(adminNotificationList.slice(0, 10));
        }
    }, [notificationType]);

    const isUnread = (updatedAt?: string) => {
        if (!updatedAt) return true;
        if (notificationType === ReadNotificationType.ROOM_BOOKING) {
            return changeStatusBookingNotifyReadTimeAt
                ? parseDate(updatedAt).isAfter(
                      parseDate(changeStatusBookingNotifyReadTimeAt),
                  )
                : true;
        } else {
            return adminNotifyReadTimeAt
                ? parseDate(updatedAt).isAfter(parseDate(adminNotifyReadTimeAt))
                : true;
        }
    };

    const changeOpenNotificationDropDown = (isOpen: boolean) => {
        if (!isOpen) {
            updateReadNotifyTime();
            setCurrentNotifications([]);
        }
        setIsOpen(isOpen);
        if (isOpen) {
            if (notificationType === ReadNotificationType.ROOM_BOOKING) {
                setCurrentNotifications(changeStatusBooingNotificationList.slice(0, 10));
            } else {
                setCurrentNotifications(adminNotificationList.slice(0, 10));
            }
        }
    };

    const changeNotificationType = (type: string) => {
        setNotificationType(type as ReadNotificationType);

        updateReadNotifyTime(
            type === ReadNotificationType.ROOM_BOOKING
                ? ReadNotificationType.ADMIN_NOTIFICATIONS
                : ReadNotificationType.ROOM_BOOKING,
        );
    };
    const navigateRoomBookingDetail = async (id: number) => {
        const response = await dispatch(getOriginalBookingId(id));
        setIsOpen(false);
        if (getOriginalBookingId.fulfilled.match(response)) {
            const roomBookingId = response?.payload?.data?.id || id;
            if (!roomBookingId) {
                navigate('/404');
            } else {
                navigate(`/room-booking/${+roomBookingId}/detail`);
            }
        }
    };
    const onOpenBookingDetail = (item?: INotification | IBookingNotification) => {
        if (item?.rawData?.roomBookingId) {
            navigateRoomBookingDetail(+item?.rawData?.roomBookingId);
        } else if (item?.rawData?.facilityBookingId) {
            navigate(`/facility-booking/${item?.rawData?.facilityBookingId}/detail`);
            setIsOpen(false);
        }
    };

    const getBookingNotificationTitle = (notification: INotification) => {
        let title =
            !notification.rawData?.bookingStatus ||
            notification.rawData?.bookingStatus ===
                RoomBookingItemBookingStatus.NOT_ARRIVED
                ? t('layout.staff.confirmedBookingTitle')
                : t('layout.staff.updatedRoomBookingTitle');
        if (notification.type === ReadNotificationType.FACILITY_BOOKING_CHANGE_STATUS) {
            title =
                !notification.rawData?.bookingStatus ||
                notification.rawData?.bookingStatus === FacilityBookingStatus.RESERVED
                    ? t('layout.staff.confirmedFacilityBookingTitle')
                    : t('layout.staff.updatedFacilityBookingTitle');
        }
        return title;
    };
    const clickAdminNotificationItem = (isClosingBooking: boolean) => {
        if (isClosingBooking) {
            navigate('/closing-booking');
        }
    };
    return (
        <Dropdown
            trigger={['click']}
            placement="bottom"
            open={isOpen}
            onOpenChange={changeOpenNotificationDropDown}
            overlayClassName="notification-bell-dropdown"
            dropdownRender={(menu) => (
                <div className="notification-bell-dropdown-content">
                    <div className="notification-bell-drawer-title">
                        <div>
                            {notificationType === ReadNotificationType.ROOM_BOOKING
                                ? t('layout.staff.notification')
                                : t('layout.staff.adminNotification')}
                        </div>
                        <div>
                            <Radio.Group
                                defaultValue={ReadNotificationType.ROOM_BOOKING}
                                onChange={(e) => changeNotificationType(e.target.value)}
                            >
                                <Radio.Button value={ReadNotificationType.ROOM_BOOKING}>
                                    {t('layout.staff.button.tabist')}
                                </Radio.Button>
                                <Radio.Button
                                    value={ReadNotificationType.ADMIN_NOTIFICATIONS}
                                >
                                    {t('layout.staff.button.tllNotification')}
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    <div
                        className="notification-bell-content-container"
                        onScroll={handleScroll}
                    >
                        {currentNotifications?.length !== 0 &&
                            currentNotifications.map((notification) => {
                                return notification.rawData ? (
                                    <BookingNotificationItem
                                        key={notification.id}
                                        title={t(
                                            `layout.staff.notificationTitle.${notification.type}`,
                                        )}
                                        notificationItem={notification}
                                        type={ReadNotificationType.ROOM_BOOKING}
                                        isShowTime={true}
                                        isUnread={isUnread(notification.updatedAt)}
                                        onOpenBooking={onOpenBookingDetail}
                                    />
                                ) : (
                                    <AdminNotificationItem
                                        key={notification.id}
                                        notificationItem={notification}
                                        isUnread={isUnread(notification.updatedAt)}
                                        onClickNotificationItem={
                                            clickAdminNotificationItem
                                        }
                                    />
                                );
                            })}
                        {currentNotifications?.length === 0 && <NoData />}
                    </div>
                </div>
            )}
        >
            <Button
                type="link"
                className={
                    isOpen
                        ? 'notification-bell-dropdown-title-active'
                        : 'notification-bell-dropdown-title'
                }
            >
                <Badge
                    count={totalUnreadNotifications}
                    offset={[2.5, 8]}
                    className="number-of-unread-notification"
                >
                    <BellOutlined className="notification-bell" />
                </Badge>
            </Button>
        </Dropdown>
    );
}

NotificationLayout.defaultProps = {
    busy: false,
    loading: false,
    width: DRAWER_WIDTH_DEFAULT,
};
