import { Button } from 'antd';
import { InputSearch } from '~components';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AdminGroup, INPUT_TEXT_MAX_LENGTH, GA_KEYWORD_CONTENT } from '~common/constants';
import './FilterForm.scss';
import { useAppDispatch } from '~hooks';
import { setShowForm } from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { setKeyword } from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { trackSearchKeyword } from '~plugins/analytics';
import { checkUserPermission } from '~common/commonFunctions';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([...AdminGroup]);

    const createRankCalendar = () => {
        dispatch(setShowForm(true));
    };
    const onSearch = (keyword: string) => {
        dispatch(setKeyword(keyword));
        trackSearchKeyword(keyword, GA_KEYWORD_CONTENT.RANK_CALENDAR);
    };

    return (
        <div className="filter-rank-calendar-list-wrapper">
            <div>
                <InputSearch
                    onSearch={onSearch}
                    placeholder={t('common.searchPlaceholder')}
                    max={INPUT_TEXT_MAX_LENGTH}
                    maxLength={INPUT_TEXT_MAX_LENGTH}
                />
            </div>
            {!isReadOnly && (
                <div>
                    <Button
                        type="primary"
                        id="btn-add-rank-calendar"
                        onClick={createRankCalendar}
                    >
                        <PlusOutlined />
                        <span className="btn-text">{t('rankCalendar.list.addBtn')}</span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default FilterForm;
