import { kioskAlmexIntegrationEn } from './en/integrations';
import { kioskAlmexIntegrationJa } from './ja/integrations';

export const kioskAlmexIntegrations = {
    ja: {
        almex: kioskAlmexIntegrationJa,
    },
    en: {
        almex: kioskAlmexIntegrationEn,
    },
};
