import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import localStorageAuthService from '~common/authStorage';
import { IAuthContext, ILoginUser } from '~common/interfaces';
import isEmpty from 'lodash/isEmpty';
import { UserGroup } from '~common/constants';
import { checkUserPermission } from '~common/commonFunctions';
import { getSession, logoutSession } from '~plugins/amplify';

let AuthContext = React.createContext<IAuthContext>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = React.useState<ILoginUser | null>(null);

    useEffect(() => {
        const loginUser = localStorageAuthService.getUser();
        if (loginUser) {
            setUser(loginUser);
        } else {
            setUser(null);
        }
    }, []);

    const login = (user: ILoginUser, callback?: VoidFunction) => {
        setUser(user);
        callback?.();
    };

    const logout = (callback?: VoidFunction) => {
        localStorageAuthService.setUser(null);
        setUser(null);
        callback?.();
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return React.useContext(AuthContext);
}
async function checkSession() {
    const session = await getSession();
    const accessToken = session?.getAccessToken()?.getJwtToken();
    const idToken = session?.getIdToken()?.getJwtToken();
    const refreshToken = session?.getRefreshToken()?.getToken();
    return !session || !accessToken || !idToken || !refreshToken;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
    const user = localStorageAuthService.getUser();
    const location = useLocation();
    const navigate = useNavigate();
    checkSession().then((isError) => {
        if (isError) {
            localStorageAuthService.resetAll();
            logoutSession();
            navigate('/login', { state: { from: location }, replace: true });
        }
    });

    if (!user || isEmpty(user)) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
}

export function RequirePermission({
    children,
    permissions,
}: {
    children: JSX.Element;
    permissions: UserGroup[];
}) {
    const location = useLocation();
    if (!checkUserPermission(permissions)) {
        return <Navigate to="/forbidden" state={{ from: location }} replace />;
    }

    return children;
}

export function RequireHotel({ children }: { children: JSX.Element }) {
    const location = useLocation();
    const hotelId = localStorageAuthService.getHotelId();
    if (!hotelId) {
        return <Navigate to="/forbidden" state={{ from: location }} replace />;
    }

    return children;
}

export function RedirectHomeIfLoggedIn({ children }: { children: JSX.Element }) {
    const user = localStorageAuthService.getUser();
    if (user && !isEmpty(user)) {
        return <Navigate to="/dashboard" replace />;
    }

    return children;
}
