import customDayjs, { parseDate } from '~plugins/dayjs';
import React, { useEffect, useState } from 'react';
import { Card, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
    getAdminNotificationList,
    tllNotificationListByHotelIdSelector,
} from '~features/admin-notification/reducers/notification.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useTranslation } from 'react-i18next';
import './NotificationCard.scss';
import { NoData } from '~components';
import { INotification } from '~features/admin-notification/interfaces';
import { AdminNotificationTag } from '~features/admin-notification/constants';

export const NotificationCard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const adminNotificationList = useAppSelector(tllNotificationListByHotelIdSelector);

    const [notificationList, setNotificationList] = useState<INotification[]>([]);

    useEffect(() => {
        setNotificationList(adminNotificationList.slice(0, 10));
    }, [adminNotificationList]);

    useEffect(() => {
        dispatch(
            getAdminNotificationList({
                isCreatedByPms: true,
            }),
        );
    }, []);

    const renderNotificationContent = (notificationItem: INotification) => {
        const isClosingBooking = notificationItem.tags?.some((notificationTag) => {
            return notificationTag.tag === AdminNotificationTag.CLOSING_BOOKING;
        });

        return isClosingBooking ? (
            <>{t('dashboard.notificationCard.closingBooking')}</>
        ) : (
            <>
                {t('dashboard.notificationCard.contentCard', {
                    title: notificationItem.title,
                    content: notificationItem.content,
                })}
            </>
        );
    };

    return (
        <div className="dashboard-notification-card-wrapper">
            <Card
                className="notification-card"
                title={
                    <div className="notification-card-title-wrapper">
                        <div>{t('dashboard.notificationCard.title')}</div>
                    </div>
                }
            >
                <div className="notification-card-content-container">
                    {notificationList?.length !== 0 &&
                        notificationList.map((notification, index) => {
                            const isNewNotification = customDayjs().isSameOrBefore(
                                parseDate(notification.updatedAt).add(1, 'day'),
                            );

                            return (
                                <div
                                    className="notification-item"
                                    key={notification.autoGeneratedCode}
                                >
                                    <div className="avatar">
                                        <Avatar size={32} icon={<UserOutlined />} />
                                    </div>
                                    <div className="content">
                                        <div className="notification-item-header">
                                            <div className="notification-item-date">
                                                {t('layout.staff.notificationDate', {
                                                    month:
                                                        parseDate(
                                                            notification.createdAt,
                                                        ).month() + 1,
                                                    date: parseDate(
                                                        notification.createdAt,
                                                    ).date(),
                                                })}
                                            </div>
                                            <div className="notification-item-createdAt">
                                                {parseDate(
                                                    notification.createdAt,
                                                )?.fmHHmm()}
                                            </div>
                                            {isNewNotification && (
                                                <div className="new-notification">
                                                    {t('dashboard.notificationCard.new')}
                                                </div>
                                            )}
                                        </div>
                                        <div className="notification-item-title">
                                            {renderNotificationContent(notification)}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    {notificationList?.length === 0 && <NoData />}
                </div>
            </Card>
        </div>
    );
};
