import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '~plugins/redux-toolkit/store';
import { ICurrentGuestQuery, IReportCurrentGuestState } from '../interfaces';
import { reportService } from '../report.service';
import customDayjs, { parseDate } from '~plugins/dayjs';

const initialState: IReportCurrentGuestState = {
    currentGuestList: [],
    showLoading: false,
    isShowTable: false,
    selectedDate: customDayjs().fmYYYYMMDD(),
    currentGuestQuery: {
        datePeriod: [
            customDayjs().startOf('day').fmYYYYMMDDHHmmss(),
            customDayjs().endOf('day').fmYYYYMMDDHHmmss(),
        ],
    },
};

export const getCurrentGuestList = createAsyncThunk(
    'unpaidDetail/getCurrentGuestList',
    async (query: ICurrentGuestQuery) => {
        return await reportService.getCurrentGuest(query);
    },
);

const currentGuestSlice = createSlice({
    name: 'current-guest',
    initialState,
    reducers: {
        setCurrentGuestList: (state, action) => {
            state.currentGuestList = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setCurrentGuestQuery: (state, action) => {
            state.currentGuestQuery = action.payload;
        },
        setShowCurrentGuestTable: (state, action) => {
            state.isShowTable = action.payload;
        },
        resetCurrentGuestState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentGuestList.pending, (state, action) => {
            state.showLoading = true;
        });
        builder.addCase(getCurrentGuestList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.currentGuestList = action.payload?.data;
        });
    },
});

export const {
    setCurrentGuestList,
    setSelectedDate,
    setCurrentGuestQuery,
    setShowCurrentGuestTable,
    resetCurrentGuestState,
} = currentGuestSlice.actions;

export const currentGuestStateSelector = (state: AppState) => {
    return state.reportCurrentGuest;
};

export const isLoadingCurrentGuestSelector = (state: AppState) => {
    return state.reportCurrentGuest.showLoading;
};

export const isShowCurrentGuestTableSelector = (state: AppState) => {
    return state.reportCurrentGuest.isShowTable;
};

export const currentGuestListSelector = (state: AppState) => {
    const { currentGuestList, selectedDate } = state.reportCurrentGuest;
    return currentGuestList.filter((item) => {
        const endDate = parseDate(item.endDateOfStay).fmYYYYMMDD();
        const startDate = parseDate(item.startDateOfStay).fmYYYYMMDD();
        const date = parseDate(selectedDate).fmYYYYMMDD();
        const isSameCheckOutDate = endDate !== date;

        if (item.isDayUse && endDate === startDate && date === startDate) {
            return true;
        }

        return isSameCheckOutDate;
    });
};

export const selectedDateSelector = (state: AppState) => {
    return state.reportCurrentGuest.selectedDate;
};

export const currentGuestQuerySelector = (state: AppState) => {
    return state.reportCurrentGuest.currentGuestQuery;
};

export default currentGuestSlice.reducer;
