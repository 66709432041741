import { Spin } from 'antd';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import './StopSellingGrid.scss';
import { useAppDispatch, useAppSelector } from '~hooks';
import { AutoSizer, GridView, ICell } from '~components';
import { GridOnItemsRenderedProps, VariableSizeGrid } from 'react-window';
import { CellHeader } from './Header';
import { RowHeader } from './RowHeader';
import { useHandleScroll } from '~common/useHooks';
import { IDirection, IScrollStatus } from '~common/interfaces';
import { CellItem } from './CellItem';
import {
    getStoppingInventoryData,
    headerColumnsSelector,
    inventoryStopSellingGridDataSelector,
    inventoryStopSellingStateSelector,
} from '~features/inventory/reducers/inventory-stop-selling.reducer';
import {
    CELL_HEIGHT,
    HEADER_HEIGHT,
    HEADER_TOP_PADDING,
    MAX_HEIGHT_GRID_VIEW,
    MIN_HEIGHT_GRID_VIEW,
    MIN_WIDTH_CELL,
    ROW_HEADER_WIDTH,
} from '~features/inventory/constants';
import { TimeControl } from './TimeControl';
import { SubHeader } from './SubHeader';
import { parseDate } from '~plugins/dayjs';
import { IScrollGridPosition } from '~features/inventory/interfaces';

type Props = {
    height: number;
};
export const StopSellingGrid = (props: Props) => {
    const { height } = props;
    const dispatch = useAppDispatch();

    const outerRef = useRef<HTMLElement>();
    const gridRef = useRef<VariableSizeGrid>() as MutableRefObject<VariableSizeGrid>;
    const containerRef = useRef<HTMLDivElement>(null);
    const currentScrollGridData = useRef<IScrollGridPosition>({
        scrollLeft: 0,
        scrollTop: 0,
    });

    const { showLoading, currentDate, firstCheckbox, selectedDate } = useAppSelector(
        inventoryStopSellingStateSelector,
    );
    const roomList = useAppSelector(inventoryStopSellingGridDataSelector);
    const columns = useAppSelector(headerColumnsSelector);

    // handle scroll
    const onStopScroll = useCallback((direction: IDirection, status: IScrollStatus) => {},
    []);

    const { onMouseDown, onMouseUp, onMouseMove, onMouseLeave, elementRef, onWheel } =
        useHandleScroll({ onStopScroll });

    useEffect(() => {
        dispatch(getStoppingInventoryData());
    }, []);

    // end handle select day for create booking

    const renderHeader = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            return <CellHeader item={columns[columnIndex]} style={style} />;
        },
        [columns],
    );

    const renderSubHeader = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            return (
                <SubHeader
                    item={roomList[rowIndex]}
                    currentDate={columns[columnIndex].id}
                    style={style}
                />
            );
        },
        [columns],
    );

    const renderCell = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            return (
                <CellItem
                    item={roomList[rowIndex]}
                    currentDate={columns[columnIndex].id}
                    style={style}
                    firstCheckbox={firstCheckbox}
                ></CellItem>
            );
        },
        [columns, roomList, firstCheckbox],
    );

    const renderRowHeader = useCallback(
        ({ rowIndex, style }: ICell) => {
            return <RowHeader item={roomList[rowIndex]} style={style} />;
        },
        [roomList],
    );

    // handle load more item

    const onItemsRendered = useCallback(
        ({ visibleColumnStartIndex }: GridOnItemsRenderedProps) => {
            if (!elementRef.current) {
                elementRef.current = outerRef.current;
            }
        },
        [columns],
    );

    const scrollTable = (index: number) => {
        setTimeout(() => {
            gridRef.current?.scrollToItem({
                columnIndex: index,
                align: 'start',
            });
        }, 0);
    };

    useEffect(() => {
        const index = columns.findIndex((item) => item.id === selectedDate);
        scrollTable(index);
    }, [selectedDate, columns]);

    useEffect(() => {
        scrollTable(0);
        currentScrollGridData.current = {
            scrollLeft: 0,
            scrollTop: 0,
        };
    }, [showLoading]);

    const handleSelectStart = (e: Event) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (!containerRef.current) return;
        containerRef.current?.addEventListener('selectstart', handleSelectStart);
        return () => {
            containerRef.current?.removeEventListener('selectstart', handleSelectStart);
        };
    }, [containerRef]);

    useEffect(() => {
        setTimeout(() => {
            gridRef.current?.scrollTo({
                scrollLeft: currentScrollGridData.current?.scrollLeft || 0,
                scrollTop: currentScrollGridData.current?.scrollTop || 0,
            });
        }, 0);
    }, [roomList]);

    const onScrollGrid = (param: IScrollGridPosition) => {
        currentScrollGridData.current = param;
    };

    const heightGrid = useMemo(() => {
        const _height = height - HEADER_TOP_PADDING;
        if (_height > MAX_HEIGHT_GRID_VIEW) return MAX_HEIGHT_GRID_VIEW;
        if (_height < MIN_HEIGHT_GRID_VIEW) return MIN_HEIGHT_GRID_VIEW;
        return _height;
    }, [height]);

    return (
        <div
            className="inventory-stop-selling-table-wrapper"
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
            onWheel={onWheel}
            ref={containerRef}
        >
            <Spin spinning={showLoading} style={{ minHeight: height }}>
                <AutoSizer disableHeight>
                    {({ width }) => {
                        let cellWidth = columns.length
                            ? (width - ROW_HEADER_WIDTH) / columns.length
                            : MIN_WIDTH_CELL;
                        if (cellWidth < MIN_WIDTH_CELL) {
                            cellWidth = MIN_WIDTH_CELL;
                        }
                        return (
                            <GridView
                                style={{
                                    fontSize: 14,
                                }}
                                outerRef={outerRef}
                                gridRef={gridRef}
                                className={'schedule-table'}
                                height={heightGrid}
                                width={width}
                                columns={columns}
                                recordset={roomList}
                                rowHeaderWidth={ROW_HEADER_WIDTH}
                                columnHeaderWidth={cellWidth}
                                columnHeaderHeight={HEADER_HEIGHT}
                                columnSubHeaderHeight={CELL_HEIGHT}
                                columnHeaderRenderer={renderHeader}
                                rowHeaderRenderer={renderRowHeader}
                                cellRenderer={renderCell}
                                getRowContentHeight={() => CELL_HEIGHT}
                                bodyProps={{
                                    onItemsRendered,
                                }}
                                columnSubHeaderRenderer={renderSubHeader}
                                showSubHeader={true}
                                onScroll={onScrollGrid}
                            />
                        );
                    }}
                </AutoSizer>
                <TimeControl />
                <div className="year-title">{parseDate(currentDate)?.fmYYYYMMJp()}</div>
            </Spin>
        </div>
    );
};
