import {
    IFacilityBooking,
    IFacilityBookingReceiptItem,
} from './../facility-booking/interfaces';
import customDayjs, { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import _, { cloneDeep, sumBy } from 'lodash';
import { MINUTES_PER_DAY } from '~common/constants';
import { FacilityBookingStatus } from '~features/facility-booking/constants';
import {
    IColumnOption,
    IFacilityBookingReceiptDetail,
} from '~features/facility-booking/interfaces';
import { PricingType } from '~features/tax/constants';
import { ITax } from '~features/tax/interfaces';
import {
    ReceiptItemType,
    RoomBookingCategory,
    RoomBookingItemBookingStatus,
    CleaningStatus,
    ReceiptItemDetailType,
    BOOKING_HORIZONTAL_SCROLL_SPEED,
    CELL_HEADER_HEIGHT,
    CELL_HEIGHT,
    DEFAULT_CHECK_IN_TIME,
    DEFAULT_CHECK_OUT_TIME,
    ACCEPTED_BOOKING_FILE_FORMATS,
    MAX_NUMBER_OF_FILES_PER_BOOKING,
    MAX_SIZE_OF_EACH_BOOKING_FILE,
} from './constants';
import {
    IAccommodationInformation,
    IAdvanceSearchGetListQuery,
    IAgentNativeInformation,
    IAgentNativeInformationExtend,
    IAllotmentBookingReport,
    IBasicInformation,
    IBasicRateInformation,
    IBookingPlanItem,
    IBookingReceiptTableData,
    IBookingStaticTypeResponse,
    IChildBooking,
    ICreateBookingFormData,
    IGroupBookingItem,
    IImportXmlBody,
    IMemberAttachment,
    IPointsDiscountList,
    IPricingSetting,
    IReceipt,
    IRisaplsCommonInformation,
    IRisaplsCommonInformationBasic,
    IRisaplsCommonInformationBasicRate,
    IRisaplsCommonInformationMember,
    IRisaplsCommonInformationOption,
    IRisaplsCommonInformationOtherInfo,
    IRisaplsCommonInformationRoomInformation,
    IRisaplsInformation,
    IRoomAndGuestInformation,
    IRoomAndGuestList,
    IRoomAndRoomRateInformation,
    IRoomBooking,
    IRoomBookingItem,
    IRoomBookingItemReceipt,
    IRoomBookingItemsReceiptItem,
    IRoomBookingList,
    IRoomBookingSchedule,
    IRoomBookingStaticItem,
    IRoomBookingTable,
    IRoomInformation,
    IRoomRateInformation,
    IRoomTypeDropdown,
    ISalesOfficeInformation,
    IScheduleItem,
    IStatisticBookingItem,
    ITemporaryBookingItem,
    ITransactionType,
    TaxSummary,
} from './interfaces';
import { guestDefault } from './model';
import i18next from 'i18next';
import { IRoomType } from '~features/room-type/interfaces';
import { IUnassignedBookingItem } from '~features/room-management/interfaces';
import { IFile } from '~features/guest/interfaces';
import dayjs from 'dayjs';
import { IStoppingRoomInDurationItem } from '~features/inventory/interfaces';

export const getWeeksOfMonth = (year: number, month: number) => {
    const startOfMonth = parseDate(`${year}-${month}-1`).startOf('month').day();
    const endOfMonth = parseDate(`${year}-${month}-1`).endOf('month').date();
    const used = startOfMonth + endOfMonth;
    return Math.ceil(used / 7);
};

export const buildStatisticsByDate = (
    numberOfRoomBookingItems: Record<string, Record<string, IStatisticBookingItem>>,
    stoppingRoomList: IStoppingRoomInDurationItem[],
): Record<string, Record<string, IStatisticBookingItem | number>> => {
    const result: Record<string, Record<string, IStatisticBookingItem | number>> = {};
    _.forEach(numberOfRoomBookingItems, (value, date) => {
        result[date] = {
            ...value,
            totalBooked: _.sumBy(_.values(value), (item) => item.bookedRoomCount),
            totalUnassigned: _.sumBy(
                _.values(value),
                (item) =>
                    item.dayUseUnassignRoomBookingRoomCount +
                    item.overnightUnassignRoomBookingRoomCount,
            ),
            totalStoppedRoomCount: 0,
        };
    });
    const filteredStoppingRoomList: IStoppingRoomInDurationItem[] = [];
    const uniqueRoomTypeIdAndDate = new Set();

    for (const item of stoppingRoomList) {
        const combination = `${item.date}-${item.roomTypeId}`;
        if (!uniqueRoomTypeIdAndDate.has(combination)) {
            uniqueRoomTypeIdAndDate.add(combination);
            filteredStoppingRoomList.push(item);
        }
    }

    filteredStoppingRoomList.forEach((item) => {
        const date = customDayjs(item.date).format('YYYY-MM-DD');
        if (result[date]) {
            result[date].totalStoppedRoomCount =
                ((result[date].totalStoppedRoomCount as number) || 0) +
                item.stoppingRoomCount;
        }
    });
    return result;
};

export const parseScheduleResponse = (data: IBookingStaticTypeResponse) => {
    const { items = [] } = data || {};
    const roomList: IScheduleItem[] = [];
    const roomTypesDropdown: IRoomTypeDropdown[] = [];
    _.forEach(items, (item) => {
        const _rooms: { id: number; name: string }[] = [];
        roomList.push({
            id: item.id,
            name: item.name,
            numberOfRooms: item.numberOfRooms,
            parentId: null,
            parentName: item.name,
        });
        item.rooms?.forEach((room) => {
            _rooms.push({
                id: room.id,
                name: room.name,
            });
            roomList.push({
                id: room.id,
                name: room.name,
                numberOfRooms: 1,
                parentId: item.id,
                parentName: item.name,
            });
        });

        roomTypesDropdown.push({
            id: item.id,
            name: item.name,
            standardCapacity: item.standardCapacity,
            rooms: _rooms as { id: number; name: string }[],
        });
    });
    return { roomList, roomTypesDropdown };
};

export const checkDayValidInRage = (
    dayPlans: Record<string, number>,
    from: string,
    to: string,
) => {
    const start = parseDate(from);
    const end = parseDate(to);
    let inRangeValid = true;
    const range = end.diff(start, 'day');
    if (range >= 2) {
        for (let i = 1; i < range; i++) {
            const date = start.clone().add(i, 'day');
            if (dayPlans[date.fmYYYYMMDD('-')]) {
                inRangeValid = false;
                break;
            }
        }
    }
    if (!inRangeValid) {
        return false;
    }
    const startDateValid = !dayPlans[from] || dayPlans[from] === 0.5;
    const endDateValid = !dayPlans[to] || dayPlans[to] === 0.5;
    return startDateValid && endDateValid;
};

export const bookingPercent = (bookingCount: number, total: number): number => {
    const _total = total || 1;
    return Math.round((bookingCount / _total) * 100);
};

export const makeBookingSchedules = (
    temporaryBookingList: ITemporaryBookingItem[],
): IRoomBookingSchedule[] => {
    const bookingTmpList: IRoomBookingSchedule[] = temporaryBookingList.map((booking) => {
        return {
            id: booking.id,
            stayingStartDate: booking.startDateOfStay
                ? parseDate(booking.startDateOfStay)?.fmYYYYMMDD('-')
                : '',
            stayingEndDate: booking.endDateOfStay
                ? parseDate(booking.endDateOfStay)?.fmYYYYMMDD('-')
                : '',
            status: booking.bookingStatus as RoomBookingItemBookingStatus,
            room: booking.room || { id: null, name: '' },
            roomType: booking.roomType,
            guest: {
                ...guestDefault,
            },
            plan: {
                id: booking.plan?.id || null,
                name: booking.plan?.name || '',
            },
            checkInTime: booking.checkInTime,
            checkOutTime: booking.checkOutTime || null,
            numberOfAdults: booking.numberOfAdults,
            roomBookingId: booking.roomBookingId,
            isTmp: !booking.roomBookingId,
            children: (booking.childrenAmounts || [])?.map((child) => ({
                id: child.id || null,
                quantity: child.quantity,
                typeId: child.childrenTypeId as number,
            })),
            roomKey: `${booking.roomTypeId}-${booking.roomId}`,
            isDayUse: booking.isDayUse,
        };
    });
    return bookingTmpList;
};

export const getChildrenObject = (children: IChildBooking[]) => {
    const childrenObject: Record<string, number> = {};
    children?.forEach((child) => {
        childrenObject['id_' + child.typeId] = child.quantity;
    });
    return childrenObject;
};

export const convertBooking = (booking: IRoomBookingStaticItem): IRoomBookingSchedule => {
    const roomKey = `${booking.roomTypeId}-${booking.roomId}`;
    const _booking: IRoomBookingSchedule = {
        id: booking.id,
        stayingStartDate: booking.startDateOfStay
            ? parseDate(booking.startDateOfStay)?.fmYYYYMMDD('-')
            : '',
        stayingEndDate: booking.endDateOfStay
            ? parseDate(booking.endDateOfStay)?.fmYYYYMMDD('-')
            : '',
        status: booking.bookingStatus as RoomBookingItemBookingStatus,
        room: {
            id: booking.room?.id,
            name: booking.room?.name,
            cleaningStatus: booking.room?.cleaningStatus as CleaningStatus,
        },
        roomType: {
            id: booking.roomType?.id,
            name: booking.roomType?.name,
        },
        roomId: booking.roomId,
        roomTypeId: booking.roomTypeId,
        planId: booking.planId as number,
        isDayUse: booking.isDayUse,
        guest: {
            ...guestDefault,
            yomigana:
                booking?.representativeGuest?.yomigana ||
                booking?.roomBooking?.representativeGuest?.yomigana ||
                '',
            id:
                booking?.representativeGuest?.id ||
                booking?.roomBooking?.representativeGuest?.id ||
                null,
        },
        plan: {
            id: booking.planId,
            name: booking.plan?.name,
        },
        checkInTime: booking.checkInTime,
        checkOutTime: booking.checkOutTime || null,
        numberOfAdults: booking.numberOfAdults,
        numberOfMale: Number(booking.numberOfMale) || null,
        numberOfFemale: Number(booking.numberOfFemale) || null,
        numberOfOtherGenderGuest: Number(booking.numberOfOtherGenderGuest) || null,
        roomBookingId: booking.roomBookingId,
        isTmp: !booking.roomBookingId,
        children: (booking.roomBookingItemChildrenTypes || [])?.map((child) => ({
            id: child.id as number,
            quantity: child.quantity,
            typeId: child.childrenTypeId as number,
        })),
        roomKey,
        createdAt: booking.createdAt,
        price: booking.totalAmount || 0,
        individualUnpaidAmount: booking.individualUnpaidAmount || 0,
    };
    return _booking;
};

export const parseBookingList = (data: Record<string, IRoomBookingSchedule>) => {
    const bookingList: IRoomBookingList = {};
    _.forEach(data, (booking) => {
        if (booking.status !== RoomBookingItemBookingStatus.CANCELLED) {
            const stayingStartDate = booking.stayingStartDate
                ? parseDate(booking.stayingStartDate)?.fmYYYYMMDD('-')
                : 'noDate';
            if (bookingList[stayingStartDate]) {
                if (bookingList[stayingStartDate][booking.roomKey]) {
                    bookingList[stayingStartDate][booking.roomKey].push(booking);
                } else {
                    bookingList[stayingStartDate][booking.roomKey] = [booking];
                }
            } else {
                bookingList[stayingStartDate] = {
                    [booking.roomKey]: [booking],
                };
            }
        }
    });
    return bookingList;
};

export const checkRangeDateTimeOverlapping = (options: {
    roomKey: string;
    start: Dayjs;
    end: Dayjs;
    bookingGroupById: Record<string, IRoomBookingSchedule>;
}) => {
    const { start, end, bookingGroupById, roomKey } = options;
    const diffDay = end.diff(start, 'day');
    const startUnix = start.unix();
    const endUnix = end.unix();
    const availableDates: string[] = [];
    _.forEach(bookingGroupById, (booking) => {
        const {
            status,
            stayingStartDate,
            stayingEndDate,
            roomKey: bookingRoomKey,
            checkInTime = DEFAULT_CHECK_IN_TIME,
            checkOutTime = DEFAULT_CHECK_OUT_TIME,
        } = booking;
        if (
            booking.isDayUse ||
            roomKey !== bookingRoomKey ||
            status === RoomBookingItemBookingStatus.CANCELLED ||
            parseDate(stayingEndDate).isBefore(customDayjs(), 'day')
        ) {
            return;
        }

        const _start = `${stayingStartDate} ${checkInTime}`;
        const _end = `${stayingEndDate} ${checkOutTime}`;
        const bookingStart = parseDate(_start).unix();
        const bookingEnd = parseDate(_end).unix();
        const valid = startUnix < bookingEnd && endUnix > bookingStart;
        if (valid) {
            for (let i = 0; i <= diffDay; i++) {
                const date = start.clone().add(i, 'day').fmYYYYMMDD('-');
                if (
                    parseDate(date).isSameOrAfter(parseDate(stayingStartDate), 'day') &&
                    parseDate(date).isBefore(parseDate(stayingEndDate), 'day')
                ) {
                    availableDates.push(date);
                }
            }
        }
    });
    return _.uniq(availableDates);
};

export const checkValidRangeDateTime = (options: {
    roomKey: string;
    start: Dayjs;
    end: Dayjs;
    bookingList: IRoomBookingList;
}) => {
    const { start, end, bookingList, roomKey } = options;
    const diffDay = end.diff(start, 'day');
    const startUnix = start.unix();
    const endUnix = end.unix();
    const availableDates: string[] = [];
    for (let i = 0; i <= diffDay; i++) {
        const bookings =
            bookingList[start.clone().add(i, 'day')?.fmYYYYMMDD('-')]?.[roomKey] || [];
        for (let index = 0; index < bookings.length; index++) {
            const booking = bookings[index];
            if (booking.status === RoomBookingItemBookingStatus.CHECKED_OUT) continue;
            const _start =
                booking.stayingStartDate + ' ' + (booking.checkInTime || '00:00');
            const _end = booking.stayingEndDate + ' ' + (booking.checkOutTime || '23:59');
            const bookingStart = parseDate(_start).unix();
            const bookingEnd = parseDate(_end).unix();
            const valid = startUnix > bookingEnd || endUnix < bookingStart;
            if (!valid && !booking.isDayUse) {
                availableDates.push(start.clone().add(i, 'day')?.fmYYYYMMDD('-'));
            }
        }
    }
    return availableDates;
};

export const getHoursColumns = (startDate: string, endDate: string) => {
    const items: IColumnOption[] = [];
    const start = parseDate(startDate).subtract(1, 'day').startOf('day');
    const end = parseDate(endDate).add(1, 'day').endOf('day');
    const days = end.diff(start, 'day');
    for (let index = 0; index <= days; index++) {
        const day = start.clone().add(index, 'days')?.fmYYYYMMDD('-');
        for (let i = 0; i < 24; i++) {
            items.push({
                id: day + ` ${i < 10 ? '0' + i : i}:00`,
                day: day,
                hours: `${i < 10 ? '0' + i : i}:00`,
                index: i,
                label: i.toString(),
            });
        }
    }
    return items;
};

export const getDayColumns = (startDate: string, endDate: string) => {
    const items: { id: string; label: string; month: number }[] = [];
    const start = parseDate(startDate).subtract(1, 'week').startOf('week');
    const end = parseDate(endDate).add(1, 'week').endOf('week');
    const days = end.diff(start, 'day');
    for (let i = 0; i <= days; i++) {
        const id = start.clone().add(i, 'day')?.fmYYYYMMDD('-');
        items.push({
            id,
            label: start.clone().add(i, 'day').format('M/D ddd'),
            month: start.clone().add(i, 'day').month() + 1,
        });
    }
    return items;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isShouldUpdate = (prev: any, next: any) => {
    const check = prev && JSON.stringify(prev) === JSON.stringify(next);
    return !check;
};

export const checkExistYomiganaAndEmail = (members: IMemberAttachment[]) => {
    const errorItems: { key: string; message: string }[] = [];
    for (let index = 0; index < members.length; index += 1) {
        if (members[index]?.yomigana && !members[index]?.emailAddress) {
            errorItems.push({
                key: `members.${index}.emailAddress`,
                message: 'roomBooking.createBooking.memberGuestEmailRequired',
            });
        }
        if (!members[index]?.yomigana && members[index]?.emailAddress) {
            errorItems.push({
                key: `members.${index}.yomigana`,
                message: 'roomBooking.createBooking.memberGuestYomiganaRequired',
            });
        }
    }
    return errorItems;
};

export function getCreateBookingFormPageId(name: string) {
    return `createBookingFormPage-form-${name}`;
}

export function getCreateBookingFormId(name: string) {
    return `create-booking-form-${name}`;
}

export function getBookingConfirmId(name: string) {
    return `booking-confirm-${name}`;
}

export function getGroupBookingFormId(name: string) {
    return `create-group-booking-form-${name}`;
}

const PRIORITY = {
    [ReceiptItemDetailType.STAY_PRICE]: 1,
    [ReceiptItemDetailType.STAY_PRICE_CHILDREN]: 2,
    [ReceiptItemDetailType.LOCAL_TAX]: 3,
    [ReceiptItemDetailType.SALE_ITEM]: 4,
};

const convertGroupReceiptItemDetails = (
    roomBookingItem: IRoomBookingItemReceipt,
    receiptItem: IRoomBookingItemsReceiptItem,
    receiptItemIndex: number,
    roomBookingItemIndex: number,
    roomBookingReceipt: IReceipt,
) => {
    const { isCheckout, startDatetime, endDatetime } =
        getCommonReceiptItem(roomBookingItem);
    return [...receiptItem.receiptItemDetails]
        .sort((a, b) => {
            if (a?.type && b?.type) {
                return (
                    PRIORITY[a.type as keyof typeof PRIORITY] -
                    PRIORITY[b.type as keyof typeof PRIORITY]
                );
            }
            return 1;
        })
        ?.map((receiptItemDetail, receiptItemDetailIndex) => {
            const {
                id,
                saleItem = null,
                saleItemId = null,
                unitPrice = 0,
                quantity = 0,
                amount,
                type: receiptItemDetailType,
                payAt,
                boughtAt,
                currentTax = null,
                childrenType = null,
                saleItemTax,
                stayPriceTax,
            } = receiptItemDetail;
            return {
                id: `hol_${roomBookingItem.id}_${receiptItem.id}_${id}`,
                saleItem,
                saleItemId,
                unitPrice: unitPrice || 0,
                quantity: quantity,
                amount: amount ? amount : unitPrice ? unitPrice * quantity : 0,
                level: 2,
                indexLevel0: roomBookingItemIndex,
                indexLevel2: receiptItemDetailIndex,
                indexLevel1: receiptItemIndex,
                isCheckout,
                receiptItemDetailType,
                bookingDate:
                    receiptItem.type === ReceiptItemType.PAYMENT ? payAt : boughtAt,
                startDatetime: parseDate(startDatetime)?.fmYYYYMMDDHHmmss(),
                endDatetime: parseDate(endDatetime)?.fmYYYYMMDDHHmmss(),
                currentTax:
                    receiptItemDetailType === ReceiptItemDetailType.LOCAL_TAX ||
                    receiptItemDetailType === ReceiptItemDetailType.BATH_TAX
                        ? currentTax
                        : null,
                childrenType:
                    receiptItemDetailType === ReceiptItemDetailType.STAY_PRICE_CHILDREN
                        ? childrenType
                        : null,
                saleItemTax,
                stayPriceTax,
                bookingCreationDate: roomBookingReceipt?.roomBooking?.createdAt,
            };
        });
};

const convertSingleReceiptItemDetails = (
    roomBookingReceipt: IReceipt,
    roomBookingItem: IRoomBookingItemReceipt,
    receiptItem: IRoomBookingItemsReceiptItem,
) => {
    const {
        receiptItemDetails = [],
        type,
        paymentMethod = null,
        createdAt,
    } = receiptItem;
    const {
        id,
        unitPrice = 0,
        quantity = 0,
        amount,
        saleItem = null,
        saleItemId = null,
        payAt,
        boughtAt,
        type: receiptItemDetailType,
        saleItemTax,
        stayPriceTax,
    } = receiptItemDetails[0];
    return {
        unitPrice: unitPrice || 0,
        quantity: quantity || 0,
        amount: amount ? amount : unitPrice ? unitPrice * quantity : 0,
        paymentMethod: paymentMethod,
        saleItem: saleItem,
        saleItemId,
        receiptItemDetailId: id,
        representativeGuest:
            type !== ReceiptItemType.PAYMENT
                ? roomBookingItem.representativeGuest ||
                  roomBookingReceipt?.roomBooking?.representativeGuest ||
                  null
                : null,
        room: type !== ReceiptItemType.PAYMENT ? roomBookingItem.room || null : null,
        bookingDate: type === ReceiptItemType.PAYMENT ? payAt : boughtAt,
        receiptItemDetailType,
        saleItemTax,
        stayPriceTax,
        createdAt,
        bookingCreationDate: roomBookingReceipt?.roomBooking.createdAt,
    };
};

const convertRoomReceiptItem = (
    roomBookingReceipt: IReceipt,
    roomBookingItem: IRoomBookingItemReceipt,
    roomBookingItemIndex: number,
) => {
    const { isCheckout, startDatetime, endDatetime, isCancelled } =
        getCommonReceiptItem(roomBookingItem);

    return roomBookingItem.receiptItems?.map((receiptItem, receiptItemIndex) => {
        const {
            id,
            type,
            paymentRoomBookingItem = null,
            bookingType,
            receiptItemDetails = [],
        } = receiptItem;
        const commonObject = {
            id: `hol_${roomBookingItem.id}_${id}`,
            status: type,
            paymentRoomBookingItem,
            level: 1,
            indexLevel0: roomBookingItemIndex,
            indexLevel1: receiptItemIndex,
            bookingType,
            isCheckout,
            isCancelled,
            startDatetime: parseDate(startDatetime)?.fmYYYYMMDDHHmmss(),
            endDatetime: parseDate(endDatetime)?.fmYYYYMMDDHHmmss(),
            bookingCreationDate: roomBookingReceipt?.roomBooking.createdAt,
        };
        let moreData = {};

        const isMoreSaleItem = !hasStayPrice(receiptItem) || isPaymentItem(receiptItem);

        if (receiptItemDetails.length === 1 && isMoreSaleItem) {
            commonObject.id = `${commonObject.id}_${receiptItemDetails[0].id}`;
            moreData = convertSingleReceiptItemDetails(
                roomBookingReceipt,
                roomBookingItem,
                receiptItem,
            );
        } else {
            const children = convertGroupReceiptItemDetails(
                roomBookingItem,
                receiptItem,
                receiptItemIndex,
                roomBookingItemIndex,
                roomBookingReceipt,
            );
            const stayPriceItem = receiptItemDetails.find(
                (item) => item.type === ReceiptItemDetailType.STAY_PRICE,
            );
            moreData = {
                representativeGuest:
                    roomBookingItem.representativeGuest ||
                    roomBookingReceipt?.roomBooking?.representativeGuest ||
                    null,
                room: roomBookingItem.room || null,
                children: children.length ? children : undefined,
                amount: sumBy(children, 'amount'),
                plan: roomBookingItem.plan || {
                    id: null,
                    name: i18next.t('roomBooking.detail.receipt.plan.notExist'),
                },
                bookingDate: stayPriceItem?.boughtAt,
                createdAt: receiptItem.createdAt,
                bookingCreationDate: roomBookingReceipt?.roomBooking.createdAt,
            };
        }
        return {
            ...commonObject,
            ...moreData,
        };
    });
};

const isPaymentItem = (receiptItem: IRoomBookingItemsReceiptItem) => {
    return receiptItem.type === ReceiptItemType.PAYMENT;
};

const hasStayPrice = (receiptItem: IRoomBookingItemsReceiptItem) => {
    return !!receiptItem.receiptItemDetails?.some(
        (item) => item.type === ReceiptItemDetailType.STAY_PRICE,
    );
};

const getCommonReceiptItem = (roomBookingItem: IRoomBookingItemReceipt) => {
    const isCheckout =
        roomBookingItem.bookingStatus === RoomBookingItemBookingStatus.CHECKED_OUT ||
        roomBookingItem.bookingStatus === RoomBookingItemBookingStatus.CANCELLED;
    const startDatetime = `${parseDate(
        roomBookingItem.startDateOfStay,
    )?.fmYYYYMMDD()} ${parseTime(roomBookingItem.checkInTime)?.fmHHmmss()}`;
    const endDatetime = roomBookingItem.endDateOfStay;
    const isCancelled =
        roomBookingItem.bookingStatus === RoomBookingItemBookingStatus.CANCELLED;
    return { isCheckout, startDatetime, endDatetime, isCancelled };
};

const convertRoomBookingReceiptItem = (roomBookingReceipt: IReceipt | null) => {
    return (
        roomBookingReceipt?.roomBooking?.roomBookingItems?.map(
            (roomBookingItem, roomBookingItemIndex) => {
                const { isCheckout, startDatetime, endDatetime, isCancelled } =
                    getCommonReceiptItem(roomBookingItem);
                const receiptItems = convertRoomReceiptItem(
                    roomBookingReceipt,
                    roomBookingItem,
                    roomBookingItemIndex,
                );

                return {
                    id: `hol_${roomBookingItem.id}`,
                    name: 'roomBooking.detail.roomItem',
                    autoGeneratedCode: roomBookingReceipt?.roomBooking?.autoGeneratedCode,
                    children: receiptItems,
                    level: 0,
                    indexLevel0: roomBookingItemIndex,
                    isCheckout,
                    isCancelled,
                    startDatetime: parseDate(startDatetime)?.fmYYYYMMDDHHmmss(),
                    endDatetime: parseDate(endDatetime)?.fmYYYYMMDDHHmmss(),
                    bookingStatus: roomBookingItem.bookingStatus,
                    bookingCreationDate: roomBookingReceipt?.roomBooking.createdAt,
                    pricingSettings: roomBookingItem?.pricingSettings,
                };
            },
        ) || []
    );
};

const getFacilityBookingTime = (facilityBooking: IFacilityBookingReceiptDetail) => {
    const {
        status,
        startDatetime: checkInDateTime,
        endDatetime: checkOutDateTime,
    } = facilityBooking;
    const isCheckout =
        status === FacilityBookingStatus.FINISHED ||
        status === FacilityBookingStatus.CANCELLED;
    const startDatetime = parseDate(checkInDateTime)?.fmYYYYMMDDHHmmss();
    const endDatetime = parseDate(checkOutDateTime)?.fmYYYYMMDDHHmmss();
    return { isCheckout, startDatetime, endDatetime };
};

const convertSingleFacilityReceiptItem = (
    facilityBooking: IFacilityBookingReceiptDetail,
    receiptItem: IFacilityBookingReceiptItem,
) => {
    const { isCheckout } = getFacilityBookingTime(facilityBooking);
    const {
        id,
        unitPrice = 0,
        quantity = 0,
        amount,
        saleItem,
        boughtAt,
        payAt,
        type: receiptItemDetailType,
        saleItemTax,
        stayPriceTax,
    } = receiptItem.receiptItemDetails?.[0] ?? {};
    const { type, paymentMethod = null } = receiptItem;
    const { guest = null } = facilityBooking;
    return {
        receiptItemDetailId: id,
        saleItem,
        quantity,
        unitPrice,
        isCheckout,
        amount: amount || quantity * unitPrice,
        paymentMethod: paymentMethod,
        representativeGuest: type !== ReceiptItemType.PAYMENT ? guest : null,
        bookingDate:
            type === ReceiptItemType.PAYMENT ? parseDate(payAt) : parseDate(boughtAt),
        receiptItemDetailType,
        saleItemTax,
        stayPriceTax,
    };
};

const convertGroupFacilityReceiptItem = (
    facilityBooking: IFacilityBookingReceiptDetail,
    receiptItem: IFacilityBookingReceiptItem,
    facilityBookingIndex: number,
    receiptItemIndex: number,
) => {
    const { isCheckout, startDatetime, endDatetime } =
        getFacilityBookingTime(facilityBooking);
    const {
        id: receiptItemId,
        receiptItemDetails = [],
        type: receiptItemType,
    } = receiptItem;
    const { id: facilityBookingId } = facilityBooking;
    return receiptItemDetails?.map((receiptItemDetail, receiptItemDetailIndex) => {
        const {
            id,
            saleItem,
            quantity = 0,
            unitPrice = 0,
            amount,
            payAt,
            boughtAt,
            type: receiptItemDetailType,
            saleItemTax,
            stayPriceTax,
        } = receiptItemDetail;
        return {
            id: `fac_${facilityBookingId}_${receiptItemId}_${id}`,
            saleItem: saleItem,
            quantity: quantity,
            unitPrice: unitPrice,
            amount: amount || quantity * unitPrice,
            level: 2,
            indexLevel0: facilityBookingIndex,
            indexLevel1: receiptItemIndex,
            indexLevel2: receiptItemDetailIndex,
            isCheckout,
            receiptItemDetailType,
            bookingDate: receiptItemType === ReceiptItemType.PAYMENT ? payAt : boughtAt,
            startDatetime,
            endDatetime,
            saleItemTax,
            stayPriceTax,
        };
    });
};

const convertFacilityReceiptItem = (
    facilityBooking: IFacilityBookingReceiptDetail,
    facilityBookingIndex: number,
) => {
    const { id: facilityBookingId, receiptItems = [] } = facilityBooking;
    const { isCheckout, startDatetime, endDatetime } =
        getFacilityBookingTime(facilityBooking);
    return receiptItems?.map((receiptItem, receiptItemIndex) => {
        const {
            id,
            type,
            bookingType,
            paymentRoomBookingItem = null,
            receiptItemDetails = [],
        } = receiptItem;

        const commonObject = {
            id: `fac_${facilityBookingId}_${id}`,
            status: type,
            paymentRoomBookingItem,
            level: 1,
            indexLevel0: facilityBookingIndex,
            indexLevel1: receiptItemIndex,
            bookingType,
            isCheckout,
            startDatetime,
            endDatetime,
        };
        let moreData = {};
        const isGroup = receiptItemDetails?.some((receiptItemDetail) => {
            return receiptItemDetail?.type === ReceiptItemDetailType.STAY_PRICE;
        });
        if (
            (type === ReceiptItemType.PAYMENT || !isGroup) &&
            receiptItemDetails?.length === 1
        ) {
            moreData = convertSingleFacilityReceiptItem(facilityBooking, receiptItem);
        } else {
            const children = convertGroupFacilityReceiptItem(
                facilityBooking,
                receiptItem,
                facilityBookingIndex,
                receiptItemIndex,
            );
            const stayPriceItem = receiptItemDetails.find(
                (item) => item.type === ReceiptItemDetailType.STAY_PRICE,
            );
            moreData = {
                representativeGuest: facilityBooking.guest || null,
                room: receiptItem.paymentRoomBookingItem?.room || null,
                facility: facilityBooking.facility || null,
                amount: sumBy(children, 'amount'),
                bookingDate: stayPriceItem?.boughtAt,
                children,
            };
        }
        return {
            ...commonObject,
            ...moreData,
        };
    });
};

const convertFacilityBookingReceiptItem = (roomBookingReceipt: IReceipt | null) => {
    return (
        roomBookingReceipt?.facilityBookings?.map(
            (facilityBooking, facilityBookingIndex) => {
                const { isCheckout, startDatetime, endDatetime } =
                    getFacilityBookingTime(facilityBooking);

                const receiptItems = convertFacilityReceiptItem(
                    facilityBooking,
                    facilityBookingIndex,
                );
                return {
                    id: `fac_${facilityBooking.id}`,
                    name: 'roomBooking.detail.facilityItem',
                    autoGeneratedCode: facilityBooking?.autoGeneratedCode,
                    children: receiptItems,
                    level: 0,
                    indexLevel0: facilityBookingIndex,
                    isCheckout,
                    startDatetime,
                    endDatetime,
                    bookingStatus: facilityBooking.status,
                };
            },
        ) || []
    );
};

export const convertRoomBookingReceipt = (roomBookingReceipt: IReceipt | null) => {
    // convert  room booking receipt data to table
    const roomBookingReceipts = convertRoomBookingReceiptItem(roomBookingReceipt);

    // convert facility booking receipt data to table
    const facilityBookingReceipts = convertFacilityBookingReceiptItem(roomBookingReceipt);
    return [...roomBookingReceipts, ...facilityBookingReceipts];
};

export const getByID = (tree: IBookingReceiptTableData, id: string) => {
    let result = null;
    if (id === tree.id) {
        return tree;
    } else {
        if (tree.children) {
            tree.children.some((node) => (result = getByID(node, id)));
        }
        return result;
    }
};

export const findReceiptRowById = (tree: IBookingReceiptTableData[], id: string) => {
    let result = null;
    for (const treeData of tree) {
        result = getByID(treeData, id);
        if (result) return result;
    }
    return result;
};

export const convertTreeToList = (
    receiptArray: IBookingReceiptTableData[],
    resultArray: IBookingReceiptTableData[],
) => {
    resultArray = [...resultArray, ...receiptArray];
    for (const item of receiptArray) {
        if (item.children) {
            resultArray = convertTreeToList(item.children, resultArray);
        }
    }
    return resultArray;
};

export const calculatePriceOnTable = (tableData: IBookingReceiptTableData[]) => {
    tableData.forEach((receiptItem) => {
        if (receiptItem.children) {
            receiptItem.children.forEach((receiptItemDetail) => {
                if (
                    receiptItemDetail.status === ReceiptItemType.RECEIPT &&
                    receiptItemDetail.children
                ) {
                    receiptItemDetail.amount = sumBy(
                        receiptItemDetail.children,
                        'amount',
                    );
                }
            });
        }
    });
};

export function getChildrenToForm(children: IChildBooking[], update: boolean) {
    const childrenToForm = children.map((child) => {
        return {
            childrenTypeId: update && child.id ? undefined : child.typeId,
            quantity: child.quantity,
            id: update && child.id ? child.id : undefined,
        };
    });
    return childrenToForm;
}

export const getAutoGeneratedCode = (autoGeneratedCode: string | number) => {
    return autoGeneratedCode?.toString()?.split('@')?.[0] || '';
};

export const getBookingId = (booking: IUnassignedBookingItem | IFacilityBooking) => {
    if (booking.roomBooking.otaReservationNumber) {
        return getAutoGeneratedCode(booking.roomBooking.otaReservationNumber);
    }
    return getAutoGeneratedCode(booking.roomBooking.autoGeneratedCode || '');
};

export const convertToRoomBookingTableData = (
    roomBookingList: IRoomBooking[],
): IRoomBookingTable[] => {
    return roomBookingList.map((roomBooking, index) => {
        if (roomBooking.roomBookingItems?.length === 0) return roomBooking;

        // remove roomBookingItem if item.id === null
        const roomBookingItems = roomBooking.roomBookingItems.filter((item) => {
            return item.id;
        });

        let category = RoomBookingCategory.GROUP;
        if (roomBookingItems.length === 1) {
            const roomBookingItem = roomBookingItems[0];
            if (roomBookingItem.isDayUse) {
                category = RoomBookingCategory.DAY_USE;
            } else {
                category = RoomBookingCategory.INDIVIDUAL;
            }
            return {
                id: roomBookingItem.id,
                isRoomBooking: false,
                roomBookingId: roomBooking.id,
                autoGeneratedCode: getAutoGeneratedCode(roomBooking.autoGeneratedCode),
                representativeGuest: roomBooking.representativeGuest,
                reserverGuest: roomBooking.reserverGuest,
                category,
                createdAt: roomBooking.createdAt,
                marketingChannel: roomBooking.marketingChannel,
                plan: roomBookingItem.plan,
                bookingStatus: roomBookingItem.bookingStatus,
                startDateOfStay: roomBookingItem.startDateOfStay,
                endDateOfStay: roomBookingItem.endDateOfStay,
                checkInTime: roomBookingItem.checkInTime,
                checkOutTime: roomBookingItem.checkOutTime,
                cleaningStatus: roomBookingItem.cleaningStatus,
                price: roomBookingItem?.individualUnpaidAmount || 0,
                memo: roomBooking.memo,
                otaMemo: roomBooking.otaMemo,
                room: roomBookingItem.room,
                roomType: roomBookingItem.roomType,
                isHasError: roomBooking.isHasError,
                isHaveErrorPrice: roomBooking.isHaveErrorPrice,
                isNoShow: roomBookingItem.isNoShow,
            };
        } else {
            const roomBookingGroup = {
                id: `${roomBooking.id}_${index}`,
                isRoomBooking: true,
                roomBookingId: roomBooking.id,
                autoGeneratedCode: getAutoGeneratedCode(roomBooking.autoGeneratedCode),
                representativeGuest: roomBooking.representativeGuest,
                reserverGuest: roomBooking.reserverGuest,
                category,
                createdAt: roomBooking.createdAt,
                marketingChannel: roomBooking.marketingChannel,
                price: sumBy(roomBookingItems, 'individualUnpaidAmount'),
                memo: roomBooking.memo,
                otaMemo: roomBooking.otaMemo,
                isHasError: roomBooking.isHasError,
                isHaveErrorPrice: roomBooking.isHaveErrorPrice,
                isNoShow: roomBooking.isNoShow,
            };
            let paymentAmount = sumBy(roomBookingItems, (item) => {
                return item.totalAmount < 0 ? item.totalAmount : 0;
            });
            const children = roomBookingItems.map((roomBookingItem) => {
                let price = 0;
                if (roomBookingItem.totalAmount < 0) {
                    price = 0;
                } else {
                    if (roomBookingItem.totalAmount + paymentAmount > 0) {
                        price = roomBookingItem.totalAmount + paymentAmount;
                        paymentAmount = 0;
                    } else {
                        price = 0;
                        paymentAmount += roomBookingItem.totalAmount;
                    }
                }
                return {
                    id: roomBookingItem.id,
                    isRoomBooking: false,
                    roomBookingId: roomBooking.id,
                    autoGeneratedCode: getAutoGeneratedCode(
                        roomBookingItem.autoGeneratedCode,
                    ),
                    representativeGuest: roomBookingItem.representativeGuest,
                    category,
                    createdAt: roomBookingItem.createdAt,
                    marketingChannel: roomBooking.marketingChannel,
                    plan: roomBookingItem.plan,
                    bookingStatus: roomBookingItem.bookingStatus,
                    startDateOfStay: roomBookingItem.startDateOfStay,
                    endDateOfStay: roomBookingItem.endDateOfStay,
                    checkInTime: roomBookingItem.checkInTime,
                    checkOutTime: roomBookingItem.checkOutTime,
                    cleaningStatus: roomBookingItem.cleaningStatus,
                    price,
                    room: roomBookingItem.room,
                    roomType: roomBookingItem.roomType,
                    isHasError: roomBooking.isHasError,
                    isHaveErrorPrice: roomBooking.isHaveErrorPrice,
                    isNoShow: roomBookingItem.isNoShow,
                };
            });
            return {
                ...roomBookingGroup,
                children,
            };
        }
    });
};

export const calculateTaxSaleItem = (
    tax: ITax | null,
    quantity: number,
    price: number,
) => {
    let taxTotal = 0;
    switch (tax?.pricingType) {
        case PricingType.FIX:
            taxTotal = quantity * tax.pricingValue;
            break;

        case PricingType.PERCENTAGE:
            taxTotal = (quantity * price * tax.pricingValue) / (100 + tax?.pricingValue);
            break;
    }
    return Math.floor(taxTotal);
};

export const validateDayUseTimeIn24H = (date: [Dayjs, Dayjs], time: [Dayjs, Dayjs]) => {
    const [checkInTime, checkOutTime] = time;
    const [checkInDate, checkOutDate] = date;
    const start = checkInDate
        .set('hours', checkInTime.get('hours'))
        .set('minutes', checkInTime.get('minutes'));
    const end = checkOutDate
        .set('hours', checkOutTime.get('hours'))
        .set('minutes', checkOutTime.get('minutes'));
    const diff = end.diff(start, 'minutes');
    return diff > 0 && diff <= MINUTES_PER_DAY;
};

export const validateDayUseTimeInAfterNow = (
    date: [Dayjs, Dayjs],
    time: [Dayjs, Dayjs],
) => {
    const [checkInTime] = time;
    const [checkInDate] = date;
    const start = checkInDate
        .set('hours', checkInTime.get('hours'))
        .set('minutes', checkInTime.get('minutes'));
    return start.unix() > todayDayjs.unix();
};

export const validateDayUseTimeInAfterTwoDaysBefore = (
    date: [Dayjs, Dayjs],
    time: [Dayjs, Dayjs],
) => {
    const [checkInTime] = time;
    const [checkInDate] = date;
    const start = checkInDate
        .set('hours', checkInTime.get('hours'))
        .set('minutes', checkInTime.get('minutes'));
    return start.isSameOrAfter(todayDayjs.subtract(2, 'd'), 'day');
};

export const validateDayUseDateTime = (date: [Dayjs, Dayjs], time: [Dayjs, Dayjs]) => {
    const [checkInTime, checkOutTime] = time;
    const [checkInDate, checkOutDate] = date;
    const start = checkInDate
        .set('hours', checkInTime.get('hours'))
        .set('minutes', checkInTime.get('minutes'));
    const end = checkOutDate
        .set('hours', checkOutTime.get('hours'))
        .set('minutes', checkOutTime.get('minutes'));
    return end.unix() > start.unix();
};

export const validateStartDateInAfterNow = (date: [Dayjs, Dayjs]) => {
    const [startDate] = date;
    return startDate.isSameOrAfter(customDayjs(), 'day');
};

export const validateStartDateInAfterTwoDaysBefore = (date: [Dayjs, Dayjs]) => {
    const [startDate] = date;
    return startDate.isSameOrAfter(customDayjs().subtract(2, 'day'), 'day');
};

export const validateStayingDate = (date: [Dayjs, Dayjs]) => {
    const [startDate, endDate] = date;
    return endDate.unix() > startDate.unix();
};

export const getBookingPlan = (booking: IRoomBookingSchedule): IBookingPlanItem => {
    return {
        isDayUse: booking.isDayUse,
        startTime: parseDate(
            booking.stayingStartDate +
                ' ' +
                (booking.isDayUse ? booking.checkInTime : '00:00'),
        ).unix(),
        endTime: parseDate(
            booking.stayingEndDate +
                ' ' +
                (booking.isDayUse ? booking.checkOutTime : '23:59'),
        ).unix(),
        roomId: booking.roomId || 0,
    };
};

export const convertBookingDateTimeToLocalTime = (
    startDate: string,
    endDate: string,
    checkInTime?: string,
    checkoutTime?: string,
) => {
    let checkInDateTime = null;
    let checkOutDateTime = null;
    if (!checkInTime || !parseTime(checkInTime).isValid()) {
        checkInDateTime = parseDate(startDate);
    } else {
        const _checkInTime = parseTime(checkInTime);
        checkInDateTime = parseDate(startDate)
            .add(_checkInTime.hour(), 'hour')
            .add(_checkInTime.minute(), 'minute');
    }
    if (!checkoutTime || !parseTime(checkoutTime).isValid()) {
        checkOutDateTime = parseDate(endDate);
    } else {
        const _checkoutTime = parseTime(checkoutTime);
        checkOutDateTime = parseDate(endDate)
            .add(_checkoutTime.hour(), 'hour')
            .add(_checkoutTime.minute(), 'minute');
    }
    return [checkInDateTime, checkOutDateTime];
};

export const getBookingFromListById = (
    bookingList: IRoomBookingList,
    options: { id: number; date?: string },
) => {
    const { id, date } = options;
    const getBooking = (items: Record<string, IRoomBookingSchedule[]>) => {
        for (const roomKey in items) {
            for (let index = 0; index < items[roomKey].length; index++) {
                const booking = items[roomKey][index];
                if (booking.id === id) {
                    return booking;
                }
            }
        }
        return null;
    };
    if (date) {
        return getBooking(bookingList[date] || {});
    } else {
        for (const _date in bookingList) {
            const booking = getBooking(bookingList[_date] || {});
            if (booking) {
                return booking;
            }
        }
    }

    return null;
};

export const getRoomTypeTitleError = (roomType?: IRoomType) => {
    if (!roomType) return '';
    const {
        id,
        isPullFromTll = false,
        isAvailableRoomsPositive = false,
        name = '',
    } = roomType;
    if (!id || isPullFromTll) {
        return i18next.t('roomBooking.detail.message.roomTypeNotExist');
    }
    if (isAvailableRoomsPositive) {
        return name;
    }
    return i18next.t('roomBooking.detail.message.availableRoomsNegative');
};

export const isRoomTypeError = (roomType?: IRoomType) => {
    if (!roomType) return true;
    const { id, isAvailableRoomsPositive, isPullFromTll } = roomType;
    return !id || !isAvailableRoomsPositive || isPullFromTll;
};

export const getRoomTypeName = (roomType?: IRoomType) => {
    if (!roomType)
        return i18next.t('roomBooking.detail.bookingItemCard.roomType.notExist');
    const { id, name = '' } = roomType;
    if (!id) {
        return i18next.t('roomBooking.detail.bookingItemCard.roomType.notExist');
    }
    return name;
};

export const maxHeightRoomList = (roomList: IScheduleItem[], collapseRooms: number[]) => {
    return roomList.reduce((total, item) => {
        if (!item.parentId) {
            total += CELL_HEADER_HEIGHT;
            return total;
        }
        if (!collapseRooms.includes(item.parentId)) {
            total += CELL_HEIGHT;
            return total;
        }
        return total;
    }, 0);
};

export const calculateBookingScrollData = (
    currentPosition: { x: number; y: number },
    offsetData: { deltaX: number; deltaY: number; shiftKey: boolean },
) => {
    const { deltaX, deltaY, shiftKey } = offsetData;
    const { x, y } = currentPosition;
    let scrollLeft = x + deltaX * BOOKING_HORIZONTAL_SCROLL_SPEED;
    let scrollTop = y + deltaY;
    // horizontal scroll when Holding down shift and scroll wheel
    if (shiftKey && deltaX === 0 && deltaY !== 0) {
        scrollLeft = x + deltaY;
        scrollTop = y;
    }
    return {
        scrollLeft,
        scrollTop,
    };
};

// use by room booking receipt
export const isCanceledBooking = (bookingStatus?: string) => {
    return (
        bookingStatus === RoomBookingItemBookingStatus.CANCELLED ||
        bookingStatus === FacilityBookingStatus.CANCELLED
    );
};

export const convertDatePeriodInRoomBookingQuery = (
    query: IAdvanceSearchGetListQuery,
) => {
    const {
        endDatePeriod = [],
        startDatePeriod = [],
        stayPeriod = [],
        receptionPeriod = [],
    } = query;
    return {
        ...query,
        endDatePeriod:
            endDatePeriod?.length === 2
                ? [
                      parseDate(endDatePeriod[0]).startOf('day')?.fmYYYYMMDDHHmmss(),
                      parseDate(endDatePeriod[1]).endOf('day')?.fmYYYYMMDDHHmmss(),
                  ]
                : undefined,
        startDatePeriod:
            startDatePeriod?.length === 2
                ? [
                      parseDate(startDatePeriod[0])?.startOf('day')?.fmYYYYMMDDHHmmss(),
                      parseDate(startDatePeriod[1])?.endOf('day')?.fmYYYYMMDDHHmmss(),
                  ]
                : undefined,
        stayPeriod:
            stayPeriod?.length === 2
                ? [
                      parseDate(stayPeriod[0]).startOf('day')?.fmYYYYMMDDHHmmss(),
                      parseDate(stayPeriod[1]).endOf('day')?.fmYYYYMMDDHHmmss(),
                  ]
                : undefined,
        receptionPeriod:
            receptionPeriod?.length === 2
                ? [
                      parseDate(receptionPeriod[0]).startOf('day').fmYYYYMMDDHHmmss(),
                      parseDate(receptionPeriod[1]).endOf('day').fmYYYYMMDDHHmmss(),
                  ]
                : undefined,
    };
};

export const validateGenderBreakdownOfGuest = ({
    numberOfAdults,
    numberOfMale,
    numberOfFemale,
    numberOfOtherGenderGuest,
}: {
    numberOfAdults: string;
    numberOfMale: string;
    numberOfFemale: string;
    numberOfOtherGenderGuest: string;
}) => {
    if (!numberOfMale && !numberOfFemale && !numberOfOtherGenderGuest) return true;
    const totalGender =
        Number(numberOfMale) + Number(numberOfFemale) + Number(numberOfOtherGenderGuest);
    return totalGender === Number(numberOfAdults);
};

export function convertBookingItemToScheduleItem(
    bookingSchedule: IRoomBookingSchedule,
    bookingItem: IRoomBookingItem,
) {
    const updateBooking: IRoomBookingSchedule = {
        ...bookingSchedule,
        stayingStartDate: parseDate(bookingItem.startDateOfStay).fmYYYYMMDD(),
        stayingEndDate: parseDate(bookingItem.endDateOfStay).fmYYYYMMDD(),
        checkInTime: bookingItem.checkInTime,
        checkOutTime: bookingItem.checkOutTime,
        roomId: bookingItem.room?.id,
        roomTypeId: bookingItem.roomType?.id,
        numberOfAdults: bookingItem.numberOfAdults || 0,
        numberOfMale: bookingItem.numberOfMale,
        numberOfFemale: bookingItem.numberOfFemale,
        numberOfOtherGenderGuest: bookingItem.numberOfOtherGenderGuest,
        plan: {
            id: bookingItem.plan?.id || null,
            name: bookingItem.plan?.name || null,
        },
        planId: bookingItem.plan?.id,
        children: (bookingItem.roomBookingItemChildrenTypes || []).map((item) => {
            return {
                id: item.id || null,
                quantity: item.quantity || 0,
                typeId: item.childrenTypeId || null,
            };
        }),
        price: bookingItem.totalAmount,
        room: {
            id: bookingItem.room?.id || null,
            name: bookingItem.room?.name || '',
            cleaningStatus: (bookingItem.room?.cleaningStatus ||
                CleaningStatus.UNCLEANED) as CleaningStatus,
        },
        roomType: {
            id: bookingItem.roomType?.id || null,
            name: bookingItem.roomType?.name || '',
        },
        roomKey: `${bookingItem.roomType?.id}-${bookingItem.room?.id}`,
        guest: {
            ...bookingItem.representativeGuest,
            phone:
                bookingItem.representativeGuest?.mobilePhoneNumber ||
                bookingItem.representativeGuest?.phoneNumberLandline ||
                null,
            emailAddress: bookingItem.representativeGuest?.emailAddress || null,
            birthday: bookingItem.representativeGuest?.birthday || null,
            gender: bookingItem.representativeGuest?.gender || null,
        },
    };

    return updateBooking;
}

export function getReceiptItemTax(
    receiptItem: IBookingReceiptTableData,
    pricingSettings: IPricingSetting[]
): TaxSummary {
    const { receiptItemDetailType } = receiptItem;
    switch (receiptItemDetailType) {
        case ReceiptItemDetailType.BATH_TAX:
        case ReceiptItemDetailType.LOCAL_TAX:
            return { taxAmount: receiptItem.amount || 0 };
        case ReceiptItemDetailType.SALE_ITEM:
            return {
                taxableAmount: receiptItem?.amount || 0,
                taxType: receiptItem?.saleItem?.tax?.type,
            };
        case ReceiptItemDetailType.STAY_PRICE_CHILDREN:
        case ReceiptItemDetailType.STAY_PRICE:
            return {
                taxableAmount: receiptItem?.amount || 0,
                taxType: pricingSettings?.[0]?.planTax?.type,
            };
    }
    return {};
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert TransactionType data from XML file to import XML body
 */
function mapTransactionType(
    xmlData: IAllotmentBookingReport,
    body: IAllotmentBookingReport,
) {
    const transactionTypes: ITransactionType[] = xmlData?.TransactionType || [];
    if (transactionTypes?.[0]) {
        const transactionType: ITransactionType = {
            DataClassification: transactionTypes?.[0]?.DataClassification || [],
            DataID: transactionTypes?.[0]?.DataID || [],
        };
        body.TransactionType = [transactionType];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert AccommodationInformation data from XML file to import XML body
 */
function mapAccommodationInformation(
    xmlData: IAllotmentBookingReport,
    body: IAllotmentBookingReport,
) {
    const accommodationInformationList: IAccommodationInformation[] =
        xmlData?.AccommodationInformation || [];
    if (accommodationInformationList?.[0]) {
        const accommodationInformation: IAccommodationInformation = {
            AccommodationName: accommodationInformationList?.[0]?.AccommodationName,
            AccommodationCode: accommodationInformationList?.[0]?.AccommodationCode,
        };
        body.AccommodationInformation = [accommodationInformation];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert SalesOfficeInformation data from XML file to import XML body
 */
function mapSalesOfficeInformation(
    xmlData: IAllotmentBookingReport,
    body: IAllotmentBookingReport,
) {
    const salesOfficeInformationList: ISalesOfficeInformation[] =
        xmlData?.SalesOfficeInformation || [];
    if (salesOfficeInformationList?.[0]) {
        const salesOfficeInformation: ISalesOfficeInformation = {
            SalesOfficeCompanyName:
                salesOfficeInformationList?.[0]?.SalesOfficeCompanyName || [],
            SalesOfficeCode: salesOfficeInformationList?.[0]?.SalesOfficeCode || [],
        };
        body.SalesOfficeInformation = [salesOfficeInformation];
    }
}

function initBasicInformation(): IBasicInformation {
    const basicInformation: IBasicInformation = {
        TravelAgencyBookingNumber: [],
        TravelAgencyBookingDate: [],
        TravelAgencyBookingTime: [],
        GuestOrGroupNameSingleByte: [],
        GuestOrGroupNameKanjiName: [],
        CheckInTime: [],
        CheckOutTime: [],
        Nights: [],
        PackagePlanCode: [],
        OtherServiceInformation: [],
        PackagePlanName: [],
    };
    return basicInformation;
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert BasicInformation data from XML file to import XML body
 */
function mapBasicInformation(
    xmlData: IAllotmentBookingReport,
    body: IAllotmentBookingReport,
) {
    const basicInformationList: IBasicInformation[] = xmlData?.BasicInformation || [];
    const basicInformation = initBasicInformation();
    const keysOfIBasicInformation = Object.keys(basicInformation);

    if (basicInformationList?.[0]) {
        keysOfIBasicInformation?.forEach((key) => {
            basicInformation[key as keyof IBasicInformation] =
                basicInformationList?.[0]?.[key as keyof IBasicInformation] || [];
        });
        body.BasicInformation = [basicInformation];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert RoomAndGuestInformation data from XML file to import XML body
 */
function mapRoomAndGuestInformation(
    xmlData: IAllotmentBookingReport,
    body: IAllotmentBookingReport,
) {
    const roomAndGuestInformationList: IRoomAndGuestInformation[] =
        xmlData?.RoomAndGuestInformation || [];
    if (roomAndGuestInformationList?.[0]) {
        const roomAndGuestInformation: IRoomAndGuestInformation = {};
        // RoomAndGuestList
        const roomAndGuestList = roomAndGuestInformationList?.[0]?.RoomAndGuestList?.map(
            (roomAndGuest) => {
                const _roomAndGuest: IRoomAndGuestList = {};
                // RoomInformation
                const roomInformation = roomAndGuest?.RoomInformation?.map((roomInfo) => {
                    const _roomInfo: IRoomInformation = {
                        RoomTypeCode: roomInfo.RoomTypeCode,
                        RoomTypeName: roomInfo.RoomTypeName,
                        PerRoomPaxCount: roomInfo.PerRoomPaxCount,
                        RoomPaxMaleCount: roomInfo.RoomPaxMaleCount,
                        RoomPaxFemaleCount: roomInfo.RoomPaxFemaleCount,
                        RoomChildA70Count: roomInfo.RoomChildA70Count,
                        RoomChildB50Count: roomInfo.RoomChildB50Count,
                        RoomChildC30Count: roomInfo.RoomChildC30Count,
                        RoomChildDNoneCount: roomInfo.RoomChildDNoneCount,
                    };
                    return _roomInfo;
                });
                if (roomInformation) {
                    _roomAndGuest.RoomInformation = roomInformation;
                }

                // RoomRateInformation
                const roomRateInformation = roomAndGuest?.RoomRateInformation?.map(
                    (roomRateInfo) => {
                        const _roomRateInfo: IRoomRateInformation = {
                            RoomDate: roomRateInfo.RoomDate,
                            TotalPerRoomRate: roomRateInfo.TotalPerRoomRate,
                            PerPaxRate: roomRateInfo.PerPaxRate,
                            PerChildA70Rate: roomRateInfo.PerChildA70Rate,
                            PerChildB50Rate: roomRateInfo.PerChildB50Rate,
                            PerChildC30Rate: roomRateInfo.PerChildC30Rate,
                            PerChildDRate: roomRateInfo.PerChildDRate,
                        };
                        return _roomRateInfo;
                    },
                );
                if (roomRateInformation) {
                    _roomAndGuest.RoomRateInformation = roomRateInformation;
                }
                return _roomAndGuest;
            },
        );

        if (roomAndGuestList) {
            roomAndGuestInformation.RoomAndGuestList = roomAndGuestList;
        }

        body.RoomAndGuestInformation = [roomAndGuestInformation];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert Basic - RisaplsCommonInformation data from XML file to import XML body
 */
function mapRisaplsCommonInformationBasic(
    xmlData: IRisaplsCommonInformation[],
    body: IRisaplsCommonInformation,
) {
    const basicList: IRisaplsCommonInformationBasic[] = xmlData?.[0]?.Basic || [];
    if (basicList?.[0]) {
        const basic: IRisaplsCommonInformationBasic = {
            SalesOfficeCompanyCode: basicList?.[0]?.SalesOfficeCompanyCode,
            PhoneNumber: basicList?.[0]?.PhoneNumber,
            Email: basicList?.[0]?.Email,
            PostalCode: basicList?.[0]?.PostalCode,
            Address: basicList?.[0]?.Address,
            RepresentativeGendar: basicList?.[0]?.RepresentativeGendar,
        };
        body.Basic = [basic];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert Option - RisaplsCommonInformation data from XML file to import XML body
 */
function mapOptionRisaplsCommonInformation(
    xmlData: IRisaplsCommonInformation[],
    body: IRisaplsCommonInformation,
) {
    const optionList: IRisaplsCommonInformationOption[] = xmlData?.[0]?.Option || [];
    if (optionList?.[0]) {
        const option: IRisaplsCommonInformationOption = {
            OptionDate: optionList?.[0].OptionDate,
            Name: optionList?.[0].Name,
            OptionCount: optionList?.[0].OptionCount,
            OptionRate: optionList?.[0].OptionRate,
            OptionCode: optionList?.[0].OptionCode,
        };
        body.Option = [option];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert Member - RisaplsCommonInformation data from XML file to import XML body
 */
function mapMemberRisaplsCommonInformation(
    xmlData: IRisaplsCommonInformation[],
    body: IRisaplsCommonInformation,
) {
    const memberList: IRisaplsCommonInformationMember[] = xmlData?.[0]?.Member || [];
    if (memberList?.[0]) {
        const member: IRisaplsCommonInformationMember = {
            UserName: memberList?.[0]?.UserName,
            UserKana: memberList?.[0]?.UserKana,
            UserTel: memberList?.[0]?.UserTel,
            UserMailAddr: memberList?.[0]?.UserMailAddr,
            UserZip: memberList?.[0]?.UserZip,
            UserAddr: memberList?.[0]?.UserAddr,
            UserGendar: memberList?.[0]?.UserGendar,
        };
        body.Member = [member];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert BasicRate - RisaplsCommonInformation data from XML file to import XML body
 */
function mapBasicRateRisaplsCommonInformation(
    xmlData: IRisaplsCommonInformation[],
    body: IRisaplsCommonInformation,
) {
    const basicRateList: IRisaplsCommonInformationBasicRate[] =
        xmlData?.[0]?.BasicRate || [];
    if (basicRateList?.[0]) {
        const basicRate: IRisaplsCommonInformationBasicRate = {};
        // PointsDiscountList
        const pointsDiscountList = basicRateList?.[0]?.PointsDiscountList?.map(
            (pointDiscount) => {
                const _pointDiscount: IPointsDiscountList = {
                    PointsDiscountName: pointDiscount?.PointsDiscountName,
                    PointsDiscount: pointDiscount?.PointsDiscount,
                };
                return _pointDiscount;
            },
        );
        if (pointsDiscountList) {
            basicRate.PointsDiscountList = pointsDiscountList;
        }
        body.BasicRate = [basicRate];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert OtherInfo - RisaplsCommonInformation data from XML file to import XML body
 */
function mapOtherInfoRisaplsCommonInformation(
    xmlData: IRisaplsCommonInformation[],
    body: IRisaplsCommonInformation,
) {
    const otherInfoList: IRisaplsCommonInformationOtherInfo[] =
        xmlData?.[0]?.OtherInfo || [];
    if (otherInfoList?.[0]) {
        const otherInfo: IRisaplsCommonInformationOtherInfo = {
            AdditionalInformation: otherInfoList?.[0]?.AdditionalInformation,
        };
        body.OtherInfo = [otherInfo];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert RoomAndRoomRateInformation - RisaplsCommonInformation data from XML file to import XML body
 */
function mapRoomAndRoomRateInfoRisaplsCommonInformation(
    xmlData: IRisaplsCommonInformation[],
    body: IRisaplsCommonInformation,
) {
    const roomAndRoomRateInformationList = xmlData?.[0]?.RoomAndRoomRateInformation?.map(
        (roomAndRoomRateInfo) => {
            const _roomAndRoomRateInfo: IRoomAndRoomRateInformation = {};
            // RoomInformation
            const roomInformationList: IRisaplsCommonInformationRoomInformation[] =
                roomAndRoomRateInfo?.RoomInformation || [];
            if (roomInformationList?.[0]) {
                const roomInformation: IRisaplsCommonInformationRoomInformation = {
                    RoomTypeCode: roomInformationList?.[0]?.RoomTypeCode,
                    PlanGroupCode: roomInformationList?.[0]?.PlanGroupCode,
                    NetRmTypeGroupCode: roomInformationList?.[0]?.NetRmTypeGroupCode,
                    RoomTypeName: roomInformationList?.[0]?.RoomTypeName,
                    PerRoomPaxCount: roomInformationList?.[0]?.PerRoomPaxCount,
                    RoomPaxMaleCount: roomInformationList?.[0]?.RoomPaxMaleCount,
                    RoomPaxFemaleCount: roomInformationList?.[0]?.RoomPaxFemaleCount,
                    RoomChildA70Count: roomInformationList?.[0]?.RoomChildA70Count,
                    RoomChildB50Count: roomInformationList?.[0]?.RoomChildB50Count,
                    RoomChildC30Count: roomInformationList?.[0]?.RoomChildC30Count,
                    RoomChildDNoneCount: roomInformationList?.[0]?.RoomChildDNoneCount,
                };
                _roomAndRoomRateInfo.RoomInformation = [roomInformation];
            }

            // RoomRateInformation
            const roomRateInfoList = roomAndRoomRateInfo?.RoomRateInformation?.map(
                (roomRateInfo) => {
                    const _roomAndRoomRateInfo: IRoomRateInformation = {
                        RoomDate: roomRateInfo?.RoomDate,
                        TotalPerRoomRate: roomRateInfo?.TotalPerRoomRate,
                        PerPaxRate: roomRateInfo?.PerPaxRate,
                        PerChildA70Rate: roomRateInfo?.PerChildA70Rate,
                        PerChildB50Rate: roomRateInfo?.PerChildB50Rate,
                        PerChildC30Rate: roomRateInfo?.PerChildC30Rate,
                        PerChildDRate: roomRateInfo?.PerChildDRate,
                        RoomRateChildA70Request: roomRateInfo?.RoomRateChildA70Request,
                        RoomRateChildB50Request: roomRateInfo?.RoomRateChildB50Request,
                        RoomRateChildC30Request: roomRateInfo?.RoomRateChildC30Request,
                        RoomRateChildDNoneRequest:
                            roomRateInfo?.RoomRateChildDNoneRequest,
                    };
                    return _roomAndRoomRateInfo;
                },
            );
            if (roomRateInfoList) {
                _roomAndRoomRateInfo.RoomRateInformation = roomRateInfoList;
            }
            return _roomAndRoomRateInfo;
        },
    );
    if (roomAndRoomRateInformationList) {
        body.RoomAndRoomRateInformation = roomAndRoomRateInformationList;
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert RisaplsCommonInformation data from XML file to import XML body
 */
function mapRisaplsCommonInformation(
    xmlData: IRisaplsInformation[],
    body: IRisaplsInformation,
) {
    const risaplsCommonInformationList: IRisaplsCommonInformation[] =
        xmlData?.[0]?.RisaplsCommonInformation || [];
    if (risaplsCommonInformationList?.[0]) {
        const risaplsCommonInformation: IRisaplsCommonInformation = {};
        // Basic
        mapRisaplsCommonInformationBasic(
            risaplsCommonInformationList,
            risaplsCommonInformation,
        );
        // Option
        mapOptionRisaplsCommonInformation(
            risaplsCommonInformationList,
            risaplsCommonInformation,
        );
        // Member
        mapMemberRisaplsCommonInformation(
            risaplsCommonInformationList,
            risaplsCommonInformation,
        );
        // BasicRate
        mapBasicRateRisaplsCommonInformation(
            risaplsCommonInformationList,
            risaplsCommonInformation,
        );
        // OtherInfo
        mapOtherInfoRisaplsCommonInformation(
            risaplsCommonInformationList,
            risaplsCommonInformation,
        );
        // RoomAndRoomRateInformation
        mapRoomAndRoomRateInfoRisaplsCommonInformation(
            risaplsCommonInformationList,
            risaplsCommonInformation,
        );

        body.RisaplsCommonInformation = [risaplsCommonInformation];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert AgentNativeInformation data from XML file to import XML body
 */
function mapAgentNativeInformation(
    xmlData: IRisaplsInformation[],
    body: IRisaplsInformation,
) {
    const agentNativeInformationList: IAgentNativeInformation[] =
        xmlData?.[0]?.AgentNativeInformation || [];
    if (agentNativeInformationList?.[0]) {
        const agentNativeInformation: IAgentNativeInformation = {};
        // Extend
        const extendList: IAgentNativeInformationExtend[] =
            agentNativeInformationList?.[0]?.Extend || [];
        if (extendList?.[0]) {
            const extend: IAgentNativeInformationExtend = {
                PointDiv: extendList?.[0]?.PointDiv,
                PointName: extendList?.[0]?.PointName,
                Points: extendList?.[0]?.Points,
                TotalAccommodationDecleasePoints:
                    extendList?.[0]?.TotalAccommodationDecleasePoints,
                TotalAccommodationConsumptionTax:
                    extendList?.[0]?.TotalAccommodationConsumptionTax,
                AmountClaimed: extendList?.[0]?.AmountClaimed,
            };
            agentNativeInformation.Extend = [extend];
        }
        body.AgentNativeInformation = [agentNativeInformation];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert RisaplsInformation data from XML file to import XML body
 */
function mapRisaplsInformation(
    xmlData: IAllotmentBookingReport,
    body: IAllotmentBookingReport,
) {
    const risaplsInformationList: IRisaplsInformation[] =
        xmlData?.RisaplsInformation || [];
    if (risaplsInformationList?.[0]) {
        const risaplsInformation: IRisaplsInformation = {};
        // RisaplsCommonInformation
        mapRisaplsCommonInformation(risaplsInformationList, risaplsInformation);

        // AgentNativeInformation
        mapAgentNativeInformation(risaplsInformationList, risaplsInformation);
        body.RisaplsInformation = [risaplsInformation];
    }
}

/**
 * @param xmlData data parse from XML file
 * @param body result map from XML file
 *
 * convert BasicRateInformation data from XML file to import XML body
 */
function mapBasicRateInformation(
    xmlData: IAllotmentBookingReport,
    body: IAllotmentBookingReport,
) {
    const basicRateInformationList: IBasicRateInformation[] =
        xmlData?.BasicRateInformation || [];
    if (basicRateInformationList?.[0]) {
        const basicRateInformation: IBasicRateInformation = {
            Payment: basicRateInformationList?.[0]?.Payment,
            TotalAccommodationCharge:
                basicRateInformationList?.[0]?.TotalAccommodationCharge,
        };
        body.BasicRateInformation = [basicRateInformation];
    }
}

/**
 * @param data data parse from XML file
 *
 * use to convert data from XML file to import XML body
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapDataToImportXMLBody(data: any): IImportXmlBody {
    const allotmentBookingReport: IAllotmentBookingReport = {};
    const _allotmentBookingReport: IAllotmentBookingReport =
        data?.AllotmentBookingReport?.[0];
    // TransactionType
    mapTransactionType(_allotmentBookingReport, allotmentBookingReport);

    // AccommodationInformation
    mapAccommodationInformation(_allotmentBookingReport, allotmentBookingReport);

    // SalesOfficeInformation
    mapSalesOfficeInformation(_allotmentBookingReport, allotmentBookingReport);

    // BasicInformation
    mapBasicInformation(_allotmentBookingReport, allotmentBookingReport);

    // RoomAndGuestInformation
    mapRoomAndGuestInformation(_allotmentBookingReport, allotmentBookingReport);

    // RisaplsInformation
    mapRisaplsInformation(_allotmentBookingReport, allotmentBookingReport);

    // BasicRateInformation
    mapBasicRateInformation(_allotmentBookingReport, allotmentBookingReport);
    const importXMLBody: IImportXmlBody = {
        AllotmentBookingReport: [allotmentBookingReport],
    };
    return importXMLBody;
}

/**
 * @param bookingDetail default booking details for group-booking
 *
 * use to return initial / new group booking row
 */
export const getGroupBookingDetail = (
    bookingDetail: IRoomBookingSchedule,
): IGroupBookingItem => {
    return {
        roomTypeId: null,
        numberOfRooms: 1,
        numberOfAvailableRooms: 0,
        standardCapacity: 0,
        plan: null,
        numberOfAdults: null,
        numberOfMale: null,
        numberOfFemale: null,
        numberOfOtherGenderGuest: null,
        childrenCount: '0',
        isChildrenDropdownOpen: false,
        isDayUse: false,
        stayingDate: [
            bookingDetail.stayingStartDate
                ? parseDate(bookingDetail.stayingStartDate)
                : undefined,
            bookingDetail.stayingEndDate
                ? parseDate(bookingDetail.stayingEndDate)
                : undefined,
        ],
        time:
            bookingDetail.checkInTime && bookingDetail.checkOutTime
                ? [
                      parseTime(bookingDetail.checkInTime),
                      parseTime(bookingDetail.checkOutTime),
                  ]
                : undefined,
    };
};

export const convertRoomBookingStaticItemToRoomBookingSchedule = (
    bookingTmpResponse: IRoomBookingStaticItem[],
) => {
    const _bookingTemporaryList: IRoomBookingSchedule[] = [];
    _.forEach(bookingTmpResponse, (booking) => {
        const _booking = convertBooking(booking);
        const index = _.findIndex(
            _bookingTemporaryList,
            (item) => item.id === _booking.id,
        );
        if (index > -1) {
            _bookingTemporaryList[index] = _booking;
        } else {
            _bookingTemporaryList.push(_booking);
        }
    });
    return _bookingTemporaryList;
};

export const checkOverlappingDate = (
    isDayUse?: boolean,
    startDateA?: string,
    endDateA?: string,
    startDateB?: string,
    endDateB?: string,
) => {
    if (startDateA && endDateA && startDateB && endDateB) {
        const startStayingDateA = parseDate(startDateA);
        const endStayingDateA = parseDate(endDateA);
        const startStayingDateB = parseDate(startDateB);
        const endStayingDateB = parseDate(endDateB);

        if (isDayUse) {
            return (
                startStayingDateA.isSameOrBefore(endStayingDateB) &&
                endStayingDateA.isSameOrAfter(startStayingDateB)
            );
        } else {
            return (
                startStayingDateA.isSameOrBefore(endStayingDateB) &&
                endStayingDateA.isSameOrAfter(startStayingDateB) &&
                !startStayingDateA.isSame(endStayingDateB) &&
                !endStayingDateA.isSame(startStayingDateB)
            );
        }
    }
    return false;
};

export const checkOverlappingTime = (
    isDayUse?: boolean,
    startTimeA?: string | null,
    endTimeA?: string | null,
    startTimeB?: string | null,
    endTimeB?: string | null,
) => {
    if (!isDayUse) return false; // no need to check time if not dayUse
    if (startTimeA && endTimeA && startTimeB && endTimeB) {
        const startStayingTimeA = parseTime(startTimeA);
        const endStayingTimeA = parseTime(endTimeA);
        const startStayingTimeB = parseTime(startTimeB);
        const endStayingTimeB = parseTime(endTimeB);
        const isValid =
            startStayingTimeA.isSameOrBefore(endStayingTimeB) &&
            endStayingTimeA.isSameOrAfter(startStayingTimeB);
        return isValid;
    }
    return false;
};

export const validateFileType = (files: IFile[], types: string[]) => {
    const isError = files.some((file) => !types.includes(file.mimetype));
    return {
        isError,
        message: i18next.t('roomBooking.form.uploadFile.message.acceptTypeError', {
            format: ACCEPTED_BOOKING_FILE_FORMATS.join(', '),
        }),
    };
};

export const validateFileSize = (files: IFile[], maxSize: number) => {
    const isError = files.some((file) => file.size > maxSize);
    return {
        isError,
        message: i18next.t('common.uploadFile.message.maxSizeError', {
            maxSize: maxSize / 1024 / 1024,
        }),
    };
};

export const mapCreateRoomBookingBody = (roomBooking: ICreateBookingFormData) => {
    const roomBookingData = mapRoomBookingData(roomBooking);
    if (roomBooking.files?.length) {
        const bodyFormData = new FormData();
        const _newBooking = cloneDeep(roomBooking);
        delete _newBooking.files;
        bodyFormData.append('roomBookingData', JSON.stringify(_newBooking));
        roomBooking.files?.forEach((file) => {
            if (file.isAdditional && file?.originFileObj) {
                bodyFormData.append(`files`, file?.originFileObj);
            }
        });
        return bodyFormData;
    }
    return roomBookingData;
};

export const mapRoomBookingData = (roomBooking: ICreateBookingFormData) => {
    const oldFiles = roomBooking.files?.filter((file) => !file.isAdditional && !!file.id);
    let fileIds;
    if (oldFiles?.length) {
        fileIds = oldFiles.map((file) => +(file.id || 0));
    }
    return {
        representativeGuestId: roomBooking?.representativeGuestId,
        representativeGuestFullName: roomBooking?.representativeGuestFullName || null,
        representativeGuestYomigana: roomBooking?.representativeGuestYomigana || null,
        representativeGuestMobilePhoneNumber:
            roomBooking?.representativeGuestMobilePhoneNumber || null,
        representativeGuestEmailAddress:
            roomBooking?.representativeGuestEmailAddress || null,
        representativeGuestBirthday: roomBooking?.representativeGuestBirthday || null,
        representativeGuestGender: roomBooking?.representativeGuestGender || null,
        isReserverTheRepresentative: roomBooking.isReserverTheRepresentative,
        reserverGuestId: roomBooking?.reserverGuestId || null,
        reserverGuestFullName: roomBooking?.reserverGuestFullName || null,
        reserverGuestYomigana: roomBooking?.reserverGuestYomigana || null,
        reserverGuestMobilePhoneNumber:
            roomBooking?.reserverGuestMobilePhoneNumber || null,
        reserverGuestEmailAddress: roomBooking?.reserverGuestEmailAddress || null,
        marketingChannelId: roomBooking.marketingChannelId,
        memo: roomBooking.memo || null,
        otaMemo: roomBooking.otaMemo || null,
        roomBookingItems: roomBooking.roomBookingItems,
        guests: roomBooking.guests,
        fileIds,
    };
};
export const errorMessagesBookingFile = {
    NUMBER_OF_FILE_TOO_MUCH: i18next.t('common.uploadFile.message.maxNumberOfFiles', {
        count: MAX_NUMBER_OF_FILES_PER_BOOKING,
    }),
    FILE_EXTENSION_NOT_ALLOW: i18next.t(
        'roomBooking.form.uploadFile.message.acceptTypeError',
        {
            format: ACCEPTED_BOOKING_FILE_FORMATS.join(', '),
        },
    ),
    FILE_SIZE_TOO_LARGE: i18next.t('common.uploadFile.message.maxSizeError', {
        maxSize: MAX_SIZE_OF_EACH_BOOKING_FILE / 1024 / 1024,
    }),
    FILE_DUPLICATE: i18next.t('common.uploadFile.message.fileDuplicate'),
    JSON_PARSING_FAILED: i18next.t(
        'roomBooking.form.uploadFile.message.roomBookingDataNotJSON',
    ),
    ROOM_BOOKING_DATA_NOT_EXIST: i18next.t(
        'roomBooking.form.uploadFile.message.roomBookingDataRequire',
    ),
};

/**
 * @param selectedReceiptItem selected receipt items to be deleted
 *
 * use to determine if there is a receipt that can't be deleted
 */
export const shouldNotDeleteReceiptItemsList = (
    selectedReceiptItem: IBookingReceiptTableData[],
) => {
    // user should not be able to remove receipt type receipt_item (receipt_items.type = receipt) if chargedOrPaidDate is inside CI/CO period
    const receiptItemsInvalidForDeletion: IBookingReceiptTableData[] = [];

    selectedReceiptItem.forEach((receiptItem) => {
        if (
            receiptItem.bookingDate &&
            receiptItem.startDatetime &&
            receiptItem.endDatetime
        ) {
            // drop CI/CO time (HH) part for simplicity just to compare dates
            const chargedOrPaidDate = dayjs(
                parseDate(receiptItem.bookingDate).format('YYYY-MM-DD'),
            );
            const checkInDate = dayjs(
                parseDate(receiptItem.startDatetime).format('YYYY-MM-DD'),
            );
            const checkOutDate = dayjs(
                parseDate(receiptItem.endDatetime).format('YYYY-MM-DD'),
            );

            // check if chargedOrPaidDate is inside CI/CO period i.e.  checkInDate <= chargedOrPaidDate <= checkOutDate
            // also check if the items is receipt_items.type = receipt
            // if both are true, the item should be rejected to remove
            if (
                chargedOrPaidDate.isSameOrAfter(checkInDate) &&
                chargedOrPaidDate.isSameOrBefore(checkOutDate) &&
                receiptItem.receiptItemDetailType === ReceiptItemDetailType.STAY_PRICE
            ) {
                receiptItemsInvalidForDeletion.push(receiptItem);
            }
        }
    });

    return receiptItemsInvalidForDeletion;
};

/**
 * {@link https://tabist.atlassian.net/browse/PMS-4423 PMS-4423}
 * check if roomBookingItem can be deleted
 * roomBookingsItems cannot be deleted except following conditions
 * - ADMIN can delete CANCELLED roomBookingItems
 * - HOTEL_ADMIN can delete CANCELLED Walk-In roomBookingItems
 */
export const checkRoomBookingItemCanDelete = (
    roomBookingItem: IRoomBookingItem,
    isAdmin: boolean,
    isAllowHotelAdminDeleteWalklInBooking: boolean,
) =>
    (isAdmin || isAllowHotelAdminDeleteWalklInBooking) &&
    roomBookingItem.bookingStatus === RoomBookingItemBookingStatus.CANCELLED;

export const isAllBookingItemCancelled = (items: IRoomBookingItem[]) => {
    return items.every(
        (booking) => booking.bookingStatus === RoomBookingItemBookingStatus.CANCELLED,
    );
};

export function getRoomNameDisplay(item: IRoomBookingItem, opts?: { withPlan: boolean }) {
    let roomName = item.room ? `/ ${item.room.name}` : '';
    if (item.bookingStatus === RoomBookingItemBookingStatus.CANCELLED) {
        roomName = '';
    }
    if (opts?.withPlan && item.plan) {
        const planName = `- ${item.plan?.name}`;
        return `${getRoomTypeName(item.roomType)} ${planName} ${roomName}`;
    }
    return `${getRoomTypeName(item.roomType)} ${roomName}`;
}

export function calculateSalesTaxAmount(percentage: number, taxableAmount: number) {
    if (taxableAmount !== 0 && percentage !== 0) {
        return Math.floor(taxableAmount - taxableAmount / percentage);
    }
    return 0;
};
