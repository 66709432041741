import React, { useContext } from 'react';
import mitt, { Emitter } from 'mitt';
import { EmitterGlobalEvent } from '~common/constants';
import { FormTab, RoomBookingEvent } from '~features/room-booking/constants';
import { DiagramChartEvent } from '~features/report/constants';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import { RoomManagementEvent } from '~features/room-management/constants';

type EmitterEvent = {
    [EmitterGlobalEvent.LOCAL_STORAGE_CHANGE]: boolean | undefined;
    [EmitterGlobalEvent.TRIGGER_SCROLL]: boolean;
    [EmitterGlobalEvent.SCROLL]: { x: number; y: number };
    [EmitterGlobalEvent.SCHEDULE_SCROLL]: {
        visibleColumnStartIndex: number;
        visibleColumnStopIndex: number;
        visibleRowStartIndex: number;
        visibleRowStopIndex: number;
    };

    [RoomBookingEvent.UPDATE_BOOKING_POSITION]: {
        bookingId: number;
        left: number;
        width: number;
        roomId: number;
    };
    [RoomBookingEvent.REMOVE_BOOKING_POSITION]: {
        bookingId: number;
        roomId: number;
    };
    [RoomBookingEvent.SHOW_MORE_BOOKINGS]: IRoomBookingSchedule[];
    [RoomBookingEvent.CHANGE_DATE]: string;
    [RoomBookingEvent.CHANGE_TAB]: FormTab;
    [RoomBookingEvent.BOOKING_DELETED]: number[];
    [RoomBookingEvent.TOGGLE_AUTO_ASSIGN_MODAL]: boolean;
    [RoomBookingEvent.TOGGLE_UPDATE_ROOM_STATUS]: boolean;
    [RoomBookingEvent.SHOW_ASSIGN_ROOM_SUCCESS]: boolean;
    [RoomManagementEvent.CHANGE_SELLING_STATUS_ROOM]: boolean;
    [DiagramChartEvent.TOGGLE_TOOLTIP]: {
        visible: boolean;
        x?: number;
        y?: number;
        dataIndex?: number;
    };
};

export type CustomEmitter = Emitter<EmitterEvent>;

const emitter: CustomEmitter = mitt<EmitterEvent>();

export interface MittContextType {
    emitter: CustomEmitter;
}

const MittContext = React.createContext<MittContextType>({ emitter });

export const MittProvider: React.FC<any> = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return <MittContext.Provider value={{ emitter }}>{children}</MittContext.Provider>;
};

export const useMitt = (): MittContextType => useContext(MittContext);
