import { INPUT_TEXT_MAX_LENGTH, Regex } from './../../common/constants';
import { TaxType } from '~common/constants';
import yup from '~plugins/yup';
export const updateTaxSchema = yup.object().shape({
    items: yup.array().of(
        yup.object().shape({
            isActive: yup.boolean().required(),
            type: yup.mixed<TaxType>().when('isActive', {
                is: true,
                then: (schema) => schema.oneOf(Object.values(TaxType)).required(),
                otherwise: (schema) => schema.nullable(),
            }),
            name: yup
                .string()
                .trim()
                .hasNoSpecialCharacters(Regex.TAX_SPECIAL_CHARACTERS)
                .max(INPUT_TEXT_MAX_LENGTH)
                .when('isActive', {
                    is: true,
                    then: (schema) => schema.required(),
                    otherwise: (schema) => schema.nullable(),
                }),
        }),
    ),
});
