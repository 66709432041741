import i18next from 'i18next';
import { DEFAULT_LIMIT_FOR_PAGINATION, DEFAULT_ORDER_DIRECTION } from '~common/constants';

export const breadcrumbs = () => [
    {
        text: i18next.t('boHistory.list.breadcrumbs.backOffice'),
    },
    {
        text: i18next.t('boHistory.list.breadcrumbs.history'),
    },
];

export enum SearchField {
    // PIC_NAME = 'picName',
    CREATED_BY_EMAIL = 'createdByEmail',
    ACTIVITY_TITLE = 'activityTitle',
}

export enum SelectSearchQuery {
    ACTION = 'action',
    TARGET_OBJECT_KEY = 'targetObjectKey',
}

export enum HistoryOrderBy {
    ID = 'id',
    CREATED_AT = 'createdAt',
    PIC_NAME = 'picName',
    ACTION = 'action',
}

export enum AuditLogType {
    BO = 'BO',
    FO = 'FO',
}

export const initHistoryListQuery = {
    keyword: '',
    page: 1,
    name: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: HistoryOrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    type: AuditLogType.BO,
};

export const enum HistoryColumn {
    CREATED_AT = 'createdAt',
    PIC_NAME = 'picName',
    ACTIVITY_TITLE = 'activityTitle',
    CREATED_BY_EMAIL = 'createdByEmail',
}

export enum AuditLogActions {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
    BULK_CREATE = 'bulkCreate',
    BULK_UPDATE = 'bulkUpdate',
    BULK_DELETE = 'bulkDelete',
    CHECK_IN = 'checkIn',
    CHECK_OUT = 'checkOut',
    UPDATE_STATUS = 'updateStatus',
    CANCEL = 'cancel',
}

export enum AuditLogModules {
    GENERAL_SETTING = 'generalSetting',
    ADMIN_NOTIFICATION = 'adminNotification',
    HOTEL = 'hotel',
}

export const EXPORT_CSV_FILE_NAME_CSV = 'history.csv';
export const EXPORT_CSV_FILE_NAME = 'history';

export const exportColumns = ['createdAt', 'picName', 'actionTitle'];
export const i18ExportKey = 'history.list';
