import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import TaxList from '../../components/TaxList/TaxList';
import { breadcrumbs } from '../../constants';
import './TaxPage.scss';

export default function TaxPage() {
    const { t } = useTranslation();

    return (
        <div className="tax-page">
            <StaffLayoutHeader breadcrumbs={breadcrumbs()} title={t('tax.list.title')} />
            <Content>
                <div className="tax-content">
                    <TaxList />
                </div>
            </Content>
        </div>
    );
}
