import indicatorListJa from './ja/indicator-list.locale';
import indicatorListEn from './en/indicator-list.locale';
import { indicatorCreate as indicatorCreateJA } from './ja/indicator-create.locale';
import { indicatorCreate as indicatorCreateEn } from './en/indicator-create.locale';

const indicator = {
    ja: {
        list: indicatorListJa,
        create: indicatorCreateJA,
    },
    en: {
        list: indicatorListEn,
        create: indicatorCreateEn,
    },
};

export default indicator;
