import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, notification, Space, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageType } from '~common/constants';
import { InputTextArea, TextTruncate } from '~components';
import { AuditLogModules } from '~features/bo-operation-history/constants';
import { sanitizeLogBody, sanitizeLogData } from '~features/bo-operation-history/helpers';
import { IHistory, IHistoryUpdateBody } from '~features/bo-operation-history/interfaces';
import {
    selectedMemoSelector,
    setMemo,
    updateHistory,
} from '~features/bo-operation-history/reducers/history-list.reducer';
import { updateHistorySchema } from '~features/bo-operation-history/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './HistoryDetail.scss';

interface IProps {
    history: IHistory;
}

function HistoryDetail(props: IProps) {
    const { history } = props;
    const { control, reset, handleSubmit, setError } = useForm({
        resolver: yupResolver(updateHistorySchema),
        defaultValues: useMemo(() => {
            return history;
        }, [history]),
    });
    const { t } = useTranslation();
    const [isEditingMemo, setIsEditingMemo] = useState(false);
    const dispatch = useAppDispatch();
    const module: AuditLogModules = history.targetObjectKey;
    const memo = useAppSelector(selectedMemoSelector(history.id.toString()));

    // recursively create tree for each log item
    const createTree = (logItem: any) => {
        let tree: DataNode[] = [];

        for (let key in logItem) {
            if (key.toLowerCase().includes('password') || key === 'autoGeneratedCode')
                continue;

            // swap beforeData, afterData
            let sanitizedKey = key;

            if (sanitizedKey === 'afterData') {
                sanitizedKey = 'beforeData';
            } else if (sanitizedKey === 'beforeData') {
                sanitizedKey = 'afterData';
            }
            let sanitizedValue = logItem[sanitizedKey];

            if (typeof sanitizedValue === 'object' && sanitizedValue !== null) {
                // if value is empty array then display no data
                if (Array.isArray(sanitizedValue) && !sanitizedValue.length) {
                    tree.push({
                        title: `${t(`boHistory.detail.key.${module}.${sanitizedKey}`)}:`,
                        key: `${sanitizedKey}+${Math.random()}+${Date.now()}}`,
                    });
                } else {
                    let order;
                    if (+sanitizedKey >= 0) order = (+sanitizedKey + 1).toString();
                    let node: DataNode = {
                        title: `${t(`boHistory.detail.key.${module}.${sanitizedKey}`)}:`,
                        key: `${sanitizedKey}+${Math.random()}+${Date.now()}`,
                    };
                    if (order) node.disabled = true;
                    node.children = createTree(sanitizedValue);
                    if (node.children.length) tree.push(node);
                }
            } else {
                const value = sanitizeLogData(sanitizedKey, sanitizedValue, module);
                let order;
                if (+sanitizedKey >= 0) order = +sanitizedKey + 1;
                tree.push({
                    title: order
                        ? `- ${value}`
                        : `${t(
                              `boHistory.detail.key.${module}.${sanitizedKey}`,
                          )}: ${value}`,
                    key: `${sanitizedKey}+${Math.random()}+${Date.now()}}`,
                });
            }
        }
        return tree;
    };

    const sanitizedLogBody = useMemo(() => sanitizeLogBody(history), [history]);
    const treeData = useMemo(
        () => sanitizedLogBody.map(createTree) ?? [],
        [createTree, sanitizeLogBody],
    );

    useEffect(() => {
        reset({ memo });
    }, [history]);

    const onCancel = () => {
        setIsEditingMemo(false);
        reset({ memo });
    };

    const onSave = () => {
        handleSubmit(async (values) => {
            const { memo } = values;
            const historyUpdateBody: IHistoryUpdateBody = {
                memo,
            };

            const response = await dispatch(
                updateHistory({ id: history.id, body: historyUpdateBody }),
            );
            if (updateHistory.fulfilled.match(response)) {
                dispatch(setMemo({ historyId: history.id, memo }));
                if (response?.payload?.success) {
                    notification.success({
                        message: t('boHistory.detail.update.success'),
                    });
                    setIsEditingMemo(false);
                } else {
                    notification.error({
                        message: t('boHistory.detail.update.failed'),
                        description: response.payload?.message,
                    });
                    (response.payload?.errors || []).forEach((error) => {
                        if (error.key === 'memo') {
                            setError(
                                error.key,
                                {
                                    type: ErrorMessageType.MANUAL,
                                    message: error?.message,
                                },
                                { shouldFocus: true },
                            );
                        }
                    });
                }
            }
        })();
    };

    const onChangeMemo = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setIsEditingMemo(true);
    };

    return (
        <div className="bo-history-detail-wrapper">
            <div>
                <div className="top-section">
                    <div className="detail-header">
                        <div className="header-item picName">
                            <div className="title">
                                {t('boHistory.list.columns.picName')}
                            </div>
                            <div>
                                {' '}
                                <TextTruncate text={history?.createdByEmail} />
                            </div>
                        </div>
                        <div className="header-item created-at">
                            <div className="title">
                                {t('boHistory.list.columns.createdAt')}
                            </div>
                            <div>
                                {parseDate(history?.createdAt)?.fmYYYYMMDDHHmm('/')}
                            </div>
                        </div>
                        <div className="header-item activity-title">
                            <div className="title">
                                {t('boHistory.list.columns.activityTitle')}
                            </div>
                            <div>
                                <TextTruncate
                                    text={t(
                                        `boHistory.list.activityTitle.${history.action}${history.targetObjectKey}`,
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mid-section">
                    <div className="body">
                        <div className="activity-log-title">
                            {t('boHistory.list.operationContents')}
                        </div>
                        <div>{history.description.header.activityContent}</div>
                        <div className="log-list">
                            {treeData.map((tree: DataNode[]) => (
                                <Tree
                                    key={Math.random()}
                                    className="log-item"
                                    defaultExpandAll={true}
                                    treeData={tree}
                                    selectable={false}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom-section">
                <Form layout="vertical" autoComplete="off" scrollToFirstError>
                    <div className="history-memo">
                        <InputTextArea
                            label={t('boHistory.detail.memo')}
                            name={`memo`}
                            control={control}
                            defaultValue={memo}
                            placeholder={t('roomCleaning.list.memoInput')}
                            onChange={onChangeMemo}
                        />
                        {isEditingMemo && (
                            <div className="button-group">
                                <Space>
                                    <Button onClick={onCancel}>
                                        {t('common.buttonCancelText')}
                                    </Button>
                                    <Button type="primary" onClick={onSave}>
                                        {t('common.buttonSaveText')}
                                    </Button>
                                </Space>
                            </div>
                        )}
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default HistoryDetail;
