import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '~queries/queries';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { roomBookingService } from '~features/room-booking/services/room-booking.service';
import { getStartDateAndEndDate } from '~features/inventory/helper';
import { IBookingStaticTypeResponse } from '~features/room-booking/interfaces';

const getRoomTypeList = async (): Promise<IBookingStaticTypeResponse | null> => {
    const startDateWeekView = customDayjs()
        .subtract(1, 'week')
        .startOf('week')
        ?.fmYYYYMMDD('-');
    const endDateWeekView = customDayjs().add(1, 'week').endOf('week')?.fmYYYYMMDD('-');
    const { startDate, endDate } = getStartDateAndEndDate(
        parseDate(startDateWeekView).fmYYYYMMDD(),
        parseDate(endDateWeekView).fmYYYYMMDD(),
    );
    const response = await roomBookingService.getRoomList(startDate, endDate);
    if (!response.data) return null;
    return response.data;
};

export const useGetRoomTypeListQuery = () => {
    return useQuery({
        queryKey: [CacheKeys.getRoomList],
        queryFn: getRoomTypeList,
    });
};
