import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { salesByPlansBreadcrumbs } from '~features/report/constants';
import FilterForm from './components/FilterForm/FilterForm';
import SalesByPlansTable from './components/SalesByPlansTable/SalesByPlansTable';
import { useAppSelector } from '~hooks';
import { salesByPlansSelector } from '~features/report/reducers/sales-by-plans.reducer';

function SalesByPlansPage() {
    const { t } = useTranslation();
    const { isShowSalesByPlansTable } = useAppSelector(salesByPlansSelector);

    return (
        <div className="sales-by-plans-page">
            <StaffLayoutHeader
                breadcrumbs={salesByPlansBreadcrumbs()}
                title={t('report.salesByPlans.title')}
            />
            <Layout.Content>
                <div className="sales-by-plans-page-wrapper">
                    <div className="sales-by-plans-page-content">
                        <FilterForm />
                        {isShowSalesByPlansTable && <SalesByPlansTable />}
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default SalesByPlansPage;
