import { CalendarDay } from '~features/rank-calendar/constants';
import { forEach } from 'lodash';
import { DayOfWeek, TllRank } from '../../common/constants';
import { TLL_RANK_UNCHANGED } from './constants';
import {
    IPlanRankDurations,
    IRankCalendarForm,
    IRankCalendarDetail,
    IRankCalendarFormData,
    IDayRank,
} from './interfaces';
import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';

export function mergePlanRankDurations(
    updateRankCalendarForm: IRankCalendarForm,
    rankCalendarForm: IRankCalendarForm,
): IRankCalendarForm {
    const {
        planRankDurations = [],
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
    } = updateRankCalendarForm;
    const dayRankMaps: { [key: string]: TllRank } = {
        [DayOfWeek.MONDAY]: monday,
        [DayOfWeek.TUESDAY]: tuesday,
        [DayOfWeek.WEDNESDAY]: wednesday,
        [DayOfWeek.THURSDAY]: thursday,
        [DayOfWeek.FRIDAY]: friday,
        [DayOfWeek.SATURDAY]: saturday,
        [DayOfWeek.SUNDAY]: sunday,
    };
    monday === (TLL_RANK_UNCHANGED as TllRank) &&
        (updateRankCalendarForm.monday = rankCalendarForm.monday);
    tuesday === (TLL_RANK_UNCHANGED as TllRank) &&
        (updateRankCalendarForm.tuesday = rankCalendarForm.tuesday);
    wednesday === (TLL_RANK_UNCHANGED as TllRank) &&
        (updateRankCalendarForm.wednesday = rankCalendarForm.wednesday);
    thursday === (TLL_RANK_UNCHANGED as TllRank) &&
        (updateRankCalendarForm.thursday = rankCalendarForm.thursday);
    friday === (TLL_RANK_UNCHANGED as TllRank) &&
        (updateRankCalendarForm.friday = rankCalendarForm.friday);
    saturday === (TLL_RANK_UNCHANGED as TllRank) &&
        (updateRankCalendarForm.saturday = rankCalendarForm.saturday);
    sunday === (TLL_RANK_UNCHANGED as TllRank) &&
        (updateRankCalendarForm.sunday = rankCalendarForm.sunday);
    // Break planRankDurations into dayObjects: { [date]: tllRank }
    const { planRankDurations: oldPlanRankDurations = [] } = rankCalendarForm;
    const mergedDateMapTllObj: { [key: string]: TllRank } = {};
    oldPlanRankDurations.forEach((oldPlanRankDuration) => {
        for (
            let i = parseDate(oldPlanRankDuration.startDate);
            parseDate(oldPlanRankDuration.endDate).diff(i, 'day') >= 0;
            i = i.add(1, 'day')
        ) {
            dayRankMaps[i.day()] === (TLL_RANK_UNCHANGED as TllRank) &&
                (mergedDateMapTllObj[i.format('YYYY-MM-DD')] =
                    oldPlanRankDuration.tllRank);
        }
    });
    planRankDurations.forEach((planRankDuration) => {
        for (
            let i = parseDate(planRankDuration.startDate);
            parseDate(planRankDuration.endDate).diff(i, 'day') >= 0;
            i = i.add(1, 'day')
        ) {
            mergedDateMapTllObj[i.format('YYYY-MM-DD')] = planRankDuration.tllRank;
        }
    });
    return {
        ...rankCalendarForm,
        ...updateRankCalendarForm,
        planRankDurations: convertToPlanRankDurations(mergedDateMapTllObj),
    };
}

export function convertToPlanRankDurations(mergedDateMapTllObj: {
    [key: string]: TllRank;
}): IPlanRankDurations[] {
    const updatedPlanRankDurations: IPlanRankDurations[] = [];
    let startDate: string, endDate: string;
    const dates = Object.keys(mergedDateMapTllObj).sort();
    dates.forEach((date, index) => {
        if (!startDate) {
            startDate = date;
        }
        endDate = date;
        const tllRank = mergedDateMapTllObj[date];
        const nextTllRank = mergedDateMapTllObj[dates[index + 1]];
        if (
            !dates[index + 1] ||
            tllRank != nextTllRank ||
            parseDate(dates[index + 1]).diff(parseDate(date), 'day') > 1
        ) {
            updatedPlanRankDurations.push({
                startDate,
                endDate,
                tllRank: tllRank as TllRank,
            });
            if (dates[index + 1]) {
                startDate = dates[index + 1];
            }
        }
    });
    return updatedPlanRankDurations;
}

export function transformPlanRankDurations(planRankDurations: IPlanRankDurations[]) {
    const planRankDurationsForm: any = [];
    forEach(planRankDurations, (planRankDuration, index) => {
        const isPastStartDate =
            parseDate(planRankDuration.startDate).diff(todayDayjs, 'day') < 0;
        const isPastEndDate =
            parseDate(planRankDuration.endDate).diff(todayDayjs, 'day') < 0;
        // remove planRankDurations which is in the past
        if (!isPastStartDate && !isPastEndDate) {
            planRankDurationsForm.push({
                endDate: parseDate(planRankDuration.endDate).format('YYYY-MM-DD'),
                startDate: parseDate(planRankDuration.startDate).format('YYYY-MM-DD'),
                tllRank: planRankDuration.tllRank,
                order: index + 1,
            });
        } else if (isPastStartDate && !isPastEndDate) {
            planRankDurationsForm.push({
                endDate: parseDate(planRankDuration.endDate).format('YYYY-MM-DD'),
                startDate: todayDayjs.format('YYYY-MM-DD'),
                tllRank: planRankDuration.tllRank,
                order: index + 1,
            });
        }
    });
    return planRankDurationsForm;
}

// transform create rank calendar FE form to BE request form
export function transformCreateRankCalendarFormData(
    form: IRankCalendarForm,
): IRankCalendarFormData {
    const formData: IRankCalendarFormData = {
        name: '',
        dayRanks: [],
        planRankDurations: [],
    };
    for (const [key, value] of Object.entries(form)) {
        if (Object.values(CalendarDay).includes(key as CalendarDay)) {
            formData.dayRanks.push({ tllRank: value, dayOfWeek: key as CalendarDay });
        } else {
            formData[key as keyof IRankCalendarFormData] = value;
        }
    }
    return formData;
}

export function getPriorityRank(
    date: Dayjs,
    oldRankCalendar: IRankCalendarDetail | null,
    updateRankCalendar: IRankCalendarForm | null,
    calendarDay: CalendarDay,
) {
    const isPastDay = date.diff(todayDayjs, 'day') < 0;
    let rank: TllRank;
    const pastDayRank = getDayRank(date, oldRankCalendar?.dayRanks, calendarDay);
    const pastCustomDurationRank = oldRankCalendar?.planRankDurations.find(
        (duration) =>
            date.isSameOrAfter(duration.startDate, 'day') &&
            date.isSameOrBefore(duration.endDate, 'day'),
    )?.tllRank;
    const updateDayRank = updateRankCalendar?.[calendarDay];
    const updateCustomDurationRank = updateRankCalendar?.planRankDurations.find(
        (duration) =>
            date.isSameOrAfter(duration.startDate, 'day') &&
            date.isSameOrBefore(duration.endDate, 'day'),
    )?.tllRank;
    if (isPastDay) {
        rank = pastCustomDurationRank || pastDayRank || TllRank.A;
    } else {
        rank =
            updateCustomDurationRank ||
            updateDayRank ||
            pastCustomDurationRank ||
            pastDayRank ||
            TllRank.A;
    }
    return rank;
}

export function getDayRank(
    date: Dayjs,
    dayRanks: IDayRank[] = [],
    calendarDay: CalendarDay,
) {
    const dayRank = dayRanks.find(
        (dayRank) =>
            date.isSameOrAfter(dayRank.startDate || date.startOf('month'), 'day') &&
            date.isSameOrBefore(dayRank.endDate || date.endOf('month'), 'day') &&
            dayRank.dayOfWeek === calendarDay,
    )?.tllRank;
    return dayRank;
}
