import { InboxOutlined } from '@ant-design/icons';
import { Form, Upload, UploadProps } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseErrorMessage } from '~common/commonFunctions';
import { Controller } from '~plugins/hook-form';
import './UploadFile.scss';
const { Dragger } = Upload;

interface CustomInput extends UploadProps {
    name: string;
    label: string;
    defaultValue: string | number;
    control: any;
    title: string;
    subTitle: string | ReactNode;
    pathKey: string;
    required?: boolean;
    maxSize?: number;
    acceptTypes?: string[];
}

export function UploadFileCustom(props: CustomInput) {
    const {
        control,
        name,
        defaultValue,
        label,
        required,
        title,
        subTitle,
        pathKey,
        maxSize,
        acceptTypes,
        ...rest
    } = props;

    const [hasFilesUpload, setHasFilesUpload] = useState(false);
    const { t } = useTranslation();

    const validateFile = (file: RcFile) => {
        // check file size
        if (maxSize && file.size > maxSize) {
            const message = t('common.uploadFile.message.maxSizeError', {
                maxSize: maxSize / 1024 / 1024,
            });
            return { message, isValid: false };
        }

        // check file extension
        const fileNameSplit = file.name?.split('.');
        const extension = fileNameSplit?.length
            ? fileNameSplit[fileNameSplit.length - 1]
            : '';
        if (acceptTypes && !acceptTypes.includes(`.${extension}`)) {
            const message = t('common.uploadFile.message.acceptTypeError');
            return { message, isValid: false };
        }
        return { isValid: true };
    };

    const uploadImage = async (options: any) => {
        const { onSuccess, onError, file } = options;
        try {
            const { isValid, message } = validateFile(file);
            if (!isValid) {
                return onError({ message });
            }
            onSuccess({ isAdditional: true });
        } catch (error) {
            onError({ error });
        }
    };

    const mapFileList = (info: UploadChangeParam<any>) => {
        const fileList = info.fileList || [];
        return fileList?.map((file) => {
            const fileNameSplit = file.name?.split('.');
            return {
                id: file.uid,
                originalName: file.name,
                size: file.size,
                path: file.path,
                url: file.url,
                fileName: file.fileName,
                mimetype: file.type,
                extension: fileNameSplit?.length
                    ? fileNameSplit[fileNameSplit.length - 1]
                    : '',
                status: file.status,
                isAdditional: !!file.response?.isAdditional,
                originFileObj: file.originFileObj,
            };
        });
    };

    return (
        <div className="upload-file-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            className={classNames('form-item-customer', {
                                'upload-list-border': hasFilesUpload,
                            })}
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            required={required}
                        >
                            <Dragger
                                {...rest}
                                name={'file'}
                                onChange={(info) => {
                                    setHasFilesUpload(!!info?.fileList?.length);
                                    field.onChange(mapFileList(info));
                                }}
                                customRequest={uploadImage}
                                accept={acceptTypes?.join()}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{title}</p>
                                <p className="ant-upload-hint">{subTitle}</p>
                            </Dragger>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

UploadFileCustom.defaultProps = {
    defaultValue: '',
};
