import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Modal, notification, Row } from 'antd';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputTextArea } from '~components';
import { UpdateCancelBookingHistoryAttributes } from '~features/report/constants';
import { IUpdateCancellationReport } from '~features/report/interfaces';
import {
    cancellationStateSelector,
    getCancellationList,
    setIsShowMemoModal,
    updateCancellationBooking,
} from '~features/report/reducers/report-cancellation.reducer';
import { updateMemoCancelBooking } from '~features/report/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './UpdateMemoModal.scss';
import { getAutoGeneratedCode } from '~features/room-booking/helper';

function UpdateMemoModal() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, handleSubmit, setValue } = useForm({
        resolver: yupResolver(updateMemoCancelBooking),
    });
    const {
        isShowMemoModal,
        selectedUpdateField,
        selectedCancelBooking,
        cancellationReportGetListQuery,
    } = useAppSelector(cancellationStateSelector);
    const closeModal = () => {
        dispatch(setIsShowMemoModal(false));
        setValue('memo', null, { shouldValidate: true });
    };

    useEffect(() => {
        if (selectedUpdateField && selectedCancelBooking && isShowMemoModal) {
            const memo =
                selectedUpdateField === UpdateCancelBookingHistoryAttributes.MEMO
                    ? selectedCancelBooking.roomBooking?.memo
                    : selectedCancelBooking.otaNote;
            setValue('memo', memo || '');
        }
    }, [selectedUpdateField, selectedCancelBooking, setValue, isShowMemoModal]);

    const _updateMemoCancelBooking = useCallback(
        async (body: IUpdateCancellationReport) => {
            const response = await dispatch(
                updateCancellationBooking({
                    ...body,
                }),
            );
            if (updateCancellationBooking.fulfilled.match(response)) {
                if (response.payload?.success) {
                    dispatch(getCancellationList(cancellationReportGetListQuery));
                    closeModal();
                    notification.success({
                        message: t('report.cancellation.table.updateSuccess'),
                    });
                    return;
                }
                notification.error({ message: response.payload?.message || '' });
            }
        },
        [cancellationReportGetListQuery],
    );

    const onSubmit = () => {
        handleSubmit((data) => {
            if (selectedCancelBooking?.id)
                _updateMemoCancelBooking({
                    id: selectedCancelBooking?.id,
                    body: {
                        key: selectedUpdateField,
                        value: data.memo?.trim() || null,
                    },
                });
        })();
    };

    return (
        <Modal
            title={
                selectedCancelBooking?.roomBooking?.otaReservationNumber
                    ? getAutoGeneratedCode(
                          selectedCancelBooking?.roomBooking?.otaReservationNumber,
                      )
                    : getAutoGeneratedCode(
                          selectedCancelBooking?.roomBooking?.autoGeneratedCode || '',
                      )
            }
            maskClosable={false}
            open={isShowMemoModal}
            onOk={onSubmit}
            onCancel={closeModal}
            okText={t('common.buttonSaveText')}
            cancelText={t('common.buttonCancelText')}
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                <Row>
                    <Col span={24}>
                        <InputTextArea label="" control={control} name="memo" rows={6} />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default UpdateMemoModal;
