const roomOrderSettings = {
    title: 'Room Order Settings',
    breadCrumbs: {
        roomOrderSettings: 'Room order settings',
    },
    header: {
        sortOption: {
            ascending: 'Sort by room type (ascending)',
            descending: 'Sort by room type (descending)',
        },
        button: {
            expandAll: 'Expand all',
            collapseAll: 'Collapse all',
        },
    },
    notification: {
        success: 'Room order has been updated successfully',
    },
};

export default roomOrderSettings;
