const roomForm = {
    edit: {
        title: '部屋の編集',
        idCol: 'ID',
        roomNameCol: '部屋名',
        roomTypeCol: '部屋タイプ',
        success: '部屋が更新されました',
        error: '部屋の更新ができませんでした',
    },
    importTitle: 'CSVアップロード',
    title: 'コネクティングルーム設定',
    connectingRoomType: '部屋タイプ',
    connectingRoomName: 'コネクティングルーム名',
    roomName: '部屋名',
    roomType: '部屋タイプ',
    familyType: 'ファミリータイプ',
    create: {
        error: '部屋の登録ができませんでした',
        success: '部屋が登録されました',
    },
    update: {
        error: '部屋の更新ができませんでした',
        success: '部屋が更新されました',
    },
    placeholder: {
        familyType: '部屋タイプを選択',
        connectingRoomName: '例：101-C',
    },
    import: {
        error: {
            name: {
                required: '表示名を入力してください',
                maxLength: '表示名は最大30文字です',
                uniqueError: '表示名は重複してはいけません',
                hasNoSpecialCharacters: '表示名は特殊字を含めません',
            },
            roomTypeName: {
                required: '部屋タイプを入力してください',
                maxLength: '部屋タイは最大30文字です',
                hasNoSpecialCharacters: '部屋タイプ特殊字を含めません',
            },
            relatedRoomNames: {
                maxLength: 'コネクティングルーム名は最大30文字です',
            },
        },
    },
};

export default roomForm;
