import i18next from '~plugins/i18next/i18n';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
} from '~common/constants';
export enum OrderBy {
    ID = 'id',
    NAME = 'name',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
}

export enum SearchField {
    ALL = 'keyword',
    ID = 'autoGeneratedCode',
    NAME = 'name',
}

export const initGuestClassificationListQuery = {
    keyword: '',
    name: '',
    autoGeneratedCode: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export const breadcrumbs = () => [
    {
        text: i18next.t('guestClassification.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('guestClassification.list.breadcrumbs.guestClassification'),
    },
];

export const MAX_GUEST_CLASSIFICATION_NAME = 32;

export const enum GuestClassificationListColumn {
    ID = 'autoGeneratedCode',
    NAME = 'name',
    COLOR = 'color',
}
export const FILE_NAME = 'guest-classification.csv';

// CSV Export params
export const EXPORT_CSV_FILE_NAME = 'guest-classification';
export const exportColumns = ['autoGeneratedCode', 'name', 'color'];
export const i18ExportKey = 'guestClassification.list';
