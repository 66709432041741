import roomCleaningListJa from './ja/room-cleaning-list.locale';
import printRoomCleaningJa from './ja/print-room-cleaning.locale';

import roomCleaningListEn from './en/room-cleaning-list.locale';
import printRoomCleaningEn from './en/print-room-cleaning.locale';

const roomCleaning = {
    ja: {
        list: roomCleaningListJa,
        printing: printRoomCleaningJa,
    },
    en: {
        list: roomCleaningListEn,
        printing: printRoomCleaningEn,
    },
};

export default roomCleaning;
