import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, notification, Table, Switch } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { cloneDeep, isEmpty, reverse } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkUserPermission, validateUniqueValue } from '~common/commonFunctions';
import { AdminGroup, ErrorMessageType, TaxType, UserGroup } from '~common/constants';
import { InputText } from '~components';
import { TaxColumn } from '~features/tax/constants';
import { getTaxFormId } from '~features/tax/helper';
import { ITax, ITaxUpdateBody } from '~features/tax/interfaces';
import {
    bulkUpdateTaxList,
    fetchTaxList,
    showLoadingSelector,
    taxListSelector,
} from '~features/tax/reducers/tax.reducer';
import { updateTaxSchema } from '~features/tax/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './TaxList.scss';

const AVAILABLE_TAX_TYPES = [
    TaxType.BATH_TAX_2,
    TaxType.BATH_TAX_4,
    TaxType.LOCAL_TAX_1,
    TaxType.LOCAL_TAX_2,
    TaxType.KUTCHAN_TAX,
    TaxType.TAX_1,
    TaxType.TAX_2,
    TaxType.TAX_3,
];

export default function TaxList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const taxList = useAppSelector(taxListSelector);
    const showLoading = useAppSelector(showLoadingSelector);
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const { control, handleSubmit, reset, setError, trigger, clearErrors } = useForm({
        resolver: yupResolver(updateTaxSchema),
    });

    const [dataSource, setDataSource] = useState<ITax[]>();
    const [currentSorter, setCurrentSorter] = useState<SorterResult<ITax>>();

    const availableTaxes = useMemo(() => {
        if (!dataSource) {
            return [];
        }

        return AVAILABLE_TAX_TYPES.map((taxType) => {
            return dataSource.find((tax) => tax.type === taxType);
        }).filter((tax): tax is ITax => tax !== undefined);
    }, [dataSource]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        reset({
            items: availableTaxes,
        });
    }, [availableTaxes]);

    useEffect(() => {
        const clonedTaxList = cloneDeep(taxList);
        const isSorting = currentSorter?.column;
        if (isSorting) {
            clonedTaxList.sort((tax1: ITax, tax2: ITax) => {
                if (!tax1?.[`${isSorting.key}` as keyof ITax]) return 1;
                if (!tax2?.[`${isSorting.key}` as keyof ITax]) return -1;
                return (tax1[`${isSorting.key}` as keyof ITax] as string).localeCompare(
                    tax2[`${isSorting.key}` as keyof ITax] as string,
                );
            });
            if (currentSorter?.order === 'descend') reverse(clonedTaxList);
        }
        setDataSource(clonedTaxList);
    }, [taxList, currentSorter]);

    const fetchData = () => {
        dispatch(fetchTaxList());
    };

    const _bulkUpdate = useCallback(async (taxes: ITaxUpdateBody[]) => {
        const response = await dispatch(bulkUpdateTaxList({ items: taxes }));
        if (bulkUpdateTaxList.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('tax.list.update.success'),
                });
                fetchData();
                return;
            }
            notification.error({
                message: t('tax.list.update.failed'),
                description: response.payload?.errors?.[0]?.message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                if (error.key === 'name' && error.order) {
                    setError(
                        `items.${error.order - 1}.${error.key}`,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                }
            });
        }
    }, []);

    const onChangeSwitch = async (tax: ITax, checked: boolean, index: number) => {
        const _taxList: ITax[] = cloneDeep(dataSource || []);
        const toUpdateTax = _taxList.find((t: ITax) => t.type === tax.type);
        if (toUpdateTax) toUpdateTax.isActive = checked;
        setDataSource(_taxList);
        await trigger();
        if (isEmpty(tax?.name)) {
            clearErrors(`items.${index}.name`);
        }
    };

    const onChangeTaxName = (tax: ITax, newValue: string) => {
        const _taxList: ITax[] = cloneDeep(dataSource || []);
        const toUpdateTax = _taxList.find((t: ITax) => t.type === tax.type);
        if (toUpdateTax) toUpdateTax.name = newValue;
        setDataSource(_taxList);
        trigger();
    };

    const onSubmit = () => {
        handleSubmit((values) => {
            const taxUpdateList: ITax[] = values.items.map((item: ITax) => {
                const taxUpdateBody: ITaxUpdateBody = {
                    isActive: item.isActive,
                    type: item.type,
                };
                if (item.isActive) {
                    taxUpdateBody.name = item?.name;
                }
                return taxUpdateBody;
            });

            const duplicatedNameIndexes = validateUniqueValue(taxUpdateList, 'name');

            if (duplicatedNameIndexes.length) {
                const lastDuplicatedIndex = duplicatedNameIndexes.pop();
                setError(
                    `items.${lastDuplicatedIndex}.name`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('tax.list.update.nameDuplicated'),
                    },
                    {
                        shouldFocus: true,
                    },
                );
                return;
            }
            _bulkUpdate(taxUpdateList);
        })();
    };

    const onChange: TableProps<ITax>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        setCurrentSorter(sorter as SorterResult<ITax>);
    };

    const taxColumns: ColumnsType<ITax> = [
        {
            title: t('tax.list.columns.name'),
            dataIndex: TaxColumn.TYPE,
            key: TaxColumn.TYPE,
            sorter: true,
            render: (taxType: TaxType, tax: ITax, index: number) => {
                return (
                    <>
                        <div className="d-none">
                            <InputText
                                id={getTaxFormId(`items.${index}.type`)}
                                hidden
                                control={control}
                                name={`items.${index}.type`}
                                label={''}
                                defaultValue={taxType}
                            />
                        </div>

                        <div className="tax-type-name">
                            {t(`tax.list.taxType.${taxType}`)}
                            {[
                                TaxType.BATH_TAX_1,
                                TaxType.BATH_TAX_2,
                                TaxType.BATH_TAX_3,
                                TaxType.BATH_TAX_4,
                                TaxType.BATH_TAX_15,
                                TaxType.BATH_TAX_16,
                                TaxType.BATH_TAX_17,
                            ].includes(taxType) && (
                                <span className="tax-type-description">
                                    {t(`tax.list.taxTypeDescription.${taxType}`)}
                                </span>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: t('tax.list.columns.isActive'),
            dataIndex: TaxColumn.IS_ACTIVE,
            width: '9%',
            key: TaxColumn.IS_ACTIVE,
            render: (isActive: boolean, tax: ITax, index: number) => {
                return (
                    <Switch
                        id={getTaxFormId(`items.${index}.is-active`)}
                        defaultChecked={isActive}
                        onChange={(checked) => onChangeSwitch(tax, checked, index)}
                        className={
                            [TaxType.TAX_1, TaxType.TAX_2, TaxType.TAX_3].includes(
                                tax.type,
                            )
                                ? 'd-none'
                                : ''
                        }
                        disabled={isReadOnly}
                    />
                );
            },
            align: 'center',
        },
        {
            title: t('tax.list.columns.displayName'),
            dataIndex: TaxColumn.NAME,
            key: TaxColumn.NAME,
            sorter: true,
            width: '33%',
            render: (name: string, tax: ITax, index: number) => {
                return (
                    tax.isActive && (
                        <InputText
                            id={getTaxFormId(`items.${index}.name`)}
                            control={control}
                            name={`items.${index}.name`}
                            disabled={isReadOnly}
                            label=""
                            defaultValue={name}
                            onBlur={(e) => onChangeTaxName(tax, e.target.value)}
                        />
                    )
                );
            },
        },
    ];

    return (
        <div className="tax-list-wrapper">
            <div className="tax-list-header">
                <div className="header-title">{t('tax.list.header.title')}</div>

                {!isReadOnly && (
                    <div className="header-button">
                        <Button
                            type="primary"
                            id={`btn-update-tax`}
                            className="btn-update"
                            onClick={onSubmit}
                        >
                            {t('tax.list.header.button.save')}
                        </Button>
                    </div>
                )}
            </div>
            <div className="tax-list-content">
                <Form layout="vertical" autoComplete="off" scrollToFirstError>
                    <Table
                        columns={taxColumns}
                        dataSource={availableTaxes}
                        pagination={false}
                        rowKey="type"
                        loading={showLoading}
                        onChange={onChange}
                    />
                </Form>
            </div>
        </div>
    );
}
