import React, {
    useEffect,
    useState,
    useMemo,
    useCallback,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { Table, Button, Form, Switch, notification } from 'antd';
import { DeleteOutlined, PlusCircleOutlined, DownOutlined } from '@ant-design/icons';
import customDayjs, { Dayjs, parseDate, parseTime } from '~plugins/dayjs';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useNavigate } from 'react-router-dom';
import { useForm } from '~plugins/hook-form';
import { useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _, { intersectionBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import {
    planStateSelector,
    planDropdownActiveSelector,
} from '~features/plan/reducers/plan.reducer';
import {
    createGroupBookingItems,
    getTemporaryBookingItemList,
    scheduleStateSelector,
} from '~features/room-booking/reducers/schedule.reducer';
import { groupBookingSchema } from '~features/room-booking/schema';
import { childrenStateSelector } from '~features/children-type/reducers/children.reducer';
import { getListForDropDown } from '~features/room/room.reducer';
import { IRoomGetListDropDownQuery, IRoomDropDown } from '~features/room/interfaces';
import {
    getBookingPrice,
    setCurrentBookingSelected,
    setBookingInfo,
    setBookingConfirmData,
} from '~features/room-booking/reducers/create-booking.reducer';
import {
    getBookingScheduleDefault,
    guestDefault,
    bookingInfoDefault,
} from '~features/room-booking/model';
import {
    DEFAULT_TIME_END_OF_DAY,
    DEFAULT_TIME_START_OF_DAY,
} from '~features/room-booking/constants';
import {
    SingleSelect,
    InputNumber,
    InputText,
    RangePicker,
    TimeRangePickerCustom,
} from '~components';
import { PlanType } from '~features/plan/constants';
import InputAdult from '~features/room-booking/components/InputAdult/InputAdult';
import { ChildrenInput } from '~features/room-booking/components/ChildrenInput/ChildrenInput';
import {
    getGroupBookingFormId,
    getGroupBookingDetail,
    validateDayUseTimeIn24H,
    validateDayUseTimeInAfterTwoDaysBefore,
    validateStartDateInAfterTwoDaysBefore,
    validateStayingDate,
    convertRoomBookingStaticItemToRoomBookingSchedule,
    validateGenderBreakdownOfGuest,
    checkOverlappingDate,
    checkOverlappingTime,
} from '~features/room-booking/helper';
import {
    IBookingConfirmDataItem,
    IBookingGuestInfo,
    IChildBooking,
    ICreateOrUpdateBookingTmp,
    IGetBookingPrice,
    IGroupBookingItem,
    IMemberAttachment,
    IRoomBookingSchedule,
    IRoomBookingStaticItem,
    IRoomGroupBookingSchedule,
} from '~features/room-booking/interfaces';
import { ErrorMessageType, UserGroup } from '~common/constants';
import { IBodyResponse } from '~common/interfaces';
import classNames from 'classnames';
import './GroupBookingTable.scss';
import { checkUserPermission } from '~common/commonFunctions';
import { showConfirm } from '~common/notification';

interface IGroupBookingItems {
    groupBookingItems: IGroupBookingItem[];
}

interface IGroupBookingTable {
    setIsSubmitButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ICategorizedGroupBooking {
    roomTypeId?: number | null;
    numberOfAvailableRooms?: number;
    stayingDate?: [Dayjs | undefined, Dayjs | undefined];
    time?: [Dayjs, Dayjs];
    isDayUse?: boolean;
    bookingItem: {
        groupBookingId?: number | null;
        acquiredNumberOfRooms?: number | null;
    }[];
}

function convertToList<R>(payload: Map<string, Object | string>): R[] {
    const list: R[] = [];
    payload.forEach((value) => {
        if (typeof value === 'object') {
            const newValue = value as R;
            list.push(newValue);
        }
    });
    return list;
}

function getDate(stayingDate?: [Dayjs | undefined, Dayjs | undefined]) {
    return {
        stayingStartDate: stayingDate?.[0]?.fmYYYYMMDD('-') || '',
        stayingEndDate: stayingDate?.[1]?.fmYYYYMMDD('-') || '',
    };
}

function getTime(time?: [Dayjs, Dayjs], isDayUse?: boolean) {
    return {
        checkInTime: isDayUse ? time?.[0]?.fmHHmm() || null : null,
        checkOutTime: isDayUse ? time?.[1]?.fmHHmm() || null : null,
    };
}

const GroupBookingTable = forwardRef<any, IGroupBookingTable>((props, ref) => {
    const isAdmin = useMemo(() => {
        return checkUserPermission([UserGroup.ADMIN]);
    }, []);
    const { setIsSubmitButtonDisabled } = props;
    let selectedPlanType: number | null | undefined = null;
    let selectedRoomType: number | null | undefined = null;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const plansOptions = useAppSelector(planDropdownActiveSelector);
    const {
        isFetchingRoomList,
        roomTypesDropdown,
        defaultBookingOptions,
        isCreatingGroupBookingItem,
    } = useAppSelector(scheduleStateSelector);
    const { children, isFetchingChildrenDropdown } =
        useAppSelector(childrenStateSelector);
    const { isFetchingGetPlanDropdown } = useAppSelector(planStateSelector);
    const bookingDetails = useMemo(() => {
        return {
            ...getBookingScheduleDefault(),
            ...defaultBookingOptions,
        };
    }, [defaultBookingOptions]);

    const [roomsListState, setRoomsListState] = useState<IRoomDropDown[]>([]);
    const [groupBookingId, setGroupBookingId] = useState<number>(1);
    const [groupBookingIdList, setGroupBookingIdList] = useState<number[]>([1]);
    const [isFetchingGroupBookingPriceList, setFetchingGroupBookingPriceList] =
        useState<boolean>(false);

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        watch,
        reset,
        setError,
        clearErrors,
    } = useForm<IGroupBookingItems>({
        resolver: yupResolver(groupBookingSchema),
        defaultValues: {
            groupBookingItems: [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'groupBookingItems',
    });

    const watchPlanType = (index: number) => {
        const planType = watch(`groupBookingItems.${index}.plan`);
        selectedPlanType = planType;
    };

    const watchRoomType = (index: number) => {
        const roomType = watch(`groupBookingItems.${index}.roomTypeId`);
        selectedRoomType = roomType;
    };

    const setInitialGroupBooking = useCallback(
        async (query: IRoomGetListDropDownQuery) => {
            const response = await dispatch(getListForDropDown(query));
            if (getListForDropDown.fulfilled?.match(response)) {
                if (response.payload?.success) {
                    const initialGroupBooking: IGroupBookingItem = {
                        ...getGroupBookingDetail(bookingDetails),
                        groupBookingId,
                        roomsList: response.payload?.data?.items || [],
                    };
                    append(initialGroupBooking);
                    setRoomsListState(response.payload?.data?.items || []);
                    setIsSubmitButtonDisabled(false);
                } else {
                    setRoomsListState([]);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        const roomBookingStayPeriod = [
            parseDate(bookingDetails.stayingStartDate).startOf('day').fmYYYYMMDDHHmmss(),
            parseDate(bookingDetails.stayingEndDate).endOf('day').fmYYYYMMDDHHmmss(),
        ];
        setInitialGroupBooking({
            roomBookingItemId: Number(bookingDetails.id) || undefined,
            roomBookingStayPeriod,
            isDayUse: !!bookingDetails.isDayUse,
        });
        return () => {
            reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const roomsListResponse = async (query: IRoomGetListDropDownQuery) => {
        let responseValue: IRoomDropDown[] = [];
        const response = await dispatch(getListForDropDown(query));
        if (getListForDropDown.fulfilled?.match(response)) {
            if (response.payload?.success) {
                responseValue = response.payload?.data?.items || [];
            }
        }
        return responseValue;
    };

    const handleClickAddButton = () => {
        const formGroupBookingListCopy = fields.map((item) => item);
        const groupBookingListIdsCopy = groupBookingIdList.map((item) => item);
        const addGroupBooking: IGroupBookingItem = {
            ...getGroupBookingDetail(bookingDetails),
            groupBookingId: groupBookingId + 1,
            roomsList: roomsListState,
        };
        if (formGroupBookingListCopy.length === 0) {
            setIsSubmitButtonDisabled(false);
        }
        groupBookingListIdsCopy.push(groupBookingId + 1);
        setGroupBookingIdList(groupBookingListIdsCopy);
        append(addGroupBooking);
        setGroupBookingId(groupBookingId + 1);
    };

    const roomsTypes = useCallback(
        (index: number) => {
            const { plan } = getValues(`groupBookingItems.${index}`);
            if (plan) {
                const planItem = plansOptions.find((option) => {
                    return option.value === plan;
                });
                return roomTypesDropdown
                    .filter((roomType) => {
                        return planItem?.roomTypeIds?.includes(roomType.id);
                    })
                    .map((item) => ({
                        label: item.name,
                        value: item.id,
                    }));
            }

            return roomTypesDropdown.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedPlanType, roomTypesDropdown, plansOptions],
    );

    const handleOnChangeRoomType = (roomTypeId: number, index: number) => {
        const { roomsList } = fields[index];
        const roomTypeDetail = roomTypesDropdown.find(
            (roomType) => roomType.id === roomTypeId,
        );
        const availableRooms =
            roomTypesDropdown.find((roomType) => roomType.id === roomTypeId)?.rooms || [];
        const numberOfAvailableRooms = intersectionBy(
            availableRooms,
            roomsList ? roomsList : [],
            'id',
        );
        setValue(`groupBookingItems.${index}.roomTypeId`, roomTypeId, {
            shouldTouch: true,
        });
        setValue(
            `groupBookingItems.${index}.standardCapacity`,
            roomTypeDetail?.standardCapacity || 0,
        );
        setValue(
            `groupBookingItems.${index}.numberOfAvailableRooms`,
            numberOfAvailableRooms.length,
        );
    };

    const planDropDownOptions = useCallback(
        (index: number) => {
            const { roomTypeId, isDayUse } = getValues(`groupBookingItems.${index}`);
            watchRoomType(index);
            const _planOptions = (plansOptions || []).filter((plan) => {
                const hasPlanType = isDayUse
                    ? plan.type === PlanType.DAY_USE
                    : plan.type === PlanType.STAY;
                return hasPlanType;
            });
            if (roomTypeId) {
                return (_planOptions || []).filter((plan) =>
                    plan.roomTypeIds?.includes(roomTypeId),
                );
            }
            return _planOptions;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [plansOptions, selectedRoomType],
    );

    const onChangePlan = async (value: number | null, index: number) => {
        let roomsResponse: IRoomDropDown[] = [];
        setValue(`groupBookingItems.${index}.plan`, value);
        const { stayingDate, time, isDayUse } = getValues(`groupBookingItems.${index}`);
        const selectedPlan = planDropDownOptions(index).find(
            (plan) => plan.value === value,
        );
        if (!selectedPlan) return;
        if (!stayingDate) {
            roomsResponse = await roomsListResponse({
                roomBookingItemId: Number(bookingDetails.id) || undefined,
                isDayUse: isDayUse,
            });
            setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
            return;
        }
        const query = {
            roomBookingItemId: Number(bookingDetails.id) || undefined,
            roomBookingStayPeriod: [
                `${parseDate(stayingDate?.[0]).fmYYYYMMDD()} ${
                    parseTime(selectedPlan?.checkInTime).fmHHmmss() ||
                    DEFAULT_TIME_START_OF_DAY
                }`,
                `${parseDate(stayingDate?.[1]).fmYYYYMMDD()} ${
                    parseTime(selectedPlan?.checkOutTime).fmHHmmss() ||
                    DEFAULT_TIME_END_OF_DAY
                }`,
            ],
            isDayUse: isDayUse,
        };
        if (isDayUse) {
            query.roomBookingStayPeriod = [
                `${parseDate(stayingDate?.[0]).fmYYYYMMDD()} ${
                    time?.[0]?.fmHHmmss() || DEFAULT_TIME_START_OF_DAY
                }`,
                `${parseDate(stayingDate?.[1]).fmYYYYMMDD()} ${
                    time?.[1]?.fmHHmmss() || DEFAULT_TIME_END_OF_DAY
                }`,
            ];
        }
        roomsResponse = await roomsListResponse(query);
        watchPlanType(index);
        setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
    };

    const inputAdultFieldNames = (index: number): Record<string, string> => {
        return {
            adult: `groupBookingItems.${index}.numberOfAdults`,
            female: `groupBookingItems.${index}.numberOfFemale`,
            male: `groupBookingItems.${index}.numberOfMale`,
            other: `groupBookingItems.${index}.numberOfOtherGenderGuest`,
        };
    };

    const setStayingDateWhenChangeDayUse = (isDayUse: boolean, index: number) => {
        // set check-in and check-out time for form
        if (!bookingDetails.checkInTime || !bookingDetails.checkOutTime) return;
        setValue(`groupBookingItems.${index}.time`, [
            parseTime(bookingDetails.checkInTime),
            parseTime(bookingDetails.checkOutTime),
        ]);

        // set staying date when change day use mode
        if (!bookingDetails.stayingStartDate || !bookingDetails.stayingEndDate) return;
        if (!isDayUse) {
            setValue(`groupBookingItems.${index}.stayingDate`, [
                parseDate(bookingDetails.stayingStartDate),
                parseDate(bookingDetails.stayingEndDate),
            ]);
            return;
        }
        if (
            parseTime(bookingDetails.checkInTime).isSameOrBefore(
                parseTime(bookingDetails.checkOutTime),
                'minute',
            )
        ) {
            setValue(`groupBookingItems.${index}.stayingDate`, [
                parseDate(bookingDetails.stayingStartDate),
                parseDate(bookingDetails.stayingStartDate),
            ]);
        } else {
            setValue(`groupBookingItems.${index}.stayingDate`, [
                parseDate(bookingDetails.stayingStartDate),
                parseDate(bookingDetails.stayingStartDate)?.add(1, 'day'),
            ]);
        }
    };

    const onChangeDayUse = async (index: number) => {
        let roomsResponse: IRoomDropDown[] = [];
        const {
            stayingDate,
            isDayUse,
            plan: planId,
            time,
        } = getValues(`groupBookingItems.${index}`);
        const newValue = !isDayUse;
        setValue(`groupBookingItems.${index}.isDayUse`, newValue);
        setStayingDateWhenChangeDayUse(newValue, index);
        if (!stayingDate) {
            roomsResponse = await roomsListResponse({
                roomBookingItemId: Number(bookingDetails.id) || undefined,
                isDayUse: newValue,
            });
            setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
            return;
        }

        const plan = plansOptions.find((item) => item.value === planId);
        if (
            (newValue && plan?.type !== PlanType.DAY_USE) ||
            (!newValue && plan?.type !== PlanType.STAY)
        ) {
            setValue(`groupBookingItems.${index}.plan`, null);
        }
        const query: IRoomGetListDropDownQuery = {
            roomBookingItemId: Number(bookingDetails.id) || undefined,
            isDayUse: newValue,
            roomBookingStayPeriod: [
                parseDate(stayingDate?.[0]).startOf('day').fmYYYYMMDDHHmmss(),
                parseDate(stayingDate?.[1]).endOf('day').fmYYYYMMDDHHmmss(),
            ],
        };
        if (newValue) {
            query.roomBookingStayPeriod = [
                `${parseDate(stayingDate?.[0]).fmYYYYMMDD()} ${
                    time?.[0]?.fmHHmmss() || DEFAULT_TIME_START_OF_DAY
                }`,
                `${parseDate(stayingDate?.[1]).fmYYYYMMDD()} ${
                    time?.[1]?.fmHHmmss() || DEFAULT_TIME_END_OF_DAY
                }`,
            ];
        }
        roomsResponse = await roomsListResponse(query);
        setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
    };

    const checkDateTimeOfBooking = (
        index: number,
        isDayUse?: boolean,
        stayingDate?: any,
        time?: any,
    ) => {
        // check date time when dayUse: true
        if (stayingDate && time) {
            const validIn24H = validateDayUseTimeIn24H(stayingDate, time);
            if (!validIn24H) {
                setError(`groupBookingItems.${index}.time`, {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.time24hPeriodError'),
                });
            } else {
                clearErrors(`groupBookingItems.${index}.time`);
            }
            const validAfterTwoDaysBefore = validateDayUseTimeInAfterTwoDaysBefore(
                stayingDate,
                time,
            );
            // Allow only ADMIN user to select dates before 2 days prior
            if (!isAdmin && !validAfterTwoDaysBefore) {
                setError(`groupBookingItems.${index}.time`, {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.checkInTimeError'),
                });
                return;
            }
        }

        // check date time when dayUse: false
        if (!isDayUse && stayingDate) {
            const validStartDate = validateStartDateInAfterTwoDaysBefore(stayingDate);
            // Allow only ADMIN user to select dates before 2 days prior
            if (!isAdmin && !validStartDate) {
                setError(`groupBookingItems.${index}.stayingDate`, {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.startDateError'),
                });
                return;
            }

            const validStayingDate = validateStayingDate(stayingDate);
            if (!validStayingDate) {
                setError(`groupBookingItems.${index}.stayingDate`, {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.datePeriodError'),
                });
                return;
            }
        }
    };

    const updateNumberOfAvailableRooms = (roomsList: IRoomDropDown[], index: number) => {
        const roomTypeId = getValues(`groupBookingItems.${index}.roomTypeId`);
        const availableRooms =
            roomTypesDropdown.find((roomType) => roomType.id === roomTypeId)?.rooms || [];
        const numberOfAvailableRooms = intersectionBy(
            availableRooms,
            roomsList ?? [],
            'id',
        );
        setValue(
            `groupBookingItems.${index}.numberOfAvailableRooms`,
            numberOfAvailableRooms.length,
        );
    };

    const onChangeStayingDate = async (
        values: any,
        formatString: string[],
        index: number,
    ) => {
        const { isDayUse, plan: planId, time } = getValues(`groupBookingItems.${index}`);
        let roomsResponse: IRoomDropDown[] = [];

        if (!values || !formatString) {
            roomsResponse = await roomsListResponse({
                roomBookingItemId: Number(bookingDetails.id) || undefined,
                isDayUse,
            });
            setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
            checkDateTimeOfBooking(index, isDayUse, values, time);
            updateNumberOfAvailableRooms(roomsResponse, index);
            return;
        }

        if (isDayUse) {
            // fetch room drop down by time picker
            roomsResponse = await roomsListResponse({
                roomBookingItemId: Number(bookingDetails.id) || undefined,
                isDayUse,
                roomBookingStayPeriod: [
                    `${formatString[0]} ${
                        time?.[0]?.fmHHmmss() || DEFAULT_TIME_START_OF_DAY
                    }`,
                    `${formatString[1]} ${
                        time?.[1]?.fmHHmmss() || DEFAULT_TIME_END_OF_DAY
                    }`,
                ],
            });
            setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
            checkDateTimeOfBooking(index, isDayUse, values, time);
            updateNumberOfAvailableRooms(roomsResponse, index);
            return;
        }

        if (planId) {
            // fetch room drop down by check in/out time of plan
            const selectedPlan = planDropDownOptions(index).find(
                (plan) => plan.value === planId,
            );
            roomsResponse = await roomsListResponse({
                roomBookingItemId: Number(bookingDetails.id) || undefined,
                isDayUse,
                roomBookingStayPeriod: [
                    `${formatString[0]} ${parseTime(
                        selectedPlan?.checkInTime,
                    ).fmHHmmss()}`,
                    `${formatString[1]} ${parseTime(
                        selectedPlan?.checkOutTime,
                    ).fmHHmmss()}`,
                ],
            });
            setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
            checkDateTimeOfBooking(index, isDayUse, values, time);
            updateNumberOfAvailableRooms(roomsResponse, index);
            return;
        }

        // fetch room drop down by check in/out time of selected booking
        roomsResponse = await roomsListResponse({
            roomBookingItemId: Number(bookingDetails.id) || undefined,
            roomBookingStayPeriod: [
                parseDate(formatString[0]).startOf('day').fmYYYYMMDDHHmmss(),
                parseDate(formatString[1]).endOf('day').fmYYYYMMDDHHmmss(),
            ],
            isDayUse,
        });
        setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
        checkDateTimeOfBooking(index, isDayUse, values, time);
        updateNumberOfAvailableRooms(roomsResponse, index);
    };

    const onChangeBookingTime = async (formatString: Dayjs[] | null, index: number) => {
        const { isDayUse, stayingDate, time } = getValues(`groupBookingItems.${index}`);
        checkDateTimeOfBooking(index, isDayUse, stayingDate, formatString);
        let roomsResponse: IRoomDropDown[] = [];
        if (!stayingDate) {
            roomsResponse = await roomsListResponse({
                roomBookingItemId: Number(bookingDetails.id) || undefined,
                isDayUse,
            });
            setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
            updateNumberOfAvailableRooms(roomsResponse, index);
            return;
        }

        roomsResponse = await roomsListResponse({
            roomBookingItemId: Number(bookingDetails.id) || undefined,
            isDayUse,
            roomBookingStayPeriod: [
                `${parseDate(stayingDate[0]).fmYYYYMMDD()} ${
                    formatString?.[0] ? formatString?.[0]?.fmHHmmss() : ''
                }`,
                `${parseDate(stayingDate[1]).fmYYYYMMDD()} ${
                    formatString?.[1] ? formatString?.[1]?.fmHHmmss() : ''
                }`,
            ],
        });
        setValue(`groupBookingItems.${index}.roomsList`, roomsResponse);
        updateNumberOfAvailableRooms(roomsResponse, index);
    };

    const validGenderBreakdownOfGuests = (items: IGroupBookingItem[]) => {
        const mapResult = items.map((data, index) => {
            if (
                !validateGenderBreakdownOfGuest({
                    numberOfAdults: data.numberOfAdults?.toString() || '',
                    numberOfMale: data.numberOfMale?.toString() || '',
                    numberOfFemale: data.numberOfFemale?.toString() || '',
                    numberOfOtherGenderGuest:
                        data.numberOfOtherGenderGuest?.toString() || '',
                })
            ) {
                setError(
                    `groupBookingItems.${index}.numberOfAdults`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.genderBreakdownError'),
                    },
                    { shouldFocus: true },
                );
                return false;
            }
            return true;
        });

        return _.every(mapResult);
    };

    const validDateTimeOfBooking = (items: IGroupBookingItem[]) => {
        const mapResult = items.map((data, index) => {
            // check date time when dayUse: true
            if (!data.stayingDate || !data.stayingDate[0] || !data.stayingDate[1]) {
                setError(`groupBookingItems.${index}.stayingDate`, {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.datePeriodError'),
                });
                return false;
            }

            const stayingDate = [data.stayingDate[0], data.stayingDate[1]] as [
                Dayjs,
                Dayjs,
            ];

            if (data.isDayUse && data.time) {
                const validIn24H = validateDayUseTimeIn24H(stayingDate, data.time);
                if (!validIn24H) {
                    setError(`groupBookingItems.${index}.time`, {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.time24hPeriodError'),
                    });
                    return false;
                }
                const validAfterTwoDaysBefore = validateDayUseTimeInAfterTwoDaysBefore(
                    stayingDate,
                    data.time,
                );
                // Allow only ADMIN user to select dates before 2 days prior
                if (!isAdmin && !validAfterTwoDaysBefore) {
                    setError(`groupBookingItems.${index}.time`, {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.checkInTimeError'),
                    });
                    return false;
                }

                // check date time when dayUse: false
                if (!data.isDayUse && stayingDate) {
                    const validStartDate =
                        validateStartDateInAfterTwoDaysBefore(stayingDate);
                    // Allow only ADMIN user to select dates before 2 days prior
                    if (!isAdmin && !validStartDate) {
                        setError(`groupBookingItems.${index}.stayingDate`, {
                            type: ErrorMessageType.MANUAL,
                            message: t('roomBooking.form.message.startDateError'),
                        });
                        return false;
                    }

                    const validStayingDate = validateStayingDate(stayingDate);
                    if (!validStayingDate) {
                        setError(`groupBookingItems.${index}.stayingDate`, {
                            type: ErrorMessageType.MANUAL,
                            message: t('roomBooking.form.message.datePeriodError'),
                        });
                        return false;
                    }
                }
                return true;
            }

            if (!data.isDayUse) {
                const validStayingDate = validateStayingDate(stayingDate);
                if (!validStayingDate) {
                    setError(`groupBookingItems.${index}.stayingDate`, {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.datePeriodError'),
                    });
                    return false;
                }
            }
            return true;
        });

        return _.every(mapResult);
    };

    const _getIndividualBookingPrices = async (items: IGetBookingPrice[]) => {
        setFetchingGroupBookingPriceList(true);
        return await Promise.all(
            items.map((item) => {
                return dispatch(getBookingPrice(item));
            }),
        );
    };

    const categorizedGroupBooking = (groupBookingItems: IGroupBookingItem[]) => {
        const result: ICategorizedGroupBooking[] = groupBookingItems.reduce(
            (prev: ICategorizedGroupBooking[], currGroupBookingItem) => {
                const {
                    roomTypeId,
                    numberOfAvailableRooms,
                    stayingDate,
                    isDayUse,
                    time,
                } = currGroupBookingItem;
                const currentStayingStartDate = stayingDate?.[0]?.fmYYYYMMDD('-') || '';
                const currentStayingEndDate = stayingDate?.[1]?.fmYYYYMMDD('-') || '';
                const currentCheckInTime = isDayUse ? time?.[0]?.fmHHmm() || null : null;
                const currentCheckOutTime = isDayUse ? time?.[1]?.fmHHmm() || null : null;
                const currBookingIndex = prev.findIndex((prevItem) => {
                    const { stayingDate, time } = prevItem;
                    const prevStayingStartDate = stayingDate?.[0]?.fmYYYYMMDD('-') || '';
                    const prevStayingEndDate = stayingDate?.[1]?.fmYYYYMMDD('-') || '';
                    const prevCheckInTime = isDayUse ? time?.[0]?.fmHHmm() || null : null;
                    const prevCheckOutTime = isDayUse
                        ? time?.[1]?.fmHHmm() || null
                        : null;
                    const isBookingDayUseEqual = isDayUse === prevItem.isDayUse;
                    let isDateAndTimeValid = false;
                    if (isBookingDayUseEqual) {
                        const isOverlappingDate = checkOverlappingDate(
                            isDayUse,
                            prevStayingStartDate,
                            prevStayingEndDate,
                            currentStayingStartDate,
                            currentStayingEndDate,
                        );
                        const isOverlappingTime = checkOverlappingTime(
                            isDayUse,
                            prevCheckInTime,
                            prevCheckOutTime,
                            currentCheckInTime,
                            currentCheckOutTime,
                        );
                        const isBookingDayUse = isDayUse && prevItem.isDayUse;
                        isDateAndTimeValid = isBookingDayUse
                            ? isOverlappingDate && isOverlappingTime
                            : isOverlappingDate;
                    }
                    return (
                        isDateAndTimeValid &&
                        prevItem.roomTypeId === currGroupBookingItem.roomTypeId
                    );
                });
                if (currBookingIndex !== -1) {
                    prev[currBookingIndex] = {
                        ...prev[currBookingIndex],
                        bookingItem: [
                            ...prev[currBookingIndex].bookingItem,
                            {
                                groupBookingId: currGroupBookingItem.groupBookingId,
                                acquiredNumberOfRooms: currGroupBookingItem.numberOfRooms,
                            },
                        ],
                    };
                } else {
                    prev.push({
                        roomTypeId,
                        numberOfAvailableRooms,
                        stayingDate: currGroupBookingItem.stayingDate,
                        time: currGroupBookingItem.time,
                        isDayUse: currGroupBookingItem.isDayUse,
                        bookingItem: [
                            {
                                groupBookingId: currGroupBookingItem.groupBookingId,
                                acquiredNumberOfRooms: currGroupBookingItem.numberOfRooms,
                            },
                        ],
                    });
                }
                return prev;
            },
            [],
        );
        return result;
    };

    const _getGroupBookingPrice = async (groupBookingItems: IGroupBookingItem[]) => {
        const query = groupBookingItems
            .filter((item) => {
                const roomTypeId = item.roomTypeId;
                const planId = item.plan;
                const numberOfAdults = item.numberOfAdults;
                const [start, end] = item.stayingDate as [Dayjs, Dayjs];
                return roomTypeId && planId && numberOfAdults && start && end;
            })
            .map((item) => {
                const [start, end] = item.stayingDate as [Dayjs, Dayjs];

                return {
                    roomTypeId: item.roomTypeId,
                    startDateOfStay: start.fmYYYYMMDD(),
                    endDateOfStay: end.fmYYYYMMDD(),
                    numberOfAdults: Number(item.numberOfAdults),
                    planId: item.plan,
                    childrenTypeQuantities: item.children?.map((quantity, index) => ({
                        childrenTypeId: children[index].id,
                        quantity: Number(quantity),
                    })),
                    groupBookingId: item.groupBookingId,
                } as IGetBookingPrice;
            });

        const response = await _getIndividualBookingPrices(query);
        if (response.every((item) => getBookingPrice.fulfilled.match(item))) {
            return response.map((res, index) => {
                if (getBookingPrice.fulfilled.match(res)) {
                    const { data, message, errors } = res.payload;
                    if (!data || errors) {
                        notification.error({
                            message:
                                message ??
                                t(
                                    'roomBooking.createBooking.message.calculateAmountError',
                                ),
                        });

                        setError(`groupBookingItems.${index}.plan`, {
                            type: ErrorMessageType.MANUAL,
                            message: t('roomBooking.form.planInvalidPrice'),
                        });

                        return {
                            isGroupBookingPriceValid: false,
                            groupBookingId: query[index].groupBookingId,
                            amount: 0,
                        };
                    }
                    return {
                        isGroupBookingPriceValid: true,
                        ...data,
                        groupBookingId: query[index].groupBookingId,
                    };
                }
                return {
                    isGroupBookingPriceValid: false,
                    groupBookingId: query[index].groupBookingId,
                    amount: 0,
                };
            });
        }

        notification.error({
            message: t('roomBooking.createBooking.message.calculateAmountError'),
        });
        return [
            {
                isGroupBookingPriceValid: false,
                groupBookingId: 0,
                amount: 0,
            },
        ];
    };

    const showConfirmOverbookingDialog = (bookings: IRoomGroupBookingSchedule[]) => {
        showConfirm({
            title: t('roomBooking.form.overbooking.title'),
            cancelText: t('roomBooking.form.popupCancel.buttonCancelText'),
            okText: t('roomBooking.form.popupCancel.buttonDeleteText'),
            async onOk() {
                _createGroupBookingItems(bookings, true);
            },
        });
    };

    const navigateToCreateBooking = async () => {
        const getTemporaryBookingListResponse = await dispatch(
            getTemporaryBookingItemList(),
        );
        if (
            getTemporaryBookingItemList.fulfilled.match(getTemporaryBookingListResponse)
        ) {
            if (getTemporaryBookingListResponse.payload?.success) {
                const bookingTmpResponse = getTemporaryBookingListResponse.payload
                    .data as IRoomBookingStaticItem[];
                const bookingTemporaryList: IRoomBookingSchedule[] =
                    convertRoomBookingStaticItemToRoomBookingSchedule(bookingTmpResponse);
                const list: IBookingConfirmDataItem[] = bookingTemporaryList.map(
                    (booking) => {
                        return {
                            booking,
                            memo: null,
                            checkInTime: null,
                            isAssignedRoom: !!booking.room?.id,
                        };
                    },
                );
                let numberOfGuests = 0;
                bookingTemporaryList.forEach((booking) => {
                    numberOfGuests += Number(booking.numberOfAdults || 0);
                });
                const arr = Array.from({ length: numberOfGuests - 1 }, (_, index) => ({
                    ...guestDefault,
                    index: index + 1,
                    gender: null,
                    birthday: null,
                    emailAddress: null,
                })) as IMemberAttachment[];
                const info: IBookingGuestInfo = {
                    ...bookingInfoDefault,
                    attachments: [...arr],
                };
                dispatch(setCurrentBookingSelected(bookingTemporaryList?.[0]?.id || 0));
                dispatch(setBookingInfo(info));
                dispatch(setBookingConfirmData(list));
                navigate('/room-booking/create-booking');
            }
        }
    };

    const _createGroupBookingItems = async (
        bookings: IRoomGroupBookingSchedule[],
        isAcceptOverbooking = false,
    ) => {
        const response = await dispatch(
            createGroupBookingItems({ bookings, isAcceptOverbooking }),
        );
        if (createGroupBookingItems.fulfilled.match(response)) {
            const responsePayload = new Map(Object.entries(response.payload));
            const isSuccess = response.payload as any;
            if (!isSuccess?.success) {
                notification.error({
                    message: t('common.somethingWentWrong'),
                });
                return;
            }
            const responseList =
                convertToList<IBodyResponse<ICreateOrUpdateBookingTmp>>(responsePayload);
            const isFailed = responseList.some(
                (response) => (response?.message ?? null) !== 'success',
            );
            if (!isFailed) {
                navigateToCreateBooking();
                return;
            }
            if (
                !isAcceptOverbooking &&
                responseList.every(
                    (response) =>
                        !response ||
                        response?.message ===
                            t('roomBooking.form.overbooking.warningResponse') ||
                        response?.message ===
                            t('roomBooking.form.overbooking.warningTLLResponse'),
                )
            ) {
                showConfirmOverbookingDialog(bookings);
            }
            responseList.forEach((response, index) => {
                if (!response?.message) {
                    return;
                }
                const message = response?.message || '';
                if (
                    message === t('roomBooking.form.overbooking.warningResponse') ||
                    message === t('roomBooking.form.overbooking.warningTLLResponse')
                ) {
                    setError(`groupBookingItems.${index}.numberOfRooms`, {
                        type: ErrorMessageType.WARNING,
                        message: t('roomBooking.createBooking.message.exceedLimit'),
                    });
                } else {
                    notification.error({
                        message: t('common.somethingWentWrong'),
                        description: message,
                    });
                    setError(`groupBookingItems.${index}.roomTypeId`, {
                        type: ErrorMessageType.MANUAL,
                        message,
                    });
                }
            });
        }
    };

    const handleSubmitGroupBooking = () => {
        handleSubmit(async (formData) => {
            if (!validGenderBreakdownOfGuests(formData.groupBookingItems)) return;
            if (!validDateTimeOfBooking(formData.groupBookingItems)) return;

            const bookingPriceList = await _getGroupBookingPrice(
                formData.groupBookingItems,
            );
            setFetchingGroupBookingPriceList(false);
            if (!bookingPriceList.every((item) => item.isGroupBookingPriceValid)) {
                return;
            }

            const isPlanWithBookingPriceValid = formData.groupBookingItems.filter(
                (item, index) => {
                    const priceResponse = bookingPriceList.find(
                        (price) => item.groupBookingId === price.groupBookingId,
                    );
                    if (priceResponse && !priceResponse.isGroupBookingPriceValid) {
                        setError(`groupBookingItems.${index}.plan`, {
                            type: ErrorMessageType.MANUAL,
                            message: t('roomBooking.form.planInvalidPrice'),
                        });
                        return true;
                    }
                    return false;
                },
            );

            if (!_.isEmpty(isPlanWithBookingPriceValid)) {
                return;
            }

            const _bookings = formData.groupBookingItems.map((data) => {
                const _children: IChildBooking[] = [];
                const { isDayUse } = data;
                _.forEach(data.children, (val, index) => {
                    _children.push({
                        id: null, // not yet created in db so no id yet
                        quantity: Number(val),
                        typeId: children[index]?.id,
                    });
                });
                const amount =
                    bookingPriceList.find(
                        (price) => data.groupBookingId === price.groupBookingId,
                    )?.amount ?? 0;
                return {
                    numberOfAdults: Number(data.numberOfAdults) || null,
                    stayingStartDate: data.stayingDate?.[0]?.fmYYYYMMDD('-') || '',
                    stayingEndDate: data.stayingDate?.[1]?.fmYYYYMMDD('-') || '',
                    checkInTime: isDayUse ? data.time?.[0]?.fmHHmm() || null : null,
                    checkOutTime: isDayUse ? data.time?.[1]?.fmHHmm() || null : null,
                    isDayUse: isDayUse || false,
                    children: _children,
                    room: {
                        id: null,
                    },
                    plan: {
                        id: data.plan || null,
                        name:
                            plansOptions.find((item) => item.value === data.plan)
                                ?.label || '',
                    },
                    roomType: {
                        id: data.roomTypeId || null,
                        name:
                            roomTypesDropdown.find((item) => item.id === data.roomTypeId)
                                ?.name || '',
                    },
                    numberOfMale: Number(data.numberOfMale) || 0,
                    numberOfFemale: Number(data.numberOfFemale) || 0,
                    numberOfOtherGenderGuest: Number(data.numberOfOtherGenderGuest) || 0,
                    price: amount,
                    numberOfRooms: Number(data.numberOfRooms) || 0,
                } as IRoomGroupBookingSchedule;
            });

            await _createGroupBookingItems(_bookings);
        })();
    };

    useImperativeHandle(ref, () => ({
        onClickSubmit() {
            handleSubmitGroupBooking();
        },
    }));

    return (
        <div className="group-booking_table-wrapper">
            <Form layout="vertical">
                <Table
                    loading={
                        isFetchingRoomList ||
                        isFetchingChildrenDropdown ||
                        isFetchingGetPlanDropdown ||
                        isFetchingGroupBookingPriceList ||
                        isCreatingGroupBookingItem
                    }
                    dataSource={fields}
                    pagination={false}
                    rowKey="groupBookingId"
                    className="group-booking-table"
                    locale={{
                        emptyText: <div></div>,
                    }}
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={handleClickAddButton}
                            disabled={
                                getValues()?.groupBookingItems
                                    ? getValues('groupBookingItems').length >= 50
                                    : true
                            }
                            className="button-add"
                        >
                            <PlusCircleOutlined className="right-register-form-icon" />
                            <span>
                                {t('roomBooking.schedule.groupBookingTable.addRowButton')}
                            </span>
                        </Button>
                    )}
                >
                    <Column
                        align="left"
                        width={0}
                        title={`${t('roomBooking.schedule.groupBookingTable.id')}`}
                        dataIndex=""
                        key="id"
                        render={(bookingItem: IGroupBookingItem, record, index) => {
                            return <div>{index + 1}</div>;
                        }}
                    />
                    <Column
                        align="left"
                        width={200}
                        className="dropdown-with-max-width-column-200"
                        title={`${t('roomBooking.schedule.groupBookingTable.roomType')}`}
                        dataIndex=""
                        key="roomTypeId"
                        render={(bookingItem: IGroupBookingItem, record, index) => {
                            return (
                                <SingleSelect
                                    showTooltip={true}
                                    label=""
                                    defaultValue=""
                                    placeholder={t(
                                        'roomBooking.schedule.groupBookingTable.placeHolder.roomType',
                                    )}
                                    name={`groupBookingItems.${index}.roomTypeId`}
                                    control={control}
                                    id={getGroupBookingFormId(
                                        `groupBookingItems.${index}.roomTypeId`,
                                    )}
                                    allowClear
                                    options={roomsTypes(index)}
                                    onChange={(value) => {
                                        handleOnChangeRoomType(value, index);
                                    }}
                                />
                            );
                        }}
                    />
                    <Column
                        align="left"
                        width={300}
                        title={`${t(
                            'roomBooking.schedule.groupBookingTable.lengthOfStay',
                        )}`}
                        dataIndex=""
                        key="lengthOfStay"
                        render={(_bookingItem: IGroupBookingItem, _record, index) => {
                            watch([`groupBookingItems.${index}.stayingDate`]);
                            watch([`groupBookingItems.${index}.isDayUse`]);
                            const { stayingDate, isDayUse } = getValues(
                                `groupBookingItems.${index}`,
                            );
                            return (
                                <>
                                    <RangePicker
                                        required
                                        label=""
                                        placeholder={[
                                            t(
                                                'roomBooking.detail.bookingItemCard.dateOfStayPeriod.placeholder.start',
                                            ),
                                            t(
                                                'roomBooking.detail.bookingItemCard.dateOfStayPeriod.placeholder.end',
                                            ),
                                        ]}
                                        name={`groupBookingItems.${index}.stayingDate`}
                                        defaultValue={[
                                            parseDate(
                                                stayingDate
                                                    ? parseDate(
                                                          stayingDate[0],
                                                      )?.fmYYYYMMDD()
                                                    : null,
                                            ),
                                            parseDate(
                                                stayingDate
                                                    ? parseDate(
                                                          stayingDate[1],
                                                      )?.fmYYYYMMDD()
                                                    : null,
                                            ),
                                        ]}
                                        control={control}
                                        disabledDate={(current) => {
                                            // Allow ADMIN user to select any dates
                                            if (isAdmin) return false;

                                            return current.isBefore(
                                                customDayjs().subtract(2, 'day'),
                                                'day',
                                            );
                                        }}
                                        onChange={(
                                            values: any,
                                            formatString: string[],
                                        ) => {
                                            onChangeStayingDate(
                                                values,
                                                formatString,
                                                index,
                                            );
                                        }}
                                    />
                                    {isDayUse && (
                                        <div className="time-range-picker">
                                            <TimeRangePickerCustom
                                                placeholder={[
                                                    t(
                                                        'roomBooking.form.placeholder.checkInTime',
                                                    ),
                                                    t(
                                                        'roomBooking.form.placeholder.checkOutTime',
                                                    ),
                                                ]}
                                                labelStart=""
                                                labelEnd=""
                                                name={`groupBookingItems.${index}.time`}
                                                control={control}
                                                onChange={(
                                                    formatString: Dayjs[] | null,
                                                ) => {
                                                    onChangeBookingTime(
                                                        formatString,
                                                        index,
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            );
                        }}
                    />
                    <Column
                        align="left"
                        width={90}
                        title={`${t(
                            'roomBooking.schedule.groupBookingTable.numberOfRooms',
                        )}`}
                        dataIndex=""
                        key="numberOfRooms"
                        render={(bookingItem: IGroupBookingItem, record, index) => {
                            return (
                                <InputNumber
                                    label={''}
                                    placeholder={t('roomBooking.form.placeholder.adults')}
                                    name={`groupBookingItems.${index}.numberOfRooms`}
                                    control={control}
                                    isShowIconArrow={true}
                                    id={getGroupBookingFormId(
                                        `groupBookingItems.${index}.numberOfRooms`,
                                    )}
                                />
                            );
                        }}
                    />
                    <Column
                        align="left"
                        width={50}
                        title={`${t('roomBooking.schedule.groupBookingTable.dayUse')}`}
                        dataIndex=""
                        key="dayUse"
                        render={(bookingItem: IGroupBookingItem, record, index) => {
                            watch([`groupBookingItems.${index}.isDayUse`]);
                            const { isDayUse } = getValues(`groupBookingItems.${index}`);
                            return (
                                <Switch
                                    checked={isDayUse}
                                    onChange={() => {
                                        onChangeDayUse(index);
                                    }}
                                />
                            );
                        }}
                    />
                    <Column
                        align="left"
                        width={200}
                        className="dropdown-with-max-width-column-200"
                        title={`${t('roomBooking.schedule.groupBookingTable.plan')}`}
                        dataIndex=""
                        key="plan"
                        render={(bookingItem: any, record, index) => {
                            return (
                                <SingleSelect
                                    showTooltip={true}
                                    label=""
                                    placeholder={t(
                                        'roomBooking.form.placeholder.planName',
                                    )}
                                    name={`groupBookingItems.${index}.plan`}
                                    control={control}
                                    required
                                    allowClear
                                    defaultValue={t('roomBooking.schedule.withoutMeal')}
                                    options={planDropDownOptions(index)}
                                    onChange={(value) => {
                                        onChangePlan(value, index);
                                    }}
                                    id={getGroupBookingFormId(
                                        `groupBookingItems.${index}.plan`,
                                    )}
                                />
                            );
                        }}
                    />
                    <Column
                        align="left"
                        width={90}
                        title={`${t('roomBooking.schedule.groupBookingTable.adult')}`}
                        dataIndex=""
                        key="numberOfAdults"
                        render={(bookingItem: any, record, index) => {
                            return (
                                <InputAdult
                                    className="booking-form-input-adult"
                                    label=""
                                    control={control}
                                    setValue={setValue}
                                    totalFieldId={getGroupBookingFormId(
                                        `groupBookingItems.${index}.numberOfAdults`,
                                    )}
                                    required={true}
                                    fieldName={inputAdultFieldNames(index)}
                                    autoSetTotalGuest={true}
                                />
                            );
                        }}
                    />
                    <Column
                        align="left"
                        width={90}
                        className="dropdown-with-max-width-column-150"
                        title={`${t('roomBooking.schedule.groupBookingTable.children')}`}
                        dataIndex=""
                        key="children"
                        render={(bookingItem: any, record, index) => {
                            watch([`groupBookingItems.${index}.isChildrenDropdownOpen`]);
                            const isDropdownOpen = getValues(
                                `groupBookingItems.${index}.isChildrenDropdownOpen`,
                            );
                            return (
                                <div
                                    className="children-field-wrapper"
                                    id={`children-field-wrapper-${index}-children`}
                                >
                                    <InputText
                                        label=""
                                        placeholder={t(
                                            'roomBooking.form.placeholder.adults',
                                        )}
                                        name={`groupBookingItems.${index}.childrenCount`}
                                        control={control}
                                        id={getGroupBookingFormId(
                                            `groupBookingItems.${index}.childrenCount`,
                                        )}
                                        itemClassName="children-count"
                                    />
                                    <div
                                        id={`input-overlay_${index}`}
                                        className="input-overlay"
                                        onClick={() => {
                                            setValue(
                                                `groupBookingItems.${index}.isChildrenDropdownOpen`,
                                                !isDropdownOpen,
                                            );
                                        }}
                                    >
                                        <DownOutlined
                                            className={classNames({
                                                'expand-icon': true,
                                                active: isDropdownOpen,
                                                deactive: !isDropdownOpen,
                                            })}
                                        />
                                    </div>
                                    <ChildrenInput
                                        isOpen={isDropdownOpen ? isDropdownOpen : false}
                                        control={control}
                                        name={(childrenIndex) =>
                                            `groupBookingItems.${index}.children.${childrenIndex}`
                                        }
                                        onChange={(value, total) => {
                                            setValue(
                                                `groupBookingItems.${index}.childrenCount`,
                                                total.toString(),
                                            );
                                        }}
                                        className="booking-form-children-input"
                                        id={(childrenIndex) =>
                                            `groupBookingItems.${index}.children.${childrenIndex}`
                                        }
                                    />
                                </div>
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.schedule.groupBookingTable.delete')}`}
                        align="center"
                        width={60}
                        dataIndex=""
                        key="delete-button"
                        render={(bookingItem: IGroupBookingItem, record, index) => {
                            return (
                                <Button
                                    id={getGroupBookingFormId(
                                        `groupBookingItems.${index}.delete-button`,
                                    )}
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        const groupBookingIdsListCopy = [
                                            ...groupBookingIdList,
                                        ];
                                        groupBookingIdsListCopy.splice(index, 1);
                                        setGroupBookingIdList(groupBookingIdsListCopy);
                                        remove(index);
                                        if (groupBookingIdsListCopy.length === 0) {
                                            setIsSubmitButtonDisabled(true);
                                        } else {
                                            setIsSubmitButtonDisabled(false);
                                        }
                                    }}
                                />
                            );
                        }}
                    />
                </Table>
            </Form>
        </div>
    );
});

export default GroupBookingTable;
