const indicatorList = {
    title: '部屋インジケーター',
    breadcrumbs: {
        home: 'ホーム',
        frontSetting: 'フロント設定',
        indicator: '部屋インジケータ',
    },
    searchColumn: {
        keyword: '全て',
        name: '部屋インジケーター名',
        indicatorType: 'インジケータータイプ',
        layout: '表示サイズ',
    },
    createBtn: '部屋インジケーターを作成',
    indicatorColumn: {
        id: 'ID',
        name: '部屋インジケーター名',
        indicatorType: 'インジケータータイプ',
        layout: '表示サイズ',
    },
    modalConfirmDeletion: {
        title: '部屋インジケーターの解除',
        cancelButton: 'キャンセル',
        deleteButton: '解除 ',
    },
    message: { deleteSuccess: 'インジケーターが削除されました' },
};

export default indicatorList;
